import React from 'react'
import {
  kakaoAdOperationPolicyURL,
  kakaoAdServiceTermsURL,
} from '../../utils/app/services/kakaoBizPolicy'
import { shallowEqual, useSelector } from 'react-redux'
import { KAKAOCORP_URL } from '../../utils/app/inhouse'
import { kakaoBizCenterPrivacy } from '../../utils/app/services/kakaoBizCenter'

const selector = ({
  user: {
    userInfo: { integrated },
  },
}) => ({
  integrated,
})

const Footer = () => {
  const { integrated } = useSelector(selector, shallowEqual)

  return (
    <footer id="kakaoFoot" className="kakao_foot">
      <div className="inner_footer">
        <h2 className="screen_out">서비스 이용정보</h2>
        <a
          className="link_copyright"
          href={KAKAOCORP_URL}
          rel="noopener noreferrer"
          target="_blank">
          © <strong>Kakao Corp.</strong>
        </a>
        <strong className="screen_out">광고 약관 및 정책</strong>
        <ul className="list_policy">
          <li>
            <a
              className="link_policy"
              href={kakaoAdServiceTermsURL()}
              rel="noopener noreferrer"
              target="_blank">
              카카오비즈니스 이용약관
            </a>
          </li>
          {integrated && (
            <li>
              <a
                className="link_policy"
                href={kakaoAdOperationPolicyURL()}
                rel="noopener noreferrer"
                target="_blank">
                광고 운영정책
              </a>
            </li>
          )}
          <li>
            <a
              className="link_policy"
              href={kakaoBizCenterPrivacy()}
              rel="noopener noreferrer"
              target="_blank">
              개인정보처리방침
            </a>
          </li>
        </ul>
      </div>
    </footer>
  )
}

export default Footer
