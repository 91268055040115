import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { coerceToArray } from '../../utils/stringUtils'
import { initCreativeCreateForm } from './mCreativeCreateV2'

export const CREATIVE_VIEW_MODE = keyMirror({
  NEW: null,
  IMPORT: null,
  COMPLETED_ITEM: null,
  IMPORT_MESSAGE_TEMPLATE: null,
  NONE: null,
})

const CreativeView = keyMirror(
  {
    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'CREATIVE_VIEW'
)

const initialState = fromJS({
  viewMode: CREATIVE_VIEW_MODE.NEW,

  image: {
    isImageUploadAnimVisible: true, // 이미지 업로드 마스킹 anim 표시 여부.
  },

  landingURL: {
    isAddSettingOpened: false, // pc 또는 mobile 타입일때 추가설정 버튼
    isSameUrlChecked: false, // pc or 모바일 url과 동일 체크박스
    isEachChecked: false, // 반응형일때 개별 설정 체크박스
  },
})

export default createReducer(initialState, {
  [CreativeView.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn(keyPath, fromJS(value)),

  [CreativeView.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(keyPath, initialState.getIn(keyPath)),

  [CreativeView.CLEAR]: state => state.merge(initialState),
})

export function setCreativeViewByKeyPath(keyPath, value) {
  return {
    type: CreativeView.SET_BY_KEY_PATH,
    keyPath: [...coerceToArray(keyPath)],
    value,
  }
}

export function initCreativeViewsByKeyPath(keyPath) {
  return {
    type: CreativeView.INIT_BY_KEY_PATH,
    keyPath: [...coerceToArray(keyPath)],
  }
}

export function turnOnCreativeImageUploadAnim() {
  return dispatch =>
    dispatch(
      setCreativeViewByKeyPath(['image', 'isImageUploadAnimVisible'], true)
    )
}

export function turnOffCreativeImageUploadAnim() {
  return dispatch =>
    dispatch(
      setCreativeViewByKeyPath(['image', 'isImageUploadAnimVisible'], false)
    )
}

export function changeCreativeFormViewMode(viewMode) {
  return dispatch => {
    dispatch(initCreativeCreateForm())
    dispatch(setCreativeViewByKeyPath('viewMode', viewMode))
  }
}

export function clearCreativeView() {
  return {
    type: CreativeView.CLEAR,
  }
}
