import { createReducer } from 'redux-immutablejs'
import { fromJS, List } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { handleReviewDocumentException } from './mReview'

const ReviewDocumentList = keyMirror({
  GET_REVIEW_CATEGORY_LIST: null,
  CLEAR_REVIEW_CATEGORY_LIST: null,
})

const initialState = fromJS({
  content: [],
  totalDocumentsCount: 0,
  totalElements: 0,
})

export default createReducer(initialState, {
  [ReviewDocumentList.GET_REVIEW_CATEGORY_LIST]: (state, { data }) => {
    return state.withMutations(s =>
      s
        .set('content', fromJS(data.content))
        .set('totalDocumentsCount', data.totalDocumentsCount)
        .set('totalElements', data.totalElements)
    )
  },
  [ReviewDocumentList.CLEAR_REVIEW_CATEGORY_LIST]: state => {
    return state.withMutations(s =>
      s
        .set('content', List())
        .set('totalDocumentsCount', 0)
        .set('totalElements', 0)
    )
  },
})

/**
 * 8.5 심사 서류 카테고리 리스트 조회
 */
function receiveReviewCategoryList(data) {
  return { type: ReviewDocumentList.GET_REVIEW_CATEGORY_LIST, data }
}

export function clearReviewCategoryList() {
  return { type: ReviewDocumentList.CLEAR_REVIEW_CATEGORY_LIST }
}

/**
 * 심사서류 조회 + 서류 전체 개수 조회
 * @param adAccountId
 * @param pageIndex
 * @param onFinish
 */
export function fetchCategoryDocumentList(
  adAccountId,
  pageIndex,
  onFinish = () => {}
) {
  return async (dispatch, getState, api) => {
    try {
      const { data } = await api.review.fetchCategoryDocumentList(
        adAccountId,
        pageIndex
      )

      const { data: totalDocumentsCount } =
        await api.review.fetchTotalCategoryDocumentCount(adAccountId)

      const { content, totalElements } = data

      dispatch(
        receiveReviewCategoryList({
          content,
          totalElements,
          totalDocumentsCount,
        })
      )
    } catch (e) {
      handleReviewDocumentException(e)
    } finally {
      onFinish()
    }
  }
}
