import { Map } from 'immutable'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  personalMessageCouponBookAssetGroupValidation,
  personalMessageFormButtonAssetGroupValidation,
  personalMessageFormDescriptionValidation,
  personalMessageFormItemValidation,
  personalMessageFormLandingValidation,
  personalMessageFormPcLandingValidation,
  personalMessageFormTitleValidation,
} from './personalMessageFormValidator'
import { PERSONAL_MESSAGE_FORM_VALIDATION_KEY } from './personalMessageFormValidationKey'
import { checkEmpty, hasFullWidthWhitespace } from '../../utils/regexUtils'
import { PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE } from './personalMessageFormValidationMessage'

const MIN_SLIDE_COUNT = 2

export const PERSONAL_MESSAGE_CAROUSEL_FEED_FORM_VALIDATOR = Map({
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_MORE]: messageForm => {
    const { pcLandingUrl } = messageForm

    const itemAssetLandingValidationResult =
      personalMessageFormLandingValidation({
        landingInfo: messageForm,
        isRequired: false,
      })

    const itemAssetPcLandingValidationResult =
      personalMessageFormPcLandingValidation(pcLandingUrl)

    const isValid = [
      itemAssetLandingValidationResult,
      itemAssetPcLandingValidationResult,
    ].every(v => v.isValid)

    return isValid
      ? IS_VALID()
      : IS_NOT_VALID('', {
          landing: itemAssetLandingValidationResult,
          pcLanding: itemAssetPcLandingValidationResult,
        })
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: ({
    itemAssetGroups,
    buttonAssetGroups,
    couponBookAssetGroups,
    creativeFormat,
  }) => {
    if (!itemAssetGroups || itemAssetGroups.count() < MIN_SLIDE_COUNT) {
      return IS_NOT_VALID('입력한 말풍선이 2개 이상이어야 합니다.')
    }

    const itemAssetGroupsValidationResults = []
    itemAssetGroups.forEach((itemAsset, index) => {
      const { assetGroupUUID, title, description, pcLandingUrl } = itemAsset

      const itemAssetTitleValidationResult = personalMessageFormTitleValidation(
        title,
        creativeFormat
      )

      const itemAssetDescriptionValidationResult =
        personalMessageFormDescriptionValidation(description, creativeFormat)

      const itemAssetValidationResult = personalMessageFormItemValidation(
        itemAsset,
        true
      )

      const itemAssetPCLandingValidatorResult =
        personalMessageFormPcLandingValidation(pcLandingUrl)

      const buttonAssetValidatorResult =
        personalMessageFormButtonAssetGroupValidation(
          buttonAssetGroups.filter(
            v =>
              v.get('ordering') === index * 2 ||
              v.get('ordering') === index * 2 + 1
          )
        )

      const couponBookAssetValidatorResult =
        personalMessageCouponBookAssetGroupValidation(
          couponBookAssetGroups.filter(v => v.get('ordering') === index)
        )

      const isValid = [
        itemAssetTitleValidationResult,
        itemAssetValidationResult,
        itemAssetDescriptionValidationResult,
        buttonAssetValidatorResult,
        couponBookAssetValidatorResult,
      ].every(v => v.isValid)

      itemAssetGroupsValidationResults.push({
        assetGroupUUID,
        isValid,
        title: itemAssetTitleValidationResult,
        description: itemAssetDescriptionValidationResult,
        pcLanding: itemAssetPCLandingValidatorResult,
        asset: itemAssetValidationResult,
        buttons:
          buttonAssetValidatorResult['buttonAssetGroupsValidationResults'],
        couponBook:
          couponBookAssetValidatorResult[
            'couponBookAssetGroupsValidationResults'
          ],
      })
    })

    const isAllValid = itemAssetGroupsValidationResults.every(v => {
      return v.isValid
    })

    return isAllValid
      ? IS_VALID()
      : IS_NOT_VALID('', { itemAssetGroupsValidationResults })
  },
})
