import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { LOADING_KEY } from '../../../modules/common/mLoading'
import BasicLoader from '../Loader/BasicLoader'

class GlobalLoadingView extends React.PureComponent {
  static propTypes = {
    isLoading: PropTypes.bool,
  }

  static defaultProps = {
    isLoading: false,
  }

  render() {
    if (this.props.isLoading) {
      return (
        <Fragment>
          <div className="dimmed_layer2" />
          <BasicLoader />
        </Fragment>
      )
    }

    return null
  }
}

const mapStateToProps = ({ loading }) => {
  return {
    isLoading: loading.has(LOADING_KEY.GLOBAL),
  }
}

export default connect(mapStateToProps)(GlobalLoadingView)
