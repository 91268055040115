import { keyMirror } from '../utils/utils'
import DeviceOsTypeEnum from './DeviceOsTypeEnum'

export default {
  Type: keyMirror({
    PC: null,
    MOBILE: null,
    ALL: null,
  }),

  Name: {
    PC: 'PC',
    MOBILE: '모바일',
    ALL: '전체',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getValueByDeviceOsTypes(deviceOsTypes) {
    if (DeviceOsTypeEnum.hasMobileOsType(deviceOsTypes)) return this.Type.MOBILE
    if (DeviceOsTypeEnum.hasPcOsType(deviceOsTypes)) return this.Type.PC
    return null
  },

  isAll(deviceOsTypes) {
    return (
      DeviceOsTypeEnum.hasMobileOsType(deviceOsTypes) &&
      DeviceOsTypeEnum.hasPcOsType(deviceOsTypes)
    )
  },

  getSelectedDeviceType(deviceTypes) {
    const pcType = DeviceOsTypeEnum.hasPcOsType(deviceTypes)
    const mobileType = DeviceOsTypeEnum.hasMobileOsType(deviceTypes)

    if (deviceTypes?.size === 0 || (pcType && mobileType)) {
      return this.Type.ALL
    } else if (pcType) {
      return this.Type.PC
    } else if (mobileType) {
      return this.Type.MOBILE
    } else {
      return undefined
    }
  },
}
