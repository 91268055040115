import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'

import { BIZ_BOARD_BANNER_TYPE } from './bizBoardUtils'
import BizBoardSubTypeEnum from '../../../../enums/BizBoardSubTypeEnum'

const selector = ({
  creativeV2: {
    create: {
      creativeForm: { boardType: createBoardType },
    },
    modify: {
      creativeForm: { boardType: modifyBoardType },
    },
  },
}) => {
  return { createBoardType, modifyBoardType }
}

const useBizBoardBannerFormAvailable = ({ templateData }) => {
  const { createBoardType, modifyBoardType } = useSelector(
    selector,
    shallowEqual
  )

  const boardType = React.useMemo(
    () => modifyBoardType || createBoardType,
    [createBoardType, modifyBoardType]
  )

  return React.useMemo(
    () => ({
      isAvailableObjetImage:
        templateData.bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT,
      isAvailableObjetImage2:
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL &&
        templateData.imageTypeArray.length > 1,
      isAvailableBehavior:
        templateData.bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT,
      isAvailableMaskType:
        templateData.bannerType !== BIZ_BOARD_BANNER_TYPE.TEXT &&
        templateData.bannerType !== BIZ_BOARD_BANNER_TYPE.OBJECT,
      isAvailableLogo:
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING,
      isAvailableBadge:
        (templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
          templateData.bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT) &&
        !BizBoardSubTypeEnum.isSpecialBizBoard(boardType),
    }),
    [boardType, templateData]
  )
}

export default useBizBoardBannerFormAvailable
