import axios from 'axios'
import { keyMirror } from '../../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  ACCOUNT_INFO_LOADING_KEY,
  ACCOUNT_INFO_LOADING_STATE,
  invoiceDefaultDate,
} from '../../../utils/setting/accountInformation'
import { showErrorMessage } from '../../../utils/alertUtils'
import { checkNotEmpty } from '../../../utils/regexUtils'
import { DELEGATION_STATUS_TYPE } from '../../../utils/setting/invoice'

const AccountInformation = keyMirror({
  GET_ACCOUNT_INFO_BY_KEY: null,
  GET_ACCOUNT_INFO_INVOICE: null,
  SET_ACCOUNT_INFO_LOADING_STATUS: null,
  ACCOUNT_INFO_INIT_STATE: null,
})

const initialState = fromJS({
  loadingState: {
    adAccountsLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
    memberLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
    bizRightLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
    cashLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
    kakaopayCardLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
    invoiceLoading: ACCOUNT_INFO_LOADING_STATE.LOADING,
  },
  adAccountInfo: {},
  allAccountMemberList: {},
  businessRight: {},
  cash: {},
  withdrawAccount: {},
  kakaopayCardList: [],
  invoice: {
    searchOptions: {
      dspId: 'KAKAOMOMENT',
      issueStatuses: 'SUCCESS,FAIL',
      issueStartDt: invoiceDefaultDate.from,
      issueEndDt: invoiceDefaultDate.to,
      page: 0,
      size: 10,
    },
    list: {},
  },
  agencyInfo: {},
})

export default createReducer(initialState, {
  [AccountInformation.SET_ACCOUNT_INFO_LOADING_STATUS]: (
    state,
    { key, value }
  ) => state.setIn(['loadingState', key], fromJS(value)),

  [AccountInformation.GET_ACCOUNT_INFO_BY_KEY]: (state, { key, data }) =>
    state.set(key, fromJS(data)),

  [AccountInformation.GET_ACCOUNT_INFO_INVOICE]: (state, { data }) =>
    state.setIn(['invoice', 'list'], fromJS(data)),

  [AccountInformation.ACCOUNT_INFO_INIT_STATE]: state =>
    state.merge(initialState),
})

export const initAccountInformation = () => ({
  type: AccountInformation.ACCOUNT_INFO_INIT_STATE,
})

const setAccountInfoLoadingStatus = (key, value) => ({
  type: AccountInformation.SET_ACCOUNT_INFO_LOADING_STATUS,
  key,
  value,
})

const getInvoice = data => ({
  type: AccountInformation.GET_ACCOUNT_INFO_INVOICE,
  data,
})

const getAccountInfoByKey = (key, data) => ({
  type: AccountInformation.GET_ACCOUNT_INFO_BY_KEY,
  key,
  data,
})

export function fetchAccountInfoApis(adAccountId) {
  return dispatch => {
    return dispatch(fetchAdAccountInfo(adAccountId)).then(() => {
      dispatch(fetchAdAccountInfoMember(adAccountId))
      dispatch(fetchAdAccountInfoBusinessRight(adAccountId))
      dispatch(fetchAdAccountInfoCash(adAccountId))
      dispatch(fetchAdAccountInfoKakaopayCard(adAccountId))
      dispatch(fetchAdAccountInfoInvoice(adAccountId))
      dispatch(fetchAdAccountInfoAgency(adAccountId))
    })
  }
}

export function fetchAccountInfoRetryApiByLoadingKey(adAccountId, key) {
  return dispatch => {
    switch (key) {
      case ACCOUNT_INFO_LOADING_KEY.AD_ACCOUNTS:
        return dispatch(fetchAdAccountInfo(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.MEMBER:
        return dispatch(fetchAdAccountInfoMember(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.BIZ_RIGHT:
        return dispatch(fetchAdAccountInfoBusinessRight(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.INVOICE:
        return dispatch(fetchAdAccountInfoInvoice(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.KAKAOPAY_CARD:
        return dispatch(fetchAdAccountInfoKakaopayCard(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.CASH:
        return dispatch(fetchAdAccountInfoCash(adAccountId))

      case ACCOUNT_INFO_LOADING_KEY.AGENCY:
        return dispatch(fetchAdAccountInfoAgency(adAccountId))

      default:
        return null
    }
  }
}

export function fetchAdAccountInfo(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.AD_ACCOUNTS,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )
    return api.adAccount
      .fetchAdAccountInfoByAdAccountId(adAccountId)
      .then(r => {
        dispatch(getAccountInfoByKey('adAccountInfo', r.data))
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.AD_ACCOUNTS,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      })
      .catch(e => {
        console.log(e.message)
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.AD_ACCOUNTS,
            ACCOUNT_INFO_LOADING_STATE.FAILED
          )
        )
      })
  }
}

export function fetchAdAccountInfoMember(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.MEMBER,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )
    return api.adAccount
      .fetchAllAdAccountMembers(adAccountId, 0, 5)
      .then(r => {
        dispatch(getAccountInfoByKey('allAccountMemberList', r.data))
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.MEMBER,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      })
      .catch(e => {
        console.log(e.message)
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.MEMBER,
            ACCOUNT_INFO_LOADING_STATE.FAILED
          )
        )
      })
  }
}

export function fetchAdAccountInfoBusinessRight(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.BIZ_RIGHT,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )
    return api.adAccount
      .fetchBusinessRights(adAccountId)
      .then(r => {
        dispatch(getAccountInfoByKey('businessRight', r.data))
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.BIZ_RIGHT,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      })
      .catch(e => {
        const { errorCode = -1 } = e?.response?.data || {}
        if (Number(errorCode) === 7001) {
          dispatch(
            setAccountInfoLoadingStatus(
              ACCOUNT_INFO_LOADING_KEY.BIZ_RIGHT,
              ACCOUNT_INFO_LOADING_STATE.IDLE
            )
          )
        } else {
          console.log(e.message)
          dispatch(
            setAccountInfoLoadingStatus(
              ACCOUNT_INFO_LOADING_KEY.BIZ_RIGHT,
              ACCOUNT_INFO_LOADING_STATE.FAILED
            )
          )
        }
      })
  }
}

function fetchAdAccountInfoCash(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.CASH,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )

    const cashAmountInfo = () => {
      return api.cash
        .fetchAdAccountCashInfo(adAccountId)
        .then(r => {
          dispatch(getAccountInfoByKey('cash', r.data))
        })
        .catch(e => {
          console.log(e.message)
          dispatch(
            setAccountInfoLoadingStatus(
              ACCOUNT_INFO_LOADING_KEY.CASH,
              ACCOUNT_INFO_LOADING_STATE.FAILED
            )
          )
        })
    }

    const withdrawAccountInfo = () => {
      return api.cash
        .requestRefundAccountInfo(adAccountId)
        .then(r => {
          dispatch(getAccountInfoByKey('withdrawAccount', r.data))
        })
        .catch(e => {
          console.log(e.message)
          const error = e?.response?.data
          const code = error?.errorCode
          const isShowMessage = [4002, 4003, 4004].includes(code)
          if (isShowMessage) {
            showErrorMessage(error?.description || '')
          }

          if (checkNotEmpty(code) && code !== 4001) {
            // 4001이면 "환불계좌 없음" 으로 에러 아님
            dispatch(
              setAccountInfoLoadingStatus(
                ACCOUNT_INFO_LOADING_KEY.CASH,
                ACCOUNT_INFO_LOADING_STATE.FAILED
              )
            )
          }
        })
    }

    return axios.all([withdrawAccountInfo(), cashAmountInfo()]).finally(() => {
      dispatch(
        setAccountInfoLoadingStatus(
          ACCOUNT_INFO_LOADING_KEY.CASH,
          ACCOUNT_INFO_LOADING_STATE.IDLE
        )
      )
    })
  }
}

function fetchAdAccountInfoKakaopayCard(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.KAKAOPAY_CARD,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )
    return api.card
      .getPaymentCardListInUse(adAccountId)
      .then(r => {
        dispatch(getAccountInfoByKey('kakaopayCardList', r.data))
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.KAKAOPAY_CARD,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      })
      .catch(e => {
        console.log(e.message)
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.KAKAOPAY_CARD,
            ACCOUNT_INFO_LOADING_STATE.FAILED
          )
        )
      })
  }
}

function fetchAdAccountInfoInvoice(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.INVOICE,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )
    const {
      accountInformation: {
        invoice: { searchOptions },
      },
    } = getState()
    return api.invoice
      .fetchInvoiceList(adAccountId, searchOptions.toJS())
      .then(r => {
        dispatch(getInvoice(r.data))
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.INVOICE,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      })
      .catch(e => {
        console.log(e.message)
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.INVOICE,
            ACCOUNT_INFO_LOADING_STATE.FAILED
          )
        )
      })
  }
}

export const fetchAdAccountInfoAgency = adAccountId => {
  return async (dispatch, getState, api) => {
    dispatch(
      setAccountInfoLoadingStatus(
        ACCOUNT_INFO_LOADING_KEY.AGENCY,
        ACCOUNT_INFO_LOADING_STATE.LOADING
      )
    )

    try {
      const agencyResponse = await api.adAccount.fetchBusinessRights(
        adAccountId
      )
      const {
        delegationStatus,
        marketer: {
          agencyCompany: { businessRegistrationNumber: agencyBizNumber },
        },
      } = agencyResponse.data

      if (delegationStatus === DELEGATION_STATUS_TYPE.COMPLETED) {
        // 세금계산서 위임발행 케이스
        const agencyCompanyInfo =
          await api.company.fetchBizCenterBusinessNumber(agencyBizNumber)

        dispatch(getAccountInfoByKey('agencyInfo', agencyCompanyInfo.data))
      }

      dispatch(
        setAccountInfoLoadingStatus(
          ACCOUNT_INFO_LOADING_KEY.AGENCY,
          ACCOUNT_INFO_LOADING_STATE.IDLE
        )
      )
    } catch (e) {
      const { errorCode = -1 } = e?.response?.data || {}
      if (Number(errorCode) === 7001) {
        dispatch(
          // 광고계정에 영업권이 설정되어 있지 않는 경우 무시
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.AGENCY,
            ACCOUNT_INFO_LOADING_STATE.IDLE
          )
        )
      } else {
        dispatch(
          setAccountInfoLoadingStatus(
            ACCOUNT_INFO_LOADING_KEY.AGENCY,
            ACCOUNT_INFO_LOADING_STATE.FAILED
          )
        )
      }
    }
  }
}
