import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    CAMPAIGN: null,
    AD_GROUP: null,
    CREATIVE: null,
  }),

  Name: {
    CAMPAIGN: '캠페인',
    AD_GROUP: '광고그룹',
    CREATIVE: '소재',
  },

  values() {
    return Object.freeze(Object.values(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
