import { isUndefinedOrNull } from '../regexUtils'
import DspAccountStatusEnum from '../../enums/DspAccountStatusEnum'

const DspAccountHelper = {
  Status: {
    toString(status, defaultValue = '', masking = false) {
      if (isUndefinedOrNull(status)) return defaultValue
      switch (status) {
        case DspAccountStatusEnum.Type.DORMANCY:
          return masking ? '-' : '휴면 회원'
        case DspAccountStatusEnum.Type.WITHDRAWAL:
          return '탈퇴 회원'
        default:
          return defaultValue
      }
    },
  },
}

export { DspAccountHelper }
