import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { fromJS, List } from 'immutable'
import { coerceToArray } from '../../utils/stringUtils'
import { hideLoading, showLoading } from '../common/mLoading'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { openPopup, POPUP_KEY } from '../common/mPopup'
import { BizBoardBundleModel } from '../../models/model-creative'
import { v4 as uuid } from 'uuid'
import CreativeUploadAssetPropertyEnum from '../../enums/CreativeUploadAssetPropertyEnum'
import { CreativeGuideTooltip } from '../../components/AdvertiseV2/Creative/Form/Common'
import React from 'react'
import { CmpAssetLibraryUtils } from '../../components/AdvertiseV2/Creative/Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'

/**
 * 비즈보드 배너 소재 생성
 */
const BizBoardCreateBundle = keyMirror(
  {
    ADD_BUNDLES: null,
    SET_BUNDLES: null,
    DELETE_BUNDLE: null,
    SET_BUNDLE_PROP_BY_KEY_PATH: null,
    SELECT_BUNDLE: null,
    CLEAR: null,
  },
  'BIZ_BOARD_CREATE_BUNDLE'
)

const initialState = fromJS({
  bizBoardBundles: [], // BizBoardBundleModel[]
  selectedBizBoardBundleId: null,
})

export default createReducer(initialState, {
  [BizBoardCreateBundle.ADD_BUNDLES]: (state, { bizBoardBundles }) => {
    const { bizBoardBundles: prevBizBoardBundles } = state

    return state.withMutations(s =>
      s
        .update('selectedBizBoardBundleId', prevSelectedBizBoardBundleId =>
          prevBizBoardBundles.isEmpty()
            ? bizBoardBundles.first().get('bundleUUID')
            : prevSelectedBizBoardBundleId
        )
        .update('bizBoardBundles', prev => prev.concat(fromJS(bizBoardBundles)))
    )
  },

  [BizBoardCreateBundle.SET_BUNDLES]: (state, { bizBoardBundles }) =>
    state.set('bizBoardBundles', fromJS(bizBoardBundles)),

  [BizBoardCreateBundle.SET_BUNDLE_PROP_BY_KEY_PATH]: (
    state,
    { index, keyPath, valueOrFn }
  ) =>
    state.updateIn(['bizBoardBundles', index], bizBoardBundle => {
      const keyPathArray = coerceToArray(keyPath)

      if (keyPathArray.length > 1) {
        const withoutLast = keyPathArray.slice(0, -1)

        if (!(bizBoardBundle && bizBoardBundle.getIn(withoutLast))) {
          return bizBoardBundle
        }
      }

      return bizBoardBundle
        ? typeof valueOrFn === 'function'
          ? bizBoardBundle.updateIn(keyPathArray, prevBizBoardBundle =>
              valueOrFn(prevBizBoardBundle)
            )
          : bizBoardBundle.setIn(keyPathArray, fromJS(valueOrFn))
        : bizBoardBundle
    }),

  [BizBoardCreateBundle.DELETE_BUNDLE]: (state, { id }) => {
    const { bizBoardBundles, selectedBizBoardBundleId } = state

    return state.withMutations(s =>
      s
        .update('selectedBizBoardBundleId', prevSelectedBizBoardBundleId => {
          const isSelected = selectedBizBoardBundleId === id

          // 삭제할 대상이 현재 선택된 경우.
          if (isSelected) {
            const bizBoardBundleIdSeq = bizBoardBundles
              .map(v => v.get('bundleUUID'))
              .toSeq()

            return bizBoardBundleIdSeq.count() === 1 // 삭제할 대상을 제외하고 그 외 요소가 존재하지 않는 경우.
              ? undefined
              : bizBoardBundleIdSeq.first() === selectedBizBoardBundleId // 삭제할 대상이 첫번 째 항목인 경우 -> 그 다음 항목을 선택
              ? bizBoardBundleIdSeq.rest().first()
              : bizBoardBundleIdSeq.takeUntil(v => v === id).last() // 삭제할 대상이 첫번 째 항목이 아닌 경우 -> 그 앞 항목을 선택
          } else {
            return prevSelectedBizBoardBundleId
          }
        })
        .update('bizBoardBundles', prev =>
          prev.filter(v => v.get('bundleUUID') !== id)
        )
    )
  },
  [BizBoardCreateBundle.SELECT_BUNDLE]: (state, { id }) =>
    state.set('selectedBizBoardBundleId', id),

  [BizBoardCreateBundle.CLEAR]: () => initialState,
})

export function addBizBoardBundles(bizBoardBundles) {
  return {
    type: BizBoardCreateBundle.ADD_BUNDLES,
    bizBoardBundles,
  }
}

export function setBizBoardCreateBundles(bizBoardBundles) {
  return {
    type: BizBoardCreateBundle.SET_BUNDLES,
    bizBoardBundles,
  }
}

export function setBizBoardCreateBundlePropByKey(index, keyPath, valueOrFn) {
  return {
    type: BizBoardCreateBundle.SET_BUNDLE_PROP_BY_KEY_PATH,
    index,
    keyPath,
    valueOrFn,
  }
}

export function deleteBizBoardCreateBundle(id) {
  return {
    type: BizBoardCreateBundle.DELETE_BUNDLE,
    id,
  }
}

export function selectBizBoardCreateBundle(id) {
  return {
    type: BizBoardCreateBundle.SELECT_BUNDLE,
    id,
  }
}

export function clearBizBoardCreateBundle() {
  return {
    type: BizBoardCreateBundle.CLEAR,
  }
}

/**
 * @param adAccountId {string | number}
 * @param index {number | undefined}
 * @param expressMetaInfo {object}
 * @param base64ImageObject {object}
 * @param responseHandler {object}
 * @param bizBoardNativeImage {object}
 */
export function uploadBizBoardCreateBundleBase64Image({
  adAccountId,
  index,
  expressMetaInfo,
  base64ImageObject,
  responseHandler,
  bizBoardNativeImage = {},
}) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    const {
      creativeV2: {
        common: {
          adGroup: { id: adGroupId },
        },
      },
    } = getState()

    const { onSuccess, onFail, onFinish } = responseHandler || {}
    const formData = new FormData()

    formData.append('multipleImageBase64', JSON.stringify([base64ImageObject]))
    formData.append('adGroupId', String(adGroupId))
    formData.append('creativeFormat', CreativeFormatEnum.Type.IMAGE_BANNER)
    formData.append('png64', String(true))

    try {
      const response =
        await api.adCreative.uploadMultipleAdCreativeImagesWithBase64(
          adAccountId,
          formData
        )

      const { successFiles, invalidFiles } = response?.data || {}

      if (successFiles?.length > 0) {
        const bizBoardImage = fromJS({
          ...successFiles[0],
          expressMetaInfo,
        })

        if (index >= 0) {
          // 기존 bizBoardBundle 의 이미지만 변경.
          dispatch(
            setBizBoardCreateBundlePropByKey(
              index,
              'image',
              bizBoardImage.toJS()
            )
          )

          // NAS 체크 시, bizBoardNativeImage  object 같이 저장
          dispatch(
            setBizBoardCreateBundlePropByKey(
              index,
              'bizBoardNativeImage',
              bizBoardNativeImage
            )
          )
        } else {
          // bizBoardBundle 신규 추가.
          const bizBoardBundle = BizBoardBundleModel.withMutations(s =>
            s
              .set('bundleUUID', uuid())
              .set('image', bizBoardImage)
              .set('bizBoardNativeImage', bizBoardNativeImage)
              .update(bundle => bundle)
          )

          dispatch(addBizBoardBundles(List.of(bizBoardBundle)))
        }

        if (typeof onSuccess === 'function') onSuccess()
      }

      if (invalidFiles?.length > 0) {
        const {
          adConstraints: {
            creativeConstraints: { imageSizes },
          },
        } = getState()

        dispatch(
          openPopup(POPUP_KEY.MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY, {
            summary: fromJS(invalidFiles),
            guideTooltip: (
              <CreativeGuideTooltip
                fileSizeClassName="filesize_info_type2"
                {...CmpAssetLibraryUtils.Upload.guideTooltip({
                  creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
                  creativeUploadAssetPropertyType:
                    CreativeUploadAssetPropertyEnum.Type.IMAGE,
                  sizeConstraintArray:
                    imageSizes
                      ?.get(CreativeFormatEnum.Type.IMAGE_BANNER)
                      .toJS() || [],
                })}
              />
            ),
          })
        )

        if (typeof onFail === 'function') onFail()
      }
    } catch (e) {
      console.log(e.message)
      if (typeof onFail === 'function') onFail(e)
    } finally {
      if (typeof onFinish === 'function') onFinish()

      dispatch(hideLoading())
    }
  }
}
