import { keyMirror } from '../../utils/utils'
import { fromJS, List } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { BizBoardExpandableAssetGroup } from '../../models/model-creative'
import { v4 as uuid } from 'uuid'
import ExpandableEnum from '../../enums/ExpandableEnum'

const BizBoardExpandable = keyMirror(
  {
    ADD_ASSET_GROUP: null,
    SET_ASSET_GROUPS: null,
    DELETE_ASSET_GROUP: null,
    SET_ASSET_GROUP_PROP_BY_KEY_PATH: null,
    CLEAR: null,
    INITIALIZE_BY_TYPE: null,
  },
  'BIZ_BOARD_EXPANDABLE'
)

const initialState = fromJS({
  expandableAssetGroups: [BizBoardExpandableAssetGroup],
})

export default createReducer(initialState, {
  [BizBoardExpandable.ADD_ASSET_GROUP]: (state, { expandableAssetGroups }) =>
    state.update('expandableAssetGroups', prev =>
      prev.concat(fromJS(expandableAssetGroups))
    ),

  [BizBoardExpandable.SET_ASSET_GROUPS]: (state, { expandableAssetGroups }) =>
    state.set('expandableAssetGroups', fromJS(expandableAssetGroups)),

  [BizBoardExpandable.SET_ASSET_GROUP_PROP_BY_KEY_PATH]: (
    state,
    { index, keyPath, value }
  ) => {
    const keyPathArray = coerceToArray(keyPath)
    return state.updateIn(
      ['expandableAssetGroups', index],
      BizBoardExpandableAssetGroup.set('assetGroupUUID', uuid()),
      assetGroup => {
        if (keyPathArray.length > 1) {
          const withoutLast = keyPathArray.slice(0, -1)

          return assetGroup && assetGroup.getIn(withoutLast)
            ? assetGroup.setIn(keyPathArray, fromJS(value))
            : assetGroup
        } else {
          return assetGroup
            ? assetGroup.setIn(keyPathArray, fromJS(value))
            : assetGroup
        }
      }
    )
  },

  [BizBoardExpandable.DELETE_ASSET_GROUP]: (state, { index }) =>
    state.update('expandableAssetGroups', prevAssetGroups =>
      prevAssetGroups.delete(index)
    ),

  [BizBoardExpandable.CLEAR]: () => initialState,

  [BizBoardExpandable.INITIALIZE_BY_TYPE]: (state, { value }) => {
    // 멀티형 확장요소의 경우 빈 리스트로 초기화
    if (value === ExpandableEnum.Type.MULTI) {
      return initialState.set('expandableAssetGroups', List())
    } else {
      return initialState.set(
        'expandableAssetGroups',
        List([BizBoardExpandableAssetGroup])
      )
    }
  },
})

export function addBizBoardExpandableAssetGroups({ expandableAssetGroups }) {
  return {
    type: BizBoardExpandable.ADD_ASSET_GROUP,
    expandableAssetGroups,
  }
}

export function setBizBoardExpandableAssetGroups({ expandableAssetGroups }) {
  return {
    type: BizBoardExpandable.SET_ASSET_GROUPS,
    expandableAssetGroups,
  }
}

export function setBizBoardExpandableAssetGroupPropByKey({
  index,
  keyPath,
  value,
}) {
  return {
    type: BizBoardExpandable.SET_ASSET_GROUP_PROP_BY_KEY_PATH,
    index,
    keyPath,
    value,
  }
}

export function deleteBizBoardExpandableAssetGroup({ index }) {
  return {
    type: BizBoardExpandable.DELETE_ASSET_GROUP,
    index,
  }
}

export function clearBizBoardExpandable() {
  return {
    type: BizBoardExpandable.CLEAR,
  }
}

export function selectBizBoardExpandableType({ value }) {
  return {
    type: BizBoardExpandable.INITIALIZE_BY_TYPE,
    value,
  }
}
