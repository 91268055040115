import { fetchGet, SHOWCASE } from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'

export const ShowcaseApi = {
  /**
   * 광고계정에 연동된 카탈로그 조회
   *  - true: 내 광고계정과 연동된 카탈로그 중 trackId 값이 설정된 카탈로그만 조회
   *  - false: 내 광고계정과 연동된 카탈로그 조회
   * @param adAccountId
   * @param isTrackLinked
   */
  fetchAuthorityCatalogListV2(adAccountId, isTrackLinked = false) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/catalogs?trackLinked=${isTrackLinked}`,
      adAccountId
    )
  },

  /**
   * 카탈로그 단건 조회 (삭제포함)
   * @param adAccountId
   * @param catalogId
   */
  fetchCatalogInfoById(adAccountId, catalogId) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/catalogs/${catalogId}`,
      adAccountId
    )
  },
  /**
   * 특정 카탈로그 하위의 상품 페이지 조회
   * @param adAccountId
   * @param catalogId
   * @param page
   * @param size
   */
  fetchCatalogProducts(adAccountId, catalogId, page = 0, size = 10) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/catalogs/${catalogId}/products?${queryString(
        {
          page,
          size,
        }
      )}`,
      adAccountId
    )
  },
  /**
   * 특정 카탈로그 하위의 상품 세트 리스트
   * @param adAccountId
   * @param catalogId
   */
  fetchCatalogProductSets(adAccountId, catalogId) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/catalogs/${catalogId}/productSets`,
      adAccountId
    )
  },
  /**
   * 특정 상품세트 하위의 상품페이지 조회
   * @param params
   * @param params.adAccountId
   * @param params.productSetId
   * @param params.page
   * @param params.size
   */
  fetchProductSetProducts({ adAccountId, productSetId, page = 0, size = 10 }) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/productSets/${productSetId}/products?${queryString(
        {
          page,
          size,
        }
      )}`,
      adAccountId
    )
  },

  /**
   * 특정 상품 세트 다이내믹 비즈보드 조회
   * @param params
   * @param params.adAccountId
   * @param params.productSetId
   */
  fetchProductSetBizboard({ adAccountId, productSetId, page = 0, size = 1 }) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/productSets/${productSetId}/bizBoardProducts?${queryString(
        {
          page,
          size,
        }
      )}`,
      adAccountId
    )
  },

  /**
   * 카탈로그세트 (삭제포함) 단건조회
   * @param adAccountId
   * @param catalogProductSetId
   */
  fetchAllCatalogProductSets(adAccountId, catalogProductSetId) {
    return fetchGet(
      `${SHOWCASE}/api/v1/showcase/moment/productSets/${catalogProductSetId}`,
      adAccountId
    )
  },
}

export default {
  ...ShowcaseApi,
}
