import React, { Fragment } from 'react'
import { checkEmpty, hasNewLine, isUndefinedOrNull } from './regexUtils'
import { coerceAtLeast, convertToCommaSeparatedString } from './utils'

/**
 * string | array -> array
 * @param value
 * @return {any[]}
 */
export function coerceToArray(value) {
  return value ? (Array.isArray(value) ? value : Array.of(value)) : []
}

/**
 * 개행을 space 로 변환.
 */
export function convertNewLinesToSpaces(input) {
  return String(input).replace(/\n/g, ' ')
}

/**
 * 개행을 무시.
 */
export function convertNewLinesToNone(input) {
  return String(input).replace(/\n/g, '')
}

/**
 * 문자열을 첫번째 개행만 허용하고 이후는 공백으로 변경
 * @param input
 * @returns {string|*}
 */
export function convertNewLinesToOnlyFirstLine(input) {
  if (!hasNewLine(input)) return input
  const newInput = String(input).split('\n')
  return `${newInput.shift()}\n${newInput.join(' ')}`
}

/**
 * 탭을 space 로 변환
 */
export function convertTabsToSpaces(input) {
  return String(input).replace(/\t/g, ' ')
}

/**
 * splitter(default: 개행)로 분할시킨 input 을 array 로 변환.
 */
export function convertStringToDOMArray(input, splitter = /\n/) {
  if (checkEmpty(input)) return input
  const stringArray = String(input).split(splitter)
  const domArray = stringArray.reduce((prev, v, i) => {
    return i === stringArray.length - 1
      ? prev.concat(v)
      : prev.concat(v).concat(<br />)
  }, [])

  return domArray.length > 1
    ? domArray.map((v, i) => <Fragment key={i}>{v}</Fragment>)
    : domArray.toString()
}

/**
 * input 에 query 로 매칭되는 text 강조 처리.
 */
export function convertStringToBoldFaceByQuery(
  input = '',
  _query = '',
  className = 'fw_bold'
) {
  // KAMOQA-23708 이슈로 인해 query 길이 제한 (하이라이트 부분은 50자로 충분해 보이나 일단 200자로 설정) 2023-04-26
  // 다시 문제 발생하여 50자로 변경 2023-06-16
  // 이모티콘의 경우 조회시에 무시되는 거 같으나 query 길이 제한으로 발생하는 부분은 아닌것으로 확인

  // 임시 처리한 코드 롤백
  const query = String(_query).trim()

  if (checkEmpty(query)) return input

  /**
   * input: ABCDEFABCDEF
   * query: A
   * result: ['', 'BCDEF', 'BCDEF']
   *
   * input: ABCDEFABCDEF
   * query: B
   * result: ['A', 'CDEFA', 'CDEF']
   */

  const pattern = String(query)
    .replaceAll(/[^a-zA-Z가-힣ㄱ-ㅎㅏ-ㅣ\d\s]/gm, char => `\\${char}`)
    .replaceAll(/\s/gm, '\\s*')

  const chunkArray =
    pattern.length > 0
      ? String(input).split(new RegExp(pattern, 'ig'))
      : [input]

  return chunkArray.map((chunk, index) => {
    const isFirstCharMatched = index === 0 && chunk === ''
    const isLastChunk = index === chunkArray.length - 1

    return (
      <Fragment key={index}>
        {isFirstCharMatched ? (
          <em key={index} className={className}>
            {query}
          </em>
        ) : isLastChunk ? (
          chunk
        ) : (
          <>
            {chunk}
            <em className={className}>{query}</em>
          </>
        )}
      </Fragment>
    )
  })
}

/**
 * JACK -> Jack
 * jack -> Jack
 * jAcK -> Jack
 */
export function capitalizeFirstLetter(input) {
  if (checkEmpty(input)) return input
  return String(input[0]).toUpperCase() + String(input).slice(1).toLowerCase()
}

// https://web.archive.org/web/20120507054320/http://codeaid.net/javascript/convert-size-in-bytes-to-human-readable-format-(javascript)
export function formatBytes({ bytes, decimals = 1, fallback = '' }) {
  if (isUndefinedOrNull(bytes)) return fallback

  if (bytes === 0 || bytes === 1) return `${bytes}KB`

  const k = 1024
  const dm = coerceAtLeast(decimals, 0)
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

  const i = Math.floor(Math.log(bytes) / Math.log(k))

  if (i === 0) return '1KB'

  return `${convertToCommaSeparatedString(
    Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))
  )}${sizes[i]}`
}

export function extractMimeSubType({ mimeType, fallback = '' }) {
  if (typeof mimeType === 'string' && mimeType.length > 0) {
    return mimeType.split('/', 2).pop().toUpperCase()
  }

  return fallback
}

/** pattern이 null, undefined, '' 인경우에는 -1을 return한다.
 *  paatern이 없는 경우에는 0 있는 경우에는 총 등장횟수를 return
 * https://stackoverflow.com/questions/881085/count-the-number-of-occurrences-of-a-character-in-a-string-in-javascript
 * @param string
 * @param pattern
 * @returns {number|*}
 */
export function countCharacterOccurrences(string, pattern) {
  if (checkEmpty(pattern)) return -1
  const regExp = new RegExp(pattern, 'g')
  return (String(string).match(regExp) || []).length
}

/**
 * Protect CSV Injection
 * https://kakao.agit.in/g/300007118/wall/355830180#comment_panel_356244385
 * https://owasp.org/www-community/attacks/CSV_Injection
 * 1. =, +, -, @, 0x09, 0x0D 로 시작하는 경우
 * 2. DDE() 함수 사용하는 곳
 */
export function replaceCsvInjectionString(str) {
  return /^([=+-@]|0x09|0x0D|DDE\s*\(.*\)).*/gi.test(str) ? `'${str}` : str
}

export function hasUnEncodedPercentage(str) {
  try {
    decodeURI(str)
    return false
  } catch (e) {
    return true
  }
}
