import {
  CONTEXTPATH,
  CONTRACT,
  fetchGet,
  fetchPut,
} from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'

function fetchAvailableDays(adAccountId, productId, cancelTokenSource) {
  return fetchGet(
    `${CONTRACT}/products/${productId}/available-days`,
    adAccountId,
    cancelTokenSource
  )
}

function fetchBookingCount(adAccountId, params, cancelTokenSource) {
  return fetchGet(
    `${CONTRACT}/contracts/bookings?${queryString(params)}`,
    adAccountId,
    cancelTokenSource
  )
}

function fetchHoliday(adAccountId, params, cancelTokenSource) {
  return fetchGet(
    `${CONTRACT}/holiday?${queryString(params)}`,
    adAccountId,
    cancelTokenSource
  )
}

function fetchProductInfo(adAccountId, productKey, cancelTokenSource) {
  return fetchGet(
    `${CONTRACT}/products/${productKey}`,
    adAccountId,
    cancelTokenSource
  )
}

function fetchPricesAll(adAccountId, productId, subType, cancelTokenSource) {
  return fetchGet(
    `${CONTRACT}/products/${productId}/prices/all?subType=${subType}`,
    adAccountId,
    cancelTokenSource
  )
}

function fetchPrices(
  adAccountId,
  productId,
  subType,
  baseDate,
  cancelTokenSource
) {
  return fetchGet(
    `${CONTRACT}/products/${productId}/prices?baseDate=${baseDate}${
      subType ? `&subType=${subType}` : ''
    }`,
    adAccountId,
    cancelTokenSource
  )
}

function cancelContract(adAccountId, campaignId) {
  return fetchPut(
    `${CONTEXTPATH}/campaigns/${campaignId}/cancelContract`,
    null,
    adAccountId
  )
}

export default {
  fetchAvailableDays,
  fetchBookingCount,
  fetchProductInfo,
  fetchPricesAll,
  fetchPrices,
  fetchHoliday,
  cancelContract,
}
