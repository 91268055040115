import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    BASIC: null,
    WIDE: null,
    WIDE_LIST: null,
    CAROUSEL: null,
  }),

  Name: {
    BASIC: '기본텍스트',
    WIDE: '와이드이미지',
    WIDE_LIST: '와이드리스트',
    CAROUSEL: '캐러셀',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
