/**
 * TODO: migrate `creativeImage`
 */

const CreativeAssetUtils = {
  Video: {
    /**
     * 코어에 정의된 형식과 동일. 동영상소재 개발 당시 트랜스코딩 테스트까지 완료된 목록.
     * @type {string[]}
     */
    allowedMimeTypes: [
      'video/mp4', // mp4(MPEG-4 동영상) *권장
      'video/3gpp2', // 3g2(모바일 동영상)
      'video/x-ms-asf', // asf(Windows Media 동영상)
      'video/quicktime', // mov(퀵타임 무비), qt(퀵타임 무비)
      'video/mpeg', // dat(MPEG 동영상), ts(MPEG 전송 스트림), vob(DVD 동영상)
      'video/x-msvideo', // avi(AVI 동영상) *권장
      'video/x-flv', // flv(Flash 동영상) *권장
      'video/avi', // avi(AVI 동영상) *권장
      'video/x-ms-wmv', // wmv(Windows Media 동영상)
      'video/m4v', // m4v(MPEG-4 동영상)
      'video/x-m4v', // m4v(MPEG-4 동영상)
    ],
    fileSizeMax: 1024 * 1024 * 1024,
    fileCountMax: 100,
  },
  MotionBoardVideo: {
    /**
     * 코어에 정의된 형식과 동일. 동영상소재 개발 당시 트랜스코딩 테스트까지 완료된 목록.
     * @type {string[]}
     */
    allowedMimeTypes: [
      'video/mp4', // mp4(MPEG-4 동영상) *권장
      'video/3gpp2', // 3g2(모바일 동영상)
      'video/x-ms-asf', // asf(Windows Media 동영상)
      'video/quicktime', // mov(퀵타임 무비), qt(퀵타임 무비)
      'video/mpeg', // dat(MPEG 동영상), ts(MPEG 전송 스트림), vob(DVD 동영상)
      'video/x-msvideo', // avi(AVI 동영상) *권장
      'video/x-flv', // flv(Flash 동영상) *권장
      'video/avi', // avi(AVI 동영상) *권장
      'video/x-ms-wmv', // wmv(Windows Media 동영상)
      'video/m4v', // m4v(MPEG-4 동영상)
      'video/x-m4v', // m4v(MPEG-4 동영상)
    ],
    fileSizeMax: 1024 * 1024 * 100,
    fileCountMax: 100,
  },

  /**
   *
   * @param imageSizeConstraintArray {{ width: number, height: number, minWidth: number }[]}
   * @returns {*[]}
   */
  sortSizeConstraintArray({ sizeConstraintArray = [] }) {
    return sizeConstraintArray.sort((a, b) => {
      return a.width > b.width
        ? 1
        : a.width < b.width
        ? -1
        : a.height > b.height
        ? 1
        : a.height < b.height
        ? -1
        : a.minWidth > b.minWidth
        ? 1
        : a.minWidth < b.minWidth
        ? -1
        : 0
    })
  },
}

export { CreativeAssetUtils }
