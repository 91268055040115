import { createReducer } from 'redux-immutablejs'
import { fromJS, List, Map } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { coerceToArray } from '../../../utils/stringUtils'
import { toSettingsReviewDetailPath } from '../../../utils/router/routeUtils'
import {
  getReviewDocumentDetailInfo,
  receiveUploadedReviewDocumentList,
  setOpenModeSet,
} from './mReviewDocumentDetail'
import { checkEmpty } from '../../../utils/regexUtils'
import { Validation } from '../../../validators/validation'
import {
  REVIEW_DOCUMENT_FORM_VALIDATION_KEY,
  REVIEW_DOCUMENT_FORM_VALIDATION_KEY_PATH,
  REVIEW_DOCUMENT_FORM_VALIDATOR,
} from '../../../validators/setting/reviewDocumentFormValidator'
import {
  getReviewDocumentErrorMessage,
  handleReviewDocumentException,
  REVIEW_DOCUMENT_ERROR_TYPE,
} from './mReview'
import ProgressStatusEnum from '../../../enums/ProgressStatusEnum'
import {
  createCancellation,
  deleteCancellation,
} from '../../../utils/cancellation/cancellation'
import axios from 'axios'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'

const ReviewDocumentCreate = keyMirror({
  INIT_REVIEW_DOCUMENT_FORM_TO_UPLOAD: null,
  CHANGE_REVIEW_DOCUMENT_FORM_TO_UPLOAD: null,
  GET_REVIEW_DOCUMENTS_TO_UPLOAD: null,
  SET_IS_VALID_REVIEW_BY_KEY: null,
  GET_CREATIVE_CATEGORIES_FIRST: null,
  GET_CREATIVE_CATEGORIES_SECOND: null,
  INIT_REVIEW_DOCUMENT_LIST: null,
  GET_REVIEW_DOCUMENT_LIST_BY_CATEGORY: null,
  SET_REVIEW_DOCUMENT_FILE_UPLOAD_STATE: null,
})

const initialState = fromJS({
  validationErrorKeysForReview: {},

  reviewDocumentFormToUpload: {
    adAccountId: -1,
    creativeCategoryId: -1,
    documents: [],
  },

  reviewDocumentList: {
    categoryId: -1,
    categoryName: '',
    documents: [],
    requiredDocuments: [],
  },

  creativeCategoriesFirst: [],
  creativeCategoriesSecond: [],
  reviewDocumentFileUploadState: ProgressStatusEnum.STATUS.IDLE,
})

export default createReducer(initialState, {
  [ReviewDocumentCreate.INIT_REVIEW_DOCUMENT_LIST]: state =>
    state.set('reviewDocumentList', initialState.get('reviewDocumentList')),

  [ReviewDocumentCreate.GET_REVIEW_DOCUMENT_LIST_BY_CATEGORY]: (
    state,
    { result }
  ) => state.set('reviewDocumentList', fromJS(result || {})),

  [ReviewDocumentCreate.INIT_REVIEW_DOCUMENT_FORM_TO_UPLOAD]: state =>
    state.withMutations(s => {
      createCancellation('uploadRequiredDocument')
      s.set(
        'reviewDocumentFormToUpload',
        initialState.get('reviewDocumentFormToUpload')
      )
        .set('creativeCategoriesSecond', List())
        .set('reviewDocumentFileUploadState', ProgressStatusEnum.STATUS.IDLE)
        .set('validationErrorKeysForReview', Map()) // TODO Jamie: validationErrorKeysForReview 부분 분리
    }),

  [ReviewDocumentCreate.CHANGE_REVIEW_DOCUMENT_FORM_TO_UPLOAD]: (
    state,
    { key, value }
  ) =>
    state.withMutations(s => {
      s.setIn(
        ['reviewDocumentFormToUpload', ...coerceToArray(key)],
        fromJS(value)
      ).set('validationErrorKeysForReview', Map()) // TODO Jamie: validationErrorKeysForReview 부분 분리
    }),

  [ReviewDocumentCreate.GET_REVIEW_DOCUMENTS_TO_UPLOAD]: (
    state,
    { data, categoryDocumentId, categoryDocumentName }
  ) =>
    state.withMutations(s => {
      s.updateIn(['reviewDocumentFormToUpload', 'documents'], documents =>
        documents.unshift(
          Map({
            categoryDocumentId,
            categoryDocumentName,
            fileSize: data.fileSize,
            downloadUrl: data.downloadUrl,
            originalFileName: data.originalFileName,
          })
        )
      ).set('validationErrorKeysForReview', Map()) // TODO Jamie:: validationErrorKeysForReview 부분 분리
    }),

  [ReviewDocumentCreate.GET_CREATIVE_CATEGORIES_FIRST]: (state, { result }) =>
    state.set('creativeCategoriesFirst', fromJS(result || [])),

  [ReviewDocumentCreate.GET_CREATIVE_CATEGORIES_SECOND]: (state, { result }) =>
    state.set('creativeCategoriesSecond', fromJS(result || [])),

  [ReviewDocumentCreate.SET_IS_VALID_REVIEW_BY_KEY]: (
    state,
    { key, isValid = true, message = '' }
  ) => {
    const { validationErrorKeysForReview } = state
    const newErrorKeys = isValid
      ? validationErrorKeysForReview.delete(key)
      : validationErrorKeysForReview.set(key, message)

    return state.set('validationErrorKeysForReview', newErrorKeys)
  },

  [ReviewDocumentCreate.SET_REVIEW_DOCUMENT_FILE_UPLOAD_STATE]: (
    state,
    { uploadState }
  ) => state.set('reviewDocumentFileUploadState', uploadState),
})

/**
 * 8.1 심사 관리 등록 (카테고리 심사 등록)
 */
export function createReviewDocumentList(adAccountId, onFinish = () => {}) {
  return async (dispatch, getState, api) => {
    const {
      review: {
        reviewDocumentCreate: {
          reviewDocumentFormToUpload,
          reviewDocumentFormToUpload: { creativeCategoryId, documents },
        },
      },
    } = getState()

    const validationResult = Validation(
      Map({ documents }),
      REVIEW_DOCUMENT_FORM_VALIDATION_KEY,
      REVIEW_DOCUMENT_FORM_VALIDATION_KEY_PATH,
      REVIEW_DOCUMENT_FORM_VALIDATOR,
      getState,
      setIsValidReviewByKey,
      dispatch
    )

    if (!validationResult) {
      onFinish()
      return
    }

    try {
      await api.review.createCategoryReview(
        reviewDocumentFormToUpload.set('adAccountId', adAccountId),
        adAccountId
      )

      dispatch(getReviewDocumentDetailInfo(adAccountId, creativeCategoryId))

      const contentToUpload = documents
        .groupBy(
          document => `review_document_${document.get('categoryDocumentId')}`
        )
        .map(content => content.map(item => item.get('downloadUrl')))

      dispatch(receiveUploadedReviewDocumentList(contentToUpload))
      dispatch(setOpenModeSet(contentToUpload.keys()))
    } catch (e) {
      if (!axios.isCancel(e)) {
        // TODO Jamie: 팝업에 에러 메시지를 추가하기 위한 장치로 추후 토스트 팝업으로 변경후 제거할 것
        dispatch(
          setIsValidReviewByKey(
            REVIEW_DOCUMENT_FORM_VALIDATION_KEY.REVIEW_DOCUMENTS_NUMBER_TO_UPLOAD,
            false,
            getReviewDocumentErrorMessage(
              e,
              REVIEW_DOCUMENT_ERROR_TYPE.UPLOAD_FAIL
            )
          )
        )
      }
    } finally {
      onFinish()
      dispatch(
        RouterV2.replace(
          toSettingsReviewDetailPath(adAccountId, creativeCategoryId)
        )
      )
    }
  }
}

/**
 * 8.7 1차 카테고리 조회
 */
function receiveCreativeCategoriesFirst(result) {
  return { type: ReviewDocumentCreate.GET_CREATIVE_CATEGORIES_FIRST, result }
}

export function getCreativeCategoriesFirst(adAccountId) {
  return (dispatch, getState, api) => {
    return api.review
      .getCreativeCategoriesFirst(adAccountId)
      .then(response => {
        dispatch(receiveCreativeCategoriesFirst(response.data))
      })
      .catch(e => handleReviewDocumentException(e))
  }
}

/**
 * 8.8 2차 카테고리 조회 + 필요서류
 */
function receiveCreativeCategoriesSecond(result) {
  return { type: ReviewDocumentCreate.GET_CREATIVE_CATEGORIES_SECOND, result }
}

export function getCreativeCategoriesSecond(adAccountId, creativeCategoryId) {
  return (dispatch, getState, api) => {
    return api.review
      .getCreativeCategoriesSecond(adAccountId, creativeCategoryId)
      .then(response => {
        dispatch(receiveCreativeCategoriesSecond(response.data))
      })
      .catch(e => handleReviewDocumentException(e))
  }
}

export function initReviewDocumentList() {
  return { type: ReviewDocumentCreate.INIT_REVIEW_DOCUMENT_LIST }
}

function receiveReviewDocumentListByCategory(result) {
  return {
    type: ReviewDocumentCreate.GET_REVIEW_DOCUMENT_LIST_BY_CATEGORY,
    result,
  }
}

export function getReviewDocumentList(adAccountId, creativeCategoryId) {
  return (dispatch, getState, api) => {
    return api.review
      .getReviewDocumentByCategory(adAccountId, creativeCategoryId)
      .then(response => {
        dispatch(receiveReviewDocumentListByCategory(response.data))
      })
      .catch(e => handleReviewDocumentException(e))
  }
}

/**
 * 심사서류 파일등록
 */
function receiveUploadedReviewDocuments(
  data,
  categoryDocumentId,
  categoryDocumentName
) {
  return {
    type: ReviewDocumentCreate.GET_REVIEW_DOCUMENTS_TO_UPLOAD,
    data,
    categoryDocumentId,
    categoryDocumentName,
  }
}

export function uploadRequiredReviewDocument(
  adAccountId,
  categoryDocumentId,
  categoryDocumentName,
  file,
  onError,
  onFinish
) {
  return (dispatch, getState, api) => {
    if (checkEmpty(file)) return false

    const validationResult = Validation(
      Map({ file }),
      REVIEW_DOCUMENT_FORM_VALIDATION_KEY,
      REVIEW_DOCUMENT_FORM_VALIDATION_KEY_PATH,
      REVIEW_DOCUMENT_FORM_VALIDATOR,
      getState,
      onError
    )

    if (!validationResult) return false

    dispatch(
      setReviewDocumentFileUploadState(ProgressStatusEnum.STATUS.LOADING)
    )

    const fileData = new FormData()
    fileData.append('file', file)

    const cancelTokenSource = createCancellation('uploadRequiredDocument')

    return api.fileUpload
      .uploadRequiredDocument(
        adAccountId,
        fileData,
        categoryDocumentId,
        file.name,
        cancelTokenSource
      )
      .then(response => {
        dispatch(
          receiveUploadedReviewDocuments(
            response.data,
            categoryDocumentId,
            categoryDocumentName
          )
        )
      })
      .catch(
        e =>
          onError &&
          onError(
            null,
            false,
            getReviewDocumentErrorMessage(
              e,
              REVIEW_DOCUMENT_ERROR_TYPE.UPLOAD_FAIL
            )
          )
      )
      .finally(() => {
        deleteCancellation(cancelTokenSource)
        dispatch(
          setReviewDocumentFileUploadState(ProgressStatusEnum.STATUS.IDLE)
        )
        onFinish && onFinish()
      })
  }
}

/***
 * 서류 업로드 입력
 */
export function changeReviewDocumentFormToUpload(key, value) {
  return {
    type: ReviewDocumentCreate.CHANGE_REVIEW_DOCUMENT_FORM_TO_UPLOAD,
    key,
    value,
  }
}

/**
 * 팝업 초기화
 */
export function initReviewDocumentFormToUpload() {
  return {
    type: ReviewDocumentCreate.INIT_REVIEW_DOCUMENT_FORM_TO_UPLOAD,
  }
}

/***
 * Validation Check
 */
export function setIsValidReviewByKey(key, isValid, message) {
  return {
    type: ReviewDocumentCreate.SET_IS_VALID_REVIEW_BY_KEY,
    key,
    isValid,
    message,
  }
}

function setReviewDocumentFileUploadState(uploadState) {
  return {
    type: ReviewDocumentCreate.SET_REVIEW_DOCUMENT_FILE_UPLOAD_STATE,
    uploadState,
  }
}
