import React, { useCallback } from 'react'
import useDropDown from '../../Common/DropDown/utils/Hooks/useDropDown'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { checkNotEmpty } from '../../../utils/regexUtils'

ColorDropDown.propTypes = {
  items: PropTypes.array,
  selectedItem: PropTypes.string,

  onSelectItem: PropTypes.func,
}

function ColorDropDown({ items, selectedItem, onSelectItem, error }) {
  const { ref, isOpen, setIsOpen } = useDropDown()

  const onOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen, setIsOpen])

  return (
    <div
      className={classNames('opt_color', { on: isOpen, in_error: error })}
      ref={ref}>
      <ColorDropDownLabel selectedItem={selectedItem} onClick={onOpen} />
      <div className="layer_color">
        <div className="wrap_color">
          <ul className="list_color">
            {items.map((item, index) => (
              <ColorDropDownItem
                key={`${item}|${index}`}
                id={`${item}|${index}`}
                item={item}
                isSelected={item === selectedItem}
                onClick={() => {
                  setIsOpen(false)
                  onSelectItem(item)
                }}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

const ColorDropDownLabel = ({ selectedItem, onClick }) => {
  return (
    <a className="link_selected" onClick={onClick}>
      {checkNotEmpty(selectedItem) ? (
        <>
          {`색상 : ${selectedItem}`}
          <span className="select_color" style={{ background: selectedItem }} />
        </>
      ) : (
        <>
          색상 : 선택하기 <span className="select_color no_color" />
        </>
      )}
    </a>
  )
}

const ColorDropDownItem = ({ id, item, onClick, isSelected }) => {
  return (
    <li className={classNames({ on: isSelected })} onClick={onClick}>
      <span className="radio_color">
        <input
          type="radio"
          name="inpLayoutSet"
          id={id}
          className="inp_radio"
          checked={isSelected}
          readOnly
        />
        <label
          htmlFor={id}
          className="lab_radio"
          style={{ background: item }}
        />
      </span>
    </li>
  )
}

export default ColorDropDown
