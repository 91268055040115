import { checkNotEmpty, isUndefinedOrNull } from '../utils/regexUtils'
import { scroller } from 'react-scroll'
import { coerceToArray } from '../utils/stringUtils'
import { Map } from 'immutable'
import { GNB_SPEC } from '../components/Navigation/Gnb/Gnb'

/**
 * Validation 하는 함수
 * @param formData {Immutable.Map}
 * @param validationKey {object}
 * @param validationKeyPath {Immutable.Map}
 * @param validators {Immutable.Map}
 * @param getState {function}
 * @param setter {function}
 * @param dispatch {function}
 * @return {boolean}
 */
export const Validation = (
  formData,
  validationKey = {},
  validationKeyPath,
  validators,
  getState,
  setter,
  dispatch = null
) => {
  if (
    [formData, validationKey, validationKeyPath, validators].some(
      v => !checkNotEmpty(v)
    )
  )
    return false

  const validationResult = Object.keys(validationKey).map(key => {
    if (validators.has(key)) {
      const keyPath = validationKeyPath.get(key)
      const hasFormValue = keyPath ? formData.hasIn(keyPath) : false

      // keyPath 에 해당하는 value 가 존재하지 않을 경우 skip
      if (!hasFormValue) return true

      const formValue = formData.getIn(keyPath)
      const validator = validators.get(key)

      // key 에 해당하는 validator 가 정의되지 않았을 경우 skip
      if (!validator) return true

      const { isValid, message, ...rest } = validator(
        formValue,
        formData,
        getState
      )

      if (typeof dispatch === 'function') {
        dispatch(setter(key, isValid, message, rest))
      } else {
        setter(key, isValid, message, rest)
      }

      return isValid
    }

    return true
  })

  return validationResult.every(Boolean)
}

/**
 * 에러 유무랑 메시지를 뽑아내는 함수
 * @param errorKeys {Immutable.Map}
 * @param keys {string[] | string}
 * @return {{error, message: string}}
 */
export const getValidationResult = (errorKeys, keys) => {
  const keyArray = coerceToArray(keys)
  const error =
    errorKeys && Map.isMap(errorKeys)
      ? errorKeys.some((v, k) => keyArray.includes(k))
      : false
  const message = error ? errorKeys.find((v, k) => keyArray.includes(k)) : ''
  return { error, message }
}

/**
 * validation key 기반 scroll (<Element name={key}/> 로 정의된 곳으로 이동한다.)
 */
export const scrollByValidationKeys = (errorKeys, validationKey, getExtra) => {
  if (!errorKeys || !Map.isMap(errorKeys)) return

  const KeySequence = Object.keys(validationKey)

  let scrollKey = errorKeys
    .keySeq()
    .sort((k1, k2) => KeySequence.indexOf(k1) - KeySequence.indexOf(k2))
    .first()

  if (typeof getExtra === 'function') {
    const extra = getExtra(scrollKey)

    if (!isUndefinedOrNull(extra)) {
      scrollKey = createValidationScrollKey({
        validationKey: scrollKey,
        suffix: extra,
      })
    }
  }

  const hasEl = document.getElementsByName(scrollKey).length > 0

  if (hasEl) {
    scroller.scrollTo(scrollKey, {
      offset: -GNB_SPEC.HEIGHT,
    })
  }
}

export const createValidationScrollKey = ({ validationKey, suffix }) =>
  [validationKey, suffix].filter(v => !isUndefinedOrNull(v)).join('|')

export const IS_VALID = extra => ({ isValid: true, message: '', ...extra })
export const IS_NOT_VALID = (message, extra) => ({
  isValid: false,
  message,
  ...extra,
})
