import {
  CMP_ASSET_TYPE,
  CMP_FILTER_DIMENSION,
  CMP_FILTER_GROUP_OPERATOR,
  CMP_FILTER_OPERATOR,
  CMP_FILTER_PROPERTY_PATH,
  CMP_SUB_ASSET_TYPE,
} from './cmpUtils'

const CmpFilterGroupCreator = {
  fileName({ fileName: _fileName = '' }) {
    const fileName = _fileName.trim()

    if (fileName.length === 0) return undefined

    return {
      operator: CMP_FILTER_GROUP_OPERATOR.OR,
      filters: [
        {
          operator: CMP_FILTER_OPERATOR.LIKE,
          dimension: CMP_FILTER_DIMENSION.FILE_NAME,
          value: fileName,
        },
        {
          operator: CMP_FILTER_OPERATOR.LIKE,
          dimension: CMP_FILTER_DIMENSION.FILE_NAME,
          value: String(fileName).normalize('NFC'),
        },
        {
          operator: CMP_FILTER_OPERATOR.LIKE,
          dimension: CMP_FILTER_DIMENSION.FILE_NAME,
          value: String(fileName).normalize('NFD'),
        },
        {
          operator: CMP_FILTER_OPERATOR.LIKE,
          dimension: CMP_FILTER_DIMENSION.FILE_NAME,
          value: String(fileName).normalize('NFKC'),
        },
        {
          operator: CMP_FILTER_OPERATOR.LIKE,
          dimension: CMP_FILTER_DIMENSION.FILE_NAME,
          value: String(fileName).normalize('NFKD'),
        },
      ],
    }
  },
  mediaRatio({ mediaRatioIn = [], mediaRatioNotIn = [] }) {
    const imageRatiosInValue = mediaRatioIn.filter(ratio => ratio > 0)
    const imageRatiosNotInValue = mediaRatioNotIn.filter(ratio => ratio > 0)

    const filters = []

    if (imageRatiosInValue.length > 0) {
      filters.push({
        operator: CMP_FILTER_OPERATOR.IN,
        dimension: CMP_FILTER_DIMENSION.RATIO,
        values: imageRatiosInValue,
      })
    }

    if (imageRatiosNotInValue.length > 0) {
      filters.push({
        operator: CMP_FILTER_OPERATOR.NOT_IN,
        dimension: CMP_FILTER_DIMENSION.RATIO,
        values: imageRatiosNotInValue,
      })
    }

    return filters.length > 0
      ? {
          operator: CMP_FILTER_GROUP_OPERATOR.OR,
          filters,
        }
      : undefined
  },
  mediaDimension({
    filterGroupOperator = CMP_FILTER_GROUP_OPERATOR.OR,
    mediaDimension = [],
  }) {
    const filterGroups = mediaDimension
      .map(({ width, height, ratio }) => {
        const { min: minWidth, max: maxWidth, eq: eqWidth } = width || {}
        const { min: minHeight, max: maxHeight, eq: eqHeight } = height || {}
        const { min: minRatio, max: maxRatio, eq: eqRatio } = ratio || {}

        const filters = []

        if (minWidth >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.GTE,
            dimension: CMP_FILTER_DIMENSION.WIDTH,
            value: minWidth,
          })
        }

        if (minHeight >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.GTE,
            dimension: CMP_FILTER_DIMENSION.HEIGHT,
            value: minHeight,
          })
        }

        if (minRatio >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.GTE,
            dimension: CMP_FILTER_DIMENSION.RATIO,
            value: minRatio,
          })
        }

        if (maxWidth >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.LTE,
            dimension: CMP_FILTER_DIMENSION.WIDTH,
            value: maxWidth,
          })
        }

        if (maxHeight >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.LTE,
            dimension: CMP_FILTER_DIMENSION.HEIGHT,
            value: maxHeight,
          })
        }

        if (maxRatio >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.LTE,
            dimension: CMP_FILTER_DIMENSION.RATIO,
            value: maxRatio,
          })
        }

        if (eqWidth >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.IN,
            dimension: CMP_FILTER_DIMENSION.WIDTH,
            values: [eqWidth],
          })
        }

        if (eqHeight >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.IN,
            dimension: CMP_FILTER_DIMENSION.HEIGHT,
            values: [eqHeight],
          })
        }

        if (eqRatio >= 0) {
          filters.push({
            operator: CMP_FILTER_OPERATOR.IN,
            dimension: CMP_FILTER_DIMENSION.RATIO,
            values: [eqRatio],
          })
        }

        return filters.length > 0
          ? {
              operator: CMP_FILTER_GROUP_OPERATOR.AND,
              filters,
            }
          : undefined
      })
      .filter(Boolean)

    return filterGroups.length > 0
      ? {
          operator: filterGroupOperator,
          filterGroups,
        }
      : undefined
  },
  videoDuration({ durationDimension }) {
    const { min, max, eq } = durationDimension
    const filters = []
    if (min >= 0) {
      filters.push({
        operator: CMP_FILTER_OPERATOR.GTE,
        dimension: CMP_FILTER_DIMENSION.DURATION,
        value: min,
      })
    }

    if (max > 0) {
      filters.push({
        operator: CMP_FILTER_OPERATOR.LTE,
        dimension: CMP_FILTER_DIMENSION.DURATION,
        value: max,
      })
    }

    if (eq > 0) {
      filters.push({
        operator: CMP_FILTER_OPERATOR.IN,
        dimension: CMP_FILTER_DIMENSION.DURATION,
        values: [eq],
      })
    }

    return {
      operator: CMP_FILTER_GROUP_OPERATOR.AND,
      filters,
    }
  },
  Preset: {
    // 이미지 에디터 런칭 후 제거 예정.
    bizBoardImageNotIn() {
      return {
        operator: CMP_FILTER_GROUP_OPERATOR.OR,
        filters: [
          {
            operator: CMP_FILTER_OPERATOR.NOT_IN,
            dimension: CMP_FILTER_DIMENSION.WIDTH,
            values: [1029],
          },
          {
            operator: CMP_FILTER_OPERATOR.NOT_IN,
            dimension: CMP_FILTER_DIMENSION.HEIGHT,
            values: [222],
          },
        ],
      }
    },
  },
}

const CmpRequestUtils = {
  RequestCreator: {
    /**
     * 소재로 즉시 `저장` 가능한 이미지
     */
    saveImageAssets({
      assetType = '',
      subAssetTypes = [],
      fileName = '',
      mediaRatioIn = [],
      mediaRatioNotIn = [],
      mediaDimension = [],
      isAvailableBizBoardImage = false,
    }) {
      return {
        assetType,
        subAssetTypes,
        filterGroups: [
          {
            operator: CMP_FILTER_GROUP_OPERATOR.AND,
            filterGroups: [
              CmpFilterGroupCreator.fileName({ fileName }),
              CmpFilterGroupCreator.mediaRatio({
                mediaRatioIn,
                mediaRatioNotIn,
              }),
              CmpFilterGroupCreator.mediaDimension({ mediaDimension }),
              !isAvailableBizBoardImage &&
                CmpFilterGroupCreator.Preset.bizBoardImageNotIn(),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      }
    },
    /**
     * 소재 라이브러리에 `업로드` 가능한 이미지(편집을 통해 `저장` 가능한 스펙으로 변경)
     */
    uploadImageAssets({
      assetType = '',
      subAssetTypes = [],
      fileName = '',
      mediaRatioIn = [],
      mediaRatioNotIn = [],
      mediaDimension = [],
      isAvailableBizBoardImage = false,
    }) {
      return {
        assetType,
        subAssetTypes,
        filterGroups: [
          {
            operator: CMP_FILTER_GROUP_OPERATOR.AND,
            filterGroups: [
              CmpFilterGroupCreator.fileName({ fileName }),
              CmpFilterGroupCreator.mediaRatio({
                mediaRatioIn,
                mediaRatioNotIn,
              }),
              CmpFilterGroupCreator.mediaDimension({ mediaDimension }),
              !isAvailableBizBoardImage &&
                CmpFilterGroupCreator.Preset.bizBoardImageNotIn(),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      }
    },
    /**
     * 소재로 즉시 `저장` 가능한 동영상
     */
    saveVideoAssets({
      assetType = CMP_ASSET_TYPE.VIDEO,
      subAssetTypes = [CMP_SUB_ASSET_TYPE.MAIN],
      fileName = '',
      mediaDimension = [],
      durationDimension,
    }) {
      return {
        assetType,
        subAssetTypes,
        filterGroups: [
          {
            operator: CMP_FILTER_GROUP_OPERATOR.AND,
            filterGroups: [
              CmpFilterGroupCreator.fileName({ fileName }),
              CmpFilterGroupCreator.mediaDimension({ mediaDimension }),
              CmpFilterGroupCreator.videoDuration({ durationDimension }),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      }
    },
    /**
     * 메시지 소재 `홍보문구`
     */
    titleAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.TITLE,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.MAIN, CMP_SUB_ASSET_TYPE.SUB],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.TEXT,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    /**
     * 디스플레이 소재 `타이틀`, 쇼핑박스 `상단 홍보문구`
     */
    mainTitleAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.TITLE,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.MAIN],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.TEXT,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    profileNameAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.DATA,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.PROFILE],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.VALUE,
                      propertyPath: CMP_FILTER_PROPERTY_PATH.PROFILE_NAME,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    altTextAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.DATA,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.ALT_TEXT],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.VALUE,
                      propertyPath: CMP_FILTER_PROPERTY_PATH.ALT_TEXT,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    landingUrlAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.LINK,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.PC, CMP_SUB_ASSET_TYPE.MOBILE],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.URL,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    landingUrlPcAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.LINK,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.PC],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.URL,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },
    landingUrlMobileAssets({ value: _value = '' }) {
      const value = _value.trim()

      return {
        assetType: CMP_ASSET_TYPE.LINK,
        subAssetTypes: [CMP_SUB_ASSET_TYPE.MOBILE],
        filterGroups:
          value.length > 0
            ? [
                {
                  operator: CMP_FILTER_GROUP_OPERATOR.AND,
                  filters: [
                    {
                      operator: CMP_FILTER_OPERATOR.LIKE,
                      dimension: CMP_FILTER_DIMENSION.URL,
                      value,
                    },
                  ],
                },
              ]
            : [],
      }
    },

    // -- EDITOR --

    editorItems({
      assetType = CMP_ASSET_TYPE.IMAGE,
      subAssetTypes = [CMP_SUB_ASSET_TYPE.UTILITY],
      fileName = '',
      mediaRatioIn = [],
      mediaRatioNotIn = [],
      mediaDimension = [],
    } = {}) {
      return {
        assetType,
        subAssetTypes,
        filterGroups: [
          {
            operator: CMP_FILTER_GROUP_OPERATOR.AND,
            filterGroups: [
              CmpFilterGroupCreator.fileName({ fileName }),
              CmpFilterGroupCreator.mediaRatio({
                mediaRatioIn,
                mediaRatioNotIn,
              }),
              CmpFilterGroupCreator.mediaDimension({
                mediaDimension,
              }),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      }
    },
    editorRawAssets({
      assetType = CMP_ASSET_TYPE.IMAGE,
      subAssetTypes = [CMP_SUB_ASSET_TYPE.RAW, CMP_SUB_ASSET_TYPE.LOGO],
      fileName = '',
      mediaRatioIn = [],
      mediaRatioNotIn = [],
      mediaDimension = [],
    } = {}) {
      return {
        assetType,
        subAssetTypes,
        filterGroups: [
          {
            operator: CMP_FILTER_GROUP_OPERATOR.AND,
            filterGroups: [
              CmpFilterGroupCreator.fileName({ fileName }),
              CmpFilterGroupCreator.mediaRatio({
                mediaRatioIn,
                mediaRatioNotIn,
              }),
              CmpFilterGroupCreator.mediaDimension({
                filterGroupOperator: CMP_FILTER_GROUP_OPERATOR.AND,
                mediaDimension,
              }),
            ].filter(Boolean),
          },
        ].filter(Boolean),
      }
    },
    editorUploadRawAssets({ adAccountId, imageFiles = [] }) {
      return {
        adAccountId,
        service: 'MOMENT',
        imageFiles,
      }
    },
    editorItemCreateOrModify({
      adAccountId,
      id,
      resultImage,
      logoImage,
      backgroundImage: backgroundImageList,
      metaData = '{}',
    }) {
      const logo = logoImage
        ? {
            ...logoImage,
            assetType: CMP_ASSET_TYPE.IMAGE,
            imageAssetType: CMP_SUB_ASSET_TYPE.LOGO,
            ext: {
              backgroundColor: '',
            },
          }
        : undefined

      const background = backgroundImageList
        ? backgroundImageList.map(backgroundImage => ({
            ...backgroundImage,
            assetType: CMP_ASSET_TYPE.IMAGE,
            imageAssetType: CMP_SUB_ASSET_TYPE.RAW,
            ext: {
              backgroundColor: '',
            },
          }))
        : undefined

      return {
        id,
        resultImage: {
          ...resultImage,
          assetType: CMP_ASSET_TYPE.IMAGE,
          imageAssetType: CMP_SUB_ASSET_TYPE.UTILITY,
          ext: {
            backgroundColor: '',
          },
        },
        sourceImages: [logo, ...background].filter(Boolean),
        metaData:
          typeof metaData === 'object' ? JSON.stringify(metaData) : metaData,
        adAccountId,
        service: { name: 'MOMENT' },
      }
    },
  },

  ResponseTransformer: {
    /**
     * CMP_ASSET_TYPE.DATA
     */
    dataAssets({ value: valueObj, ...rest }) {
      return {
        ...rest,
        value: Object.values(valueObj)?.[0] || '',
      }
    },
    profileNameAssets(response) {
      return CmpRequestUtils.ResponseTransformer.dataAssets(response)
    },
    altTextAssets(response) {
      return CmpRequestUtils.ResponseTransformer.dataAssets(response)
    },
    titleAssets({ text, ...rest }) {
      return {
        ...rest,
        value: text,
      }
    },
    landingUrlAssets({ url, ...rest }) {
      return {
        ...rest,
        value: url,
      }
    },
  },
}

export { CmpRequestUtils }
