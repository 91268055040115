import {
  convertToCommaSeparatedString,
  DATE_PRESET_KEY,
  dateTimeToString,
  keyMirror,
  selectedDatePickerValue,
} from '../utils'
import { checkEmpty, checkNoneEmpty, checkNotEmpty } from '../regexUtils'
import { getWithdrawAccountValue } from '../../modules/settings/cashCommons/aCashCommons'
import { PaymentCardUtils } from '../paymentCard/paymentCardUtils'

export const invoiceDefaultDate = selectedDatePickerValue(
  DATE_PRESET_KEY.RECENT_SIX_MONTH
) // 최근 6개월치 조회 기준 가장 최근 데이터 사용

export const ACCOUNT_INFO_LOADING_STATE = keyMirror({
  IDLE: null,
  LOADING: null,
  FAILED: null,
})

export const ACCOUNT_INFO_LOADING_KEY = {
  AD_ACCOUNTS: 'adAccountsLoading',
  MEMBER: 'memberLoading',
  BIZ_RIGHT: 'bizRightLoading',
  CASH: 'cashLoading',
  KAKAOPAY_CARD: 'kakaopayCardLoading',
  INVOICE: 'invoiceLoading',
  AGENCY: 'agencyLoading',
}

export const getPaymentCardInfo = (cardList, ownerEmail) => {
  if (cardList?.size > 0) {
    const paymentCard = cardList.find(v => v.get('priorityOrder') === 0)
    const email = paymentCard?.get('kakaoEmail') || ''
    const ownerLabel = email === ownerEmail ? '(나)' : ''
    const cardName = paymentCard?.get('cardCorpDisplayName').substr(0, 2) || ''
    const cardNumber = PaymentCardUtils.maskingCardNumber({
      numberString: paymentCard?.get('cardBin'),
    })

    return {
      cardName: `${cardName} ${cardNumber}`,
      owner: `${email} ${ownerLabel}`,
      creditLimit: convertToCommaSeparatedString(
        paymentCard?.get('dailySpendingBudget') || 0
      ),
      expectedPayment: paymentCard?.get('expectedPayment') || 0,
    }
  }

  return null
}

export const getCardSectionPaymentCardLabel = v => {
  if (checkEmpty(v)) return '미등록'
  return `${convertToCommaSeparatedString(v.expectedPayment)}원`
}

export const getCardSectionBankLabel = v => {
  if (checkEmpty(v)) return '미등록'
  const { accountNumber, bankName } = getWithdrawAccountValue(v)
  return checkNotEmpty(accountNumber)
    ? `${accountNumber} ${bankName}`
    : '미등록'
}

export const checkExistAdvertiserInfoInAdAccount = v => {
  return (
    checkNotEmpty(v) &&
    checkNoneEmpty(v.get('ownerCompany'), v.get('advertiser'))
  )
}
