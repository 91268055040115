import { keyMirror } from '../utils/utils'

/**
 * 업로드 전용 assetPropertyType.
 * `CreativeAssetPropertyEnum` 과 별개
 */
export default {
  Type: keyMirror({
    PROFILE_IMAGE: null, // 프로필 이미지
    IMAGE: null, // 홍보 이미지
    OBJET_IMAGE: null, // 이미지 배너 - 오브젝트형
    THUMBNAIL_IMAGE: null, // 이미지 배너 - 썸네일형
    THUMBNAIL_IMAGE_BLUR: null, // 이미지 배너 - 썸네일 블러형
    THUMBNAIL_IMAGE_MULTI: null, // 이미지 배너 - 썸네일 멀티형
    MASKING_IMAGE: null, // 이미지배너 - 마스킹형
    LOGO_IMAGE: null, // 로고
    ICON_IMAGE: null, // 앱 아이콘
    VIDEO: null, // 동영상
    VIDEO_IMAGE: null, // 동영상 맞춤썸네일
    AUTO_THUMBNAIL: null,
    UPLOAD_THUMBNAIL: null,
    EXPANDABLE_VIDEO_IMAGE: null, // 비즈보드 익스펜더블 맞춤 썸네일
    EXPANDABLE_IMAGE: null, // 비즈보드 익스펜더블 이미지형 이미지
    EXPANDABLE_MULTI: null, // 비즈보드 익스펜더블 멀티형 이미지
    MOTION_BOARD_VIDEO: null, // 모션 비즈보드 동영상
    MOTION_BOARD_VIDEO_IMAGE: null, // 모션 비즈보드 업로드 썸네일
    BACKGROUND_IMAGE: null, // 모션 비즈보드 배경 이미지
    TEXT_IMAGE: null, // 모션 비즈보드 텍스트 이미지
    OBJECT_IMAGE: null, // 모션 비즈보드 오브젝트 이미지
    BANNER_IMAGE: null, // 리치팝 홍보이미지 1
    COMPATIBLE_IMAGE: null,
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}
