import React from 'react'
import cx from 'classnames'
import { checkNotEmpty } from '../../../../../utils/regexUtils'
import { ScaleImageFitCenter } from '../../../../../utils/imageUtils'
import { useAnimateCSS } from '../../../../../utils/hook/useAnimateCSS'
import { openPopup, POPUP_KEY } from '../../../../../modules/common/mPopup'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import { useDispatch } from 'react-redux'
import { BIZ_BOARD_FORM_VALIDATION_KEY } from '../bizBoardUtils'
import { initBizBoardCreateImageData } from '../../../../../modules/advertise/mBizBoardImageEditor'
import PropTypes from 'prop-types'
import {
  extractMimeSubType,
  formatBytes,
} from '../../../../../utils/stringUtils'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import { CmpAssetLibraryUtils } from '../../Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'

const BizBoardBannerLogo = ({
  adAccountId,
  index,
  formData,
  validationState,
  handleFormChange,
  handleTemplateChange,
}) => {
  const dispatch = useDispatch()

  const [isVisibleAnim, setIsVisibleAnim] = React.useState(false)

  const { ref, onLoad, isAnimStarted } = useAnimateCSS()

  const { images = [] } = formData

  const logoImage = images[index]

  const { url, imageWidth, imageHeight, originalFileName, fileSize, mimeType } =
    logoImage || {}

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    imageWidth,
    imageHeight,
    70,
    70
  )
  const loadOk = checkNotEmpty(url)

  const getNewImages = React.useCallback(
    image => {
      const newImages = [...images]
      newImages[index] = image
      return newImages
    },
    [images, index]
  )

  const handleLogoImageChange = React.useCallback(
    image => {
      handleFormChange('images', getNewImages(image))
      dispatch(initBizBoardCreateImageData(index))
    },
    [dispatch, getNewImages, handleFormChange, index]
  )

  const handleLogoImageDelete = React.useCallback(() => {
    handleFormChange('images', getNewImages(undefined))
    dispatch(initBizBoardCreateImageData(index))
  }, [dispatch, getNewImages, handleFormChange, index])

  const openCreativeImportForLogoImage = React.useCallback(
    e => {
      e.preventDefault()

      const fileSizeMax = 50 * 1024

      dispatch(
        openPopup(POPUP_KEY.CREATIVE_LIBRARY, {
          adAccountId,
          campaignType: CampaignTypeEnum.Type.TALK_BIZ_BOARD,
          creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
          creativeUploadAssetPropertyType: null,
          multiSelect: false,
          onSave: ({ items, onSuccess, onFailure }) => {
            const item = items.first()
            const { fileSize } = item
            const invalidFileSize = fileSize > fileSizeMax

            if (invalidFileSize) {
              onFailure(
                `${formatBytes({
                  bytes: fileSizeMax,
                })} 이하의 이미지만 등록 가능합니다.`
              )
            } else {
              setIsVisibleAnim(true)
              handleLogoImageChange(item.toJS())
              handleTemplateChange('imageIndex', index)
              onSuccess()
            }
          },
          onlyPNG24: true, // 비즈보드 로그의 경우 png24 고정
          guideTooltipContent: CmpAssetLibraryUtils.Upload.guideTooltip({
            creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
            creativeUploadAssetPropertyType:
              CreativeUploadAssetPropertyEnum.Type.LOGO_IMAGE,
          }),
        })
      )
    },
    [adAccountId, dispatch, handleLogoImageChange, handleTemplateChange, index]
  )

  const handleImageSelect = React.useCallback(() => {
    handleTemplateChange('imageIndex', index)
  }, [handleTemplateChange, index])

  const errorMessage =
    validationState[BIZ_BOARD_FORM_VALIDATION_KEY.LOGO]?.message

  return (
    <div className="bnrimg_area">
      <strong className="tit_bnrimg">
        로고
        <span className="emph_color">*</span>
      </strong>
      <div className="bnrimg_con">
        {checkNotEmpty(url) ? (
          <div className={cx('reform_regist_img', { load_ok: loadOk })}>
            <div className="reform_item_file">
              <span className="inner_info">
                <span className="reform_img_file">
                  <span className="inner_img_file" onClick={handleImageSelect}>
                    <img
                      width={scaledWidth}
                      height={scaledHeight}
                      alt="소재이미지"
                      className="img_thumb"
                      src={url}
                      onLoad={onLoad}
                    />
                    <span className="frame_g" />
                    {isVisibleAnim && (
                      <span
                        ref={ref}
                        className={cx({
                          load_img: loadOk,
                          'animated fadeOut': isAnimStarted,
                        })}>
                        <span className="ico_comm ico_loading">로딩완료</span>
                      </span>
                    )}
                  </span>
                </span>
                <span className="reform_txt_file">
                  <span className="txt_upload">
                    {String(originalFileName).normalize('NFC')}
                  </span>
                  <span className="txt_byte">
                    {imageWidth}x{imageHeight}
                    <span className="txt_bar">|</span>
                    {extractMimeSubType({ mimeType, fallback: '-' })}
                    <span className="txt_bar">|</span>
                    {formatBytes({ bytes: fileSize, fallback: '-' })}
                  </span>
                </span>
                <button
                  type="button"
                  className="btn_del"
                  onClick={handleLogoImageDelete}>
                  <span className="ico_comm ico_del">삭제</span>
                </button>
              </span>
            </div>
          </div>
        ) : (
          <a className="btn_gw" onClick={openCreativeImportForLogoImage}>
            <span className="inner_g">
              <span className="img_comm ico_add" />
              이미지 추가
            </span>
          </a>
        )}
        {errorMessage && <p className="txt_error">{errorMessage}</p>}
      </div>
    </div>
  )
}

BizBoardBannerLogo.propTypes = {
  adAccountId: PropTypes.any,
  index: PropTypes.number,
  formData: PropTypes.object,
  validationState: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerLogo
