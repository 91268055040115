import { batch } from 'react-redux'
import {
  clearReportTable,
  initReportTableSort,
} from '../modules/reportV2/mReportTable'
import {
  clearReportDetail,
  initFromDashboard,
  resetReportDetail,
  setReportDetail,
} from '../modules/reportV2/mReportDetail'
import {
  clearReportCommon,
  initReportCommon,
} from '../modules/reportV2/mReportCommon'
import {
  clearReportFilter,
  initReportFilterConditions,
} from '../modules/reportV2/mReportFilter'
import {
  clearReportCalendar,
  initializeReportCalendar,
} from '../modules/reportV2/mReportCalendar'
import { convertResponseToState } from '../components/ReportV2/Common/reportResponseConverter'

export function batchInitReportDetail(dto) {
  return dispatch => {
    const { common, sort, calendar, filterConditions } =
      convertResponseToState(dto)

    batch(() => {
      dispatch(setReportDetail(dto.name))
      dispatch(initReportCommon(common))
      dispatch(initReportTableSort(sort))
      dispatch(initializeReportCalendar(calendar))
      dispatch(initReportFilterConditions(filterConditions))
    })
  }
}

export function batchInitReportDetailFromDashboard({
  dashboardCalendar,
  dashboardCommon,
  dashboardFilter,
  dashboardSort,
}) {
  return dispatch => {
    batch(() => {
      dispatch(
        initFromDashboard({
          dashboardCalendar,
          dashboardCommon,
          dashboardFilter,
          dashboardSort,
        })
      )

      dispatch(initReportCommon(dashboardCommon))
      dispatch(initReportTableSort(dashboardSort))
      dispatch(initializeReportCalendar(dashboardCalendar))
      dispatch(initReportFilterConditions(dashboardFilter))
    })
  }
}

export function batchClearReportDetail() {
  return dispatch => {
    dispatch(clearReportTable())
    dispatch(clearReportDetail())
    dispatch(clearReportFilter())
    dispatch(clearReportCommon())
    dispatch(clearReportCalendar())
  }
}

export function batchResetReportDetail() {
  return dispatch => {
    dispatch(clearReportTable())
    dispatch(resetReportDetail())
    dispatch(clearReportFilter())
    dispatch(clearReportCommon())
    dispatch(clearReportCalendar())
  }
}
