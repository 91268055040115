import React from 'react'
import ApngComponent from 'react-apng'
import PropTypes from 'prop-types'
import {
  EXPANDABLE_HINT_APNG_SRC,
  EXPANDABLE_HINT_PREVIEW_TYPE,
  EXPANDABLE_HINT_TYPE,
  ExpandableHintUtils,
} from './expandableHintUtils'

const DEFAULT_APNG_STYLE = {
  position: 'absolute',
  right: 0,
  bottom: -5,
  zIndex: 20,
  paddingRight: 0,
  paddingBottom: 0,
}

const ExpandableHint = ({
  id,
  hintType,
  previewType,
  zIndex = DEFAULT_APNG_STYLE.zIndex,
} = {}) => {
  const apngSrc = EXPANDABLE_HINT_APNG_SRC[hintType]?.[previewType]
  const apngStyleByPreviewType = ExpandableHintUtils.styles({ previewType })

  if (!apngSrc) return null

  return (
    <span>
      <ExpandableHintApng
        id={id}
        src={apngSrc}
        style={{
          ...DEFAULT_APNG_STYLE,
          ...apngStyleByPreviewType,
          zIndex,
        }}
      />
    </span>
  )
}

ExpandableHint.propTypes = {
  id: PropTypes.any,
  hintType: PropTypes.oneOf(Object.keys(EXPANDABLE_HINT_TYPE)).isRequired,
  previewType: PropTypes.oneOf(Object.keys(EXPANDABLE_HINT_PREVIEW_TYPE))
    .isRequired,
  zIndex: PropTypes.number,
}

const ExpandableHintApng = ({ src, style = DEFAULT_APNG_STYLE }) => {
  const ref = React.useRef()
  const playIntervalRef = React.useRef(-1)

  React.useLayoutEffect(() => {
    /**
     * mount 1초 후 자동 재생(동작할 때 까지 반복 시도)
     */

    playIntervalRef.current = setInterval(() => {
      if (!ref.current?.isPlay) {
        ref.current?.one()
      } else {
        clearInterval(playIntervalRef.current)
      }
    }, 1000)

    const apng = ref.current

    return () => {
      apng?.stop()

      clearInterval(playIntervalRef.current)
    }
  }, [])

  const onMouseOver = React.useCallback(() => {
    const play = () => {
      if (!ref.current?.isPlay) {
        ref.current?.one()
      }
    }

    clearInterval(playIntervalRef.current)

    // 반복 재생 트리거(leave 시 중단)
    playIntervalRef.current = setInterval(() => {
      play()
    }, 1000)

    // 1회 즉시 재생 트리거
    play()
  }, [])

  const onMouseLeave = React.useCallback(() => {
    // 중단 트리거(스퀀스 끝까지 마친 후 종료)
    clearInterval(playIntervalRef.current)
  }, [])

  return (
    <div style={style}>
      <ApngComponent
        ref={ref}
        rate={0.9}
        src={src}
        onMouseOver={onMouseOver}
        onMouseLeave={onMouseLeave}
      />
    </div>
  )
}

ExpandableHintApng.propTypes = {
  id: PropTypes.any,
  src: PropTypes.string.isRequired,
  style: PropTypes.object,
}

export default ExpandableHint
