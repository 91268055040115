import { Map } from 'immutable'
import {
  checkEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
  hasNewLine,
  hasWhitespace,
} from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  personalMessageFormButtonAssetGroupValidation,
  personalMessageFormDescriptionValidation,
  personalMessageFormItemValidation,
  personalMessageFormLandingValidation,
  personalMessageFormPcLandingValidation,
  personalMessageFormTitleValidation,
} from './personalMessageFormValidator'
import { PERSONAL_MESSAGE_FORM_VALIDATION_KEY } from './personalMessageFormValidationKey'
import { PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE } from './personalMessageFormValidationMessage'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from '../advertise/creativeV2/creativeFormValidationMessage'

const personalMessageValidateDiscountedPrice = ({ price, discountedPrice }) => {
  const { amount } = price || {}
  const { amount: discountedAmount } = discountedPrice || {}

  if (checkNotEmpty(amount) && checkNotEmpty(discountedAmount)) {
    const percentage = Math.floor(((amount - discountedAmount) / amount) * 100)
    if (percentage < 1) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DISCOUNT_PRICE)
    } else if (hasWhitespace(discountedAmount) || hasNewLine(discountedPrice)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE
      )
    } else if (hasFullWidthWhitespace(discountedAmount)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    } else {
      return IS_VALID()
    }
  } else {
    return IS_VALID()
  }
}

const personalMessageValidatePrice = price => {
  const { amount, currencyCode } = price || {}
  if (checkEmpty(amount) || checkEmpty(currencyCode)) {
    return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  } else if (hasWhitespace(amount) || hasNewLine(amount)) {
    return IS_NOT_VALID(
      PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE
    )
  } else if (hasFullWidthWhitespace(amount)) {
    return IS_NOT_VALID(
      PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  } else {
    return IS_VALID()
  }
}

export const PERSONAL_MESSAGE_CAROUSEL_COMMERCE_FORM_VALIDATOR = Map({
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.TITLE]: ({
    title,
    hasIntro,
    creativeFormat,
  }) => {
    if (!hasIntro) return IS_VALID()
    return personalMessageFormTitleValidation(title, creativeFormat)
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.DESCRIPTION]: ({
    description,
    hasIntro,
    creativeFormat,
  }) => {
    if (!hasIntro) return IS_VALID()

    return personalMessageFormDescriptionValidation(description, creativeFormat)
  },

  // Intro
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM]: messageForm => {
    const { hasIntro } = messageForm
    if (!hasIntro) return IS_VALID()

    return personalMessageFormItemValidation(messageForm, true)
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_INTRO_LANDING]: ({
    hasIntro,
    introLandingType,
    introMobileLandingUrl,
    introChannelCouponId,
    introChannelPostId,
    introTalkStoreLandingUrl,
    introWebembedChannelUrl,
    introPcLandingUrl,
  }) => {
    if (!hasIntro) return IS_VALID()

    const introLandingValidationResult = personalMessageFormLandingValidation({
      landingInfo: {
        landingType: introLandingType,
        mobileLandingUrl: introMobileLandingUrl,
        channelCouponId: introChannelCouponId,
        channelPostId: introChannelPostId,
        talkStoreLandingUrl: introTalkStoreLandingUrl,
        webembedChannelUrl: introWebembedChannelUrl,
      },
      isRequired: false,
    })

    const introPCLandingValidationResult =
      personalMessageFormPcLandingValidation(introPcLandingUrl)

    const isValid = [
      introLandingValidationResult,
      introPCLandingValidationResult,
    ].every(v => v.isValid)

    return isValid
      ? IS_VALID()
      : IS_NOT_VALID('', {
          introLanding: introLandingValidationResult,
          introPcLanding: introPCLandingValidationResult,
        })
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_MORE]: messageForm => {
    const { pcLandingUrl } = messageForm

    const itemAssetLandingValidationResult =
      personalMessageFormLandingValidation({
        landingInfo: messageForm,
        isRequired: false,
      })

    const itemAssetPcLandingValidationResult =
      personalMessageFormPcLandingValidation(pcLandingUrl)

    const isValid = [
      itemAssetLandingValidationResult,
      itemAssetPcLandingValidationResult,
    ].every(v => v.isValid)

    return isValid
      ? IS_VALID()
      : IS_NOT_VALID('', {
          landing: itemAssetLandingValidationResult,
          pcLanding: itemAssetPcLandingValidationResult,
        })
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: ({
    itemAssetGroups,
    buttonAssetGroups,
    creativeFormat,
    hasIntro,
  }) => {
    const minSlide = hasIntro ? 1 : 2
    if (!itemAssetGroups || itemAssetGroups.count() < minSlide) {
      return IS_NOT_VALID('입력한 말풍선이 2개 이상이어야 합니다.')
    }

    const itemAssetGroupsValidationResults = []
    itemAssetGroups.forEach((itemAsset, index) => {
      const { assetGroupUUID, title, price, discountedPrice, pcLandingUrl } =
        itemAsset

      const itemAssetTitleValidationResult = personalMessageFormTitleValidation(
        title,
        creativeFormat
      )
      const itemAssetLandingValidationResult =
        personalMessageFormLandingValidation({
          landingInfo: itemAsset,
          isRequired: true,
        })
      const itemAssetValidationResult = personalMessageFormItemValidation(
        itemAsset,
        true
      )
      const itemAssetPriceValidationResult = personalMessageValidatePrice(price)
      const itemAssetDiscountedPriceValidationResult =
        personalMessageValidateDiscountedPrice({
          price,
          discountedPrice,
        })

      const itemAssetPCLandingValidatorResult =
        personalMessageFormPcLandingValidation(pcLandingUrl)

      const buttonAssetValidatorResult =
        personalMessageFormButtonAssetGroupValidation(
          buttonAssetGroups.filter(
            v =>
              v.get('ordering') === index * 2 ||
              v.get('ordering') === index * 2 + 1
          )
        )

      const isValid = [
        itemAssetTitleValidationResult,
        itemAssetLandingValidationResult,
        itemAssetValidationResult,
        itemAssetPriceValidationResult,
        itemAssetDiscountedPriceValidationResult,
        buttonAssetValidatorResult,
      ].every(v => v.isValid)

      itemAssetGroupsValidationResults.push({
        assetGroupUUID,
        isValid,
        title: itemAssetTitleValidationResult,
        landing: itemAssetLandingValidationResult,
        pcLanding: itemAssetPCLandingValidatorResult,
        asset: itemAssetValidationResult,
        price: itemAssetPriceValidationResult,
        discountedPrice: itemAssetDiscountedPriceValidationResult,
        buttons:
          buttonAssetValidatorResult['buttonAssetGroupsValidationResults'],
      })
    })

    const isAllValid = itemAssetGroupsValidationResults.every(v => {
      return v.isValid
    })

    return isAllValid
      ? IS_VALID()
      : IS_NOT_VALID('', { itemAssetGroupsValidationResults })
  },
})
