import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { checkNoneEmpty, checkNotEmpty } from '../../../utils/regexUtils'
import SubTitle from './Layout/SubTitle'
import useDropDown from './utils/Hooks/useDropDown'
import SelectBox from '../SelectBox/SelectBox'
import { MomentLoader } from '../Loader/MomentLoader'
import { keyMirror } from '../../../utils/utils'
import { Element } from 'react-scroll'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import DefaultNoItem from './Item/ListItem/DefaultNoItem'
import ListLayout from './Layout/ListLayout'

export const DropDownType = keyMirror({
  NORMAL: null,
  CONNECTED: null,
})

DropDown.propTypes = {
  dropdownType: PropTypes.oneOf([DropDownType.NORMAL, DropDownType.CONNECTED])
    .isRequired,
  className: PropTypes.string,

  defaultTitle: PropTypes.string,
  selectedImage: PropTypes.string,
  selectedTitle: PropTypes.string,
  validationKey: PropTypes.string,

  /**
   * TODO by. benn
   *  items의 초기값은 null 이어야 합니다.
   *  초기값이 null이 아니라면, 최초의 onFetch가 동작하지 않을 수도 있습니다.
   */
  items: PropTypes.oneOfType([PropTypes.array, ImmutablePropTypes.list]),
  ListItemComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func])
    .isRequired,
  selectedItem: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
    PropTypes.number,
  ]),

  GuideComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  PreviewComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  TagComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  NoItemComponent: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.object,
  ]),
  MoreFetchButtonComponent: PropTypes.element,
  SubTitleTooltipComponent: PropTypes.element,

  active: PropTypes.bool,
  isError: PropTypes.bool,

  subTitle: PropTypes.string,
  noItemLabel: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),

  onSelect: PropTypes.func,
  onFetch: PropTypes.func,

  customProp: props => {
    switch (props['dropdownType']) {
      case DropDownType.NORMAL:
        break
      case DropDownType.CONNECTED:
        if (!checkNotEmpty(props['onFetch'])) {
          return new Error(
            '연동형 드롭다운에서는 onFetch Prop이 꼭 있어야 합니다.'
          )
        }
        break
      default:
        break
    }
  },
}

function Loading() {
  return (
    <div className="reform_area_loading">
      <MomentLoader />
    </div>
  )
}

function DropDown({
  className,
  dropdownType,
  selectedImage,
  selectedTitle,
  items,
  ListItemComponent,
  GuideComponent,
  PreviewComponent,
  TagComponent,
  NoItemComponent,
  MoreFetchButtonComponent,
  SubTitleTooltipComponent,
  selectedItem,
  subTitle,
  onSelect,
  onFetch,
  noItemLabel,
  isError,
  validationKey,
  defaultTitle = '',
  active = true,
}) {
  const listRef = useRef()

  const [previewSelectedItem, setPreviewSelectedItem] = useState(null)
  const [guideSelectedItem, setGuideSelectedItem] = useState(null)

  const [isPreviewShow, setIsPreviewShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const { ref, isOpen, setIsOpen } = useDropDown()

  const onOpen = () => {
    if (isOpen) {
      setIsPreviewShow(false)
    }
    setIsOpen(!isOpen)
  }

  const onClose = () => {
    setPreviewSelectedItem(null)
    setGuideSelectedItem(null)
    setIsPreviewShow(false)
    if (listRef && listRef.current?.scrollTop > 0) {
      listRef.current.scrollTop = 0 // 무조건 scrollTop 으로 이동 시키고 있어서 selectedItem 이 있을 경우 문제 안될런지??
    }
  }

  useLayoutEffect(() => {
    return () => {
      if (!isOpen) {
        onClose()
      }
    }
  }, [isOpen, listRef])

  const isLinking = checkNotEmpty(subTitle)
  const isGuide = checkNotEmpty(GuideComponent)
  const isPreview = checkNotEmpty(PreviewComponent)
  const isSelected = checkNotEmpty(selectedTitle)
  const hasNoItem = checkNotEmpty(NoItemComponent)
  const hasMoreFetchRenderer =
    checkNotEmpty(MoreFetchButtonComponent) &&
    React.isValidElement(MoreFetchButtonComponent)
  const hasSubTitleTooltip =
    isLinking &&
    checkNoneEmpty(subTitle, SubTitleTooltipComponent) &&
    React.isValidElement(SubTitleTooltipComponent)

  const itemList = useMemo(() => {
    if (List.isList(items)) {
      return items
    } else if (checkNotEmpty(items)) {
      return List(items)
    } else {
      return List([])
    }
  }, [items])

  const onSelectItem = useCallback(
    item => () => {
      setIsOpen(false)
      onSelect(item)
    },
    [onSelect, setIsOpen]
  )

  const onPreviewSelectItem = useCallback(
    item => e => {
      e.preventDefault()
      e.stopPropagation()

      if (!isPreviewShow) {
        setIsPreviewShow(true)
      }
      setPreviewSelectedItem(item)
    },
    [isPreviewShow]
  )

  const onGuideSelectItem = useCallback(
    item => e => {
      e.preventDefault()
      e.stopPropagation()

      setGuideSelectedItem(item)
    },
    []
  )

  const onPreviewClose = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    setIsPreviewShow(false)
  }, [])

  const handleFetch = useCallback(async () => {
    try {
      setIsLoading(true)
      await onFetch()
    } catch (e) {
      console.log(e.message)
    } finally {
      setIsLoading(false)
    }
  }, [onFetch])

  const handleRefresh = () => {
    setPreviewSelectedItem(null)
    setIsPreviewShow(false)
    setGuideSelectedItem(null)
    handleFetch()
  }

  const handleInitGuide = useCallback(() => {
    if (isGuide && checkNotEmpty(guideSelectedItem)) {
      setGuideSelectedItem(null)
    }
  }, [guideSelectedItem, isGuide])

  useEffect(() => {
    switch (dropdownType) {
      case DropDownType.CONNECTED:
        if (isOpen && items === null) {
          handleFetch()
        }
        break
      default:
        break
    }
  }, [dropdownType, handleFetch, isOpen, items])

  return (
    <div
      className={classNames(
        'opt_select2',
        {
          opt_open: isOpen,
          in_active: !active,
          in_error: isError,
        },
        className
      )}
      ref={ref}>
      {validationKey && <Element name={validationKey} />}
      <div className="screen_out">데이터종류 선택상자</div>
      <span className="screen_out">선택내용 : </span>
      <SelectBox
        title={isSelected ? selectedTitle : defaultTitle}
        imageSrc={selectedImage}
        TagComponent={TagComponent}
        onClick={onOpen}
      />
      <span className="ico_arr" />
      <div className="screen_out">선택옵션</div>
      <div className="layer_opt" onMouseLeave={handleInitGuide}>
        {isLinking && (
          <SubTitle
            subTitle={subTitle}
            totalElements={itemList.size}
            onRefresh={handleRefresh}
            tooltipElements={
              hasSubTitleTooltip ? SubTitleTooltipComponent : undefined
            }
          />
        )}
        {isGuide && <GuideComponent selectedItem={guideSelectedItem} />}
        <ListLayout listRef={listRef}>
          <>
            {isLoading ? (
              <Loading />
            ) : itemList.isEmpty() ? (
              hasNoItem ? (
                <NoItemComponent />
              ) : (
                <DefaultNoItem Label={noItemLabel} />
              )
            ) : (
              itemList.map((item, index) => {
                return (
                  <ListItemComponent
                    key={index}
                    item={item}
                    onSelect={onSelectItem(item)}
                    onSelectPreview={onPreviewSelectItem(item)}
                    onSelectGuide={onGuideSelectItem(item)}
                    selectedItem={selectedItem}
                  />
                )
              })
            )}
          </>
        </ListLayout>
        {hasMoreFetchRenderer && React.cloneElement(MoreFetchButtonComponent)}
        {isPreview && isPreviewShow && (
          <PreviewComponent
            selectedItem={previewSelectedItem}
            onClose={onPreviewClose}
          />
        )}
      </div>
    </div>
  )
}

export default DropDown
