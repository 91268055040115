import CruxFetchUtils from '../../utils/cruxFetchUtils'
import RequestLock from '../../utils/requestLock'
import { queryString } from '../../utils/utils'

/**
 * 연동 관리 > 연동 중인 픽셀 & SDK 리스트 (내 광고계정이 사용할 수 있는 트랙)
 * @param adAccountId
 * @param params
 * @param cancelTokenSource
 */
function fetchLinkedTrackList(adAccountId, params, cancelTokenSource) {
  const url = `/bcon/v2/services/moment/track-rights/adaccount/${adAccountId}/linked?${queryString(
    params
  )}`

  return CruxFetchUtils.post({
    url,
    requestPayload: {},
    cancelTokenSource,
    headers: { adAccountId },
  })
}

/**
 * 연동 중인 픽셀 & SDK 리스트 (페이징 없는 api)
 * @param adAccountId
 */
function fetchLinkedTrackListWithoutPaging(adAccountId) {
  const url = `/bcon/v2/services/moment/track-rights/adaccount/${adAccountId}/linked?paginate=false&orderType=POPULATION`

  return CruxFetchUtils.post({
    url,
    requestPayload: {},
    headers: { adAccountId },
  })
}

/**
 * 연동 관리 > 광고계정에 연동 가능한 픽셀 & SDK 리스트
 * @param adAccountId
 * @param params
 * @param cancelTokenSource
 */
function fetchAvailableTrackList(adAccountId, params, cancelTokenSource) {
  const url = `/bcon/v2/services/moment/track-rights/adaccount/${adAccountId}/unlinked?${queryString(
    params
  )}`

  return CruxFetchUtils.get({
    url,
    cancelTokenSource,
    headers: { adAccountId },
  })
}

/**
 * 연동 관리 > 트랙 연동하기
 * @param adAccountId
 * @param trackId
 */
function requestAuthorityToTrack(adAccountId, trackId) {
  const url = `/bcon/v2/services/moment/track-rights/${trackId}/adaccount/${adAccountId}`

  return RequestLock.acquire({
    key: 'requestAuthorityToTrack',
    executor: done =>
      CruxFetchUtils.put({
        url,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동 관리 > 트랙 연동해제
 * @param adAccountId
 * @param trackId
 */
function requestDeleteLinkedTrack(adAccountId, trackId) {
  const url = `/bcon/v2/services/moment/track-rights/${trackId}/adaccount/${adAccountId}`

  return RequestLock.acquire({
    key: 'requestDeleteLinkedTrack',
    executor: done =>
      CruxFetchUtils.delete({
        url,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동 관리 > 픽셀 & SDK 만들기
 * @param adAccountId
 * @param requestPayload
 */
function createPixelAndSdk(adAccountId, requestPayload) {
  const url = `/bcon/v2/services/moment/track-rights/adaccount/${adAccountId}`

  return RequestLock.acquire({
    key: 'createPixelAndSdk',
    executor: done =>
      CruxFetchUtils.post({
        url,
        requestPayload,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동관리 > 트랙에 연결가능한 카카오서비스 (조회)
 * @param adAccountId
 * @param trackId
 * @param serviceKey
 */
function fetchKakaoServicesByServiceKey(adAccountId, trackId, serviceKey) {
  const url = `/bcon/v2/tracks/kakao-services/${trackId}/${serviceKey}`
  return CruxFetchUtils.get({
    url,
    headers: { adAccountId },
  })
}

/**
 * 연동관리 > 트랙에 카카오서비스 연동하기 (저장)
 * @param adAccountId
 * @param trackId
 * @param requestPayload
 */
function addKakaoServiceToTrack(adAccountId, trackId, requestPayload) {
  const url = `/bcon/v2/tracks/${trackId}/track-rights`

  return RequestLock.acquire({
    key: 'addKakaoServiceToTrack',
    executor: done =>
      CruxFetchUtils.post({
        url,
        requestPayload,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동 관리 > 멤버 권한 요청 > 픽셀 & SDK (검색)
 * @param adAccountId
 * @param requestParams
 */
function fetchAvailableMemberRequestList(adAccountId, requestParams) {
  const url = `/bcon/v2/tracks/search/roleinfo?${queryString(requestParams)}`

  return CruxFetchUtils.get({
    url,
    headers: { adAccountId },
  })
}

/**
 * 연동관리 > 멤버 권한 요청
 * @param adAccountId
 * @param trackId
 */
function requestMemberToTrack(adAccountId, trackId) {
  const url = `/bcon/v2/tracks/${trackId}/role/request`

  return RequestLock.acquire({
    key: 'requestMemberToTrack',
    executor: done =>
      CruxFetchUtils.post({
        url,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동관리 > 멤버 권한 요청 취소
 * @param adAccountId
 * @param trackId
 * @param requestId
 */
function cancelMemberRequest(adAccountId, trackId, requestId) {
  const url = `/bcon/v2/tracks/${trackId}/role/request?requestId=${requestId}`

  return RequestLock.acquire({
    key: 'cancelMemberRequest',
    executor: done =>
      CruxFetchUtils.delete({
        url,
        headers: { adAccountId },
      }).finally(done),
  })
}

/**
 * 연동관리 > 상세 > 기본정보
 * @param adAccountId
 * @param trackId
 * @param cancelTokenSource
 */
function fetchDetailBasicInfo(adAccountId, trackId, cancelTokenSource) {
  const url = `/bcon/v2/services/moment/track-rights/adaccount/${adAccountId}/${trackId}/info`

  return CruxFetchUtils.get({
    url,
    headers: { adAccountId },
    cancelTokenSource,
  })
}

/**
 * 연동 관리 > 상세 > 연동 서비스 리스트
 * @param adAccountId
 * @param trackId
 */
function fetchDetailLinkageServiceList(adAccountId, trackId) {
  const url = `/bcon/v2/services/moment/tracks/${trackId}/services?targetCategory=adaccount&targetKey=${adAccountId}`

  return CruxFetchUtils.get({
    url,
    headers: { adAccountId },
  })
}

/**
 * 연동관리 > 상세 > 수집된 이벤트 리스트
 */
function fetchTrackCollectedEventList({
  adAccountId,
  trackId,
  requestPayload,
  withAllEvent = false,
}) {
  const url = `/bcon/v2/services/moment/tracks/${trackId}/events?targetCategory=adaccount&targetKey=${adAccountId}&withAllEvent=${withAllEvent}`

  return CruxFetchUtils.post({
    url,
    requestPayload,
    headers: { adAccountId },
  })
}

export default {
  fetchLinkedTrackList,
  fetchLinkedTrackListWithoutPaging,
  fetchAvailableTrackList,
  requestAuthorityToTrack,
  requestDeleteLinkedTrack,
  createPixelAndSdk,
  fetchKakaoServicesByServiceKey,
  addKakaoServiceToTrack,
  fetchAvailableMemberRequestList,
  requestMemberToTrack,
  cancelMemberRequest,
  fetchDetailBasicInfo,
  fetchDetailLinkageServiceList,
  fetchTrackCollectedEventList,
}
