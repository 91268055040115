import React from 'react'

/**
 * fn call 을 delay 만큼 지연 후 수행한다.
 * 가장 마지막으로 유입된 요청을 제외한 모든 요청은 무시처리 된다(singleton).
 *
 * 리스트 개별 항목 onMouseEnter 마다 fn call 이 동작되는 케이스에서 오동작(?) 방지를 위해 사용할 수 있다.
 *
 * ex)
 *  const { invoke, cancelInvoking } = useDelayedSingleton({ 100 })
 *  - 지연 호출: invoke(() => fn()) // 100ms 이후에 수행되도록 스케쥴링 한다.
 *  - 취소: cancelInvoking()
 *
 * @param delay {number}
 */
const useDelayedSingleton = ({ delay = 100 } = {}) => {
  const timerRef = React.useRef(-1)

  const invoke = React.useCallback(
    (invokableFn = () => {}) => {
      clearTimeout(timerRef.current)

      timerRef.current = setTimeout(invokableFn, delay)
    },
    [delay]
  )

  const cancelInvoking = React.useCallback(() => {
    clearTimeout(timerRef.current)

    timerRef.current = -1
  }, [])

  return {
    invoke,
    cancelInvoking,
  }
}

export { useDelayedSingleton }
