import { keyMirror } from '../utils'
import GoalEnum from '../../enums/GoalEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { memoAdGroupSelectedTargetingSize } from './campaignAdGroup'

export const ADSERVING_CATEGORY_TYPE = keyMirror({
  IAB1: null,
  IAB2: null,
  IAB3: null,
  IAB4: null,
  IAB5: null,
  IAB6: null,
  IAB7: null,
  IAB8: null,
  IAB9: null,
  IAB10: null,
  IAB11: null,
  IAB12: null,
  IAB13: null,
  IAB14: null,
  IAB15: null,
  IAB16: null,
  IAB17: null,
  IAB18: null,
  IAB19: null,
  IAB20: null,
  IAB21: null,
  IAB22: null,
  IAB23: null,
  IAB24: null,
})

export const ADSERVING_CATEGORY_TYPE_TEXT = {
  IAB1: '엔터테인먼트',
  IAB2: '자동차',
  IAB3: '비즈니스',
  IAB4: '직업',
  IAB5: '교육',
  IAB6: '가족 및 육아',
  IAB7: '건강 및 운동',
  IAB8: '식음료',
  IAB9: '취미',
  IAB10: '집과 정원',
  IAB11: '법, 정부와 정치',
  IAB12: '언론',
  IAB13: '금융',
  IAB14: '사회',
  IAB15: '과학',
  IAB16: '애완동물',
  IAB17: '스포츠',
  IAB18: '스타일 및 패션',
  IAB19: '기술 및 컴퓨터',
  IAB20: '여행',
  IAB21: '부동산',
  IAB22: '쇼핑',
  IAB23: '종교',
  IAB24: '기타',
}

/**
 * 과금 타입별 디폴트 입찰액(추천) - 컴포넌트에 기본 세팅되는 값.
 */
export const SUGGESTED_BID_AMOUNT_BY_PRICING_TYPE = {
  CPA: 1500,
  CPC: 200,
  CPM: 1000,
  CPMS: 50,
  OCPM: 0,
}

/**
 * 불러온 adGroup data pre-processing
 * - `가능한 모든 디바이스` 일 경우, deviceTypes 를 []로 강제한다.
 *
 * @param adGroupForm {object}
 * @return {object}
 */
export const preProcessingModifyAdGroupData = adGroupForm => {
  if (adGroupForm.allAvailableDeviceType) {
    adGroupForm.deviceTypes = []
  }

  return adGroupForm
}

export const PRICE_IMPRESSION_TYPE = keyMirror({
  PRICE: null,
  IMPRESSION: null,
})

export const AdGroupCardTitleTooltipMap = {
  DEMOGRAPHIC: 'create_ads.v2.ad_group.target.demography',
  PRICING_TYPE: 'create_ads.v2.ad_group.price.description',
  DAILY_BUDGET: 'create_ads.v2.ad_group.budget.daily_budget',
  PERIOD_BUDGET: 'create_ads.v2.ad_group.budget.period_budget',
  PERIOD: 'create_ads.v2.ad_group.schedule.sending_period',
  PACING_TYPE: 'create_ads.v2.ad_group.pacing.description',
}

export const getPeriodBudgetSubTitle = (
  campaignType,
  goal,
  selectedPriceImpressionType
) => {
  if (goal !== GoalEnum.Type.REACH) return ''

  switch (campaignType) {
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
      return selectedPriceImpressionType === PRICE_IMPRESSION_TYPE.PRICE
        ? '구매 금액'
        : '구매 노출수'
    }

    case CampaignTypeEnum.Type.TALK_CHANNEL: {
      return '구매 발송수'
    }

    case CampaignTypeEnum.Type.KAKAO_TV: {
      return '구매 금액'
    }

    default:
      return ''
  }
}

export const periodBudgetSubTitleToolTip = (adGroupForm, campaignType) => {
  const { targeting, smartMessage } = adGroupForm
  const isAvailableLongTermSending =
    memoAdGroupSelectedTargetingSize(targeting) === 0 && !smartMessage

  if (campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL) return ''

  return smartMessage
    ? 'create_ads.v2.ad_group.budget.sending_budget.smart_message'
    : isAvailableLongTermSending
    ? 'create_ads.v2.ad_group.budget.sending_budget.non_target'
    : 'create_ads.v2.ad_group.budget.sending_budget.target'
}
