import {
  BILLING,
  CONTEXTPATH,
  fetchGet,
  fetchPost,
  fetchPut,
  queryString,
} from '../../utils/fetchUtils'
import { queryString as queryStringV2 } from '../../utils/utils'
import { AD_ACCOUNT_TYPE } from '../../modules/adaccount/mAdAccount'

function modifyAdGroup(adAccountId, adGroupId, formData) {
  return fetchPut(`${CONTEXTPATH}/adGroups/${adGroupId}`, formData, adAccountId)
}

function fetchAdGroupInfoById(adAccountId, id) {
  return fetchGet(`${CONTEXTPATH}/adGroups/${id}`, adAccountId)
}

function fetchAdGroupsInfoByIds(adAccountId, adGroupIds) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups?${queryStringV2({ adGroupIds })}`,
    adAccountId
  )
}
/**
 * 4.3.21 계약 해지
 */
function requestAdGroupCancelDeposit(adAccountId, id) {
  return fetchPut(`${CONTEXTPATH}/adGroups/${id}/cancel`, null, adAccountId)
}

/**
 * 4.3.22 계약 해지 예상수수료 조회
 */
function fetchAdGroupExpectedCancellationFee(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups/${id}/expectedCancellationFee`,
    adAccountId
  )
}

/**
 * 4.2.7.2 캠페인, 광고그룹 다중 생성
 */
function createCampaignAdGroups(campaignWithMultiAdGroups, adAccountId) {
  return fetchPost(
    `${CONTEXTPATH}/campaigns`,
    campaignWithMultiAdGroups,
    adAccountId
  )
}

/**
 * 4.3.2 광고그룹 다중생성
 */
function createMultiAdGroups(campaignWithMultiAdGroups, adAccountId) {
  return fetchPost(
    `${CONTEXTPATH}/adGroups/multi`,
    campaignWithMultiAdGroups,
    adAccountId
  )
}

/**
 * CPMS 발송예산 확인(billing)
 */
function fetchCpmsEstimateAmount(
  adAccountId,
  amount,
  count,
  adAccountType = AD_ACCOUNT_TYPE.BUSINESS
) {
  const params = {
    amount,
    count,
    businessType: adAccountType,
  }
  return fetchGet(
    `${BILLING}/v2/wallet/${adAccountId}/deposit/estimateAmount?${queryString(
      params
    )}&dspId=KAKAOMOMENT`,
    adAccountId
  )
}

/**
 * 4.3.26
 */
function fetchCreativeCountByAdGroupId(adAccountId, id) {
  return fetchGet(`/search/adGroups/${id}/creativesCount`, adAccountId)
}

/**
 * 분산 발송하기 발송속도 옵션 리스트
 */
function fetchAdGroupSendRateOptions(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/adGroups/sendRateOptions`, adAccountId)
}

/**
 * 광고그룹 관리자정지 사유 조회
 */
function fetchAdGroupAdminStopHistory(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups/${id}/systemConfigHistories?systemConfig=ADMIN_STOP,EXTERNAL_SERVICE_STOP`,
    adAccountId
  )
}

export default {
  fetchAdGroupInfoById,
  fetchAdGroupsInfoByIds,
  modifyAdGroup,
  fetchAdGroupExpectedCancellationFee,
  requestAdGroupCancelDeposit,
  createCampaignAdGroups,
  createMultiAdGroups,
  fetchCpmsEstimateAmount,
  fetchCreativeCountByAdGroupId,
  fetchAdGroupSendRateOptions,
  fetchAdGroupAdminStopHistory,
}
