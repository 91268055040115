import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    VIDEO: null,
    IMAGE: null,
    MULTI: null,
  }),

  Name: {
    VIDEO: '동영상형',
    IMAGE: '이미지형',
    MULTI: '멀티형',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
