import PropTypes from 'prop-types'
import ActionButtonEnum from '../../../../../enums/ActionButtonEnum'
import ImmutablePropTypes from 'react-immutable-proptypes'

const ImageNativeTemplatePropTypes = {}

ImageNativeTemplatePropTypes.Container = {
  index: PropTypes.number.isRequired,
  creativeForm: ImmutablePropTypes.map.isRequired,
  at: PropTypes.string,
  promotionalImageURL: PropTypes.string,
  profileImageURL: PropTypes.string,
  showAddFriendPage: PropTypes.bool,
}

ImageNativeTemplatePropTypes.Page = {
  title: PropTypes.string,
  actionButton: PropTypes.oneOf(ActionButtonEnum.values()),
  profileName: PropTypes.string,
  profileImageURL: PropTypes.string,
  promotionalImageURL: PropTypes.string,
  isInAd: PropTypes.bool,
}

export default ImageNativeTemplatePropTypes
