import { keyMirror } from '../utils/utils'

const CouponBookTypeEnum = {
  Type: keyMirror({
    CHANNEL_COUPON_BOOK: null,
    CUSTOM_COUPON_BOOK: null,
    SET_COUPON_BOOK: null,
  }),
}

export default CouponBookTypeEnum
