import { getApiPhase } from './kakaoMoment'

const cruxGateway = {
  Host: {
    production: 'https://crux-gateway.kakao.com',
    stage: 'https://crux-gateway-cbt.kakao.com',
    sandbox: 'https://crux-gateway-sandbox.devel.kakao.com',
    develop: 'https://crux-gateway-dev.devel.kakao.com',
  },
}

export const cruxGatewayURL = () => {
  return cruxGateway.Host[getApiPhase()] || cruxGateway.Host.sandbox
}
