import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ON: null,
    VOID: null,
  }),

  Name: {
    ON: 'ON',
    VOID: 'VOID',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
