import { CONTEXTPATH, fetchPostForm, queryString } from '../../utils/fetchUtils'
import { checkNotEmpty } from '../../utils/regexUtils'

/**
 *
 * @param storageDomain {string} - secure | private | public
 * @param adAccountId {string | number}
 * @param path {string}
 * @param data {object}
 * @param fileName {string | *}
 * @param onProgress {function | *}
 * @param cancelTokenSource {string | *}
 */
function uploadTenth(
  storageDomain,
  adAccountId,
  path,
  data,
  fileName,
  onProgress,
  cancelTokenSource
) {
  const params = checkNotEmpty(fileName)
    ? { path, filename: encodeURIComponent(fileName) }
    : { path }

  return fetchPostForm(
    `${CONTEXTPATH}/upload/${storageDomain}?${queryString(params)}`,
    data,
    adAccountId,
    onProgress,
    cancelTokenSource
  )
}

function uploadTenthPrivate(
  adAccountId,
  path,
  data,
  fileName,
  onProgress,
  cancelTokenSource
) {
  return uploadTenth(
    'private',
    adAccountId,
    path,
    data,
    fileName,
    onProgress,
    cancelTokenSource
  )
}

/**
 * 필요서류 파일 등록
 * // FIXME: 심사일 때는 id = categoryDocumentId, company 사업자등록자의 경우 어떤값이 들어갈지 확인 필요(?)
 */
function uploadRequiredDocument(
  adAccountId,
  data,
  id,
  filename,
  cancelTokenSource
) {
  return uploadTenthPrivate(
    adAccountId,
    `/document/${adAccountId}`,
    data,
    filename,
    undefined,
    cancelTokenSource
  )
}

export default {
  uploadRequiredDocument,
}
