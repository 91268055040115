import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    CPA: null,
    CPC: null,
    CPM: null,
    CPV: null,
    CPT: null,
    CPMS: null,
    OCPM: null,
  }),

  Name: {
    CPA: 'CPA',
    CPC: 'CPC',
    CPM: 'CPM',
    CPV: 'CPV',
    CPT: 'CPT',
    CPMS: 'CPMS',
    OCPM: 'oCPM',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
