import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import moment from 'moment'
import CalendarEnum from '../../enums/CalendarEnum'
import { setReportChanged } from './mReportDetail'

const ReportCalendar = keyMirror(
  {
    CLEAR: null,
    SET: null,
    UPDATE: null,
  },
  'REPORT_CALENDAR'
)

const initialState = fromJS({
  /**
   * 보고서는 "오늘" 미제공, "어제" 기준으로 계산
   */
  startDate: moment().subtract(7, 'd'),
  endDate: moment().subtract(1, 'd'),
  periodType: CalendarEnum.Preset.Type.LAST7DAYS,
  customDateDiff: null,
})

export default createReducer(initialState, {
  [ReportCalendar.CLEAR]: () => {
    return initialState
  },

  [ReportCalendar.SET]: (state, { calendar }) => {
    return state.merge(fromJS(calendar))
  },

  [ReportCalendar.UPDATE]: (
    state,
    { startDate, endDate, periodType, updateCustomDateDiff }
  ) => {
    return state.withMutations(s =>
      s
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('periodType', periodType)
        .update('customDateDiff', prevCustomDateDiff =>
          updateCustomDateDiff && periodType === CalendarEnum.Preset.Type.CUSTOM
            ? Math.abs(moment(startDate).diff(moment(endDate), 'd')) // CUSTOM 일 경우 date diff 를 기억 후 간편 이동 시 이용한다(간편 이동 시 diff 갱신하지 않음).
            : prevCustomDateDiff
        )
    )
  },
})

export const clearReportCalendar = () => ({
  type: ReportCalendar.CLEAR,
})

export const initializeReportCalendar = calendar => ({
  type: ReportCalendar.SET,
  calendar,
})

export const updateReportCalendar = ({
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false,
}) => {
  return dispatch => {
    dispatch({
      type: ReportCalendar.UPDATE,
      startDate,
      endDate,
      periodType,
      updateCustomDateDiff,
    })

    dispatch(setReportChanged())
  }
}
