import { fetchPost } from '../../utils/fetchUtils'

function fetchAdAccountTotalChartData({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/adAccounts/chart',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 캠페인 차트
 */
function fetchCampaignTotalChartData({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/campaigns/chart',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 그룹 차트
 */
function fetchAdGroupTotalChartData({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/adGroups/chart',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 소재 차트
 */
function fetchCreativeTotalChartData({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/creatives/chart',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

function fetchCampaignChartData({
  adAccountId,
  campaignId,
  requestBody,
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/campaigns/${campaignId}/chart`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

function fetchAdGroupChartData({
  adAccountId,
  adGroupId,
  requestBody,
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/adGroups/${adGroupId}/chart`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

function fetchCreativeChartData({
  adAccountId,
  creativeId,
  requestBody,
  cancelTokenSource,
}) {
  return fetchPost(
    `/aggregation/report/dashboard/creatives/${creativeId}/chart`,
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

export default {
  fetchAdAccountTotalChartData,
  fetchCampaignTotalChartData,
  fetchAdGroupTotalChartData,
  fetchCreativeTotalChartData,
  fetchCampaignChartData,
  fetchAdGroupChartData,
  fetchCreativeChartData,
}
