import { getApiPhase } from './kakaoMoment'

const KakaoAdPolicy = {
  Host: {
    production: 'https://kakaobusiness-policy.kakao.com',
    stage: 'https://kakaobusiness-policy-cbt.devel.kakao.com',
    sandbox: 'https://kakaobusiness-policy-sandbox.devel.kakao.com',
    develop: 'https://kakaobusiness-policy-dev.devel.kakao.com',
  },

  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },
}

const kakaoAdPrivacyPolicyURL = () =>
  `${KakaoAdPolicy.getHost()}/policy/privacy`

const kakaoAdServiceTermsURL = () => `${KakaoAdPolicy.getHost()}/SERVICE`

const kakaoAdOperationPolicyURL = () =>
  `${KakaoAdPolicy.getHost()}/OPERATIONPOLICY`

export {
  kakaoAdPrivacyPolicyURL,
  kakaoAdServiceTermsURL,
  kakaoAdOperationPolicyURL,
}
