import { showErrorMessage } from '../alertUtils'

const DEFAULT_MAXIMUM_CALL = 3
const DEFAULT_ERROR_MESSAGE = ''
export default {
  create({
    retryFunc,
    maximumCall = DEFAULT_MAXIMUM_CALL,
    errorMessage = DEFAULT_ERROR_MESSAGE,
  }) {
    let retryCount = 0

    return {
      retryRequest: async () => {
        if (retryCount < maximumCall) {
          retryCount++
          const result = await retryFunc()
          showErrorMessage(
            `동영상을 처리 하는 중, 문제가 발생하여 재시도 중입니다. (${retryCount}/${maximumCall})`
          )
          return result
        } else {
          throw new Error(errorMessage)
        }
      },
    }
  },
}
