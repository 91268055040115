import React, { Fragment } from 'react'
import {
  BIZ_BOARD_IMAGE_EDIT_STATE,
  useBizBoardImageEditState,
} from './useBizBoardImageEditState'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { checkNoneEmpty, checkNotEmpty } from '../../../../../utils/regexUtils'
import {
  BIZ_BOARD_IMAGE_TYPE,
  BIZ_BOARD_MASK_TYPE,
  BizBoardImageEditUtil,
  BizBoardImageSpec,
} from '../bizBoardUtils'
import { useDebounce } from 'react-use'
import {
  setBizBoardCreateImageRect,
  setBizBoardCreateImageScaleValue,
} from '../../../../../modules/advertise/mBizBoardImageEditor'
import PropTypes from 'prop-types'
import { useBizBoardImageEdit } from './useBizBoardImageEdit'
import { usePrevious } from '../../../../../utils/hook/usePrevious'
import { isEqual } from 'lodash'

const selector = ({
  creativeV2: {
    bizBoardImageEditor: { imageRects, imageScales },
  },
}) => {
  return {
    imageRects,
    imageScales,
  }
}

/**
 * drag layer(event delegate)
 * --------------------------
 * image
 * --------------------------
 * background layer
 */
const BizBoardBannerImageEdit = ({
  isImageEdit,
  shouldImageCenterCropping,
  index,
  image,
  dragLayerRect,
  imageType,
  maskType,
  customEventHandlerRef,
  backgroundLayerNodeRef,
  handleTemplateChange,
}) => {
  const dispatch = useDispatch()

  const { imageRects, imageScales } = useSelector(selector, shallowEqual)
  const imageRect = imageRects.get(index)?.toJS()
  const { value: imageScale } = imageScales.get(index) || {}

  const imageNodeRef = React.useRef() // 실제로 보여지는 image

  const { editState, listener } = useBizBoardImageEditState()

  /**
   * ImageLayer 가 DragLayer 와 동일한 크기의 span 으로 감싸진 경우
   * ImageLayer 위치의 기준점이 inner_bnr 에서 이미지를 감싸는 span 으로 바뀐다.
   * (isNotPositionedRelativeToBanner = true)
   */
  const isNotPositionedRelativeToBanner = React.useMemo(
    () =>
      maskType === BIZ_BOARD_MASK_TYPE.MULTI_IMAGE ||
      imageType === BIZ_BOARD_IMAGE_TYPE.LOGO,
    [maskType, imageType]
  )

  const {
    dragLayerNodeRef,
    // useBizBoardEdit props
    imageTop,
    imageLeft,
    dragLayerEventHandler,
  } = useBizBoardImageEdit({
    index,
    image,
    storedImageNodePosition: imageRect?.imageNodePosition,
    imageNodeRef,
    dragLayerRect,
    customEventHandlerRef,
    editStateListener: listener,
    handleTemplateChange,
    imageType,
    isNotPositionedRelativeToBanner,
  })

  const prevImage = usePrevious(image)

  const imageCenterCropping = React.useCallback(() => {
    const isImageChanged = image && !isEqual(prevImage, image)

    if (!isImageChanged) return

    /**
     * 이미지 변경 or 드래그 레이어 변경 시(마스킹 유형 변경) 이미지를 드래그 레이어에 fit 하게 맞춘다.(최종 결과물: center crop)
     * 1. 이미지를 드래그 레이어 사이즈에 맞춘다. (fit center)
     * 2. 드래그 레이어 Rect 에 fit 하기 위한(center crop) scale 을 구한다.
     * 3. 이미지 node 가 최종적으로 배치될 left, top 을 구한다.
     */
    const { imageWidth, imageHeight } = image || {}

    const { top, left, scale } = BizBoardImageEditUtil.getImageNodePosition({
      imageWidth,
      imageHeight,
      dragLayerRect,
      imageType,
      isNotPositionedRelativeToBanner,
    })

    dispatch(
      setBizBoardCreateImageRect(index, {
        imageNodePosition: {
          left,
          top,
        },
      })
    )

    dispatch(setBizBoardCreateImageScaleValue(index, scale))
  }, [
    dispatch,
    dragLayerRect,
    image,
    imageType,
    index,
    isNotPositionedRelativeToBanner,
    prevImage,
  ])

  React.useEffect(() => {
    if (shouldImageCenterCropping) {
      imageCenterCropping()
    }
  }, [imageCenterCropping, shouldImageCenterCropping])

  const { fitCenterImageHeight } = React.useMemo(() => {
    if (checkNotEmpty(image)) {
      const { imageWidth, imageHeight } = image || {}
      const { fitCenterImageHeight } =
        BizBoardImageEditUtil.getImageNodePosition({
          imageWidth,
          imageHeight,
          dragLayerRect,
          imageType,
          isNotPositionedRelativeToBanner,
        })
      return {
        fitCenterImageHeight,
      }
    } else {
      return {
        fitCenterImageHeight: 0,
      }
    }
  }, [image, dragLayerRect, imageType, isNotPositionedRelativeToBanner])

  // 로고가 아닌 경우, image 높이를 dragLayer 높이에 맞추어 확대 한다.
  const imageHeight = React.useMemo(() => {
    return imageType === BIZ_BOARD_IMAGE_TYPE.LOGO
      ? fitCenterImageHeight
      : dragLayerRect?.height
  }, [imageType, fitCenterImageHeight, dragLayerRect])

  const { url } = image || {}

  return url ? (
    <Fragment>
      <DragLayer
        dragLayerNodeRef={dragLayerNodeRef}
        dragLayerRect={dragLayerRect}
        dragLayerEventHandler={dragLayerEventHandler}
        editState={editState}
      />
      {!isNotPositionedRelativeToBanner ? (
        <ImageLayer
          isEdit={isImageEdit}
          index={index}
          imageScale={imageScale}
          imageNodeRef={imageNodeRef}
          backgroundLayerNodeRef={backgroundLayerNodeRef}
          imageHeight={imageHeight}
          imageTop={imageTop}
          imageLeft={imageLeft}
          url={url}
          editState={editState}
          imageType={imageType}
        />
      ) : (
        <span
          style={{
            overflow: 'hidden',
            position: 'absolute',
            width: `${dragLayerRect.width}px`,
            height: `${dragLayerRect.height}px`,
            top: `${dragLayerRect.top}px`,
            left: `${dragLayerRect.left}px`,
            zIndex: imageType === BIZ_BOARD_IMAGE_TYPE.LOGO ? 2 : 1,
          }}>
          <ImageLayer
            isEdit={isImageEdit}
            index={index}
            imageScale={imageScale}
            imageNodeRef={imageNodeRef}
            backgroundLayerNodeRef={backgroundLayerNodeRef}
            imageHeight={imageHeight}
            imageTop={imageTop}
            imageLeft={imageLeft}
            url={url}
            editState={editState}
            imageType={imageType}
          />
        </span>
      )}
    </Fragment>
  ) : null
}

BizBoardBannerImageEdit.propTypes = {
  isImageEdit: PropTypes.bool,
  shouldImageCenterCropping: PropTypes.bool,
  index: PropTypes.number,
  image: PropTypes.object,
  dragLayerRect: PropTypes.object,
  customEventHandlerRef: PropTypes.object,
  handleTemplateChange: PropTypes.func,
  imageType: PropTypes.oneOf(Object.keys(BIZ_BOARD_IMAGE_TYPE)),
  backgroundLayerNodeRef: PropTypes.object,
  maskType: PropTypes.oneOf(Object.keys(BIZ_BOARD_MASK_TYPE)),
}

/**
 * delegate drag & scale(dray layer)
 */
const DragLayer = ({
  dragLayerNodeRef,
  dragLayerRect,
  dragLayerEventHandler,
  editState,
}) => {
  return (
    <div
      ref={dragLayerNodeRef}
      style={{
        width: `${dragLayerRect.width}px`,
        height: `${dragLayerRect.height}px`,
        top: `${dragLayerRect.top}px`,
        left: `${dragLayerRect.left}px`,
        position: 'absolute',
        zIndex: 20,
        style: {
          cursor:
            editState === BIZ_BOARD_IMAGE_EDIT_STATE.DRAGGING
              ? 'grab'
              : 'default',
        },
      }}
      {...dragLayerEventHandler}
    />
  )
}

DragLayer.propTypes = {
  dragLayerNodeRef: PropTypes.object,
  dragLayerRect: PropTypes.object,
  dragLayerEventHandler: PropTypes.object,
  editState: PropTypes.string,
}

const ImageLayer = ({
  isEdit,
  index,
  imageScale,
  imageNodeRef,
  backgroundLayerNodeRef,
  imageHeight,
  imageTop,
  imageLeft,
  url,
  editState,
  imageType,
}) => {
  const dispatch = useDispatch()

  /**
   * DRAG & SCALE EDIT DATA
   * - scale, image position 변경 시 side effect
   * - width(intrinsic): image width * ratio * scale
   * - height(intrinsic): image height * ratio * scale
   * - top(image node): background layer 를 기점으로 획득(bg layer top = 0).
   * - left(image node): background layer 를 기점으로 획득(bg layer left = 0).
   */
  const updateImageRect = React.useCallback(() => {
    const imageNode = imageNodeRef.current
    const backgroundLayerNode = backgroundLayerNodeRef.current

    if (checkNoneEmpty(imageNode, backgroundLayerNode)) {
      const imageNodeRect = imageNode.getBoundingClientRect()
      const backgroundLayerNodeRect =
        backgroundLayerNode.getBoundingClientRect()
      const width = imageNodeRect.width * BizBoardImageSpec.RATIO
      const height = imageNodeRect.height * BizBoardImageSpec.RATIO
      const left =
        (imageNodeRect.left - backgroundLayerNodeRect.left) *
        BizBoardImageSpec.RATIO
      const top =
        (imageNodeRect.top - backgroundLayerNodeRect.top) *
        BizBoardImageSpec.RATIO

      dispatch(
        setBizBoardCreateImageRect(index, {
          width,
          height,
          top,
          left,
          imageNodePosition: {
            left: imageLeft,
            top: imageTop,
          },
        })
      )
    }
  }, [dispatch, index, imageScale, imageTop, imageLeft]) // do not fix

  // debounced `updateImageRect` by drag and scale
  useDebounce(
    () => {
      updateImageRect()
    },
    500,
    [updateImageRect]
  )

  return (
    <img
      ref={imageNodeRef}
      src={url}
      style={{
        /* opacity:
                  isEdit || isFocusOut
                    ? 1
                    : imageType === BIZ_BOARD_IMAGE_TYPE.OBJET
                    ? 0.3
                    : 0.2, */
        height: `${imageHeight}px`,
        top: `${imageTop}px`,
        left: `${imageLeft}px`,
        position: 'absolute',
        transform: `scale(${imageScale})`,
        cursor:
          editState === BIZ_BOARD_IMAGE_EDIT_STATE.DRAGGING
            ? 'grab'
            : 'default',
        zIndex:
          imageType === BIZ_BOARD_IMAGE_TYPE.LOGO ? 2 : isEdit ? 1 : undefined,
      }}
      draggable={false}
    />
  )
}

ImageLayer.propTypes = {
  isEdit: PropTypes.bool,
  index: PropTypes.number,
  imageScale: PropTypes.number,
  imageNodeRef: PropTypes.object,
  backgroundLayerNodeRef: PropTypes.object,
  url: PropTypes.string,
  imageHeight: PropTypes.number,
  imageTop: PropTypes.number,
  imageLeft: PropTypes.number,
  editState: PropTypes.string,
  imageType: PropTypes.string,
}

export default BizBoardBannerImageEdit
