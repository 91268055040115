import * as Sentry from '@sentry/browser'

// Production only.
const crashReportMiddleware = store => next => action => {
  try {
    return next(action)
  } catch (err) {
    Sentry.captureException(err, {
      extra: {
        action,
        state: store.getState(),
      },
    })
    throw err
  }
}

export { crashReportMiddleware }
