import { BILLING, fetchGet, queryString } from '../../utils/fetchUtils'

/**
 * @param adAccountId
 * @param searchOptions
 * @returns {*}
 */
function fetchInvoiceList(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v1/wallet/${adAccountId}/taxbill?${queryString(searchOptions)}`,
    adAccountId
  )
}

/**
 * 세금계산서 조회후 팝업
 * @param adAccountId
 * @param taxBillId
 * @returns {*}
 */
function fetchInvoicePopup(adAccountId, taxBillId) {
  return fetchGet(
    `${BILLING}/v1/wallet/${adAccountId}/taxbill/${taxBillId}`,
    adAccountId
  )
}

export default {
  fetchInvoiceList,
  fetchInvoicePopup
}
