import DimensionEnum from '../../../enums/DimensionEnum'
import moment from 'moment'
import { v4 as uuid } from 'uuid'
import { ReportFilterUtils } from './reportFilterUtils'
import ReportCalendarUtils from './reportCalendarUtils'
import CalendarEnum from '../../../enums/CalendarEnum'

export const REPORT_ITEM_PAGE_SIZE = 50
export const REPORT_REQUEST_CANCEL_KEY = uuid()

const convertCalendarStateToRequest = ({ startDate, endDate, periodType }) => {
  const { begin, end } = ReportCalendarUtils.rangeByPreset(periodType, moment())
  const presetDate =
    startDate.isSame(begin, 'day') && endDate.isSame(end, 'day')
      ? periodType
      : CalendarEnum.Preset.Type.CUSTOM
  return {
    startDate: moment(startDate).format(moment.HTML5_FMT.DATE),
    endDate: moment(endDate).format(moment.HTML5_FMT.DATE),
    presetDate,
  }
}

const convertDimensionStateToRequest = ({ adUnits, dimensions }) => {
  return adUnits
    .concat(dimensions)
    .flatMap(dimension => {
      if (dimension === DimensionEnum.Targeting.Type.AGE_BAND_GENDER) {
        return [
          DimensionEnum.Targeting.Type.AGE_BAND,
          DimensionEnum.Targeting.Type.GENDER,
        ]
      } else if (dimension === DimensionEnum.Targeting.Type.DEVICE_PLACEMENT) {
        return [
          DimensionEnum.Targeting.Type.DEVICE,
          DimensionEnum.Targeting.Type.PLACEMENT,
        ]
      } else {
        return [dimension]
      }
    })
    .toJS()
}

const covertMetricSateToRequest = metrics => metrics.toJS()
const convertSortSateToRequest = ({ sort, order }) => {
  return [{ key: sort, direction: order }]
}

const convertFilterStateToRequest = ({ filterConditions }) => {
  /**
   * filters
   */
  const filters = {}

  filterConditions.forEach(filter => {
    const { adUnitType, filterType, filterValue } = filter

    const filterTypeKey = ReportFilterUtils.Common.filterTypeKey({
      filterType,
    })

    if (ReportFilterUtils.Common.isAdUnitFilter({ filterType })) {
      const adUnitKey = ReportFilterUtils.Common.adUnitKey({ adUnitType })

      /**
       * filters: { [adUnitType]: { [filterTypeKey]: [] } }
       */
      filters[adUnitKey] = {
        ...filters[adUnitKey],
        [filterTypeKey]: filterValue,
      }
    } else if (ReportFilterUtils.Common.isTargetingFilter({ filterType })) {
      /**
       * filters: { dimensions: { [filterTypeKey]: [] } }
       */
      filters.dimensions = {
        ...filters.dimensions,
        [filterTypeKey]: filterValue,
      }
    }
  })

  /**
   * conditionals
   */
  const conditionals = {
    campaign: [],
    adGroup: [],
    creative: [],
  }

  filterConditions
    .filter(({ filterType }) =>
      ReportFilterUtils.Common.isMetricFilter({ filterType })
    )
    .forEach(filter => {
      const { adUnitType, filterSubType, filterValue, filterOperator } = filter

      const adUnitKey = ReportFilterUtils.Common.adUnitKey({ adUnitType })
      const filterOperators = filterOperator.split('_')
      const isBetweenOperatorSelect = filterOperators.length > 1
      const [{ min, max }] = filterValue
      const valueArray = isBetweenOperatorSelect ? [min, max] : [min] // "사이"는 min, max 구조로 사용하고, "이상/이하/같음"은 min 값만 사용.

      conditionals[adUnitKey] = [
        ...conditionals[adUnitKey],
        ...valueArray.reduce(
          (acc, value, index) => [
            ...acc,
            {
              conditionalFunction: filterOperators[index],
              value,
              indicator: String(filterSubType).toUpperCase(),
            },
          ],
          []
        ),
      ]
    })

  return {
    filters,
    conditionals,
  }
}

export const convertStateToRequest = ({
  adAccountId,
  startDate,
  endDate,
  periodType,
  filterConditions,
  adUnits,
  dimensions,
  metrics,
  sort,
  order,
}) => {
  return {
    serviceAccountId: adAccountId,
    ...convertCalendarStateToRequest({
      startDate,
      endDate,
      periodType,
    }),
    ...convertFilterStateToRequest({ filterConditions }),
    dimensions: convertDimensionStateToRequest({
      filterConditions,
      adUnits,
      dimensions,
    }),
    indicators: covertMetricSateToRequest(metrics),
    sort: convertSortSateToRequest({ sort, order }),
  }
}
