import React from 'react'
import { KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD } from '../utils/app/services/kakaoBusinessGuide'

const Messages = Object.freeze({
  'ad_account.member.privilege':
    '광고계정 권한은 마스터와 멤버로 이루어져 있으며 마스터는 1명입니다. 마스터는 부재 등의 사유로 더이상 마스터 권한을 사용하지 않는 경우, 광고계정 내 멤버 1명에게 마스터 권한을 이전해야 합니다. 마스터 권한은 마스터만 이전 요청할 수 있으며 권한을 요청받은 멤버가 수락 시 마스터 권한 및 광고계정의 소유권 일체가 이전됩니다. (단, 사업자등록번호를 미등록한 광고계정은 마스터 권한을 이전할 수 없습니다.)',
  'ad_account.create.agency':
    '세금계산서 발행용 사업자등록번호를 잘못 입력한 경우 카카오에서 정보를 수정할 수 있습니다.',
  'ad_account.create.pixel_sdk.info':
    '광고계정과 픽셀 & SDK를 연동하면 캠페인 생성 시 손쉽게 광고 최적화 설정이 가능합니다.',
  'ad_account.create.pixel_sdk.service': '픽셀 & SDK에 연동된 서비스입니다.',
  'ad_account.create.pixel_sdk.population':
    '이벤트별로 최근 180일간 수집한 추정 모수입니다.',
  'ad_account.create.pixel_sdk.event_status':
    '픽셀 & SDK로 이벤트가 수집되고 있는 상태입니다. 픽셀 & SDK를 설치하거나 카카오 서비스를 연동하면 이벤트가 수집됩니다.',
  'create_ads.v2.ad_campaign.budget.daily_budget':
    '하루 기준의 통합 지출 한도를 의미하며, 일 예산을 초과할 경우 광고 집행을 중단합니다. 단, 광고 노출을 중단하는 프로세스 도중에 노출/클릭 등이 발생하거나 하위 광고그룹 내 게재방식 및 입찰금액에 따라 일 예산을 초과하는 과금이 발생할 수 있습니다.',
  'create_ads.v2.ad_campaign.budget.total_budget':
    '전체 집행기간 기준의 통합 지출 한도를 의미하며, 캠페인 잔여 예산 내에서 광고그룹 생성이 가능합니다. VAT 별도 금액을 입력하세요.',
  'create_ads.v2.ad_campaign.purpose_target.purchase': (
    <>
      구매/앱내구매/톡스토어를 통한 구매 사용자를 늘릴 수 있도록 최적화하여
      광고를 노출합니다. 최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로
      머신러닝을 작동하여, 학습된 이벤트 모수가 1,000건 이상일 때 효과적으로
      작동합니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.app_install': (
    <>
      앱설치 사용자를 늘릴 수 있도록 최적화하여 광고를 노출합니다. 최근 30일간
      픽셀 & SDK로 수집된 이벤트 모수를 기반으로 머신러닝을 작동하여, 학습된
      이벤트 모수가 1,000건 이상일 때 효과적으로 작동합니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.complete_registration': (
    <>
      회원가입/싱크를 통한 회원가입 사용자를 늘릴 수 있도록 최적화하여 광고를
      노출합니다. 최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로
      머신러닝을 작동하여, 학습된 이벤트 모수가 1,000건 이상일 때 효과적으로
      작동합니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.participation': (
    <>
      잠재고객 사용자를 늘릴 수 있도록 최적화하여 광고를 노출합니다. 해당
      이벤트는 사전예약/상담신청/시승신청/대출한도조회/보험료조회 태그로 구분할
      수 있습니다. 톡 캘린더를 통한 톡참여시에도 잠재고객 이벤트로 수집됩니다.
      최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로 머신러닝을
      작동하여, 학습된 이벤트 모수가 1,000건 이상일 때 효과적으로
      작동합니다.사전준비,튜토리얼,목표 달성 이벤트도 잠재고객 이벤트와 함께
      최적화 됩니다. <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된
      이벤트 모수와 최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.sign_up': (
    <>
      서비스신청 사용자를 늘릴 수 있도록 최적화하여 광고를 노출합니다. 해당
      이벤트는 서비스가입/구독완료/카드발급/계좌개설/대출신청 태그로 구분할 수
      있습니다. 비즈니스폼을 통한 톡참여시에도 서비스신청 이벤트로 수집됩니다.
      최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로 머신러닝을
      작동하여, 학습된 이벤트 모수가 1,000건 이상일 때 효과적으로 작동합니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.cart': (
    <>
      장바구니 보기/장바구니 추가/관심상품 추가 사용자를 늘릴 수 있도록
      최적화화여 광고를 노출합니다. 최근 30일간 픽셀 & SDK로 수집된 이벤트
      모수를 기반으로 머신러닝을 작동하여, 학습된 이벤트 모수가 1,000건 이상일
      때 효과적으로 작동합니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_campaign.purpose_target.optimization_pixel_sdk':
    '광고 최적화를 할 픽셀 & SDK를 선택 시 해당 픽셀 & SDK로 캠페인에서 발생한 전환 지표도 추적하여 수집합니다.',
  'create_ads.v2.ad_campaign.purpose_target.catalog_product_purchase':
    '구매/앱내구매/톡스토어를 통한 구매 사용자를 늘릴 수 있도록 최적화하여 광고를 노출합니다.',
  'create_ads.v2.ad_group.item_retarget.item_action':
    '고객이 상품에 보인 반응을 의미합니다. 선택한 이벤트를 기준으로 고객이 반응했던 상품을 리타게팅 광고에 활용합니다. 이벤트가 발생한 기간은 최소 1일에서 최대 90일까지 설정할 수 있습니다.',
  'create_ads.v2.ad_group.target.common.target_period':
    '선택한 기간동안 수집된 모수를 타겟팅에 사용합니다. 최소 1~ 최대 180일 중 입력이 가능합니다. 1일은 오늘을 의미하며, 오늘 수집된 가장 최근 이벤트를 모수에 포함합니다.',
  'create_ads.v2.ad_group.target.common.population':
    '선택한 기간 내에 해당 이벤트로 수집된 디바이스 수 입니다. 기간 내 모수가 0인 경우, 타겟을 사용할 수 없습니다.',
  'create_ads.v2.ad_group.target.cohort':
    '카카오모먼트에서 집행한 웹/앱 광고 및 메시지에 반응(클릭, 전환, 재생, 열람)한 사용자를 리타게팅합니다.',
  'create_ads.v2.ad_group.target.pixel_sdk':
    '카카오 픽셀 & SDK로 수집한 웹/앱 방문, 가입, 설치, 구매 등의 행동을 한 사용자를 리타게팅합니다.',
  'create_ads.v2.ad_group.index_target.skan':
    'iOS 추적 비허용 기기의 전환 지표를 수집할 수 있습니다.',
  'create_ads.v2.ad_group.index_target.app_id':
    '캠페인에서 선택한 픽셀 & SDK와 연동된 앱ID 중 선택할 수 있으며, 선택한 MMP와 포스트백을 공유합니다. 앱ID당 광고그룹은 40개 이하로 집행하길 권장합니다.',
  'create_ads.v2.ad_group.target.kakao_user':
    '내 카카오계정과 연동된 카카오톡 채널 프로필을 활용하여, 채널 친구 또는 카카오 로그인 이용자를 타게팅합니다.',
  'create_ads.v2.ad_group.target.kakao_user_sync':
    '카카오 로그인 및 싱크 가입자가 모수에 포함됩니다.',
  'create_ads.v2.ad_group.target.adid_file':
    '광고주가 보유한 오디언스 광고 식별자 (모바일 앱 식별자-맞춤타겟)를 업로드하여 타게팅합니다. ',
  'create_ads.v2.ad_group.target.mat':
    'MAT(Mobile App Tracker) 업체를 통하여 파악한 방문, 가입, 설치, 구매 등의 행동을 한 사용자를 리타게팅합니다.',
  'create_ads.v2.ad_group.target.friend_group':
    '광고주가 보유한 고객 식별자 (전화번호/앱유저아이디)를 업로드하여 타게팅합니다.',
  'create_ads.v2.ad_group.target.keyword':
    '다음/카카오 검색 관련 서비스에서 특정 키워드를 검색했던 이력을 분석하여 타겟 모수를 제공합니다.',
  'create_ads.v2.ad_group.target.keyword_search_input':
    '일치 또는 관련 키워드를 검색하면, 검색빈도가 높은 순으로 최대 50개의 결과를 확인할 수 있습니다. 특수문자가 포함된 키워드의 경우, 특수문자는 제외하고 추가됩니다.',
  'create_ads.v2.ad_group.target.keyword_direct_input':
    '키워드를 직접 입력하거나 복사하여 붙여 넣어, 한번에 대량 설정 할 수 있습니다. 한글, 영문, 숫자가 포함된 50자 이내의 키워드만 추가할 수 있습니다. 입력된 키워드 중 중복되거나 유효하지 않은 키워드는 설정된 키워드에서 제외됩니다.',
  'create_ads.v2.ad_group.target.age':
    '15세 미만은 타게팅 대상에서 제외됩니다.',
  'create_ads.v2.ad_group.target.location':
    '사용자가 가장 많이 방문한 지역을 타게팅 할 수 있으며, 행정구역상 동/읍/면 단위까지 설정할 수 있습니다.',
  'create_ads.v2.ad_group.target.demography':
    '인구통계학 기준으로 오디언스를 타게팅 할 수 있습니다. 전체 옵션 선택 시 추정 정보를 알 수 없는 대상까지 모두 포함합니다.',
  'create_ads.v2.ad_group.target.kakao_channel_friend':
    '채널 친구가 여러 디바이스를 사용하는 것으로 추정될 경우 모든 디바이스가 모수에 포함됩니다.',
  'create_ads.v2.ad_group.content_target':
    '광고를 게재할 카카오TV 동영상 콘텐츠를 타게팅합니다.',
  'create_ads.v2.ad_group.placement.kakaoservice':
    '카카오서비스 중 카카오톡, 카카오스토리, 다음 외의 서비스 지면을 의미합니다. 현재는 카카오페이지, 카카오헤어샵, 카카오맵, 카카오버스, 카카오지하철, 카카오TV, 카카오내비 등이 포함되어 있으며 광고 유형별로 노출되는 서비스는 상이할 수 있습니다.',
  'create_ads.v2.ad_group.placement.network':
    '카카오와 제휴를 맺고있는 외부 네트워크 지면에 광고를 노출할 수 있습니다. 선택된 카테고리에 속한 매체에 광고를 노출합니다.',
  'create_ads.v2.ad_group.placement.network_bizboard':
    '카카오와 제휴를 맺고있는 외부 네트워크 지면에 광고를 노출할 수 있습니다. (URL, 소식 랜딩 소재에 한하여 노출 가능)',
  'create_ads.v2.ad_group.price.description':
    '선택한 입찰방식 및 입력한 입찰금액 내에서 과금되며 설정한 타게팅, 입찰금액 및 일 예산에 따라 초과 과금이 발생할 수 있습니다.',
  'create_ads.v2.ad_group.price.cpm':
    '노출당 과금을 의미합니다. CPM 선택 시, 입력 금액은 노출 1,000회 당 금액을 의미하며 광고가 노출될 때마다 과금합니다.',
  'create_ads.v2.ad_group.price.cpc':
    '클릭당 과금을 의미합니다. 광고의 클릭이 발생할 때 마다 과금됩니다.',
  'create_ads.v2.ad_group.price.cpa':
    '액션당 과금을 의미합니다. 광고목표대상으로 설정한 채널 친구 추가 액션이 발생할 때 과금됩니다.',
  'create_ads.v2.ad_group.price.ocpm':
    '노출당 과금을 의미합니다. 시스템에서 입찰금액 및 게재방식을 자동으로 운영합니다.',
  'create_ads.v2.ad_group.price.cpms': '발송당 과금을 의미합니다.',
  'create_ads.v2.ad_group.price.cpt':
    '설정된 기간 단위(구좌)의 과금을 의미합니다. 실제 노출과 관계 없이 기간이 지나면 항상 과금됩니다.',
  'create_ads.v2.ad_group.price.cpv':
    '재생당 과금을 의미합니다. 동영상 광고가 재생될 때마다 과금됩니다.',
  'create_ads.v2.ad_group.price.max_bid_price.auto_bid':
    '광고그룹 일 예산 내에서 선택한 광고 효율을 최대한 높이도록 입찰금액을 자동으로 설정합니다.',
  'create_ads.v2.ad_group.price.sales_price.cpt':
    '노출 기간, 지면에 따라 구좌 당 금액이 변경됩니다.',
  'create_ads.v2.ad_group.price.sales_price.cpms':
    '타겟 정보가 변경되는 경우 건당 금액이 변경됩니다. 타게팅이 적용되지 않은 경우 15원, 타게팅이 적용된 경우 20원입니다.',
  'create_ads.v2.ad_group.budget.daily_budget':
    '광고그룹 기준의 1일 집행 가능 예산 한도를 의미합니다. 일 과금비용이 일 예산을 초과하는 경우 광고 집행이 자동 중지되며 설정한 입찰금액 및 일 예산에 따라 초과 과금이 발생할 수 있습니다.',
  'create_ads.v2.ad_group.budget.period_budget':
    '광고그룹 기준의 설정한 집행 기간 내 집행 가능 예산 한도를 의미합니다. 설정한 집행기간동안 입력한 기간 예산이 모두 소진될 수 있도록 합니다. VAT 별도 금액을 입력하세요.',
  'create_ads.v2.ad_group.budget.sending_budget.target':
    '실제 발송하고자 하는 건수를 입력할 수 있습니다. 입력한 건수만큼만 발송을 시도합니다. 예상 발송 모수보다 크게 설정할 수 없습니다.',
  'create_ads.v2.ad_group.budget.sending_budget.non_target':
    '실제 발송하고자 하는 건수를 입력할 수 있습니다. 입력한 건수만큼만 발송을 시도합니다. 예상 발송 모수보다 작게 설정할 수 없습니다.',
  'create_ads.v2.ad_group.budget.sending_budget.smart_message':
    '실제 발송하고자 하는 건수를 입력할 수 있습니다. 입력한 건수만큼만 발송을 시도합니다. 스마트메시지를 사용하는 경우, 최소 3만의 구매발송수가 필요합니다.',
  'create_ads.v2.ad_group.budget.sending_budget.longterm':
    '체크 시 메시지 발송 종료 일시를 설정하여 종료 일시까지 새로운 친구가 추가되는 경우 새로운 친구에게도 메시지가 발송됩니다. 발송수 설정 시 종료 일시까지 새로운 친구에게 발송할 발송수를 합하여 발송수를 설정합니다.',
  'create_ads.v2.ad_group.pacing.description':
    '예산을 사용하려는 목적에 따라 게재방식을 설정할 수 있습니다. 단, 자동입찰 광고그룹인 경우, 게재방식도 시스템이 자동으로 운영합니다.',
  'create_ads.v2.ad_group.pacing.normal':
    '광고그룹에 설정된 일 예산을 바탕으로 시간대별로 고려된 예산을 초과하지 않도록 예산을 분할하여 광고 노출을 제어하는 방식입니다. 단, 설정한 일 예산 및 입찰금액에 따라 초과 과금이 발생할 수 있습니다.',
  'create_ads.v2.ad_group.pacing.quick':
    '광고그룹에 설정된 일 예산을 최대한 빠르게 소진하도록 광고를 노출하는 방식입니다. 설정한 일 예산 및 입찰금액에 따라 초과 과금이 발생할 수 있습니다.',
  'create_ads.v2.ad_group.population.display': (
    <>
      예상 모수는 최근 120일 내 카카오 광고 지면 방문 이력이 있는 광고 식별자를
      기준으로 `광고에 노출될 수 있는 예상 모수`를 추정한 값입니다. 광고그룹에서
      설정한 타겟팅, 게재지면, 디바이스 조건에 따라 변경됩니다.
      성별,나이,지역,관심사/업종 타겟 정보는 카카오의 다양한 데이터를 기반으로
      추정되며 변화할 수 있습니다. 예상 모수는 광고 도달치 또는 실제 인구 통계
      수치를 의미하지 않습니다.
      <br />
      <br />
      *픽셀 & SDK 타겟의 경우, 설정한 타겟 기간 기준의 수집 모수를 기준으로
      광고에 노출될 예상 모수를 추정합니다.
    </>
  ),
  'create_ads.v2.ad_group.population.message':
    '예상 발송 모수는 내 카카오톡 채널 중 카카오모먼트에서 추정한 `메시지를 발송할 수 있는 타겟 수`를 의미합니다. 발송 시점에 따라 예상 모수와 실제 발송 대상은 차이가 있을 수 있습니다.',
  'create_ads.v2.ad_group.population.optimization_status': (
    <>
      최적화 상태는 최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로
      머신러닝을 작동하여, 학습된 이벤트 모수와 광고그룹의 전환수에 따라
      결정됩니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_group.schedule.sending_period': (
    <>
      메시지 발송이 가능한 총 기간을 의미합니다. 총 기간은 시작일로부터 최대
      30일로 자동 설정됩니다.(새 친구에게도 보내기를 선택한 경우, 시작 일시에서
      1시간 이후로 종료 일시를 설정할 수 있습니다.)
      <br />
      발송 가능한 상태인 경우에만 시작 일시가 도래하면 메시지를 발송합니다.
      메시지 발송이 완료되기 전에 중지가 가능하며, 집행 기간 내에 다시 재개할 수
      있습니다. 기간이 지나면 발송 완료 여부와 관계없이 종료 처리됩니다.
    </>
  ),
  'create_ads.v2.ad_group.other_setting.send_rate_options': (
    <>
      시스템 부하 등이 우려되는 경우 분산 발송 속도를 설정하여 메시지 발송
      속도를 조절할 수 있습니다.
      <br />
      발송 상황에 따라 설정한 속도와 차이가 발생할 수 있습니다.
    </>
  ),
  'create_ads.v2.ad_group.other_setting.do_not_send_push':
    '체크 시 카카오톡 푸시 알림 없이 발송합니다.',
  'create_ads.v2.ad_group.free_cash_budget':
    '이벤트 무상캐시는 특정 광고 상품에만 사용 가능한 전용 무상캐시입니다.',
  'create_ads.v2.ad_content.common.landing_url':
    '모바일, PC 디바이스 각각 랜딩 URL을 설정할 수 있습니다. 광고그룹에서 설정한 디바이스의 랜딩 URL을 입력하지 않을 경우, 모바일 또는 PC 랜딩 URL 중 입력된 랜딩 URL로 노출됩니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.\n',
  'create_ads.v2.ad_content.common.placement':
    '해당 소재 사이즈가 노출 가능한 게재지면을 의미합니다. 본 소재에 대한 효율성이 낮은 지면에는 노출되지 않을 수 있습니다.',
  'create_ads.v2.ad_content.common.connect_catalog_product':
    '`내 상점 연동`을 통해 가져온 소재는 상품ID와 연동상태를 가집니다. 랜딩 URL은 수정이 불가하며, 추후 상품 단위 보고서가 제공될 예정입니다. 소재-상품 연결은 언제든 해제할 수 있습니다.',
  'create_ads.v2.ad_content.common.tracking_url':
    '카탈로그 개별 상품 클릭 시 랜딩할 URL을 입력할 수 있습니다. 별도 설정하지 않을 경우, 카탈로그 데이터에 입력된 상품 link로 연결합니다.',
  'create_ads.v2.ad_content.common.event_tracking_url':
    'MMP 또는 트래킹 파트너사에서 발급받은 http://, https:// 형태로 시작하는 URL을 입력해주세요.',
  'create_ads.v2.ad_content.common.click_tracking_url':
    'MMP 또는 트래킹 파트너사에서 발급받은 http://, https:// 형태로 시작하는 URL을 입력해주세요. 광고 전환 성과 지표를 보다 정확하게 집계하려면 URL 파라미터에 click_id와 google_aid 또는 apple_ifa 매개변수를 포함하는 것을 권장합니다.',
  'create_ads.v2.ad_content.common.lower_version':
    '광고가 노출되는 디바이스가 특정 버전이하일 경우, 하위버전 소재요소로 설정한 값이 대체하여 노출됩니다.',
  'create_ads.v2.ad_content.common.landing_url_lower':
    '카카오톡 하위버전에서 제공하지 않는 랜딩 URL의 비즈니스폼 선택시, 하위버전 대응을 위한 랜딩 URL을 설정합니다. ',
  'create_ads.v2.ad_content.common.button_color':
    '업로드한 이미지와 어울리는 색상을 추천합니다.',
  'create_ads.v2.ad_content.type_message':
    '카카오톡 채널 관리자센터에서 카카오모먼트로 전송한 메시지 중에서 설정할 수 있습니다. 메시지를 저장한 이후에는 동일한 메시지형식으로만 변경할 수 있습니다.',
  'create_ads.v2.ad_content.type_image_banner.name':
    '실제 소재는 업로드된 이미지 수만큼 생성되며, 각각의 소재 이름은 입력받은 소재 이름 뒤에 이미지 사이즈가 붙는 형식으로 저장됩니다. 예) 소재 이름_640X200',
  'create_ads.v2.ad_content.type_image_banner.px':
    '모바일 단말 해상도에 따라 양끝 여백이 이미지의 좌우 1px에 해당하는 색으로 채워집니다.',
  'create_ads.v2.ad_content.type_image_banner.alt_text':
    '장애인에게 음성 안내되는 내용으로 특수문자는 입력 불가합니다.',
  'create_ads.v2.ad_content.type_image_banner.landing_msg':
    '메시지 관리에 등록된 메시지입니다.',
  'create_ads.v2.ad_content.type_image_banner.landing_bizForm':
    '광고계정 내 권한이 등록된 비즈니스폼 중 종료일이 지나지 않은 비즈니스폼 리스트입니다.',
  'create_ads.v2.ad_content.type_bizboard.landing_url':
    '카카오 비즈보드 유형은 다양한 랜딩을 지원합니다. 카카오톡 내에서 액션이 완결되는 비즈 솔루션 설정 시 카카오톡 게재지면에만 노출됩니다. 톡 외 지면에 노출하거나 비즈보드 캠페인의 노출 확대 옵션을 설정하려면 아웃랜딩(랜딩유형: URL)을 선택하세요. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.pc_talk_bottom.landing_url':
    '포커스 보드 유형은 다양한 랜딩을 지원합니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.pc_talk_rich_pop.landing_url':
    '리치팝 올데이 유형은 다양한 랜딩을 지원합니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.focus_full_view.landing_url':
    '포커스 풀뷰 유형은 다양한 랜딩을 지원합니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.profile_feed.landing_url':
    '프로필 풀뷰 유형은 다양한 랜딩을 지원합니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.type_bizboard.landing_type.pay_buy':
    '카카오페이 구매 판매점 내 ‘노출 가능한’ 상품만 보여줍니다.',
  'create_ads.v2.ad_content.catalog.representative_landing_url':
    '프로필 이미지나 프로필 이름 등 소재 구성에 따라 슬라이드 외 공통 요소의 랜딩 URL로 사용되며, 슬라이드 개별 요소의 랜딩 URL이 우선됩니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.catalog.catalog_product_landing_url':
    '상품ID가 연동된 소재입니다. 해당 소재는 URL을 수정할 수 없습니다.',
  'create_ads.v2.ad_content.catalog.use_price':
    '가격정보 사용 시 텍스트 영역에 가격정보가 포함된 형태로도 소재를 노출시킬 수 있습니다. 미리보기를 참조하여 텍스트 문구 길이를 입력하세요.',
  'create_ads.v2.ad_content.biz_board.notice.talk_landing':
    '채팅방 랜딩 페이지(카카오톡 채널 메시지, 챗봇, 채널웹뷰)를 사용하는 경우 선택하세요.',
  'create_ads.v2.ad_content.video_instream.video_skippable_type':
    '광고를 게재할 카카오TV 동영상 콘텐츠가 10분 이상일 경우에만 `최대 15초 후 SKIP`이 적용됩니다.',
  'create_ads.v2.ad_content.service_content.description':
    '서비스 콘텐츠 유형은 카카오서비스에서 제공하는 콘텐츠로 광고 소재를 등록할 수 있도록 지원합니다.',
  'create_ads.v2.ad_content.service_content.sponsored_board.talk_channel_select':
    '보드를 발행할 수 있는 창작채널 리스트입니다.',
  'create_ads.v2.ad_content.service_content.sponsored_board.board_select':
    '뷰탭 노출 가능 여부 확인이 완료된 공개 보드 리스트입니다.',
  'create_ads.v2.ad_content.message_editor.landing_post_list':
    '채널 관리자센터에서 만든 ‘발행’ 또는 ‘예약발행’ 상태인 소식입니다.',
  'create_ads.v2.ad_content.message_editor.landing_coupon_list':
    '채널 관리자센터에서 만든 쿠폰 중 ‘사용가능’ 상태인 쿠폰입니다.',
  'create_ads.v2.ad_content.message_editor.landing_couponbook_list':
    '채널 관리자센터에서 만든 쿠폰 중 ‘사용가능’ 상태인 쿠폰입니다. 최대 5개까지 저장이 가능하며, 선택한 순서대로 쿠폰 랜딩 화면에 노출됩니다.',
  'create_ads.v2.ad_content.message_editor.landing_adview_list':
    '사용중인 광고계정에서 생성된 애드뷰입니다.',
  'create_ads.v2.ad_content.message_editor.landing_talkbizform_list':
    '사용중인 광고계정에 권한이 추가된 비즈니스폼 중 `작성완료`, `실행` 상태인 비즈니스폼입니다.',
  'create_ads.v2.ad_content.message_editor.url_pc':
    'PC링크는 PC 카카오톡에서 버튼 클릭 시 별도의 URL로 랜딩 시키려는 경우 사용할 수 있습니다.',
  'create_ads.v2.ad_content.message_editor.load_library':
    '메시지 관리에 등록된 메시지입니다.',
  'create_ads.v2.ad_content.message_editor.detail_use_list':
    '이 메시지를 랜딩으로 사용한 소재 및 애드뷰 리스트입니다.',
  'create_ads.v2.ad_content.message_editor.preview':
    '미리보기의 텍스트 크기나 줄바꿈 위치, 이미지 크롭 처리는 디바이스별 설정환경 및 기기의 해상도 등에 따라 다를 수 있습니다.\n' +
    '메시지 발송 전 테스트 발송을 통해 모바일에서 발송된 메시지를 확인해 주시고, 첨부한 링크가 OS 및 기기별로 잘 동작하는지 확인해 주세요. \n' +
    '테스트로 발송된 메시지는 공유 기능을 지원하지 않습니다.',
  'create_ads.v2.ad_content.message_editor.load_channel_msg':
    '채널관리자센터에서 생성한 메시지를 불러옵니다. 채널관리자센터에서 메시지를 작성해 불러오지 않아도, 카카오모먼트에서 메시지 작성과 발송을 한 번에 진행할 수 있습니다.',
  'create_ads.v2.ad_content.message_editor.age_verification':
    '광고그룹에서 연령인증 메시지 옵션을 설정한 경우, 항상 연령인증 메시지로 생성됩니다. 연령인증 메시지는 공유하기 설정이 불가합니다.',
  'create_ads.v2.ad_content.message_editor.ad_option':
    '체크 시 광고성 메시지 가이드에 따라 프로필 이름 앞에 (광고)표시, 메시지 하단에 수신거부 및 수신 동의 철회 방법 안내가 표시됩니다.',
  'create_ads.v2.ad_content.message_editor.carousel_commerce':
    'Intro를 제외한 말풍선 영역을 의미합니다. 소재 라이브러리에서 이미지를 추가하거나 카카오서비스 연동을 통해 캐러셀 소재를 입력할 수 있습니다.',
  'create_ads.v2.ad_content.message_editor.carousel_commerce_share':
    '체크 시 Intro를 제외한 말풍선 버튼2로 공유하기를 노출합니다.',
  'create_ads.v2.ad_content.message_editor.carousel_feed':
    '소재 라이브러리에서 이미지 추가를 통해 캐러셀 소재를 입력할 수 있습니다. 캐러셀은 최소 2개 이상 입력해야 합니다.',
  'create_ads.v2.ad_content.message_editor.carousel_feed_share':
    '체크 시 말풍선 버튼2로 공유하기를 노출합니다. 버튼2에 랜딩을 입력한 상태에서 공유하기를 설정할 수 없습니다.',
  'create_ads.v2.ad_content.message_editor.carousel_more':
    '체크 시 마지막 말풍선 우측에 더보기 버튼을 노출합니다.',
  'create_ads.v2.ad_content.message_editor.carousel_connect_catalog_product':
    '`내 상점 연동`을 통해 가져온 소재는 상품ID와 연동상태를 가집니다. 랜딩 URL은 수정이 불가합니다. 소재-상품 연결은 언제든 해제할 수 있습니다.',
  'create_ads.v2.ad_content.message_editor.carousel_landing_url':
    '캐러셀 이미지나 버튼1 클릭 시 연결하는 URL입니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.',
  'create_ads.v2.ad_content.message_editor.carousel_use_price_discount_price':
    '캐러셀 소재에 입력한 상품의 할인가를 의미합니다. 입력시 가격정보 왼쪽에 노출합니다.',
  'create_ads.v2.ad_content.message_editor.carousel_intro':
    '체크 시 캐러셀 영역 앞에 1개의 말풍선이 추가되며 이미지와 문구, 랜딩을 입력할 수 있습니다.',
  'create_ads.v2.ad_content.message_editor.connect_kakao_tv':
    '나의 카카오TV채널 또는 메시지를 보내려는 카카오톡채널 마스터의 카카오TV채널에 영상을 업로드 합니다. 나의 카카오TV채널을 통하여 업로드된 VOD 및 LIVE 영상을 불러올 수 있으며,비번이 설정된 LIVE 영상 및 성인인증 채널을 통한 영상(VOD, LIVE)는 소재 활용이 불가합니다.',
  'create_ads.v2.ad_content.personalizedmessage.variable_button':
    '메시지 발송 요청 시 소재에 설정한 변수 항목에 해당하는 이미지 또는 동영상 URL 정보를 포함하면, 사용자마다 개인화된 홍보 이미지 또는 홍보 동영상을 첨부하여 발송합니다.',
  'create_ads.v2.ad_content.personalizedmessage.variable_popup':
    '소재에 포함된 변수 항목을 메시지 발송 요청 시 전달한 데이터로 자동 치환하여 발송합니다. 일부 변수값은 복수개 사용 가능하며, 변수 항목과 숫자를 조합하여 등록합니다. ',
  'create_ads.v2.ad_content.personalizedmessage.variable_popup_url':
    '메시지 발송 요청 시 URL 정보를 포함하여 요청하면, 버튼 선택 시 수신 대상별 설정된 랜딩으로 연결합니다.',
  'create_ads.v2.ad_content.dynamic_catalog.product_set':
    '캠페인에서 설정한 카탈로그의 상품 세트 중 선택할 수 있습니다. 선택한 상품 세트에 포함된 상품이 광고 소재로 노출됩니다.',
  'create_ads.v2.ad_content.image_box.landing_url':
    '모바일, PC 디바이스 각각 랜딩 URL을 설정할 수 있습니다. 랜딩 URL에 한글, 특수문자가 포함된 경우 일부 디바이스에서 랜딩이 동작하지 않을 수 있으므로 인코딩한 랜딩 URL 사용을 권장합니다.\n',
  'create_ads.v2.ad_content.library.video_display_filter_possible':
    '해당 소재에 최종 등록 가능한 동영상만 불러올 수 있습니다.',
  'create_ads.v2.ad_content.library.video_display_filter_direct':
    '등록된 전체 동영상에서 필터를 설정하여 동영상를 불러올 수 있습니다.',
  'create_ads.v2.ad_content.common.button_color_self':
    '샵(#)과 함께 6자리의 색상코드를 입력하세요. (예:#FFFFFF)',
  'create_ads.v2.ad_library.common.not_supported_image_size':
    '등록 가이드에 맞지 않은 이미지입니다.',
  'create_ads.v2.ad_library.common.invalid_aspect':
    '등록 비율에 맞지 않은 이미지입니다. 편집하여 사용하세요.',
  'create_ads.v2.ad_library.catalog_product.description': (
    <>
      <strong>연동 방식:</strong>
      <br />
      광고계정에 권한이 부여된 픽셀 & SDK의 콘텐츠/상품조회(ViewContent)
      이벤트로 상품정보를 수집합니다. 그 중 필수정보 유무, 이미지 사이즈 등
      제약조건에 맞는 상품만 제공합니다.
    </>
  ),
  'create_ads.v2.ad_library.image_edit_button':
    '이미지 편집 기능을 사용하여 비율에 맞는 스타일로 편집할 수 있습니다.',
  'create_ads.v2.biz_board_banner_create.preview.show_expandable_hint_asset': (
    <>
      확장 요소를 등록할 경우, 소재 영역에 제공되는 힌트에셋 미리보기 영역
      가이드 예시 입니다. (동영상형 기준)
      <br />
      배너 이미지 제작 시 힌트에셋 영역 위치 참고용으로 확인하세요.
      <br />
      힌트에셋 디자인은 확장 요소 유형별로 다를 수 있으므로 자세한 내용은 소재
      만들기 내 미리보기에서 확인하세요.
    </>
  ),
  'create_ads.v2.biz_board_banner_create.behavior.talk_icon':
    '카카오 서비스를 랜딩으로 설정하려고 할 경우 톡 아이콘을 사용하세요.',
  'create_ads.custom_target.cohort.summary':
    '카카오모먼트에서 집행한 웹/앱 광고, 메시지에 반응(클릭, 전환, 재생, 열람)한 사용자를 리타게팅합니다.',
  'create_ads.custom_target.plus_friend.summary':
    '내 카카오계정과 연동된 카카오톡 채널 프로필을 활용하여, 채널 친구 또는 카카오 로그인 이용자를 타게팅합니다.',
  'create_ads.custom_target.customer_file.summary':
    '광고주가 보유한 오디언스 광고 식별자 (모바일 앱 식별자-ADID)를 업로드하여 타게팅합니다.',
  'create_ads.custom_target.pixel_sdk.summary':
    '카카오 픽셀 & SDK로 수집한 웹/앱 방문, 가입, 설치, 구매 등의 행동을 한 사용자를 리타게팅합니다.',
  'create_ads.custom_target.pixel_sdk.terms':
    '선택한 기간동안 수집된 모수를 타겟팅에 사용합니다. 최소 1~ 최대 180일 중 입력이 가능합니다. 1일은 오늘을 의미하며, 오늘 수집된 가장 최근 이벤트를 모수에 포함합니다. ',
  'create_ads.custom_target.pixel_sdk.population':
    '선택한 기간 내에 해당 이벤트로 수집된 디바이스 수 입니다. 기간 내 모수가 0인 경우,  타겟을 사용할 수 없습니다.',
  'create_ads.custom_target.mat.summary':
    'MAT(Mobile App Tracker) 업체를 통하여 파악한 방문, 가입, 설치, 구매 등의 행동을 한 사용자를 리타게팅합니다.',
  'create_ads.custom_target.mat.terms':
    '과거 선택한 기간동안 수집된 모수를 광고 타게팅에 사용합니다. 각 기간은 중첩되지 않습니다. 1~30일, 31~60일, 61~90일, 91~120일을 의미합니다.',
  'create_ads.custom_target.mat.population':
    '선택한 기간 내에 해당 이벤트로 수집된 디바이스 수 입니다. 기간 내 모수가 0인 경우,  타겟을 사용할 수 없습니다.',
  'create_ads.custom_target.detail.summary':
    '사용자의 특성, 관심사, 카카오서비스/카카오톡 채널에 대한 사용성 등을 토대로 타겟 모수를 제공합니다.',
  'create_ads.custom_target.keyword.summary':
    '다음/카카오 검색 관련 서비스에서 특정 키워드를 검색했던 사용자의 이력을 분석하여 타게팅합니다.',
  'create_ads.custom_target.moment.summary':
    '사용자가 현재 위치할 것으로 추정되는 지역을 준실시간 타게팅 할 수 있습니다.',
  'cmp.text_asset.list':
    '해당 광고계정에서 최근 사용한 소재 정보 리스트입니다. (최대 {size}개 제공)',
  'cmp.image_asset.filter.available':
    '해당 소재에 최종 등록 가능한 이미지만 불러올 수 있습니다.',
  'cmp.image_asset.filter.custom':
    '등록된 전체 이미지에서 필터를 설정하여 이미지를 불러올 수 있습니다.',
  'cmp.editor.create_button':
    '이미지 에디터를 통하여 만든 이미지 확인 및 수정은 `소재 라이브러리 > 이미지 에디터 탭` 에서 할 수 있습니다.',
  'cmp.editor.guide_modify_button':
    '이미지 에디터 기능을 사용하여 기존에 편집한 이미지를 수정할 수 있습니다.',
  'dashboard.common.index.imp':
    '광고가 노출된 횟수를 의미합니다. 카카오 비즈보드, 디스플레이, 동영상 광고 유형(인스트림은 1초)에서 광고 영역의 50% 이상 & 게재지면에 1초 이상 노출되었을 때 1회 집계됩니다.',
  'dashboard.common.index.click':
    '광고가 클릭된 횟수를 의미합니다. 유효한 클릭 즉 광고 효과가 있는 클릭에 한해 집계되며 다양한 환경에서 발생하는 클릭 중 더블클릭 등과 같은 오클릭, 인위적인 반복 클릭 등 무효 클릭들을 제외하여 집계합니다.',
  'dashboard.common.index.ctr': (
    <>
      클릭수를 노출수로 나눈 값을 의미합니다.
      <br />
      (클릭률(%)=클릭수/노출수 X 100)
    </>
  ),
  'dashboard.common.index.vtr': (
    <>
      재생수를 노출수로 나눈 값을 의미합니다.
      <br />
      (재생률(%)=재생수/노출수 X 100)
    </>
  ),
  'dashboard.common.index.cost':
    '지출된 광고비로 부가세는 포함되지 않은 금액을 의미합니다.',
  'dashboard.common.index.reach': '광고를 한번 이상 본 사람 수를 의미합니다.',
  'dashboard.common.index.cost_per_imp': (
    <>
      비용을 노출수로 나눈 값을 의미합니다.
      <br />
      (노출당 비용=비용/노출수)
    </>
  ),
  'dashboard.common.index.cost_per_click': (
    <>
      비용을 클릭수로 나눈 값을 의미합니다.
      <br />
      (클릭당 비용=비용/클릭수)
    </>
  ),
  'dashboard.common.index.cost_per_reach': (
    <>
      비용을 도달수로 나눈 값을 의미합니다.
      <br />
      (도달당 비용=비용/도달수)
    </>
  ),
  'dashboard.common.index.cost_per_video_play_3s': (
    <>
      비용을 동영상 재생수로 나눈 값을 의미합니다
      <br />
      (재생당 비용=비용/재생수)
    </>
  ),
  'dashboard.common.index.msg_send':
    '메시지가 사용자에게 발송된 누적수를 의미합니다.',
  'dashboard.common.index.msg_send_fail':
    '메시지가 사용자에게 발송 실패된 누적수를 의미합니다.',
  'dashboard.common.index.msg_open':
    '채팅방에 진입하여 내 메시지를 확인한 사용자 수를 의미합니다.',
  'dashboard.common.index.msg_click':
    '메시지에서 발생한 전체 클릭수를 의미합니다.',
  'dashboard.common.index.msg_play':
    '전체 메시지 소재 유형에 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.cost_per_msg_send': (
    <>
      비용을 발송수로 나눈 값을 의미합니다.
      <br />
      (발송당 비용=비용/발송수)
    </>
  ),
  'dashboard.common.index.cost_per_msg_open': (
    <>
      비용을 열람수로 나눈 값을 의미합니다.
      <br />
      (열람당 비용=비용/열람수)
    </>
  ),
  'dashboard.common.index.cost_per_msg_click': (
    <>
      비용을 전체 클릭수로 나눈 값을 의미합니다.
      <br />
      (전체 클릭당 비용=비용/전체 클릭수)
    </>
  ),
  'dashboard.common.index.cost_per_msg_play': (
    <>
      비용을 메시지 전체 재생수로 나눈 값을 의미합니다.
      <br />
      (재생당 비용=비용/전체 재생수)
    </>
  ),
  'dashboard.common.index.msg_open_rate': (
    <>
      열람수를 발송수로 나눈 값을 의미합니다.
      <br />
      (열람률=열람수/ 발송수 X 100)
    </>
  ),
  'dashboard.common.index.msg_click_rate': (
    <>
      메시지에서 발생한 전체 클릭수를 열람수로 나눈 값을 의미합니다.
      <br />
      (메시지 클릭률=전체 클릭수/열람수 X 100)
    </>
  ),
  'dashboard.common.index.msg_click_share':
    '기본 텍스트형 메시지의 공유 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_media':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형 메시지의 첨부된 이미지나 동영상 또는 캐러셀형 Intro에 첨부된 이미지가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_text':
    '와이드 이미지형 메시지의 텍스트가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_title':
    '와이드 리스트형 메시지의 타이틀이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_list1':
    '와이드 리스트형 메시지의 리스트1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_list2':
    '와이드 리스트형 메시지의 리스트2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_list3':
    '와이드 리스트형 메시지의 리스트3 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_list4':
    '와이드 리스트형 메시지의 리스트4 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_button1':
    '기본 텍스트형, 와이드형 메세지의 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_button2':
    '기본 텍스트형, 와이드형 메세지의 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 첫 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 두 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button3':
    '캐러셀피드형, 캐러셀커머스형 메시지의 세 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button4':
    '캐러셀피드형, 캐러셀커머스형 메시지의 네 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button5':
    '캐러셀피드형, 캐러셀커머스형 메시지의 다섯 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_button6':
    '캐러셀피드형, 캐러셀커머스형 메시지의 여섯 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 첫 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 두 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share3':
    '캐러셀피드형, 캐러셀커머스형 메시지의 세 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share4':
    '캐러셀피드형, 캐러셀커머스형 메시지의 네 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share5':
    '캐러셀피드형, 캐러셀커머스형 메시지의 다섯 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_share6':
    '캐러셀피드형, 캐러셀커머스형 메시지의 여섯 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel_more':
    '캐러셀피드형, 캐러셀커머스형 메시지의 더보기 버튼 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_coupon':
    '기본 텍스트형, 와이드 이미지형, 와이드 리스트형 메시지의 첨부된 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_others':
    '메시지의 가이드 영역, 카카오톡 채널 홈버튼 등 정의되지 않은 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_start':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_3s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_5s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_10s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_15s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_30s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_60s':
    '기본 텍스트형, 와이드 이미지형 및 프리미엄동영상형에 추가된 동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_start':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_start':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_start':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_start':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_3s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_5s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_10s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_15s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_30s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list1_60s':
    '와이드 리스트형 메시지의 리스트1 영역에 추가된 동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_3s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_5s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_10s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_15s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_30s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list2_60s':
    '와이드 리스트형 메시지의 리스트2 영역에 추가된 동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_3s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_5s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_10s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_15s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_30s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list3_60s':
    '와이드 리스트형 메시지의 리스트3 영역에 추가된 동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_3s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_5s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_10s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_15s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_30s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_play_list4_60s':
    '와이드 리스트형 메시지의 리스트4 영역에 추가된 동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel1_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 첫 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel1_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 첫 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel2_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 두 번째 말풍선 이미지와 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel2_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 두 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel3_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 세 번째 말풍선 버튼1 이미지와 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel3_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 세 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel4_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 네 번째 말풍선 버튼1 이미지와 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel4_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 네 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel5_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 다섯 번째 말풍선 버튼1 이미지와 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel5_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 다섯 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel6_button1':
    '캐러셀피드형, 캐러셀커머스형 메시지의 여섯 번째 말풍선 버튼1 이미지와 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel6_button2':
    '캐러셀피드형, 캐러셀커머스형 메시지의 여섯 번째 말풍선 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel1_coupon':
    '첫번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel2_coupon':
    '두번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel3_coupon':
    '세번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel4_coupon':
    '네번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel5_coupon':
    '다섯번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.msg_click_carousel6_coupon':
    '여섯번째 캐러셀의 쿠폰 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.adv_click_button1':
    '모든 유형 애드뷰의 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.adv_click_button2':
    '모든 유형 애드뷰의 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.adv_click_button_share':
    '모든 유형 애드뷰의 상단 공유 버튼 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th1_view':
    '애드뷰 모든 유형의 첫번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th1_video_play_auto':
    '동영상형, 캐러셀형, 스크롤형의 첫번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th1_video_play_touch':
    '동영상형, 캐러셀형, 스크롤형의 첫번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th1_video_play_3s':
    '동영상형, 캐러셀형, 스크롤형의 첫번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th2_view':
    '캐러셀형, 스크롤형의 두번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th2_video_play_auto':
    '캐러셀형, 스크롤형의 두번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th2_video_play_touch':
    '캐러셀형, 스크롤형의 두번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th2_video_play_3s':
    '캐러셀형, 스크롤형의 두번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th3_view':
    '캐러셀형, 스크롤형의 세번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th3_video_play_auto':
    '캐러셀형, 스크롤형의 세번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th3_video_play_touch':
    '캐러셀형, 스크롤형의 세번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th3_video_play_3s':
    '캐러셀형, 스크롤형의 세번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th4_view':
    '캐러셀형, 스크롤형의 네번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th4_video_play_auto':
    '캐러셀형, 스크롤형의 네번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th4_video_play_touch':
    '캐러셀형, 스크롤형의 네번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th4_video_play_3s':
    '캐러셀형, 스크롤형의 네번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th5_view':
    '캐러셀형, 스크롤형의 다섯번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th5_video_play_auto':
    '캐러셀형, 스크롤형의 다섯번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th5_video_play_touch':
    '캐러셀형, 스크롤형의 다섯번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th5_video_play_3s':
    '캐러셀형, 스크롤형의 다섯번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th6_view':
    '스크롤형의 여섯번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th6_video_play_auto':
    '스크롤형의 여섯번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th6_video_play_touch':
    '스크롤형의 여섯번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th6_video_play_3s':
    '스크롤형의 여섯번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th7_view':
    '스크롤형의 일곱번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th7_video_play_auto':
    '스크롤형의 일곱번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th7_video_play_touch':
    '스크롤형의 일곱번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th7_video_play_3s':
    '스크롤형의 일곱번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th8_view':
    '스크롤형의 여덟번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th8_video_play_auto':
    '스크롤형의 여덟번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th8_video_play_touch':
    '스크롤형의 여덟번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th8_video_play_3s':
    '스크롤형의 여덟번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th9_view':
    '스크롤형의 아홉번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th9_video_play_auto':
    '스크롤형의 아홉번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th9_video_play_touch':
    '스크롤형의 아홉번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th9_video_play_3s':
    '스크롤형의 아홉번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th10_view':
    '스크롤형의 열번째 순서에 첨부된 이미지나 동영상이 노출된 횟수를 의미합니다. ',
  'dashboard.common.index.adv_th10_video_play_auto':
    '스크롤형의 열번째 순서에 첨부된 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th10_video_play_touch':
    '스크롤형의 열번째 순서에 첨부된 동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.adv_th10_video_play_3s':
    '스크롤형의 열번째 순서에 첨부된 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_send':
    '랜딩용 메시지가 사용자에게 발송된 실시간 누적수를 의미합니다.',
  'dashboard.common.index.landing_msg_open':
    '채팅방에 진입하여 내 랜딩용 메시지를 확인한 사용자 수를 의미합니다.',
  'dashboard.common.index.landing_msg_click':
    '랜딩용 메시지에서 발생한 전체 클릭수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_share':
    '랜딩용 기본 텍스트형 메시지의 공유 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_media':
    '랜딩용 와이드이미지형 메시지에 첨부된 이미지나 동영상이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_text':
    '랜딩용 와이드이미지형 메시지에서 텍스트가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_title':
    '랜딩용 와이드리스트형에서 타이틀이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_list1':
    '랜딩용 와이드리스트형에서 리스트1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_list2':
    '랜딩용 와이드리스트형에서 리스트2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_list3':
    '랜딩용 와이드리스트형에서 리스트3 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_list4':
    '랜딩용 와이드리스트형에서 리스트4 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_button1':
    '랜딩용 와이드이미지나 와이드리스트형에서 버튼1 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_button2':
    '랜딩용 와이드이미지나 와이드리스트형에서 버튼2 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.landing_msg_click_others':
    '랜딩용 메시지의 가이드 영역, 카카오톡 채널 홈버튼 등 정의되지 않은 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.conv_cmpt_reg_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.conv_cmpt_reg_7d':
    '광고 클릭 후, 7일 이내에 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_cmpt_reg_1d':
    '비용을 회원가입 (1일)로 나눈 값을 의미합니다. (회원가입당 비용(1일) = 비용/회원가입 (1일))',
  'dashboard.common.index.cost_per_conv_cmpt_reg_7d':
    '비용을 회원가입 (7일)로 나눈 값을 의미합니다. (회원가입당 비용(7일) = 비용/회원가입 (7일))',
  'dashboard.common.index.conv_cmpt_reg_1d_rate':
    '회원가입 (1일)을 클릭수로 나눈 값을 의미합니다. (회원가입률 (1일) = 회원가입 (1일)/클릭수 x 100)',
  'dashboard.common.index.conv_cmpt_reg_7d_rate':
    '회원가입 (7일)을 클릭수로 나눈 값을 의미합니다. (회원가입률 (7일) = 회원가입 (7일)/클릭수 x 100)',
  'dashboard.common.index.conv_view_cart_1d':
    '광고 클릭 후, 1일(24시간) 이내 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.conv_view_cart_7d':
    '광고 클릭 후, 7일 이내에 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_view_cart_1d':
    '비용을 장바구니 열람 (1일)로 나눈 값을 의미합니다. (장바구니 열람당 비용 (1일) = 비용/ 장바구니 열람 (1일))',
  'dashboard.common.index.cost_per_conv_view_cart_7d':
    '비용을 장바구니 열람 (7일)로 나눈 값을 의미합니다. (장바구니 열람당 비용 (7일) = 비용/ 장바구니 열람 (7일))',
  'dashboard.common.index.conv_view_cart_1d_rate':
    '장바구니 열람 (1일)을 클릭수로 나눈 값을 의미합니다. (장바구니 열람률 (1일) = 장바구니 열람 (1일)/ 클릭수 x 100)',
  'dashboard.common.index.conv_view_cart_7d_rate':
    '장바구니 열람 (7일)을 클릭수로 나눈 값을 의미합니다. (장바구니 열람률 (7일) = 장바구니 열람 (7일)/ 클릭수 x 100)',
  'dashboard.common.index.conv_purchase_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.conv_purchase_7d':
    '광고 클릭 후, 7일 이내에 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_purchase_1d':
    '비용을 구매 (1일)로 나눈 값을 의미합니다. (구매당 비용 (1일) = 비용/ 구매 (1일))',
  'dashboard.common.index.cost_per_conv_purchase_7d':
    '비용을 구매 (7일)로 나눈 값을 의미합니다. (구매당 비용 (7일) = 비용/ 구매 (7일))',
  'dashboard.common.index.conv_purchase_1d_rate':
    '구매 (1일)을 클릭수로 나눈 값을 의미합니다. (구매율 (1일) = 구매 (1일) / 클릭수 x 100)',
  'dashboard.common.index.conv_purchase_7d_rate':
    '구매 (7일)을 클릭수로 나눈 값을 의미합니다. (구매율 (7일) = 구매 (7일) / 클릭수 x 100)',
  'dashboard.common.index.conv_purchase_p_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 구매비용(앱 내 구매비용 포함)을 의미합니다.',
  'dashboard.common.index.conv_purchase_p_7d':
    '광고 클릭 후, 7일 이내에 발생한 구매비용(앱 내 구매비용 포함)을 의미합니다.',
  'dashboard.common.index.conv_purchase_p_per_cost_1d': (
    <>
      구매금액 (1일)을 비용으로 나눈 값을 의미합니다.
      <br />
      (ROAS (1일) = 구매금액 (1일) / 비용 x 100)
    </>
  ),
  'dashboard.common.index.conv_purchase_p_per_cost_7d': (
    <>
      구매금액 (7일)을 비용으로 나눈 값을 의미합니다.
      <br />
      (ROAS (7일) = 구매금액 (7일) / 비용 x 100)
    </>
  ),
  'dashboard.common.index.conv_estimated_purchase_p_per_cost_1d': (
    <>
      추정 구매금액 (1일)을 비용으로 나눈 값을 의미합니다. 추정 구매금액은
      수집된 구매금액과, 구매금액이 누락된 경우 최근 7일동안 수집된 구매금액을
      통계적으로 추산한 값을 기반으로 합니다.
      <br />
      (추정 ROAS (1일) = 추정 구매금액 (1일) / 비용 x 100)
    </>
  ),
  'dashboard.common.index.conv_estimated_purchase_p_per_cost_7d': (
    <>
      추정 구매금액 (7일)을 비용으로 나눈 값을 의미합니다. 추정 구매금액은
      수집된 구매금액과, 구매금액이 누락된 경우 최근 7일동안 수집된 구매금액을
      통계적으로 추산한 값을 기반으로 합니다.
      <br />
      (추정 ROAS (7일) = 추정 구매금액 (7일) / 비용 x 100)
    </>
  ),
  'dashboard.common.index.conv_participation_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.conv_participation_7d':
    '광고 클릭 후, 7일 이내에 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_participation_1d':
    '비용을 잠재고객 (1일)로 나눈 값을 의미합니다. (잠재고객당 비용 (1일) = 비용/ 잠재고객 (1일))',
  'dashboard.common.index.cost_per_conv_participation_7d':
    '비용을 잠재고객 (7일)로 나눈 값을 의미합니다. (잠재고객당 비용 (7일) = 비용/ 잠재고객 (7일))',
  'dashboard.common.index.conv_participation_1d_rate':
    '잠재고객 (1일)을 클릭수로 나눈 값을 의미합니다. (잠재고객률 (1일) = 잠재고객 (1일) / 클릭수 x 100)',
  'dashboard.common.index.conv_participation_7d_rate':
    '잠재고객 (7일)을 클릭수로 나눈 값을 의미합니다. (잠재고객률 (7일) = 잠재고객 (7일) / 클릭수 x 100)',
  'dashboard.common.index.conv_signup_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.conv_signup_7d':
    '광고 클릭 후, 7일 이내에 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_signup_1d':
    '비용을 서비스신청 (1일)로 나눈 값을 의미합니다. (서비스신청당 비용 (1일) = 비용/ 서비스신청 (1일))',
  'dashboard.common.index.cost_per_conv_signup_7d':
    '비용을 서비스신청 (7일)로 나눈 값을 의미합니다. (서비스신청당 비용 (7일) = 비용/ 서비스신청 (7일))',
  'dashboard.common.index.conv_signup_1d_rate':
    '서비스신청 (1일)을 클릭수로 나눈 값을 의미합니다. (서비스신청률 (1일) = 서비스신청 (1일) / 클릭수 x 100)',
  'dashboard.common.index.conv_signup_7d_rate':
    '서비스신청 (7일)을 클릭수로 나눈 값을 의미합니다. (서비스신청률 (7일) = 서비스신청 (7일) / 클릭수 x 100)',
  'dashboard.common.index.conv_app_install_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 앱 설치수를 의미합니다.',
  'dashboard.common.index.conv_app_install_7d':
    '광고 클릭 후, 7일 이내에 발생한 앱 설치수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_app_install_1d':
    '비용을 앱설치 (1일)로 나눈 값을 의미합니다. (앱설치당 비용 (1일) = 비용/ 앱설치 (1일))',
  'dashboard.common.index.cost_per_conv_app_install_7d':
    '비용을 앱설치 (7일)로 나눈 값을 의미합니다. (앱설치당 비용 (7일) = 비용/ 앱설치 (7일))',
  'dashboard.common.index.conv_app_install_1d_rate':
    '앱설치 (1일)을 클릭수로 나눈 값을 의미합니다. (앱설치율 (1일) = 앱설치 (1일) / 클릭수 x 100)',
  'dashboard.common.index.conv_app_install_7d_rate':
    '앱설치 (7일)을 클릭수로 나눈 값을 의미합니다. (앱설치율 (7일) = 앱설치 (7일) / 클릭수 x 100)',
  'dashboard.common.index.conv_add_to_wishlist_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 관심상품추가수를 의미합니다. ',
  'dashboard.common.index.cost_per_conv_add_to_wishlist_1d': (
    <>
      비용을 관심상품추가 (1일)로 나눈 값을 의미합니다.
      <br />
      (관심상품추가당 비용 (1일) = 비용/ 관심상품추가 (1일))
    </>
  ),
  'dashboard.common.index.conv_add_to_wishlist_1d_rate': (
    <>
      관심상품추가 (1일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (관심상품추가율 (1일) = 관심상품추가 (1일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.conv_add_to_wishlist_7d':
    '광고 클릭 후, 7일 이내 발생한 관심상품추가수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_add_to_wishlist_7d': (
    <>
      비용을 관심상품추가 (7일)로 나눈 값을 의미합니다.
      <br />
      (관심상품추가당 비용 (7일) = 비용/ 관심상품추가 (7일))
    </>
  ),
  'dashboard.common.index.conv_add_to_wishlist_7d_rate': (
    <>
      관심상품추가 (7일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (관심상품추가율 (7일) = 관심상품추가 (7일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.conv_add_to_cart_1d':
    '광고 클릭 후, 1일(24시간) 이내 발생한 장바구니추가수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_add_to_cart_1d': (
    <>
      비용을 장바구니추가 (1일)로 나눈 값을 의미합니다.
      <br />
      (장바구니추가당 비용 (1일) = 비용/ 장바구니추가 (1일))
    </>
  ),
  'dashboard.common.index.conv_add_to_cart_1d_rate': (
    <>
      장바구니추가 (1일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (장바구니추가율 (1일) = 장바구니추가 (1일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.conv_add_to_cart_7d':
    '광고 클릭 후, 7일 이내 발생한 장바구니추가수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_add_to_cart_7d': (
    <>
      비용을 장바구니추가 (7일)로 나눈 값을 의미합니다.
      <br />
      (장바구니추가당 비용 (7일) = 비용/ 장바구니추가 (7일))
    </>
  ),
  'dashboard.common.index.conv_add_to_cart_7d_rate': (
    <>
      장바구니추가 (7일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (장바구니추가율 (7일) = 장바구니추가 (7일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.conv_kf_pf_add_1d':
    '광고 클릭 후, 1일(24시간) 이내 카카오톡 채널을 추가한 친구수를 의미합니다.',
  'dashboard.common.index.conv_kf_pf_add_7d':
    '광고 클릭 후, 7일 이내 카카오톡 채널을 추가한 친구수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_kf_pf_add_1d': (
    <>
      비용을 친구 추가 수 (1일)로 나눈 값을 의미합니다.
      <br />
      (친구 추가 수당 비용 (1일) = 비용/ 친구 추가 수 (1일))
    </>
  ),
  'dashboard.common.index.cost_per_conv_kf_pf_add_7d': (
    <>
      비용을 친구 추가 수 (7일)로 나눈 값을 의미합니다.
      <br />
      (친구 추가 수당 비용 (7일) = 비용/ 친구 추가 수 (7일))
    </>
  ),
  'dashboard.common.index.conv_kf_pf_add_1d_rate': (
    <>
      친구 추가 수 (1일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (친구 추가율 (1일) = 친구 추가 수 (1일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.conv_kf_pf_add_7d_rate': (
    <>
      친구 추가 수 (7일)을 클릭수로 나눈 값을 의미합니다.
      <br />
      (친구 추가율 (7일) = 친구 추가 수 (7일) / 클릭수 X 100)
    </>
  ),
  'dashboard.common.index.video_play_auto':
    '동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_touch':
    '동영상이 터치 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_unmute':
    '스피커 버튼을 선택해서 사운드를 활성화한 횟수를 의미합니다.',
  'dashboard.common.index.video_play_3s':
    '광고가 3초 이상 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_5s':
    '동영상이 5초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_10s':
    '동영상이 10초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_15s':
    '동영상이 15초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_30s':
    '동영상이 30초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_60s':
    '동영상이 60초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_25p':
    '동영상이 25% 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_50p':
    '동영상이 50% 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_75p':
    '동영상이 75% 재생된 횟수를 의미합니다.',
  'dashboard.common.index.video_play_100p':
    '동영상이 100% 재생된 횟수를 의미합니다.',
  'dashboard.common.index.slide1_click':
    '카탈로그형 소재의 첫번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide2_click':
    '카탈로그형 소재의 두번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide3_click':
    '카탈로그형 소재의 세번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide4_click':
    '카탈로그형 소재의 네번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide5_click':
    '카탈로그형 소재의 다섯번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide6_click':
    '카탈로그형 소재의 여섯번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide7_click':
    '카탈로그형 소재의 일곱번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide8_click':
    '카탈로그형 소재의 여덟번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide9_click':
    '카탈로그형 소재의 아홉번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide10_click':
    '카탈로그형 소재의 열번째 슬라이드가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.slide_others_click':
    '카탈로그형 소재의 프로필 이미지 등 슬라이드 이외 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.conv_talk_reg_1d':
    '클릭 후 24시간 이내 픽셀을 통해 연결된 톡 서비스에서의 가입완료 수 (카카오 싱크 가입을 의미)',
  'dashboard.common.index.conv_talk_purchase_1d':
    '클릭 후 24시간 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 구매 건수 (톡스토어, 선물하기 등의 구매를 의미)',
  'dashboard.common.index.conv_talk_purchase_p_1d':
    '클릭 후 24시간 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 구매금액/ 구매 건별로 수집 (톡스토어, 선물하기 등의 구매금액을 의미)',
  'dashboard.common.index.conv_talk_part_1d':
    '클릭 후 24시간 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 이벤트 참여 건수 (톡비즈니스폼 설문 참여를 의미)',
  'dashboard.common.index.conv_talk_reg_7d':
    '클릭 후 7일 이내 픽셀을 통해 연결된 톡 서비스에서의 가입완료 수 ( 카카오 싱크 가입을 의미)',
  'dashboard.common.index.conv_talk_purchase_7d':
    '클릭 후 7일 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 구매 건수 (톡스토어, 선물하기 등의 구매를 의미)',
  'dashboard.common.index.conv_talk_purchase_p_7d':
    '클릭 후 7일 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 구매금액/ 구매 건별로 수집 (톡스토어, 선물하기 등의 구매금액을 의미)',
  'dashboard.common.index.conv_talk_part_7d':
    '클릭 후 7일 이내 픽셀을 통해 연결된 톡 서비스에서 유입된 이벤트 참여 건수 (톡비즈니스폼 설문 참여를 의미)',
  'dashboard.common.index.expandable_imp':
    '이미지형, 동영상형, 멀티형의 확장영역이 노출된 횟수를 의미합니다.',
  'dashboard.common.index.motion_video_play_auto':
    '모션 요소 동영상이 자동으로 재생된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_video_play_auto':
    '동영상형의 동영상이 자동 재생된 횟수를 의미합니다.',
  'dashboard.common.index.motion_video_play_3s':
    '모션 요소 동영상이 3초 재생된 횟수를 의미합니다. ',
  'dashboard.common.index.expandable_video_play_3s':
    '동영상형의 동영상이 3초 재생된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_image1':
    '이미지형의 이미지가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_cta':
    '이미지형, 동영상형의 확장영역 내 행동유도버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_viewable_goods_1':
    '멀티형의 확장영역 내 첫 번째 요소가 노출된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_goods_1':
    '멀티형의 확장영역 내 첫 번째 요소가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_viewable_goods_2':
    '멀티형의 확장영역 내 두 번째 요소가 노출된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_goods_2':
    '멀티형의 확장영역 내 두 번째 요소가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_viewable_goods_3':
    '멀티형의 확장영역 내 세 번째 요소가 노출된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_goods_3':
    '멀티형의 확장영역 내 세 번째 요소가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_viewable_goods_4':
    '멀티형의 확장영역 내 네 번째 요소가 노출된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_goods_4':
    '멀티형의 확장영역 내 네 번째 요소가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_viewable_goods_5':
    '멀티형의 확장영역 내 다섯 번째 요소가 노출된 횟수를 의미합니다.',
  'dashboard.common.index.expandable_click_goods_5':
    '멀티형의 확장영역 내 다섯 번째 요소가 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.channel_home_click':
    '스폰서드 보드 모든 유형의 채널 홈 영역이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.channel_add_click':
    '스폰서드 보드 모든 유형의 채널 추가 버튼이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article1_click':
    '스폰서드 보드 모든 유형의 첫번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article2_click':
    '스폰서드 보드 모든 유형의 두번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article3_click':
    '스폰서드 보드 모든 유형의 세번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article4_click':
    '스폰서드 보드 모든 유형의 네번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article5_click':
    '스폰서드 보드 모든 유형의 다섯번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article6_click':
    '스폰서드 보드 모든 유형의 여섯번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article7_click':
    '스폰서드 보드 모든 유형의 일곱번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article8_click':
    '스폰서드 보드 모든 유형의 여덟번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article9_click':
    '스폰서드 보드 모든 유형의 아홉번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.article10_click':
    '스폰서드 보드 모든 유형의 열번째 순서 아티클이 클릭된 횟수를 의미합니다.',
  'dashboard.common.index.conv_cmpt_reg':
    '광고 클릭 후, 픽셀 & SDK로 수집된 회원가입수의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_view_cart':
    '광고 클릭 후, 픽셀 & SDK로 수집된 장바구니를 확인해본 횟수의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_purchase':
    '광고 클릭 후, 픽셀 & SDK로 수집된 구매건수 (앱 내 구매건수 포함)의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_purchase_p':
    '광고 클릭 후, 픽셀 & SDK로 수집된 구매비용 (앱 내 구매비용 포함)의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_participation':
    '광고 클릭 후, 픽셀 & SDK로 수집된 잠재고객수의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_sign_up':
    '광고 클릭 후, 픽셀 & SDK로 수집된 서비스신청수의 직/간접 총합을 의미합니다.',
  'dashboard.common.index.conv_sdk_app_install':
    '광고 클릭 후, SDK로 수집된 앱 설치수의 총합을 의미합니다.',
  'dashboard.common.index.conv_px_cmpt_reg_dir':
    '광고 클릭 후, 24시간 이내 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.conv_px_cmpt_reg_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.conv_px_view_cart_dir':
    '광고 클릭 후, 24시간 이내 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.conv_px_view_cart_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.conv_px_purchase_dir':
    '광고 클릭 후, 24시간 이내 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.conv_px_purchase_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.conv_px_purchase_p_dir':
    '광고 클릭 후, 24시간 이내 발생한 구매비용을 의미합니다.',
  'dashboard.common.index.conv_px_purchase_p_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 구매비용을 의미합니다.',
  'dashboard.common.index.conv_px_participation_dir':
    '광고 클릭 후, 24시간 이내 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.conv_px_participation_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.conv_px_sign_up_dir':
    '광고 클릭 후, 24시간 이내 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.conv_px_sign_up_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.conv_sdk_app_install_dir':
    '광고 클릭 후, 24시간 이내 발생한 앱 설치수를 의미합니다.',
  'dashboard.common.index.conv_sdk_app_install_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 앱 설치수를 의미합니다.',
  'dashboard.common.index.conv_sdk_cmpt_reg_dir':
    '광고 클릭 후, 24시간 이내 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.conv_sdk_cmpt_reg_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 회원가입수를 의미합니다.',
  'dashboard.common.index.conv_sdk_view_cart_dir':
    '광고 클릭 후, 24시간 이내 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.conv_sdk_view_cart_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 장바구니를 확인해본 횟수를 의미합니다.',
  'dashboard.common.index.conv_sdk_purchase_dir':
    '광고 클릭 후, 24시간 이내 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.conv_sdk_purchase_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 구매건수를 의미합니다.',
  'dashboard.common.index.conv_sdk_purchase_p_dir':
    '광고 클릭 후, 24시간 이내 발생한 구매비용을 의미합니다.',
  'dashboard.common.index.conv_sdk_purchase_p_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 구매비용을 의미합니다.',
  'dashboard.common.index.conv_sdk_iap_dir':
    '광고 클릭 후, 24시간 이내 발생한 앱 내 구매건수를 의미합니다.',
  'dashboard.common.index.conv_sdk_iap_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 앱 내 구매건수를 의미합니다.',
  'dashboard.common.index.conv_sdk_iap_p_dir':
    '광고 클릭 후, 24시간 이내 발생한 앱 내 구매비용을 의미합니다.',
  'dashboard.common.index.conv_sdk_iap_p_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 앱 내 구매비용을 의미합니다.',
  'dashboard.common.index.conv_sdk_participation_dir':
    '광고 클릭 후, 24시간 이내 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.conv_sdk_participation_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 잠재고객수를 의미합니다.',
  'dashboard.common.index.conv_sdk_sign_up_dir':
    '광고 클릭 후, 24시간 이내 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.conv_sdk_sign_up_indir':
    '광고 클릭 24시간 이후 ~ 30일 이내에 발생한 서비스신청수를 의미합니다.',
  'dashboard.common.index.conv_kf_pf_add':
    '카카오톡 채널을 추가한 친구수를 의미합니다.',
  'dashboard.common.index.cost_per_conv_kf_pf_add': (
    <>
      비용을 친구 추가수로 나눈 값을 의미합니다.
      <br />
      (친구 추가당 비용=비용/친구 추가수)
    </>
  ),
  'dashboard.common.index.trigger_viewable':
    '업데이트 프로필 영역 소재가 노출된 횟수를 의미합니다.',
  'dashboard.common.filter.status':
    '선택한 상태의 광고만 테이블에서 제공합니다. 단, 현재 광고단위의 목표에 따라 제공하지 않는 상태가 있습니다. 상세한 광고단위별 제공 상태는 가이드를 참고하세요.',
  'dashboard.table.campaign_status':
    '캠페인의 게재와 관련된 현재 상태를 의미합니다. 상위 광고계정의 상태를 고려하여 현재 캠페인의 최종 운영 상태를 표기합니다.',
  'dashboard.table.campaign_budget':
    '캠페인 하위 모든 광고그룹에서 설정한 기간 예산의 합 또는 캠페인에 설정된 일 예산을 의미하며 VAT는 포함된 금액입니다. 일 예산의 경우, 하위 광고그룹 내 과금방식 및 입찰금액에 따라 일 예산을 초과하는 과금이 발생할 수 있습니다.',
  'dashboard.table.campaign_schedule':
    '캠페인 하위 광고그룹(삭제 제외)의 가장 빠른 시작일부터 가장 늦은 종료일을 의미합니다.',
  'dashboard.table.group_status':
    '광고그룹의 게재와 관련된 현재 상태를 의미합니다. 상위 광고계정, 캠페인의 상태를 고려하여 현재 광고그룹의 최종 운영 상태를 표기합니다.',
  'dashboard.table.group_schedule': '광고그룹의 시작일과 종료일을 의미합니다.',
  'dashboard.table.group_total_budget':
    '해당 광고그룹에서 설정한 기간 예산으로, 설정한 집행기간 동안 소진할 예산을 의미하며 VAT는 포함된 금액입니다. 일 예산의 경우, 광고그룹에 설정한 일 예산 및 입찰금액에 따라 초과 과금이 발생할 수 있습니다.',
  'dashboard.table.content_status':
    '소재의 게재와 관련된 현재 상태를 의미합니다. 상위 광고계정, 캠페인, 광고그룹의 상태 및 소재의 심사 상태를 고려하여 현재 소재의 최종 운영 상태를 표기합니다.',
  'dashboard.table.content_status_screening':
    '소재의 심사와 관련된 현재 상태를 의미합니다.',
  'dashboard.table.content_time':
    '소재의 노출 시작일시와 종료일시를 의미합니다. 수정 시 실제 노출 반영에 수십분이 소요될 수 있습니다.',
  'dashboard.table.group_optimization_status': (
    <>
      최적화 상태는 최근 30일간 픽셀 & SDK로 수집된 이벤트 모수를 기반으로
      머신러닝을 작동하여, 학습된 이벤트 모수와 광고그룹의 전환수에 따라 최적화
      상태가 결정됩니다.
      <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트 모수와
      최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
    </>
  ),
  'dashboard.status.ad_account.off':
    '광고계정 상태가 OFF로 변경되어, 광고 노출이 일시 중지되었습니다.',
  'dashboard.status.ad_account.stopped':
    '광고계정이 관리자 정지되어 광고를 운영할 수 없습니다.',
  'dashboard.status.ad_account.out_of_balance':
    '광고계정 잔액이 부족하여 광고를 운영할 수 없습니다.',
  'dashboard.status.campaign.ready': '캠페인 집행 기간에 해당하지 않습니다.',
  'dashboard.status.campaign.finished': '캠페인 집행 기간에 해당하지 않습니다.',
  'dashboard.status.campaign.off':
    '캠페인 상태가 OFF로 변경되어, 광고 노출이 일시 중지되었습니다.',
  'dashboard.status.campaign.exceed_daily_budget':
    '캠페인 일 예산 초과로 광고노출이 일시 중지되었습니다.',
  'dashboard.status.campaign.adaccount_unavailable':
    '상위 광고 단위 상태로 인해 캠페인을 운영할 수 없습니다. ',
  'dashboard.status.campaign.system_config_admin_stop':
    '캠페인이 관리자 정지되어 광고를 운영할 수 없습니다.',
  'dashboard.status.campaign.system_config_external_service_stop':
    '캠페인과 연결된 서비스의 문제로 운영할 수 없습니다.',
  'dashboard.status.ad_group.ready': '광고그룹 집행 기간에 해당하지 않습니다.',
  'dashboard.status.ad_group.finished':
    '광고그룹 집행 기간에 해당하지 않습니다.',
  'dashboard.status.ad_group.off':
    '광고그룹 상태가 OFF로 변경되어, 광고 노출이 일시 중지되었습니다.',
  'dashboard.status.ad_group.exceed_daily_budget':
    '광고그룹 일 예산 초과로 광고노출이 일시 중지되었습니다.',
  'dashboard.status.ad_group.message_pause':
    '운영 중이던 광고그룹이 일시 중지(발송 중지) 되었습니다.',
  'dashboard.status.ad_group.no_available_creative':
    '광고그룹 하위에 운영가능 상태의 소재가 없습니다.',
  'dashboard.status.ad_group.canceled':
    '광고그룹이 계약해지되어 더 이상 운영할 수 없습니다.',
  'dashboard.status.ad_group.campaign_unavailable':
    '상위 광고 단위 상태로 인해 광고그룹을 운영할 수 없습니다.',
  'dashboard.status.ad_group.system_config_admin_stop':
    '광고그룹이 관리자 정지되어 광고를 운영할 수 없습니다.',
  'dashboard.status.ad_group.system_config_external_service_stop':
    '광고그룹과 연결된 서비스의 문제로 운영할 수 없습니다.',
  'dashboard.status.creative.unapproved': '심사가 승인되지 않았습니다.',
  'dashboard.status.creative.invalid_date':
    '소재 집행 기간에 해당하지 않습니다.',
  'dashboard.status.creative.monitoring_rejected':
    '모니터링에 의해 소재가 보류되었습니다.',
  'dashboard.status.creative.off':
    '소재 상태가 OFF로 변경되어, 광고 노출이 일시 중지되었습니다.',
  'dashboard.status.creative.adgroup_unavailable':
    '상위 광고 단위 상태로 인해 소재를 운영할 수 없습니다.',
  'dashboard.status.creative.system_config_void':
    '소재에 연동된 콘텐츠의 상태가 변경되어 운영이 불가합니다.',
  'dashboard.status.creative.system_config_admin_stop':
    '소재가 관리자 정지되어 광고를 운영할 수 없습니다.',
  'dashboard.status.creative.system_config_external_service_stop':
    '소재와 연결된 서비스의 문제로 운영할 수 없습니다.',
  'dashboard.sync_status.ad_group.failed':
    '시스템 오류가 발생하여 메시지 발송이 정상적으로 재개 또는 중지 되지 않았습니다. 시스템이 자동으로 재시도하여 몇분 내 정상화됩니다. 단, 장시간 에러 상태가 지속된다면 ON/OFF 버튼을 통하여 직접 상태 변경을 시도하세요.',
  'dashboard.review_status.creative.waiting': '소재 심사 중 입니다.',
  'dashboard.review_status.creative.rejected':
    '소재가 심사 가이드에 맞지 않아 보류되었습니다. 심사 보류 사유를 확인하세요.',
  'dashboard.review_status.creative.modification_waiting':
    '수정사항을 심사 중입니다. 수정 이전 소재가 심사 승인 상태인 경우에만 수정 소재 심사 완료 이전까지 계속 노출됩니다. 수정 이전 소재가 모니터링 보류 상태이면 노출되지 않습니다.',
  'dashboard.review_status.creative.modification_rejected':
    '수정사항이 심사 가이드에 맞지 않아 보류되었습니다. 수정 이전 소재가 심사 승인 상태이면 계속 노출됩니다. 심사 보류 사유를 확인하세요.',
  'dashboard.review_status.creative.monitoring_rejected':
    '노출 중이던 소재가 심사 가이드에 맞지 않아 모니터링에 의하여 보류되었습니다. 모니터링 보류 사유를 확인하세요.',
  'dashboard.robot_reports':
    '로봇분석 보고서는 스마트메시지를 사용한 메시지 광고그룹에 대해서 메시지를 클릭한 사용자들의 특성을 분석하여 인사이트를 제공합니다. 발송 후 3일간 데이터를 갱신합니다.',
  'dashboard.information.today_cost':
    '오늘 소진되고 있는 실시간성 광고비에 대한 현황을 빠르게 확인할 수 있도록 제공되는 금액이며, 정확한 소진액은 광고캐시관리의 잔액을 통해 확인해주셔야 합니다. 기간단위(구좌)로 과금되는 CPT 구매방식은 비용에 포함되지 않습니다. ',
  'dashboard.information.month_cost':
    '이번달 1일부터 전일까지 소진된 VAT를 제외한 총 비용을 의미하여 기간단위(구좌)로 과금되는 CPT 구매방식은 비용에 포함되지 않습니다.',
  'target_management.common.target_status.error':
    '생성실패 또는 사용불가 타겟이 설정되어 있습니다. 오디언스에서 해당 타겟을 제거하세요.',
  'target_management.audience.display':
    '디스플레이 유형의 광고에 적용할 수 있는 오디언스 목록입니다.',
  'target_management.audience.message':
    '메시지 유형의 광고에 적용할 수 있는 오디언스 목록입니다.',
  'target_management.audience.create.target.age':
    '15세 미만은 타게팅 대상에서 제외됩니다.',
  'target_management.audience.create.target.location':
    '사용자가 가장 많이 방문한 지역을 타게팅 할 수 있으며, 행정구역상 동/읍/면 단위까지 설정할 수 있습니다.',
  'target_management.audience.create.target.demography':
    '인구통계학 기준으로 오디언스를 타게팅 할 수 있습니다. 전체 옵션 선택 시 추정 정보를 알 수 없는 대상까지 모두 포함합니다.',
  'target_management.audience.create.target_population': (
    <>
      예상 모수는 최근 120일 내 카카오 광고 지면 방문 이력이 있는 광고 식별자를
      기준으로 `광고에 노출될 수 있는 예상 모수`를 추정한 값입니다. 광고그룹에서
      설정한 타겟팅, 게재지면, 디바이스 조건에 따라 변경됩니다.
      성별,나이,지역,관심사/업종 타겟 정보는 카카오의 다양한 데이터를 기반으로
      추정되며 변화할 수 있습니다. 예상 모수는 광고 도달치 또는 실제 인구 통계
      수치를 의미하지 않습니다.
      <br />
      <br />
      *픽셀 & SDK 타겟의 경우, 설정한 타겟 기간 기준의 수집 모수를 기준으로
      광고에 노출될 예상 모수를 추정합니다.
    </>
  ),
  'target_management.audience.detail.target_population':
    '오디언스 예상 모수로, 개별 타겟 예상 모수에서 중복된 타겟을 제외한 수치입니다. 게재지면 및 디바이스 설정에 따라 모수가 변동될 수 있습니다.',
  'target_management.audience.detail.each_target_population':
    '개별 타겟 예상 모수입니다. 실시간으로 모수가 업데이트되는 타겟은 별도 수치를 제공하지 않습니다.',
  'target_management.audience.detail.update_date':
    '광고반응타겟, 고객파일, 친구그룹 모수가 업데이트된 시간입니다. 그 외 타겟들은 실시간으로 업데이트되므로 별도 업데이트 일시를 제공하지 않습니다.',
  'target_management.audience.detail.target_use_list':
    '해당 오디언스를 사용중인 광고그룹을 확인할 수 있습니다.',
  'target_management.cohort.display':
    '디스플레이 유형의 광고에 적용할 수 있는 광고반응타겟 목록입니다.',
  'target_management.cohort.message':
    '메시지 유형의 광고에 적용할 수 있는 광고반응타겟 목록입니다.',
  'target_management.cohort.population_score':
    '선택한 광고에 반응한 카카오 사용자 추정 도달수 입니다. 광고반응타겟 생성 요청일+1일 내 모수 추출이 완료되고, 수집기간에 따라 모수는 매일 새롭게 갱신됩니다. 준비중 상태인 타겟은 모수 추출 전 단계로 타게팅에 사용할 수 없습니다.',
  'target_management.cohort.collect_duration':
    '오늘을 기준으로 수집기간 동안 쌓인 사용자 데이터를 광고 타게팅에 사용합니다. 단, 광고 집행이력이 있어도 수집기간 내 반응한 사용자가 없는 경우 노출 대상이 없을 수 있습니다.',
  'target_management.cohort.cohort_summary_collect_duration':
    '오늘을 기준으로 지난 수집기간 동안 쌓인 광고 반응 사용자 데이터를 광고 타게팅에 사용합니다. 단, 광고 집행이력이 있어도 수집기간 내 반응한 사용자가 없는 경우 노출 대상이 없을 수 있습니다.',
  'target_management.cohort.cohort_detail_ad_group':
    '계정 내 해당 광고 반응 타겟이 타게팅에 사용된 광고그룹 리스트 입니다.',
  'target_management.cohort.indicator.play':
    '일부 캠페인의 경우, 동영상 반응 데이터를 포함하여 노출될 수 있습니다. 이때, 동영상 소재가 등록되어있지 않은 경우는 실제 반응 데이터로 사용되지 않습니다.',
  'target_management.cohort.detail.target_use_list':
    '광고계정 내 해당 광고반응타겟을 타게팅에 사용 중인 광고그룹 및 오디언스 전체 리스트입니다.',
  'target_management.cohort.detail.adgroup':
    '해당 타겟을 사용중인 광고그룹을 확인할 수 있습니다.',
  'target_management.cohort.detail.audience':
    '해당 타겟을 사용중인 오디언스를 확인할 수 있습니다.',
  'target_management.cohort.target_status.error':
    '타겟 생성에 실패하였습니다. 이 상태가 오래 지속되는 경우, 타겟 삭제 후 다시 등록하세요.',
  'target_management.cohort.target_status.seed_not_enough':
    '수집기간 내 반응 데이터에 유효한 사용자가 없어 타겟 생성이 불가능합니다.',
  'target_management.cohort.target_status.available_error':
    '모수 업데이트 중 문제가 발생하였습니다. 기존 생성된 모수로 광고 집행은 가능합니다. 이 표시가 오래 지속되는 경우, 타겟 삭제 후 재 생성하세요.',
  'target_management.pf_group.pf_count':
    '친구그룹에 등록한 고객 식별자 중에서 해당 카카오톡 채널과 친구관계인 사용자수 입니다. 준비중 상태의 친구그룹은 친구 관계 확인 전 단계로 메시지 발송에 사용할 수 없습니다. 친구수 확인은 시간이 소요됩니다.',
  'target_management.pf_group.pf_type':
    '파일을 업로드하는 유형과 메시지 발송 대상자 유형은 혼합해서 선택할 수 없습니다. 필요 시 각각의 친구그룹을 생성하여 광고그룹에 적용할 수 있습니다.',
  'target_management.pf_group.pf_type_tooltip.phone_number':
    '01X로 시작되는 형식이며, 해외 전화번호인 경우 반드시 국가번호를 함께 입력해야 합니다.',
  'target_management.pf_group.pf_type_tooltip.app_user_id':
    '해당 카카오톡 채널로 카카오에 연동한 앱이 있는 경우에만 등록할 수 있습니다.',
  'target_management.pf_group.pf_type_tooltip.message_retarget':
    '최근 30일 이내 등록한 카카오톡 채널 X 도달 광고그룹 중에서 메시지가 발송된 광고그룹을 선택할 수 있습니다.',
  'target_management.pf_group.pf_type_tooltip.account_id':
    '카카오서비스의 카카오톡 채널인 경우에만 등록할 수 있습니다.',
  'target_management.pf_group.detail.target_use_list':
    '광고계정 내 해당 친구그룹을 타게팅에 사용 중인 광고그룹 및 오디언스 전체 리스트입니다.',
  'target_management.pf_group.detail.population_score':
    '등록한 친구그룹에서 카카오톡 채널과 친구인 친구수입니다.',
  'target_management.pf_group.uploaded_file_summary':
    '템플릿 형식에 맞는 경우에만 등록할 수 있습니다. 형식에 맞지 않은 값은 등록 실패 처리됩니다.',
  'target_management.pf_group.target_status.error':
    '친구수를 확인할 수 없습니다. 이 그룹을 삭제 후 다시 등록하세요.',
  'target_management.custom_adid_file.list.update_date':
    '타겟 모수가 업데이트된 시간입니다.',
  'target_management.custom_adid_file.list.target_population':
    '고객파일을 등록 후 최대 6시간 이내에 모수가 생성됩니다. 최초 타겟 등록 후 2일 경과 시 광고 활용이 가능한 활성 사용자 기준으로 모수가 변경됩니다. 광고계정 상태가 OFF일 경우 타겟 모수가 0이 될 수 있습니다. 광고계정 상태를 ON으로 변경해 주세요.',
  'target_management.custom_adid_file.detail.target_info.target_population':
    '고객파일을 등록 후 최대 6시간 이내에 모수가 생성됩니다. 최초 타겟 등록 후 2일 경과 시 광고 활용이 가능한 활성 사용자 기준으로 모수가 변경됩니다. 광고계정 상태가 OFF일 경우 타겟 모수가 0이 될 수 있습니다. 광고계정 상태를 ON으로 변경해 주세요.',
  'target_management.custom_adid_file.detail.target_info.update_date':
    '타겟 모수가 업데이트된 시간입니다.',
  'target_management.custom_adid_file.detail.target_info.source_url':
    '등록된 URL의 도메인 및 끝 10자리입니다.',
  'target_management.custom_adid_file.detail.target_info.url_description':
    '갱신 옵션 선택되어 있을 경우 "갱신형"으로 표기됩니다. 갱신유효기간은 타겟 등록 또는 URL수정일로부터 +90일 입니다.',
  'target_management.custom_adid_file.detail.target_use_list':
    '광고계정 내 해당 고객파일을 타게팅에 사용 중인 광고그룹 및 오디언스 전체 리스트입니다.',
  'target_management.custom_adid_file.target_status.error':
    '타겟을 생성할 수 없습니다. 이 타겟을 삭제 후 다시 생성하세요.',
  'target_management.custom_adid_file.target_status.modifying_error':
    '모수 업데이트 중 문제가 발생하였습니다. 기존 생성된 모수로 광고 집행은 가능하나, 정확한 타게팅을 위해 해당 타겟을 삭제 후 다시 등록하세요.',
  'target_management.custom_adid_file.target_status.transform_error':
    'URL을 통해 정상적인 CSV파일을 가져올수 없습니다. URL의 퍼블릭 엑세스,다운로드 가능 여부 및 CSV파일을 확인해주세요.',
  'target_management.custom_adid_file.target_status.transform_modifying_error':
    'URL 갱신에 실패하였습니다. 갱신유효기간의 만료 또는 URL 정상 여부를 확인해주세요. 마지막 갱신된 모수로 광고집행은 가능하나 정확한 타겟팅을 위해 해당 타겟을 삭제 후 다시 등록하세요.',
  'settings.ad_account_management.member.member_request.rejection':
    '설정 시 해당 광고계정에 대해 더 이상 멤버 요청을 받지 않습니다.',
  'settings.ad_account_management.business_right.business_right_request_rejection':
    '설정 시 해당 광고계정에 대하여 더이상 영업권 요청을 받지 않습니다.',
  'settings.review_documents_management.uploaded_documents_count':
    '해당 업종에 등록한 심사서류 파일 총 개수입니다.',
  'settings.cash_management.deferred_payment.due_date':
    '결제기한일은 정산월별로 결제기준일 기간에 해당하는 월의 마지막 일자로 자동 설정됩니다. (예- 결제기준일이 30일인 경우, 정산월 익월 말일)',
  'settings.cash_management.deferred_payment.period':
    '정산월별 결제기준 기간입니다. 결제기준일에 따라 결제기한일이 설정됩니다.',
  'settings.cash_management.deferred_payment.settlement_month':
    '광고 비용이 발생한 월을 의미합니다.',
  'settings.cash_management.deferred_payment.settlement_amount':
    '정산 금액은 정산월 기준 다음달 초에 생성됩니다.',
  'settings.cash_management.deferred_payment.settlement_paid_amount':
    '정산 금액 중 이미 납부한 금액을 의미합니다.',
  'settings.cash_management.deferred_payment.settlement_remained_amount':
    '정산 금액 중 아직 납부되지 않은 금액을 의미합니다.',
  'settings.cash_management.deferred_payment.creditLimit':
    '광고계정의 후불한도 값으로, 광고계정 잔액이 0원 밑으로 떨어져도 후불한도 만큼 광고집행할 수 있습니다.',
  'settings.cash_management.deferred_payment.remainedCreditLimit':
    '조회시점 기준으로 광고집행에 활용할 수 있는 남은 후불한도입니다. 가용후불한도가 부족하면 광고가 중단됩니다.',
  'settings.cash_management.deferred_payment.remained_bond_amount':
    '광고계정 기준으로 다음 결제기한일 기준의 미결제금액과 이후의 결제기한일의 미결제금액의 총합입니다.',
  'settings.cash_management.deferred_payment.due_dt_remained_bond_amount':
    '다음 결제기한일 기준 미결제금액과 기한 이전의 연체금액의 총합입니다.',
  'settings.cash_management.deferred_payment.plan_repay_amount':
    '가상계좌를 이미 발급하고 입금하지 않은 금액의 총합입니다.',
  'settings.cash_management.deferred_payment.max_payable_amount':
    '결제 금액에 입력할 수 있는 최대 금액입니다. 전체 미결제 금액에서 기발급 가상계좌 입금대기 금액을 차감한 금액과 10억원 중 작은 금액입니다.',
  'settings.cash_management.deferred_payment.cancel_deposit_fee_info.total_budget':
    '계약금액은 vat 포함 금액입니다.',
  'settings.cash_management.deferred_payment.cancel_deposit_fee_info.cancel_fee':
    (
      <>
        위약금/계약 해지 수수료 정보는 광고그룹 등록 및 수정 시 확인 가능하며,
        구체적인 위약금/계약 해지 수수료율 및 부과 절차는 &apos;
        <a
          href={KAKAO_BUSINESS_GUIDE_MOMENT_CREATE_AD}
          className="fc_emph"
          rel="noopener noreferrer"
          target="_blank">
          카카오모먼트 관리자센터
        </a>
        &apos; 에서 확인 가능합니다.
      </>
    ),
  'settings.cash_management.deferred_payment.cancel_deposit_fee_info.cancel_fee_receipt':
    '세금계산서가 미발행되는 계약 상품 해지시 조회 가능합니다.',
  'settings.payment_card_management.add.daily_spending_budget':
    '1일(00시~24시)동안 자동 결제카드에서 결제 가능한 최대 한도 금액을 설정할 수 있습니다.',
  'settings.payment_card_management.list.description':
    "광고계정에 등록한 결제카드 리스트입니다. 이 중 '결제카드'는 현재 광고계정에서 발생한 비용을 자동 결제하는 카드를 의미합니다.",
  'settings.payment_card_management.list.add_button':
    '결제카드 또는 예비카드를 결제할 우선 순위에 맞게 등록합니다.',
  'settings.payment_card_management.list.suspended':
    '등록한 자동 결제카드가 카카오페이 결제 수단에서 사용 정지 되었습니다. 새로운 자동 결제카드를 등록하세요.',
  'settings.payment_card_management.summary.retry_count_over':
    '일 결제 시도 횟수가 초과되면 광고 운영이 정지됩니다.',
  'settings.payment_card_management.daily_retry_count_over':
    '일 결제 시도 횟수가 초과되면 광고 운영이 정지됩니다.',
  'settings.payment_card_management.add_card':
    '결제카드 또는 예비카드를 결제할 우선 순위에 맞게 등록합니다.',
  'settings.payment_card_management.card_list':
    "광고계정에 등록한 결제카드 리스트입니다. 이 중 '결제카드'는 현재 광고계정에서 발생한 비용을 자동 결제하는 카드를 의미합니다.",
  'settings.payment_card_management.suspended_card': (
    <>
      등록한 자동 결제카드가 카카오페이 결제수단에서 사용정지 되었습니다. <br />
      새로운 자동 결제카드를 등록하세요.
    </>
  ),
  'settings.change_log.contents':
    '일부 항목은 수정 전/후 내용의 이력을 제공하지 않습니다.',
  'settings.change_log.audience_use_list':
    '변경시점에 오디언스를 사용한 광고그룹 전체 리스트입니다.',
  'adview.button1': '버튼1은 지정된 카카오톡 내 랜딩 유형만 선택 가능합니다.',
  'adview.button2':
    '버튼2는 카카오톡 랜딩 유형 및 외부 랜딩도 가능합니다. 단, 버튼1에서 사용한 유형은 버튼 2에서는 선택할 수 없습니다.',
  'adview.detail.linked.creative_landing':
    '캠페인 하위 비즈보드 소재, 광고 메시지 발송 등 광고 소재의 랜딩으로 애드뷰를 사용한 현황을 확인할 수 있습니다.',
  'adview.detail.linked.message_landing':
    '`메시지 관리` 메뉴 하위에 저장된 메시지의 랜딩으로 애드뷰를 사용한 현황을 확인할 수 있습니다.',
  'adview.detail.preview': '실제 노출과 미리보기가 다를 수 있습니다.',
  'talk_biz_form.report':
    '비즈니스폼 리포트는 해당 비즈니스폼의 권한이 있는 계정만 확인할 수 있습니다.',
  'talk_biz_form.linker':
    '광고계정에 비즈니스폼 권한을 추가한 연동자의 카카오계정을 보여줍니다. 단, 탈퇴 또는 휴면 처리된 계정은 노출 불가합니다.',
  'report.pivot.dimension.overview':
    '보고서 지표 측정의 기준이 되는 항목입니다.',
  'report.pivot.dimension.ad_level':
    '광고단위의 이름과 ID로 구분하여 보고서 지표를 확인할 수 있습니다.',
  'report.pivot.dimension.setting':
    '캠페인 설정값을 기준으로 구분하여 보고서 지표를 확인할 수 있습니다.',
  'report.pivot.dimension.targeting':
    '데모그래픽, 게재지면, 디바이스, 소재유형 및 시간대로 구분하여 보고서 지표를 확인할 수 있습니다. 한가지 이상은 선택할 수 없습니다.',
  'report.pivot.dimension.hourly':
    "기간을 '일'로 선택한 경우에만 시간대 분석이 가능합니다.",
  'report.pivot.dimension.timeunit':
    "'일', '주', '월' 단위는 조회 기간을 2개월(62일) 내로 선택한 경우에 제공 가능합니다.",
  'report.pivot.metric.overview':
    '광고를 집행하면서 수집하는 광고의 결과값입니다.',
  'report.kakaopay_purchase.search_period': (
    <>
      &apos;일&apos; 단위는 조회 기간을 1개월(31일) 내로, <br /> &apos;주&apos;
      단위는 조회 기간을 2개월(62일) 내로, <br /> &apos;월&apos; 단위는 조회
      기간을 12개월 내로 선택한 경우에 제공 가능합니다.
    </>
  ),
  'report.skan_download': (
    <>
      SKAdNetwork로 수집한 전환 지표를 확인할 수 있습니다. 지표는 캠페인,
      광고그룹 단위까지만 제공되며, 실시간으로 반영되지 않고 SKAdNetwork 버전에
      따라 지표 반영 시점이 다릅니다.
      <br />- 3.0 : 앱 설치 기준 2일 후 반영
      <br />- 4.0 : 앱 설치 기준 4일, 13일, 41일 후 반영
    </>
  ),
  'pixel_sdk.list.linked':
    '광고계정에 연동되어 있는 픽셀 & SDK 목록으로, 최대 100개까지 사용할 수 있습니다.',
  'pixel_sdk.list.available':
    '내 카카오계정이 마스터/멤버 권한을 갖고 있는 픽셀 & SDK입니다. ‘연동하기’ 버튼을 클릭하면 즉시 광고계정과 연동됩니다.',
  'pixel_sdk.list.linked_service':
    '운영 중인 카카오서비스를 연동하거나 웹사이트와 앱에 픽셀 & SDK를 설치하면 이벤트를 수집할 수 있습니다.',
  'pixel_sdk.list.linked_event':
    '카카오서비스를 연동하거나 웹사이트와 앱에 픽셀 & SDK를 설치하면 아래 이벤트를 수집합니다.',
  'pixel_sdk.list.usage_campaign':
    '해당 픽셀 & SDK를 사용중인 캠페인을 확인할 수 있습니다.',
  'pixel_sdk.list.usage_adgroup':
    '해당 픽셀 & SDK를 사용중인 광고그룹을 확인할 수 있습니다.',
  'pixel_sdk.list.service':
    '픽셀 & SDK에 연동된 서비스입니다. 마우스오버하면 상세 서비스를 확인할 수 있습니다.',
  'pixel_sdk.list.population':
    '이벤트별로 최근 180일간 수집한 추정 모수입니다.',
  'pixel_sdk.list.event_status':
    '픽셀 & SDK로 이벤트가 수집되고 있는 상태입니다. 픽셀 & SDK를 설치하거나 카카오 서비스를 연동하면 이벤트가 수집됩니다.',
  'pixel_sdk.detail.info.service':
    '웹사이트, 앱, 싱크에서 이벤트가 발생하거나 카카오톡 채널에 템플릿이 연동되면 그 다음날부터 목록에 표시됩니다. 웹사이트, 앱, 싱크의 경우, 최근 7일간 이벤트가 발생하지 않으면 그 다음날부터 목록에 표시되지 않습니다.',
  'pixel_sdk.detail.info.population':
    '이벤트별로 최근 180일간 수집한 추정 모수입니다.',
  'pixel_sdk.detail.info.event_status':
    '픽셀 & SDK로 이벤트가 수집되고 있는 상태입니다. 픽셀 & SDK를 설치하거나 카카오 서비스를 연동하면 이벤트가 수집됩니다.',
  'pixel_sdk.detail.info.custom_event':
    '서비스 출처/태그/매개변수 조합으로 커스텀 이벤트를 생성하고 커스텀 이벤트 기준으로 모수를 확인할 수 있습니다.',
  'pixel_sdk.detail.authority':
    '‘멤버 권한 요청’ 클릭 시, 픽셀 & SDK 마스터/멤버가 요청을 수락한 후 권한을 얻으면 픽셀 & SDK 관리를 할 수 있습니다.',
  'pixel_sdk.popup.service':
    '픽셀 & SDK에 연동된 서비스입니다. 마우스오버하면 상세 서비스를 확인할 수 있습니다.',
  'dsp_info.auto_payment_card.card_list':
    '로그인한 계정과 동일한 카카오계정을 기준으로 카카오페이에 등록된 카드 중 신용카드를 연결하여 사용할 수 있습니다.',
  'dsp_info.notification.system':
    '‘카카오모먼트’에 가입 시 등록한 휴대폰번호로 가입된 카카오톡으로 알림 메시지가 발송됩니다. 카카오톡으로 알림 발송이 실패할 경우 문자메시지로 발송됩니다. ’카카오 광고’ 알림톡을 수신 거부한 경우 발송되지 않습니다.',
  'dsp_info.notification.midnight':
    '기본적으로 밤 9시 ~ 익일 오전 9시에 발생한 알림은 제공하지 않으며 심야 알림 허용 설정한 경우에만 수신 가능합니다.',
  'other.group_copy_schedule':
    '복사하는 광고그룹의 시작일, 종료일을 설정할 수 있습니다.',
  'other.group_copy_only':
    '하위 소재를 복사하지 않고, 광고그룹만 복사할 수 있습니다.',
  'my_info.dsp_auto_payment_card.description':
    '자동 결제 가능한 신용카드를 연결하여 사용할 수 있습니다.',
})
export default Messages
