import axios from 'axios'
import { cruxGatewayURL } from './app/services/cruxGateway'
import {
  onResponseFulfilled,
  onResponseRejected,
  setAuthorizationHeader,
} from './authenticationUtils'

const cruxAxios = axios.create({
  baseURL: cruxGatewayURL(),
  withCredentials: true,
})

cruxAxios.interceptors.request.use(
  config => {
    config.headers.serviceType = 'MOMENT'
    setAuthorizationHeader(config)
    return config
  },
  error => Promise.reject(error)
)
cruxAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)
const _fetchDefault = ({
  url,
  method,
  requestPayload = {},
  requestParams = {},
  headers = {},
  cancelTokenSource,
  adAccountId,
}) => {
  if (adAccountId) {
    headers.adAccountId = adAccountId
  }
  return cruxAxios(url, {
    method,
    url,
    headers,
    data: requestPayload,
    params: requestParams,
    cancelToken: cancelTokenSource?.token,
  })
}

const CruxFetchUtils = {
  getNoToken: url => {
    return cruxAxios.get(url)
  },

  get: ({ url, headers, requestParams, cancelTokenSource, adAccountId }) => {
    return _fetchDefault({
      url,
      method: 'get',
      requestParams,
      headers,
      cancelTokenSource,
      adAccountId,
    })
  },

  post: ({ url, requestPayload, headers, cancelTokenSource, adAccountId }) => {
    return _fetchDefault({
      url,
      method: 'post',
      requestPayload,
      headers,
      cancelTokenSource,
      adAccountId,
    })
  },

  put: ({ url, requestPayload, headers, adAccountId }) => {
    return _fetchDefault({
      url,
      method: 'put',
      requestPayload,
      headers,
      adAccountId,
    })
  },

  delete: ({ url, requestPayload, headers, adAccountId }) => {
    return _fetchDefault({
      url,
      method: 'delete',
      requestPayload,
      headers,
      adAccountId,
    })
  },
}

export default CruxFetchUtils
