import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    AUDIENCE: null,
    NORMAL: null,
    DISPLAY: null,
    MESSAGE: null,
  }),

  Name: {
    DISPLAY: '디스플레이',
    MESSAGE: '메시지',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
