import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import moment from 'moment'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import { updateDashboardCalendarByLeastDate } from './mDashboardCalendar'
import { CommonActionType } from '../../modules-actionTypes/actionTypes'
import { AdAccountHelper } from '../../utils/helper/helper-adAccount'
import { toInvalidApproachPath } from '../../utils/router/routeUtils'
import axios from 'axios/index'
import { setLnbAdAccountInfo } from '../lnb/mLnb'
import { setPreference } from '../adaccount/mPreference'
import { initDashboardTableMetricByStorage } from './mDashboardTableMetric'
import { handleDashboardException } from './dashboardException'
import { checkNotEmpty } from '../../utils/regexUtils'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const DashboardCommon = keyMirror(
  {
    SET_AD_ACCOUNT_INFO: null,
    SET_AD_ACCOUNT_TODAY_INDEX: null,

    SET_LAST_UPDATED_TIME: null,

    SET_ON_OFF_SPINNER_LOADING_KEY: null,
    CLEAR_ON_OFF_SPINNER_LOADING_KEY: null,
  },
  'DASHBOARD_COMMON'
)

const initialState = fromJS({
  adAccountInfo: {},

  adAccountTodayIndex: {
    costOfToday: 0,
  },

  lastUpdatedTime: null,

  /**
   * https://jira.daumkakao.com/browse/KAMOQA-15184
   */
  onOffSwitchSpinnerLoadingKey: null,
})

export default createReducer(initialState, {
  [DashboardCommon.SET_AD_ACCOUNT_INFO]: (state, { adAccountInfo }) => {
    return state.set('adAccountInfo', fromJS(adAccountInfo))
  },

  [DashboardCommon.SET_AD_ACCOUNT_TODAY_INDEX]: (
    state,
    { adAccountTodayIndex }
  ) => {
    return state.set('adAccountTodayIndex', fromJS(adAccountTodayIndex))
  },

  [DashboardCommon.SET_LAST_UPDATED_TIME]: state => {
    return state.set('lastUpdatedTime', moment())
  },

  [DashboardCommon.SET_ON_OFF_SPINNER_LOADING_KEY]: (state, { key }) => {
    return state.set('onOffSwitchSpinnerLoadingKey', key)
  },

  [DashboardCommon.CLEAR_ON_OFF_SPINNER_LOADING_KEY]: state => {
    return state.set('onOffSwitchSpinnerLoadingKey', null)
  },
})

export function setDashboardAdAccountInfo({ adAccountInfo }) {
  return {
    type: DashboardCommon.SET_AD_ACCOUNT_INFO,
    adAccountInfo,
  }
}

export function setDashboardAdAccountTodayIndex({ adAccountTodayIndex }) {
  return {
    type: DashboardCommon.SET_AD_ACCOUNT_TODAY_INDEX,
    adAccountTodayIndex,
  }
}

export function invalidateDashboardAdAccountInfo({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_AD_ACCOUNT_INFO))

    try {
      const [
        adAccountResponse,
        adAccountTodayIndexResponse,
        preferenceResponse,
      ] = await axios.all([
        api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId),
        api.dashboardSummary.fetchAdAccountTodayIndex({
          adAccountId,
        }),
        api.adAccount.getAdAccountPreference(adAccountId),
      ])

      const adAccountInfo = adAccountResponse?.data || {}
      const adAccountTodayIndex = adAccountTodayIndexResponse?.data || {}
      const { preferenceData } = preferenceResponse?.data || {}

      if (!AdAccountHelper.isValid(adAccountInfo)) {
        dispatch(RouterV2.replace(toInvalidApproachPath()))
      } else {
        dispatch(setPreference(preferenceData))

        dispatch(setDashboardAdAccountInfo({ adAccountInfo }))

        dispatch(setLnbAdAccountInfo({ adAccountInfo }))

        dispatch(setDashboardAdAccountTodayIndex({ adAccountTodayIndex }))

        // invalidate calendar start date
        dispatch(
          updateDashboardCalendarByLeastDate({
            leastDate: adAccountInfo.createdDate,
          })
        )

        dispatch(initDashboardTableMetricByStorage({ adAccountId }))
      }
    } catch (e) {
      switch (Number(e.response?.data?.errorCode)) {
        // not found
        case 6003:
        case 6009: {
          dispatch(RouterV2.replace(toInvalidApproachPath()))

          break
        }

        default: {
          dispatch(handleDashboardException({ e }))
          break
        }
      }
    } finally {
      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_AD_ACCOUNT_INFO))
    }
  }
}

export function invalidateDashboardLastUpdatedTime() {
  return {
    type: DashboardCommon.SET_LAST_UPDATED_TIME,
  }
}

export function setDashboardByUrlSearchParams({ urlSearchParams }) {
  return {
    type: CommonActionType.SET_BY_URL_SEARCH_PARAMS,
    urlSearchParams,
  }
}

export function setDashboardOnOffSpinnerLoading(key) {
  return dispatch => {
    dispatch({
      type: DashboardCommon.SET_ON_OFF_SPINNER_LOADING_KEY,
      key,
    })

    dispatch(showLoading(key))
  }
}

export function hideDashboardOnOffSpinnerLoading() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: { onOffSwitchSpinnerLoadingKey },
      },
    } = getState()

    if (checkNotEmpty(onOffSwitchSpinnerLoadingKey)) {
      dispatch(hideLoading(onOffSwitchSpinnerLoadingKey))
    }

    dispatch({
      type: DashboardCommon.CLEAR_ON_OFF_SPINNER_LOADING_KEY,
    })
  }
}
