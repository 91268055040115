import { keyMirror } from '../utils/utils'
import KakaoStoreEnum from './KakaoStoreEnum'
import { checkNotEmpty } from '../utils/regexUtils'

const TalkChannelMessageEnum = {
  Content: {
    Type: keyMirror({
      item: null, // wide list
      text: null,
      thumbnail: null,
      button: null,
    }),
  },

  Landing: {
    Type: keyMirror({
      NONE: null,
      LANDING_URL: null,
      CHANNEL_POST: null,
      CHANNEL_COUPON: null,
      AD_VIEW: null,
      BIZ_FORM: null,
      TALK_STORE_LANDING: null,
      WEBEMBED_CHANNEL_URL: null,
    }),

    Name: {
      NONE: '미설정',
      LANDING_URL: 'URL',
      CHANNEL_POST: '소식',
      CHANNEL_COUPON: '쿠폰',
      AD_VIEW: '애드뷰',
      BIZ_FORM: '비즈니스폼',
      TALK_STORE_LANDING: '톡스토어',
      WEBEMBED_CHANNEL_URL: '웹임베드 채널',
    },

    // 기본 랜딩 (기본텍스트형, 와이드이미지형, 와이드리스트형)
    Description: {
      NONE: '메시지 소재에서 버튼을 미노출합니다.',
      TALK_STORE_LANDING:
        '캐러셀에 톡스토어 상품을 연동할 경우 선택합니다. \n' +
        'Intro 홍보이미지 선택 시 톡스토어 홈으로 연결됩니다.',
      LANDING_URL:
        '버튼 선택 시 입력한 URL로 연결합니다. 치환이 필요한 매크로가 포함된 형식의 URL은 일부 환경에서 정상 작동하지 않을 수 있습니다.',
      CHANNEL_POST: '버튼 선택 시 선택한 카카오톡 채널 소식으로 연결합니다.',
      CHANNEL_COUPON: '버튼 선택 시 선택한 카카오톡 채널 쿠폰으로 연결합니다.',
      WEBEMBED_CHANNEL_URL:
        '버튼 선택 시 웹임베드 채널로 연결합니다. 입력한 URL이 일반 브라우저가 아닌 웹임베드 채널에서 열리며, 모바일 환경만 지원합니다.',
      AD_VIEW: '버튼 선택 시 선택한 애드뷰로 연결합니다.',
      BIZ_FORM:
        '버튼 선택 시 선택한 비즈니스폼으로 연결합니다. 카카오비즈니스 관리자센터에서 비즈니스폼을 생성 후 광고계정에 연동하면 랜딩으로 사용할 수 있습니다.',
    },

    // 캐러셀 인트로 랜딩
    CarouselIntroLandingDescription: {
      NONE: 'Intro 홍보이미지에서 랜딩을 미설정합니다.',
      LANDING_URL:
        'Intro 홍보이미지 선택 시 입력한 URL로 연결합니다. 치환이 필요한 매크로가 포함된 형식의 URL은 일부 환경에서 정상 작동하지 않을 수 있습니다.',
      CHANNEL_POST:
        'Intro 홍보이미지 선택 시 선택한 카카오톡 채널 소식으로 연결합니다.',
      CHANNEL_COUPON:
        'Intro 홍보이미지 선택 시 선택한 카카오톡 채널 쿠폰으로 연결합니다.',
      TALK_STORE_LANDING:
        '캐러셀에 톡스토어 상품을 연동할 경우 선택합니다. \n' +
        'Intro 홍보이미지 선택 시 톡스토어 홈으로 연결됩니다.',
      WEBEMBED_CHANNEL_URL:
        'Intro 홍보이미지 선택 시 웹임베드 채널로 연결합니다. 입력한 URL이 일반 브라우저가 아닌 웹임베드 채널에서 열리며, 모바일 환경만 지원합니다.',
    },

    // 캐러셀 랜딩 URL
    CarouselLandingDescription: {
      LANDING_URL:
        '캐러셀 홍보이미지와 버튼 선택 시 입력한 URL로 연결합니다. 치환이 필요한 매크로가 포함된 형식의 URL은 일부 환경에서 정상 작동하지 않을 수 있습니다.',
      WEBEMBED_CHANNEL_URL:
        '캐러셀 홍보이미지와 버튼 선택 시 웹임베드 채널로 연결합니다. 입력한 URL이 일반 브라우저가 아닌 웹임베드 채널에서 열리며, 모바일 환경만 지원합니다.',
    },
    // 캐러셀 더보기
    CarouselMoreDescription: {
      NONE: '메시지 소재에서 더보기 버튼을 미노출합니다.',
      LANDING_URL:
        '더보기 버튼 선택 시 입력한 URL로 연결합니다. 치환이 필요한 매크로가 포함된 형식의 URL은 일부 환경에서 정상 작동하지 않을 수 있습니다.',
      CHANNEL_POST:
        '더보기 버튼 선택 시 선택한 카카오톡 채널 소식으로 연결합니다.',
      CHANNEL_COUPON:
        '더보기 버튼 선택 시 선택한 카카오톡 채널 쿠폰으로 연결합니다.',
      TALK_STORE_LANDING:
        '캐러셀에 톡스토어 상품을 연동할 경우 선택합니다.\n' +
        '더보기 버튼 선택 시 톡스토어 홈으로 연결됩니다.',
      WEBEMBED_CHANNEL_URL:
        '더보기 버튼 선택 시 웹임베드 채널로 연결합니다. 입력한 URL이 일반 브라우저가 아닌 웹임베드 채널에서 열리며, 모바일 환경만 지원합니다.',
    },

    getName(type) {
      return this.Name[type]
    },

    getDescription(type) {
      return this.Description[type]
    },

    getCarouselIntroLandingDescription(type) {
      return this.CarouselIntroLandingDescription[type]
    },

    getCarouselLandingDescription(type) {
      return this.CarouselLandingDescription[type]
    },

    getCarouselMoreDescription(type) {
      return this.CarouselMoreDescription[type]
    },

    values(isBizChannel) {
      const values = Object.keys(this.Type)
      return isBizChannel
        ? values
        : values.filter(v => v !== this.Type.BIZ_FORM)
    },

    // intro, more
    getCarouselLandingType({
      talkStoreInfo,
      isProfileHomeInfo,
      hasItemAssetGroup,
      carouselStoreType = '',
    }) {
      const LandingTypes = Object.keys(this.Type)
        .filter(v => v !== this.Type.AD_VIEW && v !== this.Type.BIZ_FORM)
        .filter(
          v =>
            v !== this.Type.TALK_STORE_LANDING || checkNotEmpty(talkStoreInfo)
        )
        .filter(v => v !== this.Type.WEBEMBED_CHANNEL_URL || isProfileHomeInfo)

      if (!hasItemAssetGroup) return LandingTypes

      if (carouselStoreType === KakaoStoreEnum.Type.TALK_STORE) {
        return LandingTypes
      } else {
        return LandingTypes.filter(v => v !== this.Type.TALK_STORE_LANDING)
      }
    },

    getCarouselItemLandingType() {
      return [this.Type.LANDING_URL, this.Type.WEBEMBED_CHANNEL_URL]
    },

    // 캐러셀 커머스형 제외한 랜딩
    getFilteredLandings(
      isRequired,
      hasLandingBizForm,
      hasLandingAdView,
      isBizChannel,
      isProfileHomeInfo,
      isPremiumVideo
    ) {
      return Object.keys(this.Type)
        .filter(v => v !== this.Type.TALK_STORE_LANDING)
        .filter(v => v !== this.Type.BIZ_FORM || hasLandingBizForm)
        .filter(v => v !== this.Type.AD_VIEW || hasLandingAdView)
        .filter(v => v !== this.Type.BIZ_FORM || isBizChannel)
        .filter(v => v !== this.Type.NONE || !isRequired)
        .filter(v =>
          isPremiumVideo
            ? v !== this.Type.WEBEMBED_CHANNEL_URL
            : v !== this.Type.WEBEMBED_CHANNEL_URL || isProfileHomeInfo
        )
    },
  },

  TalkBizFormButton: {
    Type: keyMirror({
      TALK_IN_SURVEY: null,
      TALK_TEST_DRIVE: null,
      TALK_IN_RESERVATION: null,
      TALK_IN_APPLY: null,
      TALK_IN_PARTICIPATE: null,
    }),
    Name: {
      TALK_IN_SURVEY: '톡에서 설문하기',
      TALK_TEST_DRIVE: '톡에서 시승신청',
      TALK_IN_RESERVATION: '톡에서 예약하기',
      TALK_IN_APPLY: '톡에서 응모하기',
      TALK_IN_PARTICIPATE: '톡에서 참여하기',
    },
    values() {
      return Object.keys(this.Type)
    },

    getName(type) {
      return this.Name[type]
    },

    getType(name) {
      return Object.keys(this.Type).find(key => this.Name[key] === name)
    },
  },
}

export default TalkChannelMessageEnum
