import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cx from 'classnames'
import { checkEmpty, checkNotEmpty } from '../../../../../utils/regexUtils'
import {
  selectCreativeLibraryItem,
  setCreativeLibrarySelectedItems,
} from '../../../../../modules/advertise/mCreativeLibrary'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ScaleImageFitCenter } from '../../../../../utils/imageUtils'
import { useAnimateCSS } from '../../../../../utils/hook/useAnimateCSS'
import { List } from 'immutable'
import ImageEditorUtils from '../ImageEditor/imageEditorUtils'
import { Tooltip } from '../../../../../resources/locale'
import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'
import CreativeLibraryImageEditButton from '../Common/CreativeLibraryImageEditButton'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CmpAssetLibraryFileName from '../../Cmp/CmpAssetLibrary/CmpAssetLibraryFileName'

const selector = ({
  creativeV2: {
    creativeLibrary: { editedItemsMap },
  },
}) => {
  return {
    editedItemsMap,
  }
}

const CreativeLibraryImageView = ({
  index,
  selectedOrder,
  item,
  isVisibleAnim = true,
  deletable = false,
  isBizBoardBanner = false,
  multiSelect,
  handleDelete,
  onImageLoaded,
  creativeUploadAssetPropertyType,
  creativeFormat,
  campaignType,
  handleImageCropperOpen,
}) => {
  const dispatch = useDispatch()
  const { editedItemsMap } = useSelector(selector, shallowEqual)

  const { imageUUID, url, imageWidth, imageHeight, originalFileName } =
    item || {}
  const hasUrl = checkNotEmpty(url)

  const { ref, onLoad, isAnimStarted } = useAnimateCSS({
    onAnimEnded: () => {
      if (isVisibleAnim) {
        if (typeof onImageLoaded === 'function') {
          onImageLoaded(imageUUID)
        }
      }
    },
  })

  const isSelected = selectedOrder > 0

  const sizeText = [imageWidth, imageHeight].filter(Boolean).join('x')

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    imageWidth,
    imageHeight,
    150,
    150
  )

  const prevEditedItems = React.useMemo(() => {
    return editedItemsMap.get(imageUUID)
  }, [editedItemsMap, imageUUID])

  const openCreativeLibraryImageEditor = React.useCallback(() => {
    const items = prevEditedItems ? List(prevEditedItems) : List.of(item)
    handleImageCropperOpen({
      imageUUID,
      items,
      originalItem: item,
    })
  }, [imageUUID, item, handleImageCropperOpen, prevEditedItems])

  const validAspects = React.useMemo(
    () =>
      ImageEditorUtils.getCropAspects({
        creativeUploadAssetPropertyType,
        creativeFormat,
        imageWidth,
        imageHeight,
        campaignType,
      }),
    [
      creativeUploadAssetPropertyType,
      creativeFormat,
      imageWidth,
      imageHeight,
      campaignType,
    ]
  )

  const isAvailableEdit = validAspects.length > 0

  const needEdit = React.useMemo(
    () =>
      isAvailableEdit &&
      !validAspects.includes(imageWidth / imageHeight) &&
      checkEmpty(prevEditedItems),
    [isAvailableEdit, imageHeight, imageWidth, prevEditedItems, validAspects]
  )

  return (
    <li
      className={cx({
        on: !multiSelect && isSelected,
        on_order: multiSelect && isSelected,
        load_ok: isVisibleAnim && hasUrl,
      })}>
      <a
        className="link_material"
        onClick={e => {
          e.preventDefault()

          if (multiSelect) {
            dispatch(selectCreativeLibraryItem(item))
          } else {
            dispatch(setCreativeLibrarySelectedItems(isSelected ? [] : [item]))
          }
        }}>
        <span className="img_file">
          <span className="inner_img_file">
            {url ? (
              isBizBoardBanner ? (
                <span
                  style={{
                    display: 'block',
                    backgroundColor: '#f3f3f3',
                    height: `${scaledHeight}px`,
                  }}>
                  <img
                    src={url.replace(/\/\/t1|\/\/bz/g, '//twg')}
                    width={scaledWidth}
                    height={scaledHeight}
                    onLoad={isVisibleAnim ? onLoad : undefined}
                  />
                </span>
              ) : (
                <img
                  src={url}
                  width={scaledWidth}
                  height={scaledHeight}
                  onLoad={isVisibleAnim ? onLoad : undefined}
                />
              )
            ) : (
              <span className="bg_file" />
            )}
          </span>
          <span className="frame_g" />
          {isVisibleAnim && (
            <span
              ref={ref}
              className={cx('load_img', {
                'animated fadeOut': isAnimStarted,
              })}>
              <span className="ico_comm ico_loading">로딩완료</span>
            </span>
          )}
          {isSelected && (
            <span className="ico_comm ico_liston">{selectedOrder}</span>
          )}
        </span>
        {deletable && (
          <button
            type="button"
            className="btn_del"
            onClick={e => {
              e.stopPropagation()

              if (typeof handleDelete === 'function') {
                handleDelete(item, index)
              }
            }}>
            <span className="ico_comm ico_del">삭제</span>
          </button>
        )}
        {isAvailableEdit && (
          <CreativeLibraryImageEditButton
            index={index}
            onClick={openCreativeLibraryImageEditor}
            items={prevEditedItems}
          />
        )}
      </a>
      <p className="txt_file">
        {sizeText || '-'}
        {needEdit && (
          <DynamicTooltip
            content={Tooltip('create_ads.v2.ad_library.common.invalid_aspect')}>
            <a className="link_g link_help">
              <span className="ico_comm ico_info">도움말</span>
            </a>
          </DynamicTooltip>
        )}
      </p>
      <CmpAssetLibraryFileName fileName={originalFileName} />
    </li>
  )
}

CreativeLibraryImageView.propTypes = {
  index: PropTypes.number,
  selectedOrder: PropTypes.number,
  item: ImmutablePropTypes.map,
  isVisibleAnim: PropTypes.bool,
  deletable: PropTypes.bool,
  isBizBoardBanner: PropTypes.bool,
  multiSelect: PropTypes.bool,
  handleDelete: PropTypes.func,
  onImageLoaded: PropTypes.func,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()),
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  handleImageCropperOpen: PropTypes.func,
}

export default CreativeLibraryImageView
