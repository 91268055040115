import React, { useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PopupHOC from '../../../Popup/PopupHOC'
import { hasFullWidthWhitespace } from '../../../../utils/regexUtils'

import CmpTextInput from '../../../AdvertiseV2/Creative/Cmp/CmpInput/CmpTextInput'

ReportUpdateNamePopUp.propTypes = {
  name: PropTypes.string,
  close: PropTypes.func,
  onUpdateName: PropTypes.func,
}

function ReportUpdateNamePopUp({ close, name, onUpdateName }) {
  const [newName, setNewName] = useState('')
  const [error, setError] = useState()

  const inActive = useMemo(() => {
    return String(newName).trim().length === 0
  }, [newName])

  const onChange = useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    setNewName(e.target.value)
  }, [])

  const onSubmit = useCallback(
    e => {
      e.preventDefault()

      if (inActive) return
      if (hasFullWidthWhitespace(newName)) {
        setError('전각 공백문자는 입력할 수 없습니다.')
        return
      } else {
        setError(undefined)
      }

      onUpdateName(String(newName).trim())
      close()
    },
    [close, inActive, newName, onUpdateName]
  )

  const onCancel = useCallback(
    e => {
      e.preventDefault()
      close()
    },
    [close]
  )

  return (
    <div className="inner_basic_layer">
      <form onSubmit={onSubmit}>
        <div className="layer_head">
          <strong className="tit_layer">보고서 이름 수정</strong>
        </div>
        <div className="layer_body">
          <strong className="tit_copy fst">보고서 이름</strong>
          <CmpTextInput
            value={newName}
            onChange={onChange}
            onEnterKey={onSubmit}
            placeholder={name}
            maxLength={50}
            error={error}
            isShowingInputLength
          />
          {error && <p className="txt_error">{error}</p>}
        </div>
        <div className="layer_foot">
          <div className="btn_group">
            <button className="btn_gm" onClick={onCancel}>
              <span className="inner_g">취소</span>
            </button>
            <button
              type="submit"
              className={classNames(['btn_gm gm_bl', { in_active: inActive }])}
              disabled={inActive}>
              <span className="inner_g">저장</span>
            </button>
          </div>
          <a className="btn_close" onClick={close}>
            <span className="ico_comm ico_close">닫기</span>
          </a>
        </div>
      </form>
    </div>
  )
}

export default PopupHOC(ReportUpdateNamePopUp, {
  subClassName: 'savesearch_wrap',
})
