import React from 'react'
import PopupProxy from '../../../../Popup/Common/PopupProxy'
import { toAudienceDetailPath } from '../../../../../utils/router/routeUtils'

export const AUDIENCE_MAX_COUNT = 50
export const MY_DATA_TARGET_MAX_COUNT = 10

export const exceedAudienceCountDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">오디언스 등록 개수 초과</strong>
      <p className="txt_layer">
        오디언스는 계정당 {AUDIENCE_MAX_COUNT}개까지 등록 가능합니다.
        <br />
        기존 오디언스를 삭제 후 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

export const modifyLoadedAudienceDialog = (
  adAccountId,
  audienceId,
  buttonCallback
) => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 수정</strong>
      <p className="txt_layer">
        오디언스를 수정하면, 기존 오디언스와 이 광고그룹과의 <br />
        연결은 해제됩니다. 계속하시겠습니까?
      </p>
      <div className="box_info">
        기존 오디언스로 연결하려면 <br />
        <a
          className="fc_emph"
          rel="noopener noreferrer"
          target="_blank"
          href={toAudienceDetailPath(adAccountId, audienceId)}>
          타겟관리 &gt; 오디언스 관리
        </a>
        에서 수정 후 불러오기 하세요.
      </div>
    </PopupProxy>
  )
}

export const deleteLoadedAudienceDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 삭제</strong>
      <p className="txt_layer">
        불러온 오디언스 사용이 해제되고, <br />
        모든 설정이 초기화됩니다. 계속하시겠습니까?
      </p>
    </PopupProxy>
  )
}

export const exceedAudienceMyDataTargetCountDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">내 데이터 등록 개수 초과</strong>
      <p className="txt_layer">
        내 데이터는 {MY_DATA_TARGET_MAX_COUNT}개까지 등록 가능합니다.
      </p>
    </PopupProxy>
  )
}

export const deleteAudienceDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 삭제</strong>
      <p className="txt_layer">
        오디언스를 삭제하시겠습니까?
        <br />
        사용 중인 광고그룹이 있는 경우, 오디언스를 삭제하더라도
        <br />
        광고그룹에 설정되어있는 타겟은 계속 적용됩니다.
      </p>
    </PopupProxy>
  )
}

export const modifyAudienceDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 수정</strong>
      <p className="txt_layer">
        오디언스를 수정 하시겠습니까?
        <br />
        오디언스를 사용 중인 광고그룹이 있는 경우,
        <br />
        수정사항이 일괄 적용됩니다.
      </p>
    </PopupProxy>
  )
}

export const createAudienceCancelDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 만들기 취소</strong>
      <p className="txt_layer">
        오디언스 만들기를 취소하시겠습니까?
        <br />
        저장하지 않은 내용은 모두 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const modifyAudienceCancelDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 수정 취소</strong>
      <p className="txt_layer">
        오디언스 수정을 취소하시겠습니까?
        <br />
        저장하지 않은 내용은 모두 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const modifyUnavailableAudienceDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">오디언스 수정 불가</strong>
      <p className="txt_layer">
        오디언스를 사용 중인 광고그룹이 있는 경우,
        <br />
        사용가능 상태가 아닌 타겟이 설정되어 있으면
        <br />
        수정이 불가합니다. 사용가능 상태인 타겟으로만
        <br />
        오디언스를 설정하세요.
      </p>
    </PopupProxy>
  )
}

export const modifyUnavailableCampaignTypeGoalAudienceDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">오디언스 수정 불가</strong>
      <p className="txt_layer">
        카카오톡 채널 X 도달 캠페인 광고그룹에 사용 중인
        <br />
        오디언스는 수정 불가합니다.
        <br />
        해당 광고그룹에서 오디언스 사용 해제한 후
        <br />
        다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

export const unusedAudienceDialog = buttonCallback => {
  return (
    <PopupProxy primaryButtonFunc={buttonCallback} hasSecondaryButton={true}>
      <strong className="tit_layer">오디언스 사용 해제</strong>
      <p className="txt_layer">
        해당 광고그룹에서 오디언스의 사용을 해제하시겠습니까?
        <br />
        사용 해제하더라도 광고그룹에 설정되어있는
        <br />
        타겟은 계속 적용됩니다.
      </p>
    </PopupProxy>
  )
}
