import { combineReducers } from 'redux-immutablejs'
import common from './mMessageCommon'
import create from './mMessageCreate'
import modify from './mMessageModify'

export default combineReducers({
  common,
  create,
  modify,
})
