import React from 'react'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Footer from '../../components/Footer/Footer'

const CruxAdAccount = React.lazy(() =>
  import('../../components/AdAccount/CruxAdAccount')
)

const AdAccountWrapper = () => {
  return (
    <div id="kakaoWrap" className="layout_fix">
      <Gnb />
      <hr className="hide" />
      <div id="kakaoMain">
        <main id="kakaoContent">
          <article id="mArticle">
            <React.Suspense fallback="">
              <CruxAdAccount />
            </React.Suspense>
          </article>
        </main>
        <hr className="hide" />
        <Footer />
      </div>
    </div>
  )
}

export default AdAccountWrapper
