import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    PIXEL_AND_SDK: null,
    TALK_CHANNEL: null,
    CATALOG: null,
  }),

  Name: {
    PIXEL_AND_SDK: '픽셀 & SDK',
    TALK_CHANNEL: '카카오톡 채널',
    CATALOG: '카탈로그',
  },

  getName(type) {
    return this.Name[type]
  },
}
