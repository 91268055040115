import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

const BizBoardBannerButtonGroup = ({
  isVisibleButtonGroup = true,
  active = true,
  handleSave,
  handleClose,
  continuable,
}) => {
  return (
    <div className="layer_foot">
      {isVisibleButtonGroup && (
        <div className="btn_group">
          <a className="btn_gm" onClick={handleClose}>
            <span className="inner_g">취소</span>
          </a>
          <button
            type="button"
            className={cx('btn_gm', {
              in_active: !active,
              gm_line: continuable,
              gm_bl: !continuable,
            })}
            onClick={() => {
              if (active) {
                handleSave(true)
              }
            }}>
            <span className="inner_g">저장</span>
          </button>
          {continuable && (
            <button
              type="button"
              className={cx('btn_gm gm_bl', { in_active: !active })}
              onClick={() => {
                if (active) {
                  handleSave(false)
                }
              }}>
              <span className="inner_g">저장하고 계속 만들기</span>
            </button>
          )}
        </div>
      )}
      <a className="btn_close" onClick={handleClose}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

BizBoardBannerButtonGroup.propTypes = {
  isVisibleButtonGroup: PropTypes.bool,
  active: PropTypes.bool,
  handleSave: PropTypes.func,
  handleClose: PropTypes.func,
  continuable: PropTypes.bool,
}

export default BizBoardBannerButtonGroup
