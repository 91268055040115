import axios from 'axios'
import { KakaoLoungeBoard } from './app/services/kakaoLoungeBoard'
import { identity } from 'lodash'

export const SERVICE_TYPE = 'KAKAOMOMENT'

const loungeBoardFetchUtils = axios.create({
  baseURL: KakaoLoungeBoard.getHost(),
  withCredentials: true,
})

loungeBoardFetchUtils.interceptors.request.use(identity, error =>
  Promise.reject(error)
)
loungeBoardFetchUtils.interceptors.response.use(identity, error => {
  return Promise.reject(error)
})
export default loungeBoardFetchUtils
