import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  LNB_STORAGE_ITEM,
  LnbStorageLocal,
} from '../../utils/storage/storageFactoryImpl'

const Lnb = keyMirror(
  {
    SET_IS_EXPANDED: null,
    TOGGLE_EXPANDER: null,

    SET_AD_ACCOUNT_INFO: null,
  },
  'LNB'
)

const initialState = fromJS({
  isExpanded: LnbStorageLocal.get(LNB_STORAGE_ITEM.IS_EXPANDED) ?? true,

  adAccountInfo: {},
})

export default createReducer(initialState, {
  [Lnb.SET_IS_EXPANDED]: (state, { isExpanded }) => {
    return state.set('isExpanded', isExpanded)
  },

  [Lnb.TOGGLE_EXPANDER]: state => {
    return state.update('isExpanded', prevIsExpanded => !prevIsExpanded)
  },

  [Lnb.SET_AD_ACCOUNT_INFO]: (state, { adAccountInfo }) => {
    return state.set('adAccountInfo', fromJS(adAccountInfo))
  },
})

function updateLnbStorageLocal({ isExpanded }) {
  LnbStorageLocal.set(LNB_STORAGE_ITEM.IS_EXPANDED, isExpanded)
}

export function toggleLnbExpander() {
  return (dispatch, getState) => {
    dispatch({ type: Lnb.TOGGLE_EXPANDER })

    const {
      lnb: { isExpanded },
    } = getState()

    updateLnbStorageLocal({ isExpanded })
  }
}

export function setLnbAdAccountInfo({ adAccountInfo }) {
  return {
    type: Lnb.SET_AD_ACCOUNT_INFO,
    adAccountInfo,
  }
}
