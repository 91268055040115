import { keyMirror } from '../utils/utils'

const AdditionalRoleEnum = {
  Type: keyMirror({
    NONE: null,
    IN_HOUSE: null,
    COMMUNITY: null,
  }),

  Name: {
    NONE: '일반',
    IN_HOUSE: '사내',
    COMMUNITY: '공동체',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

export default AdditionalRoleEnum
