import { Map } from 'immutable'
import {
  checkEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
} from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  messageCouponBookAssetGroupValidation,
  messageFormButtonAssetGroupValidation,
  messageFormTitleValidation,
} from './messageFormValidator'
import { MESSAGE_FORM_VALIDATION_KEY } from './messageFormValidationKey'
import { MESSAGE_FORM_VALIDATION_MESSAGE } from './messageFormValidationMessage'
import VideoEnum from '../../enums/VideoEnum'

export const BASIC_TEXT_MESSAGE_FORM_VALIDATOR = Map({
  [MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [MESSAGE_FORM_VALIDATION_KEY.ITEM]: ({ video, messageVideoTitle }) => {
    if (
      checkNotEmpty(video) &&
      VideoEnum.isTitleRequired(video?.get('videoType'))
    ) {
      if (checkEmpty(messageVideoTitle)) {
        return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      } else if (hasFullWidthWhitespace(messageVideoTitle)) {
        return IS_NOT_VALID(
          MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      }
    }
    return IS_VALID()
  },
  [MESSAGE_FORM_VALIDATION_KEY.TITLE]: ({ title, creativeFormat }) => {
    return messageFormTitleValidation(title, creativeFormat)
  },
  [MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]: buttonAssetGroups => {
    return messageFormButtonAssetGroupValidation(buttonAssetGroups)
  },
  [MESSAGE_FORM_VALIDATION_KEY.COUPON_BOOK_ASSET_GROUPS]:
    couponBookAssetGroups => {
      return messageCouponBookAssetGroupValidation(couponBookAssetGroups)
    },
})
