import open_114 from './apng/open_114.png'
import open_72 from './apng/open_72.png'
import play_114 from './apng/play_114.png'
import play_72 from './apng/play_72.png'
import motion_open_72 from './apng/motion_open_72.png'
import motion_open_114 from './apng/motion_open_114.png'
import motion_play_72 from './apng/motion_play_72.png'
import motion_play_114 from './apng/motion_play_114.png'
import { keyMirror } from '../../../../../utils/utils'
import { BizBoardImageSpec } from '../bizBoardUtils'

const EXPANDABLE_HINT_TYPE = keyMirror({
  PLAY: null,
  OPEN: null,
  MOTION_BOARD_PLAY: null,
  MOTION_BOARD_OPEN: null,
})

const EXPANDABLE_HINT_PREVIEW_TYPE = keyMirror({
  BIZ_BOARD_BANNER_CREATE: null,
  CREATIVE_CREATE: null,
  DASHBOARD_PREVIEW: null,
  DASHBOARD_MODIFYING_HISTORY: null,
})

const PREVIEW_WIDTH = {
  BIZ_BOARD_BANNER_CREATE: 638,
  CREATIVE_CREATE: 402,
  DASHBOARD_PREVIEW: 640,
  DASHBOARD_MODIFYING_HISTORY: 402,
}

/**
 * intrinsic width `bizBoardBanner : hint` = `1029 : 165`
 * - bizBoard image editor preview = 638 : 102((638 / 1029) * 165)
 * - creative create/modify preview = 402 : 64((402 / 1029) * 165)
 * - dashboard preview = 640 : 102(640 / 1029) * 165)
 * - dashboard modifying history preview = 402 : 64(402 / 1029) * 165)
 */
const EXPANDABLE_HINT_APNG_SRC = {
  PLAY: {
    [EXPANDABLE_HINT_PREVIEW_TYPE.BIZ_BOARD_BANNER_CREATE]: play_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.CREATIVE_CREATE]: play_72,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_PREVIEW]: play_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_MODIFYING_HISTORY]: play_72,
  },
  OPEN: {
    [EXPANDABLE_HINT_PREVIEW_TYPE.BIZ_BOARD_BANNER_CREATE]: open_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.CREATIVE_CREATE]: open_72,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_PREVIEW]: open_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_MODIFYING_HISTORY]: open_72,
  },
  MOTION_BOARD_PLAY: {
    [EXPANDABLE_HINT_PREVIEW_TYPE.BIZ_BOARD_BANNER_CREATE]: motion_play_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.CREATIVE_CREATE]: motion_play_72,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_PREVIEW]: motion_play_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_MODIFYING_HISTORY]: motion_play_72,
  },
  MOTION_BOARD_OPEN: {
    [EXPANDABLE_HINT_PREVIEW_TYPE.BIZ_BOARD_BANNER_CREATE]: motion_open_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.CREATIVE_CREATE]: motion_open_72,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_PREVIEW]: motion_open_114,
    [EXPANDABLE_HINT_PREVIEW_TYPE.DASHBOARD_MODIFYING_HISTORY]: motion_open_72,
  },
}

const EXPANDABLE_HINT_PADDING = {
  RIGHT: 24,
  BOTTOM: 18,
}

const ExpandableHintUtils = {
  ratio({ previewType }) {
    return PREVIEW_WIDTH[previewType] / BizBoardImageSpec.INTRINSIC_BANNER_WIDTH
  },

  styles({ previewType }) {
    const ratio = this.ratio({ previewType })
    return {
      paddingRight: EXPANDABLE_HINT_PADDING.RIGHT * ratio,
      paddingBottom: EXPANDABLE_HINT_PADDING.BOTTOM * ratio,
    }
  },
}

export {
  EXPANDABLE_HINT_TYPE,
  EXPANDABLE_HINT_PREVIEW_TYPE,
  EXPANDABLE_HINT_APNG_SRC,
  ExpandableHintUtils,
}
