import React, { PureComponent } from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch } from 'react-router-dom'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Lnb from '../../components/Navigation/Lnb/Lnb'
import Footer from '../../components/Footer/Footer'

const ReviewDocumentManagement = React.lazy(() =>
  import('../settings/reviewDocument/reviewDocumentManagement')
)
const ReviewDocumentDetail = React.lazy(() =>
  import('../settings/reviewDocument/reviewDocumentDetail')
)
const CashManagement = React.lazy(() => import('../settings/cashManagement'))
const PaymentCardManagement = React.lazy(() =>
  import(
    '../../components/Settings/PaymentCardManagement/PaymentCardManagement'
  )
)
const EditLogManagement = React.lazy(() =>
  import('../settings/editLog/EditLogManagement')
)
const AdAccountManagementWrapper = React.lazy(() =>
  import(
    '../../components/Settings/AccountManagement/AdAccountManagementWrapper'
  )
)

class LayoutSettingsWrapper extends PureComponent {
  render() {
    return (
      <div id="kakaoWrap">
        <Gnb />
        <hr className="hide" />
        <main id="kakaoContent">
          <Lnb />
          <React.Suspense fallback="">
            <Switch>
              <Route
                path="/:adaccountid(\d+)/settings/account"
                component={AdAccountManagementWrapper}
              />
              <Route
                path="/:adaccountid(\d+)/settings/review"
                component={ReviewDocumentManagement}
              />
              <Route
                path="/:adaccountid(\d+)/settings/reviewdetail/:creativeCategoryId(\d+)"
                component={ReviewDocumentDetail}
              />
              <Route
                path="/:adaccountid(\d+)/settings/cash"
                component={CashManagement}
              />
              <Route
                path="/:adaccountid(\d+)/settings/paymentcard"
                component={PaymentCardManagement}
              />
              <Route
                path="/:adaccountid(\d+)/settings/editlog"
                component={EditLogManagement}
              />
            </Switch>
          </React.Suspense>
        </main>
        <hr className="hide" />
        <Footer />
      </div>
    )
  }
}

LayoutSettingsWrapper.propTypes = {
  match: ReactRouterPropTypes.match,
  location: ReactRouterPropTypes.location,
}

export default LayoutSettingsWrapper
