import { fetchGet, fetchPost } from '../../utils/fetchUtils'

/**
 * 전체 캠페인 하위 그룹/소재 운영정보
 */
function fetchCampaignOperationTotalSummary({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/campaigns/countByStatus',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 단건 캠페인 하위 그룹/소재 운영정보
 */
function fetchCampaignOperationSummary({
  adAccountId,
  campaignId,
  cancelTokenSource,
}) {
  return fetchGet(
    `/search/dashboard/campaigns/${campaignId}/adGroups/countByStatus`,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 전체 그룹 소재 운영정보
 */
function fetchAdGroupOperationTotalSummary({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/adGroups/countByStatus',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 단건 그룹 소재 운영정보
 */
function fetchAdGroupOperationSummary({
  adAccountId,
  adGroupId,
  cancelTokenSource,
}) {
  return fetchGet(
    `/search/dashboard/adGroups/${adGroupId}/creatives/countByStatus`,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 광고계정 오늘의 요약
 */
function fetchAdAccountTodayIndex({ adAccountId, cancelTokenSource }) {
  return fetchGet(
    '/aggregation/report/dashboard/adAccounts/cost',
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 캠페인 오늘의 요약
 */
function fetchCampaignTodayIndex({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/campaigns/cost',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 그룹 오늘의 요약
 */
function fetchAdGroupTodayIndex({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/adGroups/cost',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 소재 오늘의 요약
 */
function fetchCreativeTodayIndex({
  adAccountId,
  requestBody = {},
  cancelTokenSource,
}) {
  return fetchPost(
    '/aggregation/report/dashboard/creatives/cost',
    requestBody,
    adAccountId,
    cancelTokenSource
  )
}

/**
 *  후심사 권한 요약
 */
function fetchCreativeReviewAuthorityInfo({ adAccountId }) {
  return fetchGet(
    `moment/adAccounts/${adAccountId}/creativeReviewAuthorityInfo`,
    adAccountId
  )
}

export default {
  fetchCampaignOperationTotalSummary,
  fetchCampaignOperationSummary,
  fetchAdGroupOperationTotalSummary,
  fetchAdGroupOperationSummary,
  fetchAdAccountTodayIndex,
  fetchCampaignTodayIndex,
  fetchAdGroupTodayIndex,
  fetchCreativeTodayIndex,
  fetchCreativeReviewAuthorityInfo,
}
