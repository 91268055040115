import { checkNotEmpty, isUndefinedOrNull } from '../regexUtils'
import { fromJS, List, Map } from 'immutable'
import { RoleType } from '../../modules/common/mRole'

export const AdAccountHelper = {
  // 삭제, 삭제 신청 중, 삭제 처리 중, wallet 삭제 상태일 경우 invalid.
  isValid(adAccount) {
    const { userConfig } = fromJS(adAccount || {})
    return (
      !isUndefinedOrNull(userConfig) &&
      !['PENDING_DEL', 'PROCESSING_DEL', 'WALLET_DELETED', 'DEL'].includes(
        userConfig
      )
    )
  },

  ListSelector: {
    toView(adAccountList) {
      if (!adAccountList) return []

      return adAccountList.map(adAccount => {
        return this.toAdAccount(adAccount)
      })
    },
    toAdAccount(adAccount) {
      if (!adAccount) return {}
      const { id, status, master, name, createdDate, adAccountType } = adAccount

      return {
        adAccountId: id,
        role: master ? RoleType.MASTER : RoleType.MEMBER,
        adAccountStatus: status,
        name,
        createdDate,
        adAccountType,
      }
    },
  },

  // {
  //   "id": 2,
  //   "name": "2Tab 성과형",
  //   "status": "ACTIVE",
  //   "type": "PRIVATE_AUCTION",
  //   "sspDealId": "2tab_performance",
  //   "frequencyCapMinCount": 1,
  //   "frequencyCapMaxCount": 3,
  //   "dealPricingStrategies": [
  //   {
  //     "pricingType": "CPC",
  //     "minBidAmount": 200,
  //     "guideBidAmount": 500,
  //     "fixedBidAmount": false,
  //     "fixedBidStrategy": "MANUAL"
  //   }
  // ]
  // }
  Deal: {
    /**
     * deal 존재 여부.
     * @param adAccount {Map}
     * @return {boolean}
     */
    exist(adAccount) {
      const { deals } = fromJS(adAccount || {})
      return deals?.size > 0
    },

    /**
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @return {boolean}
     */
    hasCategory(adAccount, dealCategory) {
      const { deals } = fromJS(adAccount)
      return deals?.some(v => v.get('dealCategory') === dealCategory) || false
    },

    /**
     *
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @return {List | undefined}
     */
    getByCategory(adAccount, dealCategory) {
      const { deals } = fromJS(adAccount)
      return deals?.filter(deal => deal.get('dealCategory') === dealCategory)
    },

    /**
     *
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param placementStrategy {object}
     * @return {List | undefined}
     */
    getByCategoryAndPlacementStrategy(
      adAccount,
      dealCategory,
      placementStrategy = { device: undefined, placement: undefined }
    ) {
      return this.getByCategory(adAccount, dealCategory)?.filter(deal => {
        const { dealPlacementStrategies } = deal
        return (
          dealPlacementStrategies.find(v => {
            const d = checkNotEmpty(placementStrategy.device)
              ? v.get('device') === placementStrategy.device
              : true
            const p = checkNotEmpty(placementStrategy.placement)
              ? v.get('placement') === placementStrategy.placement
              : true
            return d && p
          }) !== undefined
        )
      })
    },

    /**
     * 특정 `pricingType` 에 대한 입찰 전략이 존재하는 지 여부.
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {boolean}
     */
    hasPricingStrategy(adAccount, dealCategory, pricingType) {
      const { dealPricingStrategies } =
        this.getByCategory(adAccount, dealCategory)?.first() || {}
      return (
        dealPricingStrategies?.find(
          v => v.get('pricingType') === pricingType
        ) !== undefined
      )
    },

    /**
     * deal 하위 입찰 전략이 존재하는 지 여부(deal 은 있지만 존재하지 않을 수 있다).
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @return {boolean}
     */
    hasPricingStrategies(adAccount, dealCategory) {
      const { dealPricingStrategies } =
        this.getByCategory(adAccount, dealCategory)?.first() || {}
      return dealPricingStrategies?.size > 0
    },

    /**
     * 특정 `pricingType` 에 대한 pricingStrategy 가져오기.
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {Map}
     */
    getPricingStrategy(adAccount, dealCategory, pricingType) {
      const { dealPricingStrategies } =
        this.getByCategory(adAccount, dealCategory)?.first() || {}
      return (
        dealPricingStrategies?.find(
          v => v.get('pricingType') === pricingType
        ) || Map()
      )
    },

    /**
     * pricingStrategy 를 갖고 있는 pricingType list 가져오기.
     * @param adAccount
     * @param dealCategory {string}
     * @return {List}
     */
    getPricingTypes(adAccount, dealCategory) {
      const { dealPricingStrategies } =
        this.getByCategory(adAccount, dealCategory)?.first() || {}
      return dealPricingStrategies?.map(v => v.get('pricingType')) || List()
    },

    /**
     * 특정 `pricingType` 에 대한 minBidAmount 가 고정 값인지 여부.
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {boolean}
     */
    getFixedBidAmount(adAccount, dealCategory, pricingType) {
      const { fixedBidAmount } = this.getPricingStrategy(
        adAccount,
        dealCategory,
        pricingType
      )

      return fixedBidAmount || false
    },

    /**
     * 특정 `pricingType` 에 대한 최소 입찰가 | 고정 입찰가.
     * - fixedBidAmount 가 true 일 경우 고정 입찰가, false 일 경우 최소 입찰가
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {number | undefined}
     */
    getMinBidAmount(adAccount, dealCategory, pricingType) {
      const { minBidAmount } = this.getPricingStrategy(
        adAccount,
        dealCategory,
        pricingType
      )
      return minBidAmount
    },

    /**
     * 특정 `pricingType` 에 대해 플랫폼에서 제시하는 기본 입찰가.
     * - Input 에 기본으로 세팅된다.
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {number | undefined}
     */
    getGuideBidAmount(adAccount, dealCategory, pricingType) {
      const { guideBidAmount } = this.getPricingStrategy(
        adAccount,
        dealCategory,
        pricingType
      )
      return guideBidAmount
    },

    /**
     * 특정 `pricingType` 에 대한 고정 입찰 전략.
     * @param adAccount {Map}
     * @param dealCategory {string}
     * @param pricingType {string}
     * @return {string | undefined}
     */
    getFixedBidStrategy(adAccount, dealCategory, pricingType) {
      const { fixedBidStrategy } = this.getPricingStrategy(
        adAccount,
        dealCategory,
        pricingType
      )
      return fixedBidStrategy
    },
  },
}
