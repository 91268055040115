import { DOMAIN } from '../../fetchUtils'
import { KakaoBizCenter, kakaoBizCenterDashboardURL } from './kakaoBizCenter'
import { getApiPhase, isMomentRootPath } from './kakaoMoment'
import { toMyAdAccountListPath } from '../../router/routeUtils'

const KakaoAccount = {
  Host: {
    production: 'https://accounts.kakao.com',
    stage: 'https://beta-accounts.kakao.com',
    sandbox: 'https://sandbox-accounts.kakao.com',
    develop: 'https://sandbox-accounts.kakao.com',
  },

  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },
}

/**
 * login() | kakaoLogoutURL() 을 통해서만 접근
 * https://wiki.daumkakao.com/pages/viewpage.action?pageId=485203376
 * @param redirectBack {boolean} - 로그인 후 return url 을 현재 Path 로 유지할 지 여부.
 */
export const kakaoLoginURL = ({ redirectBack = false } = {}) => {
  const host = KakaoAccount.Host[getApiPhase()] || KakaoAccount.Host.sandbox
  const baseURL = `${host}/login`
  const redirectURL =
    DOMAIN +
    (redirectBack && !isMomentRootPath()
      ? window.location.pathname + window.location.search
      : toMyAdAccountListPath())

  if (__NOT_PRODUCTION__) {
    console.log({ redirectBack, redirectURL })
  }

  if (isMomentRootPath()) {
    return `${baseURL}?continue=${kakaoBizCenterDashboardURL(redirectURL)}`
  } else {
    return `${baseURL}?continue=${encodeURIComponent(redirectURL)}`
  }
}

/**
 * logout() 을 통해서만 접근
 */
export const kakaoLogoutURL = () => {
  const host = KakaoAccount.Host[getApiPhase()] || KakaoAccount.Host.sandbox
  const baseURL = `${host}/logout`
  return `${baseURL}?continue=${KakaoBizCenter.getHost()}`
}
