import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cx from 'classnames'
import { checkNotEmpty } from '../../../../../../utils/regexUtils'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { ScaleImageFitCenter } from '../../../../../../utils/imageUtils'
import { useAnimateCSS } from '../../../../../../utils/hook/useAnimateCSS'
import { Tooltip } from '../../../../../../resources/locale'
import DynamicTooltip from '../../../../../Tooltip/DynamicTooltip'
import {
  selectCmpAssetLibraryImageItem,
  setCmpAssetLibraryImageSelectedItems,
} from '../../../../../../modules/cmp/mCmpAssetLibraryImage'
import { convertStringToBoldFaceByQuery } from '../../../../../../utils/stringUtils'
import { floor } from 'lodash'
import CmpAssetLibraryImageCropperOpenButton from './CmpAssetLibraryImageCropperOpenButton'
import EllipsisTooltip from '../../../../../Tooltip/TooltipImpl/EllipsisTooltip'
import { List } from 'immutable'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'

const selector = ({
  cmpAssetLibrary: {
    image: { editedItemsMap },
  },
}) => {
  return {
    editedItemsMap,
  }
}

const CmpAssetLibraryImageView = ({
  index,
  selectedOrder,
  item,
  cropAspects = [],
  query = '',
  isVisibleAnim = true,
  multiSelect,
  deletable = false,
  isBizBoardBanner = false,
  onImageLoaded,
  creativeFormat,
  handleImageCropperOpen,
  handleDelete,
  validateImageSize,
  handleImageEditorOpenForModify,
}) => {
  const dispatch = useDispatch()

  const { editedItemsMap } = useSelector(selector, shallowEqual)

  const { imageUUID, url, imageWidth, imageHeight, originalFileName } =
    item || {}

  const hasUrl = checkNotEmpty(url)

  const { ref, onLoad, isAnimStarted } = useAnimateCSS({
    onAnimEnded: () => {
      if (isVisibleAnim) {
        if (typeof onImageLoaded === 'function') {
          onImageLoaded(imageUUID)
        }
      }
    },
  })

  const isSelected = selectedOrder > 0

  const sizeText = [imageWidth, imageHeight].filter(Boolean).join('x')

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    imageWidth,
    imageHeight,
    150,
    150
  )

  const editedItems = React.useMemo(() => {
    return editedItemsMap.get(imageUUID)
  }, [editedItemsMap, imageUUID])

  const canCrop = cropAspects.length > 0
  const alreadyCropped = editedItems?.some(
    ({ editedImageWidth, editedImageHeight }) =>
      cropAspects.includes(floor(editedImageWidth / editedImageHeight, 4))
  )

  const isImageSizeValid = React.useMemo(
    () =>
      alreadyCropped
        ? editedItems.some(({ editedImageWidth, editedImageHeight }) =>
            validateImageSize({
              originWidth: imageWidth,
              originHeight: imageHeight,
              croppedWidth: editedImageWidth,
              croppedHeight: editedImageHeight,
            })
          )
        : validateImageSize({
            originWidth: imageWidth,
            originHeight: imageHeight,
          }),
    [alreadyCropped, imageHeight, imageWidth, editedItems, validateImageSize]
  )

  const notSupportedImageSize = !isImageSizeValid && !canCrop
  const invalidImageAspect = !isImageSizeValid && canCrop

  const openCropper = React.useCallback(() => {
    if (
      creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE ||
      creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL ||
      creativeFormat === CreativeFormatEnum.Type.VIDEO_NATIVE ||
      creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE
    ) {
      handleImageEditorOpenForModify(item)
    } else {
      handleImageCropperOpen({
        imageUUID,
        items: alreadyCropped ? List(editedItems) : List.of(item),
        originalItem: item,
      })
    }
  }, [
    alreadyCropped,
    creativeFormat,
    editedItems,
    handleImageCropperOpen,
    handleImageEditorOpenForModify,
    imageUUID,
    item,
  ])

  return (
    <li
      className={cx({
        on: !multiSelect && isSelected,
        on_order: multiSelect && isSelected,
        load_ok: isVisibleAnim && hasUrl,
      })}>
      <a
        className="link_material"
        onClick={e => {
          e.preventDefault()

          if (multiSelect) {
            dispatch(selectCmpAssetLibraryImageItem({ item }))
          } else {
            dispatch(
              setCmpAssetLibraryImageSelectedItems({
                items: isSelected ? [] : [item],
              })
            )
          }
        }}>
        <span className="img_file">
          <span className="inner_img_file">
            {url ? (
              isBizBoardBanner ? (
                <span
                  style={{
                    display: 'block',
                    backgroundColor: '#f3f3f3',
                    height: `${scaledHeight}px`,
                  }}>
                  <img
                    src={url}
                    width={scaledWidth}
                    height={scaledHeight}
                    onLoad={isVisibleAnim ? onLoad : undefined}
                  />
                </span>
              ) : (
                <img
                  src={url}
                  width={scaledWidth}
                  height={scaledHeight}
                  onLoad={isVisibleAnim ? onLoad : undefined}
                />
              )
            ) : (
              <span className="bg_file" />
            )}
          </span>
          <span className="frame_g" />
          {isVisibleAnim && (
            <span
              ref={ref}
              className={cx('load_img', {
                'animated fadeOut': isAnimStarted,
              })}>
              <span className="ico_comm ico_loading">로딩완료</span>
            </span>
          )}
          {isSelected && (
            <span className="ico_comm ico_liston">{selectedOrder}</span>
          )}
        </span>
        {deletable && (
          <button
            type="button"
            className="btn_del"
            onClick={e => {
              e.stopPropagation()

              if (typeof handleDelete === 'function') {
                handleDelete(item)
              }
            }}>
            <span className="ico_comm ico_del">삭제</span>
          </button>
        )}
        {canCrop && (
          <CmpAssetLibraryImageCropperOpenButton
            index={index}
            onClick={openCropper}
            items={editedItems}
          />
        )}
      </a>
      <p className="txt_file">
        {sizeText || '-'}
        {(notSupportedImageSize || invalidImageAspect) && (
          <DynamicTooltip
            content={Tooltip(
              notSupportedImageSize
                ? 'create_ads.v2.ad_library.common.not_supported_image_size'
                : 'create_ads.v2.ad_library.common.invalid_aspect'
            )}>
            <a className="link_g link_help">
              <span className="ico_comm ico_info">도움말</span>
            </a>
          </DynamicTooltip>
        )}
      </p>
      <EllipsisTooltip rootElementType="p" rootElementClassName="txt_filename">
        {convertStringToBoldFaceByQuery(
          String(originalFileName).normalize('NFC'),
          query,
          'fw_bold fc_emph'
        )}
      </EllipsisTooltip>
    </li>
  )
}

CmpAssetLibraryImageView.propTypes = {
  index: PropTypes.number.isRequired,
  selectedOrder: PropTypes.number,
  item: ImmutablePropTypes.map.isRequired,
  isVisibleAnim: PropTypes.bool,
  deletable: PropTypes.bool,
  isBizBoardBanner: PropTypes.bool,
  multiSelect: PropTypes.bool,
  handleDelete: PropTypes.func,
  onImageLoaded: PropTypes.func,
  query: PropTypes.string,
  cropAspects: PropTypes.array.isRequired,
  validateImageSize: PropTypes.func.isRequired,
  handleImageEditorOpenForModify: PropTypes.func.isRequired,
}

export default CmpAssetLibraryImageView
