import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Redirect, Route, Switch } from 'react-router-dom'
import { toNotFoundPath } from '../../utils/router/routeUtils'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Lnb from '../../components/Navigation/Lnb/Lnb'
import Footer from '../../components/Footer/Footer'

const BizForm = React.lazy(() => import('../linkage/bizForm/bizForm'))
const PixelAndSdk = React.lazy(() =>
  import('../../components/Linkage/PixelAndSdk/PixelAndSdkWrapper')
)

/**
 *  광고자산 관리 하위 > 비즈폼과 픽셀만 묶음..?
 */
const LayoutLinkageWrapper = () => {
  return (
    <div id="kakaoWrap">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb />
        <React.Suspense fallback="">
          <Switch>
            <Route
              path="/:adaccountid(\d+)/linkage/talkbizform"
              component={BizForm}
            />
            <Route
              path="/:adaccountid(\d+)/linkage/pixel"
              component={PixelAndSdk}
            />
            <Redirect to={toNotFoundPath()} />
          </Switch>
        </React.Suspense>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

LayoutLinkageWrapper.propTypes = {
  location: ReactRouterPropTypes.location,
}

export default LayoutLinkageWrapper
