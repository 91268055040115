import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    TALK_STORE: null,
  }),

  Name: {
    TALK_STORE: '톡스토어',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
