import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ASC: null,
    DESC: null,
  }),

  Name: {
    ASC: '오름차순',
    DESC: '내림차순',
  },

  getName(type) {
    return this.Name[type]
  },

  indexOf(type) {
    return type === this.Type.ASC ? 0 : type === this.Type.DESC ? 1 : undefined
  },

  values() {
    return Object.keys(this.Type)
  },
}
