import { createReducer } from 'redux-immutablejs'
import { fromJS, Map } from 'immutable'
import { keyMirror } from '../../utils/utils'

const AddressSearch = keyMirror({
  CHANGE_SEARCH_ADDRESS: null,
  CLEAR_SEARCH_ADDRESS: null
})

const initialState = Map()

export default createReducer(initialState, {
  [AddressSearch.CHANGE_SEARCH_ADDRESS]: (state, { payload }) =>
    fromJS(payload || {}),

  [AddressSearch.CLEAR_SEARCH_ADDRESS]: state => state.clear()
})

export const changeSearchAddress = payload => ({
  type: AddressSearch.CHANGE_SEARCH_ADDRESS,
  payload
})

export const clearSearchAddress = () => ({
  type: AddressSearch.CLEAR_SEARCH_ADDRESS
})
