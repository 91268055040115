import React from 'react'
import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { fromJS, List, Map } from 'immutable'
import {
  COHORT_TRIM_KEY_PATH_LIST,
  handleCohortException,
  setIsValidCohortByKey,
} from '../mCohort'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import {
  COHORT_INDICATOR,
  COHORT_OPERATION,
  createCohortTargetCondition,
} from '../../../utils/target/cohort'
import {
  toTargetingCohortPath,
  toUpdateTargetingCohortPath,
} from '../../../utils/router/routeUtils'
import { Validation } from '../../../validators/validation'
import { Trim } from '../../../utils/formTrim'
import {
  COHORT_FORM_VALIDATION_KEY,
  COHORT_FORM_VALIDATION_KEY_PATH,
  COHORT_FORM_VALIDATOR,
} from '../../../validators/targeting/cohortFormValidator'
import { hideLoading, showLoading } from '../../common/mLoading'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'

/**
 * 광고반응타겟 만들기
 */

export const COHORT_STEP_CODE = keyMirror({
  SELECT_TARGET: null,
  TARGET_SUMMARY: null,
})

const CohortCreate = keyMirror(
  {
    ADD_ADGROUP: null,
    REMOVE_ADGROUP: null,
    REMOVE_ADGROUP_ALL: null,
    STORE_ADGROUPS: null,
    STORE_CAMPAIGN: null,

    CHANGE_SELECTED_ADGROUP_TARGET_DATA: null,
    INIT_COHORT_AUDIENCE_TYPE: null,
    UPDATE_COHORT_AUDIENCE_TYPE: null,
  },
  'COHORT_CREATE'
)

// initial state
const initialState = fromJS({
  selectedAdGroups: [],
  selectedAdGroupIds: [],
  campaigns: [],

  // update
  name: null,
  cohortId: null,
  audienceType: null,
})

// reducer
export default createReducer(initialState, {
  [CohortCreate.ADD_ADGROUP]: (state, { campaignAdGroup }) =>
    state
      .update('selectedAdGroups', selectedAdGroups =>
        selectedAdGroups.unshift(fromJS(campaignAdGroup))
      )
      .update('selectedAdGroupIds', selectedAdGroupIds =>
        selectedAdGroupIds.unshift(campaignAdGroup.adGroupId)
      ),
  [CohortCreate.REMOVE_ADGROUP]: (state, { campaignAdGroup }) =>
    state
      .update('selectedAdGroups', selectedAdGroups =>
        selectedAdGroups.filter(
          group => group.get('adGroupId') !== campaignAdGroup.adGroupId
        )
      )
      .update('selectedAdGroupIds', selectedAdGroupIds =>
        selectedAdGroupIds.filter(
          adGroupId => adGroupId !== campaignAdGroup.adGroupId
        )
      ),

  [CohortCreate.REMOVE_ADGROUP_ALL]: state =>
    state.withMutations(s =>
      s.set('selectedAdGroups', List()).set('selectedAdGroupIds', List())
    ),

  [CohortCreate.STORE_ADGROUPS]: (
    state,
    { adGroups, name, cohortId, audienceType }
  ) =>
    state.withMutations(s =>
      s
        .set('selectedAdGroups', fromJS(adGroups))
        .set(
          'selectedAdGroupIds',
          adGroups.map(adGroup => adGroup.get('adGroupId'))
        )
        .set('name', name)
        .set('cohortId', cohortId)
        .set('audienceType', audienceType)
    ),

  [CohortCreate.STORE_CAMPAIGN]: (state, { payload }) =>
    state.set('campaigns', fromJS(payload || [])),

  [CohortCreate.CHANGE_SELECTED_ADGROUP_TARGET_DATA]: (
    state,
    { adGroupId, firstIndicator, operation, secondIndicator, thirdIndicator }
  ) => {
    return state.update('selectedAdGroups', adGroups => {
      const index = adGroups.findIndex(v => v.get('adGroupId') === adGroupId)
      if (index !== -1) {
        return adGroups.update(index, adGroup => {
          const {
            targetCondition: { options },
          } = adGroup

          const { operations } = options.get(firstIndicator)
          return adGroup.withMutations(s => {
            s.set('firstIndicator', firstIndicator)
              .set(
                'operation',
                operation ||
                  findFirstOperationByFirstIndicator(firstIndicator, operations)
              )
              .set('secondIndicator', secondIndicator)
              .set('thirdIndicator', thirdIndicator)
          })
        })
      }
      return adGroups
    })
  },
  [CohortCreate.INIT_COHORT_AUDIENCE_TYPE]: state =>
    state.set('audienceType', initialState.get('audienceType')),
  [CohortCreate.UPDATE_COHORT_AUDIENCE_TYPE]: (state, { audienceType }) =>
    state.set('audienceType', audienceType),
})

/**
 *
 * @param firstIndicator {String}
 * @param operations {Immutable.Map}
 */
const findFirstOperationByFirstIndicator = (firstIndicator, operations) => {
  const only =
    operations.has(COHORT_OPERATION.ONLY) &&
    operations.get(COHORT_OPERATION.ONLY)

  if (only) return COHORT_OPERATION.ONLY
  return operations
    .filter(o => o !== COHORT_OPERATION.ONLY)
    .first()
    .first()
}

export const selectAdGroupForCohort = campaignAdGroup => ({
  type: CohortCreate.ADD_ADGROUP,
  campaignAdGroup,
})

export const unSelectAdGroup = campaignAdGroup => ({
  type: CohortCreate.REMOVE_ADGROUP,
  campaignAdGroup,
})

export const unSelectAdGroupAll = () => ({
  type: CohortCreate.REMOVE_ADGROUP_ALL,
})

export function modifyCohortData(adAccountId, cohortId) {
  return (dispatch, getState) => {
    const {
      cohort: {
        cohortDetail: {
          cohortDetailData: { name, audienceType },
          cohortBaseAdsWithAdGroupInfo,
        },
      },
    } = getState()

    const adGroups = cohortBaseAdsWithAdGroupInfo
      // 삭제된 데이터 및 오디언스 타입이 다른 데이터는 수정 시에 제거
      .filter(({ deleted }) => !deleted)
      .map(adGroup =>
        adGroup.set(
          'targetCondition',
          fromJS(
            createCohortTargetCondition({
              campaignType: adGroup.get('campaignType'),
              audienceType,
            })
          )
        )
      )
      .map(adGroup => {
        // 디스플레이 재생 지표가 있는 경우 클릭 - 전체로 변경
        if (
          adGroup.get('campaignType') === CampaignTypeEnum.Type.DISPLAY &&
          (adGroup.get('firstIndicator') === COHORT_INDICATOR.PLAY ||
            adGroup.get('secondIndicator') === COHORT_INDICATOR.PLAY)
        ) {
          return adGroup
            .set('firstIndicator', COHORT_INDICATOR.CLICK)
            .set('operation', COHORT_OPERATION.ONLY)
            .delete('secondIndicator')
        } else {
          return adGroup
        }
      })
      .map(adGroup =>
        // 메세지의 열람 - 클릭인 경우 열람 - 클릭 - 재생으로 변경
        adGroup.update('thirdIndicator', thirdIndicator => {
          if (
            adGroup.get('campaignType') ===
              CampaignTypeEnum.Type.TALK_CHANNEL &&
            adGroup.get('firstIndicator') === COHORT_INDICATOR.OPEN &&
            adGroup.get('operation') === COHORT_OPERATION.MINUS &&
            adGroup.get('secondIndicator') === COHORT_INDICATOR.CLICK &&
            thirdIndicator === null
          ) {
            return COHORT_INDICATOR.PLAY
          } else {
            return thirdIndicator
          }
        })
      )
      // firstIndicator 정의되지 않은 항목 제거
      .filter(({ targetCondition: { defaultOption } = {} }) => !!defaultOption)

    dispatch({
      type: CohortCreate.STORE_ADGROUPS,
      adGroups,
      name,
      cohortId,
      audienceType,
    })

    dispatch(
      RouterV2.push(
        toUpdateTargetingCohortPath(adAccountId, cohortId, audienceType)
      )
    )
  }
}

const storeCampaign = payload => ({
  type: CohortCreate.STORE_CAMPAIGN,
  payload,
})

export function getCampaignItemsByAdAccountId(
  adAccountId,
  audienceType,
  campaignTypeGoalId,
  keyword,
  onFinish = () => undefined
) {
  return (dispatch, getState, api) => {
    return api.adAccount
      .getCampaignItemsByAdAccountId(
        adAccountId,
        audienceType,
        campaignTypeGoalId,
        keyword
      )
      .then(response => {
        dispatch(storeCampaign(response.data || []))
      })
      .catch(e => {
        dispatch(storeCampaign([]))
        handleCohortException(dispatch, e)
      })
      .finally(onFinish)
  }
}

export function createCohorts(adAccountId, targetName) {
  return async (dispatch, getState, api) => {
    const formData = Trim(Map({ name: targetName }), COHORT_TRIM_KEY_PATH_LIST)

    const validationResult = Validation(
      formData,
      COHORT_FORM_VALIDATION_KEY,
      COHORT_FORM_VALIDATION_KEY_PATH,
      COHORT_FORM_VALIDATOR,
      getState,
      setIsValidCohortByKey,
      dispatch
    )

    if (!validationResult) return

    const {
      cohort: {
        cohortCreate: { selectedAdGroups, audienceType },
      },
    } = getState()

    const cohortBaseAds = selectedAdGroups
      .filter(({ firstIndicator }) => !!firstIndicator)
      .map(
        ({
          campaignId,
          campaignType,
          adGroupId,
          firstIndicator,
          operation,
          secondIndicator,
          thirdIndicator,
          indicatorSet,
          cohortBaseAdType,
        }) => {
          return {
            campaignId,
            adGroupId,
            campaignType,
            firstIndicator,
            operation,
            secondIndicator:
              secondIndicator === null ? undefined : secondIndicator,
            thirdIndicator:
              thirdIndicator === null ? undefined : thirdIndicator,
            indicatorSet,
            cohortBaseAdType,
          }
        }
      )
      .toJS()

    const requestBody = {
      name: targetName,
      cohortBaseAds,
      collectDuration: 90,
      audienceType,
    }

    dispatch(showLoading())

    try {
      await api.cohort.createCohorts(adAccountId, requestBody)

      const handleConfirm = () => {
        dispatch(unSelectAdGroupAll())
        dispatch(
          RouterV2.replace(toTargetingCohortPath(adAccountId, audienceType))
        )
      }

      const popupEl = (
        <PopupProxy primaryButtonFunc={handleConfirm} hasCloseButton={false}>
          <strong className="tit_layer">광고반응타겟 만들기 완료</strong>
          <p className="txt_layer">
            광고반응타겟 생성 요청을 성공하였습니다.
            <br />
            선택한 타겟 모수는 다음날부터 반영됩니다.
          </p>
        </PopupProxy>
      )

      dispatch(openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupEl))
    } catch (e) {
      handleCohortException(dispatch, e)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function updateCohort(adAccountId) {
  return (dispatch, getState, api) => {
    const {
      cohort: {
        cohortCreate: { selectedAdGroups, cohortId, audienceType },
      },
    } = getState()

    const requestBody = {
      cohortBaseAds: selectedAdGroups.filter(Boolean).toJS(),
    }

    dispatch(showLoading())

    return api.cohort
      .modifyCohortBaseAd(adAccountId, cohortId, requestBody)
      .then(() => {
        const handleConfirm = () => {
          dispatch(unSelectAdGroupAll())
          dispatch(
            RouterV2.replace(toTargetingCohortPath(adAccountId, audienceType))
          )
        }

        const popupEl = (
          <PopupProxy primaryButtonFunc={handleConfirm}>
            <strong className="tit_layer">광고반응타겟 수정 완료</strong>
            <p className="txt_layer">
              광고반응타겟 수정 요청을 성공하였습니다.
              <br />
              수정된 타겟 모수는 다음날부터 반영됩니다.
            </p>
          </PopupProxy>
        )

        dispatch(openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupEl))
      })
      .catch(e => {
        handleCohortException(dispatch, e)
      })
      .finally(() => {
        dispatch(hideLoading())
      })
  }
}

export function changeSelectedAdGroupTargetData(
  adGroupId,
  firstIndicator,
  operation = null,
  secondIndicator = null,
  thirdIndicator = null
) {
  return {
    type: CohortCreate.CHANGE_SELECTED_ADGROUP_TARGET_DATA,
    adGroupId,
    firstIndicator,
    operation,
    secondIndicator,
    thirdIndicator,
  }
}

export function initCohortAudienceType() {
  return {
    type: CohortCreate.INIT_COHORT_AUDIENCE_TYPE,
  }
}

export function updateCohortAudienceType(audienceType) {
  return {
    type: CohortCreate.UPDATE_COHORT_AUDIENCE_TYPE,
    audienceType,
  }
}
