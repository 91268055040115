import React, { useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { parseUrlParameter, queryString } from '../../../utils/utils'
import { NumberUtils } from '../../../utils/numberUtils'
import { KakaoLoungeBoard } from '../../../utils/app/services/kakaoLoungeBoard'

const LoungeBoard = () => {
  const { search } = useLocation()
  const adAccountId = useRouteMatch([
    '/:adAccountId(\\d+)/*',
    '/dashboard/:adAccountId(\\d+)',
    '/ad/:adAccountId(\\d+)',
  ])?.params?.adAccountId
  const href = useMemo(() => {
    const targetTypes = []
    const targetIds = []
    const {
      campaignId = '',
      adGroupId = '',
      creativeId = '',
    } = parseUrlParameter(search)
    if (adAccountId) {
      targetTypes.push('AD_ACCOUNT')
      targetIds.push(adAccountId)
    }
    if (NumberUtils.isPositiveSequence(campaignId)) {
      targetTypes.push('CAMPAIGN')
      targetIds.push(campaignId)
    }
    if (NumberUtils.isPositiveSequence(adGroupId)) {
      targetTypes.push('AD_GROUP')
      targetIds.push(adGroupId)
    }
    if (NumberUtils.isPositiveSequence(creativeId)) {
      targetTypes.push('CREATIVE')
      targetIds.push(creativeId)
    }
    return `${KakaoLoungeBoard.getHost()}/post/list?${queryString({
      serviceType: 'KAKAOMOMENT',
      ...(targetTypes.length > 0 && { targetType: targetTypes.join(',') }),
      ...(targetIds.length > 0 && { targetId: targetIds.join(',') }),
    })}`
  }, [search, adAccountId])
  return (
    <li>
      <a
        href={href}
        rel="noopener noreferrer"
        target="_blank"
        className="link_gnb">
        문의게시판
      </a>
    </li>
  )
}
export default LoungeBoard
