import CalendarEnum from '../enums/CalendarEnum'
import moment from 'moment'
import { dateToString } from './utils'

const Calendar = {}

Calendar.ChunkSize = 7

/**
 * date 를 기준으로 해당 월의 시퀀셜한 캘린더 데이터를 만든다.
 *
 *  ex)
 *    date: 2019-01 | 2019-01-24
 *
 *    * 실제로는 moment.Moment 객체 형태로 담김
 *
 *    return [
 *      ["18-12-30", "18-12-31", "19-01-01", "19-01-02", "19-01-03", "19-01-04", "19-01-05"]
 *      ["19-01-06", "19-01-07", "19-01-08", "19-01-09", "19-01-10", "19-01-11", "19-01-12"]
 *      ["19-01-13", "19-01-14", "19-01-15", "19-01-16", "19-01-17", "19-01-18", "19-01-19"]
 *      ["19-01-20", "19-01-21", "19-01-22", "19-01-23", "19-01-24", "19-01-25", "19-01-26"]
 *      ["19-01-27", "19-01-28", "19-01-29", "19-01-30", "19-01-31", "19-02-01", "19-02-02"]
 *    ]
 *
 *    date: 2019-03 | 2019-03-05
 *
 *    return [
 *      ["19-02-24", "19-02-25", "19-02-26", "19-02-27", "19-02-28", "19-03-01", "19-03-02"]
 *      ["19-03-03", "19-03-04", "19-03-05", "19-03-06", "19-03-07", "19-03-08", "19-03-09"]
 *      ["19-03-10", "19-03-11", "19-03-12", "19-03-13", "19-03-14", "19-03-15", "19-03-16"]
 *      ["19-03-17", "19-03-18", "19-03-19", "19-03-20", "19-03-21", "19-03-22", "19-03-23"]
 *      ["19-03-24", "19-03-25", "19-03-26", "19-03-27", "19-03-28", "19-03-29", "19-03-30"]
 *      ["19-03-31", "19-04-01", "19-04-02", "19-04-03", "19-04-04", "19-04-05", "19-04-06"]
 *    ]
 *
 * @param date {moment.Moment}
 * @return {moment.Moment | *}
 */
Calendar.create = function (date) {
  /**
   * date 기준 month 의 시작일(date)에 대한 요일(weekday)
   * ex)
   *  2019-01-01(화) => 2
   *  2019-02-01(금) => 5
   *  2019-09-01(일) => 0
   *
   * @type {number}
   */
  const startWeekdayOfThisMonth = moment(date).date(1).weekday()

  /**
   * date 기준 월의 마지막 날(date)
   * ex)
   *  2019-01 => 31
   *
   * @type {number}
   */
  const lastDateOfThisMonth = moment(date).endOf('M').date()

  /**
   * date 기준 month 의 마지막 날(date)에 대한 요일(weekday)
   * @type {number}
   */
  const lastWeekdayOfThisMonth = moment(date)
    .date(lastDateOfThisMonth)
    .weekday()

  /**
   * date 기준 month 에 포함된 이전달 dates
   * ex)
   *  1월 달력에 포함된 12월 날짜
   *  2019-01 => [2018-12-30, 2018-12-31]
   *
   * @type {moment.Moment[]}
   */
  const datesOfPrevMonth = Array.from(Array(startWeekdayOfThisMonth)).map(
    (v, i) =>
      moment(date)
        .date(1)
        .subtract(startWeekdayOfThisMonth - i, 'd')
  )

  /**
   * date 기준 month 의 dates
   * ex) 2019-01 => [2019-01-01, ..., 2019-01-31]
   *
   * @type {moment.Moment[]}
   */
  const datesOfThisMonth = Array.from(Array(lastDateOfThisMonth)).map((v, i) =>
    moment(date).date(i + 1)
  )

  /**
   * date 기준 month 에 포함된 다음달 dates
   * ex)
   *  1월 달력에 포함된 2월 날짜
   *  2019-01 => [2019-02-01, 2019-02-02]
   *
   * @type {moment.Moment[]}
   */
  const datesOfNextMonth = Array.from(
    Array(Calendar.ChunkSize - lastWeekdayOfThisMonth - 1)
  ).map((v, i) =>
    moment(date)
      .date(lastDateOfThisMonth)
      .add(i + 1, 'd')
  )

  const datesOfMonth = [
    ...datesOfPrevMonth,
    ...datesOfThisMonth,
    ...datesOfNextMonth,
  ]

  return datesOfMonth.reduce((prev, v, i) => {
    const chunkIndex = Math.floor(i / Calendar.ChunkSize)
    if (!prev[chunkIndex]) prev[chunkIndex] = []
    prev[chunkIndex].push(v)
    return prev
  }, [])
}

/**
 * preset 과 date(기준일) 으로 begin moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      begin: 2019-01-18
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment}
 * @param least {moment.Moment | undefined}
 * @return {moment.Moment}
 */
Calendar.beginByPreset = function (preset, date, least = undefined) {
  switch (preset) {
    case CalendarEnum.Preset.Type.ALL: {
      const systemLeast = moment(date).subtract(2, 'y').add(1, 'd')

      return least
        ? Calendar.coerceAtLeast(moment(least), systemLeast)
        : systemLeast
    }

    case CalendarEnum.Preset.Type.TODAY: {
      return moment(date)
    }

    case CalendarEnum.Preset.Type.LAST7DAYS: {
      return Calendar.coerceAtLeast(
        moment(date).subtract(6, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      return Calendar.coerceAtLeast(
        moment(date).subtract(13, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.THISWEEK: {
      return Calendar.coerceAtLeast(
        moment(date).startOf('isoWeek'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      return Calendar.coerceAtLeast(
        moment(date).subtract(29, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.THISMONTH: {
      return Calendar.coerceAtLeast(moment(date).date(1), moment(least))
    }

    case CalendarEnum.Preset.Type.YESTERDAY: {
      const subtracted = moment(date).subtract(1, 'd')

      return moment(least).isSameOrBefore(moment(subtracted), 'd')
        ? Calendar.coerceAtLeast(moment(date).subtract(1, 'd'), moment(least))
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTWEEK: {
      const subtracted = moment(date).subtract(1, 'w')
      const firstDayOfLastWeek = moment(subtracted).startOf('isoWeek')

      return moment(least).isSameOrBefore(subtracted, 'w')
        ? Calendar.coerceAtLeast(firstDayOfLastWeek, moment(least))
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTMONTH: {
      const subtracted = moment(date).subtract(1, 'M')
      const firstDayOfLastMonth = moment(subtracted).date(1)

      return moment(least).isSameOrBefore(subtracted, 'M')
        ? Calendar.coerceAtLeast(firstDayOfLastMonth, moment(least))
        : undefined
    }

    default: {
      return moment(date)
    }
  }
}

/**
 * preset 과 date(기준일) 으로 end moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      end: 2019-01-24
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment}
 * @param least {moment.Moment | undefined}
 * @param most {moment.Moment | undefined}
 * @return {moment.Moment}
 */
Calendar.endByPreset = function (
  preset,
  date,
  least = undefined,
  most = undefined
) {
  switch (preset) {
    case CalendarEnum.Preset.Type.ALL: {
      const systemMost = moment(date).add(2, 'y').subtract(1, 'd')

      return most ? Calendar.coerceAtMost(moment(most), systemMost) : systemMost
    }

    case CalendarEnum.Preset.Type.TODAY:
    case CalendarEnum.Preset.Type.LAST7DAYS:
    case CalendarEnum.Preset.Type.LAST14DAYS:
    case CalendarEnum.Preset.Type.THISWEEK:
    case CalendarEnum.Preset.Type.LAST30DAYS: {
      return moment(date)
    }

    case CalendarEnum.Preset.Type.THISMONTH: {
      return Calendar.coerceAtMost(moment(date).endOf('M'), moment(most))
    }

    case CalendarEnum.Preset.Type.YESTERDAY: {
      const subtracted = moment(date).subtract(1, 'd')

      return moment(least).isSameOrBefore(moment(subtracted), 'd') &&
        moment(most).isSameOrAfter(moment(subtracted), 'd')
        ? Calendar.coerceIn(subtracted, moment(least), moment(most))
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTWEEK: {
      const subtracted = moment(date).subtract(1, 'w')
      const lastDayOfLastWeek = moment(subtracted).endOf('isoWeek')

      return moment(least).isSameOrBefore(moment(subtracted), 'w') &&
        moment(most).isSameOrAfter(moment(subtracted), 'w')
        ? Calendar.coerceIn(lastDayOfLastWeek, moment(least), moment(most))
        : null
    }

    case CalendarEnum.Preset.Type.LASTMONTH: {
      const subtracted = moment(date).subtract(1, 'M')
      const lastDayOfLastMonth = moment(subtracted).endOf('M')

      return moment(least).isSameOrBefore(moment(subtracted), 'M') &&
        moment(most).isSameOrAfter(moment(subtracted), 'M')
        ? Calendar.coerceIn(lastDayOfLastMonth, moment(least), moment(most))
        : null
    }

    default: {
      return moment(date)
    }
  }
}

/**
 * preset 과 date(기준일) 으로 begin, end moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      begin: 2019-01-18
 *      end: 2019-01-24
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment | object | undefined}
 * @param least {moment.Moment | undefined}
 * @param most {moment.Moment | undefined}
 * @return {{end: moment.Moment | undefined, begin: moment.Moment | undefined}}
 */
Calendar.rangeByPreset = function (
  preset,
  date,
  least = undefined,
  most = undefined
) {
  return {
    begin: Calendar.beginByPreset(preset, date, least),
    end: Calendar.endByPreset(preset, date, least, most),
  }
}

Calendar.coerceAtLeast = function (date, least) {
  return moment(date).isBefore(moment(least), 'd')
    ? moment(least)
    : moment(date)
}

Calendar.coerceAtMost = function (date, most) {
  return moment(date).isAfter(moment(most), 'd') ? moment(most) : moment(date)
}

Calendar.coerceIn = function (date, least, most) {
  return least && most && moment(date).isBefore(moment(least), 'd')
    ? moment(least)
    : moment(date).isAfter(moment(most), 'd')
    ? moment(most)
    : moment(date)
}

/**
 * begin, end moment 를 기반으로 기간 텍스트 출력.
 *  ex) 2019-01-24 ~ 2019-02-24
 *
 * @param begin {moment.Moment | Object}
 * @param end {moment.Moment | Object | undefined}
 * @return {string}
 */
Calendar.toString = function (begin, end = undefined) {
  if (!begin && !end) return ''
  return end
    ? `${dateToString(begin)} ~ ${dateToString(end)}`
    : dateToString(begin)
}

/**
 *
 * @param date {string | moment.Moment | Object}
 * @return {boolean}
 */
Calendar.isValid = function (date) {
  const m = moment(date)
  return m.year() > 0 && m.month() >= 0 && m.date() > 0
}

Calendar.getPeriodLabel = (periodType, startDate, endDate, customDateDiff) => {
  switch (periodType) {
    case CalendarEnum.Preset.Type.TODAY:
    case CalendarEnum.Preset.Type.YESTERDAY: {
      if (moment(endDate).isSame(moment(), 'd')) return '오늘'
      if (moment(endDate).isSame(moment().subtract(1, 'd'), 'd')) return '어제'
      return `${moment().diff(moment(endDate), 'd')}일 전`
    }
    case CalendarEnum.Preset.Type.THISWEEK:
    case CalendarEnum.Preset.Type.LASTWEEK: {
      if (moment(endDate).isoWeek() === moment().isoWeek()) return '이번 주'
      if (moment(endDate).isoWeek() === moment().subtract(1, 'w').isoWeek())
        return '지난 주'

      return `${Math.ceil(moment().diff(moment(endDate), 'w', true))}주 전`
    }
    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      if (moment(endDate).isSame(moment(), 'M')) return '이번 달'
      if (moment(endDate).isSame(moment().subtract(1, 'M'), 'M'))
        return '지난 달'
      return `${Math.ceil(moment().diff(moment(endDate), 'M', true))}개월 전`
    }
    case CalendarEnum.Preset.Type.LAST7DAYS: {
      if (moment(endDate).isSame(moment(), 'd'))
        return CalendarEnum.Preset.getName(periodType)
      return '7일 단위'
    }
    case CalendarEnum.Preset.Type.LAST14DAYS: {
      if (moment(endDate).isSame(moment(), 'd'))
        return CalendarEnum.Preset.getName(periodType)
      return '14일 단위'
    }
    case CalendarEnum.Preset.Type.LAST30DAYS: {
      if (moment(endDate).isSame(moment(), 'd'))
        return CalendarEnum.Preset.getName(periodType)
      return '30일 단위'
    }
    case CalendarEnum.Preset.Type.ALL: {
      return '전체'
    }
    case CalendarEnum.Preset.Type.CUSTOM: {
      return `${customDateDiff + 1}일 단위`
    }
    default: {
      return `${
        Math.abs(moment(startDate).diff(moment(endDate), 'd')) + 1
      }일 단위`
    }
  }
}

Calendar.getPrevCalendarDate = (
  createdDate,
  startDate,
  endDate,
  periodType,
  customDateDiff
) => {
  const least = Calendar.coerceAtLeast(createdDate, moment().subtract(2, 'y'))

  if (moment(startDate).isSameOrBefore(least, 'd')) return {}

  switch (periodType) {
    case CalendarEnum.Preset.Type.ALL: {
      return {}
    }
    case CalendarEnum.Preset.Type.TODAY:
    case CalendarEnum.Preset.Type.YESTERDAY: {
      const v = Calendar.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )
      return { start: v, end: v }
    }
    case CalendarEnum.Preset.Type.LAST7DAYS: {
      const end = Calendar.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )
      const start = Calendar.coerceAtLeast(moment(end).subtract(6, 'd'), least)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LASTWEEK:
    case CalendarEnum.Preset.Type.THISWEEK: {
      const end = Calendar.coerceAtLeast(
        moment(endDate).subtract(1, 'w').endOf('isoWeek'),
        least
      )
      const start = Calendar.coerceAtLeast(
        moment(end).startOf('isoWeek'),
        least
      )
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LAST14DAYS: {
      const end = Calendar.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )
      const start = Calendar.coerceAtLeast(moment(end).subtract(13, 'd'), least)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LAST30DAYS: {
      const end = Calendar.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )
      const start = Calendar.coerceAtLeast(moment(end).subtract(29, 'd'), least)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      const end = Calendar.coerceAtLeast(
        moment(endDate).subtract(1, 'M').endOf('M'),
        least
      )
      const start = Calendar.coerceAtLeast(moment(end).startOf('M'), least)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.CUSTOM: {
      const end = Calendar.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )
      const start = Calendar.coerceAtLeast(
        moment(end).subtract(customDateDiff, 'd'),
        least
      )
      return { start, end }
    }

    default: {
      return {}
    }
  }
}

Calendar.getNextCalendarDate = (
  startDate,
  endDate,
  periodType,
  customDateDiff
) => {
  const most = moment()
  if (moment(endDate).isSameOrAfter(most, 'd')) return {}

  switch (periodType) {
    case CalendarEnum.Preset.Type.ALL: {
      return {}
    }
    case CalendarEnum.Preset.Type.TODAY:
    case CalendarEnum.Preset.Type.YESTERDAY: {
      const v = Calendar.coerceAtMost(moment(startDate).add(1, 'd'), most)
      return { start: v, end: v }
    }
    case CalendarEnum.Preset.Type.LAST7DAYS: {
      const start = Calendar.coerceAtMost(moment(endDate).add(1, 'd'), most)
      const end = Calendar.coerceAtMost(moment(start).add(6, 'd'), most)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LASTWEEK:
    case CalendarEnum.Preset.Type.THISWEEK: {
      const start = Calendar.coerceAtMost(
        moment(startDate).add(1, 'w').startOf('isoWeek'),
        most
      )
      const end = Calendar.coerceAtMost(moment(start).endOf('isoWeek'), most)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LAST14DAYS: {
      const start = Calendar.coerceAtMost(moment(endDate).add(1, 'd'), most)
      const end = Calendar.coerceAtMost(moment(start).add(13, 'd'), most)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LAST30DAYS: {
      const start = Calendar.coerceAtMost(moment(endDate).add(1, 'd'), most)
      const end = Calendar.coerceAtMost(moment(start).add(29, 'd'), most)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      const start = Calendar.coerceAtMost(
        moment(startDate).add(1, 'M').startOf('M'),
        most
      )
      const end = Calendar.coerceAtMost(moment(start).endOf('M'), most)
      return { start, end }
    }
    case CalendarEnum.Preset.Type.CUSTOM: {
      const start = Calendar.coerceAtMost(moment(endDate).add(1, 'd'), most)
      const end = Calendar.coerceAtMost(
        moment(start).add(customDateDiff, 'd'),
        most
      )
      return { start, end }
    }
    default: {
      return {}
    }
  }
}

export default Calendar
