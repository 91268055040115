import { keyMirror, naturalComparator } from '../utils/utils'
import { difference } from 'lodash'

/**
 * adview button 과 key 는 유사하지만 도메인상 별도로 취급
 * https://wiki.daumkakao.com/pages/viewpage.action?pageId=761803142
 */
const ExpandableActionButtonEnum = {
  Type: keyMirror({
    NONE: null,
    PURCHASE: null,
    PRODUCT_SUBSCRIBE: null,
    TALK_GIFT: null,
    GET_EMOTICON: null,
    TALK_ORDER: null,
    PLUSFRIEND_ADD: null,
    TALK_IN_SURVEY: null,
    TALK_TEST_DRIVE: null,
    TALK_IN_RESERVATION: null,
    TALK_IN_APPLY: null,
    TALK_IN_PARTICIPATE: null,
    TALK_REGISTRATION: null,
    TALK_SHARE: null,
    TALK_CONTACT: null,
    TALK_CALENDAR_SAVE: null,
    VIEW_LIVE: null,
    // extra
    VIEW_DETAIL: null,
  }),

  Name: {
    NONE: '버튼 선택',
    PURCHASE: '구매하기',
    PRODUCT_SUBSCRIBE: '상품구독하기',
    TALK_GIFT: '선물하기',
    GET_EMOTICON: '이모티콘 받기',
    TALK_ORDER: '주문하기',
    PLUSFRIEND_ADD: '채널 추가하기',
    TALK_IN_SURVEY: '톡에서 설문하기',
    TALK_TEST_DRIVE: '톡에서 시승신청',
    TALK_IN_RESERVATION: '톡에서 예약하기',
    TALK_IN_APPLY: '톡에서 응모하기',
    TALK_IN_PARTICIPATE: '톡에서 참여하기',
    TALK_REGISTRATION: '톡에서 회원가입',
    TALK_SHARE: '톡으로 공유하기',
    TALK_CONTACT: '톡으로 문의하기',
    TALK_CALENDAR_SAVE: '톡캘린더 저장하기',
    VIEW_LIVE: 'LIVE보기',
    VIEW_DETAIL: '자세히보기',
  },

  Description: {
    NONE: '소재 랜딩 유형에 해당하는 행동유도버튼을 선택하세요.',
    PURCHASE:
      '카카오 메이커스/톡스토어로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    PRODUCT_SUBSCRIBE:
      '카카오톡 상품구독하기 URL을 직접 랜딩으로 설정하거나, 카카오톡 상품구독하기로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    TALK_GIFT:
      '카카오톡 선물하기 URL을 직접 랜딩으로 설정하거나, 카카오톡 선물하기로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    GET_EMOTICON:
      '카카오 브랜드 이모티콘 다운로드 URL을 직접 랜딩으로 설정하거나, 이모티콘 받기로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    TALK_ORDER:
      '카카오톡 주문하기 URL을 직접 랜딩으로 설정하거나, 카카오톡 주문하기로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    PLUSFRIEND_ADD: '카카오톡 채널 홈을 랜딩으로 설정할 경우 선택합니다.',
    TALK_IN_SURVEY: '비즈니스폼을 랜딩으로 설정한 경우 선택합니다.',
    TALK_TEST_DRIVE: '비즈니스폼을 랜딩으로 설정한 경우 선택합니다.',
    TALK_IN_RESERVATION:
      '카카오톡 예약하기 URL을 직접 랜딩으로 설정하거나, 비즈니스폼을 랜딩으로 설정한 경우 선택합니다.',
    TALK_IN_APPLY: '비즈니스폼을 랜딩으로 설정한 경우 선택합니다.',
    TALK_IN_PARTICIPATE: '비즈니스폼을 랜딩으로 설정한 경우 선택합니다.',
    TALK_REGISTRATION:
      '카카오싱크 동의창으로 연결되는 애드뷰를 랜딩으로 설정할 경우 선택합니다.',
    TALK_SHARE: '애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    TALK_CONTACT: '카카오톡 채널의 챗봇을 랜딩으로 설정한 경우 선택합니다.',
    TALK_CALENDAR_SAVE: '톡캘린더를 랜딩으로 설정할 경우 선택합니다.',
    VIEW_LIVE:
      '카카오쇼핑라이브 URL을 직접 랜딩으로 설정하거나, 카카오쇼핑라이브로 연결되는 애드뷰를 랜딩으로 설정한 경우 선택합니다.',
    VIEW_DETAIL: 'URL, 카카오톡 채널 소식을 랜딩으로 설정한 경우 선택합니다.',
  },

  isDeprecated(code) {
    return [this.Type.TALK_CALENDAR_SAVE].includes(code)
  },

  getName(type) {
    return this.Name[type]
  },

  getDescription(type) {
    return this.Description[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  valuesOfExtra() {
    return [this.Type.VIEW_DETAIL]
  },

  // 한글 오름차순 정렬. extra 는 array 끝으로.
  sortComparator(typeA, typeB) {
    const isNoneA = ExpandableActionButtonEnum.Type.NONE === typeA
    const isNoneB = ExpandableActionButtonEnum.Type.NONE === typeB
    const isExtraA = ExpandableActionButtonEnum.valuesOfExtra().includes(typeA)
    const isExtraB = ExpandableActionButtonEnum.valuesOfExtra().includes(typeB)

    // none: 맨 앞으로
    if (isNoneA && !isNoneB) return -1
    if (!isNoneA && isNoneB) return 1
    // extra: 맨 뒤로
    if (isExtraA && !isExtraB) return 1
    if (!isExtraA && isExtraB) return -1

    return naturalComparator(
      ExpandableActionButtonEnum.getName(typeA),
      ExpandableActionButtonEnum.getName(typeB)
    )
  },

  sortedValues() {
    return difference(this.values(), this.valuesOfExtra())
      .sort(this.sortComparator)
      .concat(this.valuesOfExtra())
  },

  isInLanding(type) {
    return type !== this.Type.NONE && type !== this.Type.VIEW_DETAIL
  },
}

export default ExpandableActionButtonEnum
