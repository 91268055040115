import React from 'react'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
  DynamicTooltipUtils,
} from '../dynamicTooltipUtils'

const useDynamicTooltipPosition = ({
  containerNode,
  verticalPosition,
  horizontalPosition,
}) => {
  const tooltipRef = React.useRef()

  React.useLayoutEffect(() => {
    const tooltipNode = tooltipRef.current

    if (tooltipNode && containerNode) {
      // display block 후 width, height 을 얻을 수 있다.
      tooltipNode.style.display = 'block'

      // whiteSpace 처리 "전" width 를 얻어내야 함.
      const tooltipWidth = tooltipNode.offsetWidth + 5 // 텍스트가 maxWidth 에 fit 될 경우 줄바꿈되는 이슈로 offset(5) 를 더함.

      tooltipNode.style.whiteSpace = 'normal'

      // whiteSpace 처리 "후" height 를 얻어내야 함.
      const tooltipHeight = tooltipNode.offsetHeight

      const containerRect = containerNode.getBoundingClientRect()

      const x = containerRect.left + containerRect.width / 2
      const y = containerRect.bottom

      const horizontalMargin = DynamicTooltipUtils.getHorizontalMargin({
        x,
        targetWidth: tooltipWidth,
        horizontalPosition,
      })

      const defaultHorizontalOffset =
        DynamicTooltipUtils.getDefaultHorizontalOffset({
          horizontalMargin,
          horizontalPosition,
        })

      const offsetLeft =
        horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.LEFT
          ? tooltipNode.offsetWidth
          : horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.CENTER
          ? tooltipNode.offsetWidth / 2
          : 0

      const realVerticalPosition = DynamicTooltipUtils.getRealVerticalPosition({
        containerRect,
        targetHeight: tooltipHeight,
        verticalPosition,
      })

      const verticalMargin =
        realVerticalPosition === DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM
          ? -7
          : containerRect.height + tooltipHeight + 7

      const left = x - offsetLeft - horizontalMargin - defaultHorizontalOffset
      const top = y - verticalMargin

      tooltipNode.style.left = `${left}px`
      tooltipNode.style.top = `${top}px`
    }
  }, [containerNode, horizontalPosition, verticalPosition])

  return {
    tooltipRef,
  }
}

export default useDynamicTooltipPosition
