import { getApiPhase } from './kakaoMoment'

const KakaoPlusFriend = {
  Host: {
    production: 'https://center-pf.kakao.com',
    stage: 'https://beta-center-pf.kakao.com',
    sandbox: 'https://rocket.devel.kakao.com',
    develop: 'https://rocket.devel.kakao.com',
    local: 'https://rocket.devel.kakao.com',
  },
}

const KakaoOfficialChannelProfileId = {
  production: [25411718, 344940307],
  stage: [276455090, 344940307], // 276455090(카.카.오.광.고.테.스.트), 344940307(오늘의채널)
  sandbox: [54746, 203702], // 54746(통합테스트용플러스친구), 203702(오늘의채널)
  develop: [54746, 203702], // 54746(통합테스트용플러스친구), 203702(오늘의채널)
}

const kakaoOfficialChannelProfileList = () => {
  return (
    KakaoOfficialChannelProfileId[getApiPhase()] ||
    KakaoOfficialChannelProfileId.sandbox
  )
}

export const isKakaoOfficialChannelProfileId = profileId => {
  const officialChannelProfileIdList = kakaoOfficialChannelProfileList()
  return officialChannelProfileIdList.indexOf(profileId) > -1
}

export const kakaoBizPlusFriendURL = () => {
  return KakaoPlusFriend.Host[getApiPhase()] || KakaoPlusFriend.Host.sandbox
}

// 메시지 관련 호출 시엔 KakaoPlusFriend.HOST 뒤에 /profiles 붙여 이동.
export const kakaoBizPlusFriendProfilesUrl = () => {
  return `${
    KakaoPlusFriend.Host[getApiPhase()] || KakaoPlusFriend.Host.sandbox
  }/profiles`
}
