/**
 * Router exact 매칭이 아닌 경우, 역순으로 배치해야 함(위에서부터 매칭).
 */

const DashboardRedirectRegexArray = [
  // - v0 -
  // display creative
  '/dashboard/:adaccountid(\\d+)/display_campaign/:campaignid(\\d+)/adgroup/:adgroupid(\\d+)/adcreative/:adcreativeid(\\d+)',
  // display adGroup
  '/dashboard/:adaccountid(\\d+)/display_campaign/:campaignid(\\d+)/adgroup/:adgroupid(\\d+)',
  // display campaign
  '/dashboard/:adaccountid(\\d+)/display_campaign/:campaignid(\\d+)',
  // message creative
  '/dashboard/:adaccountid(\\d+)/message_campaign/:campaignid(\\d+)/(direct_adgroup|ontime_adgroup|direct_pf_adgroup)/:adgroupid(\\d+)/adcreative/:adcreativeid(\\d+)',
  // message adGroup
  '/dashboard/:adaccountid(\\d+)/message_campaign/:campaignid(\\d+)/(direct_adgroup|ontime_adgroup|direct_pf_adgroup)/:adgroupid(\\d+)',
  // message campaign
  '/dashboard/:adaccountid(\\d+)/message_campaign/:campaignid(\\d+)',

  // - v1 -
  // creative
  '/dashboard/:adaccountid(\\d+)/campaign/:ad_type(display|direct_message|on_time_message|direct_pf_group_message)/:campaignid(\\d+)/ad_group/:adgroupid(\\d+)/creative/:adcreativeid(\\d+)',
  // adGroup
  '/dashboard/:adaccountid(\\d+)/campaign/:ad_type(display|direct_message|on_time_message|direct_pf_group_message)/:campaignid(\\d+)/ad_group/:adgroupid(\\d+)',
  // campaign
  '/dashboard/:adaccountid(\\d+)/campaign/:ad_type(display|direct_message|on_time_message|direct_pf_group_message)/:campaignid(\\d+)',

  // - v2 -
  // creative
  '/dashboard/:adaccountid(\\d+)/campaign/:campaignid(\\d+)/ad_group/:adgroupid(\\d+)/creative/:adcreativeid(\\d+)',
  // adGroup
  '/dashboard/:adaccountid(\\d+)/campaign/:campaignid(\\d+)/ad_group/:adgroupid(\\d+)',
  // campaign
  '/dashboard/:adaccountid(\\d+)/campaign/:campaignid(\\d+)',
  // adAccount
  '/dashboard/:adaccountid(\\d+)',
]

export default DashboardRedirectRegexArray
