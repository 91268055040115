import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { otpLogin } from '../../modules/common/mUser'
import { checkNoneEmpty } from '../../utils/regexUtils'
import { login } from '../../utils/authenticationUtils'

/**
 * OTP 키를 이용한 Observer 모드 Login Component
 */
class OtpLoginCheck extends React.PureComponent {
  componentDidMount() {
    const searchParams = new URLSearchParams(this.props.location.search)

    // OTP 로그인 요건이 만족하지 않을 시 middleware 를 태우지 않아야 함.
    if (this.isAvailableToOtpLogin(searchParams)) {
      this.props.otpLogin(searchParams)
    } else {
      login()
    }
  }

  // OTP login 을 위한 필수 조건 체크.
  isAvailableToOtpLogin = searchParams => {
    const otp = searchParams.get('otp')
    const accountId = searchParams.get('accountId')
    const adAccountId = searchParams.get('adAccountId')
    return checkNoneEmpty(otp, accountId, adAccountId)
  }

  render() {
    return null
  }
}

OtpLoginCheck.propTypes = {
  otpLogin: PropTypes.func,
  location: ReactRouterPropTypes.location,
  history: ReactRouterPropTypes.history
}

const mapDispatchToProps = {
  otpLogin
}

export default connect(
  null,
  mapDispatchToProps
)(OtpLoginCheck)
