import { keyMirror, naturalComparator } from '../utils/utils'
import AdditionalRoleEnum from './AdditionalRoleEnum'
import { difference } from 'lodash'

// 한글 오름차순 정렬. extra 는 array 끝으로.
const sortComparator = (typeA, typeB) => {
  const isExtraA = AdViewButtonEnum.valuesOfExtra().includes(typeA)
  const isExtraB = AdViewButtonEnum.valuesOfExtra().includes(typeB)
  const isFirstA = AdViewButtonEnum.valuesOfFirstItems().includes(typeA)
  const isFirstB = AdViewButtonEnum.valuesOfFirstItems().includes(typeB)

  if (isFirstA && !isFirstB) return -1
  if (!isFirstA && isFirstB) return 1
  if (isExtraA && !isExtraB) return 1
  if (!isExtraA && isExtraB) return -1

  return naturalComparator(
    AdViewButtonEnum.getName(typeA),
    AdViewButtonEnum.getName(typeB)
  )
}

// additionalRole 이 IN_HOUSE 인 경우에만 CUSTOM 노출
const filterPredicateByAdditionalRole = additionalRole => {
  return type => {
    if (type === AdViewButtonEnum.Type.CUSTOM) {
      return AdditionalRoleEnum.Type.IN_HOUSE === additionalRole
    }
    return type
  }
}

const AdViewButtonEnum = {
  Type: keyMirror({
    // common
    NONE: null,
    TALK_PURCHASE: null,
    PRODUCT_SUBSCRIBE: null,
    TALK_GIFT: null,
    GET_EMOTICON: null,
    TALK_ORDER: null,
    PLUSFRIEND_ADD: null,
    TALK_IN_SURVEY: null,
    TALK_TEST_DRIVE: null,
    TALK_IN_RESERVATION: null,
    TALK_IN_APPLY: null,
    TALK_IN_PARTICIPATE: null,
    TALK_REGISTRATION: null,
    TALK_SHARE: null,
    TALK_CONTACT: null,
    TALK_CALENDAR_SAVE: null,
    VIEW_LIVE: null,
    // button 2 only
    ACTION_SIGNUP: null,
    ACTION_GAMEPLAY: null,
    ACTION_PURCHASE: null,
    ACTION_PREBOOKING: null,
    ACTION_SUBSCRIPTION: null,
    APP_DOWNLOAD: null,
    ACTION_VIEWVIDEO: null,
    ACTION_TICKETING: null,
    ACTION_RESERVATION: null,
    ACTION_JOIN: null,
    // extra
    VIEW_DETAIL: null,
    CUSTOM: null,
  }),

  Name: {
    NONE: '미설정',
    TALK_PURCHASE: '구매하기',
    PRODUCT_SUBSCRIBE: '상품구독하기',
    TALK_GIFT: '선물하기',
    GET_EMOTICON: '이모티콘 받기',
    TALK_ORDER: '주문하기',
    PLUSFRIEND_ADD: '채널 추가하기',
    TALK_IN_SURVEY: '톡에서 설문하기',
    TALK_TEST_DRIVE: '톡에서 시승신청',
    TALK_IN_RESERVATION: '톡에서 예약하기',
    TALK_IN_APPLY: '톡에서 응모하기',
    TALK_IN_PARTICIPATE: '톡에서 참여하기',
    TALK_REGISTRATION: '톡에서 회원가입',
    TALK_SHARE: '톡으로 공유하기',
    TALK_CONTACT: '톡으로 문의하기',
    TALK_CALENDAR_SAVE: '톡캘린더 저장하기',
    VIEW_LIVE: 'LIVE보기',
    ACTION_SIGNUP: '가입하러 가기',
    ACTION_GAMEPLAY: '게임하러 가기',
    ACTION_PURCHASE: '구매하러 가기',
    ACTION_PREBOOKING: '사전예약하기',
    ACTION_SUBSCRIPTION: '신청하러 가기',
    APP_DOWNLOAD: '앱다운로드',
    ACTION_VIEWVIDEO: '영상보러 가기',
    ACTION_TICKETING: '예매하러 가기',
    ACTION_RESERVATION: '예약하러 가기',
    ACTION_JOIN: '참여하러 가기',
    VIEW_DETAIL: '자세히보기',
    CUSTOM: '직접 입력하기',
  },

  Description: {
    NONE: '',
    TALK_PURCHASE:
      '카카오 메이커스, 톡스토어를 랜딩으로 설정할 경우 선택합니다. makers.kakao.com/, store.kakao.com/가 포함된 상세 페이지로 연결하여 구매를 유도할 수 있습니다.',
    TALK_GIFT:
      '카카오톡 선물하기를 랜딩으로 설정할 경우 선택합니다. gift-talk.kakao.com/가 포함된 상세 페이지로 연결하여 구매를 유도할 수 있습니다.',
    TALK_ORDER:
      '카카오톡 주문하기를 랜딩으로 설정할 경우 선택합니다. order.kakao.com/가 포함된 상세 페이지로 연결하여 주문을 유도할 수 있습니다.',
    PRODUCT_SUBSCRIBE:
      '카카오톡 구독ON 서비스로 랜딩을 설정할 경우 선택합니다. ss.kakao.com/, on.kakao.com/가 포함된 상세 페이지로 연결하여 상품구독을 유도할 수 있습니다.',
    GET_EMOTICON:
      '카카오톡 브랜드 이모티콘을 랜딩으로 설정할 경우 선택합니다. emoticon.kakao.com/items가 포함된 상세 페이지로 연결하여 이벤트 참여 및 카카오톡 채널 추가를 유도할 수 있습니다.',
    PLUSFRIEND_ADD:
      '카카오톡 채널을 추가하는 랜딩으로 설정할 경우 선택합니다. 채널 정보와 프로모션 공지 등 콘텐츠 정보를 제공하고, 친구가 아닌 사용자에게는 채널 추가 팝업이 함께 노출됩니다.',
    TALK_IN_SURVEY:
      '광고계정에 연결된 비즈니스폼을 랜딩으로 선택하여 광고주 서비스와 관련된 설문, 응모 예약 등 사용자의 참여를 이끌어 낼 수 있습니다.',
    TALK_TEST_DRIVE:
      '광고계정에 연결된 비즈니스폼을 랜딩으로 설정할 경우 선택합니다. 시승신청이 필요한 수송업종의 경우 관련된 비즈니스폼을 통해 사용자의 참여를 이끌어 낼 수 있습니다.',
    TALK_IN_RESERVATION:
      '광고계정에 연결된 비즈니스폼을 랜딩으로 선택하여 광고주 서비스와 관련된 설문, 응모 예약 등 사용자의 참여를 이끌어 낼 수 있습니다.',
    TALK_IN_APPLY:
      '광고계정에 연결된 비즈니스폼을 랜딩으로 선택하여 광고주 서비스와 관련된 설문, 응모 예약 등 사용자의 참여를 이끌어 낼 수 있습니다.',
    TALK_IN_PARTICIPATE:
      '광고계정에 연결된 비즈니스폼을 랜딩으로 선택하여 광고주 서비스와 관련된 설문, 응모 예약 등 사용자의 참여를 이끌어 낼 수 있습니다.',
    TALK_REGISTRATION:
      '카카오싱크 동의창으로 랜딩을 설정할 경우 선택합니다. 광고주 서비스의 회원을 쉽고 빠르게 모을 수 있고, 카카오톡 계정을 사용 중인 이용자는 별도 가입 절차 없이 간편하게 회원가입이 가능합니다.',
    TALK_SHARE:
      "애드뷰 공유를 유도하고 싶은 경우 선택합니다. 사용자가 '톡으로 공유하기' 버튼을 선택하면 애드뷰를 다른 사람에게 간편하게 전달할 수 있는 공유창이 노출됩니다.",
    TALK_CONTACT:
      '카카오톡 채널의 챗봇을 랜딩으로 선택하여 채널 추가 유도와 상담/문의하기가 즉시 가능하도록 설정합니다. 챗봇이 연결되어 있는 톡채널의 채팅방으로 이동해 사용자에게 전달하고자 하는 정보를 다이렉트로 전달할 수 있습니다.',
    TALK_CALENDAR_SAVE:
      '톡캘린더를 랜딩으로 설정할 경우 톡캘린더 일정 확인 및 등록을 할 수 있습니다. 사용자에게 일정 안내 및 저장, 일정 알림을 통해 행사 참여를 유도할 수 있습니다.',
    VIEW_LIVE:
      '카카오쇼핑라이브를 랜딩으로 설정할 경우 선택합니다. pcms.kakao.com/pcms/, shoppinglive.kakao.com/가 포함된 상세 페이지로 연결하여 라이브 시청을 유도할 수 있습니다.',
    ACTION_SIGNUP:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_GAMEPLAY:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_PURCHASE:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_PREBOOKING:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_SUBSCRIPTION:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    APP_DOWNLOAD:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_VIEWVIDEO:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_TICKETING:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_RESERVATION:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    ACTION_JOIN:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    VIEW_DETAIL:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
    CUSTOM:
      '애드뷰 랜딩을 카카오톡 서비스가 아닌 외부 랜딩으로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
  },

  DescriptionOfPrimary: {
    NONE: '애드뷰에서 버튼1을 미노출합니다. 선택할 경우, 버튼2가 단독으로 노출됩니다.',
    VIEW_DETAIL:
      '애드뷰 랜딩을 목록에 있는 버튼 이외의 카카오톡 서비스로 설정할 경우 선택합니다. http:// 혹은 https:// 를 포함한 정상적인 형식의 URL을 입력하세요.',
  },

  DescriptionOfSecondary: {
    NONE: '애드뷰에서 버튼2을 미노출합니다. 선택할 경우, 버튼1이 단독으로 노출됩니다.',
  },

  getName(type) {
    return this.Name[type]
  },

  getDescription(type) {
    return this.Description[type]
  },

  getDescriptionOfPrimary(type) {
    return this.DescriptionOfPrimary[type] ?? this.getDescription(type)
  },

  getDescriptionOfSecondary(type) {
    return this.DescriptionOfSecondary[type] ?? this.getDescription(type)
  },

  values() {
    return Object.freeze(
      Object.keys(this.Type).filter(type => !this.isDeprecated(type))
    )
  },

  valuesOfSecondaryOnly() {
    return [
      this.Type.ACTION_SIGNUP,
      this.Type.ACTION_GAMEPLAY,
      this.Type.ACTION_PURCHASE,
      this.Type.ACTION_PREBOOKING,
      this.Type.ACTION_SUBSCRIPTION,
      this.Type.APP_DOWNLOAD,
      this.Type.ACTION_VIEWVIDEO,
      this.Type.ACTION_TICKETING,
      this.Type.ACTION_RESERVATION,
      this.Type.ACTION_JOIN,
    ]
  },

  valuesOfExtra() {
    // sorted
    return [this.Type.VIEW_DETAIL, this.Type.CUSTOM]
  },

  /**
   * 1. `전체`에서 `버튼2 전용` 제거, `extra` 제거
   * 2. 한글 오름차순 정렬
   * 3. 끝에 `extra` 추가
   * 4. 필터링 by role
   */
  primaryValues(additionalRole) {
    return difference(
      this.values(),
      this.valuesOfSecondaryOnly(),
      this.valuesOfExtra()
    )
      .sort(sortComparator)
      .concat(this.valuesOfExtra())
      .filter(filterPredicateByAdditionalRole(additionalRole))
      .filter(type => !this.isDeprecated(type))
  },

  /**
   * 1. 한글 오름차순 정렬된 `버튼1 전용` + 한글 오름차순 정렬된 `버튼2 전용`
   * 2. 끝에 `extra` 추가
   * 3. 필터링 by role
   */
  secondaryValues(additionalRole) {
    return difference(
      this.values(),
      this.valuesOfSecondaryOnly(),
      this.valuesOfExtra()
    )
      .sort(sortComparator)
      .concat(difference(this.valuesOfSecondaryOnly()).sort(sortComparator))
      .concat(this.valuesOfExtra())
      .filter(filterPredicateByAdditionalRole(additionalRole))
      .filter(type => !this.isDeprecated(type))
  },

  isWebUrl(type) {
    return ![
      AdViewButtonEnum.Type.TALK_PURCHASE,
      AdViewButtonEnum.Type.TALK_GIFT,
      AdViewButtonEnum.Type.TALK_ORDER,
      AdViewButtonEnum.Type.PRODUCT_SUBSCRIBE,
      AdViewButtonEnum.Type.TALK_REGISTRATION,
      AdViewButtonEnum.Type.GET_EMOTICON,
      AdViewButtonEnum.Type.PLUSFRIEND_ADD,
      AdViewButtonEnum.Type.TALK_SHARE,
      AdViewButtonEnum.Type.TALK_CONTACT,
      AdViewButtonEnum.Type.TALK_IN_SURVEY,
      AdViewButtonEnum.Type.TALK_TEST_DRIVE,
      AdViewButtonEnum.Type.TALK_IN_RESERVATION,
      AdViewButtonEnum.Type.TALK_IN_APPLY,
      AdViewButtonEnum.Type.TALK_IN_PARTICIPATE,
      AdViewButtonEnum.Type.TALK_CALENDAR_SAVE,
      AdViewButtonEnum.Type.VIEW_LIVE,
      AdViewButtonEnum.Type.NONE,
      AdViewButtonEnum.Type.CUSTOM, // 직접입력 케이스의 경우 스킴 or 웹 URL 모두 입력 가능하므로 코어 validation 건너띄기 위해 제외시킴.
    ].includes(type)
  },

  isDeprecated(code) {
    return [this.Type.TALK_CALENDAR_SAVE].includes(code)
  },

  valuesOfFirstItems() {
    return [this.Type.NONE]
  },
}

export default AdViewButtonEnum
