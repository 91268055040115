import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    MOTION: null,
    CUSTOM: null,
    NORMAL: null,
  }),

  Name: {
    MOTION: '모션 비즈보드',
    CUSTOM: '커스텀 비즈보드',
    NORMAL: '일반',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },

  isSpecialBizBoard(type) {
    return [this.Type.MOTION, this.Type.CUSTOM].includes(type)
  },

  isMotionBoard(type) {
    return this.Type.MOTION === type
  },

  isCustomBoard(type) {
    return this.Type.CUSTOM === type
  },
}
