import { CONTEXTPATH, fetchGet, fetchPost } from '../../../utils/fetchUtils'

/**
 * 17.1 연동된 카카오 비즈니스폼 리스트 조회
 * @param adAccountId
 * @param isFinished
 * true: 연동이 된 모든 비즈니스폼 리스트 조회 (비즈니스폼 연동 리스트 조회에 사용)
 * false: 연동이 된 비즈니스폼 중 종료일이 지나지 않은 리스트 조회 (소재, 애드뷰 에서 사용)
 */
function fetchAuthorizedBizFormList(adAccountId, isFinished = true) {
  return fetchGet(
    `${CONTEXTPATH}/talkBizFormLinkages?withFinished=${isFinished}`,
    adAccountId
  )
}

/**
 * 17.3 비즈니스폼 연동
 * @param adAccountId
 * @param requestBody
 */
function fetchBizFormLinkage(adAccountId, requestBody) {
  return fetchPost(
    `${CONTEXTPATH}/talkBizFormLinkages`,
    requestBody,
    adAccountId
  )
}

/**
 * 17.4 비즈니스폼 연동가능 리스트 조회
 * @param adAccountId
 */
function fetchUnauthorizedBizFormList(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/talkBizFormLinkages/linkable`, adAccountId)
}

/**
 * 17.5 비즈니스폼 단건조회(bizFormId로 조회)
 * @param adAccountId
 * @param bizFormId
 */
function fetchBizFormByBizFormId(adAccountId, bizFormId) {
  return fetchGet(
    `${CONTEXTPATH}/talkBizFormLinkages/bizForm/${bizFormId}`,
    adAccountId
  )
}

export default {
  fetchAuthorizedBizFormList,
  fetchUnauthorizedBizFormList,
  fetchBizFormLinkage,
  fetchBizFormByBizFormId,
}
