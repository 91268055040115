import {
  checkEmpty,
  checkNotEmpty,
  checkSpecialCharacter,
  checkTalkScheme,
  checkUrl,
  checkUrlHasWhiteSpace,
  hasContinuousSharp,
  hasFullWidthWhitespace,
  hasNewLine,
  hasNotAllowedEncodedSpecialCharacter,
  hasNotAllowedSpecialCharacter,
  hasOnlyAlphanumeric,
  hasSpecialCharacter,
  isPositiveIntegerSequence,
  validateInputLength,
} from '../../../utils/regexUtils'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import {
  IMAGE_NATIVE_FORM_VALIDATION_KEY_PATH,
  IMAGE_NATIVE_FORM_VALIDATOR,
} from './imageNativeFormValidatorV2'
import {
  IMAGE_BANNER_FORM_VALIDATION_KEY_PATH,
  IMAGE_BANNER_FORM_VALIDATOR,
} from './imageBannerFormValidatorV2'
import {
  VIDEO_RESPONSIVE_FORM_VALIDATION_KEY_PATH,
  VIDEO_RESPONSIVE_FORM_VALIDATOR,
} from './videoNativeFormValidatorV2'
import {
  IMAGE_BOX_FORM_VALIDATION_KEY_PATH,
  IMAGE_BOX_FORM_VALIDATOR,
} from './imageBoxFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { MESSAGE_CREATIVE_FORM_VALIDATION_KEY_PATH } from './messageCreativeFormValidatorV2'
import ActionButtonEnum from '../../../enums/ActionButtonEnum'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import {
  CATALOG_FORM_VALIDATION_KEY_PATH,
  CATALOG_FORM_VALIDATOR,
} from './catalogFormValidatorV2'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import {
  BIZ_BOARD_BANNER_FORM_VALIDATION_KEY_PATH,
  BIZ_BOARD_BANNER_FORM_VALIDATOR,
} from './bizBoardBannerFormValidatorV2'
import LandingUrlEnum from '../../../enums/LandingUrlEnum'
import { getMessageValidatorByMessageType } from '../../message/messageFormValidator'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../message/messageFormValidationKey'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import {
  PRODUCT_CATALOG_FORM_VALIDATION_KEY_PATH,
  PRODUCT_CATALOG_FORM_VALIDATOR,
} from './productCatalogFormValidator'
import FormUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'
import {
  PC_TALK_RICH_POP_FORM_VALIDATION_KEY_PATH,
  PC_TALK_RICH_POP_FORM_VALIDATOR,
} from './pcTalkRichPopFormValidator'
import {
  PC_TALK_BOTTOM_FORM_VALIDATION_KEY_PATH,
  PC_TALK_BOTTOM_FORM_VALIDATOR,
} from './pcTalkBottomFormValidator'
import { hasUnEncodedPercentage } from '../../../utils/stringUtils'
import LandingSchemeEnum from '../../../enums/LandingSchemeEnum'
import {
  FOCUS_FULL_VIEW_FORM_VALIDATION_KEY_PATH,
  FOCUS_FULL_VIEW_FORM_VALIDATOR,
} from './focusFullViewFormValidator'
import {
  PROFILE_FULL_VIEW_FORM_VALIDATION_KEY_PATH,
  PROFILE_FULL_VIEW_FORM_VALIDATOR,
} from './profileFullViewFormValidator'

const validateCreativeTitle = (
  title,
  formData,
  assetDescription,
  campaignType
) => {
  // 이미지 네이티브, 카탈로그, 비디오 네이티브만 수행
  const { creativeFormat, profileName, description } = formData || {}

  if (checkEmpty(title)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  if (hasFullWidthWhitespace(title)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  }
  if (!validateInputLength(title, 1, 25)) {
    return IS_NOT_VALID('타이틀은 1~25자까지 입력가능합니다.')
  }

  if (
    (creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE ||
      creativeFormat === CreativeFormatEnum.Type.VIDEO_NATIVE ||
      campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW ||
      campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) &&
    (profileName === title || title === description)
  ) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
  }

  if (
    creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL &&
    (title === profileName || title === assetDescription)
  ) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
  }

  return IS_VALID()
}

const validateCreativeProfileName = (profileName, formData, campaignType) => {
  // 이미지 네이티브, 카탈로그, 비디오 네이티브만 수행
  const { creativeFormat, title, description, assetGroups, compatibleTitle } =
    formData

  if (checkEmpty(profileName)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  if (hasFullWidthWhitespace(profileName)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  }

  if (!validateInputLength(profileName, 1, 20)) {
    return IS_NOT_VALID('프로필 이름은 1~20자까지 입력가능합니다.')
  }

  if (
    (creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE ||
      creativeFormat === CreativeFormatEnum.Type.VIDEO_NATIVE ||
      campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW ||
      campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) &&
    (profileName === title || profileName === description)
  ) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
  }

  if (
    creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE &&
    profileName === compatibleTitle
  ) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE_COMPATIBLE_TITLE
    )
  }

  if (creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL) {
    const duplicatedAssetGroupIndex = assetGroups.findIndex(
      assetGroup =>
        profileName === assetGroup.get('title') ||
        profileName === assetGroup.get('description')
    ) // assetGroups에서 프로필이름과 중복되는 타이틀이 있는 assetGroup이 있는지 확인, 없으면 -1, 있으면 해당 group의 index

    if (duplicatedAssetGroupIndex > -1) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
    }
  }

  return IS_VALID()
}

const validateCreativePromotionalWord = (description, formData) => {
  const { profileName, title } = formData || {}

  if (checkEmpty(description)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  } else if (hasFullWidthWhitespace(description)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  } else if (!validateInputLength(description, 1, 45)) {
    return IS_NOT_VALID('홍보문구는 1~45자까지 입력가능합니다.')
  } else if (profileName === description || title === description) {
    return IS_NOT_VALID(
      '타이틀, 프로필 이름, 홍보문구는 서로 다른 문구를 입력하세요.'
    )
  }
  return IS_VALID()
}

const validateCreativeFrequencyCap = frequencyCap => {
  const { count, type } = frequencyCap

  if (type !== 'AUTO' && !isPositiveIntegerSequence(count)) {
    return IS_NOT_VALID('게재빈도 설정이 유효하지 않습니다.')
  }

  return IS_VALID()
}

const validateCreativeActionButton = actionButton => {
  if (checkEmpty(actionButton) || actionButton === ActionButtonEnum.Type.NONE) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  return IS_VALID()
}

const validateCreativeLandingURL = ({
  formData,
  campaignType,
  deviceTypes,
  skanProtocolInfo,
}) => {
  const {
    rspvLandingUrl,
    pcLandingUrl,
    mobileLandingUrl,
    useDefaultLandingUrl,
  } = formData

  if (checkNotEmpty(skanProtocolInfo)) {
    return IS_VALID()
  }

  if (
    !useDefaultLandingUrl &&
    CreativeConstraints.isLandingURLRequired({ campaignType })
  ) {
    const landingUrls = [
      rspvLandingUrl?.trim(),
      pcLandingUrl?.trim(),
      mobileLandingUrl?.trim(),
    ]

    if (landingUrls.some(_url => checkSpecialCharacter(_url, 'kclid'))) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_KCLID_CHARACTER_IN_LANDING_URL
      )
    }

    if (landingUrls.every(v => checkEmpty(v))) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    // 쇼핑박스의 경우 모바일 / pc 모두 입력이 되어야 함
    // 기존 쇼핑박스는 pc / mobile 중 하만 입력이 되면 됨
    if (campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING) {
      const currentDevice =
        LandingUrlEnum.getCurrentSelectedDeviceTypeV2(deviceTypes)

      if (currentDevice === LandingUrlEnum.InputType.RSPV_DEVICE) {
        if (
          checkEmpty(rspvLandingUrl) &&
          (checkEmpty(pcLandingUrl) || checkEmpty(mobileLandingUrl))
        ) {
          return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }
      }

      if (currentDevice === LandingUrlEnum.InputType.PC_ONLY_DEVICE) {
        if (checkEmpty(pcLandingUrl)) {
          return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }
      }

      if (currentDevice === LandingUrlEnum.InputType.MOBILE_ONLY_DEVICE) {
        if (checkEmpty(mobileLandingUrl)) {
          return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }
      }
    }

    if (campaignType === CampaignTypeEnum.Type.ELECTION_2024_04) {
      if (
        !landingUrls
          .filter(v => checkNotEmpty(v))
          .every(v => checkUrl(v) || checkTalkScheme(v))
      )
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    } else {
      // input 두개 중 한칸이라도 입력되어있다면 validation pass 이기 때문에 입력되지 않은 것은 빼고 판단.
      if (!landingUrls.filter(v => checkNotEmpty(v)).every(v => checkUrl(v))) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }
    }

    if (landingUrls.some(v => checkUrlHasWhiteSpace(v))) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
      )
    }

    if (
      landingUrls.some(
        v => hasContinuousSharp(v) || hasNotAllowedSpecialCharacter(v)
      )
    ) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_NOT_ALLOWED_SPECIAL_CHARACTER
      )
    }

    if (
      landingUrls.some(v => {
        const { searchParams } = FormUtils.Url.urlToParams(v)
        const paramString = searchParams.map(
          ({ key, value }) => `${key}=${value}`
        )
        return (
          hasNotAllowedEncodedSpecialCharacter(paramString) ||
          hasUnEncodedPercentage(paramString)
        )
      })
    ) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_INVALID_CHARACTER_WITH_PARAMETER
      )
    }
  }

  return IS_VALID()
}

const validateCreativeName = (name, maxLength = 50) => {
  if (checkEmpty(name)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  if (hasFullWidthWhitespace(name)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  }

  if (!validateInputLength(name, 1, maxLength)) {
    return IS_NOT_VALID(`소재 이름은 1~${maxLength}자까지 입력가능합니다.`)
  }

  return IS_VALID()
}

const validateCreativeAltText = altText => {
  if (!checkNotEmpty(altText)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  if (hasFullWidthWhitespace(altText)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  }

  if (hasSpecialCharacter(altText)) {
    return IS_NOT_VALID(
      '소재 설명에 특수문자는 입력할 수 없습니다. 다시 입력하세요.'
    )
  }

  if (!validateInputLength(altText, 1, 30)) {
    return IS_NOT_VALID('소재 설명은 30자까지 입력가능합니다.')
  }

  return IS_VALID()
}

const validateCreativePromotionalVideo = video => {
  const { url } = video || {}
  // 라이브러리에서 가져올 때 인코딩 처리되므로 percent 확인 불필요.
  if (!video || !url) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  return IS_VALID()
}

const validateCreativeOpinionProofComment = opinionProofComment => {
  if (hasFullWidthWhitespace(opinionProofComment)) {
    return IS_NOT_VALID(
      CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  }

  return IS_VALID()
}

const validateLandingInfo = landingInfo => {
  const {
    landingType,
    profileId,
    action,
    hasBot,
    url,
    communicatorAdViewId,
    bizFormId,
    talkCalendarEventId,
    channelPostId,
  } = landingInfo || {}

  if (checkEmpty(landingType)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  switch (landingType) {
    /**
     * profileId 필수
     * hasBot: true 필수
     * action: 50자, 특수문자 !,?만 허용, 띄어쓰기 가능, 줄바꿈 불가
     */
    case LandingSchemeEnum.Type.PF_BOT: {
      const isValidProfile = profileId > 0 && hasBot

      if (!isValidProfile || checkEmpty(action)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (hasFullWidthWhitespace(action)) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      }

      const isValidAction =
        !hasNewLine(action) &&
        validateInputLength(action, 1, 50) &&
        !hasSpecialCharacter(action, '!?')

      if (!isValidAction) {
        return IS_NOT_VALID('정상적인 형식의 키워드를 입력하세요.')
      }

      break
    }

    case LandingSchemeEnum.Type.AD_VIEW: {
      if (!communicatorAdViewId || communicatorAdViewId === -1) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      break
    }

    case LandingSchemeEnum.Type.BIZ_FORM: {
      if (!bizFormId || bizFormId === -1) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      break
    }

    case LandingSchemeEnum.Type.URL: {
      if (checkEmpty(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (!checkUrl(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return FormUtils.Url.validateUrl(url)
    }
    case LandingSchemeEnum.Type.WEBEMBED_CHANNEL: {
      if (checkEmpty(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (!checkUrl(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      if (checkUrlHasWhiteSpace(url)) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
        )
      }

      if (hasNotAllowedSpecialCharacter(url)) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_NOT_ALLOWED_SPECIAL_CHARACTER
        )
      }

      if (checkSpecialCharacter(url, 'kclid')) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_KCLID_CHARACTER_IN_LANDING_URL
        )
      }

      break
    }

    case LandingSchemeEnum.Type.CHANNEL_WEBVIEW: {
      const isValidProfile = profileId > 0
      if (!isValidProfile || checkEmpty(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (!checkUrl(url)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      if (checkUrlHasWhiteSpace(url)) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
        )
      }

      if (checkSpecialCharacter(url, 'kclid')) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_KCLID_CHARACTER_IN_LANDING_URL
        )
      }

      break
    }

    case LandingSchemeEnum.Type.CHANNEL_POST: {
      const isValidProfile = profileId > 0

      if (
        !isValidProfile ||
        checkEmpty(channelPostId) ||
        channelPostId === -1
      ) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (!(channelPostId > 0)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      break
    }

    case LandingSchemeEnum.Type.TALK_CALENDAR: {
      if (checkEmpty(talkCalendarEventId)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      if (hasFullWidthWhitespace(talkCalendarEventId)) {
        return IS_NOT_VALID(
          CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      }

      if (!hasOnlyAlphanumeric(talkCalendarEventId)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.TALK_CALENDAR)
      }

      break
    }

    case LandingSchemeEnum.Type.CHANNEL_ADD: {
      const isValidProfile = profileId > 0

      if (!isValidProfile) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      break
    }

    default: {
      break
    }
  }

  return IS_VALID()
}

const getCreativeValidationKeyByFormat = ({ creativeFormat }) => {
  if (CreativeFormatEnum.isMessage(creativeFormat)) {
    return MESSAGE_FORM_VALIDATION_KEY
  }

  return CREATIVE_FORM_VALIDATION_KEY
}

const getCreativeValidationKeyPathByFormat = ({
  creativeFormat,
  campaignType,
}) => {
  switch (creativeFormat) {
    case CreativeFormatEnum.Type.IMAGE_NATIVE: {
      return IMAGE_NATIVE_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.IMAGE_BANNER: {
      return campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
        ? BIZ_BOARD_BANNER_FORM_VALIDATION_KEY_PATH
        : IMAGE_BANNER_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.VIDEO_NATIVE: {
      return VIDEO_RESPONSIVE_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.IMAGE_BOX: {
      return IMAGE_BOX_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.CATALOG_MANUAL: {
      return CATALOG_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
    case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
      return MESSAGE_CREATIVE_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
      return PRODUCT_CATALOG_FORM_VALIDATION_KEY_PATH
    }

    case CreativeFormatEnum.Type.RICH_NATIVE: {
      switch (campaignType) {
        case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
          return PC_TALK_RICH_POP_FORM_VALIDATION_KEY_PATH
        case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
          return PC_TALK_BOTTOM_FORM_VALIDATION_KEY_PATH
        case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
          return FOCUS_FULL_VIEW_FORM_VALIDATION_KEY_PATH
        case CampaignTypeEnum.Type.PROFILE_FULL_VIEW:
          return PROFILE_FULL_VIEW_FORM_VALIDATION_KEY_PATH
        default:
          return null
      }
    }

    default: {
      return null
    }
  }
}

const getCreativeValidatorByFormat = ({ creativeFormat, campaignType }) => {
  switch (creativeFormat) {
    case CreativeFormatEnum.Type.IMAGE_NATIVE: {
      return IMAGE_NATIVE_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.IMAGE_BANNER: {
      return campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
        ? BIZ_BOARD_BANNER_FORM_VALIDATOR
        : IMAGE_BANNER_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.VIDEO_NATIVE: {
      return VIDEO_RESPONSIVE_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.IMAGE_BOX: {
      return IMAGE_BOX_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.CATALOG_MANUAL: {
      return CATALOG_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
    case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
      return getMessageValidatorByMessageType({ campaignType, creativeFormat })
    }

    case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
      return PRODUCT_CATALOG_FORM_VALIDATOR
    }

    case CreativeFormatEnum.Type.RICH_NATIVE: {
      switch (campaignType) {
        case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
          return PC_TALK_RICH_POP_FORM_VALIDATOR
        case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
          return PC_TALK_BOTTOM_FORM_VALIDATOR
        case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
          return FOCUS_FULL_VIEW_FORM_VALIDATOR
        case CampaignTypeEnum.Type.PROFILE_FULL_VIEW:
          return PROFILE_FULL_VIEW_FORM_VALIDATOR
        default:
          return null
      }
    }

    default: {
      return null
    }
  }
}

const validateDiscountedPrice = ({ price, discountedPrice }) => {
  const { amount } = price || {}
  const { amount: discountedAmount } = discountedPrice || {}

  if (
    checkNotEmpty(amount) &&
    checkNotEmpty(discountedAmount) &&
    Math.floor(((amount - discountedAmount) / amount) * 100) < 1
  ) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DISCOUNT_PRICE)
  } else {
    return IS_VALID()
  }
}

const validateCompatibleLandingUrl = ({ compatiblePcLandingUrl, formData }) => {
  const {
    landingInfo: { landingType },
  } = formData || {}

  if (landingType === LandingSchemeEnum.Type.BIZ_FORM) {
    if (!checkUrl(compatiblePcLandingUrl)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    }

    return FormUtils.Url.validateUrl(compatiblePcLandingUrl)
  }

  return IS_VALID()
}

export {
  validateCreativeTitle,
  validateCreativeProfileName,
  validateCreativePromotionalWord,
  validateCreativeName,
  validateCreativeAltText,
  validateCreativeFrequencyCap,
  validateCreativeActionButton,
  validateCreativeLandingURL,
  validateCreativePromotionalVideo,
  validateCreativeOpinionProofComment,
  getCreativeValidationKeyByFormat,
  getCreativeValidationKeyPathByFormat,
  getCreativeValidatorByFormat,
  validateDiscountedPrice,
  validateLandingInfo,
  validateCompatibleLandingUrl,
}
