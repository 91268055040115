import { keyMirror } from '../utils/utils'
import CreativeFormatEnum from './CreativeFormatEnum'

const CreativeCategoryEnum = {
  Type: keyMirror({
    IMAGE: null,
    VIDEO: null,
    MESSAGE: null,
    SERVICE: null,
    DYNAMIC: null,
    RICH: null,
  }),

  Name: {
    IMAGE: '이미지',
    VIDEO: '동영상',
    MESSAGE: '메시지',
    SERVICE: '서비스',
    DYNAMIC: '다이내믹',
    RICH: '리치',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },

  getValueByCreativeFormat(creativeFormat) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.IMAGE_BANNER:
      case CreativeFormatEnum.Type.IMAGE_NATIVE:
      case CreativeFormatEnum.Type.IMAGE_BOX:
      case CreativeFormatEnum.Type.CATALOG_MANUAL: {
        return this.Type.IMAGE
      }

      case CreativeFormatEnum.Type.VIDEO_NATIVE:
      case CreativeFormatEnum.Type.VIDEO_INSTREAM: {
        return this.Type.VIDEO
      }

      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return this.Type.MESSAGE
      }

      case CreativeFormatEnum.Type.SERVICE_CONTENT: {
        return this.Type.SERVICE
      }

      case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
        return this.Type.DYNAMIC
      }

      case CreativeFormatEnum.Type.RICH_NATIVE: {
        return this.Type.RICH
      }

      default:
        return null
    }
  },
}

export default CreativeCategoryEnum
