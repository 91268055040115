import { ScaleImageFitCenterStyle } from '../../../../../../utils/imageUtils'
import cx from 'classnames'
import { checkEmpty } from '../../../../../../utils/regexUtils'
import ActionButtonEnum from '../../../../../../enums/ActionButtonEnum'
import React from 'react'
import ImageNativeTemplatePropTypes from '../imageNativeTemplatePropTypes'

export const BgImage_BottomVerticalTitleProfileName_1_1_Page3 = ({
  title,
  profileName,
  promotionalImageURL,
  isInAd,
}) => {
  return (
    <div className="material_default material_square2">
      <div className="material_box">
        <span className="ad_mark">AD</span>
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <div className="box_induce">
          <strong
            className={cx('tit_induce', {
              no_text: title?.trim()?.length === 0,
            })}>
            <span className="inner_tit">
              {title?.trim() || (isInAd ? '타이틀' : '미입력 요소')}
            </span>
          </strong>
          <p
            className={cx('txt_induce', {
              no_text: profileName?.trim()?.length === 0,
            })}>
            {profileName?.trim() || '프로필 이름'}
          </p>
        </div>
      </div>
    </div>
  )
}

export const LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page8 = ({
  title,
  actionButton,
  profileName,
  promotionalImageURL,
}) => {
  return (
    <div className="material_default material_square1">
      <span className="ad_mark">AD</span>
      <div className="thumb_img">
        {promotionalImageURL && (
          <span
            className="img_thumb"
            style={ScaleImageFitCenterStyle({
              url: promotionalImageURL,
              backgroundColor: '#ffffff',
            })}
          />
        )}
      </div>
      <div className="box_induce">
        <strong
          className={cx('tit_induce', {
            no_text: title?.trim()?.length === 0,
          })}>
          {title?.trim() || '타이틀'}
        </strong>
        <p
          className={cx('txt_induce', {
            no_text: profileName?.trim()?.length === 0,
          })}>
          {profileName?.trim() || '프로필 이름'}
        </p>
        <span
          className={cx('btn_induce2', {
            no_text: actionButton === ActionButtonEnum.Type.NONE,
          })}>
          {ActionButtonEnum.getName(actionButton) || '버튼 선택'}
        </span>
      </div>
    </div>
  )
}

export const NAS_1_1 = ({
  promotionalImageURL,
  title,
  profileName,
  description,
  profileImageURL,
}) => {
  return (
    <div className={cx('material_default', 'material_nas', 'nas_type1')}>
      <div className="inner_nas">
        <div className="thumb_img">
          {promotionalImageURL && (
            <div
              className={'item_bg'}
              style={{
                background: `url(${promotionalImageURL}) 50% 50%`,
              }}></div>
          )}
          <div className="item_thumb">
            {promotionalImageURL && (
              <img
                src={promotionalImageURL}
                width={`160`}
                height={`80`}
                className={'img_thumb'}
                alt={'홍보 이미지'}
              />
            )}
          </div>
        </div>
        <div className="thumb_detail">
          <strong className={cx('tit_induce', { no_text: checkEmpty(title) })}>
            {title || '타이틀'}
          </strong>
          <span
            className={cx('txt_induce', { no_text: checkEmpty(description) })}>
            {description || '홍보문구'}
          </span>
          <div className="profile_info">
            <span className="thumb_profile">
              {profileImageURL && (
                <img
                  src={profileImageURL}
                  alt="프로필이미지"
                  className="img_profile"
                />
              )}
            </span>
            <span className="info_profile">
              <span className="inner_info">
                <strong
                  className={cx('tit_profile', {
                    no_text: checkEmpty(profileName),
                  })}>
                  {profileName || '프로필 이름'}
                </strong>
              </span>
            </span>
          </div>

          <span className="ad_mark">AD</span>
          <div className="wrap_btn">
            <span className="btn_adview">
              <span className="ico_btnmore">자세히 보기</span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

BgImage_BottomVerticalTitleProfileName_1_1_Page3.propTypes =
  ImageNativeTemplatePropTypes.Page
LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page8.propTypes =
  ImageNativeTemplatePropTypes.Page
NAS_1_1.propTypes = ImageNativeTemplatePropTypes.Page
