import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    INCLUDE: null,
    EXCLUDE: null,
  }),

  Name: {
    INCLUDE: '포함',
    EXCLUDE: '제외',
  },

  getName(type) {
    return this.Name[type]
  },
}
