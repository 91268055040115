import { keyMirror } from '../utils/utils'

export default {
  STATUS: keyMirror({
    IDLE: null,
    LOADING: null,
    DONE: null,
    FAIL: null,

    // 하위 항목들은 맞춤보고서에서만 사용하고 있는 ENUM 값입니다.
    SUB_DONE: null,
    SUB_FAIL: null,
    SUB_LOADING: null
  })
}
