import assign from 'object-assign'
import { createReducer } from 'redux-immutablejs'
import { fromJS, Map } from 'immutable'
import {
  convertToCommaSeparatedString,
  dateToDefaultString,
  dateToString,
  fileDownloadBlob,
  keyMirror,
} from '../../utils/utils'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import { closeAllPopup } from '../common/mPopup'
import { redirectCashPath } from '../../utils/router/routeUtils'
import { checkEmpty, checkNotEmpty } from '../../utils/regexUtils'
import {
  BILLING_MEMBER_TYPE,
  CASH_LOADING_STATE,
  cashDefaultDate,
  CHARGE_STATUS_KEY,
  getChargeHistoryInfoLabel,
  handleErrorCatch,
  PAGE_STATE_KEY,
  WITHDRAW_ERROR_TYPE,
  WITHDRAW_TYPE,
} from './cashCommons/aCashCommons'
import CashEnum from '../../enums/CashEnum'
import { Validation } from '../../validators/validation'
import {
  CASH_DEFERRED_PAYMENT_VALIDATION_KEY,
  CASH_DEFERRED_PAYMENT_VALIDATION_KEY_PATH,
  CASH_DEFERRED_PAYMENT_VALIDATOR,
} from '../../validators/cash/deferredPaymentStatusFormValidator'
import { coerceToArray } from '../../utils/stringUtils'
import moment from 'moment'
import Calendar from '../../utils/calendar'
import CalendarEnum from '../../enums/CalendarEnum'
import {
  CASH_CHARGE_FORM_VALIDATION_KEY,
  CASH_CHARGE_FORM_VALIDATION_KEY_PATH,
  CASH_CHARGE_FORM_VALIDATOR,
} from '../../validators/cash/cashChargeFormValidator'

const Cash = keyMirror({
  GET_ACCOUNT: null,
  REQUEST_REFUND: null,
  SET_REFUND_ACCOUNT_INFO: null,
  RECEIVE_CASH_INFO: null,
  SET_CHARGE_HISTORY: null,
  CHANGE_CHARGE_HISTORY_SEARCH_OPTIONS: null,
  SET_FREE_CASH_HISTORY: null,
  CHANGE_FREE_CASH_SEARCH_OPTIONS: null,
  SET_WITH_DRAW_HISTORY: null,
  CHANGE_WITH_DRAW_HISTORY_SEARCH_OPTIONS: null,
  CHANGE_LOADING_STATUS: null,
  GET_WITHDRAW_ACCOUNT: null,
  DELETE_WITHDRAW_ACCOUNT: null,
  GET_DEFERRED_PAYMENT_LIST: null,
  GET_DEFERRED_PAYMENT_DETAIL_LIST: null,
  CHANGE_DEFERRED_PAYMENT_LIMIT_CHANGE_LOG: null,
  GET_REPAYMENT: null,
  CHANGE_DEFERRED_PAYMENT_SEARCH_OPTIONS: null,
  GET_VIRTUAL_ACCOUNT_HISTORY: null,
  INIT_ALL_CASH_STATE: null,
  UPDATE_CANCEL_DEPOSIT_FEE_INFO: null,
  CHANGE_CALENDAR: null,
})

const initialCalendarState = {
  startDate: moment().subtract(6, 'days'),
  endDate: moment(),
  periodType: CalendarEnum.Preset.Type.LAST7DAYS,
  customDateDiff: null,
}

const initialState = fromJS({
  // 캐시 현황
  cashInfo: {},

  calendar: {
    chargeHistory: initialCalendarState,
    freeCashHistory: initialCalendarState,
    withdrawHistory: initialCalendarState,
    cancelDepositFeeHistory: initialCalendarState,
    deferredPaymentLimitHistory: initialCalendarState,
  },

  // 환불 계좌
  withdrawAccount: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    accountInfo: {},
  },

  // 충전 내역
  chargeHistory: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    list: {},
    searchOptions: {
      dspId: 'KAKAOMOMENT',
      from: cashDefaultDate.from,
      to: cashDefaultDate.to,
      page: 0,
      size: 5,
      type: '',
    },
  },

  // 무상캐시 사용현황
  freeCashHistory: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    list: {},
    searchOptions: {
      dspId: 'KAKAOMOMENT',
      from: cashDefaultDate.from,
      to: cashDefaultDate.to,
      page: 0,
      size: 5,
      status: '',
    },
  },

  // 환불처리내역
  withdrawHistory: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    list: {},
    searchOptions: {
      dspId: 'KAKAOMOMENT',
      from: cashDefaultDate.from,
      to: cashDefaultDate.to,
      page: 0,
      size: 5,
      statuses: '',
    },
  },

  // 후불결제 현황
  deferredPaymentStatus: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    list: {},
    searchOptions: {
      dspId: 'KAKAOMOMENT',
      page: 0,
      size: 5,
      repaymentStatus: '',
    },
    detailList: [],
    repayment: {
      bondRepays: [],
      nextDueDt: '',
    },
  },

  // 가상계좌 발급이력
  virtualAccountHistory: {
    planRepayAmount: null,
    remainedBondAmount: null,
    repaymentPlanVirtualAccounts: [
      {
        dt: '2019-09-20',
        txId: '2121',
        amount: 1000,
        virtualAccount: '123',
        bankName: 'test',
      },
    ],
  },

  // 해지수수료 부과내역
  cancelDepositFeeHistory: {
    list: {},
    searchOptions: {
      page: 0,
      size: 10,
      from: cashDefaultDate.from,
      to: cashDefaultDate.to,
    },
  },

  // 후불한도 변경 이력
  deferredPaymentLimitChangeLog: {
    loadingStatus: CASH_LOADING_STATE.IDLE,
    list: {},
    searchOptions: {
      startDate: moment(cashDefaultDate.from).format('YYYYMMDD'),
      endDate: moment(cashDefaultDate.to).format('YYYYMMDD'),
      page: 0,
      size: 10,
    },
  },
})

export default createReducer(initialState, {
  [Cash.CHANGE_LOADING_STATUS]: (state, { key, value }) =>
    state.setIn([key, 'loadingStatus'], fromJS(value)),

  [Cash.SET_CHARGE_HISTORY]: (state, { data }) =>
    state.setIn(['chargeHistory', 'list'], fromJS(data || {})),

  [Cash.CHANGE_CHARGE_HISTORY_SEARCH_OPTIONS]: (state, { key, value }) =>
    state.setIn(['chargeHistory', 'searchOptions', key], fromJS(value)),

  [Cash.SET_FREE_CASH_HISTORY]: (state, { data }) =>
    state.setIn(['freeCashHistory', 'list'], fromJS(data || {})),

  [Cash.CHANGE_FREE_CASH_SEARCH_OPTIONS]: (state, { key, value }) =>
    state.setIn(['freeCashHistory', 'searchOptions', key], fromJS(value)),

  [Cash.SET_WITH_DRAW_HISTORY]: (state, { data }) =>
    state.setIn(['withdrawHistory', 'list'], fromJS(data || {})),

  [Cash.CHANGE_WITH_DRAW_HISTORY_SEARCH_OPTIONS]: (state, { key, value }) =>
    state.setIn(['withdrawHistory', 'searchOptions', key], fromJS(value)),

  [Cash.RECEIVE_CASH_INFO]: (state, { info }) =>
    state.set('cashInfo', fromJS(info || initialState.get('cashInfo'))),

  [Cash.GET_WITHDRAW_ACCOUNT]: (state, { info }) =>
    state.setIn(['withdrawAccount', 'accountInfo'], fromJS(info || {})),

  [Cash.DELETE_WITHDRAW_ACCOUNT]: state =>
    state.setIn(['withdrawAccount', 'accountInfo'], fromJS({})),

  [Cash.GET_DEFERRED_PAYMENT_LIST]: (state, { data }) =>
    state.setIn(['deferredPaymentStatus', 'list'], fromJS(data || {})),

  [Cash.GET_REPAYMENT]: (state, { data }) =>
    state.setIn(['deferredPaymentStatus', 'repayment'], fromJS(data || {})),

  [Cash.GET_DEFERRED_PAYMENT_DETAIL_LIST]: (state, { data }) =>
    state.setIn(['deferredPaymentStatus', 'detailList'], fromJS(data || [])),

  [Cash.CHANGE_DEFERRED_PAYMENT_SEARCH_OPTIONS]: (state, { key, value }) =>
    state.setIn(['deferredPaymentStatus', 'searchOptions', key], fromJS(value)),

  [Cash.GET_VIRTUAL_ACCOUNT_HISTORY]: (state, { data }) =>
    state.setIn(['virtualAccountHistory'], fromJS(data || {})),

  [Cash.UPDATE_CANCEL_DEPOSIT_FEE_INFO]: (state, { key, value }) =>
    state.setIn(
      ['cancelDepositFeeHistory', ...coerceToArray(key)],
      fromJS(value)
    ),

  [Cash.CHANGE_DEFERRED_PAYMENT_LIMIT_CHANGE_LOG]: (state, { key, value }) =>
    state.setIn(
      ['deferredPaymentLimitChangeLog', ...coerceToArray(key)],
      fromJS(value)
    ),

  [Cash.INIT_ALL_CASH_STATE]: state => state.merge(initialState),

  // 공통으로 어떻게 안될까
  [Cash.CHANGE_CALENDAR]: (
    state,
    { dataKey, startDate, endDate, periodType, updateCustomDateDiff = false }
  ) => {
    return state.withMutations(s => {
      s.setIn(['calendar', dataKey, 'startDate'], startDate)
        .setIn(['calendar', dataKey, 'endDate'], endDate)
        .setIn(['calendar', dataKey, 'periodType'], periodType)
        .updateIn(['calendar', dataKey, 'customDateDiff'], v =>
          updateCustomDateDiff && periodType === CalendarEnum.Preset.Type.CUSTOM
            ? Math.abs(moment(startDate).diff(moment(endDate), 'd'))
            : v
        )
    })
  },
})

const changeLoadingStatus = (key, value) => ({
  type: Cash.CHANGE_LOADING_STATUS,
  key,
  value,
})

const getChargeHistoryList = data => ({
  type: Cash.SET_CHARGE_HISTORY,
  data,
})

export const changeChargeHistorySearchOptions = (key, value) => ({
  type: Cash.CHANGE_CHARGE_HISTORY_SEARCH_OPTIONS,
  key,
  value,
})

const getFreeCashHistoryList = data => ({
  type: Cash.SET_FREE_CASH_HISTORY,
  data,
})

export const changeFreeCashHistorySearchOptions = (key, value) => ({
  type: Cash.CHANGE_FREE_CASH_SEARCH_OPTIONS,
  key,
  value,
})

const getWithDrawHistoryList = data => ({
  type: Cash.SET_WITH_DRAW_HISTORY,
  data,
})

export const changeWithDrawHistorySearchOptions = (key, value) => ({
  type: Cash.CHANGE_WITH_DRAW_HISTORY_SEARCH_OPTIONS,
  key,
  value,
})

const receiveRefundAccountInfo = info => ({
  type: Cash.GET_WITHDRAW_ACCOUNT,
  info,
})

export const deleteRefundAccountInfo = () => ({
  type: Cash.DELETE_WITHDRAW_ACCOUNT,
})

const receiveCashInfo = info => ({
  type: Cash.RECEIVE_CASH_INFO,
  info,
})

const getDeferredPaymentList = data => ({
  type: Cash.GET_DEFERRED_PAYMENT_LIST,
  data,
})

const getRepayment = data => ({
  type: Cash.GET_REPAYMENT,
  data,
})

const getDeferredPaymentDetailList = data => ({
  type: Cash.GET_DEFERRED_PAYMENT_DETAIL_LIST,
  data,
})

export const changeDeferredPaymentLimitChangeLog = (key, value) => ({
  type: Cash.CHANGE_DEFERRED_PAYMENT_LIMIT_CHANGE_LOG,
  key,
  value,
})

export const changeDeferredPaymentSearchOptions = (key, value) => ({
  type: Cash.CHANGE_DEFERRED_PAYMENT_SEARCH_OPTIONS,
  key,
  value,
})

export const getVirtualAccountHistory = data => ({
  type: Cash.GET_VIRTUAL_ACCOUNT_HISTORY,
  data,
})

export const initAllCashState = () => ({
  type: Cash.INIT_ALL_CASH_STATE,
})

export const changeCancelDepositFeeHistoryByValue = (key, value) => ({
  type: Cash.UPDATE_CANCEL_DEPOSIT_FEE_INFO,
  key,
  value,
})

export function fetchCancelDepositFeeHistory(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading(LOADING_KEY.CASH_CANCEL_DEPOSIT_FEE))

      const {
        cash: {
          cancelDepositFeeHistory: {
            searchOptions: { page, size },
          },
          calendar: {
            cancelDepositFeeHistory: { startDate, endDate },
          },
        },
      } = getState()

      const searchOptions = {
        page,
        size,
        from: dateToString(startDate),
        to: dateToString(endDate),
      }

      const { data } = await api.cash.fetchCancelDepositFeeHistory(
        adAccountId,
        searchOptions
      )

      dispatch(changeCancelDepositFeeHistoryByValue('list', data || {}))
    } catch (e) {
      console.log(e)
    } finally {
      dispatch(hideLoading(LOADING_KEY.CASH_CANCEL_DEPOSIT_FEE))
    }
  }
}

export function fetchChargeHistory(adAccountId, currentPage = 0, onFinish) {
  return (dispatch, getState, api) => {
    dispatch(
      changeLoadingStatus(
        PAGE_STATE_KEY.chargeHistory,
        CASH_LOADING_STATE.LOADING
      )
    )
    const {
      cash: {
        chargeHistory: {
          searchOptions: { dspId, size, type },
        },
        calendar: {
          chargeHistory: { startDate, endDate },
        },
      },
    } = getState()

    const searchOptions = {
      dspId,
      page: currentPage,
      size,
      type,
      from: dateToString(startDate),
      to: dateToString(endDate),
    }

    return api.cash
      .fetchChargeHistory(adAccountId, searchOptions)
      .then(response => {
        dispatch(getChargeHistoryList(response.data))
        onFinish && onFinish(response, null)
      })
      .catch(e => handleErrorCatch(e))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            PAGE_STATE_KEY.chargeHistory,
            CASH_LOADING_STATE.IDLE
          )
        )
      })
  }
}

export function fetchFreeCashHistory(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      changeLoadingStatus(
        PAGE_STATE_KEY.freeCashHistory,
        CASH_LOADING_STATE.LOADING
      )
    )
    const {
      cash: {
        freeCashHistory: {
          searchOptions: { dspId, page, size, status },
        },
        calendar: {
          freeCashHistory: { startDate, endDate },
        },
      },
    } = getState()

    const searchOptions = {
      dspId,
      page,
      size,
      status,
      from: dateToString(startDate),
      to: dateToString(endDate),
    }

    return api.cash
      .fetchFreeCashHistory(adAccountId, searchOptions)
      .then(response => {
        dispatch(getFreeCashHistoryList(response.data))
      })
      .catch(e => handleErrorCatch(e))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            PAGE_STATE_KEY.freeCashHistory,
            CASH_LOADING_STATE.IDLE
          )
        )
      })
  }
}

export function downloadCashChargeHistory(adAccountId, fileName) {
  return (dispatch, getState, api) => {
    const {
      cash: {
        chargeHistory: {
          searchOptions: { dspId, page, size, status },
        },
        calendar: {
          chargeHistory: { startDate, endDate },
        },
      },
      user: {
        userInfo: { kakaoEmail },
      },
    } = getState()
    const searchOptions = {
      dspId,
      page,
      size,
      status,
      from: dateToString(startDate),
      to: dateToString(endDate),
    }
    return api.cash
      .downloadCashChargeHistory(adAccountId, searchOptions)
      .then(response => {
        handleDownloadChargeHistory(response.data, kakaoEmail, fileName)
      })
  }
}

function handleDownloadChargeHistory(data, kakaoEmail, fileName) {
  if (data) {
    const prefix = '\uFEFF'
    const t1 = [
      '거래일시',
      '구분',
      '거래유형',
      '거래수단',
      '금액',
      '거래자',
    ].join('\t')
    const header = prefix.concat(t1, '\n')
    const result = data.reduce((prev, v) => {
      const { date, type, method, methodDetail, amount, trader } =
        getChargeHistoryInfoLabel(v, kakaoEmail)

      return prev.concat(
        [date, type, method, methodDetail, amount, trader]
          .join('\t')
          .concat('\n')
      )
    }, header)
    fileDownloadBlob(result, fileName)
  }
}

export function requestUseFreeCash(adAccountId, cashId) {
  return (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id: memberId },
      },
    } = getState()
    const queryString = {
      dspId: 'KAKAOMOMENT',
      memberType: BILLING_MEMBER_TYPE.DSP_ACCOUNT,
      memberId,
    }
    return api.cash
      .requestUseFreeCash(adAccountId, cashId, queryString)
      .then(() => {
        dispatch(fetchChargeHistory(adAccountId))
        dispatch(fetchFreeCashHistory(adAccountId))
        dispatch(fetchAdAccountCashInfo(adAccountId))
      })
      .catch(e => handleErrorCatch(e))
  }
}

export function fetchWithdrawHistory(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      changeLoadingStatus(
        PAGE_STATE_KEY.withdrawHistory,
        CASH_LOADING_STATE.LOADING
      )
    )
    const {
      cash: {
        withdrawHistory: {
          searchOptions: { dspId, page, size, statuses },
        },
        calendar: {
          withdrawHistory: { startDate, endDate },
        },
      },
    } = getState()

    const searchOptions = {
      dspId,
      page,
      size,
      statuses,
      from: dateToString(startDate),
      to: dateToString(endDate),
    }

    return api.cash
      .fetchWithdrawHistory(adAccountId, searchOptions)
      .then(response => {
        dispatch(getWithDrawHistoryList(response.data))
      })
      .catch(e => handleErrorCatch(e))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            PAGE_STATE_KEY.withdrawHistory,
            CASH_LOADING_STATE.IDLE
          )
        )
      })
  }
}

export function requestRefund(
  adAccountId,
  refundAmount,
  refundType,
  onSuccess,
  onFail,
  onError
) {
  return async (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id },
      },
      adAccount: {
        adAccountInfo: { userConfig },
      },
      cash: {
        cashInfo: { cash },
      },
    } = getState()

    const isFullRefund = refundType === WITHDRAW_TYPE.FULL
    if (cash <= 0) {
      onError(WITHDRAW_ERROR_TYPE.IMPOSSIBLE_REFUND)
    } else if (isFullRefund && userConfig === 'ON') {
      onError(WITHDRAW_ERROR_TYPE.NEED_AD_OFF)
    } else if (refundAmount > cash) {
      onError(WITHDRAW_ERROR_TYPE.NEED_FIX_AMOUNT)
    } else if (!isFullRefund && refundAmount <= 0) {
      onError(WITHDRAW_ERROR_TYPE.NO_INPUT_AMOUNT)
    } else if (!isFullRefund && refundAmount === cash) {
      onError(WITHDRAW_ERROR_TYPE.NEED_ALL_WITHDRAW)
    } else {
      onError() // validation 통과했을 경우 화면 state 인 errorType 초기화해주기 위해
      dispatch(showLoading())

      const body = {
        memberId: id,
        memberType: BILLING_MEMBER_TYPE.DSP_ACCOUNT,
        dspId: 'KAKAOMOMENT',
        allWithdraw: isFullRefund,
      }

      if (!isFullRefund) {
        assign(body, { amount: String(refundAmount).trim() })
      }

      try {
        const response = await api.cash.requestRefund(adAccountId, body)

        dispatch(fetchAdAccountCashInfo(adAccountId))
        dispatch(fetchWithdrawHistory(adAccountId))
        onSuccess(response)
      } catch (e) {
        const errorText = handleErrorCatch(e)
        onFail(errorText)
      } finally {
        dispatch(hideLoading())
      }
    }
  }
}

export function fetchAdAccountCashInfo(adAccountId) {
  return (dispatch, getState, api) => {
    return api.cash
      .fetchAdAccountCashInfo(adAccountId)
      .then(response => {
        dispatch(receiveCashInfo(response.data))
      })
      .catch(e => handleErrorCatch(e))
  }
}

export function requestRefundAccountInfo(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(
      changeLoadingStatus(
        PAGE_STATE_KEY.withdrawAccount,
        CASH_LOADING_STATE.LOADING
      )
    )
    return api.cash
      .requestRefundAccountInfo(adAccountId)
      .then(response => {
        dispatch(receiveRefundAccountInfo(response.data))
      })
      .catch(e => handleErrorCatch(e, dispatch))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            PAGE_STATE_KEY.withdrawAccount,
            CASH_LOADING_STATE.IDLE
          )
        )
      })
  }
}

export function requestSetRefundAccountInfo(
  adAccountId,
  refundAccountForm,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    const { ownerName, accountNumber, bankCode } = refundAccountForm
    if (checkEmpty(ownerName) && checkEmpty(accountNumber)) {
      onFail('입력된 내용이 없습니다.')
    } else if (checkEmpty(ownerName) || checkEmpty(accountNumber)) {
      onFail('모든 항목을 입력하세요.')
    } else {
      dispatch(showLoading())
      const {
        cash: {
          withdrawAccount: { accountInfo },
        },
      } = getState()
      const isModify = accountInfo.size > 0

      try {
        const response = await api.cash.requestSetRefundAccountInfo(
          adAccountId,
          isModify,
          {
            accountNumber,
            ownerName,
            bankCode,
            dspId: 'KAKAOMOMENT',
          }
        )
        dispatch(requestRefundAccountInfo(adAccountId))
        if (checkNotEmpty(onSuccess)) {
          onSuccess()
        } else {
          dispatch(closeAllPopup())
        }
      } catch (e) {
        const description = handleErrorCatch(e)
        if (description) {
          onFail(description)
        } else {
          onFail(
            '일시적인 오류로 환불계좌 설정에 실패하였습니다. 잠시후 다시 시도하세요.'
          )
        }
      } finally {
        dispatch(hideLoading())
      }
    }
  }
}

export function requestRemoveRefundAccount(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(showLoading())

    return api.cash
      .requestRemoveRefundAccount(adAccountId)
      .then(() => {
        dispatch(deleteRefundAccountInfo())
        dispatch(closeAllPopup())
      })
      .catch(e => {
        const description = handleErrorCatch(e)
        showErrorMessage(description)
      })
      .finally(() => dispatch(hideLoading()))
  }
}

export function requestChargeCash(adAccountId, amount, validationCallback) {
  return async (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id },
      },
      adAccountListSelector: {
        selectedAdAccount: { adAccountType },
      },
    } = getState()

    const redirectUrl = new URL(window.location.href)
    redirectUrl.pathname = redirectCashPath()

    const requestBody = Map({
      memberId: id,
      memberType: BILLING_MEMBER_TYPE.DSP_ACCOUNT,
      popUpRedirectUrl: true, // true 면 자식창에서 리다이렉트, false 면 부모창에서 리다이렉트
      isPopUpRedirectUrl: true, // fallback
      amount,
      redirectUrl: redirectUrl.href,
      dspId: 'KAKAOMOMENT',
      businessType: adAccountType,
    })

    const validationResult = Validation(
      requestBody,
      CASH_CHARGE_FORM_VALIDATION_KEY,
      CASH_CHARGE_FORM_VALIDATION_KEY_PATH,
      CASH_CHARGE_FORM_VALIDATOR,
      getState,
      validationCallback
    )

    if (!validationResult) return

    try {
      const response = await api.cash.requestChargeCash(
        adAccountId,
        requestBody
      )

      const { paymentUrl, kakaoBillingTxId } = response.data
      window.open(
        paymentUrl,
        '_blank',
        'width=520,height=701,top=0,left=0,menubar=no,status=no,toolbar=no'
      )
      window.asyncCallback = () => {
        dispatch(fetchChargeHistory(adAccountId))
        dispatch(requestPaymentInfo(kakaoBillingTxId, adAccountId))
        dispatch(closeAllPopup())
      }
    } catch (e) {
      handleErrorCatch(e)
    }
  }
}

function requestPaymentInfo(kakaoBillingTxId, adAccountId) {
  return (dispatch, getState, api) => {
    const {
      adAccountListSelector: {
        selectedAdAccount: { name },
      },
    } = getState()

    return api.cash
      .requestPaymentInfo(adAccountId, kakaoBillingTxId)
      .then(response => {
        const { amount, chargeStatus } = response.data
        const isSuccess = chargeStatus === CHARGE_STATUS_KEY.SUCCESS

        if (isSuccess) {
          const label = `${name}계정에 ${convertToCommaSeparatedString(
            amount
          )}원 충전되었습니다.`
          showSuccessMessage(label)
          dispatch(fetchAdAccountCashInfo(adAccountId))
        }
        // TODO then 탔을때 chargeStatus가 실패로 들어올 경우는??
      })
      .catch(e => handleErrorCatch(e))
  }
}

export function fetchDeferredPaymentList(adAccountId) {
  return (dispatch, getState, api) => {
    const {
      cash: {
        deferredPaymentStatus: { searchOptions },
      },
    } = getState()

    dispatch(
      changeLoadingStatus(
        'deferredPaymentStatus',
        CashEnum.Loading.Type.LOADING
      )
    )

    return api.cash
      .fetchDeferredPaymentList(adAccountId, searchOptions.toJS())
      .then(response => {
        dispatch(getDeferredPaymentList(response.data))
      })
      .catch(e => console.log(e.message))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            'deferredPaymentStatus',
            CashEnum.Loading.Type.IDLE
          )
        )
      })
  }
}

export function fetchDeferredPaymentLimitChangeLog(adAccountId) {
  return (dispatch, getState, api) => {
    const {
      cash: {
        deferredPaymentLimitChangeLog: {
          searchOptions: { page, size },
        },
        calendar: {
          deferredPaymentLimitHistory: { startDate, endDate },
        },
      },
    } = getState()

    const searchOptions = {
      page,
      size,
      startDate: dateToDefaultString(startDate),
      endDate: dateToDefaultString(endDate),
    }

    dispatch(
      changeLoadingStatus(
        'deferredPaymentLimitChangeLog',
        CashEnum.Loading.Type.LOADING
      )
    )

    return api.cash
      .fetchDeferredPaymentLimitChangeLog(adAccountId, searchOptions)
      .then(response => {
        dispatch(
          changeDeferredPaymentLimitChangeLog('list', response.data || {})
        )
      })
      .catch(e => console.log(e.message))
      .finally(() => {
        dispatch(
          changeLoadingStatus(
            'deferredPaymentLimitChangeLog',
            CashEnum.Loading.Type.IDLE
          )
        )
      })
  }
}

export function fetchRepaymentDetail(adAccountId, params, onFinish) {
  return (dispatch, getState, api) => {
    return api.cash
      .fetchRepaymentDetail(adAccountId, params)
      .then(response => {
        dispatch(getDeferredPaymentDetailList(response.data))
      })
      .catch(e => console.log(e.message))
      .finally(() => onFinish && onFinish())
  }
}

export function fetchBondRepayment(adAccountId, onSuccess, onFinish) {
  return (dispatch, getState, api) => {
    return api.cash
      .fetchEnableRepayment(adAccountId)
      .then(response => {
        dispatch(getRepayment(response.data))

        onSuccess && onSuccess()
      })
      .catch(e => console.log(e.message))
      .finally(() => {
        onFinish && onFinish()
      })
  }
}

export function requestRepayment(
  adAccountId,
  paymentAmount,
  validationCallback
) {
  return async (dispatch, getState, api) => {
    const {
      user: {
        userInfo: { id: memberId },
      },
      adAccountListSelector: {
        selectedAdAccount: { adAccountId, adAccountType },
      },
    } = getState()

    const redirectUrl = new URL(window.location.href)
    redirectUrl.pathname = redirectCashPath()

    const requestBody = Map({
      adPlatformType: 'KAKAOMOMENT',
      amount: paymentAmount,
      businessType: adAccountType,
      dspId: 'KAKAOMOMENT',
      memberId,
      memberType: BILLING_MEMBER_TYPE.DSP_ACCOUNT,
      popUpRedirectUrl: true, // true 면 자식창에서 리다이렉트, false 면 부모창에서 리다이렉트
      isPopUpRedirectUrl: true, // fallback
      redirectUrl: redirectUrl.href,
      plans: [
        {
          walletId: adAccountId,
          repayAmount: paymentAmount,
        },
      ],
    })

    const validationResult = Validation(
      requestBody,
      CASH_DEFERRED_PAYMENT_VALIDATION_KEY,
      CASH_DEFERRED_PAYMENT_VALIDATION_KEY_PATH,
      CASH_DEFERRED_PAYMENT_VALIDATOR,
      getState,
      validationCallback
    )

    if (!validationResult) return

    try {
      const response = await api.cash.requestRepayment(adAccountId, requestBody)

      const { paymentUrl } = response.data
      if (paymentUrl) {
        window.open(
          paymentUrl,
          '_blank',
          `width=520,menubar=no,status=no,toolbar=no`
        )
        window.asyncCallback = () => {
          dispatch(fetchChargeHistory(adAccountId))
          dispatch(fetchDeferredPaymentList(adAccountId))
          dispatch(fetchAdAccountCashInfo(adAccountId))
          dispatch(closeAllPopup())
        }
      }
    } catch (e) {
      const description = handleErrorCatch(e)
      showErrorMessage(description)
    }
  }
}

export function fetchVirtualAccountHistory(adAccountId, onFinish) {
  return (dispatch, getState, api) => {
    return api.cash
      .fetchVirtualAccountHistory(adAccountId)
      .then(response => {
        const data = response?.data || {}
        dispatch(getVirtualAccountHistory(data))
        return response
      })
      .catch(e => {
        const description = handleErrorCatch(e)
        showErrorMessage(description)
      })
      .finally(() => onFinish && onFinish())
  }
}

export function deleteVirtualAccount(adAccountId, txId, onSuccess, onFinish) {
  return (dispatch, getState, api) => {
    return api.cash
      .deleteVirtualAccount(adAccountId, txId)
      .then(response => {
        const data = response?.data || {}
        dispatch(getVirtualAccountHistory(data))
        onSuccess && onSuccess()
      })
      .catch(e => {
        const description = handleErrorCatch(e)
        showErrorMessage(description)
      })
      .finally(() => onFinish && onFinish())
  }
}

/***
 * 공통 : 충전 내역, 무상캐시 사용현황, 환불 처리현황
 * @param dataKey {string}
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendar(
  dataKey,
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId, createdDate },
      },
    } = getState()

    dispatch({
      type: Cash.CHANGE_CALENDAR,
      dataKey,
      startDate,
      endDate,
      periodType,
      createdDate,
      updateCustomDateDiff,
    })
  }
}

/***
 * 충전 내역 달력 변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendarChargeHistory(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
    } = getState()

    dispatch(changeChargeHistorySearchOptions('page', 0))
    dispatch(
      changeCalendar(
        'chargeHistory',
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchChargeHistory(adAccountId))
  }
}

/***
 * 무상캐시 사용현황 달력 변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendarFreeCashHistory(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
    } = getState()

    dispatch(changeFreeCashHistorySearchOptions('page', 0))
    dispatch(
      changeCalendar(
        'freeCashHistory',
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchFreeCashHistory(adAccountId))
  }
}

/***
 * 환불 처리현황 달력 변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendarWithdrawHistory(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
    } = getState()

    dispatch(changeWithDrawHistorySearchOptions('page', 0))
    dispatch(
      changeCalendar(
        'withdrawHistory',
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchWithdrawHistory(adAccountId))
  }
}

/***
 * 계약 해지수수료 부과내역 달력 변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendarCancelDepositHistory(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
    } = getState()

    dispatch(changeCancelDepositFeeHistoryByValue('page', 0))
    dispatch(
      changeCalendar(
        'cancelDepositFeeHistory',
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchCancelDepositFeeHistory(adAccountId))
  }
}

/***
 * 후불한도 변경이력 달력 변경
 * @param startDate {Object} - moment object
 * @param endDate {Object} - moment object
 * @param periodType
 * @param updateCustomDateDiff
 * @return
 */
export function changeCalendarDeferredPaymentLimitHistory(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
    } = getState()

    dispatch(changeDeferredPaymentLimitChangeLog('page', 0))
    dispatch(
      changeCalendar(
        'deferredPaymentLimitHistory',
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(fetchDeferredPaymentLimitChangeLog(adAccountId))
  }
}

export function goPrevCalendarChargeHistory() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      cash: {
        calendar: {
          chargeHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()
    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarChargeHistory(start, end, periodType))
  }
}

export function goNextCalendarChargeHistory() {
  return (dispatch, getState) => {
    const {
      cash: {
        calendar: {
          chargeHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarChargeHistory(start, end, periodType))
  }
}

export function goPrevCalendarFreeCashHistory() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      cash: {
        calendar: {
          freeCashHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()
    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarFreeCashHistory(start, end, periodType))
  }
}

export function goNextCalendarFreeCashHistory() {
  return (dispatch, getState) => {
    const {
      cash: {
        calendar: {
          freeCashHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarFreeCashHistory(start, end, periodType))
  }
}

export function goPrevCalendarWithdrawHistory() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      cash: {
        calendar: {
          withdrawHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(changeCalendarWithdrawHistory(start, end, periodType))
  }
}

export function goNextCalendarWithdrawHistory() {
  return (dispatch, getState) => {
    const {
      cash: {
        calendar: {
          withdrawHistory: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarWithdrawHistory(start, end, periodType))
  }
}

export function goPrevCalendarCancelDepositHistory() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      cash: {
        calendar: {
          cancelDepositFeeHistory: {
            startDate,
            endDate,
            periodType,
            customDateDiff,
          },
        },
      },
    } = getState()
    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarCancelDepositHistory(start, end, periodType))
  }
}

export function goNextCalendarCancelDepositHistory() {
  return (dispatch, getState) => {
    const {
      cash: {
        calendar: {
          cancelDepositFeeHistory: {
            startDate,
            endDate,
            periodType,
            customDateDiff,
          },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarCancelDepositHistory(start, end, periodType))
  }
}

export function goPrevCalendarDeferredPaymentLimitHistory() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      cash: {
        calendar: {
          deferredPaymentLimitHistory: {
            startDate,
            endDate,
            periodType,
            customDateDiff,
          },
        },
      },
    } = getState()
    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarDeferredPaymentLimitHistory(start, end, periodType))
  }
}

export function goNextCalendarDeferredPaymentLimitHistory() {
  return (dispatch, getState) => {
    const {
      cash: {
        calendar: {
          deferredPaymentLimitHistory: {
            startDate,
            endDate,
            periodType,
            customDateDiff,
          },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )
    dispatch(changeCalendarDeferredPaymentLimitHistory(start, end, periodType))
  }
}
