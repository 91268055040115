import { keyMirror } from '../utils/utils'

/**
 * 광고 단위의 상태
 * adAccount.status
 * campaign.status
 * adGroup.status
 * creative.status
 */
export default {
  Type: keyMirror({
    OPERATING: null,
    OFF: null,
    PENDING_DELETE: null,
    PROCESSING_DELETE: null,
    DELETED: null,
    STOPPED: null,
    ADMIN_STOPPED: null,
    SYSTEM_CONFIG_ADMIN_STOP: null,
    OUT_OF_BALANCE: null,
    READY: null,
    LIVE: null,
    FINISHED: null,
    EXCEED_DAILY_BUDGET: null,
    ADACCOUNT_UNAVAILABLE: null,
    PAUSED: null,
    NO_AVAILABLE_CREATIVE: null,
    CANCELED: null,
    CAMPAIGN_UNAVAILABLE: null,
    UNAPPROVED: null,
    INVALID_DATE: null,
    MONITORING_REJECTED: null,
    ADGROUP_UNAVAILABLE: null,
    SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP: null,
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  Name: {
    OPERATING: '운영 가능',
    OFF: '사용자 OFF',
    PENDING_DELETE: '삭제 신청중',
    PROCESSING_DELETE: '삭제 처리중',
    DELETED: '삭제',
    STOPPED: '운영 불가 (관리자 정지)', // 광고계정
    ADMIN_STOPPED: '운영 불가 (관리자 정지)', // 캠페인, 광고그룹
    SYSTEM_CONFIG_ADMIN_STOP: '운영 불가 (관리자 정지)', // 소재
    OUT_OF_BALANCE: '운영 불가 (잔액 부족)',
    READY: '집행 예정',
    LIVE: '집행 중',
    FINISHED: '집행 완료',
    EXCEED_DAILY_BUDGET: '운영 불가 (예산 초과)',
    ADACCOUNT_UNAVAILABLE: '운영 불가',
    PAUSED: '운영 불가 (일시 중지)',
    NO_AVAILABLE_CREATIVE: '운영 불가 (집행가능 소재없음)',
    CANCELED: '운영 불가 (계약 해지)',
    CAMPAIGN_UNAVAILABLE: '운영 불가',
    UNAPPROVED: '운영 불가 (심사 미승인)',
    INVALID_DATE: '운영 불가 (기간 오류)',
    MONITORING_REJECTED: '운영 불가 (관리자 정지)',
    ADGROUP_UNAVAILABLE: '운영 불가',
    SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP: '운영 불가 (연결 서비스 제한)',
  },

  getName(type) {
    return this.Name[type]
  },

  isActive(type) {
    return [this.Type.OPERATING, this.Type.LIVE].includes(type)
  },
}
