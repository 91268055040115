import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkEmpty } from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import { adViewButtonValidation } from '../../utils/adView/adViewV2'
import { validateAdViewName } from './adViewFormValidator'

export const FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2 = keyMirror({
  NAME: null,
  CONTENTS: null,
  BUTTON1: null,
  BUTTON2: null,
})

export const FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_PATH_V2 = Map({
  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.NAME]: ['name'],
  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.CONTENTS]: ['contents'],
  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.BUTTON1]: ['button1'],
  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.BUTTON2]: ['button2'],
})

export const FULL_VIEW_VIDEO_FORM_VALIDATOR_V2 = Map({
  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.NAME]: name => {
    return validateAdViewName(name)
  },

  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.CONTENTS]: contents => {
    if (checkEmpty(contents) || contents.isEmpty()) {
      return IS_NOT_VALID('홍보동영상을 업로드하세요.')
    }

    const {
      data: { isVideoProcessing, isKakaoTvProcessing },
    } = contents.first()

    if (isVideoProcessing || isKakaoTvProcessing) {
      return IS_NOT_VALID(
        '동영상을 처리하고 있습니다. 완료 후, 설정완료하세요.'
      )
    }

    return IS_VALID()
  },

  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.BUTTON1]: button1 => {
    return adViewButtonValidation('button1', button1)
  },

  [FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2.BUTTON2]: button2 => {
    return adViewButtonValidation('button2', button2)
  },
})
