import React from 'react'
import { keyMirror } from '../../../utils/utils'
import { Link, useLocation } from 'react-router-dom'
import cx from 'classnames'

const MENU = keyMirror({
  ACCOUNT_INFO: null,
  DSP_AUTO_PAYMENT_CARD: null,
  NOTIFICATION: null,
})

const MENU_ITEMS = [
  {
    id: MENU.ACCOUNT_INFO,
    label: '계정정보 설정',
    pathName: '/myinfo/accountinfo',
    isBeta: false,
  },
  {
    id: MENU.DSP_AUTO_PAYMENT_CARD,
    label: '자동 결제카드 설정',
    pathName: '/myinfo/autopaycard',
    isBeta: false,
  },
  {
    id: MENU.NOTIFICATION,
    label: '알림 설정',
    pathName: '/myinfo/notification',
    isBeta: false,
  },
]

const MyInfoTabMenu = () => {
  const location = useLocation()

  return (
    <ul className="tab_g5">
      {MENU_ITEMS.map(({ id, pathName, label, isBeta }) => {
        return (
          <li key={id} className={cx({ on: location.pathname === pathName })}>
            <Link to={pathName} className="link_tab">
              {label}
              {isBeta && <span className="ico_comm ico_beta">Beta</span>}
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default MyInfoTabMenu
