import ReportMetricGroupEnum from '../../../enums/ReportMetricGroupEnum'
import { ReportMetric } from './reportMetric'
import ReportFilterEnum from '../../../enums/ReportFilterEnum'
import DashboardEnumV2 from '../../../enums/DashboardEnumV2'
import UserConfigEnum from '../../../enums/UserConfigEnum'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../enums/GoalEnum'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import { isNoneUndefinedOrNull } from '../../../utils/regexUtils'
import CreativeCategoryEnum from '../../../enums/CreativeCategoryEnum'
import { NumberUtils } from '../../../utils/numberUtils'
import PropTypes from 'prop-types'
import ObjectiveDetailTypeEnum from '../../../enums/ObjectiveDetailTypeEnum'
import { getDimensionName } from './reportDimension'
import {
  LOCATION_KEY,
  LOCATION_KEY_TEXT,
} from '../../../utils/advertise/targeting'
import PlacementEnum from '../../../enums/PlacementEnum'
import { ReportTargeting } from './reportTargeting'
import ReportAdUnitTypeEnum from '../../../enums/ReportAdUnitTypeEnum'

const filterOptionSortBase = ReportFilterEnum.Filter.values()

const AD_UNIT_KEY_MAP = {
  CAMPAIGN: 'campaign',
  AD_GROUP: 'adGroup',
  CREATIVE: 'creative',
}

const AD_UNIT_TYPE_MAP = {
  campaign: DashboardEnumV2.Type.CAMPAIGN,
  adGroup: DashboardEnumV2.Type.AD_GROUP,
  creative: DashboardEnumV2.Type.CREATIVE,
}

/**
 * AGE_BAND_GENDER, DEVICE_PLACEMENT 유형은 각각 분리되어 사용됨
 */
const FILTER_STATE_KEY_MAP = {
  [ReportFilterEnum.Filter.Type.ID]: 'ids', // ids: []
  [ReportFilterEnum.Filter.Type.NAME]: 'names', // names: []
  [ReportFilterEnum.Filter.Type.ON_OFF_DELETE]: 'userConfigs', // userConfigs: []
  [ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE]: 'campaignTypes', // campaignTypes: []
  [ReportFilterEnum.Filter.Type.GOAL]: 'goals', // goals: []
  [ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE]: 'objectiveDetails', // objectiveDetails: []
  [ReportFilterEnum.Filter.Type.CREATIVE_FORMAT]: 'creativeFormats', // creativeFormats: []
  [ReportFilterEnum.Filter.Type.AGE_BAND]: 'ageBands', // ageBands: []
  [ReportFilterEnum.Filter.Type.GENDER]: 'genders', // genders: []
  [ReportFilterEnum.Filter.Type.LOCATION]: 'locations', // locations: []
  [ReportFilterEnum.Filter.Type.DEVICE]: 'devices', // devices: []
  [ReportFilterEnum.Filter.Type.PLACEMENT]: 'placements', // placements: []
  [ReportFilterEnum.Filter.Type.TIME_ZONE]: 'timeZones', // timeZones: []
}

const FILTER_TYPE_MAP = {
  ids: ReportFilterEnum.Filter.Type.ID,
  names: ReportFilterEnum.Filter.Type.NAME,
  userConfigs: ReportFilterEnum.Filter.Type.ON_OFF_DELETE,
  campaignTypes: ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE,
  goals: ReportFilterEnum.Filter.Type.GOAL,
  objectiveDetails: ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE,
  creativeFormats: ReportFilterEnum.Filter.Type.CREATIVE_FORMAT,
  ageBands: ReportFilterEnum.Filter.Type.AGE_BAND,
  genders: ReportFilterEnum.Filter.Type.GENDER,
  locations: ReportFilterEnum.Filter.Type.LOCATION,
  devices: ReportFilterEnum.Filter.Type.DEVICE,
  placements: ReportFilterEnum.Filter.Type.PLACEMENT,
  timeZones: ReportFilterEnum.Filter.Type.TIME_ZONE,
}

const TARGETING_FILTER_ITEMS_MAP = {
  [ReportFilterEnum.Filter.Type.CREATIVE_FORMAT]: [
    ReportFilterEnum.Filter.Type.CREATIVE_FORMAT,
  ],
  [ReportFilterEnum.Filter.Type.AGE_BAND]: [
    ReportFilterEnum.Filter.Type.AGE_BAND,
  ],
  [ReportFilterEnum.Filter.Type.GENDER]: [ReportFilterEnum.Filter.Type.GENDER],
  [ReportFilterEnum.Filter.Type.AGE_BAND_GENDER]: [
    ReportFilterEnum.Filter.Type.AGE_BAND,
    ReportFilterEnum.Filter.Type.GENDER,
  ],
  [ReportFilterEnum.Filter.Type.LOCATION]: [
    ReportFilterEnum.Filter.Type.LOCATION,
  ],
  [ReportFilterEnum.Filter.Type.DEVICE]: [ReportFilterEnum.Filter.Type.DEVICE],
  [ReportFilterEnum.Filter.Type.PLACEMENT]: [
    ReportFilterEnum.Filter.Type.PLACEMENT,
  ],
  [ReportFilterEnum.Filter.Type.DEVICE_PLACEMENT]: [
    ReportFilterEnum.Filter.Type.DEVICE,
    ReportFilterEnum.Filter.Type.PLACEMENT,
  ],
  [ReportFilterEnum.Filter.Type.TIME_ZONE]: [
    ReportFilterEnum.Filter.Type.TIME_ZONE,
  ],
}

const FILTER_CHILDREN_MAP = {
  [ReportFilterEnum.Filter.Type.NAME]: [
    [DashboardEnumV2.Type.CAMPAIGN, ReportFilterEnum.Filter.Type.NAME],
    [DashboardEnumV2.Type.AD_GROUP, ReportFilterEnum.Filter.Type.NAME],
    [DashboardEnumV2.Type.CREATIVE, ReportFilterEnum.Filter.Type.NAME],
    [DashboardEnumV2.Type.CAMPAIGN, ReportFilterEnum.Filter.Type.ID],
    [DashboardEnumV2.Type.AD_GROUP, ReportFilterEnum.Filter.Type.ID],
    [DashboardEnumV2.Type.CREATIVE, ReportFilterEnum.Filter.Type.ID],
  ],
  [ReportFilterEnum.Filter.Type.ON_OFF_DELETE]: [
    [DashboardEnumV2.Type.CAMPAIGN, ReportFilterEnum.Filter.Type.ON_OFF_DELETE],
    [DashboardEnumV2.Type.AD_GROUP, ReportFilterEnum.Filter.Type.ON_OFF_DELETE],
    [DashboardEnumV2.Type.CREATIVE, ReportFilterEnum.Filter.Type.ON_OFF_DELETE],
  ],
  [ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC]: [
    DashboardEnumV2.Type.CAMPAIGN,
  ],
  [ReportFilterEnum.Filter.Type.AD_GROUP_METRIC]: [
    DashboardEnumV2.Type.AD_GROUP,
  ],
  [ReportFilterEnum.Filter.Type.CREATIVE_METRIC]: [
    DashboardEnumV2.Type.CREATIVE,
  ],
}

/**
 * Initial filter value
 * @param param
 * @param param.adUnitType
 * @param param.filterType
 * @param param.filterSubType
 * @param param.filterOperator
 * @param param.filterValue
 */
const getInitialConditionValue = ({
  adUnitType = '',
  filterType = '',
  filterSubType = '',
  filterOperator = '',
  filterValue = [],
}) => {
  return {
    adUnitType,
    filterType,
    filterSubType,
    filterOperator,
    filterValue,
  }
}

/**
 *  Filter option items
 *    - ID/NAME
 *    - ON/OFF/DELETE
 */
const getIdNameOnOfDeleteFilterOptionItem = ({ filterType }) => {
  return FILTER_CHILDREN_MAP[filterType].map(([adUnitType, filterType]) => ({
    id: [adUnitType, filterType].join('|'),
    label:
      filterType === ReportFilterEnum.Filter.Type.ON_OFF_DELETE
        ? DashboardEnumV2.getName(adUnitType)
        : [
            DashboardEnumV2.getName(adUnitType),
            ReportFilterEnum.Filter.getName(filterType),
          ].join(' '),
    availableFilterOperators: ReportFilterUtils.Common.filterOperatorValues({
      filterType,
    }),
    conditionValue: getInitialConditionValue({
      adUnitType,
      filterType,
    }),
  }))
}

/**
 *  Filter option items
 *    - CAMPAIGN_TYPE
 *    - GOAL
 *    - OBJECTIVE
 *    - { CREATIVE_FORMAT | AGE_BAND | GENDER | AGE_BAND_GENDER | LOCATION | DEVICE | PLACEMENT | DEVICE_PLACEMENT | TIME_ZONE }
 */
const getDefaultFilterOptionItem = ({ filterType }) => {
  if (
    ReportFilterUtils.Common.isTargetingFilter({ filterType }) ||
    [
      ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE,
      ReportFilterEnum.Filter.Type.GOAL,
      ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE,
      ReportFilterEnum.Filter.Type.CREATIVE_FORMAT,
    ].includes(filterType)
  ) {
    const availableFilterOperators =
      ReportFilterUtils.Common.filterOperatorValues({
        filterType,
      })

    const adUnitType = ReportFilterUtils.Common.adUnitTypeByFilterType({
      filterType,
    })

    return {
      availableFilterOperators,
      conditionValue: getInitialConditionValue({
        adUnitType,
        filterType,
      }),
    }
  } else {
    return undefined
  }
}

/**
 * Targeting 유형의 항목 설정시, 기본 필터 항목에 설정된 타게팅 필터 추가
 */
const targetingFilterOptionItems = ({ dimensions = [] }) => {
  const [filters] = dimensions
    .filter(filterType =>
      ReportFilterUtils.Common.isTargetingFilter({ filterType })
    )
    .map(filterType => TARGETING_FILTER_ITEMS_MAP[filterType])

  return (
    filters?.map(filterType => ({
      id: filterType,
      label: getDimensionName(filterType),
      ...getDefaultFilterOptionItem({
        filterType,
      }),
    })) ?? []
  )
}

/**
 *  Filter option Items
 *    - CAMPAIGN_METRIC
 *    - AD_GROUP_METRIC
 *    - CREATIVE_METRIC
 */
const getAdUnitMetricFilterOptionItem = ({ filterType }) => {
  const [adUnitType] = FILTER_CHILDREN_MAP[filterType]

  return ReportMetric.getMetricGroupValues()
    .filter(({ id }) => !ReportMetricGroupEnum.isDeprecated(id))
    .map(({ id: groupId, metrics }) => ({
      id: `${filterType}|${groupId}`,
      label: ReportMetricGroupEnum.getName(groupId),
      children: metrics.map(({ id: metricId, label }) => ({
        id: `${filterType}|${metricId}`,
        label,
        availableFilterOperators: ReportFilterUtils.Common.filterOperatorValues(
          {
            filterType,
          }
        ),
        conditionValue: getInitialConditionValue({
          adUnitType,
          filterType,
          filterSubType: metricId,
        }),
      })),
    }))
}

export const ReportFilterUtils = {
  filterOptionsSelectValues({ dimensions }) {
    return [
      ...ReportFilterEnum.Filter.optionSelectValues().map(val => {
        const filterType = Array.isArray(val) ? val[0] : val // NAME, ID 값은 배열로 묶어서 관리, 그 외 값은 string

        const isIdNameOnOffDeleteType = [
          ReportFilterEnum.Filter.Type.ID,
          ReportFilterEnum.Filter.Type.NAME,
          ReportFilterEnum.Filter.Type.ON_OFF_DELETE,
        ].includes(filterType)

        const isMetricType = ReportFilterUtils.Common.isMetricFilter({
          filterType,
        })

        return {
          id: filterType,
          label: Array.isArray(val)
            ? val.map(v => ReportFilterEnum.Filter.getName(v)).join('/')
            : ReportFilterEnum.Filter.getName(filterType),

          ...(isIdNameOnOffDeleteType && {
            children: getIdNameOnOfDeleteFilterOptionItem({ filterType }),
          }),

          ...(isMetricType && {
            children: getAdUnitMetricFilterOptionItem({ filterType }),
          }),

          ...getDefaultFilterOptionItem({
            filterType,
          }),
        }
      }),

      // Targeting 설정 항목 추가
      ...targetingFilterOptionItems({ dimensions }),
    ]
      .filter(Boolean)
      .sort(
        (a, b) =>
          filterOptionSortBase.indexOf(a.id) -
          filterOptionSortBase.indexOf(b.id)
      )
  },

  suggestOptionSelectValues() {
    return FILTER_CHILDREN_MAP[ReportFilterEnum.Filter.Type.NAME].map(
      ([adUnitType, filterType]) => {
        const availableFilterOperators =
          ReportFilterUtils.Common.filterOperatorValues({
            filterType,
          })
        const [filterOperator] = availableFilterOperators

        return {
          id: [adUnitType, filterType].join('|'),
          label: [
            DashboardEnumV2.getName(adUnitType),
            ReportFilterEnum.Filter.getName(filterType),
            ReportFilterEnum.Operator.getName(filterOperator),
          ].join(' '),
          conditionValue: getInitialConditionValue({
            adUnitType,
            filterType,
            filterOperator,
          }),
          isNumberOnly: ReportFilterUtils.Common.isFilterValueNumeric({
            filterType,
          }),
          filterType,
        }
      }
    )
  },
}

/**
 *  filter common utils
 */
ReportFilterUtils.Common = {
  filterOperatorValues({ filterType }) {
    switch (filterType) {
      case ReportFilterEnum.Filter.Type.ID: {
        return [ReportFilterEnum.Operator.Type.EQ]
      }

      case ReportFilterEnum.Filter.Type.NAME: {
        return [ReportFilterEnum.Operator.Type.CONTAIN]
      }

      case ReportFilterEnum.Filter.Type.ON_OFF_DELETE:
      case ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE:
      case ReportFilterEnum.Filter.Type.GOAL:
      case ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE:
      case ReportFilterEnum.Filter.Type.CREATIVE_FORMAT: {
        return [ReportFilterEnum.Operator.Type.ANY]
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC:
      case ReportFilterEnum.Filter.Type.AD_GROUP_METRIC:
      case ReportFilterEnum.Filter.Type.CREATIVE_METRIC: {
        return [
          ReportFilterEnum.Operator.Type.GE,
          ReportFilterEnum.Operator.Type.LE,
          ReportFilterEnum.Operator.Type.EQ,
          ReportFilterEnum.Operator.Type.GE_LE,
        ]
      }

      // Targeting
      case ReportFilterEnum.Filter.Type.AGE_BAND:
      case ReportFilterEnum.Filter.Type.GENDER:
      case ReportFilterEnum.Filter.Type.AGE_BAND_GENDER:
      case ReportFilterEnum.Filter.Type.LOCATION:
      case ReportFilterEnum.Filter.Type.DEVICE:
      case ReportFilterEnum.Filter.Type.PLACEMENT:
      case ReportFilterEnum.Filter.Type.DEVICE_PLACEMENT:
      case ReportFilterEnum.Filter.Type.TIME_ZONE: {
        return [ReportFilterEnum.Operator.Type.ANY]
      }

      default: {
        return ReportFilterEnum.Operator.values()
      }
    }
  },

  isFilterValueNumeric({ filterType }) {
    return (
      filterType === ReportFilterEnum.Filter.Type.ID ||
      this.isMetricFilter({ filterType })
    )
  },

  isMetricFilter({ filterType }) {
    return [
      ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC,
      ReportFilterEnum.Filter.Type.AD_GROUP_METRIC,
      ReportFilterEnum.Filter.Type.CREATIVE_METRIC,
    ].includes(filterType)
  },

  isTargetingFilter({ filterType }) {
    return [
      ReportFilterEnum.Filter.Type.CREATIVE_FORMAT,
      ReportFilterEnum.Filter.Type.AGE_BAND,
      ReportFilterEnum.Filter.Type.GENDER,
      ReportFilterEnum.Filter.Type.AGE_BAND_GENDER,
      ReportFilterEnum.Filter.Type.LOCATION,
      ReportFilterEnum.Filter.Type.DEVICE,
      ReportFilterEnum.Filter.Type.PLACEMENT,
      ReportFilterEnum.Filter.Type.DEVICE_PLACEMENT,
      ReportFilterEnum.Filter.Type.TIME_ZONE,
    ].includes(filterType)
  },

  isAdUnitFilter({ filterType }) {
    return (
      !this.isMetricFilter({ filterType }) &&
      !this.isTargetingFilter({ filterType })
    )
  },

  valueToString({ conditionValue }) {
    const { filterType, filterOperator, filterValue } = conditionValue || {}

    if (!isNoneUndefinedOrNull(filterType, filterOperator, filterValue)) {
      return ''
    }

    switch (filterType) {
      case ReportFilterEnum.Filter.Type.ID:
      case ReportFilterEnum.Filter.Type.NAME: {
        return filterValue.join(',')
      }

      case ReportFilterEnum.Filter.Type.ON_OFF_DELETE: {
        return filterValue.map(v => UserConfigEnum.getName(v)).join(',')
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE: {
        return filterValue.map(v => CampaignTypeEnum.getName(v)).join(',')
      }

      case ReportFilterEnum.Filter.Type.GOAL: {
        return filterValue.map(v => GoalEnum.getName(v)).join(',')
      }

      case ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE: {
        return filterValue
          .map(v => ObjectiveDetailTypeEnum.getName(v))
          .join(',')
      }

      case ReportFilterEnum.Filter.Type.CREATIVE_FORMAT: {
        return filterValue
          .map(v => {
            const creativeCategoryName = CreativeCategoryEnum.getName(
              CreativeCategoryEnum.getValueByCreativeFormat(v)
            )
            const creativeFormatName = CreativeFormatEnum.getName(v)

            return [creativeCategoryName, creativeFormatName].join(' > ')
          })
          .join(',')
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC:
      case ReportFilterEnum.Filter.Type.AD_GROUP_METRIC:
      case ReportFilterEnum.Filter.Type.CREATIVE_METRIC: {
        const [value] = filterValue
        const { min = '', max = '' } = value || {}

        const [minOperator, maxOperator] = filterOperator.split('_')
        const isBetweenOperatorUsed = max > 0

        const minText = NumberUtils.withCommas(min)
        const maxText = NumberUtils.withCommas(max)

        return [
          isBetweenOperatorUsed
            ? `${minText} ${ReportFilterEnum.Operator.getName(
                minOperator
              )} ${maxText} ${ReportFilterEnum.Operator.getName(maxOperator)}`
            : minText,

          !isBetweenOperatorUsed &&
            ReportFilterEnum.Operator.getName(filterOperator),
        ]
          .filter(Boolean)
          .join(' ')
      }

      case ReportFilterEnum.Filter.Type.AGE_BAND: {
        return filterValue.map(v => ReportTargeting.Age.Text[v]).join(',')
      }

      case ReportFilterEnum.Filter.Type.GENDER: {
        return filterValue.map(v => ReportTargeting.Gender.Text[v]).join(',')
      }

      case ReportFilterEnum.Filter.Type.LOCATION: {
        return filterValue.map(v => LOCATION_KEY_TEXT[v]).join(',')
      }

      case ReportFilterEnum.Filter.Type.DEVICE: {
        return filterValue.map(v => ReportTargeting.Device.Text[v]).join(',')
      }

      case ReportFilterEnum.Filter.Type.PLACEMENT: {
        return filterValue.map(v => PlacementEnum.getName(v)).join(',')
      }

      case ReportFilterEnum.Filter.Type.TIME_ZONE: {
        return filterValue.map(v => ReportTargeting.TimeZone.Text[v]).join(',')
      }

      default: {
        return filterValue.join(',')
      }
    }
  },

  toString({ conditionValue, includeValueString = true }) {
    const { adUnitType, filterType, filterSubType, filterOperator } =
      conditionValue || {}
    const highlightedValueString = includeValueString
      ? this.valueToString({ conditionValue })
      : ''

    switch (filterType) {
      case ReportFilterEnum.Filter.Type.ID:
      case ReportFilterEnum.Filter.Type.ON_OFF_DELETE: {
        return [
          DashboardEnumV2.getName(adUnitType),
          ReportFilterEnum.Filter.getName(filterType),
          highlightedValueString,
        ]
          .filter(Boolean)
          .join(' ')
      }

      case ReportFilterEnum.Filter.Type.NAME: {
        return [
          DashboardEnumV2.getName(adUnitType),
          ReportFilterEnum.Filter.getName(filterType),
          highlightedValueString,
          ReportFilterEnum.Operator.getName(filterOperator),
        ]
          .filter(Boolean)
          .join(' ')
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE:
      case ReportFilterEnum.Filter.Type.GOAL:
      case ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE:
      case ReportFilterEnum.Filter.Type.CREATIVE_FORMAT:
      case ReportFilterEnum.Filter.Type.AGE_BAND:
      case ReportFilterEnum.Filter.Type.GENDER:
      case ReportFilterEnum.Filter.Type.LOCATION:
      case ReportFilterEnum.Filter.Type.DEVICE:
      case ReportFilterEnum.Filter.Type.PLACEMENT:
      case ReportFilterEnum.Filter.Type.TIME_ZONE: {
        return [
          ReportFilterEnum.Filter.getName(filterType),
          highlightedValueString,
        ]
          .filter(Boolean)
          .join(' ')
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC:
      case ReportFilterEnum.Filter.Type.AD_GROUP_METRIC:
      case ReportFilterEnum.Filter.Type.CREATIVE_METRIC: {
        if (filterSubType) {
          return [
            DashboardEnumV2.getName(adUnitType),
            ReportMetric.getMetricIdValues().find(
              ({ id }) => id === filterSubType
            )?.label ?? '',
            highlightedValueString,
          ]
            .filter(Boolean)
            .join(' ')
        } else {
          return ''
        }
      }

      default: {
        return ''
      }
    }
  },

  maxCountOfFilterValueArray({ filterType }) {
    switch (filterType) {
      case ReportFilterEnum.Filter.Type.ID: {
        return 10
      }

      case ReportFilterEnum.Filter.Type.NAME:
      case ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC:
      case ReportFilterEnum.Filter.Type.AD_GROUP_METRIC:
      case ReportFilterEnum.Filter.Type.CREATIVE_METRIC: {
        return 1
      }

      default: {
        return undefined
      }
    }
  },

  maxLengthOfFilterValueString({ filterType }) {
    switch (filterType) {
      case ReportFilterEnum.Filter.Type.ID: {
        return 10
      }

      case ReportFilterEnum.Filter.Type.NAME: {
        return 50
      }

      case ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC:
      case ReportFilterEnum.Filter.Type.AD_GROUP_METRIC:
      case ReportFilterEnum.Filter.Type.CREATIVE_METRIC: {
        return 12
      }

      default: {
        return undefined
      }
    }
  },

  uniqueConditionKey(conditionValue) {
    const { adUnitType, filterType, filterSubType } = conditionValue || {}
    return [
      this.isTargetingFilter({ filterType }) ? undefined : adUnitType,
      filterType,
      filterSubType,
    ]
      .filter(Boolean)
      .join('|')
  },

  adUnitKey({ adUnitType }) {
    return AD_UNIT_KEY_MAP[adUnitType]
  },

  adUnitType({ adUnitKey }) {
    return AD_UNIT_TYPE_MAP[adUnitKey]
  },

  adUnitTypeByFilterType({ filterType }) {
    switch (filterType) {
      case ReportFilterEnum.Filter.Type.CAMPAIGN_TYPE:
      case ReportFilterEnum.Filter.Type.GOAL:
      case ReportFilterEnum.Filter.Type.OBJECTIVE_DETAIL_TYPE: {
        return DashboardEnumV2.Type.CAMPAIGN
      }

      case ReportFilterEnum.Filter.Type.CREATIVE_FORMAT:
      case ReportFilterEnum.Filter.Type.AGE_BAND:
      case ReportFilterEnum.Filter.Type.GENDER:
      case ReportFilterEnum.Filter.Type.AGE_BAND_GENDER:
      case ReportFilterEnum.Filter.Type.LOCATION:
      case ReportFilterEnum.Filter.Type.DEVICE:
      case ReportFilterEnum.Filter.Type.PLACEMENT:
      case ReportFilterEnum.Filter.Type.DEVICE_PLACEMENT:
      case ReportFilterEnum.Filter.Type.TIME_ZONE: {
        // 현재 광고단위 구분만 하고있음. 실제 요청시에는 dimensions 구조로 들어가도록 변경됨.
        return DashboardEnumV2.Type.AD_GROUP
      }

      default: {
        return DashboardEnumV2.Type.CREATIVE
      }
    }
  },

  filterTypeKey({ filterType }) {
    return FILTER_STATE_KEY_MAP[filterType]
  },

  filterType({ stateKey }) {
    return FILTER_TYPE_MAP[stateKey]
  },
}

/**
 *  filter value
 */
ReportFilterUtils.FilterValue = {
  onOffDelete() {
    return UserConfigEnum.values()
  },

  onOff() {
    return UserConfigEnum.onOffValues()
  },

  campaignTypes() {
    return [
      CampaignTypeEnum.Type.TALK_BIZ_BOARD,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      CampaignTypeEnum.Type.DISPLAY,
      CampaignTypeEnum.Type.TALK_CHANNEL,
      CampaignTypeEnum.Type.DAUM_SHOPPING,
      CampaignTypeEnum.Type.VIDEO,
      CampaignTypeEnum.Type.PC_TALK_BOTTOM,
      CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
      CampaignTypeEnum.Type.PROFILE_FULL_VIEW,
      CampaignTypeEnum.Type.PC_TALK_RICH_POP,
    ]
  },

  objectiveValues() {
    return ObjectiveDetailTypeEnum.reportFilterObjectiveValues()
  },

  goals() {
    return GoalEnum.orderedValues()
  },

  creativeFormats() {
    return [
      CreativeFormatEnum.Type.IMAGE_BANNER,
      CreativeFormatEnum.Type.IMAGE_NATIVE,
      CreativeFormatEnum.Type.CATALOG_MANUAL,
      CreativeFormatEnum.Type.IMAGE_BOX,
      CreativeFormatEnum.Type.VIDEO_NATIVE,
      CreativeFormatEnum.Type.CATALOG_DYNAMIC,
      CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
      CreativeFormatEnum.Type.WIDE_MESSAGE,
      CreativeFormatEnum.Type.WIDE_LIST_MESSAGE,
      CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE,
      CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE,
      CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE,
      CreativeFormatEnum.Type.RICH_NATIVE,
    ]
  },

  ages() {
    const unknownValue = String(0)

    return [
      ...ReportTargeting.Age.values().filter(v => v !== unknownValue),
      ReportTargeting.Age.Type[unknownValue],
    ]
  },

  genders() {
    return ReportTargeting.Gender.values()
  },

  locations() {
    return [
      ...Object.keys(LOCATION_KEY).filter(v => v !== LOCATION_KEY.U),
      LOCATION_KEY.U,
    ]
  },

  devices() {
    return ReportTargeting.Device.values()
  },

  placements() {
    return PlacementEnum.reportFilterLayerValues()
  },

  timezones() {
    return ReportTargeting.TimeZone.values()
  },
}

/**
 *  Define propTypes
 */
ReportFilterUtils.PropTypes = {}

ReportFilterUtils.PropTypes.ConditionValue = PropTypes.shape({
  adUnitType: PropTypes.oneOf([...ReportAdUnitTypeEnum.values(), '']),
  filterType: PropTypes.oneOf(ReportFilterEnum.Filter.values()),
  filterSubType: PropTypes.any,
  filterOperator: PropTypes.oneOf([
    ...ReportFilterEnum.Operator.values(),
    '',
    null,
    undefined,
  ]),
})

ReportFilterUtils.PropTypes.ConditionLayer = {
  index: PropTypes.number,
  filterTitle: PropTypes.string.isRequired,
  availableFilterOperators: PropTypes.arrayOf(
    PropTypes.oneOf(ReportFilterEnum.Operator.values())
  ).isRequired,
  conditionValue: ReportFilterUtils.PropTypes.ConditionValue.isRequired,
}
