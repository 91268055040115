import GoalEnum from '../../enums/GoalEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import CreativeCategoryEnum from '../../enums/CreativeCategoryEnum'
import ObjectiveDetailTypeEnum from '../../enums/ObjectiveDetailTypeEnum'

const CreativeConstraints = {
  getNameMaxLength({ creativeFormat, isNew = true }) {
    if (isNew) {
      if (
        CreativeCategoryEnum.getValueByCreativeFormat(creativeFormat) ===
          CreativeCategoryEnum.Type.IMAGE &&
        creativeFormat !== CreativeFormatEnum.Type.CATALOG_MANUAL
      ) {
        return 40
      }

      /**
       * FIXME eddie.brock 남아 있을 필요...???
       */
      if (creativeFormat === CreativeFormatEnum.Type.SERVICE_CONTENT) {
        return 40
      }

      if (creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE) {
        return 40
      }
    }

    return 50
  },

  isBasedOnTalkChannel({ goal, objectiveDetailType }) {
    return (
      goal === GoalEnum.Type.CONVERSION &&
      objectiveDetailType === ObjectiveDetailTypeEnum.Type.ADD_FRIEND
    )
  },

  isTitleRequired({ campaignType, creativeFormat }) {
    const a =
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      [
        CreativeFormatEnum.Type.IMAGE_NATIVE,
        CreativeFormatEnum.Type.VIDEO_NATIVE,
      ].includes(creativeFormat)

    const b =
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING &&
      creativeFormat === CreativeFormatEnum.Type.IMAGE_BOX

    return a || b
  },

  isProfileNameRequired({ campaignType, creativeFormat }) {
    return (
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      [
        CreativeFormatEnum.Type.IMAGE_NATIVE,
        CreativeFormatEnum.Type.VIDEO_NATIVE,
      ].includes(creativeFormat)
    )
  },

  isPromotionalWordRequired({ campaignType, creativeFormat }) {
    const a =
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      [CreativeFormatEnum.Type.IMAGE_NATIVE].includes(creativeFormat)

    const b =
      campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING &&
      creativeFormat === CreativeFormatEnum.Type.IMAGE_BOX

    return a || b
  },

  isActionButtonRequired({ campaignType, creativeFormat }) {
    return (
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      [
        CreativeFormatEnum.Type.IMAGE_NATIVE,
        CreativeFormatEnum.Type.VIDEO_NATIVE,
      ].includes(creativeFormat)
    )
  },

  // LandingScheme(TALK BIZ BOARD LANDING) 과 다름.
  isLandingURLRequired({ campaignType }) {
    return (
      campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL &&
      campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
      campaignType !== CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED
    )
  },

  isAdditionalRequired({ campaignType, goal, creativeFormat }) {
    if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
      return false
    }

    if (goal === GoalEnum.Type.VISITING) {
      const a =
        CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        creativeFormat === CreativeFormatEnum.Type.IMAGE_BANNER
      const b =
        CampaignTypeEnum.Type.DISPLAY &&
        [
          CreativeFormatEnum.Type.IMAGE_NATIVE,
          CreativeFormatEnum.Type.IMAGE_BANNER,
          CreativeFormatEnum.Type.CATALOG_MANUAL,
          CreativeFormatEnum.Type.VIDEO_NATIVE,
          CreativeFormatEnum.Type.SERVICE_CONTENT,
        ].includes(creativeFormat)

      return a || b
    }

    if (goal === GoalEnum.Type.CONVERSION) {
      const a =
        CampaignTypeEnum.Type.TALK_BIZ_BOARD &&
        creativeFormat === CreativeFormatEnum.Type.IMAGE_BANNER
      const b =
        CampaignTypeEnum.Type.DISPLAY &&
        [
          CreativeFormatEnum.Type.IMAGE_NATIVE,
          CreativeFormatEnum.Type.IMAGE_BANNER,
          CreativeFormatEnum.Type.CATALOG_MANUAL,
          CreativeFormatEnum.Type.VIDEO_NATIVE,
        ].includes(creativeFormat)

      return a || b
    }

    if (goal === GoalEnum.Type.VIEW) {
      return (
        CampaignTypeEnum.Type.VIDEO &&
        creativeFormat === CreativeFormatEnum.Type.VIDEO_NATIVE
      )
    }

    return false
  },

  isAdditionalRequiredV2({ campaignType, goal, creativeFormat }) {
    let isFrequencyCapRequired = false
    let isTrackerRequired = false
    let isOpinionProofRequired = false

    if (campaignType !== CampaignTypeEnum.Type.ELECTION_2024_04) {
      if (goal !== GoalEnum.Type.REACH) {
        if (campaignType !== CampaignTypeEnum.Type.PRODUCT_CATALOG) {
          isFrequencyCapRequired = true
        }
      }
      if (!CreativeFormatEnum.isMessage(creativeFormat)) {
        isOpinionProofRequired = true
        if (
          creativeFormat !== CreativeFormatEnum.Type.IMAGE_BANNER &&
          creativeFormat !== CreativeFormatEnum.Type.IMAGE_BOX
        ) {
          isTrackerRequired = true
        }
      }
    }

    return {
      isAdditionalRequired:
        isFrequencyCapRequired || isTrackerRequired || isOpinionProofRequired,
      isFrequencyCapRequired,
      isTrackerRequired,
      isOpinionProofRequired,
    }
  },

  isSharpTabBidRequired({ goal }) {
    return goal === GoalEnum.Type.REACH
  },

  isMessageManagementRequired({ campaignType }) {
    switch (campaignType) {
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return false
      }

      default: {
        return true
      }
    }
  },

  isScheduleRequired({ campaignType }) {
    switch (campaignType) {
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return false
      }

      default: {
        return true
      }
    }
  },

  isBidAmountRequired({ campaignType }) {
    switch (campaignType) {
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return false
      }

      default: {
        return true
      }
    }
  },

  isPlacementDeviceRequired({ campaignType }) {
    switch (campaignType) {
      case CampaignTypeEnum.Type.KAKAO_TV:
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        return false
      }

      default: {
        return true
      }
    }
  },
}

export default CreativeConstraints
