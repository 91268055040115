import React, { useCallback } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import ImageEditorUtils from '../../../CreativeLibrary/ImageEditor/imageEditorUtils'
import { useMultipleImageUpload } from '../../../Form/Viewable/useMultipleImageUpload'
import { InputFileUtil } from '../../../../../../utils/fileUtils'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../../../utils/alertUtils'
import { CreativeGuideTooltip } from '../../../Form/Common'
import {
  hideLoading,
  showLoading,
} from '../../../../../../modules/common/mLoading'
import { v4 as uuid } from 'uuid'
import { fromJS, List } from 'immutable'
import {
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../../../../../../modules/common/mPopup'
import CmpAssetLibraryImageRecent from './CmpAssetLibraryImageRecent'
import CmpAssetLibraryImageUpload from './CmpAssetLibraryImageUpload'
import { keyMirror } from '../../../../../../utils/utils'
import { cancelRequest } from '../../../../../../utils/cancellation/cancellation'
import PopupButtonGroup from '../../../../../Common/ButtonGroup/PopupButtonGroup'
import PropTypes from 'prop-types'
import CreativeUploadAssetPropertyEnum from '../../../../../../enums/CreativeUploadAssetPropertyEnum'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'
import CampaignTypeEnum from '../../../../../../enums/CampaignTypeEnum'
import PopupHOC from '../../../../../Popup/PopupHOC'
import { NumberUtils } from '../../../../../../utils/numberUtils'
import CreativeAssetPropertyEnum from '../../../../../../enums/CreativeAssetPropertyEnum'
import * as Sentry from '@sentry/browser'
import AdConstraintsHelper from '../../../../../../utils/helper/helper-adConstraints'
import {
  addCmpAssetLibraryImageSelectedItems,
  deleteCmpAssetLibraryImageSelectedItem,
  getCmpAssetLibraryImageEditorData,
  initCmpAssetLibraryImageByKeyPath,
  modifyCmpAssetLibraryImageItem,
  setCmpAssetLibraryEditedItems,
  setCmpAssetLibraryImageSelectedItems,
  uploadCmpAssetLibraryImages,
} from '../../../../../../modules/cmp/mCmpAssetLibraryImage'
import CmpAssetLibraryGuide from '../CmpAssetLibraryGuide'
import { CmpAssetLibraryUtils } from '../cmpAssetLibraryUtils'
import CmpAssetLibraryImageEditorData from './EdtiorData/CmpAssetLibraryImageEditorData'
import {
  CMP_EDITOR_EVENT,
  CmpEditorUtils,
} from '../../CmpEditor/cmpEditorUtils'
import CmpAssetPropertyEnum from '../../../../../../enums/CmpAssetPropertyEnum'
import BizBoardSubTypeEnum from '../../../../../../enums/BizBoardSubTypeEnum'
import GoalEnum from '../../../../../../enums/GoalEnum'
import PricingTypeEnum from '../../../../../../enums/PricingTypeEnum'
import {
  notice9To16CreativeGoalTypeConstraintDialog,
  notice9To16CreativePricingTypeConstraintDialog,
} from '../../../../Common/AdvertiseDialog'

const UPLOAD_COUNT_MAX = 100

export const CMP_ASSET_LIBRARY_TAB = keyMirror({
  RECENT: null,
  UPLOAD: null,
  EDITOR_DATA: null,
})

const TAB_ITEMS = [
  { key: CMP_ASSET_LIBRARY_TAB.RECENT, name: '이미지 불러오기' },
  { key: CMP_ASSET_LIBRARY_TAB.UPLOAD, name: '직접 업로드' },
  { key: CMP_ASSET_LIBRARY_TAB.EDITOR_DATA, name: '이미지 에디터' },
]

const selector = ({
  adConstraints: { creativeAssetConstraints },
  cmpAssetLibrary: {
    image: { selectedItems, editedItemsMap },
  },
  creativeV2: {
    common: {
      adGroup: { id: adGroupId, pricingType },
      campaign: {
        contractInfo: { contractProductItems } = {},
        campaignTypeGoal: { goal } = {},
      },
    },
  },
}) => {
  return {
    creativeAssetConstraints,
    selectedItems,
    editedItemsMap,
    adGroupId,
    contractProductItems,
    pricingType,
    goal,
  }
}

const CmpAssetLibraryImage = ({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType = CreativeAssetPropertyEnum.Type.MAIN_IMAGE,
  creativeUploadAssetPropertyType,
  initialTab = CMP_ASSET_LIBRARY_TAB.UPLOAD,
  multiSelect = true,
  onlyPNG24 = false,
  guideTooltipContent,
  editorRatioValueFilter = ratioValue => true,
  onSave,
  close,
  cmpAssetPropertyType = CmpAssetPropertyEnum.Type.MAIN_IMAGE,
  specificRatio,
}) => {
  const dispatch = useDispatch()

  const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

  const {
    selectedItems,
    editedItemsMap,
    adGroupId,
    creativeAssetConstraints,
    contractProductItems,
    pricingType,
    goal,
  } = useSelector(selector, shallowEqual)
  const { subType } = contractProductItems?.first() || {}
  const isMotionBoard = BizBoardSubTypeEnum.isMotionBoard(subType)
  const isCustomBoard = BizBoardSubTypeEnum.isCustomBoard(subType)
  // https://wiki.daumkakao.com/x/Ds2CNg
  const isAvailableEditorData = React.useMemo(
    () =>
      CmpEditorUtils.getIsAvailableEditorData(
        creativeFormat,
        cmpAssetPropertyType
      ),
    [creativeFormat, cmpAssetPropertyType]
  )

  const imageOpener = React.useMemo(() => {
    return CmpEditorUtils.getOpenerByCreativeFormatAndCmpAssetPropertyType({
      creativeFormat,
      cmpAssetPropertyType,
      ratio: CmpEditorUtils.getRatioConstByValue({
        value: specificRatio,
      }),
    })
  }, [creativeFormat, cmpAssetPropertyType, specificRatio])

  const [currTab, setCurrTab] = React.useState(initialTab)
  const [isProcessing, setIsProcessing] = React.useState(false)

  const currTabItems = React.useMemo(
    () =>
      TAB_ITEMS.filter(({ key }) =>
        key === CMP_ASSET_LIBRARY_TAB.EDITOR_DATA ? isAvailableEditorData : true
      ),
    [isAvailableEditorData]
  )

  const currTabIndex = currTabItems.findIndex(({ key }) => key === currTab)

  React.useEffect(() => {
    return () => {
      dispatch(initCmpAssetLibraryImageByKeyPath({ keyPath: ['recent'] }))
      dispatch(initCmpAssetLibraryImageByKeyPath({ keyPath: ['editor'] }))
      dispatch(initCmpAssetLibraryImageByKeyPath({ keyPath: ['catalog'] }))
      dispatch(
        initCmpAssetLibraryImageByKeyPath({ keyPath: ['selectedItems'] })
      )
      dispatch(
        initCmpAssetLibraryImageByKeyPath({ keyPath: ['editedItemsMap'] })
      )
      dispatch(initCmpAssetLibraryImageByKeyPath({ keyPath: ['viewState'] }))
    }
  }, [dispatch])

  const assetConstraint = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getAssetConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      }),
    [creativeAssetConstraints, creativeAssetPropertyType, creativeFormat]
  )
  const uploadSizeConstraints = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getUploadSizeConstraints({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      }) ?? List(),
    [creativeAssetConstraints, creativeAssetPropertyType, creativeFormat]
  )
  const saveSizeConstraints = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getSaveSizeConstraints({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.filter(constraint => {
        if (specificRatio) {
          // specificRatio 가 있는 상황이면 백엔드에서 주는 제약조건에도 무조건 있어야 함
          const { ratio: { eq: ratio } = {} } = constraint
          return ratio === specificRatio
        }
        return true
      }) ?? List(),
    [
      creativeAssetConstraints,
      creativeAssetPropertyType,
      creativeFormat,
      specificRatio,
    ]
  )
  const cropSizes = React.useMemo(
    () =>
      isMessage
        ? []
        : saveSizeConstraints
            .map(({ width, height, ratio }) => {
              return {
                width:
                  width?.get('eq') ?? width?.get('min') ?? width?.get('max'),
                height:
                  height?.get('eq') ?? height?.get('min') ?? height?.get('max'),
                ratio:
                  ratio?.get('eq') ?? ratio?.get('min') ?? ratio?.get('max'),
              }
            })
            // 현재 1:1, 2:1, 9:16, 16:9 비율 지원
            .filter(
              ({ width, height, ratio }) =>
                width > 0 &&
                height > 0 &&
                [1, 2, 1.7777, 0.5625].includes(ratio)
            )
            .toJS(),
    [isMessage, saveSizeConstraints]
  )

  const cropAspects = React.useMemo(
    () => cropSizes.map(({ ratio }) => ratio),
    [cropSizes]
  )

  const cropDimensionMap = React.useMemo(
    () =>
      cropSizes.reduce((obj, { width, height, ratio }) => {
        obj[ratio] = { width, height }
        return obj
      }, {}),
    [cropSizes]
  )
  // 2023.04.20 https://jira.daumkakao.com/browse/KAMOQA-23647
  // 해당 이슈로 인해 selector 위치및 로직 변경
  const multiImageSelector = React.useCallback(
    ({
      cmpAssetLibrary: {
        image: {
          upload: { uploadItems },
        },
      },
    }) => {
      return {
        // for `useMultipleImageUpload` props
        countOfImages: CmpAssetLibraryUtils.Upload.filterByConstraint({
          uploadAssets: uploadItems,
          creativeUploadAssetPropertyType,
          uploadSizeConstraints,
        }).count(),
      }
    },
    [creativeUploadAssetPropertyType, uploadSizeConstraints]
  )

  const needEdit =
    cropAspects.length > 0 &&
    creativeAssetPropertyType !== CreativeAssetPropertyEnum.Type.PROFILE_IMAGE

  const multipleImageUpload = useMultipleImageUpload({
    adAccountId,
    imageSelector: multiImageSelector,
    imageUploadCountMax: UPLOAD_COUNT_MAX,
    onUpload: ({ files, cancelKey, onProgress, onFinish }) => {
      if (!InputFileUtil.isValid(files)) {
        showErrorMessage(
          `유효하지 않은 이미지 파일입니다.\n다른 이미지 파일을 업로드해 주세요.`
        )
      } else {
        dispatch(
          uploadCmpAssetLibraryImages({
            adAccountId,
            creativeUploadAssetPropertyType,
            uploadSizeConstraints,
            needEdit,
            creativeFormat,
            guideTooltip: (
              <CreativeGuideTooltip
                fileSizeClassName="filesize_info_type2"
                {...guideTooltipContent}
              />
            ),
            files,
            cancelKey,
            onlyPNG24,
            multiSelect,
            onProgress,
            onFinish,
          })
        )
      }
    },
  })

  const { imageUploadState, imageUploadCancelKey } = multipleImageUpload

  const validateImageSize = React.useCallback(
    ({ originWidth, originHeight, croppedWidth, croppedHeight }) => {
      return CmpAssetLibraryUtils.isImageSizeValid({
        sizeConstraints: saveSizeConstraints,
        originWidth,
        originHeight,
        croppedWidth,
        croppedHeight,
      })
    },
    [saveSizeConstraints]
  )

  const onOK = async () => {
    dispatch(showLoading())

    setIsProcessing(true)

    const items = []

    for (const selectedItem of selectedItems) {
      const imageUUID = selectedItem.get('imageUUID')

      if (editedItemsMap.has(imageUUID)) {
        const editedItems = editedItemsMap.get(imageUUID)

        for (const editedItem of editedItems) {
          const { editedImageUrl, originalFileName, mimeType } = editedItem

          const uploadResult = await ImageEditorUtils.uploadEditedImage({
            adAccountId,
            objectUrl: editedImageUrl,
            originalFileName,
            mimeType,
            adGroupId,
            creativeFormat,
            creativeUploadAssetPropertyType,
            needEdit,
          })

          if (uploadResult) {
            // catalogProduct 와 같은 props 를 그대로 보존한다.
            items.push(Object.assign(editedItem.toJS(), uploadResult))
          }
        }
      } else {
        items.push(selectedItem.toJS())
      }
    }

    // 동일 항목을 반복해서 가져올 수 있기 때문에 가져오는 시점에 unique id 를 새로 발급한다.
    items.forEach(v => {
      v['imageUUID'] = uuid()
    })

    if (typeof onSave === 'function') {
      if (items.length > 0) {
        const isAllValid = items.every(
          ({
            imageWidth: originWidth,
            imageHeight: originHeight,
            editedImageWidth: croppedWidth,
            editedImageHeight: croppedHeight,
          }) =>
            validateImageSize({
              originWidth,
              originHeight,
              croppedWidth,
              croppedHeight,
            })
        )

        if (isAllValid) {
          const hasShortFormImage = items.some(({ imageWidth, imageHeight }) =>
            CreativeFormatEnum.isRatio9To16Image({
              width: imageWidth,
              height: imageHeight,
            })
          )

          // 9:16 비율의 이미지를 허용하는 소재의 경우 확인해야함
          onSave({
            items: fromJS(items),
            onSuccess: message => {
              close()
              if (hasShortFormImage) {
                if (
                  creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE &&
                  goal === GoalEnum.Type.VISITING &&
                  pricingType !== PricingTypeEnum.Type.CPM
                ) {
                  dispatch(
                    openPopupByProxy(
                      POPUP_KEY.SIMPLE_POPUP,
                      notice9To16CreativePricingTypeConstraintDialog(() =>
                        showSuccessMessage(
                          message || '소재 요소 등록이 완료되었습니다.'
                        )
                      )
                    )
                  )
                } else if (
                  creativeFormat === CreativeFormatEnum.Type.IMAGE_NATIVE &&
                  goal === GoalEnum.Type.CONVERSION
                ) {
                  dispatch(
                    openPopupByProxy(
                      POPUP_KEY.SIMPLE_POPUP,
                      notice9To16CreativeGoalTypeConstraintDialog(() =>
                        showSuccessMessage(
                          message || '소재 요소 등록이 완료되었습니다.'
                        )
                      )
                    )
                  )
                } else {
                  showSuccessMessage(
                    message || '소재 요소 등록이 완료되었습니다.'
                  )
                }
              } else {
                showSuccessMessage(
                  message || '소재 요소 등록이 완료되었습니다.'
                )
              }
            },
            onFailure: message => {
              showErrorMessage(message || '소재 요소 등록에 실패했습니다.')
            },
            close,
          })
        } else {
          const canCrop = cropAspects.length > 0

          if (canCrop) {
            showErrorMessage(
              '편집이 필요한 이미지가 있습니다. 등록 가능한 이미지로 변경 후 다시 시도하세요.'
            )
          } else {
            showErrorMessage('등록 가능한 이미지로 변경 후 다시 시도하세요.')
          }
        }
      } else {
        close()
      }

      setIsProcessing(false)

      dispatch(hideLoading())
    }
  }

  const GuideView = React.useMemo(() => {
    return (
      <CmpAssetLibraryGuide
        campaignType={campaignType}
        creativeFormat={creativeFormat}
        creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
        uploadGuideTooltip={<CreativeGuideTooltip {...guideTooltipContent} />}
        isVisibleCatalogProductGuide={
          currTab === CMP_ASSET_LIBRARY_TAB.CATALOG_PRODUCT
        }
        isVisibleEditorGuide={currTab === CMP_ASSET_LIBRARY_TAB.EDITOR_DATA}
        isMotionBoard={isMotionBoard}
        isCustomBoard={isCustomBoard}
      />
    )
  }, [
    campaignType,
    creativeFormat,
    creativeUploadAssetPropertyType,
    guideTooltipContent,
    currTab,
    isMotionBoard,
    isCustomBoard,
  ])

  const handleImageCropperOpen = React.useCallback(
    ({ imageUUID, items, originalItem }) => {
      dispatch(
        openPopup(POPUP_KEY.CREATIVE_LIBRARY_IMAGE_EDITOR, {
          campaignType,
          creativeFormat,
          creativeUploadAssetPropertyType,
          validAspects: cropAspects,
          cropDimensionMap,
          originalImageUUID: imageUUID,
          images: items,
          containerHeight: 420,
          onComplete: editedItems => {
            dispatch(
              setCmpAssetLibraryEditedItems({
                key: imageUUID,
                items: editedItems,
              })
            )

            dispatch(
              deleteCmpAssetLibraryImageSelectedItem({ item: originalItem })
            )

            if (
              editedItems &&
              (multiSelect ||
                selectedItems.filter(v => v.get('imageUUID') !== imageUUID)
                  .size === 0)
            ) {
              dispatch(
                addCmpAssetLibraryImageSelectedItems({
                  items: [originalItem],
                })
              )
            }
          },
        })
      )
    },
    [
      dispatch,
      campaignType,
      creativeFormat,
      creativeUploadAssetPropertyType,
      cropAspects,
      cropDimensionMap,
      multiSelect,
      selectedItems,
    ]
  )

  const handleCmpImageEditorOpenForModify = React.useCallback(
    ({ imageEditorItem }) => {
      const { imageEditorId } = imageEditorItem

      dispatch(
        openPopup(POPUP_KEY.CMP_EDITOR, {
          adAccountId,
          imageEditorId,
          imageOpener,
          eventCallback: ({ event, payload }) => {
            if (event === CMP_EDITOR_EVENT.MODIFY_ITEM) {
              const editorItem = payload.data || {}

              setCurrTab(CMP_ASSET_LIBRARY_TAB.EDITOR_DATA)

              // 다중 선택일 경우 add, 단일 선택일 경우 set
              if (multiSelect) {
                dispatch(modifyCmpAssetLibraryImageItem({ item: editorItem }))
              } else {
                dispatch(
                  setCmpAssetLibraryImageSelectedItems({
                    items: [editorItem],
                  })
                )
              }

              dispatch(
                getCmpAssetLibraryImageEditorData({
                  adAccountId,
                  campaignType,
                  creativeFormat,
                  creativeAssetPropertyType,
                  specificRatio,
                })
              )
            }
          },
        })
      )
    },
    [
      adAccountId,
      campaignType,
      creativeAssetPropertyType,
      creativeFormat,
      dispatch,
      imageOpener,
      multiSelect,
      specificRatio,
    ]
  )

  const imageModifyEventCallback = useCallback(
    item =>
      ({ event, payload }) => {
        switch (event) {
          case CMP_EDITOR_EVENT.CREATE_ITEM: {
            setCurrTab(CMP_ASSET_LIBRARY_TAB.EDITOR_DATA)
            const recentItems = payload.data || []

            dispatch(
              initCmpAssetLibraryImageByKeyPath({
                keyPath: ['editor', 'searchOptions'],
              })
            )
            if (multiSelect) {
              dispatch(deleteCmpAssetLibraryImageSelectedItem({ item }))
              dispatch(
                addCmpAssetLibraryImageSelectedItems({ items: recentItems })
              )
            } else {
              recentItems.length > 0 &&
                dispatch(
                  setCmpAssetLibraryImageSelectedItems({
                    items: [recentItems[0]],
                  })
                )
            }

            dispatch(
              getCmpAssetLibraryImageEditorData({
                adAccountId,
                campaignType,
                creativeFormat,
                creativeAssetPropertyType,
                specificRatio,
              })
            )
            break
          }
          default:
            break
        }
      },
    [
      adAccountId,
      campaignType,
      creativeAssetPropertyType,
      creativeFormat,
      specificRatio,
      dispatch,
      multiSelect,
    ]
  )

  const handleImageEditorOpenForModify = React.useCallback(
    imageItem => {
      const { url, originalFileName } = imageItem || {}

      dispatch(
        openPopup(POPUP_KEY.CMP_EDITOR, {
          adAccountId,
          imageSourceUrl: url,
          imageSourceFileName: originalFileName,
          eventCallback: imageModifyEventCallback(imageItem),
          imageOpener,
        })
      )
    },
    [adAccountId, dispatch, imageModifyEventCallback, imageOpener]
  )

  const selectedImageCount = React.useMemo(
    () =>
      selectedItems.reduce((prev, v) => {
        const { imageUUID } = v
        if (editedItemsMap.has(imageUUID)) {
          const editedItems = editedItemsMap.get(imageUUID)
          return prev + editedItems.count()
        } else {
          return prev + 1
        }
      }, 0),
    [editedItemsMap, selectedItems]
  )

  // assertion error
  if (!assetConstraint) {
    // const errorMessage = `Constraint not found: ${creativeFormat} ${creativeAssetPropertyType}`
    const errorMessage = '사용가능한 소재 유형이 없습니다.'

    Sentry.captureException(
      JSON.stringify({
        errorMessage,
        creativeFormat,
        creativeAssetPropertyType,
        creativeAssetConstraints: creativeAssetConstraints?.toJS(),
      })
    )

    showErrorMessage(errorMessage)

    close()
  }

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">소재 라이브러리</strong>
      </div>
      <div className="layer_body">
        <strong className="screen_out">소재 라이브러리 종류</strong>
        <Tabs
          onSelect={nextTabIndex => {
            const nextTab = currTabItems.find(
              (_, tabIndex) => tabIndex === nextTabIndex
            )?.key
            setCurrTab(prevTab => nextTab ?? prevTab)
          }}
          selectedIndex={currTabIndex}>
          <TabList className="tab_g8">
            {currTabItems.map(({ key, name }, tabIndex) => {
              return (
                <Tab
                  key={key}
                  className={cx({ on: currTabIndex === tabIndex })}>
                  <a className="link_tab">
                    {name}
                    {currTabIndex === tabIndex && (
                      <span className="screen_out">선택됨</span>
                    )}
                  </a>
                </Tab>
              )
            })}
          </TabList>
          <TabPanel>
            <CmpAssetLibraryImageRecent
              adAccountId={adAccountId}
              campaignType={campaignType}
              creativeFormat={creativeFormat}
              creativeAssetPropertyType={creativeAssetPropertyType}
              cropAspects={cropAspects}
              assetConstraint={assetConstraint}
              multiSelect={multiSelect}
              handleImageCropperOpen={handleImageCropperOpen}
              validateImageSize={validateImageSize}
              renderGuideView={GuideView}
              handleImageEditorOpenForModify={handleImageEditorOpenForModify}
              specificRatio={specificRatio}
            />
          </TabPanel>
          <TabPanel>
            <CmpAssetLibraryImageUpload
              multiSelect={multiSelect}
              campaignType={campaignType}
              creativeFormat={creativeFormat}
              creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
              uploadSizeConstraints={uploadSizeConstraints}
              cropAspects={cropAspects}
              renderGuideView={GuideView}
              handleImageCropperOpen={handleImageCropperOpen}
              validateImageSize={validateImageSize}
              handleImageEditorOpenForModify={handleImageEditorOpenForModify}
              {...multipleImageUpload}
            />
          </TabPanel>
          {isAvailableEditorData && (
            <TabPanel>
              <CmpAssetLibraryImageEditorData
                adAccountId={adAccountId}
                campaignType={campaignType}
                creativeFormat={creativeFormat}
                creativeAssetPropertyType={creativeAssetPropertyType}
                cropAspects={cropAspects}
                assetConstraint={assetConstraint}
                multiSelect={multiSelect}
                handleCmpImageEditorOpenForModify={
                  handleCmpImageEditorOpenForModify
                }
                validateImageSize={validateImageSize}
                editorRatioValueFilter={editorRatioValueFilter}
                renderGuideView={GuideView}
                cmpAssetPropertyType={cmpAssetPropertyType}
                specificRatio={specificRatio}
              />
            </TabPanel>
          )}
        </Tabs>
        {imageUploadState.progress > 0 && (
          <div className="layer_upload">
            <div className="inner_layer">
              <span className="txt_upload">
                {NumberUtils.withCommas(multipleImageUpload.fileCount)}개 이미지
                업로드 중
              </span>
              <span className="load_wrap">
                <span className="load_bg">
                  <span
                    className="load_bar"
                    style={{ width: `${imageUploadState.progress * 100}%` }}>
                    {imageUploadState.progress}%
                  </span>
                </span>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => {
                    cancelRequest(imageUploadCancelKey)
                  }}>
                  <span className="ico_comm ico_del">삭제</span>
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="layer_foot">
        {selectedItems.count() > 0 && (
          <span className="txt_select">
            <button
              type="button"
              className="btn_del"
              onClick={() => {
                dispatch(
                  initCmpAssetLibraryImageByKeyPath({
                    keyPath: ['selectedItems'],
                  })
                )
              }}>
              <span className="ico_comm ico_del">삭제</span>
            </button>
            선택된 이미지
            <em className="num_select">
              {NumberUtils.withCommas(selectedImageCount)}
            </em>
          </span>
        )}
        <PopupButtonGroup
          okButtonLabel="확인"
          hasBack={false}
          isEnabledOK={selectedItems.count() > 0 && !isProcessing}
          isEnabledCancel={!isProcessing}
          onOK={onOK}
          onCancel={close}
        />
        <a className="btn_close" onClick={close}>
          <span className="ico_comm ico_close">닫기</span>
        </a>
      </div>
    </div>
  )
}

CmpAssetLibraryImage.propTypes = {
  adAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()).isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  initialTab: PropTypes.oneOf(Object.keys(CMP_ASSET_LIBRARY_TAB)),
  multiSelect: PropTypes.bool,
  onlyPNG24: PropTypes.bool,
  editorRatioValueFilter: PropTypes.func,

  onSave: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,

  guideTooltipContent: PropTypes.shape({
    formats: PropTypes.array,
    sizes: PropTypes.array,
    extras: PropTypes.array,
  }),
  cmpAssetPropertyType: PropTypes.oneOf(CmpAssetPropertyEnum.values()),
  specificRatio: PropTypes.number,
}

export default PopupHOC(CmpAssetLibraryImage, {
  subClassName: 'material_layer',
})
