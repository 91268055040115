import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    NONE: null,
    JOIN: null,
    PLAY: null,
    PURCHASE: null,
    DOWNLOAD: null,
    INQUIRE: null,
    LINK: null,
    LAUNCH: null,
    INSTALL: null,
    SUBSCRIBE: null,
    REQUEST: null,
    RUN: null,
    MORE: null,
    MORE_VIDEO: null,
    RESERVATION: null,
    ADD_FRIEND: null,
    COUPON: null,
  }),

  Name: {
    NONE: '버튼 선택',
    JOIN: '가입하기',
    PLAY: '게임하기',
    PURCHASE: '구매하기',
    DOWNLOAD: '다운로드',
    INQUIRE: '문의하기',
    LINK: '바로가기',
    LAUNCH: '사용하기',
    INSTALL: '설치하기',
    SUBSCRIBE: '소식받기',
    REQUEST: '신청하기',
    RUN: '실행하기',
    MORE: '알아보기',
    MORE_VIDEO: '영상 더보기',
    RESERVATION: '예약하기',
    ADD_FRIEND: '채널추가',
    COUPON: '쿠폰받기',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
