import {
  CONTEXTPATH,
  DOMINO,
  fetchGet,
  queryString,
} from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'
import cruxFetchUtils from '../../utils/cruxFetchUtils'

/**
 * 7.8.1 내 광고반응 타겟 생성
 * @param adAccountId
 * @param requestBody
 */
function createCohorts(adAccountId, requestBody) {
  return cruxFetchUtils.post({
    url: `${DOMINO}/${adAccountId}/cohorts`,
    requestPayload: requestBody,
    adAccountId,
  })
}

/**
 * 7.8.2 내 광고반응 타겟 상세 조회
 * @param adAccountId
 * @param cohortId
 */
function fetchCohortDetail(adAccountId, cohortId) {
  return cruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/cohorts/${cohortId}`,
    adAccountId,
  })
}

/**
 * 7.8.3 내 광고반응 타겟 리스트 조회
 * @param adAccountId
 * @param requestParam
 *
 *  { type = '', page = 0, size = 50 }
 */
function fetchCohorts(adAccountId, requestParam) {
  return cruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/cohorts?${queryString(requestParam)}`,
    adAccountId,
  })
}

/**
 * 7.8.4 내 광고반응 타겟 수정 - 이름
 * @param adAccountId
 * @param cohortId
 * @param requestBody
 * {
    "name" : "내 광고반응 1_수정"
  }
 */
function modifyCohortName(adAccountId, cohortId, requestBody) {
  return cruxFetchUtils.put({
    url: `${DOMINO}/${adAccountId}/cohorts/${cohortId}?type=name`,
    requestPayload: requestBody,
    adAccountId,
  })
}

/**
 * 7.8.5 내 광고반응 타겟 수정 - base ad
 * @param adAccountId
 * @param cohortId
 * @param requestBody
 * { "cohortBaseAds": [
 *   {
 *     "campaignId": 937,
 *     "adGroupId": 1500,
 *     "campaignType": "DISPLAY",
 *     "firstIndicator": "CLICK",
 *     "operation": "ONLY"
 *   },
 *   {
 *     "campaignId": 124,
 *     "adGroupId": 538,
 *     "campaignType": "MESSAGE",
 *     "firstIndicator": "OPEN",
 *     "operation": "MINUS",
 *     "secondIndicator": "CLICK"
 *   }
 *  ]
 * }
 */
function modifyCohortBaseAd(adAccountId, cohortId, requestBody) {
  return cruxFetchUtils.put({
    url: `${DOMINO}/${adAccountId}/cohorts/${cohortId}?type=ad`,
    requestPayload: requestBody,
    adAccountId,
  })
}

/**
 * 7.8.6 내 광고반응 타겟 사용 중인 광고그룹 조회
 */
function fetchCohortAdGroup(adAccountId, cohortId, searchOptions) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/cohorts/${cohortId}/adGroups?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 7.8.7 내 광고반응 타겟 삭제
 * @param adAccountId
 * @param cohortId
 */
function deleteCohort(adAccountId, cohortId) {
  return cruxFetchUtils.delete({
    url: `${DOMINO}/${adAccountId}/cohorts/${cohortId}`,
    adAccountId,
  })
}

/**
 * @param adAccountId
 * @param cohortIds
 */
const deleteMultipleCohort = (adAccountId, cohortIds) => {
  return RequestLock.acquire({
    key: 'deleteMultipleCohort',
    executor: done =>
      cruxFetchUtils
        .delete({
          url: `${DOMINO}/${adAccountId}/cohorts?${queryString({ cohortIds })}`,
          adAccountId,
        })
        .finally(() => {
          done()
        }),
  })
}

export default {
  createCohorts,
  fetchCohortDetail,
  fetchCohorts,
  modifyCohortName,
  modifyCohortBaseAd,
  fetchCohortAdGroup,
  deleteCohort,
  deleteMultipleCohort,
}
