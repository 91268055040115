import React, { Fragment } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import './PopupContainer.css'
import {
  closeAllPopupWithoutKeys,
  closePopup,
} from '../../../modules/common/mPopup'
import { useLocation } from 'react-router-dom'
import isPassiveSupported from '../../../utils/isPassiveSupported'
import { PopupComponentMap } from '../popupComponentMap'
import { usePrevious } from '../../../utils/hook/usePrevious'
import BasicLoader from '../../Common/Loader/BasicLoader'

const PopupContainer = () => {
  const dispatch = useDispatch()
  const popups = useSelector(({ popup }) => popup, shallowEqual)
  const location = useLocation()
  const isEmpty = popups.isEmpty()
  const prevIsEmpty = usePrevious(isEmpty)
  const prevLocation = usePrevious(location)

  const [isExiting, setIsExiting] = React.useState(false)

  React.useEffect(() => {
    if (prevIsEmpty !== isEmpty) {
      setTimeout(() => {
        // add event queue(delay)
        if (isEmpty) {
          document.body.classList.remove('layer_on')
        } else {
          document.body.classList.add('layer_on')
        }
      }, 0)

      setTimeout(() => {
        if (isEmpty) {
          setIsExiting(false)
        }
      }, 1000)
    }
  }, [isEmpty, prevIsEmpty])

  React.useEffect(() => {
    if (prevLocation !== location) {
      if (!isEmpty) {
        dispatch(closeAllPopupWithoutKeys())
      }

      setIsExiting(false)
    }
  }, [dispatch, isEmpty, location, prevLocation])

  const defaultDimClassName = 'dimmed_layer'
  const currentDimClassName = React.useMemo(() => {
    if (isEmpty) return defaultDimClassName
    const { dimClassName } = popups.last()
    return dimClassName || defaultDimClassName
  }, [isEmpty, popups])

  const handleKeyDown = React.useCallback(
    ({ popupIndex, popupKey }) =>
      e => {
        switch (e.key) {
          // escape
          case 'Escape': {
            if (popupIndex !== 0) return

            if (!isPassiveSupported) {
              e.preventDefault() // for prevent iframe bug in IE
            }

            dispatch(closePopup(popupKey))
            break
          }

          default:
            break
        }
      },
    [dispatch]
  )

  let index = 0

  const popupComponents = React.useMemo(
    () =>
      popups
        .reverse()
        .map((popupProps, popupKey) => {
          const component = PopupComponentMap[popupKey]

          if (component) {
            return {
              popupKey,
              popupComponent: React.cloneElement(component, {
                ...popupProps,
                index: index++,
                close: () => {
                  dispatch(closePopup(popupKey))
                },
              }),
            }
          }

          return null
        })
        .filter(Boolean)
        .valueSeq(),
    [dispatch, index, popups]
  )

  return (
    <React.Suspense
      fallback={
        <Fragment>
          <div className="dimmed_layer2" />
          <BasicLoader />
        </Fragment>
      }>
      {/* prevent click when closing popup with anim */}
      {isExiting && (
        <div
          className="dimmed_layer2"
          style={{ backgroundColor: 'transparent' }}
        />
      )}
      {popupComponents.count() > 0 && <div className={currentDimClassName} />}
      <TransitionGroup>
        {popupComponents.map(({ popupKey, popupComponent }, i) => {
          return (
            <CSSTransition
              key={popupKey}
              classNames="popup"
              timeout={{ enter: 300, exit: 300 }}
              onExit={() => setIsExiting(true)}
              onExited={() => setIsExiting(false)}>
              <KeyDownEventWrapper
                callback={handleKeyDown({ popupIndex: i, popupKey })}>
                {popupComponent}
              </KeyDownEventWrapper>
            </CSSTransition>
          )
        })}
      </TransitionGroup>
    </React.Suspense>
  )
}

const KeyDownEventWrapper = ({ children, callback }) => {
  React.useEffect(() => {
    const onKeyDown = e => {
      if (typeof callback === 'function') {
        callback(e)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [callback])

  return children
}

export default PopupContainer
