import { DashboardUriUtils } from './dashboardUriUtils'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageSession,
} from '../../utils/storage/storageFactoryImpl'

const DashboardRouter = {
  Path: {
    isDashboard({ pathname }) {
      return /^\/dashboard\/*/.test(pathname) || pathname === '/'
    },

    AdAccount({ adAccountId }) {
      const url = DashboardUriUtils.createURLObject({ adAccountId })

      return url.pathname + url.search
    },

    Campaign({ adAccountId, summaryId, rowIdType, rowIds }) {
      const url = DashboardUriUtils.createURLObject({
        adAccountId,
        table: {
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          rowIdType, // 테이블 탭과 다른 타입의 rowIds 를 선택 시
          rowIds: rowIds?.length > 0 ? rowIds : undefined,
        },
        summary:
          summaryId > 0
            ? {
                dashboardType: DashboardEnumV2.Type.CAMPAIGN,
                id: summaryId,
              }
            : undefined,
      })

      return url.pathname + url.search
    },

    AdGroup({ adAccountId, summaryId, rowIdType, rowIds }) {
      const url = DashboardUriUtils.createURLObject({
        adAccountId,
        table: {
          dashboardType: DashboardEnumV2.Type.AD_GROUP,
          rowIdType,
          rowIds: rowIds?.length > 0 ? rowIds : undefined,
        },
        summary:
          summaryId > 0
            ? {
                dashboardType: DashboardEnumV2.Type.AD_GROUP,
                id: summaryId,
              }
            : undefined,
      })

      return url.pathname + url.search
    },

    Creative({ adAccountId, summaryId, rowIdType, rowIds = [] }) {
      const url = DashboardUriUtils.createURLObject({
        adAccountId,
        table: {
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          rowIdType,
          rowIds: rowIds?.length > 0 ? rowIds : undefined,
        },
        summary:
          summaryId > 0
            ? {
                dashboardType: DashboardEnumV2.Type.CREATIVE,
                id: summaryId,
              }
            : undefined,
      })

      return url.pathname + url.search
    },

    /**
     * 이전 상태의 대시보드 진입 URL 생성 (세션 스토리지에 저장된 uriSearchParams 사용)
     * @param adAccountId
     * @returns {string}
     * @constructor
     */
    Prev(adAccountId) {
      const storedDashboardSearchParamString = DashboardStorageSession.get(
        DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS
      )
      return `/dashboard/${adAccountId}${
        storedDashboardSearchParamString
          ? `?${storedDashboardSearchParamString}`
          : ''
      }`
    },
  },
}

export default DashboardRouter
