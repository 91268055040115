import { keyMirror } from '../utils/utils'

export default {
  Loading: {
    Type: keyMirror({
      IDLE: null,
      LOADING: null,
    }),
  },

  CashCharge: {
    Status: {
      Type: keyMirror({
        CHARGE: null,
        REPAYMENT: null,
        REFUND: null,
        WITHDRAW: null,
      }),

      Name: {
        CHARGE: '충전',
        REPAYMENT: '후불 결제',
        REFUND: '환급',
        WITHDRAW: '환불',
      },

      values() {
        return Object.freeze(Object.keys(this.Type))
      },

      getName(type) {
        return this.Name[type]
      },

      getOptionSelectItems() {
        return this.values().reduce(
          (prev, k) =>
            prev.concat({
              id: k,
              label: this.getName(k),
              type: k,
            }),
          Array.of({ id: 'ALL', label: '전체', type: '' })
        )
      },
    },
  },

  UsingFreeCash: {},

  Withdraw: {},

  RepaymentCriteria: {
    Type: keyMirror({
      DAYS_30: null,
      DAYS_60: null,
      DAYS_90: null,
      DAYS_120: null,
    }),

    Name: {
      DAYS_30: 30,
      DAYS_60: 60,
      DAYS_90: 90,
      DAYS_120: 120,
    },

    getName(type) {
      return this.Name[type]
    },
  },

  DeferredPayment: {
    Status: {
      Type: keyMirror({
        READY: null,
        PARTIAL: null,
        DONE: null,
        OVERDUE: null,
      }),

      Name: {
        READY: '미결제',
        PARTIAL: '일부 결제',
        DONE: '결제 완료',
        OVERDUE: '연체',
      },

      values() {
        return Object.freeze(Object.keys(this.Type))
      },

      getName(type) {
        return this.Name[type]
      },

      getOptionSelectItems() {
        return this.values().reduce(
          (prev, k) =>
            prev.concat({
              id: k,
              label: this.getName(k),
              status: k,
            }),
          Array.of({ id: 'ALL', label: '전체', status: '' })
        )
      },
    },
  },
}
