import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { ScaleImageFitCenter } from '../../../../../../../utils/imageUtils'
import { useAnimateCSS } from '../../../../../../../utils/hook/useAnimateCSS'
import { getRemainingTimeSeparatedString } from '../../../../../../../utils/utils'
import { setCmpAssetLibraryVideoSelectedItems } from '../../../../../../../modules/cmp/mCmpAssetLibraryVideo'
import CmpAssetLibraryFileName from '../../CmpAssetLibraryFileName'

const CmpAssetLibraryVideoMessageAssetView = ({
  index,
  item,
  query = '',
  isSelected,
  isVisibleAnim = true,
  deletable = false,
  onVideoLoaded,
  handleDelete,
}) => {
  const dispatch = useDispatch()

  const {
    videoUUID,
    originalFileName: videoFileName,
    duration,
    thumbnail,
    url: videoUrl,
    type: videoType,
  } = item || {}

  const {
    imageWidth: thumbnailWidth,
    imageHeight: thumbnailHeight,
    url: thumbnailUrl,
  } = thumbnail || {}

  const { ref, onLoad, isAnimStarted } = useAnimateCSS({
    onAnimEnded: () => {
      if (typeof onVideoLoaded === 'function') {
        onVideoLoaded(videoUUID)
      }
    },
  })

  const sizeText = [thumbnailWidth, thumbnailHeight].filter(Boolean).join('x')

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    thumbnailWidth,
    thumbnailHeight,
    150,
    150
  )

  const hasLink = videoType === 'KAKAOTV' // 카카오 TV 인 경우 clipLink 로 이동

  return (
    <li
      className={cx({
        on: isSelected,
        load_ok: isVisibleAnim && !!videoUrl,
      })}>
      <a
        className="link_material"
        onClick={e => {
          e.preventDefault()

          dispatch(
            setCmpAssetLibraryVideoSelectedItems({
              items: isSelected ? [] : [item],
            })
          )
        }}>
        <span className="img_file">
          <span className="inner_img_file">
            {thumbnailUrl ? (
              <img
                src={thumbnailUrl}
                width={scaledWidth}
                height={scaledHeight}
                onLoad={onLoad}
              />
            ) : (
              <span className="bg_file" />
            )}
          </span>
          <div className="time_control">
            {getRemainingTimeSeparatedString(duration)}
          </div>
          <span className="frame_g" />
          {isVisibleAnim && (
            <span
              ref={ref}
              className={cx('load_img', {
                'animated fadeOut': isAnimStarted,
              })}>
              <span className="ico_comm ico_loading">로딩완료</span>
            </span>
          )}
          {isSelected && <span className="ico_comm ico_liston" />}
        </span>
        {deletable && (
          <button
            type="button"
            className="btn_del"
            onClick={e => {
              e.stopPropagation()

              if (typeof handleDelete === 'function') {
                handleDelete(item)
              }
            }}>
            <span className="ico_comm ico_del">삭제</span>
          </button>
        )}
      </a>
      <p className="txt_file">{sizeText || '-'}</p>
      <CmpAssetLibraryFileName
        fileName={videoFileName}
        query={query}
        linkUrl={videoUrl}
      />
    </li>
  )
}

CmpAssetLibraryVideoMessageAssetView.propTypes = {
  index: PropTypes.number.isRequired,
  item: ImmutablePropTypes.map.isRequired,
  query: PropTypes.string,
  isSelected: PropTypes.bool,
  isVisibleAnim: PropTypes.bool,
  deletable: PropTypes.bool,
  onVideoLoaded: PropTypes.func,
  handleDelete: PropTypes.func,
}

export default CmpAssetLibraryVideoMessageAssetView
