import axios from 'axios'
import { removeElementFocus } from './utils'

const externalAxios = axios.create()

function fetchPostFormExternal(
  url,
  data,
  cancelTokenSource,
  onProgress,
  preventDuplicateRequest = true
) {
  if (preventDuplicateRequest) {
    removeElementFocus()
  }

  return externalAxios(url, {
    method: 'post',
    data,
    onUploadProgress: onUploadProgress(onProgress),
    cancelToken: cancelTokenSource?.token,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const onUploadProgress = onProgress => {
  if (typeof onProgress !== 'function') return () => undefined

  const min = 0.001
  let startTime = null

  return callback => {
    const { timeStamp, loaded, total } = callback
    if (startTime === null) startTime = timeStamp
    const progress = Math.max(loaded / total, min)
    const expectedRemainTime = (timeStamp - startTime) * (1 / progress - 1)
    onProgress(progress, expectedRemainTime)
  }
}
export { fetchPostFormExternal }
