import React from 'react'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import { showErrorMessage } from '../../../../../../utils/alertUtils'
import CreativeUploadAssetPropertyEnum from '../../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../../enums/CampaignTypeEnum'
import CmpAssetLibraryImageView from './CmpAssetLibraryImageView'
import { CmpAssetLibraryUtils } from '../cmpAssetLibraryUtils'
import { deleteCmpAssetLibraryImageUploadItem } from '../../../../../../modules/cmp/mCmpAssetLibraryImage'
import useComponentId from '../../../../../../utils/hook/useComponentId'
import ImmutablePropTypes from 'react-immutable-proptypes'
import CmpDropArea, { UPLOAD_TYPE } from '../../CmpInput/CmpDropArea'

const selector = ({
  cmpAssetLibrary: {
    image: {
      upload: { uploadItems },
      selectedItems,
      viewState: { isUploading },
    },
  },
}) => {
  return {
    uploadItems,
    selectedItems,
    isUploading,
  }
}

const ERROR_MESSAGE_BUSY = '다른 작업을 처리하고 있습니다.'

const CmpAssetLibraryImageUpload = ({
  multiSelect,
  campaignType,
  creativeUploadAssetPropertyType,
  uploadSizeConstraints,
  renderGuideView,
  cropAspects,
  creativeFormat,
  handleImageCropperOpen,
  validateImageSize,
  handleImageEditorOpenForModify,
  // `useMultipleImageUpload` props
  onImageDrop,
  onImageDragOver,
  onImageDragEnter,
  onImageDragLeave,
  onImageDragEnd,
  onImageChange,
}) => {
  const dispatch = useDispatch()

  const [isVisibleAnim, setIsVisibleAnim] = React.useState(false)
  const { uploadItems, selectedItems, isUploading } = useSelector(
    selector,
    shallowEqual
  )

  const imageAssets = CmpAssetLibraryUtils.Upload.filterByConstraint({
    uploadAssets: uploadItems,
    creativeUploadAssetPropertyType,
    uploadSizeConstraints,
  })

  const [loadedImageUUIDs, setLoadedImageUUIDs] = React.useState(
    Set(imageAssets.map(({ imageUUID }) => imageUUID))
  )

  const [isOverDropTarget, setIsOverDropTarget] = React.useState(false)
  const dropTargetCounter = React.useRef(0)

  /**
   * override `useMultipleImageUpload` drag handler
   * https://stackoverflow.com/questions/7110353/html5-dragleave-fired-when-hovering-a-child-element*
   */
  const onDragEnter = React.useCallback(
    e => {
      onImageDragEnter(e)
      dropTargetCounter.current++
      setIsOverDropTarget(true)
    },
    [onImageDragEnter]
  )

  const onDragLeave = React.useCallback(
    e => {
      onImageDragLeave(e)
      dropTargetCounter.current--
      if (dropTargetCounter.current === 0) {
        setIsOverDropTarget(false)
      }
    },
    [onImageDragLeave]
  )

  const onDrop = React.useCallback(
    e => {
      if (isUploading) {
        e.preventDefault()

        showErrorMessage(ERROR_MESSAGE_BUSY)
      } else {
        onImageDrop(e)
      }

      setIsOverDropTarget(false)
      dropTargetCounter.current = 0
    },
    [isUploading, onImageDrop]
  )

  const handleDelete = React.useCallback(
    item => {
      dispatch(deleteCmpAssetLibraryImageUploadItem({ item }))

      setLoadedImageUUIDs(prev => prev.delete(item.get('imageUUID')))
    },
    [dispatch]
  )

  const onImageLoaded = React.useCallback(
    imageUUID =>
      setLoadedImageUUIDs(prev =>
        !prev.has(imageUUID) ? prev.add(imageUUID) : prev
      ),
    []
  )

  const onUpload = React.useCallback(e => {
    if (isUploading) {
      e.preventDefault()

      showErrorMessage(ERROR_MESSAGE_BUSY)
    } else {
      setIsVisibleAnim(true)

      onImageChange(e)
    }
  }, [])

  const isBizBoardBanner =
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

  const uploadFileButtonId = useComponentId()

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function'
          ? renderGuideView()
          : renderGuideView}
        <span className="box_inpfile">
          <label
            htmlFor={uploadFileButtonId}
            className={cx('btn_gs lab_file', { in_active: isUploading })}>
            <span className="inner_g">
              <span className="img_comm ico_add" />
              파일 업로드
            </span>
          </label>
          <input
            id={uploadFileButtonId}
            type="file"
            className="inp_file"
            accept="image/*"
            onChange={e => {
              if (isUploading) {
                e.preventDefault()

                showErrorMessage(ERROR_MESSAGE_BUSY)
              } else {
                setIsVisibleAnim(true)

                onImageChange(e)
              }
            }}
            multiple={true}
          />
        </span>
      </div>
      <div
        className={cx('group_material', {
          drop_area_all: isOverDropTarget,
          on_drop: imageAssets.count() === 0 || isOverDropTarget,
        })}
        onDragOver={onImageDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragEnd={onImageDragEnd}
        onDrop={e => {
          setIsVisibleAnim(true)

          onDrop(e)
        }}>
        <CmpDropArea
          allowedMimeTypeString={'image/*'}
          uploadType={UPLOAD_TYPE.IMAGE}
          multiple={true}
          onUpload={onUpload}
        />
        <div className="inner_material">
          <ul className="list_material">
            {imageAssets.map((imageAsset, index) => {
              const { imageUUID } = imageAsset
              const selectedOrder =
                selectedItems.findIndex(
                  ({ imageUUID: selectedItemImageUUID }) =>
                    selectedItemImageUUID === imageUUID
                ) + 1

              const isAlreadyLoaded = loadedImageUUIDs.has(imageUUID)

              return (
                <CmpAssetLibraryImageView
                  key={imageUUID}
                  index={index}
                  selectedOrder={selectedOrder}
                  item={imageAsset}
                  cropAspects={cropAspects}
                  isVisibleAnim={!isAlreadyLoaded && isVisibleAnim}
                  multiSelect={multiSelect}
                  deletable={true}
                  isBizBoardBanner={isBizBoardBanner}
                  onImageLoaded={onImageLoaded}
                  creativeFormat={creativeFormat}
                  handleImageCropperOpen={handleImageCropperOpen}
                  handleDelete={handleDelete}
                  validateImageSize={validateImageSize}
                  handleImageEditorOpenForModify={
                    handleImageEditorOpenForModify
                  }
                />
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

CmpAssetLibraryImageUpload.propTypes = {
  multiSelect: PropTypes.bool.isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()).isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  uploadSizeConstraints: ImmutablePropTypes.list,
  renderGuideView: PropTypes.any,
  cropAspects: PropTypes.array.isRequired,
  validateImageSize: PropTypes.func.isRequired,
  handleImageCropperOpen: PropTypes.func.isRequired,
  handleImageEditorOpenForModify: PropTypes.func.isRequired,
  // `useMultipleImageUpload` props
  onImageDrop: PropTypes.func.isRequired,
  onImageDragOver: PropTypes.func.isRequired,
  onImageDragEnter: PropTypes.func.isRequired,
  onImageDragLeave: PropTypes.func.isRequired,
  onImageDragEnd: PropTypes.func.isRequired,
  onImageChange: PropTypes.func.isRequired,
}

export default CmpAssetLibraryImageUpload
