import { keyMirror } from '../utils/utils'

// dashboard | table 공용.
export default {
  Type: keyMirror({
    AD_ACCOUNT: null,
    CAMPAIGN: null,
    AD_GROUP: null,
    CREATIVE: null,
  }),

  Name: {
    AD_ACCOUNT: '광고계정',
    CAMPAIGN: '캠페인',
    AD_GROUP: '광고그룹',
    CREATIVE: '소재',
  },

  values() {
    return Object.freeze(Object.values(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },

  getChildren(type) {
    switch (type) {
      case this.Type.AD_ACCOUNT: {
        return [this.Type.CAMPAIGN, this.Type.AD_GROUP, this.Type.CREATIVE]
      }

      case this.Type.CAMPAIGN: {
        return [this.Type.AD_GROUP, this.Type.CREATIVE]
      }

      case this.Type.AD_GROUP: {
        return [this.Type.CREATIVE]
      }

      default: {
        return []
      }
    }
  },

  getParents(type) {
    switch (type) {
      case this.Type.CREATIVE: {
        return [this.Type.AD_ACCOUNT, this.Type.CAMPAIGN, this.Type.AD_GROUP]
      }

      case this.Type.AD_GROUP: {
        return [this.Type.AD_ACCOUNT, this.Type.CAMPAIGN]
      }

      case this.Type.CAMPAIGN: {
        return [this.Type.AD_ACCOUNT]
      }

      default: {
        return []
      }
    }
  },
}
