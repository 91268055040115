import DimensionEnum from '../../../enums/DimensionEnum'
import DimensionGroupEnum from '../../../enums/DimensionGroupEnum'
import { keyMirror } from '../../../utils/utils'

export const DRAGGABLE_AD_UNIT_KEY = keyMirror({
  CAMPAIGN: null,
  AD_GROUP: null,
  CREATIVE: null,
  ETC: null,
})

export const getAdUnitGroup = key => {
  switch (key) {
    case DimensionEnum.AdUnit.Type.CAMPAIGN_NAME:
    case DimensionEnum.AdUnit.Type.CAMPAIGN_ID: {
      return DRAGGABLE_AD_UNIT_KEY.CAMPAIGN
    }

    case DimensionEnum.AdUnit.Type.AD_GROUP_NAME:
    case DimensionEnum.AdUnit.Type.AD_GROUP_ID: {
      return DRAGGABLE_AD_UNIT_KEY.AD_GROUP
    }

    case DimensionEnum.AdUnit.Type.CREATIVE_NAME:
    case DimensionEnum.AdUnit.Type.CREATIVE_ID: {
      return DRAGGABLE_AD_UNIT_KEY.CREATIVE
    }

    default: {
      return DRAGGABLE_AD_UNIT_KEY.ETC
    }
  }
}

export const DimensionTreeData = {
  AdUnit: DimensionEnum.AdUnit.values(),
  Setting: DimensionEnum.Setting.values(),
  Targeting: DimensionEnum.Targeting.values(),
  PeriodUnit: DimensionEnum.PeriodUnit.values(),
}

export const getDimensionGroupKey = dimension => {
  if (DimensionEnum.AdUnit.values().includes(dimension)) {
    return DimensionGroupEnum.Type.AdUnit
  } else if (DimensionEnum.Setting.values().includes(dimension)) {
    return DimensionGroupEnum.Type.Setting
  } else if (DimensionEnum.Targeting.values().includes(dimension)) {
    return DimensionGroupEnum.Type.Targeting
  } else if (DimensionEnum.PeriodUnit.values().includes(dimension)) {
    return DimensionGroupEnum.Type.PeriodUnit
  } else {
    return ''
  }
}

export const getDimensionName = dimension => {
  if (DimensionEnum.AdUnit.values().includes(dimension)) {
    return DimensionEnum.AdUnit.getName(dimension)
  } else if (DimensionEnum.Setting.values().includes(dimension)) {
    return DimensionEnum.Setting.getName(dimension)
  } else if (DimensionEnum.Targeting.values().includes(dimension)) {
    return DimensionEnum.Targeting.getName(dimension)
  } else {
    return DimensionEnum.PeriodUnit.getName(dimension)
  }
}
