import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../utils/utils'
import axios from 'axios'
import { coerceToArray } from '../../utils/stringUtils'
import {
  CATEGORY_TARGET_KEY,
  CATEGORY_TARGET_FORM_MAPPER,
} from '../../utils/advertise/targeting'
import { TreeUtils } from '../../utils/treeUtils'

const Targeting = keyMirror({
  INIT_ALL_TARGETING_STATE: null,
  SET_IS_VALID_APP_TRACKING_BY_KEY: null,

  // category targeting
  INIT_CATEGORY_TARGETING: null,
  SET_CATEGORY_TARGETING_TREE_DATA: null,
  SET_CATEGORY_TARGETING_SELECTED_ITEMS: null,
  SET_CATEGORY_TARGETING_SELECTED_MAP: null,
  INIT_CATEGORY_TARGETING_SELECTED_MAP: null,
  UPDATE_CATEGORY_TARGETING: null,
})

export const TARGETING_POPULATION_CATEGORY_TYPE = keyMirror({
  FIVE_AGES: null,
  GENDERS: null,
  LOCATIONS: null,
  BEHAVIORS: null,
  INTERESTS: null,
  KEYWORDS: null,
  DEVICES: null,
  PLACEMENTS: null,
  PLUS_FRIENDS: null,
  SYNC_APP: null,
  TRACKER: null,
  PLACES: null,
  CUSTOM_ADID_UPLOAD: null,
  COHORT: null,
  PLUS_FRIEND_GROUP_FILE: null,
})

export const CUSTOMER_FILE_TYPE = keyMirror({
  ADID: null,
})

export const PLUS_FRIEND_PROFILE_TRIM_KEY_PATH_LIST = [['name']]

const initialState = fromJS({
  /**
   * category targeting
   */
  categoryTargeting: {
    query: '',
    selectedItems: [],
    idLabelMap: {
      interests: {},
      business: {},
    },
    treeData: {
      interests: [],
      business: [],
    },
    selectedMap: {
      interests: {},
      business: {},
    },
    parentChildrenMap: {
      interests: {},
      business: {},
    },
  },
})

export default createReducer(initialState, {
  [Targeting.INIT_CATEGORY_TARGETING]: state =>
    state.set('categoryTargeting', initialState.get('categoryTargeting')),

  [Targeting.SET_CATEGORY_TARGETING_TREE_DATA]: (state, { data, dataType }) =>
    state.setIn(['categoryTargeting', 'treeData', dataType], fromJS(data)),

  [Targeting.SET_CATEGORY_TARGETING_SELECTED_ITEMS]: (state, { data }) =>
    state.setIn(['categoryTargeting', 'selectedItems'], fromJS(data)),

  [Targeting.SET_CATEGORY_TARGETING_SELECTED_MAP]: (
    state,
    { data, dataType }
  ) =>
    state.setIn(['categoryTargeting', 'selectedMap', dataType], fromJS(data)),

  [Targeting.INIT_CATEGORY_TARGETING_SELECTED_MAP]: state =>
    state.setIn(
      ['categoryTargeting', 'selectedMap'],
      initialState.getIn(['categoryTargeting', 'selectedMap'])
    ),

  [Targeting.UPDATE_CATEGORY_TARGETING]: (state, { data, key }) =>
    state.setIn(['categoryTargeting', ...coerceToArray(key)], fromJS(data)),
})

export function initAllTargetingState() {
  return {
    type: Targeting.INIT_ALL_TARGETING_STATE,
  }
}

export const setDataSetForCategoryTargetingTree = (
  type,
  treeData,
  targeting,
  dispatch
) => {
  const selectedIds = targeting.get(CATEGORY_TARGET_FORM_MAPPER[type])
  const selectedMap = TreeUtils.getSelectedMap(treeData, selectedIds)
  const idLabelMap = TreeUtils.getIdLabelMap(treeData)
  const parentChildrenMap = TreeUtils.getParentChildrenMap(treeData)

  dispatch(setCategoryTargetingTreeData(type, treeData))
  dispatch(setCategoryTargetingSelectedMap(type, selectedMap))
  dispatch(updateCategoryTargeting(['idLabelMap', type], idLabelMap))
  dispatch(
    updateCategoryTargeting(['parentChildrenMap', type], parentChildrenMap)
  )
}

export function getCategoryTargetingData(
  targeting,
  adAccountId,
  onSuccess,
  onFinish
) {
  return async (dispatch, getState, api) => {
    try {
      const [interestResponse, businessResponse] = await axios.all([
        api.targeting.fetchKakaoDataCategory(adAccountId, 'INTEREST'),
        api.targeting.fetchKakaoDataCategory(adAccountId, 'BUSINESS_TYPE'),
      ])

      const { data: interests } = interestResponse?.data || {}
      const { data: business } = businessResponse?.data || {}

      const interestsList = fromJS(interests)
      const businessList = fromJS(business)

      if (typeof onSuccess === 'function') {
        onSuccess(interestsList, businessList)
      }

      setDataSetForCategoryTargetingTree(
        CATEGORY_TARGET_KEY.interests,
        interestsList,
        targeting,
        dispatch
      )
      setDataSetForCategoryTargetingTree(
        CATEGORY_TARGET_KEY.business,
        businessList,
        targeting,
        dispatch
      )
    } catch (e) {
      console.log(e)
    } finally {
      if (typeof onFinish === 'function') {
        onFinish()
      }
    }
  }
}

export function initCategoryTargeting() {
  return {
    type: Targeting.INIT_CATEGORY_TARGETING,
  }
}

export function updateCategoryTargeting(key, data) {
  return {
    type: Targeting.UPDATE_CATEGORY_TARGETING,
    key,
    data,
  }
}

export function setCategoryTargetingSelectedItems(data) {
  return {
    type: Targeting.SET_CATEGORY_TARGETING_SELECTED_ITEMS,
    data,
  }
}

export function setCategoryTargetingTreeData(dataType, data) {
  return {
    type: Targeting.SET_CATEGORY_TARGETING_TREE_DATA,
    dataType,
    data,
  }
}

export function setCategoryTargetingSelectedMap(dataType, data) {
  return {
    type: Targeting.SET_CATEGORY_TARGETING_SELECTED_MAP,
    dataType,
    data,
  }
}

export function initCategoryTargetingSelectedMap() {
  return {
    type: Targeting.INIT_CATEGORY_TARGETING_SELECTED_MAP,
  }
}
