import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    TALK_BIZ_BOARD: null,
    TALK_BIZ_BOARD_RESERVED: null,
    DISPLAY: null,
    TALK_CHANNEL: null,
    DAUM_SHOPPING: null,
    KAKAO_TV: null,
    VIDEO: null,
    ELECTION_2020: null,
    SPONSORED_BOARD: null,
    ELECTION_2022_03: null,
    ELECTION_2022_06: null,
    ELECTION_2024_04: null,
    PRODUCT_CATALOG: null,
    PERSONAL_MESSAGE: null,
    PC_TALK_BOTTOM: null,
    PC_TALK_RICH_POP: null,
    FOCUS_FULL_VIEW: null,
    PROFILE_FULL_VIEW: null,
  }),

  Name: {
    TALK_BIZ_BOARD: '카카오 비즈보드',
    TALK_BIZ_BOARD_RESERVED: '카카오 비즈보드 CPT',
    DISPLAY: '디스플레이',
    TALK_CHANNEL: '카카오톡 채널',
    DAUM_SHOPPING: '다음쇼핑',
    KAKAO_TV: '카카오TV',
    VIDEO: '동영상',
    ELECTION_2020: '2020 국회의원 선거',
    SPONSORED_BOARD: '스폰서드 보드',
    ELECTION_2022_03: '제20대 대통령선거',
    ELECTION_2022_06: '제8회 전국동시지방선거',
    ELECTION_2024_04: '제22대 국회의원 선거',
    PRODUCT_CATALOG: '상품 카탈로그',
    PERSONAL_MESSAGE: '개인화 메시지',
    PC_TALK_BOTTOM: '포커스 보드',
    PC_TALK_RICH_POP: '리치팝 올데이',
    FOCUS_FULL_VIEW: '포커스 풀뷰',
    PROFILE_FULL_VIEW: '프로필 풀뷰',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  orderedValues() {
    return Object.freeze([
      this.Type.TALK_BIZ_BOARD,
      this.Type.TALK_BIZ_BOARD_RESERVED,
      this.Type.PC_TALK_BOTTOM,
      this.Type.FOCUS_FULL_VIEW,
      this.Type.PROFILE_FULL_VIEW,
      this.Type.PC_TALK_RICH_POP,
      this.Type.DISPLAY,
      this.Type.TALK_CHANNEL,
      this.Type.DAUM_SHOPPING,
      this.Type.VIDEO,
      this.Type.KAKAO_TV,
      this.Type.ELECTION_2020,
      this.Type.SPONSORED_BOARD,
      this.Type.ELECTION_2022_03,
      this.Type.ELECTION_2022_06,
      this.Type.ELECTION_2024_04,
      this.Type.PRODUCT_CATALOG,
      this.Type.PERSONAL_MESSAGE,
    ])
  },

  getName(type) {
    return this.Name[type]
  },

  isDeprecated(type) {
    return [
      this.Type.ELECTION_2020,
      this.Type.KAKAO_TV,
      this.Type.SPONSORED_BOARD,
    ].includes(type)
  },

  /**
   * 계약 시스템을 이용하는 캠페인들
   * 세금계산서 발행이 안되어 거래영수내역 제공 대상이기 때문에 그룹화
   */
  isContractCampaignType(type) {
    return [
      this.Type.TALK_BIZ_BOARD_RESERVED,
      this.Type.ELECTION_2024_04,
      this.Type.PC_TALK_BOTTOM,
      this.Type.PC_TALK_RICH_POP,
      this.Type.FOCUS_FULL_VIEW,
      this.Type.PROFILE_FULL_VIEW,
    ].includes(type)
  },

  isRichNativeType(type) {
    return [
      this.Type.PC_TALK_RICH_POP,
      this.Type.PC_TALK_BOTTOM,
      this.Type.FOCUS_FULL_VIEW,
      this.Type.PROFILE_FULL_VIEW,
    ].includes(type)
  },

  /**
   * 선거 관련 캠페인의 경우 특정 기간이 지나면 캠페인 생성이 불가하고, 수정해도 의미가 없으므로 뷰 조건처리를 최신 선거유형 기준으로 통일함.
   * 기간 한정 진행하는 캠페인임에도 일부 세부적인 조건이 각기 다른 부분이 계속 추가될경우 코드 가독성이 떨어지기때문에 유형 체크를 통합 하였음.
   */
  isElectionCampaignType(type) {
    return [this.Type.ELECTION_2022_03, this.Type.ELECTION_2022_06].includes(
      type
    )
  },

  isVideoType(type) {
    return [this.Type.FOCUS_FULL_VIEW].includes(type)
  },

  isRelatedToBizFormAndAdViewLanding(type) {
    return [
      this.Type.TALK_BIZ_BOARD,
      this.Type.TALK_BIZ_BOARD_RESERVED,
      this.Type.PC_TALK_BOTTOM,
      this.Type.FOCUS_FULL_VIEW,
      this.Type.PC_TALK_RICH_POP,
      this.Type.PROFILE_FULL_VIEW,
    ].includes(type)
  },

  isFullViewType(type) {
    return [this.Type.FOCUS_FULL_VIEW, this.Type.PROFILE_FULL_VIEW].includes(
      type
    )
  },

  /**
   * 협력광고 유형
   */
  isRelatedToCollaborativeAds(type) {
    return [this.Type.PRODUCT_CATALOG].includes(type)
  },
}
