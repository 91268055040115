import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { coerceToArray } from '../../utils/stringUtils'
import { createReducer } from 'redux-immutablejs'
import { hideLoading, showLoading } from '../common/mLoading'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import { toMessageListPath } from '../../utils/router/routeUtils'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import {
  handleMessageError,
  initMessageValidation,
  setIsValidMessageByKey,
  setSpamValidationResult,
  showSpamFilterPopUp,
} from './mMessageCommon'
import {
  getMessageValidatorByMessageType,
  MESSAGE_FORM_VALIDATION_KEY_PATH,
} from '../../validators/message/messageFormValidator'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../validators/message/messageFormValidationKey'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const TalkChannelMessageCreate = keyMirror(
  {
    INITIALIZE: null,
    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,
    CLEAR: null,
    SET_PLUS_FRIEND_INFO: null,
    SET_BY_CHANNEL_MANAGER_CENTER: null,
  },
  'TALK_CHANNEL_MESSAGE_CREATE'
)

const initialState = fromJS({
  profileId: -1,
  profileName: '',
  profileImageUrl: '',
  csInfo: '',
  profileHomeInfo: null,
  creativeFormat: CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
})

export default createReducer(initialState, {
  [TalkChannelMessageCreate.INITIALIZE]: (state, { data }) =>
    state.merge(fromJS(data)),

  [TalkChannelMessageCreate.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn([...coerceToArray(keyPath)], fromJS(value)),

  [TalkChannelMessageCreate.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(
      [...coerceToArray(keyPath)],
      initialState.getIn([...coerceToArray(keyPath)])
    ),

  [TalkChannelMessageCreate.CLEAR]: () => initialState,
  [TalkChannelMessageCreate.SET_PLUS_FRIEND_INFO]: (state, { data }) => {
    const {
      profileId,
      profileName,
      profileImageUrl,
      csInfo,
      encodedId,
      bizChannel,
      profileHomeInfo,
    } = data

    const { type } = profileHomeInfo || {}
    const isProfileHomeInfo = type === 'web'

    return state
      .set('profileId', profileId)
      .set('profileName', profileName)
      .set('profileImageUrl', profileImageUrl)
      .set('csInfo', csInfo)
      .set('encodedId', encodedId)
      .set('isBizChannel', bizChannel)
      .set('isProfileHomeInfo', isProfileHomeInfo)
  },
  [TalkChannelMessageCreate.SET_BY_CHANNEL_MANAGER_CENTER]: (state, { data }) =>
    state.merge(fromJS(data)),
})

export function initializeTalkChannelMessage(data) {
  return {
    type: TalkChannelMessageCreate.INITIALIZE,
    data,
  }
}

export function setTalkChannelMessageByKey(keyPath, value) {
  return {
    type: TalkChannelMessageCreate.SET_BY_KEY_PATH,
    keyPath,
    value,
  }
}

export function clearTalkChannelMessage() {
  return {
    type: TalkChannelMessageCreate.CLEAR,
  }
}

export function setPlusFriendInfo(plusFriendProfile) {
  const {
    id: profileId,
    name: profileName,
    profileImageUrl,
    csInfo,
    encodedId,
    bizChannel,
    profileHomeInfo,
  } = plusFriendProfile
  return {
    type: TalkChannelMessageCreate.SET_PLUS_FRIEND_INFO,
    data: {
      profileId,
      profileName,
      profileImageUrl,
      csInfo,
      encodedId,
      bizChannel,
      profileHomeInfo,
    },
  }
}

export function createMessageTemplate(adAccountId, messageTemplate) {
  return async (dispatch, getState, api) => {
    const { creativeFormat } = messageTemplate

    const messageForm = TalkChannelMessageHelper.toTrimForm(messageTemplate)

    dispatch(initMessageValidation())

    const validationResult = Validation(
      messageForm,
      MESSAGE_FORM_VALIDATION_KEY,
      MESSAGE_FORM_VALIDATION_KEY_PATH,
      getMessageValidatorByMessageType({ creativeFormat }),
      getState,
      setIsValidMessageByKey,
      dispatch
    )

    if (!validationResult) {
      const {
        message: {
          common: {
            validationErrorKeys,
            validationExtra: { itemAssetGroupsValidationResults },
          },
        },
      } = getState()

      const getExtra = validationKey => {
        const invalidItemAssetIndex =
          validationKey === MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS
            ? itemAssetGroupsValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : undefined

        return invalidItemAssetIndex >= 0 ? invalidItemAssetIndex : undefined
      }

      scrollByValidationKeys(
        validationErrorKeys,
        MESSAGE_FORM_VALIDATION_KEY,
        getExtra
      )

      return
    }

    try {
      dispatch(showLoading())
      const response = await api.message.validateSpamMessage(
        adAccountId,
        TalkChannelMessageHelper.toAPI(messageForm)
      )
      const { isFiltered = false } = response?.data || {}

      if (isFiltered) {
        const spamValidationResult =
          TalkChannelMessageHelper.Spam.convertSpamResult({
            spamResult: response.data,
            creativeFormat,
            messageForm: messageTemplate,
          })
        dispatch(setSpamValidationResult(spamValidationResult))
        dispatch(showSpamFilterPopUp())
        dispatch(hideLoading())

        return
      }

      await api.message.createMessageTemplate(
        adAccountId,
        TalkChannelMessageHelper.toAPI(messageForm)
      )
      dispatch(hideLoading())
      dispatch(RouterV2.push(toMessageListPath(adAccountId)))
    } catch (e) {
      console.log(e)
      dispatch(hideLoading())
      handleMessageError({ error: e?.response?.data, dispatch })
    }
  }
}
