import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { InputFileUtil } from '../../../../../../utils/fileUtils'
import { setIsVisibleImageMasking } from '../../../../../../modules/advertise/mCreativeCommonV2'
import { showErrorMessage } from '../../../../../../utils/alertUtils'
import { cancelRequest } from '../../../../../../utils/cancellation/cancellation'
import { useIsMounted } from '../../../../../../utils/hook/useIsMounted'
import { v4 as uuid } from 'uuid'
import { CreativeAssetUtils } from '../../../creativeAssetUtils'
import CreativeUploadAssetPropertyEnum from '../../../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  UPLOAD_INITIAL_STATUS,
  UPLOAD_SUCCESS_STATUS,
} from '../cmpAssetLibraryUtils'

const selector = ({
  creativeV2: {
    common: { isVisibleImageMasking },
  },
  cmpAssetLibrary: {
    video: {
      upload: { uploadItems },
    },
  },
}) => {
  return {
    isVisibleImageMasking,
    countOfVideos: uploadItems.count(),
  }
}

const useCmpVideoUpload = ({ onUpload, creativeUploadAssetPropertyType }) => {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()

  const videoProps = useSelector(selector, shallowEqual)
  const { countOfVideos } = videoProps

  const [isOverVideoDropTarget, setIsOverVideoDropTarget] =
    React.useState(false)
  const [videoUploadCancelKey, setVideoUploadCancelKey] = React.useState(null)
  const [videoUploadState, setVideoUploadState] = React.useState(
    UPLOAD_INITIAL_STATUS
  )
  const isMotionBoardVideo =
    creativeUploadAssetPropertyType ===
    CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO

  const [fileCount, setFileCount] = React.useState(0)

  React.useEffect(() => {
    return () => {
      cancelRequest(videoUploadCancelKey)
    }
  }, [videoUploadCancelKey])

  const handleVideoUpload = React.useCallback(
    ({ e, files }) => {
      if (files.length > 1) {
        showErrorMessage(
          `동영상은 한 번에 1개만 업로드 가능합니다. 확인 후 다시 시도하세요.`
        )
      } else if (countOfVideos >= CreativeAssetUtils.Video.fileCountMax) {
        showErrorMessage(
          `동영상은 최대 ${CreativeAssetUtils.Video.fileCountMax}개까지 업로드 가능합니다. 확인 후 다시 시도하세요.`
        )
      } else if (
        !InputFileUtil.isValidType(
          files,
          new RegExp(CreativeAssetUtils.Video.allowedMimeTypes.join('|'))
        )
      ) {
        showErrorMessage(
          '등록 가능한 동영상 포맷을 확인하신 후 다시 시도하세요.'
        )
      } else if (
        !InputFileUtil.isValidSize(
          files,
          isMotionBoardVideo
            ? CreativeAssetUtils.MotionBoardVideo.fileSizeMax
            : CreativeAssetUtils.Video.fileSizeMax
        )
      ) {
        showErrorMessage(
          '등록 가능한 동영상 용량을 확인하신 후 다시 시도하세요.'
        )
      } else if (!InputFileUtil.isValidName(files)) {
        showErrorMessage(
          '동영상 파일 이름은 필수 입니다. 확인하신 후 다시 시도하세요.'
        )
      } else {
        setFileCount(files.length)

        const cancelKey = uuid()

        setVideoUploadCancelKey(cancelKey)

        dispatch(setIsVisibleImageMasking(true))

        if (typeof onUpload === 'function') {
          onUpload({
            files,
            cancelKey,
            onProgress: progress => {
              if (isMounted.current) {
                setVideoUploadState({
                  progress,
                  requested: progress === 1,
                  received: false,
                })
              }
            },
            onFinish: () => {
              if (isMounted.current) {
                setVideoUploadState(UPLOAD_SUCCESS_STATUS)

                setVideoUploadCancelKey(null)
              }
            },
          })
        }
      }

      setIsOverVideoDropTarget(false)

      e.target.value = null
    },
    [countOfVideos, dispatch, isMounted, onUpload, isMotionBoardVideo]
  )

  const onVideoDrop = React.useCallback(
    e => {
      e.preventDefault()
      e.stopPropagation()

      handleVideoUpload({ e, files: e.dataTransfer.files })
    },
    [handleVideoUpload]
  )

  const onVideoDragOver = React.useCallback(e => {
    e.preventDefault()
    e.stopPropagation()
  }, [])

  const onVideoDragEnter = React.useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    setIsOverVideoDropTarget(true)
  }, [])

  const onVideoDragLeave = React.useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    setIsOverVideoDropTarget(false)
  }, [])

  const onVideoDragEnd = React.useCallback(e => {
    e.preventDefault()
    e.stopPropagation()

    setIsOverVideoDropTarget(false)
  }, [])

  const onVideoChange = React.useCallback(
    e => {
      handleVideoUpload({ e, files: e.target.files })
    },
    [handleVideoUpload]
  )

  return {
    ...videoProps,
    fileCount,
    isOverVideoDropTarget,
    videoUploadCancelKey,
    setVideoUploadCancelKey,
    videoUploadState,
    setVideoUploadState,
    onVideoDrop,
    onVideoDragOver,
    onVideoDragEnter,
    onVideoDragLeave,
    onVideoDragEnd,
    onVideoChange,
  }
}

export { useCmpVideoUpload }
