import React from 'react'
import PropTypes from 'prop-types'
import { Radio, RadioGroup } from '../../../../Common/RadioGroup/RadioGroup'

const BizBoardBannerImageMaskSelect = ({
  maskOptions,
  maskType,
  handleMaskTypeChange,
}) => {
  return (
    <div className="bnrimg_area">
      <strong className="subtit_bnrimg">이미지 유형</strong>
      <div className="bnrimg_con">
        <RadioGroup
          Component="ul"
          selectedValue={maskType}
          onChange={handleMaskTypeChange}
          name="radioGroup|imageMask"
          className="reform_list_radiocheck reform_list_float">
          {maskOptions.map(v => (
            <li key={v.id}>
              <Radio
                label={v.label}
                value={v.id}
                id={v.id}
                disabled={!v.available}
              />
            </li>
          ))}
        </RadioGroup>
      </div>
    </div>
  )
}

BizBoardBannerImageMaskSelect.propTypes = {
  maskOptions: PropTypes.array,
  maskType: PropTypes.string,
  handleMaskTypeChange: PropTypes.func,
}

export default BizBoardBannerImageMaskSelect
