import * as React from 'react'
import { LoaderIcon, toast, ToastBar, useToaster } from 'react-hot-toast'
import {
  TOAST_DURATION_ERROR,
  TOAST_DURATION_SUCCESS,
} from '../../utils/alertUtils'
import PropTypes from 'prop-types'
import { usePrevious } from '../../utils/hook/usePrevious'
import { take } from 'lodash'
import cx from 'classnames'
import { MomentToasterUtils } from './momentToasterUtils'

const TOASTER_CAPACITY = 10
const TOASTER_POSITION = 'bottom-right'
const TOASTER_GUTTER = 10
const TOASTER_BUTTON_GROUP_CLASSNAME = 'bnt_toastdash'

const MomentToaster = () => {
  const { toasts: momentToasts, handlers: momentToastHandlers } = useToaster({
    style: {
      padding: '30px 20px',
      borderRadius: '4px',
      boxShadow: '0 2px 8px rgba(0,0,0,.40)',
      background: '#FFF',
      fontSize: '14px',
      lineHeight: '20px',
      color: '#111',
      minWidth: '269px',
      maxWidth: '385px',
    },
    success: {
      duration: TOAST_DURATION_SUCCESS,
      iconTheme: {
        primary: '#326EDC',
      },
    },
    error: {
      duration: TOAST_DURATION_ERROR,
      iconTheme: {
        primary: '#F51045',
      },
    },
    loading: {
      iconTheme: {
        primary: '#326EDC',
        secondary: '#E0E0E0',
      },
    },
  })

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 9999,
        inset: 16,
        pointerEvents: 'none',
      }}
      onMouseEnter={momentToastHandlers.startPause}
      onMouseLeave={momentToastHandlers.endPause}>
      {take(momentToasts, TOASTER_CAPACITY).map(momentToast => {
        return (
          <MomentToastBar
            key={momentToast.id}
            momentToast={momentToast}
            calculateOffset={momentToastHandlers.calculateOffset}
            updateHeight={momentToastHandlers.updateHeight}
          />
        )
      })}
    </div>
  )
}

/**
 * @param momentToast {object} Toast
 * @param calculateOffset {function} (toast, opt) => number
 * @param updateHeight {function} (toastId, height) => void
 */
const MomentToastBar = ({ momentToast, calculateOffset, updateHeight }) => {
  const ref = React.useRef()

  const prevMomentToast = usePrevious(momentToast)

  const offset = calculateOffset(momentToast, {
    reverseOrder: false,
    gutter: TOASTER_GUTTER,
    defaultPosition: TOASTER_POSITION,
  })

  React.useLayoutEffect(() => {
    if (ref.current && !momentToast.height) {
      updateHeight(momentToast.id, ref.current.offsetHeight)
    }
  }, [momentToast.height, momentToast.id, updateHeight])

  React.useLayoutEffect(() => {
    /**
     * `INVALIDATE HEIGHT`
     * loading -> (success | error | etc) 로 상태가 변경된 후,
     * 변경된 height 가 offset 에 반영되지 않아 포지션이 겹치는 문제가 있다.
     * 변경된 height 를 얻어내 offset 을 갱신할 수 있도록 한다(react-hot-toast 자체적으로 기능 제공되지 않고 있어 커스텀하게 구현).
     */
    if (
      ref.current &&
      prevMomentToast?.type === 'loading' &&
      prevMomentToast.type !== momentToast?.type
    ) {
      updateHeight(momentToast.id, ref.current.offsetHeight)
    }
  }, [prevMomentToast, momentToast, updateHeight])

  /**
   * `INVALIDATE HEIGHT` 에 따라 갱신된 offset 을 기준으로 animated position style 을 갱신한다.
   */
  const positionStyle = MomentToasterUtils.getPositionStyle({
    position: TOASTER_POSITION,
    offset,
  })

  return (
    <div key={momentToast.id} ref={ref} style={positionStyle}>
      <ToastBar toast={momentToast} position={TOASTER_POSITION}>
        {({ icon, message }) => {
          const isLoading = momentToast.type === 'loading'

          return (
            <div
              className={cx('layer_toastdash', {
                toastdash_btn: MomentToasterUtils.hasButton(message),
              })}>
              {isLoading ? <MomentToasterLoaderIcon /> : icon}
              {message}
              {!isLoading && (
                <a
                  className="btn_close"
                  onClick={() => toast.dismiss(momentToast.id)}>
                  <span className="ico_comm">닫기</span>
                </a>
              )}
            </div>
          )
        }}
      </ToastBar>
    </div>
  )
}

MomentToastBar.propTypes = {
  momentToast: PropTypes.shape({
    type: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    height: PropTypes.number,
  }).isRequired,
  calculateOffset: PropTypes.func.isRequired,
  updateHeight: PropTypes.func.isRequired,
}

const MomentToasterButton = ({
  primary = true,
  label = '확인',
  style = {},
  onClick = () => undefined,
}) => {
  return (
    <button
      type="button"
      className={cx('btn_gs', {
        gs_bl: primary,
      })}
      style={style}
      onClick={onClick}>
      <span className="inner_g">{label}</span>
    </button>
  )
}

MomentToasterButton.propTypes = {
  primary: PropTypes.bool,
  label: PropTypes.string,
  style: PropTypes.object,
  onClick: PropTypes.func,
}

/**
 * 디자인 반영을 위한 react-hot-toast 의 LoaderIcon 스타일 재정의
 */
const MomentToasterLoaderIcon = () => {
  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minWidth: '20px',
        minHeight: '20px',
      }}>
      <LoaderIcon
        style={{
          width: '20px',
          height: '20px',
          border: '3px solid',
          borderColor: '#E0E0E0',
          borderRightColor: '#326EDC',
        }}
      />
    </div>
  )
}

export { TOASTER_BUTTON_GROUP_CLASSNAME, MomentToaster, MomentToasterButton }
