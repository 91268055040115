import { Map } from 'immutable'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../message/messageFormValidationKey'

const MESSAGE_CREATIVE_FORM_VALIDATION_KEY_PATH = Map({
  [MESSAGE_FORM_VALIDATION_KEY.NAME]: ['name'],
  [MESSAGE_FORM_VALIDATION_KEY.TITLE]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]: [
    'messageElement',
    'buttonAssetGroups',
  ],
  [MESSAGE_FORM_VALIDATION_KEY.COUPON_BOOK_ASSET_GROUPS]: [
    'messageElement',
    'couponBookAssetGroups',
  ],
  [MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.DESCRIPTION]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.ITEM]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.VIDEO_TITLE]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_INTRO_LANDING]: ['messageElement'],
  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_MORE]: ['messageElement'],
})

export { MESSAGE_CREATIVE_FORM_VALIDATION_KEY_PATH }
