import { keyMirror } from '../utils/utils'
import AudienceTypeEnum from './AudienceTypeEnum'

export default {
  Type: keyMirror({
    // 데모그래픽
    AGE: null,
    GENDER: null,
    LOCATION: null,

    // 맞춤타겟 > 내데이터
    COHORT: null,
    PIXEL_AND_SDK: null,
    KAKAO_USER: null,
    CUSTOMER_FILE: null,
    FRIEND_GROUP: null,

    // 맞춤타겟 > 추가 설정
    CATEGORY: null,
  }),

  Name: {
    AGE: '나이',
    GENDER: '성별',
    LOCATION: '지역',

    COHORT: '광고반응타겟',
    PIXEL_AND_SDK: '픽셀 & SDK',
    KAKAO_USER: '카카오 사용자',
    CUSTOMER_FILE: '고객파일',
    FRIEND_GROUP: '친구그룹',

    CATEGORY: '카테고리',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  valuesOfDemoGraphic() {
    return [this.Type.AGE, this.Type.GENDER, this.Type.LOCATION]
  },

  valuesOfMyData() {
    return [
      this.Type.COHORT,
      this.Type.PIXEL_AND_SDK,
      this.Type.KAKAO_USER,
      this.Type.CUSTOMER_FILE,
      this.Type.FRIEND_GROUP,
    ]
  },

  valuesOfMyDataByAudienceType(audienceType) {
    switch (audienceType) {
      case AudienceTypeEnum.Type.DISPLAY:
        return [
          this.Type.COHORT,
          this.Type.PIXEL_AND_SDK,
          this.Type.KAKAO_USER,
          this.Type.CUSTOMER_FILE,
        ]
      case AudienceTypeEnum.Type.MESSAGE:
        return [this.Type.COHORT, this.Type.KAKAO_USER, this.Type.FRIEND_GROUP]
      default:
        return []
    }
  },

  valuesOfAdditionalTargetData() {
    return [this.Type.CATEGORY]
  },

  valuesOfCustomTarget() {
    return this.valuesOfMyData().concat(this.valuesOfAdditionalTargetData())
  },
}
