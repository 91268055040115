import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    AdUnit: null,
    Setting: null,
    Targeting: null,
    PeriodUnit: null,
  }),

  Name: {
    AdUnit: '광고단위',
    Setting: '설정',
    Targeting: '타게팅',
    PeriodUnit: '기간',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
