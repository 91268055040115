import { coerceAtLeast } from './utils'
import { Range } from 'immutable'

/**
 * x, y 좌표를 기반으로 각도 계산.
 */
const calculateAngleByCoords = (x, y, positiveX = true, positiveY = true) => {
  const v1 = (Math.atan(y / x) * 180) / Math.PI
  const v2 = positiveY ? 90 - v1 : 90 + v1
  return positiveX ? v2 : 360 - v2
}

const pythagorean = (x, y) => Math.sqrt(x * x + y * y)

const calculateNextPageIndexBySize = (index, prevPageSize, nextPageSize) => {
  return Math.floor(
    prevPageSize > nextPageSize
      ? index * (prevPageSize / nextPageSize)
      : index / (nextPageSize / prevPageSize)
  )
}

const calculateMaximumAvailablePageIndex = (count, pageSize, minimum = 0) =>
  coerceAtLeast(Math.ceil(count / pageSize) - 1, minimum)

/**
 *
 * 최대 max 크기의 chunk array 로 count 를 균등 분할.
 *
 * max = 6
 [ { count: 0, chunks: [] },
 { count: 1, chunks: [ 1 ] },
 { count: 2, chunks: [ 2 ] },
 { count: 3, chunks: [ 3 ] },
 { count: 4, chunks: [ 4 ] },
 { count: 5, chunks: [ 5 ] },
 { count: 6, chunks: [ 6 ] },
 { count: 7, chunks: [ 4, 3 ] },
 { count: 8, chunks: [ 4, 4 ] },
 { count: 9, chunks: [ 5, 4 ] },
 { count: 10, chunks: [ 5, 5 ] },
 { count: 11, chunks: [ 6, 5 ] },
 { count: 12, chunks: [ 6, 6 ] },
 { count: 13, chunks: [ 5, 4, 4 ] },
 { count: 14, chunks: [ 5, 5, 4 ] },
 { count: 15, chunks: [ 5, 5, 5 ] },
 { count: 16, chunks: [ 6, 5, 5 ] },
 { count: 17, chunks: [ 6, 6, 5 ] },
 { count: 18, chunks: [ 6, 6, 6 ] },
 { count: 19, chunks: [ 5, 5, 5, 4 ] },
 { count: 20, chunks: [ 5, 5, 5, 5 ] },
 { count: 21, chunks: [ 6, 5, 5, 5 ] },
 { count: 22, chunks: [ 6, 6, 5, 5 ] },
 { count: 23, chunks: [ 6, 6, 6, 5 ] },
 { count: 24, chunks: [ 6, 6, 6, 6 ] },
 { count: 25, chunks: [ 5, 5, 5, 5, 5 ] },
 { count: 26, chunks: [ 6, 5, 5, 5, 5 ] },
 { count: 27, chunks: [ 6, 6, 5, 5, 5 ] },
 { count: 28, chunks: [ 6, 6, 6, 5, 5 ] },
 { count: 29, chunks: [ 6, 6, 6, 6, 5 ] } ]
 * @param count
 * @param max
 * @return {any[]|Array}
 */
const createChunkArray = (count, max) => {
  if (count <= 0) return []
  let size = 1
  if (count > max) {
    const remainder = Math.floor(count % max)
    const quotient = Math.floor(count / max)
    size = quotient + (remainder > 0 ? 1 : 0)
  }

  return Range()
    .take(count)
    .reduce((prev, v, i) => {
      const index = i % size
      prev[index] = prev[index] + 1
      return prev
    }, Array.from(Array(size).fill(0)))
}

const calculateAspectRatio = (originWidth, originHeight) => {
  // gcd: greatest common divisor
  const recursion = (w, h) =>
    h === 0
      ? { gcd: w, aspect: `${originWidth / w}:${originHeight / w}` }
      : recursion(h, w % h)

  return recursion(originWidth, originHeight)
}

export {
  calculateAngleByCoords,
  pythagorean,
  calculateNextPageIndexBySize,
  calculateMaximumAvailablePageIndex,
  createChunkArray,
  calculateAspectRatio,
}
