import { keyMirror } from '../../utils/utils'
import { fromJS, List, Set } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { ReportFilterUtils } from '../../components/ReportV2/Common/reportFilterUtils'
import ReportFilterEnum from '../../enums/ReportFilterEnum'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import { setReportChanged } from './mReportDetail'

const ReportFilter = keyMirror(
  {
    SET_QUERY: null,
    SET_FILTER: null,

    UPDATE_FILTER_CONDITION: null,
    UPDATE_FILTER_SUGGEST_CONDITION: null,

    DELETE_FILTER_CONDITIONS: null,
    DELETE_FILTER_CONDITION_BY_FILTER_TYPE: null,

    CLEAR_FILTER_CONDITIONS: null,
  },
  'REPORT_FILTER'
)

const initialState = fromJS({
  query: '',
  filterConditions: [
    {
      adUnitType: DashboardEnumV2.Type.CAMPAIGN,
      filterType: ReportFilterEnum.Filter.Type.CAMPAIGN_METRIC,
      filterSubType: 'cost',
      filterOperator: ReportFilterEnum.Operator.Type.GE,
      filterValue: [{ min: '1', max: '' }],
    },
  ],
})

export default createReducer(initialState, {
  [ReportFilter.SET_QUERY]: (state, { query }) => {
    return state.set('query', query)
  },

  [ReportFilter.SET_FILTER]: (state, { filterConditions }) => {
    return state.set(
      'filterConditions',
      List.isList(filterConditions)
        ? filterConditions
        : fromJS(filterConditions)
    )
  },

  [ReportFilter.UPDATE_FILTER_CONDITION]: (
    state,
    { index, filterCondition }
  ) => {
    const newFilterCondition = fromJS(filterCondition)

    const alreadyAddedIndex =
      index ??
      state
        .get('filterConditions')
        .findIndex(
          v =>
            ReportFilterUtils.Common.uniqueConditionKey(v) ===
            ReportFilterUtils.Common.uniqueConditionKey(newFilterCondition)
        )

    return alreadyAddedIndex >= 0
      ? state.setIn(['filterConditions', alreadyAddedIndex], newFilterCondition)
      : state.update('filterConditions', prevConditions =>
          prevConditions.push(newFilterCondition)
        )
  },

  [ReportFilter.UPDATE_FILTER_SUGGEST_CONDITION]: (
    state,
    { filterCondition }
  ) => {
    const newFilterCondition = fromJS(filterCondition)
    const { filterType } = newFilterCondition

    const alreadyAddedIndex = state
      .get('filterConditions')
      .findIndex(
        v =>
          ReportFilterUtils.Common.uniqueConditionKey(v) ===
          ReportFilterUtils.Common.uniqueConditionKey(newFilterCondition)
      )

    const alreadyAddedCondition =
      alreadyAddedIndex >= 0
        ? state.getIn(['filterConditions', alreadyAddedIndex])
        : undefined

    return alreadyAddedIndex >= 0
      ? state.update('filterConditions', prev =>
          prev
            .filter((_, index) => index !== alreadyAddedIndex)
            .push(
              filterType === ReportFilterEnum.Filter.Type.NAME ||
                ReportFilterUtils.Common.isMetricFilter({ filterType })
                ? newFilterCondition
                : alreadyAddedCondition.mergeWith((valueOld, valueNew, key) => {
                    if (key === 'filterValue') {
                      const maxCount =
                        ReportFilterUtils.Common.maxCountOfFilterValueArray(
                          newFilterCondition
                        )
                      const list = Set(valueOld.concat(valueNew)).toList()
                      return maxCount >= 0 ? list.take(maxCount) : list
                    }

                    return valueNew
                  }, newFilterCondition)
            )
        )
      : state.update('filterConditions', prev => prev.push(newFilterCondition))
  },

  [ReportFilter.DELETE_FILTER_CONDITIONS]: (state, { index }) => {
    return state.update('filterConditions', prev => prev.delete(index))
  },

  [ReportFilter.DELETE_FILTER_CONDITION_BY_FILTER_TYPE]: (
    state,
    { filterTypes }
  ) => {
    return state.update('filterConditions', prev =>
      prev.filter(
        ({ filterType: prevFilterType }) =>
          !filterTypes.includes(prevFilterType)
      )
    )
  },

  [ReportFilter.CLEAR_FILTER_CONDITIONS]: () => {
    return initialState
  },
})

export const setReportFilterQuery = query => ({
  type: ReportFilter.SET_QUERY,
  query,
})

export const clearReportFilter = () => ({
  type: ReportFilter.CLEAR_FILTER_CONDITIONS,
})

export const updateReportFilterCondition = ({ index, filterCondition }) => {
  return dispatch => {
    dispatch({
      type: ReportFilter.UPDATE_FILTER_CONDITION,
      index,
      filterCondition,
    })
    dispatch(setReportChanged())
  }
}

export const updateReportFilterSuggestCondition = ({ filterCondition }) => {
  return dispatch => {
    dispatch({
      type: ReportFilter.UPDATE_FILTER_SUGGEST_CONDITION,
      filterCondition,
    })

    dispatch(setReportFilterQuery(''))
  }
}

export const deleteReportFilter = index => ({
  type: ReportFilter.DELETE_FILTER_CONDITIONS,
  index,
})

export const deleteReportFilterByDimension = filterTypes => ({
  type: ReportFilter.DELETE_FILTER_CONDITION_BY_FILTER_TYPE,
  filterTypes,
})

export const initReportFilterConditions = filterConditions => ({
  type: ReportFilter.SET_FILTER,
  filterConditions,
})
