import axios from 'axios'
import { Map } from 'immutable'
import { checkEmpty } from '../regexUtils'

let cancelSources = Map()

/**
 * action param 의 key 로 cancelSource 생성, 적재, 리턴
 * - 동일 key 로 적재된 cancelSource 가 있다면 (requested), cancel() 후 제거.
 *
 * @param key
 */
export function createCancellation(key) {
  if (checkEmpty(key)) return null

  cancelRequest(key)

  const cancelToken = axios.CancelToken
  const source = cancelToken.source()
  cancelSources = cancelSources.set(key, source)
  return source
}

export function cancelRequest(key) {
  if (checkEmpty(key)) return

  const source = cancelSources.get(key)

  if (typeof source?.cancel === 'function') {
    source.cancel()
  }
}

/**
 * action param 의 key 로 적재된 cancelSource 를 찾아 cancel() 후 제거.
 * @param source
 */
export function deleteCancellation(source) {
  if (typeof source?.cancel === 'function') {
    source.cancel()
    cancelSources = cancelSources.filter(v => v !== source)
  }
}
