import memoizeOne from 'memoize-one'
import { List, Map } from 'immutable'
import DemoGraphicEnum from '../../../../enums/DemoGraphicEnum'
import { AdGroupHelper } from '../../../../utils/helper/helper-adGroup'
import {
  ADDITIONAL_TARGET_KEY,
  CATEGORY_TARGET_KEY,
  COMBINED_POPULATION_MAX_SCORE,
  getAdditionalDataTargetingValue,
  getMyDataTargetingValue,
  getPopulationAdditionalString,
  MY_DATA_TARGET_KEY,
} from '../../../../utils/advertise/targeting'
import TargetingInclusionEnum from '../../../../enums/TargetingInclusionEnum'
import { checkNotEmpty, isUndefinedOrNull } from '../../../../utils/regexUtils'
import { keyMirror } from '../../../../utils/utils'
import AudienceStatusEnum from '../../../../enums/AudienceStatusEnum'
import { NumberUtils } from '../../../../utils/numberUtils'
import DemoGraphicAgeEnum from '../../../../enums/DemoGraphicAgeEnum'

export const AUDIENCE_TARGET = keyMirror({
  AUDIENCE: null,
  AD_GROUP: null,
})

export const audienceCustomTargetKeys = Object.keys({
  ...MY_DATA_TARGET_KEY,
  ...ADDITIONAL_TARGET_KEY,
})

export const audienceDemographicKeys = ['genders', 'fiveAges', 'locations']

export const audienceTotalTargetingKeys = audienceCustomTargetKeys.concat(
  audienceDemographicKeys
)

export const memoIsShowAudienceCustomTargetToolTip = memoizeOne(audienceForm =>
  audienceCustomTargetKeys.some(k => audienceForm.get(k).size > 0)
)

export const memoAudiencePopulationText = memoizeOne(
  (populationScore, audienceForm) => {
    const score =
      populationScore >= COMBINED_POPULATION_MAX_SCORE
        ? COMBINED_POPULATION_MAX_SCORE
        : populationScore

    const numText = getPopulationAdditionalString(score)
    const isWaitingTargetSelected =
      getMyDataTargetReadyStatusIncludedChecker(audienceForm)

    return isUndefinedOrNull(populationScore)
      ? '조회실패'
      : isWaitingTargetSelected
      ? '준비중'
      : `${NumberUtils.withCommas(score)} ${numText}`
  }
)

export const getMyDataTargetReadyStatusIncludedChecker = (
  audienceForm = Map()
) =>
  List(Object.keys(MY_DATA_TARGET_KEY))
    .flatMap(k => audienceForm.get(k))
    .some(v =>
      [
        AudienceStatusEnum.Type.STANDBY,
        AudienceStatusEnum.Type.WAITING,
      ].includes(v.get('status'))
    )

export const getAudienceDetailDefaultText = target => {
  return `${target.get('name')} - ${TargetingInclusionEnum.getName(
    target.get('inclusionType')
  )}`
}

const getBasicTextByTargetings = targets => {
  return targets?.size > 0
    ? targets.map(v => getAudienceDetailDefaultText(v)).join(', ')
    : ''
}

export const getAudienceDetailTrackerTargetText = target => {
  const { trackRuleName, term = 0, inclusionType } = target || {}
  return `${trackRuleName} (최근 ${term}일) - ${TargetingInclusionEnum.getName(
    inclusionType
  )}`
}

const getCategoryTargetText = (type, target, idLabelMap) => {
  return target
    .filter(k => idLabelMap.get(type).get(k))
    .map(k => idLabelMap.get(type).get(k))
    .join(', ')
}

const getCategoryTargetSplitText = (type, target, idLabelMap) => {
  const data = target.take(5)
  const etcSize = target.size - data.size
  const text = getCategoryTargetText(type, data, idLabelMap)
  const etcText = etcSize > 0 ? `외 ${etcSize}건` : ''

  return checkNotEmpty(text) ? `${text} ${etcText}` : ''
}

export const memoCohortTargetDetailText = memoizeOne(targeting => {
  const { cohortTargetings } = targeting || {}
  return getBasicTextByTargetings(cohortTargetings)
})

export const memoTrackerTargetDetailText = memoizeOne(targeting => {
  const { trackerTargetings } = targeting || {}
  return trackerTargetings?.size > 0
    ? trackerTargetings
        .map(v => getAudienceDetailTrackerTargetText(v))
        .join(', ')
    : ''
})

export const memoKakaoUserTargetDetailText = memoizeOne(targeting => {
  const { plusFriendTargetings } = targeting || {}
  return getBasicTextByTargetings(plusFriendTargetings)
})

export const memoSyncAppTargetDetailText = memoizeOne(targeting => {
  const { syncAppTargetings } = targeting || {}
  return getBasicTextByTargetings(syncAppTargetings)
})

export const memoCustomerFileTargetDetailText = memoizeOne(targeting => {
  const { customerFileTargetings } = targeting || {}
  return getBasicTextByTargetings(customerFileTargetings)
})

export const memoPlusFriendGroupTargetDetailText = memoizeOne(targeting => {
  const { plusFriendGroupTargetings } = targeting || {}
  return getBasicTextByTargetings(plusFriendGroupTargetings)
})

export const memoUfoInterestsTargetSplitText = memoizeOne(
  (targeting, idLabelMap) => {
    const { ufoInterests } = targeting || {}
    return getCategoryTargetSplitText(
      CATEGORY_TARGET_KEY.interests,
      ufoInterests,
      idLabelMap
    )
  }
)

export const memoUfoBusinessTargetSplitText = memoizeOne(
  (targeting, idLabelMap) => {
    const { ufoBusinessTypes } = targeting || {}
    return getCategoryTargetSplitText(
      CATEGORY_TARGET_KEY.business,
      ufoBusinessTypes,
      idLabelMap
    )
  }
)

export const memoAudienceDemographicGenderText = memoizeOne(targeting => {
  const { genders } = targeting || {}
  return DemoGraphicEnum.Gender.getGendersDetailOptionsValues(genders)
})

export const memoAudienceDemographicAgeText = memoizeOne(targeting => {
  const { fiveAges } = targeting || {}
  return DemoGraphicAgeEnum.getAgesDetailOptionsValues(fiveAges)
})

export const memoAudienceDemographicLocationText = memoizeOne(targeting => {
  const { locations } = targeting || {}
  const selectedLocationType = AdGroupHelper.Location.getSelectedType(targeting)

  return DemoGraphicEnum.Location.getLocationDetailOptionValues(
    selectedLocationType,
    locations
  )
})

export const memoAudienceSelectedTargetSummary = memoizeOne(
  (targeting, isIncludeDemographic) => {
    const myData = getMyDataTargetingValue(targeting)
    const additionalData = getAdditionalDataTargetingValue(targeting)

    return myData
      .concat(additionalData)
      .concat(isIncludeDemographic ? '데모그래픽' : '')
      .filter(v => checkNotEmpty(v))
      .join(', ')
  }
)

export const memoAudienceObjectiveTypeCheckInAdGroups = memoizeOne(
  (adGroups, objectiveType) =>
    adGroups
      ?.map(v => v.get('objective'))
      ?.groupBy(v => v.get('type'))
      ?.keySeq()
      ?.includes(objectiveType) || false
)

export const checkIsInAudiencePath = location => {
  const { pathname: pathName = '' } = location || {}

  return (
    pathName?.split('/')?.some(p => ['targeting', 'audience'].includes(p)) ||
    false
  )
}
