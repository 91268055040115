import React from 'react'
import PropTypes from 'prop-types'
import Gnb from '../Navigation/Gnb/Gnb'
import Footer from '../Footer/Footer'

const resolveValueOrFn = valueOrFn =>
  typeof valueOrFn === 'function' ? valueOrFn() : valueOrFn

const StaticPageBase = ({
  hasKakaoWrap = false,
  accessibility = '',
  titleValueOrFn = () => null,
  descriptionValueOrFn = () => null,
  buttonGroupValueOrFn = () => null,
}) => {
  const Base = () => (
    <div className="error_wrap2">
      <div className="inner_error">
        <strong className="tit_error">
          {resolveValueOrFn(titleValueOrFn)}
        </strong>
        <p className="txt_error">{resolveValueOrFn(descriptionValueOrFn)}</p>
        {buttonGroupValueOrFn && (
          <div className="page_btn">
            <div className="inner_btn">
              {resolveValueOrFn(buttonGroupValueOrFn)}
            </div>
          </div>
        )}
      </div>
    </div>
  )

  return hasKakaoWrap ? (
    <div id="kakaoWrap" className="layout_error">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <article id="mArticle">
          <h2 id="#kakaoBody" className="screen_out">
            {accessibility}
          </h2>
          <Base />
        </article>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  ) : (
    <Base />
  )
}

StaticPageBase.propTypes = {
  hasKakaoWrap: PropTypes.bool,
  accessibility: PropTypes.string,
  titleValueOrFn: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired,
  descriptionValueOrFn: PropTypes.oneOfType([PropTypes.node, PropTypes.func])
    .isRequired,
  buttonGroupValueOrFn: PropTypes.oneOfType([PropTypes.node, PropTypes.func]),
}

export default StaticPageBase
