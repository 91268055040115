import React, { useState } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { usePrevious } from '../../../utils/hook/usePrevious'
import isPassiveSupported from '../../../utils/isPassiveSupported'

export const ConversionCriteriaType = {
  DAY: 'DAY',
  WEEK: 'WEEK',
}

export const ConversionCriteriaKey = {
  DAY: '1d',
  WEEK: '7d',
}

export const ConversionCriteriaText = {
  DAY: '1일',
  WEEK: '7일',
}

const ConversionCriteriaRadioOption = Object.keys(ConversionCriteriaType).map(
  type => ({
    label: ConversionCriteriaText[type],
    value: type,
  })
)

const ChangeConversionCriteriaPopup = React.forwardRef(
  (
    {
      positionTargetNode, // 팝업이 위치할 대상 dom
      conversionCriteria,
      onChange,
      onClose,
    },
    forwardedRef
  ) => {
    const rootRef = React.useRef()

    const prevPositionTargetNode = usePrevious(positionTargetNode)

    const invalidatePosition = React.useCallback(() => {
      if (rootRef.current && positionTargetNode) {
        const positionTargetRect = positionTargetNode.getBoundingClientRect()

        rootRef.current.style.position = 'fixed'
        rootRef.current.style.left = `${positionTargetRect.left}px`
        rootRef.current.style.top = `${positionTargetRect.bottom}px`
      }
    }, [positionTargetNode])

    React.useLayoutEffect(() => {
      invalidatePosition()
    }, [invalidatePosition])

    const [selectedConversionCriteria, setSelectedConversionCriteria] =
      useState(conversionCriteria)

    React.useEffect(() => {
      // 명시적으로 닫히지 않고 대상이 변경된 경우 conversionCriteria 초기화.
      if (
        prevPositionTargetNode &&
        prevPositionTargetNode !== positionTargetNode
      ) {
        setSelectedConversionCriteria(conversionCriteria)
      }
    }, [conversionCriteria, positionTargetNode, prevPositionTargetNode])

    React.useLayoutEffect(() => {
      const onClick = e => {
        if (!document.body) return

        const rootNode = rootRef.current

        let targetNode = e.target

        while (targetNode) {
          if (rootNode === targetNode) return
          if (rootNode !== targetNode) {
            targetNode = targetNode.parentNode
          }
        }

        onClose()
      }

      document.addEventListener(
        'scroll',
        invalidatePosition,
        isPassiveSupported ? { capture: false, passive: true } : false
      )
      document.addEventListener('click', onClick, true)

      return () => {
        document.removeEventListener('click', onClick, true)
        document.removeEventListener('scroll', invalidatePosition)
      }
    }, [onClose, invalidatePosition])

    // parent -> child controller
    React.useImperativeHandle(
      forwardedRef,
      () => ({
        invalidatePosition,
      }),
      [invalidatePosition]
    )

    const handleChange = React.useCallback(e => {
      const value = e.currentTarget.value ?? ConversionCriteriaType.WEEK
      setSelectedConversionCriteria(value)
    }, [])

    const handleConfirm = React.useCallback(() => {
      onChange(selectedConversionCriteria)
      onClose()
    }, [onChange, onClose, selectedConversionCriteria])

    return (
      <div ref={rootRef} className="layer_day">
        <div className="inner_layer">
          <strong className="tit_day">전환 지표 기준 변경</strong>
          <p className="txt_day">전환 지표 확인 기준을 변경할 수 있습니다.</p>
          <div className="box_day">
            <em className="subtit_day">기준 설정</em>
            {ConversionCriteriaRadioOption.map(item => {
              return (
                <span
                  key={item.label}
                  className={cx('box_radioinp', {
                    on: selectedConversionCriteria === item.value,
                  })}>
                  <input
                    type="radio"
                    name="inpArea101"
                    id={`inpArea101|${item.label}`}
                    className="inp_radio"
                    value={item.value}
                    checked={selectedConversionCriteria === item.value}
                    onChange={handleChange}
                  />
                  <label
                    htmlFor={`inpArea101|${item.label}`}
                    className="lab_radio">
                    <span className="ico_comm ico_radio" />
                    {item.label}
                  </label>
                </span>
              )
            })}
          </div>
          <ul className="list_day">
            <li>1일: 클릭 후 24시간 동안 수집된 전환 지표</li>
            <li>7일: 클릭 후 7일 동안 수집된 전환 지표</li>
          </ul>
          <div className="btn_group">
            <a onClick={onClose} className="btn_gm">
              <span className="inner_g">취소</span>
            </a>
            <a onClick={handleConfirm} className="btn_gm gm_bl">
              <span className="inner_g">확인</span>
            </a>
          </div>
        </div>
      </div>
    )
  }
)

ChangeConversionCriteriaPopup.displayName = 'ChangeConversionCriteriaPopup'
ChangeConversionCriteriaPopup.propTypes = {
  positionTargetNode: PropTypes.object.isRequired,
  conversionCriteria: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ChangeConversionCriteriaPopup
