import loungeBoardFetchUtils, {
  SERVICE_TYPE,
} from '../../utils/loungeBoardFetchUtils'

const { get } = loungeBoardFetchUtils

export const loungeBoardAPI = {
  // 공지사항 목록 조회
  getNoticeList: () =>
    get(`/api/public/bulletin/list?serviceType=${SERVICE_TYPE}`),

  // 띠배너 노출 공지사항 조회
  getNoticeBanner: () =>
    get(`/api/public/bulletin/banner?serviceType=${SERVICE_TYPE}`),
}
