import React from 'react'
import moment from 'moment'
import { Range } from 'immutable'
import PropTypes from 'prop-types'
import { isUndefinedOrNull } from '../../../utils/regexUtils'

/**
 *  기준값의 배수로 분의 단위를 맞춤.
 *    ex) 5분단위로 맞출 경우 분을 5의 배수로 맞춤
 *      - 09:21 --> 09:25
 *      - 12:37 --> 12:40
 *      - 15:05 --> 15:10
 *      - 22:59 --> 23:00
 *
 * @param baseTime
 * @param defaultHour
 * @param defaultMinute
 * @returns {moment.Moment}
 */
export const replaceCurrentTimeToBaseTime = (
  baseTime,
  defaultHour = moment().hour(),
  defaultMinute = moment().minute()
) => {
  if (!baseTime) return moment({ hour: defaultHour, minute: defaultMinute })

  const result = defaultMinute + baseTime - (defaultMinute % baseTime)
  const isNextTime = result >= 60
  const hours = defaultHour > 23 ? 0 : defaultHour + (isNextTime ? 1 : 0)
  const minute = isNextTime ? 0 : result

  return moment({ hours, minute })
}

/**
 * time, minute specific range
 *  - [min, max]
 *  - [max]
 * @param range
 * @returns {Array|*}
 */
export const getTimeRangeArray = range => {
  if (!range || range.length === 0) return []

  if (range.length === 1) {
    return Range().take(range[0]).toJS()
  }

  const [min, max] = range
  return Range(min, max).toJS()
}

const replaceTimeIdToString = (value = 0) => {
  return String(value < 10 ? `0${value}` : value)
}

const getTimeRangeItems = (
  range,
  baseTime,
  labelSuffix,
  activeFilteredItem
) => {
  return getTimeRangeArray(range)
    .filter(
      v =>
        !(
          !isUndefinedOrNull(baseTime) && // 기준 시간으로 항목 필터링 옵션 사용시 baseTime 도 필수로 넣어줄것.
          activeFilteredItem &&
          v % baseTime !== 0
        )
    )
    .map(v => {
      const value = replaceTimeIdToString(v)
      return { id: value, label: `${value}${labelSuffix}`, value }
    })
}

const getSelectedItemIndex = (items, defaultValue) => {
  return items.findIndex(v => v.value === defaultValue)
}

const getCurrentSelectedDateTime = (
  baseTime,
  defaultValue,
  defaultValuesRange,
  replaceTime,
  dateTimeKey // hour or minute
) => {
  return !isUndefinedOrNull(defaultValue) &&
    Range().take(defaultValuesRange[1]).includes(defaultValue)
    ? replaceTimeIdToString(
        baseTime ? replaceTime.get(dateTimeKey) : defaultValue
      )
    : null
}

/**
 * USAGE 시/분 선택 컴포넌트 사용법
 *
 *  <HourSelector
      active={ true | false }       // 선택항목 비활성화시 false (기본값 true)
      subClassName=""               // OptionSelectList 에 넣어줄 className
      labelSuffix="시"               // 미입력시 기본값 공백
      defaultHour={3}               // 시간의 기본 값을 3시로 설정. 0 ~ 23 이내의 값만 넣도록 타입 강제함
      defaultMinute={50}            // 분의 기본 값을 50분으로 설정. 0 ~ 59 이내의 값만 넣도록 타입 강제함
      defaultHourRange={ [0, 24] }  // 시간의 범위를 [max] 또는 [min, max]로 설정.
      baseTime={5}                  // 5의 배수 단위로 분의 값을 설정하고, 선택 항목도 5의 배수로 필터링
      handleHourChange={handleChange}   // 시간 선택값 콜백
    />
 
    <MinuteSelector
      active={ true | false }         // 선택항목 비활성화시 false (기본값 true)
      subClassName=""                 // OptionSelectList 에 넣어줄 className
      labelSuffix="분"                 // 미입력시 기본값 공백
      defaultHour={3}                 // 시간의 기본 값을 3시로 설정. 0 ~ 23 이내의 값만 넣도록 타입 강제함
      defaultMinute={50}              // 분의 기본 값을 50분으로 설정. 0 ~ 59 이내의 값만 넣도록 타입 강제함
      defaultMinuteRange={ [0, 60] }  // 분의 범위를 [max] 또는 [min, max]로 설정.
      baseTime={5}                    // 5의 배수 단위로 분의 값을 설정하고, 선택 항목도 5의 배수로 필터링
      handleMinuteChange={handleChange}   // 시간 선택값 콜백
    />
 *
 * @param Selector
 * @returns {function(*): *}
 * @constructor
 */
const HourMinuteSelectorHOC = Selector => props => {
  const {
    defaultHour,
    defaultMinute,
    baseTime, // 기준 시간으로 세팅. 기본값 따로 안줄 경우 1분 단위로 동작
    defaultHourRange = [0, 24], // 시간의 디폴트 범위 0 ~ 24
    defaultMinuteRange = [0, 60], // 분의 디폴트 범위 0 ~ 60
    labelSuffix = '',
    activeFilteredItemByBaseTime = false, // 기준 시간으로 세팅할 경우, 선택 가능 항목도 기준시간의 배수로 필터링 할것인지 옵션
    onHourChange = () => {},
    onMinuteChange = () => {},
  } = props

  const hourArrayItems = getTimeRangeItems(
    defaultHourRange,
    baseTime,
    labelSuffix
  )

  const minuteArrayItems = getTimeRangeItems(
    defaultMinuteRange,
    baseTime,
    labelSuffix,
    activeFilteredItemByBaseTime
  )

  const replaceTime = replaceCurrentTimeToBaseTime(
    baseTime,
    defaultHour,
    defaultMinute
  )

  const selectedHour = getCurrentSelectedDateTime(
    baseTime,
    defaultHour,
    defaultHourRange,
    replaceTime,
    'hour'
  )

  const selectedMinute = getCurrentSelectedDateTime(
    baseTime,
    defaultMinute,
    defaultMinuteRange,
    replaceTime,
    'minute'
  )

  const selectedHourIndex = getSelectedItemIndex(hourArrayItems, selectedHour)
  const selectedMinuteIndex = getSelectedItemIndex(
    minuteArrayItems,
    selectedMinute
  )

  const handleHourChange = ({ value }) => {
    onHourChange(value)
  }

  const handleMinuteChange = ({ value }) => {
    onMinuteChange(value)
  }

  return (
    <Selector
      {...props}
      hourArrayItems={hourArrayItems}
      minuteArrayItems={minuteArrayItems}
      selectedHourIndex={selectedHourIndex}
      selectedMinuteIndex={selectedMinuteIndex}
      handleHourChange={handleHourChange}
      handleMinuteChange={handleMinuteChange}
    />
  )
}

HourMinuteSelectorHOC.propTypes = {
  labelSuffix: PropTypes.string,
  baseTime: PropTypes.number,
  activeFilteredItemByBaseTime: PropTypes.bool,
  defaultHour: PropTypes.oneOf(getTimeRangeArray([0, 24])).isRequired,
  defaultMinute: PropTypes.oneOf(getTimeRangeArray([0, 60])).isRequired,
  onHourChange: PropTypes.func,
  onMinuteChange: PropTypes.func,
}

export default HourMinuteSelectorHOC
