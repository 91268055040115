import React from 'react'
import { useDispatch } from 'react-redux'
import {
  initBizBoardCreateImageData,
  setBizBoardCreateImageRect,
  setBizBoardCreateImageScaleValue,
  updateBizBoardCreateImageScaleValue,
} from '../../../../../modules/advertise/mBizBoardImageEditor'
import { usePrevious } from '../../../../../utils/hook/usePrevious'
import { BizBoardImageEditUtil } from '../bizBoardUtils'
import UserAgent from 'fbjs/lib/UserAgent'
import { isMobile } from 'react-device-detect'
import { assign, isEqual } from 'lodash'
import { useDoubleClick } from '../../../../../utils/hook/useDoubleClick'
import isPassiveSupported from '../../../../../utils/isPassiveSupported'

const initialDragPosition = {
  sx: 0,
  sy: 0,
  dx: 0,
  dy: 0,
}

const useBizBoardImageEdit = ({
  index,
  image,
  imageNodeRef,
  dragLayerRect,
  wheelSensitivity = 0.0001,
  customEventHandlerRef,
  storedImageNodePosition,
  editStateListener,
  handleTemplateChange,
  imageType,
  isNotPositionedRelativeToBanner,
}) => {
  const useTouchAction = UserAgent.isBrowser('IE') || isMobile

  const dispatch = useDispatch()

  const prevDragLayerRect = usePrevious(dragLayerRect)

  const prevStoredImageNodePosition = usePrevious(storedImageNodePosition)

  React.useEffect(() => {
    // 드래그 중 포인터가 영역 밖으로 나갈 시의 중단 처리.
    const onDragOver = e => e.preventDefault()

    document.addEventListener('dragover', onDragOver)

    return () => {
      document.removeEventListener('dragover', onDragOver)
    }
  }, [])

  const [draggable, setDraggable] = React.useState(false)
  const [dragPosition, setDragPosition] = React.useState(initialDragPosition)

  // for pointer
  const [isDragging, setIsDragging] = React.useState(false)
  const { onPointerUp, isDoubleClicked } = useDoubleClick()

  // drag 시작 시 image node 의 상대적 position.
  const [imageNodePosition, setImageNodePosition] = React.useState({
    top: isNotPositionedRelativeToBanner ? 0 : dragLayerRect.top,
    left: isNotPositionedRelativeToBanner ? 0 : dragLayerRect.left,
  })

  const restore = React.useCallback(() => {
    setDragPosition(initialDragPosition)

    if (image) {
      const { top, left, scale } = BizBoardImageEditUtil.getImageNodePosition({
        imageWidth: image.imageWidth,
        imageHeight: image.imageHeight,
        dragLayerRect,
        imageType,
        isNotPositionedRelativeToBanner,
      })

      setImageNodePosition({
        top,
        left,
      })

      dispatch(
        setBizBoardCreateImageRect(index, {
          imageNodePosition: {
            left,
            top,
          },
        })
      )

      dispatch(setBizBoardCreateImageScaleValue(index, scale))
    } else {
      setImageNodePosition({
        top: dragLayerRect.top,
        left: dragLayerRect.left,
      })

      dispatch(initBizBoardCreateImageData(index))
    }
  }, [
    dispatch,
    dragLayerRect,
    image,
    index,
    imageType,
    isNotPositionedRelativeToBanner,
  ])

  // dragLayerRect 변경 시 초기화
  React.useEffect(() => {
    if (prevDragLayerRect && !isEqual(prevDragLayerRect, dragLayerRect)) {
      restore()
    }
  }, [dragLayerRect, prevDragLayerRect, restore])

  // `editMode` SET stored imageNodePosition
  React.useEffect(() => {
    if (!prevStoredImageNodePosition && storedImageNodePosition) {
      setImageNodePosition(prev => ({ ...prev, ...storedImageNodePosition }))
    }
  }, [prevStoredImageNodePosition, storedImageNodePosition])

  // block screen touch(move)
  React.useEffect(() => {
    const listener = e => {
      if (draggable) {
        e.preventDefault()
      }
    }

    if (useTouchAction) {
      document.addEventListener('touchmove', listener)
    }

    return () => {
      if (useTouchAction) {
        document.removeEventListener('touchmove', listener)
      }
    }
  }, [draggable, useTouchAction])

  React.useEffect(() => {
    if (isDoubleClicked) {
      restore()
    }
  }, [isDoubleClicked, restore])

  const dragEventHandler = {
    draggable: true,
    onDragStart: e => {
      // do not preventDefault!!

      handleTemplateChange('imageIndex', index)

      editStateListener?.onDragStart?.()

      const { clientX, clientY } = e

      setDraggable(true)

      setDragPosition({ sx: clientX, sy: clientY, dx: 0, dy: 0 })

      if (imageNodeRef?.current) {
        const top = imageNodeRef.current.offsetTop
        const left = imageNodeRef.current.offsetLeft

        setImageNodePosition({
          top,
          left,
        })
      }
    },
    onDrag: e => {
      e.preventDefault()

      if (draggable) {
        editStateListener?.onDrag?.()

        const { clientX, clientY } = e

        setDragPosition(prev => ({
          ...prev,
          dx: clientX - prev.sx,
          dy: clientY - prev.sy,
        }))
      }
    },
    onDragEnd: e => {
      e.preventDefault()

      editStateListener?.onDragEnd?.()
    },
    onDragOver: e => {
      e.preventDefault()

      editStateListener?.onDragOver?.()
    },
    onDragEnter: e => {
      e.preventDefault()

      editStateListener?.onDragEnter?.()

      setDraggable(true)
    },
    onDragLeave: e => {
      e.preventDefault()

      editStateListener?.onDragLeave?.()

      setDraggable(false)
    },
  }

  const pointerEventHandler = {
    onPointerOver: () => {
      setDraggable(true)
    },
    onPointerDown: e => {
      if (draggable) {
        setIsDragging(true)

        const { clientX, clientY } = e

        setDragPosition({ sx: clientX, sy: clientY, dx: 0, dy: 0 })

        if (imageNodeRef?.current) {
          const top = imageNodeRef.current.offsetTop
          const left = imageNodeRef.current.offsetLeft

          setImageNodePosition({
            top,
            left,
          })
        }
      }
    },
    onPointerMove: e => {
      if (draggable && isDragging) {
        const { clientX, clientY } = e

        setDragPosition(prev => ({
          ...prev,
          dx: clientX - prev.sx,
          dy: clientY - prev.sy,
        }))
      }
    },
    onPointerLeave: () => {
      setIsDragging(false)
      setDraggable(false)
    },
    onPointerUp: e => {
      setIsDragging(false)

      onPointerUp(e)
    },
  }

  const commonEventHandler = {
    onWheel: e => {
      if (!isPassiveSupported) {
        e.preventDefault()
      }

      const { deltaY } = e
      const dy = deltaY * -wheelSensitivity

      editStateListener?.onWheel?.(dy)

      dispatch(updateBizBoardCreateImageScaleValue(index, prev => prev + dy))
    },
    onDoubleClick: e => {
      e.preventDefault()

      editStateListener?.onDoubleClick?.()

      restore()
    },
    onClick: e => {
      e.preventDefault()

      handleTemplateChange('imageIndex', index)

      editStateListener?.onClick?.()
    },
  }

  // for drag layer node(event delegate)
  const dragLayerEventHandler = assign(
    commonEventHandler,
    useTouchAction ? pointerEventHandler : dragEventHandler
  )

  // bind custom ui event handler
  React.useImperativeHandle(
    customEventHandlerRef,
    () => ({
      reset: () => {
        restore()
      },
    }),
    [restore]
  )

  return {
    imageTop: imageNodePosition.top + dragPosition.dy,
    imageLeft: imageNodePosition.left + dragPosition.dx,
    dragLayerEventHandler,
  }
}

export { useBizBoardImageEdit }
