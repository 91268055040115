import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPut,
} from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'

/**
 * 3.26 광고계정 활성/비활성
 */
function modifyAdAccountOnOff(adAccountId, active) {
  return RequestLock.acquire({
    key: 'modifyAdAccountOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/adAccounts/${adAccountId}/onOff`,
        { userConfig: active ? 'ON' : 'OFF' },
        adAccountId
      ).finally(done),
  })
}

/**
 * 4.2.11 캠페인 단일 활성/비활성
 */
function modifyCampaignOnOff(adAccountId, id, active) {
  return RequestLock.acquire({
    key: 'modifyCampaignOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/campaigns/${id}/onOff`,
        { userConfig: active ? 'ON' : 'OFF' },
        adAccountId
      ).finally(done),
  })
}

/**
 * 4.2.12 캠페인 다중 활성/비활성
 */
function modifyCampaignOnOffBulk(adAccountId, ids, active) {
  return RequestLock.acquire({
    key: 'modifyCampaignOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/campaigns/onOff`,
        { userConfig: active ? 'ON' : 'OFF', campaignIds: ids },
        adAccountId
      ).finally(done),
  })
}

/**
 * 4.3.7 광고그룹 단일 활성/비활성
 */
function modifyAdGroupOnOff(adAccountId, id, active) {
  return RequestLock.acquire({
    key: 'modifyAdGroupOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/adGroups/${id}/onOff`,
        { userConfig: active ? 'ON' : 'OFF' },
        adAccountId
      ).finally(done),
  })
}

/**
 * 4.3.8 광고그룹 다중 활성/비활성
 */
function modifyAdGroupOnOffBulk(adAccountId, ids, active) {
  return RequestLock.acquire({
    key: 'modifyAdGroupOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/adGroups/onOff`,
        {
          userConfig: active ? 'ON' : 'OFF',
          adGroupIds: ids,
        },
        adAccountId
      ).finally(done),
  })
}

/**
 * 5.9 소재 단일 활성/비활성
 */
function modifyCreativeOnOff(adAccountId, id, active) {
  return RequestLock.acquire({
    key: 'modifyCreativeOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/creatives/${id}/onOff`,
        { userConfig: active ? 'ON' : 'OFF' },
        adAccountId
      ).finally(done),
  })
}

/**
 * 5.10 소재 다중 활성/비활성
 */
function modifyCreativeOnOffBulk(adAccountId, ids, active) {
  return RequestLock.acquire({
    key: 'modifyCreativeOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/creatives/onOff`,
        { userConfig: active ? 'ON' : 'OFF', creativeIds: ids },
        adAccountId
      ).finally(done),
  })
}

/**
 * 4.2.14 캠페인 다중 삭제
 */
function deleteCampaignBulk(adAccountId, ids) {
  return RequestLock.acquire({
    key: 'deleteCampaignBulk',
    executor: done =>
      fetchDelete(`${CONTEXTPATH}/campaigns`, ids, adAccountId).finally(done),
  })
}

/**
 * 4.3.10 광고그룹 다중 삭제
 */
function deleteAdGroupBulk(adAccountId, ids) {
  return RequestLock.acquire({
    key: 'deleteAdGroupBulk',
    executor: done =>
      fetchDelete(`${CONTEXTPATH}/adGroups`, ids, adAccountId).finally(done),
  })
}

/**
 * 5.12 소재 다중 삭제
 */
function deleteCreativeBulk(adAccountId, ids) {
  return RequestLock.acquire({
    key: 'deleteCreativeBulk',
    executor: done =>
      fetchDelete(`${CONTEXTPATH}/creatives`, ids, adAccountId).finally(done),
  })
}

/**
 * 캠페인 일 예산 수정
 */
function modifyCampaignDailyBudget(adAccountId, id, value) {
  return fetchPut(
    `${CONTEXTPATH}/campaigns/${id}/dailyBudget`,
    { dailyBudget: value },
    adAccountId
  )
}

/**
 * 광고그룹 일 예산 수정
 */
function modifyAdGroupDailyBudget(adAccountId, id, value) {
  return fetchPut(
    `${CONTEXTPATH}/adGroups/${id}/dailyBudget`,
    { dailyBudget: value },
    adAccountId
  )
}

/**
 * 광고그룹 입찰금액 수정
 */
function modifyAdGroupBidAmount(adAccountId, id, value) {
  return fetchPut(
    `${CONTEXTPATH}/adGroups/${id}/bidAmount`,
    { bidAmount: value },
    adAccountId
  )
}

function modifyAdGroupBidAmountBulk({
  adAccountId,
  ids,
  bidAmount,
  campaignType,
  pricingType,
}) {
  return fetchPut(
    `${CONTEXTPATH}/adGroups/bidAmount/multiple`,
    {
      adGroupIds: ids,
      bidAmount,
      campaignType,
      pricingType,
    },
    adAccountId
  )
}

/**
 * 3.26.1
 * 광고계정 ON/OFF 제약 체크
 * [{ campaignType, goal }]
 */
function preCheckAdAccountOnOff(adAccountId, onOff) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/onOff/preCheck?userConfig=${onOff}`,
    adAccountId
  )
}

/**
 * 4.2.20
 * 캠페인 ON/OFF 제약 체크
 * [{ campaignType, goal }]
 */
function preCheckCampaignsOnOff(adAccountId, ids, onOff) {
  return RequestLock.acquire({
    key: 'preCheckCampaignsOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/campaigns/onOff/preCheck`,
        {
          campaignIds: ids,
          userConfig: onOff, // 'ON' | 'OFF'
        },
        adAccountId
      ).finally(done),
  })
}

/**
 * 광고그룹 ON/OFF 제약 체크
 * [{ campaignType, goal }]
 */
function preCheckAdGroupsOnOff(adAccountId, ids, onOff) {
  return RequestLock.acquire({
    key: 'preCheckAdGroupsOnOff',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/adGroups/onOff/preCheck`,
        {
          adGroupIds: ids,
          userConfig: onOff, // 'ON' | 'OFF'
        },
        adAccountId
      ).finally(done),
  })
}

// 광고계정 당 캠페인 수 제약
function validateCampaignCount(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/campaigns/validateCount`, adAccountId)
}

// 캠페인 당 광고그룹 수 제약
function validateAdGroupCount(adAccountId, campaignId) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups/validateCount?campaignId=${campaignId}`,
    adAccountId
  )
}

// 광고계정 당 소재 수 | 그룹 당 소재 수 제약
function validateCreativeCount(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/validateCount?adGroupId=${adGroupId}`,
    adAccountId
  )
}

function requestCreativesReviewWithoutChange({ adAccountId, ids }) {
  return RequestLock.acquire({
    key: 'requestCreativesReviewWithoutChange',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/creatives/modifyReviewWithoutChange`,
        { creativeIds: ids },
        adAccountId
      ).finally(done),
  })
}

export default {
  modifyAdAccountOnOff,
  modifyCampaignOnOff,
  modifyCampaignOnOffBulk,
  modifyAdGroupOnOff,
  modifyAdGroupOnOffBulk,

  deleteCampaignBulk,
  deleteAdGroupBulk,

  modifyCreativeOnOff,
  modifyCreativeOnOffBulk,
  deleteCreativeBulk,

  modifyCampaignDailyBudget,
  modifyAdGroupDailyBudget,
  modifyAdGroupBidAmount,
  modifyAdGroupBidAmountBulk,

  preCheckAdAccountOnOff,
  preCheckCampaignsOnOff,
  preCheckAdGroupsOnOff,

  validateCampaignCount,
  validateAdGroupCount,
  validateCreativeCount,

  requestCreativesReviewWithoutChange,
}
