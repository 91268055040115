import { convertToCommaSeparatedString } from '../../../../../utils/utils'

const CatalogUtils = {
  // warning: prefix or suffix 중 1개만 있어야 함.
  priceCurrencies() {
    return [
      {
        currencyCode: 'KRW',
        prefix: '',
        suffix: '원',
        decimalScale: 0,
        description: '원화',
      },
      {
        currencyCode: 'USD',
        prefix: '$',
        suffix: '',
        decimalScale: 2,
        description: '달러',
      },
      {
        currencyCode: 'JPY',
        prefix: '¥',
        suffix: '',
        decimalScale: 0,
        description: '엔화',
      },
      {
        currencyCode: 'EUR',
        prefix: '€',
        suffix: '',
        decimalScale: 2,
        description: '유로',
      },
    ]
  },

  createAmount(price, currencyCode) {
    const decimalScale =
      CatalogUtils.priceCurrencies().find(v => v.currencyCode === currencyCode)
        ?.decimalScale || 0
    return decimalScale === 0 ? Math.ceil(price) : price
  },

  createPriceLabel(amount, currencyCode) {
    const currencyItem = this.priceCurrencies().find(
      v => v.currencyCode === currencyCode
    )

    if (currencyItem) {
      return `${currencyItem.prefix}${convertToCommaSeparatedString(amount)}${
        currencyItem.suffix
      }`
    }

    return `${convertToCommaSeparatedString(amount)}`
  },
}
export const CURRENCY_OPTION_ITEMS = CatalogUtils.priceCurrencies().map(v => ({
  id: v.currencyCode,
  label: `${v.description} (${v.prefix || v.suffix})`,
  decimalScale: v.decimalScale,
}))
export default CatalogUtils
