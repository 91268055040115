import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { DashboardUriUtils } from '../../components/DashboardV3/dashboardUriUtils'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import axios from 'axios'
import {
  getCampaignAndAdGroupConstraints,
  getCreativeConstraintsByAdGroupId,
} from '../advertise/mAdConstraints'
import ObjectiveTypeEnum from '../../enums/ObjectiveTypeEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import DashboardRequestBodyUtils from './dashboardRequestBodyUtils'
import { showErrorMessage } from '../../utils/alertUtils'
import { AdAccountHelper } from '../../utils/helper/helper-adAccount'
import { toInvalidApproachPath } from '../../utils/router/routeUtils'
import { setDashboardAdAccountTodayIndex } from './mDashboardCommon'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageLocal,
} from '../../utils/storage/storageFactoryImpl'
import CreativeAPI from '../../modules-api/advertise/adCreativeApi'
import { checkNotEmpty, isUndefinedOrNull } from '../../utils/regexUtils'
import { PIXEL_AND_SDK_LINKAGE_STATUS } from '../../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import RequestLock from '../../utils/requestLock'
import { handleDashboardException } from './dashboardException'
import { ShowcaseApi } from '../../modules-api/showcase/showcaseApi'
import campaignApi from '../../modules-api/advertise/campaignApi'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { DashboardTableMetricIds } from '../../utils/dashboard/dashboardTableMetric'
import AdGroupConstraints from '../../utils/constraints/constraints-adGroup'

export const dashboardChartDefaultAxes = [
  'cost',
  'click',
  'imp',
  'cost_per_click',
]

const DashboardSummary = keyMirror(
  {
    SELECT_SUMMARY: null,

    SET_VISIBILITY: null,

    SET_BASIC_INFO: null,
    SET_OPERATION_INFO: null,
    SET_IS_SKAN_TYPE: null,
    SET_TODAY_INDEX: null,
    SET_CREATIVE_AUTHORITY_INFO: null,

    SET_CHART_DATA: null,
    SET_CHART_AXES: null,
    SET_CHART_AXIS_BY_INDEX: null,
    SET_CHART_METRIC_SUM: null,

    CLEAR: null,
  },
  'DASHBOARD_SUMMARY'
)

const initialState = fromJS({
  id: null,
  dashboardType: null,

  visibility: { info: true, chart: true },

  basicInfo: {},
  operationInfo: {},
  isSkanType: false,
  creativeReviewAuthorityInfo: {},

  todayIndex: {
    costOfToday: 0,
    costOfThisMonth: 0,
  },

  chart: {
    data: [],
    axes: dashboardChartDefaultAxes,
    range: 8, // 8, 15, 30
  },
})

export default createReducer(initialState, {
  [DashboardSummary.SELECT_SUMMARY]: (state, { dashboardType, id }) => {
    return state.withMutations(s =>
      s.set('id', id).set('dashboardType', dashboardType)
    )
  },

  [DashboardSummary.SET_VISIBILITY]: (state, { key, isVisible }) => {
    return state.setIn(['visibility', key], isVisible)
  },

  [DashboardSummary.SET_BASIC_INFO]: (state, { basicInfo }) => {
    return state.set('basicInfo', fromJS(basicInfo))
  },

  [DashboardSummary.SET_OPERATION_INFO]: (state, { operationInfo }) => {
    return state.set('operationInfo', fromJS(operationInfo))
  },

  [DashboardSummary.SET_IS_SKAN_TYPE]: (state, { isSkanType }) => {
    return state.set('isSkanType', isSkanType)
  },

  [DashboardSummary.SET_CREATIVE_AUTHORITY_INFO]: (
    state,
    { creativeReviewAuthorityInfo }
  ) => {
    return state.set(
      'creativeReviewAuthorityInfo',
      fromJS(creativeReviewAuthorityInfo)
    )
  },

  [DashboardSummary.SET_TODAY_INDEX]: (state, { todayIndex }) => {
    return state.set('todayIndex', fromJS(todayIndex))
  },

  [DashboardSummary.SET_CHART_DATA]: (state, { data }) => {
    return state.setIn(['chart', 'data'], fromJS(data || []))
  },

  [DashboardSummary.SET_CHART_METRIC_SUM]: (state, { data }) => {
    return state.setIn(['chart', 'metricSum'], fromJS(data))
  },

  [DashboardSummary.SET_CHART_AXES]: (state, { metricCodeList }) => {
    return state.setIn(['chart', 'axes'], fromJS(metricCodeList))
  },

  [DashboardSummary.SET_CHART_AXIS_BY_INDEX]: (
    state,
    { index, metricCode }
  ) => {
    return state.updateIn(['chart', 'axes'], prevAxes =>
      prevAxes.set(index, metricCode)
    )
  },

  [DashboardSummary.CLEAR]: () => initialState,
})

function setDashboardChartMetricSum(data) {
  return {
    type: DashboardSummary.SET_CHART_METRIC_SUM,
    data,
  }
}

export function initDashboardSummaryByStorageAndParams({
  adAccountId,
  urlSearchParams,
}) {
  return dispatch => {
    dispatch(
      selectDashboardSummaryByStorageAndParams({ adAccountId, urlSearchParams })
    )
    dispatch(initDashboardSummaryVisibilityByStorage({ adAccountId }))
  }
}

function selectDashboardSummaryByStorageAndParams({
  adAccountId,
  urlSearchParams,
}) {
  return dispatch => {
    const { dashboardType, id } = DashboardUriUtils.Summary.getObjectValue({
      urlSearchParams,
      href: window.location.href,
    })
    if (dashboardType && DashboardEnumV2.values().includes(dashboardType)) {
      dispatch({
        type: DashboardSummary.SELECT_SUMMARY,
        dashboardType,
        id,
      })
    } else {
      const expanded =
        DashboardStorageLocal.get(DASHBOARD_STORAGE_ITEM.SUMMARY_EXPANDED)?.[
          adAccountId
        ] || false
      if (expanded) {
        dispatch({
          type: DashboardSummary.SELECT_SUMMARY,
          dashboardType: DashboardEnumV2.Type.AD_ACCOUNT,
          id: null,
        })
      }
    }
  }
}

function initDashboardSummaryVisibilityByStorage({ adAccountId }) {
  return dispatch => {
    const visibilityStateJson = DashboardStorageLocal.get(
      DASHBOARD_STORAGE_ITEM.SUMMARY_VISIBILITY
    )?.[adAccountId]

    dispatch({
      type: DashboardSummary.SET_VISIBILITY,
      key: 'info',
      isVisible: visibilityStateJson?.info ?? true,
    })

    dispatch({
      type: DashboardSummary.SET_VISIBILITY,
      key: 'chart',
      isVisible: visibilityStateJson?.chart ?? true,
    })
  }
}

export function setDashboardSummaryVisibility({ key, isVisible }) {
  return (dispatch, getState) => {
    dispatch({ type: DashboardSummary.SET_VISIBILITY, key, isVisible })

    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        summary: { visibility },
      },
    } = getState()

    DashboardStorageLocal.update(
      DASHBOARD_STORAGE_ITEM.SUMMARY_VISIBILITY,
      (obj = {}) => {
        if (adAccountId > 0) {
          obj[adAccountId] = visibility.toJS()
        }
        return obj
      }
    )
  }
}

function persistStorageSummaryExpanded(adAccountId, value) {
  DashboardStorageLocal.update(
    DASHBOARD_STORAGE_ITEM.SUMMARY_EXPANDED,
    (obj = {}) => {
      if (adAccountId > 0) {
        obj[adAccountId] = value
      }
      return obj
    }
  )
}

export function selectDashboardSummary({ dashboardType, id }) {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
      },
    } = getState()

    dispatch(initDashboardSummaryVisibilityByStorage({ adAccountId }))
    dispatch(setDashboardSummaryUri({ dashboardType, id }))

    dispatch({
      type: DashboardSummary.SELECT_SUMMARY,
      dashboardType,
      id,
    })

    persistStorageSummaryExpanded(adAccountId, true)
  }
}

export function unselectDashboardSummary() {
  return (dispatch, getState) => {
    dispatch(clearDashboardSummaryUri())
    dispatch(clearDashboardSummary())

    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
      },
    } = getState()
    persistStorageSummaryExpanded(adAccountId, false)
  }
}

function setDashboardSummaryBasicInfo({ basicInfo }) {
  return {
    type: DashboardSummary.SET_BASIC_INFO,
    basicInfo,
  }
}

function setDashboardSummaryIsSkanType(isSkanType) {
  return {
    type: DashboardSummary.SET_IS_SKAN_TYPE,
    isSkanType,
  }
}

function setDashboardSummaryOperationInfo({ operationInfo }) {
  return {
    type: DashboardSummary.SET_OPERATION_INFO,
    operationInfo,
  }
}

function setDashboardSummaryCreativeReviewAuthorityInfo(
  creativeReviewAuthorityInfo
) {
  return {
    type: DashboardSummary.SET_CREATIVE_AUTHORITY_INFO,
    creativeReviewAuthorityInfo,
  }
}

function setDashboardSummaryTodayIndex({ todayIndex }) {
  return {
    type: DashboardSummary.SET_TODAY_INDEX,
    todayIndex,
  }
}

function setDashboardSummaryChart({ data }) {
  return {
    type: DashboardSummary.SET_CHART_DATA,
    data,
  }
}

function persistStorageChartAxes(getState) {
  const {
    dashboardV3: {
      common: {
        adAccountInfo: { id: adAccountId },
      },
      summary: {
        chart: { axes },
      },
    },
  } = getState()

  DashboardStorageLocal.update(
    DASHBOARD_STORAGE_ITEM.SUMMARY_CHART_AXES,
    (obj = {}) => {
      if (adAccountId > 0) {
        obj[adAccountId] = axes.toJS()
      }
      return obj
    }
  )
}

export function initDashboardSummaryChartAxes({ metricCodeList }) {
  return dispatch => {
    dispatch({
      type: DashboardSummary.SET_CHART_AXES,
      metricCodeList,
    })
  }
}

export function setDashboardSummaryChartAxes({
  metricCodeList,
  invalidate = true,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: DashboardSummary.SET_CHART_AXES,
      metricCodeList,
    })

    persistStorageChartAxes(getState)

    if (invalidate) {
      dispatch(invalidateDashboardSummaryChart())
    }
  }
}

export function setDashboardSummaryChartAxisByIndex({
  index,
  metricCode,
  invalidate = true,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: DashboardSummary.SET_CHART_AXIS_BY_INDEX,
      index,
      metricCode,
    })

    persistStorageChartAxes(getState)

    if (invalidate) {
      dispatch(invalidateDashboardSummaryChart())
    }
  }
}

export function getDashboardSummaryAdAccountInfo({ adAccountId }) {
  return async (dispatch, getState, api) => {
    try {
      await RequestLock.acquire({
        key: 'getDashboardSummaryAdAccountInfo',
        executor: async done => {
          dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

          try {
            const [
              adAccountResponse,
              operationResponse,
              todayIndexResponse,
              creativeReviewAuthorityResponse,
            ] = await axios.all([
              api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId),
              api.search.getAdAccountOperationTotalSummary({ adAccountId }),
              api.dashboardSummary.fetchAdAccountTodayIndex({
                adAccountId,
              }),
              api.dashboardSummary.fetchCreativeReviewAuthorityInfo({
                adAccountId,
              }),
            ])

            const adAccountInfo = adAccountResponse?.data || {}

            if (!AdAccountHelper.isValid(adAccountInfo)) {
              dispatch(RouterV2.replace(toInvalidApproachPath()))
            } else {
              dispatch(
                setDashboardSummaryOperationInfo({
                  operationInfo: operationResponse?.data || {},
                })
              )

              const todayIndex = todayIndexResponse?.data || {}
              const creativeReviewAuthorityInfo =
                creativeReviewAuthorityResponse?.data || {}

              dispatch(
                setDashboardAdAccountTodayIndex({
                  adAccountTodayIndex: todayIndex,
                })
              )

              dispatch(
                setDashboardSummaryCreativeReviewAuthorityInfo(
                  creativeReviewAuthorityInfo
                )
              )

              try {
                const walletResponse = await api.cash.fetchAdAccountCashInfo(
                  adAccountId
                )
                const [
                  paymentCardResponse,
                  dailyBudgetResponse,
                  paymentCardAvailableAmountResponse,
                ] = await axios.all([
                  api.card.getPaymentCardListInUse(adAccountId),
                  api.card.getPaymentCardDailySpendingBudget(adAccountId),
                  api.card.getPaymentCardAvailableAmount(adAccountId),
                ])

                todayIndex.wallet = walletResponse?.data || {}
                todayIndex.paymentCardListInUse =
                  paymentCardResponse?.data || {}
                todayIndex.dailyBudget = dailyBudgetResponse?.data || 0
                todayIndex.paymentCardAvailableAmount =
                  paymentCardAvailableAmountResponse?.data || 0
              } catch (e) {
                dispatch(handleDashboardException({ e }))
              }

              dispatch(
                setDashboardSummaryTodayIndex({
                  todayIndex,
                })
              )
            }
          } catch (e) {
            if (e.response?.data?.errorCode === 21006) {
              dispatch(deletedOrNoLongerSupportedException())
            } else {
              dispatch(handleDashboardException({ e }))
            }
          } finally {
            dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

            done()
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('getDashboardSummaryAdAccountInfo is locked')
      }
    }
  }
}

/**
 *  상세 내용은 https://jira.daumkakao.com/browse/KAMOQA-15310 참고
 */
export function fetchDashboardChartMetricSum() {
  return async (dispatch, getState, api) => {
    const {
      dashboardV3: {
        calendar: { startDate, endDate },
        filter: { conditions },
        table: {
          checkedRowIdSet: {
            campaign: checkedCampaignIdSet,
            adGroup: checkedAdGroupIdSet,
          },
        },
        common: {
          adAccountInfo: { id: adAccountId },
        },
        summary: {
          id: summaryAdUnitId,
          dashboardType,
          chart: { axes },
        },
      },
    } = getState()

    if (!(adAccountId > 0)) {
      console.warn('fetchDashboardChartMetricSum', { adAccountId })
      return
    }

    const cancelTokenSource = createCancellation('fetchDashboardChartMetricSum')

    try {
      const metrics = axes.map(metricCode => ({
        id: metricCode,
        label:
          DashboardTableMetricIds.find(({ id }) => id === metricCode)?.label ||
          '',
      }))

      switch (dashboardType) {
        case DashboardEnumV2.Type.AD_ACCOUNT: {
          const requestBody = DashboardRequestBodyUtils.chartSummary({
            axes,
            startDate,
            endDate,
          })

          const { data } = await api.dashboardTable.fetchAdAccountChartRowSum({
            adAccountId,
            requestBody,
            cancelTokenSource,
          })
          dispatch(setDashboardChartMetricSum(data.indicatorMap))

          break
        }

        case DashboardEnumV2.Type.CAMPAIGN: {
          const requestBody = DashboardRequestBodyUtils.campaignTable({
            summaryAdUnitId,
            metrics,
            conditions,
            startDate,
            endDate,
          })

          const { data } = await api.dashboardTable.fetchCampaignTableRowSum({
            adAccountId,
            requestBody,
            cancelTokenSource,
          })
          dispatch(setDashboardChartMetricSum(data.indicatorMap))

          break
        }

        case DashboardEnumV2.Type.AD_GROUP: {
          const requestBody = DashboardRequestBodyUtils.adGroupTable({
            summaryAdUnitId,
            campaignIdSet: checkedCampaignIdSet,
            metrics,
            conditions,
            startDate,
            endDate,
          })

          const { data } = await api.dashboardTable.fetchAdGroupTableRowSum({
            adAccountId,
            requestBody,
            cancelTokenSource,
          })
          dispatch(setDashboardChartMetricSum(data.indicatorMap))

          break
        }

        case DashboardEnumV2.Type.CREATIVE: {
          const requestBody = DashboardRequestBodyUtils.creativeTable({
            summaryAdUnitId,
            campaignIdSet: checkedCampaignIdSet,
            adGroupIdSet: checkedAdGroupIdSet,
            metrics,
            conditions,
            startDate,
            endDate,
          })

          const { data } = await api.dashboardTable.fetchCreativeTableRowSum({
            adAccountId,
            requestBody,
            cancelTokenSource,
          })
          dispatch(setDashboardChartMetricSum(data.indicatorMap))

          break
        }

        default: {
          break
        }
      }
    } catch (e) {
      dispatch(handleDashboardException({ e }))
    } finally {
      deleteCancellation(cancelTokenSource)
    }
  }
}

export function getDashboardSummaryAdAccountChart({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))

    try {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          summary: {
            chart: { axes },
          },
        },
      } = getState()

      const [chartResponse] = await axios.all([
        api.dashboardChart.fetchAdAccountTotalChartData({
          adAccountId,
          requestBody: DashboardRequestBodyUtils.adAccountTotalChart({
            metrics: axes.map(metricCode => ({ id: metricCode })),
            startDate,
            endDate,
          }),
        }),
      ])

      dispatch(fetchDashboardChartMetricSum())
      dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
    } catch (e) {
      dispatch(handleDashboardException({ e }))
    } finally {
      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
    }
  }
}

function deletedOrNoLongerSupportedException() {
  return dispatch => {
    showErrorMessage('삭제되었거나 지원 종료된 광고입니다.')

    dispatch(unselectDashboardSummary())
  }
}

export function getDashboardSummaryCampaignInfo({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    try {
      await RequestLock.acquire({
        key: 'getDashboardSummaryCampaignInfo',
        executor: async done => {
          dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

          const {
            dashboardV3: {
              calendar: { startDate, endDate },
              filter: { conditions },
              tableMetric: { selected: tableMetrics },
            },
          } = getState()

          if (id > 0) {
            try {
              const [campaignResponse, operationResponse] = await axios.all([
                api.campaign.fetchCampaignInfoById(adAccountId, id),
                api.dashboardSummary.fetchCampaignOperationSummary({
                  adAccountId,
                  campaignId: id,
                }),
              ])

              const campaign = campaignResponse?.data || {}

              if (campaign.userConfig === 'DEL') {
                dispatch(deletedOrNoLongerSupportedException())
              } else {
                const { id: campaignTypeGoalId } =
                  campaign.campaignTypeGoal || {}

                try {
                  dispatch(
                    getCampaignAndAdGroupConstraints(
                      adAccountId,
                      campaignTypeGoalId,
                      campaign?.objective?.type,
                      campaign?.objective?.detailType
                    )
                  )
                } catch (e) {
                  console.log(e.message)
                }

                if (
                  campaign.objective?.type ===
                  ObjectiveTypeEnum.Type.TALK_CHANNEL
                ) {
                  try {
                    const pfProfileResponse =
                      await api.campaign.fetchPlusFriendProfilesInCampaign(
                        adAccountId,
                        campaign.id
                      )

                    campaign.profileName = pfProfileResponse?.data?.name || ''
                    campaign.plusFriendProfileInfo =
                      pfProfileResponse?.data || {}
                  } catch (e) {
                    console.log(e.message)
                  }
                }

                if (campaign.trackId > 0) {
                  try {
                    const {
                      data: { body },
                    } = await api.bconV2.fetchDetailBasicInfo(
                      adAccountId,
                      campaign.trackId
                    )

                    const { adAccountLinkStatus, deletedAt } = body
                    const status = !isUndefinedOrNull(deletedAt)
                      ? PIXEL_AND_SDK_LINKAGE_STATUS.DELETED
                      : PIXEL_AND_SDK_LINKAGE_STATUS[adAccountLinkStatus]

                    campaign.trackInfo = { ...body, status }
                  } catch (e) {
                    console.log(e.message)
                  }
                }

                // 카탈로그 상품 이름 조회
                if (
                  campaign.objective?.type === ObjectiveTypeEnum.Type.CATALOG
                ) {
                  try {
                    const catalogResponse =
                      await ShowcaseApi.fetchCatalogInfoById(
                        adAccountId,
                        campaign.objective?.value
                      )
                    campaign.catalogInfo = catalogResponse?.data || {}
                  } catch (e) {
                    const { message = '' } = e?.response?.data || {}
                    if (checkNotEmpty(message)) {
                      dispatch(handleDashboardException({ e }))
                    } else {
                      showErrorMessage(
                        '일시적인 오류로 인해 카탈로그 관련 서비스 이용이 불가능합니다.'
                      )
                    }
                  }
                }

                // 비즈보드 CPT 광고 상품 설정 조회
                if (campaign.contractId > 0) {
                  try {
                    const contractResponse =
                      await campaignApi.fetchAdProductContractById(
                        adAccountId,
                        campaign.contractId
                      )

                    campaign.contractInfo = contractResponse.data
                  } catch (e) {
                    console.log(e.message)
                  }
                }

                dispatch(setDashboardSummaryBasicInfo({ basicInfo: campaign }))
                dispatch(
                  setDashboardSummaryOperationInfo({
                    operationInfo: operationResponse?.data || {},
                  })
                )
                dispatch(setDashboardSummaryCreativeReviewAuthorityInfo())
              }
            } catch (e) {
              // deleted
              if (e.response?.data?.errorCode === 31001) {
                dispatch(deletedOrNoLongerSupportedException())
              }

              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          } else {
            const paramObject = {
              metrics: tableMetrics,
              conditions,
              startDate,
              endDate,
            }

            try {
              const [todayIndexResponse, operationResponse] = await axios.all([
                api.dashboardSummary.fetchCampaignTodayIndex({
                  adAccountId,
                  requestBody:
                    DashboardRequestBodyUtils.campaignTodayIndex(paramObject),
                }),
                api.dashboardSummary.fetchCampaignOperationTotalSummary({
                  adAccountId,
                  requestBody:
                    DashboardRequestBodyUtils.campaignOperationTotalSummary(
                      paramObject
                    ),
                }),
              ])

              dispatch(
                setDashboardSummaryTodayIndex({
                  todayIndex: todayIndexResponse?.data || {},
                })
              )
              dispatch(
                setDashboardSummaryOperationInfo({
                  operationInfo: operationResponse?.data || {},
                })
              )
            } catch (e) {
              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('getDashboardSummaryCampaignInfo is locked')
      }
    }
  }
}

export function getDashboardSummaryCampaignChart({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))

    if (id > 0) {
      try {
        const {
          dashboardV3: {
            calendar: { startDate, endDate },
            summary: {
              chart: { axes },
            },
          },
        } = getState()

        const [chartResponse] = await axios.all([
          api.dashboardChart.fetchCampaignChartData({
            adAccountId,
            campaignId: id,
            requestBody: DashboardRequestBodyUtils.singleChart({
              metrics: axes.map(metricCode => ({ id: metricCode })),
              startDate,
              endDate,
            }),
          }),
        ])

        dispatch(fetchDashboardChartMetricSum())
        dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    } else {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          summary: {
            chart: { axes },
          },
        },
      } = getState()

      try {
        dispatch(
          setDashboardSummaryChartAxes({
            metricCodeList: axes,
            invalidate: false,
          })
        )

        const [chartResponse] = await axios.all([
          api.dashboardChart.fetchCampaignTotalChartData({
            adAccountId,
            requestBody: DashboardRequestBodyUtils.campaignTotalChart({
              metrics: axes.map(metricCode => ({ id: metricCode })),
              conditions,
              startDate,
              endDate,
            }),
          }),
        ])

        dispatch(fetchDashboardChartMetricSum())
        dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    }
  }
}

export function getDashboardSummaryAdGroupInfo({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    try {
      await RequestLock.acquire({
        key: 'getDashboardSummaryAdGroupInfo',
        executor: async done => {
          dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

          const {
            dashboardV3: {
              calendar: { startDate, endDate },
              filter: { conditions },
              tableMetric: { selected: tableMetrics },
            },
            adAccount: {
              adAccountInfo: { extras },
            },
          } = getState()

          if (id > 0) {
            try {
              const [adGroupResponse, operationResponse] = await axios.all([
                api.adGroup.fetchAdGroupInfoById(adAccountId, id),
                api.dashboardSummary.fetchAdGroupOperationSummary({
                  adAccountId,
                  adGroupId: id,
                }),
              ])

              const adGroup = adGroupResponse?.data || {}

              if (adGroup.userConfig === 'DEL') {
                dispatch(deletedOrNoLongerSupportedException())
              } else {
                const { id: campaignTypeGoalId, campaignType } =
                  adGroup.campaign?.campaignTypeGoal || {}

                try {
                  dispatch(
                    getCampaignAndAdGroupConstraints(
                      adAccountId,
                      campaignTypeGoalId,
                      adGroup.campaign?.objective?.type,
                      adGroup.campaign?.objective?.detailType
                    )
                  )
                } catch (e) {
                  showErrorMessage(e.response?.data?.message || e.message)
                  console.log(e.message)
                }

                if (
                  adGroup.campaignId > 0 &&
                  campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
                ) {
                  try {
                    const pfProfileResponse =
                      await api.campaign.fetchPlusFriendProfilesInCampaign(
                        adAccountId,
                        adGroup.campaignId
                      )
                    adGroup.plusFriendProfileInfo =
                      pfProfileResponse?.data || {}
                  } catch (e) {
                    console.log(e.message)
                  }
                }

                dispatch(setDashboardSummaryBasicInfo({ basicInfo: adGroup }))
                dispatch(
                  setDashboardSummaryOperationInfo({
                    operationInfo: operationResponse?.data || {},
                  })
                )

                const {
                  campaignTypeGoal: { goal },
                  objective: {
                    type: objectiveType,
                    detailType: objectiveDetailType,
                  },
                } = adGroup?.campaign || {}

                const isSkanType = AdGroupConstraints.isSkanType({
                  campaignType,
                  goal,
                  objectiveType,
                  objectiveDetailType,
                  extras,
                })
                dispatch(setDashboardSummaryIsSkanType(isSkanType))
              }
            } catch (e) {
              // deleted
              if (
                e.response?.data?.errorCode === 32001 ||
                e.response?.data?.errorCode === 38015 ||
                e.response?.data?.errorCode === 32026
              ) {
                dispatch(deletedOrNoLongerSupportedException())
              }

              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          } else {
            try {
              const paramObject = {
                metrics: tableMetrics,
                conditions,
                startDate,
                endDate,
              }

              const [todayIndexResponse, operationResponse] = await axios.all([
                api.dashboardSummary.fetchAdGroupTodayIndex({
                  adAccountId,
                  requestBody:
                    DashboardRequestBodyUtils.adGroupTodayIndex(paramObject),
                }),
                api.dashboardSummary.fetchAdGroupOperationTotalSummary({
                  adAccountId,
                  requestBody:
                    DashboardRequestBodyUtils.adGroupOperationTotalSummary(
                      paramObject
                    ),
                }),
              ])

              dispatch(
                setDashboardSummaryTodayIndex({
                  todayIndex: todayIndexResponse?.data || {},
                })
              )
              dispatch(
                setDashboardSummaryOperationInfo({
                  operationInfo: operationResponse?.data || {},
                })
              )
            } catch (e) {
              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('getDashboardSummaryAdGroupInfo is locked')
      }
    }
  }
}

export function getDashboardSummaryAdGroupChart({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))

    if (id > 0) {
      try {
        const {
          dashboardV3: {
            calendar: { startDate, endDate },
            summary: {
              chart: { axes },
            },
          },
        } = getState()

        const [chartResponse] = await axios.all([
          api.dashboardChart.fetchAdGroupChartData({
            adAccountId,
            adGroupId: id,
            requestBody: DashboardRequestBodyUtils.singleChart({
              metrics: axes.map(metricCode => ({ id: metricCode })),
              startDate,
              endDate,
            }),
          }),
        ])

        dispatch(fetchDashboardChartMetricSum())
        dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    } else {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          summary: {
            chart: { axes },
          },
        },
      } = getState()

      try {
        dispatch(
          setDashboardSummaryChartAxes({
            metricCodeList: axes,
            invalidate: false,
          })
        )

        const [chartResponse] = await axios.all([
          api.dashboardChart.fetchAdGroupTotalChartData({
            adAccountId,
            requestBody: DashboardRequestBodyUtils.adGroupTotalChart({
              metrics: axes.map(metricCode => ({ id: metricCode })),
              conditions,
              startDate,
              endDate,
            }),
          }),
        ])

        dispatch(fetchDashboardChartMetricSum())
        dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    }
  }
}

export function getDashboardSummaryCreativeInfo({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    try {
      await RequestLock.acquire({
        key: 'getDashboardSummaryCreativeInfo',
        executor: async done => {
          dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

          const {
            dashboardV3: {
              calendar: { startDate, endDate },
              filter: { conditions },
              tableMetric: { selected: tableMetrics },
            },
          } = getState()

          if (id > 0) {
            try {
              const [creativeResponse] = await axios.all([
                api.adCreative.fetchCreativeChildInfoById({ adAccountId, id }),
              ])

              const creative = creativeResponse?.data || {}

              /**
               * userConfig 가 DEL 이고 modifyingCreative 가 존재할 경우 후심사(자동심사) 처리인 것으로 식별한다.
               * modifyingCreative 의 id 로 redirect 한다.
               */
              if (creative.userConfig === 'DEL') {
                dispatch(deletedOrNoLongerSupportedException())
              } else {
                const campaignId = creative.campaign?.id || -1
                const adGroupId = creative.adGroup?.id || -1
                const { campaignType } =
                  creative.campaign?.campaignTypeGoal || {}

                if (
                  campaignId > 0 &&
                  campaignType === CampaignTypeEnum.Type.TALK_CHANNEL
                ) {
                  try {
                    const plusFriendProfileResponse =
                      await api.campaign.fetchPlusFriendProfilesInCampaign(
                        adAccountId,
                        campaignId
                      )

                    creative.plusFriendProfileInfo =
                      plusFriendProfileResponse?.data || {}
                  } catch (e) {
                    console.log(e.message)
                  }
                }

                if (adGroupId > 0) {
                  // adGroupId 로 소재 제약조건 갱신.
                  try {
                    dispatch(
                      getCreativeConstraintsByAdGroupId(adAccountId, adGroupId)
                    )
                  } catch (e) {
                    showErrorMessage(e.response?.data?.message || e.message)
                    console.log(e.message)
                  }
                }

                try {
                  if (campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL) {
                    // 소재 재심사요청 가능 여부
                    const isReviewableResponse =
                      await api.adCreative.isReviewableAdCreativeWithoutChange(
                        adAccountId,
                        id
                      )

                    creative.isReviewable = isReviewableResponse?.data || false
                  }
                } catch (e) {
                  console.log(e.message)
                }
              }

              dispatch(setDashboardSummaryBasicInfo({ basicInfo: creative }))
            } catch (e) {
              // deleted
              if (e.response?.data?.errorCode === 33003) {
                dispatch(deletedOrNoLongerSupportedException())
              }

              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          } else {
            try {
              const [todayIndexResponse] = await axios.all([
                api.dashboardSummary.fetchCreativeTodayIndex({
                  adAccountId,
                  requestBody: DashboardRequestBodyUtils.creativeTodayIndex({
                    metrics: tableMetrics,
                    conditions,
                    startDate,
                    endDate,
                  }),
                }),
              ])

              dispatch(
                setDashboardSummaryTodayIndex({
                  todayIndex: todayIndexResponse?.data || {},
                })
              )
            } catch (e) {
              console.log(e.message)
            } finally {
              dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_INFO))

              done()
            }
          }
        },
      })
    } catch (e) {
      if (e?.isLocked) {
        console.warn('getDashboardSummaryCreativeInfo is locked')
      }
    }
  }
}

export function getDashboardSummaryCreativeChart({ adAccountId, id }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))

    if (id > 0) {
      try {
        const {
          dashboardV3: {
            calendar: { startDate, endDate },
            summary: {
              chart: { axes },
            },
          },
        } = getState()

        const creativeResponse = await CreativeAPI.fetchAdCreativeInfoById(
          adAccountId,
          id
        )

        const { representativeId } = creativeResponse?.data || {}

        // 소재 단건 차트는 representativeId 로 조회.
        if (representativeId > 0) {
          const [chartResponse] = await axios.all([
            api.dashboardChart.fetchCreativeChartData({
              adAccountId,
              creativeId: representativeId,
              requestBody: DashboardRequestBodyUtils.singleChart({
                metrics: axes.map(metricCode => ({ id: metricCode })),
                startDate,
                endDate,
              }),
            }),
          ])

          dispatch(fetchDashboardChartMetricSum())
          dispatch(
            setDashboardSummaryChart({ data: chartResponse?.data || [] })
          )
        }
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    } else {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          summary: {
            chart: { axes },
          },
        },
      } = getState()

      try {
        dispatch(
          setDashboardSummaryChartAxes({
            metricCodeList: axes,
            invalidate: false,
          })
        )

        const [chartResponse] = await axios.all([
          api.dashboardChart.fetchCreativeTotalChartData({
            adAccountId,
            requestBody: DashboardRequestBodyUtils.creativeTotalChart({
              metrics: axes.map(metricCode => ({ id: metricCode })),
              conditions,
              startDate,
              endDate,
            }),
          }),
        ])

        dispatch(fetchDashboardChartMetricSum())
        dispatch(setDashboardSummaryChart({ data: chartResponse?.data || [] }))
      } catch (e) {
        dispatch(handleDashboardException({ e }))
      } finally {
        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_SUMMARY_CHART))
      }
    }
  }
}

export function invalidateDashboardSummary() {
  return dispatch => {
    dispatch(invalidateDashboardSummaryInfo())
    dispatch(invalidateDashboardSummaryChart())
  }
}

export function invalidateDashboardSummaryInfo() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        summary: { dashboardType, id: adId },
      },
    } = getState()

    if (!(adAccountId > 0)) {
      console.warn('invalidateDashboardSummaryInfo', { adAccountId })
      return
    }

    switch (dashboardType) {
      case DashboardEnumV2.Type.AD_ACCOUNT: {
        dispatch(getDashboardSummaryAdAccountInfo({ adAccountId }))
        break
      }

      case DashboardEnumV2.Type.CAMPAIGN: {
        dispatch(getDashboardSummaryCampaignInfo({ adAccountId, id: adId }))
        break
      }

      case DashboardEnumV2.Type.AD_GROUP: {
        dispatch(getDashboardSummaryAdGroupInfo({ adAccountId, id: adId }))
        break
      }

      case DashboardEnumV2.Type.CREATIVE: {
        dispatch(getDashboardSummaryCreativeInfo({ adAccountId, id: adId }))
        break
      }

      default: {
        break
      }
    }
  }
}

export function invalidateDashboardSummaryChart() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        summary: { dashboardType, id: adId },
      },
    } = getState()

    if (!(adAccountId > 0)) {
      console.warn('invalidateDashboardSummaryChart', { adAccountId })
      return
    }

    switch (dashboardType) {
      case DashboardEnumV2.Type.AD_ACCOUNT: {
        dispatch(getDashboardSummaryAdAccountChart({ adAccountId }))
        break
      }

      case DashboardEnumV2.Type.CAMPAIGN: {
        dispatch(getDashboardSummaryCampaignChart({ adAccountId, id: adId }))
        break
      }

      case DashboardEnumV2.Type.AD_GROUP: {
        dispatch(getDashboardSummaryAdGroupChart({ adAccountId, id: adId }))
        break
      }

      case DashboardEnumV2.Type.CREATIVE: {
        dispatch(getDashboardSummaryCreativeChart({ adAccountId, id: adId }))
        break
      }

      default: {
        break
      }
    }
  }
}

export function setDashboardSummaryUri({ dashboardType, id }) {
  return dispatch => {
    const url = DashboardUriUtils.Summary.set({ dashboardType, id })

    dispatch(
      RouterV2.replace({
        search: url.search,
      })
    )
  }
}

export function clearDashboardSummaryUri() {
  return dispatch => {
    const url = DashboardUriUtils.Summary.clear()

    dispatch(
      RouterV2.replace({
        search: url.search,
      })
    )
  }
}

export function clearDashboardSummary() {
  return {
    type: DashboardSummary.CLEAR,
  }
}
