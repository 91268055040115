import { keyMirror } from '../utils/utils'

const DimensionEnum = {
  AdUnit: {
    Type: keyMirror({
      CAMPAIGN_NAME: null,
      CAMPAIGN_ID: null,
      AD_GROUP_NAME: null,
      AD_GROUP_ID: null,
      CREATIVE_NAME: null,
      CREATIVE_ID: null,
    }),

    Name: {
      CAMPAIGN_NAME: '캠페인 이름',
      CAMPAIGN_ID: '캠페인 ID',
      AD_GROUP_NAME: '광고그룹 이름',
      AD_GROUP_ID: '광고그룹 ID',
      CREATIVE_NAME: '소재 이름',
      CREATIVE_ID: '소재 ID',
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getName(type) {
      return this.Name[type]
    },
  },

  Setting: {
    Type: keyMirror({
      CAMPAIGN_TYPE: null,
      GOAL: null,
      OBJECTIVE_DETAIL_TYPE: null,
    }),

    Name: {
      CAMPAIGN_TYPE: '캠페인 유형',
      GOAL: '캠페인 목표',
      OBJECTIVE_DETAIL_TYPE: '광고 전환 목표',
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getName(type) {
      return this.Name[type]
    },
  },

  Targeting: {
    Type: keyMirror({
      CREATIVE_FORMAT: null,
      AGE_BAND: null,
      GENDER: null,
      AGE_BAND_GENDER: null,
      LOCATION: null,
      DEVICE: null,
      PLACEMENT: null,
      DEVICE_PLACEMENT: null,
      TIME_ZONE: null,
    }),

    Name: {
      CREATIVE_FORMAT: '소재 유형',
      AGE_BAND: '연령',
      GENDER: '성별',
      AGE_BAND_GENDER: '연령 및 성별',
      LOCATION: '지역',
      DEVICE: '디바이스',
      PLACEMENT: '게재지면',
      DEVICE_PLACEMENT: '디바이스 및 게재지면',
      TIME_ZONE: '시간대',
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getName(type) {
      return this.Name[type]
    },
  },

  PeriodUnit: {
    Type: keyMirror({
      DAY: null,
      WEEK: null,
      MONTH: null,
    }),

    Name: {
      DAY: '일',
      WEEK: '주',
      MONTH: '월',
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getName(type) {
      return this.Name[type]
    },
  },
}

export default DimensionEnum
