import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    CONVERSION: null,
    VISITING: null,
    REACH: null,
    VIEW: null,
  }),

  Name: {
    CONVERSION: '전환',
    VISITING: '방문',
    REACH: '도달',
    VIEW: '조회',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  orderedValues() {
    return Object.freeze([
      this.Type.CONVERSION,
      this.Type.VISITING,
      this.Type.REACH,
      this.Type.VIEW,
    ])
  },

  getName(type) {
    return this.Name[type]
  },
}
