const SentryUtils = {
  options: {
    whitelistUrls: [/https?:\/\/[^/$\s]+(\.devel)?\.kakao\.com/],
    ignoreErrors: [
      'Network Error',
      'timeout of 0ms exceeded', // `Network Error`
      'Request aborted',
      'Request failed with status code 500',
      'Request failed with status code 401',
      'ResizeObserver loop limit exceeded', // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
      'ResizeObserver loop completed with undelivered notifications.', // KAMOQA-29846
      "Unexpected token '<'", // chunk load error
      "Cannot read property 'frames' of null", // apng component
      "Cannot read properties of null (reading 'frames')", // apng component
      "Cannot read properties of null (reading 'width')", // apng component
      'this._apng is null', // apng component
      'Object Not Found Matching Id', // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062
    ],
  },
}

export default SentryUtils
