import { MomentLoader } from './MomentLoader'
import React from 'react'

const BasicLoader = () => {
  return (
    <div className="all_loading">
      <span className="reform_area_loading">
        <span className="group_loading loading_size3">
          <MomentLoader size={12} margin={6} />
        </span>
      </span>
    </div>
  )
}

export default BasicLoader
