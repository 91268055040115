import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../utils/utils'

const Advertise = keyMirror(
  {
    GET_AD_ACCOUNT_INFO: null,

    CLEAR: null,
  },
  'ADVERTISE'
)

const initialState = fromJS({
  adAccountInfo: {},
})

export default createReducer(initialState, {
  [Advertise.GET_AD_ACCOUNT_INFO]: (state, { data }) => {
    return state.set('adAccountInfo', fromJS(data))
  },

  [Advertise.CLEAR]: state => {
    return state.merge(initialState)
  },
})

export function receiveAdvertiseAdAccountInfo(data) {
  return {
    type: Advertise.GET_AD_ACCOUNT_INFO,
    data,
  }
}

export function clearAllAdvertise() {
  return {
    type: Advertise.CLEAR,
  }
}
