import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { dateToString, keyMirror } from '../../utils/utils'
import { closeAllPopup } from '../common/mPopup'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import moment from 'moment'
import { showErrorMessage, showSuccessMessage } from '../../utils/alertUtils'
import CalendarEnum from '../../enums/CalendarEnum'
import Calendar from '../../utils/calendar'
import { RoleType } from '../common/mRole'
import { PAYMENT_CARD_STATUS } from '../../utils/paymentCard/paymentCardUtils'
import axios from 'axios'

const PaymentCard = keyMirror(
  {
    SET_PAYMENT_CARD_LIST_IN_USE: null,
    SET_PAYMENT_CARD_SUMMARY: null,

    SET_TRANSACTION_HISTORY: null,
    SET_TRANSACTION_HISTORY_SEARCH_OPTION: null,
    SET_TRANSACTION_HISTORY_CALENDAR: null,

    SET_MODIFYING_HISTORY: null,

    INIT: null,
  },
  'PAYMENT_CARD'
)

const initialState = fromJS({
  paymentCardListInUse: [], // 광고계정에 등록된 카드 리스트

  paymentCardSummary: {
    dailySpendingBudget: 0, // 일지출 한도
    availableAmount: 0, // 결제카드 사용가능금액
  },

  // 거래 내역
  transactionHistory: {
    list: {
      content: [],
      number: 0,
      size: 10,
      totalElements: 0,
    },
    searchOptions: {
      walletId: -1,
      status: [PAYMENT_CARD_STATUS.SUCCESS, PAYMENT_CARD_STATUS.FAIL].join(','),
    },
    calendar: {
      startDate: moment().subtract(6, 'days'),
      endDate: moment(),
      periodType: CalendarEnum.Preset.Type.LAST7DAYS,
      customDateDiff: null,
    },
  },
  // 변경 이력
  modifyingHistory: {
    list: {
      content: [],
      number: 0,
      size: 10,
      totalElements: 0,
    },
  },
})

export default createReducer(initialState, {
  [PaymentCard.SET_PAYMENT_CARD_LIST_IN_USE]: (state, { data }) =>
    state.set('paymentCardListInUse', fromJS(data)),

  [PaymentCard.SET_PAYMENT_CARD_SUMMARY]: (
    state,
    { dailySpendingBudget = 0, availableAmount = 0 }
  ) =>
    state.set(
      'paymentCardSummary',
      fromJS({ dailySpendingBudget, availableAmount })
    ),

  [PaymentCard.SET_TRANSACTION_HISTORY]: (state, { data }) =>
    state.setIn(['transactionHistory', 'list'], fromJS(data || {})),

  [PaymentCard.SET_TRANSACTION_HISTORY_SEARCH_OPTION]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['transactionHistory', 'searchOptions', keyPath],
      fromJS(value)
    ),

  [PaymentCard.SET_TRANSACTION_HISTORY_CALENDAR]: (
    state,
    { startDate, endDate, periodType, updateCustomDateDiff = false }
  ) => {
    return state.updateIn(['transactionHistory', 'calendar'], prevCalendar =>
      prevCalendar.withMutations(s =>
        s
          .set('startDate', startDate)
          .set('endDate', endDate)
          .set('periodType', periodType)
          .update('customDateDiff', prev =>
            updateCustomDateDiff &&
            periodType === CalendarEnum.Preset.Type.CUSTOM
              ? Math.abs(moment(startDate).diff(moment(endDate), 'd'))
              : prev
          )
      )
    )
  },

  [PaymentCard.SET_MODIFYING_HISTORY]: (state, { data }) =>
    state.setIn(['modifyingHistory', 'list'], fromJS(data || {})),

  [PaymentCard.INIT]: state => state.merge(initialState),
})

/**
 * 결제카드 리스트 조회
 */
export function getPaymentCardListInUse({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.PAYMENT_CARD_LIST_IN_USE))

    try {
      const response = await api.card.getPaymentCardListInUse(adAccountId)

      dispatch({
        type: PaymentCard.SET_PAYMENT_CARD_LIST_IN_USE,
        data: response.data || [],
      })
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.PAYMENT_CARD_LIST_IN_USE))
    }
  }
}

/**
 * 일 지출 한도 & 사용 가능액 조회
 */
export function getPaymentCardSummary({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.PAYMENT_CARD_SUMMARY))

    try {
      const [dailySpendingBudgetResponse, availableAmountResponse] =
        await axios.all([
          api.card.getPaymentCardDailySpendingBudget(adAccountId),
          api.card.getPaymentCardAvailableAmount(adAccountId),
        ])

      dispatch({
        type: PaymentCard.SET_PAYMENT_CARD_SUMMARY,
        dailySpendingBudget: dailySpendingBudgetResponse.data,
        availableAmount: availableAmountResponse.data || 0,
      })
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.PAYMENT_CARD_SUMMARY))
    }
  }
}

/**
 * 결제카드 추가
 */
export function addPaymentCard({
  adAccountId,
  autopayCardKey,
  dailySpendingBudget,
  userKakaoAccountId,
  priorityOrder,
}) {
  return async (dispatch, getState, api) => {
    const {
      role: { currentRole },
      card: { dailySpendingBudget: prevDailySpendingBudget },
    } = getState()

    dispatch(showLoading())

    try {
      await api.card.addPaymentCard(adAccountId, {
        autopayCardKey,
        dailySpendingBudget:
          dailySpendingBudget >= 0
            ? dailySpendingBudget
            : prevDailySpendingBudget,
        walletId: adAccountId,
        isMaster: [RoleType.MASTER, RoleType.OBSERVER].includes(currentRole),
        kakaoAccountId: userKakaoAccountId,
        priorityOrder,
        dspId: 'KAKAOMOMENT',
      })

      dispatch(getPaymentCardListInUse({ adAccountId }))

      dispatch(getPaymentCardSummary({ adAccountId }))

      dispatch(getPaymentCardModifyingHistory({ adAccountId }))

      dispatch(closeAllPopup())

      showSuccessMessage('카드 추가 변경사항이 적용되었습니다.')
    } catch (e) {
      showErrorMessage('카드 추가 변경사항을 적용할 수 없습니다.')
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 결제카드 삭제
 */
export function deletePaymentCard({
  adAccountId,
  autopayCardKey,
  userKakaoAccountId,
  cardKakaoAccountId,
}) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      await api.card.deletePaymentCard(adAccountId, {
        autopayCardKey,
        dspId: 'KAKAOMOMENT',
        kakaoAccountId: userKakaoAccountId,
        ownerKakaoAccountId: cardKakaoAccountId,
      })

      dispatch(getPaymentCardListInUse({ adAccountId }))

      dispatch(getPaymentCardSummary({ adAccountId }))

      dispatch(getPaymentCardModifyingHistory({ adAccountId }))

      showSuccessMessage('카드 삭제 변경사항이 적용되었습니다.')

      dispatch(closeAllPopup())
    } catch (e) {
      showErrorMessage('카드 삭제 변경사항을 적용할 수 없습니다.')
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 카드 일 지출 한도 변경
 */
export function modifyPaymentCardDailySpendingBudget({
  adAccountId,
  value,
  onSuccess,
  onError,
}) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      await api.card.modifyPaymentCardDailySpendingBudget(adAccountId, value)

      dispatch(getPaymentCardListInUse({ adAccountId }))

      dispatch(getPaymentCardSummary({ adAccountId }))

      dispatch(getPaymentCardModifyingHistory({ adAccountId }))

      if (typeof onSuccess === 'function') {
        onSuccess()
      }

      showSuccessMessage('일 지출 한도 변경사항이 적용되었습니다.')
    } catch (e) {
      if (typeof onError === 'function') {
        onError()
      }

      showErrorMessage('일 지출 한도 변경사항을 적용할 수 없습니다.')
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 대표 결제카드로 변경
 */
export function changePaymentCardPriorityToTop({
  adAccountId,
  autopayCardKey,
  kakaoAccountId,
}) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      await api.card.changePaymentCardPriorityToTop(adAccountId, {
        dspId: 'KAKAOMOMENT',
        autopayCardKey,
        kakaoAccountId,
      })

      dispatch(getPaymentCardListInUse({ adAccountId }))

      dispatch(getPaymentCardSummary({ adAccountId }))

      dispatch(getPaymentCardModifyingHistory({ adAccountId }))

      showSuccessMessage('변경사항이 적용되었습니다.')

      dispatch(closeAllPopup())
    } catch (e) {
      showErrorMessage('변경사항을 적용할 수 없습니다.')
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 결제카드 거래 내역 조회
 */
export function getPaymentCardTransactionHistory({ adAccountId, number = 0 }) {
  return async (dispatch, getState, api) => {
    const {
      card: {
        transactionHistory: {
          searchOptions: { walletId, status },
          calendar: { startDate, endDate },
        },
      },
    } = getState()

    dispatch(showLoading(LOADING_KEY.PAYMENT_CARD_TRANSACTION_HISTORY))

    try {
      const response = await api.card.getPaymentCardTransactionHistory(
        adAccountId,
        {
          dspId: 'KAKAOMOMENT',
          page: number,
          size: 10,
          walletId,
          status,
          from: dateToString(startDate),
          to: dateToString(endDate),
        }
      )

      dispatch({
        type: PaymentCard.SET_TRANSACTION_HISTORY,
        data: response.data,
      })
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.PAYMENT_CARD_TRANSACTION_HISTORY))
    }
  }
}

/**
 * 결제카드 거래 내역 검색 옵션 변경
 */
export function setPaymentCardTransactionHistorySearchOption({
  adAccountId,
  keyPath,
  value,
}) {
  return dispatch => {
    dispatch({
      type: PaymentCard.SET_TRANSACTION_HISTORY_SEARCH_OPTION,
      keyPath,
      value,
    })

    dispatch(getPaymentCardTransactionHistory({ adAccountId }))
  }
}

/**
 * 결제카드 거래 내역 캘린더 변경
 */
export function changePaymentCardTransactionHistoryCalendar({
  adAccountId,
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false,
}) {
  return dispatch => {
    dispatch({
      type: PaymentCard.SET_TRANSACTION_HISTORY_CALENDAR,
      startDate,
      endDate,
      periodType,
      updateCustomDateDiff,
    })

    dispatch(getPaymentCardTransactionHistory({ adAccountId, number: 0 }))
  }
}

/**
 * 결제카드 거래 내역 캘린더 변경 > 이전
 */
export function goPrevPaymentCardTransactionHistoryCalendar() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId, createdDate },
      },
      card: {
        transactionHistory: {
          calendar: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(
      changePaymentCardTransactionHistoryCalendar({
        adAccountId,
        startDate: start,
        endDate: end,
        periodType,
      })
    )
  }
}

/**
 * 결제카드 거래 내역 캘린더 변경 > 다음
 */
export function goNextPaymentCardTransactionHistoryCalendar() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { id: adAccountId },
      },
      card: {
        transactionHistory: {
          calendar: { startDate, endDate, periodType, customDateDiff },
        },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(
      changePaymentCardTransactionHistoryCalendar({
        adAccountId,
        startDate: start,
        endDate: end,
        periodType,
      })
    )
  }
}

/**
 * 결제카드 변경 이력
 */
export function getPaymentCardModifyingHistory({ adAccountId, number = 0 }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.PAYMENT_CARD_MODIFYING_HISTORY))

    try {
      const response = await api.card.getPaymentCardModifyingHistory(
        adAccountId,
        {
          dspId: 'KAKAOMOMENT',
          page: number,
          size: 10,
        }
      )

      dispatch({
        type: PaymentCard.SET_MODIFYING_HISTORY,
        data: response.data || {},
      })
    } catch (e) {
      // TODO
    } finally {
      dispatch(hideLoading(LOADING_KEY.PAYMENT_CARD_MODIFYING_HISTORY))
    }
  }
}

export const initPaymentCard = () => ({
  type: PaymentCard.INIT,
})
