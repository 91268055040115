import React, { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import adAccountApi from '../../../../modules-api/adAccount/adAccountApi'
import { AdAccountHelper } from '../../../../utils/helper/helper-adAccount'
import { AD_ACCOUNT_SEARCH_TYPE } from '../../../../modules/settings/account/mAccount'
import { checkEmpty } from '../../../../utils/regexUtils'

const AD_ACCOUNT_SIZE = 200

const useAdAccountQuery = ({
  adAccountId = undefined,
  pageIndex,
  searchKeyword,
  expandKeys = [],
}) => {
  const queryClient = useQueryClient()

  const [adAccounts, setAdAccounts] = useState([])
  const [totalCount, setTotalCount] = useState(0)
  const [totalPageCount, setTotalPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)

  const resetQuery = () => {
    setIsLoading(true)
    queryClient.invalidateQueries([
      'adAccountPage',
      pageIndex,
      searchKeyword,
      expandKeys,
    ])
  }

  const { data, isLoading: isQueryLoading } = useQuery(
    ['adAccountPage', pageIndex, searchKeyword, expandKeys],
    async () => {
      try {
        const data = await fetchAdAccounts()
        setIsLoading(false)
        return data
      } catch (error) {
        console.log(error)
      }
    }
  )

  useEffect(() => {
    const { content, totalElements, totalPages, number } = data || {}
    const adAccountList = searchKeyword
      ? content?.map(({ adAccount }) => adAccount)
      : content

    setAdAccounts(AdAccountHelper.ListSelector.toView(adAccountList || []))

    if (
      !checkEmpty(totalElements) &&
      !checkEmpty(totalPages) &&
      !checkEmpty(number)
    ) {
      setTotalCount(totalElements)
      setTotalPageCount(totalPages)
      setCurrentPage(number)
    }

    setIsLoading(false)
  }, [data, searchKeyword])

  const fetchAdAccounts = React.useCallback(async () => {
    if (!searchKeyword) {
      const { data } = await adAccountApi.fetchAdAccounts(adAccountId, {
        page: pageIndex,
        size: AD_ACCOUNT_SIZE,
      })
      return data
    } else {
      const { data } = await adAccountApi.fetchAdAccountMembers({
        page: pageIndex,
        size: AD_ACCOUNT_SIZE,
        searchKeyword,
        adAccountSearchType: AD_ACCOUNT_SEARCH_TYPE.AD_ACCOUNT_NAME,
      })
      return data
    }
  }, [adAccountId, pageIndex, searchKeyword])

  return {
    adAccounts,
    currentPage,
    totalCount,
    totalPageCount,
    isLoading: isQueryLoading || isLoading,
    resetQuery,
  }
}

export default useAdAccountQuery
