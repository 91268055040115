import assign from 'object-assign'
import { checkNoneEmpty } from './regexUtils'
import { naturalComparator } from './utils'

/**
 * img 태그의 frame width, height 에 맞게 원본 이미지를 `center crop` scale 한다.
 * - 무한루프에 걸리지 않도록 주의. (setState 사용 시 isMounted 체크는 필수)
 * @param intrinsicWidth {number} - 원본 이미지 width
 * @param intrinsicHeight {number} - 원본 이미지 height
 * @param frameWidth 이미지가 {number} - 놓일 프레임 width
 * @param frameHeight {number} - 이미지가 놓일 프레임 height
 */
export const ScaleImageCenterCrop = (
  intrinsicWidth,
  intrinsicHeight,
  frameWidth,
  frameHeight
) => {
  if (!checkNoneEmpty(frameWidth, frameHeight)) return {}
  if (!checkNoneEmpty(intrinsicWidth, intrinsicHeight))
    return { scaledWidth: frameWidth, scaledHeight: frameHeight }

  const frameAspect = frameWidth / frameHeight
  const imageAspect = intrinsicWidth / intrinsicHeight
  const fitHorizontal = frameAspect >= imageAspect

  const scaledWidth = fitHorizontal
    ? frameWidth
    : intrinsicWidth * (frameHeight / intrinsicHeight)

  const scaledHeight = fitHorizontal
    ? intrinsicHeight * (frameWidth / intrinsicWidth)
    : frameHeight

  const marginTop =
    scaledHeight > frameHeight ? -(scaledHeight - frameHeight) / 2 : 0

  const marginLeft =
    scaledWidth > frameWidth ? -(scaledWidth - frameWidth) / 2 : 0

  return {
    scaledWidth,
    scaledHeight,
    marginTop,
    marginLeft,
  }
}

/**
 * img 태그의 frame width, height 에 맞게 원본 이미지를 `fit center` scale 한다.
 * @param intrinsicWidth {number} - 원본 이미지 width
 * @param intrinsicHeight {number} - 원본 이미지 height
 * @param frameWidth {number} - 이미지가 놓일 프레임 width
 * @param frameHeight {number} - 이미지가 놓일 프레임 height
 */
export const ScaleImageFitCenter = (
  intrinsicWidth,
  intrinsicHeight,
  frameWidth,
  frameHeight
) => {
  if (!checkNoneEmpty(frameWidth, frameHeight)) return {}
  if (!checkNoneEmpty(intrinsicWidth, intrinsicHeight))
    return { scaledWidth: frameWidth, scaledHeight: frameHeight }

  const frameRatio = frameWidth / frameHeight // 68/48 = 1.416
  const imageRatio = intrinsicWidth / intrinsicHeight // 340/256 = 1.328
  const fitVertical = frameRatio > imageRatio

  const scaledWidth = fitVertical
    ? intrinsicWidth * (frameHeight / intrinsicHeight)
    : frameWidth

  const scaledHeight = fitVertical
    ? frameHeight
    : intrinsicHeight * (frameWidth / intrinsicWidth)

  const marginTop = fitVertical ? 0 : (frameHeight - scaledHeight) / 2

  const marginLeft = fitVertical ? (frameWidth - scaledWidth) / 2 : 0

  return {
    scaledWidth,
    scaledHeight,
    marginLeft,
    marginTop,
  }
}

/**
 * 원본 이미지를 Element backgroundImage 에 `fit center` scale drawing 할 수 있는 style.
 */
export const ScaleImageFitCenterStyle = ({
  url,
  backgroundColor = null,
  ...rest
}) => {
  return assign(
    {
      width: '100%',
      height: '100%',
      backgroundImage: `url(${url})`,
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      ...rest,
    },
    backgroundColor ? { backgroundColor } : {}
  )
}

/**
 * 원본 이미지를 Element backgroundImage 에 `center crop` scale drawing 할 수 있는 style.
 */
export const ScaleImageCenterCropStyle = ({ url, ...rest }) => {
  return {
    backgroundImage: `url(${url})`,
    backgroundSize: 'cover',
    backgroundPosition: '50% 50%',
    ...rest,
  }
}

export const sortImageGroupInDescendingOrder = (a, b) => {
  /**
   * 각 크기별로 분리된 이미지 그룹을 이미지 width, height에 따라서 내림차순 정렬
   */
  const v1 = a.first()
  const v2 = b.first()
  const w1 = v1.get('imageWidth')
  const w2 = v2.get('imageWidth')
  const h1 = v1.get('imageHeight')
  const h2 = v2.get('imageHeight')
  return w1 > w2 ? -1 : w1 < w2 ? 1 : h1 > h2 ? -1 : h1 < h2 ? 1 : 0
}

export const sortImageInDescendingOrder = (a, b) => {
  /**
   * 이미지를 width, height에 따라서 내림차순 정렬
   * 이미지 크기가 같은 경우에는 이름에 따라서 정렬
   */

  const w1 = a.get('imageWidth')
  const w2 = b.get('imageWidth')
  const h1 = a.get('imageHeight')
  const h2 = b.get('imageHeight')
  const name1 = a.get('originalFileName')
  const name2 = b.get('originalFileName')
  return w1 > w2
    ? -1
    : w1 < w2
    ? 1
    : h1 > h2
    ? -1
    : h1 < h2
    ? 1
    : naturalComparator(name1, name2)
}
