import { fromJS, Map } from 'immutable'
import {
  validateCreativeActionButton,
  validateCreativeAltText,
  validateCreativeFrequencyCap,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
  validateCreativePromotionalWord,
  validateCreativeTitle,
} from './creativeFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_IMAGE_TYPE } from '../../../utils/advertise/creativeImage'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import { checkNotEmpty, checkUrl } from '../../../utils/regexUtils'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const IMAGE_NATIVE_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: ['uploadedImages'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: ['profileImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: [
    'uploadedMultiImages',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: ['title'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: ['description'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButton'],
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],
  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: ['frequencyCap'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const IMAGE_NATIVE_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: uploadedImages => {
    if (
      !uploadedImages ||
      uploadedImages.isEmpty() ||
      uploadedImages
        .keySeq()
        .every(k => !k.startsWith(CREATIVE_IMAGE_TYPE.PROFILE))
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: profileImage => {
    if (!profileImage || profileImage?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]:
    uploadedMultiImages => {
      if (!uploadedMultiImages || uploadedMultiImages.isEmpty()) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: image => {
    const imageMap = Map.isMap(image) ? image : fromJS(image)
    if (!imageMap || imageMap?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: (title, formData) => {
    return validateCreativeTitle(title, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: (description, formData) => {
    return validateCreativePromotionalWord(description, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (profileName, formData) => {
    return validateCreativeProfileName(profileName, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          adGroup: { skanProtocolInfo },
        },
      },
    } = getState()

    return validateCreativeLandingURL({
      formData,
      campaignType,
      undefined,
      skanProtocolInfo,
    })
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: frequencyCap => {
    return validateCreativeFrequencyCap(frequencyCap)
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.IMAGE_NATIVE,
        isNew: !isModify,
      })
    )
  },
})

export { IMAGE_NATIVE_FORM_VALIDATION_KEY_PATH, IMAGE_NATIVE_FORM_VALIDATOR }
