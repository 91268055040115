import { Map } from 'immutable'
import { keyMirror } from '../../../../utils/utils'
import { checkNotEmpty } from '../../../../utils/regexUtils'

// plus friend profile targeting form
export const NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY = keyMirror({
  NEW_PLUS_FRIEND_PROFILE_ID: null,
})

export const NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY_PATH = Map({
  [NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY.PLUS_FRIEND_PROFILE_ID]: [
    'profileId',
  ],
})

const validateProfileId = (profileId, plusFriendTargetings) => {
  if (!checkNotEmpty(profileId) || profileId === -1)
    return { isValid: false, message: '카카오톡 채널을 선택하세요.' }
  if (plusFriendTargetings.some(p => p.get('profileId') === profileId)) {
    return { isValid: false, message: '이미 등록된 카카오톡 채널입니다.' }
  }
  return { isValid: true, message: '' }
}

export const NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATOR_FOR_AD_GROUP = Map({
  [NEW_PLUS_FRIEND_PROFILE_FORM_VALIDATION_KEY.PLUS_FRIEND_PROFILE_ID]: (
    profileId,
    formData
  ) => {
    const {
      targeting: { plusFriendTargetings },
    } = formData

    return validateProfileId(profileId, plusFriendTargetings)
  },
})
