import { TOASTER_BUTTON_GROUP_CLASSNAME } from './MomentToaster'

/**
 * react-hot-toast/src/core/utils.ts
 */
const prefersReducedMotion = (() => {
  // Cache result
  let shouldReduceMotion

  return () => {
    if (shouldReduceMotion === undefined) {
      const mediaQuery = matchMedia('(prefers-reduced-motion: reduce)')
      shouldReduceMotion = !mediaQuery || mediaQuery.matches
    }
    return shouldReduceMotion
  }
})()

const MomentToasterUtils = {
  /**
   * ToastBar.message 에서의 button 포함 여부를 탐색
   */
  hasButton: messageNode => {
    if (Array.isArray(messageNode?.props?.children?.props?.children)) {
      return (
        messageNode.props.children.props.children?.some(
          child => child.props?.className === TOASTER_BUTTON_GROUP_CLASSNAME
        ) ?? false
      )
    }

    return false
  },

  /**
   * react-hot-toast/src/components/toaster.tsx
   */
  getPositionStyle: ({ position, offset }) => {
    const top = position.includes('top')
    const verticalStyle = top ? { top: 0 } : { bottom: 0 }
    const horizontalStyle = position.includes('center')
      ? {
          justifyContent: 'center',
        }
      : position.includes('right')
      ? {
          justifyContent: 'flex-end',
        }
      : {}
    return {
      left: 0,
      right: 0,
      display: 'flex',
      position: 'absolute',
      transition: prefersReducedMotion()
        ? undefined
        : `all 230ms cubic-bezier(.21,1.02,.73,1)`,
      transform: `translateY(${offset * (top ? 1 : -1)}px)`,
      ...verticalStyle,
      ...horizontalStyle,
    }
  },
}

export { MomentToasterUtils }
