import moment from 'moment'
import { InputFileUtil } from '../fileUtils'
import { showErrorMessage } from '../alertUtils'
import AdViewButtonEnum from '../../enums/AdViewButtonEnum'
import {
  checkEmpty,
  checkSecureUrl,
  checkTalkServiceUrl,
  checkUrl,
  hasFullWidthWhitespace,
  hasOnlyAlphanumeric,
  hasSpecialCharacter,
} from '../regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../../validators/validation'
import { convertNewLinesToSpaces } from '../stringUtils'
import AdViewEnum from '../../enums/AdViewEnum'
import AdViewTemplateEnum from '../../enums/AdViewTemplateEnum'
import { FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/fullViewImageFormValidatorV2'
import { FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/fullViewVideoFormValidatorV2'
import { FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/fullViewScrollFormValidatorV2'
import { COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/compactViewImageFormValidatorV2'
import { COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/compactViewVideoFormValidatorV2'
import { COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_V2 } from '../../validators/adView/compactViewCarouselFormValidatorV2'
import { getApiPhase } from '../app/services/kakaoMoment'
import { CreativeAssetUtils } from '../../components/AdvertiseV2/Creative/creativeAssetUtils'

export const getAdViewDateFormat = date =>
  moment(date).format(moment.HTML5_FMT.DATE)

export const getAdViewDateTimeFormat = date =>
  moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL).replace('T', ' ')

export const adViewFileUploadValidation = {
  image: {
    validType: /image\/jpg|image\/jpeg|image\/png/,
    validSize: 400 * 1024,
    validator(files) {
      if (files.length === 0) return false

      if (!InputFileUtil.isValidType(files, this.validType)) {
        showErrorMessage(
          '등록 가능한 이미지 포맷을 확인하신 후 다시 시도하세요.'
        )
        return false
      }

      if (!InputFileUtil.isValidSize(files, this.validSize)) {
        showErrorMessage(
          '등록 가능한 이미지 용량을 확인하신 후 다시 시도하세요.'
        )
        return false
      }

      return true
    },
  },
  video: {
    // 코어에서 업로드 허용된 mimeType 기준
    validType: new RegExp(CreativeAssetUtils.Video.allowedMimeTypes.join('|')),
    validSize: CreativeAssetUtils.Video.fileSizeMax,
    validator(files) {
      if (files.length === 0) return false

      if (!InputFileUtil.isValidType(files, this.validType)) {
        showErrorMessage(
          '등록 가능한 동영상 포맷을 확인하신 후 다시 시도하세요.'
        )
        return false
      }

      if (!InputFileUtil.isValidSize(files, this.validSize)) {
        showErrorMessage(
          '등록 가능한 동영상 용량을 확인하신 후 다시 시도하세요.'
        )
        return false
      }

      return true
    },
  },
}
const TALK_SERVICE_LABEL = {
  TALK_PURCHASE: '메이커스, 톡스토어',
  TALK_GIFT: '선물하기',
  TALK_ORDER: '주문하기',
  PRODUCT_SUBSCRIBE: '상품구독하기',
  GET_EMOTICON: '이모티콘 받기',
  VIEW_LIVE: 'LIVE보기',
}
const talkStoreDomains = ['store.kakao.com', 'makers.kakao.com']
const talkGiftDomain = ['gift-talk.kakao.com']
const talkOrderDomain = ['order.kakao.com']
const productSubscribeDomain = ['ss.kakao.com', 'on.kakao.com']
const emoticonDomain = ['emoticon.kakao.com/items']
const liveViewDomain = ['pcms.kakao.com/pcms', 'shoppinglive.kakao.com']

export const adViewButtonValidation = (buttonKey, button) => {
  const {
    type,
    label,
    inputValue,
    landingUrl,
    selectedPlusFriend,
    selectedBizForm,
  } = button

  if (type === AdViewButtonEnum.Type.NONE) {
    return IS_VALID()
  }

  // 자세히 보기 랜딩 url에 톡서비스 url 포함되었는지 체크
  const checkNotAllowedDomain = inputValue => {
    return [
      ...talkStoreDomains,
      ...talkGiftDomain,
      ...talkOrderDomain,
      ...productSubscribeDomain,
      ...emoticonDomain,
      ...liveViewDomain,
    ].some(v => inputValue.indexOf(v) > -1)
  }

  // 톡서비스 유형에서 필수 입력 체크
  const checkAllowedTalkServiceDomain = (type, value) => {
    const domainChecker = v =>
      checkTalkServiceUrl(value) && String(value).trim().indexOf(v) > -1

    switch (type) {
      case AdViewButtonEnum.Type.TALK_PURCHASE: {
        return talkStoreDomains.some(domainChecker)
      }

      case AdViewButtonEnum.Type.TALK_GIFT: {
        return talkGiftDomain.every(domainChecker)
      }

      case AdViewButtonEnum.Type.TALK_ORDER: {
        return talkOrderDomain.every(domainChecker)
      }

      case AdViewButtonEnum.Type.PRODUCT_SUBSCRIBE: {
        return productSubscribeDomain.some(domainChecker)
      }

      case AdViewButtonEnum.Type.GET_EMOTICON: {
        return emoticonDomain.every(domainChecker)
      }

      case AdViewButtonEnum.Type.VIEW_LIVE: {
        return liveViewDomain.some(domainChecker)
      }

      default: {
        return false
      }
    }
  }

  const notInputLandingUrl = () => {
    const buttonText = buttonKey === 'button1' ? '버튼1' : '버튼2'
    return IS_NOT_VALID(`${buttonText} 랜딩 URL을 입력하세요.`)
  }

  if (checkEmpty(type)) {
    return notInputLandingUrl()
  }

  if (hasFullWidthWhitespace(inputValue)) {
    return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
  }

  switch (type) {
    /**
     *  - 채널 추가하기
     */
    case AdViewButtonEnum.Type.PLUSFRIEND_ADD: {
      if (selectedPlusFriend?.isEmpty()) {
        return notInputLandingUrl()
      }

      break
    }

    /**
     *  - 톡으로 문의하기
     */
    case AdViewButtonEnum.Type.TALK_CONTACT: {
      if (selectedPlusFriend?.isEmpty() || checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (hasSpecialCharacter(convertNewLinesToSpaces(inputValue), '!?')) {
        return IS_NOT_VALID('정상적인 형식의 챗봇 키워드를 입력하세요.')
      }

      break
    }

    /**
     * 톡서비스 도메인 한정 (구매하기, 선물하기, 주문하기, 상품구독하기, 이모티콘 받기, LIVE보기)
     */
    case AdViewButtonEnum.Type.TALK_PURCHASE:
    case AdViewButtonEnum.Type.TALK_GIFT:
    case AdViewButtonEnum.Type.TALK_ORDER:
    case AdViewButtonEnum.Type.PRODUCT_SUBSCRIBE:
    case AdViewButtonEnum.Type.GET_EMOTICON:
    case AdViewButtonEnum.Type.VIEW_LIVE: {
      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (!checkAllowedTalkServiceDomain(type, inputValue)) {
        return IS_NOT_VALID(
          `http:// 혹은 https:// 를 포함하여 정상적인 형식의 ${TALK_SERVICE_LABEL[type]} 랜딩 URL을 입력하세요.`
        )
      }

      break
    }

    /**
     *  - 톡에서 회원가입
     */
    case AdViewButtonEnum.Type.TALK_REGISTRATION: {
      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (!checkSecureUrl(inputValue)) {
        return IS_NOT_VALID(
          'https:// 를 포함하여 정상적인 형식의 랜딩 URL을 입력하세요.'
        )
      }

      break
    }

    /**
     *  - 톡으로 공유하기
     */
    case AdViewButtonEnum.Type.TALK_SHARE: {
      if (checkEmpty(landingUrl)) {
        return notInputLandingUrl()
      }

      if (hasFullWidthWhitespace(landingUrl)) {
        return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
      }

      break
    }

    /**
     * 비즈니스폼 유형
     *  - 톡에서 설문하기
     *  - 톡에서 시승신청
     *  - 톡에서 예약하기
     *  - 톡에서 응모하기
     *  - 톡에서 참여하기
     */
    case AdViewButtonEnum.Type.TALK_IN_SURVEY:
    case AdViewButtonEnum.Type.TALK_TEST_DRIVE:
    case AdViewButtonEnum.Type.TALK_IN_RESERVATION:
    case AdViewButtonEnum.Type.TALK_IN_APPLY:
    case AdViewButtonEnum.Type.TALK_IN_PARTICIPATE: {
      if (!selectedBizForm || selectedBizForm.isEmpty()) {
        return notInputLandingUrl()
      }

      break
    }

    case AdViewButtonEnum.Type.CUSTOM: {
      if (checkEmpty(label)) {
        const buttonText = buttonKey === 'button1' ? '버튼1' : '버튼2'
        return IS_NOT_VALID(`${buttonText} 이름을 입력하세요.`)
      }

      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      break
    }

    /**
     *  - 톡캘린더 저장하기
     */
    case AdViewButtonEnum.Type.TALK_CALENDAR_SAVE: {
      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (!hasOnlyAlphanumeric(inputValue)) {
        return IS_NOT_VALID('정상적인 형식의 이벤트ID를 입력하세요.')
      }

      break
    }

    /**
     *  - 자세히 보기
     */
    case AdViewButtonEnum.Type.VIEW_DETAIL: {
      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (!checkUrl(inputValue)) {
        return IS_NOT_VALID(
          'http:// 혹은 https:// 를 포함하여 정상적인 형식의 랜딩 URL을 입력하세요.'
        )
      }

      if (checkNotAllowedDomain(inputValue)) {
        if (buttonKey === 'button1') {
          return IS_NOT_VALID(
            '목록에 있는 버튼에 해당하는 서비스를 제외한 카카오톡 서비스 랜딩 URL을 입력하세요.'
          )
        } else
          return IS_NOT_VALID(
            '카카오톡 내 서비스를 제외한 랜딩 URL을 입력하세요.'
          )
      }
      break
    }

    /**
     *  기타 아웃랜딩 케이스
     */
    default: {
      if (checkEmpty(inputValue)) {
        return notInputLandingUrl()
      }

      if (!checkUrl(inputValue)) {
        return IS_NOT_VALID(
          'http:// 혹은 https:// 를 포함하여 정상적인 형식의 랜딩 URL을 입력하세요.'
        )
      }

      if (checkNotAllowedDomain(inputValue)) {
        return IS_NOT_VALID(
          '카카오톡 내 서비스를 제외한 랜딩 URL을 입력하세요.'
        )
      }
    }
  }

  return IS_VALID()
}

export const getAdViewFormValidationKey = (adViewType, templateType) => {
  if (adViewType === AdViewEnum.Type.FULL) {
    if (templateType === AdViewTemplateEnum.Type.IMAGE)
      return FULL_VIEW_IMAGE_FORM_VALIDATION_KEY_V2
    if (templateType === AdViewTemplateEnum.Type.VIDEO)
      return FULL_VIEW_VIDEO_FORM_VALIDATION_KEY_V2
    if (templateType === AdViewTemplateEnum.Type.SCROLL)
      return FULL_VIEW_SCROLL_FORM_VALIDATION_KEY_V2
  } else {
    if (templateType === AdViewTemplateEnum.Type.IMAGE)
      return COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2
    if (templateType === AdViewTemplateEnum.Type.VIDEO)
      return COMPACT_VIEW_VIDEO_FORM_VALIDATION_KEY_V2
    if (templateType === AdViewTemplateEnum.Type.CAROUSEL)
      return COMPACT_VIEW_CAROUSEL_FORM_VALIDATION_KEY_V2
  }

  return ''
}

export const getAdViewAppScheme = button => {
  const { type, inputValue, selectedPlusFriend } = button

  const apiPhaseScheme = __PRODUCTION__ || __STAGE__ ? 'kakaoplus' : 'alphaplus'
  const encodedValue = encodeURIComponent(inputValue || '')

  const { searchId } = selectedPlusFriend || {}

  switch (type) {
    case AdViewButtonEnum.Type.TALK_SHARE: {
      // 톡으로 공유하기
      return 'javascript:AD_VIEW_KAKAO_LINK.send()'
    }

    case AdViewButtonEnum.Type.PLUSFRIEND_ADD: {
      // 채널 추가하기
      return `${apiPhaseScheme}://plusfriend/home/@${searchId}/add?click_id={click_id}&ad_service_id=10004&logReferer=moment_adview`
    }

    case AdViewButtonEnum.Type.TALK_CONTACT: {
      // supplement 이하 값만 인코딩 처리
      // 톡으로 문의하기
      return `${apiPhaseScheme}://plusfriend/talk/bot/@${searchId}/${encodedValue}?skip_sendmessage=true&supplement=%7B%22referer%22%3A%22AD%22%7D`
    }

    case AdViewButtonEnum.Type.TALK_IN_SURVEY:
    case AdViewButtonEnum.Type.TALK_TEST_DRIVE:
    case AdViewButtonEnum.Type.TALK_IN_RESERVATION:
    case AdViewButtonEnum.Type.TALK_IN_APPLY:
    case AdViewButtonEnum.Type.TALK_IN_PARTICIPATE: {
      // 비즈폼 유형은 프론트에서 랜딩URL 만들지 않음. (https://jira.daumkakao.com/browse/KAMOQA-29113)
      return ''
    }

    case AdViewButtonEnum.Type.TALK_CALENDAR_SAVE: {
      // 톡캘린더 저장하기
      return `kakaotalk://calendar/event/${encodedValue}`
    }

    /**
     * 직접 입력하기일 경우 스킴 or webUrl 뭐가 들어올지 모르니 입력한 값을 그대로 저장. 인코딩 처리는 알아서 맡긴다 :)
     */
    case AdViewButtonEnum.Type.CUSTOM: {
      return inputValue
    }

    case AdViewButtonEnum.Type.NONE: {
      return null
    }

    default: {
      // 아웃랜딩
      return `kakaotalk://web/open?url=${encodedValue}&close=false`
    }
  }
}

export const getAdViewLandingUrlPlaceholder = type => {
  switch (type) {
    case AdViewButtonEnum.Type.TALK_CONTACT: {
      return '랜딩에 사용할 챗봇 키워드를 입력하세요.'
    }
    case AdViewButtonEnum.Type.TALK_PURCHASE: {
      return '메이커스, 톡스토어 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.TALK_GIFT: {
      return '선물하기 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.TALK_ORDER: {
      return '주문하기 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.PRODUCT_SUBSCRIBE: {
      return '구독ON 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.GET_EMOTICON: {
      return '브랜드 이모티콘 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.TALK_REGISTRATION: {
      return 'https:// 로 시작하는 랜딩 URL을 입력하세요.'
    }
    case AdViewButtonEnum.Type.TALK_CALENDAR_SAVE: {
      return '이벤트ID를 입력하세요.'
    }
    case AdViewButtonEnum.Type.VIEW_LIVE: {
      return '쇼핑라이브 랜딩 URL을 입력하세요.'
    }
    default: {
      return '랜딩 URL을 입력하세요.'
    }
  }
}
