import { fromJS, Map, Set } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceAtMost, keyedComparator, keyMirror } from '../../utils/utils'
import {
  createCreativeImageGroupId,
  getCreativePreviewBannerPageSize,
} from '../../utils/advertise/creativeImage'
import {
  sortImageGroupInDescendingOrder,
  sortImageInDescendingOrder,
} from '../../utils/imageUtils'

const BannerCreate = keyMirror(
  {
    ADD_IMAGES: null,
    ADD_IMAGE: null,

    SELECT_ITEM: null,
    REMOVE_ITEM: null,
    REMOVE_ALL_ITEMS: null,
    TOGGLE_GROUP: null,

    CHANGE_PREVIEW_PAGE_NUMBER: null,
    CHANGE_PREVIEW_PAGE_SIZE: null,

    SET_FROM_COMPLETED_LIST: null,

    CLEAR: null,
  },
  'BANNER_CREATE'
)

const initialState = fromJS({
  images: {},

  viewState: {
    newestImage: {}, // 직전 업로드 된 이미지들(real width, height 기준으로 정렬. 자동 스크롤을 위함)

    selectedGroupId: null, // {width}x{height}

    selectedListIndex: -1,

    openedGroupIds: Set(),

    previewPaging: {
      number: 0,
      pageSize: 2,
    },
  },
})

const getNextPreviewPageNumber = (listIndex, pageSize) => {
  return listIndex < pageSize ? 0 : Math.floor(listIndex / pageSize)
}

export default createReducer(initialState, {
  [BannerCreate.ADD_IMAGES]: (state, { images }) => {
    /**
     * GroupId 가 이미 존재하면 해당 list 에 추가, 없으면 GroupId 로 새 list 생성.
     * Real width, height 기준 오름차순으로 정렬.
     * 신규 추가된 데이터 중 첫번째 GroupId를 기본 선택.
     * 신규 업로드된 GroupId 를 열림 처리.
     * 선택된 GroupId 로 real size 를 얻어낸 후 pageSize 업데이트.
     */

    // 업로드된 image 더미를 real width, height, original file name 으로 정렬
    const newestImages = images
      .sort(sortImageInDescendingOrder)
      .map(v => v.set('groupId', createCreativeImageGroupId(v)))

    const newestImage = newestImages.first()
    const newestImageGroupId = createCreativeImageGroupId(newestImage)

    return state.withMutations(s => {
      s.update('images', images => {
        const nextImages = newestImages.reduce((prev, image) => {
          const imageGroupId = createCreativeImageGroupId(image)
          return prev.has(imageGroupId)
            ? prev.update(imageGroupId, list => list.push(fromJS(image)))
            : prev.set(imageGroupId, fromJS([image]))
        }, images)
        return nextImages.sort(sortImageGroupInDescendingOrder)
      })
        .update(s => {
          return s
            .setIn(['viewState', 'selectedGroupId'], newestImageGroupId)
            .setIn(['viewState', 'selectedListIndex'], -1)
            .updateIn(['viewState', 'openedGroupIds'], v =>
              v.union(newestImages.map(image => image.get('groupId')))
            )
        })
        .update(s => {
          const {
            viewState: { selectedGroupId },
          } = s

          const pageSize = getCreativePreviewBannerPageSize(selectedGroupId)
          return s.setIn(
            ['viewState', 'previewPaging'],
            Map({ number: 0, pageSize })
          )
        })
        .setIn(['viewState', 'newestImage'], newestImage)
    })
  },

  [BannerCreate.ADD_IMAGE]: (
    state,
    { image, groupId = undefined, index = undefined }
  ) => {
    return state.withMutations(s => {
      return s.update('images', images => {
        if (groupId) {
          return images.has(groupId)
            ? images.update(groupId, list => {
                return index >= 0
                  ? list.set(index, fromJS(image)).filter(Boolean)
                  : list.push(fromJS(image))
              })
            : images.set(groupId, fromJS([image]))
        } else {
          const imageGroupId = createCreativeImageGroupId(image)
          return images.has(imageGroupId)
            ? images.update(imageGroupId, list => list.push(fromJS(image)))
            : images.set(imageGroupId, fromJS([image]))
        }
      })
    })
  },

  [BannerCreate.SELECT_ITEM]: (state, { groupId = null, index = -1 }) => {
    /**
     * 선택한 GroupId 의 첫번째 항목에서 real width, height 얻어내기.
     * 선택한 GroupId 를 '선택' 및 '열림' 처리
     * list index parameter 가 있다면 해당 index '선택' 처리 없으면 -1(선택없음. 그룹만 선택된 상태)
     * Preview pageSize 업데이트(real width, height 이용함. 없으면 기본 2).
     * Preview pageNumber 업데이트.
     */
    return state.withMutations(s =>
      s.update('viewState', v =>
        v
          .set('selectedGroupId', groupId)
          .update('openedGroupIds', v => v.add(groupId))
          .set('selectedListIndex', index)
          .setIn(
            ['previewPaging', 'pageSize'],
            getCreativePreviewBannerPageSize(groupId)
          )
          .update('previewPaging', v => {
            const { pageSize } = v
            return v.set('number', getNextPreviewPageNumber(index, pageSize))
          })
      )
    )
  },

  [BannerCreate.REMOVE_ITEM]: (state, { groupId = null, index = -1 }) => {
    /**
     * -- GROUP 전체 삭제 --
     * Index 가 없을 경우 Group 삭제로 인식(순서 중요).
     * 1. 현재 선택된 GroupId 일 경우, selectedListIndex 초기화.
     * 2. 현재 선택된 GroupId 일 경우,
     *  - 데이터에 남은 마지막 그룹일 경우, selectedGroupId 를 null 처리.
     *  - 정렬 우선 순위가 높은 GroupId 가 존재할 경우 해당 GroupId 를 `선택` 처리.
     *  - 정렬 우선 순위가 높은 GroupId 가 존재하지 않을 경우 후 순위의 GroupId 를 `선택` 처리.
     * 3. `열림 Set` 에서 GroupId 제거
     * 4. 해당 GroupId 항목 전체 제거
     */
    const { images } = state

    if (index === -1) {
      return state.withMutations(s => {
        s.update('viewState', v => {
          const { selectedGroupId } = v
          return v.update('selectedListIndex', i =>
            selectedGroupId === null || selectedGroupId === groupId ? -1 : i
          )
        })
          .updateIn(['viewState', 'selectedGroupId'], v => {
            const selfGroup = v === groupId
            if (selfGroup) {
              const imageKeySeq = images.keySeq()
              if (images.size === 1) return null // last survivor
              if (imageKeySeq.first() === groupId) {
                return imageKeySeq.skipWhile(v => v === groupId).first()
              } else {
                return imageKeySeq.takeUntil(v => v === groupId).last()
              }
            }
            return v
          })
          .updateIn(['viewState', 'openedGroupIds'], v =>
            v.has(groupId) ? v.remove(groupId) : v
          )
          .update('images', v => v.delete(groupId))
      })
    }

    /**
     * -- GROUP 하위 항목 삭제 --
     * 해당 group 의 image list 에서 제거
     * image list 가 빈 경우 image 항목 자체 제거
     */
    const _images = images
      .update(groupId, list => list.delete(index))
      .filter(l => l.size > 0)

    /**
     * 1. 삭제 후 GroupId 에 대한 하위 항목이 더이상 존재하지 않고 `열림 Set` 에 존재할 경우 제거.
     * 2. GroupId 가 선택된 상태이고,
     *  - 하위 항목이 여전히 존재하면, 현재 list index 를 자연스럽게 업데이트(항상 list 최대 size 보다 낮도록).
     *  - 하위 항목이 더이상 존재하지 않으면 현재 list index 초기화.
     * 3. GroupId 가 선택된 상태가 아니고,
     *  - 하위 항목이 여전히 존재하면 현재 list index 그대로 유지.
     *  - 하위 항목이 더이상 존재하지 않으면 현재 list index 초기화.
     * 4. GroupId 가 선택된 상태가 아니고 하위 항목이 여전히 존재하면 선택된 GroupId 유지.
     * 5. 선택된 GroupId 를 자연스럽게 업데이트(정렬 순위가 높은 GroupId 가 존재하면 그것을 선택, 없으면 후 순위의 GroupId 선택).
     * 6. 선택된 list index 와 pageSize 기준으로 paging number 업데이트.
     */
    return state.withMutations(s => {
      s.set('images', _images)
        .updateIn(['viewState', 'openedGroupIds'], v =>
          _images.has(groupId) ? v : v.remove(groupId)
        )
        .update('viewState', v => {
          const { selectedGroupId } = v
          const selfGroup = selectedGroupId === groupId
          return v
            .update('selectedListIndex', i => {
              if (selfGroup) {
                return _images.has(groupId)
                  ? coerceAtMost(i, _images.get(groupId).size - 1)
                  : -1
              }
              return i
            })
            .update('selectedGroupId', v => {
              if (selfGroup) {
                if (_images.has(v)) return v
                const imageKeySeq = images.keySeq()
                if (imageKeySeq.first() === groupId) {
                  return imageKeySeq.skipWhile(v => v === groupId).first()
                } else {
                  return imageKeySeq.takeUntil(v => v === groupId).last()
                }
              }
              return v
            })
        })
        .update('viewState', v => {
          const {
            selectedListIndex,
            previewPaging: { pageSize },
          } = v
          return v.setIn(
            ['previewPaging', 'number'],
            getNextPreviewPageNumber(selectedListIndex, pageSize)
          )
        })
    })
  },

  [BannerCreate.REMOVE_ALL_ITEMS]: state => {
    return state.merge(initialState)
  },

  [BannerCreate.TOGGLE_GROUP]: (state, { groupId }) => {
    return state.withMutations(s => {
      s.updateIn(['viewState', 'openedGroupIds'], v =>
        v.has(groupId) ? v.delete(groupId) : v.add(groupId)
      )
    })
  },

  [BannerCreate.CHANGE_PREVIEW_PAGE_NUMBER]: (state, { number }) => {
    return state.setIn(['viewState', 'previewPaging', 'number'], number)
  },

  [BannerCreate.CHANGE_PREVIEW_PAGE_SIZE]: (state, { pageSize }) => {
    return state.setIn(['viewState', 'previewPaging', 'pageSize'], pageSize)
  },

  [BannerCreate.CLEAR]: state => state.merge(initialState),

  [BannerCreate.SET_FROM_COMPLETED_LIST]: (state, { images }) => {
    // original fileName 기준으로 각 항목의 image list 재정렬(Immutable.ListLayout).
    const sortedImages = images.map(v =>
      v.sort((a, b) => keyedComparator(a, b, 'originalFileName'))
    )

    return state.withMutations(s =>
      s
        .set('images', sortedImages)
        .set('viewState', initialState.get('viewState'))
        .setIn(['viewState', 'selectedGroupId'], sortedImages.keySeq().first())
        .setIn(['viewState', 'openedGroupIds'], Set.fromKeys(sortedImages))
    )
  },
})

export function addBannerImages(images) {
  return {
    type: BannerCreate.ADD_IMAGES,
    images,
  }
}

export function addBannerImage(image, groupId = undefined, index = undefined) {
  return {
    type: BannerCreate.ADD_IMAGE,
    image,
    groupId,
    index,
  }
}

export function selectMultiBannerImageItem(groupId, index) {
  return {
    type: BannerCreate.SELECT_ITEM,
    groupId,
    index,
  }
}

export function removeMultiBannerImageItem(groupId, index) {
  return {
    type: BannerCreate.REMOVE_ITEM,
    groupId,
    index,
  }
}

export function removeAllMultiBannerImageItem() {
  return {
    type: BannerCreate.REMOVE_ALL_ITEMS,
  }
}

export function toggleMultiBannerImageGroup(groupId) {
  return {
    type: BannerCreate.TOGGLE_GROUP,
    groupId,
  }
}

export function changeMultiBannerPageNumber(number) {
  return {
    type: BannerCreate.CHANGE_PREVIEW_PAGE_NUMBER,
    number,
  }
}

export function changeMultiBannerPageSize(pageSize) {
  return {
    type: BannerCreate.CHANGE_PREVIEW_PAGE_SIZE,
    pageSize,
  }
}

export function setMultiBannerImagesFromCompleteList(images) {
  return {
    type: BannerCreate.SET_FROM_COMPLETED_LIST,
    images,
  }
}

export function clearBannerImageCreate() {
  return {
    type: BannerCreate.CLEAR,
  }
}
