import { Map } from 'immutable'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import { CREATIVE_IMAGE_TYPE } from '../../../utils/advertise/creativeImage'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import {
  validateCreativeActionButton,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
} from './creativeFormValidatorV2'
import FormUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'
import {
  checkNotEmpty,
  checkUrl,
  looslyCheckHttpsUrlWithParamRegex,
} from '../../../utils/regexUtils'

const PRODUCT_CATALOG_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PRODUCT_CATALOG_SET_ID]: [
    'catalogProductSetId',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: ['uploadedImages'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: ['profileImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButton'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
  [CREATIVE_FORM_VALIDATION_KEY.TRACKING_URL]: ['trackingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
})

const PRODUCT_CATALOG_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PRODUCT_CATALOG_SET_ID]:
    catalogProductSetId => {
      if (!catalogProductSetId || catalogProductSetId < 0) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: uploadedImages => {
    if (
      !uploadedImages ||
      uploadedImages?.isEmpty() ||
      uploadedImages
        .keySeq()
        .every(k => !k.startsWith(CREATIVE_IMAGE_TYPE.PROFILE))
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: modifyImages => {
    if (!modifyImages || modifyImages?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (profileName, formData) => {
    return validateCreativeProfileName(profileName, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL({ formData, campaignType })
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: name => {
    return validateCreativeName(name)
  },

  [CREATIVE_FORM_VALIDATION_KEY.TRACKING_URL]: trackingUrl => {
    if (checkNotEmpty(trackingUrl)) {
      if (!looslyCheckHttpsUrlWithParamRegex(trackingUrl)) {
        return IS_NOT_VALID(
          'https:// 를 포함하여 정상적인 형식의 트래킹 URL을 입력하세요.'
        )
      }
      return FormUtils.Url.validateProductTrackingUrl(trackingUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return FormUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return FormUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },
})

export {
  PRODUCT_CATALOG_FORM_VALIDATION_KEY_PATH,
  PRODUCT_CATALOG_FORM_VALIDATOR,
}
