import {
  DEMOGRAPHIC_TARGET_GENDER,
  LOCATION_KEY,
  LOCATION_TYPE,
} from '../advertise/targeting'
import { List, Map } from 'immutable'
import { keyedComparator } from '../utils'
import { checkNoneEmpty } from '../regexUtils'
import DemoGraphicAgeEnum from '../../enums/DemoGraphicAgeEnum'

function DemoAgeHelper(items) {
  return {
    getOrdered() {
      const seq = DemoGraphicAgeEnum.values()
      return items.sort((a, b) => seq.indexOf(a) - seq.indexOf(b))
    },

    toString() {
      if (!items || items.size === 0) return '전체'
      return this.getOrdered()
        .map(v => DemoGraphicAgeEnum.getName(v))
        .filter(Boolean)
        .join(', ')
    },

    has(value) {
      return items.find(v => v === value) !== undefined
    },

    addOrDelete(value) {
      if (this.has(value)) {
        return items.size > 1 ? items.filter(v => v !== value) : items
      } else {
        return items.push(value)
      }
    },

    // 성인 타게팅
    createAdult() {
      return List(Object.keys(DemoGraphicAgeEnum.Type).filter(v => v !== '15'))
    },
  }
}

function DemoGenderHelper(items) {
  return {
    getOrdered() {
      const seq = Object.keys(DEMOGRAPHIC_TARGET_GENDER)
      return items.sort((a, b) => seq.indexOf(a) - seq.indexOf(b))
    },

    has(value) {
      return items.find(v => v === value) !== undefined
    },

    toString() {
      if (!items || items.size === 0) return '전체'
      return this.getOrdered()
        .map(v => {
          if (v === DEMOGRAPHIC_TARGET_GENDER.M) return '남성'
          if (v === DEMOGRAPHIC_TARGET_GENDER.F) return '여성'
          return '' // assertion!
        })
        .filter(Boolean)
        .join(', ')
    },

    addOrDelete(value) {
      if (this.has(value)) {
        return items.size > 1 ? items.filter(v => v !== value) : items
      } else {
        return items.push(value)
      }
    },
  }
}

function DemoLocationHelper(items) {
  return {
    getOrdered() {
      return items.sort((a, b) => keyedComparator(a, b, 'description'))
    },

    getCategoryV2() {
      if (!items || items.size === 0) return LOCATION_TYPE.ALL

      const values = items.map(v => v.get('value'))

      return Object.keys(LOCATION_KEY)
        .filter(v => v !== LOCATION_KEY.Z)
        .every(v => values.includes(v))
        ? LOCATION_TYPE.ALL
        : LOCATION_TYPE.DISTRICT
    },

    has(value) {
      return items.find(v => v.get('value') === value) !== undefined
    },

    // 전국
    createNational() {
      return List(
        Object.keys(LOCATION_KEY)
          .filter(v => v !== LOCATION_KEY.Z)
          .map(v => Map({ value: v }))
      )
    },

    // 국내(전국) + 해외
    createGlobal() {
      return List()
    },

    // 대전/세종에 대한 예외 처리. G, Q를 함께 관리한다.
    exceptionForGQ(locations) {
      if (
        locations.find(v => v.get('value') === LOCATION_KEY.G) !== undefined
      ) {
        return locations.find(v => v.get('value') === LOCATION_KEY.Q) !==
          undefined
          ? locations
          : locations.push(Map({ value: LOCATION_KEY.Q }))
      } else {
        return locations.filter(v => v.get('value') !== LOCATION_KEY.Q)
      }
    },

    getLocationDistrictMap() {
      const locationDistrictMap = {
        depth1: [],
        depth2: [],
        depth3: [],
      }

      this.getOrdered().forEach(item => {
        const { depth1Name, depth2Name, depth3Name } = item

        if (checkNoneEmpty(depth1Name, depth2Name, depth3Name)) {
          locationDistrictMap['depth3'].push(
            `${depth3Name}(${depth1Name} ${depth2Name})`
          )
        } else if (checkNoneEmpty(depth1Name, depth2Name)) {
          locationDistrictMap['depth2'].push(`${depth2Name}(${depth1Name})`)
        } else {
          locationDistrictMap['depth1'].push(depth1Name)
        }
      })

      return locationDistrictMap
    },
  }
}

export { DemoAgeHelper, DemoGenderHelper, DemoLocationHelper }
