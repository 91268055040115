import { createReducer } from 'redux-immutablejs'
import { fromJS, Map, Set } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { toSettingsReviewPath } from '../../../utils/router/routeUtils'
import {
  handleReviewDocumentException,
  REVIEW_DOCUMENT_ERROR_TYPE,
} from './mReview'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'

const ReviewDocumentDetail = keyMirror({
  INIT_REVIEW_DOCUMENT_DETAIL_INFO: null,
  GET_REVIEW_DOCUMENT_DETAIL_INFO: null,
  INIT_REVIEW_DOCUMENT_VIEW_MODE: null,
  SET_REVIEW_DOCUMENT_VIEW_MODE: null,
  GET_UPLOADED_REVIEW_DOCUMENT_LIST: null,
})

const initialState = fromJS({
  reviewDocumentDetailInfo: {
    categoryName: '',
    totalReviewDocuments: 0,
  },
  uploadedDocuments: {},
  openModeSet: Set(),
})

export default createReducer(initialState, {
  [ReviewDocumentDetail.INIT_REVIEW_DOCUMENT_DETAIL_INFO]: state =>
    state.set(
      'reviewDocumentDetailInfo',
      initialState.get('reviewDocumentDetailInfo')
    ),

  [ReviewDocumentDetail.GET_REVIEW_DOCUMENT_DETAIL_INFO]: (state, { result }) =>
    state.set('reviewDocumentDetailInfo', fromJS(result || {})),

  [ReviewDocumentDetail.GET_UPLOADED_REVIEW_DOCUMENT_LIST]: (
    state,
    { result }
  ) => state.set('uploadedDocuments', fromJS(result || [])),

  [ReviewDocumentDetail.INIT_REVIEW_DOCUMENT_VIEW_MODE]: state =>
    state.withMutations(s => {
      s.set('openModeSet', Set()).set('uploadedDocuments', Map())
    }),

  [ReviewDocumentDetail.SET_REVIEW_DOCUMENT_VIEW_MODE]: (state, { result }) =>
    state.set('openModeSet', Set(result)),
})

export function initReviewDocumentDetailInfo() {
  return { type: ReviewDocumentDetail.INIT_REVIEW_DOCUMENT_DETAIL_INFO }
}

function receiveReviewDocumentDetailInfo(result) {
  return { type: ReviewDocumentDetail.GET_REVIEW_DOCUMENT_DETAIL_INFO, result }
}

export function getReviewDocumentDetailInfo(adAccountId, creativeCategoryId) {
  return (dispatch, getState, api) => {
    return api.review
      .getReviewDocumentByCategory(adAccountId, creativeCategoryId)
      .then(response => {
        const data = response.data
        if (data.documents?.length === 0) {
          dispatch(RouterV2.replace(toSettingsReviewPath(adAccountId)))
        } else {
          const sortedData = fromJS(data).withMutations(s => {
            s.set('totalReviewDocuments', s.get('documents').size).update(
              'documents',
              documents =>
                documents
                  .sort(
                    (a, b) =>
                      b
                        .get('createdDate')
                        .localeCompare(a.get('createdDate')) ||
                      a
                        .get('originalFileName')
                        .localeCompare(b.get('originalFileName'), 'en')
                  )
                  .groupBy(sortedDocument =>
                    sortedDocument.get('categoryDocumentId')
                  )
            )
          })

          dispatch(receiveReviewDocumentDetailInfo(sortedData))
        }
      })
      .catch(e => handleReviewDocumentException(e))
  }
}

/**
 * 8.15 심사 문서 다중 삭제 (카테고리 삭제)
 */
export function deleteReviewDocumentCategory(
  adAccountId,
  regCategoryDocumentId
) {
  return (dispatch, getState, api) => {
    return api.review
      .deleteReviewDocumentCategory(adAccountId, regCategoryDocumentId)
      .then(() => {
        dispatch(RouterV2.replace(toSettingsReviewPath(adAccountId)))
      })
      .catch(e =>
        handleReviewDocumentException(
          e,
          REVIEW_DOCUMENT_ERROR_TYPE.CATEGORY_DELETE_FAIL
        )
      )
  }
}

/**
 * 8.16 심사 문서 단일 삭제
 */
export function deleteReviewDocument(
  adAccountId,
  creativeCategoryId,
  regCategoryDocumentId
) {
  return (dispatch, getState, api) => {
    return api.review
      .deleteReviewDocument(adAccountId, regCategoryDocumentId)
      .then(() => {
        dispatch(getReviewDocumentDetailInfo(adAccountId, creativeCategoryId))
      })
      .catch(e =>
        handleReviewDocumentException(
          e,
          REVIEW_DOCUMENT_ERROR_TYPE.FILE_DELETE_FAIL
        )
      )
  }
}

export function initOpenModeSet() {
  return { type: ReviewDocumentDetail.INIT_REVIEW_DOCUMENT_VIEW_MODE }
}

export function setOpenModeSet(result) {
  return {
    type: ReviewDocumentDetail.SET_REVIEW_DOCUMENT_VIEW_MODE,
    result,
  }
}

export function receiveUploadedReviewDocumentList(result) {
  return {
    type: ReviewDocumentDetail.GET_UPLOADED_REVIEW_DOCUMENT_LIST,
    result,
  }
}
