import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import axios from 'axios/index'
import { defaultAdGroupOptimizationStatusInfo } from '../../components/AdvertiseV2/CampaignAndGroupV2/CampaignAdGroupSideBar/OptimizationStatusPopulation'

const Population = keyMirror({
  GET_COMBINED_POPULATION: null,

  GET_MULTIPLE_CUSTOM_TARGET_POPULATION: null,

  GET_SINGLE_PF_GROUP_TARGET_POPULATION: null,
  GET_MULTIPLE_PF_GROUP_TARGET_POPULATION: null,

  CLEAR_ALL_POPULATION: null,
})

const initialState = fromJS({
  // 절대 null 이 와서는 안된다.
  combinedPopulation: {
    populationScore: null,
  },
  customTargetPopulations: [], // [{ targetingCategory: 'retargeting_webs', targetingValue: 'pUdjZhuH1SHTsrk-PKr63MFPF0s', inclusionType: 'INCLUDE', populationScore: 0 },...]
  plusFriendGroupTargetPopulations: [], // [{ groupKey: 'ALL', inclusionType: 'INCLUDE', plusFriendProfileId: 55261}, ...]
  singlePopulation: {},

  optimizationStatus: defaultAdGroupOptimizationStatusInfo,
})

export default createReducer(initialState, {
  [Population.GET_COMBINED_POPULATION]: (state, { data }) =>
    state.set('combinedPopulation', fromJS(data)),

  [Population.GET_MULTIPLE_CUSTOM_TARGET_POPULATION]: (state, { data }) =>
    state.set('customTargetPopulations', fromJS(data)),

  [Population.GET_SINGLE_PF_GROUP_TARGET_POPULATION]: (state, { data }) =>
    state.set('singlePopulations', fromJS(data)),

  [Population.GET_MULTIPLE_PF_GROUP_TARGET_POPULATION]: (state, { data }) =>
    state.set('plusFriendGroupTargetPopulations', fromJS(data)),

  [Population.CLEAR_ALL_POPULATION]: state => state.merge(initialState),
})

export function receiveCombinedPopulation(data) {
  return {
    type: Population.GET_COMBINED_POPULATION,
    data,
  }
}

export const LOADING_POPULATION = {
  populationScore: null,
}
export const FAILED_POPULATION = { populationScore: -1 }

export function getCombinedPopulationV2(adAccountId, adGroupForm) {
  return (dispatch, getState, api) => {
    dispatch(receiveCombinedPopulation(LOADING_POPULATION))

    const cancelKey = 'getCombinedPopulationV2'
    const source = createCancellation(cancelKey)

    return api.population
      .fetchCombinedTargetingPopulationByAdGroupFormV2(
        adAccountId,
        adGroupForm,
        source
      )
      .then(response => {
        dispatch(receiveCombinedPopulation(response.data || LOADING_POPULATION))
      })
      .catch(e => {
        // 선 요청이 cancel 되었을 경우 null 을 리턴해서 loadingView 가 유지되도록 함.
        const score = axios.isCancel(e) ? LOADING_POPULATION : FAILED_POPULATION

        dispatch(receiveCombinedPopulation(score))
      })
      .finally(() => {
        deleteCancellation(source)
      })
  }
}

function receiveMultipleCustomTargetPopulation(data) {
  return {
    type: Population.GET_MULTIPLE_CUSTOM_TARGET_POPULATION,
    data,
  }
}

/**
 * Multiple 단일 모수 조회 V2
 */
export function getMultipleCustomTargetPopulationV2(
  adAccountId,
  valueSet,
  receiver
) {
  return (dispatch, getState, api) => {
    return api.population
      .fetchTargetingPopulationByValueSetV2(adAccountId, valueSet)
      .then(response => {
        if (typeof receiver === 'function') {
          receiver(response.data || [])
        } else {
          dispatch(receiveMultipleCustomTargetPopulation(response.data || []))
        }
      })
      .catch(e => {
        console.log(e.message)
      })
  }
}

function receiveMultiplePfGroupTargetPopulation(data) {
  return {
    type: Population.GET_MULTIPLE_PF_GROUP_TARGET_POPULATION,
    data,
  }
}

export function getMultiplePfGroupTargetPopulation(
  adAccountId,
  valueSet,
  receiver
) {
  return (dispatch, getState, api) => {
    return api.population
      .fetchTargetingPopulationByValueSetV2(adAccountId, valueSet)
      .then(response => {
        dispatch(receiveMultiplePfGroupTargetPopulation(response.data || []))

        if (typeof receiver === 'function') {
          dispatch(receiver(response.data || []))
        }
      })
      .catch(e => {
        const failedPopulationResponse = valueSet.map(body => ({
          ...body,
          populationScore: -1,
        }))

        if (typeof receiver === 'function') {
          dispatch(receiver(failedPopulationResponse))
        }

        console.log(e.message)
      })
  }
}

export function clearAllPopulation() {
  return {
    type: Population.CLEAR_ALL_POPULATION,
  }
}
