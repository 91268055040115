import { keyMirror } from '../utils/utils'

const CouponBookTitleTypeEnum = {
  Type: keyMirror({
    DISCOUNT_PRICE: null, // 할인 금액 유형
    DISCOUNT_RATE: null, // 할인율 유형
    FREE_SHIPPING: null, // 배송비 할인 유형
    FREE_GIFT: null, // 무료증정 유형
    UPGRADE: null, // 업그레이드 유형
    CHANNEL_COUPON_BOOK: null,
    CUSTOM_COUPON_BOOK: null,
  }),

  Name: {
    DISCOUNT_PRICE: '할인 금액',
    DISCOUNT_RATE: '할인율',
    FREE_SHIPPING: '배송비 할인',
    FREE_GIFT: '무료증정',
    UPGRADE: '업그레이드',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}

export default CouponBookTitleTypeEnum
