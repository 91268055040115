import { Map } from 'immutable'
import {
  checkEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
} from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  personalMessageCouponBookAssetGroupValidation,
  personalMessageFormButtonAssetGroupValidation,
  personalMessageFormTitleValidation,
} from './personalMessageFormValidator'
import { PERSONAL_MESSAGE_FORM_VALIDATION_KEY } from './personalMessageFormValidationKey'
import { PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE } from './personalMessageFormValidationMessage'
import VideoEnum from '../../enums/VideoEnum'

export const PERSONAL_MESSAGE_BASIC_TEXT_FORM_VALIDATOR = Map({
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM]: ({
    video,
    messageVideoTitle,
  }) => {
    if (
      checkNotEmpty(video) &&
      VideoEnum.isTitleRequired(video?.get('videoType'))
    ) {
      if (checkEmpty(messageVideoTitle)) {
        return IS_NOT_VALID(
          PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED
        )
      } else if (hasFullWidthWhitespace(messageVideoTitle)) {
        return IS_NOT_VALID(
          PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      }
    }
    return IS_VALID()
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.TITLE]: ({ title, creativeFormat }) => {
    return personalMessageFormTitleValidation(title, creativeFormat)
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]:
    buttonAssetGroups => {
      return personalMessageFormButtonAssetGroupValidation(buttonAssetGroups)
    },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.COUPON_BOOK_ASSET_GROUPS]:
    couponBookAssetGroups => {
      return personalMessageCouponBookAssetGroupValidation(
        couponBookAssetGroups
      )
    },
})
