import { fromJS, Set } from 'immutable'
import { Trim } from '../../../utils/formTrim'
import { Validation } from '../../../validators/validation'
import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import {
  PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY,
  PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY_PATH,
  PLUS_FRIEND_GROUP_FORM_VALIDATOR,
} from '../../../validators/targeting/plusFriendGroupFormValidator'
import { closeAllPopup } from '../../common/mPopup'
import {
  handlePlusFriendGroupFileException,
  PLUS_FRIEND_GROUP_FILE_UPLOAD_TRIM_KEY_PATH_LIST,
  setIsValidPlusFriendGroupByKey,
} from '../mPlusFriendGroup'
import {
  getPlusFriendGroupFileListWithPaging,
  initPlusFriendGroupListWithPaging,
} from './mPlusFriendGroupList'
import { hideLoading, showLoading } from '../../common/mLoading'
import PlusFriendGroupEnum from '../../../enums/PlusFriendGroupEnum'
import { initUploadedCustomerIdentificationFiles } from './mPlusFriendGroupUpload'

const PlusFriendGroupCreateKey = keyMirror({
  GET_SELECTED_PLUS_FRIEND_PROFILE: null,

  INIT_PLUS_FRIEND_GROUP_REGISTRATION_FORM: null,
  CHANGE_PLUS_FRIEND_GROUP_REGISTRATION_FORM_BY_KEY: null,

  INIT_FILE_UPLOAD_REGISTRATION_FORM: null,
  INIT_MESSAGE_RETARGET_REGISTRATION_FORM: null,

  SET_SELECTED_CAMPAIGN_SET: null,
})

const initialState = fromJS({
  validationErrorKeysForPlusFriendGroup: {},
  plusFriendGroupRegistrationForm: {
    name: '',
    fileType: PlusFriendGroupEnum.PlusFriendGroupType.PHONE_NUMBER,
    fileUrl: null,
    plusFriendProfileId: null,
    appId: null,
    adGroups: [],
  },
  selectedPlusFriendProfile: {
    id: -1,
  },
  selectedCampaignSet: Set(),
})

export default createReducer(initialState, {
  [PlusFriendGroupCreateKey.GET_SELECTED_PLUS_FRIEND_PROFILE]: (
    state,
    { data }
  ) => {
    return state.set('selectedPlusFriendProfile', data)
  },

  [PlusFriendGroupCreateKey.INIT_PLUS_FRIEND_GROUP_REGISTRATION_FORM]: state => {
    return state
      .set(
        'plusFriendGroupRegistrationForm',
        initialState.get('plusFriendGroupRegistrationForm')
      )
      .set(
        'selectedPlusFriendProfile',
        initialState.get('selectedPlusFriendProfile')
      )
      .set('selectedCampaignSet', initialState.get('selectedCampaignSet'))
  },

  [PlusFriendGroupCreateKey.CHANGE_PLUS_FRIEND_GROUP_REGISTRATION_FORM_BY_KEY]: (
    state,
    { data }
  ) => {
    return state.setIn(
      ['plusFriendGroupRegistrationForm', data.key],
      fromJS(data.value)
    )
  },

  [PlusFriendGroupCreateKey.INIT_FILE_UPLOAD_REGISTRATION_FORM]: state => {
    return state.set(
      'validationErrorKeysForPlusFriendGroup',
      initialState.get('validationErrorKeysForPlusFriendGroup')
    )
  },

  [PlusFriendGroupCreateKey.INIT_MESSAGE_RETARGET_REGISTRATION_FORM]: state => {
    return state
      .set('selectedCampaignSet', initialState.get('selectedCampaignSet'))
      .setIn(
        ['plusFriendGroupRegistrationForm', 'adGroups'],
        initialState.getIn(['plusFriendGroupRegistrationForm', 'adGroups'])
      )
  },

  [PlusFriendGroupCreateKey.SET_SELECTED_CAMPAIGN_SET]: (state, { value }) => {
    return state.set('selectedCampaignSet', value)
  },
})

export function submitPlusFriendGroupFileRegistrationForm(adAccountId) {
  return async (dispatch, getState, api) => {
    const {
      plusFriendGroup: {
        plusFriendGroupUpload: {
          uploadedCustomerIdentificationFilesResultData: { successFileUrl },
        },
        plusFriendGroupCreate: { plusFriendGroupRegistrationForm },
      },
    } = getState()

    const formData = Trim(
      plusFriendGroupRegistrationForm.set('fileUrl', successFileUrl),
      PLUS_FRIEND_GROUP_FILE_UPLOAD_TRIM_KEY_PATH_LIST
    )

    const validationResult = Validation(
      formData,
      PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY,
      PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY_PATH,
      PLUS_FRIEND_GROUP_FORM_VALIDATOR,
      getState,
      setIsValidPlusFriendGroupByKey,
      dispatch
    )

    if (!validationResult) {
      return
    }

    dispatch(showLoading())

    try {
      await api.plusFriendGroup.submitPlusFriendGroupFileRegistrationForm(
        adAccountId,
        formData.toJS()
      )
      dispatch(initPlusFriendGroupListWithPaging())
      dispatch(getPlusFriendGroupFileListWithPaging(adAccountId))
      dispatch(closeAllPopup())
    } catch (e) {
      handlePlusFriendGroupFileException(dispatch, e)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function getSelectedPlusFriendProfile(data) {
  return {
    type: PlusFriendGroupCreateKey.GET_SELECTED_PLUS_FRIEND_PROFILE,
    data,
  }
}

export function initPlusFriendGroupFileRegistrationForm() {
  return {
    type: PlusFriendGroupCreateKey.INIT_PLUS_FRIEND_GROUP_REGISTRATION_FORM,
  }
}

export function changePlusFriendGroupFileRegistrationFormByKey(key, value) {
  return {
    type:
      PlusFriendGroupCreateKey.CHANGE_PLUS_FRIEND_GROUP_REGISTRATION_FORM_BY_KEY,
    data: { key, value },
  }
}

function initFileUploadRegistrationForm() {
  return {
    type: PlusFriendGroupCreateKey.INIT_FILE_UPLOAD_REGISTRATION_FORM,
  }
}

function initMessageRetargetRegistrationForm() {
  return {
    type: PlusFriendGroupCreateKey.INIT_MESSAGE_RETARGET_REGISTRATION_FORM,
  }
}

export function initFileUploadTypeRegistrationForm() {
  return dispatch => {
    dispatch(initFileUploadRegistrationForm())
    dispatch(initUploadedCustomerIdentificationFiles())
  }
}

export function initMessageRetargetTypeRegistrationForm() {
  return dispatch => {
    dispatch(initMessageRetargetRegistrationForm())
  }
}

export function setSelectedCampaignSet(value) {
  return {
    type: PlusFriendGroupCreateKey.SET_SELECTED_CAMPAIGN_SET,
    value,
  }
}
