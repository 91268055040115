const MESSAGE_FORM_VALIDATION_MESSAGE = {
  IS_REQUIRED: '필수 입력 항목입니다.',
  LANDING_URL:
    'http:// 혹은 https:// 를 포함하여 정상적인 형식의 URL을 입력하세요.',
  LINK_IS_NOT_AVAILABLE: '링크는 입력할 수 없습니다.',
  HAS_FULL_WIDTH_WHITE_SPACE: '전각 공백문자는 입력할 수 없습니다.',
  HAS_WHITE_SPACE_BY_URL: '랜딩 URL에는 공백 및 줄바꿈을 포함할 수 없습니다.',
  IS_NOT_ALLOWED_ZERO_PRICE: '가격 정보는 0으로 입력할 수 없습니다.',
  IS_NOT_ALLOWED_ZERO_RATE: '할인율 정보는 0으로 입력할 수 없습니다.',
  IS_NOT_ALLOWED_TV_LANDING_URL:
    '카카오TV URL을 랜딩 URL로 입력할 수 없습니다.',
}

export { MESSAGE_FORM_VALIDATION_MESSAGE }
