const KakaoSentry = {
  production:
    'https://1edfbe65febb41ad9c4a90a388984bf3@aem-kakao-collector.onkakao.net/1024',
  stage:
    'https://2f039b3b79ff49f4aaac73d142f6b41a@aem-kakao-collector.onkakao.net/1055',
  sandbox:
    'https://66cc50af0d5349f8b7e1300e9f33090a@aem-kakao-collector.onkakao.net/1080',
  develop:
    'https://ca10ad6173a7481687cde2f93e1fdd6c@aem-kakao-collector.onkakao.net/1105',
}

export const getKakaoSentryURL = () => {
  if (__PRODUCTION__) {
    return KakaoSentry.production
  } else if (__STAGE__) {
    return KakaoSentry.stage
  } else if (__SANDBOX__) {
    return KakaoSentry.sandbox
  } else if (__DEV__) {
    return KakaoSentry.develop
  } else {
    return ''
  }
}
