import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import moment from 'moment'
import CalendarEnum from '../../enums/CalendarEnum'
import Calendar from '../../utils/calendar'
import { invalidateDashboardTableRows } from './mDashboardTable'
import {
  fetchDashboardChartMetricSum,
  invalidateDashboardSummaryChart,
} from './mDashboardSummary'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageSession,
} from '../../utils/storage/storageFactoryImpl'

export const DashboardCalendar = keyMirror(
  {
    SET: null,
    UPDATE: null,
    UPDATE_BY_LEAST_DATE: null,
  },
  'DASHBOARD_CALENDAR'
)

const initialState = fromJS({
  startDate: moment(),
  endDate: moment(),
  periodType: CalendarEnum.Preset.Type.TODAY,
  customDateDiff: null, // preset = CUSTOM 일 때, 최초 선택한 date diff
})

export default createReducer(initialState, {
  [DashboardCalendar.SET]: (state, { calendarState }) => {
    return state.merge(fromJS(calendarState))
  },

  [DashboardCalendar.UPDATE]: (
    state,
    { startDate, endDate, periodType, updateCustomDateDiff = false }
  ) => {
    return state.withMutations(s =>
      s
        .set('startDate', startDate)
        .set('endDate', endDate)
        .set('periodType', periodType)
        .update('customDateDiff', prevCustomDateDiff =>
          updateCustomDateDiff && periodType === CalendarEnum.Preset.Type.CUSTOM
            ? Math.abs(moment(startDate).diff(moment(endDate), 'd')) // CUSTOM 일 경우 date diff 를 기억 후 간편 이동 시 이용한다(간편 이동 시 diff 갱신하지 않음).
            : prevCustomDateDiff
        )
    )
  },

  [DashboardCalendar.UPDATE_BY_LEAST_DATE]: (state, { leastDate }) => {
    return state.update('startDate', prevStartDate =>
      Calendar.coerceAtLeast(moment(prevStartDate), moment(leastDate))
    )
  },
})

export function initDashboardCalendarByStorage({ adAccountId }) {
  return dispatch => {
    // from session storage
    const calendarStateJson = DashboardStorageSession.get(
      DASHBOARD_STORAGE_ITEM.CALENDAR
    )?.[adAccountId]

    if (calendarStateJson) {
      const startDate = moment(calendarStateJson.startDate)
      const endDate = moment(calendarStateJson.endDate)
      const periodType = calendarStateJson.periodType
      const customDateDiff = calendarStateJson.customDateDiff

      if (startDate && endDate && periodType) {
        dispatch({
          type: DashboardCalendar.SET,
          calendarState: {
            startDate,
            endDate,
            periodType,
            customDateDiff,
          },
        })
        return
      }
    }

    dispatch(
      updateDashboardCalendar({
        startDate: moment(),
        endDate: moment(),
        periodType: CalendarEnum.Preset.Type.TODAY,
        invalidate: false,
      })
    )
  }
}

/**
 * 대시보드 테이블 캘린더 데이터 변경.
 */
export function updateDashboardCalendar({
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false,
  invalidate = true,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: DashboardCalendar.UPDATE,
      startDate,
      endDate,
      periodType,
      updateCustomDateDiff,
    })

    if (invalidate) {
      dispatch(invalidateDashboardTableRows())
      dispatch(invalidateDashboardSummaryChart())
      dispatch(fetchDashboardChartMetricSum())
    }

    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        calendar,
      },
    } = getState()

    DashboardStorageSession.update(
      DASHBOARD_STORAGE_ITEM.CALENDAR,
      (obj = {}) => {
        if (adAccountId > 0) {
          obj[adAccountId] = calendar.toJS()
        }
        return obj
      }
    )
  }
}

export function updateDashboardCalendarByLeastDate({ leastDate }) {
  return {
    type: DashboardCalendar.UPDATE_BY_LEAST_DATE,
    leastDate,
  }
}

export function goPrevDashboardCalendar() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { createdDate },
        },
        calendar: { startDate, endDate, periodType, customDateDiff },
      },
    } = getState()

    const leastDate = Calendar.coerceAtLeast(
      createdDate,
      moment().subtract(2, 'y')
    )

    if (moment(startDate).isSameOrBefore(leastDate, 'd')) return

    switch (periodType) {
      case CalendarEnum.Preset.Type.ALL: {
        break
      }
      case CalendarEnum.Preset.Type.TODAY:
      case CalendarEnum.Preset.Type.YESTERDAY: {
        const nextDate = Calendar.coerceAtLeast(
          moment(startDate).subtract(1, 'd'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextDate,
            endDate: nextDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST7DAYS: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(startDate).subtract(1, 'd'),
          leastDate
        )
        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).subtract(6, 'd'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LASTWEEK:
      case CalendarEnum.Preset.Type.THISWEEK: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(endDate).subtract(1, 'w').endOf('isoWeek'),
          leastDate
        )
        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).startOf('isoWeek'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST14DAYS: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(startDate).subtract(1, 'd'),
          leastDate
        )
        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).subtract(13, 'd'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST30DAYS: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(startDate).subtract(1, 'd'),
          leastDate
        )
        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).subtract(29, 'd'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LASTMONTH:
      case CalendarEnum.Preset.Type.THISMONTH: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(endDate).subtract(1, 'M').endOf('M'),
          leastDate
        )

        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).startOf('M'),
          leastDate
        )

        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.CUSTOM: {
        const nextEndDate = Calendar.coerceAtLeast(
          moment(startDate).subtract(1, 'd'),
          leastDate
        )
        const nextStartDate = Calendar.coerceAtLeast(
          moment(nextEndDate).subtract(customDateDiff, 'd'),
          leastDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      default: {
        break
      }
    }
  }
}

export function goNextDashboardCalendar() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        calendar: { startDate, endDate, periodType, customDateDiff },
      },
    } = getState()

    const mostDate = moment()

    if (moment(endDate).isSameOrAfter(mostDate, 'd')) return

    switch (periodType) {
      case CalendarEnum.Preset.Type.ALL: {
        break
      }
      case CalendarEnum.Preset.Type.TODAY:
      case CalendarEnum.Preset.Type.YESTERDAY: {
        const nextDate = Calendar.coerceAtMost(
          moment(startDate).add(1, 'd'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextDate,
            endDate: nextDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST7DAYS: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(endDate).add(1, 'd'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).add(6, 'd'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LASTWEEK:
      case CalendarEnum.Preset.Type.THISWEEK: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(startDate).add(1, 'w').startOf('isoWeek'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).endOf('isoWeek'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST14DAYS: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(endDate).add(1, 'd'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).add(13, 'd'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LAST30DAYS: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(endDate).add(1, 'd'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).add(29, 'd'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.LASTMONTH:
      case CalendarEnum.Preset.Type.THISMONTH: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(startDate).add(1, 'M').startOf('M'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).endOf('M'),
          mostDate
        )

        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      case CalendarEnum.Preset.Type.CUSTOM: {
        const nextStartDate = Calendar.coerceAtMost(
          moment(endDate).add(1, 'd'),
          mostDate
        )
        const nextEndDate = Calendar.coerceAtMost(
          moment(nextStartDate).add(customDateDiff, 'd'),
          mostDate
        )
        dispatch(
          updateDashboardCalendar({
            startDate: nextStartDate,
            endDate: nextEndDate,
            periodType,
          })
        )
        break
      }
      default: {
        break
      }
    }
  }
}
