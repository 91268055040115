import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPostForm,
  fetchPut,
  queryString,
} from '../../utils/fetchUtils'
import { CreativeHelper } from '../../utils/helper/helper-creative'
import { v4 as uuid } from 'uuid'
import KakaoStoreEnum from '../../enums/KakaoStoreEnum'
import RequestLock from '../../utils/requestLock'

/**
 * 19.1 메시지 템플릿 단건 조회. (+ 미리보기 사용)
 * @param adAccountId
 * @param messageTemplateId
 * @return {Promise}
 */

function fetchMessageByTemplateId(adAccountId, messageTemplateId) {
  return fetchGet(
    `${CONTEXTPATH}/messageTemplate/${messageTemplateId}?type=NO_AUTH`,
    adAccountId
  )
}

/**
 * 19.2 메시지 템플릿 리스트 조회(페이징)
 * @param adAccountId
 * @param page
 * @return {Promise}
 */
function fetchMessageList(adAccountId, page) {
  return fetchGet(
    `${CONTEXTPATH}/messageTemplate?page=${page}&size=10`,
    adAccountId
  )
}

/**
 * 19.3 메시지 템플릿 생성
 * @param adAccountId
 * @param body
 * @return {Promise}
 */
function createMessageTemplate(adAccountId, body) {
  return fetchPost(`${CONTEXTPATH}/messageTemplate`, body, adAccountId)
}

/**
 * 19.4 메시지 템플릿 수정
 * @param adAccountId
 * @param messageTemplateId
 * @param body
 * @return {Promise}
 */
function modifyMessageTemplate(adAccountId, messageTemplateId, body) {
  return fetchPut(
    `${CONTEXTPATH}/messageTemplate/${messageTemplateId}`,
    body,
    adAccountId
  )
}

/**
 * 19.5 메시지 템플릿 삭제
 * @param adAccountId
 * @param messageTemplateId
 * @return {Promise}
 */
function deleteMessage(adAccountId, messageTemplateId) {
  return fetchDelete(
    `${CONTEXTPATH}/messageTemplate/${messageTemplateId}`,
    null,
    adAccountId
  )
}

/**
 * 19.6 소재 생성에서 메시지 템플릿 리스트 조회(더보기 형태)
 * @param adAccountId
 * @param profileId
 * @parm adGroupId
 * @param limit
 * @param lastIndex
 * @return {Promise}
 */
function fetchMessageListByProfileId(
  adAccountId,
  profileId,
  adGroupId,
  limit,
  lastIndex = undefined
) {
  const lastIndexParams = lastIndex ? `&lastIndex=${lastIndex}` : ''
  return fetchGet(
    `${CONTEXTPATH}/messageTemplate/profiles/${profileId}?adGroupId=${adGroupId}&limit=${limit}${lastIndexParams}`,
    adAccountId
  )
}

/**
 * 19.8 애드뷰를 사용중인 메시지 리스트 조회
 * @param adAccountId
 * @param adViewId
 * @param page
 * @return {Promise}
 */
function fetchMessageListByAdviewId(adAccountId, adViewId, page = 0) {
  return fetchGet(
    `${CONTEXTPATH}/messageTemplate/linkagedAdViews?adViewItemId=${adViewId}&page=${page}&size=10`,
    adAccountId
  )
}

/**
 * 19.9 미리보기에서 테스트 발송
 */
function submitTestMessage({ adAccountId, ageVerification, requestBody }) {
  return RequestLock.acquire({
    key: 'submitTestMessage',
    executor: done =>
      fetchPost(
        `${CONTEXTPATH}/messageTemplate/testMessages?ageVerification=${ageVerification}`,
        requestBody,
        adAccountId
      ).finally(done),
  })
}

/**
 * 5.31 특정 메시지를 사용 중인 비즈보드 소재 목록 조회
 * @param adAccountId
 * @param messageTemplateId
 * @param page
 * @return {Promise}
 */
function fetchBizBoardListByMessageTemplateId(
  adAccountId,
  messageTemplateId,
  page
) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/bizboard/linkagedMessage?messageTemplateId=${messageTemplateId}&page=${page}&size=10`,
    adAccountId
  )
}

/**
 * 1.25 kakao 계정에 등록된 카카오톡 채널의 소식 모두 조회
 * @param adAccountId
 * @param profileId
 * @param lastSort
 * @param limit
 * @param page
 * @return {Promise}
 */
function getMessageLandingChannelPosts(
  adAccountId,
  profileId,
  lastSort = undefined,
  limit = 20,
  page = 0
) {
  return fetchGet(
    `${CONTEXTPATH}/dspAccounts/channel/${profileId}/posts/withOptions?${queryString(
      {
        hasMessageOnlyPost: 'y',
        hasPublished: 'y',
        lastSort,
        limit,
        page,
      }
    )}`,
    adAccountId
  )
}

/**
 * 1.26 kakao 계정에 등록된 카카오톡 채널의 사용 가능한 쿠폰 조회
 * @param adAccountId
 * @param profileId
 * @param limit
 * @param page
 * @return {Promise}
 */
function getMessageLandingChannelCoupons(
  adAccountId,
  profileId,
  limit = 20,
  page = 0
) {
  return fetchGet(
    `${CONTEXTPATH}/dspAccounts/channel/${profileId}/coupons?${queryString({
      limit,
      page,
    })}`,
    adAccountId
  )
}

/**
 * 1.27 쿠폰 단일 상세 조회
 */
function getTalkChannelCoupon(adAccountId, profileId, couponId) {
  return fetchGet(
    `${CONTEXTPATH}/dspAccounts/channel/${profileId}/coupon/${couponId}`,
    adAccountId
  )
}

/**
 * 1.28 소식 단일 상세 조회
 */
function getTalkChannelPosts(adAccountId, profileId, postId) {
  return fetchGet(
    `${CONTEXTPATH}/dspAccounts/channel/${profileId}/post/${postId}`,
    adAccountId
  )
}

/**
 * 5.1.7 메시지 말풍선 이미지 업로드
 * @param adAccountId
 * @param formData
 * @param onProgress
 * @param cancelTokenSource
 * @param creativeFormat
 * @return {Promise}
 */
function uploadMessageImages(
  adAccountId,
  formData,
  onProgress,
  cancelTokenSource,
  creativeFormat
) {
  return fetchPostForm(
    `${CONTEXTPATH}/creatives/upload/chatbubbleImages`,
    formData,
    adAccountId,
    onProgress,
    cancelTokenSource
  ).then(response => {
    if (response?.data) {
      response.data.successFiles =
        response.data.successFiles?.map(file => {
          return { ...CreativeHelper.Image.toAPI(file), imageUUID: uuid() }
        }) || []
    }
    return response
  })
}

/**
 * 5.2.7 메시지 말풍선 비디오 등록
 */
function uploadMessageVideo(
  adAccountId,
  videoData = {
    id: '',
    isLive: true,
    isLoad: true,
    isLink: true,
    thumbnail: '',
  }
) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/chatbubbleVideos`,
    {
      ...videoData,
      thumbnail: videoData.thumbnail
        ? videoData.thumbnail.startsWith('http')
          ? videoData.thumbnail
          : `http:${videoData.thumbnail}`
        : undefined,
    },
    adAccountId
  )
}

/**
 * 5.2.7.1 메시지 말풍선 비디오 공용 채널 등록 (등록 시점에 blind(일부공개) 처리)
 */
function uploadMessageVideoShared(adAccountId, videoData) {
  return fetchPost(
    `${CONTEXTPATH}/creatives/video/chatbubbleVideos/shared`,
    videoData,
    adAccountId
  )
}

/**
 * 5.1.8 메시지 말풍선 이미지 업로드(by url)
 */
function uploadMessageVideoThumbnail(
  adAccountId,
  creativeFormat,
  thumbnailUrl = ''
) {
  const videoThumbnail = {
    creativeFormat,
    fileUrls: [
      thumbnailUrl.startsWith('http') ? thumbnailUrl : `http:${thumbnailUrl}`,
    ],
  }

  return fetchPost(
    `${CONTEXTPATH}/creatives/upload/chatbubbleImages?by=URL`,
    videoThumbnail,
    adAccountId
  )
}

/**
 * 19.10 비즈보드, 애드뷰에서 메시지 템플릿 리스트 조회(더보기 형태) - 애드뷰 랜딩을 포함한 메시지는 제외
 * @param adAccountId
 * @param profileId
 * @param limit
 * @param lastIndex
 * @returns {AxiosPromise}
 */
function fetchMessageListExcludeAdviewByProfileId(
  adAccountId,
  profileId,
  limit,
  lastIndex = undefined
) {
  const lastIndexParams = lastIndex ? `&lastIndex=${lastIndex}` : ''
  return fetchGet(
    `${CONTEXTPATH}/messageTemplate/profiles/${profileId}?filterType=excludeAdView&limit=${limit}${lastIndexParams}`,
    adAccountId
  )
}

/**
 * 톡스토어 통합 판매점 정보 조회 API 추가
 * @param adAccountId
 * @param profileId
 * @param storeType (TALK_STORE)
 * @returns {AxiosPromise}
 */
function getTalkChannelStoreInfoByProfileId(
  adAccountId,
  profileId,
  storeType = KakaoStoreEnum.Type.TALK_STORE
) {
  return fetchGet(
    `${CONTEXTPATH}/dspAccounts/channel/${profileId}/stores/${storeType}`,
    adAccountId
  )
}

/**
 * 고객센터 전화번호 등록 API
 * @param adAccountId
 * @param profileId
 * @param body
 * @return {Promise}
 */
function addTalkChannelCsInfo(adAccountId, profileId, body) {
  return fetchPut(
    `${CONTEXTPATH}/dspAccounts/plusFriend/profiles/${profileId}/csInfo`,
    body,
    adAccountId
  )
}

function validateSpamMessage(adAccountId, messageTemplate) {
  return fetchPost(
    `${CONTEXTPATH}/messageTemplate/validateSpam`,
    messageTemplate,
    adAccountId
  )
}

export default {
  fetchMessageByTemplateId,
  fetchMessageList,
  deleteMessage,
  fetchMessageListByProfileId,
  fetchMessageListByAdviewId,
  submitTestMessage,
  fetchBizBoardListByMessageTemplateId,
  getMessageLandingChannelPosts,
  getMessageLandingChannelCoupons,
  createMessageTemplate,
  uploadMessageImages,
  modifyMessageTemplate,
  uploadMessageVideo,
  uploadMessageVideoShared,
  uploadMessageVideoThumbnail,
  getTalkChannelCoupon,
  getTalkChannelPosts,
  fetchMessageListExcludeAdviewByProfileId,
  getTalkChannelStoreInfoByProfileId,
  addTalkChannelCsInfo,
  validateSpamMessage,
}
