import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    READY: null,
    LEVEL_1: null,
    LEVEL_2: null,
    LEVEL_3: null,
    LEVEL_4: null,
    INVALID: null,
  }),

  Name: {
    READY: '머신러닝 학습 대기',
    LEVEL_1: '1단계 제한된 머신러닝',
    LEVEL_2: '2단계 기본 머신러닝',
    LEVEL_3: '3단계 심화된 머신러닝',
    LEVEL_4: '4단계 최적의 머신러닝',
    INVALID: '조회 불가',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  valuesOfExistOptimizationStatus() {
    return this.values().filter(
      v => ![this.Type.READY, this.Type.INVALID].includes(v)
    )
  },

  getName(type) {
    return this.Name[type]
  },
}
