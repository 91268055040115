export const SESSION_KEY_ACCESS_TOKEN = 'moment.accessToken'
export const SESSION_KEY_LOGIN_TOKEN = 'moment.loginToken'
export const SESSION_KEY_SELECTED_ADACCOUNT = 'selectedAdAccount'
export const SESSION_KEY_OBSERVER = 'observer'
export const SESSION_KEY_OBSERVER_SERVICE_TYPE = 'observer.serviceType'
export const SESSION_KEY_OBSERVER_ADACCOUNT_ID = 'observer.adAccountId'
export const SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT =
  'moment.ignoreIntegratedAgreement'

const momentSessionStorage = (function () {
  try {
    let testKey = '__sessionStorage.test__'
    sessionStorage.setItem(testKey, testKey)
    sessionStorage.removeItem(testKey)
    return sessionStorage
  } catch (e) {
    return createStorage()
  }
})()

function createStorage() {
  let storage = {}
  return {
    setItem(key, value) {
      storage[key] = value
    },
    getItem(key) {
      return storage[key]
    },
    removeItem(key) {
      delete storage[key]
    },
    clear() {
      storage = {}
    },
  }
}

export default momentSessionStorage
