import { coerceAtLeast, keyMirror } from '../../utils/utils'
import { fromJS, List, Map, Set } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { camelCase, toLower } from 'lodash'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import axios from 'axios'
import { coerceToArray } from '../../utils/stringUtils'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import { DashboardUriUtils } from '../../components/DashboardV3/dashboardUriUtils'
import DashboardRequestBodyUtils from './dashboardRequestBodyUtils'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { CommonActionType } from '../../modules-actionTypes/actionTypes'
import { invalidateDashboardLastUpdatedTime } from './mDashboardCommon'
import { handleDashboardException } from './dashboardException'
import SortEnum from '../../enums/SortEnum'
import { DashboardTableUtils } from '../../components/DashboardV3/DashboardTable/dashboardTableUtils'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageLocal,
  DashboardStorageSession,
} from '../../utils/storage/storageFactoryImpl'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const DashboardTable = keyMirror(
  {
    SELECT_TABLE: null,

    INIT_CHECKED_ROW_IDS: null,
    SET_CHECKED_ROW_IDS: null,
    ADD_CHECKED_ROW_ID: null,
    DELETE_CHECKED_ROW_ID: null,

    SET_TABLE_ROW_SUM: null,
    SET_TABLE_ROWS: null,

    SET_TABLE_SORT: null,
    SET_TABLE_SORT_BY_KEY: null,

    SET_PAGING: null,

    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,

    SET_ADGROUP_BIDSTRATEGY_ACTIVE: null,
    CLEAR_ADGROUP_BIDSTRATEGY_ACTIVE: null,

    CLEAR: null,
  },
  'DASHBOARD_TABLE'
)

const initialState = fromJS({
  dashboardType: null,

  checkedRowIdSet: {
    campaign: Set(),
    adGroup: Set(),
    creative: Set(),
  },

  rowSum: {
    campaign: {
      indicatorMap: {},
      totalBudget: null,
      totalBudgetWithVAT: null,
    },
    adGroup: {
      indicatorMap: {},
      totalBudget: null,
      totalBudgetWithVAT: null,
    },
    creative: {
      indicatorMap: {},
    },
  },

  // 전체 row list
  rows: {
    campaign: [],
    adGroup: [],
    creative: [],
  },

  // 현재 page index 기준의 row list
  pageRows: {
    campaign: [],
    adGroup: [],
    creative: [],
  },

  sort: {
    campaign: {
      key: 'cost',
      direction: SortEnum.Type.DESC,
    },
    adGroup: {
      key: 'cost',
      direction: SortEnum.Type.DESC,
    },
    creative: {
      key: 'cost',
      direction: SortEnum.Type.DESC,
    },
  },

  paging: {
    index: 0,
    size: 20_000,
  },

  totalCount: {
    campaign: 0,
    adGroup: 0,
    creative: 0,
  },

  bidStrategyActive: {
    adGroup: {},
  },
})

const convertDashboardTypeToKeyName = dashboardType => camelCase(dashboardType)

export default createReducer(initialState, {
  [DashboardTable.SELECT_TABLE]: (state, { dashboardType }) => {
    return state.set('dashboardType', dashboardType)
  },

  [DashboardTable.INIT_CHECKED_ROW_IDS]: (state, { checkedRowIdSet }) => {
    return state.set('checkedRowIdSet', checkedRowIdSet)
  },

  [DashboardTable.SET_CHECKED_ROW_IDS]: (state, { dashboardType, ids }) => {
    return state.setIn(
      ['checkedRowIdSet', convertDashboardTypeToKeyName(dashboardType)],
      Set(ids)
    )
  },

  [DashboardTable.ADD_CHECKED_ROW_ID]: (state, { dashboardType, id }) => {
    return state.updateIn(
      ['checkedRowIdSet', convertDashboardTypeToKeyName(dashboardType)],
      prevSet => prevSet.add(id)
    )
  },

  [DashboardTable.DELETE_CHECKED_ROW_ID]: (state, { dashboardType, id }) => {
    return state.updateIn(
      ['checkedRowIdSet', convertDashboardTypeToKeyName(dashboardType)],
      prevSet => prevSet.delete(id)
    )
  },

  [DashboardTable.SET_TABLE_ROW_SUM]: (state, { dashboardType, rowSum }) => {
    return state.setIn(
      ['rowSum', convertDashboardTypeToKeyName(dashboardType)],
      fromJS(rowSum)
    )
  },

  [DashboardTable.SET_TABLE_ROWS]: (state, { dashboardType, rows }) => {
    const {
      paging: { index: pageIndex, size: pageSize },
    } = state

    const allRows = fromJS(rows)

    const dashboardTypeKeyName = convertDashboardTypeToKeyName(dashboardType)

    return state.withMutations(s =>
      s
        .setIn(['rows', dashboardTypeKeyName], allRows)
        .setIn(
          ['pageRows', dashboardTypeKeyName],
          allRows.skip(pageIndex * pageSize).take(pageSize)
        )
        .setIn(['totalCount', dashboardTypeKeyName], allRows.count())
    )
  },

  [DashboardTable.SET_TABLE_SORT]: (state, { sort }) => {
    return state.mergeIn(['sort'], fromJS(sort))
  },

  [DashboardTable.SET_TABLE_SORT_BY_KEY]: (
    state,
    { dashboardType, sortKey, sortDirection = undefined }
  ) => {
    return state.updateIn(
      ['sort', convertDashboardTypeToKeyName(dashboardType)],
      prevMap => {
        const { key: prevSortKey, direction: prevSortDirection } = prevMap
        const defaultDirection = DashboardTableUtils.isMetric(sortKey)
          ? SortEnum.Type.DESC
          : SortEnum.Type.ASC
        const nextSortDirection =
          sortDirection ||
          (prevSortKey === sortKey
            ? prevSortDirection === SortEnum.Type.DESC
              ? SortEnum.Type.ASC
              : SortEnum.Type.DESC
            : defaultDirection)

        return prevMap.set('key', sortKey).set('direction', nextSortDirection)
      }
    )
  },

  [DashboardTable.SET_PAGING]: (
    state,
    {
      dashboardType,
      index = initialState.getIn(['paging', 'index']),
      size = initialState.getIn(['paging', 'size']),
    }
  ) => {
    const dashboardTypeKeyName = convertDashboardTypeToKeyName(dashboardType)
    const nextPageRows = state
      .getIn(['rows', dashboardTypeKeyName])
      .skip(index * size)
      .take(size)

    return state.withMutations(s =>
      s
        .setIn(['paging', 'index'], index)
        .setIn(['paging', 'size'], size)
        .setIn(['pageRows', dashboardTypeKeyName], nextPageRows)
    )
  },

  [DashboardTable.SET_BY_KEY_PATH]: (state, { keyPath, value }) => {
    return state.setIn(coerceToArray(keyPath), fromJS(value))
  },

  [DashboardTable.INIT_BY_KEY_PATH]: (state, { keyPath }) => {
    return state.setIn(
      coerceToArray(keyPath),
      initialState.getIn(coerceToArray(keyPath))
    )
  },

  [DashboardTable.CLEAR]: () => initialState,

  [CommonActionType.SET_BY_URL_SEARCH_PARAMS]: (state, { urlSearchParams }) => {
    const { dashboardType } = DashboardUriUtils.Table.getObjectValue({
      urlSearchParams,
    })

    const nextDashboardType = dashboardType || DashboardEnumV2.Type.CAMPAIGN

    const { pageIndex } = DashboardUriUtils.Page.getObjectValue({
      urlSearchParams,
    })

    return state.withMutations(s =>
      s
        .set('dashboardType', nextDashboardType)
        .setIn(['paging', 'index'], coerceAtLeast(pageIndex, 0))
    )
  },
  [DashboardTable.SET_ADGROUP_BIDSTRATEGY_ACTIVE]: (state, action) => {
    const { adGroupId, optimizationStatus } = action
    return state.setIn(
      ['bidStrategyActive', 'adGroup', adGroupId],
      optimizationStatus
    )
  },
  [DashboardTable.CLEAR_ADGROUP_BIDSTRATEGY_ACTIVE]: state => {
    return state.setIn(['bidStrategyActive', 'adGroup'], fromJS({}))
  },
})

/**
 * 탭 선택
 */
export function selectDashboardTable({ dashboardType }) {
  return dispatch => {
    dispatch({
      type: DashboardTable.SELECT_TABLE,
      dashboardType,
    })

    dispatch(setDashboardTableUri({ dashboardType }))
    dispatch(initDashboardTableByKeyPath({ keyPath: ['paging'] }))
  }
}

function persistStorageRowIds(getState) {
  const {
    dashboardV3: {
      common: {
        adAccountInfo: { id: adAccountId },
      },
      table: { checkedRowIdSet },
    },
  } = getState()

  DashboardStorageSession.update(
    DASHBOARD_STORAGE_ITEM.TABLE_ROW_ID_SET,
    (obj = {}) => {
      if (adAccountId > 0) {
        obj[adAccountId] = checkedRowIdSet.toJS()
      }
      return obj
    }
  )
}

/**
 * 단일/다중 행 선택(강제 SET)
 */
export function setDashboardTableCheckedRowIds({ dashboardType, ids = [] }) {
  return (dispatch, getState) => {
    dispatch({
      type: DashboardTable.SET_CHECKED_ROW_IDS,
      dashboardType,
      ids,
    })

    dispatch(invalidateTableCheckedRowIds({ dashboardType }))

    persistStorageRowIds(getState)
  }
}

/**
 * 행 전체 선택 해제
 */
export function deleteAllDashboardTableCheckedRowIds({ dashboardType }) {
  return (dispatch, getState) => {
    dispatch(setDashboardTableCheckedRowIds({ dashboardType, ids: [] }))

    dispatch(invalidateTableCheckedRowIds({ dashboardType }))

    persistStorageRowIds(getState)
  }
}

/**
 * 단일 행 선택
 */
export function addDashboardTableCheckedRowId({ dashboardType, id }) {
  return (dispatch, getState) => {
    dispatch({ type: DashboardTable.ADD_CHECKED_ROW_ID, dashboardType, id })

    dispatch(invalidateTableCheckedRowIds({ dashboardType }))

    persistStorageRowIds(getState)
  }
}

/**
 * 단일 행 선택 해제
 */
export function deleteDashboardTableCheckedRowId({ dashboardType, id }) {
  return (dispatch, getState) => {
    dispatch({ type: DashboardTable.DELETE_CHECKED_ROW_ID, dashboardType, id })

    dispatch(invalidateTableCheckedRowIds({ dashboardType }))

    persistStorageRowIds(getState)
  }
}

/**
 * 상위 테이블의 선택된 항목을 기준으로 하위 테이블 선택 상태 갱신
 */
function invalidateTableCheckedRowIds({ dashboardType }) {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        table: { checkedRowIdSet, dashboardType: currentTableDashboardType },
      },
    } = getState()

    const hasCheckedRowIds =
      checkedRowIdSet
        .get(convertDashboardTypeToKeyName(dashboardType))
        .count() > 0

    const childrenDashboardTypes = DashboardEnumV2.getChildren(dashboardType)
    const shouldTableUpdate = childrenDashboardTypes.length > 0

    // 하위 자식 테이블 존재 시 업데이트.
    if (shouldTableUpdate) {
      if (hasCheckedRowIds) {
        // 체크된 항목 존재 시
        childrenDashboardTypes.forEach(childDashboardType => {
          dispatch(
            invalidateTableRowsByParentCheckedRowIds({
              dashboardType: childDashboardType,
            })
          )
        })
      } else {
        // 체크된 항목 없을 시
        childrenDashboardTypes.forEach(childDashboardType => {
          if (childDashboardType === currentTableDashboardType) {
            // 현재 활성화된 탭인 경우 API 갱신.
            dispatch(invalidateDashboardTableRows())
          } else {
            // 현재 활성화된 탭이 아닌 경우?
          }
        })
      }
    }
  }
}

const PARENT_ID_DATA_KEY = {
  [DashboardEnumV2.Type.CAMPAIGN]: 'campaignId',
  [DashboardEnumV2.Type.AD_GROUP]: 'adGroupId',
}

/**
 * 상위 테이블의 선택된 항목을 기준으로 하위 테이블 갱신(NO API)
 */
function invalidateTableRowsByParentCheckedRowIds({ dashboardType }) {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        table: { checkedRowIdSet, rows },
      },
    } = getState()

    const parentDashboardType = DashboardEnumV2.getParents(dashboardType).pop()

    if (parentDashboardType) {
      const parentIdKey = PARENT_ID_DATA_KEY[parentDashboardType]
      const parentCheckedRowIdSet = checkedRowIdSet.get(
        convertDashboardTypeToKeyName(parentDashboardType)
      )

      const tableRows = rows.get(convertDashboardTypeToKeyName(dashboardType))

      if (
        parentIdKey &&
        parentCheckedRowIdSet.count() > 0 &&
        tableRows.count() > 0
      ) {
        const nextTableRows = tableRows.filter(row =>
          parentCheckedRowIdSet.includes(row.get(parentIdKey))
        )

        // 부모의 선택된 id set 기준으로 현재 테이블을 갱신한다.
        dispatch(setDashboardTableRows({ dashboardType, rows: nextTableRows }))
      }
    }
  }
}

/**
 * 테이블 데이터 갱신(API)
 */
export function invalidateDashboardTableRows() {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        table: { dashboardType },
      },
    } = getState()

    if (!(adAccountId > 0)) {
      console.warn('invalidateDashboardTableRows', { adAccountId })
      return
    }

    switch (dashboardType) {
      case DashboardEnumV2.Type.CAMPAIGN: {
        dispatch(getDashboardTableCampaignRows({ adAccountId }))
        break
      }

      case DashboardEnumV2.Type.AD_GROUP: {
        dispatch(getDashboardTableAdGroupRows({ adAccountId }))
        break
      }

      case DashboardEnumV2.Type.CREATIVE: {
        dispatch(getDashboardTableCreativeRows({ adAccountId }))
        break
      }

      default: {
        break
      }
    }
  }
}

function setDashboardTableRowSum({ dashboardType, rowSum }) {
  return {
    type: DashboardTable.SET_TABLE_ROW_SUM,
    dashboardType,
    rowSum,
  }
}

function setDashboardTableRows({ dashboardType, rows, useSorting = false }) {
  return (dispatch, getState) => {
    if (useSorting) {
      const {
        dashboardV3: {
          table: { sort },
        },
      } = getState()

      const dashboardTypeKeyName = convertDashboardTypeToKeyName(dashboardType)

      const { key: sortKey, direction: sortDirection } =
        sort.get(dashboardTypeKeyName) || Map()

      const sortedRows = DashboardTableUtils.sort({
        dashboardType,
        rows: fromJS(rows),
        sortKey,
        sortDirection,
      })

      dispatch({
        type: DashboardTable.SET_TABLE_ROWS,
        dashboardType,
        rows: sortedRows,
      })
    } else {
      dispatch({
        type: DashboardTable.SET_TABLE_ROWS,
        dashboardType,
        rows,
      })
    }
  }
}

const isSortObjValid = sortObj =>
  sortObj &&
  typeof sortObj.key === 'string' &&
  SortEnum.values().includes(sortObj.direction)

export function initDashboardTableSortByStorage({ adAccountId }) {
  return dispatch => {
    const userSortStateJson = DashboardStorageLocal.get(
      DASHBOARD_STORAGE_ITEM.TABLE_SORT
    )?.[adAccountId]

    if (userSortStateJson) {
      const isSortStateJsonValid = [
        userSortStateJson.campaign,
        userSortStateJson.adGroup,
        userSortStateJson.creative,
      ].every(isSortObjValid)

      if (isSortStateJsonValid) {
        const systemSortStateJson =
          DashboardStorageLocal.get(DASHBOARD_STORAGE_ITEM.TABLE_SORT_SYSTEM) ||
          {}

        const nextSortState = Object.entries(userSortStateJson).reduce(
          (obj, [key, value]) => {
            obj[key] = isSortObjValid(systemSortStateJson[key])
              ? systemSortStateJson[key]
              : value
            return obj
          },
          {}
        )

        dispatch({
          type: DashboardTable.SET_TABLE_SORT,
          sort: nextSortState,
        })

        DashboardStorageLocal.delete(DASHBOARD_STORAGE_ITEM.TABLE_SORT_SYSTEM)
      }
    }
  }
}

export function initDashboardTableRowIdSetByStorage({ adAccountId }) {
  return dispatch => {
    const userRowIdSetState = DashboardStorageSession.get(
      DASHBOARD_STORAGE_ITEM.TABLE_ROW_ID_SET
    )?.[adAccountId]
    if (userRowIdSetState) {
      const checkedRowIdSet = Map({
        campaign: Set(userRowIdSetState.campaign || []),
        adGroup: Set(userRowIdSetState.adGroup || []),
        creative: Set(userRowIdSetState.creative || []),
      })
      dispatch({
        type: DashboardTable.INIT_CHECKED_ROW_IDS,
        checkedRowIdSet,
      })
    }
  }
}

/**
 * 사용자 인터페이스가 아닌 시스템에서 정렬 컨트롤
 * - 사용자 세션에 반영하지 않음
 */
export function setDashboardTableSortBySystemTemporary({
  dashboardType,
  sortKey,
  sortDirection = undefined,
}) {
  return dispatch => {
    /**
     * 대시보드에 있는 상태일 경우 -> 즉시 변경(사용자 스토리지에 반영 X)
     * 대시보드가 아닌 경우 -> initDashboardTableSortByStorage 에서 처리
     */
    if (DashboardRouter.Path.isDashboard({ pathname: location.pathname })) {
      dispatch(
        setDashboardTableSortByKey({
          dashboardType,
          sortKey,
          sortDirection,
          invalidateStorage: false,
        })
      )
    } else {
      DashboardStorageLocal.set(DASHBOARD_STORAGE_ITEM.TABLE_SORT_SYSTEM, {
        [toLower(dashboardType)]: {
          key: sortKey,
          direction: sortDirection,
        },
      })
    }
  }
}

export function setDashboardTableSortByKey({
  dashboardType,
  sortKey,
  sortDirection = undefined,
  invalidateStorage = true,
}) {
  return (dispatch, getState) => {
    dispatch({
      type: DashboardTable.SET_TABLE_SORT_BY_KEY,
      dashboardType,
      sortKey,
      sortDirection,
    })

    // sort dispatch 후 getState()
    const {
      dashboardV3: {
        common: {
          adAccountInfo: { id: adAccountId },
        },
        table: { rows, sort },
      },
    } = getState()

    const dashboardTypeKeyName = convertDashboardTypeToKeyName(dashboardType)

    const nextRows = rows.get(dashboardTypeKeyName) || List()

    const { key: nextSortKey, direction: nextSortDirection } =
      sort.get(dashboardTypeKeyName) || Map()

    if (
      nextRows.count() > 0 &&
      dashboardType &&
      nextSortKey &&
      nextSortDirection
    ) {
      const sortedRows = DashboardTableUtils.sort({
        dashboardType,
        rows: nextRows,
        sortKey: nextSortKey,
        sortDirection: nextSortDirection,
      })

      dispatch(
        setDashboardTableRows({
          dashboardType,
          rows: sortedRows,
        })
      )

      if (invalidateStorage) {
        DashboardStorageLocal.update(
          DASHBOARD_STORAGE_ITEM.TABLE_SORT,
          (obj = {}) => {
            if (adAccountId > 0) {
              obj[adAccountId] = sort.toJS()
            }
            return obj
          }
        )
      }
    }
  }
}

/**
 * 테이블 데이터 기준으로 선택된 항목 정보 갱신.
 */
function invalidateCheckedRowIdsByTableRows({ dashboardType }) {
  return (dispatch, getState) => {
    const {
      dashboardV3: {
        table: { checkedRowIdSet, rows },
      },
    } = getState()

    const tableRowIds = rows
      .get(convertDashboardTypeToKeyName(dashboardType))
      .map(({ id }) => id)

    // 선택된 id set 에서 테이블에 없는 것을 제거한다.
    const nextCheckedRowIds = checkedRowIdSet
      .get(convertDashboardTypeToKeyName(dashboardType))
      .intersect(tableRowIds)

    dispatch(
      setDashboardTableCheckedRowIds({
        dashboardType,
        ids: nextCheckedRowIds,
      })
    )
  }
}

export function getDashboardTableCampaignRows({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_TABLE))

    const cancelKey = 'getDashboardTableCampaignRows'
    const cancelTokenSource = createCancellation(cancelKey)

    try {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          tableMetric: { selected: selectedMetrics },
        },
      } = getState()

      const requestBody = DashboardRequestBodyUtils.campaignTable({
        metrics: selectedMetrics,
        conditions,
        startDate,
        endDate,
      })

      const [campaignRowSumResponse, campaignRowsResponse] = await axios.all([
        api.dashboardTable.fetchCampaignTableRowSum({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
        api.dashboardTable.fetchCampaignTableRows({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
      ])

      const campaignRowSum = campaignRowSumResponse?.data || {}
      const campaignRows = campaignRowsResponse?.data?.content || []

      dispatch(
        setDashboardTableRowSum({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          rowSum: campaignRowSum,
        })
      )

      dispatch(
        setDashboardTableRows({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          rows: campaignRows,
          useSorting: true,
        })
      )

      dispatch(
        invalidateCheckedRowIdsByTableRows({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
        })
      )

      dispatch(invalidateDashboardLastUpdatedTime())

      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(handleDashboardException({ e }))

        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
      }
    } finally {
      deleteCancellation(cancelTokenSource)
    }
  }
}

export function getDashboardTableAdGroupRows({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_TABLE))

    const cancelKey = 'getDashboardTableAdGroupRows'
    const cancelTokenSource = createCancellation(cancelKey)

    try {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          table: {
            checkedRowIdSet: { campaign: checkedCampaignIdSet },
          },
          tableMetric: { selected: selectedMetrics },
        },
      } = getState()

      const requestBody = DashboardRequestBodyUtils.adGroupTable({
        campaignIdSet: checkedCampaignIdSet,
        metrics: selectedMetrics,
        conditions,
        startDate,
        endDate,
      })

      const [adGroupRowSumResponse, adGroupRowsResponse] = await axios.all([
        api.dashboardTable.fetchAdGroupTableRowSum({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
        api.dashboardTable.fetchAdGroupTableRows({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
      ])

      const adGroupRowSum = adGroupRowSumResponse?.data || {}
      const adGroupRows = adGroupRowsResponse?.data?.content || []

      dispatch(
        setDashboardTableRowSum({
          dashboardType: DashboardEnumV2.Type.AD_GROUP,
          rowSum: adGroupRowSum,
        })
      )

      dispatch(
        setDashboardTableRows({
          dashboardType: DashboardEnumV2.Type.AD_GROUP,
          rows: adGroupRows,
          useSorting: true,
        })
      )

      dispatch(
        invalidateCheckedRowIdsByTableRows({
          dashboardType: DashboardEnumV2.Type.AD_GROUP,
        })
      )

      dispatch(invalidateDashboardLastUpdatedTime())

      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(handleDashboardException({ e }))

        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
      }
    } finally {
      deleteCancellation(cancelTokenSource)
    }
  }
}

export function getDashboardTableCreativeRows({ adAccountId }) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DASHBOARD_V3_TABLE))

    const cancelKey = 'getDashboardTableCreativeRows'
    const cancelTokenSource = createCancellation(cancelKey)

    try {
      const {
        dashboardV3: {
          calendar: { startDate, endDate },
          filter: { conditions },
          table: {
            checkedRowIdSet: {
              campaign: checkedCampaignIdSet,
              adGroup: checkedAdGroupIdSet,
            },
          },
          tableMetric: { selected: selectedMetrics },
        },
      } = getState()

      const requestBody = DashboardRequestBodyUtils.creativeTable({
        campaignIdSet: checkedCampaignIdSet,
        adGroupIdSet: checkedAdGroupIdSet,
        metrics: selectedMetrics,
        conditions,
        startDate,
        endDate,
      })

      const [creativeRowSumResponse, creativeRowsResponse] = await axios.all([
        api.dashboardTable.fetchCreativeTableRowSum({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
        api.dashboardTable.fetchCreativeTableRows({
          adAccountId,
          requestBody,
          cancelTokenSource,
        }),
      ])

      const creativeRowSum = creativeRowSumResponse?.data || {}
      const creativeRows = creativeRowsResponse?.data?.content || []

      dispatch(
        setDashboardTableRowSum({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          rowSum: creativeRowSum,
        })
      )

      dispatch(
        setDashboardTableRows({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          rows: creativeRows,
          useSorting: true,
        })
      )

      dispatch(
        invalidateCheckedRowIdsByTableRows({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
        })
      )

      dispatch(invalidateDashboardLastUpdatedTime())

      dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(handleDashboardException({ e }))

        dispatch(hideLoading(LOADING_KEY.DASHBOARD_V3_TABLE))
      }
    } finally {
      deleteCancellation(cancelTokenSource)
    }
  }
}

export function setDashboardTablePaging({ dashboardType, index, size }) {
  return { type: DashboardTable.SET_PAGING, dashboardType, index, size }
}

export function initDashboardTableByKeyPath({ keyPath }) {
  return {
    type: DashboardTable.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function setDashboardTablePagingUri({ pageIndex }) {
  return dispatch => {
    const url = DashboardUriUtils.Page.set({ pageIndex })

    dispatch(RouterV2.replace({ search: url.search }))
  }
}

export function setDashboardAdGroupBidStrategyActive(
  adGroupId,
  optimizationStatus
) {
  return {
    type: DashboardTable.SET_ADGROUP_BIDSTRATEGY_ACTIVE,
    adGroupId,
    optimizationStatus,
  }
}

export function setDashboardTableUri({ dashboardType }) {
  return dispatch => {
    const url = DashboardUriUtils.Table.set({ dashboardType })

    dispatch(RouterV2.replace({ search: url.search }))
  }
}

export function clearDashboardBidStrategyActive() {
  return {
    type: DashboardTable.CLEAR_ADGROUP_BIDSTRATEGY_ACTIVE,
  }
}

export function clearDashboardTable() {
  return {
    type: DashboardTable.CLEAR,
  }
}
