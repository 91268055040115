import AdViewButtonEnum from '../../enums/AdViewButtonEnum'
import { List } from 'immutable'
import AdViewEnum from '../../enums/AdViewEnum'
import AdViewTemplateEnum from '../../enums/AdViewTemplateEnum'
import { checkNoneEmpty, isUndefinedOrNull } from '../regexUtils'
import { v4 as uuid } from 'uuid'
import { initialAdViewButton } from '../../modules/adViewV2/mAdViewV2'

export const AdViewHelperV2 = {
  Form: {
    updateFormButtonValue(adViewForm) {
      const updater = _button =>
        AdViewButtonEnum.values().every(_type => _type !== _button.get('type'))
          ? initialAdViewButton
          : _button

      return adViewForm
        .update('button1', button1 => updater(button1))
        .update('button2', button2 => updater(button2))
    },

    updateLoadAdViewForm(adViewForm) {
      return adViewForm
        .update('contents', contents =>
          contents.map(v =>
            v.get('type') === AdViewTemplateEnum.Type.VIDEO
              ? v.withMutations(s =>
                  s
                    .set('id', uuid())
                    .set('isOpen', false)
                    .set('isVisibleImageMasking', false)
                    .updateIn(['data', 'originalFileName'], originalFileName =>
                      !isUndefinedOrNull(v.getIn(['data', 'kakaoTVUrl']))
                        ? v.getIn(['data', 'displayTitle'])
                        : originalFileName
                    )
                )
              : v.withMutations(s =>
                  s.withMutations(s =>
                    s
                      .set('id', uuid())
                      .set('isOpen', false)
                      .set('isVisibleImageMasking', false)
                  )
                )
          )
        )
        .set('formUUID', uuid())
    },

    getUploadAdViewForm(adViewType, templateType, adViewForm) {
      if (!checkNoneEmpty(adViewType, templateType, adViewForm)) return null

      const isFullView = !AdViewEnum.isCompactView(adViewType)
      const isMultipleUploadType =
        AdViewTemplateEnum.isMultiUploadType(templateType)

      return adViewForm.withMutations(s =>
        s
          .update(
            (
              formData // 풀뷰면 title, brandName 제거
            ) =>
              isFullView
                ? formData.delete('title').delete('brandName')
                : formData
          )
          .update(
            'contents',
            (
              contents // 뷰 스테이트 제거
            ) =>
              isMultipleUploadType
                ? contents.map(v =>
                    v.withMutations(s =>
                      s
                        .delete('id')
                        .delete('isOpen')
                        .delete('isVisibleImageMasking')
                    )
                  )
                : contents.delete('isVisibleImageMasking')
          )
          .update('contents', contents => contents.filterNot(v => v.isEmpty())) // 비어있는 contents 제거
          .update('button1', button1 =>
            button1.set(
              'webUrl',
              AdViewButtonEnum.isWebUrl(button1.get('type'))
            )
          )
          .update('button2', button2 =>
            button2.set(
              'webUrl',
              AdViewButtonEnum.isWebUrl(button2.get('type'))
            )
          )
      )
    },

    // 애드뷰 파일(이미지형)에서 필요한 추가적인 요소
    setAdditionalImageProperty(imageData) {
      return imageData.withMutations(s =>
        s.set('thumbnailImage', s.get('downloadUrl'))
      )
    },

    // 애드뷰 파일(동영상형)에서 필요한 추가적인 요소
    setAdditionalVideoProperty(videoData) {
      return videoData.withMutations(s =>
        s
          .set('videoUrl', s.getIn(['preview', 'url']))
          .set('thumbnailImage', s.getIn(['thumbnail', 'url']))
      )
    },
  },

  Preview: {},

  Button: {
    hasPlusFriendInfo(button) {
      return Number(button?.get('selectedPlusFriend')?.get('id')) > 0
    },

    hasMessageInfo(button) {
      return Number(button?.get('selectedMessageInfo')?.get('profileId')) > 0
    },

    /**
     *
     * @param button {Map}
     * @return {Map | undefined}
     */
    getPlusFriendInfo(button) {
      return this.hasPlusFriendInfo(button)
        ? button.get('selectedPlusFriend')
        : undefined
    },

    /**
     *
     * @param button {Map}
     * @return {Map | undefined}
     */
    getMessageInfo(button) {
      return this.hasMessageInfo(button)
        ? button.get('selectedMessageInfo')
        : undefined
    },

    /**
     * N개의 버튼에서 카카오톡 채널 정보 추출.
     * @param buttons
     * @return {List}
     */
    getPlusFriendInfos(...buttons) {
      return buttons
        .reduce((prev, v) => prev.push(this.getPlusFriendInfo(v)), List())
        .filter(Boolean)
    },

    /**
     * N개의 버튼에서 카카오톡 채널 메시지 정보 추출.
     * @param buttons
     * @return {*}
     */
    getMessageInfos(...buttons) {
      return buttons
        .reduce((prev, v) => prev.push(this.getMessageInfo(v)), List())
        .filter(Boolean)
    },
  },
}
