const ROUTER_CALL_HISTORY_METHOD = 'ROUTER_CALL_HISTORY_METHOD'

/**
 * 전체 교체 전 구분 용도로 묶음 (누락이나 교체 중 혼선 방지)
 */
export const RouterV2 = {
  push: path => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: 'push', values: [path] },
  }),
  replace: path => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: 'replace', values: [path] },
  }),
  go: index => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: 'go', values: [index] },
  }),
  goBack: () => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: 'goBack', values: [] },
  }),
  goForward: () => ({
    type: ROUTER_CALL_HISTORY_METHOD,
    payload: { method: 'goForward', values: [] },
  }),
}

const routerMiddleware = history => () => next => action => {
  if (action.type !== ROUTER_CALL_HISTORY_METHOD) {
    return next(action)
  }

  const {
    payload: { method, values },
  } = action

  history[method](...values)
}

export default routerMiddleware
