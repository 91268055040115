import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { v4 as uuid } from 'uuid'
import { showErrorMessage } from '../../utils/alertUtils'
import { coerceToArray } from '../../utils/stringUtils'
import axios from 'axios'
import { hideLoading, showLoading } from '../common/mLoading'
import MessageAPI from '../../modules-api/message/messageApi'
import {
  getCreativeVideoUploadExceptionMessageByErrorCode,
  handleCreativeVideoUploadException,
} from '../advertise/creativeActions/aCreativeCommonV2'
import {
  CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT,
  CMP_ASSET_LIBRARY_FILTER_TYPE,
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
  CmpAssetLibraryUtils,
} from '../../components/AdvertiseV2/Creative/Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'
import AdConstraintsHelper from '../../utils/helper/helper-adConstraints'
import { CmpRequestUtils } from '../../components/AdvertiseV2/Creative/Cmp/cmpRequestUtils'
import { CmpAPI } from '../../modules-api/advertise/cmpApi'

const CmpAssetLibraryVideo = keyMirror(
  {
    SET_RECENT_DATA_DISPLAY: null,
    SET_RECENT_SEARCH_OPTION_DISPLAY: null,

    SET_RECENT_DATA_MESSAGE: null,
    SET_RECENT_SEARCH_OPTION_MESSAGE: null,

    ADD_UPLOAD_ITEMS: null,
    SET_UPLOAD_ITEMS: null,
    DELETE_UPLOAD_ITEM: null,
    UPDATE_UPLOAD_ITEM: null,

    SET_SELECTED_ITEMS: null,

    SET_VIEW_STATE: null,
    INIT_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'CMP_ASSET_LIBRARY_VIDEO'
)

const initialState = fromJS({
  recent: {
    searchOptions: {
      searchText: '',
      filterType: CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE,
      filterCondition: {
        duration: { id: 'ALL' },
        sizes: [],
      },
    },
    recentData: {
      query: '', // 조회한 당시의 searchText 를 보관. itemView name 과 매칭한다.
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  // 메시지 동영상 탭(recentMessage)은 임시용으로 추후 recent 로 통합.
  recentMessage: {
    searchOptions: {
      searchText: '',
      filterCondition: {
        duration: { id: 'ALL' },
      },
    },
    recentData: {
      query: '',
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  upload: {
    uploadItems: [],
  },

  selectedItems: [],

  viewState: {
    isFetchingRecent: false,
    isUploading: false,
    isChecking: false,
    isFetchingCatalog: false,
    isFetchingCatalogProduct: false,
  },
})

export default createReducer(initialState, {
  [CmpAssetLibraryVideo.SET_RECENT_DATA_DISPLAY]: (state, { data }) =>
    state.setIn(['recent', 'recentData'], fromJS(data)),

  [CmpAssetLibraryVideo.SET_RECENT_SEARCH_OPTION_DISPLAY]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['recent', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CmpAssetLibraryVideo.SET_RECENT_DATA_MESSAGE]: (state, { data }) =>
    state.setIn(['recentMessage', 'recentData'], fromJS(data)),

  [CmpAssetLibraryVideo.SET_RECENT_SEARCH_OPTION_MESSAGE]: (
    state,
    { keyPath, value }
  ) =>
    state.setIn(
      ['recentMessage', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CmpAssetLibraryVideo.ADD_UPLOAD_ITEMS]: (state, { items }) =>
    state.updateIn(['upload', 'uploadItems'], prev =>
      fromJS(items).concat(prev)
    ),

  [CmpAssetLibraryVideo.SET_UPLOAD_ITEMS]: (state, { items }) =>
    state.setIn(['upload', 'uploadItems'], fromJS(items)),

  [CmpAssetLibraryVideo.DELETE_UPLOAD_ITEM]: (state, { item }) =>
    state.withMutations(s =>
      s
        .updateIn(['upload', 'uploadItems'], prev =>
          prev.filter(({ videoUUID }) => videoUUID !== item.get('videoUUID'))
        )
        .update('selectedItems', prev =>
          prev.filter(({ videoUUID }) => videoUUID !== item.get('videoUUID'))
        )
    ),

  [CmpAssetLibraryVideo.UPDATE_UPLOAD_ITEM]: (
    state,
    { index, keyPath, value }
  ) =>
    state.updateIn(['upload', 'uploadItems', index], prev =>
      prev.setIn(coerceToArray(keyPath), fromJS(value))
    ),

  [CmpAssetLibraryVideo.SET_SELECTED_ITEMS]: (state, { items }) =>
    state.withMutations(s => s.set('selectedItems', fromJS(items))),

  [CmpAssetLibraryVideo.SET_VIEW_STATE]: (state, { keyPath, value }) =>
    state.setIn(['viewState', ...coerceToArray(keyPath)], fromJS(value)),

  [CmpAssetLibraryVideo.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(
      coerceToArray(keyPath),
      initialState.getIn(coerceToArray(keyPath))
    ),

  [CmpAssetLibraryVideo.CLEAR]: () => initialState,
})

function setCmpAssetLibraryVideoRecentDataForDisplay({ data }) {
  return {
    type: CmpAssetLibraryVideo.SET_RECENT_DATA_DISPLAY,
    data,
  }
}

export function setCmpAssetLibraryVideoRecentSearchOptionForDisplay({
  keyPath,
  value,
}) {
  return {
    type: CmpAssetLibraryVideo.SET_RECENT_SEARCH_OPTION_DISPLAY,
    keyPath,
    value,
  }
}

function setCmpAssetLibraryVideoRecentDataForMessage({ data }) {
  return {
    type: CmpAssetLibraryVideo.SET_RECENT_DATA_MESSAGE,
    data,
  }
}

export function setCmpAssetLibraryVideoRecentSearchOptionForMessage({
  keyPath,
  value,
}) {
  return {
    type: CmpAssetLibraryVideo.SET_RECENT_SEARCH_OPTION_MESSAGE,
    keyPath,
    value,
  }
}

function addCmpAssetLibraryVideoUploadItems({ items }) {
  return {
    type: CmpAssetLibraryVideo.ADD_UPLOAD_ITEMS,
    items,
  }
}

export function setCmpAssetLibraryVideoUploadItems({ items }) {
  return {
    type: CmpAssetLibraryVideo.SET_UPLOAD_ITEMS,
    items,
  }
}

export function deleteCmpAssetLibraryVideoUploadItem({ item }) {
  return {
    type: CmpAssetLibraryVideo.DELETE_UPLOAD_ITEM,
    item,
  }
}

export function setCmpAssetLibraryVideoSelectedItems({ items }) {
  return {
    type: CmpAssetLibraryVideo.SET_SELECTED_ITEMS,
    items,
  }
}

export function updateCmpAssetLibraryVideoItem({ index, keyPath, value }) {
  return {
    type: CmpAssetLibraryVideo.UPDATE_UPLOAD_ITEM,
    index,
    keyPath,
    value,
  }
}

export function setCmpAssetLibraryVideoViewState({ keyPath, value }) {
  return {
    type: CmpAssetLibraryVideo.SET_VIEW_STATE,
    keyPath,
    value,
  }
}

export function initCmpAssetLibraryVideoByKeyPath({ keyPath }) {
  return {
    type: CmpAssetLibraryVideo.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function clearCmpAssetLibraryVideo() {
  return {
    type: CmpAssetLibraryVideo.CLEAR,
  }
}

export function getCmpAssetLibraryVideoRecentDataForDisplay({
  adAccountId,
  creativeFormat,
  creativeAssetPropertyType,
  page = 0,
  size = 50,
}) {
  return async (dispatch, getState) => {
    const {
      cmpAssetLibrary: {
        video: {
          recent: {
            searchOptions: {
              searchText,
              filterCondition: { duration: initDuration },
            },
          },
        },
      },
      adConstraints: { creativeAssetConstraints },
    } = getState()

    const { value: { duration } = {} } = initDuration || {}

    const constraint = AdConstraintsHelper.Creative.getAssetConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    })

    if (constraint) {
      const {
        cmpAssetProperty: { assetType, subAssetTypes },
        saveConstraint: { sizeConstraints, videoDuration },
      } = constraint

      const requestBody = CmpRequestUtils.RequestCreator.saveVideoAssets({
        assetType,
        subAssetTypes,
        fileName: searchText,
        mediaDimension: sizeConstraints?.toJS() ?? [],
        durationDimension: duration ?? videoDuration,
      })

      dispatch(
        setCmpAssetLibraryVideoViewState({
          keyPath: ['isFetchingRecent'],
          value: true,
        })
      )

      const cancelTokenSource = createCancellation(
        CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT
      )

      try {
        const response = await CmpAPI.getDisplayVideoAssets({
          adAccountId,
          requestBody,
          pageRequest: {
            page,
            size,
          },
          cancelTokenSource,
        })

        dispatch(
          setCmpAssetLibraryVideoRecentDataForDisplay({
            data: { ...response.data, query: searchText },
          })
        )
      } catch (e) {
        if (!axios.isCancel(e)) {
          showErrorMessage(e.response?.data?.message || e.message)
        }
      } finally {
        dispatch(
          setCmpAssetLibraryVideoViewState({
            keyPath: ['isFetchingRecent'],
            value: false,
          })
        )

        deleteCancellation(cancelTokenSource)
      }
    }
  }
}

export function getCmpAssetLibraryVideoRecentDataForMessage({
  adAccountId,
  creativeFormat,
  creativeAssetPropertyType,
  page = 0,
  size = 50,
}) {
  return async (dispatch, getState) => {
    const {
      cmpAssetLibrary: {
        video: {
          recentMessage: {
            searchOptions: {
              searchText,
              filterCondition: { duration: initDuration },
            },
          },
        },
      },
      adConstraints: { creativeAssetConstraints },
    } = getState()

    const constraint = AdConstraintsHelper.Creative.getAssetConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    })

    const { value: { duration } = {} } = initDuration || {}

    if (constraint) {
      const {
        cmpAssetProperty: { assetType, subAssetTypes },
        saveConstraint: { sizeConstraints, videoDuration },
      } = constraint

      const requestBody = CmpRequestUtils.RequestCreator.saveVideoAssets({
        assetType,
        subAssetTypes,
        fileName: searchText,
        mediaDimension: sizeConstraints?.toJS() ?? [],
        durationDimension: duration ?? videoDuration,
      })

      dispatch(
        setCmpAssetLibraryVideoViewState({
          keyPath: ['isFetchingRecent'],
          value: true,
        })
      )

      const cancelTokenSource = createCancellation(
        CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT
      )

      try {
        const response = await CmpAPI.getMessageVideoAssets({
          adAccountId,
          requestBody,
          pageRequest: {
            page,
            size,
          },
          cancelTokenSource,
        })

        dispatch(
          setCmpAssetLibraryVideoRecentDataForMessage({
            data: { ...response.data, query: searchText },
          })
        )
      } catch (e) {
        if (!axios.isCancel(e)) {
          showErrorMessage(e.response?.data?.message || e.message)
        }
      } finally {
        dispatch(
          setCmpAssetLibraryVideoViewState({
            keyPath: ['isFetchingRecent'],
            value: false,
          })
        )

        deleteCancellation(cancelTokenSource)
      }
    }
  }
}

/**
 * 동영상 tenth 업로드1 > 동영상 파일명으로 코어에 tenth url 정보 요청
 *  - 인스트림 영상에 한해 -24LKFS 음성 필터 기본 적용함. (https://jira.daumkakao.com/browse/KAMOQA-10911)
 */
export function uploadCmpAssetLibraryVideoForDisplay({
  adAccountId,
  creativeUploadAssetPropertyType,
  uploadSizeConstraints = [],
  files,
  cancelKey = uuid(),
  onProgress = () => undefined,
  onFinish = () => undefined,
}) {
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()
    // 현재 비디오는 단일 등록만 가능
    const [videoFile] = files

    dispatch(
      setCmpAssetLibraryVideoViewState({
        keyPath: ['isUploading'],
        value: true,
      })
    )

    const cancelTokenSource = createCancellation(cancelKey)

    try {
      // 업로드할 tenth path 요청
      const tenthPathResponse = await api.adCreative.getVideoUploadTenthPath({
        adAccountId,
        fileName: encodeURIComponent(videoFile.name),
        loudnorm: encodeURIComponent('I=-24'),
        transcodeType: CmpAssetLibraryUtils.getTranscodeType({
          campaignType,
          creativeUploadAssetPropertyType,
        }),
      })

      const { url, type, transCodeId } = tenthPathResponse.data || {}

      const formData = new FormData()
      formData.append('type', type)
      formData.append('transcode', transCodeId)
      formData.append('file', videoFile)

      // tenth 업로드 후 응답
      const tentnVideoResponse =
        await api.adCreative.uploadCreativeVideoToTenth({
          url,
          formData,
          cancelTokenSource,
          onProgress,
        })

      const tenthVideo = tentnVideoResponse.data

      if (tenthVideo) {
        tenthVideo.trans_code_type = CmpAssetLibraryUtils.getTranscodeType({
          campaignType,
          creativeUploadAssetPropertyType,
        })

        const videoAssetResponse =
          await api.adCreative.getCreativeVideoInfoByFileName({
            adAccountId,
            fileName: encodeURIComponent(videoFile.name),
            body: tenthVideo,
          })

        const videoAsset = {
          ...videoAssetResponse.data,
          cmpVideoType: CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY,
        }

        if (videoAsset) {
          const nextVideoAssets =
            CmpAssetLibraryUtils.Upload.filterByConstraint({
              uploadAssets: fromJS([videoAsset]),
              creativeUploadAssetPropertyType,
              uploadSizeConstraints,
            })

          if (nextVideoAssets.count() > 0) {
            dispatch(
              addCmpAssetLibraryVideoUploadItems({ items: nextVideoAssets })
            )

            dispatch(
              setCmpAssetLibraryVideoSelectedItems({ items: nextVideoAssets })
            )
          } else {
            showErrorMessage(
              '등록 가능한 동영상 사이즈 및 비율을 확인하신 후 다시 시도하세요.'
            )
          }
        }
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(handleCreativeVideoUploadException({ e }))
      }
    } finally {
      deleteCancellation(cancelTokenSource)

      dispatch(
        setCmpAssetLibraryVideoViewState({
          keyPath: ['isUploading'],
          value: false,
        })
      )

      onFinish()
    }
  }
}

export function uploadCmpAssetLibraryVideoForMessage({
  adAccountId,
  files,
  cancelKey = uuid(),
  isMountedRef,
  onProgress = () => undefined,
  onFinish = () => undefined,
}) {
  return async (dispatch, getState, api) => {
    // 현재 비디오는 단일 등록
    const [videoFile] = files

    dispatch(
      setCmpAssetLibraryVideoViewState({
        keyPath: ['isUploading'],
        value: true,
      })
    )

    const cancelTokenSource = createCancellation(cancelKey)

    try {
      // 업로드할 tenth path 요청
      const tenthPathResponse =
        await api.adCreative.getMessageCreativeVideoUploadUrlToKakaoTv({
          adAccountId,
        })

      const [{ vid, srcUrl, signature, maxFileSize, maxFileCount, expires }] =
        tenthPathResponse.data || []

      const formData = new FormData()

      formData.append('file', videoFile)
      formData.append('srcUrl', srcUrl)
      formData.append('signature', signature)
      formData.append('maxFileSize', maxFileSize)
      formData.append('maxFileCount', maxFileCount)
      formData.append('expires', expires)

      // kakaoTV 업로드
      const videoAssetResponse =
        await api.adCreative.uploadMessageCreativeVideoToKakaoTv({
          formData,
          adAccountId,
          onProgress,
          cancelTokenSource,
        })

      // kakaoTV 업로드 영상에 대해 인코딩 상태 요청
      const encodingResponse =
        await api.adCreative.requestMessageCreativeVideoEncoding({
          adAccountId,
          body: {
            reservedVideo: {
              vid,
            },
          },
        })

      // 인코딩 완료 및 수신
      if (encodingResponse.data?.code === 'Success') {
        dispatch(
          getCmpAssetLibraryVideoInfoForKakaoTv({
            adAccountId,
            vid,
            isMountedRef,
            onStart: () => {
              dispatch(showLoading())

              dispatch(
                setCmpAssetLibraryVideoViewState({
                  keyPath: ['isChecking'],
                  value: true,
                })
              )
            },
            onSuccess: kakaoTvVideoInfo => {
              if (kakaoTvVideoInfo) {
                const nextVideoAsset = {
                  ...kakaoTvVideoInfo,
                  originalFileName: videoAssetResponse.data?.originalFileName,
                  fileName: videoAssetResponse.data?.originalFileName,
                  cmpVideoType: CMP_ASSET_LIBRARY_VIDEO_TYPE.MESSAGE,
                }

                dispatch(
                  addCmpAssetLibraryVideoUploadItems({
                    items: [nextVideoAsset],
                  })
                )

                dispatch(
                  setCmpAssetLibraryVideoSelectedItems({
                    items: [nextVideoAsset],
                  })
                )
              }
            },
            onError: () => {
              showErrorMessage(
                '동영상을 처리 하는 중, 문제가 발생하였습니다. 잠시 후, 다시 홍보동영상을 등록하세요.'
              )
            },
            onFinish: () => {
              dispatch(hideLoading())

              dispatch(
                setCmpAssetLibraryVideoViewState({
                  keyPath: ['isChecking'],
                  value: false,
                })
              )
            },
          })
        )
      } else {
        showErrorMessage(
          '동영상을 처리 하는 중, 문제가 발생하였습니다. 잠시 후, 다시 홍보동영상을 등록하세요.'
        )
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        dispatch(handleCreativeVideoUploadException({ e }))
      }
    } finally {
      deleteCancellation(cancelTokenSource)

      dispatch(
        setCmpAssetLibraryVideoViewState({
          keyPath: ['isUploading'],
          value: false,
        })
      )

      onFinish()
    }
  }
}

export function getCmpAssetLibraryVideoInfoForKakaoTv({
  adAccountId,
  vid,
  isMountedRef,
  onStart = () => undefined,
  onSuccess = kakaoTvVideoInfo => undefined,
  onError = errorMessage => undefined,
  onFinish = () => undefined,
}) {
  return (dispatch, getState, api) => {
    onStart()

    const pollingInterval = setInterval(async () => {
      try {
        if (!isMountedRef?.current) {
          clearInterval(pollingInterval)

          onError('')

          onFinish()

          return
        }

        const kakaoTvVideoResponse =
          await api.adCreative.getMessageCreativeVideoKakaoTvInfo({
            adAccountId,
            vid,
          })

        const kakaoTvVideoInfo = kakaoTvVideoResponse.data

        const { fileSize, duration } = kakaoTvVideoInfo || {}

        // 성공
        if (fileSize > 0 && duration > 0) {
          clearInterval(pollingInterval)

          onSuccess(kakaoTvVideoInfo)

          onFinish()
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          dispatch(handleCreativeVideoUploadException({ e }))
        }

        clearInterval(pollingInterval)

        // KAMOQA-22317 에러 중복 발생 이슈
        // KAMOQA-24652 이슈로 인해 롤백
        onError()

        onFinish()
      }
    }, 1000)
  }
}

export function getCmpAssetLibraryVideoEncodingProcessForKakaoTv({
  adAccountId,
  vid,
  isMountedRef,
  onStart = () => undefined,
  onSuccess = () => undefined,
  onError = () => undefined,
}) {
  return (dispatch, getState, api) => {
    onStart()

    const pollingInterval = setInterval(async () => {
      try {
        if (!isMountedRef?.current) {
          onError('')

          clearInterval(pollingInterval)

          return
        }

        const response =
          await api.adCreative.getMessageCreativeVideoEncodingProgressingInfo({
            adAccountId,
            vid,
          })

        const { state: encodingProcessState } = response.data || {}

        if (encodingProcessState === 'ERROR_ENCODING') {
          clearInterval(pollingInterval)

          onError()
        }

        if (encodingProcessState === 'DONE') {
          clearInterval(pollingInterval)

          onSuccess()
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          const { errorCode, message } = e?.response?.data || {}

          onError(
            getCreativeVideoUploadExceptionMessageByErrorCode({
              errorCode,
              defaultMessage: message,
            })
          )
        }

        clearInterval(pollingInterval)
      }
    }, 1000)
  }
}

export function uploadCmpAssetLibraryVideoThumbnailForKakaoTv({
  adAccountId,
  creativeFormat,
  thumbnail,
  onSuccess = () => undefined,
  onError = () => undefined,
}) {
  return async () => {
    try {
      const { data: thumbnailData } =
        await MessageAPI.uploadMessageVideoThumbnail(
          adAccountId,
          creativeFormat,
          thumbnail
        )

      const { successFiles = [], invalidFiles = [] } = thumbnailData || {}

      if (successFiles.length > 0) {
        onSuccess(successFiles[0])
      } else {
        const { invalidReasons = [] } =
          invalidFiles.length > 0 ? invalidFiles[0] || {} : {}

        const { description } =
          invalidReasons.length > 0 ? invalidReasons[0] || {} : {}

        onError(description)
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        const { errorCode, message } = e?.response?.data || {}

        onError(
          getCreativeVideoUploadExceptionMessageByErrorCode({
            errorCode,
            defaultMessage: message,
          })
        )
      }
    }
  }
}
