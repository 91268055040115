import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

/**
 * CheckBox
 *
 * params:
 *  onChange {callback function(e=ChangeEvent)}
 *
 */
export default class CheckBox extends React.Component {
  render() {
    const {
      id,
      name,
      subLabel,
      label,
      isChecked,
      active,
      className,
      imgClassName,
      labelClassName,
      activeClassName,
      onChange,
      onClick,
    } = this.props

    return (
      <span
        className={cx('box_checkinp', className, {
          [activeClassName]: !active,
        })}>
        <input
          type="checkbox"
          name={name || id}
          id={id}
          className="inp_check"
          checked={isChecked}
          onChange={active ? onChange : undefined}
          onClick={active ? onClick : undefined}
          readOnly={!active}
        />
        <label htmlFor={id} className={labelClassName}>
          <span className={`${imgClassName} ico_check`}>{subLabel}</span>
          {label}
          {this.props.children}
        </label>
      </span>
    )
  }
}

CheckBox.defaultProps = {
  subLabel: '',
  name: '',
  imgClassName: 'ico_comm',
  labelClassName: 'lab_check',
  activeClassName: 'in_active',
  isChecked: false,
  active: true,
  onChange: () => undefined,
  onClick: () => undefined,
}

CheckBox.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.any,
  name: PropTypes.string,
  subLabel: PropTypes.string,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  labelClassName: PropTypes.string,
  imgClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  active: PropTypes.bool,
  isChecked: PropTypes.bool,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.any,
}
