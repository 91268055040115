import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { openPopup, POPUP_KEY } from '../common/mPopup'
import { v4 as uuid } from 'uuid'
import { showErrorMessage } from '../../utils/alertUtils'
import { coerceToArray } from '../../utils/stringUtils'
import axios from 'axios'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { filterCreativeLibraryUploadAssets } from '../../components/AdvertiseV2/Creative/CreativeLibrary/creativeLibraryUtils'

const CreativeLibrary = keyMirror(
  {
    // recent
    SET_RECENT_SEARCH_OPTION: null,
    SET_RECENT_DATA: null,

    // upload
    ADD_UPLOAD_ITEMS: null,
    SET_UPLOAD_ITEMS: null,
    DELETE_UPLOAD_ITEM: null,
    UPDATE_UPLOAD_ITEM: null,

    // product
    SET_CATALOG_SEARCH_OPTION: null,
    SET_CATALOG_ITEMS: null,
    SET_CATALOG_PRODUCT_DATA: null,

    SELECT_ITEM: null,
    ADD_SELECTED_ITEMS: null,
    SET_SELECTED_ITEMS: null,
    DELETE_SELECTED_ITEM: null,

    SET_EDITED_ITEMS: null,

    SET_VIEW_STATE: null,
    INIT_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'CREATIVE_LIBRARY'
)

const initialState = fromJS({
  recent: {
    searchOptions: {
      imageSizeOptionId: null,
    },
    recentData: {
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  upload: {
    uploadItems: [], // { imageUUID, url, imageWidth, imageHeight, imageHash, originalFileName... }
  },

  catalog: {
    searchOptions: {
      catalogId: null,
      order: 'CREATED_DATE', // CREATED_DATE | DISCOUNT_RAGE
      searchType: 'TITLE', // TITLE | ITEM_ID
      searchValue: '',
    },
    catalogItems: [], // [{ id, pixelAndSdkName }]
    productData: {
      content: [],
      number: 0,
      size: 0,
      totalElements: 0,
    },
  },

  /**
   * [image]
   */
  selectedItems: [],

  /**
   * {
   *   [imageUUID]: image
   * }
   */
  editedItemsMap: {},

  viewState: {
    isFetchingRecent: false,
    isUploading: false,
    isFetchingCatalog: false,
    isFetchingCatalogProduct: false,
  },
})

export default createReducer(initialState, {
  [CreativeLibrary.SET_RECENT_SEARCH_OPTION]: (state, { keyPath, value }) =>
    state.setIn(
      ['recent', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CreativeLibrary.SET_RECENT_DATA]: (state, { data }) =>
    state.setIn(['recent', 'recentData'], fromJS(data)),

  [CreativeLibrary.ADD_UPLOAD_ITEMS]: (state, { items }) =>
    state.updateIn(['upload', 'uploadItems'], prev =>
      fromJS(items).concat(prev)
    ),

  [CreativeLibrary.SET_UPLOAD_ITEMS]: (state, { items }) =>
    state.setIn(['upload', 'uploadItems'], fromJS(items)),

  [CreativeLibrary.DELETE_UPLOAD_ITEM]: (state, { item, index }) =>
    state.withMutations(s =>
      s
        .updateIn(['upload', 'uploadItems'], prev => prev.delete(index))
        .update('selectedItems', prevSelectedItems =>
          prevSelectedItems.filter(
            v => v.get('imageUUID') !== item.get('imageUUID')
          )
        )
    ),

  [CreativeLibrary.UPDATE_UPLOAD_ITEM]: (state, { index, keyPath, value }) =>
    state.updateIn(['upload', 'uploadItems', index], prev =>
      prev.setIn(coerceToArray(keyPath), fromJS(value))
    ),

  [CreativeLibrary.SET_CATALOG_SEARCH_OPTION]: (state, { keyPath, value }) =>
    state.setIn(
      ['catalog', 'searchOptions', ...coerceToArray(keyPath)],
      fromJS(value)
    ),

  [CreativeLibrary.SET_CATALOG_ITEMS]: (state, { items }) =>
    state.setIn(['catalog', 'catalogItems'], fromJS(items)),

  [CreativeLibrary.SET_CATALOG_PRODUCT_DATA]: (state, { data }) =>
    state.setIn(['catalog', 'productData'], fromJS(data)),

  [CreativeLibrary.SELECT_ITEM]: (state, { item }) =>
    state.update('selectedItems', prev => {
      const index = prev.findIndex(
        v => v.get('imageUUID') === item.get('imageUUID')
      )
      return index >= 0 ? prev.delete(index) : prev.push(item)
    }),

  [CreativeLibrary.ADD_SELECTED_ITEMS]: (state, { items = [] }) =>
    state.update('selectedItems', prev => prev.concat(fromJS(items))),

  [CreativeLibrary.SET_SELECTED_ITEMS]: (state, { items }) =>
    state.withMutations(s => s.set('selectedItems', fromJS(items))),

  [CreativeLibrary.DELETE_SELECTED_ITEM]: (state, { item }) =>
    state.withMutations(s =>
      s.update('selectedItems', prev =>
        prev.filter(v => v.get('imageUUID') !== item.get('imageUUID'))
      )
    ),

  [CreativeLibrary.SET_EDITED_ITEMS]: (state, { key, items = [] }) => {
    const nextItems = fromJS(items)

    return state.update('editedItemsMap', prevEditedItemsMap =>
      nextItems.isEmpty()
        ? prevEditedItemsMap.delete(key)
        : prevEditedItemsMap.set(key, nextItems)
    )
  },

  [CreativeLibrary.SET_VIEW_STATE]: (state, { keyPath, value }) =>
    state.setIn(['viewState', ...coerceToArray(keyPath)], fromJS(value)),

  [CreativeLibrary.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(
      coerceToArray(keyPath),
      initialState.getIn(coerceToArray(keyPath))
    ),

  [CreativeLibrary.CLEAR]: () => initialState,
})

export function addCreativeLibraryUploadItems(items) {
  return {
    type: CreativeLibrary.ADD_UPLOAD_ITEMS,
    items,
  }
}

export function deleteCreativeLibraryUploadItem(item, index) {
  return {
    type: CreativeLibrary.DELETE_UPLOAD_ITEM,
    item,
    index,
  }
}

export function selectCreativeLibraryItem(item) {
  return {
    type: CreativeLibrary.SELECT_ITEM,
    item,
  }
}

export function addCreativeLibrarySelectedItems(items) {
  return {
    type: CreativeLibrary.ADD_SELECTED_ITEMS,
    items,
  }
}

export function setCreativeLibrarySelectedItems(items) {
  return {
    type: CreativeLibrary.SET_SELECTED_ITEMS,
    items,
  }
}

export function deleteCreativeLibrarySelectedItem(item) {
  return {
    type: CreativeLibrary.DELETE_SELECTED_ITEM,
    item,
  }
}

export function updateCreativeLibraryItem(index, keyPath, value) {
  return {
    type: CreativeLibrary.UPDATE_UPLOAD_ITEM,
    index,
    keyPath,
    value,
  }
}

/**
 *
 * @param adAccountId {number | string}
 * @param creativeUploadAssetPropertyType {string | undefined}
 * @param sizeConstraintArray {array}
 * @param needEdit {boolean}
 * @param creativeFormat {string}
 * @param files {FileList}
 * @param cancelKey {string}
 * @param onlyPNG24 {boolean}
 * @param multiSelect {boolean}
 * @param callback {object}
 * @param isMessage {boolean}
 */
export function uploadCreativeLibraryImages({
  adAccountId,
  creativeUploadAssetPropertyType,
  sizeConstraintArray = [],
  needEdit = false,
  creativeFormat,
  guideTooltip,
  files,
  cancelKey = 'uploadCreativeLibraryImages',
  onlyPNG24 = false,
  multiSelect = true,
  callback = {
    onProgress: () => undefined,
    onFinish: () => undefined,
  },
}) {
  return async (dispatch, getState, api) => {
    const {
      creativeV2: {
        common: {
          adGroup: { id: adGroupId },
        },
      },
    } = getState()

    const cancelTokenSource = createCancellation(cancelKey)
    const formData = new FormData()

    Array.from(files).forEach(file => {
      formData.append('files', file)
    })

    formData.append('creativeFormat', creativeFormat)
    formData.append('needEdit', String(needEdit))

    const isMessage = CreativeFormatEnum.isMessage(creativeFormat)

    if (!isMessage) {
      formData.append('adGroupId', adGroupId)
      formData.append('assetPropertyType', creativeUploadAssetPropertyType)
      formData.append('png24', String(onlyPNG24))
    }

    dispatch(setCreativeLibraryViewState(['isUploading'], true))

    try {
      const API = isMessage
        ? api.message.uploadMessageImages
        : !creativeUploadAssetPropertyType
        ? api.adCreative.uploadCreativeImagesNoConstraint
        : api.adCreative.uploadCreativeImages

      const response = await API(
        adAccountId,
        formData,
        callback?.onProgress,
        cancelTokenSource,
        creativeFormat
      )

      const { successFiles, invalidFiles } = response?.data || {}

      if (successFiles?.length > 0) {
        const uploadedImages = successFiles.map(image => {
          image['imageUUID'] = uuid()
          return image
        })

        const filteredImageList = filterCreativeLibraryUploadAssets({
          assetList: fromJS(uploadedImages),
          creativeUploadAssetPropertyType,
          sizeConstraintArray,
          creativeFormat,
        })

        if (filteredImageList.count() > 0) {
          dispatch(addCreativeLibraryUploadItems(filteredImageList))

          if (multiSelect) {
            dispatch(addCreativeLibrarySelectedItems(filteredImageList))
          } else {
            dispatch(
              setCreativeLibrarySelectedItems([filteredImageList.first()])
            )
          }
        } else {
          showErrorMessage('소재 제약조건에 맞는 이미지가 없습니다.')
        }
      }

      if (invalidFiles?.length > 0) {
        dispatch(
          openPopup(POPUP_KEY.MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY, {
            summary: fromJS(invalidFiles),
            guideTooltip,
          })
        )
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        showErrorMessage(e.response?.data?.message || e.message)
      }

      console.log(e.message)
    } finally {
      if (typeof callback?.onFinish === 'function') {
        callback.onFinish()
      }

      deleteCancellation(cancelTokenSource)

      dispatch(setCreativeLibraryViewState(['isUploading'], false))
    }
  }
}

function setCreativeLibraryRecentData(data) {
  return {
    type: CreativeLibrary.SET_RECENT_DATA,
    data,
  }
}

/**
 *
 * @param adAccountId
 * @param requestBody
 * {
 *  assetPropertyTypes: ['PROFILE_IMAGE', 'IMAGE', 'OBJET_IMAGE', 'ICON_IMAGE'],
 *    imageSizes: [
 *      {
 *        width: 640,
 *        height: 480,
 *        minWidth: null,
 *        ratio: null,
 *      },
 *      {
 *        width: null,
 *        height: null,
 *        minWidth: 500,
 *        ratio: 1.0,
 *      },
 *    ],
 * }
 *
 * - 프로필 이미지용 이미지만 찾고 싶을때
 *  assetPropertyTypes : ["PROFILE_IMAGE"]
 * - 640x100 만 찾고 싶을때
 *  imageSizes : [{ width: 640, height: 100 }]
 * - 전체 사이즈 이미지를 찾고 싶을때
 *  assetPropertyTypes : [], imageSizes : []
 *  또는 assetPropertyTypes : ["IMAGE"]
 *
 * @param pageRequest {object}
 * @param cancelKey {string}
 */
export function fetchCreativeLibraryRecentData(
  adAccountId,
  requestBody = {
    assetPropertyTypes: [],
    imageSizes: [],
  },
  pageRequest = {
    query: '',
    sort: '',
    page: 0,
    size: 50,
  },
  cancelKey = 'fetchCreativeLibraryRecentData'
) {
  return async (dispatch, getState, api) => {
    const cancelTokenSource = createCancellation(cancelKey)

    try {
      dispatch(setCreativeLibraryViewState(['isFetchingRecent'], true))

      const response = await api.creativeAsset.getLatestCreativeAssetImages(
        adAccountId,
        requestBody,
        pageRequest,
        cancelTokenSource
      )

      response.data.content = response.data.content?.map(image => {
        // 로드할 때 마다 아이템의 id 가 바뀌면 안되기 때문에 image asset id 를 사용한다.
        image['imageUUID'] = image.id
        return image
      })

      dispatch(setCreativeLibraryRecentData(response.data || {}))
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(setCreativeLibraryViewState(['isFetchingRecent'], false))

      deleteCancellation(cancelTokenSource)
    }
  }
}

export function setCreativeLibraryRecentSearchOption(keyPath, value) {
  return {
    type: CreativeLibrary.SET_RECENT_SEARCH_OPTION,
    keyPath,
    value,
  }
}

export function setCreativeLibraryEditedItems(key, items) {
  return {
    type: CreativeLibrary.SET_EDITED_ITEMS,
    key,
    items,
  }
}

export function setCreativeLibraryViewState(keyPath, value) {
  return {
    type: CreativeLibrary.SET_VIEW_STATE,
    keyPath,
    value,
  }
}

export function initCreativeLibraryByKeyPath(keyPath) {
  return {
    type: CreativeLibrary.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function clearCreativeLibrary() {
  return {
    type: CreativeLibrary.CLEAR,
  }
}
