import { Set } from 'immutable'
import DeviceTypeEnum from '../../enums/DeviceTypeEnum'

const AdConstraintsHelper = {
  AdGroup: {
    getPlacementsByDeviceType(adGroupConstraints, deviceType = undefined) {
      const { devicePlacementConstraints } = adGroupConstraints || {}

      return deviceType === DeviceTypeEnum.Type.PC ||
        deviceType === DeviceTypeEnum.Type.MOBILE
        ? Set(
            devicePlacementConstraints
              .filter(v => v.get('device') === deviceType)
              .map(v => v.get('placement'))
          )
        : Set(devicePlacementConstraints.map(v => v.get('placement')))
    },
  },

  Creative: {
    getAssetConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return creativeAssetConstraints
        ?.find(({ creativeFormat: cf }) => cf === creativeFormat)
        ?.get('assetPropertyConstraints')
        ?.find(({ assetProperty: ap }) => ap === creativeAssetPropertyType)
        ?.get('constraint')
    },

    getSaveConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getAssetConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('saveConstraint')
    },

    getUploadConstraint({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getAssetConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('uploadConstraint')
    },

    getSaveSizeConstraints({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getSaveConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('sizeConstraints')
    },

    getSaveSizeConstraintsRecommended({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getSaveConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('recommendSizeConstraints')
    },

    getUploadSizeConstraints({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getUploadConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('sizeConstraints')
    },

    getUploadSizeConstraintsRecommended({
      creativeAssetConstraints,
      creativeFormat,
      creativeAssetPropertyType,
    }) {
      return this.getUploadConstraint({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType,
      })?.get('recommendSizeConstraints')
    },
  },
}

export default AdConstraintsHelper
