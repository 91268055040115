import PopupHOC from '../PopupHOC'
import React, { Fragment } from 'react'
import { NumberUtils } from '../../../utils/numberUtils'
import PopupButtonGroup from '../../Common/ButtonGroup/PopupButtonGroup'
import PropTypes from 'prop-types'

const TargetPromiseMessageDetail = ({
  requestName,
  successCount,
  failCount,
  errorMessages,
  close,
}) => {
  const requestCount = successCount + failCount
  const hasFailures = failCount > 0

  return (
    <div className="inner_basic_layer" onClick={e => e.stopPropagation()}>
      <strong className="tit_layer">요청 결과 상세</strong>
      <p className="txt_layer">
        <span className="fc_empty">{NumberUtils.withCommas(requestCount)}</span>
        개 {requestName} 처리가 적용되었습니다.
        <br />
        (성공&nbsp;
        <span className="fc_empty">{NumberUtils.withCommas(successCount)}</span>
        개, 실패&nbsp;
        <span className="fc_empty">{NumberUtils.withCommas(failCount)}</span>
        개)
        {hasFailures && (
          <>
            <br />
            실패 사유를 확인 후 다시 시도하세요.
          </>
        )}
      </p>
      {hasFailures && (
        <div className="imgupload_wrap">
          {errorMessages.map((errorMessage, index) => {
            return (
              <Fragment key={index}>
                <strong className="tit_imgupload"></strong>
                <p className="txt_imgupload">{errorMessage}</p>
              </Fragment>
            )
          })}
        </div>
      )}
      <PopupButtonGroup
        onOK={close}
        okButtonLabel="확인"
        hasCancel={false}
        hasBack={false}
      />
      <a className="btn_close" onClick={close}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

TargetPromiseMessageDetail.propTypes = {
  requestName: PropTypes.string,
  successCount: PropTypes.number,
  failCount: PropTypes.number,
  close: PropTypes.func.isRequired,
  errorMessages: PropTypes.array,
}

export default PopupHOC(TargetPromiseMessageDetail, {
  className: 'basic_layer2',
  subClassName: 'imgupload_layer',
})
