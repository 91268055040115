import { fromJS, Map } from 'immutable'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  hasFullWidthWhitespace,
  isUndefinedOrNull,
} from '../../../utils/regexUtils'
import {
  validateCreativeAltText,
  validateCreativeFrequencyCap,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeTitle,
  validateDiscountedPrice,
  validateLandingInfo,
} from './creativeFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import ExpandableActionButtonEnum from '../../../enums/ExpandableActionButtonEnum'
import ExpandableEnum from '../../../enums/ExpandableEnum'
import {
  BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX,
  BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN,
} from '../../../utils/advertise/creativeImage'
import BizBoardSubTypeEnum from '../../../enums/BizBoardSubTypeEnum'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const BIZ_BOARD_BANNER_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: ['landingInfo'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EXPANDABLE_ASSET_GROUPS]: [
    'expandableAssetGroups',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: ['bizBoardBundles'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: ['frequencyCap'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
  [CREATIVE_FORM_VALIDATION_KEY.MOTION_BOARD_VIDEO]: [
    'boardAssetGroup',
    'video',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_IMAGE]: [
    'boardAssetGroup',
    'backgroundImage',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.TEXT_IMAGE]: ['boardAssetGroup', 'textImage'],
  [CREATIVE_FORM_VALIDATION_KEY.OBJECT_IMAGE]: [
    'boardAssetGroup',
    'objectImage3',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
})

const validateEventClickTrackerUrl = url => {
  if (checkNotEmpty(url)) {
    if (!checkUrl(url)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    }
    return formUtils.Url.validateUrl(url)
  }

  return IS_VALID()
}

const validateBizBoardExpandableAsset = ({
  expandableType,
  expandableAssetGroups,
} = {}) => {
  if (expandableType === ExpandableEnum.Type.IMAGE) {
    const { image, actionButton } = expandableAssetGroups?.first() || {}
    const { url: imageUrl } = image || {}
    const isImageValid = !!imageUrl

    return isImageValid && actionButton !== ExpandableActionButtonEnum.Type.NONE
  }

  if (expandableType === ExpandableEnum.Type.VIDEO) {
    const { video, actionButton } = expandableAssetGroups?.first() || {}
    const { id: videoId, videoUUID } = video || {}
    const isVideoValid = videoId > 0 || !!videoUUID

    return isVideoValid && actionButton !== ExpandableActionButtonEnum.Type.NONE
  }

  if (expandableType === ExpandableEnum.Type.MULTI) {
    return (
      expandableAssetGroups.count() >=
        BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN &&
      expandableAssetGroups.count() <=
        BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX &&
      expandableAssetGroups.every(
        ({ image, title, actionButton, mobileLandingUrl }) => {
          const { url: imageUrl } = image || {}
          const isMobileLandingUrlValid =
            !hasFullWidthWhitespace(mobileLandingUrl) &&
            checkUrl(mobileLandingUrl)

          return (
            !!imageUrl &&
            !!title &&
            isMobileLandingUrlValid &&
            actionButton !== ExpandableActionButtonEnum.Type.NONE
          )
        }
      )
    )
  }

  return false
}

const validateExpandableMultiSlideCount = ({
  expandableAssetGroups,
  onValid = IS_VALID,
}) => {
  return expandableAssetGroups.count() <
    BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN ||
    expandableAssetGroups.count() > BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX
    ? IS_NOT_VALID(
        `슬라이드는 최소 ${BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MIN}개 이상 ${BIZ_BOARD_EXPANDABLE_MULTI_IMAGE_SLIDE_MAX}개 이하로 설정 가능합니다.`
      )
    : onValid()
}

const validateExpandableMultiActionButton = ({ expandableActionButton }) => {
  return !expandableActionButton ||
    expandableActionButton === ExpandableActionButtonEnum.Type.NONE
    ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    : IS_VALID()
}

const validateExpandableMultiLandingUrl = ({ landingUrl }) => {
  if (checkEmpty(landingUrl)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }

  if (!checkUrl(landingUrl)) {
    return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
  }

  return formUtils.Url.validateUrl(landingUrl)
}

const BIZ_BOARD_BANNER_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: bizBoardBundles => {
    if (!bizBoardBundles || bizBoardBundles.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    const bizBoardBundleValidationResults = []

    bizBoardBundles.forEach(
      ({
        bundleUUID,
        image: bizBoardImage,
        landingInfo,
        useDefaultLandingInfo,
        useDefaultExpandable,
        expandableType,
        expandableAssetGroups,
        useDefaultTrackerUrl,
        clickTrackerUrl,
        eventTrackerUrl,
      }) => {
        switch (expandableType) {
          case ExpandableEnum.Type.VIDEO: {
            const {
              video: expandableVideo,
              actionButton: expandableActionButton,
            } = expandableAssetGroups?.first() || {}

            const validationResultBizBoardImage =
              !bizBoardImage || bizBoardImage.isEmpty()
                ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
                : IS_VALID()

            // 공통요소 랜딩 사용일 경우 skip
            const validationResultLandingInfo = useDefaultLandingInfo
              ? IS_VALID()
              : validateLandingInfo(landingInfo)

            const isVideoEmpty = !expandableVideo || !expandableVideo.get('url')
            const isActionButtonEmpty =
              !expandableActionButton ||
              expandableActionButton === ExpandableActionButtonEnum.Type.NONE

            // video & actionButton 모두 미설정 시 통과
            const isExpandableEmpty = isVideoEmpty && isActionButtonEmpty

            const validationResultExpandableVideo =
              useDefaultExpandable || isExpandableEmpty || !isVideoEmpty
                ? IS_VALID()
                : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

            const validationResultExpandableActionButton =
              useDefaultExpandable || isExpandableEmpty || !isActionButtonEmpty
                ? IS_VALID()
                : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

            const validationResultClickTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(clickTrackerUrl)

            const validationResultEventTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(eventTrackerUrl)

            const isValid = [
              validationResultBizBoardImage,
              validationResultLandingInfo,
              validationResultExpandableVideo,
              validationResultExpandableActionButton,
              validationResultClickTrackerUrl,
              validationResultEventTrackerUrl,
            ].every(v => v.isValid)

            bizBoardBundleValidationResults.push({
              bundleUUID,
              image: validationResultBizBoardImage,
              landingInfo: validationResultLandingInfo,
              expandableVideo: validationResultExpandableVideo,
              expandableImage: IS_VALID(),
              expandableActionButton: validationResultExpandableActionButton,
              expandableMulti: IS_VALID(),
              clickTrackerUrl: validationResultClickTrackerUrl,
              eventTrackerUrl: validationResultEventTrackerUrl,
              isValid,
            })
            break
          }

          case ExpandableEnum.Type.IMAGE: {
            const {
              image: expandableImage,
              actionButton: expandableActionButton,
            } = expandableAssetGroups?.first() || {}

            const validationResultBizBoardImage =
              !bizBoardImage || bizBoardImage.isEmpty()
                ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
                : IS_VALID()

            // 공통요소 랜딩 사용일 경우 skip
            const validationResultLandingInfo = useDefaultLandingInfo
              ? IS_VALID()
              : validateLandingInfo(landingInfo)

            const isImageEmpty = !expandableImage || !expandableImage.get('url')
            const isActionButtonEmpty =
              !expandableActionButton ||
              expandableActionButton === ExpandableActionButtonEnum.Type.NONE

            // image & actionButton 모두 미설정 시 통과
            const isExpandableEmpty = isImageEmpty && isActionButtonEmpty

            const validationResultExpandableImage =
              useDefaultExpandable || isExpandableEmpty || !isImageEmpty
                ? IS_VALID()
                : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

            const validationResultExpandableActionButton =
              useDefaultExpandable || isExpandableEmpty || !isActionButtonEmpty
                ? IS_VALID()
                : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

            const validationResultClickTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(clickTrackerUrl)

            const validationResultEventTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(eventTrackerUrl)

            const isValid = [
              validationResultBizBoardImage,
              validationResultLandingInfo,
              validationResultExpandableImage,
              validationResultExpandableActionButton,
              validationResultClickTrackerUrl,
              validationResultEventTrackerUrl,
            ].every(v => v.isValid)

            bizBoardBundleValidationResults.push({
              bundleUUID,
              image: validationResultBizBoardImage,
              landingInfo: validationResultLandingInfo,
              expandableVideo: IS_VALID(),
              expandableImage: validationResultExpandableImage,
              expandableActionButton: validationResultExpandableActionButton,
              expandableMulti: IS_VALID(),
              clickTrackerUrl: validationResultClickTrackerUrl,
              eventTrackerUrl: validationResultEventTrackerUrl,
              isValid,
            })
            break
          }

          case ExpandableEnum.Type.MULTI: {
            const validationResultBizBoardImage =
              !bizBoardImage || bizBoardImage.isEmpty()
                ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
                : IS_VALID()

            // 공통요소 랜딩 사용일 경우 skip
            const validationResultLandingInfo = useDefaultLandingInfo
              ? IS_VALID()
              : validateLandingInfo(landingInfo)

            const expandableMultiAssetValidationResults = []

            const isExpandableEmpty =
              !expandableAssetGroups || expandableAssetGroups.isEmpty()

            // 대표 익스펜더블 사용이거나 슬라이드가 0개 인 경우 skip
            if (!useDefaultExpandable && !isExpandableEmpty) {
              expandableAssetGroups.forEach(assetGroup => {
                const {
                  assetGroupUUID,
                  image: expandableImage,
                  title: expandableTitle,
                  price: expandablePrice,
                  discountedPrice: expandableDiscountedPrice,
                  actionButton: expandableActionButton,
                  mobileLandingUrl: expandableLandingUrl,
                } = assetGroup

                const validationResultExpandableImage =
                  !expandableImage || !expandableImage.get('url')
                    ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
                    : IS_VALID()

                const validationResultExpandableTitle =
                  validateCreativeTitle(expandableTitle)

                const validationResultExpandableDiscountedPrice =
                  validateDiscountedPrice({
                    price: expandablePrice,
                    discountedPrice: expandableDiscountedPrice,
                  })

                const validationResultExpandableActionButton =
                  validateExpandableMultiActionButton({
                    expandableActionButton,
                  })

                const validationResultExpandableLandingUrl =
                  validateExpandableMultiLandingUrl({
                    landingUrl: expandableLandingUrl,
                  })

                const isValid = [
                  validationResultExpandableImage,
                  validationResultExpandableTitle,
                  validationResultExpandableDiscountedPrice,
                  validationResultExpandableActionButton,
                  validationResultExpandableLandingUrl,
                ].every(v => v.isValid)

                expandableMultiAssetValidationResults.push({
                  assetGroupUUID,
                  image: validationResultExpandableImage,
                  title: validationResultExpandableTitle,
                  discountedPrice: validationResultExpandableDiscountedPrice,
                  actionButton: validationResultExpandableActionButton,
                  landingUrl: validationResultExpandableLandingUrl,
                  isValid,
                })
              })
            }

            const isAllExpandableMultiAssetValid =
              expandableMultiAssetValidationResults.length === 0 ||
              expandableMultiAssetValidationResults.every(v => v.isValid)

            const validationResultExpandableMulti =
              useDefaultExpandable || isExpandableEmpty
                ? IS_VALID()
                : validateExpandableMultiSlideCount({
                    expandableAssetGroups,
                    onValid: () =>
                      expandableAssetGroups.count() > 0 &&
                      !isAllExpandableMultiAssetValid
                        ? IS_NOT_VALID('', {
                            expandableMultiAssetValidationResults,
                          })
                        : IS_VALID(),
                  })

            const validationResultClickTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(clickTrackerUrl)

            const validationResultEventTrackerUrl = useDefaultTrackerUrl
              ? IS_VALID()
              : validateEventClickTrackerUrl(eventTrackerUrl)

            const isValid = [
              validationResultBizBoardImage,
              validationResultLandingInfo,
              validationResultExpandableMulti,
              validationResultClickTrackerUrl,
              validationResultEventTrackerUrl,
            ].every(v => v.isValid)

            bizBoardBundleValidationResults.push({
              bundleUUID,
              image: validationResultBizBoardImage,
              landingInfo: validationResultLandingInfo,
              expandableVideo: IS_VALID(),
              expandableImage: IS_VALID(),
              expandableActionButton: IS_VALID(),
              expandableMulti: validationResultExpandableMulti,
              clickTrackerUrl: validationResultClickTrackerUrl,
              eventTrackerUrl: validationResultEventTrackerUrl,
              isValid,
            })
            break
          }

          default: {
            // 공통요소 랜딩 사용일 경우 skip
            const validationResultLandingInfo = useDefaultLandingInfo
              ? IS_VALID()
              : validateLandingInfo(landingInfo)

            const validationResultBizBoardImage =
              !bizBoardImage || bizBoardImage.isEmpty()
                ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
                : IS_VALID()

            const isValid = [
              validationResultBizBoardImage,
              validationResultLandingInfo,
            ].every(v => v.isValid)

            bizBoardBundleValidationResults.push({
              bundleUUID,
              image: validationResultBizBoardImage,
              landingInfo: validationResultLandingInfo,
              isValid,
            })
            break
          }
        }
      }
    )

    const isAllValid =
      bizBoardBundleValidationResults.length === 0 ||
      bizBoardBundleValidationResults.every(v => v.isValid)

    return isAllValid
      ? IS_VALID({ bizBoardBundleValidationResults })
      : IS_NOT_VALID('', { bizBoardBundleValidationResults })
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: image => {
    const imageMap = Map.isMap(image) ? image : fromJS(image)
    if (!imageMap || imageMap?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EXPANDABLE_ASSET_GROUPS]: (
    expandableAssetGroups,
    { expandableType }
  ) => {
    switch (expandableType) {
      case ExpandableEnum.Type.VIDEO: {
        const { video: expandableVideo, actionButton: expandableActionButton } =
          expandableAssetGroups?.first() || {}

        const isVideoEmpty = !expandableVideo || !expandableVideo.get('url')
        const isActionButtonEmpty =
          !expandableActionButton ||
          expandableActionButton === ExpandableActionButtonEnum.Type.NONE

        // video & actionButton 모두 미설정 시 통과
        const isExpandableEmpty = isVideoEmpty && isActionButtonEmpty

        const validationResultExpandableVideo =
          isExpandableEmpty || !isVideoEmpty
            ? IS_VALID()
            : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

        const validationResultExpandableActionButton =
          isExpandableEmpty || !isActionButtonEmpty
            ? IS_VALID()
            : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

        const isValid = [
          validationResultExpandableVideo,
          validationResultExpandableActionButton,
        ].every(v => v.isValid)

        const repAssetGroupValidationResult = {
          video: validationResultExpandableVideo,
          actionButton: validationResultExpandableActionButton,
        }

        return isValid
          ? IS_VALID({ repAssetGroupValidationResult })
          : IS_NOT_VALID('', {
              repAssetGroupValidationResult,
            })
      }

      case ExpandableEnum.Type.IMAGE: {
        const { image: expandableImage, actionButton: expandableActionButton } =
          expandableAssetGroups?.first() || {}

        const isImageEmpty = !expandableImage || !expandableImage.get('url')
        const isActionButtonEmpty =
          !expandableActionButton ||
          expandableActionButton === ExpandableActionButtonEnum.Type.NONE

        // image & actionButton 모두 미설정 시 통과
        const isExpandableEmpty = isImageEmpty && isActionButtonEmpty

        const validationResultExpandableImage =
          isExpandableEmpty || !isImageEmpty
            ? IS_VALID()
            : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

        const validationResultExpandableActionButton =
          isExpandableEmpty || !isActionButtonEmpty
            ? IS_VALID()
            : IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)

        const isValid = [
          validationResultExpandableImage,
          validationResultExpandableActionButton,
        ].every(v => v.isValid)

        const repAssetGroupValidationResult = {
          image: validationResultExpandableImage,
          actionButton: validationResultExpandableActionButton,
        }

        return isValid
          ? IS_VALID({ repAssetGroupValidationResult })
          : IS_NOT_VALID('', {
              repAssetGroupValidationResult,
            })
      }

      case ExpandableEnum.Type.MULTI: {
        if (!expandableAssetGroups || expandableAssetGroups.isEmpty()) {
          return IS_VALID()
        }

        const validationResultExpandableSlideCount =
          validateExpandableMultiSlideCount({ expandableAssetGroups })

        const repMultiAssetGroupValidationResults = []

        expandableAssetGroups.forEach(assetGroup => {
          const {
            assetGroupUUID,
            image: expandableImage,
            title: expandableTitle,
            price: expandablePrice,
            discountedPrice: expandableDiscountedPrice,
            actionButton: expandableActionButton,
            mobileLandingUrl: expandableLandingUrl,
          } = assetGroup

          const validationResultExpandableImage =
            !expandableImage || !expandableImage.get('url')
              ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
              : IS_VALID()

          const validationResultExpandableTitle =
            validateCreativeTitle(expandableTitle)

          const validationResultExpandableActionButton =
            validateExpandableMultiActionButton({ expandableActionButton })

          const validationResultExpandableDiscountedPrice =
            validateDiscountedPrice({
              price: expandablePrice,
              discountedPrice: expandableDiscountedPrice,
            })
          const validationResultExpandableLandingUrl =
            validateExpandableMultiLandingUrl({
              landingUrl: expandableLandingUrl,
            })

          const isValid = [
            validationResultExpandableImage,
            validationResultExpandableTitle,
            validationResultExpandableDiscountedPrice,
            validationResultExpandableActionButton,
            validationResultExpandableLandingUrl,
          ].every(v => v.isValid)

          repMultiAssetGroupValidationResults.push({
            assetGroupUUID,
            image: validationResultExpandableImage,
            title: validationResultExpandableTitle,
            discountedPrice: validationResultExpandableDiscountedPrice,
            actionButton: validationResultExpandableActionButton,
            landingUrl: validationResultExpandableLandingUrl,
            isValid,
          })
        })

        const repAssetGroupValidationResult = {
          slideCount: validationResultExpandableSlideCount,
          multiAssetGroup: repMultiAssetGroupValidationResults,
        }

        const isAllValid = repMultiAssetGroupValidationResults
          .concat([validationResultExpandableSlideCount])
          .every(v => v.isValid)

        return isAllValid
          ? IS_VALID({
              repAssetGroupValidationResult,
            })
          : IS_NOT_VALID('', {
              repAssetGroupValidationResult,
            })
      }

      default: {
        break
      }
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: (
    landingInfo,
    formData,
    getState
  ) => {
    const { id, bizBoardBundles } = formData
    const {
      creativeV2: {
        common: {
          adGroup: { skanProtocolInfo },
        },
      },
    } = getState()

    const isModify = id > 0

    if (checkNotEmpty(skanProtocolInfo)) {
      return IS_VALID()
    }

    if (isModify) {
      return validateLandingInfo(landingInfo)
    } else {
      /**
       * bizBoardBundle 전체가 개별 랜딩 설정일 경우 대표 랜딩 validation skip
       */
      const isAllUseCustomLandingInfo =
        bizBoardBundles.count() > 0 &&
        bizBoardBundles.every(
          bizBoardBundle => !bizBoardBundle.get('useDefaultLandingInfo')
        )

      return isAllUseCustomLandingInfo
        ? IS_VALID()
        : validateLandingInfo(landingInfo)
    }
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL({ formData, campaignType })
  },

  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: frequencyCap => {
    return validateCreativeFrequencyCap(frequencyCap)
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
        isNew: !isModify,
      })
    )
  },

  [CREATIVE_FORM_VALIDATION_KEY.MOTION_BOARD_VIDEO]: (
    motionBoardVideo,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: { contractInfo: { contractProductItems } = {} },
        },
      },
    } = getState()
    const { subType } = contractProductItems?.first() || {}
    if (
      subType === BizBoardSubTypeEnum.Type.MOTION &&
      isUndefinedOrNull(motionBoardVideo)
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_IMAGE]: (
    motionBoardBackgroundImage,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: { contractInfo: { contractProductItems } = {} },
        },
      },
    } = getState()
    const { subType } = contractProductItems?.first() || {}
    if (
      BizBoardSubTypeEnum.isSpecialBizBoard(subType) &&
      isUndefinedOrNull(motionBoardBackgroundImage)
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.TEXT_IMAGE]: (
    motionBoardTextImage,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: { contractInfo: { contractProductItems } = {} },
        },
      },
    } = getState()
    const { subType } = contractProductItems?.first() || {}
    if (
      BizBoardSubTypeEnum.isSpecialBizBoard(subType) &&
      isUndefinedOrNull(motionBoardTextImage)
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.OBJECT_IMAGE]: (
    motionBoardObjectImage3,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: { contractInfo: { contractProductItems } = {} },
        },
      },
    } = getState()
    const { subType } = contractProductItems?.first() || {}
    if (
      subType === BizBoardSubTypeEnum.Type.MOTION &&
      isUndefinedOrNull(motionBoardObjectImage3)
    ) {
      return IS_NOT_VALID('필수 입력(이미지 3개) 항목입니다.')
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },
})

export {
  BIZ_BOARD_BANNER_FORM_VALIDATION_KEY_PATH,
  BIZ_BOARD_BANNER_FORM_VALIDATOR,
  validateBizBoardExpandableAsset,
}
