import { List, Map, Range } from 'immutable'
import DeviceTypeEnum from '../../enums/DeviceTypeEnum'
import DeviceOsTypeEnum from '../../enums/DeviceOsTypeEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import moment from 'moment'
import { getVat, queryParams } from '../utils'
import { replaceCurrentTimeToBaseTime } from '../../components/Common/HourMinuteSelector/HourMinuteSelectorHOC'
import memoizeOne from 'memoize-one'
import { checkEmpty, checkNotEmpty, isUndefinedOrNull } from '../regexUtils'
import {
  COMBINED_POPULATION_MAX_SCORE,
  COMBINED_POPULATION_SCORE_MIN,
  getPopulationAdditionalString,
  LOCATION_KEY,
  LOCATION_TYPE,
} from './targeting'
import { showErrorMessage } from '../alertUtils'
import { getApiPhase } from '../app/services/kakaoMoment'
import { audienceTotalTargetingKeys } from '../../components/Targeting/Audience/Common/audience'
import SchedulePeriodTypeEnum from '../../enums/SchedulePeriodTypeEnum'
import { NumberUtils } from '../numberUtils'
import ObjectiveDetailTypeEnum from '../../enums/ObjectiveDetailTypeEnum'
import TargetingInclusionEnum from '../../enums/TargetingInclusionEnum'

export const memoAvailableCampaignObjectiveDetailTypes = memoizeOne(
  (objectiveType, constraints, campaignType) => {
    if (campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE) {
      // 고정값
      return List([
        Map({
          objectiveDetailName: '채널 메시지 발송',
          objectiveDetailType: ObjectiveDetailTypeEnum.Type.SEND_MESSAGE,
          objectiveType: 'TALK_CHANNEL',
        }),
      ])
    }

    if (checkEmpty(constraints) || !constraints.size) return List()

    return constraints.groupBy(v => v.get('objectiveType')).get(objectiveType)
  }
)

/**
 * 광고그룹 게재지면 정보
 * @type {function(*): (Immutable.Seq.Indexed<K> | Seq.Indexed<K> | * | Range | undefined)}
 */
export const memoAdGroupPlacements = memoizeOne(
  devicePlacementConstraints =>
    devicePlacementConstraints
      ?.groupBy(v => v?.get('placement'))
      ?.map(v => v.first())
      ?.keySeq() || []
)

/**
 * 광고그룹 입찰방식 정보
 * @type {function(*): (Immutable.Seq.Indexed<K> | Seq.Indexed<K> | * | Range | undefined)}
 */
export const memoAdGroupPricingTypes = memoizeOne(
  pricingTypeConstraints =>
    pricingTypeConstraints
      ?.groupBy(v => v?.get('pricingType'))
      ?.map(v => v.first())
      ?.keySeq() || []
)

/**
 * 입찰방식 선택 items
 * @type {function(*): (Immutable.Seq.Indexed<K> | Seq.Indexed<K> | * | Range | undefined)}
 */
export const memoAdGroupBidStrategyTypes = memoizeOne(
  bidConstraints =>
    bidConstraints
      ?.groupBy(v => v?.get('bidStrategy'))
      ?.map(v => v.first())
      ?.keySeq() || []
)

/**
 * 게재방식 선택 items
 * @type {function(*, *): *}
 */
export const memoAdGroupPacingTypes = memoizeOne(
  (bidConstraints, bidStrategy) =>
    bidConstraints
      ?.filter(v => v?.get('bidStrategy') === bidStrategy)
      ?.groupBy(v => v?.get('pacing'))
      ?.map(v => v.first())
      ?.keySeq() || []
)

export const memoAdGroupSelectedTargetingSize = memoizeOne(targeting => {
  return (
    targeting // targeting 내에 다른 값들이 추가되면서 필터링조건 추가됌
      ?.filter((v, k) => audienceTotalTargetingKeys.includes(k))
      ?.reduce((prev, v) => prev + v.size, 0) || 0
  )
})

/**
 * 모수 최소값 리턴 (카카오톡 채널 X 도달 소재최적화 30000, 카카오톡 채널 X 도달 실시간타겟 100000 그 외 모든케이스는 10)
 * @param campaignType
 * @param goal
 * @param populationScore
 * @param targeting
 * @param smartMessage
 * @returns {number}
 */
export const getAdGroupTalkChannelPopulationMin = (
  campaignType,
  goal,
  populationScore,
  targeting,
  smartMessage
) => {
  if (isTalkChannelReach(campaignType, goal) && smartMessage) {
    return getTalkChannelSmartMessageMinCount() - 1
  } else {
    return COMBINED_POPULATION_SCORE_MIN
  }
}

/**
 * 구매방식 금액 계산
 * @param campaignType
 * @param goal
 * @param adGroupConstraints
 * @param targeting
 * @param deviceTypes
 * @param schedule
 * @param selectedLocationType
 * @param dynamicTarget
 * @param smartMessage
 * @returns {number|*}
 */
export const memoTotalBudgetByPricingConstraints = memoizeOne(
  ({
    campaignType,
    goal,
    adGroupConstraints,
    targeting,
    deviceTypes,
    schedule,
    selectedLocationType,
    dynamicTarget,
    smartMessage,
  }) => {
    if (goal !== GoalEnum.Type.REACH || !adGroupConstraints?.size) return 0

    const { priceConstraints } = adGroupConstraints
      .get('pricingTypeConstraints')
      .first()

    // 톡채널, 쇼핑 외에는 guideBidAmount 사용 (도달 아닌 목표도 포함)
    switch (campaignType) {
      case CampaignTypeEnum.Type.ELECTION_2022_03:
      case CampaignTypeEnum.Type.ELECTION_2022_06: {
        const selectedDeviceType =
          DeviceTypeEnum.getSelectedDeviceType(deviceTypes)

        /**
         * pc_deal_id : election_2022_03_pc | election_2022_06_pc
         * mobile_deal_id : election_2022_03_mobile | election_2022_06_mobile
         */
        const [pc_deal_id, mobile_deal_id] = priceConstraints
          .groupBy(v => v.get('deal').get('sspDealId'))
          .keySeq()

        return (
          priceConstraints
            ?.filter(v =>
              selectedDeviceType === DeviceTypeEnum.Type.MOBILE
                ? v?.getIn(['deal', 'sspDealId']) === mobile_deal_id
                : v?.getIn(['deal', 'sspDealId']) === pc_deal_id
            )
            ?.first()
            ?.get('guideBidAmount') ?? 0
        )
      }

      case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
        const { guideBidAmount } = priceConstraints.first()
        return guideBidAmount || 0
      }

      case CampaignTypeEnum.Type.TALK_CHANNEL: {
        const targetingCount = memoAdGroupSelectedTargetingSize(
          targeting.filterNot((v, k) => k === 'locations') // locations 를 제외한 타게팅 설정 개수 체크
        )

        const { price } =
          priceConstraints.find(v =>
            targetingCount <= 0 && // 타게팅을 설정하지 않았고
            !dynamicTarget && // 실시간 타겟이 아니고
            !smartMessage &&
            selectedLocationType === LOCATION_TYPE.ALL &&
            !targeting.get('locations').includes(LOCATION_KEY.Z) // 전체 옵션 내에서 해외만 제외한 경우는 논타겟
              ? v.get('nonTarget')
              : !v.get('nonTarget')
          ) || {} // 미설정 및 전체 선택이면 nonTarget
        return price || 0
      }

      case CampaignTypeEnum.Type.DAUM_SHOPPING: {
        const { periodUnit, beginDate } = schedule
        const current = DeviceTypeEnum.getSelectedDeviceType(deviceTypes)
        const price = priceConstraints
          .filter(
            v =>
              v.get('periodUnit') === periodUnit &&
              moment(v.get('applyDate')).isSameOrBefore(moment(beginDate))
          )
          .filter(v =>
            current === DeviceTypeEnum.Type.ALL
              ? v.getIn(['deal', 'sspDealId']) === 'shopping_box'
              : current === DeviceTypeEnum.Type.PC
              ? v.getIn(['deal', 'sspDealId']) === 'shopping_box_pc'
              : v.getIn(['deal', 'sspDealId']) === 'shopping_box_mobile'
          )
          .sort((a, b) => {
            const v1 = moment(a.get('applyDate'))
            const v2 = moment(b.get('applyDate'))
            return v2.isBefore(v1) ? -1 : v1.isBefore(v2) ? 1 : 0
          })
          .groupBy(v => v.get('price'))
          .keySeq()
          .first()

        return price || 0
      }

      /**
       * 다음 쇼핑과 마찬가지로 applyDate 기준으로 가격정보 추출
       */
      case CampaignTypeEnum.Type.PERSONAL_MESSAGE: {
        const price = priceConstraints
          .filter(({ applyDate }) => moment(applyDate).isSameOrBefore(moment()))
          .groupBy(({ price }) => price)
          .keySeq()
          .first()

        return price || 0
      }

      default:
        return 0
    }
  }
)

export const memoAdGroupBidAmountConstraint = memoizeOne(
  (pricingTypeConstraints, pricingType = null) => {
    if (!pricingType || !pricingTypeConstraints) return 0

    const { priceConstraints } =
      pricingTypeConstraints
        .filter(v => v.get('pricingType') === pricingType)
        .first() || {}

    if (!pricingTypeConstraints) {
      showErrorMessage(
        `${pricingType} 입찰방식 제약 데이터가 존재하지 않음: ${pricingTypeConstraints.toJS()}`
      )
    }

    return priceConstraints?.first() || {}
  }
)

/**
 * 톡채널의 집행날짜, 집행시간 설정 (오늘 20시 이후는 +1d 및 오전 08시로 고정)
 * @type {function(moment.Moment): moment.Moment}
 */
export const getAdGroupTalkChannelReplacedScheduleBeginTime = (
  today = moment()
) => {
  const availableHour = getHours(8, 21).find(v => v.id === today.hour())
  if (availableHour?.id === 20 && today.minute() >= 50) {
    // 20:50 ~ 20:59 case
    return today.add(1, 'd').hour(8).minute(0).second(0)
  } else {
    const defaultHour = availableHour?.value || '08'
    const defaultMinute = replaceCurrentTimeToBaseTime(
      10,
      availableHour?.id || 8,
      availableHour ? today.minute() : 50
    ).minute()

    return today
      .add(!isUndefinedOrNull(availableHour) || today.hour() < 8 ? 0 : 1, 'd')
      .hour(defaultHour)
      .add(defaultMinute === 0 && defaultHour > 8 ? 1 : 0, 'h')
      .minute(defaultMinute)
      .second(0)
  }
}

/**
 * 캠페인유형별 schedule state 업데이트 처리
 * @param campaignType
 * @param scheduleState
 * @param rest
 */
export const updateAdGroupScheduleByCampaignType = ({
  campaignType,
  scheduleState,
  ...rest
}) => {
  switch (campaignType) {
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
    case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
    case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
    case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
    case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
      const { contractInfo, isNewContract } = rest

      if (!contractInfo || isNewContract) {
        return scheduleState
      }

      const { contractProductItems } = contractInfo
      const [item] = contractProductItems
      const { beginDateTime, endDateTime } = item

      return scheduleState
        .set('beginDate', moment(beginDateTime).format(moment.HTML5_FMT.DATE))
        .set(
          'beginTime',
          moment(beginDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
        )
        .set('endDate', moment(endDateTime).format(moment.HTML5_FMT.DATE))
        .set(
          'endTime',
          moment(endDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
        )
    }

    case CampaignTypeEnum.Type.DAUM_SHOPPING: {
      return scheduleState
        .update('beginDate', beginDate =>
          moment(beginDate)
            .add(1, 'M')
            .startOf('M')
            .format(moment.HTML5_FMT.DATE)
        )
        .set(
          'endDate',
          moment(scheduleState.get('beginDate'))
            .endOf('M')
            .format(moment.HTML5_FMT.DATE)
        )
        .set('periodUnit', SchedulePeriodTypeEnum.Type.MONTH)
    }

    case CampaignTypeEnum.Type.TALK_CHANNEL: {
      return scheduleState
        .set(
          'beginDate',
          getAdGroupTalkChannelReplacedScheduleBeginTime().format(
            moment.HTML5_FMT.DATE
          )
        )
        .set(
          'beginTime',
          getAdGroupTalkChannelReplacedScheduleBeginTime().format(
            moment.HTML5_FMT.TIME_SECONDS
          )
        )
    }

    case CampaignTypeEnum.Type.ELECTION_2022_03:
    case CampaignTypeEnum.Type.ELECTION_2022_06: {
      const { deal } = rest

      if (!deal) {
        return scheduleState
      }

      const { beginDateTime, endDateTime } = deal || {}

      return scheduleState
        .set(
          'beginDate',
          moment().isBefore(beginDateTime)
            ? moment(beginDateTime).format(moment.HTML5_FMT.DATE)
            : moment().format(moment.HTML5_FMT.DATE)
        )
        .set('endDate', moment(endDateTime).format(moment.HTML5_FMT.DATE))
    }

    case CampaignTypeEnum.Type.ELECTION_2024_04: {
      const { contractInfo } = rest

      const { contractProductItems } = contractInfo
      const [item] = contractProductItems
      const { beginDateTime, endDateTime } = item

      return scheduleState
        .set('beginDate', moment(beginDateTime).format(moment.HTML5_FMT.DATE))
        .set(
          'beginTime',
          moment(beginDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
        )
        .set('endDate', moment(endDateTime).format(moment.HTML5_FMT.DATE))
        .set(
          'endTime',
          moment(endDateTime).format(moment.HTML5_FMT.TIME_SECONDS)
        )
    }

    default: {
      return scheduleState
    }
  }
}

/**
 * 캠페인 생성 or 수정 상태 체크
 * @param location
 * @returns {boolean|*}
 */
export const isCampaignModifyStatus = location => {
  if (!location) return false

  const { create, campaignId } = queryParams(location)
  return checkEmpty(create) && checkNotEmpty(campaignId)
}

/**
 * 새 광고그룹 만들기로 이동 상태 체크
 * @param location
 * @returns {boolean|*}
 */
export const isDirectAdGroupCreate = location => {
  if (!location) return false

  const { campaignId, adGroupId, create } = queryParams(location)

  return (
    checkNotEmpty(campaignId) && !checkNotEmpty(adGroupId) && Boolean(create)
  )
}

/**
 * 광고그룹 생성 or 수정 상태 체크
 * @param location url param
 * @returns {*|boolean}
 */
export const isAdGroupModifyStatus = location => {
  if (!location) return false

  const { create, adGroupId } = queryParams(location)
  return checkEmpty(create) && checkNotEmpty(adGroupId)
}

/**
 * 광고그룹 집행예정 상태 체크
 * @param beginDate
 * @param beginTime
 * @param campaignType
 * @param goal
 * @returns {boolean}
 */
export const isAdGroupScheduleReady = ({
  beginDate,
  beginTime,
  campaignType,
  goal,
}) => {
  if (isTalkChannelReach(campaignType, goal)) {
    return moment().isBefore(
      moment(`${beginDate} ${beginTime}`).subtract(5, 'm')
    )
  } else if (campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING) {
    return moment().isBefore(moment(beginDate), 'd')
  } else if (beginTime) {
    return moment().isBefore(moment(`${beginDate} ${beginTime}`))
  } else {
    return moment().isBefore(moment(beginDate), 'd')
  }
}

export const memoAdGroupPlacementsBySelectedDeviceTypes = memoizeOne(
  (deviceTypes, devicePlacementConstraints) => {
    const isPcOnly =
      DeviceOsTypeEnum.hasPcOsType(deviceTypes) &&
      !DeviceOsTypeEnum.hasMobileOsType(deviceTypes)
    const isMobileOnly =
      DeviceOsTypeEnum.hasMobileOsType(deviceTypes) &&
      !DeviceOsTypeEnum.hasPcOsType(deviceTypes)

    const deviceConstraint = devicePlacementConstraints.filter(constraint => {
      const { device } = constraint
      return isPcOnly
        ? device === DeviceTypeEnum.Type.PC
        : isMobileOnly
        ? device === DeviceTypeEnum.Type.MOBILE
        : true
    })

    return memoAdGroupPlacements(deviceConstraint)
  }
)

/**
 * 기간 시작일의 가장 빠른 날짜와 기간 종료일의 가장 미래의 날짜로 텍스트 조합
 * @param creatingAdGroups
 * @returns {string}
 */
export const memoCampaignSideBarPeriodTextOnCreate = memoizeOne(
  (creatingAdGroups, campaignType, selectedDate) => {
    if (!creatingAdGroups || creatingAdGroups.size === 0) return '-'
    if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
      return checkNotEmpty(selectedDate) ? selectedDate : '-'
    }

    /**
     * 오름차순 정렬. endDate의 경우 nullable 하므로, 미설정일 경우 null 값을 가장 후순위로 정렬함.
     * @param key
     * @returns {*}
     */
    const sortedPeriodList = key => {
      return creatingAdGroups
        .map(v => v.getIn(['adGroupForm', 'schedule', key]))
        .sort((a, b) => {
          if (!a) return 1
          if (!b) return -1

          const v1 = moment(a)
          const v2 = moment(b)

          return v2.isBefore(v1) ? 1 : v1.isBefore(v2) ? -1 : 0
        })
    }

    const beginDate = sortedPeriodList('beginDate').first()
    const endDate = sortedPeriodList('endDate').last()
    const beginDateText = beginDate || ''
    const endDateText = checkNotEmpty(endDate) ? endDate : '미설정'

    return beginDateText ? `${beginDateText} ~ ${endDateText}` : '-'
  }
)

export const memoSelectedTrackDescription = memoizeOne(
  (trackRights, selectedValue) =>
    trackRights
      ?.filter(v => v.get('trackId') === selectedValue)
      ?.groupBy(v => v.get('trackName'))
      ?.keySeq()
      ?.first() || ''
)

export const memoCampaignTalkChannelObjectiveOptionListOnCreate = memoizeOne(
  plusFriendProfileList =>
    plusFriendProfileList?.map(pf => ({
      value: pf.get('id'),
      label: pf.get('name'),
      imageUrl: pf.get('profileImageUrl'),
    })) ?? null
)

export const memoCampaignTalkChannelObjectiveOptionListOnModify = memoizeOne(
  plusFriendProfileInfo =>
    plusFriendProfileInfo && !plusFriendProfileInfo.isEmpty()
      ? Array.of({
          value: plusFriendProfileInfo.get('id'),
          label: plusFriendProfileInfo.get('name'),
          imageUrl: plusFriendProfileInfo.get('profileImageUrl'),
        })
      : []
)

export const memoCampaignSideBarPeriodTextOnAdGroupModify = memoizeOne(
  (adGroupForm, campaignType) => {
    const { schedule } = adGroupForm || {}
    const beginDate = schedule?.get('beginDate') || ''
    const endDate = schedule?.get('endDate') || ''
    const endDateText = checkNotEmpty(endDate) ? endDate : '미설정'

    return CampaignTypeEnum.isContractCampaignType(campaignType)
      ? beginDate
      : beginDate
      ? `${beginDate} ~ ${endDateText}`
      : '-'
  }
)

/* fixme 예상발송모수 관련 정책 확인 필요 nonTargeting일때 validation은 10명 이하인경우 실패함 그런데 nonTargeting 발송가능 최소 모수는 0으로 되어있음 */
export const memoExpectedPopulationTextByType = memoizeOne(
  (campaignType, populationScore) => {
    if (checkEmpty(campaignType) || checkEmpty(populationScore)) return ''
    if (
      campaignType === CampaignTypeEnum.Type.SPONSORED_BOARD ||
      campaignType === CampaignTypeEnum.Type.VIDEO ||
      campaignType === CampaignTypeEnum.Type.DISPLAY ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
      campaignType === CampaignTypeEnum.Type.ELECTION_2022_06
    ) {
      const score =
        populationScore >= COMBINED_POPULATION_MAX_SCORE
          ? COMBINED_POPULATION_MAX_SCORE
          : populationScore

      return `${NumberUtils.withCommas(score)} ${getPopulationAdditionalString(
        populationScore
      )}`
    } else {
      const text =
        populationScore <= COMBINED_POPULATION_SCORE_MIN ? '이하' : ''
      return `${NumberUtils.withCommas(populationScore)} ${text}`
    }
  }
)

export const getTalkChannelSmartMessageMinCount = () => {
  const talkChannelSmartMessageMinCount = {
    production: 30000,
    stage: 20,
    sandbox: 11,
    develop: 11,
  }

  return (
    talkChannelSmartMessageMinCount[getApiPhase()] ||
    talkChannelSmartMessageMinCount.sandbox
  )
}

export const PRICE_UNIT_TEXT = {
  [CampaignTypeEnum.Type.TALK_BIZ_BOARD]: '1000 노출',
  [CampaignTypeEnum.Type.TALK_CHANNEL]: '건',
  [CampaignTypeEnum.Type.DAUM_SHOPPING]: '구좌',
  [CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED]: '구좌',
  [CampaignTypeEnum.Type.PC_TALK_BOTTOM]: '구좌',
  [CampaignTypeEnum.Type.PC_TALK_RICH_POP]: '구좌',
  [CampaignTypeEnum.Type.KAKAO_TV]: '1000 노출',
  [CampaignTypeEnum.Type.ELECTION_2024_04]: '구좌',
  [CampaignTypeEnum.Type.FOCUS_FULL_VIEW]: '구좌',
  [CampaignTypeEnum.Type.PROFILE_FULL_VIEW]: '구좌',
}

export const pricingTypeSubTitleToolTip = memoizeOne(pricingType => {
  const pricingTypeToolTip = {
    CPA: 'create_ads.v2.ad_group.price.cpa',
    CPC: 'create_ads.v2.ad_group.price.cpc',
    CPM: 'create_ads.v2.ad_group.price.cpm',
    CPT: 'create_ads.v2.ad_group.price.cpt',
    CPV: 'create_ads.v2.ad_group.price.cpv',
    CPMS: 'create_ads.v2.ad_group.price.cpms',
    OCPM: 'create_ads.v2.ad_group.price.ocpm',
  }
  return pricingTypeToolTip[pricingType]
})

export const isTalkChannelReach = memoizeOne((campaignType, goal) => {
  return (
    campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
    goal === GoalEnum.Type.REACH
  )
})

export const getHours = (beginTime = 8, endTime = 21) => {
  return Range(beginTime, endTime)
    .map(id => {
      const label = id < 10 ? `0${id}시` : `${id}시`
      const value = id < 10 ? `0${id}` : `${id}`
      return Map({ id, label, value })
    })
    .toJS()
}

export const getMinutes = () => {
  return Range(0, 60, 5)
    .map(id => {
      const label = id < 10 ? `0${id}분` : `${id}분`
      const value = id < 10 ? `0${id}` : `${id}`
      return Map({ id, label, value })
    })
    .toJS()
}

export const getTotalBudgetWithVATText = (totalBudget, totalBudgetVAT) => {
  if (!totalBudget || !totalBudgetVAT) return '-'

  return `${NumberUtils.withCommas(totalBudget)}원
    (VAT 포함 ${NumberUtils.withCommas(totalBudgetVAT)}원)`
}

export const adGroupCalculatedBudgetAmount = (adGroupList, keyPath) =>
  adGroupList?.reduce(
    (prev, item) => (Number(item?.getIn(keyPath)) || 0) + prev,
    0
  ) || 0

export const campaignSummaryBudgetText = ({
  campaignForm,
  creatingAdGroups,
  campaignViewState,
  isModify = false,
}) => {
  const {
    dailyBudget = 0,
    contractBudget = 0,
    totalBudget = 0,
    totalBudgetWithVAT = 0,
    campaignTypeGoal,
  } = campaignForm || {}

  const { campaignType } = campaignTypeGoal || {}

  switch (campaignType) {
    // contractBudget
    case CampaignTypeEnum.Type.KAKAO_TV: {
      return getTotalBudgetWithVATText(contractBudget, getVat(contractBudget))
    }

    // totalBudget
    case CampaignTypeEnum.Type.TALK_CHANNEL:
    case CampaignTypeEnum.Type.DAUM_SHOPPING:
    case CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED:
    case CampaignTypeEnum.Type.ELECTION_2024_04:
    case CampaignTypeEnum.Type.PC_TALK_BOTTOM:
    case CampaignTypeEnum.Type.PC_TALK_RICH_POP:
    case CampaignTypeEnum.Type.FOCUS_FULL_VIEW:
    case CampaignTypeEnum.Type.PROFILE_FULL_VIEW: {
      const adGroupTotalBudget = isModify
        ? totalBudget
        : adGroupCalculatedBudgetAmount(creatingAdGroups, [
            'adGroupForm',
            'totalBudget',
          ])

      const adGroupTotalBudgetWithVAT = isModify
        ? totalBudgetWithVAT
        : adGroupCalculatedBudgetAmount(creatingAdGroups, [
            'adGroupViewState',
            'totalBudgetVAT',
          ])

      // 광고그룹 기간 예산의 합
      return getTotalBudgetWithVATText(
        adGroupTotalBudget,
        adGroupTotalBudgetWithVAT
      )
    }

    // dailyBudget
    default: {
      const { isDailyBudgetUnlimited } = campaignViewState || {}

      return isDailyBudgetUnlimited // 캠페인 일 예산 미설정 체크
        ? '미설정'
        : `${NumberUtils.withCommas(dailyBudget)}원`
    }
  }
}

export const memoCreativeFormatPriceConstraints = memoizeOne(
  ({ campaignType, adGroupConstraints }) => {
    // 개인화메시지인 경우만
    if (campaignType !== CampaignTypeEnum.Type.PERSONAL_MESSAGE) return

    const { priceConstraints } =
      adGroupConstraints.get('pricingTypeConstraints').first() || {}

    return (
      priceConstraints
        .filter(({ applyDate }) => moment(applyDate).isSameOrBefore(moment()))
        .groupBy(
          ({ creativeFormatPriceConstraints }) => creativeFormatPriceConstraints
        )
        .keySeq()
        .first() || {}
    )
  }
)

/**
 * 유효하지 않은 광고그룹 설정 체크하여 팝업 노출
 *  - 조건: 디바이스 PC only && 아래 타게팅 항목의 설정이 제외인 타겟 설정시
 *  - 대상 타겟: plusFriendTargetings, syncAppTargetings, customerFileTargetings
 */
export const checkInvalidAdGroupSettings = formData => {
  if (checkEmpty(formData)) {
    return false
  }

  const {
    deviceTypes = [],
    targeting: {
      plusFriendTargetings = [],
      syncAppTargetings = [],
      customerFileTargetings = [],
    },
  } = formData

  const hasPcOnly =
    deviceTypes.size > 0 && // size 0은 '가능한 모든 디바이스'
    deviceTypes.every(value => value === DeviceTypeEnum.Type.PC)

  const hasExcludeItem = [
    ...plusFriendTargetings,
    ...syncAppTargetings,
    ...customerFileTargetings,
  ].some(
    ({ inclusionType }) => inclusionType === TargetingInclusionEnum.Type.EXCLUDE
  )

  return hasPcOnly && hasExcludeItem
}
