import { Map } from 'immutable'
import {
  validateCreativeActionButton,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
  validateCreativePromotionalVideo,
  validateCreativePromotionalWord,
  validateCreativeTitle,
} from './creativeFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_IMAGE_TYPE } from '../../../utils/advertise/creativeImage'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import { checkNotEmpty, checkUrl } from '../../../utils/regexUtils'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const VIDEO_RESPONSIVE_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: ['uploadedImages'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: ['profileImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_VIDEO_CREATE]: ['uploadedVideo'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_VIDEO_MODIFY]: ['video'],
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: ['title'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: ['description'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButton'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const VIDEO_RESPONSIVE_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: uploadedImages => {
    if (
      !uploadedImages ||
      uploadedImages?.isEmpty() ||
      uploadedImages
        .keySeq()
        .every(k => !k.startsWith(CREATIVE_IMAGE_TYPE.PROFILE))
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: modifyImages => {
    if (!modifyImages || modifyImages?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_VIDEO_CREATE]: video => {
    return validateCreativePromotionalVideo(video)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_VIDEO_MODIFY]: video => {
    return validateCreativePromotionalVideo(video)
  },

  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: (title, formData) => {
    return validateCreativeTitle(title, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: (description, formData) => {
    return validateCreativePromotionalWord(description, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (profileName, formData) => {
    return validateCreativeProfileName(profileName, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL({ formData, campaignType })
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: name => {
    return validateCreativeName(name)
  },
})

export {
  VIDEO_RESPONSIVE_FORM_VALIDATION_KEY_PATH,
  VIDEO_RESPONSIVE_FORM_VALIDATOR,
}
