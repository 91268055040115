import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkEmpty } from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  validateAdViewBrandName,
  validateAdViewTitle,
} from './adViewFormValidator'

export const TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2 = keyMirror({
  TALK_SHARE_BRAND_NAME: null,
  TALK_SHARE_TITLE: null,
  TALK_SHARE_CONTENTS: null,
})

export const TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_PATH_V2 = Map({
  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_BRAND_NAME]: [
    'brandName',
  ],
  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_TITLE]: ['title'],
  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_CONTENTS]: [
    'contents',
  ],
})

export const TALK_SHARE_CONTENTS_FORM_VALIDATOR_V2 = Map({
  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_BRAND_NAME]:
    brandName => {
      return validateAdViewBrandName(brandName)
    },

  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_TITLE]: title => {
    return validateAdViewTitle(title)
  },

  [TALK_SHARE_CONTENTS_FORM_VALIDATION_KEY_V2.TALK_SHARE_CONTENTS]:
    contents => {
      if (checkEmpty(contents) || contents.isEmpty()) {
        return IS_NOT_VALID('홍보이미지를 업로드하세요.')
      }

      return IS_VALID()
    },
})
