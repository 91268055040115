import React from 'react'
import PopupProxy from '../../../Popup/Common/PopupProxy'
import { convertToCommaSeparatedString } from '../../../../utils/utils'

const exceedAdDialog = (mySelf, parent) => {
  return (
    <PopupProxy>
      <strong className="tit_layer">{mySelf} 제한 수 초과</strong>
      <p className="txt_layer">
        {parent} 당 {mySelf} 수 제한으로
        <br />
        {mySelf} 만들기를 할 수 없습니다.
        <br />
        {mySelf} 삭제 후 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

export const exceedCampaignOfAdAccountDialog = () =>
  exceedAdDialog('캠페인', '광고계정')

export const exceedAdGroupOfAdAccountDialog = () =>
  exceedAdDialog('광고그룹', '광고계정')

export const exceedCreativeOfAdGroupDialog = () =>
  exceedAdDialog('소재', '광고그룹')

export const exceedCreativeOfAdAccountDialog = () =>
  exceedAdDialog('소재', '광고계정')

const deleteAdDialog = (mySelf, onOK) => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOK}>
      <strong className="tit_layer">{mySelf} 삭제</strong>
      <p className="txt_layer">삭제하시겠습니까?</p>
    </PopupProxy>
  )
}

export const deleteCampaignDialog = onOK => deleteAdDialog('캠페인', onOK)
export const deleteAdGroupDialog = onOK => deleteAdDialog('광고그룹', onOK)
export const deleteCreativeDialog = onOK => deleteAdDialog('소재', onOK)
