import { fromJS, Map } from 'immutable'
import validateColor from 'validate-color'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import {
  validateCreativeActionButton,
  validateCreativeAltText,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
  validateCreativePromotionalVideo,
  validateCreativePromotionalWord,
  validateCreativeTitle,
  validateLandingInfo,
} from './creativeFormValidatorV2'
import { CREATIVE_IMAGE_TYPE } from '../../../utils/advertise/creativeImage'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  hasFullWidthWhitespace,
  validateColorCodeValue,
} from '../../../utils/regexUtils'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'
import {
  ProfileFullViewBackgroundEnum,
  ProfileFullViewTitleEnum,
} from '../../../enums/CreativeProfileFullViewEnum'

const PROFILE_FULL_VIEW_FORM_VALIDATION_KEY_PATH = Map({
  /** 배경 구성 요소 */
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: [
    'uploadedMultiImages',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_VIDEO_CREATE]: ['uploadedVideo'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_VIDEO_MODIFY]: ['video'],

  /** 텍스트 구성 요소 */
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: ['title'],
  [CREATIVE_FORM_VALIDATION_KEY.TEXT_IMAGE]: ['textImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: ['description'],

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: ['uploadedImages'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: ['profileImage'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],

  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButtonText'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_BACKGROUND_COLOR]: [
    'actionButtonBackgroundColor',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_FONT_COLOR]: [
    'actionButtonFontColor',
  ],

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: ['landingInfo'],

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const PROFILE_FULL_VIEW_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: (
    uploadedMultiImages,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { backgroundTab },
        },
      },
    } = getState()

    if (backgroundTab === ProfileFullViewBackgroundEnum.Type.VIDEO) {
      return IS_VALID()
    }

    const uploadedImages = uploadedMultiImages.toJS()

    const hasInvalidImage = Object.values(uploadedImages).some(images =>
      images.some(({ url }) => !url)
    )
    if (hasInvalidImage) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (!uploadedMultiImages || uploadedMultiImages?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },
  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_VIDEO_CREATE]: (
    uploadedVideo,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { backgroundTab },
        },
      },
    } = getState()
    const { url: videoUrl } = uploadedVideo || {}

    if (backgroundTab === ProfileFullViewBackgroundEnum.Type.IMAGE) {
      return IS_VALID()
    }

    if (checkEmpty(videoUrl)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return validateCreativePromotionalVideo(uploadedVideo)
  },
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: (
    image,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { backgroundTab },
        },
      },
    } = getState()
    const imageMap = Map.isMap(image) ? image : fromJS(image)
    const { url: imageUrl } = imageMap || {}

    if (backgroundTab === ProfileFullViewBackgroundEnum.Type.VIDEO) {
      return IS_VALID()
    }

    if (!imageUrl) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },
  [CREATIVE_FORM_VALIDATION_KEY.BACKGROUND_VIDEO_MODIFY]: (
    video,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { backgroundTab },
        },
      },
    } = getState()
    const { url: videoUrl } = video || {}

    if (backgroundTab === ProfileFullViewBackgroundEnum.Type.IMAGE) {
      return IS_VALID()
    }

    if (!videoUrl) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return validateCreativePromotionalVideo(video)
  },
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: (title, formData, getState) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          profileFullViewTab: { titleTab },
        },
      },
    } = getState()

    if (titleTab === ProfileFullViewTitleEnum.Type.IMAGE) {
      return IS_VALID()
    }

    if (checkEmpty(title)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return validateCreativeTitle(title, formData, null, campaignType)
  },
  [CREATIVE_FORM_VALIDATION_KEY.TEXT_IMAGE]: (
    textImage,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { titleTab },
        },
      },
    } = getState()

    const { url } = textImage || {}

    if (titleTab === ProfileFullViewTitleEnum.Type.TEXT) {
      return IS_VALID()
    }

    if (checkEmpty(url)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_WORD]: (
    description,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          profileFullViewTab: { titleTab },
        },
      },
    } = getState()

    if (titleTab === ProfileFullViewTitleEnum.Type.TEXT) {
      return IS_VALID()
    }

    return validateCreativePromotionalWord(description, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_CREATE]: uploadedImages => {
    if (
      !uploadedImages ||
      uploadedImages?.isEmpty() ||
      uploadedImages
        .keySeq()
        .every(k => !k.startsWith(CREATIVE_IMAGE_TYPE.PROFILE))
    ) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_IMAGE_MODIFY]: modifyImages => {
    if (!modifyImages || modifyImages?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (
    profileName,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeProfileName(profileName, formData, campaignType)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_BACKGROUND_COLOR]: (
    actionButtonBackgroundColor,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        nativeCreate: { images: nativeImages },
        promotionalVideo: { autoThumbnail, uploadThumbnail },
        modify: {
          creativeForm: { image },
        },
      },
    } = getState()

    const { id } = formData
    const isModify = id > 0
    const nativeImage = isModify ? image : nativeImages?.first()?.get(0)
    const targetImage =
      [nativeImage, uploadThumbnail, autoThumbnail].find(source => {
        const { url } = source || {}
        return Boolean(url)
      }) || {}

    const { url } = targetImage

    if (
      url && // 이미지 업로드 하지 않은 경우, validation 걸리지 않음
      !(
        (
          validateColor(actionButtonBackgroundColor) &&
          validateColorCodeValue(actionButtonBackgroundColor)
        ) // 코드값 규칙 체크
      )
    ) {
      return IS_NOT_VALID(
        '샵(#)과 함께 6자리의 올바른 색상코드를 입력하세요. (예:#000000)'
      )
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    if (hasFullWidthWhitespace(actionButton)) {
      return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
    }

    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_FONT_COLOR]:
    actionButtonFontColor => {
      if (checkEmpty(actionButtonFontColor)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: landingInfo => {
    return validateLandingInfo(landingInfo)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: name => {
    return validateCreativeName(name)
  },
})

export {
  PROFILE_FULL_VIEW_FORM_VALIDATION_KEY_PATH,
  PROFILE_FULL_VIEW_FORM_VALIDATOR,
}
