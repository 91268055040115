import React, { Fragment } from 'react'
import BizBoardBannerObjetImage from './BizBoardBannerObjetImage'
import useBizBoardBannerFormAvailable from '../useBizBoardBannerFormAvailable'
import PropTypes from 'prop-types'

const BizBoardBannerObjetImageGroup = ({
  adAccountId,
  formData,
  templateData,
  validationState,
  isModify,
  handleFormChange,
  handleTemplateChange,
}) => {
  const { isAvailableObjetImage2 } = useBizBoardBannerFormAvailable({
    isModify,
    templateData,
  })

  return (
    <Fragment>
      <BizBoardBannerObjetImage
        adAccountId={adAccountId}
        index={0}
        formData={formData}
        templateData={templateData}
        validationState={validationState}
        handleFormChange={handleFormChange}
        handleTemplateChange={handleTemplateChange}
      />
      {isAvailableObjetImage2 && (
        <BizBoardBannerObjetImage
          adAccountId={adAccountId}
          index={1}
          formData={formData}
          templateData={templateData}
          validationState={validationState}
          handleFormChange={handleFormChange}
          handleTemplateChange={handleTemplateChange}
        />
      )}
    </Fragment>
  )
}

BizBoardBannerObjetImageGroup.propTypes = {
  adAccountId: PropTypes.any,
  formData: PropTypes.object,
  templateData: PropTypes.object,
  validationState: PropTypes.object,
  isModify: PropTypes.bool,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerObjetImageGroup
