import { fetchPost } from '../../utils/fetchUtils'

const BASE_URL = '/bizboard-editor/express'
// const BASE_URL = 'http://localhost:3007'

export const BizBoardEditorAPI = {
  /**
   * 완성된 배너 이미지
   * @param requestBody { BizBoardFormUtil.toBannerAPI }
   * @param cancelTokenSource {axios.CancelTokenSource}
   * @param preventDuplicateRequest {boolean}
   */
  getBizBoardBannerImageBase64({
    requestBody,
    cancelTokenSource,
    preventDuplicateRequest = false,
  }) {
    return fetchPost(
      `${BASE_URL}/banner`,
      requestBody,
      undefined,
      cancelTokenSource,
      preventDuplicateRequest
    )
  },
  /**
   * 오브제 이미지가 없고 마스킹된 배너 이미지
   * @param requestBody {BizBoardFormUtil.toBannerAPI}
   * @param cancelTokenSource {axios.CancelTokenSource}
   * @param preventDuplicateRequest {boolean}
   */
  getMaskedBizBoardBannerImageBase64({
    requestBody,
    cancelTokenSource,
    preventDuplicateRequest = false,
  }) {
    return fetchPost(
      `${BASE_URL}/pre`,
      requestBody,
      undefined,
      cancelTokenSource,
      preventDuplicateRequest
    )
  },
}

// for redux thunk `api`
export default {
  ...BizBoardEditorAPI,
}
