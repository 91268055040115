import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import cx from 'classnames'
import { Radio, RadioGroup } from './Common/RadioGroup/RadioGroup'
import { logout } from '../utils/authenticationUtils'
import { getSelectableApiServerKeys } from '../utils/app/api'
import { getApiPhase } from '../utils/app/services/kakaoMoment'
import Gnb from './Navigation/Gnb/Gnb'
import Footer from './Footer/Footer'
import {
  GLOBAL_STORAGE_ITEM,
  GlobalStorageLocal,
} from '../utils/storage/storageFactoryImpl'

const Api = ({ history }) => {
  const [apiPhase, setApiPhase] = React.useState(getApiPhase())

  return (
    <div id="kakaoWrap" className="layout_fix">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <article id="mArticle">
          <style
            dangerouslySetInnerHTML={{
              __html:
                '\n\t.guide{padding:30px;background-color:#fff;margin:30px 0}\n\t.guide .tit{display:block;padding-bottom:5px;margin-bottom:30px;border-bottom:1px solid #e6e6e6;font-size:26px;}\n',
            }}
          />
          <div className="guide">
            <strong className="tit">사이트 정보</strong>
            <div>
              <h3>{`Version - ${__VERSION__}`}</h3>
            </div>
          </div>
          <div className="guide">
            <strong className="tit">API 서버 선택</strong>
            <div>
              <RadioGroup
                name="inpArea"
                selectedValue={apiPhase}
                onChange={setApiPhase}
                className="filter_radio"
                Component="div">
                {getSelectableApiServerKeys().map((v, i) => {
                  const id = `inpArea${i}`
                  return (
                    <Radio
                      key={id}
                      id={id}
                      className={cx('box_radioinp', {
                        on: apiPhase === v,
                      })}
                      label={String(v).toUpperCase()}
                      value={v}
                    />
                  )
                })}
              </RadioGroup>
              <br />
              {__LOCAL__ ||
                (__DEV__ && (
                  <span>
                    *Local 선택시 /etc/hosts에 test.kakao.com을 추가하시면 local
                    API Gateway에 접속됩니다
                  </span>
                ))}
            </div>
          </div>
          <div className="page_btn">
            <div className="inner_btn">
              <button
                type="button"
                className="btn_gb"
                onClick={() => {
                  GlobalStorageLocal.delete(GLOBAL_STORAGE_ITEM.API_PHASE)

                  logout()
                }}>
                <span className="inner_g">리셋</span>
              </button>
              <button
                type="button"
                className="btn_gb"
                onClick={() => history.replace('/')}>
                <span className="inner_g">취소</span>
              </button>
              <button
                type="button"
                className="btn_gb gb_bl"
                onClick={() => {
                  GlobalStorageLocal.set(
                    GLOBAL_STORAGE_ITEM.API_PHASE,
                    apiPhase
                  )

                  logout()
                }}>
                <span className="inner_g">확인</span>
              </button>
            </div>
          </div>
        </article>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

Api.propTypes = {
  history: ReactRouterPropTypes.history,
}

export default Api
