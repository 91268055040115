import { getApiPhase } from './kakaoMoment'

const KakaoLoungeBoard = {
  Host: {
    production: 'https://lounge-board.kakao.com',
    stage: 'https://lounge-board.kakao.com',
    sandbox: 'https://lounge-board.devel.kakao.com',
  },
  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },

  getPhase() {
    return getApiPhase() === 'production' || getApiPhase() === 'stage'
      ? 'prod'
      : 'sandbox'
  },
}

export { KakaoLoungeBoard }
