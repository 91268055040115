import {
  BILLING,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  queryString,
} from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'

export const CardAPI = {
  getDspAutoPaymentCardList() {
    return fetchGet(`${BILLING}/v1/my/autopay/card?dspId=KAKAOMOMENT`)
  },

  getDspAutoPaymentCardTransactionHistory(searchOptions) {
    return fetchGet(
      `${BILLING}/v1/my/autopay/card/autopayCardPayHistory?dspId=KAKAOMOMENT&${queryString(
        searchOptions
      )}`
    )
  },

  /**
   * dsp 정기 결제카드 등록 iframe (response.data.paymentUrl)
   */
  getDspAutoPaymentCardRegistrationUrl(requestBody) {
    return fetchPost(
      `${BILLING}/v1/my/autopay/card?${queryString(requestBody)}`,
      requestBody
    )
  },

  /**
   * dsp 정기 결제카드 삭제
   */
  deleteDspAutoPaymentCard(requestBody) {
    return RequestLock.acquire({
      key: 'deleteDspAutoPaymentCard',
      executor: done =>
        fetchDelete(
          `${BILLING}/v1/my/autopay/card?adPlatformType=KAKAOMOMENT`,
          requestBody
        ).finally(done),
    })
  },

  /**
   * 광고계정에서 사용 중인 결제카드 리스트
   */
  getPaymentCardListInUse(adAccountId) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card?dspId=KAKAOMOMENT`,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 카드 추가 > 카카오페이 카드선택
   */
  getKakaoPayCardList(adAccountId) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/getKakaoPayCards?dspId=KAKAOMOMENT`,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 카드 추가
   */
  addPaymentCard(adAccountId, requestBody) {
    return fetchPost(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card?dspId=KAKAOMOMENT`,
      requestBody,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 카드 삭제
   */
  deletePaymentCard(adAccountId, requestBody) {
    return fetchDelete(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card`,
      requestBody,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 일 지출 한도 설정
   */
  modifyPaymentCardDailySpendingBudget(adAccountId, amount) {
    return fetchPut(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/setAutopayDailySpendingBudget?${queryString(
        {
          amount,
          dspId: 'KAKAOMOMENT',
        }
      )}`,
      { dailyBudget: amount, dspId: 'KAKAOMOMENT' },
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 일 지출 한도 조회
   */
  getPaymentCardDailySpendingBudget(adAccountId) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/getAutopayDailySpendingBudget`,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 결제카드로 변경(priorityOrder = 0)
   */
  changePaymentCardPriorityToTop(adAccountId, requestBody) {
    return fetchPut(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/setPriorityOrderToZero`,
      requestBody,
      adAccountId
    )
  },

  /**
   * 카드 사용가능금액 조회
   */
  getPaymentCardAvailableAmount(adAccountId) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/getAvailableAmount`,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 거래 내역
   */
  getPaymentCardTransactionHistory(adAccountId, searchOptions) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/payHistory?${queryString(
        searchOptions
      )}`,
      adAccountId
    )
  },

  /**
   * 결제카드 관리 > 변경이력
   */
  getPaymentCardModifyingHistory(adAccountId, searchOptions) {
    return fetchGet(
      `${BILLING}/v1/wallet/${adAccountId}/autopay/card/changeHistory?${queryString(
        searchOptions
      )}`,
      adAccountId
    )
  },
}

export default {
  ...CardAPI,
}
