import { keyMirror } from '../../../utils/utils'
import { range } from 'lodash'

const ReportTargeting = {}

/**
 * Targeting > TIME_ZONE
 */
const times = range(24).map(time => time)

ReportTargeting.TimeZone = {
  Type: keyMirror({ ...times }),

  Text: {
    0: '00:00~00:59',
    1: '01:00~01:59',
    2: '02:00~02:59',
    3: '03:00~03:59',
    4: '04:00~04:59',
    5: '05:00~05:59',
    6: '06:00~06:59',
    7: '07:00~07:59',
    8: '08:00~08:59',
    9: '09:00~09:59',
    10: '10:00~10:59',
    11: '11:00~11:59',
    12: '12:00~12:59',
    13: '13:00~13:59',
    14: '14:00~14:59',
    15: '15:00~15:59',
    16: '16:00~16:59',
    17: '17:00~17:59',
    18: '18:00~18:59',
    19: '19:00~19:59',
    20: '20:00~20:59',
    21: '21:00~21:59',
    22: '22:00~22:59',
    23: '23:00~23:59',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

/**
 * Targeting > DEVICE
 */
ReportTargeting.Device = {
  Type: keyMirror({
    PC: null,
    Android: null,
    iOS: null,
    'N/A': null,
  }),

  Text: {
    PC: 'PC',
    Android: 'Android',
    iOS: 'iOS',
    'N/A': '기타',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

ReportTargeting.Age = {
  Type: keyMirror({
    15: null, // 15-19
    20: null, // 20-24
    25: null, // 25-29
    30: null, // 30-34
    35: null, // 35-39
    40: null, // 40-44
    45: null, // 45-49
    50: null, // 50-54
    55: null, // 55-59
    60: null, // 60-64
    65: null, // 65-69
    70: null, // 70-
    0: null, // 알 수 없음
  }),

  Text: {
    15: '15-19',
    20: '20-24',
    25: '25-29',
    30: '30-34',
    35: '35-39',
    40: '40-44',
    45: '45-49',
    50: '50-54',
    55: '55-59',
    60: '60-64',
    65: '65-69',
    70: '70이상',
    0: '알 수 없음',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

ReportTargeting.Gender = {
  Type: keyMirror({
    M: null,
    F: null,
    N: null,
  }),

  Text: {
    M: '남성',
    F: '여성',
    N: '알 수 없음',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

export { ReportTargeting }
