import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { MomentLoader } from '../../../Common/Loader/MomentLoader'
import { shallowEqual, useSelector } from 'react-redux'
import { dateTimeToString, queryParams } from '../../../../utils/utils'
import { isAdGroupModifyStatus } from '../../../../utils/advertise/campaignAdGroup'
import cn from 'classnames'
import { NumberUtils } from '../../../../utils/numberUtils'
import { PIXEL_AND_SDK_LINKAGE_STATUS } from '../../../Linkage/PixelAndSdk/Utils/pixelAndSdk'
import AdGroupOptimizationStatusEnum from '../../../../enums/AdGroupOptimizationStatusEnum'
import { checkEmpty } from '../../../../utils/regexUtils'
import populationApi from '../../../../modules-api/targeting/populationApi'
import {
  createCancellation,
  deleteCancellation,
} from '../../../../utils/cancellation/cancellation'
import { AD_GROUP_OPTIMIZATION_STATUS_GUIDE } from '../../../../utils/app/inhouse'
import DynamicTooltip from '../../../Tooltip/DynamicTooltip'
import { Tooltip } from '../../../../resources/locale'
import { useIsMounted } from '../../../../utils/hook/useIsMounted'
import ObjectiveTypeEnum from '../../../../enums/ObjectiveTypeEnum'

export const defaultAdGroupOptimizationStatusInfo = {
  adGroupId: -1,
  optimizationStatus: AdGroupOptimizationStatusEnum.Type.INVALID,
  trackerSeed: 0,
  adGroupSeed: 0,
  updateDate: '',
}

export const AD_GROUP_OPTIMIZATION_STATUS_CLASS_NAME_MAP = {
  READY: 'aside_ready',
  LEVEL_1: 'aside_sept1',
  LEVEL_2: 'aside_sept2',
  LEVEL_3: 'aside_sept3',
  LEVEL_4: 'aside_sept4',
}

const Guide = () => {
  return (
    <>
      보다 자세한 내용은&nbsp;
      <a
        href={AD_GROUP_OPTIMIZATION_STATUS_GUIDE}
        rel="noopener noreferrer"
        target="_blank"
        className="link_txt">
        운영가이드
      </a>
      를 확인하세요.
    </>
  )
}

const getOptimizationStatusDescription = ({
  optimizationStatus,
  adGroupSeed,
  trackerSeed,
  updateDate,
}) => {
  switch (optimizationStatus) {
    case AdGroupOptimizationStatusEnum.Type.READY: {
      return ''
    }
    case AdGroupOptimizationStatusEnum.Type.LEVEL_1: {
      return (
        <>
          최적화를 위해 학습된 이벤트 모수가 1,000명 미만(
          <em className="fc_emph2">{trackerSeed}</em>
          )입니다.
          <br />
          다음 단계를 위해서 모수 수집이 더 필요합니다.
          <br />({dateTimeToString(updateDate)} 기준)
          <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트
          모수와 최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
        </>
      )
    }
    case AdGroupOptimizationStatusEnum.Type.LEVEL_2: {
      return (
        <>
          최적화를 위해 학습된 이벤트 모수가 1,000명 이상(
          <em className="fc_emph2">{NumberUtils.withCommas(trackerSeed)}</em>
          )이고 광고그룹 전환 수가 50건 미만(
          <em className="fc_emph2">{adGroupSeed}</em>
          )입니다. 다음 단계를 위해서 전환수가 더 필요합니다.
          <br />({dateTimeToString(updateDate)} 기준)
          <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트
          모수와 최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
        </>
      )
    }
    case AdGroupOptimizationStatusEnum.Type.LEVEL_3: {
      return (
        <>
          최근 30일간 모수가 1,000명 이상(
          <em className="fc_emph2">{NumberUtils.withCommas(trackerSeed)}</em>
          )이고 광고그룹 전환 수가 500건 미만(
          <em className="fc_emph2">{adGroupSeed}</em>
          )입니다. 다음 단계를 위해서 전환수가 더 필요합니다.
          <br />({dateTimeToString(updateDate)} 기준)
          <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트
          모수와 최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
        </>
      )
    }
    case AdGroupOptimizationStatusEnum.Type.LEVEL_4: {
      return (
        <>
          최근 30일간 모수가 1,000명 이상(
          <em className="fc_emph2">{NumberUtils.withCommas(trackerSeed)}</em>
          )이고 광고그룹 전환 수가 500건 이상(
          <em className="fc_emph2">{NumberUtils.withCommas(adGroupSeed)}</em>
          )입니다.
          <br />({dateTimeToString(updateDate)} 기준)
          <br />※ 적합한 시드를 선별하는 과정에서 픽셀 & SDK로 수집된 이벤트
          모수와 최적화를 위해 학습된 이벤트 모수는 차이가 있을 수 있습니다.
        </>
      )
    }
    default: {
      return '최적화 상태를 조회할 수 없습니다.'
    }
  }
}

const LoadingView = () => {
  return (
    <div className="reform_box_state">
      <span className="reform_area_loading">
        <span className="group_loading loading_size2">
          <MomentLoader size={4} margin={2} />
        </span>
      </span>
    </div>
  )
}

const selector = ({
  campaignV2: {
    campaignForm: { objective },
    campaignViewState: { trackInfo },
  },
}) => {
  return {
    objective,
    trackInfo,
  }
}

const OptimizationStatusPopulation = () => {
  const location = useLocation()
  const isMounted = useIsMounted()
  const { adaccountid: adAccountId } = useParams()
  const { objective, trackInfo } = useSelector(selector, shallowEqual)
  const [isLoading, setLoading] = useState(false)
  const [isError, setError] = useState(false)
  const [optimizationStatusInfo, setOptimizationStatusInfo] = useState(
    defaultAdGroupOptimizationStatusInfo
  )

  const { trackStatus } = trackInfo || {}
  const { optimizationStatus: status } = optimizationStatusInfo
  const { adGroupId } = queryParams(location)
  const { type: objectiveType } = objective || {}
  const notSupportPopulation =
    !isAdGroupModifyStatus(location) ||
    checkEmpty(status) ||
    objectiveType !== ObjectiveTypeEnum.Type.PIXEL_AND_SDK

  const fetchOptimizationStatus = useCallback(async () => {
    if (!notSupportPopulation) {
      const cancelToken = createCancellation('fetchOptimizationStatus')

      try {
        if (isMounted.current) {
          setError(false)
          setLoading(true)
        }

        const response = await populationApi.fetchAdGroupOptimizationStatus(
          adAccountId,
          adGroupId,
          cancelToken
        )

        const [_status] = response.data
        if (isMounted.current) {
          setOptimizationStatusInfo(_status)
        }
      } catch (e) {
        console.log(e.message)
        if (isMounted.current) {
          setError(true)
        }
      } finally {
        deleteCancellation(cancelToken)
        if (isMounted.current) {
          setLoading(false)
        }
      }
    }
  }, [adAccountId, adGroupId, isMounted, notSupportPopulation])

  useEffect(() => {
    fetchOptimizationStatus()
  }, [fetchOptimizationStatus])

  const existOptimizationStatus = useMemo(
    () =>
      AdGroupOptimizationStatusEnum.valuesOfExistOptimizationStatus().includes(
        status
      ),
    [status]
  )

  if (notSupportPopulation) {
    return (
      <li>
        <p className="reform_desc_adgroup">
          해당 광고 유형, 목표에서는
          <br /> 예상 모수를 제공하지 않습니다.
        </p>
      </li>
    )
  }

  const isNotLinkageTrack = [
    PIXEL_AND_SDK_LINKAGE_STATUS.UNLINKED,
    PIXEL_AND_SDK_LINKAGE_STATUS.DELETED,
  ].includes(trackStatus)

  const statusClassName =
    status !== AdGroupOptimizationStatusEnum.Type.INVALID
      ? AD_GROUP_OPTIMIZATION_STATUS_CLASS_NAME_MAP[status]
      : ''

  const titleText = AdGroupOptimizationStatusEnum.getName(
    !isNotLinkageTrack ? status : AdGroupOptimizationStatusEnum.Type.INVALID
  )

  const guideText = getOptimizationStatusDescription(optimizationStatusInfo)

  return (
    <li>
      <em className="reform_subtit_aside">최적화 상태</em>
      <DynamicTooltip
        content={Tooltip(
          'create_ads.v2.ad_group.population.optimization_status'
        )}>
        <a className="link_g link_help">
          <span className="ico_comm ico_help">도움말</span>
        </a>
      </DynamicTooltip>
      {isLoading ? (
        <LoadingView />
      ) : isError ? (
        <div className="reform_box_state reform_nodata">
          조회 실패
          <a
            className="btn_refresh"
            onClick={() => {
              fetchOptimizationStatus()
            }}>
            <span className="ico_comm">새로고침</span>
          </a>
        </div>
      ) : isNotLinkageTrack ? (
        <div className="reform_box_state reform_nodata">{titleText}</div>
      ) : (
        <div className={cn('reform_box_state', { [statusClassName]: true })}>
          <strong className="tit_state">{titleText}</strong>
          {existOptimizationStatus && (
            <p className="desc_state">
              {guideText}
              <br />
              <Guide />
            </p>
          )}
        </div>
      )}
    </li>
  )
}

export default OptimizationStatusPopulation
