import { keyMirror } from '../utils/utils'

const CommonActionType = keyMirror(
  {
    SET_PREFERENCE: null,
    SET_BY_URL_SEARCH_PARAMS: null,
  },
  'COMMON_ACTION_TYPE'
)

export { CommonActionType }
