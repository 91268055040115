const DETECTABLE_STYLE_VALUES = [
  {
    style: 'overflow',
    value: 'hidden',
  },
  {
    style: 'text-overflow',
    value: 'ellipsis',
  },
]

const isEllipsisDetectable = el =>
  DETECTABLE_STYLE_VALUES.some(
    ({ style, value }) => window.getComputedStyle(el)[style] === value
  )

const getElementWidth = el =>
  el.getBoundingClientRect().right - el.getBoundingClientRect().left

/**
 * target 에 대한 overflow 여부를 검출한다.
 * - `el.offsetWidth < el.scrollWidth` 연산 만으로는 float 수준의 비교가 불가능하다.
 */
const isEllipsis = el => {
  if (!el || !isEllipsisDetectable(el)) return false

  // target 에 대해, 현 구조(ellipsis)에서의 가시화된 width 구하기.
  const visibleWidth = getElementWidth(el)

  // 원형 style 보관
  const originStyles = {
    overflow: el.style.overflow,
    width: el.style.width,
    display: el.style.display,
  }

  // target 내 모든 컨텐츠가 표현되도록 스타일 변경
  el.style.overflow = 'visible'
  el.style.width = 'auto'
  el.style.display = 'inline-block'

  // target 에 대한 full width 구하기
  const naturalWidth = getElementWidth(el)

  // target 의 스타일 복원
  el.style.overflow = originStyles.overflow
  el.style.width = originStyles.width
  el.style.display = originStyles.display

  // overflow 여부 검출
  return visibleWidth >= 0 && naturalWidth >= 0
    ? visibleWidth < naturalWidth
    : el.offsetWidth < el.scrollWidth
}

export default isEllipsis
