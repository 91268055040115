import { useIsMounted } from '../../../utils/hook/useIsMounted'
import React from 'react'
import isEllipsis from '../../../utils/isEllipsis'

const useDynamicTooltip = () => {
  const isMounted = useIsMounted()
  const showTimerId = React.useRef(-1)
  const dismissTimerId = React.useRef(-1)
  const isFocus = React.useRef(false)
  const isOverflow = React.useRef(false)

  const [isVisible, setIsVisible] = React.useState(false)

  React.useEffect(() => {
    return () => {
      clearTimeout(showTimerId.current)
      clearTimeout(dismissTimerId.current)
    }
  }, [])

  const onMouseEnter = React.useCallback(e => {
    isFocus.current = true

    isOverflow.current = isEllipsis(e.target)

    clearTimeout(dismissTimerId.current)

    setIsVisible(true)
  }, [])

  const onMouseLeave = React.useCallback(() => {
    isFocus.current = false
    isOverflow.current = false

    dismissTimerId.current = setTimeout(() => {
      if (!isFocus.current && isMounted.current) {
        setIsVisible(false)
      }
    }, 200)
  }, [isMounted])

  const show = React.useCallback(
    ({ showDelay = 0, dismissTimeout = 5 * 1000 }) => {
      showTimerId.current = setTimeout(() => {
        setIsVisible(true)

        dismissTimerId.current = setTimeout(() => {
          if (isMounted.current) {
            setIsVisible(false)
          }
        }, dismissTimeout)
      }, showDelay)
    },
    [isMounted]
  )

  return {
    isMounted,
    isVisible,
    setIsVisible,
    onMouseEnter,
    onMouseLeave,
    show,
    isOverflow: isOverflow.current, // isVisible state 변경이 되는 시점의 isOverflow 상태를 리턴한다.
  }
}

export default useDynamicTooltip
