import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  dateToDefaultString,
  fileDownloadBlob,
  keyMirror,
} from '../../utils/utils'
import moment from 'moment'
import { coerceToArray } from '../../utils/stringUtils'
import CalendarEnum from '../../enums/CalendarEnum'
import { Sort } from '../../enums/CommonEnums'
import Calendar from '../../utils/calendar'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'

const EditLog = keyMirror({
  GET_EDIT_LOG_LIST: null,
  INIT_EDIT_LOG_LIST: null,
  GET_EDIT_LOG_DETAIL_NAME: null,
  GET_EDIT_LOG_DETAIL_INFO: null,
  CHANGE_EDIT_LOG_VIEW_STATE_BY_KEY: null,
  INIT_EDIT_LOG_BY_KEY: null,
  INIT_SEARCH_VIEW_STATE: null,
  CHANGE_EDIT_LOG_CALENDAR: null,
})

export const SEARCH_TYPE = keyMirror({
  DSP_ACCOUNT: null,
  NAME: null,
})

const initialState = fromJS({
  editLogList: {
    content: [],
    totalElements: 0,
  },
  editLogDetailName: {
    adAccountName: '',
    campaignName: '',
    adGroupName: '',
    creativeName: '',
  },
  editLogDetailInfo: [
    {
      editLogType: '',
      beforeValue: '',
      afterValue: '',
    },
  ],

  calendar: {
    startDate: moment().subtract(6, 'days'),
    endDate: moment(),
    periodType: CalendarEnum.Preset.Type.LAST7DAYS,
    customDateDiff: null,
  },

  // 상단 검색 영역
  searchViewState: {
    searchType: SEARCH_TYPE.DSP_ACCOUNT,
    searchKeyword: '',
    searchTarget: '',
    page: 0,
    size: 10,
    sort: Sort.DESC,
  },
})

export default createReducer(initialState, {
  [EditLog.GET_EDIT_LOG_LIST]: (state, { data }) =>
    state.set('editLogList', fromJS(data || {})),

  [EditLog.GET_EDIT_LOG_DETAIL_NAME]: (state, { data }) =>
    state.set('editLogDetailName', fromJS(data || {})),

  [EditLog.GET_EDIT_LOG_DETAIL_INFO]: (state, { data }) =>
    state.set('editLogDetailInfo', fromJS(data || [])),

  [EditLog.CHANGE_EDIT_LOG_VIEW_STATE_BY_KEY]: (state, { key, value }) =>
    state.setIn(['searchViewState', ...coerceToArray(key)], fromJS(value)),

  [EditLog.CHANGE_EDIT_LOG_CALENDAR]: (
    state,
    { startDate, endDate, periodType, updateCustomDateDiff = false }
  ) =>
    state.withMutations(s =>
      s
        .setIn(['calendar', 'startDate'], startDate)
        .setIn(['calendar', 'endDate'], endDate)
        .setIn(['calendar', 'periodType'], periodType)
        .updateIn(['calendar', 'customDateDiff'], v =>
          updateCustomDateDiff && periodType === CalendarEnum.Preset.Type.CUSTOM
            ? Math.abs(moment(startDate).diff(moment(endDate), 'd'))
            : v
        )
    ),

  [EditLog.INIT_EDIT_LOG_LIST]: state =>
    state.set('editLogList', initialState.get('editLogList')),

  [EditLog.INIT_SEARCH_VIEW_STATE]: state =>
    state
      .set('searchViewState', initialState.get('searchViewState'))
      .set('calendar', initialState.get('calendar')),

  [EditLog.INIT_EDIT_LOG_BY_KEY]: state =>
    state
      .set('editLogDetailName', initialState.get('editLogDetailName'))
      .set('editLogDetailInfo', initialState.get('editLogDetailInfo')),
})

export function changeEditLogViewStateCalendar(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff
) {
  return {
    type: EditLog.CHANGE_EDIT_LOG_CALENDAR,
    startDate,
    endDate,
    periodType,
    updateCustomDateDiff,
  }
}

export function changeEditLogCalendar(
  startDate,
  endDate,
  periodType,
  updateCustomDateDiff = false
) {
  return (dispatch, getState) => {
    dispatch(
      changeEditLogViewStateCalendar(
        startDate,
        endDate,
        periodType,
        updateCustomDateDiff
      )
    )
    dispatch(changeEditLogViewState('page', 0))

    const {
      adAccountListSelector: {
        selectedAdAccount: { adAccountId },
      },
    } = getState()

    dispatch(fetchEditLogList(adAccountId))
  }
}

export function goPrevCalendar() {
  return (dispatch, getState) => {
    const {
      adAccount: {
        adAccountInfo: { createdDate },
      },
      editLog: {
        calendar: { startDate, endDate, periodType, customDateDiff },
      },
    } = getState()

    const { start, end } = Calendar.getPrevCalendarDate(
      createdDate,
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(changeEditLogCalendar(start, end, periodType))
  }
}

export function goNextCalendar() {
  return (dispatch, getState) => {
    const {
      editLog: {
        calendar: { startDate, endDate, periodType, customDateDiff },
      },
    } = getState()

    const { start, end } = Calendar.getNextCalendarDate(
      startDate,
      endDate,
      periodType,
      customDateDiff
    )

    dispatch(changeEditLogCalendar(start, end, periodType))
  }
}

function receiveEditLogList(data) {
  return { type: EditLog.GET_EDIT_LOG_LIST, data }
}

export function fetchEditLogList(adAccountId) {
  return (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.COMMON_BOARD))
    dispatch(initEditLogList())

    const {
      editLog: {
        calendar: { startDate, endDate },
        searchViewState: {
          searchType,
          searchKeyword,
          searchTarget,
          page,
          size,
          sort,
        },
      },
    } = getState()

    const searchOptions = {
      searchType,
      searchKeyword: encodeURIComponent(searchKeyword),
      searchTarget,
      startDate: moment(startDate).isValid()
        ? dateToDefaultString(startDate)
        : undefined,
      endDate: moment(endDate).isValid()
        ? dateToDefaultString(endDate)
        : undefined,
      page,
      size,
      sort: `createdDate,${sort.toLowerCase()}`,
    }

    return api.editLog
      .fetchEditLogList(adAccountId, searchOptions)
      .then(response => {
        dispatch(receiveEditLogList(response.data))
      })
      .catch(e => console.log(e.message))
      .finally(() => {
        dispatch(hideLoading(LOADING_KEY.COMMON_BOARD))
      })
  }
}

export function fetchDownLoadEditLog(adAccountId, onFinish) {
  return (dispatch, getState, api) => {
    const {
      editLog: {
        calendar: { startDate, endDate },
        searchViewState: { searchType, searchKeyword, searchTarget },
      },
    } = getState()

    const searchOptions = {
      searchType,
      searchKeyword,
      searchTarget,
      startDate: dateToDefaultString(startDate),
      endDate: dateToDefaultString(endDate),
    }

    return api.editLog
      .fetchDownloadEditLogList(adAccountId, searchOptions)
      .then(response => {
        fileDownloadBlob(
          `\uFEFF${fromJS(response.data)}`,
          `변경이력_${moment(startDate).format('YYYYMMDD')}_${moment(
            endDate
          ).format('YYYYMMDD')}.csv`
        )
      })
      .catch(e => console.log(e.message))
      .finally(() => {
        onFinish && onFinish()
      })
  }
}

function receiveEditLogDetailName(editLogDetailName) {
  return {
    type: EditLog.GET_EDIT_LOG_DETAIL_NAME,
    data: editLogDetailName,
  }
}

/**
 * 팝업 : 9.3 광고계정별 변경이력 상세 팝업 타겟 이름
 * @param adAccountId {string}
 * @param editLogId {string}
 * @param searchTarget {string}
 */
export function getEditLogDetailName(adAccountId, editLogId, searchTarget) {
  return (dispatch, getState, api) => {
    return api.editLog
      .getEditLogDetailName(adAccountId, editLogId, searchTarget)
      .then(response => {
        dispatch(receiveEditLogDetailName(response.data))
      })
      .catch(e => e.message)
  }
}

function receiveEditLogDetailInfo(editLogDetailInfo) {
  return {
    type: EditLog.GET_EDIT_LOG_DETAIL_INFO,
    data: editLogDetailInfo,
  }
}

/**
 * 팝업 : 9.4 광고계정별 변경이력 상세 리스트
 * @param adAccountId
 * @param editLogId
 * @param searchTarget
 */
export function getEditLogDetailInfo(adAccountId, editLogId, searchTarget) {
  return (dispatch, getState, api) => {
    return api.editLog
      .getEditLogDetailInfo(adAccountId, editLogId, searchTarget)
      .then(response => {
        dispatch(receiveEditLogDetailInfo(response.data))
      })
      .catch(e => e.message)
  }
}

export const fetchAudienceMappedAdGroups = (
  adAccountId,
  editLogId,
  onSuccess = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const {
        data: { mappedAdGroups },
      } = await api.editLog.fetchAudienceMappedAdGroups(adAccountId, editLogId)

      onSuccess(fromJS(mappedAdGroups))
    } catch (e) {
      console.log(e.message)
    }
  }
}

export function changeEditLogViewState(key, value) {
  return {
    type: EditLog.CHANGE_EDIT_LOG_VIEW_STATE_BY_KEY,
    key,
    value,
  }
}

export function changeEditLogViewStateAndGetList(key, value) {
  return (dispatch, getState) => {
    dispatch(changeEditLogViewState(key, value))

    const {
      adAccountListSelector: {
        selectedAdAccount: { adAccountId },
      },
    } = getState()

    dispatch(fetchEditLogList(adAccountId))
  }
}

export function initEditLogByKey() {
  return { type: EditLog.INIT_EDIT_LOG_BY_KEY }
}

export function initEditLogList() {
  return { type: EditLog.INIT_EDIT_LOG_LIST }
}

export function initSearchViewState() {
  return { type: EditLog.INIT_SEARCH_VIEW_STATE }
}
