import React from 'react'
import cx from 'classnames'
import * as PropTypes from 'prop-types'

const PopupButtonGroup = ({
  onOK = () => undefined,
  onCancel = () => undefined,
  onBack = () => undefined,
  okButtonLabel = '저장',
  cancelButtonLabel = '취소',
  backButtonLabel = '이전',
  hasOK = true,
  hasCancel = true,
  hasBack = true,
  isEnabledOK = true,
  isEnabledCancel = true,
  isEnabledBack = true,
}) => {
  return (
    <div className="btn_group">
      {hasBack && (
        <button
          className={cx('btn_gm', { in_active: !isEnabledBack })}
          onClick={onBack}>
          <span className="inner_g">{backButtonLabel}</span>
        </button>
      )}
      {hasCancel && (
        <button
          className={cx('btn_gm', {
            in_active: !isEnabledCancel,
            gm_line: hasBack && hasOK,
          })}
          onClick={onCancel}>
          <span className="inner_g">{cancelButtonLabel}</span>
        </button>
      )}
      {hasOK && (
        <button
          type="button"
          className={cx('btn_gm gm_bl', { in_active: !isEnabledOK })}
          onClick={onOK}>
          <span className="inner_g">{okButtonLabel}</span>
        </button>
      )}
    </div>
  )
}

PopupButtonGroup.propTypes = {
  okButtonLabel: PropTypes.string,
  cancelButtonLabel: PropTypes.string,
  backButtonLabel: PropTypes.string,
  onOK: PropTypes.func,
  onCancel: PropTypes.func,
  onBack: PropTypes.func,
  hasOK: PropTypes.bool,
  hasCancel: PropTypes.bool,
  hasBack: PropTypes.bool,
  isEnabledOK: PropTypes.bool,
  isEnabledCancel: PropTypes.bool,
  isEnabledBack: PropTypes.bool,
}

export default PopupButtonGroup
