import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import axios from 'axios'
import {
  handlePixelAndSdkException,
  PIXEL_AND_SDK_PAGE_TAB,
  PIXEL_AND_SDK_ROLE_KEY,
} from '../../../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import { hideLoading, showLoading } from '../../common/mLoading'
import {
  createCancellation,
  deleteCancellation,
} from '../../../utils/cancellation/cancellation'
import { showSuccessMessage } from '../../../utils/alertUtils'
import { coerceToArray } from '../../../utils/stringUtils'

const PixelAndSdkManagement = keyMirror(
  {
    SET_TAB: null,
    CLEAR: null,

    SET_LIST_BY_KEY: null,
    CLEAR_LIST_BY_KEY: null,
  },
  'PIXEL_AND_SDK_MANAGEMENT'
)

const initialState = fromJS({
  selectedTab: PIXEL_AND_SDK_PAGE_TAB.LINKAGE_LIST,

  linkagePixel: {
    pageIndex: 0,
    totalElements: 0,
    content: [],
  },

  availableLinkagePixel: {
    pageIndex: 0,
    totalElements: 0,
    content: [],
  },

  memberRequest: {
    totalElements: 0,
    totalPages: 0,
    content: [],
  },
})

export default createReducer(initialState, {
  [PixelAndSdkManagement.SET_TAB]: (state, { tab }) =>
    state.set('selectedTab', tab),

  [PixelAndSdkManagement.SET_LIST_BY_KEY]: (state, { keyPath, value }) =>
    state.setIn([...coerceToArray(keyPath)], fromJS(value)),

  [PixelAndSdkManagement.CLEAR_LIST_BY_KEY]: (state, { key }) =>
    state.set(key, initialState.get(key)),

  [PixelAndSdkManagement.CLEAR]: state => state.merge(initialState),
})

export const clearPixelAndSdkPage = () => ({
  type: PixelAndSdkManagement.CLEAR,
})

export const setPixelAndSdkPageTab = tab => ({
  type: PixelAndSdkManagement.SET_TAB,
  tab,
})

export const setPixelAndSdkManagementListByKey = (keyPath, value) => ({
  type: PixelAndSdkManagement.SET_LIST_BY_KEY,
  keyPath,
  value,
})

export const clearPixelAndSdkManagementListByKey = key => ({
  type: PixelAndSdkManagement.CLEAR_LIST_BY_KEY,
  key,
})

export const fetchLinkedPixelAndSdkList = ({
  adAccountId,
  pageIndex,
  onSuccess = () => undefined,
  onFail = () => undefined,
}) => {
  return async (dispatch, getState, api) => {
    const cancelTokenSource = createCancellation('fetchLinkedPixelAndSdkList')
    const loadingKey = 'fetchLinkedPixelAndSdkList'

    try {
      dispatch(showLoading(loadingKey))

      const response = await api.bconV2.fetchLinkedTrackList(
        adAccountId,
        {
          page: pageIndex,
          size: 10,
        },
        cancelTokenSource
      )

      const {
        body: { content, totalElements },
      } = response.data

      dispatch(
        setPixelAndSdkManagementListByKey('linkagePixel', {
          totalElements,
          content,
        })
      )

      onSuccess()
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(e.message)
        onFail()
      }
    } finally {
      deleteCancellation(cancelTokenSource)
      dispatch(hideLoading(loadingKey))
    }
  }
}

export const fetchAvailablePixelAndSdkList = ({
  adAccountId,
  pageIndex,
  onSuccess = () => undefined,
  onFail = () => undefined,
}) => {
  return async (dispatch, getState, api) => {
    const cancelTokenSource = createCancellation(
      'fetchAvailablePixelAndSdkList'
    )
    const loadingKey = 'fetchAvailablePixelAndSdkList'

    try {
      dispatch(showLoading(loadingKey))

      const response = await api.bconV2.fetchAvailableTrackList(
        adAccountId,
        {
          page: pageIndex,
          size: 10,
        },
        cancelTokenSource
      )

      const {
        body: { content, totalElements },
      } = response.data

      dispatch(
        setPixelAndSdkManagementListByKey('availableLinkagePixel', {
          totalElements,
          content,
          pageIndex,
        })
      )

      onSuccess()
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(e.message)
        onFail()
      }
    } finally {
      deleteCancellation(cancelTokenSource)
      dispatch(hideLoading(loadingKey))
    }
  }
}

export const requestAuthorityToTrack = (
  adAccountId,
  trackId,
  onSuccess = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.bconV2.requestAuthorityToTrack(adAccountId, trackId)

      onSuccess()
      showSuccessMessage('해당 광고계정과 픽셀 & SDK 연동이 완료되었습니다.')
    } catch (e) {
      console.log(e.message)
      handlePixelAndSdkException({ e })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const requestDeleteLinkedTrack = (
  adAccountId,
  trackId,
  onSuccess = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.bconV2.requestDeleteLinkedTrack(adAccountId, trackId)

      onSuccess()
      showSuccessMessage('해당 광고계정과 픽셀 & SDK 연동이 해제되었습니다.')
    } catch (e) {
      console.log(e.message)
      handlePixelAndSdkException({ e })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const createPixelAndSdk = (
  adAccountId,
  name,
  onSuccess = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      const response = await api.bconV2.createPixelAndSdk(adAccountId, { name })
      const { body } = response.data

      onSuccess(body)
    } catch (e) {
      console.log(e.message)
      handlePixelAndSdkException({ e })
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const fetchPixelKakaoServiceByKey = (
  adAccountId,
  trackId,
  serviceKey,
  onSuccess = () => undefined,
  onFail = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bconV2.fetchKakaoServicesByServiceKey(
        adAccountId,
        trackId,
        serviceKey
      )

      const { body } = response.data

      onSuccess(body)
    } catch (e) {
      console.log(e.message)
      onFail()
    }
  }
}

export const addKakaoServiceToTrack = (
  adAccountId,
  trackId,
  selectedContents,
  onSuccess = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.bconV2.addKakaoServiceToTrack(
        adAccountId,
        trackId,
        selectedContents
      )

      onSuccess()
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export const fetchAvailableMemberRequestList = (
  adAccountId,
  searchType,
  searchKey,
  pageIndex = 0
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading('fetchAvailableMemberRequestList'))

      const requestParams = {
        searchType,
        searchKey: encodeURIComponent(searchKey),
        page: pageIndex,
        size: 10,
      }
      const response = await api.bconV2.fetchAvailableMemberRequestList(
        adAccountId,
        requestParams
      )

      const { content, totalElements, totalPages } = response.data.body

      const {
        pixelV2: {
          main: {
            memberRequest: { content: prevContent },
          },
        },
      } = getState()

      dispatch(
        setPixelAndSdkManagementListByKey('memberRequest', {
          totalElements,
          content: prevContent.concat(fromJS(content)),
          totalPages,
        })
      )
    } catch (e) {
      console.log(e.message)
      dispatch(clearPixelAndSdkManagementListByKey('memberRequest'))
    } finally {
      dispatch(hideLoading('fetchAvailableMemberRequestList'))
    }
  }
}

export const requestMemberToTrack = (
  adAccountId,
  trackId,
  onSuccess = () => undefined,
  onFail = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      await api.bconV2.requestMemberToTrack(adAccountId, trackId)

      const {
        pixelV2: {
          main: {
            memberRequest: { content },
          },
        },
      } = getState()

      // 요청 중인 목록 item 실시간으로 표기 위해 update. list 양이 많을 경우 새로 로딩하는 시간이 오래걸려서 직접 업데이트 함.
      const index = content.findIndex(item => item.get('trackId') === trackId)
      const newContent = content.update(index, item =>
        item.set('role', PIXEL_AND_SDK_ROLE_KEY.REQUEST)
      )

      dispatch(
        setPixelAndSdkManagementListByKey('memberRequest', {
          totalElements: newContent.size,
          content: newContent,
        })
      )

      onSuccess()

      showSuccessMessage('멤버 요청이 완료되었습니다.')
    } catch (e) {
      console.log(e)
      onFail()
      handlePixelAndSdkException({ e })
    }
  }
}

export const cancelMemberRequest = (
  adAccountId,
  trackId,
  requestId,
  onSuccess = () => {}
) => {
  return async (dispatch, getState, api) => {
    try {
      dispatch(showLoading())

      await api.bconV2.cancelMemberRequest(adAccountId, trackId, requestId)

      dispatch(fetchAvailablePixelAndSdkList({ adAccountId }))
      dispatch(
        setPixelAndSdkManagementListByKey(
          ['availableLinkagePixel', 'pageIndex'],
          0
        )
      )

      showSuccessMessage('멤버 요청을 취소하였습니다.')
      onSuccess()
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading())
    }
  }
}
