import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { setCurrentRole } from './mRole'
import { getAdAccountInfoById } from '../adaccount/mAdAccount'
import { getDspAccountInviteListWhenLoginSuccess } from './mUser'
import {
  toInvalidApproachPath,
  toMomentInitialPath,
  toMyAdAccountListPath,
  toServiceUnavailablePath,
} from '../../utils/router/routeUtils'
import { isUndefinedOrNull } from '../../utils/regexUtils'
import {
  getStoredAdAccountInfo,
  setCurrentAdAccountInfo,
} from '../../utils/authenticationUtils'
import { getAdAccountConstraints } from '../advertise/mAdConstraints'
import { hideLoading, LOADING_KEY, showLoading } from './mLoading'
import axios from 'axios/index'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import { AdAccountHelper } from '../../utils/helper/helper-adAccount'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const AdAccountSelector = keyMirror(
  {
    SET_SELECT_ITEMS: null,
    UPDATE_SELECT_ITEM: null,

    SET_SELECTED: null,
    CLEAR_SELECTED: null,

    SET_CASH_INFO: null,

    SET_PAGE_INDEX: null,
  },
  'AD_ACCOUNT_LIST_SELECT'
)

const initialState = fromJS({
  selectedAdAccount: {
    adAccountId: -1,
    role: '',
    name: '',
    adAccountStatus: [],
    createdDate: null,
    adAccountType: '',
  },
  pageIndex: 0,
  adAccountSelectorItems: [],
  adAccountCashInfo: {},
})

export default createReducer(initialState, {
  [AdAccountSelector.SET_SELECTED]: (state, { adAccount }) =>
    state.set('selectedAdAccount', fromJS(adAccount || {})),

  [AdAccountSelector.SET_SELECT_ITEMS]: (state, { data }) =>
    state.set(
      'adAccountSelectorItems',
      fromJS(data || []).map(v =>
        v.set('normalizedName', v.get('name').toLocaleLowerCase().trim())
      )
    ),

  [AdAccountSelector.CLEAR_SELECTED]: state =>
    state.set('selectedAdAccount', initialState.get('selectedAdAccount')),

  [AdAccountSelector.SET_PAGE_INDEX]: (state, { index }) =>
    state.set('pageIndex', index),

  [AdAccountSelector.SET_CASH_INFO]: (state, { data }) =>
    state.set('adAccountCashInfo', fromJS(data || {})),

  /**
   * mDashboard > adAccountInfo 갱신 시 selector 의 list, selected adAccount 를 갱신.
   */
  [AdAccountSelector.UPDATE_SELECT_ITEM]: (state, { data }) => {
    const adAccountInfo = fromJS({
      adAccountId: data.id,
      name: data.name,
      adAccountType: data.adAccountType,
      createdDate: data.createdDate,
      adAccountStatus: data.status,
    })

    return state.withMutations(s =>
      s
        .update('adAccountSelectorItems', list => {
          const index = list.findIndex(
            v => v.get('adAccountId') === adAccountInfo.get('adAccountId')
          )
          return index !== -1
            ? list.update(index, v => v.merge(adAccountInfo))
            : list
        })
        .update('selectedAdAccount', v =>
          v.get('adAccountId') === adAccountInfo.get('adAccountId')
            ? v.merge(adAccountInfo)
            : v
        )
    )
  },
})

/**
 * current AdAccount 변경 시.
 * - AdAccountListSelectorItem (사용자가 변경할 때),
 * - DashboardWrapper (refresh 시에 처음 반영할 때)
 * - 이전 계정과 동일한 경우에도 갱신한다(role, name 갱신)
 * @param adAccount {Immutable.Map}
 */
export function setSelectedAdAccountInfo(adAccount) {
  return async dispatch => {
    const { adAccountId, role } = adAccount || {}

    dispatch({
      type: AdAccountSelector.SET_SELECTED,
      adAccount,
    })

    // update session storage
    setCurrentAdAccountInfo(adAccount)

    // update role
    dispatch(setCurrentRole(role))
    try {
      await axios.all([
        // 제약조건
        dispatch(getAdAccountConstraints(adAccountId)),
        // 광고계정 정보
        dispatch(getAdAccountInfoById(adAccountId)),
      ])
    } catch (e) {
      console.log(e.message)
    }

    // 캐시
    dispatch(fetchAdAccountCashInfoFromListSelector(adAccountId))
  }
}

function receiveAdAccountSelectorItems(data) {
  return {
    type: AdAccountSelector.SET_SELECT_ITEMS,
    data,
  }
}

export function clearSelectedAdAccount() {
  return { type: AdAccountSelector.CLEAR_SELECTED }
}

export function setPageIndex(index) {
  return { type: AdAccountSelector.SET_PAGE_INDEX, index }
}

// 내보내기, 탈퇴 등으로 인해 선택된 adAccount 정보 손실 시.
function fallbackForEmptyAccount(list) {
  return dispatch => {
    if (list?.length > 0) {
      dispatch(setSelectedAdAccountInfo(fromJS(list[0])))
    } else {
      dispatch(RouterV2.replace(toInvalidApproachPath()))
    }
  }
}

export function getAdAccountSelectorItems(
  adAccountId = undefined,
  redirectHome = false
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.LNB_AD_ACCOUNT_SELECT))

    try {
      const [adAccountResponse, adAccountListResponse] = await axios.all([
        adAccountId
          ? api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId)
          : {},
        api.adAccount.fetchAdAccounts(adAccountId),
      ])

      const { data: adAccountData } = adAccountResponse
      const adAccount = AdAccountHelper.ListSelector.toAdAccount(adAccountData)

      const {
        data: { content: adAccounts },
      } = adAccountListResponse
      const adAccountList = AdAccountHelper.ListSelector.toView(
        adAccounts || []
      )

      dispatch(receiveAdAccountSelectorItems(adAccountList))

      if (adAccountList.length === 0) {
        dispatch(clearSelectedAdAccount())
        dispatch(getDspAccountInviteListWhenLoginSuccess())
      } else {
        if (adAccountId) {
          if (isUndefinedOrNull(adAccount)) {
            dispatch(fallbackForEmptyAccount(adAccountList))
          } else {
            dispatch(setSelectedAdAccountInfo(fromJS(adAccount)))
          }
        } else {
          const storedAdAccount = getStoredAdAccountInfo()

          if (isUndefinedOrNull(storedAdAccount)) {
            dispatch(
              RouterV2.replace(
                toMomentInitialPath({
                  adAccountId: adAccountList[0].adAccountId,
                  redirectHome,
                })
              )
            )
          } else {
            if (isUndefinedOrNull(adAccount)) {
              dispatch(fallbackForEmptyAccount(adAccountList))
            } else {
              dispatch(
                RouterV2.replace(
                  toMomentInitialPath({
                    adAccountId: adAccount.adAccountId,
                    redirectHome,
                  })
                )
              )
            }
          }
        }
      }
    } catch (e) {
      console.log('get Ad Account Selector Items : ', e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.LNB_AD_ACCOUNT_SELECT))
    }
  }
}

export function getAdAccountSelectorRefresh(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const [adAccountResponse, adAccountListResponse] = await axios.all([
        adAccountId
          ? api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId)
          : {},
        api.adAccount.fetchAdAccounts(adAccountId),
      ])

      const { data: adAccountData } = adAccountResponse
      const adAccount = AdAccountHelper.ListSelector.toAdAccount(adAccountData)

      const {
        data: { content: adAccounts },
      } = adAccountListResponse
      const adAccountList = AdAccountHelper.ListSelector.toView(
        adAccounts || []
      )
      dispatch(receiveAdAccountSelectorItems(adAccountList))

      if (adAccountList.length > 0) {
        if (adAccountId) {
          if (isUndefinedOrNull(adAccount)) {
            dispatch(RouterV2.replace(toInvalidApproachPath()))
          } else {
            dispatch(setSelectedAdAccountInfo(fromJS(adAccount)))
          }
        }
      }
    } catch (e) {
      dispatch(RouterV2.push(toServiceUnavailablePath()))
    }
  }
}

/**
 * 대행사 점프 시.
 */
export function getAgencyAdAccountSelectorItems(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const [adAccountResponse, adAccountListResponse] = await axios.all([
        adAccountId
          ? api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId)
          : {},
        api.adAccount.fetchAdAccounts(adAccountId),
      ])

      const { data: adAccountData } = adAccountResponse
      const adAccount = AdAccountHelper.ListSelector.toAdAccount(adAccountData)

      const {
        data: { content: adAccounts },
      } = adAccountListResponse
      const adAccountList = AdAccountHelper.ListSelector.toView(
        adAccounts || []
      )
      dispatch(receiveAdAccountSelectorItems(adAccountList))

      if (adAccountList.length > 0) {
        // 해당 맴버일때
        if (!isUndefinedOrNull(adAccount)) {
          dispatch(setSelectedAdAccountInfo(fromJS(adAccount)))
          dispatch(
            RouterV2.replace(
              DashboardRouter.Path.AdAccount({
                adAccountId: adAccount.adAccountId,
              })
            )
          )
          return
        }
      }

      // 해당 멤버가 아닐때
      dispatch(RouterV2.push(toMyAdAccountListPath()))
    } catch (e) {
      console.log(e.message)
    }
  }
}

export function fetchAdAccountCashInfoFromListSelector(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.cash.fetchAdAccountCashInfo(adAccountId)

      dispatch({
        type: AdAccountSelector.SET_CASH_INFO,
        data: response.data,
      })
    } catch (e) {
      console.log('fetchAdAccountCashInfoFromListSelector :', e.message)
    }
  }
}

export function updateCurrentAdAccountSelectorItem(data) {
  return {
    type: AdAccountSelector.UPDATE_SELECT_ITEM,
    data,
  }
}
