import React from 'react'
import StaticPageBase from './StaticPageBase'

const NotSupportedBrowserPage = () => {
  return (
    <StaticPageBase
      titleValueOrFn="지원하지 않는 브라우저입니다."
      descriptionValueOrFn={
        <>
          현재 사용하는 인터넷 브라우저에서는 카카오모먼트를 이용할 수 없습니다.
          <br />
          카카오모먼트는 크롬(Chrome) 브라우저에 최적화되어 있습니다.
          <br />
          원활한 이용을 위하여 크롬(Chrome) 브라우저에서 접속하세요.
        </>
      }
      buttonGroupValueOrFn={
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.google.co.kr/chrome/browser/desktop/index.html"
          className="btn_gb gb_bl">
          <span className="inner_g">
            <span className="ico_chrome" />
            크롬 브라우저 다운로드
          </span>
        </a>
      }
    />
  )
}

export default NotSupportedBrowserPage
