import React from 'react'
import PropTypes from 'prop-types'
import { R, string } from '../../../resources/string'

/**
 * Basic or Simple Popup에 필요한 Props와 children을 담아 전달하기 위한 클래스.
 * openPopup에 key: 'popupProxy', value: {object} 형태로 넘긴다.
 *
 * openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupProxyObject) => SimplePopup.props.popupProxy.props로 전달받는다.
 *
 * ex)
 * <PopupProxy hasSecondaryButton={true}>
 *     <div>children</div>
 * </PopupProxy>
 *
 * => popupProxy.props: { hasSecondaryButton: true, children: object }
 */
export default class PopupProxy extends React.PureComponent {
  render() {
    return null
  }
}

PopupProxy.defaultProps = {
  title: '',
  className: 'basic_layer',
  subClassName: 'inner_basic_layer inner_basic_layer1',
  hasSecondaryButton: false,
  primaryButtonText: R(string.common.button.confirm),
  secondaryButtonText: R(string.common.button.cancel),
  autoClose: true,
  hasCloseButton: true,
  onShow: () => undefined,
  onDismiss: () => undefined,
}

PopupProxy.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  subClassName: PropTypes.string,
  hasSecondaryButton: PropTypes.bool,
  primaryButtonText: PropTypes.string,
  primaryButtonFunc: PropTypes.func,
  secondaryButtonText: PropTypes.string,
  secondaryButtonFunc: PropTypes.func,
  autoClose: PropTypes.bool,
  hasCloseButton: PropTypes.bool,
  onShow: PropTypes.func,
  onDismiss: PropTypes.func,
}
