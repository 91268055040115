import { keyMirror } from '../utils/utils'

const CreativeAssetPropertyEnum = {
  Type: keyMirror({
    PROFILE_IMAGE: null, // 프로필 이미지
    MAIN_IMAGE: null, // 소재 메인 이미지, 이미지네이티브 - 홍보이미지
    EXPANDABLE_IMAGE: null, // 비즈보드 확장요소 이미지
    EXPANDABLE_MULTI_IMAGE: null, // 비즈보드 확장요소 이미지
    VIDEO: null, // 비즈보드 확장요소 동영상
    VIDEO_IMAGE: null, // 비디오 맞춤 썸네일
    KAKAO_TV_VIDEO_CONTENT: null, // 카카오TV 동영상
    MAIN_TITLE: null,
    SUB_TITLE: null,
    PROFILE_NAME: null, // 프로필명
    CTATEXT: null, // 비즈보드 확장요소 행동유도버튼
    ALT_TEXT: null, // 소재 설명문구
    LINK: null, // 소재 url
    MOTION_BOARD_VIDEO: null, // 모션 비즈보드 동영상
    MOTION_BOARD_VIDEO_IMAGE: null, // 모션 비즈보드 업로드 썸네일
    BACKGROUND_IMAGE: null, // 모션 비즈보드 배경 이미지
    TEXT_IMAGE: null, // 모션 비즈보드 텍스트 이미지
    OBJECT_IMAGE: null, // 모션 비즈보드 오브젝트 이미지
    MOTION_BOARD_KAKAO_TV_VIDEO_CONTENT: null, // 모션 비즈보드에서 소재 라이브러리 메시지 동영상 불러올때 사용
    BANNER_IMAGE: null,
    COMPATIBLE_IMAGE: null,
    COMPATIBLE_TITLE: null,
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}

export default CreativeAssetPropertyEnum
