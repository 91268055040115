import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import cx from 'classnames'
import { checkNotEmpty } from '../../../../../../../utils/regexUtils'
import { useDispatch } from 'react-redux'
import { ScaleImageFitCenter } from '../../../../../../../utils/imageUtils'
import { useAnimateCSS } from '../../../../../../../utils/hook/useAnimateCSS'
import { Tooltip } from '../../../../../../../resources/locale'
import DynamicTooltip from '../../../../../../Tooltip/DynamicTooltip'
import {
  selectCmpAssetLibraryImageItem,
  setCmpAssetLibraryImageSelectedItems,
} from '../../../../../../../modules/cmp/mCmpAssetLibraryImage'
import { convertStringToBoldFaceByQuery } from '../../../../../../../utils/stringUtils'
import EllipsisTooltip from '../../../../../../Tooltip/TooltipImpl/EllipsisTooltip'
import {
  GUIDE_STORAGE_ITEM,
  GuideStorageLocal,
} from '../../../../../../../utils/storage/storageFactoryImpl'
import GuideTooltip from '../../../../../../Tooltip/TooltipImpl/GuideTooltip'
import useComponentId from '../../../../../../../utils/hook/useComponentId'

const CmpAssetLibraryImageEditorItemView = ({
  index,
  selectedOrder,
  item,
  cropAspects = [],
  query = '',
  isVisibleAnim = true,
  multiSelect,
  isBizBoardBanner = false,
  onImageLoaded,
  handleCmpImageEditorOpenForModify,
  validateImageSize,
}) => {
  const dispatch = useDispatch()

  const [showGuide, setShowGuide] = React.useState(index === 0)
  const buttonId = useComponentId()

  const { imageUUID, url, imageWidth, imageHeight, originalFileName } =
    item || {}

  const hasUrl = checkNotEmpty(url)

  const { ref, onLoad, isAnimStarted } = useAnimateCSS({
    onAnimEnded: () => {
      if (isVisibleAnim) {
        if (typeof onImageLoaded === 'function') {
          onImageLoaded(imageUUID)
        }
      }
    },
  })

  const isSelected = selectedOrder > 0

  const sizeText = [imageWidth, imageHeight].filter(Boolean).join('x')

  const { scaledWidth, scaledHeight } = ScaleImageFitCenter(
    imageWidth,
    imageHeight,
    150,
    150
  )

  const isImageSizeValid = React.useMemo(
    () =>
      validateImageSize({ originWidth: imageWidth, originHeight: imageHeight }),
    [imageHeight, imageWidth, validateImageSize]
  )

  const invalidImageAspect = !isImageSizeValid

  return (
    <li
      className={cx({
        on: !multiSelect && isSelected,
        on_order: multiSelect && isSelected,
        load_ok: isVisibleAnim && hasUrl,
      })}>
      <a
        className="link_material"
        onClick={e => {
          e.preventDefault()
          const selectedItem = item.delete('value').delete('id')

          if (multiSelect) {
            dispatch(
              selectCmpAssetLibraryImageItem({
                item: selectedItem,
              })
            )
          } else {
            dispatch(
              setCmpAssetLibraryImageSelectedItems({
                items: isSelected ? [] : [selectedItem],
              })
            )
          }
        }}>
        <span className="img_file">
          <span className="inner_img_file">
            {url ? (
              isBizBoardBanner ? (
                <span
                  style={{
                    display: 'block',
                    backgroundColor: '#f3f3f3',
                    height: `${scaledHeight}px`,
                  }}>
                  <img
                    src={url}
                    width={scaledWidth}
                    height={scaledHeight}
                    onLoad={isVisibleAnim ? onLoad : undefined}
                  />
                </span>
              ) : (
                <img
                  src={url}
                  width={scaledWidth}
                  height={scaledHeight}
                  onLoad={isVisibleAnim ? onLoad : undefined}
                />
              )
            ) : (
              <span className="bg_file" />
            )}
          </span>
          <span className="frame_g" />
          {isVisibleAnim && (
            <span
              ref={ref}
              className={cx('load_img', {
                'animated fadeOut': isAnimStarted,
              })}>
              <span className="ico_comm ico_loading">로딩완료</span>
            </span>
          )}
          {isSelected && (
            <span className="ico_comm ico_liston">{selectedOrder}</span>
          )}
        </span>
        <button
          id={buttonId}
          style={{
            zIndex: 12,
          }}
          type="button"
          className="btn_imgedit"
          onClick={e => {
            e.stopPropagation()

            handleCmpImageEditorOpenForModify({ imageEditorItem: item })

            setShowGuide(false)
          }}>
          <span className="ico_comm">이미지 편집</span>{' '}
        </button>
        {showGuide && <ImageEditorGuideTooltip targetId={buttonId} />}
      </a>
      <p className="txt_file">
        {sizeText || '-'}
        {invalidImageAspect && (
          <DynamicTooltip
            content={Tooltip('create_ads.v2.ad_library.common.invalid_aspect')}>
            <a className="link_g link_help">
              <span className="ico_comm ico_info">도움말</span>
            </a>
          </DynamicTooltip>
        )}
      </p>
      <EllipsisTooltip rootElementType="p" rootElementClassName="txt_filename">
        {convertStringToBoldFaceByQuery(
          String(originalFileName).normalize('NFC'),
          query,
          'fw_bold fc_emph'
        )}
      </EllipsisTooltip>
    </li>
  )
}

CmpAssetLibraryImageEditorItemView.propTypes = {
  index: PropTypes.number.isRequired,
  selectedOrder: PropTypes.number,
  item: ImmutablePropTypes.map.isRequired,
  isVisibleAnim: PropTypes.bool,
  deletable: PropTypes.bool,
  isBizBoardBanner: PropTypes.bool,
  multiSelect: PropTypes.bool,
  onImageLoaded: PropTypes.func,
  query: PropTypes.string,
  cropAspects: PropTypes.array.isRequired,
  handleCmpImageEditorOpenForModify: PropTypes.func,
  validateImageSize: PropTypes.func.isRequired,
}

const ImageEditorGuideTooltip = ({ targetId }) => {
  const [shouldShow, setShouldShow] = React.useState(false)

  React.useEffect(() => {
    const shouldShow =
      GuideStorageLocal.get(
        GUIDE_STORAGE_ITEM.CMP_ASSET_LIBRARY_IMAGE_EDITOR
      ) !== true

    if (shouldShow) {
      GuideStorageLocal.set(
        GUIDE_STORAGE_ITEM.CMP_ASSET_LIBRARY_IMAGE_EDITOR,
        true
      )

      setShouldShow(true)
    }
  }, [])

  return (
    shouldShow && (
      <GuideTooltip
        content={Tooltip('cmp.editor.guide_modify_button')}
        showDelay={100}
        dismissTimeout={10 * 1000}
        styles={{ backgroundColor: '#326EDC', color: '#fff' }}
        getElement={() => document.getElementById(targetId)}
      />
    )
  )
}

ImageEditorGuideTooltip.propTypes = {
  targetId: PropTypes.any.isRequired,
}

export default CmpAssetLibraryImageEditorItemView
