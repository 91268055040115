import React from 'react'
import { closeAllPopup, openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import {
  checkEmpty,
  checkNotEmpty,
  isUndefinedOrNull,
} from '../../../utils/regexUtils'
import { DemoAgeHelper } from '../../../utils/helper/helper-demoGraphic'
import {
  changeAdGroupForm,
  changeAdGroupFormAtIndexByKey,
  setIsValidAdGroup,
} from '../mAdGroupV2'
import { scroller } from 'react-scroll'
import { uniq } from 'lodash'
import { showErrorMessage } from '../../../utils/alertUtils'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../enums/GoalEnum'
import { NEW_AD_GROUP_FORM_VALIDATION_KEY } from '../../../components/AdvertiseV2/CampaignAndGroupV2/Validators/newAdGroupFormValidator'
import {
  modifyAdGroupAgeVerificationSettingDialog,
  releaseAgeVerificationSettingDialog,
} from '../../../components/AdvertiseV2/CampaignAndGroupV2/AdGroup/Common/AdGroupDialog'
import { KAKAO_DATA_UFO_TARGET_KEYS } from '../../../utils/advertise/targeting'
import { AUDIENCE_TARGET } from '../../../components/Targeting/Audience/Common/audience'
import { PIXEL_AND_SDK_LINKAGE_STATUS } from '../../../components/Linkage/PixelAndSdk/Utils/pixelAndSdk'
import {
  pixelAndSdkCancelLinkageAdGroup,
  pixelAndSdkDeleteLinkageAdGroup,
} from '../../../components/Linkage/PixelAndSdk/Dialog/PixelAndSdkDialog'
import { NumberUtils } from '../../../utils/numberUtils'
import { toInvalidApproachPath } from '../../../utils/router/routeUtils'
import {
  alreadyUsedContractDialog,
  notValidContractDialog,
} from '../../../components/DashboardV3/Dialog/CampaignDialog'
import {
  adGroupBidAmountModifyDialog,
  adGroupBidAmountModifyReconfirmDialog,
  adGroupBidStrategyTargetRoasModifyDialog,
  adGroupCountLimitCreateDialog,
  adGroupDailyBudgetModifyReconfirmDialog,
  adGroupSkanOptionDialog,
} from '../../../components/DashboardV3/Dialog/AdGroupDialog'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'
import BidStrategyEnum from '../../../enums/BidStrategyEnum'
import BidStrategyTargetEnum from '../../../enums/BidStrategyTargetEnum'

/**
 * 데모 > 나이 > 성인 타게팅 설정 변경
 */
export function setIsAdultTargetingOn(isOn, audienceTarget) {
  return dispatch => {
    if (isOn) {
      // 광고그룹 타게팅이 오디언스 타입인 경우, fiveAges 값은 업데이트 하지 않음.
      if (audienceTarget === AUDIENCE_TARGET.AD_GROUP) {
        dispatch(
          changeAdGroupForm(
            ['targeting', 'fiveAges'],
            DemoAgeHelper().createAdult()
          )
        )
      }
    }

    dispatch(changeAdGroupForm('adult', isOn))
  }
}

export function checkAgeVerificationChange(
  dispatch,
  prevAgeVerification,
  ageVerification,
  onAccept = () => undefined
) {
  if (!prevAgeVerification && ageVerification) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        modifyAdGroupAgeVerificationSettingDialog(onAccept)
      )
    )
  } else if (prevAgeVerification && !ageVerification) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        releaseAgeVerificationSettingDialog(onAccept)
      )
    )
  } else {
    onAccept()
  }
}

/**
 * 광고그룹 수정시 디바이스 or 게재지면 정보가 변경되었을 경우에 대한 처리.
 */
export function checkDeviceOrPlacementChangeV2(
  dispatch,
  oldAdGroupForm,
  adGroupForm,
  onAccept = () => {}
) {
  const {
    deviceTypes: newDevices,
    placements: newPlacements,
    allAvailableDeviceType: newIsAllDeviceType,
    allAvailablePlacement: newIsAllPlacement,
  } = adGroupForm

  const {
    deviceTypes: oldDevices,
    placements: oldPlacements,
    allAvailableDeviceType: oldIsAllDeviceType,
    allAvailablePlacement: oldIsAllPlacement,
  } = oldAdGroupForm

  const D =
    oldDevices &&
    (!oldDevices.toSet().equals(newDevices.toSet()) ||
      newIsAllDeviceType !== oldIsAllDeviceType)

  const P =
    oldPlacements &&
    (!oldPlacements.toSet().equals(newPlacements.toSet()) ||
      newIsAllPlacement !== oldIsAllPlacement)

  if (D || P) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy
          hasSecondaryButton={true}
          primaryButtonFunc={onAccept}
          autoClose={false}>
          <strong className="tit_layer">디바이스 및 게재지면 수정</strong>
          <p className="txt_layer">
            디바이스 및 게재지면을 변경한 경우,
            <br />
            현재 기준에 맞는 소재 유형만 등록 가능하며
            <br />
            기 등록된 소재 일부가 노출되지 않을 수 있습니다.
            <br />
            계속하시겠습니까?
          </p>
        </PopupProxy>
      )
    )
  } else {
    onAccept()
  }
}

/**
 * 입찰 방식 변경 안내 팝업
 * bidStrategy 가 수동(MANUAL) <-> 자동(AUTOBID, OPTIMIZATION) 간 변경되었을 경우
 * - auto -> manual (설정 완료 소재 bidAmount 를 입력한 광고그룹 bidAmount 로 일괄 업데이트, prev 가 0 이었기 때문에 minimum 고려 X)
 * - manual -> auto (설정 완료 소재 bidAmount 를 0 으로 일괄 업데이트)
 * - pricing type 이 바뀌었을 때,
 */
export function checkBidStrategyChange({
  dispatch,
  bidStrategy,
  prevBidStrategy,
  pricingType,
  prevPricingType,
  bidStrategyTarget,
  prevBidStrategyTarget,
  onOK,
}) {
  dispatch(closeAllPopup())

  const { type } = bidStrategyTarget || {}
  const { type: prevType } = prevBidStrategyTarget || {}

  const isBidStrategyChange =
    BidStrategyEnum.isAdGroupAutoBid(bidStrategy) !==
    BidStrategyEnum.isAdGroupAutoBid(prevBidStrategy)
  const isPricingTypeChange = pricingType !== prevPricingType
  const isBidStrategyTargetTypeChange = type !== prevType

  if (
    isBidStrategyChange ||
    isPricingTypeChange ||
    isBidStrategyTargetTypeChange
  ) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        adGroupBidAmountModifyDialog({ onOK })
      )
    )
  } else {
    onOK()
  }
}

/**
 * 입찰 금액 변경 팝업
 */
export function checkBidAmountChange({
  dispatch,
  bidAmount,
  prevBidAmount,
  pricingType,
  prevPricingType,
  bidStrategyTarget,
  prevBidStrategyTarget,
  onOK,
}) {
  dispatch(closeAllPopup())

  const { type, value } = bidStrategyTarget || {}
  const { value: prevValue } = prevBidStrategyTarget || {}

  const isBidAmountChange = bidAmount !== prevBidAmount
  const isPricingTypeChange = pricingType !== prevPricingType
  const isUnavailableBidStrategyTargetType = [
    BidStrategyTargetEnum.Type.TARGET_ROAS,
    BidStrategyTargetEnum.Type.MAXIMIZE_CONVERSION_VALUE,
  ].includes(type)
  const isBidStrategyTargetValueChange = value !== prevValue

  if (isUnavailableBidStrategyTargetType || checkEmpty(bidStrategyTarget)) {
    onOK()
  } else if (
    isBidAmountChange ||
    isPricingTypeChange ||
    isBidStrategyTargetValueChange
  ) {
    const changedValue = checkNotEmpty(bidStrategyTarget) ? value : bidAmount
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        adGroupBidAmountModifyReconfirmDialog({ bidAmount: changedValue, onOK })
      )
    )
  } else {
    onOK()
  }
}

/**
 * ROAS 목표 변경 팝업
 */
export function checkTargetRoasChange({
  dispatch,
  bidStrategyTarget,
  prevBidStrategyTarget,
  onOK,
}) {
  dispatch(closeAllPopup())

  const { type, value } = bidStrategyTarget || {}
  const { type: prevType, value: prevValue } = prevBidStrategyTarget || {}

  const isTargetRoas = type === BidStrategyTargetEnum.Type.TARGET_ROAS

  if (isTargetRoas && (type !== prevType || value !== prevValue)) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        adGroupBidStrategyTargetRoasModifyDialog({ value, onOK })
      )
    )
  } else {
    onOK()
  }
}

export function checkDailyBudgetChange(
  dispatch,
  dailyBudget,
  prevDailyBudget,
  onAccept
) {
  dispatch(closeAllPopup())
  if (dailyBudget !== prevDailyBudget) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        adGroupDailyBudgetModifyReconfirmDialog({
          dailyBudget,
          onOK: onAccept,
        })
      )
    )
  } else {
    onAccept()
  }
}

export function checkSkanProtocolInfoChange(
  dispatch,
  skanAppId,
  prevSkanAppId,
  onAccept,
  onSubmit
) {
  dispatch(closeAllPopup())
  if (checkNotEmpty(skanAppId) !== checkNotEmpty(prevSkanAppId)) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        adGroupSkanOptionDialog({ onOk: onSubmit })
      )
    )
  } else {
    onAccept()
  }
}

export function checkTalkChannelReachModify(
  dispatch,
  isAdGroupReadyStatus,
  onAccept = () => {}
) {
  if (isAdGroupReadyStatus) {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy
          hasSecondaryButton={true}
          primaryButtonFunc={onAccept}
          autoClose={false}>
          <strong className="tit_layer">수정사항 저장</strong>
          <p className="txt_layer">
            수정사항 저장 전 타겟 변경사항을 반드시 확인해주시기 바랍니다.
            구매발송수 또는 타겟을 변경한 경우 발송 대상을 다시 추출합니다.
            시작일시가 1시간 이내인 경우 모수 규모에 따라 시작일시보다 발송
            시작이 지연될 수 있습니다.
          </p>
        </PopupProxy>
      )
    )
  } else {
    onAccept()
  }
}

function noLongerSupportedUfoTargetException({ detail }) {
  return (dispatch, getState) => {
    const {
      adGroupV2: {
        creatingAdGroups,
        modifyAdGroup: { adGroupForm },
      },
    } = getState()

    /**
     * JSON string
     * [{ id: '', name: '', children: [] }...]
     */
    const deprecatedTargetString = detail?.[0]?.description || ''
    const deprecatedTargetArray = JSON.parse(deprecatedTargetString)

    if (
      Array.isArray(deprecatedTargetArray) &&
      deprecatedTargetArray.length > 0
    ) {
      const deprecatedTargetIdArray = deprecatedTargetArray.map(v => v.id)

      const updateUfoTargetByDeprecatedIds = ({ targeting }) => {
        return targeting.map((list, k) =>
          KAKAO_DATA_UFO_TARGET_KEYS.includes(k)
            ? list.filter(id => !deprecatedTargetIdArray.includes(id))
            : list
        )
      }

      const getSelectedUfoTargetsByDeprecatedIds = ({ targeting }) => {
        return targeting
          .map((list, k) =>
            KAKAO_DATA_UFO_TARGET_KEYS.includes(k)
              ? list.filter(id => deprecatedTargetIdArray.includes(id))
              : undefined
          )
          .filter(Boolean)
          .valueSeq()
          .flatten()
      }

      const isModify = adGroupForm.get('id') > 0

      let selectedTargetNameArray = []

      /**
       * 그룹에 설정된 `지원 중단 타겟` name 을 메시지에 포함하여 보여줌.
       * 그룹에 설정된 `지원 중단 타겟` 제거 후 갱신(생성 시에는 모든 그룹 대상).
       */
      if (isModify) {
        const selectedIds = getSelectedUfoTargetsByDeprecatedIds({
          targeting: adGroupForm.get('targeting'),
        })

        selectedTargetNameArray = deprecatedTargetArray
          .filter(v => selectedIds.includes(v.id))
          .map(v => v.name)

        const nextTargeting = updateUfoTargetByDeprecatedIds({
          targeting: adGroupForm.get('targeting'),
          deprecatedTargetIdArray,
        })

        dispatch(changeAdGroupForm('targeting', nextTargeting))
      } else {
        creatingAdGroups.forEach(({ adGroupForm }, index) => {
          const selectedIds = getSelectedUfoTargetsByDeprecatedIds({
            targeting: adGroupForm.get('targeting'),
          })

          selectedTargetNameArray.push(
            ...deprecatedTargetArray
              .filter(v => selectedIds.includes(v.id))
              .map(v => v.name)
          )

          const nextTargeting = updateUfoTargetByDeprecatedIds({
            targeting: adGroupForm.get('targeting'),
            deprecatedTargetIdArray,
          })

          dispatch(
            changeAdGroupFormAtIndexByKey(index, 'targeting', nextTargeting)
          )
        })
      }

      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">지원이 중단된 카테고리 선택</strong>
            <p className="txt_layer">
              선택한 카테고리 중 지원 중단된 값이 있어 해제되었습니다.
              <br />
              변경된 타겟과 모수를 확인 후 다시 저장하세요.
              <br />
              (삭제된 카테고리: {uniq(selectedTargetNameArray).join(', ')})
            </p>
          </PopupProxy>
        )
      )
    }
  }
}

/**
 * 광고그룹에 타게팅으로 픽셀 설정된 경우, 픽셀 상태를 체크하여 연동해제/삭제된 상태에 대해 팝업 알림.
 * @param responses
 */
export function showAdGroupPixelNotAvailableWarning(responses) {
  return dispatch => {
    const hasDeletedStatus = responses.some(response => {
      const {
        data: {
          body: { deletedAt },
        },
      } = response

      return !isUndefinedOrNull(deletedAt)
    })

    const hasUnLInkedStatus = responses.some(response => {
      const {
        data: {
          body: { adAccountLinkStatus },
        },
      } = response

      return adAccountLinkStatus === PIXEL_AND_SDK_LINKAGE_STATUS.UNLINKED
    })

    if (hasDeletedStatus) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          pixelAndSdkDeleteLinkageAdGroup()
        )
      )
    } else if (hasUnLInkedStatus) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          pixelAndSdkCancelLinkageAdGroup()
        )
      )
    }
  }
}

/**
 * 광고그룹에 설정된 맞춤타겟의 상태 업데이트를 위한 처리
 * @param responses
 * @param trackerTargetings
 */
export const updateAdGroupTrackStatus = ({
  responses = [],
  trackerTargetings,
}) => {
  responses.forEach(response => {
    const body = response?.data?.body

    if (!isUndefinedOrNull(body)) {
      const { adAccountLinkStatus, deletedAt, trackId } = body

      const status = !isUndefinedOrNull(deletedAt)
        ? PIXEL_AND_SDK_LINKAGE_STATUS.DELETED
        : PIXEL_AND_SDK_LINKAGE_STATUS[adAccountLinkStatus]

      const index = trackerTargetings.findIndex(
        target => target.trackId === trackId
      )

      trackerTargetings[index].trackStatus = status
    }
  })
}

export const handleAdGroupFormExceptionV2 = (e, openAdGroupIndex = 0) => {
  return (dispatch, getState) => {
    if (!e || !e.response || !e.response.data) {
      console.log(e?.message || e)
      return
    }

    const { errorCode, message, detail } = e.response.data

    const {
      campaignV2: {
        campaignForm: {
          campaignTypeGoal: { campaignType, goal },
        },
      },
    } = getState()

    const isTalkChannelReachCampaign =
      campaignType === CampaignTypeEnum.Type.TALK_CHANNEL &&
      goal === GoalEnum.Type.REACH

    switch (errorCode) {
      case 21011: {
        // 캠페인 개수 제한
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">캠페인 제한 수 초과</strong>
              <p className="txt_layer">
                광고계정 내 캠페인 개수 제한으로
                <br />
                캠페인을 저장할 수 없습니다.
                <br />
                기존 캠페인을 삭제한 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 31025: {
        // 캠페인 기간 예산 초과
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">캠페인 기간 예산 초과</strong>
              <p className="txt_layer">
                캠페인 기간 예산을 초과하여
                <br />
                광고그룹을 생성할 수 없습니다.
                <br />
                기간 예산을 확인 후 진행하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 32048: {
        // 광고그룹 다중생성 에러 캐치
        if (!isUndefinedOrNull(detail) && Array.isArray(detail)) {
          const { errorCode, description } =
            detail[openAdGroupIndex] || detail[0]

          switch (errorCode) {
            case 22404: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고계정 저장 불가</strong>
                    <p className="txt_layer">
                      불러온 오디언스를 사용중인 광고그룹 수가 50개를 초과하여
                      <br />
                      광고그룹을 저장할 수 없습니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 쇼케이스에 설정된 trackId와 캠페인 저장 시점 프론트의 trackId 값이 상이할 경우
            case 31003: {
              showErrorMessage(
                '카탈로그에 연동된 픽셀 & SDK 정보가 유효하지 않습니다. 비즈도구에서 카탈로그 정보와 픽셀 & SDK 정보 확인 후 다시 시도해주세요.'
              )
              break
            }

            // 쇼케이스 에러
            case 31028: {
              showErrorMessage('카탈로그가 유효하지 않습니다.')
              break
            }

            // 광고그룹 저장 시점에 어드민 화이트리스트 설정 풀린 경우
            case 35012: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고그룹 저장 불가</strong>
                    <p className="txt_layer">
                      SKAdNetwork를 사용 할 수 없는 광고계정입니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // SKAN 운영상태가 활성+룩백인 광고그룹 100개 이상 만들 경우
            case 35013: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고그룹 제한수 초과</strong>
                    <p className="txt_layer">
                      앱ID당 광고그룹 개수 제한으로
                      <br />
                      광고그룹을 저장할 수 없습니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            case 88005: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고계정 잔액 부족</strong>
                    <p className="txt_layer">
                      광고계정 잔액이 부족하여
                      <br />
                      광고그룹을 저장할 수 없습니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            case 89720: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">앱ID 연동 오류</strong>
                    <p className="txt_layer">
                      앱ID 연동 중 시스템 오류가 발생했습니다. <br />
                      잠시 후 다시 시도하세요.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 광고그룹 제한 수 초과
            case 31007: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  adGroupCountLimitCreateDialog()
                )
              )
              break
            }

            case 31025: {
              // 캠페인 기간 예산 초과
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">캠페인 기간 예산 초과</strong>
                    <p className="txt_layer">
                      캠페인 기간 예산을 초과하여
                      <br />
                      광고그룹을 생성할 수 없습니다.
                      <br />
                      기간 예산을 확인 후 진행하세요.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 광고그룹 집행일시가 1분 미만으로 남은 경우
            case 38022: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고그룹 저장 불가</strong>
                    <p className="txt_layer">
                      {`광고그룹 시작일시가 ${
                        isTalkChannelReachCampaign ? 5 : 1
                      }분 미만으로 남은 경우`}
                      <br />
                      광고그룹을 저장할 수 없습니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 지원종료된 과금방식으로 그룹 저장시
            case 31027: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">입찰방식 저장 불가</strong>
                    <p className="txt_layer">
                      해당 입찰방식은 상위 캠페인에서 지원이 종료되었습니다.
                      <br />
                      다른 입찰방식을 선택하세요.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            case 33011: {
              showErrorMessage(
                '상품반응 이벤트 정책 요구사항에 맞지 않아 오류가 발생했습니다.'
              )
              break
            }

            // 계약 해지된 캠페인에 광고그룹 생성시
            case 39813: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">계약 해지 상태</strong>
                    <p className="txt_layer">
                      해당 계약건의 상태는 계약 해지로
                      <br />
                      광고그룹을 저장할 수 없습니다.
                      <br />
                      신규 계약 생성 후 진행하세요.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 다이나믹 카탈로그
            case 51620: {
              showErrorMessage(
                '카탈로그에 문제가 있어 오류가 발생했습니다.\n카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
              )
              break
            }

            case 51621: {
              showErrorMessage(
                '해당 카탈로그의 권한이 없습니다.\n 카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
              )
              break
            }

            // 비즈보드 CPT 사용불가 유형
            case 35002: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고그룹 저장 불가</strong>
                    <p className="txt_layer">
                      사용가능한 캠페인 유형과 목표가 아닙니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            // 비즈보드 CPT 유효하지 않은 계약
            case 39805: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  notValidContractDialog()
                )
              )
              break
            }

            // 비즈보드 CPT 중복 계약 에러
            case 39808: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  alreadyUsedContractDialog()
                )
              )
              break
            }
            default: {
              showErrorMessage(description)
            }
          }
        }
        break
      }

      case 32018: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                이미 광고그룹이 집행중이거나 종료되어 저장 할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 32026: {
        showErrorMessage('삭제되었거나 지원 종료된 광고입니다.')
        break
      }

      // 지원종료된 과금방식으로 그룹 저장시
      case 31027: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">입찰방식 저장 불가</strong>
              <p className="txt_layer">
                해당 입찰방식은 상위 캠페인에서 지원이 종료되었습니다.
                <br />
                다른 입찰방식을 선택하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      /* 광고그룹 단건 생성 및 수정 */
      case 32052: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                하위 소재가 2개 이상 있는 경우
                <br />
                스마트메시지 설정을 해제할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 쇼케이스에 설정된 trackId와 캠페인 저장 시점 프론트의 trackId 값이 상이할 경우
      case 31003: {
        showErrorMessage(
          '카탈로그에 연동된 픽셀 & SDK 정보가 유효하지 않습니다. 비즈도구에서 카탈로그 정보와 픽셀 & SDK 정보 확인 후 다시 시도해주세요.'
        )
        break
      }

      // 쇼케이스 에러
      case 31028: {
        showErrorMessage('카탈로그가 유효하지 않습니다.')
        break
      }

      // 광고그룹 저장 시점에 어드민 화이트리스트 설정 풀린 경우
      case 35012: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                SKAdNetwork를 사용 할 수 없는 광고계정입니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 운영상태가 활성+룩백인 광고그룹 100개 이상 만들 경우
      case 35013: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 제한수 초과</strong>
              <p className="txt_layer">
                앱ID당 광고그룹 개수 제한으로
                <br />
                광고그룹을 저장할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 광고그룹 집행 종료일 경우인데 집행기간 수정할 경우
      case 35024: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">집행기간 수정 불가</strong>
              <p className="txt_layer">
                SKAdNetwork 설정된 광고그룹은 집행이 완료되면
                <br />
                집행기간 수정이 불가합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 광고그룹 수정시 이미 생성된 소재가 있으면 설정 불가
      case 35025: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">SKAdNetwork 설정 불가</strong>
              <p className="txt_layer">
                이미 생성된 소재가 있어, SKAdNetwork 설정 불가합니다.
                <br />
                소재 삭제 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 미설정 광고그룹 집행완료시 SKAN 설정할 경우
      case 35026: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">SKAdNetwork 설정 불가</strong>
              <p className="txt_layer">
                집행 완료된 광고그룹에서
                <br />
                SKAdNetwork 설정 불가합니다.
                <br />
                집행 기간 수정 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 광고그룹 미지원 디바이스 설정시
      case 35027: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                지원하지 않는 디바이스 항목이 설정되어,
                <br />
                광고그룹을 저장 할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // SKAN 광고그룹 미지원 게재지면 설정시
      case 35028: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                지원하지 않는 게재지면 항목이 설정되어,
                <br />
                광고그룹을 저장 할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 32050: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">입찰방식 변경 불가</strong>
              <p className="txt_layer">
                하위에 동영상 유형의 소재가 등록되어 있습니다.
                <br />
                동영상은 CPM 입찰방식만 지원합니다.
                <br />
                소재 삭제 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 이름 중복.
      case 31002: {
        dispatch(
          setIsValidAdGroup(
            NEW_AD_GROUP_FORM_VALIDATION_KEY.AD_GROUP_NAME,
            false,
            '동일 캠페인에서 이미 사용 중인 광고그룹 이름입니다.'
          )
        )
        break
      }

      // begin date over end date
      // begin date 180 over
      case 31004:
      case 31005: {
        dispatch(
          setIsValidAdGroup(
            NEW_AD_GROUP_FORM_VALIDATION_KEY.SCHEDULE,
            false,
            message
          )
        )
        break
      }

      // 등록 가능한 광고그룹 수 초과.
      case 31007: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            adGroupCountLimitCreateDialog()
          )
        )
        break
      }

      // ??
      case 21009:
      case 32001: {
        dispatch(RouterV2.replace(toInvalidApproachPath()))
        break
      }

      // 스케쥴을 선택하지 않았을 경우.
      case 31013: {
        scroller.scrollTo(NEW_AD_GROUP_FORM_VALIDATION_KEY.SCHEDULE, {
          offset: -100,
        })
        dispatch(
          setIsValidAdGroup(
            NEW_AD_GROUP_FORM_VALIDATION_KEY.SCHEDULE,
            false,
            '집행기간의 요일/시간 설정을 확인하세요. 반드시 한 개 이상 설정해야 합니다.'
          )
        )
        break
      }

      // 광고그룹 수정 시점에 하위 소재의 상태에 따른 제한.
      case 32015: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 수정 불가</strong>
              <p className="txt_layer">
                발송 준비, 발송 중, 발송 중지 상태의
                <br />
                메시지가 있는 경우 수정이 불가능합니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 일 예산이 캠페인 일 예산을 초과함.
      case 32019: {
        scroller.scrollTo(NEW_AD_GROUP_FORM_VALIDATION_KEY.DAILY_BUDGET, {
          offset: -100,
        })

        const {
          campaignV2: {
            campaignForm: { dailyBudget: campaignDailyBudget },
          },
          adConstraints: {
            adGroupConstraints: {
              adGroupDailyBudgetConstraint: { min, max: adGroupDailyBudgetMax },
            },
          },
        } = getState()

        const max = Math.min(campaignDailyBudget, adGroupDailyBudgetMax)
        const unit = 10

        dispatch(
          setIsValidAdGroup(
            NEW_AD_GROUP_FORM_VALIDATION_KEY.DAILY_BUDGET,
            false,
            `${NumberUtils.toShorten(min)}원 이상 ${NumberUtils.toShorten(
              max
            )}원 이하 ${NumberUtils.toShorten(unit)}원 단위로 입력하세요.`
          )
        )
        break
      }

      case 33011: {
        showErrorMessage(
          '상품반응 이벤트 정책 요구사항에 맞지 않아 오류가 발생했습니다.'
        )
        break
      }

      // 광고그룹의 집행기간에 벗어나는 하위 소재가 있는 경우
      case 33228: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">집행기간 저장 불가</strong>
              <p className="txt_layer">
                하위 소재의 집행시간이 설정한 광고그룹의 집행기간을 벗어나
                저장할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹이 종료된 경우
      case 33232: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 수정 불가</strong>
              <p className="txt_layer">
                광고그룹 집행기간 중이거나 종료된 경우 <br /> 광고그룹을 수정할
                수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 34533: // 카카오톡 채널에 고객센터 연락처 누락
      case 34534: // 카카오톡 채널의 상태가 변경 (휴면 제재 등)
      case 24039: // 카카오톡 채널 삭제
      case 34535: {
        // 카카오톡 채널의 홈노출 off
        showErrorMessage(message)
        break
      }

      // 비즈보드 CPT 사용불가 유형
      case 35002: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                사용가능한 캠페인 유형과 목표가 아닙니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 딜 정보가 유효하지 않은 경우
      case 36001:
      case 36002: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">집행기간 설정 불가</strong>
              <p className="txt_layer">
                설정한 집행기간은 현재 구매할 수 없습니다.
                <br />
                처음부터 다시 진행해주시기 바랍니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 집행일시가 1분 미만으로 남은 경우
      case 38022: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                {`광고그룹 시작일시가 ${
                  isTalkChannelReachCampaign ? 5 : 1
                }분 미만으로 남은 경우`}
                <br />
                광고그룹을 저장할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 비즈보드 CPT 유효하지 않은 계약
      case 39805:
      case 39806: {
        dispatch(
          openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, notValidContractDialog())
        )
        break
      }

      // 비즈보드 CPT 중복 계약 에러
      case 39808: {
        dispatch(
          openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, alreadyUsedContractDialog())
        )
        break
      }

      // 계약 해지된 캠페인에 광고그룹 생성시
      case 39813: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">계약 해지 상태</strong>
              <p className="txt_layer">
                해당 계약건의 상태는 계약 해지로
                <br />
                광고그룹을 저장할 수 없습니다.
                <br />
                신규 계약 생성 후 진행하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      case 39999: {
        if (!isUndefinedOrNull(detail) && Array.isArray(detail)) {
          const { errorCode, description } =
            detail[openAdGroupIndex] || detail[0]

          switch (errorCode) {
            case 30003: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">광고 상품 계약 오류</strong>
                    <p className="txt_layer">
                      광고 상품 계약이 유효하지 않습니다.
                      <br />
                      계약 상태를 확인해주세요.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            case 30004: {
              dispatch(
                openPopupByProxy(
                  POPUP_KEY.SIMPLE_POPUP,
                  <PopupProxy>
                    <strong className="tit_layer">
                      광고 상품 집행일시 중복
                    </strong>
                    <p className="txt_layer">
                      동일한 집행일시에 이미 등록된 계약이 존재합니다.
                    </p>
                  </PopupProxy>
                )
              )
              break
            }

            case 30011: {
              if (CampaignTypeEnum.isRichNativeType(campaignType)) {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    <PopupProxy>
                      <strong className="tit_layer">광고시간 오류</strong>
                      <p className="txt_layer">
                        집행일시가 유효하지 않습니다.
                        <br />
                        광고 노출 영업일 2일 전 17시까지 계약 등록 가능합니다.
                      </p>
                    </PopupProxy>
                  )
                )
              } else {
                dispatch(
                  openPopupByProxy(
                    POPUP_KEY.SIMPLE_POPUP,
                    <PopupProxy>
                      <strong className="tit_layer">광고시간 오류</strong>
                      <p className="txt_layer">
                        집행시간이 유효하지 않습니다.
                        <br />
                        집행시간까지 61분 이상 남았을 경우에만 계약 저장
                        가능합니다.
                      </p>
                    </PopupProxy>
                  )
                )
              }
              break
            }

            default: {
              showErrorMessage(description)
            }
          }
        }
        break
      }

      // 다이나믹 카탈로그
      case 51620: {
        showErrorMessage(
          '카탈로그에 문제가 있어 오류가 발생했습니다.\n카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
        )
        break
      }

      case 51621: {
        showErrorMessage(
          '해당 카탈로그의 권한이 없습니다.\n 카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
        )
        break
      }

      // 광고그룹의 잔액이 부족한 경우
      case 88005: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고계정 잔액 부족</strong>
              <p className="txt_layer">
                광고계정 잔액이 부족하여
                <br />
                광고그룹을 저장할 수 없습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 생성 시점 픽셀 권한 에러
      case 89708: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">픽셀 & SDK 권한 오류</strong>
              <p className="txt_layer">
                설정한 픽셀 & SDK의 권한이 없습니다.
                <br />
                처음부터 다시 진행해주시기 바랍니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 모든 이벤트는 제외지만 같은 트랙의 다른 이벤트가 포함일 경우
      case 89717: {
        showErrorMessage(message)
        break
      }

      case 89720: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">앱ID 연동 오류</strong>
              <p className="txt_layer">
                앱ID 연동 중 시스템 오류가 발생했습니다. <br />
                잠시 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 광고그룹 분산 발송하기 발송속도 변경 실패 시
      case 28015: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                광고그룹이 ON 상태이거나 집행이 완료되어
                <br />
                저장할 수 없습니다.
                <br />
                분산 발송 옵션은 광고그룹 OFF 상태에서
                <br />
                수정할 수 있습니다.
              </p>
            </PopupProxy>
          )
        )
        break
      }
      /**
       * 지원 중단된 UFO 카테고리 타겟이 포함.
       * - 타게팅 설정 exception 은 다중 생성 시에도 32048 로 떨어지지 않는다.
       */
      case 22300: {
        dispatch(noLongerSupportedUfoTargetException({ detail }))
        break
      }

      case 22403: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">광고그룹 저장 불가</strong>
              <p className="txt_layer">
                불러온 오디언스가 삭제되어 광고그룹을 저장할 수 없습니다.
                <br />
                오디언스를 다시 설정하세요.
              </p>
            </PopupProxy>
          )
        )

        break
      }

      case 84007: {
        showErrorMessage(
          '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
        )
        break
      }

      default: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">시스템 오류</strong>
              <p className="txt_layer">
                시스템 오류가 발생하였습니다.
                <br />
                잠시 후 다시 시도하세요.
                <br />
                시스템 오류가 반복될 경우 처음부터 다시 진행하세요.
                <br />
                (errorCode: {errorCode}, message: {message})
              </p>
            </PopupProxy>
          )
        )
        break
      }
    }
  }
}
