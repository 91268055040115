import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import { CmpAssetLibraryUtils } from '../../Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'

const ImageEditorGuide = ({
  creativeFormat,
  creativeUploadAssetPropertyType,
  campaignType,
  aspect,
  setIsGuideMouseOver,
}) => {
  const reviewGuideUrl = CmpAssetLibraryUtils.reviewGuideUrl({
    campaignType,
    creativeFormat,
    creativeUploadAssetPropertyType,
  })

  const getGuideForAspect = React.useCallback(
    ({ aspect }) => {
      switch (aspect) {
        case 2:
          return (
            <Fragment>
              편집 영역안{' '}
              <a
                className="link_txt"
                onMouseOver={() => setIsGuideMouseOver(true)}
                onMouseLeave={() => setIsGuideMouseOver(false)}>
                반투명 가이드
              </a>
              에는 텍스트와 로고(CI,BI) 및 주요 오브젝트를 적용하지 않는 것을
              권장합니다.
            </Fragment>
          )
        case 1:
          return '편집 영역안에는 한글, 영어, 특수문자 등 모든 텍스트를 사용할 수 없습니다.'
        default:
          return undefined
      }
    },
    [setIsGuideMouseOver]
  )

  return (
    <div className="info_imgcheck">
      <p className="desc_imgcheck">{getGuideForAspect({ aspect })}</p>
      <a
        className="link_guide link_emph"
        href={reviewGuideUrl}
        target="_blank"
        rel="noopener noreferrer">
        심사 가이드
        <span className="ico_comm ico_out" />
      </a>
    </div>
  )
}

ImageEditorGuide.propTypes = {
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ).isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  aspect: PropTypes.number.isRequired,
  setIsGuideMouseOver: PropTypes.func.isRequired,
}

export default ImageEditorGuide
