import {
  ScaleImageCenterCropStyle,
  ScaleImageFitCenterStyle,
} from '../../../../../../utils/imageUtils'
import cx from 'classnames'
import { checkEmpty } from '../../../../../../utils/regexUtils'
import ActionButtonEnum from '../../../../../../enums/ActionButtonEnum'
import React from 'react'
import ImageNativeTemplatePropTypes from '../imageNativeTemplatePropTypes'

export const BgImage_BottomHorizontalTitleActionButton_2_1_Page1 = ({
  title,
  actionButton,
  promotionalImageURL,
  isInAd,
}) => {
  return (
    <div className="material_default material_thumb6">
      <span className="ad_mark">AD</span>
      <div className="thumb_img">
        {promotionalImageURL && (
          <span
            className="img_thumb"
            style={ScaleImageFitCenterStyle({
              url: promotionalImageURL,
              backgroundColor: '#ffffff',
            })}
          />
        )}
      </div>
      <div className="box_induce">
        <strong
          className={cx('tit_induce2', {
            no_text: title?.trim()?.length === 0,
          })}>
          <span className="inner_tit">
            {title?.trim() || (isInAd ? '타이틀' : '미입력 요소')}
          </span>
        </strong>
        <span
          className={cx('btn_induce2', {
            no_text: checkEmpty(actionButton),
          })}>
          {ActionButtonEnum.getName(actionButton) || '버튼 선택'}
        </span>
      </div>
    </div>
  )
}

export const TopImage_BottomVerticalTitleProfileName_2_1_Page2 = ({
  title,
  profileName,
  promotionalImageURL,
  isInAd,
}) => {
  return (
    <div className="material_default material_thumb5">
      <div className="material_box">
        <span className="ad_mark">AD</span>
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <div className="box_induce">
          <strong
            className={cx('tit_induce2', {
              no_text: title?.trim()?.length === 0,
            })}>
            <span className="inner_tit">
              {title?.trim() || (isInAd ? '타이틀' : '미입력 요소')}
            </span>
          </strong>
          <p
            className={cx('txt_induce', {
              no_text: profileName?.trim()?.length === 0,
            })}>
            {profileName?.trim() || '프로필 이름'}
          </p>
        </div>
      </div>
    </div>
  )
}

export const CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4 =
  ({ title, actionButton, profileName, promotionalImageURL, isInAd }) => {
    return (
      <div className="material_default material_img3">
        <div className="profile_info2">
          <span className="txt_profile txt_profilead">AD</span>
          <strong
            className={cx('tit_profile', {
              no_text: profileName?.trim()?.length === 0,
            })}>
            {profileName?.trim() || (isInAd ? '프로필 이름' : '미입력 요소')}
          </strong>
        </div>
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <div className="box_induce">
          <strong
            className={cx('tit_induce2', {
              no_text: title?.trim()?.length === 0,
            })}>
            <span className="inner_tit">
              {title?.trim() || (isInAd ? '타이틀' : '미입력 요소')}
            </span>
          </strong>
          <span
            className={cx('btn_induce2', {
              no_text: actionButton === ActionButtonEnum.Type.NONE,
            })}>
            {ActionButtonEnum.getName(actionButton) ||
              (isInAd ? '행동유도문구' : '미입력 요소')}
          </span>
        </div>
      </div>
    )
  }

export const LeftImage_RightVerticalTitleProfileName_2_1_Page5 = ({
  title,
  profileName,
  promotionalImageURL,
}) => {
  return (
    <div className="material_default material_thumb2">
      <div className="thumb_img">
        {promotionalImageURL && (
          <span
            className="img_thumb"
            style={ScaleImageCenterCropStyle({ url: promotionalImageURL })}
          />
        )}
      </div>
      <div className="box_induce">
        <strong
          className={cx('tit_induce', {
            no_text: title?.trim()?.length === 0,
          })}>
          {title?.trim() || '타이틀'}
        </strong>
        <div className="profile_info">
          <span className="info_profile">
            <span className="inner_info">
              <strong
                className={cx('tit_profile', {
                  no_text: profileName?.trim()?.length === 0,
                })}>
                {profileName?.trim() || '프로필 이름'}
              </strong>
            </span>
          </span>
        </div>
      </div>
    </div>
  )
}

export const BgImage_2_1_Page6 = ({ promotionalImageURL }) => {
  return (
    <div className="material_default material_img4">
      <div className="thumb_img">
        {promotionalImageURL && (
          <span
            className="img_thumb"
            style={ScaleImageFitCenterStyle({
              url: promotionalImageURL,
              backgroundColor: '#ffffff',
            })}
          />
        )}
      </div>
    </div>
  )
}

export const TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page7 =
  ({
    title,
    description,
    actionButton,
    profileName,
    profileImageURL,
    promotionalImageURL,
  }) => {
    return (
      <div className="material_default material_thumb1">
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <strong
          className={cx('tit_induce', {
            no_text: title?.trim()?.length === 0,
          })}>
          {title?.trim() || '타이틀'}
        </strong>
        <p
          className={cx('txt_induce', {
            no_text: description?.trim()?.length === 0,
          })}>
          {description?.trim() || '홍보문구'}
        </p>
        <div className="profile_info">
          <span className="thumb_profile">
            {profileImageURL && (
              <span
                className="img_thumb"
                style={ScaleImageFitCenterStyle({
                  url: profileImageURL,
                  backgroundColor: '#ffffff',
                })}
              />
            )}
          </span>
          <span className="info_profile">
            <span className="inner_info">
              <strong
                className={cx('tit_profile', {
                  no_text: profileName?.trim()?.length === 0,
                })}>
                {profileName?.trim() || '프로필 이름'}
              </strong>
            </span>
          </span>
        </div>
        <span
          className={cx('btn_induce2', {
            no_text: actionButton === ActionButtonEnum.Type.NONE,
          })}>
          {ActionButtonEnum.getName(actionButton) || '행동유도문구'}
        </span>
      </div>
    )
  }

export const TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9 =
  ({
    title,
    description,
    actionButton,
    profileName,
    profileImageURL,
    promotionalImageURL,
    isInAd,
  }) => {
    return (
      <div className="material_default material_img2">
        <div className="profile_info">
          <span className="thumb_profile">
            {profileImageURL && (
              <span
                className="img_thumb"
                style={ScaleImageFitCenterStyle({
                  url: profileImageURL,
                  backgroundColor: '#ffffff',
                })}
              />
            )}
          </span>
          <span className="info_profile">
            <span className="inner_info">
              <strong
                className={cx('tit_profile', {
                  no_text: profileName?.trim()?.length === 0,
                })}>
                {profileName?.trim() ||
                  (isInAd ? '프로필 이름' : '미입력 요소')}
              </strong>
              <span className="txt_profile">Sponsored</span>
            </span>
          </span>
        </div>
        <p
          className={cx('txt_induce', {
            no_text: description?.trim()?.length === 0,
          })}>
          {description?.trim() || '홍보문구'}
        </p>
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <div className="box_induce">
          <strong
            className={cx('tit_induce2', {
              no_text: title?.trim()?.length === 0,
            })}>
            <span className="inner_tit">
              {title?.trim() || (isInAd ? '타이틀' : '미입력 요소')}
            </span>
          </strong>
          <span
            className={cx('btn_induce2', {
              no_text: actionButton === ActionButtonEnum.Type.NONE,
            })}>
            {ActionButtonEnum.getName(actionButton) ||
              (isInAd ? '행동유도문구' : '미입력 요소')}
          </span>
        </div>
      </div>
    )
  }

export const LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10 = ({
  title,
  actionButton,
  profileName,
  profileImageURL,
}) => {
  return (
    <div className="material_default material_thumb3">
      <div className="profile_info">
        <span className="thumb_profile">
          {profileImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: profileImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </span>
        <span className="info_profile">
          <span className="inner_info">
            <strong
              className={cx('tit_profile', {
                no_text: profileName?.trim()?.length === 0,
              })}>
              {profileName?.trim() || '프로필 이름'}
            </strong>
            <strong
              className={cx('tit_induce', {
                no_text: title?.trim()?.length === 0,
              })}>
              {title?.trim() || '타이틀'}
            </strong>
          </span>
        </span>
        <span
          className={cx('btn_induce2', {
            no_text: actionButton === ActionButtonEnum.Type.NONE,
          })}>
          {ActionButtonEnum.getName(actionButton) || '행동유도문구'}
        </span>
      </div>
    </div>
  )
}

export const TopImage_BottomVerticalProfileImageAndNameActionButton_2_1_Page11 =
  ({
    actionButton,
    profileName,
    profileImageURL,
    promotionalImageURL,
    isInAd,
  }) => {
    return (
      <div className="material_default material_img5">
        <div className="thumb_img">
          {promotionalImageURL && (
            <span
              className="img_thumb"
              style={ScaleImageFitCenterStyle({
                url: promotionalImageURL,
                backgroundColor: '#ffffff',
              })}
            />
          )}
        </div>
        <div className="profile_info">
          <span className="thumb_profile">
            {profileImageURL && (
              <span
                className="img_thumb"
                style={ScaleImageFitCenterStyle({
                  url: profileImageURL,
                  backgroundColor: '#ffffff',
                })}
              />
            )}
          </span>
          <span className="info_profile">
            <span className="inner_info">
              <strong
                className={cx('tit_profile', {
                  no_text: profileName?.trim()?.length === 0,
                })}>
                {profileName?.trim() ||
                  (isInAd ? '프로필 이름' : '미입력 요소')}
              </strong>
              <span className="txt_profile">Sponsored</span>
            </span>
          </span>
        </div>
        <div className="box_induce">
          <span
            className={cx('btn_induce2', {
              no_text: actionButton === ActionButtonEnum.Type.NONE,
            })}>
            {ActionButtonEnum.getName(actionButton) ||
              (isInAd ? '행동유도문구' : '미입력 요소')}
          </span>
        </div>
      </div>
    )
  }

export const TopImage_BottomTitle_2_1_Page12 = ({
  title,
  promotionalImageURL,
}) => {
  return (
    <div className="material_default material_thumb4">
      <div className="thumb_img">
        {promotionalImageURL && (
          <span
            className="img_thumb"
            style={ScaleImageFitCenterStyle({
              url: promotionalImageURL,
              backgroundColor: '#ffffff',
            })}
          />
        )}
      </div>
      <strong
        className={cx('tit_induce', {
          no_text: title?.trim()?.length === 0,
        })}>
        {title?.trim() || '타이틀'}
      </strong>
    </div>
  )
}

export const NAS_2_1_Page13 = ({
  promotionalImageURL,
  title,
  profileName,
  description,
}) => {
  return (
    <div className={cx('material_default', 'material_nas')}>
      <div className="inner_nas">
        <div className="thumb_img">
          {promotionalImageURL && (
            <div
              className={'item_bg'}
              style={{
                background: `url(${promotionalImageURL}) 50% 50%`,
              }}></div>
          )}
          <div className="item_thumb">
            {promotionalImageURL && (
              <img
                src={promotionalImageURL}
                width={`160`}
                height={`80`}
                className={'img_thumb'}
                alt={'홍보 이미지'}
              />
            )}
          </div>
        </div>
        <div className="thumb_detail">
          <span className="info_profile">
            <span className="inner_info">
              <strong
                className={cx('tit_profile', {
                  no_text: checkEmpty(profileName),
                })}>
                {profileName || '프로필 이름'}
              </strong>
            </span>{' '}
          </span>
          <strong className={cx('tit_induce', { no_text: checkEmpty(title) })}>
            {title || '타이틀'}
          </strong>
          <span
            className={cx('txt_induce', { no_text: checkEmpty(description) })}>
            {description || '홍보문구'}
          </span>
          <span className="ad_mark">AD</span>
        </div>
      </div>
    </div>
  )
}

BgImage_BottomHorizontalTitleActionButton_2_1_Page1.propTypes =
  ImageNativeTemplatePropTypes.Page
TopImage_BottomVerticalTitleProfileName_2_1_Page2.propTypes =
  ImageNativeTemplatePropTypes.Page
CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4.propTypes =
  ImageNativeTemplatePropTypes.Page
LeftImage_RightVerticalTitleProfileName_2_1_Page5.propTypes =
  ImageNativeTemplatePropTypes.Page
BgImage_2_1_Page6.propTypes = ImageNativeTemplatePropTypes.Page
TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page7.propTypes =
  ImageNativeTemplatePropTypes.Page
TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9.propTypes =
  ImageNativeTemplatePropTypes.Page
LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10.propTypes =
  ImageNativeTemplatePropTypes.Page
TopImage_BottomVerticalProfileImageAndNameActionButton_2_1_Page11.propTypes =
  ImageNativeTemplatePropTypes.Page
TopImage_BottomTitle_2_1_Page12.propTypes = ImageNativeTemplatePropTypes.Page
NAS_2_1_Page13.propTypes = ImageNativeTemplatePropTypes.Page
