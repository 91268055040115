import { checkNotEmpty } from './regexUtils'

const InputFileUtil = {
  /**
   *
   * @param files {FileList}
   * @return {boolean}
   */
  isValid(files) {
    return (
      files?.length >= 1 &&
      Array.from(files).every(file => checkNotEmpty(file.type))
    )
  },

  /**
   *
   * @param files {FileList}
   * @param max {number}
   * @return {boolean}
   */
  isValidCount(files, max) {
    return this.isValid(files) && files.length <= max
  },

  /**
   *
   * ex:
   *  - image: /image\/+/
   *  - video: /video\/+/
   * @param files {FileList}
   * @param regex {RegExp | string}
   * @return {boolean}
   */
  isValidType(files, regex) {
    return (
      this.isValid(files) &&
      Array.from(files).every(file => regex.test(file.type))
    )
  },

  /**
   *
   *
   * @param files {FileList}
   * @param regexArray {RegExp[] | string[]}
   * @return {boolean}
   */
  isValidAnyType(files, regexArray) {
    return (
      this.isValid(files) &&
      Array.from(files).every(file =>
        regexArray.some(regex => regex.test(file.type))
      )
    )
  },

  /**
   *
   * @param files {FileList}
   * @param regex {RegExp | string}
   * @return {boolean}
   */
  isNotInvalidType(files, regex) {
    return (
      this.isValid(files) &&
      Array.from(files).every(file => !regex.test(file.type))
    )
  },

  /**
   *
   * @param files {FileList}
   * @return {boolean}
   */
  isValidImageType(files) {
    return this.isValidType(files, /image\/+/)
  },

  /**
   *
   * @param files {FileList}
   * @return {boolean}
   */
  isValidVideoType(files) {
    return this.isValidType(files, /video\/+/)
  },

  /**
   *
   * @param files {FileList}
   * @param maximum {number}
   * @return {boolean}
   */
  isValidSize(files, maximum = Number.MAX_SAFE_INTEGER) {
    return (
      this.isValid(files) &&
      Array.from(files).every(file => file.size > 0 && file.size <= maximum)
    )
  },

  /**
   * @param files {FileList}
   * @return {boolean}
   */
  isValidName(files) {
    return (
      this.isValid(files) &&
      Array.from(files).every(file => checkNotEmpty(file.name))
    )
  },
}

export { InputFileUtil }
