import React from 'react'
import PropTypes from 'prop-types'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import { CmpAssetLibraryUtils } from './cmpAssetLibraryUtils'
import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'

const CmpAssetLibraryGuide = ({
  campaignType,
  creativeFormat,
  creativeUploadAssetPropertyType,
  uploadGuideTooltip,
  isVisibleCatalogProductGuide = false,
  isVisibleEditorGuide = false,
  isMotionBoard = false,
  isCustomBoard = false,
}) => {
  const reviewGuideUrl = CmpAssetLibraryUtils.reviewGuideUrl({
    campaignType,
    creativeFormat,
    creativeUploadAssetPropertyType,
    isMotionBoard,
    isCustomBoard,
  })

  return (
    <>
      {isVisibleEditorGuide && (
        <div className="box_info2">
          <span className="ico_comm ico_info4" />
          이미지 에디터에 저장된 이미지를 불러옵니다. 이미지를 수정하거나{' '}
          <em className="fw_bold">+ 이미지 만들기</em>를 통하여 새로운 이미지를
          만들 수 있습니다.
        </div>
      )}
      {CreativeFormatEnum.isMessage(creativeFormat) &&
        creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.VIDEO && (
          <div className="box_info2">
            <span className="ico_comm ico_info4" />
            영상은 카카오TV ‘카카오 광고’ 채널에 업로드됩니다.
          </div>
        )}
      <div className="guide_section">
        {uploadGuideTooltip && (
          <div className="guide_item">
            <DynamicTooltip content={uploadGuideTooltip}>
              <a className="link_guide">소재 등록 가이드</a>
            </DynamicTooltip>
          </div>
        )}
        {reviewGuideUrl && (
          <div className="guide_item">
            <a
              className="link_guide link_emph"
              target="_blank"
              rel="noopener noreferrer"
              href={reviewGuideUrl}>
              심사 가이드
              <span className="ico_comm ico_out" />
            </a>
          </div>
        )}
        {isVisibleCatalogProductGuide && (
          <div className="guide_item">
            <DynamicTooltip
              content={
                <>
                  <strong>카탈로그란?</strong>
                  <br />
                  카탈로그는 내 픽셀 & SDK로 수집한 상품정보를 담는 묶음입니다.
                  상품을 소재 요소로 설정 시 소재와 상품이 자동 연동되며, 손쉽게
                  소재로 활용할 수 있습니다. 단, 연동된 경우 소재의 랜딩URL은
                  수정이 불가하며, 추후 상품단위 보고서가 제공될 예정입니다.
                  <br />
                  <br />
                  <strong>상품 정보 제공 방식</strong>
                  <br />
                  광고계정에 권한이 부여된 픽셀 & SDK의
                  콘텐츠/상품조회(ViewContent) 이벤트로 상품정보를 수집합니다.
                  그 중 필수정보 유무, 소재 사이즈 등 제약조건에 맞는 상품만
                  제공합니다.
                </>
              }>
              <a className="link_guide">내 상점 연동 알아보기</a>
            </DynamicTooltip>
          </div>
        )}
      </div>
    </>
  )
}

CmpAssetLibraryGuide.propTypes = {
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  uploadGuideTooltip: PropTypes.any,
  isVisibleCatalogProductGuide: PropTypes.bool,
  isVisibleEditorGuide: PropTypes.bool,
  isMotionBoard: PropTypes.bool,
  isCustomBoard: PropTypes.bool,
}

export default CmpAssetLibraryGuide
