import { combineReducers } from 'redux-immutablejs'
import reviewDocumentCreate from './mReviewDocumentCreate'
import reviewDocumentList from './mReviewDocumentList'
import reviewDocumentDetail from './mReviewDocumentDetail'
import { showErrorMessage } from '../../../utils/alertUtils'
import { keyMirror } from '../../../utils/utils'
import { ERROR_CODE } from '../../../utils/errorCode'

export const REVIEW_DOCUMENT_ERROR_TYPE = keyMirror({
  UPLOAD_FAIL: null,
  FILE_DELETE_FAIL: null,
  CATEGORY_DELETE_FAIL: null,
})

export const REVIEW_DOCUMENT_ERROR_DESCRIPTION = {
  [REVIEW_DOCUMENT_ERROR_TYPE.UPLOAD_FAIL]:
    '일시적인 오류로 파일 업로드를 진행할 수 없습니다. 잠시 후 다시 시도하세요.',
  [REVIEW_DOCUMENT_ERROR_TYPE.FILE_DELETE_FAIL]: '파일을 삭제할 수 없습니다.',
  [REVIEW_DOCUMENT_ERROR_TYPE.CATEGORY_DELETE_FAIL]:
    '업종을 삭제할 수 없습니다.',
}

export const getReviewDocumentErrorMessage = (e, errorType) => {
  return (
    e?.response?.data?.message ||
    REVIEW_DOCUMENT_ERROR_DESCRIPTION[errorType] ||
    '일시적인 오류 입니다.'
  )
}

export const handleReviewDocumentException = (e, errorType) => {
  const errorCode = Number(e?.response?.data?.errorCode)
  switch (errorCode) {
    case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
      break
    default: {
      const message = getReviewDocumentErrorMessage(e, errorType)
      showErrorMessage(message)
    }
  }
}

export default combineReducers({
  reviewDocumentCreate,
  reviewDocumentList,
  reviewDocumentDetail,
})
