import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'
import ImageEditorUtils from '../ImageEditor/imageEditorUtils'
import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import { v4 as uuid } from 'uuid'
import ImageEditorGuideTooltip from '../ImageEditor/ImageEditorGuideTooltip'

const CreativeLibraryImageEditButton = ({ index, onClick, items = List() }) => {
  const useGuide = index === 0
  const targetId = useGuide ? uuid() : undefined

  return (
    <>
      <button
        id={targetId}
        style={{
          zIndex: '12',
        }}
        type="button"
        className="btn_imgedit"
        onClick={e => {
          e.stopPropagation()

          onClick()
        }}>
        <span className="ico_comm">이미지 편집</span>{' '}
        {items.count() > 0 && (
          <DynamicTooltip
            styles={{
              padding: 'none',
            }}
            content={
              <div
                className="tooltip_basic show place-top ly_tooltip"
                onClick={e => e.stopPropagation()}>
                <strong className="reform_tit_filesize">
                  편집된 이미지 비율
                </strong>
                <ul className="reform_list_filesize">
                  {items.map(({ editedImageAspect: aspect }) => {
                    return (
                      <li key={`editedImageAspect|${aspect}`}>
                        {`${ImageEditorUtils.getCropAspectString({
                          aspect,
                        })} (${ImageEditorUtils.getEditedImageSize({
                          aspect,
                        })})`}
                      </li>
                    )
                  })}
                </ul>
              </div>
            }>
            <span className="num_imgedit">
              <span className="ico_comm">편집완료</span>
            </span>
          </DynamicTooltip>
        )}
      </button>
      {useGuide && <ImageEditorGuideTooltip targetId={targetId} />}
    </>
  )
}

CreativeLibraryImageEditButton.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  items: ImmutablePropTypes.list,
}

export default React.memo(CreativeLibraryImageEditButton)
