import { distinct } from '../utils'
import { fromJS } from 'immutable'
import DashboardMetricGroupEnumV2 from '../../enums/DashboardMetricGroupEnumV2'
import { DashboardTableMetric } from '../dashboard/dashboardTableMetric'
import { ConversionCriteriaKey } from '../../components/Popup/Metric/ChangeConversionCriteriaPopup'

const DashboardSettingsHelper = {
  TableMetric: {
    getSelectedMetricsV2() {
      return distinct(
        this.getFixedMetricGroupsV2()
          .filter(v => v.get('isSelected'))
          .flatMap(v => v.get('metrics')),
        v => v.get('id')
      )
    },

    getFixedMetricGroupsV2() {
      const items = DashboardMetricGroupEnumV2.values()
      const defaults = DashboardMetricGroupEnumV2.getDefaults()
      return fromJS(
        items.map(group => {
          return {
            id: group,
            name: DashboardMetricGroupEnumV2.getName(group),
            metrics: DashboardTableMetric[group],
            isSelected: defaults.includes(group),
          }
        })
      )
    },

    getFixedMetricGroupsV2ByConversionCriteria(criteria) {
      const items = DashboardMetricGroupEnumV2.values()
      const defaults = DashboardMetricGroupEnumV2.getDefaults()
      return fromJS(
        items.map(group => {
          return {
            id: group,
            name: DashboardMetricGroupEnumV2.getName(group),
            metrics: DashboardMetricGroupEnumV2.isRelatedToConversion(group)
              ? DashboardTableMetric[group].filter(item =>
                  item.id.includes(ConversionCriteriaKey[criteria])
                )
              : DashboardTableMetric[group],
            isSelected: defaults.includes(group),
          }
        })
      )
    },
  },
}

export { DashboardSettingsHelper }
