import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    NONE: null,
    READY: null,
    LIVE: null,
    FINISH: null,
  }),

  Name: {
    NONE: '시간 미입력',
    READY: '집행 예정',
    LIVE: '집행 중',
    FINISH: '집행 종료',
  },

  getName(type) {
    return this.Name[type]
  },

  isAlive(status) {
    return status === this.Type.LIVE
  },

  isFinished(status) {
    return status === this.Type.FINISH
  },
}
