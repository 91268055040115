import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ACTIVE: null,
    DORMANCY: null,
    WITHDRAWAL: null,
  }),

  Name: {
    ACTIVE: '',
    DORMANCY: '휴면',
    WITHDRAWAL: '탈퇴',
  },

  getName(type) {
    return this.Name[type]
  },
}
