/***
 * customerFile 관련 API
 */

import { CONTEXTPATH, DOMINO, fetchGet } from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'
import cruxFetchUtils from '../../utils/cruxFetchUtils'
import { queryString } from '../../utils/utils'
import { checkNotEmpty } from '../../utils/regexUtils'

/**
 * 7.5.1 ADID 업로드
 * @param adAccountId
 * @param formData
 */
function submitCustomerFilesUpload(adAccountId, formData) {
  return cruxFetchUtils.post({
    url: `${DOMINO}/${adAccountId}/customerFiles/upload`,
    requestPayload: formData,
    adAccountId,
  })
}

/**
 * 7.5.2 고객파일 등록
 * @param adAccountId
 * @param formData
 */
function submitCustomerFilesUploadForm(adAccountId, formData) {
  return RequestLock.acquire({
    key: 'createCustomerFile',
    executor: done =>
      cruxFetchUtils
        .post({
          url: `${DOMINO}/${adAccountId}/customerFiles`,
          requestPayload: formData,
          adAccountId,
        })
        .finally(() => {
          done()
        }),
  })
}

/**
 * 7.5.3 고객파일 삭제
 * @param adAccountId
 * @param customerFileId
 */
function deleteCustomerFiles(adAccountId, customerFileId) {
  return cruxFetchUtils.delete({
    url: `${DOMINO}/${adAccountId}/customerFiles/${customerFileId}`,
    adAccountId,
  })
}

/**
 *7.5.3.1 다수의 고객파일 삭제
 * @param adAccountId
 * @param customerFileIds
 */

function deleteMultipleCustomerFiles(adAccountId, customerFileIds) {
  return RequestLock.acquire({
    key: 'deleteMultipleCustomerFiles',
    executor: done =>
      cruxFetchUtils
        .delete({
          url: `${DOMINO}/${adAccountId}/customerFiles?${queryString({
            customerFileIds,
          })}`,
          adAccountId,
        })
        .finally(() => {
          done()
        }),
  })
}

/**
 * 7.5.4 고객파일 상세 조회
 * @param adAccountId
 * @param customerFileId
 */
function getCustomerFilesDetail(adAccountId, customerFileId) {
  return cruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/customerFiles/${customerFileId}`,
    adAccountId,
  })
}

/**
 * 7.5.5 고객파일 목록 조회
 * @param adAccountId
 * @param currentPage
 * @param size
 */
function getCustomerFiles(adAccountId, currentPage = 0, size = 10) {
  return cruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/customerFiles?page=${currentPage}&size=${size}`,
    adAccountId,
  })
}

/**
 * 7.5.6 고객파일 이름 수정
 * @param adAccountId
 * @param form
 * @param customerFileId
 * PUT  /adAccounts/{adAccountId}/customerFiles/{customerFileId}
 */
function modifyCustomerFilesName(adAccountId, form, customerFileId) {
  return RequestLock.acquire({
    key: 'modifyCustomerFilesName',
    executor: done =>
      cruxFetchUtils
        .put({
          url: `${DOMINO}/${adAccountId}/customerFiles/${customerFileId}/name`,
          requestPayload: form,
          adAccountId,
        })
        .finally(done),
  })
}

/**
 * 7.5.8 고객파일을 사용 중인 광고그룹 조회
 * @param adAccountId
 * @param customerFileId
 * @param searchOptions
 * @return {*}
 */
function getCustomerFilesDetailAdGroupList(
  adAccountId,
  customerFileId,
  searchOptions
) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/customerFiles/${customerFileId}/adGroups?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 7.5.11 고객파일 수정
 */
function submitModifyCustomerFilesUploadForm(
  adAccountId,
  customerFileId,
  formData
) {
  return RequestLock.acquire({
    key: 'modifyCustomerFile',
    executor: done =>
      cruxFetchUtils
        .put({
          url: `${DOMINO}/${adAccountId}/customerFiles/${customerFileId}`,
          requestPayload: formData,
          adAccountId,
        })
        .finally(() => {
          done()
        }),
  })
}

/**
 * 7.5.2.1 고객파일 등록 - URL 타입
 */
function createCustomerFilesUrlUpload(adAccountId, formData) {
  return RequestLock.acquire({
    key: '',
    executor: done =>
      cruxFetchUtils
        .post({
          url: `${DOMINO}/${adAccountId}/customerFiles/url`,
          requestPayload: formData,
          adAccountId,
        })
        .finally(done),
  })
}

/**
 * 7.5.11.2 고객파일 수정 (url 타입)
 */
function modifyCustomerFilesUrlUpload(adAccountId, customerFileId, formData) {
  return RequestLock.acquire({
    key: '',
    executor: done =>
      cruxFetchUtils
        .put({
          url: `${DOMINO}/${adAccountId}/customerFiles/${customerFileId}/url`,
          requestPayload: formData,
          adAccountId,
        })
        .finally(done),
  })
}

/**
 * 고객 파일 업로드
 */
function uploadCustomerFile(adAccountId, path, data, originalFileName) {
  const params = checkNotEmpty(originalFileName)
    ? { path, filename: encodeURIComponent(originalFileName) }
    : { path }

  return cruxFetchUtils.post({
    url: `${DOMINO}/upload/secure?${queryString(params)}`,
    requestPayload: data,
    adAccountId,
  })
}

export default {
  submitCustomerFilesUpload,
  submitCustomerFilesUploadForm,
  deleteCustomerFiles,
  deleteMultipleCustomerFiles,
  getCustomerFilesDetail,
  getCustomerFiles,
  modifyCustomerFilesName,
  getCustomerFilesDetailAdGroupList,
  submitModifyCustomerFilesUploadForm,
  createCustomerFilesUrlUpload,
  modifyCustomerFilesUrlUpload,
  uploadCustomerFile,
}
