import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkEmpty } from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import { adViewButtonValidation } from '../../utils/adView/adViewV2'
import {
  validateAdViewBrandName,
  validateAdViewName,
  validateAdViewTitle,
} from './adViewFormValidator'

export const COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2 = keyMirror({
  NAME: null,
  BRAND_NAME: null,
  TITLE: null,
  BUTTON1: null,
  BUTTON2: null,
  CONTENTS: null,
})

export const COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_PATH_V2 = Map({
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.NAME]: ['name'],
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BRAND_NAME]: ['brandName'],
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.TITLE]: ['title'],
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BUTTON1]: ['button1'],
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BUTTON2]: ['button2'],
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.CONTENTS]: ['contents'],
})

export const COMPACT_VIEW_IMAGE_FORM_VALIDATOR_V2 = Map({
  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.NAME]: name => {
    return validateAdViewName(name)
  },

  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BRAND_NAME]: brandName => {
    return validateAdViewBrandName(brandName)
  },

  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.TITLE]: title => {
    return validateAdViewTitle(title)
  },

  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.CONTENTS]: contents => {
    if (checkEmpty(contents) || contents.isEmpty()) {
      return IS_NOT_VALID('홍보이미지를 업로드하세요.')
    }

    return IS_VALID()
  },

  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BUTTON1]: button1 => {
    return adViewButtonValidation('button1', button1)
  },

  [COMPACT_VIEW_IMAGE_FORM_VALIDATION_KEY_V2.BUTTON2]: button2 => {
    return adViewButtonValidation('button2', button2)
  },
})
