import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    PageView: null,
    AppLaunch: null,
    CompleteRegistration: null,
    Search: null,
    ViewContent: null,
    ViewCart: null,
    Purchase: null,
    Participation: null,
    SignUp: null,
    AddToCart: null,
    AddToWishList: null,
    AppInstall: null,
  }),

  Name: {
    PageView: '방문',
    AppLaunch: '앱 실행',
    CompleteRegistration: '회원가입',
    Search: '검색',
    ViewContent: '콘텐츠/상품 조회',
    ViewCart: '장바구니 보기',
    Purchase: '구매',
    Participation: '잠재고객',
    SignUp: '서비스신청',
    AddToCart: '장바구니 추가',
    AddToWishList: '관심상품 추가',
    AppInstall: '설치:마켓제공값',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  campaignObjectiveDetailTypeEventValues() {
    return this.values().filter(v =>
      [
        this.Type.Purchase,
        this.Type.AppInstall,
        this.Type.CompleteRegistration,
        this.Type.Participation,
        this.Type.SignUp,
        this.Type.ViewCart,
      ].includes(v)
    )
  },

  // 광고그룹 > 상품 반응 이벤트 목록
  catalogProductEventValues() {
    return [this.Type.AddToCart, this.Type.AddToWishList, this.Type.ViewContent]
  },

  getName(type) {
    return this.Name[type]
  },
}
