import { keyMirror } from '../../../utils/utils'

const LNB_MENU = keyMirror({
  DASHBOARD: null,
  CREATE_ADS: null,
  REPORT: null,
  AD_ASSET: null,
  TARGET: null,
  SETTING: null,

  // -- sub
  // ad asset
  MESSAGE: null,
  AD_VIEW: null,
  TALK_BIZ_FORM: null,
  PIXEL_AND_SDK: null,
  // target
  AUDIENCE: null,
  COHORT: null,
  CUSTOMER_FILE: null,
  PF_GROUP: null,
  // setting
  AD_ACCOUNT: null,
  REVIEW: null,
  CASH: null,
  PAYMENT_CARD: null,
  CHANGE_LOG: null,
})

const LnbMenuUtils = {
  Model: {
    [LNB_MENU.DASHBOARD]: {
      name: '대시보드',
      className: 'ico_snb1',
      getPathFn: ({ adAccountId }) => `/dashboard/${adAccountId}`,
    },
    [LNB_MENU.CREATE_ADS]: {
      name: '광고 만들기',
      className: 'ico_snb2',
      getPathFn: ({ adAccountId }) => `/ad/${adAccountId}`,
    },
    [LNB_MENU.REPORT]: {
      name: '보고서',
      className: 'ico_snb3',
      getPathFn: ({ adAccountId }) => `/${adAccountId}/report`,
      getActivePathFn: ({ adAccountId }) => `/${adAccountId}/report`,
    },
    [LNB_MENU.AD_ASSET]: {
      name: '광고자산 관리',
      className: 'ico_snb4',
      getPathFn: undefined,
    },
    [LNB_MENU.TARGET]: {
      name: '타겟 관리',
      className: 'ico_snb5',
      getPathFn: undefined,
    },
    [LNB_MENU.SETTING]: {
      name: '설정',
      className: 'ico_snb6',
      getPathFn: undefined,
    },
    // ----------------
    // ad asset
    [LNB_MENU.MESSAGE]: {
      name: '메시지 관리',
      parent: LNB_MENU.AD_ASSET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/message`,
    },
    [LNB_MENU.AD_VIEW]: {
      name: '애드뷰 관리',
      parent: LNB_MENU.AD_ASSET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/adview`,
    },
    [LNB_MENU.TALK_BIZ_FORM]: {
      name: '비즈니스폼 연동 관리',
      parent: LNB_MENU.AD_ASSET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/linkage/talkbizform`,
    },
    [LNB_MENU.PIXEL_AND_SDK]: {
      name: '픽셀 & SDK 연동 관리',
      parent: LNB_MENU.AD_ASSET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/linkage/pixel`,
    },
    // target
    [LNB_MENU.AUDIENCE]: {
      name: '오디언스 관리',
      parent: LNB_MENU.TARGET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/targeting/audience`,
    },
    [LNB_MENU.COHORT]: {
      name: '광고반응타겟 관리',
      parent: LNB_MENU.TARGET,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/targeting/cohort`,
    },
    [LNB_MENU.CUSTOMER_FILE]: {
      name: '고객파일 관리',
      parent: LNB_MENU.TARGET,
      getPathFn: ({ adAccountId }) =>
        `/${adAccountId}/targeting/customerfilesupload`,
    },
    [LNB_MENU.PF_GROUP]: {
      name: '친구그룹 관리',
      parent: LNB_MENU.TARGET,
      getPathFn: ({ adAccountId }) =>
        `/${adAccountId}/targeting/plusfriendgroup`,
    },
    // setting
    [LNB_MENU.AD_ACCOUNT]: {
      name: '광고계정 관리',
      parent: LNB_MENU.SETTING,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/settings/account`,
    },
    [LNB_MENU.REVIEW]: {
      name: '심사서류 관리',
      parent: LNB_MENU.SETTING,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/settings/review`,
    },
    [LNB_MENU.CASH]: {
      name: '광고캐시 관리',
      parent: LNB_MENU.SETTING,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/settings/cash`,
    },
    [LNB_MENU.PAYMENT_CARD]: {
      name: '결제카드 관리',
      parent: LNB_MENU.SETTING,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/settings/paymentcard`,
    },
    [LNB_MENU.CHANGE_LOG]: {
      name: '변경이력 관리',
      parent: LNB_MENU.SETTING,
      getPathFn: ({ adAccountId }) => `/${adAccountId}/settings/editlog`,
    },
  },
}

export { LNB_MENU, LnbMenuUtils }
