import React from 'react'
import { isChrome, isEdge } from 'react-device-detect'

const BrowserWarningRibbon = () => {
  const [isVisible, setIsVisible] = React.useState(!(isChrome || isEdge))

  if (!isVisible) return null

  return (
    <div className="guide_msg3">
      <strong className="screen_out">최적화 브라우저 안내</strong>
      <p className="desc_msg">
        카카오모먼트는 <em className="fs_line">크롬(Chrome) 및 엣지(Edge)</em>{' '}
        브라우저에 최적화되어 있어 현재 브라우저에서는 정상적으로 동작하지 않을
        수 있습니다.
      </p>
      <a className="link_close" onClick={() => setIsVisible(false)}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

export default BrowserWarningRibbon
