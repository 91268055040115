import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch, useParams } from 'react-router-dom'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  batchAdvertiseOnMount,
  batchAdvertiseOnUnmount,
} from '../../modules-batch/batch-advertiseV2'
import { AdvertiseRouterV2 } from '../../utils/advertise/advertiseRouterV2'
import { LOADING_KEY } from '../../modules/common/mLoading'
import { isUndefinedOrNull } from '../../utils/regexUtils'
import Footer from '../Footer/Footer'
import Gnb from '../Navigation/Gnb/Gnb'
import Lnb from '../Navigation/Lnb/Lnb'
import useLnbState from '../Navigation/Lnb/useLnbState'
import BasicLoader from '../Common/Loader/BasicLoader'

const CampaignAndGroupPageV2 = React.lazy(() =>
  import('./CampaignAndGroupPageV2')
)
const CreativePageV2 = React.lazy(() => import('./CreativePageV2'))

const selector = ({
  loading,
  advertiseV2: {
    adAccountInfo: { id },
  },
}) => {
  return {
    isLoading:
      loading.has(LOADING_KEY.SETUP_AD_BASE) ||
      isUndefinedOrNull(id) ||
      id === -1,
  }
}

const AdWrapper = () => {
  const dispatch = useDispatch()

  const { isLoading } = useSelector(selector, shallowEqual)

  const adAccountId = useParams().adaccountid

  React.useEffect(() => {
    dispatch(batchAdvertiseOnMount(adAccountId))

    return () => {
      dispatch(batchAdvertiseOnUnmount())
    }
  }, [adAccountId, dispatch])

  const { lnbState } = useLnbState()

  return (
    <div id="kakaoWrap" className="reform_guide_show">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb state={lnbState} />
        {!isLoading && (
          <React.Suspense fallback={<BasicLoader />}>
            <Switch>
              <Route
                exact
                path={AdvertiseRouterV2.Matcher.getCampaignAndGroupPageV2()}
                component={CampaignAndGroupPageV2}
              />
              <Route
                exact
                path={AdvertiseRouterV2.Matcher.getCreativePageV2()}
                component={CreativePageV2}
              />
            </Switch>
          </React.Suspense>
        )}
        <div
          id={AdWrapper.sideBarContainerId}
          className="reform_m_aside"
          role="navigation"
        />
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

AdWrapper.propTypes = {
  location: ReactRouterPropTypes.location,
  match: ReactRouterPropTypes.match,
  history: ReactRouterPropTypes.history,
  isLoading: PropTypes.bool,
  batchAdvertiseOnMount: PropTypes.func,
  batchAdvertiseOnUnmount: PropTypes.func,
}

AdWrapper.sideBarContainerId = 'adSideBarContainer'

export default AdWrapper
