import { showErrorMessage } from '../../utils/alertUtils'
import { ERROR_CODE } from '../../utils/errorCode'

export function getDashboardExceptionMessage({ e, getExceptionDetailFn }) {
  const { errorCode, message, detail } = e?.response?.data || {}

  return getDashboardExceptionMessageByErrorCode({
    errorCode,
    defaultMessage: message,
    getExceptionDetailFn,
    detail,
  })
}

export function getDashboardExceptionMessageByErrorCode({
  errorCode,
  defaultMessage,
  getExceptionDetailFn,
  detail,
}) {
  switch (Number(errorCode)) {
    case 21006:
    case 31001:
    case 32001:
    case 38015:
    case 32026:
    case 33003:
    case 33131: {
      return '삭제되었거나 지원 종료된 광고입니다.'
    }

    case 86001: {
      return '카카오톡 채널 메시지 발송서버 연동에 실패하였습니다.'
    }

    case 21011: {
      return '광고계정 당 캠페인 수 제한으로 캠페인 만들기를 할 수 없습니다. 캠페인 삭제 후 다시 시도하세요.'
    }

    case 21018: {
      return '광고계정 당 광고그룹 수 제한으로 광고그룹 만들기를 할 수 없습니다. 기존 광고그룹 삭제 후 다시 시도하세요.'
    }

    case 28005:
    case 32040: {
      return '기간이 유효한 광고그룹이 있는 캠페인은 삭제할 수 없습니다. 하위 모든 광고그룹 집행기간이 종료되거나, 계약해지된 캠페인만 삭제할 수 있습니다.'
    }

    case 31026: {
      return '집행 시작일시가 지난 캠페인은 삭제할 수 없습니다.'
    }

    // 그룹 > 계약 해지
    case 32041: {
      return '집행 완료되었거나 이미 해지된 광고그룹입니다.'
    }

    // 그룹 > 계약 해지
    case 88008: {
      return '정산 시간에는 계약 해지를 진행할 수 없습니다. 1~2시간 후에 다시 시도하세요.'
    }

    case 33225: {
      return '입찰금액을 설정할 수 없는 광고그룹입니다.'
    }

    case 33233: {
      const { hasTalkChannelReach } = getExceptionDetailFn?.() || {}
      if (hasTalkChannelReach?.count() > 0) {
        return '집행 5분 전부터 집행 기간 내에는 소재를 삭제할 수 없습니다. 집행기간이 종료되거나 계약 해지된 이후 삭제할 수 있습니다.'
      } else {
        return '광고그룹 시작일이 지난 소재는 삭제할 수 없습니다. 광고그룹 집행기간이 종료되거나, 계약 해지 된 이후 삭제할 수 있습니다.'
      }
    }

    // 건스미스 + 소재최적화
    case 33246: {
      return '소재최적화 사용중인 광고그룹은 OFF 설정 후 소재 삭제가 가능합니다.'
    }

    case 33248: {
      return '집행 5분 전부터 집행 기간 내 광고그룹의 소재는 1개 이상이어야 합니다.'
    }

    case 33114: {
      return '재심사 요청이 불가능한 소재 상태입니다.'
    }

    case 33115: {
      return '재심사 요청이 불가능한 사유로 보류되었습니다.'
    }

    case 33241: {
      return '홍보문구/하단 홍보문구가 유효하지 않습니다.'
    }

    // 스폰서드 보드 복사
    case 84007: {
      return '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
    }

    case 51620: {
      return '카탈로그에 문제가 있어 오류가 발생했습니다. 카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
    }

    case 51621: {
      return '해당 카탈로그의 권한이 없습니다. 카탈로그 관리에서 내 카탈로그 정보를 확인하세요.'
    }

    case 39999: {
      if (Array.isArray(detail)) {
        const { errorCode, description = '' } = detail[0] || {}
        switch (errorCode) {
          case 30003:
            return '계약해지가 가능한 시점이 아닙니다.'
          default:
            return description
        }
      }
      break
    }

    default: {
      return defaultMessage
    }
  }
}

export function handleDashboardException({ e, defaultMessage }) {
  return () => {
    const { errorCode, message: errorMessage, detail } = e?.response?.data || {}
    switch (Number(errorCode)) {
      case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
        break
      default: {
        const message = getDashboardExceptionMessageByErrorCode({
          errorCode,
          defaultMessage: defaultMessage || errorMessage,
          detail,
        })
        if (message) {
          showErrorMessage(message)
        }
        break
      }
    }
  }
}
