import { keyMirror } from '../utils/utils'

const getSortedValue = (seq, map) => {
  return map.sort((a, b) => {
    const a1 = seq.indexOf(a)
    const a2 = seq.indexOf(b)
    return a1 > a2 ? 1 : -1
  })
}

export default {
  Type: keyMirror({
    15: null, // 15-19
    20: null, // 20-24
    25: null, // 25-29
    30: null, // 30-34
    35: null, // 35-39
    40: null, // 40-44
    45: null, // 45-49
    50: null, // 50-54
    55: null, // 55-59
    60: null, // 60-64
    65: null, // 65-69
    70: null, // 70-
  }),

  Name: {
    15: '15-19',
    20: '20-24',
    25: '25-29',
    30: '30-34',
    35: '35-39',
    40: '40-44',
    45: '45-49',
    50: '50-54',
    55: '55-59',
    60: '60-64',
    65: '65-69',
    70: '70이상',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getOrderedValues(ages) {
    return getSortedValue(this.values(), ages)
  },

  getAgesDetailOptionsValues(ages) {
    return ages?.size > 0
      ? this.getOrderedValues(ages)
          .map(v => this.getName(v))
          .join(', ')
      : '전체'
  },
}
