/***
 * company 관련 API (CompanyTypeEnum.js)
 * 사업자
 */

import CruxFetchUtils from '../../utils/cruxFetchUtils'
import { CRUX_ACCOUNT } from '../../utils/fetchUtils'

/**
 * 카비센 연동 사업자 번호 검색
 * @param bizNumber {string}
 * @returns {*}
 */
function fetchBizCenterBusinessNumber(bizNumber) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/companies/suggestByBizNumber?value=${bizNumber}`,
  })
}

/***
 * 6.02 사업자등록번호로 회사 조회
 * @param companyId {string}
 * @return {*}
 */

function getCompanyInfoByCompanyId(companyId) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/companies/${companyId}`,
  })
}

export default {
  fetchBizCenterBusinessNumber,
  getCompanyInfoByCompanyId,
}
