import React, { useRef } from 'react'
import { useIsMounted } from './useIsMounted'

/**
 * https://github.com/animate-css/animate.css
 */
const useAnimateCSS = (callback = {}) => {
  const ref = useRef()
  const isMounted = useIsMounted()
  const [isLoaded, setIsLoaded] = React.useState(false)
  const [isAnimStarted, setIsAnimStarted] = React.useState(false)
  const { onAnimStarted, onAnimEnded } = callback

  React.useLayoutEffect(() => {
    if (isLoaded && !isAnimStarted) {
      setTimeout(() => {
        if (isMounted.current) {
          setIsAnimStarted(true)

          if (typeof onAnimStarted === 'function') {
            onAnimStarted()
          }
        }
      }, 2000)

      const animEl = ref.current
      if (animEl) {
        const listener = () => {
          if (isMounted.current) {
            setIsLoaded(false)

            if (typeof onAnimEnded === 'function') {
              onAnimEnded()
            }
          }
          animEl.removeEventListener('animationend', listener)
        }
        animEl.addEventListener('animationend', listener)
      } else {
        if (isMounted.current) {
          setIsLoaded(false)
        }
      }
    }
  }, [isLoaded, isAnimStarted, isMounted, onAnimStarted, onAnimEnded])

  // onload callback for img DOM
  const onLoad = React.useCallback(() => {
    if (isMounted.current) {
      setIsLoaded(true)
      setIsAnimStarted(false)
    }
  }, [isMounted])

  return {
    ref,
    onLoad,
    isAnimStarted,
  }
}

export { useAnimateCSS }
