import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    MANUAL: null, // 기본입찰
    AUTOBID: null, // 자동입찰
    OPTIMIZATION: null, // 최적입찰
  }),

  isAdGroupAutoBid(type) {
    return [this.Type.AUTOBID, this.Type.OPTIMIZATION].includes(type)
  },
}
