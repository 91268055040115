import { CSSTransition } from 'react-transition-group'
import React from 'react'
import useDynamicTooltip from '../hooks/useDynamicTooltip'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
  DynamicTooltipCommonPropTypes,
  DynamicTooltipStyles,
} from '../dynamicTooltipUtils'
import useDynamicTooltipPosition from '../hooks/useDynamicTooltipPosition'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'

const EllipsisTooltip = ({
  content,
  //
  verticalPosition = DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM,
  horizontalPosition = DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.RIGHT,
  rootElementType = 'span',
  rootElementClassName = '',
  className,
  children,
  getContainerNode = ({ rootRef }) =>
    rootRef.current?.children?.[0] ?? rootRef.current,
  styles = {},
  onClick = e => {},
}) => {
  const rootRef = React.useRef()

  const {
    isVisible: _isVisible,
    onMouseEnter,
    onMouseLeave,
    isOverflow,
  } = useDynamicTooltip()

  const Element = rootElementType

  const isVisible = _isVisible && isOverflow

  const containerNode = getContainerNode({ rootRef })

  return (
    <Element
      ref={rootRef}
      className={rootElementClassName}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      {children}
      <CSSTransition in={isVisible} classNames="dynamic-tooltip" timeout={300}>
        <Portal>
          {isVisible && (
            <EllipsisTooltipContent
              content={content ?? children}
              containerNode={containerNode}
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              className={className}
              styles={styles}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          )}
        </Portal>
      </CSSTransition>
    </Element>
  )
}

EllipsisTooltip.propTypes = DynamicTooltipCommonPropTypes

const EllipsisTooltipContent = ({
  content,
  containerNode,
  verticalPosition,
  horizontalPosition,
  className,
  styles,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { tooltipRef } = useDynamicTooltipPosition({
    containerNode,
    verticalPosition,
    horizontalPosition,
  })

  return (
    <span
      ref={tooltipRef}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...DynamicTooltipStyles, ...styles }}
      onClick={e => {
        // 툴팁 컨텐츠의 클릭 이벤트 전파 차단.
        e.stopPropagation()
      }}>
      {content}
    </span>
  )
}

EllipsisTooltipContent.propTypes = {
  content: PropTypes.any,
  containerNode: PropTypes.any,
  verticalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_VERTICAL)
  ),
  horizontalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_HORIZONTAL)
  ),
  className: PropTypes.string,
  styles: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

const Portal = ({ children }) =>
  document.body ? ReactDOM.createPortal(children, document.body) : null

export default EllipsisTooltip
