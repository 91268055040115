import sessionStorage, {
  SESSION_KEY_OBSERVER_SERVICE_TYPE,
} from '../utils/storage/momentSessionStorage'
import { Link } from 'react-router-dom'
import React from 'react'
import { ApiPhase, getApiPhase } from '../utils/app/services/kakaoMoment'
import { browserName, fullBrowserVersion } from 'react-device-detect'

const commonStyles = {
  position: 'fixed',
  height: '16px',
  padding: '0 8px',
  borderRadius: '16px',
  fontSize: '11px',
  lineHeight: '16px',
  margin: '1px',
  border: '1px solid #37c333',
  color: '#37C333',
  zIndex: 99999,
  fontWeight: 500,
}

const ENV_NAME = {
  [ApiPhase.stage]: 'CBT',
  [ApiPhase.sandbox]: 'Sandbox',
  [ApiPhase.develop]: 'Dev',
  [ApiPhase.adhoc]: 'Adhoc',
}

const _AppEnvInfo = () => {
  const observerType = sessionStorage.getItem(SESSION_KEY_OBSERVER_SERVICE_TYPE)
  const observerMode = observerType === 'ADMIN'

  const commonProps = {
    className: 'user_access',
    style: { ...commonStyles, left: 0, top: 0 },
  }

  if (observerMode) {
    return <p {...commonProps}>통합어드민</p>
  }

  if (!__PRODUCTION__) {
    const description = ENV_NAME[getApiPhase()]

    if (__LOCAL__) {
      return (
        <Link to="/api" {...commonProps}>
          {description}
        </Link>
      )
    } else {
      return <p {...commonProps}>{description}</p>
    }
  }

  return null
}

const _AppCommitInfo = () => {
  const [isVisible, setIsVisible] = React.useState(true)

  return (
    isVisible && (
      <span
        onDoubleClick={() => setIsVisible(false)}
        style={{
          ...commonStyles,
          right: 0,
          bottom: 0,
        }}>
        v{__VERSION__} | Branch:{' '}
        {String(__GIT_BRANCH__).replace(/remotes\/origin\//, '')} | Last commit:{' '}
        {__GIT_LAST_COMMIT_DATETIME__} | {browserName} {fullBrowserVersion}
      </span>
    )
  )
}

const AppEnvInfo = React.memo(_AppEnvInfo)
const AppCommitInfo = React.memo(_AppCommitInfo)

export { AppEnvInfo, AppCommitInfo }
