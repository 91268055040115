import React from 'react'
import PropTypes from 'prop-types'
import Cropper from 'react-easy-crop'
import ImageEditorUtils from './imageEditorUtils'
import cx from 'classnames'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'

const CreativeLibraryImageCrop = ({
  creativeUploadAssetPropertyType,
  url,
  imageWidth,
  imageHeight,
  crop,
  zoom,
  aspect,
  containerHeight,
  imageEditHandler,
  isGuideMouseOver,
}) => {
  const cropArea = React.useMemo(() => {
    return { width: containerHeight * aspect, height: containerHeight }
  }, [aspect, containerHeight])

  const { mediaWidth, mediaHeight } = React.useMemo(
    () =>
      ImageEditorUtils.getMediaDimension({
        mediaAspect: imageWidth / imageHeight,
        cropAreaWidth: cropArea.width,
        cropAreaHeight: cropArea.height,
      }),
    [cropArea.height, cropArea.width, imageHeight, imageWidth]
  )

  const isExpandableImageAsset =
    creativeUploadAssetPropertyType ===
      CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_IMAGE ||
    creativeUploadAssetPropertyType ===
      CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_MULTI

  return (
    <div
      className={cx('wrap_edit', { over: isGuideMouseOver })}
      onDoubleClick={imageEditHandler.onReset}
      style={{
        height: containerHeight,
      }}>
      <Cropper
        image={url}
        crop={crop}
        zoom={zoom}
        aspect={aspect}
        minZoom={ImageEditorUtils.ZOOM.MIN}
        maxZoom={ImageEditorUtils.ZOOM.MAX}
        onCropChange={location =>
          imageEditHandler.onCropChange(location, aspect)
        }
        onCropComplete={imageEditHandler.onCropComplete}
        onZoomChange={imageEditHandler.onZoomChange}
        onInteractionStart={imageEditHandler.onRelease}
        style={{
          containerStyle: {},
          mediaStyle: {
            width: mediaWidth,
            height: mediaHeight,
            maxWidth: 'none',
            maxHeight: 'none',
          },
          cropAreaStyle: {
            width: cropArea.width,
            height: cropArea.height,
            borderStyle: 'solid',
            borderWidth: 2,
            borderColor: isGuideMouseOver ? '#326edc' : '#fff',
          },
        }}
      />
      {aspect === 2 && (
        <>
          {isExpandableImageAsset && <span className="ico_comm ico_close" />}
          <span
            className={cx('guide_boxedit', {
              guide_basic: isExpandableImageAsset,
            })}>
            <span className="line_g line_t" />
            <span className="line_g line_b" />
            <span className="line_g line_l" />
            <span className="line_g line_r" />
          </span>
        </>
      )}
    </div>
  )
}

CreativeLibraryImageCrop.propTypes = {
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ).isRequired,
  url: PropTypes.string,
  imageWidth: PropTypes.number,
  imageHeight: PropTypes.number,
  crop: PropTypes.object,
  zoom: PropTypes.number,
  aspect: PropTypes.number,
  containerHeight: PropTypes.number,
  imageEditHandler: PropTypes.object,
  isGuideMouseOver: PropTypes.bool,
}

export default CreativeLibraryImageCrop
