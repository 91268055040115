import { fetchGet, queryString } from '../../utils/fetchUtils'
import { checkNotEmpty } from '../../utils/regexUtils'

/**
 * 광고계정 하위 캠페인/그룹/소재 전체 카운트
 * {
 *   campaignCount: number,
 *   adGroupCount: number,
 *   creativeCount: number
 * }
 */
function getAdAccountOperationTotalSummary({ adAccountId }) {
  return fetchGet('/search/dashboard/adAccounts/countByAd', adAccountId)
}

/**
 * 소재 복사에서 복사 가능한 그룹 조회
 * 1. 광고계정이 사용할 수 있는 campaignType x goal 하위 그룹
 * 2. name 에 매칭된 그룹
 * 3. 메시지 그룹의 경우 종료나 취소가 아닌 것
 * [
 *  {
 *    id, name, userConfig, createdDate,lastModifiedDate,
 *    campaign: {
 *      id, name, userConfig, createdDate, lastModifiedDate, campaignTypeGoal: { campaignType, goal },
 *    }
 *  }
 * ]
 */
function getCopyableAdGroupsByName({
  adAccountId,
  name,
  creativeFormats,
  campaignTypes,
  cancelTokenSource,
}) {
  return fetchGet(
    `search/adGroups/copyableTarget?${queryString({
      creativeFormats,
      campaignTypes,
      name,
    })}`,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * string
 */
function getCampaignDashboardStatus({ adAccountId, campaignId }) {
  return fetchGet(
    `/search/dashboard/campaigns/${campaignId}/dashboardStatus`,
    adAccountId
  )
}

/**
 * string
 */
function getAdGroupDashboardStatus({ adAccountId, adGroupId }) {
  return fetchGet(
    `/search/dashboard/adGroups/${adGroupId}/dashboardStatus`,
    adAccountId
  )
}

const createSimpleSearchQueryString = ({ paramObject }) => {
  for (const key in paramObject) {
    if (Object.hasOwnProperty.call(paramObject, key)) {
      const value = paramObject[key]
      if (Array.isArray(value)) {
        paramObject[key] =
          value.filter(Boolean).length > 0
            ? encodeURIComponent(value.join(','))
            : undefined
      } else {
        paramObject[key] = checkNotEmpty(value)
          ? encodeURIComponent(value)
          : undefined
      }
    }
  }

  return queryString(paramObject)
}

function getSimpleCampaignsByProperties({
  adAccountId,
  campaignIds = [],
  campaignNames = [],
  campaignTypes = [],
  goals = [],
  userConfig = [],
}) {
  const paramObject = {
    id: campaignIds,
    name: campaignNames,
    campaignTypeGoal_campaignType: campaignTypes,
    campaignTypeGoal_goal: goals,
    userConfig,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/campaigns/properties', queryString].join('?'),
    adAccountId
  )
}

function getSimpleCampaignsPagingByProperties({
  adAccountId,
  campaignIds = [],
  campaignNames = [],
  campaignTypes = [],
  goals = [],
  userConfig = [],
  page = 0,
  size = 100,
}) {
  const paramObject = {
    id: campaignIds,
    name: campaignNames,
    campaignTypeGoal_campaignType: campaignTypes,
    campaignTypeGoal_goal: goals,
    userConfig,
    page,
    size,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/campaigns/properties/page', queryString].join('?'),
    adAccountId
  )
}

function getSimpleAdGroupsByProperties({
  adAccountId,
  campaignIds = [],
  campaignTypes = [],
  goals = [],
  adGroupIds = [],
  adGroupNames = [],
  adGroupTypes = [],
  userConfig = [],
  creatable = false, // 도달 목표의 집행종료, 계약해지 상태가 아닌 광고그룹 필터링.
}) {
  const paramObject = {
    id: adGroupIds,
    campaignId: campaignIds,
    campaign_campaignType: campaignTypes,
    campaign_goal: goals,
    name: adGroupNames,
    type: adGroupTypes,
    userConfig,
    creatable,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/adGroups/properties', queryString].join('?'),
    adAccountId
  )
}

function getSimpleAdGroupsPagingByProperties({
  adAccountId,
  campaignIds = [],
  campaignTypes = [],
  goals = [],
  adGroupIds = [],
  adGroupNames = [],
  adGroupTypes = [],
  userConfig = [],
  creatable = false,
  page = 0,
  size = 100,
}) {
  const paramObject = {
    id: adGroupIds,
    campaignId: campaignIds,
    campaign_campaignType: campaignTypes,
    campaign_goal: goals,
    name: adGroupNames,
    type: adGroupTypes,
    userConfig,
    creatable,
    page,
    size,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/adGroups/properties/page', queryString].join('?'),
    adAccountId
  )
}

function getSimpleCreativesByProperties({
  adAccountId,
  campaignIds = [],
  adGroupIds = [],
  creativeIds = [],
  creativeFormats = [],
  creativeReviewStatus = [],
  creativeNames = [],
  userConfig = [],
}) {
  const paramObject = {
    id: creativeIds,
    campaignId: campaignIds,
    adGroupId: adGroupIds,
    creativeFormat: creativeFormats,
    creativeReview_reviewStatus: creativeReviewStatus,
    name: creativeNames,
    userConfig,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/creatives/properties', queryString].join('?'),
    adAccountId
  )
}

function getSimpleCreativesPagingByProperties({
  adAccountId,
  campaignIds = [],
  adGroupIds = [],
  creativeIds = [],
  creativeFormats = [],
  creativeReviewStatus = [],
  creativeNames = [],
  userConfig = [],
  page = 0,
  size = 100,
}) {
  const paramObject = {
    id: creativeIds,
    campaignId: campaignIds,
    adGroupId: adGroupIds,
    creativeFormat: creativeFormats,
    creativeReview_reviewStatus: creativeReviewStatus,
    name: creativeNames,
    userConfig,
    page,
    size,
  }

  const queryString = createSimpleSearchQueryString({ paramObject })

  return fetchGet(
    ['/search/creatives/properties/page', queryString].join('?'),
    adAccountId
  )
}

export default {
  getAdAccountOperationTotalSummary,
  getCopyableAdGroupsByName,
  getCampaignDashboardStatus,
  getAdGroupDashboardStatus,
  getSimpleCampaignsByProperties,
  getSimpleCampaignsPagingByProperties,
  getSimpleAdGroupsByProperties,
  getSimpleAdGroupsPagingByProperties,
  getSimpleCreativesByProperties,
  getSimpleCreativesPagingByProperties,
}
