import { keyMirror, naturalComparator } from '../../../utils/utils'
import { List } from 'immutable'
import { DashboardTableMetricIds } from '../../../utils/dashboard/dashboardTableMetric'
import UserConfigEnum from '../../../enums/UserConfigEnum'
import SortEnum from '../../../enums/SortEnum'
import moment from 'moment'
import { Sort } from '../../../enums/CommonEnums'
import { DashboardFilterUtils } from '../DashboardHeader/DashboardFilter/dashboardFilterUtils'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'

const PADDING = 12 * 2

/**
 * 디자인 가이드 수치에서 마크업상 좌우 padding 이 추가로 더해지게 되므로 minus 처리한다.
 */
const COLUMN_SPEC = {
  WIDTH: {
    DEFAULT: 50,
    SELECTION: 50,
    NAME: 400 - PADDING,
    NAME_WITH_THUMBNAIL: 500 - PADDING,
    ON_OFF: 120 - PADDING,
    STATUS: 250 - PADDING,
    REVIEW_STATUS: 250 - PADDING,
    OPTIMIZATION_STATUS: 250 - PADDING,
    CAMPAIGN_TYPE_GOAL: 220 - PADDING,
    CAMPAIGN_NAME: 400 - PADDING,
    AD_GROUP_NAME: 400 - PADDING,
    BUDGET: 180 - PADDING,
    BID_AMOUNT: 180 - PADDING,
    SCHEDULE: 250 - PADDING,
    METRIC: 200 - PADDING,
  },
  MIN_WIDTH: {
    DEFAULT: 50,
    SELECTION: 50,
    NAME: 194 - PADDING,
    NAME_WITH_THUMBNAIL: 194 - PADDING,
    ON_OFF: 54 - PADDING,
    STATUS: 54 - PADDING,
    OPTIMIZATION_STATUS: 54 - PADDING,
    REVIEW_STATUS: 54 - PADDING,
    CAMPAIGN_TYPE_GOAL: 54 - PADDING,
    CAMPAIGN_NAME: 54 - PADDING,
    AD_GROUP_NAME: 54 - PADDING,
    BUDGET: 54 - PADDING,
    BID_AMOUNT: 54 - PADDING,
    SCHEDULE: 54 - PADDING,
    METRIC: 54 - PADDING,
  },
  MAX_WIDTH: {
    DEFAULT: 600,
    NAME: 750 - PADDING,
    NAME_WITH_THUMBNAIL: 750 - PADDING,
  },
  // primitive type
  ACCESSOR: {
    ID: '$id',
    LAST_MODIFIED_DATE: '$lastModifiedDate',
    SELECTION: '$selection',
    NAME: '$name',
    NAME_WITH_THUMBNAIL: '$name',
    ON_OFF: '$onOff',
    STATUS: '$status',
    OPTIMIZATION_STATUS: '$optimizationStatus',
    REVIEW_STATUS: '$reviewStatus',
    CAMPAIGN_TYPE_GOAL: '$campaignTypeGoal',
    CAMPAIGN_NAME: '$campaignName',
    AD_GROUP_NAME: '$adGroupName',
    BUDGET: '$budget',
    BID_AMOUNT: '$bidAmount',
    SCHEDULE: '$schedule',
    METRIC: metricCode => ['$indicatorMap', metricCode].join('|'),
  },

  SORT_KEY: {
    NAME: 'name',
    ID: 'id',
    LAST_MODIFIED_DATE: 'lastModifiedDate',
    ON_OFF: 'userConfig',
    STATUS: 'dashboardStatus',
    REVIEW_STATUS: 'creativeReviewStatus',
    CAMPAIGN_TYPE_GOAL: 'campaignTypeGoal',
    CAMPAIGN_NAME: 'campaignName',
    AD_GROUP_NAME: 'adGroupName',
    BID_AMOUNT: 'bidAmount',
    CAMPAIGN_SCHEDULE: 'scheduleStart',
    AD_GROUP_SCHEDULE: 'scheduleStart',
    CREATIVE_SCHEDULE: 'beginDateTime',
    METRIC: metricCode => metricCode,
  },
}

const COLUMN_ALIGN = keyMirror({
  LEFT: null,
  CENTER: null,
  RIGHT: null,
})

const COLUMN_CLASSNAME = {
  ALIGN_RIGHT: 'align_r',
  ALIGN_CENTER: 'align_c',
  ALIGN_LEFT: 'align_l',

  INACTIVE: 'td_inactive',
  BOLD: 'fw_bold',

  ELLIPSIS_BOX: 'txt_dash',
  BUTTON_BOX_TEXT_2: 'btn_dash',
  BUTTON_BOX_TEXT_3: 'btn_dash txt_num3',
  BUTTON_BOX_TEXT_4: 'btn_dash txt_num4',
  BUTTON_BOX_TEXT_5: 'btn_dash txt_num5',
  BUTTON_LEFT: 'btn_left',
}

const DashboardTableUtils = {
  getColumnWidthByAccessor({ accessor }) {
    const columnKey = Object.entries(COLUMN_SPEC.ACCESSOR).find(
      ([, v]) => v === accessor
    )?.[0]

    if (columnKey) {
      return {
        width: COLUMN_SPEC.WIDTH[columnKey],
        minWidth: COLUMN_SPEC.MIN_WIDTH[columnKey],
      }
    }

    return {}
  },
  isMetric(sortKey) {
    return DashboardTableMetricIds.find(v => v.id === sortKey) !== undefined
  },
  sort({ dashboardType, rows, sortKey, sortDirection }) {
    if (!rows || rows.isEmpty()) return rows

    const isMetric = this.isMetric(sortKey)

    return rows.sort(
      sortComparator({
        dashboardType,
        sortKey,
        sortDirection,
        isMetric,
      })
    )
  },
}

const sortComparator =
  ({ dashboardType, sortKey, sortDirection, isMetric }) =>
  (a, b) => {
    let v1 =
      sortDirection === SortEnum.Type.ASC ? a.get(sortKey) : b.get(sortKey)
    let v2 =
      sortDirection === SortEnum.Type.ASC ? b.get(sortKey) : a.get(sortKey)

    if (List.isList(v1)) {
      v1 = v1.first()
      v2 = v2.first()
    }

    if (isMetric) {
      v1 =
        sortDirection === SortEnum.Type.ASC
          ? a.getIn(['indicatorMap', sortKey])
          : b.getIn(['indicatorMap', sortKey])
      v2 =
        sortDirection === SortEnum.Type.ASC
          ? b.getIn(['indicatorMap', sortKey])
          : a.getIn(['indicatorMap', sortKey])
    }

    switch (sortKey) {
      case COLUMN_SPEC.SORT_KEY.ON_OFF: {
        v1 = v1 === UserConfigEnum.Type.ON ? 0 : 1
        v2 = v2 === UserConfigEnum.Type.ON ? 0 : 1
        return v1 - v2
      }

      // ASC: index 빠른 순
      case COLUMN_SPEC.SORT_KEY.STATUS: {
        const dashboardStatus =
          DashboardFilterUtils.FilterValue.operationStatus({
            dashboardType,
          })
        return dashboardStatus.indexOf(v1) - dashboardStatus.indexOf(v2)
      }

      // ASC: index 빠른 순
      case COLUMN_SPEC.SORT_KEY.REVIEW_STATUS: {
        const reviewStatus = DashboardFilterUtils.FilterValue.reviewStatus()
        return reviewStatus.indexOf(v1) - reviewStatus.indexOf(v2)
      }

      case COLUMN_SPEC.SORT_KEY.CAMPAIGN_TYPE_GOAL: {
        const campaignTypes = DashboardFilterUtils.FilterValue.campaignType()
        const goals = DashboardFilterUtils.FilterValue.goal()

        const { campaignType: campaignTypeA, goal: goalA } = v1
        const { campaignType: campaignTypeB, goal: goalB } = v2

        const campaignTypeIndexA = campaignTypes.indexOf(campaignTypeA)
        const campaignTypeIndexB = campaignTypes.indexOf(campaignTypeB)
        return campaignTypeIndexA === campaignTypeIndexB
          ? goals.indexOf(goalA) - goals.indexOf(goalB)
          : campaignTypeIndexA - campaignTypeIndexB
      }

      /**
       * 입찰금액/단가
       * - 다음쇼핑의 경우 totalBudget 으로, 그 외 bidAmount
       */
      case COLUMN_SPEC.SORT_KEY.BID_AMOUNT: {
        const {
          campaignTypeGoal: { campaignType: campaignTypeA },
          isAutoBid: isAutoBidA,
          totalBudget: totalBudgetA,
        } = sortDirection === SortEnum.Type.ASC ? a : b

        const {
          campaignTypeGoal: { campaignType: campaignTypeB },
          isAutoBid: isAutoBidB,
          totalBudget: totalBudgetB,
        } = sortDirection === SortEnum.Type.ASC ? b : a

        v1 = isAutoBidA
          ? 0
          : campaignTypeA === CampaignTypeEnum.Type.DAUM_SHOPPING
          ? totalBudgetA
          : v1
        v2 = isAutoBidB
          ? 0
          : campaignTypeB === CampaignTypeEnum.Type.DAUM_SHOPPING
          ? totalBudgetB
          : v2

        return v1 - v2
      }

      // ASC: 과거 시간 > 최근 시간
      case COLUMN_SPEC.SORT_KEY.LAST_MODIFIED_DATE: {
        v1 = moment(v1, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
        v2 = moment(v2, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)

        if (v1.isValid() && v2.isValid()) {
          return v1.isBefore(v2) ? -1 : v2.isBefore(v1) ? 1 : 0
        }

        return 0
      }

      // ASC: 과거 날짜 > 최근 날짜 > 종료일 없음
      case COLUMN_SPEC.SORT_KEY.CAMPAIGN_SCHEDULE:
      case COLUMN_SPEC.SORT_KEY.AD_GROUP_SCHEDULE: {
        return sortScheduleRecursion({
          a,
          b,
          dataKeyArray: [
            'scheduleStart',
            'scheduleEnd',
            'scheduleStartTime',
            'scheduleEndTime',
          ],
          sortDirection,
        })
      }

      case COLUMN_SPEC.SORT_KEY.CREATIVE_SCHEDULE: {
        return sortScheduleRecursion({
          a,
          b,
          dataKeyArray: ['beginDateTime', 'endDateTime'],
          sortDirection,
        })
      }

      default: {
        // ASC: 숫자 낮은 순
        if (typeof v1 === 'number') {
          return v1 - v2
        }

        // ASC: 숫자 > 문자 > 한글 > 영문
        return naturalComparator(v1, v2)
      }
    }
  }

/**
 * sortKeyArray 에 담긴 key 를 순서대로 꺼내면서 재귀적으로 정렬.
 */
const sortScheduleRecursion = ({ a, b, dataKeyArray, sortDirection }) => {
  const key = dataKeyArray.shift()

  if (key && a.has(key) && b.has(key)) {
    const valueA = sortDirection === Sort.ASC ? a.get(key) : b.get(key)
    const valueB = sortDirection === Sort.ASC ? b.get(key) : a.get(key)

    let momentA
    let momentB

    const dateTimeSecA = moment(valueA, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    if (dateTimeSecA.isValid()) {
      momentA = dateTimeSecA
    } else {
      const arr = valueA?.split(':')
      momentA = moment({
        hours: arr?.[0],
        minutes: arr?.[1],
        seconds: arr?.[2],
      })
    }

    const dateTimeSecB = moment(valueB, moment.HTML5_FMT.DATETIME_LOCAL_SECONDS)
    if (dateTimeSecB.isValid()) {
      momentB = dateTimeSecB
    } else {
      const arr = valueB?.split(':')
      momentB = moment({
        hours: arr?.[0],
        minutes: arr?.[1],
        seconds: arr?.[2],
      })
    }

    if (momentA.isValid() && momentB.isValid()) {
      return momentA.isBefore(momentB)
        ? -1
        : momentB.isBefore(momentA)
        ? 1
        : sortScheduleRecursion({ a, b, dataKeyArray, sortDirection })
    } else {
      const o1 = momentA.isValid() ? 0 : 1
      const o2 = momentB.isValid() ? 0 : 1
      const result = o1 - o2
      return result === 0
        ? sortScheduleRecursion({ a, b, dataKeyArray, sortDirection })
        : result
    }
  }

  return 0
}

export { COLUMN_SPEC, COLUMN_ALIGN, COLUMN_CLASSNAME, DashboardTableUtils }
