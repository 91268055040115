import { keyMirror } from '../../utils/utils'
import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'

const PromotionalVideo = keyMirror(
  {
    SET_VIDEO: null,
    SET_AUTO_THUMBNAIL: null,
    SET_UPLOAD_THUMBNAIL: null,
    SET_SKIPPABLE_TYPE: null,
    DELETE_UPLOAD_THUMBNAIL: null,

    SET_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'PROMOTIONAL_VIDEO'
)

const initialState = fromJS({
  video: {},
  autoThumbnail: {}, // 동영상 추출 썸네일
  uploadThumbnail: {}, // 맞춤 썸네일
  videoSkippableType: undefined,
})

export default createReducer(initialState, {
  [PromotionalVideo.SET_VIDEO]: (state, { video = {} }) => {
    const nextVideo = fromJS(video ?? {})

    return state.set(
      'video',
      nextVideo.get('id') > 0 ? nextVideo.set('encodingProgress', 1) : nextVideo
    )
  },
  [PromotionalVideo.SET_AUTO_THUMBNAIL]: (state, { autoThumbnail = {} }) =>
    state.set('autoThumbnail', fromJS(autoThumbnail)),

  [PromotionalVideo.SET_UPLOAD_THUMBNAIL]: (state, { uploadThumbnail = {} }) =>
    state.set('uploadThumbnail', fromJS(uploadThumbnail)),

  [PromotionalVideo.DELETE_UPLOAD_THUMBNAIL]: state =>
    state.withMutations(s => s.set('uploadThumbnail', Map())),

  [PromotionalVideo.SET_SKIPPABLE_TYPE]: (state, { videoSkippableType }) =>
    state.withMutations(s => s.set('videoSkippableType', videoSkippableType)),

  [PromotionalVideo.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn(keyPath, fromJS(value)),

  [PromotionalVideo.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(keyPath, initialState.getIn(keyPath)),

  [PromotionalVideo.CLEAR]: () => initialState,
})

export function setPromotionalVideo({ video }) {
  return {
    type: PromotionalVideo.SET_VIDEO,
    video,
  }
}

export function setPromotionalVideoAutoThumbnail({ autoThumbnail }) {
  return {
    type: PromotionalVideo.SET_AUTO_THUMBNAIL,
    autoThumbnail,
  }
}

export function setPromotionalVideoUploadThumbnail({ uploadThumbnail }) {
  return {
    type: PromotionalVideo.SET_UPLOAD_THUMBNAIL,
    uploadThumbnail,
  }
}

export function setPromotionalVideoSkippableType({ videoSkippableType }) {
  return {
    type: PromotionalVideo.SET_SKIPPABLE_TYPE,
    videoSkippableType,
  }
}

export function deletePromotionalVideoUploadThumbnail() {
  return {
    type: PromotionalVideo.DELETE_UPLOAD_THUMBNAIL,
  }
}

export function setPromotionalVideoEncodingCompleted() {
  return dispatch => {
    dispatch(
      setPromotionalVideoByKeyPath({
        keyPath: ['video', 'preview', 'percent'],
        value: 100,
      })
    )
  }
}

export function setPromotionalVideoByKeyPath({ keyPath, value }) {
  return {
    type: PromotionalVideo.SET_BY_KEY_PATH,
    keyPath,
    value,
  }
}

export function initPromotionalVideoByKeyPath({ keyPath }) {
  return {
    type: PromotionalVideo.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function clearPromotionalVideo() {
  return {
    type: PromotionalVideo.CLEAR,
  }
}
