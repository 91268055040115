import cx from 'classnames'
import Slider from 'rc-slider'
import { BIZ_BOARD_IMAGE_SCALE_STEP } from '../../../../../utils/advertise/creative/bizBoard'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
  setBizBoardCreateImageScaleValue,
  updateBizBoardCreateImageScaleValue,
} from '../../../../../modules/advertise/mBizBoardImageEditor'
import PropTypes from 'prop-types'

const selector = ({
  creativeV2: {
    bizBoardImageEditor: { imageScales },
  },
}) => {
  return {
    imageScales,
  }
}

const BizBoardBannerImageResizer = ({
  index,
  active,
  customEventHandlerRef,
  handleTemplateChange,
}) => {
  const dispatch = useDispatch()

  const { imageScales } = useSelector(selector, shallowEqual)
  const { value: scale, min: minScale, max: maxScale } =
    imageScales.get(index) || {}

  const handleSlideChange = React.useCallback(
    v => {
      if (active) {
        dispatch(setBizBoardCreateImageScaleValue(index, v))
        handleTemplateChange('imageIndex', index)
      }
    },
    [active, dispatch, index, handleTemplateChange]
  )

  const handleScaleUp = React.useCallback(() => {
    if (active) {
      dispatch(
        updateBizBoardCreateImageScaleValue(
          index,
          prev => prev + BIZ_BOARD_IMAGE_SCALE_STEP
        )
      )
      handleTemplateChange('imageIndex', index)
    }
  }, [active, dispatch, index, handleTemplateChange])

  const handleScaleDown = React.useCallback(() => {
    if (active) {
      dispatch(
        updateBizBoardCreateImageScaleValue(
          index,
          prev => prev - BIZ_BOARD_IMAGE_SCALE_STEP
        )
      )
      handleTemplateChange('imageIndex', index)
    }
  }, [active, dispatch, index, handleTemplateChange])

  const handleReset = React.useCallback(() => {
    customEventHandlerRef.current?.reset()
    handleTemplateChange('imageIndex', index)
  }, [customEventHandlerRef, index, handleTemplateChange])

  return (
    <div className="bnrimg_area">
      <strong className="subtit_bnrimg">이미지 사이즈</strong>
      <div className="bnrimg_con">
        <div className="size_edit">
          <div className="control_bg">
            <div
              className={cx('range_slider', {
                in_active: !active,
              })}>
              <Slider
                dots={false}
                value={scale}
                defaultValue={scale}
                step={BIZ_BOARD_IMAGE_SCALE_STEP}
                min={minScale}
                max={maxScale}
                onChange={handleSlideChange}
              />
            </div>
          </div>
          <div className="control_btn">
            <button
              type="button"
              className={cx('btn_gm gm_line', {
                in_active: !active,
              })}
              onClick={handleScaleDown}>
              <span className="inner_g">- 축소</span>
            </button>
            <button
              type="button"
              className={cx('btn_gm gm_line', {
                in_active: !active,
              })}
              onClick={handleScaleUp}>
              <span className="inner_g">+ 확대</span>
            </button>
            <button
              type="button"
              className={cx('btn_gm gm_line', {
                in_active: !active,
              })}
              onClick={handleReset}>
              <span className="inner_g">원본</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

BizBoardBannerImageResizer.propTypes = {
  index: PropTypes.number,
  active: PropTypes.bool,
  customEventHandlerRef: PropTypes.object,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerImageResizer
