import React from 'react'
import { Redirect, Route, Switch, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { initPlusFriendGroupListWithPaging } from '../../modules/targeting/plusFriendGroup/mPlusFriendGroupList'
import { initCustomerFileListWithPaging } from '../../modules/targeting/mCustomerFilesUpload'
import cx from 'classnames'
import Gnb from '../../components/Navigation/Gnb/Gnb'
import Lnb from '../../components/Navigation/Lnb/Lnb'
import Footer from '../../components/Footer/Footer'
import useLnbState from '../../components/Navigation/Lnb/useLnbState'

const CustomerFilesPage = React.lazy(() =>
  import('../../components/Targeting/CustomerFiles/CustomerFilesPage')
)
const CustomerFilesUploadDetailV2 = React.lazy(() =>
  import(
    '../../components/Targeting/CustomerFiles/Detail/CustomerFilesDetailV2'
  )
)
const PlusFriendGroupList = React.lazy(() =>
  import('../../components/Targeting/PlusFriendGroup/plusFriendGroupList')
)
const PlusFriendGroupDetailV2 = React.lazy(() =>
  import('../../components/Targeting/PlusFriendGroup/PlusFriendGroupDetailV2')
)
const Cohort = React.lazy(() => import('../targeting/cohort/cohort'))
const AudienceManagement = React.lazy(() =>
  import(
    '../../components/Targeting/Audience/AudienceManagement/AudienceManagement'
  )
)
const AudienceCreate = React.lazy(() =>
  import('../../components/Targeting/Audience/AudiencePage')
)
const AudienceDetail = React.lazy(() =>
  import('../../components/Targeting/Audience/AudienceDetail/AudienceDetail')
)

const PlusFriendGroupWrapper = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => dispatch(initPlusFriendGroupListWithPaging())
  }, [dispatch])

  return (
    <Switch>
      <Route
        exact
        path="/:adaccountid(\d+)/targeting/plusfriendgroup"
        component={PlusFriendGroupList}
      />
      <Route
        exact
        path="/:adaccountid(\d+)/targeting/plusfriendgroup/:plusfriendgroupid?"
        component={PlusFriendGroupDetailV2}
      />
    </Switch>
  )
}

const CustomerFilesUploadWrapper = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    return () => dispatch(initCustomerFileListWithPaging())
  }, [dispatch])

  return (
    <Switch>
      <Route
        exact
        path="/:adaccountid(\d+)/targeting/customerfilesupload"
        component={CustomerFilesPage}
      />
      <Route
        exact
        path="/:adaccountid(\d+)/targeting/customerfilesupload/:customerfileid?"
        component={CustomerFilesUploadDetailV2}
      />
    </Switch>
  )
}

const AudienceWrapper = () => {
  return (
    <Switch>
      <Route
        path="/:adaccountid(\d+)/targeting/audience"
        exact
        component={AudienceManagement}
      />
      <Route
        path="/:adaccountid(\d+)/targeting/audience/create"
        exact
        component={AudienceCreate}
      />
      <Route
        path="/:adaccountid(\d+)/targeting/audience/:audienceid(\d+)"
        exact
        component={AudienceDetail}
      />
      <Route
        path="/:adaccountid(\d+)/targeting/audience/:audienceid(\d+)/modify"
        component={AudienceCreate}
      />
    </Switch>
  )
}

const USE_LNB_STATE_PATH_PATTERN = [
  '\\d+\\/targeting\\/audience\\/create*', // 생성
  '\\d+\\/targeting\\/audience\\/\\d+\\/modify*', // 수정
]

const LayoutTargetingWrapper = () => {
  const location = useLocation()

  const shouldLnbStateUse = React.useMemo(
    () =>
      USE_LNB_STATE_PATH_PATTERN.some(pattern =>
        new RegExp(pattern, 'gi').test(location.pathname)
      ),
    [location.pathname]
  )

  const { lnbState } = useLnbState()

  return (
    <div
      id="kakaoWrap"
      className={cx({ reform_guide_show: shouldLnbStateUse })}>
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb state={shouldLnbStateUse ? lnbState : undefined} />
        <React.Suspense fallback="">
          <Switch>
            <Redirect
              exact
              from="/:adaccountid(\d+)/targeting/pixel"
              to="/:adaccountid(\d+)/linkage/pixel"
            />
            <Route
              path="/:adaccountid(\d+)/targeting/audience"
              component={AudienceWrapper}
            />
            <Route
              path="/:adaccountid(\d+)/targeting/cohort"
              component={Cohort}
            />
            <Route
              path="/:adaccountid(\d+)/targeting/customerfilesupload"
              component={CustomerFilesUploadWrapper}
            />
            <Route
              path="/:adaccountid(\d+)/targeting/plusfriendgroup"
              component={PlusFriendGroupWrapper}
            />
          </Switch>
        </React.Suspense>
        <div
          id={LayoutTargetingWrapper.sideBarContainerId}
          className="reform_m_aside"
          role="navigation"
        />
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

LayoutTargetingWrapper.sideBarContainerId = 'targetingSideBarContainer'

export default LayoutTargetingWrapper
