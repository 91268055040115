import React from 'react'
import BrowserWarningRibbon from './BrowserWarningRibbon'
import CI from './CI'
import GnbMenu from './GnbMenu'
import MyMenu from './MyInfo'
import { KakaoAD } from '../../../utils/app/services/kakaoAD'
import { useIsMounted } from '../../../utils/hook/useIsMounted'
import { isObserved } from '../../../utils/authenticationUtils'
import { AppEnvInfo } from '../../AppInfo'
import { getApiPhase } from '../../../utils/app/services/kakaoMoment'
import LoungeBoard from './LoungeBoard'
import LoungeBoardNotice from './LoungeBoardNotice'

export const GNB_SPEC = {
  HEIGHT: 60,
}

const GNB_MENU_API = {
  production: 'https://public-api-business.kakao.com/menu/v3.json',
  stage: 'https://cbt-api-business.kakao.com/menu/v3.json',
  sandbox: 'https://sandbox-api-business.kakao.com/menu/v3.json',
  develop: 'https://sandbox-api-business.kakao.com/menu/v3.json', // dev-business deprecated
}

const Gnb = () => {
  const isMounted = useIsMounted()

  /**
   * {
   *   id: string,
   *   name: string,
   *   link: {
   *     href: string
   *   }
   * }
   */
  const [gnbHome, setGnbHome] = React.useState({})
  /**
   * [
   *  {
   *    id: string,
   *    name: string,
   *    children?: [{ name: string, id: string, link?: {} }]
   *    link?: { href: string }
   *  }
   * ]
   */
  const [gnbMenu, setGnbMenu] = React.useState([])

  const fetchGnb = React.useCallback(async () => {
    try {
      const apiUrl = GNB_MENU_API[getApiPhase()] || GNB_MENU_API.sandbox

      const response = await fetch(apiUrl, {
        cache: 'no-cache',
      })

      const data = await response.json()

      if (isMounted.current) {
        setGnbMenu(data?.serviceMenu || [])
        setGnbHome(data?.home || {})
      }
    } catch (e) {
      console.log(e.message)
    }
  }, [isMounted])

  React.useEffect(() => {
    fetchGnb()
  }, [fetchGnb])

  return (
    <>
      <header id="kakaoHead" className="kakao_head">
        <CI gnbHome={gnbHome} />
        <GnbMenu gnbMenu={gnbMenu} />
        <BrowserWarningRibbon />
        <div className="gnb_relate">
          <h2 className="screen_out">서비스 관련 메뉴</h2>
          <ul className="list_relate">
            <LoungeBoardNotice />
            <LoungeBoard />

            <li>
              <a
                href={KakaoAD.URL.CS}
                rel="noopener noreferrer"
                target="_blank"
                className="link_gnb">
                고객센터
              </a>
            </li>
            {!isObserved() && <MyMenu />}
          </ul>
        </div>
      </header>
      <AppEnvInfo />
    </>
  )
}

export default Gnb
