import React, { useMemo } from 'react'
import PopupHOC from '../../../../Popup/PopupHOC'
import PopupButtonGroup from '../../../../Common/ButtonGroup/PopupButtonGroup'
import ImageEditorImageResizer from './ImageEditorImageResizer'
import PropTypes from 'prop-types'
import CreativeLibraryImageCrop from './CreativeLibraryImageCrop'
import { List, Set } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ImageEditorAspectSelect from './ImageEditorAspectSelect'
import ImageEditorUtils from './imageEditorUtils'
import PopupProxy from '../../../../Popup/Common/PopupProxy'
import {
  openPopupByProxy,
  POPUP_KEY,
} from '../../../../../modules/common/mPopup'
import { useDispatch } from 'react-redux'
import ImageEditorGuide from './ImageEditorGuide'
import { checkNotEmpty } from '../../../../../utils/regexUtils'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../../utils/alertUtils'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'

/**
 * image sample
 * {
 *  imageUUID: 22521,
 *  isEdited: true,
 *  crop: {x: 0, y: 0},
 *  zoom: 1,
 *  editedImageAspect: 2,
 *  editedImageUrl: "blob:https://dsp.kakao.com:4000/ec77c458-071d-416a-98c3-1e313ec6dd61",
 *  editedImageWidth: 1200,
 *  editedImageHeight: 600,
 *  url: "//beta.daumcdn.net/b2/creative/27429/678397526261eac4dcdc155342d0746a.jpg",
 *  imageWidth: 1200,
 *  imageHeight: 600,
 *  originalFileName: "sample1",
 *  mimeType: "image/jpeg",
 *  isConvertedToJpeg: false,
 * }
 */
const CreativeLibraryImageEditor = ({
  creativeFormat,
  creativeUploadAssetPropertyType,
  campaignType,
  validAspects,
  cropDimensionMap,
  originalImageUUID,
  images,
  containerHeight, // containerHeight 에 맞추어 cropArea 를 꽉 채운다.
  onComplete,
  close,
}) => {
  const dispatch = useDispatch()

  const initialEditedImages = images || List()

  const [cropperAspect, setCropperAspect] = React.useState(validAspects[0])
  const [crop, setCrop] = React.useState({ x: 0, y: 0 })
  const [zoom, setZoom] = React.useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = React.useState(null)
  const [isGuideMouseOver, setIsGuideMouseOver] = React.useState(false)
  const [isProcessing, setIsProcessing] = React.useState(false)
  const [editedImages, setEditedImages] = React.useState(initialEditedImages)
  const [selectedAspects, setSelectedAspects] = React.useState(
    Set(
      initialEditedImages
        .map(({ editedImageAspect }) => editedImageAspect)
        .filter(Boolean)
    )
  )

  const representativeImage = editedImages.first()

  const { url, imageWidth, imageHeight, mimeType } = representativeImage

  React.useEffect(() => {
    const {
      isEdited,
      crop: prevCrop,
      zoom: prevZoom,
    } = editedImages.find(
      ({ editedImageAspect }) => editedImageAspect === cropperAspect
    ) || {}

    setCrop(isEdited ? prevCrop : { x: 0, y: 0 })
    setZoom(isEdited ? prevZoom : 1)
  }, [cropperAspect, editedImages])

  const applyEdit = async () => {
    try {
      setIsProcessing(true)

      const { width: canvasWidth, height: canvasHeight } =
        cropDimensionMap[cropperAspect] || {}

      const { blob, isConvertedToJpeg } =
        (await ImageEditorUtils.createEditedImageBlob({
          imageSrc: url,
          canvasWidth,
          canvasHeight,
          imageWidth,
          imageHeight,
          aspect: cropperAspect,
          croppedPixels: croppedAreaPixels,
          mimeType,
        })) || {}

      if (checkNotEmpty(blob)) {
        const editedImageObjectUrl = URL.createObjectURL(blob)

        const image = new Image()
        image.src = editedImageObjectUrl
        image.onload = () => {
          const nextEditedImageWidth = image.width
          const nextEditedImageHeight = image.height

          const nextEditedImageAspect =
            nextEditedImageWidth / nextEditedImageHeight

          // representativeImage 기반으로 set 한다. catalogProduct 와 같은 props 가 포함되어 있다.
          const nextEditedImage = representativeImage.withMutations(s =>
            s
              .set('crop', crop)
              .set('zoom', zoom)
              .set('isEdited', true)
              .set('imageUUID', originalImageUUID)
              .set('editedImageUrl', editedImageObjectUrl)
              .set('editedImageAspect', nextEditedImageAspect)
              .set('editedImageWidth', nextEditedImageWidth)
              .set('editedImageHeight', nextEditedImageHeight)
              .update('mimeType', prevMimeType =>
                isConvertedToJpeg ? 'image/jpeg' : prevMimeType
              )
              .set('isConvertedToJpeg', isConvertedToJpeg)
          )

          const editedImageIndexByAspect = editedImages.findIndex(
            ({ editedImageAspect }) =>
              editedImageAspect === nextEditedImageAspect
          )

          setEditedImages(prev =>
            editedImageIndexByAspect >= 0
              ? prev.set(editedImageIndexByAspect, nextEditedImage)
              : prev.push(nextEditedImage)
          )

          setSelectedAspects(prev => prev.add(cropperAspect))
        }
      } else {
        showErrorMessage('편집 적용이 불가능한 이미지입니다.')
      }
    } catch (e) {
      console.error(e)
    } finally {
      setIsProcessing(false)
    }
  }

  const onOK = React.useCallback(() => {
    if (selectedAspects.size > 0) {
      const selectedEditedImages = editedImages.filter(
        ({ editedImageAspect }) =>
          selectedAspects.find(
            selectedAspect => editedImageAspect === selectedAspect
          )
      )

      selectedEditedImages.forEach(
        ({ isConvertedToJpeg, editedImageAspect }) => {
          if (isConvertedToJpeg)
            showSuccessMessage(
              `업로드 가능한 이미지 용량을 초과하여, JPEG 파일로 변환하여 저장합니다. (${ImageEditorUtils.getCropAspectString(
                {
                  aspect: editedImageAspect,
                }
              )} 비율 이미지)`
            )
        }
      )

      onComplete(selectedEditedImages)
    } else {
      onComplete()
    }

    close()
  }, [close, editedImages, onComplete, selectedAspects])

  const onCancel = React.useCallback(() => {
    const popupProxy = (
      <PopupProxy hasSecondaryButton primaryButtonFunc={close}>
        <strong className="tit_layer">이미지 편집 취소</strong>
        <p className="txt_layer">
          편집을 취소하시겠습니까?
          <br />
          저장하지 않은 모든 내용은 사라집니다.
        </p>
      </PopupProxy>
    )

    dispatch(openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, popupProxy))
  }, [close, dispatch])

  const imageEditHandler = useMemo(() => {
    return {
      onCropChange: location => {
        setCrop(location)
      },
      onZoomChange: zoom => {
        setZoom(zoom)
      },
      onCropComplete: (croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
      },
      onReset: () => {
        setCrop({ x: 0, y: 0 })
        setZoom(1)
      },
      onAspectChange: aspect => {
        setCropperAspect(aspect)
      },
      onRelease: () => {
        setSelectedAspects(prev => prev.filterNot(v => v === cropperAspect))
      },
    }
  }, [cropperAspect])

  const isSelectedAspect = selectedAspects.includes(cropperAspect)

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">이미지 편집</strong>
      </div>
      <div className="layer_body">
        <ImageEditorAspectSelect
          validAspects={validAspects}
          selectedAspects={selectedAspects}
          aspect={cropperAspect}
          imageEditHandler={imageEditHandler}
        />
        <ImageEditorGuide
          creativeFormat={creativeFormat}
          creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
          campaignType={campaignType}
          aspect={cropperAspect}
          setIsGuideMouseOver={setIsGuideMouseOver}
        />
        <div className="box_imgedit">
          <CreativeLibraryImageCrop
            creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
            url={url}
            imageWidth={imageWidth}
            imageHeight={imageHeight}
            crop={crop}
            zoom={zoom}
            aspect={cropperAspect}
            containerHeight={containerHeight}
            imageEditHandler={imageEditHandler}
            isGuideMouseOver={isGuideMouseOver}
          />
          <ImageEditorImageResizer
            isSelectedAspect={isSelectedAspect}
            zoom={zoom}
            minZoom={ImageEditorUtils.ZOOM.MIN}
            maxZoom={ImageEditorUtils.ZOOM.MAX}
            step={ImageEditorUtils.ZOOM.STEP}
            onComplete={applyEdit}
            imageEditHandler={imageEditHandler}
            isProcessing={isProcessing}
          />
        </div>
      </div>
      <div className="layer_foot">
        <span className="txt_select">
          <button type="button" className="btn_del">
            <span
              className="ico_comm ico_del"
              onClick={() => setSelectedAspects(Set())}>
              삭제
            </span>
          </button>
          선택된 이미지<em className="num_select">{selectedAspects.size}</em>
        </span>
        <PopupButtonGroup
          okButtonLabel="확인"
          hasBack={false}
          isEnabledOK={true}
          onOK={onOK}
          onCancel={onCancel}
        />
        <a className="btn_close" onClick={onCancel}>
          <span className="ico_comm ico_close">닫기</span>
        </a>
      </div>
    </div>
  )
}

CreativeLibraryImageEditor.propTypes = {
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ).isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  cropDimensionMap: PropTypes.object.isRequired,
  validAspects: PropTypes.array.isRequired,
  originalImageUUID: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  images: ImmutablePropTypes.list.isRequired,
  containerHeight: PropTypes.number.isRequired,
  onComplete: PropTypes.func.isRequired,
  close: PropTypes.func.isRequired,
}

export default PopupHOC(CreativeLibraryImageEditor, {
  subClassName: 'imgedit_layer',
})
