import { createReducer } from 'redux-immutablejs'
import { List, Map } from 'immutable'
import { keyMirror } from '../../utils/utils'

export const POPUP_ANIMATION_DURATION = 100

const Popup = keyMirror({
  OPEN_POPUP_V2: null,
  CLOSE_POPUP_V2: null,
  CLOSE_POPUP_WITHOUT_KEY_V2: null,
  CLOSE_ALL_POPUP_V2: null,
  HIDE_POPUP_V2: null,
})

const initialState = List()

export default createReducer(initialState, {
  [Popup.OPEN_POPUP_V2]: (state, { popupKey, props }) => {
    return state.push(Map({ popupKey, isVisible: true, props }))
  },

  [Popup.CLOSE_POPUP_V2]: (state, { popupKey }) => {
    return state.filter(({ popupKey: key }) => key !== popupKey)
  },

  [Popup.CLOSE_POPUP_WITHOUT_KEY_V2]: state => {
    return state.pop()
  },

  [Popup.CLOSE_ALL_POPUP_V2]: state => state.clear(),

  [Popup.HIDE_POPUP_V2]: state => {
    return state.withMutations(s => {
      const last = s.last().update('isVisible', () => false)
      s.pop()
      s.push(last)
    })
  },
})

export function openPopupV2({ popupKey, ...props }) {
  return {
    type: Popup.OPEN_POPUP_V2,
    popupKey,
    ...props,
  }
}

export function closePopupV2(popupKey) {
  return {
    type: Popup.CLOSE_POPUP_V2,
    popupKey,
  }
}

export function closePopupWithoutKeyV2() {
  return {
    type: Popup.CLOSE_POPUP_WITHOUT_KEY_V2,
  }
}

export function closeAllPopupV2() {
  return {
    type: Popup.CLOSE_ALL_POPUP_V2,
  }
}

export function hidePopupV2() {
  return {
    type: Popup.HIDE_POPUP_V2,
  }
}
