import React from 'react'

const CI = ({ gnbHome }) => {
  return (
    <h1>
      {gnbHome?.link?.href ? (
        <a id="kakaoLogo" rel="noopener noreferrer" href={gnbHome.link.href}>
          <span className="ir_pm">Kakao business</span>
        </a>
      ) : (
        <span id="kakaoLogo">
          <span className="ir_pm">Kakao business</span>
        </span>
      )}
    </h1>
  )
}

export default CI
