import { LocalStorageFactory, SessionStorageFactory } from './storageFactory'

const GLOBAL_STORAGE_ITEM = {
  // local
  API_PHASE: 'apiPhase',
}

const LNB_STORAGE_ITEM = {
  // local
  IS_EXPANDED: 'isExpanded',
}

const DASHBOARD_STORAGE_ITEM = {
  // local
  TABLE_COLUMN_WIDTH: 'tableColumnWidth',
  TABLE_METRICS: 'tableMetrics',
  TABLE_SORT: 'tableSort',
  TABLE_SORT_SYSTEM: 'tableSortSystem',
  SUMMARY_EXPANDED: 'summaryExpanded',
  SUMMARY_VISIBILITY: 'summaryVisibility',
  SUMMARY_CHART_AXES: 'summaryChartAxes',

  // session
  URI_SEARCH_PARAMS: 'uriSearchParams',
  CALENDAR: 'calendar',
  TABLE_ROW_ID_SET: 'tableRowIdSet',
}

const GUIDE_STORAGE_ITEM = {
  CREATIVE_LIBRARY_IMAGE_EDITOR: 'creativeLibraryImageEditor',
  CMP_ASSET_LIBRARY_IMAGE_EDITOR: 'cmpAssetLibraryImageEditor',
}

const REPORT_STORAGE_ITEM = {
  TABLE_COLUMN_WIDTH: 'tableColumnWidth',
}

const GlobalStorageLocal = LocalStorageFactory({
  storageKey: 'moment.global',
  initialValue: {
    version: 1,
    [GLOBAL_STORAGE_ITEM.API_PHASE]: '',
  },
})

const LnbStorageLocal = LocalStorageFactory({
  storageKey: 'moment.lnb',
  initialValue: {
    version: 1,
    [LNB_STORAGE_ITEM.IS_EXPANDED]: true,
  },
})

const DashboardStorageLocal = LocalStorageFactory({
  storageKey: 'moment.dashboard',
  initialValue: {
    version: 1,
    /**
     * {
     *   [adAccountId]: {
     *     [dashboardType]: {
     *       [columnAccessor]: number
     *     }
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.TABLE_COLUMN_WIDTH]: {},
    /**
     * {
     *   [adAccountId]: {
     *      conversionCriteria: '',
     *      fixedGroups: [],
     *      selected: [],
     *      storedGroups: [],
     *      temporaryGroup: {}
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.TABLE_METRICS]: {},
    /**
     * {
     *   [adAccountId]: {
     *      campaign: { key, direction },
     *      adGroup: { key, direction },
     *      creative: { key, direction },
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.TABLE_SORT]: {},
    /**
     * {
     *  campaign: { key, direction },
     *  adGroup: { key, direction },
     *  creative: { key, direction },
     * }
     */
    [DASHBOARD_STORAGE_ITEM.TABLE_SORT_SYSTEM]: {},
    /**
     * {
     *   [adAccountId]: boolean
     * }
     */
    [DASHBOARD_STORAGE_ITEM.SUMMARY_EXPANDED]: {},
    /**
     * {
     *   [adAccountId]: {
     *      info: boolean,
     *      chart: boolean
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.SUMMARY_VISIBILITY]: {},
    /**
     * {
     *   [adAccountId]: [string, string]
     * }
     */
    [DASHBOARD_STORAGE_ITEM.SUMMARY_CHART_AXES]: {},
  },
  useRx: true,
})

const DashboardStorageSession = SessionStorageFactory({
  storageKey: 'moment.dashboard',
  initialValue: {
    version: 1,
    [DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS]: '',
    /**
     * {
     *   [adAccountId]: {
     *     startDate: moment(),
     *     endDate: moment(),
     *     periodType: CalendarEnum.Preset.Type.TODAY,
     *     customDateDiff
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.CALENDAR]: {},
    /**
     * {
     *   [adAccountId]: {
     *     campaign: [],
     *     adGroup: [],
     *     creative: []
     *   }
     * }
     */
    [DASHBOARD_STORAGE_ITEM.TABLE_ROW_ID_SET]: {},
  },
})

const GuideStorageLocal = LocalStorageFactory({
  storageKey: 'moment.guide',
  initialValue: {
    version: 1,
    [GUIDE_STORAGE_ITEM.CREATIVE_LIBRARY_IMAGE_EDITOR]: false,
    [GUIDE_STORAGE_ITEM.CMP_ASSET_LIBRARY_IMAGE_EDITOR]: false,
  },
})

const ReportStorageLocal = LocalStorageFactory({
  storageKey: 'moment.report',
  initialValue: {
    version: 1,
    /**
     * {
     *   [adAccountId]: {
     *      [columnAccessor]: number
     *   }
     * }
     */
    [REPORT_STORAGE_ITEM.TABLE_COLUMN_WIDTH]: {},
  },
})

export {
  GLOBAL_STORAGE_ITEM,
  LNB_STORAGE_ITEM,
  DASHBOARD_STORAGE_ITEM,
  GUIDE_STORAGE_ITEM,
  REPORT_STORAGE_ITEM,
  GlobalStorageLocal,
  LnbStorageLocal,
  DashboardStorageLocal,
  DashboardStorageSession,
  GuideStorageLocal,
  ReportStorageLocal,
}
