import { combineReducers } from 'redux-immutablejs'
import common from './mDashboardCommon'
import summary from './mDashboardSummary'
import filter from './mDashboardFilter'
import table from './mDashboardTable'
import tableMetric from './mDashboardTableMetric'
import calendar from './mDashboardCalendar.js'

export default combineReducers({
  common,
  table,
  summary,
  filter,
  tableMetric,
  calendar,
})
