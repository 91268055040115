const requestLockKeySet = new Set()

const RequestLock = {
  acquire: ({ key, executor, releaseTimeoutMs = 60 * 1000, onBlock }) => {
    return new Promise((resolve, reject) => {
      if (typeof executor !== 'function') {
        reject(new Error('RequestLock executor must be a function.'))
      } else {
        if (RequestLock.isBusy(key)) {
          if (typeof onBlock === 'function') {
            resolve(onBlock())
          } else {
            reject({ isLocked: true, request: key })
          }
        } else {
          requestLockKeySet.add(key)

          const releaseTimeout = setTimeout(() => {
            RequestLock.release(key)
          }, releaseTimeoutMs)

          resolve(
            executor(() => {
              RequestLock.release(key)
              clearTimeout(releaseTimeout)
            })
          )
        }
      }
    })
  },

  release: key => {
    requestLockKeySet.delete(key)
  },

  isBusy: key => requestLockKeySet.has(key),
}

export default RequestLock
