import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { checkEmpty } from '../../utils/regexUtils'
import LandingUrlEnum from '../../enums/LandingUrlEnum'

const Catalog = keyMirror(
  {
    ADD_ASSET_GROUP: null,
    SET_ASSET_GROUPS: null,
    DELETE_ASSET_GROUP: null,
    SET_ASSET_GROUP_PROP_BY_KEY_PATH: null,
    CLEAR: null,
  },
  'CATALOG'
)

const initialState = fromJS({
  assetGroups: [],
})

export default createReducer(initialState, {
  [Catalog.ADD_ASSET_GROUP]: (state, { assetGroups }) =>
    state.update('assetGroups', prev => prev.concat(fromJS(assetGroups))),

  [Catalog.SET_ASSET_GROUPS]: (state, { assetGroups }) =>
    state.set('assetGroups', fromJS(assetGroups)),

  [Catalog.SET_ASSET_GROUP_PROP_BY_KEY_PATH]: (
    state,
    { index, keyPath, value }
  ) =>
    state.updateIn(['assetGroups', index], assetGroup => {
      const keyPathArray = coerceToArray(keyPath)

      if (keyPathArray.length > 1) {
        const withoutLast = keyPathArray.slice(0, -1)

        return assetGroup && assetGroup.getIn(withoutLast)
          ? assetGroup.setIn(keyPathArray, fromJS(value))
          : assetGroup
      } else {
        return assetGroup
          ? assetGroup.setIn(keyPathArray, fromJS(value))
          : assetGroup
      }
    }),

  [Catalog.DELETE_ASSET_GROUP]: (state, { index }) =>
    state.update('assetGroups', prevAssetGroups =>
      prevAssetGroups.delete(index)
    ),

  [Catalog.CLEAR]: () => initialState,
})

export function addCatalogAssetGroups(assetGroups) {
  return {
    type: Catalog.ADD_ASSET_GROUP,
    assetGroups,
  }
}

export function setCatalogAssetGroups(assetGroups) {
  return {
    type: Catalog.SET_ASSET_GROUPS,
    assetGroups,
  }
}

export function setCatalogAssetGroupPropByKey(index, keyPath, value) {
  return {
    type: Catalog.SET_ASSET_GROUP_PROP_BY_KEY_PATH,
    index,
    keyPath,
    value,
  }
}

export function deleteCatalogAssetGroup(index) {
  return {
    type: Catalog.DELETE_ASSET_GROUP,
    index,
  }
}

export function clearCatalog() {
  return {
    type: Catalog.CLEAR,
  }
}

export function updateAssetGroupLandingURLDefaultSettings({
  isAddSettingOpen,
  isEachChecked,
  isSameUrlChecked,
  notRspvInputType,
}) {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        catalog: { assetGroups },
        common: {
          adGroup: { deviceTypes },
        },
      },
    } = getState()

    const currentDevice =
      LandingUrlEnum.getCurrentSelectedDeviceTypeV2(deviceTypes)

    assetGroups.forEach(({ rspvLandingUrl }, index) => {
      dispatch(
        setCatalogAssetGroupPropByKey(
          index,
          'isAddSettingOpen',
          isAddSettingOpen
        )
      )
      dispatch(
        setCatalogAssetGroupPropByKey(index, 'isEachChecked', isEachChecked)
      )
      dispatch(
        setCatalogAssetGroupPropByKey(
          index,
          'isSameUrlChecked',
          isSameUrlChecked
        )
      )

      if (notRspvInputType) {
        dispatch(
          setCatalogAssetGroupPropByKey(
            index,
            'pcLandingUrl',
            currentDevice === LandingUrlEnum.InputType.PC_ONLY_DEVICE
              ? rspvLandingUrl
              : null
          )
        )
        dispatch(
          setCatalogAssetGroupPropByKey(
            index,
            'mobileLandingUrl',
            currentDevice === LandingUrlEnum.InputType.MOBILE_ONLY_DEVICE
              ? rspvLandingUrl
              : null
          )
        )
        dispatch(setCatalogAssetGroupPropByKey(index, 'rspvLandingUrl', null))
      }
    })
  }
}
