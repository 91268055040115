import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    COST: null,
    REACH: null,
    DEFAULT: null,
    MESSAGE_SENDING: null,
    PIXEL_AND_SDK: null,
    PLUS_FRIEND: null,
    VIDEO: null,
    CATALOG_SLIDE: null,
    MESSAGE: null,
    AD_VIEW: null,
    BIZ_BOARD_EXPANDABLE: null,
    SPONSORED_BOARD: null,
    TRIGGER: null,
  }),

  Name: {
    COST: '비용 지표',
    REACH: '도달 지표',
    DEFAULT: '기본 지표',
    MESSAGE_SENDING: '발송 지표',
    PIXEL_AND_SDK: '픽셀 & SDK 전환 지표',
    PLUS_FRIEND: '카카오친구 지표',
    VIDEO: '동영상 지표',
    CATALOG_SLIDE: '슬라이드 지표',
    MESSAGE: '메시지 지표',
    AD_VIEW: '애드뷰 지표',
    BIZ_BOARD_EXPANDABLE: '비즈보드 지표',
    SPONSORED_BOARD: '보드 지표',
    TRIGGER: '전면형 유도 지표',
  },

  isDeprecated(type) {
    return [this.Type.SPONSORED_BOARD].includes(type)
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}
