import React, { Fragment } from 'react'
import { useIsMounted } from '../../../../../utils/hook/useIsMounted'
import axios from 'axios'
import { CmpAPI } from '../../../../../modules-api/advertise/cmpApi'
import { deleteCancellation } from '../../../../../utils/cancellation/cancellation'
import { useDebounce } from 'react-use'
import { MomentLoader } from '../../../../Common/Loader/MomentLoader'
import { convertStringToBoldFaceByQuery } from '../../../../../utils/stringUtils'
import PropTypes from 'prop-types'
import cmpPropTypes from '../cmpPropTypes'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
} from '../../../../Tooltip/dynamicTooltipUtils'
import { Tooltip } from '../../../../../resources/locale'
import EllipsisTooltip from '../../../../Tooltip/TooltipImpl/EllipsisTooltip'

const ASSET_SIZE = 10

const CmpInputAssetLayer = ({
  adAccountId,
  value: inputValue,
  cmpRequestCreator,
  cmpResponseTransformer = ({ text, ...rest }) => ({
    ...rest,
    value: text,
  }),
  cmpAssetListItemRenderer,
  onCloseForced,
  onSelect,
}) => {
  const [isLoading, setIsLoading] = React.useState(true)

  const [cmpAssets, setCmpAssets] = React.useState([])

  const isMounted = useIsMounted()

  const cancelTokenSource = React.useRef(axios.CancelToken.source())

  const fetchCmpAssets = React.useCallback(async () => {
    setIsLoading(true)

    try {
      cancelTokenSource.current.cancel()
      cancelTokenSource.current = axios.CancelToken.source()

      const response = await CmpAPI.getImageAssets({
        adAccountId,
        pageRequest: {
          page: 0,
          size: ASSET_SIZE,
        },
        requestBody: cmpRequestCreator({ value: inputValue }),
        cancelTokenSource: cancelTokenSource.current,
      })

      if (isMounted.current) {
        setCmpAssets(response.data.content || [])
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        showErrorMessage(e.response?.data?.message || e.message)
      }
    } finally {
      if (isMounted.current) {
        setIsLoading(false)
      }

      deleteCancellation(cancelTokenSource.current)
    }
  }, [adAccountId, cmpRequestCreator, inputValue, isMounted])

  React.useEffect(() => {
    return () => {
      deleteCancellation(cancelTokenSource.current)
    }
  }, [])

  useDebounce(fetchCmpAssets, 500, [fetchCmpAssets])

  const listItemResolver = React.useCallback(
    props => {
      if (typeof cmpAssetListItemRenderer === 'function') {
        return cmpAssetListItemRenderer(props)
      }

      if (React.isValidElement(cmpAssetListItemRenderer)) {
        return React.cloneElement(cmpAssetListItemRenderer, props)
      }

      return <CmpInputAssetListItem {...props} />
    },
    [cmpAssetListItemRenderer]
  )

  return (
    <div className="layer_inphistory">
      <strong className="tit_history">
        최근 사용
        <DynamicTooltip
          content={Tooltip('cmp.text_asset.list', { size: ASSET_SIZE })}
          verticalPosition={DYNAMIC_TOOLTIP_POSITION_VERTICAL.TOP}>
          <a className="link_g link_help">
            <span className="ico_comm ico_help">도움말</span>
          </a>
        </DynamicTooltip>
      </strong>
      <ul className="list_history">
        {isLoading ? (
          <div className="box_nodata">
            <span
              className="reform_area_loading"
              style={{ backgroundColor: '#fff' }}>
              <span className="group_loading loading_size2">
                <MomentLoader size={4} margin={2} />
              </span>
            </span>
          </div>
        ) : cmpAssets.length === 0 ? (
          <div className="box_nodata">최근 사용한 내역이 없습니다.</div>
        ) : (
          cmpAssets.map(cmpResponseTransformer).map((cmpAsset, index) => (
            <Fragment key={index}>
              {listItemResolver({
                id: index,
                cmpAsset,
                inputValue,
                onSelect,
              })}
            </Fragment>
          ))
        )}
      </ul>
      <a className="btn_close" onClick={onCloseForced}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

CmpInputAssetLayer.propTypes = {
  ...cmpPropTypes.InputAsset.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onCloseForced: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

const CmpInputAssetListItem = ({
  id,
  cmpAsset,
  inputValue,
  iconView = ({ cmpAsset }) => null,
  onSelect = ({ cmpAssetValue }) => undefined,
}) => {
  const { value: cmpAssetValue } = cmpAsset || {}

  return (
    <li
      key={id}
      onClick={() => {
        onSelect({ cmpAssetValue })
      }}>
      <EllipsisTooltip
        rootElementType="a"
        rootElementClassName="link_history"
        horizontalPosition={DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.RIGHT}
        getContainerNode={({ rootRef }) => {
          const childLength = rootRef.current?.current?.length
          return childLength > 0
            ? rootRef.current?.children?.[childLength - 1]
            : rootRef.current
        }}
        content={cmpAssetValue}>
        <>
          {iconView({ cmpAsset })}
          {convertStringToBoldFaceByQuery(cmpAssetValue, inputValue, 'fc_emph')}
        </>
      </EllipsisTooltip>
    </li>
  )
}

const CmpInputAssetListItemUrl = props => {
  return (
    <CmpInputAssetListItem
      {...props}
      iconView={({ cmpAsset }) => (
        <>
          {cmpAsset.subAssetType === 'PC' && (
            <span className="ico_comm ico_pc">PC</span>
          )}
          {cmpAsset.subAssetType === 'MOBILE' && (
            <span className="ico_comm ico_mo">MOBILE</span>
          )}
        </>
      )}
    />
  )
}

const assetListItemPropTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  cmpAsset: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }),
  inputValue: PropTypes.string,
  iconView: PropTypes.func,
  onSelect: PropTypes.func,
}

CmpInputAssetListItem.propTypes = assetListItemPropTypes
CmpInputAssetListItemUrl.propTypes = assetListItemPropTypes

export { CmpInputAssetLayer, CmpInputAssetListItemUrl }
