import { toLower, toUpper, uniq } from 'lodash'
import {
  DashboardFilterUtils,
  FILTER_OPERATOR,
  FILTER_TYPE,
} from './DashboardHeader/DashboardFilter/dashboardFilterUtils'
import {
  checkNoneEmpty,
  isPositiveIntegerSequence,
  isUndefinedOrNull,
  validateInputLength,
} from '../../utils/regexUtils'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import { DOMAIN } from '../../utils/fetchUtils'
import { DashboardTableMetricIds } from '../../utils/dashboard/dashboardTableMetric'
import DashboardStatusEnum from '../../enums/DashboardStatusEnum'
import * as Sentry from '@sentry/browser'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'

/**
 * WHATWG URL 표준 기반
 * @see https://nodejs.org/api/url.html#url_the_whatwg_url_api
 * @see https://url.spec.whatwg.org/#example-url-parsing
 */

const URI_PARAM_NAME = {
  /**
   * filter=...
   */
  FILTER: 'filter',
  /**
   * summary=:DashboardEnumV2.Type~:id
   * - 없을 시: 광고계정
   * - id 없을 시: 전체
   */
  SUMMARY: 'summary',
  /**
   * table=:DashboardEnumV2.Type
   */
  TABLE: 'table',
  /**
   * table_row_ids_filter=:DashboardEnumV2.Type~:rowIds
   * - ex: CAMPAIGN~"1,2,3"
   */
  TABLE_ROW_IDS_FILTER: 'table_row_ids_filter',
  /**
   * page=:number
   */
  PAGE: 'page',
}

const DashboardUriUtils = {
  /**
   *
   * @param baseUrl {string | undefined}
   * @param paramName {string}
   * @param paramValue {string | undefined}
   * @returns {URL}
   */
  setSearchParam({ baseUrl, paramName, paramValue }) {
    const url = new URL(baseUrl || window.location.href)

    if (typeof paramName === 'string' && paramName.length > 0) {
      if (typeof paramValue === 'string') {
        if (paramValue.length > 0) {
          url.searchParams.set(paramName, paramValue)
        }
      }
    }

    return url
  },
  /**
   *
   * @param paramName {string}
   * @returns {URL}
   */
  deleteSearchParam({ paramName }) {
    const url = new URL(window.location.href)

    if (typeof paramName === 'string' && paramName.length > 0) {
      url.searchParams.delete(paramName)
    }

    return url
  },

  isValidDashboardTypeParam(dashboardType) {
    return (
      typeof dashboardType === 'string' &&
      DashboardEnumV2.values().includes(toUpper(dashboardType))
    )
  },
}

DashboardUriUtils.Summary = {
  /**
   * @param baseUrl {string | undefined}
   * @param dashboardType {string}
   * @param id {number | string | undefined}
   * @returns {URL}
   */
  set({ baseUrl, dashboardType, id }) {
    return DashboardUriUtils.setSearchParam({
      baseUrl,
      paramName: URI_PARAM_NAME.SUMMARY,
      paramValue: [toLower(dashboardType), id].filter(Boolean).join('~'),
    })
  },

  /**
   * @param urlSearchParams {URLSearchParams}
   * @returns {object}
   */
  getObjectValue({ urlSearchParams }) {
    const searchParamString = urlSearchParams.get(URI_PARAM_NAME.SUMMARY)

    if (!searchParamString) return {}

    const decodedUriString = decodeURI(searchParamString)

    const [dashboardType, id] = decodedUriString.split('~')

    return {
      dashboardType: toUpper(dashboardType),
      id: Number(id),
    }
  },

  /**
   * @returns {URL}
   */
  clear() {
    return DashboardUriUtils.deleteSearchParam({
      paramName: URI_PARAM_NAME.SUMMARY,
    })
  },
}

/**
 *
 * @param adAccountId
 * @param table
 * @param summary
 * @param tableDashboardType
 * @param filterSearchParamString
 * @param pageIndex
 * @returns {URL}
 */
DashboardUriUtils.createURLObject = ({
  adAccountId,
  table,
  summary,
  filterSearchParamString,
  pageIndex = 0,
}) => {
  const baseUrl = ['/dashboard', adAccountId].filter(Boolean).join('/')

  let urlObject = new URL(baseUrl, DOMAIN)

  if (table) {
    const { dashboardType, rowIdType, rowIds } = table

    if (DashboardUriUtils.isValidDashboardTypeParam(dashboardType)) {
      urlObject = DashboardUriUtils.Table.set({
        baseUrl: urlObject.href,
        dashboardType,
        rowIdType: rowIdType ?? dashboardType,
        rowIds: rowIds?.filter(Boolean)?.length > 0 ? rowIds : undefined,
      })
    }
  }

  if (summary) {
    const { dashboardType, id } = summary

    if (DashboardUriUtils.isValidDashboardTypeParam(dashboardType) && id > 0) {
      urlObject = DashboardUriUtils.Summary.set({
        baseUrl: urlObject.href,
        dashboardType,
        id,
      })
    }
  }

  if (filterSearchParamString) {
    urlObject = DashboardUriUtils.Filter.set({
      baseUrl: urlObject.href,
      searchParamString: filterSearchParamString,
    })
  }

  urlObject = DashboardUriUtils.Page.set({ baseUrl: urlObject.href, pageIndex })

  return urlObject
}

DashboardUriUtils.Table = {
  /**
   * @param baseUrl {string | undefined}
   * @param dashboardType {string}
   * @param rowIdType {string}
   * @param rowIds {array | undefined}
   * @returns {URL}
   */
  set({ baseUrl, dashboardType, rowIdType, rowIds = [] }) {
    let urlObject = DashboardUriUtils.setSearchParam({
      baseUrl,
      paramName: URI_PARAM_NAME.TABLE,
      paramValue: toLower(dashboardType),
    })

    if (rowIdType && rowIds?.length > 0) {
      urlObject = DashboardUriUtils.setSearchParam({
        baseUrl: urlObject.href,
        paramName: URI_PARAM_NAME.TABLE_ROW_IDS_FILTER,
        paramValue: [toLower(rowIdType), rowIds.join(',')].join('~'),
      })
    }

    return urlObject
  },

  /**
   * @param urlSearchParams {URLSearchParams}
   * @returns {object}
   */
  getObjectValue({ urlSearchParams }) {
    const dashboardType = urlSearchParams.get(URI_PARAM_NAME.TABLE)
    const transformed = toUpper(dashboardType || '')

    return {
      dashboardType: DashboardUriUtils.isValidDashboardTypeParam(transformed)
        ? transformed
        : undefined,
    }
  },

  /**
   * @returns {URL}
   */
  clear() {
    return DashboardUriUtils.deleteSearchParam({
      paramName: URI_PARAM_NAME.TABLE,
    })
  },
}

DashboardUriUtils.Page = {
  /**
   * @param baseUrl {string | undefined}
   * @param pageIndex {number | string | undefined}
   * @returns {URL}
   */
  set({ baseUrl, pageIndex }) {
    return DashboardUriUtils.setSearchParam({
      baseUrl,
      paramName: URI_PARAM_NAME.PAGE,
      paramValue: pageIndex >= 0 ? String(pageIndex) : undefined,
    })
  },

  /**
   * @param urlSearchParams {URLSearchParams}
   * @returns {object}
   */
  getObjectValue({ urlSearchParams }) {
    const pageIndex = urlSearchParams.get(URI_PARAM_NAME.PAGE) || 0

    return {
      pageIndex: Number(pageIndex),
    }
  },

  /**
   * @returns {URL}
   */
  clear() {
    return DashboardUriUtils.deleteSearchParam({
      paramName: URI_PARAM_NAME.PAGE,
    })
  },
}

DashboardUriUtils.Filter = {
  /**
   * @param baseUrl {string | undefined}
   * @param searchParamString {string | undefined}
   * @returns {URL}
   */
  set({ baseUrl, searchParamString }) {
    return DashboardUriUtils.setSearchParam({
      baseUrl,
      paramName: URI_PARAM_NAME.FILTER,
      paramValue: searchParamString,
    })
  },

  /**
   * @returns {string}
   */
  regexPattern() {
    /**
     * DashboardEnumV2.Type 중 하나(필수).
     * ex:
     *  campaign.name-contain~"a" -> "campaign"
     *  abc.name-contain~"a" -> null
     */
    const captureGroup1 = `(${DashboardEnumV2.values().join('|')})`
    /**
     * FILTER_TYPE 중 하나(필수).
     * ex:
     *  campaign.name-contain~"a" -> "name"
     *  campaign.abc-contain~"a" -> null
     */
    const captureGroup2 = `(${Object.keys(FILTER_TYPE).join('|')})`
    /**
     * dot(.)으로 시작하는 0개 혹은 1개 이상의 문자(optional).
     * dot(.) 은 기억하지 않는다.
     * ex:
     *  campaign.campaign_metric.cost-gt~"300" -> "cost"
     *  campaign.campaign_metric.abc-gt~"300" -> "abc"
     */
    const captureGroup3 = `([.].*)?`
    /**
     * FILTER_OPERATOR 중 하나(필수).
     * ex:
     *  campaign.campaign_metric.cost-gt~"300" -> "gt"
     *  campaign.campaign_metric.imp-lt~"300" -> "lt"
     */
    const captureGroup4 = `(${Object.keys(FILTER_OPERATOR).join('|')})`
    /**
     * (1~50자 value or 콤마(,)로 구분된 value) + 공백1
     * 뒤 `공백1`은 각 필터 섫정 간 구분자 역할로 반드시 필요하다.
     * 필터 설정이 1개일 경우, 공백1이 없기 때문에 패턴 매칭 시 항상 맨 끝 공백1을 강제로 추가 후 매칭한다.
     * ex:
     *  ...~"ABCDEF" -> "ABCDEF"
     *  ...~"DISPLAY,VIDEO,MESSAGE" -> "DISPLAY,VIDEO,MESSAGE"
     */
    const captureGroup5 = '"((.{1,50})|(.+,*.*))"\\s{1}'
    /**
     * ex1: campaign.id~contain~"캠페인명" -> [campaign, id, contain, "캠페인명"]
     * ex2: campaign.campaign_metric.imp-gt~"300" -> [campaign, campaign_metric, img, gt, "300"]
     *
     */
    return `${captureGroup1}.${captureGroup2}${captureGroup3}-${captureGroup4}~${captureGroup5}`
  },

  Transform: {
    stringToConditionValue({
      dashboardType,
      filterType,
      filterSubType,
      filterOperator,
      filterValue,
    }) {
      const transformedDashboardType = toUpper(dashboardType)
      const transformedFilterType = toUpper(filterType)
      const transformedFilterSubType = filterSubType
      const transformedFilterOperator = toUpper(filterOperator)
      const transformedFilterValue =
        DashboardFilterUtils.takeOnlyFirstValueOfArray({
          filterType: transformedFilterType,
        })
          ? [filterValue]
          : uniq(filterValue.split(','))

      const conditionValueObject = {
        dashboardType: transformedDashboardType,
        filterType: transformedFilterType,
        filterSubType: transformedFilterSubType,
        filterOperator: transformedFilterOperator,
        filterValue: transformedFilterValue,
      }

      /**
       * filterValue 정규화
       * - 선택 가능한 옵션의 경우 제공하는 옵션 외 값은 제거한다.
       */
      switch (conditionValueObject.filterType) {
        case FILTER_TYPE.OPERATION_STATUS: {
          const availableOperationStatusArray =
            DashboardFilterUtils.FilterValue.operationStatus({
              dashboardType: conditionValueObject.dashboardType,
            }).concat(DashboardStatusEnum.Type.DELETED)

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableOperationStatusArray.includes(v)
            )

          break
        }

        case FILTER_TYPE.REVIEW_STATUS: {
          const availableReviewStatusArray =
            DashboardFilterUtils.FilterValue.reviewStatus()

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableReviewStatusArray.includes(v)
            )

          break
        }

        case FILTER_TYPE.ON_OFF: {
          const availableOnOffArray = DashboardFilterUtils.FilterValue.onOff()

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableOnOffArray.includes(v)
            )

          break
        }

        case FILTER_TYPE.CAMPAIGN_TYPE: {
          const availableCampaignTypeArray = CampaignTypeEnum.values()

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableCampaignTypeArray.includes(v)
            )

          break
        }

        case FILTER_TYPE.GOAL: {
          const availableGoalArray = DashboardFilterUtils.FilterValue.goal()

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableGoalArray.includes(v)
            )

          break
        }

        case FILTER_TYPE.CREATIVE_FORMAT: {
          const availableCreativeFormatArray =
            DashboardFilterUtils.FilterValue.creativeFormat()

          conditionValueObject.filterValue =
            conditionValueObject.filterValue.filter(v =>
              availableCreativeFormatArray.includes(v)
            )

          break
        }

        default: {
          break
        }
      }

      return conditionValueObject
    },

    /**
     * 모먼트 데이터인 conditionValue 를 searchParam string 으로 변환한다.
     * - toLower
     */
    conditionValueToSearchParamString({
      dashboardType,
      filterType,
      filterSubType,
      filterOperator,
      filterValue,
    }) {
      const transformedDashboardType = toLower(dashboardType)
      const transformedFilterType = toLower(filterType)
      const transformedFilterSubType = filterSubType
      const transformedFilterOperator = toLower(filterOperator)
      const transFormedFilterValue1 =
        DashboardFilterUtils.takeOnlyFirstValueOfArray({
          filterType,
        })
          ? filterValue[0]
          : uniq(filterValue)

      const noneEmpty = checkNoneEmpty(
        transformedDashboardType,
        transformedFilterType,
        transformedFilterOperator,
        transFormedFilterValue1
      )

      if (noneEmpty) {
        if (
          DashboardFilterUtils.isMetric({ filterType }) &&
          !transformedFilterSubType
        ) {
          return null
        } else {
          const transFormedFilterValue2 = `"${
            DashboardFilterUtils.shouldValueEncode({
              filterType,
            })
              ? encodeURI(transFormedFilterValue1)
              : transFormedFilterValue1
          }"`

          return {
            dashboardType: transformedDashboardType,
            filterType: transformedFilterType,
            filterSubType: transformedFilterSubType,
            filterOperator: transformedFilterOperator,
            filterValue: transFormedFilterValue2,
          }
        }
      } else {
        return null
      }
    },
  },

  /**
   * conditionValue object 의 유효성 검증.
   * - conditionValue 는 url 입력값이 아닌 모먼트 내에서 관리되는 데이터들로 구성되어 toUpper 처리하지 않는다.
   */
  validateConditionValueObject(cvo = {}) {
    if (Array.isArray(cvo.filterValue) && cvo.filterValue.length > 0) {
      const isDashboardTypeValid = DashboardEnumV2.values().includes(
        cvo.dashboardType
      )

      const isFilterTypeValid = Object.keys(FILTER_TYPE).includes(
        cvo.filterType
      )

      const isFilterOperatorValid = Object.keys(FILTER_OPERATOR).includes(
        cvo.filterOperator
      )

      const maxLength = DashboardFilterUtils.maxLengthOfFilterValueString({
        filterType: cvo.filterType,
      })

      const maxCount = DashboardFilterUtils.maxCountOfFilterValueArray({
        filterType: cvo.filterType,
      })

      const isNumeric = DashboardFilterUtils.isFilterValueNumeric({
        filterType: cvo.filterType,
      })

      const isMetric = DashboardFilterUtils.isMetric({
        filterType: cvo.filterType,
      })

      const isFilterSubTypeValid = isMetric
        ? DashboardTableMetricIds.some(v => v.id === cvo.filterSubType)
        : true

      const isFilterValueStringLengthValid =
        maxLength > 0
          ? cvo.filterValue.every(v => validateInputLength(v, 1, maxLength))
          : true

      const isFilterValueDataTypeValid = isNumeric
        ? cvo.filterValue.every(v => {
            if (cvo.filterType === FILTER_TYPE.ID) {
              return isPositiveIntegerSequence(v)
            }

            if (isMetric) {
              return v >= 0.1
            }

            return true
          })
        : cvo.filterValue.every(v => !isUndefinedOrNull(v))

      const isFilterValueCountValid =
        maxCount > 0 ? cvo.filterValue.length <= maxCount : true

      return (
        isDashboardTypeValid &&
        isFilterTypeValid &&
        isFilterSubTypeValid &&
        isFilterOperatorValid &&
        isFilterValueStringLengthValid &&
        isFilterValueDataTypeValid &&
        isFilterValueCountValid
      )
    }

    return false
  },

  /**
   * @param urlSearchParams {URLSearchParams}
   * @returns {object}
   */
  getObjectValue({ urlSearchParams }) {
    const uriString = urlSearchParams.get(URI_PARAM_NAME.FILTER)

    if (!uriString) return {}

    let decodedUriString = ''

    try {
      decodedUriString = decodeURI(uriString)
    } catch (e) {
      // catches a malformed URI
      const errorMessage = `malformed URI: ${JSON.stringify({ uriString, e })}`

      Sentry.captureException(errorMessage)

      console.log(errorMessage)

      return {
        conditions: [],
      }
    }

    const regexPattern = this.regexPattern()

    const matchedStringSet = new Set()

    // 필터 간 끝(구분)을 위해 끝에 공백1을 추가한다.
    const decodedUriStringArray = [...decodedUriString.split(''), ' ']

    /**
     * full string 매칭 시, N개의 필터 설정이 항상 1개로 인식되는 것을 방지하기 위해
     * 앞 부분부터 string concatenate 를 수행하면서 매칭한다.
     * ex:
     *  uriString = `campaign.name-contain~"abc" ad_group.name-contain~"def" `
     *  -> uriStringArray = ['c', 'a', 'm', 'p'....]
     *    -> c.match(regexp) = skip
     *    -> ca.match(regexp) = skip
     *    -> ...
     *    -> campaign.name-contain~"abc" .match(regexp) = matched!
     *    -> (clear)
     *    -> a.match(regexp) = skip
     *    -> ...
     *    -> ad_group.name-contain~"def" .match(regexp) = matched!
     */
    decodedUriStringArray.reduce((concatenatedStr, char) => {
      const nextConcatenatedStr = concatenatedStr.concat(char)
      const regexMatchArray = nextConcatenatedStr.match(
        new RegExp(regexPattern, 'gi')
      )

      // 매칭
      if (regexMatchArray?.length > 0) {
        regexMatchArray.forEach(regexMatchString =>
          matchedStringSet.add(regexMatchString)
        )
        // 기존 매칭 str 비움.
        return ''
      } else {
        return nextConcatenatedStr
      }
    }, '')

    const conditionArray = []

    matchedStringSet.forEach(matchedString => {
      /**
       * 매칭된 각 string 을 다시 세분화한다(capture group).
       * https://developer.mozilla.org/ko/docs/Web/JavaScript/Reference/Global_Objects/RegExp/exec
       * - 0: 전체
       * - 1 ~ N: capture group
       */
      const regexExecArray = new RegExp(regexPattern, 'gi').exec(matchedString)

      const [
        ,
        dashboardType,
        filterType,
        filterSubType,
        filterOperator,
        filterValue,
      ] = regexExecArray || Array.from(Array(6))

      const stringValueObject = {
        dashboardType,
        filterType,
        /**
         * filterSubType is optional(only metric type)
         * substr(1): without dot(.)
         */
        filterSubType: filterSubType?.substr(1),
        filterOperator,
        filterValue,
      }

      const conditionValueObject =
        this.Transform.stringToConditionValue(stringValueObject)

      const isValidConditionValueObject =
        this.validateConditionValueObject(conditionValueObject)

      if (isValidConditionValueObject) {
        conditionArray.push(conditionValueObject)
      }
    }, [])

    return {
      conditions: conditionArray || [],
    }
  },

  /**
   *
   * @param conditionValueArray {array}
   * @returns {string[]}
   */
  createSearchParamStringArray({ conditionValueArray }) {
    return conditionValueArray.reduce((arr, conditionValueObject) => {
      const isValidConditionValueObject =
        this.validateConditionValueObject(conditionValueObject)

      if (isValidConditionValueObject) {
        const searchParamStringObject =
          this.Transform.conditionValueToSearchParamString(conditionValueObject)

        if (searchParamStringObject) {
          /**
           * campaign.goal
           * campaign.id
           * campaign.name
           * ad_group.ad_group_metric.click
           * creative.creative_format
           */
          const filterHead = [
            searchParamStringObject.dashboardType,
            searchParamStringObject.filterType,
            searchParamStringObject.filterSubType,
          ]
            .filter(Boolean)
            .join('.')

          /**
           * any~CONVERSION,VISITING
           * contain~123,456,789
           * eq~abc
           * gt~3000
           * any~IMAGE_NATIVE,IMAGE_BOX
           * @type {string}
           */
          const filterTail = [
            searchParamStringObject.filterOperator,
            searchParamStringObject.filterValue,
          ].join('~')

          /**
           *
           * campaign.goal-any~"CONVERSION,VISITING"
           * campaign.id-contain~"123,456,789"
           * campaign.name-eq~":ENCODED_STRING"
           * ad_group.ad_group_metric.click-gt~"3000"
           * creative.creative_format-any~"IMAGE_NATIVE,IMAGE_BOX"
           */
          const filter = [filterHead, filterTail].join('-')

          arr.push(filter)
        }
      }

      return arr
    }, [])
  },

  /**
   * @param conditionValueArray {array}
   * @returns {string}
   */
  createSearchParamString({ conditionValueArray = [] }) {
    return this.createSearchParamStringArray({ conditionValueArray }).join(' ')
  },

  /**
   * @returns {URL}
   */
  clear() {
    return DashboardUriUtils.deleteSearchParam({
      paramName: URI_PARAM_NAME.FILTER,
    })
  },
}

export { URI_PARAM_NAME, DashboardUriUtils }
