import {
  CONTEXTPATH,
  CRUX_ACCOUNT,
  fetchGet,
  fetchPost,
} from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'
import RequestLock from '../../utils/requestLock'
import CruxFetchUtils from '../../utils/cruxFetchUtils'

/**
 * 08.005 adAccount 조회 (08.001 대체)
 */
function fetchAdAccounts(adAccountId, queryParams = {}) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts:Page?userConfigs=ON,OFF&adPlatformTypes=KAKAOMOMENT&${queryString(
      { ...queryParams }
    )}`,
    adAccountId,
  })
}

/**
 * 08.011 adAccount 생성 (paw 3.7, 3.7.1, 3.7.2, 3.7.3)
 * @param requestPayload
 */
function createAdAccountForm(requestPayload) {
  return RequestLock.acquire({
    key: 'createAdAccountForm',
    executor: done =>
      CruxFetchUtils.post({
        url: `${CRUX_ACCOUNT}/adAccounts`,
        requestPayload,
      }).finally(done),
  })
}

/**
 * 08.211 adAccount 멤버 가입 신청 (paw 3.9)
 * @param adAccountId
 */
function requestJoinAdAccountMember(adAccountId) {
  return RequestLock.acquire({
    key: 'requestJoinAdAccountMember',
    executor: done =>
      CruxFetchUtils.post({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMember/join`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.202 adAccount adAccountMember 상태로 멤버 조회, 디폴트는 승인된(APPROVED, PENDING_MASTER) 멤버들만 조회 (paw 54.3, 3.8, 3.18, 3.20, 3.32)
 */
function fetchAllAdAccountMembers(adAccountId, pageIndex, size = 5) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMembers:Page?adAccountMemberStatuses=APPROVED,PENDING_MASTER&page=${pageIndex}&size=${size}`,
    adAccountId,
  })
}

/**
 * 08.261 adAccount 멤버 가입 요청 승인 (3.11)
 * @param adAccountId
 * @param adAccountMemberId
 */
function approveMemberRequested(adAccountId, adAccountMemberId) {
  return RequestLock.acquire({
    key: 'approveMemberRequested',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/join/approve`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.262 adAccount 멤버 가입 요청 거절 (3.12)
 * @param adAccountId
 * @param adAccountMemberId
 */
function declineMemberRequested(adAccountId, adAccountMemberId) {
  return RequestLock.acquire({
    key: 'declineMemberRequested',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/join/reject`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.221 adAccount 멤버로 초대 (3.14)
 * @param adAccountId
 * @param requestPayload
 */
function inviteAdAccountMember(adAccountId, requestPayload) {
  return RequestLock.acquire({
    key: 'inviteAdAccountMember',
    executor: done =>
      CruxFetchUtils.post({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMember/invite`,
        adAccountId,
        requestPayload,
      }).finally(done),
  })
}

/**
 * 08.271 adAccount 초대에 대한 멤버의 승인 (paw 3.15)
 * @param adAccountMemberId
 */
const approveInviteAdAccountMember = adAccountMemberId => {
  return RequestLock.acquire({
    key: 'approveInviteAdAccountMember',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/invite/approve`,
      }).finally(done),
  })
}

/**
 * 08.272 adAccount 초대에 대한 멤버의 거절 (paw 3.16)
 * @param adAccountMemberId
 */
const rejectInviteAdAccountMember = adAccountMemberId => {
  return RequestLock.acquire({
    key: 'rejectInviteAdAccountMember',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/invite/reject`,
      }).finally(done),
  })
}

/**
 * 08.273 adAccount 초대 마스터의 취소 (3.17)
 * @param adAccountId
 * @param adAccountMemberId
 */
function cancelMemberInvited(adAccountId, adAccountMemberId) {
  return RequestLock.acquire({
    key: 'cancelMemberInvited',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/invite/cancel`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.202 초대/요청중인 멤버 목록 조회
 * @param adAccountId
 * @param pageIndex
 * @param status
 */
function fetchRequestAndInvite(adAccountId, pageIndex, status) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMembers:Page?adAccountMemberStatuses=${status}&page=${pageIndex}&size=5`,
    adAccountId,
  })
}

/**
 * 08.202 마스터 권한 양도 요청 조회
 * @param adAccountId
 */
function fetchTransferMasterInfo(adAccountId) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMembers?adAccountMemberStatuses=PENDING_MASTER`,
    adAccountId,
  })
}

/**
 * 08.203 adAccount 멤버 요청 가능/거부 설정 (paw 3.50)
 * @param adAccountId
 * @param isRequestable
 */
function modifyMemberRequestable(adAccountId, isRequestable) {
  return RequestLock.acquire({
    key: 'modifyMemberRequestable',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMember/memberRequestable?isRequestable=${isRequestable}`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.281 adAccount 마스터 권한 양도 요청 (3.21)
 * @param adAccountId
 * @param adAccountMemberId
 */
function requestChangeMaster(adAccountId, adAccountMemberId) {
  return CruxFetchUtils.put({
    url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/transferMaster`,
    adAccountId,
  })
}

/**
 * 08.283 adAccount 마스터 권한 양도 요청 승인 (3.22)
 */
function approveChangeMasterById(adAccountId, adAccountMemberId) {
  return RequestLock.acquire({
    key: 'approveChangeMasterById',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/transferMaster/approve`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.284 adAccount 마스터 권한 양도 요청 거절 (3.23)
 * @param adAccountId
 * @param adAccountMemberId
 */
function declineChangeMaster(adAccountId, adAccountMemberId) {
  return RequestLock.acquire({
    key: 'declineChangeMaster',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/transferMaster/reject`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.222 adAccount 멤버 나가기 (3.24)
 */
function leaveMemberById(adAccountId) {
  return CruxFetchUtils.put({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMember/quit`,
    adAccountId,
  })
}

/**
 * 08.292 adAccount 멤버 내보내기(MASTER) (3.25)
 */
function releaseMemberById(adAccountId, adAccountMemberId) {
  return CruxFetchUtils.put({
    url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/release`,
    adAccountId,
  })
}

/**
 * 08.383 adAccount 세금 계산서 이메일 변경 (54.9, 3.27)
 * @param adAccountId
 * @param requestPayload
 */
function modifyTaxBillEmail(adAccountId, requestPayload) {
  return RequestLock.acquire({
    key: 'modifyTaxBillEmail',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/taxBillEmail`,
        requestPayload,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.002 adAccount 조회 (paw 51.3, 54.1, 3.28, 3.46, 32.6)
 * @param adAccountId
 * @param queryParams
 */
function fetchAdAccountInfoByAdAccountId(adAccountId, queryParams = {}) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}?${queryString(
      queryParams
    )}`,
    adAccountId,
  })
}

/**
 * 3.29.1.2 광고 계정 하위의 계층구조 조회 (메시지 캠페인) 메시지 발송 대상자 선택
 */
function getMessageRetargetAdGroupsWithCampaign(adAccountId) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/campaigns?type=PLUSFRIEND_GROUP`,
    adAccountId
  )
}

/**
 * 3.29.5 광고 계정 하위의 캠페인 및 광고그룹 조회 for 내 광고반응 타겟
 */
function getCampaignItemsByAdAccountId(
  adAccountId,
  audienceType,
  campaignTypeGoalId,
  searchKeyword
) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/campaigns?type=COHORT&audienceType=${audienceType}&campaignTypeGoalId=${campaignTypeGoalId}&searchKeyword=${encodeURIComponent(
      searchKeyword
    )}`,
    adAccountId
  )
}

/**
 * 08.282 adAccount 마스터 권한 양도 요청 취소 (3.33)
 * @param adAccountId
 * @param adAccountMemberId
 */
function cancelTransferMaster(adAccountId, adAccountMemberId) {
  return CruxFetchUtils.put({
    url: `${CRUX_ACCOUNT}/adAccountMembers/${adAccountMemberId}/transferMaster/cancel`,
    adAccountId,
  })
}

/**
 * 08.401 adAccount 영업권 기본정보 조회 (paw 54.10, 3.34, 31.1)
 * @param adAccountId
 * @param status COMPLETED | REQUESTED
 */
function fetchBusinessRights(adAccountId, status = 'COMPLETED') {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/bizRight?bizRightStatus=${status}`,
    adAccountId,
  })
}

/**
 * 09.01 adAccount 영업권 승인 (3.36)
 * @param adAccountId
 * @param bizRightId
 */
function approveBusinessRights(adAccountId, bizRightId) {
  return RequestLock.acquire({
    key: 'approveBusinessRights',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/bizRights/${bizRightId}/approve`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 09.02 adAccount 영업권 거절 (paw 3.37)
 * @param adAccountId
 * @param bizRightId
 */
function rejectBusinessRights(adAccountId, bizRightId) {
  return RequestLock.acquire({
    key: 'rejectBusinessRights',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/bizRights/${bizRightId}/reject`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 09.03 adAccount 영업권 해제 (paw 3.38)
 * @param adAccountId
 * @param bizRightId
 */
function releaseBusinessRights(adAccountId, bizRightId) {
  return RequestLock.acquire({
    key: 'releaseBusinessRights',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/bizRights/${bizRightId}/release`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.013 adAccount 삭제 요청 (paw 3.40)
 * @param adAccountId
 */
function deleteAdAccountV2(adAccountId) {
  return RequestLock.acquire({
    key: 'deleteAdAccountV2',
    executor: done =>
      CruxFetchUtils.delete({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}`,
        adAccountId,
      }).finally(done),
  })
}

function fetchAdAccountDeleteCheck(adAccountId) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/delete/check`,
    adAccountId,
  })
}

/**
 * 08.014 adAccount 삭제 요청 취소 (paw 3.41)
 * @param adAccountId
 */
function cancelDeleteAdAccountV2(adAccountId) {
  return RequestLock.acquire({
    key: 'cancelDeleteAdAccountV2',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/delete/cancel`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.241 adAccount 멤버 히스토리 조회
 * @param adAccountId
 * @param pageIndex
 */
function fetchMemberHistory(adAccountId, pageIndex = 0) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMemberHistories?page=${pageIndex}&size=10`,
    adAccountId,
  })
}

/**
 * 08.403 adAccount 영업권 이전 신청 가능 여부 변경 (paw 3.43)
 * @param adAccountId
 * @param transferBizRight
 */
function modifyAvailableTransferBusinessRight(adAccountId, transferBizRight) {
  return RequestLock.acquire({
    key: 'modifyAvailableTransferBusinessRight',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/bizRightTransferable?transferBizRight=${transferBizRight}`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.101 adAccount 이름 변경 (paw 3.44)
 * @param adAccountId
 * @param name
 */
function modifyAdAccountName(adAccountId, name) {
  return RequestLock.acquire({
    key: 'modifyAdAccountName',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/name?name=${name}`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.301 adAccount 환불계좌 실명 정보 조회 (paw 3.57, 51.13)
 */
function fetchAdAccountRefundOwnName(adAccountId) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/refundOwnName`,
    adAccountId,
  })
}

/**
 * 3.62 카카오톡 채널에 카카오모먼트 광고 보내기 연결
 */
function connectPlusFriendByProfileId(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/connect/plusFriend?profileId=${id}`,
    adAccountId
  )
}

/**
 * 3.63 광고계정 Preference 가져오기.
 * @param adAccountId
 */
function getAdAccountPreference(adAccountId) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/preference`,
    adAccountId
  )
}

/**
 * 3.64 광고계정 Preference 저장.
 * @param adAccountId
 * @param body
 */
function updateAdAccountPreference(adAccountId, body) {
  return fetchPost(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/preference`,
    body,
    adAccountId
  )
}

/**
 * 08.703 adAccount 위임 발행 히스토리 조회 (3.67)
 * @param adAccountId
 * @param urlParams
 */
function fetchDelegationHistory(adAccountId, urlParams) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/delegationHistory?${queryString(
      urlParams
    )}`,

    adAccountId,
  })
}

/**
 * 08.702 adAccount 광고주 위임발행 상태 변경(승인, 거부, 해지) (3.68)
 * @param adAccountId
 * @param bizRightId
 * @param delegationStatus
 */
function modifyDelegationInvoiceStatus(
  adAccountId,
  bizRightId,
  delegationStatus
) {
  return RequestLock.acquire({
    key: 'modifyDelegationInvoiceStatus',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/bizRights/${bizRightId}/delegation/modify?delegationStatus=${delegationStatus}`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.251 adAccount 멤버 상태별 내 광고계정 목록 조회 (paw 61.3), 멤버 상태별 내 광고계정 수 (paw 61.2)
 *  내 광고계정 - 모먼트 화면에서 사용
 * @param queryParams
 */
const fetchAdAccountMembers = queryParams => {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccountMembers:Page?adPlatformTypes=KAKAOMOMENT&${queryString(
      {
        ...queryParams,
        searchKeyword: encodeURIComponent(queryParams.searchKeyword),
      }
    )}`,
  })
}

/**
 * 08.212 adAccount 멤버 가입 신청 취소 (paw 3.13)
 * @param adAccountId
 */
const cancelAdAccountMemberJoin = adAccountId => {
  return RequestLock.acquire({
    key: 'cancelAdAccountMemberJoin',
    executor: done =>
      CruxFetchUtils.put({
        url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/adAccountMember/join/cancel`,
        adAccountId,
      }).finally(done),
  })
}

/**
 * 08.808 adAccount alive & 멤버 요청 가능 상태 검색 (paw 3.5)
 * @param queryParams
 */
const fetchAdAccountsSearchMemberRequestable = queryParams => {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/searchMemberRequestable?adPlatformTypes=KAKAOMOMENT&${queryString(
      {
        ...queryParams,
        searchKeyword: encodeURIComponent(queryParams.searchKeyword),
      }
    )}&sort=name,ASC`,
  })
}

/**
 * 08.213 adAccount 여러 광고계정에 멤버 가입 (paw 3.10)
 * @param adAccountIds
 */
const requestAdAccountMemberMultiJoin = adAccountIds => {
  return CruxFetchUtils.post({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountIds}/adAccountMember/multiJoin`,
  })
}

/**
 * 08.906 비즈니스계정 자산 소속 조회
 * @param adAccountId
 * @param assetStatus
 */
const fetchAdAccountBusinessAssets = (adAccountId, assetStatus) => {
  return CruxFetchUtils.get({
    key: 'businessAssets',
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/businessAssets?assetStatus=${assetStatus}`,
    adAccountId,
  })
}

export default {
  fetchAdAccounts,
  releaseMemberById,
  leaveMemberById,
  approveChangeMasterById,
  getMessageRetargetAdGroupsWithCampaign,
  getCampaignItemsByAdAccountId,
  connectPlusFriendByProfileId,
  getAdAccountPreference,
  updateAdAccountPreference,
  fetchAdAccountMembers,
  cancelAdAccountMemberJoin,
  fetchAdAccountsSearchMemberRequestable,
  requestAdAccountMemberMultiJoin,
  approveInviteAdAccountMember,
  rejectInviteAdAccountMember,
  fetchMemberHistory,
  fetchAllAdAccountMembers,
  fetchTransferMasterInfo,
  fetchRequestAndInvite,
  approveMemberRequested,
  declineMemberRequested,
  cancelMemberInvited,
  inviteAdAccountMember,
  declineChangeMaster,
  requestChangeMaster,
  cancelTransferMaster,
  fetchBusinessRights,
  approveBusinessRights,
  rejectBusinessRights,
  modifyDelegationInvoiceStatus,
  modifyTaxBillEmail,
  fetchDelegationHistory,
  modifyAdAccountName,
  deleteAdAccountV2,
  cancelDeleteAdAccountV2,
  createAdAccountForm,
  requestJoinAdAccountMember,
  fetchAdAccountInfoByAdAccountId,
  fetchAdAccountRefundOwnName,
  modifyAvailableTransferBusinessRight,
  releaseBusinessRights,
  modifyMemberRequestable,
  fetchAdAccountBusinessAssets,
  fetchAdAccountDeleteCheck,
}
