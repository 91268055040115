import { createReducer, combineReducers } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { DemoLocationHelper } from '../../../utils/helper/helper-demoGraphic'
import { keyMirror } from '../../../utils/utils'
import {
  AUDIENCE_TARGET,
  audienceTotalTargetingKeys,
} from '../../../components/Targeting/Audience/Common/audience'
import {
  updateAudienceCombinedPopulation,
  updateAudienceTargetPopulation,
} from './mAudienceCommon'
import {
  LOCATION_TYPE,
  MY_DATA_TARGET_KEY,
} from '../../../utils/advertise/targeting'
import audienceList from './mAudienceList'
import AudienceTypeEnum from '../../../enums/AudienceTypeEnum'

const Audience = keyMirror(
  {
    INIT_FORM: null,
    SET_AUDIENCE_FORM: null,
    CHANGE_FORM_BY_KEY: null,
    CHANGE_VIEW_STATE_BY_KEY: null,
    CHANGE_VALIDATION_ERROR_KEY: null,
    INIT_VALIDATION_ERROR_KEY: null,
  },
  'AUDIENCE'
)

const initialState = fromJS({
  audienceForm: {
    id: -1,
    adAccountId: -1, // 필수값
    name: '', // 필수값
    audienceTarget: AUDIENCE_TARGET.AUDIENCE,
    audienceType: AudienceTypeEnum.Type.NORMAL,
    saveTarget: true, // 필수값

    // 맞춤타겟
    cohortTargetings: [],
    trackerTargetings: [],
    plusFriendTargetings: [],
    syncAppTargetings: [],
    customerFileTargetings: [],
    plusFriendGroupTargetings: [],

    // 추가설정 타겟
    ufoInterests: [],
    ufoBusinessTypes: [],

    // 데모그래픽
    fiveAges: [],
    genders: [],
    locations: DemoLocationHelper().createNational(),

    /**
     * @deprecated
     */
    services: [], // 서비스
    keywords: [], // 키워드 타겟
    momentLocations: [], // 현재위치 타겟
    lookalikeTargetings: [], // 유사타겟
    depth2Locations: [], // 시/군/구 타겟
  },

  audienceViewState: {
    selectedLocationType: LOCATION_TYPE.ALL,
    availableMyDataTargets: [],
    availableAdditionalDataTargets: [],
  },

  validationErrorKeys: {},
})

const audienceCreate = createReducer(initialState, {
  [Audience.INIT_FORM]: state =>
    state.withMutations(s =>
      s
        .set('audienceForm', initialState.get('audienceForm'))
        .set('audienceViewState', initialState.get('audienceViewState'))
        .set('validationErrorKeys', initialState.get('validationErrorKeys'))
    ),

  [Audience.SET_AUDIENCE_FORM]: (state, { data }) =>
    state.set('audienceForm', fromJS(data)),

  [Audience.CHANGE_FORM_BY_KEY]: (state, { key, value }) =>
    state.setIn(['audienceForm', key], fromJS(value)),

  [Audience.CHANGE_VIEW_STATE_BY_KEY]: (state, { key, value }) =>
    state.setIn(['audienceViewState', key], value),

  [Audience.CHANGE_VALIDATION_ERROR_KEY]: (state, { key, isValid, message }) =>
    state.set(
      'validationErrorKeys',
      isValid
        ? state.get('validationErrorKeys').delete(key)
        : state.get('validationErrorKeys').set(key, message)
    ),
  [Audience.INIT_VALIDATION_ERROR_KEY]: state =>
    state.set('validationErrorKeys', initialState.get('validationErrorKeys')),
})

export const initAudienceForm = () => ({
  type: Audience.INIT_FORM,
})

export const initAudienceValidationErrorKeys = () => ({
  type: Audience.INIT_VALIDATION_ERROR_KEY,
})

export const setAudienceForm = data => ({
  type: Audience.SET_AUDIENCE_FORM,
  data,
})

export const changeAudienceViewState = (key, value) => ({
  type: Audience.CHANGE_VIEW_STATE_BY_KEY,
  key,
  value,
})

export const changeAudienceForm = (key, value) => {
  return dispatch => {
    dispatch({
      type: Audience.CHANGE_FORM_BY_KEY,
      key,
      value,
    })

    const isAvailableTargetScore = Object.keys(MY_DATA_TARGET_KEY).includes(key)
    if (isAvailableTargetScore) {
      dispatch(updateAudienceTargetPopulation())
    }

    const isAvailableCombinedScore = audienceTotalTargetingKeys.includes(key)
    if (isAvailableCombinedScore) {
      dispatch(updateAudienceCombinedPopulation())
    }
  }
}

export const changeAudienceFormValidationKeys = (key, isValid, message) => {
  return {
    type: Audience.CHANGE_VALIDATION_ERROR_KEY,
    key,
    isValid,
    message,
  }
}

export default combineReducers({
  audienceCreate,
  audienceList,
})
