import { Map } from 'immutable'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  checkEmpty,
  checkKakaoTvUrl,
  checkNotEmpty,
  checkTalkScheme,
  checkUrl,
  checkUrlHasWhiteSpace,
  hasFullWidthWhitespace,
  hasUrl,
} from '../../utils/regexUtils'
import TalkChannelMessageEnum from '../../enums/TalkChannelMessageEnum'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { BASIC_TEXT_MESSAGE_FORM_VALIDATOR } from './basicTextMessageValidator'
import { WIDE_MESSAGE_FORM_VALIDATOR } from './wideMessageValidator'
import { MESSAGE_FORM_VALIDATION_KEY } from './messageFormValidationKey'
import { MESSAGE_FORM_VALIDATION_MESSAGE } from './messageFormValidationMessage'
import { countCharacterOccurrences } from '../../utils/stringUtils'
import { CAROUSEL_COMMERCE_MESSAGE_FORM_VALIDATOR } from './carouselCommerceMessageValidator'
import { CAROUSEL_FEED_MESSAGE_FORM_VALIDATOR } from './carouselFeedMessageValidator'
import CouponBookTitleTypeEnum from '../../enums/CouponBookTitleTypeEnum'
import CouponBookTypeEnum from '../../enums/CouponBookTypeEnum'
import { PREMIUM_VIDEO_MESSAGE_FORM_VALIDATOR } from './premiumVideoValidator'
import { PERSONAL_MESSAGE_BASIC_TEXT_FORM_VALIDATOR } from '../personalMessage/personalMessageBasicTextValidator'
import { PERSONAL_MESSAGE_WIDE_FORM_VALIDATOR } from '../personalMessage/personalMessageWideMessageValidator'
import { PERSONAL_MESSAGE_CAROUSEL_COMMERCE_FORM_VALIDATOR } from '../personalMessage/personalMessageCarouselCommerceValidator'
import { PERSONAL_MESSAGE_CAROUSEL_FEED_FORM_VALIDATOR } from '../personalMessage/personalMessageCarouselFeedValidator'
import { PERSONAL_MESSAGE_PREMIUM_VIDEO_FORM_VALIDATOR } from '../personalMessage/personalMessagePremiumVideoValidator'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'

export const MESSAGE_FORM_VALIDATION_KEY_PATH = Map({
  [MESSAGE_FORM_VALIDATION_KEY.NAME]: ['name'],
  [MESSAGE_FORM_VALIDATION_KEY.TITLE]: [],
  [MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]: ['buttonAssetGroups'],
  [MESSAGE_FORM_VALIDATION_KEY.COUPON_BOOK_ASSET_GROUPS]: [
    'couponBookAssetGroups',
  ],
  [MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: [],
  [MESSAGE_FORM_VALIDATION_KEY.DESCRIPTION]: [],
  [MESSAGE_FORM_VALIDATION_KEY.ITEM]: [],
  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_INTRO_LANDING]: [],
  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_MORE]: [],
  [MESSAGE_FORM_VALIDATION_KEY.VIDEO_TITLE]: [],
})

export const messageFormButtonValidation = button => {
  const { title, landingType } = button

  if (landingType !== TalkChannelMessageEnum.Landing.Type.NONE) {
    if (checkEmpty(title)) {
      return IS_NOT_VALID(
        `버튼명을 ${
          landingType !== TalkChannelMessageEnum.Landing.Type.BIZ_FORM
            ? '입력'
            : '선택'
        }하세요`
      )
    } else if (hasFullWidthWhitespace(title)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
  }
  return IS_VALID()
}

export const messageFormItemValidation = (itemAsset, isRequired) => {
  const { image, video } = itemAsset

  if (isRequired) {
    if (checkEmpty(image) && checkEmpty(video)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
  }
  return IS_VALID()
}

export const messageFormLandingValidation = ({
  landingInfo,
  isRequired,
  creativeFormat,
}) => {
  const {
    landingType,
    mobileLandingUrl,
    channelCouponId,
    channelPostId,
    bizFormId,
    adViewId,
    talkStoreLandingUrl,
    webembedChannelUrl,
  } = landingInfo

  if (isRequired && landingType === TalkChannelMessageEnum.Landing.Type.NONE) {
    return IS_NOT_VALID('랜딩을 선택하세요')
  } else {
    switch (landingType) {
      case TalkChannelMessageEnum.Landing.Type.LANDING_URL: {
        if (checkEmpty(mobileLandingUrl)) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }

        if (
          creativeFormat === CreativeFormatEnum.Type.WIDE_MESSAGE &&
          checkKakaoTvUrl(String(mobileLandingUrl).trim())
        ) {
          return IS_NOT_VALID(
            MESSAGE_FORM_VALIDATION_MESSAGE.IS_NOT_ALLOWED_TV_LANDING_URL
          )
        }

        if (
          !(checkUrl(mobileLandingUrl) || checkTalkScheme(mobileLandingUrl))
        ) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LANDING_URL)
        }

        if (checkUrlHasWhiteSpace(mobileLandingUrl)) {
          return IS_NOT_VALID(
            MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
          )
        }

        return IS_VALID()
      }
      case TalkChannelMessageEnum.Landing.Type.CHANNEL_POST:
        if (checkEmpty(channelPostId) || channelPostId < 0) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        } else {
          return IS_VALID()
        }
      case TalkChannelMessageEnum.Landing.Type.CHANNEL_COUPON:
        if (checkEmpty(channelCouponId) || channelCouponId < 0) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        } else {
          return IS_VALID()
        }
      case TalkChannelMessageEnum.Landing.Type.BIZ_FORM:
        if (checkEmpty(bizFormId) || bizFormId < 0) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        } else {
          return IS_VALID()
        }
      case TalkChannelMessageEnum.Landing.Type.AD_VIEW:
        if (checkEmpty(adViewId) || adViewId < 0) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        } else {
          return IS_VALID()
        }
      case TalkChannelMessageEnum.Landing.Type.TALK_STORE_LANDING:
        if (checkEmpty(talkStoreLandingUrl)) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        } else {
          return IS_VALID()
        }
      case TalkChannelMessageEnum.Landing.Type.WEBEMBED_CHANNEL_URL: {
        if (checkEmpty(webembedChannelUrl)) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
        }

        if (!checkUrl(webembedChannelUrl)) {
          return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LANDING_URL)
        }

        if (checkUrlHasWhiteSpace(webembedChannelUrl)) {
          return IS_NOT_VALID(
            MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
          )
        }

        return IS_VALID()
      }
      default:
        return IS_VALID()
    }
  }
}
export const getMessageValidatorByMessageType = ({
  campaignType,
  creativeFormat,
}) => {
  if (campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE: {
        return PERSONAL_MESSAGE_BASIC_TEXT_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE: {
        return PERSONAL_MESSAGE_WIDE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE: {
        return PERSONAL_MESSAGE_CAROUSEL_COMMERCE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return PERSONAL_MESSAGE_CAROUSEL_FEED_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return PERSONAL_MESSAGE_PREMIUM_VIDEO_FORM_VALIDATOR
      }
      default: {
        return null
      }
    }
  } else {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE: {
        return BASIC_TEXT_MESSAGE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE: {
        return WIDE_MESSAGE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE: {
        return CAROUSEL_COMMERCE_MESSAGE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return CAROUSEL_FEED_MESSAGE_FORM_VALIDATOR
      }
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return PREMIUM_VIDEO_MESSAGE_FORM_VALIDATOR
      }
      default: {
        return null
      }
    }
  }
}

export const messageFormButtonAssetGroupValidation = buttonAssetGroups => {
  const buttonAssetGroupsValidationResults = []
  buttonAssetGroups.forEach(buttonAsset => {
    const { ordering, pcLandingUrl } = buttonAsset
    const buttonTitleValidationResult = messageFormButtonValidation(buttonAsset)
    const buttonLandingValidationResult = messageFormLandingValidation({
      landingInfo: buttonAsset,
      isRequired: false,
    })
    const buttonPcLandingValidationResult =
      messageFormPcLandingValidation(pcLandingUrl)

    const isValid = [
      buttonTitleValidationResult,
      buttonLandingValidationResult,
      buttonPcLandingValidationResult,
    ].every(v => v.isValid)

    buttonAssetGroupsValidationResults.push({
      ordering,
      isValid,
      title: buttonTitleValidationResult,
      landing: buttonLandingValidationResult,
      pcLanding: buttonPcLandingValidationResult,
    })
  })

  const isAllValid = buttonAssetGroupsValidationResults.every(v => {
    return v.isValid
  })

  return isAllValid
    ? IS_VALID()
    : IS_NOT_VALID('', { buttonAssetGroupsValidationResults })
}

export const messageFormTitleValidation = (title, creativeFormat) => {
  const count =
    creativeFormat === CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE
      ? 29
      : creativeFormat === CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE ||
        creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE
      ? 0
      : 1

  if (checkEmpty(title)) {
    return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  } else if (countCharacterOccurrences(title, '\n') > count) {
    return IS_NOT_VALID(`줄바꿈은 ${count}개까지 입력 가능합니다.`)
  } else if (hasUrl(title)) {
    return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LINK_IS_NOT_AVAILABLE)
  } else if (hasFullWidthWhitespace(title)) {
    return IS_NOT_VALID(
      MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
    )
  } else {
    return IS_VALID()
  }
}

export const messageFormPcLandingValidation = pcLandingUrl => {
  if (checkNotEmpty(pcLandingUrl)) {
    if (!checkUrl(pcLandingUrl) && !checkTalkScheme(pcLandingUrl)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    }

    if (checkUrlHasWhiteSpace(pcLandingUrl)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
      )
    }
  }

  return IS_VALID()
}

export const messageFormDescriptionValidation = (
  description,
  creativeFormat
) => {
  switch (creativeFormat) {
    case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
      const textNewLineCount = 2
      if (checkEmpty(description)) {
        return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      } else if (
        countCharacterOccurrences(description, '\n') > textNewLineCount
      ) {
        return IS_NOT_VALID(
          `줄바꿈은 ${textNewLineCount}개까지 입력 가능합니다.`
        )
      } else if (hasUrl(description)) {
        return IS_NOT_VALID(
          MESSAGE_FORM_VALIDATION_MESSAGE.LINK_IS_NOT_AVAILABLE
        )
      } else if (hasFullWidthWhitespace(description)) {
        return IS_NOT_VALID(
          MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      } else {
        return IS_VALID()
      }
    }

    default:
      return IS_VALID()
  }
}

export const messageFromCouponBookTypeValidation = couponBook => {
  const { couponBookType } = couponBook
  if (couponBookType === CouponBookTypeEnum.Type.SET_COUPON_BOOK) {
    return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  }
  return IS_VALID()
}

export const messageFromCouponBookMobileUrlValidation = couponBook => {
  const { couponBookType, mobileLandingUrl } = couponBook
  if (couponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK) {
    if (checkEmpty(mobileLandingUrl)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (!checkUrl(mobileLandingUrl)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    }

    if (checkUrlHasWhiteSpace(mobileLandingUrl)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
      )
    }
  }
  return IS_VALID()
}

export const messageFromCouponBookPcUrlValidation = couponBook => {
  const { couponBookType, pcLandingUrl } = couponBook
  if (
    couponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK &&
    checkNotEmpty(pcLandingUrl)
  ) {
    if (!checkUrl(pcLandingUrl)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.LANDING_URL)
    }

    if (checkUrlHasWhiteSpace(pcLandingUrl)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_WHITE_SPACE_BY_URL
      )
    }
  }
  return IS_VALID()
}

export const messageFromCouponBookChannelCouponsValidation = couponBook => {
  const { couponBookType, channelCoupons } = couponBook
  if (couponBookType === CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK) {
    if (channelCoupons.size === 0) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
  }
  return IS_VALID()
}

export const messageFormCouponBookTitleValidation = couponBook => {
  const { couponBookTitle, couponBookTitleType, couponBookType } = couponBook
  if (
    couponBookType &&
    couponBookTitleType !== CouponBookTitleTypeEnum.Type.FREE_SHIPPING
  ) {
    if (hasFullWidthWhitespace(couponBookTitle)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE,
        {
          couponBookTitleType,
        }
      )
    }
    if (
      checkEmpty(
        typeof couponBookTitle === 'string'
          ? couponBookTitle.trim()
          : couponBookTitle
      )
    ) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED, {
        couponBookTitleType,
      })
    }
    if (
      couponBookTitleType === CouponBookTitleTypeEnum.Type.DISCOUNT_PRICE &&
      couponBookTitle === 0
    ) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.IS_NOT_ALLOWED_ZERO_PRICE,
        { couponBookTitleType }
      )
    }
    if (
      couponBookTitleType === CouponBookTitleTypeEnum.Type.DISCOUNT_RATE &&
      couponBookTitle === 0
    ) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.IS_NOT_ALLOWED_ZERO_RATE,
        { couponBookTitleType }
      )
    }
  }
  return IS_VALID()
}

export const messageFormCouponBookDescriptionValidation = couponBook => {
  const { title, couponBookType } = couponBook
  if (couponBookType) {
    if (hasFullWidthWhitespace(title)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    if (checkEmpty(title.trim())) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
  }
  return IS_VALID()
}

export const messageCouponBookAssetGroupValidation = couponBookAssetGroups => {
  const couponBookAssetGroupsValidationResults = []
  couponBookAssetGroups.forEach(couponBookAsset => {
    const { ordering } = couponBookAsset
    const couponBookTitleValidationResult =
      messageFormCouponBookTitleValidation(couponBookAsset)
    const couponBookDescriptionValidationResult =
      messageFormCouponBookDescriptionValidation(couponBookAsset)
    const couponBookMobileUrlValidationResult =
      messageFromCouponBookMobileUrlValidation(couponBookAsset)
    const couponBookPcUrlValidationResult =
      messageFromCouponBookPcUrlValidation(couponBookAsset)
    const couponBookChannelCouponsValidationResult =
      messageFromCouponBookChannelCouponsValidation(couponBookAsset)
    const couponBookTypeValidationResult =
      messageFromCouponBookTypeValidation(couponBookAsset)

    const isValid = [
      couponBookTitleValidationResult,
      couponBookDescriptionValidationResult,
      couponBookMobileUrlValidationResult,
      couponBookPcUrlValidationResult,
      couponBookChannelCouponsValidationResult,
      couponBookTypeValidationResult,
    ].every(v => v.isValid)

    couponBookAssetGroupsValidationResults.push({
      ordering,
      isValid,
      couponBookTitle: couponBookTitleValidationResult,
      title: couponBookDescriptionValidationResult,
      mobileLandingUrl: couponBookMobileUrlValidationResult,
      pcLandingUrl: couponBookPcUrlValidationResult,
      channelCoupons: couponBookChannelCouponsValidationResult,
      couponBookType: couponBookTypeValidationResult,
    })
  })

  const isAllValid = couponBookAssetGroupsValidationResults.every(v => {
    return v.isValid
  })

  return isAllValid
    ? IS_VALID()
    : IS_NOT_VALID('', { couponBookAssetGroupsValidationResults })
}
