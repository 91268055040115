// modify: on/off, dailyBudget, bidAmount, delete...
import { convertToCommaSeparatedString } from '../utils'
import React from 'react'

export const DashboardToastMessage = {
  modifyLoading: (prefix = '') =>
    [prefix, '변경사항을 적용하는 중입니다.'].filter(Boolean).join(' ').trim(),
  modifySuccess: (prefix = '') =>
    [prefix, '변경사항이 적용되었습니다.'].filter(Boolean).join(' ').trim(),
  modifyFailure: (prefix = '') =>
    [prefix, '변경사항을 적용할 수 없습니다.'].filter(Boolean).join(' ').trim(),

  changeOnOffAdGroupSendingStatusResult: ({ successCount, failureCount }) => {
    const total = convertToCommaSeparatedString(successCount + failureCount)
    const success = convertToCommaSeparatedString(successCount)
    const failure = convertToCommaSeparatedString(failureCount)

    return (
      <>
        ON/OFF 변경으로 발송 중지 및 재개가 필요한
        <br />총 {total}개 광고그룹의 발송 상태 변경이 적용되었습니다.
        <br />
        (성공 {success}개, 실패 {failure}개)
        <br />
        ON/OFF 실패한 광고그룹을 확인하세요.
      </>
    )
  },

  changeAdGroupBidAmountResult: ({
    totalCount,
    successCount,
    failureCount,
  }) => {
    const total = convertToCommaSeparatedString(totalCount)
    const success = convertToCommaSeparatedString(successCount)
    const failure = convertToCommaSeparatedString(failureCount)

    return (
      <>
        선택한 광고그룹 {total}개 입찰금액 수정 처리가 완료되었습니다.
        <br />
        (성공 {success}개, 실패 {failure}개)
        <br />
        입찰금액 수정 실패한 광고그룹을 확인하세요.
      </>
    )
  },

  requestCreativeReviewResult: ({ totalCount, successCount, failureCount }) => {
    const total = convertToCommaSeparatedString(totalCount)
    const success = convertToCommaSeparatedString(successCount)
    const failure = convertToCommaSeparatedString(failureCount)

    return (
      <>
        선택한 소재 {total}개 재심사 요청 처리가 완료되었습니다.
        <br />
        (성공 {success}개, 실패 {failure}개)
        <br />
        재심사 요청 실패한 소재를 확인하세요.
      </>
    )
  },

  copyAdGroupResult: ({ totalCount, successCount, failureCount }) => {
    const total = convertToCommaSeparatedString(totalCount)
    const success = convertToCommaSeparatedString(successCount)
    const failure = convertToCommaSeparatedString(failureCount)

    return (
      <span>
        복사한 광고그룹 하위에 소재 {total}개 복사 처리가 완료되었습니다.
        <br />
        (성공 {success}개, 실패 {failure}개)
        <br />
        복사 실패한 소재를 확인하세요.
      </span>
    )
  },

  copyCreativeResult: ({ totalCount, successCount, failureCount }) => {
    const total = convertToCommaSeparatedString(totalCount)
    const success = convertToCommaSeparatedString(successCount)
    const failure = convertToCommaSeparatedString(failureCount)

    return (
      <>
        선택한 소재 {total}개 복사 처리가 완료되었습니다.
        <br />
        (성공 {success}개, 실패 {failure}개)
        <br />
        복사 실패한 소재를 확인하세요.
      </>
    )
  },
}
