import React from 'react'
import PropTypes from 'prop-types'

import useComponentId from '../../../../../utils/hook/useComponentId'
import { keyMirror } from '../../../../../utils/utils'
import classNames from 'classnames'

CmpDropArea.propTypes = {
  allowedMimeTypeString: PropTypes.string.isRequired,
  uploadType: PropTypes.string.isRequired,
  multiple: PropTypes.bool,

  onUpload: PropTypes.func,
}

export const UPLOAD_TYPE = keyMirror({
  VIDEO: null,
  IMAGE: null,
})

const uploadText = {
  [UPLOAD_TYPE.VIDEO]: '업로드할 동영상을 끌어다 놓으세요.',
  [UPLOAD_TYPE.IMAGE]: '업로드할 이미지를 끌어다 놓으세요.',
}

function CmpDropArea({
  allowedMimeTypeString,
  uploadType,
  multiple = false,
  onUpload,
}) {
  const uploadFileAreaId = useComponentId()

  return (
    <div className="drop_area">
      <div
        className={classNames('reform_item_file', {
          item_movie: uploadType === UPLOAD_TYPE.VIDEO,
        })}>
        <label htmlFor={uploadFileAreaId} className="file_info">
          <span className="reform_img_file">
            <span className="ico_comm reform_ico_img" />
          </span>
          <span className="reform_txt_file">
            <span className="txt_upload">{uploadText[uploadType]}</span>
          </span>
        </label>
        <input
          id={uploadFileAreaId}
          type="file"
          className="inp_file"
          accept={allowedMimeTypeString}
          onChange={onUpload}
          multiple={multiple}
        />
      </div>
    </div>
  )
}

export default CmpDropArea
