import { fromJS } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { combineReducers, createReducer } from 'redux-immutablejs'
import { PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY } from '../../validators/targeting/plusFriendGroupFormValidator'
import { showErrorMessage } from '../../utils/alertUtils'
import plusFriendGroupCreate from './plusFriendGroup/mPlusFriendGroupCreate'
import plusFriendGroupList from './plusFriendGroup/mPlusFriendGroupList'
import plusFriendGroupDetail from './plusFriendGroup/mPlusFriendGroupDetail'
import plusFriendGroupLoad from './plusFriendGroup/mPlusFriendGroupLoad'
import plusFriendGroupUpload from './plusFriendGroup/mPlusFriendGroupUpload'
import { TARGETING_POPULATION_CATEGORY_TYPE } from './mTargeting'
import TargetingInclusionEnum from '../../enums/TargetingInclusionEnum'
import { openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import React from 'react'
import { toPlusFriendGroupPath } from '../../utils/router/routeUtils'
import { ERROR_CODE } from '../../utils/errorCode'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

export const PLUS_FRIEND_GROUP_MAXIMUM = 50
export const PLUS_FRIEND_GROUP_FILE_UPLOAD_TRIM_KEY_PATH_LIST = [['name']]

const PlusFriendGroupKey = keyMirror({
  INIT_PLUS_FRIEND_GROUP_VALIDATION_ERROR_KEYS: null,
  SET_IS_VALID_PLUS_FRIEND_GROUP_BY_KEY: null,
})

export const PLUS_FRIEND_GROUP_STATUS = keyMirror({
  WAITING: null,
  COMPLETE: null,
  DELETE: null,
  ERROR: null,
})

export const PLUS_FRIEND_GROUP_STATUS_TEXT = {
  WAITING: '준비 중',
  COMPLETE: '조회 중',
  DELETE: '삭제 됨',
  ERROR: '생성 실패',
}

export const SENDING_TARGET = keyMirror({
  PLUS_FRIEND_GROUP: null,
  CUSTOM_AUDIENCE: null,
})

export const SENDING_TARGET_TEXT = {
  PLUS_FRIEND_GROUP: '친구 전체/그룹',
  CUSTOM_AUDIENCE: '맞춤타겟',
}

export const PLUS_FRIEND_GROUP_STEP = keyMirror({
  STEP_1: null,
  STEP_2: null,
  STEP_3: null,
})

export const PLUS_FRIEND_GROUP_STEP_TEXT = {
  STEP_1: '채널 선택',
  STEP_2: '유형 선택 및 파일 등록',
  STEP_3: '그룹 생성',
}

const PLUS_FRIEND_GROUP_ERROR_CODE = {
  PLUS_FRIEND_GROUP_FILE_NOT_EXIST: 1003,
  PLUS_FRIEND_GROUP_FILE_NAME_DUPLICATED: 1005,
  PLUS_FRIEND_GROUP_FILE_USED_BY_ADGROUP: 1035,
  PLUS_FRIEND_GROUP_FILE_USED_BY_AUDIENCE: 1036,
  PLUS_FRIEND_GROUP_FILE_NOT_MODIFIABLE: 1044,
}

const initialState = fromJS({
  validationErrorKeysForPlusFriendGroup: {},
})

const plusFriendGroupCommon = createReducer(initialState, {
  [PlusFriendGroupKey.INIT_PLUS_FRIEND_GROUP_VALIDATION_ERROR_KEYS]: state => {
    return state.set(
      'validationErrorKeysForPlusFriendGroup',
      initialState.get('validationErrorKeysForPlusFriendGroup')
    )
  },

  [PlusFriendGroupKey.SET_IS_VALID_PLUS_FRIEND_GROUP_BY_KEY]: (
    state,
    { data }
  ) => {
    const { validationErrorKeysForPlusFriendGroup } = state
    const { key, isValid, message } = data
    const newErrorKeys = isValid
      ? validationErrorKeysForPlusFriendGroup.delete(key)
      : validationErrorKeysForPlusFriendGroup.set(key, message)
    return state.set('validationErrorKeysForPlusFriendGroup', newErrorKeys)
  },
})

export function initPlusFriendGroupFileValidationErrorKeys() {
  return {
    type: PlusFriendGroupKey.INIT_PLUS_FRIEND_GROUP_VALIDATION_ERROR_KEYS,
  }
}

export function setIsValidPlusFriendGroupByKey(key, isValid, message) {
  return {
    type: PlusFriendGroupKey.SET_IS_VALID_PLUS_FRIEND_GROUP_BY_KEY,
    data: { key, isValid, message },
  }
}

export function handlePlusFriendGroupFileException(dispatch, e, adAccountId) {
  if (!e || !e.response || !e.response.data) return

  const { errorCode, message } = e.response.data
  switch (Number(errorCode)) {
    case PLUS_FRIEND_GROUP_ERROR_CODE.PLUS_FRIEND_GROUP_FILE_NAME_DUPLICATED: {
      dispatch(
        setIsValidPlusFriendGroupByKey(
          PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY.PLUS_FRIEND_GROUP_NAME,
          false,
          '이미 사용 중인 친구그룹 이름입니다.'
        )
      )
      break
    }

    case PLUS_FRIEND_GROUP_ERROR_CODE.PLUS_FRIEND_GROUP_FILE_NOT_EXIST: {
      showErrorMessage(message)
      dispatch(RouterV2.replace(toPlusFriendGroupPath(adAccountId)))
      break
    }

    case PLUS_FRIEND_GROUP_ERROR_CODE.PLUS_FRIEND_GROUP_FILE_USED_BY_ADGROUP:
    case PLUS_FRIEND_GROUP_ERROR_CODE.PLUS_FRIEND_GROUP_FILE_USED_BY_AUDIENCE: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">친구그룹 삭제 불가</strong>
            <p className="txt_layer">
              해당 타겟을 사용 중인 광고그룹 또는 오디언스가 있습니다.
              <br />
              타겟 사용현황을 참고하여 모든 광고그룹, 오디언스에서
              <br />
              타겟을 삭제한 후 다시 시도하세요.
            </p>
          </PopupProxy>
        )
      )
      break
    }

    case PLUS_FRIEND_GROUP_ERROR_CODE.PLUS_FRIEND_GROUP_FILE_NOT_MODIFIABLE: {
      showErrorMessage('수정이 불가능한 친구그룹입니다.')
      break
    }

    case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
      break

    default: {
      showErrorMessage(message)
    }
  }
}

export function buildPlusFriendGroupsPopulationBody(plusFriendGroups) {
  return plusFriendGroups.map(plusFriendGroup => {
    const { plusFriendProfileId, groupKey, inclusionType } = plusFriendGroup
    return {
      plusFriendProfileId,
      targetingValue: groupKey, // for Test : groupKey='ALL'
      inclusionType: inclusionType || TargetingInclusionEnum.Type.INCLUDE,
      targetingCategory:
        TARGETING_POPULATION_CATEGORY_TYPE.PLUS_FRIEND_GROUP_FILE,
    }
  })
}

export default combineReducers({
  plusFriendGroupCreate,
  plusFriendGroupList,
  plusFriendGroupDetail,
  plusFriendGroupLoad,
  plusFriendGroupUpload,
  plusFriendGroupCommon,
})
