import { CONTEXTPATH, fetchGet } from '../../utils/fetchUtils'

/**
 10.11 Gunsmith 메시지 상태 조회
 */

function getRobotReportsReadyStatus(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/robotReports/status?adGroupId=${adGroupId}`,
    adAccountId
  )
}

/**
 * 10.12 Demographics 로봇 분석 결과 조회
 */
function fetchDemographicReports(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/robotReports/demographics?adGroupId=${adGroupId}`,
    adAccountId
  )
}

/**
 * 10.13 UfoInterests 로봇 분석 결과 조회
 */
function fetchUfoInterestReports(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/robotReports/ufoInterests?adGroupId=${adGroupId}`,
    adAccountId
  )
}

/**
 * 10.14 UfoDemographicsCross 로봇 분석 결과 조회
 */
function fetchUfoDemographicCrossReports(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/robotReports/ufoDemographicsCross?adGroupId=${adGroupId}`,
    adAccountId
  )
}

export default {
  getRobotReportsReadyStatus,
  fetchDemographicReports,
  fetchUfoInterestReports,
  fetchUfoDemographicCrossReports,
}
