import React, { useMemo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MomentLoader } from '../../Common/Loader/MomentLoader'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { setDashboardOnOffSpinnerLoading } from '../../../modules/dashboardV3/mDashboardCommon'
import DynamicTooltip from '../../Tooltip/DynamicTooltip'

const OnOffSwitch = ({
  id,
  initialActive,
  userConfig,
  disabled,
  onChange,
  tooltip = '',
  isShowSpinnerForced,
}) => {
  const dispatch = useDispatch()
  const [active, setActive] = React.useState(initialActive)

  const spinnerLoadingKey = useMemo(
    () => `DASHBOARD_V3_ON_OFF_CELL_SPINNER_${id}`,
    [id]
  )

  const isShowingSpinner = useSelector(
    ({ loading }) => loading.has(spinnerLoadingKey),
    shallowEqual
  )

  React.useEffect(() => {
    if (active !== initialActive) {
      setActive(initialActive)
    }
  }, [active, initialActive])

  const handleChange = e => {
    e.preventDefault()

    if (userConfig === 'DEL') return

    const { checked } = e.target

    dispatch(setDashboardOnOffSpinnerLoading(spinnerLoadingKey))

    onChange(checked)
  }

  return isShowSpinnerForced || isShowingSpinner ? (
    <span className="reform_area_loading">
      <span className="group_loading loading_size2">
        <MomentLoader size={4} margin={2} />
      </span>
    </span>
  ) : (
    <span
      className={cx('switch_check', {
        on: active,
        in_active: disabled,
      })}>
      <input
        type="checkbox"
        id={id}
        name={id}
        className="inp_check"
        checked={active}
        disabled={disabled}
        onChange={handleChange}
      />
      <DynamicTooltip content={tooltip}>
        <label htmlFor={id} className="lab_check">
          <span className="ico_switch" />
        </label>
      </DynamicTooltip>
    </span>
  )
}

OnOffSwitch.displayName = 'OnOffSwitch'
OnOffSwitch.propTypes = {
  id: PropTypes.string.isRequired,
  initialActive: PropTypes.bool.isRequired,
  userConfig: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  tooltip: PropTypes.string,
  isShowSpinnerForced: PropTypes.bool,
}

export default React.memo(OnOffSwitch)
