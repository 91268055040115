import React from 'react'

const useDoubleClick = () => {
  const [isClickedOnce, setIsClickedOnce] = React.useState(false)
  const [isDoubleClicked, setIsDoubleClicked] = React.useState(false)

  React.useEffect(() => {
    if (isClickedOnce) {
      setTimeout(() => setIsClickedOnce(false), 300)
    }
  }, [isClickedOnce])

  const onPointerUp = () => {
    if (isClickedOnce) {
      setIsDoubleClicked(true)
      setIsClickedOnce(false)
    } else {
      setIsDoubleClicked(false)
      setIsClickedOnce(true)
    }
  }

  return {
    onPointerUp,
    isDoubleClicked,
  }
}

export { useDoubleClick }
