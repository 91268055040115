import { CONTEXTPATH, fetchGet } from '../../utils/fetchUtils'

/**
 * 7.6.9 카카오 데이터 카테고리 항목 조회(관심사/업종 조회)
 * @param adAccountId
 * @param type : [INTEREST, BUSINESS_TYPE]
 * @return {*}
 */
function fetchKakaoDataCategory(adAccountId, type) {
  return fetchGet(
    `${CONTEXTPATH}/targeting/kakaoDataCategory?type=${encodeURIComponent(
      type
    )}`,
    adAccountId
  )
}

/**
 * 지역 타게팅 목록 조회
 * @param adAccountId
 * @returns {AxiosPromise}
 */
function fetchLocationTargeting(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/targeting/locations`, adAccountId)
}

export default {
  fetchKakaoDataCategory,
  fetchLocationTargeting,
}
