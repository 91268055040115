import { CONTEXTPATH, fetchGet, queryString } from '../../utils/fetchUtils'

/**
 * 연동관리 > 상세 > 픽셀 & SDK 사용 현황 - 캠페인
 * @param adAccountId
 * @param trackId
 * @param queryParams
 */
function fetchDetailUsageCampaignList(adAccountId, trackId, queryParams) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/trackers/${trackId}/campaigns?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

/**
 * 연동관리 > 상세 > 픽셀 & SDK 사용 현황 - 광고그룹
 * @param adAccountId
 * @param trackId
 * @param queryParams
 */
function fetchDetailUsageAdGroupList(adAccountId, trackId, queryParams) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/trackers/${trackId}/adGroups?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

export default {
  fetchDetailUsageCampaignList,
  fetchDetailUsageAdGroupList,
}
