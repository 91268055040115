import React from 'react'
import PropTypes from 'prop-types'
import Slider from 'rc-slider'

const ImageEditorImageResizer = ({
  isSelectedAspect,
  zoom,
  minZoom,
  maxZoom,
  step,
  onComplete,
  imageEditHandler,
  isProcessing,
}) => {
  const handleSlideChange = React.useCallback(
    v => {
      imageEditHandler.onZoomChange(v)
      imageEditHandler.onRelease()
    },
    [imageEditHandler]
  )

  const handleZoomUp = React.useCallback(() => {
    if (zoom + step <= maxZoom) {
      imageEditHandler.onZoomChange(zoom + step)
      imageEditHandler.onRelease()
    }
  }, [maxZoom, step, zoom, imageEditHandler])

  const handleZoomDown = React.useCallback(() => {
    if (zoom - step >= minZoom) {
      imageEditHandler.onZoomChange(zoom - step)
      imageEditHandler.onRelease()
    }
  }, [minZoom, step, zoom, imageEditHandler])

  const handleReset = React.useCallback(() => {
    imageEditHandler.onReset()
    imageEditHandler.onRelease()
  }, [imageEditHandler])

  return (
    <div className="bnrimg_con">
      <div className="size_edit">
        <div className="control_bg">
          <div className="range_slider">
            <Slider
              dots={false}
              value={zoom}
              defaultValue={zoom}
              step={step}
              min={minZoom}
              max={maxZoom}
              onChange={handleSlideChange}
            />
          </div>
        </div>
        <div className="control_btn">
          <span className="f_left">
            <button
              type="button"
              className="btn_gs gs_line"
              onClick={handleZoomDown}>
              <span className="inner_g">- 이미지 축소</span>
            </button>
          </span>
          <span className="f_right">
            <button
              type="button"
              className="btn_gs gs_line"
              onClick={handleZoomUp}>
              <span className="inner_g">+ 이미지 확대</span>
            </button>
            <button
              type="button"
              className="btn_gs gs_line"
              onClick={handleReset}>
              <span className="inner_g">원본</span>
            </button>
            <button
              type="button"
              className="btn_gs"
              disabled={isProcessing}
              onClick={() => {
                isSelectedAspect ? imageEditHandler.onRelease() : onComplete()
              }}>
              <span className="inner_g">
                {isSelectedAspect ? '편집해제' : '편집적용'}
              </span>
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

ImageEditorImageResizer.propTypes = {
  isSelectedAspect: PropTypes.bool,
  zoom: PropTypes.number,
  minZoom: PropTypes.number,
  maxZoom: PropTypes.number,
  step: PropTypes.number,
  onComplete: PropTypes.func,
  imageEditHandler: PropTypes.object,
  isProcessing: PropTypes.bool,
}

export default ImageEditorImageResizer
