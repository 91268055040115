import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    COST: null,

    DEFAULT: null, // DISPLAY_DEFAULT + DISPLAY_ADDITIONAL
    MESSAGE_SENDING: null, // MESSAGE_DEFAULT + MESSAGE_ADDITIONAL

    PIXEL_AND_SDK: null,
    PLUS_FRIEND: null,

    VIDEO: null,
    CATALOG_SLIDE: null,
    MESSAGE: null, // MESSAGE_CLICK
    AD_VIEW: null,
    BIZ_BOARD_EXPANDABLE: null,
    SPONSORED_BOARD: null,
    TRIGGER: null,
  }),

  Name: {
    COST: '비용 지표',

    DEFAULT: '기본 지표',
    MESSAGE_SENDING: '발송 지표',

    PIXEL_AND_SDK: '픽셀 & SDK 전환 지표',
    PLUS_FRIEND: '카카오친구 지표',

    VIDEO: '동영상 지표',
    CATALOG_SLIDE: '슬라이드 지표',
    MESSAGE: '메시지 지표',
    AD_VIEW: '애드뷰 지표',
    BIZ_BOARD_EXPANDABLE: '비즈보드 지표',
    SPONSORED_BOARD: '보드 지표',
    TRIGGER: '전면형 유도 지표',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getDefaults() {
    return [this.Type.COST, this.Type.DEFAULT, this.Type.MESSAGE_SENDING]
  },

  isRelatedToCost(type) {
    return [this.Type.COST].includes(type)
  },

  isRelatedToPerformance(type) {
    return [this.Type.DEFAULT, this.Type.MESSAGE_SENDING].includes(type)
  },

  isRelatedToConversion(type) {
    return [this.Type.PIXEL_AND_SDK, this.Type.PLUS_FRIEND].includes(type)
  },

  isRelatedToCreative(type) {
    return [
      this.Type.VIDEO,
      this.Type.CATALOG_SLIDE,
      this.Type.MESSAGE,
      this.Type.AD_VIEW,
      this.Type.BIZ_BOARD_EXPANDABLE,
      this.Type.SPONSORED_BOARD,
      this.Type.TRIGGER,
    ].includes(type)
  },
}
