import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import DynamicTooltip from '../../Tooltip/DynamicTooltip'

/// 참고 : https://github.com/chenglou/react-radio-group/blob/master/index.jsx
/***
 * create by luke
 * children에 props으로 넘기는게 안되기 때문에 context를 활용
 * ex)
 <RadioGroup name="name"
 selectedValue={this.state.radio}
 onChange={this.handleChange}
 Component="dd">
 <Radio value={1} id="inptalkSelect1" label="레이블1" hasHelp={false} />
 <Radio value={2} id="inptalkSelect2" label="레이블2" hasHelp={true} />
 </RadioGroup>
 *
 *  note : InfoRadioGroup과 기능은 동일 하나 내부적인 마크업이 달라서 일단 분리 추후에 통합 고려
 */

const RadioGroupContext = React.createContext({
  radioGroup: {
    name: '',
    selectedValue: '',
    isEquals: ratioValue => false,
    disabled: false,
    onChange: () => undefined,
  },
})

export class RadioGroup extends React.Component {
  render() {
    const {
      Component,
      children,
      className,
      name,
      selectedValue,
      isEquals,
      disabled,
      onChange,
    } = this.props

    return (
      <RadioGroupContext.Provider
        value={{
          radioGroup: { name, selectedValue, isEquals, disabled, onChange },
        }}>
        <RadioGroupContext.Consumer>
          {value => (
            <Component className={className} {...value}>
              {children}
            </Component>
          )}
        </RadioGroupContext.Consumer>
      </RadioGroupContext.Provider>
    )
  }
}

RadioGroup.defaultProps = {
  Component: 'div',
  className: '',
}

RadioGroup.propTypes = {
  Component: PropTypes.string,
  name: PropTypes.string.isRequired,
  selectedValue: PropTypes.any,
  isEquals: PropTypes.func,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  children: PropTypes.any,
}

export class Radio extends React.Component {
  static contextType = RadioGroupContext

  render() {
    const {
      name,
      selectedValue,
      isEquals,
      onChange,
      disabled: allDisabled,
    } = this.context.radioGroup

    const {
      label,
      id,
      value,
      hasHelp,
      toolTipContent,
      baseClassName,
      className,
      disabled: eachDisabled,
    } = this.props

    const disabled = allDisabled || eachDisabled
    const checked =
      !allDisabled &&
      (typeof isEquals === 'function'
        ? isEquals(value)
        : value === selectedValue)

    return (
      <span
        className={
          className ?? cx(baseClassName, { on: checked, in_active: disabled })
        }>
        <input
          className="inp_radio"
          type="radio"
          id={id}
          name={name}
          disabled={disabled}
          checked={checked}
          onChange={onChange.bind(null, this.props.value)}
        />
        <label htmlFor={id} className="lab_radio">
          <span className="img_comm ico_radio" />
          {label}
          {hasHelp && (
            <DynamicTooltip content={toolTipContent}>
              <a className="link_g link_help">
                <span className="ico_comm ico_help">도움말</span>
              </a>
            </DynamicTooltip>
          )}
        </label>
      </span>
    )
  }
}

Radio.defaultProps = {
  hasHelp: false,
  toolTipContent: '도움말',
  baseClassName: 'box_radioinp',
}

Radio.propTypes = {
  label: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  hasHelp: PropTypes.bool,
  toolTipContent: PropTypes.any,
  baseClassName: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
