import { keyMirror } from '../../utils/utils'
import { fromJS, List } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import SortEnum from '../../enums/SortEnum'
import {
  createCancellation,
  deleteCancellation,
} from '../../utils/cancellation/cancellation'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'
import axios from 'axios'
import { showErrorMessage } from '../../utils/alertUtils'
import {
  convertStateToRequest,
  REPORT_ITEM_PAGE_SIZE,
  REPORT_REQUEST_CANCEL_KEY,
} from '../../components/ReportV2/Common/reportRequestConverter'
import DimensionEnum from '../../enums/DimensionEnum'

const ReportTable = keyMirror(
  {
    CLEAR: null,
    SET_SORT: null,
    SET_TABLE_DATA: null,
    ADD_TABLE_DATA: null,
    INIT_TABLE_SORT: null,
  },
  'REPORT_TABLE'
)
const initialState = fromJS({
  page: 0,
  sort: 'cost',
  order: SortEnum.Type.DESC,
  data: [],
  periodUnit: DimensionEnum.PeriodUnit.Type.DAY,
  last: false,
})

export default createReducer(initialState, {
  [ReportTable.CLEAR]: () => initialState,
  [ReportTable.SET_SORT]: (state, { sort, order }) =>
    state.withMutations(mutable =>
      mutable.set('sort', sort).set('order', order)
    ),
  [ReportTable.SET_TABLE_DATA]: (state, { data, periodUnit }) => {
    return state.withMutations(mutable =>
      mutable
        .set('data', fromJS(data))
        .set('page', 0)
        .set('last', data.length < REPORT_ITEM_PAGE_SIZE)
        .set('periodUnit', periodUnit)
    )
  },
  [ReportTable.ADD_TABLE_DATA]: (state, { data, periodUnit }) =>
    state.withMutations(mutable =>
      mutable
        .update('data', items => items.concat(fromJS(data)))
        .update('page', page => page + 1)
        .set('last', data.length < REPORT_ITEM_PAGE_SIZE)
        .set('periodUnit', periodUnit)
    ),
  [ReportTable.INIT_TABLE_SORT]: (state, { sort, order }) =>
    state.withMutations(mutable =>
      mutable
        .set('sort', sort)
        .set('order', order)
        .set('page', 0)
        .set('data', List())
        .set('periodUnit', DimensionEnum.PeriodUnit.Type.DAY)
        .set('last', false)
    ),
})

export function clearReportTable() {
  return {
    type: ReportTable.CLEAR,
  }
}

export function setSort({ sort, order }) {
  return {
    type: ReportTable.SET_SORT,
    sort,
    order,
  }
}

export function setReportTableData({ data, periodUnit }) {
  return {
    type: ReportTable.SET_TABLE_DATA,
    data,
    periodUnit,
  }
}

export function addReportTableData({ data, periodUnit }) {
  return {
    type: ReportTable.ADD_TABLE_DATA,
    data,
    periodUnit,
  }
}

export function initReportTableSort({ sort, order }) {
  return {
    type: ReportTable.INIT_TABLE_SORT,
    sort,
    order,
  }
}

export function getReportTableData(adAccountId, more = false) {
  return async (dispatch, getState, api) => {
    const {
      reportV2: {
        calendar: { startDate, endDate, periodType },
        filter: { filterConditions },
        common: { adUnits, dimensions, metrics },
        table: { page, sort, order, last },
      },
    } = getState()
    if (more && last) return
    dispatch(showLoading(LOADING_KEY.REPORT_V2_TABLE))
    const cancelTokenSource = createCancellation(REPORT_REQUEST_CANCEL_KEY)
    const periodUnit =
      dimensions.find(dimension => {
        return (
          dimension === DimensionEnum.PeriodUnit.Type.DAY ||
          dimension === DimensionEnum.PeriodUnit.Type.WEEK ||
          dimension === DimensionEnum.PeriodUnit.Type.MONTH
        )
      }) || DimensionEnum.PeriodUnit.Type.DAY
    try {
      const body = {
        ...convertStateToRequest({
          adAccountId,
          startDate,
          endDate,
          periodType,
          filterConditions,
          adUnits,
          dimensions,
          metrics,
          sort,
          order,
        }),
      }
      const { data } = await api.reportV2.searchReport(
        adAccountId,
        body,
        more ? page + 1 : 0,
        REPORT_ITEM_PAGE_SIZE
      )
      const action = more ? addReportTableData : setReportTableData
      dispatch(action({ data, periodUnit }))
    } catch (e) {
      console.error(e)
      if (!axios.isCancel(e)) {
        showErrorMessage('보고서 리스트를 가져오는 중 오류가 발생했습니다.')
      }
    } finally {
      deleteCancellation(cancelTokenSource)
      dispatch(hideLoading(LOADING_KEY.REPORT_V2_TABLE))
    }
  }
}
