import { keyMirror } from '../../utils/utils'
import { Map } from 'immutable'
import { NumberUtils } from '../../utils/numberUtils'
import { isUndefinedOrNull } from '../../utils/regexUtils'

const MAX_AMOUNT = 1_000_000_000
const MIN_AMOUNT = 1_000

export const CASH_CHARGE_FORM_VALIDATION_KEY = keyMirror({
  CHARGE_AMOUNT: null,
})

export const CASH_CHARGE_FORM_VALIDATION_KEY_PATH = Map({
  [CASH_CHARGE_FORM_VALIDATION_KEY.CHARGE_AMOUNT]: ['amount'],
})

export const CASH_CHARGE_FORM_VALIDATOR = Map({
  [CASH_CHARGE_FORM_VALIDATION_KEY.CHARGE_AMOUNT]: amount => {
    if (isUndefinedOrNull(amount)) {
      return { isValid: false, message: '충전 금액을 입력하세요.' }
    }

    if (amount < MIN_AMOUNT || amount > MAX_AMOUNT) {
      return {
        isValid: false,
        message: `${NumberUtils.toShorten(
          MIN_AMOUNT
        )}원 이상 ${NumberUtils.toShorten(MAX_AMOUNT)}원 이하로 입력하세요`,
      }
    }

    return { isValid: true, message: '' }
  },
})
