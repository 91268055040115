/**
 * @param form {Immutable.Map}
 * @param keys - [['value'], ['something', 'value'],...]
 */
export const Trim = (form, keys = []) => {
  if (!keys || keys.length === 0) return form
  return keys.reduce((prevForm, key) => {
    if (prevForm.hasIn(key)) {
      const val = prevForm.getIn(key)
      if (typeof val === 'string') {
        return prevForm.setIn(key, val.trim())
      }
    }
    return prevForm
  }, form)
}
