import PopupProxy from '../../Popup/Common/PopupProxy'
import { convertToCommaSeparatedString } from '../../../utils/utils'
import React from 'react'

/**
 * 캠페인 + 그룹 같이 생성: 광고계정 대시보드
 * 캠페인 수정: 본인 대시보드
 * 그룹만 생성: 상위 캠페인 대시보드
 * 그룹 수정: 본인 대시보드
 * 소재 생성: 상위 그룹 대시보드
 * 소재 수정: 본인 대시보드
 */
const cancelCreateOrModifyAdDialog = (onOK, create = true) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">
        광고 {create ? '만들기' : '수정'} 취소
      </strong>
      <p className="txt_layer">
        광고 {create ? '만들기를' : '수정을'} 취소하시겠습니까?
        <br />
        저장하지 않은 내용은 모두 사라집니다.
      </p>
    </PopupProxy>
  )
}

const exceedCreativeImageCountDialog = max => {
  return (
    <PopupProxy>
      <strong className="tit_layer">이미지 업로드 개수 초과</strong>
      <p className="txt_layer">
        홍보 이미지는 한번에 {convertToCommaSeparatedString(max)}
        개까지 업로드 가능합니다.
        <br />
        확인 후 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

const exceedCreativeUploadImageCountDialog = max => {
  return (
    <PopupProxy>
      <strong className="tit_layer">이미지 업로드 개수 초과</strong>
      <p className="txt_layer">
        이미지는 한번에 {convertToCommaSeparatedString(max)}
        개까지 업로드 가능합니다.
        <br />
        확인 후 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

const notice9To16CreativePricingTypeConstraintDialog = (
  onDismiss = () => undefined
) => {
  return (
    <PopupProxy onDismiss={onDismiss}>
      <strong className="tit_layer">이미지 노출 안내</strong>
      <p className="txt_layer">
        9:16 비율 이미지는 CPC/자동입찰 방식으로 광고에 노출되지 않습니다.
        <br />
        9:16 비율 이미지 소재의 노출을 원하신다면 CPM으로 입찰방식을 변경해
        주세요.
      </p>
    </PopupProxy>
  )
}

const notice9To16CreativeGoalTypeConstraintDialog = (
  onDismiss = () => undefined
) => {
  return (
    <PopupProxy onDismiss={onDismiss}>
      <strong className="tit_layer">이미지 노출 안내</strong>
      <p className="txt_layer">
        9:16 비율 이미지는 전환 목표로 광고에 노출되지 않습니다.
        <br />
        9:16 비율 이미지 소재의 노출을 원하신다면 디스플레이X방문 캠페인을
        만드신 후 CPM 입찰방식으로 광고그룹을 설정해 주세요.
      </p>
    </PopupProxy>
  )
}

const adGroupInvalidSettingDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      secondaryButtonText="취소"
      primaryButtonFunc={onAccept}>
      <strong className="tit_layer">타겟 설정 안내</strong>
      <p className="txt_layer">
        PC 디바이스 설정 시 제외 설정이 유효하지 않은
        <br />
        타겟(고객파일,카카오사용자)이 설정되어 있습니다.
      </p>
    </PopupProxy>
  )
}

const noticeMismatchBubbleTabDialog = (onDismiss = () => undefined) => {
  return (
    <PopupProxy onDismiss={onDismiss} hasCloseButton={false}>
      <strong className="tit_layer">프로필 구성 요소 미일치</strong>
      <p className="txt_layer">
        각 소재별 프로필 구성 요소는 모두 동일해야 합니다. <br />
        서로 다른 프로필 구성 요소가 입력된 부분이 확인되어,
        <br />
        최종입력/선택한 프로필 구성 요소값으로 모든 소재의
        <br />
        프로필 구성 요소를 동일하게 업데이트합니다.
      </p>
    </PopupProxy>
  )
}

const noticeMismatchBubbleTabForAdGroupDialog = (
  onDismiss = () => undefined
) => {
  return (
    <PopupProxy onDismiss={onDismiss} hasCloseButton={false}>
      <strong className="tit_layer">프로필 구성 요소 미일치</strong>
      <p className="txt_layer">
        각 소재별 프로필 구성 요소는 모두 동일해야 합니다. <br />
        불러오기 소재의 프로필 구성 요소가 기존 입력된 <br />
        프로필 구성 요소와 다른 부분이 확인됩니다. <br />
        브랜딩 페이지 구성 요소만 불러오기를 진행합니다.
      </p>
    </PopupProxy>
  )
}

export {
  cancelCreateOrModifyAdDialog,
  exceedCreativeImageCountDialog,
  exceedCreativeUploadImageCountDialog,
  notice9To16CreativePricingTypeConstraintDialog,
  notice9To16CreativeGoalTypeConstraintDialog,
  noticeMismatchBubbleTabDialog,
  noticeMismatchBubbleTabForAdGroupDialog,
  adGroupInvalidSettingDialog,
}
