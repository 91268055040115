/**
 * https://lifesaver.codes/answer/make-react-resilient-to-dom-mutations-from-google-translate
 * https://bugs.chromium.org/p/chromium/issues/detail?id=872770
 */
if (typeof Node === 'function' && Node.prototype) {
  const originalRemoveChild = Node.prototype.removeChild
  Node.prototype.removeChild = function(child) {
    if (child.parentNode !== this) {
      if (console) {
        console.error('Cannot remove a child from a different parent', child, this)
      }
      return child
    }
    return originalRemoveChild.apply(this, arguments)
  }

  const originalInsertBefore = Node.prototype.insertBefore
  Node.prototype.insertBefore = function(newNode, referenceNode) {
    if (referenceNode && referenceNode.parentNode !== this) {
      if (console) {
        console.error('Cannot insert before a reference node from a different parent', referenceNode, this)
      }
      return newNode
    }
    return originalInsertBefore.apply(this, arguments)
  }
}
