import { keyMirror } from '../utils/utils'
import { ReportFilterUtils } from '../components/ReportV2/Common/reportFilterUtils'

export default {
  Filter: {
    Type: keyMirror({
      NAME: null,
      ID: null,
      ON_OFF_DELETE: null,
      CAMPAIGN_TYPE: null,
      GOAL: null,
      OBJECTIVE_DETAIL_TYPE: null,

      // Targeting
      CREATIVE_FORMAT: null,
      AGE_BAND: null,
      GENDER: null,
      AGE_BAND_GENDER: null,
      LOCATION: null,
      DEVICE: null,
      PLACEMENT: null,
      DEVICE_PLACEMENT: null,
      TIME_ZONE: null,

      // Metric
      CAMPAIGN_METRIC: null,
      AD_GROUP_METRIC: null,
      CREATIVE_METRIC: null,
    }),

    Name: {
      NAME: '이름',
      ID: 'ID',
      ON_OFF_DELETE: 'ON/OFF/삭제',
      CAMPAIGN_TYPE: '캠페인 유형',
      GOAL: '캠페인 목표',
      OBJECTIVE_DETAIL_TYPE: '광고 전환 목표',

      // Targeting
      CREATIVE_FORMAT: '소재 유형',
      AGE_BAND: '연령',
      GENDER: '성별',
      AGE_BAND_GENDER: '연령 및 성별',
      LOCATION: '지역',
      DEVICE: '디바이스',
      PLACEMENT: '게재지면',
      DEVICE_PLACEMENT: '디바이스 및 게재지면',
      TIME_ZONE: '시간대',

      // Metric
      CAMPAIGN_METRIC: '캠페인 지표',
      AD_GROUP_METRIC: '광고그룹 지표',
      CREATIVE_METRIC: '소재 지표',
    },

    getName(type) {
      return this.Name[type]
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    optionSelectValues() {
      return (
        this.values()
          // targeting 유형의 필터는 설정되었을때만 노출
          .filter(
            filterType =>
              !ReportFilterUtils.Common.isTargetingFilter({ filterType })
          )
          .reduce(
            (prev, val) =>
              [this.Type.ID, this.Type.NAME].includes(val)
                ? [[this.Type.NAME, this.Type.ID]]
                : [...prev, val],
            []
          )
      )
    },
  },

  Operator: {
    Type: keyMirror({
      CONTAIN: null,
      NOT_CONTAIN: null,
      ALL: null,
      IN: null,
      ANY: null,
      NONE: null,
      GE: null,
      LE: null,
      EQ: null,
      NEQ: null,
      GE_LE: null,
    }),

    Name: {
      // 입력 문자열이 포함됨
      CONTAIN: '포함',
      // 입력 문자열을 포함하지 않음
      NOT_CONTAIN: '포함하지 않음',
      // 선택된 항목들과 모두 일치함
      ALL: '모두 일치',
      // 선택된 항목들 중 하나라도 일치함(alias: IN)
      ANY: '다음 중 하나',
      // 선택된 항목들이 모두 일치하지 않음
      NONE: '모두 아님',
      // 입력된 수치보다 같거나 큼
      GE: '이상',
      // 입력된 수치보다 같거나 작음
      LE: '이하',
      // 입력된 문자열|수치과 같음
      EQ: '같음',
      // 입력된 문자열|수치와 같지 않음
      NEQ: '같지 않음',
      // GE ~ LE 범위로 사용되는 필터 (보고서만 사용)
      GE_LE: '사이',
    },

    getName(type) {
      return this.Name[type]
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },
  },
}
