import PropTypes from 'prop-types'

const cmpPropTypes = {
  InputAsset: PropTypes.shape({
    adAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    cmpRequestCreator: PropTypes.func.isRequired,
    cmpResponseTransformer: PropTypes.func,
    cmpAssetButtonTooltip: PropTypes.any,
    cmpAssetListItemRenderer: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.element,
    ]),
  }),
}

export default cmpPropTypes
