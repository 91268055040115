import React, { useCallback, useEffect } from 'react'

import PropTypes from 'prop-types'

const waitEntryPointsLoaded = (stylesheetId, name) =>
  new Promise(resolve => {
    const intervalId = setInterval(() => {
      const loaded =
        typeof window[name]?.render === 'function' &&
        (document.getElementById(stylesheetId)
          ? Array.from(document.styleSheets).some(
              style => style?.ownerNode?.id === stylesheetId
            )
          : true)
      if (loaded) {
        clearInterval(intervalId)
        resolve()
      }
    }, 300)
  })
const isAbsoluteUrl = url => url.startsWith('http') || url.startsWith('//')
const MicroFrontend = ({ name, host, history }) => {
  const scriptId = `micro-frontend-script-${name}`
  const stylesheetId = `micro-frontend-stylesheet-${name}`
  const render = useCallback(async () => {
    await waitEntryPointsLoaded(stylesheetId, name)
    try {
      window[name].render({
        containerId: `${name}-container`,
        history,
      })
    } catch (error) {
      console.error(error)
    }
  }, [history, name, stylesheetId])

  const unmount = useCallback(() => {
    try {
      window[name].unmount(`${name}-container`)
    } catch (error) {
      console.error(error)
    }
  }, [name])

  const fetchManifest = useCallback(async () => {
    const response = await fetch(`${host}/asset-manifest.json`, {
      cache: 'no-store',
    })
    const manifest = await response.json()
    const { files } = manifest
    const mainCss = files['main.css']
    if (mainCss) {
      if (!document.getElementById(stylesheetId)) {
        const stylesheet = document.createElement('link')
        stylesheet.rel = 'stylesheet'
        stylesheet.type = 'text/css'
        stylesheet.id = stylesheetId
        stylesheet.href = isAbsoluteUrl(mainCss) ? mainCss : `${host}${mainCss}`
        document.head.appendChild(stylesheet)
      }
    }
    const mainJs = files['main.js']
    if (mainJs) {
      const script = document.createElement('script')
      script.id = scriptId
      script.src = isAbsoluteUrl(mainJs) ? mainJs : `${host}${mainJs}`
      script.onload = render
      document.head.appendChild(script)
    }
  }, [host, render, scriptId, stylesheetId])

  useEffect(() => {
    if (document.getElementById(scriptId)) {
      render()
    } else {
      fetchManifest().catch(error => console.error(error))
    }
    return () => {
      unmount()
    }
  }, [host, fetchManifest, render, scriptId, unmount])

  return <div id={`${name}-container`} />
}

MicroFrontend.propTypes = {
  history: PropTypes.object.isRequired,
  host: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

export default MicroFrontend
