import { CONTEXTPATH, fetchGet, fetchPost } from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'

/**
 * 16.1 선택 가능한 목표 & 유형 조회
 *
 * --
 *
 * [
 *  {
 *    campaignType: CampaignTypeEnum.Type,
 *    goal: GoalEnum.Type
 *    campaignDailyBudget: { guide: number, max: number, min: number },
 *    adGroupDailyBudget: { guide: number, max: number, min: number }
 *  }
 * ]
 *
 * @param adAccountId {string | number}
 * @return {Promise}
 */
function getAdAccountConstraints(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/campaignTypeGoals`, adAccountId)
}

/**
 * 16.3 소재 제약 조건 조회
 *
 * @param adAccountId {string | number}
 * @param campaignType {string}: 유형
 * @param goal {string}: 목표
 * @param devices {array | List}
 * @param placements {array | List}
 * @param pricingType {string}
 * @return {Promise}
 *
 * --
 *
 * {
 *  campaignType: CampaignTypeEnum.Type,
 *  goal: GoalEnum.Type,
 *  creativeFormats: CreativeFormatEnum.Type[],
 *  imageSizes: {
 *   [CreativeFormatEnum.Type]: [
 *    {
 *      width: number,
 *      height: number,
 *      minWidth: number,
 *      templateType: string,
 *      placements: PlacementEnum.Type[]
 *    }
 *   ]
 *  }
 * }
 */
function getCreativeConstraints(
  adAccountId,
  campaignType,
  goal,
  devices,
  placements,
  pricingType
) {
  return fetchPost(
    `${CONTEXTPATH}/campaignTypeGoals/constraint`,
    {
      campaignType,
      goal,
      devices,
      placements,
      pricingType,
    },
    adAccountId
  )
}

function getCreativeConstraintsByAdGroupId(adAccountId, adGroupId) {
  return fetchGet(
    `${CONTEXTPATH}/creatives/constraints?adGroupId=${adGroupId}`,
    adAccountId
  )
}

/**
 * 16.2 유형 목표별 제약조건
 * @param adAccountId
 * @param campaignTypeGoalId
 * @param objectiveType
 * @param objectiveDetailType
 */
function getAdGroupConstraints(
  adAccountId,
  campaignTypeGoalId,
  objectiveType = null,
  objectiveDetailType = null
) {
  return fetchGet(
    `${CONTEXTPATH}/campaignTypeGoals/${campaignTypeGoalId}/constraints?${queryString(
      { objectiveType, objectiveDetailType }
    )}`,
    adAccountId
  )
}

function getAdGroupRecommendedBidAmount(adGroupId, adAccountId) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups/${adGroupId}/bidStrategyTarget/recommend`,
    adAccountId
  )
}

function getAdGroupConstraintsByCampaignIds(adAccountId, campaignIds = []) {
  return fetchPost(
    `${CONTEXTPATH}/campaignTypeGoals/constraints/multiple`,
    { ids: campaignIds },
    adAccountId
  )
}

export default {
  getAdAccountConstraints,
  getAdGroupConstraints,
  getAdGroupConstraintsByCampaignIds,
  getCreativeConstraints,
  getCreativeConstraintsByAdGroupId,
  getAdGroupRecommendedBidAmount,
}
