import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    MANUAL_UPLOAD: null,
    URL_UPLOAD: null,
    PREUPLOAD: null,
    UPLOAD: null,
  }),

  isTitleRequired(type) {
    return [this.Type.MANUAL_UPLOAD, this.Type.URL_UPLOAD].includes(type)
  },

  isKakaoTvChannelUploaded(type) {
    return ![this.Type.MANUAL_UPLOAD, this.Type.URL_UPLOAD].includes(type)
  },
}
