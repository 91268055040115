import { Map, Set } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { cancelRequest } from '../../utils/cancellation/cancellation'

const Download = keyMirror(
  {
    PENDING: null,
    CANCEL: null,
    COMPLETE: null,
    FAILURE: null,
    CLEAR: null,
  },
  'DOWNLOAD'
)

const initialState = Map({
  pending: Map(),
  cancel: Set(),
  complete: Set(),
  failure: Set(),
})

export default createReducer(initialState, {
  [Download.PENDING]: (state, { key, name = '' }) =>
    state.withMutations(s =>
      s.update('pending', pending => pending.set(key, name))
    ),

  [Download.CANCEL]: (state, { key }) =>
    state.withMutations(s =>
      s
        .update('cancel', cancel => cancel.add(key))
        .update('pending', pending => pending.remove(key))
    ),

  [Download.COMPLETE]: (state, { key }) =>
    state.withMutations(s =>
      s
        .update('complete', complete => complete.add(key))
        .update('pending', pending => pending.remove(key))
    ),

  [Download.FAILURE]: (state, { key }) =>
    state.withMutations(s =>
      s
        .update('failure', failure => failure.add(key))
        .update('pending', pending => pending.remove(key))
    ),

  [Download.CLEAR]: state => state.merge(initialState),
})

// noinspection JSUnusedGlobalSymbols
export function pendingDownload(key, name) {
  return {
    type: Download.PENDING,
    key,
    name,
  }
}

export function cancelDownload(key) {
  cancelRequest(key)

  return {
    type: Download.CANCEL,
    key,
  }
}

// noinspection JSUnusedGlobalSymbols
export function completeDownload(key) {
  cancelRequest(key)

  return {
    type: Download.COMPLETE,
    key,
  }
}

// noinspection JSUnusedGlobalSymbols
export function failureDownload(key) {
  cancelRequest(key)

  return {
    type: Download.FAILURE,
    key,
  }
}

// noinspection JSUnusedGlobalSymbols
export function clearDownload() {
  return {
    type: Download.CLEAR,
  }
}
