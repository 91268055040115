import OnOffSwitch from '../../DashboardV3/Common/OnOffSwitch'
import cx from 'classnames'
import DynamicTooltip from '../../Tooltip/DynamicTooltip'
import TotalStatusTooltip from '../../DashboardV3/DashboardTable/Common/Cell/TotalStatusTooltip'
import React from 'react'
import DashboardStatusEnum from '../../../enums/DashboardStatusEnum'
import { DashboardCellUtils } from '../../DashboardV3/dashboardCellUtils'
import DashboardEnumV2 from '../../../enums/DashboardEnumV2'
import { modifyAdAccountOnOff } from '../../../modules-batch/dashboardV3/batch-dashboard-adAccount'
import { useDispatch } from 'react-redux'
import { openPopupByProxy, POPUP_KEY } from '../../../modules/common/mPopup'
import PopupProxy from '../../Popup/Common/PopupProxy'
import ImmutablePropTypes from 'react-immutable-proptypes'
import DebugTooltip from '../../Tooltip/TooltipImpl/DebugTooltip'

const LnbAdAccountInfo = ({ adAccountInfo }) => {
  const dispatch = useDispatch()

  const {
    id: adAccountId,
    userConfig,
    status,
    adminStop,
    adminStopLog,
    extras,
  } = adAccountInfo

  const onOffSwitchId = 'check|adAccount|onOff'
  const initialOnOffActive = React.useMemo(
    () => userConfig === 'ON',
    [userConfig]
  )

  const { adminStopReason } = adminStopLog || {}
  const dashboardStatus = status?.first()

  const representationTooltip =
    DashboardCellUtils.Status.Tooltip.getRepresentation({
      dashboardType: DashboardEnumV2.Type.AD_ACCOUNT,
      dashboardStatus,
    })
  const isAdAccountActive = DashboardStatusEnum.isActive(dashboardStatus)
  const repStatusLabel = DashboardStatusEnum.getName(dashboardStatus)

  const handleOnOffChange = React.useCallback(
    active => {
      dispatch(modifyAdAccountOnOff({ adAccountId, active }))
    },
    [dispatch, adAccountId]
  )

  const openAdAccountAdminStoppedReason = React.useCallback(() => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.BASIC_POPUP,
        <PopupProxy
          title="관리자 정지 사유"
          className="basic_layer reason_layer">
          <div className="reason_wrap">
            <p className="txt_reason">{adminStopReason || '관리자 정지'}</p>
          </div>
        </PopupProxy>
      )
    )
  }, [dispatch, adminStopReason])

  const StatusEl = adminStop ? 'a' : 'span'

  return (
    <div className="ad_info">
      <div className="item_info">
        <strong className="tit_item">광고계정 ID</strong>
        <div className="txt_item">{adAccountId}</div>
        {extras && <DebugTooltip content={JSON.stringify(extras.toJS())} />}
      </div>
      <div className="item_info">
        <strong className="tit_item">ON/OFF</strong>
        <div className="txt_item">
          <OnOffSwitch
            id={onOffSwitchId}
            initialActive={initialOnOffActive}
            userConfig={userConfig}
            disabled={false}
            onChange={handleOnOffChange}
          />
        </div>
      </div>
      <div className="item_info">
        <strong className="tit_item">상태</strong>
        <div className="txt_item">
          <StatusEl className={cx('txt_state', { off: !isAdAccountActive })}>
            <span className={isAdAccountActive ? 'ico_on' : 'ico_off'} />
            {representationTooltip ? (
              <DynamicTooltip
                content={
                  <TotalStatusTooltip
                    representationTooltip={representationTooltip}
                  />
                }>
                <span
                  onClick={
                    adminStop ? openAdAccountAdminStoppedReason : undefined
                  }>
                  {repStatusLabel}
                </span>
              </DynamicTooltip>
            ) : (
              repStatusLabel
            )}
          </StatusEl>
        </div>
      </div>
    </div>
  )
}

LnbAdAccountInfo.propTypes = {
  adAccountInfo: ImmutablePropTypes.map.isRequired,
}

export default React.memo(LnbAdAccountInfo)
