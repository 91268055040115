import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import classNames from 'classnames'
import { checkNoneEmpty } from '../../../utils/regexUtils'
import useDropDown from '../DropDown/utils/Hooks/useDropDown'

const STANDARD_PAGE = 5
export const DEFAULT_PAGE_SIZE_ARRAY = [50, 100, 200]

Pagination.propTypes = {
  currentIndex: PropTypes.number,
  totalElements: PropTypes.number,
  totalPage: PropTypes.number,
  pageSize: PropTypes.number,
  onChangePage: PropTypes.func.isRequired,

  isPageOptionEnable: PropTypes.bool,
  pageSizeArray: PropTypes.arrayOf(PropTypes.number),
  onPageSizeChange: PropTypes.func,

  paginationVisible: PropTypes.bool,
}

const getPagingArray = (totalPage, currentPage) => {
  const isMaxPageUnderFive = totalPage <= STANDARD_PAGE

  if (isMaxPageUnderFive) {
    return range(totalPage).map(index => index + 1)
  }
  const prevPage = currentPage - 1
  const nextPage = currentPage + 1

  switch (currentPage) {
    case 1:
    case 2:
      return [1, 2, 3, 0, totalPage]

    case 3:
      return [1, prevPage, currentPage, nextPage, 0, totalPage]

    case totalPage - 1:
    case totalPage:
      return [1, 0, totalPage - 2, totalPage - 1, totalPage]

    case totalPage - 2:
      return [1, 0, prevPage, currentPage, nextPage, totalPage]

    default:
      return [1, 0, prevPage, currentPage, nextPage, 0, totalPage]
  }
}

export const PrevButton = ({ onClick, active, className }) => {
  const El = active ? 'a' : 'span'
  return (
    <El
      className={classNames('num_paging', className)}
      onClick={active ? onClick : null}>
      <span className="ico_comm ico_prev">이전</span>
    </El>
  )
}

PrevButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
}

export const NextButton = ({ onClick, active, className }) => {
  const El = active ? 'a' : 'span'
  return (
    <El
      className={classNames('num_paging', className)}
      onClick={active ? onClick : null}>
      <span className="ico_comm ico_next">다음</span>
    </El>
  )
}

NextButton.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  className: PropTypes.string,
}

const PageButtons = ({ totalPage, currentPage, onChangePage }) => {
  const pagingArray = getPagingArray(totalPage, currentPage)

  return pagingArray.map((page, index) => {
    const isCurrentPage = page === currentPage
    const Element = isCurrentPage ? 'em' : 'a'
    const onClick = page > 0 ? () => onChangePage(page) : null

    return page > 0 ? (
      <Element key={index} onClick={onClick} className="num_paging">
        {isCurrentPage && <span className="screen_out">현재페이지</span>}
        {page}
      </Element>
    ) : (
      <span key={index} className="num_paging num_skip">
        ...
      </span>
    )
  })
}

PageButtons.propTypes = {
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  onChangePage: PropTypes.func,
}

const PageDropDown = ({
  currentPageSize,
  pageSizeArray = DEFAULT_PAGE_SIZE_ARRAY,
  onPageSizeSelect,
}) => {
  const { isOpen, setIsOpen, ref } = useDropDown()

  const onOpen = () => setIsOpen(!isOpen)
  const onSelect = pageSize => {
    setIsOpen(false)
    onPageSizeSelect(pageSize)
  }
  return (
    <div ref={ref} className={classNames('opt_select2', { opt_open: isOpen })}>
      <div className="screen_out">데이터종류 선택상자</div>
      <span className="screen_out">선택내용 : </span>
      <a className="link_selected" onClick={onOpen}>
        행 : {currentPageSize}개
      </a>
      <span className="ico_arr" />
      <div className="screen_out">선택옵션</div>
      <div className="layer_opt">
        <div className="wrap_list">
          <ul className="list_option">
            {pageSizeArray.map(pageSize => (
              <PageDropDownItem
                key={pageSize}
                pageSize={pageSize}
                isSelect={pageSize === currentPageSize}
                onSelect={() => onSelect(pageSize)}
              />
            ))}
          </ul>
        </div>
      </div>
    </div>
  )
}

PageDropDown.propTypes = {
  currentPageSize: PropTypes.number,
  pageSizeArray: PropTypes.arrayOf(PropTypes.number),
  onPageSizeSelect: PropTypes.func,
}

const PageDropDownItem = ({ pageSize, onSelect, isSelect }) => {
  return (
    <li className={classNames({ on: isSelect })} onClick={onSelect}>
      <a className="link_option">{pageSize}</a>
    </li>
  )
}

PageDropDownItem.propTypes = {
  pageSize: PropTypes.number,
  onSelect: PropTypes.func,
  isSelect: PropTypes.bool,
}

function Pagination({
  currentIndex = 0,
  totalElements = 0,
  totalPage = 0,
  pageSize = 10,
  paginationVisible = true,
  isPageOptionEnable,
  pageSizeArray,
  onPageSizeChange,
  onChangePage = () => {},
}) {
  const currentPage = currentIndex + 1

  const onNext = () => onChangePage(currentIndex + 1)
  const onPrev = () => onChangePage(currentIndex - 1)
  const onChange = page => onChangePage(page - 1)

  const totalPages =
    totalPage > 0 ? totalPage : Math.ceil(totalElements / pageSize)
  const isPageOptionsExist =
    isPageOptionEnable && checkNoneEmpty(onPageSizeChange)

  return (
    paginationVisible && (
      <div className="paging_wrap">
        <div className="inner_paging">
          <PrevButton onClick={onPrev} active={currentPage !== 1} />
          <PageButtons
            currentPage={currentPage}
            totalPage={totalPages}
            onChangePage={onChange}
          />
          <NextButton onClick={onNext} active={currentPage !== totalPages} />
          {isPageOptionsExist && (
            <PageDropDown
              currentPageSize={pageSize}
              pageSizeArray={pageSizeArray}
              onPageSizeSelect={onPageSizeChange}
            />
          )}
        </div>
      </div>
    )
  )
}

export default Pagination
