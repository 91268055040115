import DynamicTooltip from '../../../../../Tooltip/DynamicTooltip'
import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { List } from 'immutable'
import useComponentId from '../../../../../../utils/hook/useComponentId'
import {
  GUIDE_STORAGE_ITEM,
  GuideStorageLocal,
} from '../../../../../../utils/storage/storageFactoryImpl'
import GuideTooltip from '../../../../../Tooltip/TooltipImpl/GuideTooltip'
import { Tooltip } from '../../../../../../resources/locale'
import { calculateAspectRatio } from '../../../../../../utils/mathUtils'

const CmpAssetLibraryImageCropperOpenButton = ({
  index,
  onClick,
  items = List(),
}) => {
  const useGuide = index === 0
  const buttonId = useComponentId()

  return (
    <>
      <button
        id={buttonId}
        style={{
          zIndex: 12,
        }}
        type="button"
        className="btn_imgedit"
        onClick={e => {
          e.stopPropagation()

          onClick()
        }}>
        <span className="ico_comm">이미지 편집</span>{' '}
        {items.count() > 0 && (
          <DynamicTooltip
            content={
              <>
                <strong>편집된 이미지 비율</strong>
                <br />
                <ul style={{ marginTop: '4px' }}>
                  {items.map(
                    ({ editedImageWidth, editedImageHeight }, index) => {
                      const { aspect } = calculateAspectRatio(
                        editedImageWidth,
                        editedImageHeight
                      )

                      const dimension = `(${editedImageWidth}x${editedImageHeight})`

                      return (
                        <li key={index}>{[aspect, dimension].join(' ')}</li>
                      )
                    }
                  )}
                </ul>
              </>
            }>
            <span className="num_imgedit">
              <span className="ico_comm">편집완료</span>
            </span>
          </DynamicTooltip>
        )}
      </button>
      {useGuide && <ImageCropperGuideTooltip targetId={buttonId} />}
    </>
  )
}

CmpAssetLibraryImageCropperOpenButton.propTypes = {
  index: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
  items: ImmutablePropTypes.list,
}

const ImageCropperGuideTooltip = ({ targetId }) => {
  const [shouldShow, setShouldShow] = React.useState(false)

  React.useEffect(() => {
    const shouldShow =
      GuideStorageLocal.get(
        GUIDE_STORAGE_ITEM.CREATIVE_LIBRARY_IMAGE_EDITOR
      ) !== true

    if (shouldShow) {
      GuideStorageLocal.set(
        GUIDE_STORAGE_ITEM.CREATIVE_LIBRARY_IMAGE_EDITOR,
        true
      )

      setShouldShow(true)
    }
  }, [])

  return (
    shouldShow && (
      <GuideTooltip
        content={Tooltip('cmp.editor.guide_modify_button')}
        showDelay={100}
        dismissTimeout={10 * 1000}
        styles={{ backgroundColor: '#326EDC', color: '#fff' }}
        getElement={() => document.getElementById(targetId)}
      />
    )
  )
}

ImageCropperGuideTooltip.propTypes = {
  targetId: PropTypes.any.isRequired,
}

export default CmpAssetLibraryImageCropperOpenButton
