import React from 'react'
import {
  cancelRequest,
  createCancellation,
  deleteCancellation,
} from '../../../../../../utils/cancellation/cancellation'
import axios from 'axios'
import { v4 as uuid } from 'uuid'
import VideoStatusEnum from '../../../../../../enums/VideoStatusEnum'
import {
  CONTEXTPATH,
  fetchGet,
  fetchPost,
} from '../../../../../../utils/fetchUtils'
import { queryString } from '../../../../../../utils/utils'
import transCodingRetryer from '../../../../../../utils/transCodingRetryer'

const initialEncodingState = {
  progress: 0, // 0 ~ 100
  requested: false,
  done: false,
}

const useVideoEncoding = ({ cancelKey = uuid() } = {}) => {
  const pollingIntervalRef = React.useRef(-1)

  const isPendingRef = React.useRef(false)

  const [encodingState, setEncodingState] = React.useState(initialEncodingState)

  const clearPollingInterval = React.useCallback(() => {
    if (pollingIntervalRef.current) {
      clearInterval(pollingIntervalRef.current)
      pollingIntervalRef.current = -1
    }
  }, [])

  React.useEffect(() => {
    return () => {
      clearPollingInterval()
    }
  }, [clearPollingInterval])

  const cancelEncoding = React.useCallback(() => {
    clearPollingInterval()
    cancelRequest(cancelKey)
    setEncodingState(initialEncodingState)
  }, [cancelKey, clearPollingInterval])

  const startEncoding = React.useCallback(
    ({
      intervalTimeout = 1000,
      adAccountId,
      transCodeIds = [],
      onProgress = () => {},
      onSuccess = () => {},
      onError = () => {},
      onCancel = () => {},
    }) => {
      const videoPresetId = transCodeIds.join(',')
      const cancelTokenSource = createCancellation(cancelKey)
      const checkTransCoding = async () => {
        await fetchPost(
          `${CONTEXTPATH}/creatives/video/encoding/retry?${queryString({
            videoPresetId,
            loudnorm: encodeURI('I=-24'),
          })}`,
          undefined,
          adAccountId,
          cancelTokenSource
        )
      }

      const transCodingChecker = transCodingRetryer.create({
        retryFunc: checkTransCoding,
      })

      pollingIntervalRef.current = setInterval(async () => {
        const isPending = isPendingRef.current

        // 요청 중일 경우엔 중복 요청하지 않음(cancel 됨)
        if (isPending) return

        try {
          isPendingRef.current = true
          setEncodingState(prev => ({ ...prev, requested: true }))

          const response = await fetchGet(
            `${CONTEXTPATH}/creatives/video/encoding?${queryString({
              videoPresetId,
            })}`,
            adAccountId,
            cancelTokenSource // cancelKey 를 이용해 외부에서 cancel 시킬 수 있어야 함.
          )

          const { totalPercent, status } = response.data || {}
          const done = totalPercent >= 100

          setEncodingState(prev => ({
            ...prev,
            progress: totalPercent,
            done,
          }))

          switch (status) {
            case VideoStatusEnum.Type.FAILED:
              await transCodingChecker.retryRequest()
              break
            case VideoStatusEnum.Type.TRANSCODING:
              onProgress(totalPercent)
              break
            case VideoStatusEnum.Type.COMPLETED:
              onProgress(totalPercent)
              onSuccess()
              deleteCancellation(cancelTokenSource)
              clearPollingInterval()
              break
            default:
              break
          }
        } catch (e) {
          console.log(e.message)

          if (axios.isCancel(e)) {
            onCancel()
          } else {
            onError(e?.response?.data)
          }

          setEncodingState(initialEncodingState)
          clearPollingInterval()

          deleteCancellation(cancelTokenSource)
        } finally {
          isPendingRef.current = false
        }
      }, intervalTimeout)
    },
    [cancelKey, clearPollingInterval]
  )

  return {
    startEncoding,
    cancelEncoding,
    encodingState,
    cancelKey,
  }
}

export default useVideoEncoding
