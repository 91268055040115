import MemoizeUtils from '../../utils/memoization/memoizeUtils'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import GoalEnum from '../../enums/GoalEnum'
import BudgetEnum from '../../enums/BudgetEnum'
import { dateTimeToString, dateToString } from '../../utils/utils'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
} from '../../utils/regexUtils'
import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import DashboardStatusEnum from '../../enums/DashboardStatusEnum'
import BidStrategyEnum from '../../enums/BidStrategyEnum'
import moment from 'moment'
import DashboardConstraints from '../../utils/constraints/constraints-dashboard'
import { Tooltip } from '../../resources/locale'
import { toLower } from 'lodash'
import AdGroupConstraints from '../../utils/constraints/constraints-adGroup'
import AutoBidPurposeEnum from '../../enums/AutoBidPurposeEnum'
import BidStrategyTargetEnum from '../../enums/BidStrategyTargetEnum'
import { NumberUtils } from '../../utils/numberUtils'
import PricingTypeEnum from '../../enums/PricingTypeEnum'

const getBidStrategyTargetLabel = (type, value) => {
  const target = BidStrategyTargetEnum.getName(type)

  if (type === BidStrategyTargetEnum.Type.MAXIMIZE_CONVERSION_VALUE) {
    return target
  }

  const number = NumberUtils.withCommas(value)

  return `(${target}) ${number}`
}

const DashboardCellUtils = {
  PropsAreEqual: {
    OnOff: MemoizeUtils.propsAreEqual({
      propGetter: ({
        row: {
          original: {
            data: { id, userConfig },
          },
        },
      }) => `${id}|${userConfig}`,
    }),
  },

  Status: {
    Tooltip: {
      getRepresentation({ dashboardType, dashboardStatus }) {
        if (!checkNoneEmpty(dashboardType, dashboardStatus)) return ''

        return Tooltip(
          `dashboard.status.${toLower(dashboardType)}.${toLower(
            dashboardStatus
          )}`
        )
      },
      getExtra({
        dashboardType,
        adAccountStatus,
        campaignStatus,
        adGroupStatus,
      }) {
        const isStoppedAdAccount = [
          DashboardStatusEnum.Type.OFF,
          DashboardStatusEnum.Type.STOPPED,
          DashboardStatusEnum.Type.OUT_OF_BALANCE,
        ].includes(adAccountStatus)
        const isStoppedCampaign = [
          DashboardStatusEnum.Type.OFF,
          DashboardStatusEnum.Type.MESSAGE_PAUSE,
          DashboardStatusEnum.Type.EXCEED_DAILY_BUDGET,
          DashboardStatusEnum.Type.SYSTEM_CONFIG_ADMIN_STOP,
          DashboardStatusEnum.Type.SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP,
        ].includes(campaignStatus)
        const isStoppedAdGroup = [
          DashboardStatusEnum.Type.FINISHED,
          DashboardStatusEnum.Type.OFF,
          DashboardStatusEnum.Type.CANCELED,
          DashboardStatusEnum.Type.EXCEED_DAILY_BUDGET,
          DashboardStatusEnum.Type.MESSAGE_PAUSE,
          DashboardStatusEnum.Type.SYSTEM_CONFIG_ADMIN_STOP,
          DashboardStatusEnum.Type.SYSTEM_CONFIG_EXTERNAL_SERVICE_STOP,
        ].includes(adGroupStatus)

        return {
          adAccountStatus: isStoppedAdAccount ? adAccountStatus : undefined,
          campaignStatus:
            (dashboardType === DashboardEnumV2.Type.AD_GROUP ||
              dashboardType === DashboardEnumV2.Type.CREATIVE) &&
            !isStoppedAdAccount &&
            isStoppedCampaign
              ? campaignStatus
              : undefined,
          adGroupStatus:
            dashboardType === DashboardEnumV2.Type.CREATIVE &&
            !isStoppedAdAccount &&
            !isStoppedCampaign &&
            isStoppedAdGroup
              ? adGroupStatus
              : undefined,
        }
      },
    },
  },

  CampaignTypeGoal: {
    toString({ campaignType, goal }) {
      return [CampaignTypeEnum.getName(campaignType), GoalEnum.getName(goal)]
        .filter(Boolean)
        .join(' X ')
    },
  },

  Budget: {
    toString({ campaignType, dailyBudget, totalBudgetWithVAT }) {
      const budgetType = DashboardConstraints.Campaign.budgetType({
        campaignType,
      })
      const budgetSuffix = `(${BudgetEnum.getName(budgetType)})`
      const budget = CampaignTypeEnum.isContractCampaignType(campaignType)
        ? NumberUtils.withCommas(totalBudgetWithVAT)
        : totalBudgetWithVAT > 0
        ? NumberUtils.withCommas(totalBudgetWithVAT)
        : checkEmpty(dailyBudget)
        ? '미설정'
        : NumberUtils.withCommas(dailyBudget)

      return [budgetSuffix, budget].join(' ')
    },
    toCsvStringArray({ campaignType, dailyBudget, totalBudgetWithVAT }) {
      const budgetType = DashboardConstraints.Campaign.budgetType({
        campaignType,
      })
      const budgetTypeName = BudgetEnum.getName(budgetType)
      const budgetLabel = CampaignTypeEnum.isContractCampaignType(campaignType)
        ? NumberUtils.withCommas(totalBudgetWithVAT)
        : totalBudgetWithVAT > 0
        ? NumberUtils.withCommas(totalBudgetWithVAT)
        : checkEmpty(dailyBudget)
        ? '미설정'
        : NumberUtils.withCommas(dailyBudget)

      return [budgetTypeName, budgetLabel]
    },
  },

  BidAmount: {
    toString({
      campaignType,
      goal,
      bidStrategy,
      bidStrategyTarget,
      pricingType,
      bidAmount,
      totalBudget,
    }) {
      const { type, value } = bidStrategyTarget || {}
      const autoBidPurpose =
        AdGroupConstraints.BidStrategy.getAutoBidPurposeType({
          campaignType,
          goal,
        })

      const autoBidLabel = checkNotEmpty(type)
        ? getBidStrategyTargetLabel(type, value)
        : AutoBidPurposeEnum.getName(autoBidPurpose)

      return BidStrategyEnum.isAdGroupAutoBid(bidStrategy)
        ? autoBidLabel
        : campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
        ? `(${pricingType}) -`
        : [
            `(${pricingType})`,
            NumberUtils.withCommas(
              campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
                CampaignTypeEnum.isContractCampaignType(campaignType)
                ? totalBudget
                : bidAmount
            ),
          ].join(' ')
    },
    toCsvStringArray({
      campaignType,
      goal,
      bidStrategy,
      bidStrategyTarget,
      pricingType,
      bidAmount,
      totalBudget,
    }) {
      const { type, value } = bidStrategyTarget || {}
      const autoBidPurpose =
        AdGroupConstraints.BidStrategy.getAutoBidPurposeType({
          campaignType,
          goal,
        })

      const autoBidLabel = checkNotEmpty(type)
        ? getBidStrategyTargetLabel(type, value)
        : AutoBidPurposeEnum.getName(autoBidPurpose)

      return [
        pricingType,
        BidStrategyEnum.isAdGroupAutoBid(bidStrategy)
          ? autoBidLabel
          : campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
          ? '-'
          : NumberUtils.withCommas(
              campaignType === CampaignTypeEnum.Type.DAUM_SHOPPING ||
                campaignType ===
                  CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED ||
                campaignType === CampaignTypeEnum.Type.ELECTION_2024_04 ||
                pricingType === PricingTypeEnum.Type.CPT
                ? totalBudget
                : bidAmount
            ),
      ]
    },
  },

  // campaign | adGroup
  Schedule: {
    toString({
      campaignType,
      scheduleStart,
      scheduleEnd,
      scheduleStartTime,
      scheduleEndTime,
    }) {
      const useTime = campaignType === CampaignTypeEnum.Type.TALK_CHANNEL

      const beginDateTime = !scheduleStart
        ? '시작일 미설정'
        : useTime && scheduleStartTime
        ? dateTimeToString([scheduleStart, scheduleStartTime].join('T'))
        : dateToString(scheduleStart)

      const notSetEndDateTime =
        !scheduleEnd || moment(scheduleEnd).year() >= 9999

      const endDateTime = notSetEndDateTime
        ? '종료일 미설정'
        : useTime && scheduleEndTime
        ? dateTimeToString([scheduleEnd, scheduleEndTime].join('T'))
        : dateToString(scheduleEnd)

      return [beginDateTime, endDateTime].join(' ~ ')
    },
  },

  CreativeSchedule: {
    toString({ campaignType, goal, beginDateTime, endDateTime, noSchedule }) {
      if (
        DashboardConstraints.Creative.isAvailableSchedule({
          campaignType,
          goal,
        })
      ) {
        return noSchedule
          ? '미설정'
          : [
              dateTimeToString(beginDateTime),
              dateTimeToString(endDateTime),
            ].join(' ~ ')
      }

      return '-'
    },
  },
}

export { DashboardCellUtils }
