import { checkEmpty, checkNotEmpty } from './regexUtils'
import { NumberUtils } from './numberUtils'
import { fromJS } from 'immutable'

export const getDiscountedPercent = (originAmount, discountedAmount) => {
  if (checkEmpty(originAmount) || checkEmpty(discountedAmount)) return null
  if (Number(originAmount) <= Number(discountedAmount)) return null

  return Math.floor(((originAmount - discountedAmount) / originAmount) * 100)
}

export const setDiscountedPricePercentage = asset => {
  const { price, discountedPrice } = asset

  const { amount } = price || {}
  const { amount: discountedAmount } = discountedPrice || {}

  if (
    checkEmpty(price) ||
    checkEmpty(amount) ||
    checkEmpty(discountedPrice) ||
    checkEmpty(discountedAmount)
  ) {
    return asset
  }

  const percentage = getDiscountedPercent(amount, discountedAmount)

  return checkNotEmpty(percentage)
    ? asset.set(
        'discountedPrice',
        fromJS({
          amount: discountedAmount,
          percentage,
        })
      )
    : asset
}

export const FORMATTED_PRICE_MAX_LENGTH_CATALOG =
  NumberUtils.withCommas(9_999_999_999).length

export const FORMATTED_PRICE_MAX_LENGTH_CAROUSEL =
  NumberUtils.withCommas(99_999_999).length
