import React, { useEffect } from 'react'
import { isChrome, isEdge } from 'react-device-detect'
import { shallowEqual, useSelector } from 'react-redux'
import { toLoungeBoardNoticeDetailPath } from '../../../utils/router/routeUtils'

const selector = ({
  notice: {
    noticeBanner: {
      data: { id, title },
    },
  },
}) => {
  return {
    id,
    title,
  }
}

const PromotionRibbon = () => {
  const { id, title } = useSelector(selector, shallowEqual)

  const [isVisible, setIsVisible] = React.useState(false)
  const promotionRibbon = localStorage.getItem('promotionRibbonExpireTime')

  const handleClose = () => {
    const promotionRibbonExpireTime = new Date()
    promotionRibbonExpireTime.setHours(23, 59, 59, 0)
    localStorage.setItem(
      'promotionRibbonExpireTime',
      promotionRibbonExpireTime.getTime()
    )
    setIsVisible(false)
  }

  useEffect(() => {
    const now = new Date()
    if (promotionRibbon && promotionRibbon > now.getTime()) {
      setIsVisible(false)
    } else {
      setIsVisible(isChrome || isEdge)
    }
  }, [promotionRibbon])

  if (!isVisible || id <= -1) return null

  return (
    <div
      className="guide_msg3"
      style={{ backgroundColor: `rgba(4, 189, 224, 0.9)`, zIndex: `99999` }}>
      <a
        href={toLoungeBoardNoticeDetailPath(id)}
        style={{ textDecoration: 'none' }}
        target={'_blank'}
        rel="noreferrer"
        onClick={() => setIsVisible(false)}>
        <p className="desc_msg" style={{ color: `#fff` }}>
          {title}
        </p>
      </a>
      <a className="link_close" onClick={handleClose}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

PromotionRibbon.propTypes = {}

export default PromotionRibbon
