import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkEmpty, validateInputLength } from '../../utils/regexUtils'

export const REVIEW_DOCUMENT_FORM_VALIDATION_KEY = keyMirror({
  REVIEW_DOCUMENT_FILE: null,
  REVIEW_DOCUMENTS_NUMBER_TO_UPLOAD: null
})

export const REVIEW_DOCUMENT_FORM_VALIDATION_KEY_PATH = Map({
  [REVIEW_DOCUMENT_FORM_VALIDATION_KEY.REVIEW_DOCUMENT_FILE]: ['file'],
  [REVIEW_DOCUMENT_FORM_VALIDATION_KEY.REVIEW_DOCUMENTS_NUMBER_TO_UPLOAD]: [
    'documents'
  ]
})

const defaultValidationResult = {
  isValid: true,
  message: ''
}
export const REVIEW_DOCUMENT_FORM_VALIDATOR = Map({
  [REVIEW_DOCUMENT_FORM_VALIDATION_KEY.REVIEW_DOCUMENT_FILE]: file => {
    if (!validateInputLength(file.name, 1, 255)) {
      return {
        isValid: false,
        message: '파일명은 최대 255자를 초과할 수 없습니다.'
      }
    } else if (file.size > 10 * 1024 * 1024 || file.size < 1024) {
      return {
        isValid: false,
        message: '업로드한 파일 용량을 확인 후 다시 시도하세요.'
      }
    }

    return defaultValidationResult
  },

  [REVIEW_DOCUMENT_FORM_VALIDATION_KEY.REVIEW_DOCUMENTS_NUMBER_TO_UPLOAD]: documents => {
    if (checkEmpty(documents) || documents.size === 0) {
      return {
        isValid: false,
        message: '업로드된 심사서류 파일이 없습니다.'
      }
    }

    return defaultValidationResult
  }
})
