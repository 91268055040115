import { ScaleImageFitCenterStyle } from '../../../../../../utils/imageUtils'
import ActionButtonEnum from '../../../../../../enums/ActionButtonEnum'
import React from 'react'
import ImageNativeTemplatePropTypes from '../imageNativeTemplatePropTypes'

const AddFriendPage = ({
  title,
  actionButton,
  profileName,
  profileImageURL,
}) => {
  return (
    <div className="material_default material_channel">
      <div className="material_box">
        <div className="profile_info">
          <span className="thumb_profile">
            {profileImageURL && (
              <span
                className="img_thumb"
                style={ScaleImageFitCenterStyle({
                  url: profileImageURL,
                  backgroundColor: '#ffffff',
                })}
              />
            )}
          </span>
        </div>
        <div className="box_induce">
          <strong className="tit_induce">
            {profileName?.trim() || '프로필 이름'}
          </strong>
          <p className="txt_induce">{title?.trim() || '타이틀'}</p>
          <span className="btn_induce">
            {ActionButtonEnum.getName(actionButton) || '행동유도문구'}
          </span>
        </div>
      </div>
    </div>
  )
}

AddFriendPage.propTypes = ImageNativeTemplatePropTypes.Page

export default AddFriendPage
