import { keyMirror } from '../utils/utils'
import { LOCATION_KEY } from '../utils/advertise/targeting'

const getSortedValue = (seq, map) => {
  return map.sort((a, b) => {
    const a1 = seq.indexOf(a)
    const a2 = seq.indexOf(b)
    return a1 > a2 ? 1 : -1
  })
}

/**
 * TODO 리팩토링 필요
 *  - gender, age, location 각각 분리
 *    - age 부분 분리 (DemoGraphicAgeEnum.js)
 *  - targeting.js 내 enum 제거
 *    - 연령 관련 이넘 제거
 */

export default {
  Gender: {
    Type: keyMirror({
      M: null,
      F: null,
    }),

    Name: {
      M: '남성',
      F: '여성',
    },

    getName(type) {
      return this.Name[type]
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getOrderedValues(genders) {
      return getSortedValue(this.values(), genders)
    },

    getGendersDetailOptionsValues(genders) {
      return genders?.size > 0
        ? this.getOrderedValues(genders)
            .map(v => this.getName(v))
            .join(', ')
        : '전체'
    },
  },

  Location: {
    Type: {
      Category: keyMirror({
        NATIONAL: null,
        GLOBAL: null,
        DISTRICT: null,
        DONG: null,
      }),

      CategoryV2: keyMirror({
        ALL: null,
        DISTRICT: null,
      }),

      District: keyMirror({
        GEO_SELECT: null, // 시/도
        GEO_SEARCH: null, // 시/군/구
      }),
    },

    Name: {
      NATIONAL: '전국',
      GLOBAL: '국내(전국) + 해외',
      DISTRICT: '행정구역',
      DONG: '동',
    },

    getName(type) {
      return this.Name[type]
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    getLocationDetailOptionValues(locationType, locations) {
      if (locationType === this.Type.CategoryV2.DISTRICT) {
        return '행정구역'
      }

      if (locations?.size > 0 && !locations?.includes(LOCATION_KEY.Z)) {
        return '전체(국내)'
      }

      return '전체(국내+해외)'
    },
  },
}
