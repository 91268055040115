import { keyMirror } from '../utils/utils'

export default {
  Preset: {
    Type: keyMirror({
      TODAY: null,
      YESTERDAY: null,
      THISWEEK: null,
      LASTWEEK: null,
      LAST7DAYS: null,
      LAST14DAYS: null,
      LAST30DAYS: null,
      THISMONTH: null,
      LASTMONTH: null,
      ALL: null,
      CUSTOM: null,
    }),

    Name: {
      TODAY: '오늘',
      YESTERDAY: '어제',
      THISWEEK: '이번 주',
      LASTWEEK: '지난 주',
      LAST7DAYS: '최근 7일',
      LAST14DAYS: '최근 14일',
      LAST30DAYS: '최근 30일',
      THISMONTH: '이번 달',
      LASTMONTH: '지난 달',
      ALL: '전체',
      CUSTOM: '맞춤 설정',
    },

    getName(type) {
      return this.Name[type]
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    reportPresetValues() {
      return this.values().filter(
        v => ![this.Type.TODAY, this.Type.ALL].includes(v)
      )
    },
  },

  Constraint: {
    Type: keyMirror({
      NONE: null,
      ONE_MONTH: null,
      TWO_MONTH: null,
      THREE_MONTH: null,
      ONE_YEAR: null,
    }),
  },
}
