import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import useComponentId from '../../../../../utils/hook/useComponentId'
import { changeCampaignFormByKey } from '../../../../../modules/advertise/mCampaignV2'
import CheckBox from '../../../../Common/CheckBox'
import cn from 'classnames'
import PopupHOC from '../../../../Popup/PopupHOC'
import PropTypes from 'prop-types'

const UtmParamCheckList = ({ close }) => {
  const dispatch = useDispatch()

  const [isCreativeLandingCheck, setCreativeLandingCheck] = useState(false)
  const [hasAcknowledgedLandingErrorPolicy, setAcknowledgedLandingErrorPolicy] =
    useState(false)

  const creativeLandingCheckBoxId = useComponentId()
  const acknowledgedLandingErrorPolicyCheckBoxId = useComponentId()

  const isButtonActive =
    isCreativeLandingCheck && hasAcknowledgedLandingErrorPolicy

  const handleCheckBoxChange = e => {
    switch (e.target.id) {
      case creativeLandingCheckBoxId: {
        setCreativeLandingCheck(prev => !prev)
        break
      }

      case acknowledgedLandingErrorPolicyCheckBoxId: {
        setAcknowledgedLandingErrorPolicy(prev => !prev)
        break
      }

      default: {
        //
      }
    }
  }

  const handleConfirm = () => {
    if (isButtonActive) {
      dispatch(changeCampaignFormByKey('utmParam', true))
      close()
    }
  }

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">UTM 파라미터 자동 설정 확인</strong>
      </div>
      <div className="layer_body">
        <p className="txt_top">
          본 캠페인 하위 소재 전체 랜딩 URL에 협력광고용 UTM 파라미터가 자동
          설정됩니다.
        </p>
        <div className="box_agree">
          <strong className="screen_out">설정 동의 안내</strong>
          <ul className="list_agree">
            <li>
              <CheckBox
                id={creativeLandingCheckBoxId}
                isChecked={isCreativeLandingCheck}
                label="캠페인 하위 소재 전체에 대해 UTM 파라미터를 설정한 랜딩이 정상적으로 동작하는 것을 테스트를 통해 확인했습니다."
                onChange={handleCheckBoxChange}
              />
              {/* FIXME 협력광고 10월30일 이후 가이드 제공시 되살리기 */}
              {/* <a */}
              {/*  href={''} */}
              {/*  rel="noopener noreferrer" */}
              {/*  target="_blank" */}
              {/*  className="link_guide"> */}
              {/*  UTM 파라미터 랜딩 테스트 방법 보러가기 */}
              {/*  <span className="ico_comm ico_outlink"></span> */}
              {/* </a> */}
            </li>
            <li>
              <CheckBox
                id={acknowledgedLandingErrorPolicyCheckBoxId}
                isChecked={hasAcknowledgedLandingErrorPolicy}
                label="미리 점검하지 않은 랜딩 오류 발생 시, 이는 보상 대상이 아닌 점을 이해했습니다."
                onChange={handleCheckBoxChange}
              />
            </li>
          </ul>
        </div>
        <p className="txt_agree">
          <em className="fw_bold">
            위 내용에 모두 동의하시는 경우 확인 버튼을 눌러주세요.
          </em>
        </p>
      </div>
      <div className="layer_foot">
        <div className="btn_group">
          <a className="btn_gm" onClick={close}>
            <span className="inner_g">취소</span>
          </a>
          <button
            type="submit"
            className={cn('btn_gm gm_bl', { in_active: !isButtonActive })}
            onClick={handleConfirm}>
            <span className="inner_g">확인</span>
          </button>
        </div>
        <a className="btn_close" onClick={close}>
          <span className="ico_comm ico_close">닫기</span>
        </a>
      </div>
    </div>
  )
}

UtmParamCheckList.propTypes = {
  close: PropTypes.func,
}

export default PopupHOC(UtmParamCheckList, {
  className: 'basic_layer layer_kclid', // kclid 마크업과 동일하여 재사용.
})
