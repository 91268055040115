/***
 * review 관련 API
 * 사업자
 */
import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPost,
} from '../../utils/fetchUtils'

/**
 * 8.1 심사 관리 등록 (카테고리 심사 등록)
 * @param reviewForm
 * @param adAccountId
 */
function createCategoryReview(reviewForm, adAccountId) {
  return fetchPost(
    `${CONTEXTPATH}/regCategoryDocument`,
    reviewForm,
    adAccountId
  )
}

/**
 * 8.4 adAccount별 심사 관리 리스트 조회(카테고리 심사 리스트 조회)
 * @param adAccountId
 */
function getReviewDocumentCategoryList(adAccountId) {
  return fetchGet(
    `${CONTEXTPATH}/regCategoryDocument/adAccounts/${adAccountId}/brief/`,
    adAccountId
  )
}
/**
 * 8.7 1차 카테고리 조회
 * @param adAccountId
 */
function getCreativeCategoriesFirst(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/creativeCategories`, adAccountId)
}

/**
 * 8.8 2차 카테고리 조회 + 필요서류
 * @param adAccountId
 * @param creativeCategoryId
 */
function getCreativeCategoriesSecond(adAccountId, creativeCategoryId) {
  return fetchGet(
    `${CONTEXTPATH}/creativeCategories/${creativeCategoryId}`,
    adAccountId
  )
}

/**
 * 8.13 카테고리 문서 리스트 (Page) <- 8.4 대체
 * @param adAccountId
 * @param pageIndex
 */
function fetchCategoryDocumentList(adAccountId, pageIndex) {
  return fetchGet(
    `${CONTEXTPATH}/regCategoryDocument/pages?page=${pageIndex}&size=10`,
    adAccountId
  )
}

/**
 * 8.13.1 카테고리 문서 총 심사서류 카운트
 * @param adAccountId
 */
function fetchTotalCategoryDocumentCount(adAccountId) {
  return fetchGet(`${CONTEXTPATH}/regCategoryDocument/count`, adAccountId)
}

/**
 * 8.14 카테고리별 심사 문서 조회
 * @param adAccountId
 * @param creativeCategoryId
 */

function getReviewDocumentByCategory(adAccountId, creativeCategoryId) {
  return fetchGet(
    `${CONTEXTPATH}/regCategoryDocument/adAccounts/${adAccountId}/${creativeCategoryId}`,
    adAccountId
  )
}

/**
 * 8.15 카테고리 심사 문서 다중 삭제
 * @param adAccountId
 * @param creativeCategoryId
 */

function deleteReviewDocumentCategory(adAccountId, creativeCategoryId) {
  return fetchDelete(
    `${CONTEXTPATH}/regCategoryDocument/adAccounts/${adAccountId}/creativeCategories/${creativeCategoryId}`,
    null,
    adAccountId
  )
}

/**
 * 8.15 카테고리 심사 문서 단일 삭제
 * @param adAccountId
 * @param regCategoryDocumentId
 */

function deleteReviewDocument(adAccountId, regCategoryDocumentId) {
  return fetchDelete(
    `${CONTEXTPATH}/regCategoryDocument/adAccounts/${adAccountId}/regCategoryDocuments/${regCategoryDocumentId}`,
    null,
    adAccountId
  )
}

export default {
  getReviewDocumentCategoryList,
  getCreativeCategoriesFirst,
  getCreativeCategoriesSecond,
  createCategoryReview,
  getReviewDocumentByCategory,
  deleteReviewDocumentCategory,
  deleteReviewDocument,
  fetchCategoryDocumentList,
  fetchTotalCategoryDocumentCount,
}
