import momentLocalStorage from './momentLocalStorage'
import momentSessionStorage from './momentSessionStorage'
import { isUndefinedOrNull } from '../regexUtils'
import { Subject } from 'rxjs'
import { keyMirror } from '../utils'

const STORAGE_TYPE = keyMirror({
  LOCAL: null,
  SESSION: null,
})

const storageFactory = ({
  storageType,
  storageKey,
  initialValue, // object
  useRx = false,
}) => {
  const storage =
    storageType === STORAGE_TYPE.LOCAL
      ? momentLocalStorage
      : storageType === STORAGE_TYPE.SESSION
      ? momentSessionStorage
      : undefined

  if (!storage) return {}

  const cache = (function () {
    const storageValue = storage.getItem(storageKey)
    const prevStorageValueObject = !isUndefinedOrNull(storageValue)
      ? JSON.parse(storageValue)
      : undefined

    const prevVersion = prevStorageValueObject?.version
    const nextVersion = initialValue.version
    const isVersionChanged =
      !isUndefinedOrNull(nextVersion) && nextVersion !== prevVersion

    if (!prevStorageValueObject || isVersionChanged) {
      storage.setItem(storageKey, JSON.stringify(initialValue))

      return initialValue
    } else {
      const nextStorageValueObject = {}

      Object.entries(initialValue).forEach(
        ([initialObjectKey, initialObjectValue]) => {
          const prevObjectValue = prevStorageValueObject[initialObjectKey]
          if (typeof prevObjectValue === typeof initialObjectValue) {
            if (typeof prevObjectValue === 'object') {
              nextStorageValueObject[initialObjectKey] = Object.assign(
                initialObjectValue,
                prevObjectValue
              )
            } else {
              nextStorageValueObject[initialObjectKey] = prevObjectValue
            }
          } else {
            nextStorageValueObject[initialObjectKey] = initialObjectValue
          }
        }
      )

      storage.setItem(storageKey, JSON.stringify(nextStorageValueObject))

      return nextStorageValueObject
    }
  })()

  const rxSubject = useRx ? new Subject() : undefined

  return {
    set(key, value) {
      cache[key] = value
      try {
        storage.setItem(storageKey, JSON.stringify(cache))
      } catch (ignored) {
        storage.clear()
      }
      rxSubject?.next()
    },
    get(key) {
      return cache[key]
    },
    delete(key) {
      this.set(key, undefined)
    },
    update(key, updater) {
      if (typeof updater === 'function') {
        const prev = this.get(key)
        if (prev) {
          const next = updater(prev)
          this.set(key, next)
        }
      }
    },
    all() {
      return cache
    },
    rxSubject,
  }
}

const LocalStorageFactory = ({ storageKey, initialValue, useRx }) =>
  storageFactory({
    storageType: STORAGE_TYPE.LOCAL,
    storageKey,
    initialValue,
    useRx,
  })

const SessionStorageFactory = ({ storageKey, initialValue, useRx }) =>
  storageFactory({
    storageType: STORAGE_TYPE.SESSION,
    storageKey,
    initialValue,
    useRx,
  })

export { LocalStorageFactory, SessionStorageFactory }
