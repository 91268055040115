import React from 'react'
import ReactDOM from 'react-dom'
import { shouldPullDownOpen } from '../../../utils/utils'
import hoistNonReactStatics from 'hoist-non-react-statics'

const FloatingViewHOC = Component => {
  class C extends React.PureComponent {
    state = { isVisible: false }

    componentDidMount() {
      this._isMounted = true

      document.addEventListener('click', this.onDocumentClick, true)
    }

    componentWillUnmount() {
      this._isMounted = false

      document.removeEventListener('click', this.onDocumentClick, true)
    }

    onDocumentClick = e => {
      if (!this._isMounted) return

      this.setState(prevState => {
        const isVisible = shouldPullDownOpen(
          ReactDOM.findDOMNode(this),
          e.target,
          this.state.isVisible
        )

        return prevState.isVisible && isVisible ? null : { isVisible }
      })
    }

    componentDidUpdate(prevProps, prevState, prevContext) {
      const { onShow, onDismiss } = this.props
      if (prevState.isVisible && !this.state.isVisible) {
        if (typeof onDismiss === 'function') {
          onDismiss()
        }
      }

      if (!prevState.isVisible && this.state.isVisible) {
        if (typeof onShow === 'function') {
          onShow()
        }
      }
    }

    render() {
      return (
        <Component
          {...this.props}
          {...this.state}
          show={() => this.setState({ isVisible: true })}
          dismiss={callback => {
            this.setState({ isVisible: false }, () => {
              if (typeof callback === 'function') {
                callback()
              }
            })
          }}
        />
      )
    }
  }

  C.displayName = `floatingViewHOC(${Component.displayName || Component.name})`
  C.WrappedComponent = Component

  return hoistNonReactStatics(C, Component)
}

export default FloatingViewHOC
