import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { fromJS } from 'immutable'
import { handleCohortException } from '../mCohort'

/**
 * 광고반응타겟 가져오기
 */
const CohortLoad = keyMirror(
  {
    STORE_COHORTS: null,
    INIT: null,
    SET_CAMPAIGN_ADGROUP_INFO: null,
  },
  'COHORT_LOAD'
)

const initialState = fromJS({
  cohortsAvailable: [],
  cohortBaseAdsWithAdGroupInfo: [],
})

export default createReducer(initialState, {
  [CohortLoad.STORE_COHORTS]: (state, { content }) => {
    return state.set('cohortsAvailable', fromJS(content))
  },

  [CohortLoad.SET_CAMPAIGN_ADGROUP_INFO]: (
    state,
    { cohortBaseAdsWithAdGroupInfo }
  ) =>
    state.set(
      'cohortBaseAdsWithAdGroupInfo',
      fromJS(cohortBaseAdsWithAdGroupInfo || [])
    ),

  [CohortLoad.INIT]: state => state.merge(initialState),
})

const storeCohortsAvailable = (content, availableTargetInfo) => ({
  type: CohortLoad.STORE_COHORTS,
  content,
  availableTargetInfo,
})

export function fetchCohortsList(adAccountId, onFinish) {
  return async (dispatch, getState, api) => {
    try {
      const {
        data: { content },
      } = await api.cohort.fetchCohorts(adAccountId, {
        type: 'audience', // 파라미터에 type=audience 붙으면 7.8.9 api (백엔드 기준)
        page: 0,
        size: 50,
      })

      const filteredContents = content.filter(v => v.status !== 'ERROR')

      dispatch(storeCohortsAvailable(filteredContents))
    } catch (e) {
      handleCohortException(dispatch, e)
    } finally {
      onFinish && onFinish()
    }
  }
}

export const initSelectCohort = () => ({
  type: CohortLoad.INIT,
})

export const setCohortBaseAdsWithAdGroupInfo =
  cohortBaseAdsWithAdGroupInfo => ({
    type: CohortLoad.SET_CAMPAIGN_ADGROUP_INFO,
    cohortBaseAdsWithAdGroupInfo,
  })
