import React from 'react'
import PopupProxy from '../../../../Popup/Common/PopupProxy'

export const duplicatedKakaoContentIdDialog = videoId => {
  return (
    <PopupProxy>
      <strong className="tit_layer">동영상ID 중복 안내</strong>
      <p className="txt_layer">추가하려는 동영상ID가 이미 설정되어있습니다.</p>
      <div className="overlap_keyword">
        <em className="tit_overlap">중복 동영상ID</em>
        {videoId}
      </div>
    </PopupProxy>
  )
}

export const availableCountOverKakaoContentDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">설정 가능 갯수 초과</strong>
      <p className="txt_layer">
        최대 설정 가능한 개수를 초과하여
        <br />
        더이상 추가할 수 없습니다.
      </p>
    </PopupProxy>
  )
}

export const kakaoTvContentsChangedDialog = onAccept => {
  return (
    <PopupProxy primaryButtonFunc={onAccept}>
      <strong className="tit_layer">동영상 게재 안내</strong>
      <p className="txt_layer">
        카카오TV 동영상 ID가 추가 또는 삭제된 경우
        <br />
        운영 담당자를 통해 게재 승인 또는 해제를 요청하세요.
        <br />
        담당자 처리 후 광고가 노출 또는 중단됩니다.
      </p>
    </PopupProxy>
  )
}

export const createAdGroupAgeVerificationSettingDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onAccept}
      autoClose={false}>
      <strong className="tit_layer">연령인증 메시지 설정</strong>
      <p className="txt_layer">
        광고그룹 하위 소재는
        <br />
        모두 연령인증 메시지로 생성됩니다.
      </p>
    </PopupProxy>
  )
}

export const modifyAdGroupAgeVerificationSettingDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onAccept}
      autoClose={false}>
      <strong className="tit_layer">연령인증 메시지 설정</strong>
      <p className="txt_layer">
        광고그룹 하위 소재가 있는 경우
        <br />
        모두 연령인증 메시지로 변경됩니다.
      </p>
    </PopupProxy>
  )
}

export const releaseAgeVerificationSettingDialog = onAccept => {
  return (
    <PopupProxy
      hasSecondaryButton={true}
      primaryButtonFunc={onAccept}
      autoClose={false}>
      <strong className="tit_layer">연령인증 메시지 해제</strong>
      <p className="txt_layer">
        광고그룹 하위 소재가 있는 경우
        <br />
        모두 일반 메시지로 변경됩니다.
      </p>
    </PopupProxy>
  )
}

export const releasePlusFriendGroupTargetDialog = (
  onAccept,
  plusFriendGroupTargetings
) => {
  const releaseTargets =
    plusFriendGroupTargetings?.map(({ name }) => name)?.join(', ') ?? ''

  return (
    <PopupProxy primaryButtonFunc={onAccept} autoClose={false}>
      <strong className="tit_layer">모수 준비중 타겟</strong>
      <p className="txt_layer">
        광고그룹에 설정된 친구그룹의 모수가
        <br />
        준비되지 않았습니다. 해당 타겟을 해제하고 광고그룹을
        <br />
        수정하시겠습니까?
      </p>
      <dl className="box_exceed">
        <dt>대상: {releaseTargets}</dt>
      </dl>
    </PopupProxy>
  )
}
