import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_IMAGE_TYPE,
  BIZ_BOARD_MASK_TYPE,
} from '../bizBoardUtils'
import BizBoardBannerImageMaskSelect from './BizBoardBannerImageMaskSelect'
import BizBoardBannerImageResizer from './BizBoardBannerImageResizer'

const imageTypeText = {
  [BIZ_BOARD_IMAGE_TYPE.LOGO]: '로고',
  [BIZ_BOARD_IMAGE_TYPE.IMAGE]: '이미지',
}

const BizBoardBannerImageControl = ({
  index,
  bannerType,
  maskType,
  imageType,
  imageCount,
  validationState,
  availableMaskOptions = [],
  active,
  customEventHandlerRef,
  handleTemplateChange,
  onMaskTypeChange,
}) => {
  const [isOpen, setIsOpen] = React.useState(true)

  const maskOptions = availableMaskOptions.filter(
    v =>
      v.bannerType === bannerType &&
      (v.imageCount ? v.imageCount === imageCount : true)
  )

  const isAvailableImageMaskSelect = React.useMemo(
    () =>
      ((bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && imageCount === 1) ||
        bannerType === BIZ_BOARD_BANNER_TYPE.MASKING) &&
      index === 0,
    [bannerType, imageCount, index]
  )

  const errorMessage = useMemo(() => {
    if (bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && index === 0) {
      return validationState[BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_1]
        ?.message
    } else if (bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && index === 1) {
      return validationState[BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_2]
        ?.message
    } else if (bannerType === BIZ_BOARD_BANNER_TYPE.MASKING && index === 0) {
      return validationState[BIZ_BOARD_FORM_VALIDATION_KEY.FULL_IMAGE_1]
        ?.message
    }
  }, [bannerType, index, validationState])

  return (
    <>
      <div
        className={cx('box_imgedit', { fold_on: isOpen })}
        style={{ display: 'block' }}>
        <strong className="tit_bnrimg">
          {imageTypeText[imageType]}
          {bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && imageCount > 1
            ? index + 1
            : ''}{' '}
          편집
        </strong>
        <a
          className="btn_more"
          onClick={e => {
            e.preventDefault()
            setIsOpen(prev => !prev)
          }}>
          <span className="ico_comm ico_more">내용 더보기</span>
        </a>
        <div
          className={cx('list_imgedit', {
            list_masking: bannerType === BIZ_BOARD_BANNER_TYPE.MASKING,
          })}>
          {isAvailableImageMaskSelect && (
            <BizBoardBannerImageMaskSelect
              maskOptions={maskOptions}
              maskType={maskType}
              handleMaskTypeChange={onMaskTypeChange}
            />
          )}
          <BizBoardBannerImageResizer
            index={index}
            active={active}
            customEventHandlerRef={customEventHandlerRef}
            handleTemplateChange={handleTemplateChange}
          />
        </div>
      </div>
      {errorMessage && <p className="txt_error">{errorMessage}</p>}
    </>
  )
}

BizBoardBannerImageControl.propTypes = {
  index: PropTypes.number,
  badgeType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BADGE_TYPE)),
  bannerType: PropTypes.oneOf(Object.keys(BIZ_BOARD_BANNER_TYPE)),
  maskType: PropTypes.oneOf(Object.keys(BIZ_BOARD_MASK_TYPE)),
  imageType: PropTypes.oneOf(Object.keys(BIZ_BOARD_IMAGE_TYPE)),
  validationState: PropTypes.object,
  imageCount: PropTypes.number,
  availableMaskOptions: PropTypes.array,
  active: PropTypes.bool,
  customEventHandlerRef: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
  onMaskTypeChange: PropTypes.func,
}

export default BizBoardBannerImageControl
