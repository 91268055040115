import { Map } from 'immutable'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import { PERSONAL_MESSAGE_FORM_VALIDATION_KEY } from './personalMessageFormValidationKey'
import { PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE } from './personalMessageFormValidationMessage'
import {
  checkEmpty,
  hasFullWidthWhitespace,
  hasUrl,
} from '../../utils/regexUtils'
import VideoEnum from '../../enums/VideoEnum'
import { countCharacterOccurrences } from '../../utils/stringUtils'
import { personalMessageFormButtonAssetGroupValidation } from './personalMessageFormValidator'

export const PERSONAL_MESSAGE_PREMIUM_VIDEO_FORM_VALIDATOR = Map({
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.TITLE]: formData => {
    const { title } = formData
    if (hasUrl(title)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.LINK_IS_NOT_AVAILABLE
      )
    } else if (hasFullWidthWhitespace(title)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }

    return IS_VALID()
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.DESCRIPTION]: formData => {
    const { description } = formData
    if (countCharacterOccurrences(description, '\n') > 1) {
      return IS_NOT_VALID('줄바꿈은 1개까지 입력 가능합니다.')
    } else if (hasUrl(description)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.LINK_IS_NOT_AVAILABLE
      )
    } else if (hasFullWidthWhitespace(description)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }

    return IS_VALID()
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM]: formData => {
    const { video } = formData

    if (!video || video?.isEmpty()) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    return IS_VALID()
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.VIDEO_TITLE]: formData => {
    const { messageVideoTitle } = formData

    const { video } = formData
    const { videoType } = video || {}

    if (VideoEnum.isTitleRequired(videoType)) {
      if (checkEmpty(messageVideoTitle)) {
        return IS_NOT_VALID(
          PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED
        )
      }

      if (hasFullWidthWhitespace(messageVideoTitle)) {
        return IS_NOT_VALID(
          PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
        )
      }
    }

    return IS_VALID()
  },

  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]:
    buttonAssetGroups => {
      return personalMessageFormButtonAssetGroupValidation(buttonAssetGroups)
    },
})
