import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import {
  checkNotEmpty,
  checkUrl,
  validateInputLength,
} from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'

export const CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY = keyMirror({
  CUSTOMER_FILES_UPLOAD_NAME: null,
  CUSTOMER_FILES_UPLOAD_FILE: null,
  URL: null,
})

export const CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY_PATH = Map({
  [CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_NAME]: [
    'name',
  ],

  [CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.URL]: ['sourceUrl'],
})

export const CUSTOMER_FILES_UPLOAD_FORM_VALIDATOR = Map({
  [CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.URL]: sourceUrl => {
    if (!checkNotEmpty(sourceUrl.trim())) {
      return { isValid: false, message: 'URL을 입력하세요.' }
    }

    if (!checkUrl(sourceUrl)) {
      return IS_NOT_VALID(
        'http:// 혹은 https:// 를 포함하여 정상적인 형식의 URL을 입력하세요.'
      )
    }

    return IS_VALID()
  },

  [CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_NAME]: (
    name,
    formData
  ) => {
    const { customerFileId = -1 } = formData

    if (customerFileId === -1) {
      if (!checkNotEmpty(name.trim())) {
        return IS_NOT_VALID('고객파일 이름을 입력하세요.')
      }

      if (!validateInputLength(name, 1, 50)) {
        return IS_NOT_VALID('고객파일 이름은 1~50자까지 입력가능합니다.')
      }
    }

    return IS_VALID()
  },
})
