import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { MomentLoader } from '../Loader/MomentLoader'
import { convertStringToDOMArray } from '../../../utils/stringUtils'
import { keyMirror } from '../../../utils/utils'

class InputTextArea extends React.PureComponent {
  static MAX_LENGTH_CHANGE_MODE = keyMirror({
    CLEAR: null,
    TRIM: null,
  })

  static defaultProps = {
    className: 'box_textarea2 textarea_s',
    maxLength: 999,
    maxLengthChangeMode: InputTextArea.MAX_LENGTH_CHANGE_MODE.CLEAR,
    readOnly: false,
    isShowingInputLength: false,
    error: false,
    loading: false,
    autoNewLine: true,
    active: true,
    spellCheck: false,
    onChange: () => undefined,
    onClick: () => undefined,
    onFocus: () => undefined,
    onBlur: () => undefined,
    onEnterKey: () => undefined,
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    placeholder: PropTypes.any,
    value: PropTypes.any,
    maxLength: PropTypes.number,
    maxLengthChangeMode: PropTypes.oneOf(
      Object.keys(InputTextArea.MAX_LENGTH_CHANGE_MODE)
    ),
    inputRef: PropTypes.any,
    readOnly: PropTypes.bool,
    isShowingInputLength: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onEnterKey: PropTypes.func,
    error: PropTypes.bool,
    loading: PropTypes.bool,
    children: PropTypes.any,
    autoNewLine: PropTypes.bool,
    active: PropTypes.bool,
    spellCheck: PropTypes.bool,
  }

  state = {
    hasFocus: false,
    inputText: this.props.value || '',
    maxLength: this.props.maxLength,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.maxLength !== nextProps.maxLength) {
      return { maxLength: nextProps.maxLength }
    }

    if (prevState.inputText !== nextProps.value) {
      return { inputText: nextProps.value || '' }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.maxLength !== this.props.maxLength) {
      // set native value -> trigger input `change` event
      const inputEl = document.getElementById(this.props.id)
      const prevValue = inputEl.value
      inputEl.value = this.getInputValueByMaxLengthChangeMode()
      const event = new Event('input', { bubbles: true })
      const tracker = inputEl._valueTracker
      if (tracker) {
        tracker.setValue(prevValue)
      }

      inputEl.dispatchEvent(event)
    }
  }

  render() {
    const {
      id,
      className,
      placeholder,
      value,
      maxLength,
      inputRef,
      readOnly,
      error,
      children,
      loading,
      autoNewLine,
      active,
      isShowingInputLength,
      spellCheck,
    } = this.props

    const { inputText } = this.state
    const isPlaceholderVisible =
      (!inputText || inputText.length === 0) && (!value || value.length === 0)

    const textLength = maxLength - inputText.length

    return (
      <div
        className={cx(className, {
          on: this.state.hasFocus,
          in_error: error,
          loading,
          in_active: !active,
        })}>
        <label htmlFor={id} className="lab_txt">
          {isPlaceholderVisible && convertStringToDOMArray(placeholder)}
        </label>
        {children}
        {isShowingInputLength && maxLength >= 0 && (
          <span className="num_byte">
            <span className="screen_out">작성가능한 총 텍스트 수</span>
            {textLength >= 0 ? textLength : ''}
          </span>
        )}
        <textarea
          name={id}
          id={id}
          className="tf_area"
          value={inputText}
          maxLength={maxLength}
          ref={inputRef}
          readOnly={readOnly}
          disabled={!active}
          onChange={this.handleEvent}
          onFocus={this.handleEvent}
          onBlur={this.handleEvent}
          onClick={this.handleEvent}
          onKeyPress={this.handleEvent}
          wrap={autoNewLine ? null : 'off'} // IE 에서의 개행 이슈 해결.
          spellCheck={spellCheck}
        />
        {loading && (
          <div className="thumb_loading">
            <MomentLoader />
          </div>
        )}
      </div>
    )
  }

  getInputValueByMaxLengthChangeMode = () => {
    switch (this.props.maxLengthChangeMode) {
      case InputTextArea.MAX_LENGTH_CHANGE_MODE.CLEAR: {
        return ''
      }

      case InputTextArea.MAX_LENGTH_CHANGE_MODE.TRIM: {
        return String(this.state.inputText).slice(0, this.props.maxLength)
      }

      default: {
        break
      }
    }

    return ''
  }

  handleEvent = e => {
    if (this.props.readOnly || !this.props.active) return

    switch (e.type) {
      case 'focus':
        this.setState({ hasFocus: true })
        this.props.onFocus(e)
        break

      case 'blur':
        /**
         * Firefox issue
         * http://tirdadc.github.io/blog/2015/06/11/react-dot-js-firefox-issue-with-onblur/
         */
        if (
          e.nativeEvent.explicitOriginalTarget &&
          e.nativeEvent.explicitOriginalTarget === e.nativeEvent.originalTarget
        )
          return
        this.setState({ hasFocus: false }, () => this.props.onBlur(e))
        break

      case 'change':
        if (e.target.value.length > this.props.maxLength) return
        this.props.onChange(e)
        break

      case 'click':
        this.props.onClick(e)
        break

      case 'keypress':
        if (e.key === 'Enter') {
          this.props.onEnterKey(e)
        }
        break
      default:
    }
  }
}

export default InputTextArea
