import { Map } from 'immutable'
import {
  checkEmpty,
  checkNotEmpty,
  hasFullWidthWhitespace,
} from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  personalMessageCouponBookAssetGroupValidation,
  personalMessageFormButtonAssetGroupValidation,
  personalMessageFormItemValidation,
  personalMessageFormLandingValidation,
  personalMessageFormPcLandingValidation,
  personalMessageFormTitleValidation,
} from './personalMessageFormValidator'
import { PERSONAL_MESSAGE_FORM_VALIDATION_KEY } from './personalMessageFormValidationKey'
import { PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE } from './personalMessageFormValidationMessage'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import VideoEnum from '../../enums/VideoEnum'

export const PERSONAL_MESSAGE_WIDE_FORM_VALIDATOR = Map({
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.TITLE]: ({ title, creativeFormat }) => {
    return personalMessageFormTitleValidation(title, creativeFormat)
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.BUTTON_ASSET_GROUPS]:
    buttonAssetGroups => {
      return personalMessageFormButtonAssetGroupValidation(buttonAssetGroups)
    },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: ({
    itemAssetGroups,
    creativeFormat,
  }) => {
    const itemAssetGroupsValidationResults = []

    itemAssetGroups.forEach(itemAsset => {
      const { ordering, image, title, pcLandingUrl, video, messageVideoTitle } =
        itemAsset

      // 와이드 리스트형 메시지 리스트4는 옵션 (단 한개라도 세팅시에는 itemAsset validation 진행)
      const isNotNeedForthListValidation =
        creativeFormat === CreativeFormatEnum.Type.WIDE_LIST_MESSAGE &&
        ordering === 3 &&
        checkEmpty(image) &&
        checkEmpty(video) &&
        checkEmpty(title)

      // 와이드 리스트형 메시지 리스트의 첫번째 홍보 문구 선택 사항
      const isNotNeedTitleValidation =
        creativeFormat === CreativeFormatEnum.Type.WIDE_LIST_MESSAGE &&
        ordering === 0 &&
        checkEmpty(title)

      const isNotNeedValidation =
        isNotNeedTitleValidation || isNotNeedForthListValidation

      const assetValidationResult = personalMessageFormItemValidation(
        itemAsset,
        !isNotNeedForthListValidation
      )

      const assetTitleValidationResult =
        checkNotEmpty(video) &&
        VideoEnum.isTitleRequired(video.get('videoType'))
          ? checkEmpty(messageVideoTitle)
            ? IS_NOT_VALID(PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
            : hasFullWidthWhitespace(messageVideoTitle)
            ? IS_NOT_VALID(
                PERSONAL_MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
              )
            : IS_VALID()
          : IS_VALID()

      const titleValidationResult =
        creativeFormat === CreativeFormatEnum.Type.WIDE_LIST_MESSAGE &&
        !isNotNeedValidation
          ? personalMessageFormTitleValidation(title, creativeFormat)
          : IS_VALID()

      const landingValidationResult = checkNotEmpty(image)
        ? personalMessageFormLandingValidation({
            landingInfo: itemAsset,
            isRequired: true,
            creativeFormat,
          })
        : IS_VALID()

      const pcLandingValidationResult =
        personalMessageFormPcLandingValidation(pcLandingUrl)

      const isValid = [
        assetValidationResult,
        assetTitleValidationResult,
        titleValidationResult,
        landingValidationResult,
        pcLandingValidationResult,
      ].every(v => v.isValid)

      itemAssetGroupsValidationResults.push({
        ordering,
        isValid,
        asset: assetValidationResult,
        assetTitle: assetTitleValidationResult,
        title: titleValidationResult,
        landing: landingValidationResult,
        pcLanding: pcLandingValidationResult,
      })
    })

    const isAllValid = itemAssetGroupsValidationResults.every(v => {
      return v.isValid
    })

    return isAllValid
      ? IS_VALID()
      : IS_NOT_VALID('', { itemAssetGroupsValidationResults })
  },
  [PERSONAL_MESSAGE_FORM_VALIDATION_KEY.COUPON_BOOK_ASSET_GROUPS]:
    couponBookAssetGroups => {
      return personalMessageCouponBookAssetGroupValidation(
        couponBookAssetGroups
      )
    },
})
