import create from './mCreativeCreateV2'
import modify from './mCreativeModifyV2'
import common from './mCreativeCommonV2'
import nativeCreate from './mNativeCreateV2'
import bannerCreate from './mBannerCreateV2'
import promotionalVideo from './mPromotionalVideo'
import opinionProof from './mOpinionProof'
import catalog from './mCatalog'
import view from './mCreativeViewV2'
import creativeLibrary from './mCreativeLibrary'
import bizBoardImageEditor from './mBizBoardImageEditor'
import bizBoardCreateBundle from './mBizBoardCreateBundle'
import pcTalkBottomCreateBundle from './mPcTalkBottomCreateBundle'
import bizBoardExpandable from './mBizBoardExpandable'
import creativeMessage from './mMessageCreative'
import dynamicCatalog from './mDynamicCatalog'
import { combineReducers } from 'redux-immutablejs'

export default combineReducers({
  create,
  modify,
  common,
  nativeCreate,
  bannerCreate,
  promotionalVideo,
  opinionProof,
  catalog,
  dynamicCatalog,
  view,
  creativeLibrary,
  bizBoardImageEditor,
  bizBoardCreateBundle,
  bizBoardExpandable,
  creativeMessage,
  pcTalkBottomCreateBundle,
})
