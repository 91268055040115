import GuideTooltip from '../../../../Tooltip/TooltipImpl/GuideTooltip'
import React from 'react'
import {
  GUIDE_STORAGE_ITEM,
  GuideStorageLocal,
} from '../../../../../utils/storage/storageFactoryImpl'
import PropTypes from 'prop-types'
import { Tooltip } from '../../../../../resources/locale'

const ImageEditorGuideTooltip = ({ targetId }) => {
  const [shouldShow, setShouldShow] = React.useState(false)

  React.useEffect(() => {
    const shouldShow =
      GuideStorageLocal.get(
        GUIDE_STORAGE_ITEM.CREATIVE_LIBRARY_IMAGE_EDITOR
      ) !== true

    if (shouldShow) {
      GuideStorageLocal.set(
        GUIDE_STORAGE_ITEM.CREATIVE_LIBRARY_IMAGE_EDITOR,
        true
      )

      setShouldShow(true)
    }
  }, [])

  return (
    shouldShow && (
      <GuideTooltip
        content={Tooltip('cmp.editor.guide_modify_button')}
        showDelay={100}
        dismissTimeout={10 * 1000}
        styles={{ backgroundColor: '#326EDC', color: '#fff' }}
        getElement={() => document.getElementById(targetId)}
      />
    )
  )
}

ImageEditorGuideTooltip.displayName = 'ImageEditorGuideTooltip'
ImageEditorGuideTooltip.propTypes = {
  targetId: PropTypes.any.isRequired,
}

export default ImageEditorGuideTooltip
