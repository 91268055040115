import { Map } from 'immutable'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import {
  validateCompatibleLandingUrl,
  validateCreativeActionButton,
  validateCreativeAltText,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeTitle,
  validateLandingInfo,
} from './creativeFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  hasFullWidthWhitespace,
  isUndefinedOrNull,
  validateColorCodeValue,
  validateInputLength,
} from '../../../utils/regexUtils'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'
import validateColor from 'validate-color'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const PC_TALK_RICH_POP_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: ['landingInfo'],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: ['title'],
  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_BANNER_IMAGE]: ['bannerImage'],
  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_IMAGE_CREATE]: ['richPopImage'],
  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_BACKGROUND_COLOR]: [
    'actionButtonBackgroundColor',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: ['actionButton'],
  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_FONT_COLOR]: [
    'actionButtonFontColor',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_PROMOTIONAL_IMAGE]: [
    'compatibleImage',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_LANDING_URL]: [
    'compatiblePcLandingUrl',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_TITLE]: ['compatibleTitle'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const PC_TALK_RICH_POP_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: landingInfo => {
    return validateLandingInfo(landingInfo)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.TITLE]: title => {
    if (checkEmpty(title)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (hasFullWidthWhitespace(title)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    if (!validateInputLength(title, 1, 28)) {
      return IS_NOT_VALID('타이틀은 1~28자까지 입력가능합니다.')
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_BANNER_IMAGE]: bannerImage => {
    if (checkEmpty(bannerImage)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_IMAGE_CREATE]: richPopImage => {
    if (checkEmpty(richPopImage)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.RICH_POP_IMAGE_MODIFY]: image => {
    if (checkEmpty(image)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_BACKGROUND_COLOR]: (
    actionButtonBackgroundColor,
    formData
  ) => {
    const { richPopImage, id, image } = formData
    const isModifying = id > 0
    const targetImage = isModifying ? image : richPopImage
    if (
      !isUndefinedOrNull(targetImage) && // 이미지 업로드 하지 않은 경우, validation 걸리지 않음
      !(
        (
          validateColor(actionButtonBackgroundColor) &&
          validateColorCodeValue(actionButtonBackgroundColor)
        ) // 코드값 규칙 체크
      )
    ) {
      return IS_NOT_VALID(
        '샵(#)과 함께 6자리의 올바른 색상코드를 입력하세요. (예:#000000)'
      )
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON]: actionButton => {
    return validateCreativeActionButton(actionButton)
  },

  [CREATIVE_FORM_VALIDATION_KEY.ACTION_BUTTON_FONT_COLOR]:
    actionButtonFontColor => {
      if (checkEmpty(actionButtonFontColor)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_PROMOTIONAL_IMAGE]:
    compatibleImage => {
      if (checkEmpty(compatibleImage)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_LANDING_URL]: (
    compatiblePcLandingUrl,
    formData
  ) => {
    return validateCompatibleLandingUrl({ compatiblePcLandingUrl, formData })
  },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_TITLE]: (
    compatibleTitle,
    formData
  ) => {
    const { profileName } = formData || {}

    if (checkEmpty(compatibleTitle)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (hasFullWidthWhitespace(compatibleTitle)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    if (!validateInputLength(compatibleTitle, 1, 25)) {
      return IS_NOT_VALID('타이틀은 1~25자까지 입력가능합니다.')
    }

    if (profileName === compatibleTitle) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE)
    }
    return validateCreativeTitle(compatibleTitle, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.RICH_NATIVE,
        isNew: !isModify,
      })
    )
  },
})

export {
  PC_TALK_RICH_POP_FORM_VALIDATION_KEY_PATH,
  PC_TALK_RICH_POP_FORM_VALIDATOR,
}
