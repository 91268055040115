import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

SelectBox.propTypes = {
  title: PropTypes.string.isRequired,
  imageSrc: PropTypes.string,
  className: PropTypes.string,
  TagComponent: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.object,
  ]),
  onClick: PropTypes.func,
}

function SelectBox({ title, imageSrc, className, TagComponent, onClick }) {
  return (
    <>
      <a className={classNames('link_selected', className)} onClick={onClick}>
        {TagComponent && <TagComponent />}
        {imageSrc && (
          <span className="img_option">
            <img src={imageSrc} alt="셀렏트박스 이미지" />
          </span>
        )}
        {title}
      </a>
      <span className="ico_arr" />
    </>
  )
}

export default SelectBox
