import React from 'react'
import { shallowEqual, useSelector } from 'react-redux'
import { LOADING_KEY } from '../../../modules/common/mLoading'
import LnbAdAccountSelect from './LnbAdAccountSelect'
import LnbAdAccountInfo from './LnbAdAccountInfo'
import { Link } from 'react-router-dom'

const selector = ({
  loading,
  adAccountListSelector: { selectedAdAccount: adAccountInfoSimple },
  lnb: { adAccountInfo },
}) => {
  return {
    isAdAccountOnOffLoading: loading.has(
      LOADING_KEY.DASHBOARD_V3_AD_ACCOUNT_INFO
    ),
    adAccountInfo,
    adAccountInfoSimple,
  }
}

const LnbTop = () => {
  const adAccountSelectHandlerRef = React.useRef()

  const { adAccountInfo, adAccountInfoSimple } = useSelector(
    selector,
    shallowEqual
  )

  const { adAccountId: adAccountSelectId, name: adAccountSelectName } =
    adAccountInfoSimple

  const { id: adAccountInfoId, status } = adAccountInfo

  React.useLayoutEffect(() => {
    const kakaoWrapNode = document.getElementById('kakaoWrap')

    const mo = new MutationObserver(mutations => {
      const isLnbOver = mutations[0]?.target?.classList?.contains('lnb_over') // 커서 over 로 open 한 경우
      const isLnbOn = mutations[0]?.target?.classList?.contains('lnb_on') // 명시적으로 open 한 경우

      /**
       * over 상태에서 leave 된 경우 닫기.
       * - 명시적으로 open 한 경우에는 유지.
       */
      if (!isLnbOver && !isLnbOn) {
        adAccountSelectHandlerRef.current?.dismiss?.()
      }
    })

    if (kakaoWrapNode) {
      mo.observe(kakaoWrapNode, { attributes: true })
    }

    return () => {
      mo.disconnect()
    }
  }, [])

  return (
    <div className="cont_info">
      <div className="account_thumb">
        <span className="ico_gnb" />
        <h2 className="tit_service">
          <Link
            className="link_service"
            to={`/dashboard/${adAccountInfoId ?? adAccountSelectId}`}>
            모먼트 관리자센터
          </Link>
        </h2>
      </div>
      <div className="account_info">
        {adAccountSelectId > 0 && (
          <LnbAdAccountSelect
            ref={adAccountSelectHandlerRef}
            adAccountId={adAccountSelectId}
            adAccountName={adAccountSelectName}
            adAccountStatus={status}
          />
        )}
        {adAccountInfoId > 0 && (
          <LnbAdAccountInfo adAccountInfo={adAccountInfo} />
        )}
      </div>
    </div>
  )
}

export default LnbTop
