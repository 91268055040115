import { keyMirror } from '../../utils/utils'
import { fromJS, List } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import {
  getAdUnitGroup,
  getDimensionGroupKey,
  getDimensionName,
} from '../../components/ReportV2/Common/reportDimension'
import DimensionGroupEnum from '../../enums/DimensionGroupEnum'
import { closePopup, openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import DimensionEnum from '../../enums/DimensionEnum'
import { reportNotExistDimensionFilterDialog } from '../../components/ReportV2/Dialog/ReportCommonDialog'
import { deleteReportFilterByDimension } from './mReportFilter'
import { showErrorMessage } from '../../utils/alertUtils'
import ReportAdUnitTypeEnum from '../../enums/ReportAdUnitTypeEnum'
import { checkNotEmpty } from '../../utils/regexUtils'

const ReportCommon = keyMirror(
  {
    SET_DIMENSIONS: null,
    SET_METRICS: null,
    INIT: null,
    CLEAR: null,
  },
  'REPORT_COMMON'
)

const initialState = fromJS({
  /**
   *  [
   *    CAMPAIGN_ID, CAMPAIGN_NAME, AD_GROUP_ID, AD_GROUP_NAME, CREATIVE_ID, CREATIVE_NAME
   *  ]
   */
  adUnits: [DimensionEnum.AdUnit.Type.CAMPAIGN_NAME],

  /**
   * Setting, Targeting, periodUnit 혼합
   *  [
   *    CAMPAIGN_TYPE, GOAL, OBJECTIVE_DETAIL_TYPE, LOCATION, DEVICE, WEEK, ...
   *  ]
   */
  dimensions: [DimensionEnum.PeriodUnit.Type.DAY],

  /**
   *  [
   *    imp, click, ctr, cost_per_video_play_3s, cost_per_click, ...
   *  ]
   */
  metrics: ['cost', 'imp', 'click', 'ctr', 'msg_send', 'video_play_3s', 'vtr'],
})

export default createReducer(initialState, {
  [ReportCommon.SET_DIMENSIONS]: (state, { data }) => {
    const selectedDimensions = fromJS(
      Array.from(data).map(dimension => ({
        group: getDimensionGroupKey(dimension),
        dimension,
        ...(DimensionGroupEnum.Type.AdUnit ===
          getDimensionGroupKey(dimension) && {
          adUnitType: getAdUnitGroup(dimension),
        }),
      }))
    )

    const adUnitSortBase = ReportAdUnitTypeEnum.values()

    return state.withMutations(s =>
      s
        .set(
          'adUnits',
          selectedDimensions
            .filter(({ group }) => group === DimensionGroupEnum.Type.AdUnit)
            .sort(
              // 광고단위 순서에 맞게 정렬 (ID, NAME 간 순서는 설정한 순서대로.)
              (a, b) =>
                adUnitSortBase.indexOf(a.get('adUnitType')) -
                adUnitSortBase.indexOf(b.get('adUnitType'))
            )
            .map(({ dimension }) => dimension)
        )
        .set(
          'dimensions',
          selectedDimensions
            .filter(({ group }) => group !== DimensionGroupEnum.Type.AdUnit)
            .map(({ dimension }) => dimension)
        )
    )
  },

  [ReportCommon.SET_METRICS]: (state, { data }) => {
    return state.set('metrics', List(Array.from(data)))
  },

  [ReportCommon.INIT]: (state, { adUnits, dimensions, metrics }) =>
    state.withMutations(mutable =>
      mutable
        .set('adUnits', adUnits)
        .set('dimensions', dimensions)
        .set('metrics', metrics)
    ),

  [ReportCommon.CLEAR]: () => initialState,
})

const setDimensions = data => ({
  type: ReportCommon.SET_DIMENSIONS,
  data,
})

const setMetrics = data => ({
  type: ReportCommon.SET_METRICS,
  data,
})

export const setReportDimensionMetricSettingValues = (
  selectedDimensions = new Set(),
  selectedMetrics = new Set()
) => {
  return (dispatch, getState) => {
    const {
      reportV2: {
        filter: { filterConditions },
        table: { sort },
        common: { dimensions },
      },
    } = getState()

    const prevTargetingFilterType = dimensions.find(filterType =>
      DimensionEnum.Targeting.values().includes(filterType)
    )

    const filterConditionArray = filterConditions.map(
      ({ filterType }) => filterType
    )

    let usedFilterConditionType = filterConditionArray.includes(
      prevTargetingFilterType
    )

    if (
      prevTargetingFilterType === DimensionEnum.Targeting.Type.DEVICE_PLACEMENT
    ) {
      usedFilterConditionType = filterConditionArray.some(filterType =>
        [
          DimensionEnum.Targeting.Type.DEVICE,
          DimensionEnum.Targeting.Type.PLACEMENT,
        ].includes(filterType)
      )
    } else if (
      prevTargetingFilterType === DimensionEnum.Targeting.Type.AGE_BAND_GENDER
    ) {
      usedFilterConditionType = filterConditionArray.some(filterType =>
        [
          DimensionEnum.Targeting.Type.AGE_BAND,
          DimensionEnum.Targeting.Type.GENDER,
        ].includes(filterType)
      )
    }

    const hasInvalidFilter =
      usedFilterConditionType &&
      !selectedDimensions.has(prevTargetingFilterType)

    if (hasInvalidFilter) {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          reportNotExistDimensionFilterDialog({
            onOk: () => {
              if (sort === prevTargetingFilterType) {
                showErrorMessage(
                  '정렬이 설정된 항목은 삭제가 불가합니다. 유지하고자 하는 항목에 정렬 설정후 다시 시도하세요.'
                )
              } else {
                dispatch(
                  setDimensions(
                    new Set(
                      Array.from(selectedDimensions).filter(
                        v => prevTargetingFilterType !== v
                      )
                    )
                  )
                )

                const shouldDeleteFilterTypes = filterConditionArray.filter(
                  filterType =>
                    DimensionEnum.Targeting.values().includes(filterType)
                )

                if (checkNotEmpty(shouldDeleteFilterTypes)) {
                  dispatch(
                    deleteReportFilterByDimension(shouldDeleteFilterTypes)
                  )
                }

                dispatch(closePopup(POPUP_KEY.REPORT_DIMENSION_METRIC_SETTING))
              }
            },
            dimensionName: getDimensionName(prevTargetingFilterType),
          })
        )
      )
    } else {
      dispatch(setDimensions(selectedDimensions))
      dispatch(closePopup(POPUP_KEY.REPORT_DIMENSION_METRIC_SETTING))
    }

    dispatch(setMetrics(selectedMetrics))
  }
}

export const initReportCommon = ({ adUnits, dimensions, metrics }) => ({
  type: ReportCommon.INIT,
  adUnits,
  dimensions,
  metrics,
})

export const clearReportCommon = () => ({
  type: ReportCommon.CLEAR,
})
