import React from 'react'
import PropTypes from 'prop-types'
import PulseLoader from 'react-spinners/PulseLoader'
import { css } from '@emotion/react'

// const defaultStyle = {
//   position: 'absolute',
//   left: '50%',
//   top: '50%',
//   margin: '-10.5px 0 0 -21px'
// }

const MomentLoader = ({
  size = 8,
  margin = 4,
  color = '#326edc',
  style = css``, // emotion css
}) => <PulseLoader size={size} margin={margin} color={color} css={style} />

MomentLoader.propTypes = {
  size: PropTypes.number,
  margin: PropTypes.number,
  color: PropTypes.string,
  style: PropTypes.object,
}

export { MomentLoader }
