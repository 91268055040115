import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    AVAILABLE: null,
    STANDBY: null,
    WAITING: null,
    SEED_NOT_ENOUGH: null,
    ERROR: null,
  }),

  Name: {
    AVAILABLE: '사용가능',
    STANDBY: '준비중',
    WAITING: '준비중',
    SEED_NOT_ENOUGH: '모수부족',
    ERROR: '사용불가',
  },

  getName(type) {
    return this.Name[type]
  },
}
