import React from 'react'
import PopupProxy from '../../../Popup/Common/PopupProxy'

const pixelAndSdkLinkRequestDialog = onOk => {
  return (
    <PopupProxy primaryButtonFunc={onOk} hasSecondaryButton={true}>
      <strong className="tit_layer">픽셀 &amp; SDK 연동</strong>
      <p className="txt_layer">
        광고계정에 해당 픽셀 &amp; SDK를 연동하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkDeleteRequestLinkedTrackDialog = onOk => {
  return (
    <PopupProxy primaryButtonFunc={onOk} hasSecondaryButton={true}>
      <strong className="tit_layer">픽셀 &amp; SDK 연동 해제</strong>
      <p className="txt_layer">
        광고계정에 연동된 픽셀 &amp; SDK를 해제하시겠습니까?
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkCancelLinkageCampaignConversion = callback => {
  return (
    <PopupProxy primaryButtonFunc={callback}>
      <strong className="tit_layer">픽셀 &amp; SDK 연동 해제됨</strong>
      <p className="txt_layer">
        해당 캠페인에 설정된 픽셀 &amp; SDK가 <br />
        연동 해제되었습니다.
        <br />
        사용 가능한 픽셀 &amp; SDK로 캠페인을 새로 등록하세요.
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkCancelLinkageCampaignVisiting = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">픽셀 &amp; SDK 연동 해제됨</strong>
      <p className="txt_layer">
        해당 캠페인에 설정된 픽셀 &amp; SDK가 연동 해제되었습니다.
        <br />
        사용 가능한 픽셀 &amp; SDK로 타게팅 설정을 변경하세요.
        <br />
        캠페인에서 사용 가능한 픽셀 &amp; SDK로 변경하세요.
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkDeleteLinkageCampaignConversion = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">픽셀 &amp; SDK 삭제됨</strong>
      <p className="txt_layer">
        해당 캠페인에 설정된 픽셀 &amp; SDK가 삭제되어
        <br />
        더이상 정상적인 광고 집행 및 전환 추적이 불가합니다.
        <br />
        사용 가능한 픽셀 &amp; SDK로 캠페인을 새로 등록하세요.
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkDeleteLinkageCampaignVisiting = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">픽셀 &amp; SDK 삭제됨</strong>
      <p className="txt_layer">
        해당 캠페인에 설정된 픽셀 &amp; SDK가 삭제되어
        <br />
        더이상 정상적인 전환 추적이 불가합니다.
        <br />
        캠페인에서 사용 가능한 픽셀 &amp; SDK로 변경하세요.
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkCancelLinkageAdGroup = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">픽셀 &amp; SDK 연동 해제됨</strong>
      <p className="txt_layer">
        해당 광고그룹에 설정된 픽셀 &amp; SDK가 <br />
        연동 해제되었습니다.
        <br />
        사용 가능한 픽셀 &amp; SDK로 타게팅 설정을 변경하세요.
      </p>
    </PopupProxy>
  )
}

const pixelAndSdkDeleteLinkageAdGroup = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">픽셀 &amp; SDK 삭제됨</strong>
      <p className="txt_layer">
        해당 광고그룹에 설정된 픽셀 &amp; SDK가 삭제되어
        <br />
        더이상 타겟 모수 수집이 불가합니다.
        <br />
        사용 가능한 픽셀 &amp; SDK로 타게팅 설정을 변경하세요.
      </p>
    </PopupProxy>
  )
}

export {
  pixelAndSdkLinkRequestDialog,
  pixelAndSdkDeleteRequestLinkedTrackDialog,
  pixelAndSdkCancelLinkageCampaignConversion,
  pixelAndSdkCancelLinkageCampaignVisiting,
  pixelAndSdkDeleteLinkageCampaignConversion,
  pixelAndSdkDeleteLinkageCampaignVisiting,
  pixelAndSdkCancelLinkageAdGroup,
  pixelAndSdkDeleteLinkageAdGroup,
}
