import { CONTEXTPATH, fetchGet, fetchPost } from '../../utils/fetchUtils'

/**
 * 7.6.6 장소 조회
 * @param adAccountId
 * @param keyword
 * @return {*}
 */
function fetchLocationSearch(adAccountId, keyword) {
  return fetchGet(
    `${CONTEXTPATH}/targeting/location?keyword=${encodeURIComponent(keyword)}`,
    adAccountId
  )
}

/**
 * 7.6.7 주소 정규화
 * @param adAccountId
 * @param addresses
 * @return {*}
 */
function validateAddresses(adAccountId, addresses) {
  return fetchPost(
    `${CONTEXTPATH}/targeting/address/validation`,
    addresses,
    adAccountId
  )
}

export default {
  fetchLocationSearch,
  validateAddresses
}
