import { range } from 'lodash'

const SHORTEN_UNITS = [
  { length: 9, name: '억' },
  { length: 5, name: '만' },
]

/**
 * @rain.x(snack) 의 number util 일부 참고함
 */
const NumberUtils = {
  /**
   * 지정한 만큼의 소수점 자리수 유지
   * - 부족할 경우 0으로 채움
   */
  fixedDecimal(number, decimal = 0) {
    const divider = 10 ** decimal
    /**
     * floor 사용할 경우 음수에서 표현 이슈 있음
     * - floor: -10.12345 -> '-10.13' (X)
     * - split: -10.12345 -> '-10.12' (O)
     */
    return (String(number * divider).split('.')[0] / divider).toFixed(decimal)
  },

  /**
   * 숫자를 3자리 마다 콤마로 구분
   */
  withCommas(number) {
    if (!number) return '0'

    const digitStrArray = String(number).split('.')
    const positive = digitStrArray[0].replace(/(\d)(?=(\d{3})+$)/g, '$1,')
    const decimal = digitStrArray.length > 1 ? `.${digitStrArray[1]}` : ''

    return `${positive}${decimal}`
  },

  /**
   * 숫자를 축약형으로 변환(all unit)
   * - 12_345_678_900 -> 123억4,567만8,900
   */
  toShorten(number, { decimal = 0, separator = '' } = {}) {
    let nextNumber = number

    const digitStrLength = this.fixedDecimal(nextNumber).length

    const filteredUnits = SHORTEN_UNITS.filter(
      unit => unit.length <= digitStrLength
    )

    const resultArray = filteredUnits
      .map(unit => {
        const divider = 10 ** (unit.length - 1)
        const numberFixedDecimal = this.fixedDecimal(nextNumber / divider, 0) // 여기선 decimal 반영 X

        nextNumber = nextNumber - numberFixedDecimal * divider

        if (numberFixedDecimal > 0) {
          const numberWithCommas = this.withCommas(numberFixedDecimal)
          const suffix = unit.name

          return `${numberWithCommas}${suffix}`
        }

        return ''
      })
      .filter(Boolean)

    if (nextNumber > 0) {
      resultArray.push(this.withCommas(this.fixedDecimal(nextNumber, decimal)))
    }

    return resultArray.join(separator)
  },

  /**
   * 숫자를 축약형으로 변환(only top unit)
   * - 12_345_678_900 -> 123억
   */
  toShortenToOne(number, { decimal = 0 } = {}) {
    const digitStrLength = this.fixedDecimal(number).length
    const unit = SHORTEN_UNITS.find(unit => unit.length <= digitStrLength)

    if (unit) {
      // unit 있을 시, unit name 까지 표현
      const numberWithCommas = this.withCommas(
        this.fixedDecimal(number / 10 ** (unit.length - 1), decimal)
      )
      const suffix = unit.name

      return `${numberWithCommas}${suffix}`
    } else {
      // unit 없을 시, 숫자로만 표현
      return this.withCommas(this.fixedDecimal(number, decimal))
    }
  },

  /**
   * 숫자의 양수 여부
   * - 소수점 허용
   */
  isPositiveSequence(number) {
    return /^[1-9]+\.?\d*$/g.test(number)
  },

  /**
   * 숫자의 음수 여부
   * - 소수점 허용
   */
  isNegativeSequence(number) {
    return /^-[1-9]+\.?\d*$/g.test(number)
  },

  /**
   * 숫자를 한글로 변환
   * - 음수의 경우 앞에 minus 부호 유지
   */
  toKor(number, { korUnit = true } = {}) {
    if (!this.isPositiveSequence(number) && !this.isNegativeSequence(number)) {
      return ''
    }

    const digitUnitNames = korUnit
      ? ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구']
      : ['', ...range(1, 10)]

    const digitNamesPlace10 = ['', '십', '백', '천']
    const digitNamesPlace10000 = ['', '만', '억', '조']

    const digitStrArray = [...number.toString()]

    // 음수일 경우 minus 부호 제거 후 연산
    if (this.isNegativeSequence(number)) {
      digitStrArray.shift()
    }

    const DIGIT_GROUP_FACTOR = 4

    let digitIntSum = 0

    /**
     * digitIntSum = 10000 단위씩 sum 하여 0 이상일 경우 digitNamesPlace10000 처리
     * 예)
     *  - 53_000 -> ['오만', '삼천', '', '', '']
     *  - 123_456_789 -> ['일억', '이천', '삼백', '사십', '오만', '육천', '칠백', '팔십', '구']
     */
    const resultArray = digitStrArray.map((digitStr, digitStrIndex, arr) => {
      const digitInt = Number(digitStr)

      digitIntSum += digitInt

      const digitPlaceIndex = arr.length - digitStrIndex - 1
      const isPlace10000 = digitPlaceIndex % DIGIT_GROUP_FACTOR === 0

      const digitName1 = digitUnitNames[digitInt]
      const digitName2 =
        digitInt > 0
          ? digitNamesPlace10[digitPlaceIndex % DIGIT_GROUP_FACTOR]
          : ''
      const digitName3 =
        isPlace10000 && digitIntSum > 0
          ? digitNamesPlace10000[digitPlaceIndex / DIGIT_GROUP_FACTOR]
          : ''

      if (isPlace10000) {
        digitIntSum = 0
      }

      return `${digitName1}${digitName2}${digitName3}`
    })

    // 음수일 경우 최종 결과 앞에 minus 부호 추가
    if (this.isNegativeSequence(number)) {
      resultArray.unshift('-')
    }

    return resultArray.join('')
  },
}

export { NumberUtils }
