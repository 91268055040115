import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
} from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'
import { CreativeHelper } from '../../utils/helper/helper-creative'
import { v4 as uuid } from 'uuid'
import { CMP_ASSET_LIBRARY_VIDEO_TYPE } from '../../components/AdvertiseV2/Creative/Cmp/CmpAssetLibrary/cmpAssetLibraryUtils'

export const CmpAPI = {
  getCreativeAssetConstraints({
    adAccountId,
    adGroupId, // nullable(for message)
    creativeFormats = [],
  }) {
    return fetchGet(
      `${CONTEXTPATH}/assets/policies/assetPropertyTypes/constraints?${queryString(
        {
          adGroupId,
          creativeFormats,
        }
      )}`,
      adAccountId
    )
  },

  getImageAssets({
    adAccountId,
    requestBody,
    pageRequest = { page: 0, size: 50 },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/search/adAccounts/${adAccountId}/assets?${queryString(pageRequest)}`,
      requestBody,
      adAccountId,
      cancelTokenSource,
      false
    ).then(response => {
      /**
       * asset 에 존재하는 id 를 API 요청 시 들어가지 않도록 제거한다.
       */
      response.data.content =
        response.data.content?.map(({ id, ...assetData }) => {
          const uniqueId = id ?? uuid()

          return {
            ...assetData,
            ...CreativeHelper.Image.toView(assetData),
            ...CreativeHelper.Image.toAPI(assetData),
            imageUUID: uniqueId,
          }
        }) || []

      return response
    })
  },

  /**
   * display video
   *  {
   *    videoAsset: {
   *      id: 1,
   *      subAssetType: 'MAIN',
   *      type: 'VIDEO',
   *      duration: 10.12,
   *      fileName: '123',
   *      fileSize: 1024,
   *      mimeType: 'video/mp4',
   *      url: 'a',
   *    },
   *    autoThumbnail: {
   *      id: 1,
   *      fileName: 'a.jpb',
   *      fileSize: 1024,
   *      width: 1200,
   *      height: 720,
   *      ratio: 1.7777,
   *      imageHash: 'abc',
   *      mimeType: 'image/jpeg',
   *      subAssetType: 'UPLOAD_THUMBNAIL',
   *      type: 'IMAGE',
   *      url: '',
   *    },
   *    uploadThumbnail: {
   *      id: 1,
   *      fileName: 'a.jpb',
   *      fileSize: 1024,
   *      width: 1200,
   *      height: 720,
   *      ratio: 1.7777,
   *      imageHash: 'abc',
   *      mimeType: 'image/jpeg',
   *      subAssetType: 'UPLOAD_THUMBNAIL',
   *      type: 'IMAGE',
   *      url: '',
   *    },
   *    lastUsedDateTime: '2022-03-30 14:24:14',
   *  }
   */
  getDisplayVideoAssets({
    adAccountId,
    requestBody,
    pageRequest = { page: 0, size: 50 },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/search/adAccounts/${adAccountId}/videoImageSets?${queryString(
        pageRequest
      )}`,
      requestBody,
      adAccountId,
      cancelTokenSource,
      false
    ).then(response => {
      /**
       * documentId 와 asset 에 존재하는 id 를 API 요청 시 들어가지 않도록 제거한다.
       */
      response.data.content =
        response.data.content?.map(({ documentId, ...assetData }) => {
          const { videoAsset, autoThumbnail, uploadThumbnail } = assetData

          const uniqueId = documentId ?? uuid()

          const convertedVideoAsset = {
            ...videoAsset,
            ...CreativeHelper.Video.toView(videoAsset),
            ...CreativeHelper.Video.toAPI(videoAsset),
            id: undefined,
          }

          const convertedAutoThumbnail = {
            ...CreativeHelper.Image.toAPI(autoThumbnail),
            id: undefined,
          }

          const convertedUploadThumbnail = uploadThumbnail
            ? { ...CreativeHelper.Image.toAPI(uploadThumbnail), id: undefined }
            : undefined

          return {
            ...assetData, // 원형 유지
            ...convertedVideoAsset,
            video: convertedVideoAsset,
            thumbnail: convertedUploadThumbnail ?? convertedAutoThumbnail,
            autoThumbnail: convertedAutoThumbnail,
            uploadThumbnail: convertedUploadThumbnail,
            videoUUID: uniqueId,
            cmpVideoType: CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY,
          }
        }) || []

      return response
    })
  },

  /**
   * message video(kakaoTV)
   *  {
   *    dataAsset: {
   *      id: 1,
   *      subAssetType: 'VIDEO_CONTENT',
   *      type: 'DATA',
   *      value: {
   *        VIDEO_URL: 'https://...',
   *      }
   *      ext: {
   *        channelId: '1',
   *        clipId: '2',
   *        clipLinkId: '3',
   *        duration: '4',
   *        isVertical: 'true',
   *        liveLinkId: '0',
   *        name: 'a',
   *        type: 'KAKAOTV',
   *        vid: 'a123',
   *        videoType: 'URL_UPLOAD',
   *      }
   *    },
   *    autoThumbnail: {
   *      id: 1,
   *      fileName: 'a.jpb',
   *      fileSize: 1024,
   *      width: 1200,
   *      height: 720,
   *      ratio: 1.7777,
   *      imageHash: 'abc',
   *      mimeType: 'image/jpeg',
   *      subAssetType: 'UPLOAD_THUMBNAIL',
   *      type: 'IMAGE',
   *      url: '',
   *    },
   *    uploadThumbnail: {
   *      id: 1,
   *      fileName: 'a.jpb',
   *      fileSize: 1024,
   *      width: 1200,
   *      height: 720,
   *      ratio: 1.7777,
   *      imageHash: 'abc',
   *      mimeType: 'image/jpeg',
   *      subAssetType: 'UPLOAD_THUMBNAIL',
   *      type: 'IMAGE',
   *      url: '',
   *    },
   *    lastUsedDateTime: '2022-03-30 14:24:14',
   *  }
   */
  getMessageVideoAssets({
    adAccountId,
    requestBody,
    pageRequest = { page: 0, size: 50 },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/search/adAccounts/${adAccountId}/videoContentImageSets?${queryString(
        pageRequest
      )}`,
      requestBody,
      adAccountId,
      cancelTokenSource,
      false
    ).then(response => {
      /**
       * documentId 와 asset 에 존재하는 id 를 API 요청 시 들어가지 않도록 제거한다.
       */
      response.data.content =
        response.data.content?.map(({ documentId, ...assetData }) => {
          const { dataAsset, autoThumbnail, uploadThumbnail } = assetData

          const uniqueId = documentId ?? uuid()

          const videoJS = {
            ...dataAsset,
            ...dataAsset.ext,
            url: dataAsset.value.VIDEO_URL,
            fileName: dataAsset.ext.name,
          }

          const convertedVideoAsset = {
            ...CreativeHelper.Video.toView(videoJS),
            ...CreativeHelper.Video.toAPI(videoJS),
            id: undefined,
          }

          const convertedAutoThumbnail = {
            ...CreativeHelper.Image.toAPI(autoThumbnail),
            id: undefined,
          }

          const convertedUploadThumbnail = uploadThumbnail
            ? { ...CreativeHelper.Image.toAPI(uploadThumbnail), id: undefined }
            : undefined

          return {
            ...assetData, // 원형 유지
            ...convertedVideoAsset,
            video: convertedVideoAsset,
            thumbnail: convertedUploadThumbnail ?? convertedAutoThumbnail,
            autoThumbnail: convertedAutoThumbnail,
            uploadThumbnail: convertedUploadThumbnail,
            videoUUID: uniqueId,
            cmpVideoType: CMP_ASSET_LIBRARY_VIDEO_TYPE.MESSAGE,
          }
        }) || []

      return response
    })
  },
  /**
   * 이미지에디터
   */

  // 아이템 다건 조회
  getEditorItems({
    adAccountId,
    requestBody,
    pageRequest = { page: 0, size: 10_000, sort: 'id,DESC' },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/cmp/searcher/adAccounts/${adAccountId}/imageEditors?${queryString({
        ...pageRequest,
      })}`,
      requestBody,
      adAccountId,
      cancelTokenSource
    ).then(response => {
      response.data.content =
        response.data.content?.map(editorItem => {
          const uniqueId = editorItem.imageEditorId ?? uuid()

          return {
            ...editorItem.resultImage,
            ...CreativeHelper.Image.toView(editorItem.resultImage),
            ...CreativeHelper.Image.toAPI(editorItem.resultImage),
            ...editorItem,
            imageUUID: uniqueId,
          }
        }) || []

      return response
    })
  },
  // 아이템 단건 조회
  getEditorItem({ adAccountId, id }) {
    return fetchGet(`/cmp/editor/imageEditors/${id}`, adAccountId).then(
      response => {
        const editorItem = response.data

        const uniqueId = editorItem.imageEditorId ?? uuid()

        response.data = {
          ...editorItem.resultImage,
          ...CreativeHelper.Image.toView(editorItem.resultImage),
          ...CreativeHelper.Image.toAPI(editorItem.resultImage),
          ...editorItem,
          imageUUID: uniqueId,
        }

        return response
      }
    )
  },
  // 아이템 생성
  createEditorItem({ adAccountId, requestBody = {} }) {
    return fetchPost(`/cmp/editor/imageEditors`, requestBody, adAccountId).then(
      response => {
        const editorItem = response.data

        const uniqueId = editorItem.imageEditorId ?? uuid()

        response.data = {
          ...editorItem.resultImage,
          ...CreativeHelper.Image.toView(editorItem.resultImage),
          ...CreativeHelper.Image.toAPI(editorItem.resultImage),
          ...editorItem,
          imageUUID: uniqueId,
        }

        return response
      }
    )
  },
  // 아이템 수정
  modifyEditorItem({ adAccountId, id, requestBody }) {
    return fetchPut(
      `/cmp/editor/imageEditors/${id}`,
      requestBody,
      adAccountId
    ).then(response => {
      const editorItem = response.data

      const uniqueId = editorItem.imageEditorId ?? uuid()

      response.data = {
        ...editorItem.resultImage,
        ...CreativeHelper.Image.toView(editorItem.resultImage),
        ...CreativeHelper.Image.toAPI(editorItem.resultImage),
        ...editorItem,
        imageUUID: uniqueId,
      }

      return response
    })
  },
  // 아이템 삭제
  deleteEditorItem({ adAccountId, id }) {
    return fetchDelete(`/cmp/editor/imageEditors/${id}`, null, adAccountId)
  },
  // 이미지 업로드(tenth)
  uploadEditorRawAssets({
    adAccountId,
    requestBody = {
      adAccountId,
      service: 'MOMENT',
      imageFiles: [],
    },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/cmp/uploader/upload/images`,
      requestBody,
      adAccountId,
      cancelTokenSource
    )
  },
  // 원본 & 로고 이미지 조회
  getEditorRawAssets({
    adAccountId,
    requestBody,
    pageRequest = { page: 0, size: 50 },
    cancelTokenSource,
  }) {
    return fetchPost(
      `/cmp/searcher/adAccounts/${adAccountId}/assets?${queryString(
        pageRequest
      )}`,
      requestBody,
      adAccountId,
      cancelTokenSource,
      false
    ).then(response => {
      /**
       * asset 에 존재하는 id 를 API 요청 시 들어가지 않도록 제거한다.
       */
      response.data.content =
        response.data.content?.map(({ id, ...assetData }) => {
          const uniqueId = id ?? uuid()

          return {
            ...assetData,
            ...CreativeHelper.Image.toView(assetData),
            ...CreativeHelper.Image.toAPI(assetData),
            imageUUID: uniqueId,
          }
        }) || []

      return response
    })
  },
}

export default {
  ...CmpAPI,
}
