import { List, Map } from 'immutable'

export const TreeUtils = {
  reduceForIdLabelMap: (prev, v) => {
    const hasChild = v.get('children')?.size > 0
    const next = hasChild
      ? v.get('children').reduce(TreeUtils.reduceForIdLabelMap, prev)
      : prev
    return next.set(v.get('id'), v.get('name'))
  },
  reduceForParentChildrenMap: (prev, v) => {
    const hasChild = v.get('children')?.size > 0
    if (!hasChild) return prev
    const next = hasChild
      ? v.get('children').reduce(TreeUtils.reduceForParentChildrenMap, prev)
      : prev
    return next.set(
      v.get('id'),
      v.get('children').map(v => v.get('id'))
    )
  },
  reduceForSelectedMap: (prev, v) => {
    const hasChild = v.get('children')?.size > 0
    const next = hasChild
      ? v.get('children').reduce(TreeUtils.reduceForSelectedMap, prev)
      : prev
    return next.set(v.get('id'), false)
  },
  containsDeep: text => node => {
    if (!node) return false
    const valueType = typeof node

    if (valueType === 'object') {
      const { name, children } = node
      const hasKeyword = name.toLowerCase().includes(text.toLowerCase())

      if (children && List.isList(children)) {
        return hasKeyword || children.some(TreeUtils.containsDeep(text))
      } else {
        return hasKeyword
      }
    }
    return false
  },
  getParentChildrenMap(treeData) {
    return treeData.reduce(this.reduceForParentChildrenMap, Map())
  },
  getIdLabelMap(treeData) {
    const parentChildrenMap = this.getParentChildrenMap(treeData)

    const buildLabelWithParentLabel = (_, id, iter) => {
      const parentId = parentChildrenMap.findKey(v => v.includes(id))
      const hasParent = parentId !== undefined

      let label = iter.get(id)
      if (hasParent) {
        label = `${buildLabelWithParentLabel(_, parentId, iter)} > ${label}`
      }
      return label
    }

    return treeData
      .reduce(this.reduceForIdLabelMap, Map())
      .map(buildLabelWithParentLabel)
  },
  getSelectedMap(treeData, selectedItems) {
    const parentChildrenMap = this.getParentChildrenMap(treeData)

    const recursionForParentChildrenIds = (prev, id) => {
      const hasChild = parentChildrenMap.get(id)?.size > 0
      const next = hasChild
        ? parentChildrenMap.get(id).reduce(recursionForParentChildrenIds, prev)
        : prev
      return next.concat(id)
    }

    const childrenIds = selectedItems.reduce(
      recursionForParentChildrenIds,
      List()
    )

    return treeData
      .reduce(this.reduceForSelectedMap, Map())
      .map((v, k) => childrenIds.includes(k))
  },
}
