import { fromJS, Map } from 'immutable'
import {
  validateCreativeAltText,
  validateCreativeFrequencyCap,
  validateCreativeLandingURL,
  validateCreativeName,
  validateCreativeOpinionProofComment,
} from './creativeFormValidatorV2'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import CreativeConstraints from '../../../utils/constraints/constraints-creative'
import CreativeFormatEnum from '../../../enums/CreativeFormatEnum'

const IMAGE_BANNER_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]: [
    'uploadedMultiImages',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: ['image'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: ['landingUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],
  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: ['frequencyCap'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const IMAGE_BANNER_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_CREATE]:
    uploadedMultiImages => {
      if (!uploadedMultiImages || uploadedMultiImages?.isEmpty()) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.PROMOTIONAL_IMAGE_MODIFY]: image => {
    const imageMap = Map.isMap(image) ? image : fromJS(image)
    if (!imageMap || imageMap?.isEmpty()) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_URL]: (
    landingUrl,
    formData,
    getState
  ) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
        },
      },
    } = getState()

    return validateCreativeLandingURL({ formData, campaignType })
  },

  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.FREQUENCY_CAP]: frequencyCap => {
    return validateCreativeFrequencyCap(frequencyCap)
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: (name, formData) => {
    const { id } = formData
    const isModify = id > 0

    return validateCreativeName(
      name,
      CreativeConstraints.getNameMaxLength({
        creativeFormat: CreativeFormatEnum.Type.IMAGE_BANNER,
        isNew: !isModify,
      })
    )
  },
})

export { IMAGE_BANNER_FORM_VALIDATION_KEY_PATH, IMAGE_BANNER_FORM_VALIDATOR }
