/***
 * editLog 관련 API (mEditLog.js)
 * 사업자
 */

import { CONTEXTPATH, fetchGet, queryString } from '../../utils/fetchUtils'

/**
 * 9.1 광고계정별 변경이력 목록 조회
 * @param adAccountId
 * @param searchOptions
 */
function fetchEditLogList(adAccountId, searchOptions) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 9.2 광고계정별 변경이력 csv 다운로드
 * @param adAccountId
 * @param searchOptions
 */
function fetchDownloadEditLogList(adAccountId, searchOptions) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs/download?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 9.3 광고계정별 변경이력 상세 팝업 타겟 이름
 * @param adAccountId
 * @param editLogId
 * @param searchTarget
 */
function getEditLogDetailName(adAccountId, editLogId, searchTarget) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs/${editLogId}?${queryString(
      { searchTarget }
    )}`,
    adAccountId
  )
}

/**
 * 9.4 광고계정별 변경이력 상세 리스트
 * @param adAccountId
 * @param editLogId
 * @param searchTarget
 */
function getEditLogDetailInfo(adAccountId, editLogId, searchTarget) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs/${editLogId}/details?${queryString(
      { searchTarget }
    )}`,
    adAccountId
  )
}

/**
 * 9.5 오디언스 관리 상세의 오디언스 별 변경이력 리스트
 * @param adAccountId
 * @param audienceId
 * @param queryPrams
 */
const fetchAudienceChangeLog = (adAccountId, audienceId, queryPrams) => {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs/audiences/${audienceId}?${queryString(
      queryPrams
    )}`,
    adAccountId
  )
}

/**
 * 9.6 오디언스 상세의 광고그룹 사용 현황(변경이력 → 오디언스 수준 변경이력 → 상세보기 시에 노출)
 * @param adAccountId
 * @param editLogId
 */
const fetchAudienceMappedAdGroups = (adAccountId, editLogId) => {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/editLogs/${editLogId}/audienceMappedAdGroups`,
    adAccountId
  )
}

export default {
  fetchEditLogList,
  fetchDownloadEditLogList,
  getEditLogDetailName,
  getEditLogDetailInfo,
  fetchAudienceChangeLog,
  fetchAudienceMappedAdGroups,
}
