import { queryString } from '../fetchUtils'

/**
 * TODO: 필요한 페이지 경우의 수를 모두 파악 한 후 URL 정책 다시 세워야함
 *
 * /ad/{adAccountId}/campaign_group?campaignId=1&adGroupId=2
 * /ad/{adAccountId}/creative?campaignId=1&adGroupId=2&creativeId=3
 */
const AdvertiseRouterV2 = {
  Path: {
    getCampaignAndGroupPageV2(adAccountId, params) {
      return `/ad/${adAccountId}/campaign_group?${queryString(params)}`
    },

    getCreativePageV2(adAccountId, params) {
      return `/ad/${adAccountId}/creative?${queryString(params)}`
    },
  },

  Matcher: {
    getCampaignAndGroupPageV2() {
      return '/ad/:adaccountid(\\d+)/campaign_group*'
    },

    getCreativePageV2() {
      return '/ad/:adaccountid(\\d+)/creative*'
    },
  },

  Regex: {
    CampaignAndGroupPageV2: /^\/ad\/\d+\/campaign_group?$/,
    CreativePageV2: /^\/ad\/\d+\/creative?$/,
  },
}

export { AdvertiseRouterV2 }
