import { checkEmpty } from '../regexUtils'

/**
 * https://gs.saro.me/#!m=elec&jn=10
 *
 * 사업자 등록번호는 123-45-67890 의 10 자리 구조.
 *    123   국세청 / 세무서별 코드
 *    45    개인 법인 구분코드
 *    6789  과세/면세/법인 사업자 등록/지정일자 일련번호
 *    0     검증번호
 *
 * input: '123-45-67891'
 *    1. 하이픈 제거 -> '1234567891' (사업자 10자리)
 *
 *    2. 인증키 배열 -> [1, 3, 7, 1, 3, 7, 1, 3, 5] (인증키 9자리)
 *
 *    3. 사업자 앞 9자리와 인증키 앞 9자리를 각각 곱하여 모두 더한다.
 *      123456789 [사업자 앞9자리]
 *      137137135 [인증키 9자리]
 *      v1 = (1 * 2) + (2 * 3) + (3 * 7)..... + (9 * 5) -> 165
 *
 *    4. 앞 9자리의 마지막 값을 다시 곱하고 10으로 나눈다.
 *      x1 = ((9 * 5) / 10) -> 4.5 소수점제거 -> 4
 *
 *    5. 합계에 바로 위 값을 더해준다.
 *      sum = v1 + x1 -> 169
 *
 *    6. 합계를 10으로 나누어 나머지를 구한다.
 *      x2 = sum % 10 -> 9
 *
 *    7. (10 - x) % 10 을 적용한다. (마지막 자릿 수를 얻는다)
 *      v3 = (10 - x2) % 10
 *
 *    8. input[9] === v3 이면 사업자등록번호.
 *
 * @param bizNumberString
 * @return {boolean}
 */
export function bizNumberValidation(bizNumberString) {
  const AUTH_KEY = [1, 3, 7, 1, 3, 7, 1, 3, 5]
  const bizNumber = String(bizNumberString).replace(/-/g, '')

  if (checkEmpty(bizNumber) || bizNumber.match(/\d/g).length !== 10)
    return false

  const v1 = Array.from(bizNumber.substr(0, 9)).reduce((sum, n, i) => {
    return sum + Number(n) * AUTH_KEY[i]
  }, 0)

  const v2 = Math.floor((Number(bizNumber.substr(8, 1)) * AUTH_KEY[8]) / 10)
  const v3 = (10 - ((v1 + v2) % 10)) % 10

  return v3 === Number(bizNumber.substr(9, 1))
}
