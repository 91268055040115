import { DemoLocationHelper } from './helper-demoGraphic'
import { Range, Repeat } from 'immutable'
import AdGroupScheduleEnum from '../../enums/AdGroupScheduleEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import PricingTypeEnum from '../../enums/PricingTypeEnum'

export const AdGroupHelper = {
  Targeting: {
    CustomTargetDataKeys: [
      'cohortTargetings', // 광고반응타겟
      'trackerTargetings', // 픽셀 & SDK
      'plusFriendTargetings', // 카카오 사용자 - 채널 친구
      'syncAppTargetings', // 카카오 사용자 - 카카오 로그인 이용자
      'customerFileTargetings', // 고객파일
      'plusFriendGroupTargetings', // 친구그룹 - 전화번호 | 앱 유저 아이디
      // my data
      'ufoInterests', // UFO 관심사
      'ufoBusinessTypes', //  UFO 업종
    ],

    DemoGraphicTargetDataKeys: ['fiveAges', 'genders', 'locations'],

    hasCustomTarget(adGroup) {
      const { targeting } = adGroup || {}
      return targeting
        ?.filter((v, k) => this.CustomTargetDataKeys.includes(k))
        ?.some(v => v.count() > 0)
    },

    hasDemoGraphicTarget(adGroup) {
      const { targeting } = adGroup || {}
      return targeting
        ?.filter((v, k) => this.DemoGraphicTargetDataKeys.includes(k))
        ?.some(v => v.count() > 0)
    },
  },

  Location: {
    /**
     * adGroup > locations 데이터를 기반으로 지역 타겟 타입 알아내기
     * (전국, 국내(전국) + 해외 | 행정구역)
     *
     * @param targeting
     * @returns {null|string|*}
     */
    getSelectedType(targeting) {
      const { locations } = targeting
      return DemoLocationHelper(locations).getCategoryV2()
    },
  },

  Schedule: {
    /**
     * adGroup > schedule 데이터를 기반으로 adType별 default schedule 생성하기
     *
     * @param schedule {Immutable.Map}
     * @param campaignType {CampaignTypeEnum.Type | string}
     * @return {Immutable.Iterable} - Schedule
     */
    create(schedule, campaignType) {
      const { lateNight } = schedule
      const defaultValue = this.Time.default()
        .map((v, i) => {
          return this.Time.renderingRange(campaignType).includes(i) &&
            this.Time.isActive(campaignType, i, lateNight)
            ? AdGroupScheduleEnum.ACTIVE
            : AdGroupScheduleEnum.INACTIVE
        })
        .toList()

      const days = AdGroupScheduleEnum.Day.values()
      return schedule.map((v, k) => (days.includes(k) ? defaultValue : v))
    },

    /**
     * 요일
     */
    Day: {
      /**
       * adGroup > schedule 데이터에서 day 항목만 추출하기
       *
       * schedule: {
       *  mondayTime: [],
       *  tuesdayTime: [],
       *  wednesdayTime: [],
       *  thursdayTime: [],
       *  fridayTime: [],
       *  saturdayTime: [],
       *  sundayTime: [],
       * }
       *
       * @param schedule {Immutable.Map}
       * @return {Immutable.Iterable} - Schedule (days only)
       */
      entries(schedule) {
        if (!schedule || schedule.size === 0) return null
        return schedule.filter((v, k) =>
          AdGroupScheduleEnum.Day.values().includes(k)
        )
      },
      /**
       * adGroup > schedule 데이터를 기반으로 설정된 day string 얻어내기
       *
       * @param schedule {Immutable.Map}
       * @return {string}
       */
      toString(schedule) {
        const seq = ['월', '화', '수', '목', '금', '토', '일']
        const entries = this.entries(schedule)
        if (entries === null) return ''
        return entries
          .filter(v => Boolean(v?.includes(AdGroupScheduleEnum.ACTIVE)))
          .map((v, k) => AdGroupScheduleEnum.Day.getName(k))
          .sort((a, b) => seq.indexOf(a) - seq.indexOf(b))
          .join(', ')
      },
    },

    /**
     * 시간
     */
    Time: {
      /**
       * schedule time array 에 담길 기본 값
       * ['0', '0',...] (size: 24)
       *
       * @return {Seq.Indexed<string>}
       */
      default() {
        return Repeat(AdGroupScheduleEnum.INACTIVE, 24)
      },
      /**
       * ex) '08:00 ~ 23:59'
       *
       * @param campaignType {CampaignTypeEnum.Type | string}
       * @param lateNight {boolean}
       * @return {string}
       */
      toString(campaignType, lateNight = false) {
        const start = this.startLabel(campaignType, lateNight)
        const end = this.endLabel(campaignType, lateNight)
        return `${start} ~ ${end}`
      },
      /**
       * ex) '08:00', '12:00'
       *
       * @param campaignType {CampaignTypeEnum.Type | string}
       * @param lateNight {boolean}
       * @return {string}
       */
      startLabel(campaignType, lateNight = false) {
        const start = lateNight ? 22 : this.renderingRange(campaignType).first()
        return String(start < 10 ? `0${start}` : start).concat(':00')
      },
      /**
       * ex) '09:59', '23:59', '06:59
       *
       * @param campaignType {CampaignTypeEnum.Type | string}
       * @param lateNight {boolean}
       * @return {string}
       */
      endLabel(campaignType, lateNight = false) {
        const end = lateNight ? 6 : this.renderingRange(campaignType).last()
        return String(end < 10 ? `0${end}` : end).concat(':59')
      },
      /**
       * ScheduleMatrix 에서 rendering 해야할 time range 얻어내기
       * - DISPLAY: 0 ~ 24
       * - other(ON TIME MESSAGE): 8 ~ 20
       *
       * [0, 1, 2... 23]
       * [8, 9, 10...19]
       *
       * @param campaignType {CampaignTypeEnum.Type | string}
       * @return {Seq.Indexed<number>}
       */
      renderingRange(campaignType) {
        return campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL
          ? Range(0, 24)
          : Range(8, 20)
      },
      /**
       * adType별 각 time 이 active | inactive 인지 알아내기
       * - DISPLAY + lateNight(심야 타겟)일 경우 static 한 time range 내에서만 active(7 ~ 22시)
       *
       * @param campaignType {CampaignTypeEnum.Type | string}
       * @param time {number}
       * @param lateNight {boolean}
       * @return {boolean}
       */
      isActive(campaignType, time, lateNight = false) {
        const range = Range(7, 22)
        if (campaignType !== CampaignTypeEnum.Type.TALK_CHANNEL && lateNight) {
          return !range.includes(time)
        }
        return true
      },
    },
  },

  PricingType: {
    getOrdered(items) {
      const sequence = PricingTypeEnum.values()
      return items.sort((a, b) => sequence.indexOf(a) - sequence.indexOf(b))
    },
  },

  BidAmount: {},

  Device: {},

  Placement: {},
}
