import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import ImageEditorUtils from './imageEditorUtils'
import cx from 'classnames'

const ImageEditorAspectSelect = ({
  validAspects,
  selectedAspects,
  aspect,
  imageEditHandler,
}) => {
  return (
    <ul className="list_imgcheck">
      {validAspects.map((v, i) => {
        const isSelected = selectedAspects.includes(v)
        const checkboxId = `inputCheckbox|${i}`

        return (
          <li
            key={`aspect|${v}`}
            className={cx({
              on: v === aspect,
            })}>
            <span
              className={cx('box_checkinp', {
                on: isSelected,
              })}
              onClick={() => imageEditHandler.onAspectChange(v)}>
              <span className="inner_checkinp">
                <input
                  type="checkbox"
                  name={checkboxId}
                  id={checkboxId}
                  className="inp_check"
                />
                <label htmlFor={checkboxId} className="lab_check">
                  <span className="img_comm ico_check" />
                  {ImageEditorUtils.getCropAspectName({ aspect: v })}
                </label>
              </span>
            </span>
          </li>
        )
      })}
    </ul>
  )
}

ImageEditorAspectSelect.propTypes = {
  validAspects: PropTypes.array,
  selectedAspects: ImmutablePropTypes.set,
  aspect: PropTypes.number,
  imageEditHandler: PropTypes.object,
}

export default ImageEditorAspectSelect
