import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    FULL: null,
    COMPACT: null,
  }),

  Name: {
    FULL: '풀뷰',
    COMPACT: '콤팩트뷰',
  },

  getName(type) {
    return this.Name[type]
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  isCompactView(type) {
    return type === this.Type.COMPACT
  },
}
