import React from 'react'
import PropTypes from 'prop-types'
import * as Sentry from '@sentry/browser'
import { showErrorMessage } from '../../../utils/alertUtils'
import { toErrorPath } from '../../../utils/router/routeUtils'

class ErrorBoundary extends React.PureComponent {
  static propTypes = {
    children: PropTypes.any,
  }

  componentDidCatch(error, errorInfo) {
    if (
      /ChunkLoadError/.test(error) ||
      /Loading chunk [\d]+ failed/.test(error)
    ) {
      window.location.reload()
    } else {
      if (!__LOCAL__) {
        Sentry.captureException(error, { extra: errorInfo })

        // crash 시 error 페이지로 이동(로컬 개발 환경에서는 예외)
        window.location.assign(toErrorPath())
      }

      showErrorMessage(JSON.stringify({ error, errorInfo }))
    }
  }

  render() {
    return this.props.children
  }
}

export default ErrorBoundary
