import { useEffect } from 'react'

/**
 * 주어진 component의 외부에 클릭이 일어남을 감지
 * @param ref
 * @param handler
 * @param options? {boolean | object}
 * ref: https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
 */
const useClickOutSide = (ref, handler, options) => {
  useEffect(() => {
    const handleClickOutside = event => {
      if (ref.current && !ref.current.contains(event.target)) {
        if (typeof handler === 'function') {
          handler()
        }
      }
    }

    document.addEventListener('click', handleClickOutside, options)

    return () => {
      document.removeEventListener('click', handleClickOutside, options)
    }
  }, [])
}

export { useClickOutSide }
