import React from 'react'
import PopupProxy from '../../Popup/Common/PopupProxy'

export const reportNotExistDimensionFilterDialog = ({
  onOk,
  dimensionName,
}) => (
  <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onOk}>
    <strong className="tit_layer">필터 삭제</strong>
    <p className="txt_layer">
      {dimensionName} 항목이 제거되어 설정된 필터도 함께 삭제됩니다.
      <br />
      진행하시겠습니까?
    </p>
  </PopupProxy>
)
