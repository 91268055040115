import {
  getStoredAdAccountInfo,
  onResponseFulfilled,
  onResponseRejected,
  setAuthorizationHeader,
} from './authenticationUtils'
import axios from 'axios'
import { getApiServerURL } from './app/services/kakaoMoment'
import { queryString as _queryString, removeElementFocus } from './utils'
import { onUploadProgress } from './externalFetchUtils'
import { isNil } from 'lodash'

export const LOCAL_HOST = 'dsp.kakao.com'
export const CONTEXTPATH = '/moment'
export const CONTRACT = '/contract-api'
export const CRUX_ACCOUNT = '/crux-account'
export const BILLING = '/billing/moment'
export const SHOWCASE = '/catalog'
export const REPORT_COMMAND = '/report-command'
export const REPORT_QUERY = '/report-query'
export const DOMINO = '/domino/custom-audience/web'

const hostName = window.location.hostname

// TODO: renaming
export const DOMAIN = `${window.location.protocol}//${hostName}${
  hostName.indexOf(LOCAL_HOST) !== -1 ? `:${window.location.port}` : ''
}`

const tokenAxios = axios.create({
  baseURL: getApiServerURL(),
  withCredentials: true,
})
tokenAxios.interceptors.request.use(
  config => {
    config.headers.serviceName = 'MOMENT'
    setAuthorizationHeader(config)
    return config
  },
  error => Promise.reject(error)
)
tokenAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)

/**
 * @deprecated
 * use `utils.queryString()` instead
 */
function queryString(params) {
  return _queryString(params)
}

function _fetch(url, method, data, adAccountId, cancelTokenSource) {
  return tokenAxios(url, {
    method: method.toLowerCase(),
    url,
    data,
    ...(adAccountId && { headers: { adAccountId } }),
    cancelToken: cancelTokenSource?.token,
  })
}

const noTokenAxios = axios.create({
  baseURL: getApiServerURL(),
  withCredentials: true,
})
noTokenAxios.interceptors.request.use(
  config => {
    config.headers.serviceName = 'MOMENT'
    return config
  },
  error => Promise.reject(error)
)
noTokenAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)

function fetchGetNoToken(url, headers = {}) {
  return noTokenAxios.get(url, {
    headers,
  })
}

function fetchGet(url, adAccountId, cancelTokenSource) {
  return _fetch(url, 'get', {}, adAccountId, cancelTokenSource)
}

function fetchPost(
  url,
  data,
  adAccountId,
  cancelTokenSource,
  preventDuplicateRequest = true
) {
  if (preventDuplicateRequest) {
    removeElementFocus()
  }

  return _fetch(url, 'post', data, adAccountId, cancelTokenSource)
}

function fetchPut(url, data, adAccountId, preventDuplicateRequest = true) {
  if (preventDuplicateRequest) {
    removeElementFocus()
  }

  return _fetch(url, 'put', data, adAccountId)
}

function fetchDelete(url, data, adAccountId, preventDuplicateRequest = true) {
  if (preventDuplicateRequest) {
    removeElementFocus()
  }

  return _fetch(url, 'delete', data, adAccountId)
}

function fetchPostForm(
  url,
  data,
  adAccountId,
  onProgress,
  cancelTokenSource,
  preventDuplicateRequest = true
) {
  if (preventDuplicateRequest) {
    removeElementFocus()
  }

  return tokenAxios(url, {
    method: 'post',
    data,
    onUploadProgress: onUploadProgress(onProgress),
    cancelToken: cancelTokenSource?.token,
    headers: {
      'Content-Type': 'multipart/form-data',
      adAccountId,
    },
  })
}

export const momentAxios = axios.create({
  baseURL: getApiServerURL(),
  withCredentials: true,
})
momentAxios.interceptors.request.use(
  config => {
    config.headers.serviceName = 'MOMENT'
    setAuthorizationHeader(config)
    const storedAdAccountId = getStoredAdAccountInfo()?.adAccountId
    if (isNil(config.headers.adAccountId) && storedAdAccountId) {
      config.headers.adAccountId = storedAdAccountId
    }
    return config
  },
  error => Promise.reject(error)
)
momentAxios.interceptors.response.use(onResponseFulfilled, onResponseRejected)

export {
  fetchGetNoToken,
  fetchGet,
  fetchPost,
  fetchPut,
  fetchDelete,
  fetchPostForm,
  queryString,
}
