import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { PreferenceHelper } from '../../utils/helper/helper-preference'
import { CommonActionType } from '../../modules-actionTypes/actionTypes'

const initialState = Map()

export default createReducer(initialState, {
  [CommonActionType.SET_PREFERENCE]: (state, { value }) => {
    return fromJS(PreferenceHelper.initialize(value))
  },
})

export function setPreference(value) {
  return {
    type: CommonActionType.SET_PREFERENCE,
    value,
  }
}
