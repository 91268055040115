import { keyMirror } from '../utils/utils'
import BidStrategyEnum from './BidStrategyEnum'

export default {
  Type: keyMirror({
    NONE: null,
    NORMAL: null,
    QUICK: null,
  }),

  Name: {
    NONE: '',
    NORMAL: '일반 게재',
    QUICK: '빠른 게재',
  },

  getName(type) {
    return this.Name[type]
  },

  getValueByBidStrategy(bidStrategy) {
    return BidStrategyEnum.isAdGroupAutoBid(bidStrategy)
      ? this.Type.NONE
      : this.Type.QUICK
  },
}
