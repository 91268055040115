import cx from 'classnames'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Set } from 'immutable'
import {
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
  CmpAssetLibraryUtils,
} from '../../cmpAssetLibraryUtils'
import { showErrorMessage } from '../../../../../../../utils/alertUtils'
import { CreativeAssetUtils } from '../../../../creativeAssetUtils'
import CreativeUploadAssetPropertyEnum from '../../../../../../../enums/CreativeUploadAssetPropertyEnum'
import useComponentId from '../../../../../../../utils/hook/useComponentId'
import { deleteCmpAssetLibraryVideoUploadItem } from '../../../../../../../modules/cmp/mCmpAssetLibraryVideo'
import ImmutablePropTypes from 'react-immutable-proptypes'
import CmpAssetLibraryVideoMessageAssetView from './CmpAssetLibraryVideoMessageAssetView'
import CmpDropArea, { UPLOAD_TYPE } from '../../../CmpInput/CmpDropArea'

const selector = ({
  cmpAssetLibrary: {
    video: {
      upload: { uploadItems },
      selectedItems,
      viewState: { isUploading },
    },
  },
}) => {
  return {
    uploadItems,
    selectedItems,
    isUploading,
  }
}

const ERROR_MESSAGE_BUSY = '다른 작업을 처리하고 있습니다.'

const CmpAssetLibraryVideoMessageUpload = ({
  creativeUploadAssetPropertyType,
  uploadSizeConstraints,
  renderGuideView = () => {},
  //
  onVideoDrop,
  onVideoDragOver,
  onVideoDragEnter,
  onVideoDragLeave,
  onVideoDragEnd,
  onVideoChange,
}) => {
  const dispatch = useDispatch()

  const [isVisibleAnim, setIsVisibleAnim] = React.useState(false)

  const {
    uploadItems: _uploadItems,
    selectedItems,
    isUploading,
  } = useSelector(selector, shallowEqual)

  // 메시지 영상만 노출
  const uploadItems = React.useMemo(
    () =>
      _uploadItems.filter(
        ({ cmpVideoType }) =>
          cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.MESSAGE
      ),
    [_uploadItems]
  )

  const videoAssets = CmpAssetLibraryUtils.Upload.filterByConstraint({
    uploadAssets: uploadItems,
    creativeUploadAssetPropertyType,
    uploadSizeConstraints,
  })

  const [loadedVideoUUIDs, setLoadedVideoUUIDs] = React.useState(
    Set(videoAssets.map(({ videoUUID }) => videoUUID))
  )

  const [isOverDropTarget, setIsOverDropTarget] = React.useState(false)
  const dropTargetCounter = React.useRef(0)

  const onDragEnter = React.useCallback(
    e => {
      onVideoDragEnter(e)
      dropTargetCounter.current++
      setIsOverDropTarget(true)
    },
    [onVideoDragEnter]
  )

  const onDragLeave = React.useCallback(
    e => {
      onVideoDragLeave(e)
      dropTargetCounter.current--
      if (dropTargetCounter.current === 0) {
        setIsOverDropTarget(false)
      }
    },
    [onVideoDragLeave]
  )

  const onDrop = React.useCallback(
    e => {
      if (isUploading) {
        e.preventDefault()

        showErrorMessage(ERROR_MESSAGE_BUSY)
      } else {
        onVideoDrop(e)
      }

      setIsOverDropTarget(false)
      dropTargetCounter.current = 0
    },
    [isUploading, onVideoDrop]
  )

  const handleDelete = React.useCallback(
    item => {
      dispatch(deleteCmpAssetLibraryVideoUploadItem({ item }))

      setLoadedVideoUUIDs(prev => prev.delete(item.get('videoUUID')))
    },
    [dispatch]
  )

  const onVideoLoaded = React.useCallback(
    videoUUID =>
      setLoadedVideoUUIDs(prev =>
        !prev.has(videoUUID) ? prev.add(videoUUID) : prev
      ),
    []
  )

  const onUpload = React.useCallback(e => {
    if (isUploading) {
      e.preventDefault()

      showErrorMessage(ERROR_MESSAGE_BUSY)
    } else {
      setIsVisibleAnim(true)

      onVideoChange(e)
    }
  }, [])

  const allowedMimeTypeString = React.useMemo(
    () => CreativeAssetUtils.Video.allowedMimeTypes.join(','),
    []
  )

  const uploadFileButtonId = useComponentId()

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function'
          ? renderGuideView()
          : renderGuideView}
        <span className="box_inpfile">
          <label
            htmlFor={uploadFileButtonId}
            className={cx('btn_gs lab_file', { in_active: isUploading })}>
            <span className="inner_g">
              <span className="img_comm ico_add" />
              파일 업로드
            </span>
          </label>
          <input
            id={uploadFileButtonId}
            type="file"
            className="inp_file"
            accept={allowedMimeTypeString}
            onChange={e => {
              if (isUploading) {
                e.preventDefault()

                showErrorMessage(ERROR_MESSAGE_BUSY)
              } else {
                setIsVisibleAnim(true)

                onVideoChange(e)
              }
            }}
          />
        </span>
      </div>
      <div
        className={cx('group_material', {
          drop_area_all: isOverDropTarget,
          on_drop: videoAssets.count() === 0 || isOverDropTarget,
        })}
        onDragOver={onVideoDragOver}
        onDragEnter={onDragEnter}
        onDragLeave={onDragLeave}
        onDragEnd={onVideoDragEnd}
        onDrop={e => {
          setIsVisibleAnim(true)

          onDrop(e)
        }}>
        <CmpDropArea
          allowedMimeTypeString={allowedMimeTypeString}
          uploadType={UPLOAD_TYPE.VIDEO}
          onUpload={onUpload}
        />
        <div className="inner_material">
          <ul className="list_material">
            {videoAssets.map((videoAsset, index) => {
              const { videoUUID } = videoAsset
              const isSelected =
                selectedItems.findIndex(
                  ({ videoUUID: selectedItemVideoUUID }) =>
                    selectedItemVideoUUID === videoUUID
                ) >= 0

              const isAlreadyLoaded = loadedVideoUUIDs.has(videoUUID)

              return (
                <CmpAssetLibraryVideoMessageAssetView
                  key={videoUUID}
                  index={index}
                  item={videoAsset}
                  isSelected={isSelected}
                  isVisibleAnim={!isAlreadyLoaded && isVisibleAnim}
                  deletable={true}
                  handleDelete={handleDelete}
                  onVideoLoaded={onVideoLoaded}
                />
              )
            })}
          </ul>
        </div>
      </div>
    </div>
  )
}

CmpAssetLibraryVideoMessageUpload.propTypes = {
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  uploadSizeConstraints: ImmutablePropTypes.list,
  onVideoDrop: PropTypes.func,
  onVideoDragOver: PropTypes.func,
  onVideoDragEnter: PropTypes.func,
  onVideoDragLeave: PropTypes.func,
  onVideoDragEnd: PropTypes.func,
  onVideoChange: PropTypes.func,
  renderGuideView: PropTypes.any,
}

export default CmpAssetLibraryVideoMessageUpload
