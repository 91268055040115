import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { fromJS } from 'immutable'
import { handleCohortException } from '../mCohort'
import axios from 'axios'
import AudienceTypeEnum from '../../../enums/AudienceTypeEnum'

/**
 * 광고반응타겟 리스트
 */

const CohortList = keyMirror(
  {
    SET_COHORTS_DATA: null,
    SET_COHORTS_DATA_COUNT: null,
    INIT: null,
    SET_PAGE: null,
  },
  'COHORT_LIST'
)

const initialState = fromJS({
  cohortsData: {
    content: [],
    totalElements: 0,
    number: 0,
  },
  cohortsManagement: {
    displayCohortCount: 0,
    messageCohortCount: 0,
  },
})

export default createReducer(initialState, {
  [CohortList.SET_COHORTS_DATA]: (state, { payload }) => {
    return state.set('cohortsData', fromJS(payload))
  },

  [CohortList.SET_COHORTS_DATA_COUNT]: (state, { payload }) => {
    return state
      .setIn(
        ['cohortsManagement', 'displayCohortCount'],
        payload.displayCohortCount
      )
      .setIn(
        ['cohortsManagement', 'messageCohortCount'],
        payload.messageCohortCount
      )
  },

  [CohortList.INIT]: state =>
    state
      .set('cohortsData', initialState.get('cohortsData'))
      .set('cohortsManagement', initialState.get('cohortsManagement')),
})

const setCohortsData = payload => ({
  type: CohortList.SET_COHORTS_DATA,
  payload,
})

export const setCohortsDataCount = payload => ({
  type: CohortList.SET_COHORTS_DATA_COUNT,
  payload,
})

export function fetchCohorts({
  adAccountId,
  audienceType,
  size = 10,
  page,
  onFinish = () => undefined,
}) {
  return async (dispatch, getState, api) => {
    try {
      const [{ data: displayCohortResponse }, { data: messageCohortResponse }] =
        await axios.all([
          api.cohort.fetchCohorts(adAccountId, {
            page,
            size,
            audienceType: AudienceTypeEnum.Type.DISPLAY,
          }),
          api.cohort.fetchCohorts(adAccountId, {
            page,
            size,
            audienceType: AudienceTypeEnum.Type.MESSAGE,
          }),
        ])

      dispatch(
        setCohortsDataCount({
          displayCohortCount: displayCohortResponse.totalElements,
          messageCohortCount: messageCohortResponse.totalElements,
        })
      )

      const data =
        audienceType === AudienceTypeEnum.Type.DISPLAY
          ? displayCohortResponse
          : messageCohortResponse

      dispatch(setCohortsData(data))
    } catch (e) {
      console.log(e)
      handleCohortException(dispatch, e)
    } finally {
      onFinish()
    }
  }
}

export const initCohorts = () => ({
  type: CohortList.INIT,
})
