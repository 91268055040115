import { createReducer } from 'redux-immutablejs'
import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'

const Popup = keyMirror({
  OPEN_POPUP: null,
  CLOSE_POPUP: null,
  CLOSE_ALL_POPUP: null,
  CLOSE_ALL_POPUP_WITHOUT_KEY: null,
})

export const POPUP_KEY = keyMirror({
  /* -- global -- */
  SIMPLE_POPUP: null,
  BASIC_POPUP: null,

  /* -- dashboard -- */
  CREATIVE_PREVIEW: null,
  CREATIVE_PREVIEW_MODIFYING_MESSAGE: null,
  CREATIVE_PREVIEW_MODIFYING_HISTORY: null,
  CREATIVE_REVIEW_DENY_REASONS: null,
  COPY_AD_GROUP: null,
  COPY_CREATIVE: null,
  AD_TARGET_SUMMARY: null,
  KAKAO_CONTENTS_TARGET_SUMMARY: null,
  ADMIN_STOP_HISTORY: null,
  AD_GROUP_BASIC_INFO_CATALOG_SUMMARY: null,
  CANCEL_CPT_CONTRACT: null,

  /* -- load target v2-- */
  LOAD_CUSTOMER_FILE_V2: null,
  LOAD_KAKAO_USERS_V2: null,
  LOAD_LOOK_ALIKE_V2: null,
  LOAD_COHORT_V2: null,
  LOAD_PIXEL_AND_SDK_V2: null,
  LOAD_FRIEND_GROUP_V2: null,
  LOAD_CATEGORY_TARGET_V2: null,
  LOAD_KAKAO_TV_TARGET: null,

  /* -- campaign -- */
  KCLID_CHECK_LIST: null,
  UTM_PARAM_CHECK_LIST: null,

  /* -- adGroup -- */
  SELECT_AD_GROUP: null,

  /* -- creative -- */
  BIZ_BOARD_BANNER: null,
  MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY: null,
  CREATIVE_PREVIEW_BANNER_DETAIL: null,

  MESSAGE_DETAIL_PREVIEW: null,

  /**
   * @deprecated
   */
  CREATIVE_LIBRARY: null,
  CREATIVE_LIBRARY_IMAGE_EDITOR: null,

  CMP_ASSET_LIBRARY_IMAGE: null,
  CMP_ASSET_LIBRARY_VIDEO: null,

  /* -- settings -- */
  WITHDRAW_MEMBER: null,

  /* -- settings - account -- */
  DELETE_AD_ACCOUNT: null,
  CANCEL_DELETE_AD_ACCOUNT: null,
  EDIT_AD_ACCOUNT_NAME: null,

  /* -- settings - paymentCard -- */
  PAYMENT_CARD_ADD: null,

  /* -- settings - editLog -- */
  EDIT_LOG_DETAIL: null,

  /* -- settings - member -- */
  INVITE_MEMBER: null,
  CHANGE_MASTER: null,

  /* -- settings - review -- */
  REVIEW_DOCUMENT_CREATE: null,

  /* -- settings - cash -- */
  CASH_CHARGE: null,
  REFUND_GUIDE: null,
  REQUEST_REFUND: null,
  REFUND_HISTORY: null,
  MODIFY_REFUND_ACCOUNT: null,
  REQUEST_PAYMENT: null,
  PAYMENT_DETAIL: null,
  VIRTUAL_ACCOUNT_HISTORY: null,
  DEFERRED_PAYMENT_CHANGE_LOG: null,
  CANCELLATION_FEE_DETAIL: null,

  /* -- targeting -- */
  CREATE_LOOK_ALIKE: null,
  CUSTOMER_FILES_UPLOAD: null,
  COHORT_DATA_GUIDE: null,
  PLUS_FRIEND_GROUP_FILE: null,
  LOAD_AUDIENCE: null,
  SAVE_AUDIENCE: null,
  LOAD_ADMINISTRATIVE_DISTRICT: null,
  TARGET_PROMISE_MESSAGE_DETAIL: null,

  /* -- pixel&sdk V2 -- */
  CREATE_PIXEL_AND_SDK_V2: null,
  PIXEL_KAKAO_SERVICE_LINKAGE: null,
  PIXEL_AND_SDK_INSTALL: null,
  ADD_MEMBER_REQUEST_RIGHTS: null,

  /* -- myInfo  -- */
  DSP_AUTO_PAYMENT_CARD_SERVICE_AGREEMENT: null,

  /* -- adView V2 -- */
  LOAD_AD_VIEW: null,
  ADVIEW_DETAIL_PREVIEW: null,

  FULL_SCREEN_AD_VIEW: null,
  FULL_SCREEN_MESSAGE: null,

  /* -- bizForm -- */
  ADD_BIZ_FORM_AUTHORITY: null,

  /* -- robotAnalysis -- */
  ROBOT_ANALYSIS: null,

  /* -- message -- */
  SEND_TEST_MESSAGE: null,
  ADD_TALK_CHANNEL_CS_INFO: null,
  INAPPROPRIATE_PHRASES: null,
  MESSAGE_COUPON: null,

  /* -- dashboard v3 */
  DASHBOARD_CREATE_AD_GROUP_CREATIVE: null,
  DASHBOARD_FILTER_STORE: null,
  DASHBOARD_TABLE_METRIC_SETTING: null,
  DASHBOARD_AD_GROUP_BID_AMOUNT_MODIFY_BULK: null,
  DASHBOARD_PROMISE_MESSAGE_DETAIL: null,

  CREATIVE_PREVIEW_EXPANDABLE_VIDEO: null,
  CREATIVE_PREVIEW_EXPANDABLE_IMAGE: null,
  CREATIVE_PREVIEW_EXPANDABLE_MULTI: null,
  CREATIVE_PREVIEW_MESSAGE_VIDEO: null,
  CREATIVE_PREVIEW_BUBBLETAB: null,

  CMP_EDITOR: null,
  CMP_EDITOR_LIBRARY_FOR_QA: null,

  /* report v2 */
  REPORT_UPDATE_NAME: null,
  REPORT_SHARE: null,
  REPORT_DIMENSION_METRIC_SETTING: null,
  REPORT_SAVE: null,
  REPORT_DOWNLOAD: null,

  /* -- personal Message -- */
  PERSONAL_MESSAGE_VARIABLES: null,

  /* Tracking Url */
  TRACKING_URL_ADD_POPUP: null,
  URL_PARAMETER_ADD_POPUP: null,

  /* Booking Calendar */
  BOOKING_CALENDAR: null,
  PRICE_TABLE: null,

  /* Election 2024 */
  PRICE_TABLE_ELECTION_2024: null,
})

const SINGLETON_POPUP_SET = new Set([POPUP_KEY.CMP_EDITOR])

const initialState = Map()

export default createReducer(initialState, {
  [Popup.OPEN_POPUP]: (state, { result }) =>
    SINGLETON_POPUP_SET.has(result.key) && state.has(result.key)
      ? state
      : state.set(result.key, result.props || {}),

  [Popup.CLOSE_POPUP]: (state, { result }) => state.delete(result.key),

  [Popup.CLOSE_ALL_POPUP]: state => state.clear(),

  [Popup.CLOSE_ALL_POPUP_WITHOUT_KEY]: (state, { keys }) =>
    state.filter((v, k) => keys.includes(k)),
})

export function openPopup(key, props = {}) {
  return {
    type: Popup.OPEN_POPUP,
    result: { key, props },
  }
}

export function openPopupByProxy(key, proxyProps) {
  return {
    type: Popup.OPEN_POPUP,
    result: { key, props: { popupProxy: proxyProps } },
  }
}

export function closePopup(key) {
  return {
    type: Popup.CLOSE_POPUP,
    result: { key },
  }
}

export function closeAllPopup() {
  return {
    type: Popup.CLOSE_ALL_POPUP,
  }
}

export function closeAllPopupWithoutKeys(...keys) {
  return {
    type: Popup.CLOSE_ALL_POPUP_WITHOUT_KEY,
    keys,
  }
}
