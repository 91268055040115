import { Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'

const Role = keyMirror({
  SET_CURRENT_ROLE: null
})

export const RoleType = keyMirror({
  MASTER: null,
  MEMBER: null,
  OBSERVER: null
})

const initialState = Map({
  currentRole: null
})

export default createReducer(initialState, {
  [Role.SET_CURRENT_ROLE]: (state, { role }) => state.set('currentRole', role)
})

/**
 * adAccountListSelector를 통하여 현재 선택된 adAccount role 정보를 업데이트한다.
 * @param role {string} - ['MASTER' or 'MEMBER' or 'OBSERVER']
 * @return {{type: null, role: *}}
 */
export const setCurrentRole = role => ({
  type: Role.SET_CURRENT_ROLE,
  role
})
