import { createReducer } from 'redux-immutablejs'
import { fromJS, Map } from 'immutable'
import { showErrorMessage } from '../../utils/alertUtils'
import { hideLoading, showLoading } from '../common/mLoading'
import { keyMirror } from '../../utils/utils'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
} from '../../utils/regexUtils'
import { handleCreativeFormExceptionV2 } from './creativeActions/aCreativeCommonV2'
import LandingUrlEnum from '../../enums/LandingUrlEnum'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import {
  changeCreativeCreateFormByKey,
  setCreativeCreateImageByKey,
} from './mCreativeCreateV2'
import { changeCreativeModifyFormByKey } from './mCreativeModifyV2'
import { coerceToArray } from '../../utils/stringUtils'
import { updateAssetGroupLandingURLDefaultSettings } from './mCatalog'
import axios from 'axios'
import { getDominantColors } from '@kakao/color-extract'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import DashboardRouter from '../../components/DashboardV3/dashboardRouter'
import TalkChannelStatusEnum from '../../enums/TalkChannelStatusEnum'
import {
  ProfileFullViewBackgroundEnum,
  ProfileFullViewTitleEnum,
} from '../../enums/CreativeProfileFullViewEnum'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'
import { CREATIVE_CREATE_PROFILE_KEY } from '../../utils/advertise/creativeImage'

const CreativeCommon = keyMirror(
  {
    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,

    // validation
    SET_IS_VALID: null,
    INIT_VALIDATION: null,
    SET_VALIDATION_EXTRA: null,

    SET_IS_VISIBLE_IMAGE_MASKING: null,
    DISABLE_DEFAULT_PROFILE_IMAGE_CHECK: null,

    // landingUrl
    CHANGE_SELECTED_LANDING_URL_TYPE: null,
    UPDATE_LANDING_URL_BY_KEY: null,
    INIT_LANDING_URL_VIEW_STATE: null,

    SET_OPINION_PROOF_FILE_OBJECT_URL: null,

    CLEAR: null,

    // 포커스 풀뷰 버블탭, 소재 생성/수정/기존소재불러오기에서 사용
    SET_BUBBLE_TAB: null,
  },
  'CREATIVE_COMMON'
)

export const CREATIVE_TRIM_KEY_PATH = [
  ['name'],
  ['rspvLandingUrl'],
  ['pcLandingUrl'],
  ['mobileLandingUrl'],
  ['altText'],
  ['profileName'],
  ['description'],
  ['title'],
  ['compatibleTitle'],
  ['messageSummary'],
  ['landingInfo', 'query'],
  ['landingInfo', 'action'],
  ['landingInfo', 'url'],
  ['opinionProof', 'opinion'],
  ['messageElement', 'title'],
  ['skanCustomProductPageId', 'skanCustomProductPageId'],
]

const initialState = fromJS({
  validationErrorKeys: null,
  validationExtra: {},

  isVisibleImageMasking: true,

  disableDefaultProfileImageCheck: false,

  creativeViewState: {
    isAddSettingOpen: false, // pc 또는 mobile 타입일때 추가설정 버튼
    isSameUrlChecked: false, // pc or 모바일 url과 동일 체크박스
    isEachChecked: false, // 반응형일때 개별 설정 체크박스
  },

  campaign: {},
  adGroup: {},
  plusFriendProfile: {},
  creativeCount: 0,
  webEmbedChannelList: [],

  opinionProofFileObjectUrls: [],

  /**
   * [{ code, name, description, isInLandingOnly }]
   */
  expandableActionButtons: [],
  bubbleTab: {
    profileName: null,
    profileImage: {},
  },
  // SKAN
  hasSkanType: false,

  // profile full view
  profileFullViewTab: {
    backgroundTab: ProfileFullViewBackgroundEnum.Type.VIDEO,
    titleTab: ProfileFullViewTitleEnum.Type.TEXT,
  },
})

export default createReducer(initialState, {
  [CreativeCommon.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn(keyPath, fromJS(value)),

  [CreativeCommon.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(keyPath, initialState.getIn(keyPath)),

  [CreativeCommon.SET_IS_VALID]: (state, { key, isValid, message }) => {
    const { validationErrorKeys } = state
    const errorKeys = !validationErrorKeys ? Map() : validationErrorKeys
    const newErrorKeys = isValid
      ? errorKeys.delete(key)
      : errorKeys.set(key, message)
    return state.set('validationErrorKeys', newErrorKeys)
  },

  [CreativeCommon.SET_VALIDATION_EXTRA]: (state, { extra }) => {
    const { validationExtra } = state
    return state.set('validationExtra', validationExtra.merge(fromJS(extra)))
  },

  [CreativeCommon.INIT_VALIDATION]: state =>
    state.withMutations(s =>
      s
        .set('validationErrorKeys', initialState.get('validationErrorKeys'))
        .set('validationExtra', initialState.get('validationExtra'))
    ),

  [CreativeCommon.SET_IS_VISIBLE_IMAGE_MASKING]: (state, { visible }) =>
    state.set('isVisibleImageMasking', visible),

  [CreativeCommon.DISABLE_DEFAULT_PROFILE_IMAGE_CHECK]: (state, { boolean }) =>
    state.set('disableDefaultProfileImageCheck', boolean),

  [CreativeCommon.UPDATE_LANDING_URL_BY_KEY]: (state, { key, value }) =>
    state.setIn(['creativeViewState', key], value),

  [CreativeCommon.INIT_LANDING_URL_VIEW_STATE]: state =>
    state.withMutations(s =>
      s
        .setIn(['creativeViewState', 'isAddSettingOpen'], false)
        .setIn(['creativeViewState', 'isSameUrlChecked'], false)
        .setIn(['creativeViewState', 'isEachChecked'], false)
    ),

  [CreativeCommon.SET_OPINION_PROOF_FILE_OBJECT_URL]: (state, { objectUrl }) =>
    state.withMutations(s =>
      s.update('opinionProofFileObjectUrls', objectUrls =>
        objectUrls.concat(objectUrl)
      )
    ),

  [CreativeCommon.CLEAR]: state => state.merge(initialState),

  [CreativeCommon.SET_BUBBLE_TAB]: (state, { profileName, profileImage }) =>
    state.set('bubbleTab', fromJS({ profileName, profileImage })),
})

export function setCreativeCommonByKeyPath(keyPath, value) {
  return {
    type: CreativeCommon.SET_BY_KEY_PATH,
    keyPath: [...coerceToArray(keyPath)],
    value,
  }
}

export function initCreativeCommonByKeyPath(keyPath) {
  return {
    type: CreativeCommon.INIT_BY_KEY_PATH,
    keyPath: [...coerceToArray(keyPath)],
  }
}

const updateCreativeLandingURLViewState = (key, value) => ({
  type: CreativeCommon.UPDATE_LANDING_URL_BY_KEY,
  key,
  value,
})

export const initCreativeLandingURLViewState = () => ({
  type: CreativeCommon.INIT_LANDING_URL_VIEW_STATE,
})

export function setIsValidCreativeByKey(key, isValid, message, extra = {}) {
  return dispatch => {
    dispatch({
      type: CreativeCommon.SET_IS_VALID,
      key,
      isValid,
      message,
    })

    if (Object.keys(extra).length > 0) {
      dispatch({
        type: CreativeCommon.SET_VALIDATION_EXTRA,
        extra,
      })
    }
  }
}

export function setIsVisibleImageMasking(visible) {
  return {
    type: CreativeCommon.SET_IS_VISIBLE_IMAGE_MASKING,
    visible,
  }
}

export function disableDefaultProfileImageCheck(boolean) {
  return {
    type: CreativeCommon.DISABLE_DEFAULT_PROFILE_IMAGE_CHECK,
    boolean,
  }
}

export function initCreativeValidation() {
  return {
    type: CreativeCommon.INIT_VALIDATION,
  }
}

export const setOpinionProofFileObjectUrl = objectUrl => {
  return {
    type: CreativeCommon.SET_OPINION_PROOF_FILE_OBJECT_URL,
    objectUrl,
  }
}

export function clearCreativeCommon() {
  return {
    type: CreativeCommon.CLEAR,
  }
}

export function setBubbleTab({ profileName, profileImage }) {
  return {
    type: CreativeCommon.SET_BUBBLE_TAB,
    profileName,
    profileImage,
  }
}

/**
 * 5.2.1 요구되는 사이즈 정보와 소스 url 을 이용한 이미지 업로드. 요구사항에 맞지 않을 경우 exception
 */
export function uploadCreativeImageByURL(
  adAccountId,
  url,
  requiredWidth,
  requiredHeight,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    const urlValue =
      String(url).startsWith('http') || String(url).startsWith('https')
        ? url
        : `http:${url}`

    try {
      const response = await api.adCreative.uploadAdCreativeImageByURL(
        adAccountId,
        urlValue
      )

      const { width, height } = response?.data || {}
      if (width === requiredWidth && height === requiredHeight) {
        if (typeof onSuccess === 'function') {
          onSuccess(dispatch, response)
        }
      } else {
        if (typeof onFail === 'function') {
          onFail(dispatch)
        }
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail(dispatch)
      }
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 5.2.2 crop 될 사이즈 정보와 소스 url 을 이용한 이미지 업로드.
 * - 원하는 사이즈보다 원본이 작을 경우 exception
 * - 적합한 이미지 포맷이 아닐 경우 exception
 */
export function uploadCreativeImageByURLWithCrop(
  adAccountId,
  url,
  cropWidth,
  cropHeight,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      const response = await api.adCreative.uploadAdCreativeImageAndCropByURL(
        adAccountId,
        url,
        cropWidth,
        cropHeight
      )

      if (typeof onSuccess === 'function') {
        onSuccess(response)
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail(e)
      }
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 요구되는 사이즈 비율과 소스 url 을 이용한 이미지 업로드. 요구사항에 맞지 않을 경우 exception
 */
export function uploadCreativeImageWithRatioByURL(
  adAccountId,
  url,
  minWidth,
  ratio,
  onSuccess = () => undefined,
  onFail = () => undefined
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    const urlValue =
      String(url).startsWith('http') || String(url).startsWith('https')
        ? url
        : `http:${url}`

    try {
      const response = await api.adCreative.uploadAdCreativeImageByURL(
        adAccountId,
        urlValue
      )

      const { width, height } = response?.data || {}
      if (width / height === ratio && width >= minWidth) {
        onSuccess(dispatch, response)
      } else {
        onFail(dispatch)
      }
    } catch (e) {
      onFail(dispatch)
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function uploadCreativeImage(
  adAccountId,
  data,
  requiredWidth,
  requiredHeight,
  onSuccess,
  onFail
) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())

    try {
      const response = await api.adCreative.uploadCreativeImage(
        adAccountId,
        data
      )
      const { width, height } = response?.data || {}
      if (width === requiredWidth && height === requiredHeight) {
        if (typeof onSuccess === 'function') {
          onSuccess(dispatch, response)
        }
      } else {
        if (typeof onFail === 'function') {
          onFail(dispatch)
        }

        showErrorMessage(
          '등록 가능한 이미지 사이즈를 확인하신 후 다시 시도하세요'
        )
      }
    } catch (e) {
      if (typeof onFail === 'function') {
        onFail(dispatch)
      }

      const { errorCode, message, detail } = e?.response?.data || {}
      dispatch(handleCreativeFormExceptionV2({ errorCode, message, detail }))
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function updateCreativeLandingURLDefaultSettings(formData) {
  return (dispatch, getState) => {
    const {
      id,
      creativeFormat,
      rspvLandingUrl,
      pcLandingUrl,
      mobileLandingUrl,
      landingUrlType,
    } = formData

    const {
      creativeV2: {
        common: {
          adGroup: { deviceTypes },
        },
      },
    } = getState()

    const isModify = id > 0

    const formUpdater = isModify
      ? changeCreativeModifyFormByKey
      : changeCreativeCreateFormByKey

    const currentDevice =
      LandingUrlEnum.getCurrentSelectedDeviceTypeV2(deviceTypes)

    // input의 타입이 PC나 Mobile 이지만 landingUrl 타입이 RSPV 로 온 경우
    const notRspvInputType =
      currentDevice !== LandingUrlEnum.InputType.RSPV_DEVICE &&
      LandingUrlEnum.Type.RSPV === landingUrlType

    // rspvLandingUrl의 값을 pcLandingUrl과 mobileLandingUrl에 조건에 따라 값을 채워준다.
    if (notRspvInputType) {
      dispatch(
        formUpdater(
          'pcLandingUrl',
          currentDevice === LandingUrlEnum.InputType.MOBILE_ONLY_DEVICE
            ? ''
            : rspvLandingUrl
        )
      )
      dispatch(
        formUpdater(
          'mobileLandingUrl',
          currentDevice === LandingUrlEnum.InputType.PC_ONLY_DEVICE
            ? ''
            : rspvLandingUrl
        )
      )
      dispatch(formUpdater('rspvLandingUrl', ''))
    }

    // 추가설정 펼침 조건
    //  - 광고그룹 설정된 디바이스가 PC 이면서 두번째 input에 값이 채워져 있고
    //  - 광고그룹 설정된 디바이스가 모바일 이면서 두번째 input에 값이 채워져 있을때
    const isAddSettingOpen =
      notRspvInputType ||
      (currentDevice === LandingUrlEnum.InputType.MOBILE_ONLY_DEVICE &&
        checkNotEmpty(pcLandingUrl)) ||
      (currentDevice === LandingUrlEnum.InputType.PC_ONLY_DEVICE &&
        checkNotEmpty(mobileLandingUrl))
    dispatch(
      updateCreativeLandingURLViewState('isAddSettingOpen', isAddSettingOpen)
    )

    // 반응형 input의 개별 설정 조건
    //  - landingUrlType이 PC, MOBILE, PC_AND_MOBILE 에 속하며 pcLandingUrl이나 mobileLandingUrl의 값이 있어야 한다.
    const isEachChecked =
      [
        LandingUrlEnum.Type.PC,
        LandingUrlEnum.Type.MOBILE,
        LandingUrlEnum.Type.PC_AND_MOBILE,
      ].includes(landingUrlType) &&
      (checkNotEmpty(pcLandingUrl) || checkNotEmpty(mobileLandingUrl))
    dispatch(updateCreativeLandingURLViewState('isEachChecked', isEachChecked))

    // pc, mobile input의 url 동일 체크 조건
    //  - pc와 mobile 각각 값이 있지만 landingUrlType이 PC_AND_MOBILE 이면 안됌. (RSPV 여야함.)
    //  - 디바이스 타입이 전체가 아니면서 landingUrlType이 반응형으로 왔을때.
    const isSameUrlChecked =
      (checkNoneEmpty(pcLandingUrl, mobileLandingUrl) &&
        pcLandingUrl === mobileLandingUrl &&
        landingUrlType !== LandingUrlEnum.Type.PC_AND_MOBILE) ||
      notRspvInputType
    dispatch(
      updateCreativeLandingURLViewState('isSameUrlChecked', isSameUrlChecked)
    )

    if (creativeFormat === CreativeFormatEnum.Type.CATALOG_MANUAL) {
      dispatch(
        updateAssetGroupLandingURLDefaultSettings({
          isAddSettingOpen,
          isEachChecked,
          isSameUrlChecked,
          notRspvInputType,
        })
      )
    }
  }
}

/**
 * 소재 랜딩 url 뷰를 컨트롤하기 위한 미들웨어.
 * 선택된 옵션의 key에 따라 컨버팅해주어야 할 랜딩 url 관련 값을 변경해주고 view state 도 업데이트 한다.
 */
export function changeCreativeLandingURLViewState(
  key,
  value,
  creativeForm,
  formUpdater
) {
  return dispatch => {
    dispatch(changeLandingURLViewState(key, value, creativeForm, formUpdater))
    dispatch(updateCreativeLandingURLViewState(key, value))
  }
}

/**
 * 카탈로그 에셋 url 뷰를 컨트롤하기 위한 미들웨어
 */
export function changeAssetLandingURLViewState(
  key,
  value,
  creativeForm,
  formUpdater
) {
  return dispatch => {
    dispatch(changeLandingURLViewState(key, value, creativeForm, formUpdater))
    dispatch(formUpdater(key, value))
  }
}

export function changeLandingURLViewState(
  key,
  value,
  creativeForm,
  formUpdater
) {
  return (dispatch, getState) => {
    const { mobileLandingUrl, pcLandingUrl, rspvLandingUrl } = creativeForm

    // 개별 설정 체크시 반응형 값을 모바일과 pc에 각각 입력, 체크 해제시에는 mobile 또는 pc 입력된 값으로 반응형에 입력.
    if (key === 'isEachChecked') {
      if (value) {
        dispatch(
          formUpdater(
            'mobileLandingUrl',
            value ? rspvLandingUrl : mobileLandingUrl
          )
        )
        dispatch(
          formUpdater('pcLandingUrl', value ? rspvLandingUrl : pcLandingUrl)
        )
      } else {
        dispatch(
          formUpdater('rspvLandingUrl', mobileLandingUrl || pcLandingUrl)
        )
        dispatch(formUpdater('mobileLandingUrl', ''))
        dispatch(formUpdater('pcLandingUrl', ''))
      }
    }

    // PC 또는 모바일 url과 동일 옵션 체크시 상위 input에 있는 값을 하위에 채워주거나 조건에 따라 비운다.
    if (key === 'isSameUrlChecked') {
      const {
        creativeV2: {
          common: {
            adGroup: { deviceTypes },
          },
        },
      } = getState()

      const currentDevice =
        LandingUrlEnum.getCurrentSelectedDeviceTypeV2(deviceTypes)

      dispatch(
        formUpdater(
          'pcLandingUrl',
          currentDevice === LandingUrlEnum.InputType.MOBILE_ONLY_DEVICE &&
            checkNotEmpty(pcLandingUrl)
            ? ''
            : pcLandingUrl
        )
      )

      dispatch(
        formUpdater(
          'mobileLandingUrl',
          currentDevice === LandingUrlEnum.InputType.PC_ONLY_DEVICE &&
            checkNotEmpty(mobileLandingUrl)
            ? ''
            : mobileLandingUrl
        )
      )
    }
  }
}

export function setCreativeMessageSpamValidation(spamValidationResults) {
  return dispatch => {
    dispatch({
      type: CreativeCommon.SET_VALIDATION_EXTRA,
      extra: { spamValidationResults },
    })
  }
}

const tenthAxios = axios.create({
  withCredentials: false,
  responseType: 'blob',
})

export const getRGBColorByImage = async ({
  url,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  try {
    const { data } = await tenthAxios.get(url)
    const image = await createImageBitmap(data)
    const { width, height } = image
    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(image, 0, 0)
    const imageData = ctx.getImageData(0, 0, width, height)

    const dominantColors = getDominantColors(imageData, {
      colorFilter: {
        minimumSaturation: 0,
        maximumSaturation: 59,
        minimumValue: 31,
        maximumValue: 94,
      },
      maximumOutputSize: 7,
      fill: true,
      adjust: true,
    })

    onSuccess(dominantColors)

    return dominantColors
  } catch (e) {
    console.log(e.message)
    onFail()
  }
}

export const checkTalkChannelStatus = (
  adAccountId,
  campaignType,
  plusFriendProfile
) => {
  return dispatch => {
    const { authority, status, dormantAt, spamLevel, csInfo } =
      plusFriendProfile

    if (
      spamLevel > 1 ||
      status === TalkChannelStatusEnum.Type.DELETING || // 삭제대기중
      status === TalkChannelStatusEnum.Type.DELETED || // 삭제
      dormantAt !== null // dormantAt값이 null이 아닌경우에는 휴면
    ) {
      showErrorMessage(
        '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
      )
      dispatch(RouterV2.replace(DashboardRouter.Path.Creative({ adAccountId })))
      throw new Error('휴면, 제재, 삭제 등의 사유로 정상 상태가 아님')
    }

    if (status === TalkChannelStatusEnum.Type.DEACTIVATED) {
      showErrorMessage(
        '선택한 카카오톡 채널의 홈 노출 설정이 꺼져 있어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 홈 노출 설정을 확인하세요.'
      )
      dispatch(RouterV2.replace(DashboardRouter.Path.Creative({ adAccountId })))
      throw new Error('홈 노출 설정이 꺼져 있음')
    }

    if (checkEmpty(csInfo)) {
      showErrorMessage(
        '선택한 카카오톡 채널의 고객센터 연락처가 등록되지 않아 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 고객센터 연락처를 확인하세요.'
      )
      dispatch(RouterV2.replace(DashboardRouter.Path.Creative({ adAccountId })))
      throw new Error('고객센터 연락처가 등록되지 않음')
    }

    if (
      !authority &&
      [
        CampaignTypeEnum.Type.TALK_CHANNEL,
        CampaignTypeEnum.Type.PERSONAL_MESSAGE,
      ].includes(campaignType)
    ) {
      showErrorMessage(
        '선택한 카카오톡 채널의 상태가 휴면, 제재, 삭제 등의 사유로 정상 상태가 아니거나, 해당 채널에 권한이 없어 요청한 작업을 실행할 수 없습니다. 카카오톡 채널 관리자센터에서 채널의 상태 및 권한을 확인하세요.'
      )
      dispatch(RouterV2.replace(DashboardRouter.Path.Creative({ adAccountId })))
      throw new Error('권한이 없음')
    }
  }
}

export const checkHavingBubbleTab = ({ adAccountId, adGroupId, isNew }) => {
  return async (dispatch, getState, api) => {
    const searchResponse =
      await api.search.getSimpleCreativesPagingByProperties({
        adAccountId,
        adGroupIds: [adGroupId],
        size: 1,
        userConfig: ['ON,OFF'],
      })

    const {
      data: { content },
    } = searchResponse || { data: {} }
    const [creative] = content || []
    const { id: creativeId } = creative || {}

    if (creativeId) {
      const creativeResponse = await api.adCreative.fetchAdCreativeInfoById(
        adAccountId,
        creativeId
      )

      const {
        data: { profileName, profileImage },
      } = creativeResponse || { data: {} }

      dispatch(
        setBubbleTab({
          profileName,
          profileImage,
        })
      )

      if (isNew) {
        dispatch(
          setCreativeCreateImageByKey(CREATIVE_CREATE_PROFILE_KEY, profileImage)
        )
        dispatch(changeCreativeCreateFormByKey('profileName', profileName))
      }
    } else {
      dispatch(
        setBubbleTab({
          profileName: null,
          profileImage: {},
        })
      )
    }
  }
}

export const initCreativeBubbleTab = () => {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        common: {
          campaign: {
            campaignTypeGoal: { campaignType },
          },
          bubbleTab: { profileName, profileImage },
        },
      },
    } = getState()
    const { url: profileImageUrl } = profileImage || {}

    if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
      if (profileName && profileImageUrl) {
        dispatch(
          setCreativeCreateImageByKey(CREATIVE_CREATE_PROFILE_KEY, profileImage)
        )
        dispatch(changeCreativeCreateFormByKey('profileName', profileName))
      }
    }
  }
}

export const clearProfileFullViewTab = () => {
  return {
    type: CreativeCommon.SET_BY_KEY_PATH,
    keyPath: ['profileFullViewTab'],
    value: initialState.get('profileFullViewTab'),
  }
}
