import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import './DynamicTooltip.css'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
  DynamicTooltipCommonPropTypes,
  DynamicTooltipStyles,
} from './dynamicTooltipUtils'
import useDynamicTooltipPosition from './hooks/useDynamicTooltipPosition'
import useDynamicTooltip from './hooks/useDynamicTooltip'

const DynamicTooltip = ({
  content,
  //
  verticalPosition = DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM,
  horizontalPosition = DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.RIGHT,
  rootElementType = 'span',
  rootElementClassName = '',
  rootElementStyles = {},
  className,
  children,
  styles = {},
  getContainerNode = ({ rootRef }) =>
    rootRef.current?.children?.[0] ?? rootRef.current,
  onClick = () => {},
  onVisible = () => false,
  transitionTimeout = 300,
}) => {
  const rootRef = React.useRef()

  const { isVisible, onMouseEnter, onMouseLeave, isOverflow } =
    useDynamicTooltip()

  const Element = rootElementType

  React.useEffect(() => {
    onVisible(isVisible)
  }, [isVisible, onVisible])

  const containerNode = getContainerNode({ rootRef })

  if (!content) {
    return children || null
  }

  return (
    <Element
      ref={rootRef}
      className={rootElementClassName}
      style={rootElementStyles}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}>
      {children}
      <CSSTransition
        in={isVisible}
        classNames="dynamic-tooltip"
        timeout={transitionTimeout}>
        <Portal>
          {isVisible && (
            <DynamicTooltipContent
              content={content}
              containerNode={containerNode}
              verticalPosition={verticalPosition}
              horizontalPosition={horizontalPosition}
              className={className}
              styles={styles}
              isOverflow={isOverflow}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
            />
          )}
        </Portal>
      </CSSTransition>
    </Element>
  )
}

DynamicTooltip.propTypes = {
  ...DynamicTooltipCommonPropTypes,
  content: PropTypes.any,
}

const DynamicTooltipContent = ({
  content,
  containerNode,
  verticalPosition,
  horizontalPosition,
  className,
  styles,
  isOverflow,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { tooltipRef } = useDynamicTooltipPosition({
    containerNode,
    verticalPosition,
    horizontalPosition,
  })

  return (
    <span
      ref={tooltipRef}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...DynamicTooltipStyles, ...styles }}
      onClick={e => {
        // 툴팁 컨텐츠의 클릭 이벤트 전파 차단.
        e.stopPropagation()
      }}>
      {typeof content === 'function' ? content({ isOverflow }) : content}
    </span>
  )
}

DynamicTooltipContent.propTypes = {
  content: PropTypes.any,
  containerNode: PropTypes.any,
  verticalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_VERTICAL)
  ),
  horizontalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_HORIZONTAL)
  ),
  className: PropTypes.string,
  styles: PropTypes.object,
  isOverflow: PropTypes.bool,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

const Portal = ({ children }) =>
  document.body ? ReactDOM.createPortal(children, document.body) : null

export default DynamicTooltip
