import { fromJS } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY } from '../../../validators/targeting/plusFriendGroupFormValidator'
import {
  handlePlusFriendGroupFileException,
  initPlusFriendGroupFileValidationErrorKeys,
  setIsValidPlusFriendGroupByKey,
} from '../mPlusFriendGroup'

const PlusFriendGroupUploadKey = keyMirror({
  INIT_UPLOADED_CUSTOMER_IDENTIFICATION_FILES: null,
  SET_UPLOADED_CUSTOMER_IDENTIFICATION_FILE: null,
  REMOVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE: null,
  RECEIVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE_RESULT_DATA_FOR_TARGETING: null,
})

const initialState = fromJS({
  uploadedCustomerIdentificationFiles: [],
  uploadedCustomerIdentificationFilesResultData: {},
})

export default createReducer(initialState, {
  // customer identification files
  [PlusFriendGroupUploadKey.INIT_UPLOADED_CUSTOMER_IDENTIFICATION_FILES]:
    state => {
      return state
        .set(
          'uploadedCustomerIdentificationFiles',
          initialState.get('uploadedCustomerIdentificationFiles')
        )
        .set(
          'uploadedCustomerIdentificationFilesResultData',
          initialState.get('uploadedCustomerIdentificationFilesResultData')
        )
    },

  [PlusFriendGroupUploadKey.SET_UPLOADED_CUSTOMER_IDENTIFICATION_FILE]: (
    state,
    { data }
  ) => {
    return state.update('uploadedCustomerIdentificationFiles', v =>
      v.push(fromJS(data))
    )
  },

  [PlusFriendGroupUploadKey.REMOVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE]: (
    state,
    { data }
  ) => {
    return state.update('uploadedCustomerIdentificationFiles', v =>
      v.filter(file => file !== data)
    )
  },

  [PlusFriendGroupUploadKey.RECEIVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE_RESULT_DATA_FOR_TARGETING]:
    (state, { data }) => {
      return state.set(
        'uploadedCustomerIdentificationFilesResultData',
        fromJS(data)
      )
    },
})

export function initUploadedCustomerIdentificationFiles() {
  return {
    type: PlusFriendGroupUploadKey.INIT_UPLOADED_CUSTOMER_IDENTIFICATION_FILES,
  }
}

function getUploadedCustomerIdentificationFile(data) {
  return {
    type: PlusFriendGroupUploadKey.SET_UPLOADED_CUSTOMER_IDENTIFICATION_FILE,
    data,
  }
}

/**
 * APP USER ID / PHONE NUMBER 고객 식별자 파일 업로드
 */
export function uploadPlusFriendCustomerIdentificationFile(
  adAccountId,
  data,
  originalFileName,
  onFinish
) {
  const path = `/tid/${adAccountId}`

  return (dispatch, getState, api) => {
    return api.plusFriendGroup
      .uploadPlusFriendGroupFile(adAccountId, path, data, originalFileName)
      .then(response => {
        dispatch(getUploadedCustomerIdentificationFile(response.data || []))
        dispatch(initPlusFriendGroupFileValidationErrorKeys())
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e)
      })
      .finally(() => {
        onFinish && onFinish()
      })
  }
}

export function removeUploadedCustomerIdentificationFile(data) {
  return {
    type: PlusFriendGroupUploadKey.REMOVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE,
    data,
  }
}

function receiveUploadedCustomerIdentificationFileResultDataForTargeting(data) {
  return {
    type: PlusFriendGroupUploadKey.RECEIVE_UPLOADED_CUSTOMER_IDENTIFICATION_FILE_RESULT_DATA_FOR_TARGETING,
    data,
  }
}

export function analysisCustomerIdentificationFilesForTargeting(
  adAccountId,
  data,
  onSuccess,
  onFinish
) {
  return (dispatch, getState, api) => {
    return api.plusFriendGroup
      .submitPlusFriendGroupFilesForTargeting(adAccountId, data)
      .then(response => {
        dispatch(
          receiveUploadedCustomerIdentificationFileResultDataForTargeting(
            {
              ...{ fileType: data.fileType },
              ...response.data,
            } || {}
          )
        )
        dispatch(
          setIsValidPlusFriendGroupByKey(
            PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY.PLUS_FRIEND_GROUP_FILE,
            true
          )
        )
        onSuccess && onSuccess()
      })
      .catch(e => {
        if (e.response && e.response.data) {
          const { message } = e.response.data
          dispatch(
            setIsValidPlusFriendGroupByKey(
              PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY.PLUS_FRIEND_GROUP_FILE,
              false,
              message
            )
          )
        }
      })
      .finally(() => {
        onFinish && onFinish()
      })
  }
}
