import React from 'react'

const AdSideBarHandle = () => {
  return (
    <button className="reform_btn_aside" onClick={AdSideBarHandle.toggle}>
      <span className="ico_comm">우측 설명영역 닫기</span>
    </button>
  )
}

AdSideBarHandle.open = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.remove('reform_guide_hide')
  }
}

AdSideBarHandle.close = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    kakaoWrapNode.classList.add('reform_guide_hide')
  }
}

AdSideBarHandle.toggle = () => {
  const kakaoWrapNode = document.getElementById('kakaoWrap')
  if (kakaoWrapNode) {
    if (kakaoWrapNode.classList.contains('reform_guide_hide')) {
      kakaoWrapNode.classList.remove('reform_guide_hide')
    } else {
      kakaoWrapNode.classList.add('reform_guide_hide')
    }
  }
}

export default AdSideBarHandle
