import React from 'react'

const useLnbState = () => {
  const [isLnbExpanded, setIsLnbExpanded] = React.useState(false)

  /**
   * 광고 만들기, 오디언스 만들기에서 LNB 는 별도의 상태와 핸들러를 갖는다.
   * - 기본: 닫힘
   */
  const lnbState = React.useMemo(
    () => ({
      isExpanded: isLnbExpanded,
      toggleExpander: () => setIsLnbExpanded(prev => !prev),
    }),
    [isLnbExpanded]
  )

  return {
    lnbState,
  }
}

export default useLnbState
