import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkEmpty } from '../../utils/regexUtils'

const defaultValidationResult = { isValid: true, message: '' }

export const COHORT_FORM_VALIDATION_KEY = keyMirror({
  COHORT_NAME: null
})

export const COHORT_FORM_VALIDATION_KEY_PATH = Map({
  [COHORT_FORM_VALIDATION_KEY.COHORT_NAME]: ['name']
})

export const COHORT_FORM_VALIDATOR = Map({
  [COHORT_FORM_VALIDATION_KEY.COHORT_NAME]: name => {
    if (checkEmpty(name))
      return { isValid: false, message: '광고반응타겟 이름을 입력하세요.' }

    return defaultValidationResult
  }
})
