import * as Sentry from '@sentry/browser'
import { isPlainObject } from 'lodash'

const validateActionMiddleware = store => next => action => {
  if (isPlainObject(action)) {
    return next(action)
  } else {
    Sentry.captureException('Actions must be plain objects.', {
      extra: {
        action,
        state: store.getState(),
      },
    })

    // ignore action
    return undefined
  }
}

export default validateActionMiddleware
