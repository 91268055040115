import React, { Fragment, useEffect } from 'react'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { LNB_MENU, LnbMenuUtils } from './LnbMenuUtils'
import { goToCreateCampaign } from '../../../modules-batch/dashboardV3/batch-dashboard-campaign'
import PropTypes from 'prop-types'
import { checkEmpty } from '../../../utils/regexUtils'
import Lnb from './Lnb'
import DashboardRouter from '../../DashboardV3/dashboardRouter'
import { clearContract } from '../../../modules/advertise/mContract'

const _LnbMenuItem = ({
  id,
  name,
  getPathFn,
  getActivePathFn,
  className,
  onExpand,
  isMenuVisible,
}) => {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const adAccountId = useParams().adaccountid

  const childArray = React.useMemo(
    () =>
      Object.entries(LnbMenuUtils.Model)
        .filter(([, { parent }]) => parent === id)
        .filter(([menu]) => isMenuVisible({ menu })),
    [id, isMenuVisible]
  )

  const hasChild = childArray?.length > 0
  const path = getPathFn?.({ adAccountId })
  const activePath = getActivePathFn?.({ adAccountId }) ?? path
  const active = React.useMemo(
    () =>
      hasChild
        ? childArray.some(child => {
            const [, childObject] = child
            const childPath = childObject.getPathFn?.({ adAccountId })
            const childActivePath =
              childObject.getActivePathFn?.({
                adAccountId,
              }) ?? childPath

            return childActivePath
              ? new RegExp(childActivePath).test(location.pathname)
              : false
          })
        : activePath
        ? new RegExp(activePath).test(location.pathname)
        : false,
    [adAccountId, childArray, hasChild, location.pathname, activePath]
  )

  const [isExpanded, setIsExpanded] = React.useState(hasChild && active)

  useEffect(() => {
    if (hasChild && active) {
      setIsExpanded(true)
    }
  }, [hasChild, active])

  const onClick = React.useCallback(() => {
    if (hasChild) {
      setIsExpanded(prev => !prev)

      onExpand()
    } else {
      if (id === LNB_MENU.DASHBOARD) {
        history.push(DashboardRouter.Path.Prev(adAccountId))
      } else if (id === LNB_MENU.CREATE_ADS) {
        dispatch(clearContract())
        dispatch(goToCreateCampaign({ adAccountId }))
      } else {
        history.push(path)
      }

      Lnb.collapse()
    }
  }, [hasChild, onExpand, id, dispatch, adAccountId, history, path])

  return (
    <li key={id} className={cx({ on: active, open: isExpanded })}>
      <a className="link_snb" onClick={onClick}>
        <span className={`ico_gnb ${className}`} />
        {name}
        {hasChild && <span className="ico_gnb ico_arrow" />}
      </a>
      {isExpanded && <LnbSubMenuItem menuArray={childArray} />}
    </li>
  )
}

_LnbMenuItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  getPathFn: PropTypes.func,
  getActivePathFn: PropTypes.func,
  className: PropTypes.string.isRequired,
  onExpand: PropTypes.func.isRequired,
  isMenuVisible: PropTypes.func.isRequired,
}

const _LnbSubMenuItem = ({ menuArray }) => {
  const adAccountId = useParams().adaccountid
  const location = useLocation()
  const history = useHistory()

  return (
    <ul className="sub_snb">
      {menuArray.map(([id, { name, getPathFn }]) => {
        const path = getPathFn?.({ adAccountId })
        const active = path ? new RegExp(path).test(location.pathname) : false

        const onClick = () => {
          if (path) {
            history.push(path)

            Lnb.collapse()
          }
        }

        return (
          <Fragment key={id}>
            <li className={cx({ on: active })}>
              <a className="link_sub" onClick={onClick}>
                {name}
              </a>
            </li>
          </Fragment>
        )
      })}
    </ul>
  )
}

_LnbSubMenuItem.propTypes = {
  menuArray: PropTypes.array.isRequired,
}

const _LnbMenu = React.forwardRef(
  ({ onExpand, isParentMenuVisible, isMenuVisible }, menuListNodeRef) => {
    return (
      <nav className="cont_snb">
        <ul ref={menuListNodeRef} className="list_snb">
          {Object.entries(LnbMenuUtils.Model)
            .filter(([, { parent }]) => checkEmpty(parent))
            .filter(([menu]) => isParentMenuVisible({ menu }))
            .map(([id, props]) => {
              return (
                <LnbMenuItem
                  key={id}
                  id={id}
                  {...props}
                  onExpand={onExpand}
                  isMenuVisible={isMenuVisible}
                />
              )
            })}
        </ul>
      </nav>
    )
  }
)

_LnbMenu.displayName = '_LnbMenu'
_LnbMenu.propTypes = {
  onExpand: PropTypes.func.isRequired,
  isParentMenuVisible: PropTypes.func.isRequired,
  isMenuVisible: PropTypes.func.isRequired,
}

const LnbMenuItem = React.memo(_LnbMenuItem)
const LnbSubMenuItem = React.memo(_LnbSubMenuItem)
const LnbMenu = React.memo(_LnbMenu)

export default LnbMenu
