import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import '../Popup/PopupContainer/PopupContainer.css'
import isPassiveSupported from '../../utils/isPassiveSupported'
import {
  closeAllPopupV2,
  closePopupWithoutKeyV2,
  hidePopupV2,
  POPUP_ANIMATION_DURATION,
} from '../../modules/common/mPopupV2'
import { useLocation } from 'react-router-dom'
import { usePrevious } from '../../utils/hook/usePrevious'

const PopupContainerV2 = React.forwardRef((props, forwardedRef) => {
  const dispatch = useDispatch()

  const popups = useSelector(({ popupV2 }) => popupV2, shallowEqual)
  const location = useLocation()
  const isEmpty = popups.isEmpty()
  const prevIsEmpty = usePrevious(isEmpty)
  const prevLocation = usePrevious(location)

  React.useEffect(() => {
    if (prevIsEmpty !== isEmpty) {
      setTimeout(() => {
        if (isEmpty) {
          document.body.classList.remove('layer_on')
        } else {
          document.body.classList.add('layer_on')
        }
      }, 0)
    }
  }, [isEmpty, prevIsEmpty])

  React.useEffect(() => {
    if (prevLocation !== location) {
      if (!isEmpty) {
        dispatch(closeAllPopupV2())
      }
    }
  }, [dispatch, isEmpty, location, prevLocation])

  const handleKeyDown = React.useCallback(
    e => {
      switch (e.keyCode) {
        // escape
        case 27: {
          if (!isPassiveSupported) {
            e.preventDefault() // for prevent iframe bug in IE
          }
          if (!isEmpty) {
            dispatch(hidePopupV2())
            setTimeout(() => {
              dispatch(closePopupWithoutKeyV2())
            }, POPUP_ANIMATION_DURATION)
          }
          break
        }

        default:
          break
      }
    },
    [dispatch, isEmpty]
  )

  return (
    <KeyDownEventWrapper callback={handleKeyDown}>
      <div id={'#PopupContainerV2'} ref={forwardedRef} />
    </KeyDownEventWrapper>
  )
})

const KeyDownEventWrapper = ({ children, callback }) => {
  React.useEffect(() => {
    const onKeyDown = e => {
      if (typeof callback === 'function') {
        callback(e)
      }
    }

    document.addEventListener('keydown', onKeyDown)

    return () => {
      document.removeEventListener('keydown', onKeyDown)
    }
  }, [callback])

  return children
}

PopupContainerV2.displayName = 'PopupContainerV2'

export default PopupContainerV2
