import { keyMirror } from '../utils/utils'
import { floor } from 'lodash'

/**
 * CreativeFormat.Type.IMAGE_NATIVE 하위 상세 format 개념.
 */
export default {
  Type: keyMirror({
    IMAGE_FEED: null,
    THUMBNAIL_FEED: null,
    NATIVE_INTERSTITIAL: null,
    RATIO_1_TO_1: null,
    RATIO_2_TO_1: null,
    RATIO_9_TO_16: null,
    RATIO_16_TO_9: null,
  }),

  Name: {
    IMAGE_FEED: '이미지피드',
    THUMBNAIL_FEED: '썸네일피드',
    NATIVE_INTERSTITIAL: '전면형',
    RATIO_1_TO_1: '1:1 비율 소재',
    RATIO_2_TO_1: '2:1 비율 소재',
    RATIO_9_TO_16: '9:16 비율 소재',
    RATIO_16_TO_9: '16:9 비율 소재',
  },

  values() {
    return Object.freeze(Object.values(this.Type))
  },

  getByImageSize(width, height) {
    const ratio = floor(width / height, 4)
    if (ratio === 1) return this.Type.RATIO_1_TO_1
    if (ratio === 2) return this.Type.RATIO_2_TO_1
    if (ratio === 0.5625) return this.Type.RATIO_9_TO_16
    if (ratio === 1.7777) return this.Type.RATIO_16_TO_9
    if (width === 1200 && height === 628) return this.Type.IMAGE_FEED
    if (width === 640 && height === 480) return this.Type.THUMBNAIL_FEED
    if (width === 720 && height === 1000) return this.Type.NATIVE_INTERSTITIAL
    return undefined
  },
}
