import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const PopupHOC = (PopupComponent, popupProps = {}) =>
  class HOC extends React.Component {
    popup = null

    zIndexMax = 998

    render() {
      const {
        className = 'basic_layer',
        subClassName,
        getStyle = ({ popupIndex }) => {},
        getLayerStyle = () => {},
      } = popupProps

      const baseStyle =
        this.props.index > 0
          ? {
              zIndex: this.zIndexMax - this.props.index,
            }
          : {}

      return (
        <div
          className="layer_wrap"
          style={{
            ...baseStyle,
            ...getStyle({ popupIndex: this.props.index }),
          }}>
          <div
            ref={n => (this.popup = n)}
            className={cx(className, subClassName, this.props.subClassName)}
            style={{
              ...getLayerStyle(),
            }}>
            <PopupComponent
              recalculateMarginTop={this.recalculateMarginTop}
              {...this.props}
            />
          </div>
        </div>
      )
    }

    componentDidMount() {
      this.recalculateMarginTop()
    }

    static propTypes = {
      index: PropTypes.any,
      className: PropTypes.string,
      subClassName: PropTypes.string,
    }

    recalculateMarginTop = () => {
      const { className = 'basic_layer' } = popupProps

      if (className === 'basic_layer2') {
        if (this.popup && this.popup.clientHeight) {
          const calculateMarginTop = -(this.popup.clientHeight / 2)
          const calculateMarginLeft = -(this.popup.clientWidth / 2)
          this.popup.style.marginTop = `${calculateMarginTop}px`
          this.popup.style.marginLeft = `${calculateMarginLeft}px`
        }
      }
    }
  }

export default PopupHOC
