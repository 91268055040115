import { keyMirror } from '../utils'
import { isPositiveIntegerSequence } from '../regexUtils'

const PAYMENT_CARD_STATUS = keyMirror({
  SUCCESS: null,
  FAIL: null,
})

const PAYMENT_CARD_STATUS_NAME = {
  SUCCESS: '결제 성공',
  FAIL: '결제 실패',
}

const PAYMENT_CARD_AUTO_PAY_STATUS = keyMirror({
  OK: null,
  HOLD: null,
})

const PAYMENT_CARD_COUNT_MAX = 3
const PAYMENT_CARD_PRIORITY_DESCRIPTION = ['결제카드', '예비카드1', '예비카드2']
const PAYMENT_CARD_DAILY_SPENDING_BUDGET_UNIT = 1_000
const PAYMENT_CARD_DAILY_SPENDING_BUDGET_MIN = 1_000
const PAYMENT_CARD_DAILY_SPENDING_BUDGET_MAX = 100_000_000

const PaymentCardUtils = {
  maskingCardNumber({ numberString = '' }) {
    const pattern = '**** **** **** ****'
    const numberArray = String(numberString).split('')
    return pattern
      .split('')
      .map(patternChar =>
        patternChar === '*' ? numberArray.shift() ?? '*' : patternChar
      )
      .join('')
  },
  cardDescriptionByPriority({ priorityOrder }) {
    return PAYMENT_CARD_PRIORITY_DESCRIPTION[priorityOrder] || ''
  },
  validateDailySpendingBudget({ dailySpendingBudget }) {
    return (
      isPositiveIntegerSequence(dailySpendingBudget) &&
      dailySpendingBudget >= PAYMENT_CARD_DAILY_SPENDING_BUDGET_MIN &&
      dailySpendingBudget <= PAYMENT_CARD_DAILY_SPENDING_BUDGET_MAX &&
      dailySpendingBudget % PAYMENT_CARD_DAILY_SPENDING_BUDGET_UNIT === 0
    )
  },
}

export {
  PaymentCardUtils,
  PAYMENT_CARD_STATUS,
  PAYMENT_CARD_STATUS_NAME,
  PAYMENT_CARD_AUTO_PAY_STATUS,
  PAYMENT_CARD_COUNT_MAX,
  PAYMENT_CARD_PRIORITY_DESCRIPTION,
  PAYMENT_CARD_DAILY_SPENDING_BUDGET_UNIT,
  PAYMENT_CARD_DAILY_SPENDING_BUDGET_MIN,
  PAYMENT_CARD_DAILY_SPENDING_BUDGET_MAX,
}
