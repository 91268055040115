import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    DONE: null, // 기본 상태
    UPLOADING: null,
    TRANSCODING: null,
    COMPLETED: null,
    FAILED: null,
  })
}
