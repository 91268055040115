import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { checkNotEmpty, validateInputLength } from '../../utils/regexUtils'

const defaultValidationResult = { isValid: true, message: '' }

export const PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY = keyMirror({
  PLUS_FRIEND_GROUP_FILE: null,
  PLUS_FRIEND_GROUP_NAME: null,
  PLUS_FRIEND_GROUP_MESSAGE_RETARGET_LIMIT: null,
})

export const PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY_PATH = Map({
  [PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY.PLUS_FRIEND_GROUP_NAME]: ['name'],
})

export const PLUS_FRIEND_GROUP_FORM_VALIDATOR = Map({
  [PLUS_FRIEND_GROUP_FORM_VALIDATION_KEY.PLUS_FRIEND_GROUP_NAME]: name => {
    if (!checkNotEmpty(name))
      return { isValid: false, message: '친구그룹 이름을 입력하세요.' }
    if (!validateInputLength(name, 1, 50))
      return {
        isValid: false,
        message: '친구그룹 이름은 1~50자까지 입력가능합니다.',
      }
    return defaultValidationResult
  },
})
