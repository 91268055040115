import React from 'react'
import { CMP_INPUT_MAX_LENGTH_CHANGE_MODE } from './cmpInputUtils'
import { usePrevious } from '../../../../../utils/hook/usePrevious'

const INITIAL_CMP_ASSET_LAYER_STATE = {
  focusOpen: true,
  isOpened: false,
}

const useCmpInputAssetLayer = ({
  inputRef,
  inputId,
  inputValue,
  maxLength,
  maxLengthChangeMode,
}) => {
  const [cmpAssetLayerState, setCmpAssetLayerState] = React.useState(
    INITIAL_CMP_ASSET_LAYER_STATE
  )

  const prevInputId = usePrevious(inputId)
  const prevMaxLength = usePrevious(maxLength)

  const dispatchInputValueChangeEvent = React.useCallback(
    ({ nextValue }) => {
      const inputEl = document.getElementById(inputId)
      const prevValue = inputEl.value

      inputEl.value = String(nextValue).slice(0, maxLength)

      // set native value -> trigger input `change` event
      const event = new Event('input', { bubbles: true })
      const tracker = inputEl._valueTracker

      if (tracker) {
        tracker.setValue(prevValue)
      }

      inputEl.dispatchEvent(event)
    },
    [inputId, maxLength]
  )

  // maxLength 변경 시 value update
  React.useEffect(() => {
    if (
      prevMaxLength !== maxLength &&
      maxLengthChangeMode !== CMP_INPUT_MAX_LENGTH_CHANGE_MODE.NONE
    ) {
      dispatchInputValueChangeEvent({
        nextValue:
          maxLengthChangeMode === CMP_INPUT_MAX_LENGTH_CHANGE_MODE.CLEAR
            ? ''
            : String(inputValue).slice(0, maxLength),
      })
    }
  }, [
    dispatchInputValueChangeEvent,
    inputId,
    maxLength,
    maxLengthChangeMode,
    prevMaxLength,
    inputValue,
  ])

  // 레이어 오픈 시 포커스
  React.useLayoutEffect(() => {
    if (cmpAssetLayerState.isOpened) {
      setTimeout(() => {
        inputRef.current?.focus()
      })
    }
  }, [cmpAssetLayerState.isOpened, inputRef])

  // id 변경 시 상태 초기화
  React.useEffect(() => {
    if (!!prevInputId && prevInputId !== inputId) {
      setCmpAssetLayerState(INITIAL_CMP_ASSET_LAYER_STATE)
    }
  }, [inputId, prevInputId])

  const onClickAway = React.useCallback(e => {
    setCmpAssetLayerState(prevState => ({
      ...prevState,
      isOpened: false,
    }))
  }, [])

  const cmpAssetLayerHandlers = React.useMemo(
    () => ({
      onCloseForced() {
        setCmpAssetLayerState(prevState => ({
          ...prevState,
          isOpened: false,
        }))
      },
      onSelect({ cmpAssetValue }) {
        dispatchInputValueChangeEvent({
          nextValue: String(cmpAssetValue).trim(),
        })

        setCmpAssetLayerState(prevState => ({
          ...prevState,
          isOpened: false,
        }))
      },
    }),
    [dispatchInputValueChangeEvent]
  )

  return {
    cmpAssetLayerHandlers,
    cmpAssetLayerState,
    setCmpAssetLayerState,
    onClickAway,
  }
}

export default useCmpInputAssetLayer
