import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { fetchMyDspAccount } from '../../../modules/common/mUser'
import Footer from '../../Footer/Footer'
import Gnb from '../../Navigation/Gnb/Gnb'
import MyInfoTitle from './MyInfoTitle'
import MyInfoTabMenu from './MyInfoTabMenu'

const DspAccountInfo = React.lazy(() =>
  import('./DspAccountInfo/DspAccountInfo')
)
const DspAutoPaymentCard = React.lazy(() =>
  import('./DspAutoPaymentCard/DspAutoPaymentCard')
)
const Notification = React.lazy(() => import('./Notification/Notification'))

const MyInfoWrapper = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    dispatch(fetchMyDspAccount())
  }, [dispatch])

  return (
    <div id="kakaoWrap" className="layout_fix">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <article id="mArticle">
          <h2 id="#kakaoBody" className="screen_out">
            내 정보 본문
          </h2>
          <div className="myinfo_wrap">
            <MyInfoTitle />
            <strong className="screen_out">내 정보 메뉴 탭</strong>
            <MyInfoTabMenu />
            <React.Suspense fallback="">
              <Switch>
                <Redirect exact from="/myinfo" to="/myinfo/accountinfo" />
                <Route path="/myinfo/accountinfo" component={DspAccountInfo} />
                <Route
                  path="/myinfo/autopaycard"
                  component={DspAutoPaymentCard}
                />
                <Route path="/myinfo/notification" component={Notification} />
              </Switch>
            </React.Suspense>
          </div>
        </article>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

export default MyInfoWrapper
