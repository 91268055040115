const PREFERENCE_ITEM = {
  DASHBOARD_CUSTOM_METRIC: 'dashboardCustomMetric',
  DASHBOARD_FILTER: 'dashboardFilter',
}

const PreferenceHelper = {
  /**
   * 정의된 item key 가 아닌 값은 filtering 한다.
   * @param preferenceData {string} JSON string (API response)
   * @param PreferenceKeys {string[]}
   */
  initialize(preferenceData, PreferenceKeys = Object.values(PREFERENCE_ITEM)) {
    let obj = {}

    if (preferenceData) {
      try {
        obj = JSON.parse(preferenceData)
      } catch (e) {
        console.log(e.message)
      }
    }

    const json = typeof obj === 'object' && !Array.isArray(obj) ? obj : {}

    const result = {}

    Object.keys(json).forEach(key => {
      if (PreferenceKeys.includes(key)) result[key] = json[key]
    })

    return result
  },
}

export { PREFERENCE_ITEM, PreferenceHelper }
