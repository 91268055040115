// creative.landingInfo
import { fromJS } from 'immutable'
import ActionButtonEnum from '../enums/ActionButtonEnum'

/**
 * creative.landingInfo
 */
const BizBoardLandingInfo = fromJS({
  landingType: '', // SHARP_TAB | AD_VIEW | PF_BOT | URL
  query: '', // 샾탭
  bid: null, // 샾탭
  profileId: -1, // 챗봇 | 메시지
  action: '', // 챗봇(키워드)
  hasBot: false, // 챗봇(실제 CORE 도메인에 존재하는 데이터는 아니다. 카카오톡 채널 선택, 조회 후 View 에서의 식별자 역할)
  url: '', // URL 랜딩
  clientMessageId: -1, // 메시지
  bizFormId: -1, // 비즈니스폼
  talkCalendarEventId: '', // 톡캘린더
  channelPostId: -1, // 채널 소식

  // 카카오페이 구매
  shopKey: '',
  productId: null,

  // -- 애드뷰 (nullable) --
  // adViewItem: Map({
  //   id: -1,
  //   type: '', // COMPACT | FULL
  //   templateType: '', // IMAGE | VIDEO
  //   name: '',
  //   createdDate: null,
  //   componentData: ''
  // })

  // -- 참여형 이벤트 (nullable) --
  // participativeEvent: {
  //   id: -1,
  //   type: '', // BOT | PLUGIN
  //   name: '',
  //   profileName: '',
  //   beginDate: '',
  //   endDate: ''
  // }
})

/**
 * creative.assetGroups[]
 */
const CatalogAssetGroup = fromJS({
  assetGroupUUID: undefined, // front 에서만 사용하는 식별자.
  assetGroupId: 0,
  image: {
    adAccountId: 0,
    fileSize: 0,
    hash: '',
    id: 0,
    imageHash: '',
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    originalFileName: '',
    url: '',
    value: '',
  },
  discountedPrice: null,
  price: null, // { amount: null, currencyCode: 'KRW', } // KRW | USD | JPY | EUR
  rspvLandingUrl: '',
  title: '',
})

/**
 * creative.expandableAssetGroups[]
 * - image: [BizBoardExpandableAssetGroup]
 * - video: [BizBoardExpandableAssetGroup]
 * - multi: [BizBoardExpandableAssetGroup, BizBoardExpandableAssetGroup, BizBoardExpandableAssetGroup,...]
 */
const BizBoardExpandableAssetGroup = fromJS({
  assetGroupUUID: undefined, // front 에서만 사용하는 식별자.
  assetGroupId: 0,
  video: {
    id: null,
    url: '',
    presetIds: [],
    encodingProgress: 1,
    transCodeIds: [],
    adAccountId: null,
    fileName: '',
    preview: {},
    mimeType: '',
    duration: 0,
    fileSize: 0,
  },
  autoThumbnail: {
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    url: '',
    fileSize: 0,
    originalFileName: '',
  },
  uploadThumbnail: {
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    url: '',
    fileSize: 0,
    originalFileName: '',
  },
  image: {
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    url: '',
    fileSize: 0,
    originalFileName: '',
  },
  actionButton: ActionButtonEnum.Type.NONE,
  title: '',
  price: null, // { amount: null, currencyCode: 'KRW', } // KRW | USD | JPY | EUR
  discountedPrice: null, // { amount: null, currencyCode: 'KRW', } // KRW | USD | JPY | EUR
  mobileLandingUrl: '',
})

/**
 * creative.image = bizBoardBundle.image
 * creative.landingInfo = bizBoardBundle.landingInfo
 * creative.expandableAssetGroups = BizBoardExpandableAssetGroup[]
 */
const BizBoardBundleModel = fromJS({
  bundleUUID: undefined, // front 에서만 사용하는 식별자.
  image: {
    fileSize: 0,
    hash: '',
    id: 0,
    imageHash: '',
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    originalFileName: '',
    url: '',
    expressMetaInfo: null, // BizBoardFormUtil.toExpressMetaInfo
  },

  useDefaultLandingInfo: true, // creative.landingInfo 를 따를 것인지 여부
  landingInfo: BizBoardLandingInfo,

  useDefaultTrackerUrl: true,
  clickTrackerUrl: '',
  eventTrackerUrl: '',

  // expandable
  useDefaultExpandable: true, // 대표 익스펜더블을 따를 것인지 여부
  expandableType: undefined,
  expandableAssetGroups: [BizBoardExpandableAssetGroup],
  hasPrice: false, // 익스펜더블 멀티형 가격정보 사용 유무

  // NAS 용 비즈보드 오브젝트 이미지
  bizBoardNativeImage: {
    fileSize: 0,
    hash: '',
    id: 0,
    imageHash: '',
    imageHeight: 0,
    imageWidth: 0,
    mimeType: '',
    originalFileName: '',
    url: '',
    expressMetaInfo: null,
  },
})

const PcTalkBottomBundleModel = fromJS({
  bundleUUID: undefined, // front 에서만 사용하는 식별자.
  backgroundImage: undefined,
  objectImage: undefined,
})

const ServiceContent = fromJS({
  serviceType: '', // ServiceContentEnum.Type
  contentId: '', // board id
  contentDetailId: '', // board revision(history) id
  encodedChannelId: '', // talk channel id(encoded)
})

const FrequencyCap = fromJS({
  count: null,
  type: 'AUTO', // AUTO | DAY_IMP
})

export {
  BizBoardLandingInfo,
  BizBoardExpandableAssetGroup,
  BizBoardBundleModel,
  CatalogAssetGroup,
  ServiceContent,
  FrequencyCap,
  PcTalkBottomBundleModel,
}
