import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ACTIVE: null,
    DELETED: null,
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },
}
