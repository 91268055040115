import React from 'react'
import PropTypes from 'prop-types'

ListLayout.propTypes = {
  children: PropTypes.element,
  listRef: PropTypes.any,
}

function ListLayout({ children, listRef }) {
  return (
    <div className="wrap_list">
      <ul className="list_option" ref={listRef}>
        {children}
      </ul>
    </div>
  )
}

export default ListLayout
