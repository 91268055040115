import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    SCROLL: null,
    CAROUSEL: null,
    IMAGE: null,
    VIDEO: null,
  }),

  Name: {
    SCROLL: '스크롤',
    CAROUSEL: '캐러셀',
    IMAGE: '이미지',
    VIDEO: '동영상',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },

  isMultiUploadType(type) {
    return [this.Type.CAROUSEL, this.Type.SCROLL].includes(type)
  },
}
