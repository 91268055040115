import React, { useMemo } from 'react'
import FloatingViewHOC from '../../Common/HOC/FloatingViewHOC'
import { shallowEqual, useSelector } from 'react-redux'
import cx from 'classnames'
import {
  toMyAdAccountListPath,
  toMyInfoPath,
} from '../../../utils/router/routeUtils'
import { NavLink } from 'react-router-dom'
import { logout } from '../../../utils/authenticationUtils'
import PropTypes from 'prop-types'
import { KAKAO_BUSINESS_GUIDE_MOMENT } from '../../../utils/app/services/kakaoBusinessGuide'
import { hasOnlyAlphanumeric } from '../../../utils/regexUtils'

const selector = ({
  user: {
    userInfo: { kakaoEmail, name, kakaoAccountId },
  },
}) => {
  return {
    kakaoEmail,
    name,
    kakaoAccountId,
  }
}

const PROFILE_BACKGROUND_COLORS = [
  '#DF5C54',
  '#EA8569',
  '#97BC4B',
  '#51A598',
  '#51A3B1',
  '#5D9CD1',
  '#4E679D',
  '#7061A6',
  '#8A5DB2',
  '#C25D97',
]

const MyInfo = ({ isVisible, dismiss }) => {
  const {
    kakaoEmail = '',
    name = '',
    kakaoAccountId = 0,
  } = useSelector(selector, shallowEqual)
  const background = useMemo(
    () => PROFILE_BACKGROUND_COLORS[kakaoAccountId % 10],
    [kakaoAccountId]
  )
  const initialCharacterOfName = useMemo(() => name.charAt(0), [name])
  const isAlphaNumeric = useMemo(
    () => hasOnlyAlphanumeric(initialCharacterOfName),
    [initialCharacterOfName]
  )
  return (
    <li className={cx('menu_my', { on: isVisible })}>
      <a className="link_gnb">
        <span className="thumb_account" style={{ background }}>
          <span
            className={cx('txt_account', { account_type2: isAlphaNumeric })}>
            {initialCharacterOfName}
          </span>
        </span>
        {kakaoEmail}
        <span className="ico_gnb ico_fold" />
        <span className="screen_out">
          My 메뉴 {isVisible ? '닫기' : '열기'}
        </span>
      </a>
      {isVisible && (
        <div className="layer_mymenu">
          <div className="wrap_account">
            <span className="thumb_account" style={{ background }}>
              <span
                className={cx('txt_account', {
                  account_type2: isAlphaNumeric,
                })}>
                {initialCharacterOfName}
              </span>
            </span>
            <strong className="tit_account">{name}</strong>
            <span className="desc_account">{kakaoEmail}</span>
          </div>
          <ul className="list_service">
            <li>
              <NavLink
                to={toMyInfoPath()}
                onClick={dismiss}
                activeClassName="on"
                className="link_sub">
                <span className="ico_comm ico_myinfo" />
                <span className="txt_sub">내 정보</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                to={toMyAdAccountListPath()}
                onClick={dismiss}
                activeClassName="on"
                className="link_sub">
                <span className="ico_comm ico_myaccount" />
                <span className="txt_sub">내 광고계정</span>
              </NavLink>
            </li>
            <li>
              <a
                target="_blank"
                href={KAKAO_BUSINESS_GUIDE_MOMENT}
                rel="noopener noreferrer"
                onClick={dismiss}
                className="link_sub">
                <span className="ico_comm ico_guide" />
                <span className="txt_sub">광고 이용가이드</span>
              </a>
            </li>
          </ul>
          <ul className="list_service">
            <li>
              <a className="link_sub" onClick={logout}>
                <span className="ico_comm ico_logout" />
                <span className="txt_sub">로그아웃</span>
              </a>
            </li>
          </ul>
        </div>
      )}
    </li>
  )
}

MyInfo.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  dismiss: PropTypes.func.isRequired,
}

export default FloatingViewHOC(MyInfo)
