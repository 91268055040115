import { useEffect, useRef, useState } from 'react'

export default function useDropDown() {
  const ref = useRef()
  const [isOpen, setIsOpen] = useState(false)

  const onDocumentClick = e => {
    if (ref.current.contains(e.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.body.addEventListener('click', onDocumentClick, true)
    return () => {
      document.body.removeEventListener('click', onDocumentClick, true)
    }
  }, [])

  return { ref, isOpen, setIsOpen }
}
