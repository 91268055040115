import PopupProxy from '../../Popup/Common/PopupProxy'
import React from 'react'
import { kakaoBizPlusFriendProfilesUrl } from '../../../utils/app/services/kakaoPlusFriend'
import TalkChannelMessageEnum from '../../../enums/TalkChannelMessageEnum'

export const cancelCreateMessageDialog = onOK => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">메시지 만들기 취소</strong>
      <p className="txt_layer">
        메시지 만들기를 취소하시겠습니까?
        <br />
        저장되지 않은 모든 내용은 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const cancelModifyMessageDialog = onOK => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">수정 취소</strong>
      <p className="txt_layer">
        수정을 취소하시겠습니까?
        <br />
        저장되지 않은 모든 내용은 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const incorrectKakaoChannelStatus = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">카카오톡 채널 상태 확인</strong>
      <p className="txt_layer">
        선택한 카카오톡 채널은 휴면, 제재, 삭제 등의 사유로 요청한 작업을 실행할
        수 없습니다.
        <br />
        <a
          href={kakaoBizPlusFriendProfilesUrl()}
          className="fc_empty"
          rel="noopener noreferrer"
          target="_blank">
          카카오톡 채널
          <br />
          관리자센터
        </a>
        에서 채널의 상태를 확인하세요.
      </p>
    </PopupProxy>
  )
}

export const partnerInfoInVisible = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">카카오톡 채널 홈 노출 설정 확인</strong>
      <p className="txt_layer">
        선택한 카카오톡 채널의 홈 노출 설정이 꺼져 있어
        <br />
        요청한 작업을 실행할 수 없습니다.{' '}
        <a
          href={kakaoBizPlusFriendProfilesUrl()}
          className="fc_empty"
          rel="noopener noreferrer"
          target="_blank">
          카카오톡 채널 관리
          <br />
          자센터
        </a>
        에서 홈 노출 설정을 확인하세요.
      </p>
    </PopupProxy>
  )
}

export const exceedMessageCountDialog = max => {
  return (
    <PopupProxy>
      <strong className="tit_layer">메시지 개수 초과</strong>
      <p className="txt_layer">
        메시지 관리에는 {max}개까지 저장할 수 있습니다.
        <br />
        사용하지 않는 메시지를 삭제하고 다시 시도하세요.
      </p>
    </PopupProxy>
  )
}

// type : 카카오톡 채널 / 메시지 유형
export const cancelMyKakaoTalkChannelOrMessageTypeDialog = (
  onOK,
  type = '카카오톡 채널'
) => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">입력 정보 초기화</strong>
      <p className="txt_layer">
        {type}을 변경하면 입력 정보가 초기화
        <br />
        됩니다. 변경하시겠습니까?
      </p>
    </PopupProxy>
  )
}

export const deleteMessageDialog = onOK => {
  return (
    <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
      <strong className="tit_layer">메시지 소재 삭제</strong>
      <p className="txt_layer">삭제하시겠습니까?</p>
    </PopupProxy>
  )
}

export const unavailableDeleteMessageDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">메시지 소재 삭제 불가</strong>
      <p className="txt_layer">
        이 메시지를 랜딩으로 사용중인 카카오톡 비즈보드 소
        <br />재 혹은 애드뷰가 있어 삭제할 수 없습니다.
      </p>
    </PopupProxy>
  )
}

export const unavailableSaveByDomainMismatchDialog = ({
  isTestMessageSend,
}) => {
  return (
    <PopupProxy>
      <strong className="tit_layer">
        {isTestMessageSend ? '발송' : '저장'} 불가
      </strong>
      <p className="txt_layer">
        웹임베드 채널에 연결된 URL과
        <br />
        입력한 URL의 도메인이 일치해야 합니다.
      </p>
    </PopupProxy>
  )
}

export const unavailableInvalidUrlDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">랜딩 URL 형식 오류</strong>
      <p className="txt_layer">
        랜딩 URL 형식이 유효하지 않습니다.
        <br />
        정상적인 URL을 입력하세요.
      </p>
    </PopupProxy>
  )
}

export const unavailableInvalidSpecialCharacterUrlDialog = ({
  isTestMessageSend,
}) => {
  return (
    <PopupProxy>
      <strong className="tit_layer">
        {isTestMessageSend ? '발송 불가' : '저장 불가'}
      </strong>
      <p className="txt_layer">
        랜딩 URL의 파라미터에 연결 시 오류가 발생할 수 있는 특수문자가
        포함되어있습니다.
        <br />
        문제가 될 수 있는 부분을 인코딩한 후 다시 진행하세요.
      </p>
    </PopupProxy>
  )
}

export const finishedLandingTypeMessageDialog = landingType => {
  const landingTypeName = TalkChannelMessageEnum.Landing.getName(landingType)

  return (
    <PopupProxy>
      <strong className="tit_layer">랜딩 상태</strong>
      <p className="txt_layer">
        종료된 {landingTypeName}이 있습니다.
        <br />
        {landingTypeName}의 상태를 확인하세요.
      </p>
    </PopupProxy>
  )
}

export const deletedLandingPostMessageDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">랜딩 상태</strong>
      <p className="txt_layer">
        삭제된 소식이 있습니다.
        <br />
        소식 상태를 확인하세요.
      </p>
    </PopupProxy>
  )
}

export const carouselCommerceInvalidOrderingDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">소재 저장 불가</strong>
      <p className="txt_layer">
        버튼 랜딩 정보가 유효하지 않아 소재 저장이 불가합니다. <br />
        처음부터 다시 시도해주세요.
      </p>
    </PopupProxy>
  )
}
