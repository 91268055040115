import { combineReducers, createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import main from './mPixelAndSDKManagement'
import detail from './mPixelAndSDKDetail'

const PixelAndSdk = keyMirror(
  {
    GET_TRACK_LIST_BY_AD_ACCOUNT: null,
    CLEAR_TRACK_LIST_BY_AD_ACCOUNT: null,
    GET_TRACK_EVENT_LIST_BY_AD_ACCOUNT: null,
    CLEAR_TRACK_EVENT_LIST_BY_AD_ACCOUNT: null,
  },
  'PIXEL_AND_SDK_COMMON'
)

const initialState = fromJS({
  trackListByAdAccount: null,
  trackEventListByAdAccount: [],
})

const common = createReducer(initialState, {
  [PixelAndSdk.GET_TRACK_LIST_BY_AD_ACCOUNT]: (state, { data }) =>
    state.set('trackListByAdAccount', fromJS(data)),

  [PixelAndSdk.CLEAR_TRACK_LIST_BY_AD_ACCOUNT]: state =>
    state.set('trackListByAdAccount', initialState.get('trackListByAdAccount')),

  [PixelAndSdk.GET_TRACK_EVENT_LIST_BY_AD_ACCOUNT]: (state, { data }) =>
    state.set('trackEventListByAdAccount', fromJS(data)),

  [PixelAndSdk.CLEAR_TRACK_EVENT_LIST_BY_AD_ACCOUNT]: state =>
    state.set(
      'trackEventListByAdAccount',
      initialState.get('trackEventListByAdAccount')
    ),
})

const receiveTrackByAdAccountId = data => ({
  type: PixelAndSdk.GET_TRACK_LIST_BY_AD_ACCOUNT,
  data,
})

export const clearTrackListByAdAccount = () => ({
  type: PixelAndSdk.CLEAR_TRACK_LIST_BY_AD_ACCOUNT,
})

const receiveEventListByAdAccountId = data => ({
  type: PixelAndSdk.GET_TRACK_EVENT_LIST_BY_AD_ACCOUNT,
  data,
})

export const clearTrackEventList = () => ({
  type: PixelAndSdk.CLEAR_TRACK_EVENT_LIST_BY_AD_ACCOUNT,
})

export const fetchCampaignTrackList = ({
  adAccountId,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bconV2.fetchLinkedTrackListWithoutPaging(
        adAccountId
      )
      const content = response.data.body?.content || []

      dispatch(receiveTrackByAdAccountId(content))

      onSuccess(content)
    } catch (e) {
      dispatch(clearTrackListByAdAccount())
      console.error(e.message)
      onFail(e)
    }
  }
}

export const fetchAuthorizedTrackEventListByIdAndDimensionsV2 = ({
  trackId,
  adAccountId,
  term,
  withAllEvent,
  onSuccess = () => {},
  onFail = () => {},
}) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bconV2.fetchTrackCollectedEventList({
        adAccountId,
        trackId,
        requestPayload: {
          dimensions: ['tracklabel'],
          term,
        },
        withAllEvent,
      })

      dispatch(receiveEventListByAdAccountId(response.data.body))
      onSuccess()
    } catch (e) {
      console.error(e.message)
      onFail()
    }
  }
}

export default combineReducers({
  common,
  main,
  detail,
})
