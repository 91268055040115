import React from 'react'
import { checkNotEmpty } from '../../../../../utils/regexUtils'
import DashboardStatusEnum from '../../../../../enums/DashboardStatusEnum'
import PropTypes from 'prop-types'

const TotalStatusTooltip = ({ representationTooltip, extraTooltip }) => {
  const hasRepresentation = checkNotEmpty(representationTooltip)
  const hasExtra =
    typeof extraTooltip === 'object' &&
    Object.values(extraTooltip).some(Boolean)

  return (
    <div>
      {hasRepresentation && (
        <p className="txt_tooltip">{representationTooltip}</p>
      )}
      {hasExtra && (
        <ul className="list_tooltip">
          {extraTooltip.adAccountStatus && (
            <li>
              · 광고계정:{' '}
              {DashboardStatusEnum.getName(extraTooltip.adAccountStatus)}
            </li>
          )}
          {extraTooltip.campaignStatus && (
            <li>
              · 캠페인:{' '}
              {DashboardStatusEnum.getName(extraTooltip.campaignStatus)}
            </li>
          )}
          {extraTooltip.adGroupStatus && (
            <li>
              · 광고그룹:{' '}
              {DashboardStatusEnum.getName(extraTooltip.adGroupStatus)}
            </li>
          )}
        </ul>
      )}
    </div>
  )
}

TotalStatusTooltip.propTypes = {
  representationTooltip: PropTypes.string,
  extraTooltip: PropTypes.object,
}

export default TotalStatusTooltip
