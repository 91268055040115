import DimensionEnum from '../../../enums/DimensionEnum'
import moment from 'moment/moment'
import CalendarEnum from '../../../enums/CalendarEnum'
import ReportCalendarUtils from './reportCalendarUtils'
import { fromJS } from 'immutable'
import { compact, flatten, groupBy, toPairs, uniqBy } from 'lodash'
import { ReportFilterUtils } from './reportFilterUtils'
import ReportFilterEnum from '../../../enums/ReportFilterEnum'
import { ReportMetric } from './reportMetric'
import SortEnum from '../../../enums/SortEnum'
import UserConfigEnum from '../../../enums/UserConfigEnum'
import DashboardEnumV2 from '../../../enums/DashboardEnumV2'

const convertCalendarResponseToState = ({
  startDate = moment().subtract(7, 'd'),
  endDate = moment().subtract(1, 'd'),
  presetDate = CalendarEnum.Preset.Type.LAST7DAYS,
}) => {
  const { begin, end } = ReportCalendarUtils.rangeByPreset(presetDate, moment())
  return {
    startDate:
      presetDate === CalendarEnum.Preset.Type.CUSTOM
        ? moment(startDate)
        : begin,
    endDate:
      presetDate === CalendarEnum.Preset.Type.CUSTOM ? moment(endDate) : end,
    periodType: presetDate,
    ...(presetDate === CalendarEnum.Preset.Type.CUSTOM && {
      customDateDiff: Math.abs(moment(startDate).diff(moment(endDate), 'd')),
    }),
  }
}
const convertDimensionResponseToState = ({ dimensions, indicators }) => {
  const hasAgeBandGender =
    dimensions.includes(DimensionEnum.Targeting.Type.AGE_BAND) &&
    dimensions.includes(DimensionEnum.Targeting.Type.GENDER)
  if (hasAgeBandGender) {
    const ageBandIndex = dimensions.indexOf(
      DimensionEnum.Targeting.Type.AGE_BAND
    )
    const genderIndex = dimensions.indexOf(DimensionEnum.Targeting.Type.GENDER)
    dimensions[Math.min(ageBandIndex, genderIndex)] =
      DimensionEnum.Targeting.Type.AGE_BAND_GENDER
    dimensions.splice(Math.max(ageBandIndex, genderIndex), 1)
  }
  const hasDevicePlacement =
    dimensions.includes(DimensionEnum.Targeting.Type.DEVICE) &&
    dimensions.includes(DimensionEnum.Targeting.Type.PLACEMENT)
  if (hasDevicePlacement) {
    const deviceIndex = dimensions.indexOf(DimensionEnum.Targeting.Type.DEVICE)
    const placementIndex = dimensions.indexOf(
      DimensionEnum.Targeting.Type.PLACEMENT
    )
    dimensions[Math.min(deviceIndex, placementIndex)] =
      DimensionEnum.Targeting.Type.DEVICE_PLACEMENT
    dimensions.splice(Math.max(deviceIndex, placementIndex), 1)
  }
  const splits = dimensions.reduce(
    (prev, dimension) => {
      if (DimensionEnum.AdUnit.values().includes(dimension)) {
        prev.adUnits.push(dimension)
      } else {
        prev.dimensions.push(dimension)
      }
      return prev
    },
    {
      adUnits: [],
      dimensions: [],
    }
  )
  return {
    adUnits: splits.adUnits,
    dimensions: splits.dimensions,
    metrics: indicators.map(indicator => indicator.toLowerCase()),
  }
}
const convertFilterResponseToState = ({ filters = {}, conditionals = {} }) => {
  const filterOrder = ReportFilterEnum.Filter.values()
  const indicatorOrder = ReportMetric.getAllMetricIds()
  const filterArray = flatten(
    compact(
      toPairs(filters).map(([adUnitKey, values]) =>
        toPairs(values)
          .filter(([, filterValues]) => filterValues?.length > 0)
          .map(([stateKey, filterValue]) => {
            const filterType = ReportFilterUtils.Common.filterType({
              stateKey,
            })
            const [filterOperator] =
              ReportFilterUtils.Common.filterOperatorValues({
                filterType,
              })
            return {
              adUnitType: ReportFilterUtils.Common.adUnitType({ adUnitKey }),
              filterType,
              filterOperator,
              filterValue,
            }
          })
      )
    )
  ).sort(
    (a, b) =>
      filterOrder.indexOf(a.filterType) - filterOrder.indexOf(b.filterType)
  )

  const conditionalArray = flatten(
    toPairs(conditionals).map(([adUnitKey, values]) =>
      // 사이필터의 경우 두개의 값으로 분리되어 있어 유니크한 값으로 추림
      uniqBy(
        values.map(({ indicator, conditionalFunction, value }) => {
          const adUnitType = ReportFilterUtils.Common.adUnitType({
            adUnitKey,
          })

          const indicatorGroupMap = groupBy(
            values,
            ({ indicator }) => indicator
          )

          const isBetweenOperatorUsed =
            indicatorGroupMap[indicator]
              .map(({ conditionalFunction }) => conditionalFunction)
              .join('_') === ReportFilterEnum.Operator.Type.GE_LE // join()시 GE, LE 순서 유의

          const defaultObj = {
            adUnitType,
            filterType: `${adUnitType}_METRIC`,
            filterSubType: String(indicator).toLowerCase(),
          }

          if (isBetweenOperatorUsed) {
            const [min, max] = indicatorGroupMap[indicator].map(
              ({ value }) => value
            )

            return {
              ...defaultObj,
              filterOperator: ReportFilterEnum.Operator.Type.GE_LE,
              filterValue: [
                {
                  min,
                  max,
                },
              ],
            }
          } else {
            return {
              ...defaultObj,
              filterOperator: conditionalFunction,
              filterValue: [
                {
                  min: value,
                  max: '',
                },
              ],
            }
          }
        }),
        ({ filterSubType }) => filterSubType
      ).sort(
        (a, b) =>
          indicatorOrder.indexOf(a.filterSubType) -
          indicatorOrder.indexOf(b.filterSubType)
      )
    )
  ).sort(
    (a, b) =>
      filterOrder.indexOf(a.filterType) - filterOrder.indexOf(b.filterType)
  )

  return [...filterArray, ...conditionalArray]
}
const convertSortResponseToState = sort => {
  const [{ key, direction }] = sort
  return {
    sort: key,
    order: direction,
  }
}

export const fixResponse = response => {
  const { dimensions, indicators, filters = {}, sort } = response
  // 정렬값 보정
  const columns = [...dimensions, ...indicators]
  const [{ key }] = sort
  if (!columns.includes(key)) {
    const [first] = columns
    const direction = ReportMetric.getAllMetricIds().includes(first)
      ? SortEnum.Type.DESC
      : SortEnum.Type.ASC
    response.sort = {
      key: first,
      direction,
    }
  }
  // ON/OFF/삭제 필터 보정
  ;[
    DashboardEnumV2.Type.CAMPAIGN,
    DashboardEnumV2.Type.AD_GROUP,
    DashboardEnumV2.Type.CREATIVE,
  ].forEach(adUnitType => {
    const adUnitKey = ReportFilterUtils.Common.adUnitKey({ adUnitType })
    const userConfigs = filters[adUnitKey]?.userConfigs || []
    if (
      userConfigs.includes(UserConfigEnum.Type.DEL) &&
      (userConfigs.includes(UserConfigEnum.Type.ON) ||
        userConfigs.includes(UserConfigEnum.Type.OFF))
    ) {
      response.filters[adUnitKey].userConfigs = userConfigs.filter(
        value => value !== UserConfigEnum.Type.DEL
      )
    }
  })
  return response
}
export const convertResponseToState = response => {
  const {
    startDate,
    endDate,
    presetDate,
    dimensions,
    indicators,
    filters,
    conditionals,
    sort,
  } = fixResponse(response)
  return fromJS({
    calendar: convertCalendarResponseToState({
      startDate,
      endDate,
      presetDate,
    }),
    common: convertDimensionResponseToState({ dimensions, indicators }),
    filterConditions: convertFilterResponseToState({ filters, conditionals }),
    sort: convertSortResponseToState(sort),
  })
}
