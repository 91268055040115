import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'
import '../DynamicTooltip.css'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
  DynamicTooltipCommonPropTypes,
  DynamicTooltipStyles,
} from '../dynamicTooltipUtils'
import useDynamicTooltipPosition from '../hooks/useDynamicTooltipPosition'
import useDynamicTooltip from '../hooks/useDynamicTooltip'

const GuideTooltip = ({
  content,
  getElement = () => undefined,
  showDelay = 0,
  dismissTimeout = 5 * 1000,
  //
  verticalPosition = DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM,
  horizontalPosition = DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.RIGHT,
  className,
  children,
  styles = {},
}) => {
  const [targetNode, setTargetNode] = React.useState()

  const { isVisible, onMouseEnter, onMouseLeave, show } = useDynamicTooltip()

  React.useLayoutEffect(() => {
    setTargetNode(getElement())
  }, [getElement])

  React.useLayoutEffect(() => {
    if (targetNode) {
      show({ showDelay, dismissTimeout })
    }
  }, [dismissTimeout, show, showDelay, targetNode])

  if (!content || !targetNode) return children || null

  return (
    <CSSTransition in={isVisible} classNames="dynamic-tooltip" timeout={300}>
      <Portal>
        {isVisible && (
          <GuideTooltipContent
            content={content}
            containerNode={targetNode}
            verticalPosition={verticalPosition}
            horizontalPosition={horizontalPosition}
            className={className}
            styles={styles}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
          />
        )}
      </Portal>
    </CSSTransition>
  )
}

GuideTooltip.propTypes = {
  ...DynamicTooltipCommonPropTypes,
  content: PropTypes.any,
  getElement: PropTypes.func.isRequired,
  showDelay: PropTypes.number,
  dismissTimeout: PropTypes.number,
}

const GuideTooltipContent = ({
  content,
  containerNode,
  verticalPosition,
  horizontalPosition,
  className,
  styles,
  onMouseEnter,
  onMouseLeave,
}) => {
  const { tooltipRef } = useDynamicTooltipPosition({
    containerNode,
    verticalPosition,
    horizontalPosition,
  })

  return (
    <span
      ref={tooltipRef}
      className={className}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ ...DynamicTooltipStyles, ...styles }}
      onClick={e => {
        // 툴팁 컨텐츠의 클릭 이벤트 전파 차단.
        e.stopPropagation()
      }}>
      {content}
    </span>
  )
}

GuideTooltipContent.propTypes = {
  content: PropTypes.any,
  containerNode: PropTypes.any,
  verticalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_VERTICAL)
  ),
  horizontalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_HORIZONTAL)
  ),
  className: PropTypes.string,
  styles: PropTypes.object,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
}

const Portal = ({ children }) =>
  document.body ? ReactDOM.createPortal(children, document.body) : null

export default GuideTooltip
