import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    SECONDS_5: null,
    SECONDS_15: null,
  }),

  Name: {
    SECONDS_5: '최대 5초 후 SKIP',
    SECONDS_15: '최대 15초 후 SKIP',
  },

  SkipSeconds: {
    SECONDS_5: 5,
    SECONDS_15: 15,
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },

  getSkipSeconds(type) {
    return this.SkipSeconds[type]
  },
}
