import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { openPopup, POPUP_KEY } from '../../../modules/common/mPopup'
import { kakaoBizCenterWithdrawURL } from '../../../utils/app/services/kakaoBizCenter'
import { KAKAO_BUSINESS_GUIDE } from '../../../utils/app/services/kakaoBusinessGuide'

const selector = ({
  user: {
    userInfo: { integrated },
  },
}) => ({
  integrated,
})

const MyInfoTitle = () => {
  const dispatch = useDispatch()

  const { integrated } = useSelector(selector, shallowEqual)

  return (
    <>
      <div className="dsp_tit">
        <div className="tit_dsp">
          <h3 className="tit_subject">내 정보</h3>
          <div className="f_right">
            <a
              href={KAKAO_BUSINESS_GUIDE.MY_INFO}
              className="link_help5"
              rel="noopener noreferrer"
              target="_blank">
              <span className="ico_comm" />
              도움말
            </a>
            <div className="single_wrap">
              <a
                className="btn_gm gm_line"
                onClick={() => {
                  if (integrated) {
                    window.open(kakaoBizCenterWithdrawURL(), '_blank')
                  } else {
                    dispatch(openPopup(POPUP_KEY.WITHDRAW_MEMBER))
                  }
                }}>
                <span className="inner_g">회원탈퇴</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <ul className="list_append">
        <li>
          카카오모먼트 내 광고 운영의 편의를 위한 계정 정보, 자동 결제카드, 알림
          설정이 가능합니다.
        </li>
        <li>
          카카오 계정의 정보 설정은 카카오비즈니스 관리자센터를 통해 확인할 수
          있습니다.
        </li>
      </ul>
    </>
  )
}

export default MyInfoTitle
