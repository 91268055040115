import React from 'react'
import PopupHOC from '../../../Popup/PopupHOC'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { showSuccessMessage } from '../../../../utils/alertUtils'
import {
  getApiPhase,
  KakaoMoment,
} from '../../../../utils/app/services/kakaoMoment'
import PropTypes from 'prop-types'

ReportSharePopup.propTypes = {
  reportId: PropTypes.number,
  adAccountId: PropTypes.number,
  close: PropTypes.func,
}

function ReportSharePopup({ reportId, adAccountId, close }) {
  const phase = getApiPhase()
  const reportUrl = `${KakaoMoment.Host[phase]}/${adAccountId}/report/${reportId}`

  const onCopy = () => {
    showSuccessMessage('보고서 링크를 클립보드에 복사했습니다.')
  }

  return (
    <div className="inner_basic_layer">
      <strong className="tit_layer">보고서 공유</strong>
      <p className="txt_layer">
        해당 광고계정에 권한을 갖고 있는 마스터/멤버만
        <br />
        보고서를 확인할 수 있습니다.
      </p>
      <div className="box_download">
        <p className="desc_download">{reportUrl}</p>
        <CopyToClipboard text={reportUrl} onCopy={onCopy}>
          <button type="button" className="btn_gm gm_bl">
            <span className="inner_g">복사</span>
          </button>
        </CopyToClipboard>
      </div>
      <a className="btn_close" onClick={close}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

export default PopupHOC(ReportSharePopup, {
  className: 'basic_layer2',
  subClassName: 'layer_reportshare',
})
