import { Map } from 'immutable'
import { checkEmpty, hasFullWidthWhitespace } from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'
import {
  messageFormDescriptionValidation,
  messageFormItemValidation,
  messageFormLandingValidation,
  messageFormPcLandingValidation,
  messageFormTitleValidation,
} from './messageFormValidator'
import { MESSAGE_FORM_VALIDATION_KEY } from './messageFormValidationKey'
import { MESSAGE_FORM_VALIDATION_MESSAGE } from './messageFormValidationMessage'
import { validateDiscountedPrice } from '../advertise/creativeV2/creativeFormValidatorV2'

const validatePrice = price => {
  const { amount, currencyCode } = price || {}
  if (checkEmpty(amount) || checkEmpty(currencyCode)) {
    return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
  } else {
    return IS_VALID()
  }
}

export const CAROUSEL_COMMERCE_MESSAGE_FORM_VALIDATOR = Map({
  [MESSAGE_FORM_VALIDATION_KEY.NAME]: name => {
    if (checkEmpty(name)) {
      return IS_NOT_VALID(MESSAGE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    } else if (hasFullWidthWhitespace(name)) {
      return IS_NOT_VALID(
        MESSAGE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    return IS_VALID()
  },
  [MESSAGE_FORM_VALIDATION_KEY.TITLE]: ({
    title,
    hasIntro,
    creativeFormat,
  }) => {
    if (!hasIntro) return IS_VALID()
    return messageFormTitleValidation(title, creativeFormat)
  },
  [MESSAGE_FORM_VALIDATION_KEY.DESCRIPTION]: ({
    description,
    hasIntro,
    creativeFormat,
  }) => {
    if (!hasIntro) return IS_VALID()

    return messageFormDescriptionValidation(description, creativeFormat)
  },

  // Intro
  [MESSAGE_FORM_VALIDATION_KEY.ITEM]: messageForm => {
    const { hasIntro } = messageForm
    if (!hasIntro) return IS_VALID()

    return messageFormItemValidation(messageForm, true)
  },

  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_INTRO_LANDING]: ({
    hasIntro,
    introLandingType,
    introMobileLandingUrl,
    introChannelCouponId,
    introChannelPostId,
    introTalkStoreLandingUrl,
    introWebembedChannelUrl,
    introPcLandingUrl,
  }) => {
    if (!hasIntro) return IS_VALID()

    const introLandingValidationResult = messageFormLandingValidation({
      landingInfo: {
        landingType: introLandingType,
        mobileLandingUrl: introMobileLandingUrl,
        channelCouponId: introChannelCouponId,
        channelPostId: introChannelPostId,
        talkStoreLandingUrl: introTalkStoreLandingUrl,
        webembedChannelUrl: introWebembedChannelUrl,
      },
      isRequired: false,
    })

    const introPCLandingValidationResult =
      messageFormPcLandingValidation(introPcLandingUrl)

    const isValid = [
      introLandingValidationResult,
      introPCLandingValidationResult,
    ].every(v => v.isValid)

    return isValid
      ? IS_VALID()
      : IS_NOT_VALID('', {
          introLanding: introLandingValidationResult,
          introPcLanding: introPCLandingValidationResult,
        })
  },

  [MESSAGE_FORM_VALIDATION_KEY.CAROUSEL_MORE]: messageForm => {
    const { pcLandingUrl } = messageForm

    const itemAssetLandingValidationResult = messageFormLandingValidation({
      landingInfo: messageForm,
      isRequired: false,
    })

    const itemAssetPcLandingValidationResult =
      messageFormPcLandingValidation(pcLandingUrl)

    const isValid = [
      itemAssetLandingValidationResult,
      itemAssetPcLandingValidationResult,
    ].every(v => v.isValid)

    return isValid
      ? IS_VALID()
      : IS_NOT_VALID('', {
          landing: itemAssetLandingValidationResult,
          pcLanding: itemAssetPcLandingValidationResult,
        })
  },

  [MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS]: ({
    itemAssetGroups,
    creativeFormat,
    hasIntro,
  }) => {
    const minSlide = hasIntro ? 1 : 2
    if (!itemAssetGroups || itemAssetGroups.count() < minSlide) {
      return IS_NOT_VALID('입력한 말풍선이 2개 이상이어야 합니다.')
    }

    const itemAssetGroupsValidationResults = []
    itemAssetGroups.forEach(itemAsset => {
      const { assetGroupUUID, title, price, discountedPrice, pcLandingUrl } =
        itemAsset

      const itemAssetTitleValidationResult = messageFormTitleValidation(
        title,
        creativeFormat
      )
      const itemAssetLandingValidationResult = messageFormLandingValidation({
        landingInfo: itemAsset,
        isRequired: true,
      })
      const itemAssetValidationResult = messageFormItemValidation(
        itemAsset,
        true
      )
      const itemAssetPriceValidationResult = validatePrice(price)
      const itemAssetDiscountedPriceValidationResult = validateDiscountedPrice({
        price,
        discountedPrice,
      })

      const itemAssetPCLandingValidatorResult =
        messageFormPcLandingValidation(pcLandingUrl)

      const isValid = [
        itemAssetTitleValidationResult,
        itemAssetLandingValidationResult,
        itemAssetValidationResult,
        itemAssetPriceValidationResult,
        itemAssetDiscountedPriceValidationResult,
        itemAssetPCLandingValidatorResult,
      ].every(v => v.isValid)

      itemAssetGroupsValidationResults.push({
        assetGroupUUID,
        isValid,
        title: itemAssetTitleValidationResult,
        landing: itemAssetLandingValidationResult,
        pcLanding: itemAssetPCLandingValidatorResult,
        asset: itemAssetValidationResult,
        price: itemAssetPriceValidationResult,
        discountedPrice: itemAssetDiscountedPriceValidationResult,
      })
    })

    const isAllValid = itemAssetGroupsValidationResults.every(v => {
      return v.isValid
    })

    return isAllValid
      ? IS_VALID()
      : IS_NOT_VALID('', { itemAssetGroupsValidationResults })
  },
})
