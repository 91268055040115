import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { MomentLoader } from '../../../../../Common/Loader/MomentLoader'
import CampaignTypeEnum from '../../../../../../enums/CampaignTypeEnum'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'
import CmpAssetLibraryImageView from './CmpAssetLibraryImageView'
import CreativeAssetPropertyEnum from '../../../../../../enums/CreativeAssetPropertyEnum'
import { getCmpAssetLibraryImageRecentData } from '../../../../../../modules/cmp/mCmpAssetLibraryImage'
import { cancelRequest } from '../../../../../../utils/cancellation/cancellation'
import CmpAssetLibraryImageRecentFilter from './CmpAssetLibraryImageRecentFilter'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT } from '../cmpAssetLibraryUtils'
import Pagination from '../../../../../Common/Pagenation/Pagenation'

const selector = ({
  cmpAssetLibrary: {
    image: {
      recent: { searchOptions, recentData },
      selectedItems,
      viewState: { isFetchingRecent },
    },
  },
}) => {
  return {
    searchOptions,
    recentData,
    selectedItems,
    isFetchingRecent,
  }
}

const CmpAssetLibraryImageRecent = ({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType,
  cropAspects,
  assetConstraint,
  multiSelect,
  handleImageCropperOpen,
  validateImageSize,
  renderGuideView,
  handleImageEditorOpenForModify,
  specificRatio,
}) => {
  const dispatch = useDispatch()

  const { recentData, selectedItems, isFetchingRecent } = useSelector(
    selector,
    shallowEqual
  )

  const {
    query,
    content: items,
    number: pageIndex,
    size: pageSize,
    totalElements,
  } = recentData || {}

  const fetchRecent = React.useCallback(
    ({ page = 0, size = 50 } = {}) => {
      dispatch(
        getCmpAssetLibraryImageRecentData({
          adAccountId,
          campaignType,
          creativeFormat,
          creativeAssetPropertyType,
          page,
          size,
          specificRatio,
        })
      )
    },
    [
      adAccountId,
      campaignType,
      creativeAssetPropertyType,
      creativeFormat,
      dispatch,
      specificRatio,
    ]
  )

  React.useEffect(() => {
    // empty 일 떄만 로드하도록 한다(탭 변경 시 유지되어야 한다).
    if (items.isEmpty()) {
      fetchRecent()
    }

    return () => {
      cancelRequest(CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT)
    }
  }, [adAccountId, creativeFormat, dispatch, fetchRecent, items])

  const handlePageIndexChange = currentIndex => {
    if (pageIndex !== currentIndex) {
      fetchRecent({ page: currentIndex, size: pageSize })
    }
  }

  const handlePageSizeChange = nextPageSize => {
    if (pageSize !== nextPageSize) {
      fetchRecent({ page: 0, size: nextPageSize })
    }
  }

  const isBizBoardBanner =
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function'
          ? renderGuideView()
          : renderGuideView}
        <CmpAssetLibraryImageRecentFilter
          adAccountId={adAccountId}
          campaignType={campaignType}
          creativeFormat={creativeFormat}
          creativeAssetPropertyType={creativeAssetPropertyType}
          assetConstraint={assetConstraint}
        />
      </div>
      <div className="group_material">
        {isFetchingRecent ? (
          <div className="reform_box_nodata">
            <div className="txt_nodata">
              <MomentLoader />
            </div>
          </div>
        ) : totalElements > 0 ? (
          <div className="inner_material">
            <ul className="list_material">
              {items.map((item, index) => {
                const { imageUUID } = item
                const selectedOrder =
                  selectedItems.findIndex(
                    ({ imageUUID: selectedItemImageUUID }) =>
                      selectedItemImageUUID === imageUUID
                  ) + 1

                return (
                  <CmpAssetLibraryImageView
                    key={imageUUID}
                    index={index}
                    selectedOrder={selectedOrder}
                    item={item}
                    cropAspects={cropAspects}
                    query={query}
                    isVisibleAnim={false}
                    multiSelect={multiSelect}
                    isBizBoardBanner={isBizBoardBanner}
                    creativeFormat={creativeFormat}
                    handleImageCropperOpen={handleImageCropperOpen}
                    validateImageSize={validateImageSize}
                    handleImageEditorOpenForModify={
                      handleImageEditorOpenForModify
                    }
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="reform_box_nodata">
            <div className="txt_nodata">최근 사용한 이미지가 없습니다.</div>
          </div>
        )}
      </div>
      <Pagination
        currentIndex={pageIndex}
        totalElements={totalElements}
        paginationVisible={!isFetchingRecent && totalElements > 0}
        onChangePage={handlePageIndexChange}
        pageSize={pageSize}
        isPageOptionEnable={true}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  )
}

CmpAssetLibraryImageRecent.propTypes = {
  adAccountId: PropTypes.any.isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
  cropAspects: PropTypes.array.isRequired,
  assetConstraint: ImmutablePropTypes.map.isRequired,
  multiSelect: PropTypes.bool,
  handleImageCropperOpen: PropTypes.func,
  validateImageSize: PropTypes.func.isRequired,
  //
  renderGuideView: PropTypes.any,
  handleImageEditorOpenForModify: PropTypes.func,
  specificRatio: PropTypes.number,
}

export default CmpAssetLibraryImageRecent
