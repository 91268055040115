import { List } from 'immutable'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  BIZ_BOARD_ICON_SPEC,
  BIZ_BOARD_MULTI_OBJET_SPEC,
  BIZ_BOARD_OBJET_SPEC,
  BIZ_BOARD_SINGLE_THUMBNAIL_SPEC,
  BIZ_BOARD_SPEC,
  CREATIVE_IMAGE_PROFILE_SPEC,
  FULL_VIEW_SPEC,
  getCreativeImageFileSizeKB,
  getCreativeImageSizeText,
  MOTION_BOARD_SPEC,
  PC_TALK_BOTTOM_SPEC,
  PC_TALK_RICH_POP_SPEC,
} from '../../../../../utils/advertise/creativeImage'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import { CreativeAssetUtils } from '../../creativeAssetUtils'
import { formatBytes } from '../../../../../utils/stringUtils'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import { keyMirror } from '../../../../../utils/utils'
import { v4 as uuid } from 'uuid'
import { floor } from 'lodash'
import CreativeAssetPropertyEnum from '../../../../../enums/CreativeAssetPropertyEnum'
import TranscodeTypeEnum from '../../../../../enums/TranscodeTypeEnum'

const CMP_ASSET_LIBRARY_VIDEO_TYPE = keyMirror({
  DISPLAY: null,
  MESSAGE: null,
})

const CMP_ASSET_LIBRARY_FILTER_TYPE = keyMirror({
  AVAILABLE: null,
  CUSTOM: null,
})

const CMP_ASSET_LIBRARY_FILTER_OPTION = keyMirror({
  ALL: null,
  CUSTOM: null,
  UNKNOWN: null,
})

const CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT = uuid()
const CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR = uuid()

export const UPLOAD_INITIAL_STATUS = {
  progress: 0,
  requested: false,
  received: false,
}

export const UPLOAD_SUCCESS_STATUS = {
  progress: 0,
  requested: false,
  received: true,
}

const CmpAssetLibraryUtils = {}

CmpAssetLibraryUtils.Recent = {
  ratioOptions() {
    return [
      { width: 1, height: 1 },
      { width: 2, height: 1 },
      { width: 4, height: 3 },
      { width: 5, height: 1 },
      { width: 9, height: 16 },
      { width: 16, height: 9 },
    ]
  },
  ratioValues() {
    return this.ratioOptions().map(({ width, height }) =>
      floor(width / height, 4)
    )
  },

  /**
   * 등록 가능한 이미지만 보기에서 `비즈보드 배너` 노출 조건
   * - 비즈보드 홍보 이미지
   * - 비즈보드 익스펜더블 동영상 맞춤썸네일
   * - 디스플레이 동영상 맞춤썸네일
   */
  isAvailableBizBoardImageOnAvailable({
    campaignType,
    creativeFormat,
    creativeAssetPropertyType,
  }) {
    const isVideo = CreativeFormatEnum.isVideo(creativeFormat)
    const isBizBoard =
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

    const isBizBoardMainImage =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.MAIN_IMAGE

    const isMotionBoardTextImage =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.TEXT_IMAGE

    const isBizBoardExpandableVideoUploadThumbnail =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.VIDEO_IMAGE
    const isDisplayVideoUploadThumbnail =
      isVideo &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.VIDEO_IMAGE

    return (
      isBizBoardMainImage ||
      isBizBoardExpandableVideoUploadThumbnail ||
      isDisplayVideoUploadThumbnail ||
      isMotionBoardTextImage
    )
  },

  /**
   * 이미지 직접 선택하기에서 `비즈보드 배너` 노출 조건
   * - 비즈보드 홍보 이미지
   * - 비즈보드 익스펜더블 동영상 맞춤썸네일
   * - 디스플레이 동영상 맞춤썸네일
   * - 쇼핑박스
   * - 메시지 소재 모든 유형
   */
  isAvailableBizBoardImageOnCustom({
    campaignType,
    creativeFormat,
    creativeAssetPropertyType,
  }) {
    const isVideo = CreativeFormatEnum.isVideo(creativeFormat)
    const isMessage = CreativeFormatEnum.isMessage(creativeFormat)
    const isBizBoard =
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD ||
      campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

    const isBizBoardMainImage =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.MAIN_IMAGE
    const isBizBoardExpandableVideoUploadThumbnail =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.VIDEO_IMAGE
    const isDisplayVideoUploadThumbnail =
      isVideo &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.VIDEO_IMAGE
    const isShoppingBox = creativeFormat === CreativeFormatEnum.Type.IMAGE_BOX
    const isMotionBoardTextImage =
      isBizBoard &&
      creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.TEXT_IMAGE

    return (
      isBizBoardMainImage ||
      isBizBoardExpandableVideoUploadThumbnail ||
      isDisplayVideoUploadThumbnail ||
      isShoppingBox ||
      isMessage ||
      isMotionBoardTextImage
    )
  },
}

CmpAssetLibraryUtils.Editor = CmpAssetLibraryUtils.Recent

CmpAssetLibraryUtils.Upload = {
  filterByConstraint({
    uploadAssets = List(),
    creativeUploadAssetPropertyType,
    uploadSizeConstraints = List(),
  }) {
    switch (creativeUploadAssetPropertyType) {
      case CreativeUploadAssetPropertyEnum.Type.PROFILE_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.BACKGROUND_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.TEXT_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.OBJECT_IMAGE: {
        return uploadSizeConstraints.count() > 0
          ? uploadAssets.filter(({ imageWidth, imageHeight }) =>
              uploadSizeConstraints.some(({ width, height, ratio }) => {
                let isWidthValid = true
                let isHeightValid = true
                let isRatioValid = true

                if (width) {
                  const { min, max, eq } = width

                  if (min >= 0) {
                    isWidthValid = imageWidth >= min
                  }

                  if (eq >= 0) {
                    isWidthValid = imageWidth === eq
                  }

                  if (max >= 0) {
                    isWidthValid = imageWidth === max
                  }
                }

                if (height) {
                  const { min, max, eq } = height

                  if (min >= 0) {
                    isHeightValid = imageHeight >= min
                  }

                  if (eq >= 0) {
                    isHeightValid = imageHeight === eq
                  }

                  if (max >= 0) {
                    isHeightValid = imageHeight <= max
                  }
                }

                const mediaRatio = floor(imageWidth / imageHeight, 4)

                if (ratio) {
                  const { min, max, eq } = ratio

                  if (min >= 0) {
                    isRatioValid = mediaRatio >= min
                  }

                  if (eq >= 0) {
                    isRatioValid = mediaRatio === eq
                  }

                  if (max >= 0) {
                    isRatioValid = mediaRatio <= max
                  }
                }

                return isWidthValid && isHeightValid && isRatioValid
              })
            )
          : uploadAssets
      }

      case CreativeUploadAssetPropertyEnum.Type.VIDEO:
      case CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO: {
        return uploadSizeConstraints.count() > 0
          ? uploadAssets.filter(({ width: videoWidth, height: videoHeight }) =>
              uploadSizeConstraints.some(({ width, height, ratio }) => {
                let isWidthValid = true
                let isHeightValid = true
                let isRatioValid = true

                if (width) {
                  const { min, max, eq } = width

                  if (min >= 0) {
                    isWidthValid = videoWidth >= min
                  }

                  if (eq >= 0) {
                    isWidthValid = videoWidth === eq
                  }

                  if (max >= 0) {
                    isWidthValid = videoWidth === max
                  }
                }

                if (height) {
                  const { min, max, eq } = height

                  if (min >= 0) {
                    isHeightValid = videoHeight >= min
                  }

                  if (eq >= 0) {
                    isHeightValid = videoHeight === eq
                  }

                  if (max >= 0) {
                    isHeightValid = videoHeight <= max
                  }
                }

                const mediaRatio = floor(videoWidth / videoHeight, 4)

                if (ratio) {
                  const { min, max, eq } = ratio

                  if (min >= 0) {
                    isRatioValid = mediaRatio >= min
                  }

                  if (eq >= 0) {
                    isRatioValid = mediaRatio === eq
                  }

                  if (max >= 0) {
                    isRatioValid = mediaRatio <= max
                  }
                }

                return isWidthValid && isHeightValid && isRatioValid
              })
            )
          : uploadAssets
      }

      case CreativeUploadAssetPropertyEnum.Type.OBJET_IMAGE: {
        return uploadAssets.filter(
          ({ imageWidth, imageHeight }) =>
            imageWidth === BIZ_BOARD_OBJET_SPEC.WIDTH &&
            imageHeight === BIZ_BOARD_OBJET_SPEC.HEIGHT
        )
      }

      case CreativeUploadAssetPropertyEnum.Type.ICON_IMAGE: {
        return uploadAssets.filter(
          ({ imageWidth, imageHeight }) =>
            imageWidth === BIZ_BOARD_ICON_SPEC.WIDTH &&
            imageHeight === BIZ_BOARD_ICON_SPEC.HEIGHT
        )
      }

      default:
        return uploadAssets
    }
  },

  guideTooltip({
    creativeFormat,
    campaignType,
    creativeUploadAssetPropertyType,
    sizeConstraintArray,
  }) {
    const sortedSizeConstraintArray =
      CreativeAssetUtils.sortSizeConstraintArray({
        sizeConstraintArray,
      })

    switch (creativeUploadAssetPropertyType) {
      case CreativeUploadAssetPropertyEnum.Type.IMAGE:
        switch (creativeFormat) {
          case CreativeFormatEnum.Type.IMAGE_NATIVE:
          case CreativeFormatEnum.Type.CATALOG_MANUAL:
          case CreativeFormatEnum.Type.IMAGE_BOX:
          case CreativeFormatEnum.Type.VIDEO_NATIVE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes:
                sortedSizeConstraintArray.map(
                  ({ width, height, minWidth, ratio }) => {
                    return {
                      imageSize: `${getCreativeImageSizeText({
                        width,
                        height,
                        minWidth,
                        ratio,
                      })}`,
                      fileSize: `${getCreativeImageFileSizeKB({
                        width,
                        height,
                        minWidth,
                        ratio,
                      })}KB`,
                    }
                  }
                ) || [],
            }
          case CreativeFormatEnum.Type.RICH_NATIVE:
            if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
              return {
                formats: ['JPG, JPEG, PNG'],
                sizes:
                  sortedSizeConstraintArray.map(
                    ({ width, height, minWidth, ratio }) => {
                      return {
                        imageSize: `${getCreativeImageSizeText({
                          width,
                          height,
                          minWidth,
                          ratio,
                        })}`,
                        fileSize: `${getCreativeImageFileSizeKB({
                          width,
                          height,
                          minWidth,
                          ratio,
                        })}KB`,
                      }
                    }
                  ) || [],
                extras: ['투명배경의 이미지 등록 불가(심사 반려)'],
              }
            } else {
              return {
                formats: ['JPG, JPEG, PNG'],
                sizes:
                  sortedSizeConstraintArray.map(
                    ({ width, height, minWidth, ratio }) => {
                      return {
                        imageSize: `${getCreativeImageSizeText({
                          width,
                          height,
                          minWidth,
                          ratio,
                        })}`,
                        fileSize: `${getCreativeImageFileSizeKB({
                          width,
                          height,
                          minWidth,
                          ratio,
                        })}KB`,
                      }
                    }
                  ) || [],
              }
            }
          case CreativeFormatEnum.Type.IMAGE_BANNER:
            return {
              formats: ['PNG-24 이상'],
              sizes:
                sortedSizeConstraintArray.map(
                  ({ width, height, minWidth, ratio }) => {
                    return {
                      imageSize: `${getCreativeImageSizeText({
                        width,
                        height,
                        minWidth,
                        ratio,
                      })}`,
                      fileSize: `${getCreativeImageFileSizeKB({
                        width,
                        height,
                        minWidth,
                        ratio,
                      })}KB`,
                    }
                  }
                ) || [],
            }

          case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes: [
                {
                  imageSize: '800x400(2:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '800x800(1:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '800x600(4:3 비율) 권장',
                  fileSize: '10MB',
                },
              ],
              extras: ['가로 80px 이상', ' 가로 세로 비율이 1:2.5 이하'],
            }

          case CreativeFormatEnum.Type.WIDE_MESSAGE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes: [
                {
                  imageSize: '800x600(4:3 비율) 권장',
                  fileSize: '10MB',
                },
              ],
              extras: ['가로 80px 이상', '가로 세로 비율이 1:2.5 이하'],
            }

          case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes: [
                {
                  imageSize: '리스트1: 800x400(2:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '리스트2~4: 800x800(1:1 비율) 권장',
                  fileSize: '10MB',
                },
              ],
              extras: ['가로 80px 이상', '가로 세로 비율이 1:2.5 이하'],
            }

          case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes: [
                {
                  imageSize: '800x400(2:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '800x800(1:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '800x600(4:3 비율) 권장',
                  fileSize: '10MB',
                },
              ],
              extras: [
                '가로 80px 이상',
                '가로 세로 비율이 1:2.5 이하',
                '캐러셀형 소재에 최소 1개, 최대 6개의 이미지 설정 가능',
                '복수 개의 이미지 입력 시 같은 비율 설정 권장',
              ],
            }

          case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
            return {
              formats: ['JPG, JPEG, PNG'],
              sizes: [
                {
                  imageSize: '800x400(2:1 비율) 권장',
                  fileSize: '10MB',
                },
                {
                  imageSize: '800x600(4:3 비율) 권장',
                  fileSize: '10MB',
                },
              ],
              extras: [
                '가로 80px 이상',
                '가로 세로 비율이 1:2.5 이하',
                '캐러셀형 소재에 최소 2개, 최대 6개의 이미지 설정 가능',
                '복수 개의 이미지 입력 시 같은 비율 설정 권장',
              ],
            }

          default:
            return {
              formats: [],
              sizes: [],
              extras: [],
            }
        }

      case CreativeUploadAssetPropertyEnum.Type.OBJET_IMAGE:
        return {
          formats: ['PNG'],
          sizes: [
            {
              imageSize: [
                BIZ_BOARD_OBJET_SPEC.WIDTH,
                BIZ_BOARD_OBJET_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: BIZ_BOARD_OBJET_SPEC.WIDTH,
                height: BIZ_BOARD_OBJET_SPEC.HEIGHT,
              })}KB`,
            },
          ],
          extras: [],
        }

      case CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: `${[
                BIZ_BOARD_SINGLE_THUMBNAIL_SPEC.WIDTH,
                BIZ_BOARD_SINGLE_THUMBNAIL_SPEC.HEIGHT,
              ].join('x')} 이상`,
              fileSize: '10MB',
            },
          ],
          extras: ['투명 배경 이미지 사용 불가'],
        }

      case CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE_MULTI:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: `${[
                BIZ_BOARD_MULTI_OBJET_SPEC.WIDTH,
                BIZ_BOARD_MULTI_OBJET_SPEC.HEIGHT,
              ].join('x')} 이상`,
              fileSize: '10MB',
            },
          ],
          extras: ['투명 배경 이미지 사용 불가'],
        }

      case CreativeUploadAssetPropertyEnum.Type.THUMBNAIL_IMAGE_BLUR:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: `${[
                BIZ_BOARD_OBJET_SPEC.WIDTH,
                BIZ_BOARD_OBJET_SPEC.HEIGHT,
              ].join('x')} 이상`,
              fileSize: '10MB',
            },
          ],
          extras: ['투명 배경 이미지 사용 불가', '360x258 이상 권장'],
        }

      case CreativeUploadAssetPropertyEnum.Type.MASKING_IMAGE:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: `${[
                BIZ_BOARD_OBJET_SPEC.WIDTH,
                BIZ_BOARD_OBJET_SPEC.HEIGHT,
              ].join('x')} 이상`,
              fileSize: '10MB',
            },
          ],
          extras: ['투명 배경 이미지 사용 불가', '360x258 이상 권장'],
        }

      case CreativeUploadAssetPropertyEnum.Type.LOGO_IMAGE:
        return {
          formats: ['PNG-24'],
          sizes: [
            {
              imageSize: '제약 없음',
              fileSize: '50KB',
            },
          ],
        }

      case CreativeUploadAssetPropertyEnum.Type.ICON_IMAGE:
        return {
          formats: ['PNG'],
          sizes: [
            {
              imageSize: [
                BIZ_BOARD_ICON_SPEC.WIDTH,
                BIZ_BOARD_ICON_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: BIZ_BOARD_ICON_SPEC.WIDTH,
                height: BIZ_BOARD_ICON_SPEC.HEIGHT,
              })}KB`,
            },
          ],
        }

      case CreativeUploadAssetPropertyEnum.Type.PROFILE_IMAGE:
        if (CampaignTypeEnum.isFullViewType(campaignType)) {
          return {
            formats: ['JPG, JPEG, PNG'],
            sizes: [
              {
                imageSize: `${getCreativeImageSizeText({
                  minWidth: CREATIVE_IMAGE_PROFILE_SPEC.WIDTH,
                  ratio: 1,
                })}`,
                fileSize: `${getCreativeImageFileSizeKB({
                  minWidth: CREATIVE_IMAGE_PROFILE_SPEC.WIDTH,
                  ratio: 1,
                })}KB`,
              },
            ],
            extras: ['투명배경의 이미지 등록 불가'],
          }
        } else {
          return {
            formats: ['JPG, JPEG, PNG'],
            sizes: [
              {
                imageSize: `${getCreativeImageSizeText({
                  minWidth: CREATIVE_IMAGE_PROFILE_SPEC.WIDTH,
                  ratio: 1,
                })}`,
                fileSize: `${getCreativeImageFileSizeKB({
                  minWidth: CREATIVE_IMAGE_PROFILE_SPEC.WIDTH,
                  ratio: 1,
                })}KB`,
              },
            ],
            extras: [],
          }
        }

      case CreativeUploadAssetPropertyEnum.Type.VIDEO:
        if (CreativeFormatEnum.isMessage(creativeFormat)) {
          return {
            formats: ['AVI, FLV, MP4 권장'],
            sizes: [
              {
                imageSize: `가로 80px 이상의 2:1~3:4 비율 권장`,
                fileSize: `1GB`,
              },
            ],
            extras: [
              '한 번에 1개만 업로드 가능',
              '동영상 3초 이상 업로드 가능',
            ],
          }
        } else if (creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE) {
          if (CampaignTypeEnum.isFullViewType(campaignType)) {
            return {
              formats: ['AVI, FLV, MP4 권장'],
              sizes: [
                {
                  imageSize: `${[
                    FULL_VIEW_SPEC.WIDTH,
                    FULL_VIEW_SPEC.HEIGHT,
                  ].join('x')} 이상의 9:16 비율`,
                  fileSize: `1GB`,
                },
              ],
              extras: [
                '한 번에 1개만 업로드 가능',
                '동영상 3초 이상 업로드 가능',
              ],
            }
          } else {
            return {
              formats: ['AVI, FLV, MP4 권장'],
              sizes: [
                {
                  imageSize: [
                    PC_TALK_BOTTOM_SPEC.WIDTH,
                    PC_TALK_BOTTOM_SPEC.HEIGHT,
                  ].join('x'),
                  fileSize: `20MB`,
                },
              ],
              extras: ['동영상 3초만 업로드 가능', '업로드 동영상은 무음처리'],
            }
          }
        } else {
          return {
            formats: ['AVI, FLV, MP4 권장'],
            sizes:
              sortedSizeConstraintArray.map(
                ({ width, height, minWidth, ratio }) => {
                  return {
                    imageSize: `${getCreativeImageSizeText({
                      width,
                      height,
                      minWidth,
                      ratio,
                    })}`,
                    fileSize: `${formatBytes({
                      bytes: CreativeAssetUtils.Video.fileSizeMax,
                    })}`,
                  }
                }
              ) || [],
            extras: [
              '한 번에 1개만 업로드 가능',
              '동영상 3초 이상 업로드 가능',
            ],
          }
        }

      case CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO:
        return {
          formats: ['AVI, FLV, MP4 권장'],
          sizes: [
            {
              imageSize: [
                MOTION_BOARD_SPEC.WIDTH,
                MOTION_BOARD_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `20MB`,
            },
          ],
          extras: ['동영상 3초만 업로드 가능', '업로드 동영상은 무음처리'],
        }
      case CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO_IMAGE:
      case CreativeUploadAssetPropertyEnum.Type.BACKGROUND_IMAGE:
        if (creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE) {
          return {
            formats: ['JPG, JPEG, PNG'],
            sizes: [
              {
                imageSize: [
                  PC_TALK_BOTTOM_SPEC.WIDTH,
                  PC_TALK_BOTTOM_SPEC.HEIGHT,
                ].join('x'),
                fileSize: `${getCreativeImageFileSizeKB({
                  width: PC_TALK_BOTTOM_SPEC.WIDTH,
                  height: PC_TALK_BOTTOM_SPEC.HEIGHT,
                })}KB`,
              },
            ],
            extras: ['최대 30개의 이미지를 한번에 등록 가능'],
          }
        }
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: [
                MOTION_BOARD_SPEC.WIDTH,
                MOTION_BOARD_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: MOTION_BOARD_SPEC.WIDTH,
                height: MOTION_BOARD_SPEC.HEIGHT,
              })}KB`,
            },
          ],
        }
      case CreativeUploadAssetPropertyEnum.Type.TEXT_IMAGE: {
        if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
          return {
            formats: ['PNG'],
            sizes: [
              {
                imageSize: `960x126`,
                fileSize: `500KB`,
              },
            ],
            extras: ['투명배경의 텍스트로만 구성 가능'],
          }
        } else {
          return {
            formats: ['PNG-24'],
            sizes: [
              {
                imageSize: [BIZ_BOARD_SPEC.WIDTH, BIZ_BOARD_SPEC.HEIGHT].join(
                  'x'
                ),
                fileSize: `${getCreativeImageFileSizeKB({
                  width: BIZ_BOARD_SPEC.WIDTH,
                  height: BIZ_BOARD_SPEC.HEIGHT,
                })}KB`,
              },
            ],
            extras: ['투명배경의 텍스트로만 구성 가능'],
          }
        }
      }

      case CreativeUploadAssetPropertyEnum.Type.OBJECT_IMAGE:
        if (creativeFormat === CreativeFormatEnum.Type.RICH_NATIVE) {
          return {
            formats: ['PNG-24'],
            sizes: [
              {
                imageSize: [
                  PC_TALK_BOTTOM_SPEC.WIDTH,
                  PC_TALK_BOTTOM_SPEC.HEIGHT,
                ].join('x'),
                fileSize: `${getCreativeImageFileSizeKB({
                  width: PC_TALK_BOTTOM_SPEC.WIDTH,
                  height: PC_TALK_BOTTOM_SPEC.HEIGHT,
                })}KB`,
              },
            ],
            extras: ['투명배경의 이미지만 가능'],
          }
        }
        return {
          formats: ['PNG-24'],
          sizes: [
            {
              imageSize: [
                BIZ_BOARD_OBJET_SPEC.WIDTH,
                BIZ_BOARD_OBJET_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: BIZ_BOARD_OBJET_SPEC.WIDTH,
                height: BIZ_BOARD_OBJET_SPEC.HEIGHT,
              })}KB`,
            },
          ],
          extras: ['투명배경의 이미지만 가능'],
        }

      case CreativeUploadAssetPropertyEnum.Type.VIDEO_IMAGE:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: [
                PC_TALK_BOTTOM_SPEC.WIDTH,
                PC_TALK_BOTTOM_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: PC_TALK_BOTTOM_SPEC.WIDTH,
                height: PC_TALK_BOTTOM_SPEC.HEIGHT,
              })}KB`,
            },
          ],
        }

      case CreativeUploadAssetPropertyEnum.Type.BANNER_IMAGE:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: [
                PC_TALK_RICH_POP_SPEC.WIDTH,
                PC_TALK_RICH_POP_SPEC.HEIGHT,
              ].join('x'),
              fileSize: `${getCreativeImageFileSizeKB({
                width: PC_TALK_RICH_POP_SPEC.WIDTH,
                height: PC_TALK_RICH_POP_SPEC.HEIGHT,
              })}KB`,
            },
          ],
        }

      case CreativeUploadAssetPropertyEnum.Type.COMPATIBLE_IMAGE:
        return {
          formats: ['JPG, JPEG, PNG'],
          sizes: [
            {
              imageSize: ['1200x600 이상의 2:1 비율'],
              fileSize: `500KB`,
            },
          ],
        }

      default:
        return {
          formats: [],
          sizes: [],
          extras: [],
        }
    }
  },
}

CmpAssetLibraryUtils.reviewGuideUrl = ({
  campaignType,
  creativeFormat,
  creativeUploadAssetPropertyType,
  isMotionBoard = false,
  isCustomBoard = false,
}) => {
  switch (creativeFormat) {
    case CreativeFormatEnum.Type.IMAGE_BANNER: {
      const isBizBoard = [
        CampaignTypeEnum.Type.TALK_BIZ_BOARD,
        CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      ].includes(campaignType)
      const isExpandableVideo =
        isBizBoard &&
        creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.VIDEO
      const isExpandableImage =
        isBizBoard &&
        creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_IMAGE

      const isExpandableMultiImage =
        isBizBoard &&
        creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_MULTI

      const isCPT =
        campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED

      if (isMotionBoard) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/cpt/motion/content-guide'
      }

      if (isCustomBoard) {
        return `https://kakaobusiness.gitbook.io/main/ad/moment/start/cpt/custom/content-guide`
      }

      if (isExpandableVideo) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/talkboard/content-guide#2-7-1'
      }

      if (isExpandableImage) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/talkboard/content-guide#2-7-2'
      }

      if (isExpandableMultiImage) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/talkboard/content-guide#2-7-3'
      }

      return `https://kakaobusiness.gitbook.io/main/ad/moment/start/${
        isCPT ? `cpt` : `talkboard`
      }/content-guide`
    }

    case CreativeFormatEnum.Type.IMAGE_NATIVE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/displayad/6-24#1-greater-than'
    }

    case CreativeFormatEnum.Type.CATALOG_MANUAL: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/displayad/6-24#2-greater-than'
    }

    case CreativeFormatEnum.Type.VIDEO_NATIVE: {
      if (
        [
          CreativeUploadAssetPropertyEnum.Type.IMAGE,
          CreativeUploadAssetPropertyEnum.Type.PROFILE_IMAGE,
        ].includes(creativeUploadAssetPropertyType)
      ) {
        return `https://kakaobusiness.gitbook.io/main/ad/moment/start/videoad/content-guide#1-2.`
      }
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/videoad/content-guide'
    }

    case CreativeFormatEnum.Type.IMAGE_BOX: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/daumshop/content-guide'
    }

    case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-5.'
    }

    case CreativeFormatEnum.Type.WIDE_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-1.'
    }

    case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-2.'
    }

    case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-3.'
    }

    case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-4.'
    }

    case CreativeFormatEnum.Type.CATALOG_DYNAMIC: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/catalog/content-guide'
    }

    case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/messagead/content-guide#1-6'
    }

    case CreativeFormatEnum.Type.RICH_NATIVE: {
      if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/brandingdisplay/focusboard/content-guide'
      } else if (campaignType === CampaignTypeEnum.Type.FOCUS_FULL_VIEW) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/focusfullview/content-guide'
      } else if (campaignType === CampaignTypeEnum.Type.PROFILE_FULL_VIEW) {
        return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/profilefullview/content-guide'
      }
      return 'https://kakaobusiness.gitbook.io/main/ad/moment/start/brandingdisplay/richpop/content-guide'
    }

    default: {
      return undefined
    }
  }
}

CmpAssetLibraryUtils.isImageSizeValid = ({
  sizeConstraints,
  originWidth,
  originHeight,
  croppedWidth,
  croppedHeight,
}) => {
  if (!sizeConstraints || sizeConstraints.isEmpty()) return true

  return sizeConstraints.some(({ width, height, ratio }) => {
    const targetWidth = croppedWidth ?? originWidth
    const targetHeight = croppedHeight ?? originHeight
    const targetRatio = floor(targetWidth / targetHeight, 4)

    const isDimensionEQ =
      width?.get('eq') > 0 &&
      height?.get('eq') > 0 &&
      width?.get('eq') === targetWidth &&
      height?.get('eq') === targetHeight

    const isWidthGTE = width?.get('min') > 0 && targetWidth >= width?.get('min')
    const isHeightGTE =
      height?.get('min') > 0 && targetHeight >= height?.get('min')
    const isRatioEQ = ratio?.get('eq') > 0 && targetRatio === ratio?.get('eq')
    const isRatioGTE = ratio?.get('min') > 0 && targetRatio >= ratio?.get('min')

    const isDimensionValid = isDimensionEQ
    const isMinWidthAndRatioValid = isWidthGTE && (isRatioEQ || isRatioGTE)
    const isMinHeightAndRatioValid = isHeightGTE && (isRatioEQ || isRatioGTE)

    return (
      isDimensionValid || isMinWidthAndRatioValid || isMinHeightAndRatioValid
    )
  })
}

CmpAssetLibraryUtils.getAvailableRatioValues = ({
  creativeFormat,
  creativeAssetPropertyType,
  specificRatio,
}) => {
  switch (creativeFormat) {
    case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_MESSAGE:
    case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
    case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
      return [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
        { width: 4, height: 3 },
        { width: 16, height: 9 },
      ]
    }

    case CreativeFormatEnum.Type.IMAGE_NATIVE:
      if (
        creativeAssetPropertyType ===
        CreativeAssetPropertyEnum.Type.PROFILE_IMAGE
      ) {
        return [{ width: 1, height: 1 }]
      } else if (
        creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.MAIN_IMAGE
      ) {
        if (specificRatio === 2) {
          return [{ width: 2, height: 1 }]
        }
        return [
          { width: 1, height: 1 },
          { width: 2, height: 1 },
          { width: 9, height: 16 },
        ]
      } else {
        return []
      }
    case CreativeFormatEnum.Type.CATALOG_MANUAL:
      return [{ width: 1, height: 1 }]

    case CreativeFormatEnum.Type.VIDEO_NATIVE:
      if (specificRatio === 1.7777) {
        return [{ width: 16, height: 9 }]
      } else if (specificRatio === 0.5625) {
        return [{ width: 9, height: 16 }]
      }
      return [{ width: 1, height: 1 }]

    case CreativeFormatEnum.Type.RICH_NATIVE: {
      if (
        creativeAssetPropertyType ===
        CreativeAssetPropertyEnum.Type.PROFILE_IMAGE
      ) {
        return [{ width: 1, height: 1 }]
      } else if (
        creativeAssetPropertyType ===
          CreativeAssetPropertyEnum.Type.VIDEO_IMAGE ||
        creativeAssetPropertyType === CreativeAssetPropertyEnum.Type.MAIN_IMAGE
      ) {
        return [{ width: 9, height: 16 }]
      } else return [{ width: 2, height: 1 }]
    }

    default:
      return [
        { width: 1, height: 1 },
        { width: 2, height: 1 },
        { width: 4, height: 3 },
        { width: 16, height: 9 },
      ]
  }
}

CmpAssetLibraryUtils.getTranscodeType = ({
  campaignType,
  creativeUploadAssetPropertyType,
}) => {
  if (campaignType === CampaignTypeEnum.Type.PC_TALK_BOTTOM) {
    return TranscodeTypeEnum.Type.PC_TALK_BOTTOM
  }
  if (
    campaignType === CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED &&
    creativeUploadAssetPropertyType ===
      CreativeUploadAssetPropertyEnum.Type.MOTION_BOARD_VIDEO
  ) {
    return TranscodeTypeEnum.Type.MOTION_BOARD
  }
  return TranscodeTypeEnum.Type.DEFAULT
}

export {
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
  CMP_ASSET_LIBRARY_FILTER_TYPE,
  CMP_ASSET_LIBRARY_FILTER_OPTION,
  CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT,
  CMP_ASSET_LIBRARY_CANCEL_KEY_EDITOR,
  CmpAssetLibraryUtils,
}
