import {
  CONTEXTPATH,
  fetchGet,
  fetchPost,
  fetchPut,
} from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'

/**
 * 4.2.1 캠페인 정보 조회
 */
function fetchCampaignInfoById(adAccountId, campaignId, cancelTokenSource) {
  return fetchGet(
    `${CONTEXTPATH}/campaigns/${campaignId}`,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 4.2.7.1 캠페인 생성 validation
 */
function validateCampaignOnCreate(adAccountId, formData) {
  return fetchPost(
    `${CONTEXTPATH}/campaigns/validateOnCreate`,
    formData,
    adAccountId
  )
}

/**
 * 4.2.8 캠페인 수정
 */
function modifyCampaign(adAccountId, campaignId, formData) {
  return fetchPut(
    `${CONTEXTPATH}/campaigns/${campaignId}`,
    formData,
    adAccountId
  )
}

/**
 * 4.2.15 캠페인 광고대상 중 카카오톡 채널 프로필 조회
 */
function fetchPlusFriendProfilesInCampaign(adAccountId, campaignId) {
  return fetchGet(
    `${CONTEXTPATH}/campaigns/${campaignId}/plusFriend/profiles`,
    adAccountId
  )
}

/**
 * 4.2.19 광고그룹 복사 가능한 캠페인 리스트 조회
 */
function fetchCopyableCampaigns(adAccountId, id, cancelTokenSource) {
  return fetchGet(
    `${CONTEXTPATH}/campaigns/${id}/copyAvailables`,
    adAccountId,
    cancelTokenSource
  )
}

function copyAdGroups(adAccountId, body, cancelTokenSource) {
  return RequestLock.acquire({
    key: 'copyAdGroups',
    executor: done =>
      fetchPost(
        `${CONTEXTPATH}/adGroups/copy/bulk`,
        body,
        adAccountId,
        cancelTokenSource
      ).finally(done),
  })
}

/**
 * 캠페인 관리자정지 사유 조회
 */
function fetchCampaignAdminStopHistory(adAccountId, id) {
  return fetchGet(
    `${CONTEXTPATH}/campaigns/${id}/systemConfigHistories?systemConfig=ADMIN_STOP,EXTERNAL_SERVICE_STOP`,
    adAccountId
  )
}

/**
 * 비즈보드 CPT 캠페인 하위 계약정보 조회
 * @param adAccountId
 * @param productId
 */
function fetchAdProductContract(adAccountId, productId) {
  return fetchGet(
    `${CONTEXTPATH}/contracts/availables?productId=${productId}`,
    adAccountId
  )
}

/**
 * 계약 상세 조회
 * @param adAccountId
 * @param contractId
 */
function fetchAdProductContractById(adAccountId, contractId) {
  return fetchGet(`${CONTEXTPATH}/contracts/${contractId}`, adAccountId)
}

/**
 * 광고계정 기준 이벤트 무상캐시 조회
 * @param adAccountId
 * @param campaignType
 * @returns {null}
 */
function fetchEventFreeCash(adAccountId, campaignType) {
  return fetchGet(
    `${CONTEXTPATH}/eventFreeCash?campaignType=${campaignType}`,
    adAccountId
  )
}

export default {
  fetchCampaignInfoById,
  modifyCampaign,
  fetchPlusFriendProfilesInCampaign,
  validateCampaignOnCreate,
  fetchCopyableCampaigns,
  copyAdGroups,
  fetchCampaignAdminStopHistory,
  fetchAdProductContract,
  fetchAdProductContractById,
  fetchEventFreeCash,
}
