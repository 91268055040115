import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

/**
 *
 * @param formats ['format', 'text',...]
 * @param sizes [{ imageSize: 'imageSize', fileSize: 'fileSize' },...]
 * @param extras ['extra', 'text',...]
 * @param fileSizeClassName
 */
const CreativeGuideTooltip = ({
  formats = [],
  sizes = [],
  extras = [],
  fileSizeClassName = '',
}) => {
  return (
    <Fragment>
      {formats.length > 0 && (
        <Fragment>
          <strong className="reform_tit_filesize">파일형식</strong>
          <ul className="reform_list_filesize">
            {formats.map((format, i) => (
              <li key={i}>{format}</li>
            ))}
          </ul>
        </Fragment>
      )}
      {sizes.length > 0 && (
        <Fragment>
          <strong className="reform_tit_filesize">사이즈/용량</strong>
          <ul className="reform_list_filesize">
            {sizes.map((size, i) => {
              const { imageSize, fileSize } = size
              return (
                <li key={i}>
                  <p className={`reform_desc_filesize ${fileSizeClassName}`}>
                    <span className="screen_out">사이즈</span>
                    {imageSize}
                  </p>
                  <p className="reform_desc_filekb">
                    <span className="screen_out">용량</span>
                    {fileSize}
                  </p>
                </li>
              )
            })}
          </ul>
        </Fragment>
      )}
      {extras.length > 0 && (
        <Fragment>
          <strong className="reform_tit_filesize">참고사항</strong>
          <ul className="reform_list_filesize">
            {extras.map((extra, i) => (
              <li key={i}>· {extra}</li>
            ))}
          </ul>
        </Fragment>
      )}
    </Fragment>
  )
}

CreativeGuideTooltip.propTypes = {
  formats: PropTypes.array,
  sizes: PropTypes.array,
  extras: PropTypes.array,
  fileSizeClassName: PropTypes.string,
}

export { CreativeGuideTooltip }
