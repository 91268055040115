import {
  coerceAtMost,
  convertToCommaSeparatedString,
  keyMirror,
} from '../utils'
import { checkNotEmpty } from '../regexUtils'
import { List } from 'immutable'

export const DEMOGRAPHIC_TARGET_GENDER = keyMirror({
  M: null,
  F: null,
})

export const DEMOGRAPHIC_TARGET_GENDER_TEXT = {
  M: '남성',
  F: '여성',
}

export const LOCATION_TYPE = keyMirror({
  ALL: null,
  DISTRICT: null,
})

export const LOCATION_TYPE_TEXT = {
  ALL: '전체',
  DISTRICT: '행정구역',
}

export const LOCATION_DISTRICT_MAP_TEXT = {
  depth1: '시/도',
  depth2: '시/군/구',
  depth3: '동/읍/면',
}

export const LOCATION_DETAIL_TYPE = keyMirror({
  DOMESTIC: null,
  OVERSEAS: null,
})

export const LOCATION_DETAIL_TYPE_TEXT = {
  DOMESTIC: '국내',
  OVERSEAS: '해외',
}

/**
 * Z를 제외한 전체 리스트: 전국
 * null: 전국 + 해외
 */
export const LOCATION_KEY = keyMirror({
  U: null, // 알 수 없음 (전국 or 전국+해외 일 때만 추가 됨)
  I: null, // 서울
  K: null, // 인천
  B: null, // 경기
  A: null, // 강원
  G: null, // 대전 (대전 + 세종을 하나의 세그먼트로 관리)
  Q: null, // 세종 (대전 + 세종을 하나의 세그먼트로 관리)
  P: null, // 충북
  O: null, // 충남
  E: null, // 광주
  M: null, // 전북
  L: null, // 전남
  F: null, // 대구
  J: null, // 울산
  D: null, // 경북
  C: null, // 경남
  H: null, // 부산
  N: null, // 제주
  Z: null, // 해외
})

export const LOCATION_KEY_TEXT = {
  U: '알 수 없음',
  I: '서울특별시',
  K: '인천광역시',
  B: '경기도',
  A: '강원도',
  G: '대전광역시',
  Q: '세종특별자치시',
  P: '충청북도',
  O: '충청남도',
  E: '광주광역시',
  M: '전라북도',
  L: '전라남도',
  F: '대구광역시',
  J: '울산광역시',
  D: '경상북도',
  C: '경상남도',
  H: '부산광역시',
  N: '제주특별자치도',
  Z: '해외',
}

export const MY_DATA_TARGET_KEY = keyMirror({
  cohortTargetings: null,
  trackerTargetings: null,
  plusFriendTargetings: null,
  syncAppTargetings: null,
  customerFileTargetings: null,
  plusFriendGroupTargetings: null,
})

export const ADDITIONAL_TARGET_KEY = keyMirror({
  ufoInterests: null,
  ufoBusinessTypes: null,
})

export const MY_DATA_TARGET_TEXT = {
  cohortTargetings: '광고반응타겟',
  trackerTargetings: '픽셀 & SDK 타겟',
  plusFriendTargetings: '카카오 사용자 - 카카오톡 채널 친구',
  syncAppTargetings: '카카오 사용자 - 카카오 로그인 이용자',
  customerFileTargetings: '고객파일',
  plusFriendGroupTargetings: '친구그룹',
}

export const CATEGORY_TARGET_KEY = keyMirror({
  business: null,
  interests: null,
})

export const ADDITIONAL_DATA_TARGET_TEXT = {
  interests: '관심사',
  ufoInterests: '관심사',
  business: '업종',
  ufoBusinessTypes: '업종',
}

export const CATEGORY_TARGET_FORM_MAPPER = {
  interests: 'ufoInterests',
  business: 'ufoBusinessTypes',
}

export const KAKAO_DATA_UFO_TARGET_KEYS = ['ufoInterests', 'ufoBusinessTypes']

// 채널X도달 광고그룹 phase별 밸리데이션 기준 https://jira.daumkakao.com/browse/KAMOQA-10988
export const COMBINED_POPULATION_SCORE_MIN =
  __LOCAL__ || __DEV__ || __SANDBOX__ ? 0 : 10
export const COMBINED_PF_GROUP_POPULATION_SCORE_MIN =
  __LOCAL__ || __DEV__ || __SANDBOX__ ? 0 : 10
export const COMBINED_POPULATION_MAX_SCORE = 50 * 1000 * 1000

export const isEnoughPfGroupPopulation = score =>
  score > COMBINED_PF_GROUP_POPULATION_SCORE_MIN

/**
 * 모수 View text.
 */
export const getPopulationAdditionalString = score => {
  return Number(score) >= COMBINED_POPULATION_MAX_SCORE ? '이상' : '이하'
}

/**
 * *** 이 패턴은 절대로 바뀌면 안된다 ***
 * Tracker Targeting value. 개별 모수 조회 시 key 로 쓰인다.
 * - 'trackingCode|||trackerId|||event'
 * - 이미 이 패턴으로 데이터가 쌓여있음.
 */
export const createPixelS2Value = ({ trackId, eventCode, trackRuleId }) =>
  `${trackId}|||${eventCode}|||${trackRuleId}`

export const getPopulationScoreText = (adType, combinedPopulation) => {
  if (checkNotEmpty(combinedPopulation)) {
    const { populationScore } = combinedPopulation
    // 실패 시 -1
    if (populationScore > 0) {
      return convertToCommaSeparatedString(
        coerceAtMost(populationScore, COMBINED_POPULATION_MAX_SCORE)
      )
    }
  }

  return '-'
}

export const hasMyDataTargeting = (targeting = List()) => {
  const {
    plusFriendTargetings,
    syncAppTargetings,
    customerFileTargetings,
    cohortTargetings,
    trackerTargetings,
  } = targeting

  return [
    plusFriendTargetings,
    syncAppTargetings,
    customerFileTargetings,
    cohortTargetings,
    trackerTargetings,
  ].some(v => v?.length > 0)
}

export const getMyDataTargetingValue = (targeting = List()) => {
  const sortBaseArray = [
    'cohortTargetings',
    'trackerTargetings',
    'plusFriendTargetings',
    'syncAppTargetings',
    'customerFileTargetings',
    'plusFriendGroupTargetings',
  ]

  return targeting
    .filter((_, k) => Object.keys(MY_DATA_TARGET_KEY).includes(k))
    .filter(v => v?.size > 0)
    .keySeq()
    .sort((v1, v2) => sortBaseArray.indexOf(v1) - sortBaseArray.indexOf(v2))
    .map(t => MY_DATA_TARGET_TEXT[t])
}

export const getAdditionalDataTargetingValue = (targeting = List()) => {
  const sortBaseArray = ['ufoInterests', 'ufoBusinessTypes']

  return targeting
    .filter((_, k) => sortBaseArray.includes(k))
    .filter(v => v?.size > 0)
    .keySeq()
    .sort((v1, v2) => sortBaseArray.indexOf(v1) - sortBaseArray.indexOf(v2))
    .map(t => ADDITIONAL_DATA_TARGET_TEXT[t])
}
