import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { PreferenceHelper } from '../../utils/helper/helper-preference'
import moment from 'moment'
import { loungeBoardAPI } from '../../modules-api/notice/noticeApi'

const Notice = keyMirror({
  HAS_NEW_NOTICE: null,
  GET_NOTICE_LIST: null,
  GET_NOTICE_BANNER: null,
})

export const SERVICE_TYPE = keyMirror({
  MOMENT: null,
  AGENCY: null,
  ADMIN: null,
  REVIEW: null,
  OPEN_API: null,
})

// initial state
const initialState = fromJS({
  hasNewNotice: false,

  noticeList: {
    list: [],
    totalCount: 0,
    number: 0,
  },

  // 띠배너
  noticeBanner: {
    code: '',
    data: {
      id: -1,
      title: '',
    },
  },
})

// reducer
export default createReducer(initialState, {
  [Notice.HAS_NEW_NOTICE]: (state, { result }) =>
    state.set('hasNewNotice', result),

  [Notice.GET_NOTICE_LIST]: (state, { result }) =>
    state.set(
      'noticeList',
      result ? fromJS(result) : initialState.get('noticeList')
    ),

  [Notice.GET_NOTICE_BANNER]: (state, { result }) => {
    return state.set(
      'noticeBanner',
      result.data ? fromJS(result) : initialState.get('noticeBanner')
    )
  },
})

export function receiveNoticeList(result) {
  return {
    type: Notice.GET_NOTICE_LIST,
    result,
  }
}

export function receiveNoticeBanner(result) {
  return {
    type: Notice.GET_NOTICE_BANNER,
    result,
  }
}

export const receiveHasNewNotice = result => ({
  type: Notice.HAS_NEW_NOTICE,
  result,
})

export function fetchInitGnbNoticeList(dspAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const noticeBannerResponse = await loungeBoardAPI.getNoticeBanner()

      dispatch(receiveNoticeBanner(noticeBannerResponse?.data))
      const noticeListResponse = await loungeBoardAPI.getNoticeList()
      dispatch(receiveNoticeList(noticeListResponse?.data || {}))

      const preferenceResponse = await api.user.getDspAccountPreference(
        dspAccountId
      )
      const { lastDate: lastSeenDate } = PreferenceHelper.initialize(
        preferenceResponse.data.preferenceData,
        ['lastDate']
      )

      if (noticeListResponse.data?.list?.length > 0) {
        if (lastSeenDate) {
          const lastDate = noticeListResponse.data?.list?.reduce(
            (prev, curr) => {
              return moment(prev.createdAt).isAfter(moment(curr.createdAt))
                ? prev
                : curr
            }
          ).createdAt

          dispatch(
            receiveHasNewNotice(moment(lastDate).isAfter(moment(lastSeenDate)))
          )
        } else {
          dispatch(
            receiveHasNewNotice(
              noticeListResponse.data?.list?.some(data => data.isNew)
            )
          )
        }
      }
    } catch (e) {
      console.log(e)
    }
  }
}
