import { combineReducers } from 'redux-immutablejs'

import filter from './mReportFilter'
import calendar from './mReportCalendar'
import common from './mReportCommon'
import table from './mReportTable'
import detail from './mReportDetail'
import download from './mReportDownload'

export default combineReducers({
  filter,
  calendar,
  common,
  table,
  detail,
  download,
})
