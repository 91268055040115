import {
  convertToCommaSeparatedString,
  keyMirror,
} from '../../../../utils/utils'
import DashboardEnumV2 from '../../../../enums/DashboardEnumV2'
import DashboardMetricGroupEnumV2 from '../../../../enums/DashboardMetricGroupEnumV2'
import {
  DashboardTableMetric,
  DashboardTableMetricIds,
} from '../../../../utils/dashboard/dashboardTableMetric'
import CampaignTypeEnum from '../../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../../enums/GoalEnum'
import CreativeFormatEnum from '../../../../enums/CreativeFormatEnum'
import { isNoneUndefinedOrNull } from '../../../../utils/regexUtils'
import { fromJS, List } from 'immutable'
import { toLower } from 'lodash'
import DashboardStatusEnum from '../../../../enums/DashboardStatusEnum'
import CreativeCategoryEnum from '../../../../enums/CreativeCategoryEnum'
import UserConfigEnum from '../../../../enums/UserConfigEnum'

const FILTER_TYPE = keyMirror({
  ID: null,
  NAME: null,
  ON_OFF: null,
  OPERATION_STATUS: null,
  REVIEW_STATUS: null,
  CAMPAIGN_TYPE: null,
  GOAL: null,
  CREATIVE_FORMAT: null,
  CAMPAIGN_METRIC: null,
  AD_GROUP_METRIC: null,
  CREATIVE_METRIC: null,
})

const FILTER_TYPE_NAME = {
  ID: 'ID',
  NAME: '이름',
  ON_OFF: 'ON/OFF',
  OPERATION_STATUS: '운영 상태',
  REVIEW_STATUS: '소재 심사 상태',
  CAMPAIGN_TYPE: '캠페인 유형',
  GOAL: '캠페인 목표',
  CREATIVE_FORMAT: '소재 유형',
  CAMPAIGN_METRIC: '캠페인 지표',
  AD_GROUP_METRIC: '광고그룹 지표',
  CREATIVE_METRIC: '소재 지표',
}

const FILTER_OPERATOR = keyMirror({
  CONTAIN: null,
  NOT_CONTAIN: null,
  ALL: null,
  IN: null,
  ANY: null,
  NONE: null,
  GE: null,
  LE: null,
  EQ: null,
  NEQ: null,
})

const FILTER_OPERATOR_NAME = {
  // 입력 문자열이 포함됨
  CONTAIN: '포함',
  // 입력 문자열을 포함하지 않음
  NOT_CONTAIN: '포함하지 않음',
  // 선택된 항목들과 모두 일치함
  ALL: '모두 일치',
  // 선택된 항목들 중 하나라도 일치함(alias: IN)
  ANY: '다음 중 하나',
  // 선택된 항목들이 모두 일치하지 않음
  NONE: '모두 아님',
  // 입력된 수치보다 같거나 큼
  GE: '이상',
  // 입력된 수치보다 같거나 작음
  LE: '이하',
  // 입력된 문자열|수치과 같음
  EQ: '같음',
  // 입력된 문자열|수치와 같지 않음
  NEQ: '같지 않음',
}

const DashboardFilterUtils = {
  /**
   * @param param
   * @param param.dashboardType {DashboardEnumV2.Type}
   * @param param.filterType {FILTER_TYPE}
   * @param param.filterSubType {string | undefined}
   * @param param.filterOperator {FILTER_OPERATOR}
   * @param param.filterValue {Immutable.List}
   * @returns {Immutable.Map}
   */
  initialConditionValue({
    dashboardType = '',
    filterType = '',
    filterSubType = '',
    filterOperator = '',
    filterValue = List(),
  }) {
    return fromJS({
      dashboardType,
      filterType,
      filterSubType,
      filterOperator,
      filterValue,
    })
  },

  metricConditionSelectOptions({ dashboardType, filterType }) {
    return [
      DashboardMetricGroupEnumV2.Type.COST,
      DashboardMetricGroupEnumV2.Type.DEFAULT,
      DashboardMetricGroupEnumV2.Type.MESSAGE_SENDING,
      DashboardMetricGroupEnumV2.Type.PIXEL_AND_SDK,
      DashboardMetricGroupEnumV2.Type.PLUS_FRIEND,
    ].map(metricGroup => {
      return {
        id: [dashboardType, filterType, metricGroup].join('|'),
        name: DashboardMetricGroupEnumV2.getName(metricGroup),
        children: DashboardTableMetric[metricGroup].map(
          ({ id: metricCode, label: metricName }) => ({
            id: [dashboardType, filterType, metricCode].join('|'),
            name: metricName,
            availableFilterOperators:
              DashboardFilterUtils.FilterOperator.values({
                filterType,
              }),
            conditionValue: this.initialConditionValue({
              dashboardType,
              filterType,
              filterSubType: metricCode,
            }),
          })
        ),
      }
    })
  },

  conditionSelectOptions() {
    return [
      {
        id: [FILTER_TYPE.NAME, FILTER_TYPE.ID].join('|'),
        name: [FILTER_TYPE_NAME.NAME, FILTER_TYPE_NAME.ID].join('/'),
        children: [
          [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.NAME],
          [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.NAME],
          [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.NAME],
          [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.ID],
          [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.ID],
          [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.ID],
        ].map(([dashboardType, filterType]) => ({
          id: [dashboardType, filterType].join('|'),
          name: [
            DashboardEnumV2.getName(dashboardType),
            FILTER_TYPE_NAME[filterType],
          ].join(' '),
          availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
            filterType,
          }),
          conditionValue: this.initialConditionValue({
            dashboardType,
            filterType,
          }),
        })),
      },
      {
        id: FILTER_TYPE.ON_OFF,
        name: FILTER_TYPE_NAME.ON_OFF,
        children: [
          [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.ON_OFF],
          [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.ON_OFF],
          [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.ON_OFF],
        ].map(([dashboardType, filterType]) => ({
          id: [dashboardType, filterType].join('|'),
          name: DashboardEnumV2.getName(dashboardType),
          availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
            filterType,
          }),
          conditionValue: this.initialConditionValue({
            dashboardType,
            filterType,
            filterValue: [],
          }),
        })),
      },
      {
        id: FILTER_TYPE.OPERATION_STATUS,
        name: FILTER_TYPE_NAME.OPERATION_STATUS,
        children: [
          [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.OPERATION_STATUS],
          [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.OPERATION_STATUS],
          [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.OPERATION_STATUS],
        ].map(([dashboardType, filterType]) => ({
          id: [dashboardType, filterType].join('|'),
          name: DashboardEnumV2.getName(dashboardType),
          availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
            filterType,
          }),
          conditionValue: this.initialConditionValue({
            dashboardType,
            filterType,
            filterValue: [],
          }),
        })),
      },
      {
        id: FILTER_TYPE.REVIEW_STATUS,
        name: FILTER_TYPE_NAME.REVIEW_STATUS,
        availableFilterOperators: [FILTER_OPERATOR.ANY],
        conditionValue: this.initialConditionValue({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          filterType: FILTER_TYPE.REVIEW_STATUS,
          filterValue: [],
        }),
      },

      {
        id: FILTER_TYPE.CAMPAIGN_TYPE,
        name: FILTER_TYPE_NAME.CAMPAIGN_TYPE,
        availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
          filterType: FILTER_TYPE.CAMPAIGN_TYPE,
        }),
        conditionValue: this.initialConditionValue({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          filterType: FILTER_TYPE.CAMPAIGN_TYPE,
          filterValue: [],
        }),
      },
      {
        id: FILTER_TYPE.GOAL,
        name: FILTER_TYPE_NAME.GOAL,
        availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
          filterType: FILTER_TYPE.GOAL,
        }),
        conditionValue: this.initialConditionValue({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          filterType: FILTER_TYPE.GOAL,
          filterValue: [],
        }),
      },
      {
        id: FILTER_TYPE.CREATIVE_FORMAT,
        name: FILTER_TYPE_NAME.CREATIVE_FORMAT,
        availableFilterOperators: DashboardFilterUtils.FilterOperator.values({
          filterType: FILTER_TYPE.CREATIVE_FORMAT,
        }),
        conditionValue: this.initialConditionValue({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          filterType: FILTER_TYPE.CREATIVE_FORMAT,
          filterValue: [],
        }),
      },
      {
        id: FILTER_TYPE.CAMPAIGN_METRIC,
        name: FILTER_TYPE_NAME.CAMPAIGN_METRIC,
        children: this.metricConditionSelectOptions({
          dashboardType: DashboardEnumV2.Type.CAMPAIGN,
          filterType: FILTER_TYPE.CAMPAIGN_METRIC,
        }),
      },
      {
        id: FILTER_TYPE.AD_GROUP_METRIC,
        name: FILTER_TYPE_NAME.AD_GROUP_METRIC,
        children: this.metricConditionSelectOptions({
          dashboardType: DashboardEnumV2.Type.AD_GROUP,
          filterType: FILTER_TYPE.AD_GROUP_METRIC,
        }),
      },
      {
        id: FILTER_TYPE.CREATIVE_METRIC,
        name: FILTER_TYPE_NAME.CREATIVE_METRIC,
        children: this.metricConditionSelectOptions({
          dashboardType: DashboardEnumV2.Type.CREATIVE,
          filterType: FILTER_TYPE.CREATIVE_METRIC,
        }),
      },
    ]
  },

  suggestConditionOptions() {
    return [
      [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.NAME],
      [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.NAME],
      [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.NAME],
      [DashboardEnumV2.Type.CAMPAIGN, FILTER_TYPE.ID],
      [DashboardEnumV2.Type.AD_GROUP, FILTER_TYPE.ID],
      [DashboardEnumV2.Type.CREATIVE, FILTER_TYPE.ID],
    ].map(([dashboardType, filterType]) => {
      const availableFilterOperators =
        DashboardFilterUtils.FilterOperator.values({ filterType })
      const repFilterOperator = availableFilterOperators[0]

      return {
        id: [dashboardType, filterType].join('|'),
        name: [
          DashboardEnumV2.getName(dashboardType),
          FILTER_TYPE_NAME[filterType],
          FILTER_OPERATOR_NAME[repFilterOperator],
        ].join(' '),
        conditionValue: this.initialConditionValue({
          dashboardType,
          filterType,
          filterOperator: repFilterOperator,
        }),
        isNumberOnly: DashboardFilterUtils.isFilterValueNumeric({ filterType }),
        filterType,
      }
    })
  },

  /**
   * 실제로 화면에 표현되는 string 으로 변환.
   * @param param
   * @param param.conditionValue {initialConditionValue}
   * @returns {string|*}
   */
  valueToString({ conditionValue }) {
    const { filterType, filterOperator, filterValue } = conditionValue || {}

    if (!isNoneUndefinedOrNull(filterType, filterOperator, filterValue)) {
      return ''
    }

    switch (filterType) {
      case FILTER_TYPE.ID: {
        return filterValue.join(',')
      }

      case FILTER_TYPE.NAME: {
        return filterValue.join(',')
      }

      case FILTER_TYPE.ON_OFF: {
        return filterValue.map(v => UserConfigEnum.getName(v)).join(',')
      }

      case FILTER_TYPE.OPERATION_STATUS:
      case FILTER_TYPE.REVIEW_STATUS: {
        return filterValue.map(v => DashboardStatusEnum.getName(v)).join(',')
      }

      case FILTER_TYPE.CAMPAIGN_TYPE: {
        return filterValue.map(v => CampaignTypeEnum.getName(v)).join(',')
      }

      case FILTER_TYPE.GOAL: {
        return filterValue.map(v => GoalEnum.getName(v)).join(',')
      }

      case FILTER_TYPE.CREATIVE_FORMAT: {
        return filterValue
          .map(creativeFormat => {
            const creativeCategoryName = CreativeCategoryEnum.getName(
              CreativeCategoryEnum.getValueByCreativeFormat(creativeFormat)
            )

            const creativeFormatName =
              CreativeFormatEnum.getName(creativeFormat)

            return [creativeCategoryName, creativeFormatName].join(' > ')
          })
          .join(',')
      }

      case FILTER_TYPE.CAMPAIGN_METRIC:
      case FILTER_TYPE.AD_GROUP_METRIC:
      case FILTER_TYPE.CREATIVE_METRIC: {
        if (filterValue.count() > 0) {
          return [
            convertToCommaSeparatedString(filterValue.get(0)),
            FILTER_OPERATOR_NAME[filterOperator],
          ].join(' ')
        } else {
          return ''
        }
      }

      default: {
        return filterValue.join(',')
      }
    }
  },

  /**
   * @param param
   * @param param.conditionValue {initialConditionValue}
   * @param param.includeValueString {boolean}
   * @returns {string}
   */
  toString({ conditionValue, includeValueString = true }) {
    const { dashboardType, filterType, filterSubType, filterOperator } =
      conditionValue || {}

    const stringArray = []

    const highlightedValueString = includeValueString
      ? this.valueToString({ conditionValue })
      : ''

    switch (filterType) {
      // 캠페인 ID {highlightedValueString}
      case FILTER_TYPE.ID: {
        stringArray.push(
          DashboardEnumV2.getName(dashboardType),
          FILTER_TYPE_NAME[filterType],
          highlightedValueString
        )
        break
      }

      // 캠페인 이름에 {highlightedValueString} 포함
      case FILTER_TYPE.NAME: {
        stringArray.push(
          DashboardEnumV2.getName(dashboardType),
          `${FILTER_TYPE_NAME[filterType]}`,
          highlightedValueString,
          FILTER_OPERATOR_NAME[filterOperator]
        )
        break
      }

      /**
       * 캠페인 운영 상태 {highlightedValueString}
       * 소재 심사 상태 {highlightedValueString}
       * ON/OFF {highlightedValueString}
       * 캠페인 유형 {highlightedValueString}
       * 캠페인 목표 {highlightedValueString}
       * 소재 유형 {highlightedValueString}
       */
      case FILTER_TYPE.ON_OFF: {
        stringArray.push(
          DashboardEnumV2.getName(dashboardType),
          FILTER_TYPE_NAME[filterType],
          highlightedValueString
        )
        break
      }

      case FILTER_TYPE.OPERATION_STATUS: {
        stringArray.push(
          DashboardEnumV2.getName(dashboardType),
          FILTER_TYPE_NAME[filterType],
          highlightedValueString
        )
        break
      }

      case FILTER_TYPE.REVIEW_STATUS:
      case FILTER_TYPE.CAMPAIGN_TYPE:
      case FILTER_TYPE.GOAL:
      case FILTER_TYPE.CREATIVE_FORMAT: {
        stringArray.push(FILTER_TYPE_NAME[filterType], highlightedValueString)
        break
      }

      /**
       * 캠페인 클릭당 비용 {highlightedValueString(with operator)}
       */
      case FILTER_TYPE.CAMPAIGN_METRIC:
      case FILTER_TYPE.AD_GROUP_METRIC:
      case FILTER_TYPE.CREATIVE_METRIC: {
        if (filterSubType) {
          const metricCode = filterSubType

          stringArray.push(
            DashboardEnumV2.getName(dashboardType),
            DashboardTableMetricIds.find(v => v.id === toLower(metricCode))
              ?.label || '',
            highlightedValueString
          )
        }
        break
      }

      default: {
        break
      }
    }

    return stringArray.filter(Boolean).join(' ')
  },

  /**
   * dashboardType + filterType, filterSubType 을 이용한 일관된 유니크 키 생성
   * @param conditionValue {initialConditionValue | undefined}
   * @returns {string}
   */
  uniqueConditionKey(conditionValue) {
    const { dashboardType, filterType, filterSubType } = conditionValue || {}
    return [dashboardType, filterType, filterSubType].filter(Boolean).join('|')
  },

  /**
   * 인코딩(encodeURI)이 필요한지 여부
   * @param param
   * @param param.filterType {FILTER_TYPE}
   * @returns {boolean}
   */
  shouldValueEncode({ filterType }) {
    return filterType === FILTER_TYPE.NAME
  },

  /**
   * array 의 단일 항목(index: 0)만 취하는지 여부
   * @param param
   * @param param.filterType {FILTER_TYPE}
   * @returns {boolean}
   */
  takeOnlyFirstValueOfArray({ filterType }) {
    return filterType === FILTER_TYPE.NAME || this.isMetric({ filterType })
  },

  /**
   * @param param
   * @param param.filterType {FILTER_TYPE}
   * @returns {boolean}
   */
  isMetric({ filterType }) {
    return [
      FILTER_TYPE.CAMPAIGN_METRIC,
      FILTER_TYPE.AD_GROUP_METRIC,
      FILTER_TYPE.CREATIVE_METRIC,
    ].includes(filterType)
  },

  /**
   * @param param
   * @param param.filterConditionA {initialConditionValue}
   * @param param.filterConditionB {initialConditionValue}
   * @returns {boolean}
   */
  isConditionTypeSame({ filterConditionA, filterConditionB }) {
    return (
      this.uniqueConditionKey(filterConditionA) ===
      this.uniqueConditionKey(filterConditionB)
    )
  },

  maxCountOfFilterValueArray({ filterType }) {
    switch (filterType) {
      case FILTER_TYPE.ID: {
        return 10
      }
      case FILTER_TYPE.NAME: {
        return 1
      }
      case FILTER_TYPE.CAMPAIGN_METRIC:
      case FILTER_TYPE.AD_GROUP_METRIC:
      case FILTER_TYPE.CREATIVE_METRIC: {
        return 1
      }

      default: {
        return undefined
      }
    }
  },

  maxLengthOfFilterValueString({ filterType }) {
    switch (filterType) {
      case FILTER_TYPE.ID: {
        return 10
      }
      case FILTER_TYPE.NAME: {
        return 50
      }
      case FILTER_TYPE.CAMPAIGN_METRIC:
      case FILTER_TYPE.AD_GROUP_METRIC:
      case FILTER_TYPE.CREATIVE_METRIC: {
        return 15
      }

      default: {
        return undefined
      }
    }
  },

  isFilterValueNumeric({ filterType }) {
    return (
      filterType === FILTER_TYPE.ID ||
      DashboardFilterUtils.isMetric({ filterType })
    )
  },
}

DashboardFilterUtils.FilterValue = {
  operationStatus({ dashboardType }) {
    switch (dashboardType) {
      case DashboardEnumV2.Type.CAMPAIGN: {
        return DashboardStatusEnum.valuesOfCampaign()
      }

      case DashboardEnumV2.Type.AD_GROUP: {
        return DashboardStatusEnum.valuesOfAdGroup()
      }

      case DashboardEnumV2.Type.CREATIVE: {
        return DashboardStatusEnum.valuesOfCreative()
      }

      default: {
        return []
      }
    }
  },

  reviewStatus() {
    return DashboardStatusEnum.valuesOfCreativeReview()
  },

  onOff() {
    return [UserConfigEnum.Type.ON, UserConfigEnum.Type.OFF]
  },

  // 모든 광고계정에서 기본적으로 노출되는 캠페인 유형만 정의 (화이트리스트 권한 있을때 노출되는 유형은 availableCampaignTypes 참조)
  campaignType() {
    return [
      CampaignTypeEnum.Type.TALK_BIZ_BOARD,
      CampaignTypeEnum.Type.TALK_BIZ_BOARD_RESERVED,
      CampaignTypeEnum.Type.DISPLAY,
      CampaignTypeEnum.Type.TALK_CHANNEL,
      CampaignTypeEnum.Type.DAUM_SHOPPING,
      CampaignTypeEnum.Type.VIDEO,
      CampaignTypeEnum.Type.PC_TALK_BOTTOM,
      CampaignTypeEnum.Type.FOCUS_FULL_VIEW,
      CampaignTypeEnum.Type.PROFILE_FULL_VIEW,
      CampaignTypeEnum.Type.PC_TALK_RICH_POP,
    ]
  },

  goal() {
    return GoalEnum.orderedValues()
  },

  creativeFormat() {
    return [
      CreativeFormatEnum.Type.IMAGE_BANNER,
      CreativeFormatEnum.Type.IMAGE_NATIVE,
      CreativeFormatEnum.Type.CATALOG_MANUAL,
      CreativeFormatEnum.Type.IMAGE_BOX,
      CreativeFormatEnum.Type.VIDEO_NATIVE,
      CreativeFormatEnum.Type.CATALOG_DYNAMIC,
      CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
      CreativeFormatEnum.Type.WIDE_MESSAGE,
      CreativeFormatEnum.Type.WIDE_LIST_MESSAGE,
      CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE,
      CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE,
      CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE,
      CreativeFormatEnum.Type.RICH_NATIVE,
    ]
  },
}

DashboardFilterUtils.FilterOperator = {
  values({ filterType }) {
    switch (filterType) {
      case FILTER_TYPE.ID: {
        return [FILTER_OPERATOR.EQ]
      }

      case FILTER_TYPE.NAME: {
        return [FILTER_OPERATOR.CONTAIN]
      }

      case FILTER_TYPE.ON_OFF:
      case FILTER_TYPE.OPERATION_STATUS:
      case FILTER_TYPE.REVIEW_STATUS:
      case FILTER_TYPE.CAMPAIGN_TYPE:
      case FILTER_TYPE.GOAL:
      case FILTER_TYPE.CREATIVE_FORMAT: {
        return [FILTER_OPERATOR.ANY]
      }

      case FILTER_TYPE.CAMPAIGN_METRIC:
      case FILTER_TYPE.AD_GROUP_METRIC:
      case FILTER_TYPE.CREATIVE_METRIC: {
        return [FILTER_OPERATOR.GE, FILTER_OPERATOR.LE, FILTER_OPERATOR.EQ]
      }

      default: {
        return Object.keys(FILTER_OPERATOR)
      }
    }
  },
}

export {
  FILTER_TYPE,
  FILTER_TYPE_NAME,
  FILTER_OPERATOR,
  FILTER_OPERATOR_NAME,
  DashboardFilterUtils,
}
