import PopupHOC from '../PopupHOC'
import React, { Fragment } from 'react'
import PopupButtonGroup from '../../Common/ButtonGroup/PopupButtonGroup'
import PropTypes from 'prop-types'
import { getDashboardExceptionMessageByErrorCode } from '../../../modules/dashboardV3/dashboardException'
import { NumberUtils } from '../../../utils/numberUtils'

const DashboardPromiseMessageDetail = ({
  requestName,
  resultIdContextName,
  requests,
  successes,
  failures,
  successCount,
  failureCount,
  close,
}) => {
  const requestCount = requests.length
  const failureEntries = Object.entries(failures)
  const hasFailures = failureEntries.length > 0

  return (
    <div className="inner_basic_layer" onClick={e => e.stopPropagation()}>
      <strong className="tit_layer">요청 결과 상세</strong>
      <p className="txt_layer">
        <span className="fc_empty">{NumberUtils.withCommas(requestCount)}</span>
        개 {requestName} 처리가 적용되었습니다.
        <br />
        (성공{' '}
        <span className="fc_empty">{NumberUtils.withCommas(successCount)}</span>
        개, 실패{' '}
        <span className="fc_empty">{NumberUtils.withCommas(failureCount)}</span>
        개)
        {hasFailures && (
          <>
            <br />
            실패 대상 및 자세한 사유를 확인 후, 다시 시도하세요.
          </>
        )}
      </p>
      {hasFailures && (
        <div className="imgupload_wrap">
          {failureEntries.map(([id, { errorCode, errorMessage }]) => {
            return (
              <Fragment key={id}>
                <strong className="tit_imgupload">
                  [{resultIdContextName}] {id}
                </strong>
                <p className="txt_imgupload">
                  {getDashboardExceptionMessageByErrorCode({
                    errorCode,
                    defaultMessage: errorMessage,
                  })}
                </p>
              </Fragment>
            )
          })}
        </div>
      )}
      <PopupButtonGroup
        onOK={close}
        okButtonLabel="확인"
        hasCancel={false}
        hasBack={false}
      />
      <a className="btn_close" onClick={close}>
        <span className="ico_comm ico_close">닫기</span>
      </a>
    </div>
  )
}

DashboardPromiseMessageDetail.propTypes = {
  subject: PropTypes.string,
  requestName: PropTypes.string,
  resultIdContextName: PropTypes.string,
  requests: PropTypes.array.isRequired,
  successes: PropTypes.object,
  failures: PropTypes.object,
  successCount: PropTypes.number,
  failureCount: PropTypes.number,
  close: PropTypes.func.isRequired,
}

export default PopupHOC(DashboardPromiseMessageDetail, {
  className: 'basic_layer2',
  subClassName: 'imgupload_layer',
})
