import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    TARGET_CPC: null,
    TARGET_CPA: null,
    TARGET_ROAS: null,
    MAXIMIZE_CONVERSION_VALUE: null,
  }),
  Name: {
    TARGET_CPC: 'CPC 비용 목표',
    TARGET_CPA: 'CPA 비용 목표',
    TARGET_ROAS: 'ROAS 목표',
    MAXIMIZE_CONVERSION_VALUE: '전환 가치 최대화',
  },
  getName(type) {
    return this.Name[type]
  },
}

export const BidStrategyInitialValue = {
  TARGET_CPC: 200,
  TARGET_CPA: 1500,
  TARGET_ROAS: 150,
  MAXIMIZE_CONVERSION_VALUE: 0,
}
