import { keyMirror, queryString } from '../../../../../utils/utils'
import { CmpAPI } from '../../../../../modules-api/advertise/cmpApi'
import { CmpRequestUtils } from '../cmpRequestUtils'
import {
  checkNotEmpty,
  extractUrlFileName,
} from '../../../../../utils/regexUtils'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CmpAssetPropertyEnum from '../../../../../enums/CmpAssetPropertyEnum'

const CMP_EDITOR_EVENT = keyMirror({
  // 에디터 아이템 단건 조회
  GET_ITEM: null,
  // 에디터 아이템 다건 조회
  GET_ITEMS: null,
  // 에디터 아이템 단건 생성
  CREATE_ITEM: null,
  // 에디터 아이템 단건 수정
  MODIFY_ITEM: null,
  // 에디터 아이템 단건 삭제
  DELETE_ITEM: null,
  // 원본 이미지 에셋 다건 조회
  GET_RAW_ASSETS: null,
  // 이미지 단건 업로드
  UPLOAD_RAW_ASSET: null,
  // 이미지 다건 업로드
  UPLOAD_RAW_ASSETS: null,
  // 에디터 템플릿(TBD)
  GET_TEMPLATES: null,
  // 닫기
  CLOSE: null,
  // 연결 체크
  HEALTH_CHECK: null,
})

const CMP_EDITOR_CONNECTION_EVENT = keyMirror({
  // 에디터가 mount 된 후 port 를 수신할 준비가 됨
  READY: null,
  // 에디터로 postMessage 를 전송했으나 연동할 port 가 없음
  PORT_EMPTY: null,
  // 에디터 연동이 이루어졌으나 예기치 않은 오류로 port(state) 가 유실됨
  PORT_MISSING: null,
  // 에디터에서 허용하지 않은 도메인으로 연결을 시도함
  NOT_ALLOWED_ORIGIN: null,
  // 에디터에서 port 를 받아 연동이 성공적으로 이루어짐
  CONNECTED: null,
})

const CMP_EDITOR_CONNECTION_EVENT_MESSAGE = {
  PORT_EMPTY: '에디터를 사용할 수 없습니다. 잠시 후에 다시 시도하세요.',
  PORT_MISSING: '에디터를 사용할 수 없습니다. 잠시 후에 다시 시도하세요.',
  NOT_ALLOWED_ORIGIN: '에디터에서 허용되지 않은 도메인입니다.',
}

const CMP_EDITOR_STATUS = keyMirror({
  // 요청 수신 & 처리 중
  PENDING: null,
  // 요청 성공
  OK: null,
  // 요청 실패
  ERROR: null,
  // 요청이 취소됨
  CANCELED: null,
})

const CMP_EDITOR_RATIO = keyMirror({
  RATIO_1x1: null,
  RATIO_2x1: null,
  RATIO_4x3: null,
  RATIO_9x16: null,
  RATIO_16x9: null,
})

// 소재 유형별 Opener 타입 : https://wiki.daumkakao.com/pages/viewpage.action?pageId=910569739
const CMP_EDITOR_OPENER = keyMirror({
  MESSAGE: null, // 메시지 기본텍스트(소재), 캐러셀커머스(홍보이미지, 캐러셀)
  MESSAGE_W_I: null, // 메시지 와이드이미지
  MESSAGE_W_L_O: null, // 메시지 와이드리스트((리스트1)
  MESSAGE_W_L_TF: null, // 메시지 와이드리스트((리스트2~4)
  MESSAGE_C_F: null, // 메시지 캐러셀피드MESSAGE_W_I( 캐러셀)
  DISPLAY: null, // 이미지 네이티브/카탈로그(프로필,슬라이드)
  DISPLAY_N_P: null, // 이미지 네이티브(홍보이미지)
  DISPLAY_E: null, // 이미지 네이티브(홍보이미지)
  VIDEO: null, // 동영상 네이티브 (프로필)
  VIDEO_C_T_H: null, // 동영상 네이티브 맞춤 썸네일 (16:9)
  VIDEO_C_T_V: null, // 동영상 네이티브 맞춤 썸네일 (9:16)
})

const CMP_EDITOR_ITEM_PAGE_SIZE = 50
const CMP_EDITOR_RAW_PAGE_SIZE = 50

const CmpEditorUtils = {
  getRatioConstByValue({ value }) {
    if (value === 1) return CMP_EDITOR_RATIO.RATIO_1x1
    if (value === 2) return CMP_EDITOR_RATIO.RATIO_2x1
    if (value === 1.3333) return CMP_EDITOR_RATIO.RATIO_4x3
    if (value === 0.5625) return CMP_EDITOR_RATIO.RATIO_9x16
    if (value === 1.7777) return CMP_EDITOR_RATIO.RATIO_16x9
    return null
  },

  getOpenerByCreativeFormatAndCmpAssetPropertyType({
    creativeFormat,
    cmpAssetPropertyType,
    ratio,
  }) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
        return CMP_EDITOR_OPENER.MESSAGE

      case CreativeFormatEnum.Type.WIDE_MESSAGE:
        return CMP_EDITOR_OPENER.MESSAGE_W_I

      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
        if (
          cmpAssetPropertyType ===
          CmpAssetPropertyEnum.Type.MESSAGE_WIDE_LIST_FIRST_IMAGE
        ) {
          return CMP_EDITOR_OPENER.MESSAGE_W_L_O
        } else {
          return CMP_EDITOR_OPENER.MESSAGE_W_L_TF
        }

      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
        return CMP_EDITOR_OPENER.MESSAGE

      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
        return CMP_EDITOR_OPENER.MESSAGE_C_F

      case CreativeFormatEnum.Type.IMAGE_NATIVE:
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE) {
          return CMP_EDITOR_OPENER.DISPLAY
        } else if (
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MAIN_IMAGE
        ) {
          return CMP_EDITOR_OPENER.DISPLAY_N_P
        } else if (
          cmpAssetPropertyType ===
          CmpAssetPropertyEnum.Type.MAIN_IMAGE_ELECTION_2024
        ) {
          return CMP_EDITOR_OPENER.DISPLAY_E
        } else {
          return null
        }
      case CreativeFormatEnum.Type.CATALOG_MANUAL:
        return CMP_EDITOR_OPENER.DISPLAY

      case CreativeFormatEnum.Type.VIDEO_NATIVE:
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE) {
          return CMP_EDITOR_OPENER.VIDEO
        }
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.VIDEO_IMAGE) {
          if (ratio === CMP_EDITOR_RATIO.RATIO_9x16) {
            return CMP_EDITOR_OPENER.VIDEO_C_T_V
          }
          if (ratio === CMP_EDITOR_RATIO.RATIO_16x9) {
            return CMP_EDITOR_OPENER.VIDEO_C_T_H
          }
        }
        return null

      case CreativeFormatEnum.Type.RICH_NATIVE: {
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.VIDEO_IMAGE) {
          if (ratio === CMP_EDITOR_RATIO.RATIO_9x16) {
            return CMP_EDITOR_OPENER.VIDEO_C_T_V
          }
        }
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MAIN_IMAGE) {
          return CMP_EDITOR_OPENER.VIDEO_C_T_V
        }
        if (cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE) {
          return CMP_EDITOR_OPENER.DISPLAY
        } else {
          return CMP_EDITOR_OPENER.DISPLAY_E
        }
      }
      default:
        return null
    }
  },

  getIsAvailableEditorData(creativeFormat, cmpAssetPropertyType) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE:
        return (
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MAIN_IMAGE ||
          cmpAssetPropertyType ===
            CmpAssetPropertyEnum.Type.MESSAGE_WIDE_LIST_FIRST_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MESSAGE_WIDE_IMAGE
        )

      case CreativeFormatEnum.Type.IMAGE_NATIVE:
      case CreativeFormatEnum.Type.CATALOG_MANUAL:
        return (
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MAIN_IMAGE ||
          cmpAssetPropertyType ===
            CmpAssetPropertyEnum.Type.MAIN_IMAGE_ELECTION_2024
        )

      case CreativeFormatEnum.Type.VIDEO_NATIVE:
        return (
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.VIDEO_IMAGE
        )
      case CreativeFormatEnum.Type.RICH_NATIVE:
        return (
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.PROFILE_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.VIDEO_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.COMPATIBLE_IMAGE ||
          cmpAssetPropertyType === CmpAssetPropertyEnum.Type.MAIN_IMAGE
        )

      default:
        return false
    }
  },

  createIframeUrl({
    baseUrl,
    imageEditorId,
    imageSourceUrl,
    imageSourceFileName,
    imageOpener = CMP_EDITOR_OPENER.MESSAGE,
  }) {
    const isEditorItemSelected = imageEditorId > 0

    const params = encodeURIComponent(
      queryString({
        id: isEditorItemSelected ? imageEditorId : undefined,
        sourceUrl:
          !isEditorItemSelected && imageSourceUrl ? imageSourceUrl : undefined,
        fileName:
          !isEditorItemSelected && imageSourceUrl
            ? imageSourceFileName || extractUrlFileName(imageSourceUrl) // imageSourceFileName 이 없을 경우, url 에서 추출하여 사용
            : undefined,
        opener: imageOpener,
      })
    )

    if (isEditorItemSelected || imageSourceUrl) {
      return `${baseUrl}/relocate?${params}`
    } else {
      return `${baseUrl}?${params}`
    }
  },

  /**
   * @param port {MessagePort}
   */
  createMessageChannelEventHandler({ port }) {
    return {
      send({ event, status, payload = {} }) {
        if (port && event && status) {
          port.postMessage({
            event,
            status,
            payload,
          })

          console.info({ event, status, payload })
        }
      },
      sendPending({ event }) {
        this.send({ event, status: CMP_EDITOR_STATUS.PENDING })
      },
      sendOk({ event, payload }) {
        this.send({ event, status: CMP_EDITOR_STATUS.OK, payload })
      },
      sendError({ event, payload }) {
        this.send({ event, status: CMP_EDITOR_STATUS.ERROR, payload })
      },
      sendCancel({ event }) {
        this.send({ event, status: CMP_EDITOR_STATUS.CANCELED })
      },
    }
  },

  createHttpRequestHandler({ adAccountId }) {
    return {
      getItems({
        fileName = '',
        mediaRatioIn = [],
        mediaRatioNotIn = [],
        mediaDimension = [{ width: { min: 1 } }, { height: { min: 1 } }],
        cancelTokenSource,
        pageIndex = 0,
        pageSize = CMP_EDITOR_ITEM_PAGE_SIZE,
      } = {}) {
        return CmpAPI.getEditorItems({
          adAccountId,
          requestBody: CmpRequestUtils.RequestCreator.editorItems({
            fileName,
            mediaRatioIn,
            mediaRatioNotIn,
            mediaDimension,
          }),
          pageRequest: { page: pageIndex, size: pageSize },
          cancelTokenSource,
        })
      },
      getItem({ id } = {}) {
        return CmpAPI.getEditorItem({
          adAccountId,
          id,
        })
      },
      createItem({
        resultImage,
        logoImage,
        backgroundImage,
        metaData = '{}',
      } = {}) {
        return CmpAPI.createEditorItem({
          adAccountId,
          requestBody: CmpRequestUtils.RequestCreator.editorItemCreateOrModify({
            adAccountId,
            resultImage,
            logoImage,
            backgroundImage,
            metaData,
          }),
        })
      },
      modifyItem({
        id,
        resultImage,
        logoImage,
        backgroundImage,
        metaData = '{}',
      } = {}) {
        return CmpAPI.modifyEditorItem({
          adAccountId,
          id,
          requestBody: CmpRequestUtils.RequestCreator.editorItemCreateOrModify({
            adAccountId,
            resultImage,
            logoImage,
            backgroundImage,
            metaData,
          }),
        })
      },
      deleteItem({ id } = {}) {
        return CmpAPI.deleteEditorItem({ adAccountId, id })
      },
      uploadRawAssets({ imageFiles } = {}) {
        return CmpAPI.uploadEditorRawAssets({
          adAccountId,
          requestBody: CmpRequestUtils.RequestCreator.editorUploadRawAssets({
            adAccountId,
            imageFiles,
          }),
        })
      },
      getRawAssets({
        fileName = '',
        mediaRatioIn = [],
        mediaRatioNotIn = [],
        mediaDimension = [{ width: { min: 1 } }, { height: { min: 1 } }],
        pageIndex = 0,
        pageSize = CMP_EDITOR_RAW_PAGE_SIZE,
        cancelTokenSource,
      } = {}) {
        return CmpAPI.getEditorRawAssets({
          adAccountId,
          requestBody: CmpRequestUtils.RequestCreator.editorRawAssets({
            fileName,
            mediaRatioIn,
            mediaRatioNotIn,
            mediaDimension,
          }),
          pageRequest: {
            page: pageIndex,
            size: pageSize,
          },
          cancelTokenSource,
        })
      },
    }
  },

  getImageHashes(imageHashes) {
    const imageHashSet = new Set()

    imageHashes.forEach(({ imageTO }) => {
      const { imageHash } = imageTO || {}
      imageHashSet.add(imageHash)
    })

    const imageHashList = []
    imageHashSet.forEach(hash => {
      const ids = imageHashes
        .filter(
          ({ imageTO: { imageHash }, image: { id } }) =>
            checkNotEmpty(id) && imageHash === hash
        )
        .map(({ image: { id } }) => id)

      const {
        imageTO: { url },
      } = imageHashes.find(({ imageTO: { imageHash } }) => imageHash === hash)

      imageHashList.push({ hash, ids, url })
    })

    return imageHashList
  },
}

export {
  CmpEditorUtils,
  CMP_EDITOR_EVENT,
  CMP_EDITOR_CONNECTION_EVENT,
  CMP_EDITOR_CONNECTION_EVENT_MESSAGE,
  CMP_EDITOR_STATUS,
  CMP_EDITOR_RATIO,
  CMP_EDITOR_ITEM_PAGE_SIZE,
  CMP_EDITOR_RAW_PAGE_SIZE,
  CMP_EDITOR_OPENER,
}
