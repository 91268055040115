import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import DynamicTooltip from '../DynamicTooltip'

const DebugTooltip = ({ content, styles, onClick }) => {
  if (!(__LOCAL__ || __DEV__ || __SANDBOX__)) return null

  return (
    <DynamicTooltip
      onClick={onClick}
      content={
        <Fragment>
          <span style={{ color: 'blue', fontWeight: 'bold' }}>
            *개발 환경에서만 노출되는 툴팁입니다*
          </span>
          <br />
          {content}
        </Fragment>
      }
      styles={styles}>
      <a className="link_g link_help">
        <span className="ico_comm ico_help">도움말</span>
      </a>
    </DynamicTooltip>
  )
}

DebugTooltip.propTypes = {
  content: PropTypes.any,
  styles: PropTypes.object,
  onClick: PropTypes.func,
}

export default DebugTooltip
