import { FixedSizeList } from 'react-window'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  toCreateAdAccountPath,
  toMyAdAccountListPath,
} from '../../../utils/router/routeUtils'
import React from 'react'
import InputBox from '../../Common/InputBox'
import { RoleType } from '../../../modules/common/mRole'
import { AD_ACCOUNT_STATUS } from '../../../modules/adaccount/mAdAccount'
import cx from 'classnames'
import { setSelectedAdAccountInfo } from '../../../modules/common/mAdAccountListSelector'
import PropTypes from 'prop-types'
import DashboardRouter from '../../DashboardV3/dashboardRouter'
import { useClickOutSide } from '../../../utils/hook/useClickOutside'
import useAdAccountQuery from './hooks/useAdAccountQuery'
import { PrevButton, NextButton } from '../../Common/Pagenation/Pagenation'
import { MomentLoader } from '../../Common/Loader/MomentLoader'

const LIST_HEIGHT = 315
const LIST_ITEM_HEIGHT = 44

const LnbAdAccountSelect = React.forwardRef(
  ({ adAccountId, adAccountName, adAccountStatus }, forwardedRef) => {
    const [pageIndex, setPageIndex] = React.useState(0)
    const [searchKeyword, setSearchKeyword] = React.useState('')
    const {
      adAccounts: adAccountSelectItems,
      totalPageCount,
      isLoading,
    } = useAdAccountQuery({
      adAccountId,
      pageIndex,
      searchKeyword,
      expandKeys: [adAccountStatus],
    })

    const dispatch = useDispatch()

    const selectLayerRef = React.useRef()
    const selectListRef = React.useRef()

    const [isOpened, setIsOpened] = React.useState(false)

    const resetQueryParams = () => {
      setPageIndex(0)
      setSearchKeyword('')
    }

    const close = React.useCallback(() => {
      setIsOpened(false)
      resetQueryParams()
    }, [])

    useClickOutSide(
      selectLayerRef,
      () => {
        close()
      },
      { capture: true }
    )

    React.useImperativeHandle(
      forwardedRef,
      () => ({
        dismiss: close,
      }),
      [close]
    )

    React.useLayoutEffect(() => {
      if (isOpened && selectListRef.current) {
        const selectedItem = adAccountSelectItems.findIndex(
          ({ adAccountId: id }) => Number(id) === Number(adAccountId)
        )

        if (selectedItem) {
          selectListRef.current.scrollToItem(selectedItem, 'start')
        } else {
          selectListRef.current.scrollTo(0, 0)
        }
      }
    }, [adAccountId, adAccountSelectItems, isOpened])

    const handleSearch = React.useCallback((query = '') => {
      setSearchKeyword(query)
      setPageIndex(0)
    }, [])

    React.useEffect(() => {
      if (isOpened) {
        handleSearch()
      }
    }, [handleSearch, isOpened])

    const onSelect = React.useCallback(
      item => {
        dispatch(setSelectedAdAccountInfo(item))
        resetQueryParams()
        setIsOpened(false)
      },
      [dispatch]
    )

    const onNextPage = React.useCallback(() => {
      const nextPageIndex = pageIndex + 1
      if (nextPageIndex < totalPageCount) {
        setPageIndex(nextPageIndex)
      }
    }, [pageIndex, totalPageCount])

    const onPrevPage = React.useCallback(() => {
      const nextPageIndex = pageIndex - 1
      setPageIndex(nextPageIndex < 0 ? 0 : nextPageIndex)
    }, [pageIndex])

    return (
      <div ref={selectLayerRef} className={cx('user_info', { on: isOpened })}>
        <strong
          className="tit_account"
          onClick={e => {
            e.stopPropagation()

            setIsOpened(prev => !prev)
          }}>
          {adAccountName}
          <span className="ico_gnb ico_arrow">더보기</span>
        </strong>
        {isOpened && (
          <div className="layer_account">
            <AdAccountSelectSearchInput
              onSearch={handleSearch}
              isLoading={isLoading}
            />
            {isLoading ? (
              <div className="fake_scroll">
                <div className="txt_nodata">
                  <MomentLoader />
                </div>
              </div>
            ) : adAccountSelectItems.length === 0 ? (
              <div className="fake_scroll">
                <p className="txt_nodata">검색 결과가 없습니다.</p>
              </div>
            ) : (
              <FixedSizeList
                ref={selectListRef}
                innerElementType="ul"
                className="fake_scroll"
                width="100%"
                height={LIST_HEIGHT}
                itemSize={LIST_ITEM_HEIGHT}
                itemData={adAccountSelectItems}
                itemCount={adAccountSelectItems.length}>
                {({ data, index, style }) => {
                  const adAccount = data[index]
                  return (
                    <AdAccountSelectListItem
                      key={adAccount.adAccountId}
                      currAdAccountId={adAccountId}
                      style={style}
                      adAccount={adAccount}
                      onSelect={onSelect}
                    />
                  )
                }}
              </FixedSizeList>
            )}
            <div className="opt_tool">
              <div className="paging_wrap2">
                <span className="inner_paging">
                  <strong className="num_current">
                    <span className="screen_out">현재 광고계정 리스트</span>
                    {pageIndex + 1}
                  </strong>
                  / <span className="screen_out">총 광고계정 리스트 수</span>
                  {totalPageCount || 1}
                </span>
                <PrevButton
                  onClick={onPrevPage}
                  active={pageIndex !== 0}
                  className="num_prev"
                />
                <NextButton
                  onClick={onNextPage}
                  active={
                    !!(totalPageCount && pageIndex !== totalPageCount - 1)
                  }
                  className="num_next"
                />
              </div>
              <Link to={toMyAdAccountListPath()} className="link_all">
                전체보기
              </Link>
              <Link to={toCreateAdAccountPath()} className="link_create">
                + 새 광고계정 만들기
              </Link>
            </div>
          </div>
        )}
      </div>
    )
  }
)

LnbAdAccountSelect.displayName = 'LnbAdAccountSelect'
LnbAdAccountSelect.propTypes = {
  adAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  adAccountName: PropTypes.string.isRequired,
  adAccountStatus: PropTypes.object,
}

const _AdAccountSelectSearchInput = ({ onSearch, isLoading }) => {
  const [query, setQuery] = React.useState('')

  const handleQueryChange = React.useCallback(e => {
    setQuery(e.target.value)
  }, [])

  const handleSearch = React.useCallback(() => {
    onSearch(query)
  }, [onSearch, query])

  const handleClear = React.useCallback(() => {
    setQuery('')
  }, [])

  return (
    <div className="search_account">
      <InputBox
        id="input|search|adAccount"
        className="search_inp"
        labelClassName="lab_txt"
        placeholder="광고계정 이름을 입력하세요."
        onChange={handleQueryChange}
        onEnterKey={handleSearch}
        isShowingRemoveButton={true}
        onRemove={handleClear}
        active={!isLoading}
        value={query}>
        {!isLoading && (
          <button type="button" className="btn_search" onClick={handleSearch}>
            <span className="ico_gnb ico_search">검색</span>
          </button>
        )}
      </InputBox>
    </div>
  )
}

_AdAccountSelectSearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

const _AdAccountSelectListItem = ({
  currAdAccountId,
  style,
  adAccount,
  onSelect,
}) => {
  const { adAccountId, name, role, adAccountStatus } = adAccount

  const isSelected = currAdAccountId === adAccountId
  const isMaster = role === RoleType.MASTER
  const isOn = adAccountStatus?.includes(AD_ACCOUNT_STATUS.OPERATING) || false

  const onClick = React.useCallback(() => {
    onSelect(adAccount)
  }, [adAccount, onSelect])

  const link = React.useMemo(
    () =>
      DashboardRouter.Path.AdAccount({
        adAccountId,
      }),
    [adAccountId]
  )

  return (
    <li
      style={style}
      className={cx({
        on: isSelected,
      })}>
      <Link to={link} className="link_option" onClick={onClick}>
        <div className="opt_state">
          {isMaster && <span className="ico_badge ico_master">마스터</span>}
        </div>
        <span className="txt_option">
          <span className={cx({ ico_on: isOn, ico_off: !isOn })} />
          {name}
          <span className="txt_num">{adAccountId}</span>
        </span>
      </Link>
    </li>
  )
}

_AdAccountSelectListItem.propTypes = {
  currAdAccountId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
  style: PropTypes.object.isRequired,
  adAccount: PropTypes.object.isRequired,
  onSelect: PropTypes.func.isRequired,
}

const AdAccountSelectSearchInput = React.memo(_AdAccountSelectSearchInput)
export const AdAccountSelectListItem = React.memo(_AdAccountSelectListItem)

export default LnbAdAccountSelect
