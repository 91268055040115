import CampaignTypeEnum from '../../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../../enums/GoalEnum'
import ObjectiveDetailTypeEnum from '../../../../enums/ObjectiveDetailTypeEnum'
import AddFriendPage from './DisplayTemplates/Pages/AddFriendPage'
import {
  BgImage_2_1_Page6,
  BgImage_BottomHorizontalTitleActionButton_2_1_Page1,
  CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4,
  LeftImage_RightVerticalTitleProfileName_2_1_Page5,
  LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10,
  NAS_2_1_Page13,
  TopImage_BottomTitle_2_1_Page12,
  TopImage_BottomVerticalProfileImageAndNameActionButton_2_1_Page11,
  TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page7,
  TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9,
  TopImage_BottomVerticalTitleProfileName_2_1_Page2,
} from './DisplayTemplates/Pages/ImageNativeRatioPage_2_1'
import {
  BgImage_BottomVerticalTitleProfileName_1_1_Page3,
  LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page8,
  NAS_1_1,
} from './DisplayTemplates/Pages/ImageNativeRatioPage_1_1'
import { Short_Form_9_16_Page14 } from './DisplayTemplates/Pages/ImageNativeRatioPage_9_16'
import { fromJS } from 'immutable'
import { CreativeHelper } from '../../../../utils/helper/helper-creative'
import { irisVideoPreviewUrl } from '../../../../utils/app/inhouse'

const DEFAULT_PRESET_TYPE = 'P360'
const MOTION_BOARD_PRESET_TYPE = 'P258_NOSOUND'

const CreativePreviewUtils = {
  isAddFriendPageAvailable({ campaignType, goal, objectiveDetailType }) {
    return (
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      goal === GoalEnum.Type.CONVERSION &&
      objectiveDetailType === ObjectiveDetailTypeEnum.Type.ADD_FRIEND
    )
  },
}

CreativePreviewUtils.Template = {
  PageSequence: {
    IMAGE_NATIVE_RATIO_2_1: Object.freeze([
      AddFriendPage,
      BgImage_BottomHorizontalTitleActionButton_2_1_Page1,
      TopImage_BottomVerticalTitleProfileName_2_1_Page2,
      CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4,
      LeftImage_RightVerticalTitleProfileName_2_1_Page5,
      BgImage_2_1_Page6,
      TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page7,
      TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9,
      LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10,
      TopImage_BottomVerticalProfileImageAndNameActionButton_2_1_Page11,
      TopImage_BottomTitle_2_1_Page12,
      NAS_2_1_Page13,
      Short_Form_9_16_Page14,
    ]),
    IMAGE_NATIVE_RATIO_2_1_ELECTION_202203: Object.freeze([
      BgImage_BottomHorizontalTitleActionButton_2_1_Page1,
      TopImage_BottomVerticalTitleProfileName_2_1_Page2,
      CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4,
      LeftImage_RightVerticalTitleProfileName_2_1_Page5,
    ]),
    IMAGE_NATIVE_RATIO_2_1_ELECTION_2024: Object.freeze([
      TopImage_BottomVerticalTitleProfileName_2_1_Page2,
      CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4,
      TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9,
      TopImage_BottomTitle_2_1_Page12,
    ]),
    IMAGE_NATIVE_RATIO_1_1: Object.freeze([
      AddFriendPage,
      BgImage_BottomVerticalTitleProfileName_1_1_Page3,
      LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page8,
      LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10,
      NAS_1_1,
      Short_Form_9_16_Page14,
    ]),
    IMAGE_NATIVE_RATIO_9_16: Object.freeze([
      AddFriendPage,
      Short_Form_9_16_Page14,
    ]),
    IMAGE_NATIVE_ALL_TEMPLATE: Object.freeze([
      AddFriendPage,
      BgImage_BottomHorizontalTitleActionButton_2_1_Page1, // 1
      TopImage_BottomVerticalTitleProfileName_2_1_Page2, // 2
      BgImage_BottomVerticalTitleProfileName_1_1_Page3, // 3
      CenterImage_TopProfileName_BottomHorizontalTitleActionButton_2_1_Page4, // 4
      LeftImage_RightVerticalTitleProfileName_2_1_Page5, // 5
      BgImage_2_1_Page6, // 6
      TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page7, // 7
      LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page8, // 8
      TopImage_BottomVerticalTitleProfileImageAndNameActionButton_2_1_Page9, // 9
      LeftImage_RightVerticalTitleProfileNameActionButton_1_1_Page10, // 10
      TopImage_BottomVerticalProfileImageAndNameActionButton_2_1_Page11, // 11
      TopImage_BottomTitle_2_1_Page12, // 12
      NAS_2_1_Page13, // 13
      Short_Form_9_16_Page14, // 14
    ]),
  },
}

const VideoUtils = {
  extract({ videoData }) {
    const { video, image1, image2, uploadThumbnail, autoThumbnail } =
      videoData || {}

    if (image1 || image2) {
      return fromJS({
        video: CreativeHelper.Video.toView(video),
        autoThumbnail: CreativeHelper.Video.toView(image1),
        uploadThumbnail: CreativeHelper.Image.toView(image2),
      })
    }

    return fromJS({
      video,
      autoThumbnail,
      uploadThumbnail,
    })
  },
  getVideoUrl({ video }) {
    const { preview, url } = video || {}
    const { url: previewURL } = preview || {}
    return previewURL || url || ''
  },

  getThumbnailUrl({ autoThumbnail, uploadThumbnail }) {
    return uploadThumbnail?.get('url') || autoThumbnail?.get('url') || ''
  },
  getIrisPreviewUrl({ videoData }) {
    const { video, autoThumbnail, uploadThumbnail } = this.extract({
      videoData,
    })

    const videoUrl = this.getVideoUrl({ video })
    const thumbnailUrl = this.getThumbnailUrl({
      autoThumbnail,
      uploadThumbnail,
    })

    if (videoUrl && thumbnailUrl) {
      return irisVideoPreviewUrl({
        src: `https:${videoUrl}`,
        posterSrc: `https:${thumbnailUrl}`,
        landingUrl: '#',
      })
    }

    return ''
  },

  getVideoUrlV2({ video }) {
    const { preview, url, videoPresets } = video || {}
    const { url: previewURL } = preview || {}
    const { url: videoPresetsUrl } =
      videoPresets?.find(({ presetType }) =>
        [DEFAULT_PRESET_TYPE, MOTION_BOARD_PRESET_TYPE].includes(presetType)
      ) || {}
    return previewURL || videoPresetsUrl || url || ''
  },
}

export { CreativePreviewUtils, VideoUtils }
