import React, { useEffect } from 'react'
import InputBox from '../../../../../Common/InputBox'
import useComponentId from '../../../../../../utils/hook/useComponentId'
import { Radio, RadioGroup } from '../../../../../Common/RadioGroup/RadioGroup'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import {
  CMP_ASSET_LIBRARY_FILTER_OPTION,
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
} from '../cmpAssetLibraryUtils'
import PropTypes from 'prop-types'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'
import CreativeAssetPropertyEnum from '../../../../../../enums/CreativeAssetPropertyEnum'
import {
  getCmpAssetLibraryVideoRecentDataForDisplay,
  getCmpAssetLibraryVideoRecentDataForMessage,
  setCmpAssetLibraryVideoRecentSearchOptionForDisplay,
  setCmpAssetLibraryVideoRecentSearchOptionForMessage,
} from '../../../../../../modules/cmp/mCmpAssetLibraryVideo'
import { CMP_FILTER_OPERATOR } from '../../cmpUtils'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { usePrevious } from '../../../../../../utils/hook/usePrevious'
import CheckBox from '../../../../../Common/CheckBox'
import { isUndefinedOrNull } from '../../../../../../utils/regexUtils'

const FilterCheckBoxGroup = ({
  presets,
  filterConditionKey,
  filterCondition,
  setFilterCondition,
  active = true,
}) => {
  const prevActive = usePrevious(active)

  // 비활성 -> 활성으로 변경된 경우 모든 옵션 추가
  React.useEffect(() => {
    // prevActive 의 경우 undefined 가 발생해서 === false로 명확하게 표기
    if (prevActive === false && active) {
      setFilterCondition(prevCondition => {
        return {
          ...prevCondition,
          [filterConditionKey]: presets,
        }
      })
    }
  }, [active, filterConditionKey, presets, prevActive, setFilterCondition])

  const handlePresetChange = React.useCallback(
    presetObj => e => {
      setFilterCondition(prevCondition => {
        return {
          ...prevCondition,
          [filterConditionKey]: e.target.checked
            ? prevCondition[filterConditionKey].concat(presetObj)
            : prevCondition[filterConditionKey].filter(
                ({ id }) => id !== presetObj.id
              ),
        }
      })
    },
    [filterConditionKey, setFilterCondition]
  )

  const baseId = useComponentId()

  return (
    <ul className="list_filter">
      {presets.map(preset => {
        const key = `${baseId}|${preset.id}`

        return (
          <li key={key}>
            <CheckBox
              id={key}
              label={preset.label ?? preset.value}
              isChecked={filterCondition[filterConditionKey].some(
                ({ id }) => id === preset.id
              )}
              active={active}
              onChange={handlePresetChange(preset)}
            />
          </li>
        )
      })}
    </ul>
  )
}

FilterCheckBoxGroup.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  filterConditionKey: PropTypes.string.isRequired,
  filterCondition: PropTypes.object.isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

const CmpAssetLibraryVideoRecentFilter = ({
  adAccountId,
  cmpVideoType,
  creativeFormat,
  creativeAssetPropertyType,
  filterSearchOptions: {
    searchText,
    filterCondition: initialFilterCondition,
    filterType: initialFilterType,
  },
}) => {
  const dispatch = useDispatch()

  const [isOpened, setIsOpened] = React.useState(false)

  const searchInputId = useComponentId()

  const handleSearchTextChange = React.useCallback(
    (searchText = '') => {
      if (cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY) {
        dispatch(
          setCmpAssetLibraryVideoRecentSearchOptionForDisplay({
            keyPath: 'searchText',
            value: searchText,
          })
        )
      } else {
        dispatch(
          setCmpAssetLibraryVideoRecentSearchOptionForMessage({
            keyPath: 'searchText',
            value: searchText,
          })
        )
      }
    },
    [cmpVideoType, dispatch]
  )

  const handleSearch = React.useCallback(() => {
    if (cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY) {
      dispatch(
        getCmpAssetLibraryVideoRecentDataForDisplay({
          adAccountId,
          creativeFormat,
          creativeAssetPropertyType,
        })
      )
    } else {
      dispatch(
        getCmpAssetLibraryVideoRecentDataForMessage({
          adAccountId,
          creativeFormat,
          creativeAssetPropertyType,
        })
      )
    }
  }, [
    adAccountId,
    cmpVideoType,
    creativeAssetPropertyType,
    creativeFormat,
    dispatch,
  ])

  return (
    <div className="group_option">
      <InputBox
        id={searchInputId}
        value={searchText}
        className="search_inp2"
        placeholder="파일 이름을 입력하세요."
        labelClassName="lab_txt"
        isShowingRemoveButton={true}
        maxLength={30}
        onChange={e => {
          handleSearchTextChange(e.target.value)
        }}
        onEnterKey={handleSearch}
        onRemove={() => {
          handleSearchTextChange('')
        }}>
        <button type="button" className="btn_search" onClick={handleSearch}>
          <span className="ico_comm ico_search">검색</span>
        </button>
      </InputBox>
      <button
        type="button"
        className={cx('btn_filter', { on: isOpened })}
        onClick={() => {
          setIsOpened(!isOpened)
        }}>
        <span className="ico_comm">필터 설정</span>
      </button>
      {isOpened && (
        <FilterLayer
          cmpVideoType={cmpVideoType}
          initialFilterCondition={initialFilterCondition.toJS()}
          initialFilterType={initialFilterType}
          handleSearch={handleSearch}
          dismiss={() => {
            setIsOpened(false)
          }}
        />
      )}
    </div>
  )
}

CmpAssetLibraryVideoRecentFilter.propTypes = {
  cmpVideoType: PropTypes.oneOf(Object.keys(CMP_ASSET_LIBRARY_VIDEO_TYPE))
    .isRequired,
  adAccountId: PropTypes.any.isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
  filterSearchOptions: ImmutablePropTypes.shape({
    searchText: PropTypes.string.isRequired,
    filterCondition: ImmutablePropTypes.map.isRequired,
    filterType: PropTypes.string,
  }).isRequired,
}

const FILTER_PRESET = {
  DURATION: [
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
      value: { duration: { min: 1 }, operator: CMP_FILTER_OPERATOR.GTE },
      label: '전체',
    },
    {
      id: 'LT|5',
      value: { duration: { max: 4.999999 }, operator: CMP_FILTER_OPERATOR.LTE },
      label: '5초 미만',
    },
    {
      id: 'LT|15',
      value: {
        duration: { max: 14.999999 },
        operator: CMP_FILTER_OPERATOR.LTE,
      },
      label: '15초 미만',
    },
    {
      id: 'LT|30',
      value: {
        duration: { max: 29.999999 },
        operator: CMP_FILTER_OPERATOR.LTE,
      },
      label: '30초 미만',
    },
    {
      id: 'GTE|30',
      value: { duration: { min: 30 }, operator: CMP_FILTER_OPERATOR.GTE },
      label: '30초 이상',
    },
  ],
  SIZE: [
    {
      id: '1280|720',
      value: {
        width: { min: 1280 },
        height: { min: 720 },
        ratio: { eq: 1.7777 },
      },
      label: '1280x720 이상의 16:9 비율',
    },
    {
      id: '1290|258',
      value: { width: { eq: 1290 }, height: { eq: 258 } },
      label: '1290 x 258',
    },
  ],
}

const FilterLayer = ({
  cmpVideoType,
  initialFilterType,
  initialFilterCondition,
  handleSearch,
  dismiss,
}) => {
  const dispatch = useDispatch()

  const [filterCondition, setFilterCondition] = React.useState(
    initialFilterCondition
  )

  const baseIdDurationPreset = useComponentId()

  useEffect(() => {
    if (isUndefinedOrNull(initialFilterCondition.duration?.value)) {
      setFilterCondition(prev => ({
        ...prev,
        duration: FILTER_PRESET.DURATION[0],
      }))
    }
  }, [initialFilterCondition.duration.value])

  return (
    <div className="layer_filter" style={{ display: 'block' }}>
      <div className="inner_g">
        <strong className="tit_filter">동영상 필터 설정</strong>
        <em className="subtit_filter">재생시간</em>
        <RadioGroup
          Component="ul"
          className="list_filter"
          isEquals={radioItemDurationObj =>
            radioItemDurationObj.id === filterCondition.duration.id
          }
          onChange={nextDurationPreset => {
            setFilterCondition(prevFilterCondition => ({
              ...prevFilterCondition,
              duration: nextDurationPreset,
            }))
          }}
          name={baseIdDurationPreset}
          selectedValue={filterCondition.duration}>
          {FILTER_PRESET.DURATION.map(durationPreset => {
            const key = `${baseIdDurationPreset}|${durationPreset.id}`

            return (
              <li key={key}>
                <Radio
                  id={key}
                  label={durationPreset.label}
                  value={durationPreset}
                />
              </li>
            )
          })}
        </RadioGroup>
        <div className="btn_group">
          <button
            type="button"
            className={cx('btn_gm gm_bl', { in_active: false })}
            onClick={() => {
              // validation

              if (cmpVideoType === CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY) {
                dispatch(
                  setCmpAssetLibraryVideoRecentSearchOptionForDisplay({
                    keyPath: 'filterCondition',
                    value: filterCondition,
                  })
                )
              } else {
                dispatch(
                  setCmpAssetLibraryVideoRecentSearchOptionForMessage({
                    keyPath: 'filterCondition',
                    value: filterCondition,
                  })
                )
              }

              handleSearch()

              dismiss()
            }}>
            <span className="inner_g">적용</span>
          </button>
        </div>
      </div>
    </div>
  )
}

FilterLayer.propTypes = {
  cmpVideoType: PropTypes.oneOf(Object.keys(CMP_ASSET_LIBRARY_VIDEO_TYPE))
    .isRequired,
  initialFilterCondition: PropTypes.shape({
    duration: PropTypes.object,
  }).isRequired,
  initialFilterType: PropTypes.string,
  handleSearch: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
}

export default CmpAssetLibraryVideoRecentFilter
