import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'

const Button = ({
  label = '',
  onClick = undefined,
  className = 'btn_gm gm_bl',
}) => {
  return (
    <button type="button" className={className} onClick={onClick}>
      <span className="inner_g">{label}</span>
    </button>
  )
}

Button.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

class SimplePopup extends React.PureComponent {
  static propTypes = {
    // PopupProxy props
    popupProxy: PropTypes.any,
    // PopupContainer props
    index: PropTypes.number,
    close: PropTypes.func,
  }

  popupRef = React.createRef()

  componentDidMount() {
    const V = this.popupRef.current
    if (V?.clientHeight) {
      const calculateMarginTop = -(V.clientHeight / 2)
      const calculateMarginLeft = -(V.clientWidth / 2)
      V.style.marginTop = `${calculateMarginTop}px`
      V.style.marginLeft = `${calculateMarginLeft}px`
    }

    const {
      props: { onShow },
    } = this.props.popupProxy

    if (typeof onShow === 'function') onShow() // callback param 으로 필요한 게 있을까?
  }

  componentWillUnmount() {
    const {
      props: { onDismiss },
    } = this.props.popupProxy

    if (typeof onDismiss === 'function') onDismiss() // callback param 으로 필요한 게 있을까?
  }

  render() {
    const {
      close,
      popupProxy: {
        props: {
          primaryButtonText,
          secondaryButtonText,
          children,
          hasSecondaryButton,
          hasCloseButton,
        },
      },
    } = this.props

    return (
      <div
        className={cx('layer_wrap', { layer_dimmed: this.props.index !== 0 })}>
        <div ref={this.popupRef} className="basic_layer2">
          <div className="inner_basic_layer" onClick={e => e.stopPropagation()}>
            {children}
            <div className="btn_group">
              {hasSecondaryButton && (
                <Button
                  label={secondaryButtonText}
                  onClick={this.handleSecondaryButtonClick}
                  className="btn_gm"
                />
              )}
              <Button
                label={primaryButtonText}
                onClick={this.handlePrimaryButtonClick}
              />
            </div>
            {hasCloseButton && (
              <a className="btn_close" onClick={close}>
                <span className="ico_comm ico_close">닫기</span>
              </a>
            )}
          </div>
        </div>
      </div>
    )
  }

  handlePrimaryButtonClick = () => {
    const {
      props: { primaryButtonFunc, autoClose },
    } = this.props.popupProxy

    /**
     * primaryButtonFunc 보다 반드시 먼저 수행한다.
     * func 이 popup open action 인 경우 close 가 이후 수행되어 닫히게 됨.
     */
    if (autoClose) {
      this.props.close()
    }

    if (typeof primaryButtonFunc === 'function') primaryButtonFunc()
  }

  handleSecondaryButtonClick = () => {
    const {
      props: { secondaryButtonFunc },
    } = this.props.popupProxy

    /**
     * secondaryButtonFunc 보다 반드시 먼저.
     * func 이 popup open action 인 경우 close 가 이후 수행되어 닫히게 됨.
     */
    this.props.close()

    if (typeof secondaryButtonFunc === 'function') secondaryButtonFunc()
  }
}

export default SimplePopup
