import React, { lazy } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import Gnb from '../Navigation/Gnb/Gnb'
import Lnb from '../Navigation/Lnb/Lnb'
import Footer from '../Footer/Footer'

const ReportV2 = lazy(() => import('./ReportV2'))
const ReportDetail = lazy(() => import('./ReportDetail'))

const ReportWrapper = () => {
  return (
    <div id="kakaoWrap">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb />
        <React.Suspense fallback="">
          <Switch>
            <Route
              exact
              path="/:adaccountid(\d+)/report"
              component={ReportV2}
            />
            {/* 보고서 생성/수정 페이지 구분은 id > 0 로 구분. */}
            <Route
              exact
              path={[
                '/:adaccountid(\\d+)/report/create',
                '/:adaccountid(\\d+)/report/:id(\\d+)',
              ]}
              component={ReportDetail}
            />
            <Redirect
              from="/:adaccountid(\d+)/report/customreport/all"
              to="/:adaccountid(\d+)/report"
            />
            <Redirect
              from="/:adaccountid(\d+)/report/customreport"
              to="/:adaccountid(\d+)/report"
            />
            <Redirect
              from="/:adaccountid(\d+)/report/customreport/:customReportId(\d+)"
              to="/:adaccountid(\d+)/report/:id(\d+)"
            />
          </Switch>
        </React.Suspense>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

export default ReportWrapper
