import CruxFetchUtils from '../../utils/cruxFetchUtils'
import { CONTEXTPATH, DOMINO, fetchGet } from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'
import { checkNotEmpty } from '../../utils/regexUtils'

/**
 * 고객식별자(전화번호/앱유저아이디) 업로드
 * @param adAccountId
 * @param formData
 */
function submitPlusFriendGroupFilesForTargeting(adAccountId, formData) {
  return CruxFetchUtils.post({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles/upload`,
    requestPayload: formData,
    adAccountId,
  })
}

/**
 * 친구그룹 등록
 * @param adAccountId
 * @param formData
 */
function submitPlusFriendGroupFileRegistrationForm(adAccountId, formData) {
  return CruxFetchUtils.post({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles`,
    requestPayload: formData,
    adAccountId,
  })
}

/**
 * 친구그룹 삭제
 * @param adAccountId
 * @param plusFriendGroupFileId
 */
function deletePlusFriendGroupFile(adAccountId, plusFriendGroupFileId) {
  return CruxFetchUtils.delete({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles/${plusFriendGroupFileId}`,
    adAccountId,
  })
}

/**
 * 여러개의 plusFriendGroupFile 삭제
 * @param adAccountId
 * @param plusFriendGroupFileIds
 * @returns {Promise | Promise<unknown>}
 */
function deleteMultipleFriendGroupFiles(adAccountId, plusFriendGroupFileIds) {
  return CruxFetchUtils.delete({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles?${queryString({
      plusFriendGroupFileIds,
    })}`,
    adAccountId,
  })
}

/**
 * 친구그룹 목록 조회
 * @param adAccountId
 * @param currentPage
 * @param size
 */
function getPlusFriendGroupFiles(adAccountId, currentPage = 0, size = 10) {
  return CruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles?page=${currentPage}&size=${size}`,
    adAccountId,
  })
}

/**
 * 친구그룹 상세 조회
 * @param adAccountId
 * @param plusFriendGroupFileId
 */
function getPlusFriendGroupFileDetail(adAccountId, plusFriendGroupFileId) {
  return CruxFetchUtils.get({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles/${plusFriendGroupFileId}`,
    adAccountId,
  })
}

/**
 * 친구그룹 이름 수정
 * @param adAccountId
 * @param form
 * @param plusFriendGroupFileId
 * PUT  /adAccounts/{adAccountId}/customerFiles/{customerFileId}
 */
function modifyPlusFriendGroupFileName(
  adAccountId,
  form,
  plusFriendGroupFileId
) {
  return CruxFetchUtils.put({
    url: `${DOMINO}/${adAccountId}/plusFriendGroupFiles/${plusFriendGroupFileId}`,
    requestPayload: form,
    adAccountId,
  })
}

/**
 * 친구 그룹 파일 업로드
 * @param adAccountId
 * @param path
 * @param data
 * @param originalFileName
 */
function uploadPlusFriendGroupFile(adAccountId, path, data, originalFileName) {
  const params = checkNotEmpty(originalFileName)
    ? { path, filename: encodeURIComponent(originalFileName) }
    : { path }

  return CruxFetchUtils.post({
    url: `${DOMINO}/upload/secure?${queryString(params)}`,
    requestPayload: data,
    adAccountId,
  })
}

/**
 * 7.9.8 카카오톡 채널 그룹을 사용중인 광고그룹 조회
 * @param adAccountId
 * @param plusFriendGroupFileId
 * @param plusFriendProfileId
 * @param currentPage
 * @param size
 * @return {*}
 */
function getPlusFriendGroupFileDetailAdGroupList(
  adAccountId,
  plusFriendGroupFileId,
  plusFriendProfileId,
  currentPage = 0,
  size = 10
) {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/plusFriendGroupFiles/${plusFriendGroupFileId}/adGroups?plusFriendProfileId=${plusFriendProfileId}&page=${currentPage}&size=${size}`,
    adAccountId
  )
}

export default {
  submitPlusFriendGroupFilesForTargeting,
  submitPlusFriendGroupFileRegistrationForm,
  deletePlusFriendGroupFile,
  deleteMultipleFriendGroupFiles,
  getPlusFriendGroupFiles,
  getPlusFriendGroupFileDetail,
  modifyPlusFriendGroupFileName,
  getPlusFriendGroupFileDetailAdGroupList,
  uploadPlusFriendGroupFile,
}
