import { getApiPhase } from './kakaoMoment'
import { checkNoneEmpty, isUndefinedOrNull } from '../../regexUtils'

const CatalogService = {
  host: {
    production: 'https://catalog.kakao.com',
    stage: 'https://catalog-cbt.devel.kakao.com',
    sandbox: 'https://catalog-sandbox.devel.kakao.com',
    develop: 'https://catalog-sandbox.devel.kakao.com', // 불필요
  },

  url() {
    return CatalogService.host[getApiPhase()] || CatalogService.host.sandbox
  },

  // 상세
  detailUrl(catalogId) {
    if (isUndefinedOrNull(catalogId)) {
      return this.url()
    }

    return `${this.url()}/catalogs/${catalogId}`
  },

  // 상세 > 상품 관리 > 상품 탭
  productsTabUrl(catalogId) {
    if (isUndefinedOrNull(catalogId)) {
      return this.url()
    }

    return `${this.detailUrl(catalogId)}/products`
  },

  // 상세 > 상품 관리 > 상품 탭 (특정 상품 필터)
  productsTabItemUrl(catalogId, itemId) {
    if (!checkNoneEmpty(catalogId, itemId)) {
      return this.url()
    }

    return `${this.detailUrl(catalogId)}/products/${itemId}`
  },

  // 상세 > 상품 관리 > 세트 탭 (특정 세트 선택)
  productSetsUrl(catalogId, productSetsId) {
    if (!checkNoneEmpty(catalogId, productSetsId)) {
      return this.url()
    }

    return `${this.detailUrl(catalogId)}/product-sets/${productSetsId}`
  },
}

export default CatalogService
