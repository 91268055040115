import React from 'react'
import PropTypes from 'prop-types'
import ReactRouterPropTypes from 'react-router-prop-types'
import { connect } from 'react-redux'
import { agencyLogin } from '../../modules/common/mUser'
import { checkNotEmpty } from '../../utils/regexUtils'
import { login } from '../../utils/authenticationUtils'

class AgencyLoginCheck extends React.Component {
  componentDidMount() {
    const { adaccountid } = this.props.match.params
    if (checkNotEmpty(adaccountid)) {
      this.props.agencyLogin(adaccountid)
    } else {
      login()
    }
  }

  render() {
    return null
  }
}

AgencyLoginCheck.propTypes = {
  agencyLogin: PropTypes.func,
  match: ReactRouterPropTypes.match,
  history: ReactRouterPropTypes.history
}

const mapDispatchToProps = {
  agencyLogin
}

export default connect(
  null,
  mapDispatchToProps
)(AgencyLoginCheck)
