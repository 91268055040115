import { keyMirror } from '../../utils/utils'
import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'

const MessageCreative = keyMirror({
  MESSAGE_CREATIVE_INITIALIZE: null,
  MESSAGE_CREATIVE_SET_BY_KEY_PATH: null,
  MESSAGE_CREATIVE_CLEAR: null,
})

const initialState = Map({
  profileId: -1,
  profileName: '',
  profileImageUrl: '',
  csInfo: '',
  creativeFormat: CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
  isCompleted: false,
  profileHomeInfo: null,
})

export default createReducer(initialState, {
  [MessageCreative.MESSAGE_CREATIVE_INITIALIZE]: (state, { data }) =>
    state.merge(fromJS(data)),

  [MessageCreative.MESSAGE_CREATIVE_SET_BY_KEY_PATH]: (
    state,
    { keyPath, value }
  ) => state.setIn([...coerceToArray(keyPath)], fromJS(value)),

  [MessageCreative.MESSAGE_CREATIVE_CLEAR]: () => initialState,
})

export function initializeCreativeMessage(messageForm, campaignType) {
  return {
    type: MessageCreative.MESSAGE_CREATIVE_INITIALIZE,
    data: TalkChannelMessageHelper.defaultInitialize({
      messageForm,
      campaignType,
    }),
  }
}

export function setCreativeMessageByKey(keyPath, value) {
  return {
    type: MessageCreative.MESSAGE_CREATIVE_SET_BY_KEY_PATH,
    keyPath,
    value,
  }
}

export function clearCreativeMessage() {
  return {
    type: MessageCreative.MESSAGE_CREATIVE_CLEAR,
  }
}

export function initPlusFriendInfo(plusFriendInfo) {
  const {
    id: profileId,
    name: profileName,
    profileImageUrl,
    csInfo,
    encodedId,
    bizChannel: isBizChannel,
    profileHomeInfo,
  } = plusFriendInfo

  const { type } = profileHomeInfo || {}
  const isProfileHomeInfo = type === 'web'

  return {
    type: MessageCreative.MESSAGE_CREATIVE_INITIALIZE,
    data: {
      profileId,
      profileName,
      profileImageUrl,
      csInfo,
      encodedId,
      isBizChannel,
      isProfileHomeInfo,
    },
  }
}

export function initCreativeMessageForm() {
  return dispatch => {
    dispatch(
      initializeCreativeMessage(
        TalkChannelMessageHelper.Basic.createDefault().set('isCompleted', false)
      )
    )
  }
}
