import { Map } from 'immutable'
import { CREATIVE_FORM_VALIDATION_KEY } from './creativeFormValidationKey'
import {
  validateCompatibleLandingUrl,
  validateCreativeAltText,
  validateCreativeName,
  validateCreativeOpinionProofComment,
  validateCreativeProfileName,
  validateLandingInfo,
} from './creativeFormValidatorV2'
import {
  checkEmpty,
  checkNotEmpty,
  checkUrl,
  hasFullWidthWhitespace,
  validateInputLength,
} from '../../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../../validation'
import { CREATIVE_FORM_VALIDATION_MESSAGE } from './creativeFormValidationMessage'
import formUtils from '../../../components/AdvertiseV2/Creative/Form/formUtils'

const PC_TALK_BOTTOM_FORM_VALIDATION_KEY_PATH = Map({
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: ['altText'],
  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: ['landingInfo'],
  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: ['clickTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: ['eventTrackerUrl'],
  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_BACKGROUND_IMAGE_CREATE]: [
    'pcTalkBottomBundles',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_BACKGROUND_IMAGE_MODIFY]: [
    'backgroundImage',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_OBJECT_IMAGE_CREATE]: [
    'pcTalkBottomBundles',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_OBJECT_IMAGE_MODIFY]: [
    'objectImage',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_PROMOTIONAL_IMAGE]: [
    'compatibleImage',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_LANDING_URL]: [
    'compatiblePcLandingUrl',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_TITLE]: ['compatibleTitle'],
  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: ['profileName'],
  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: [
    'opinionProof',
    'opinion',
  ],
  [CREATIVE_FORM_VALIDATION_KEY.NAME]: ['name'],
})

const PC_TALK_BOTTOM_FORM_VALIDATOR = Map({
  [CREATIVE_FORM_VALIDATION_KEY.ALT_TEXT]: altText => {
    return validateCreativeAltText(altText)
  },

  [CREATIVE_FORM_VALIDATION_KEY.LANDING_SCHEME]: landingInfo => {
    return validateLandingInfo(landingInfo)
  },

  [CREATIVE_FORM_VALIDATION_KEY.CLICK_TRACKER_URL]: clickTrackerUrl => {
    if (checkNotEmpty(clickTrackerUrl)) {
      if (!checkUrl(clickTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(clickTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.EVENT_TRACKER_URL]: eventTrackerUrl => {
    if (checkNotEmpty(eventTrackerUrl)) {
      if (!checkUrl(eventTrackerUrl)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.LANDING_URL)
      }

      return formUtils.Url.validateUrl(eventTrackerUrl)
    }
    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_BACKGROUND_IMAGE_CREATE]:
    pcTalkBottomBundles => {
      if (!pcTalkBottomBundles || pcTalkBottomBundles.isEmpty()) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }

      const pcTalkBottomBundleValidationResults = []

      pcTalkBottomBundles.forEach(
        ({ bundleUUID, backgroundImage, objectImage }) => {
          const validationResultPcTalkBottomImage =
            !backgroundImage || backgroundImage.isEmpty()
              ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
              : IS_VALID()
          const validationResultPcTalkBottomObjectImage =
            !objectImage || objectImage.isEmpty()
              ? IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
              : IS_VALID()
          const isValid = [
            validationResultPcTalkBottomImage,
            validationResultPcTalkBottomObjectImage,
          ].every(v => v.isValid)

          pcTalkBottomBundleValidationResults.push({
            bundleUUID,
            image: validationResultPcTalkBottomImage,
            objectImage: validationResultPcTalkBottomObjectImage,
            isValid,
          })
        }
      )

      const isAllValid = pcTalkBottomBundleValidationResults.every(
        v => v?.isValid
      )

      return isAllValid
        ? IS_VALID()
        : IS_NOT_VALID('', { pcTalkBottomBundleValidationResults })
    },

  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_BACKGROUND_IMAGE_MODIFY]:
    backgroundImage => {
      if (checkEmpty(backgroundImage)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.PC_TALK_BOTTOM_OBJECT_IMAGE_MODIFY]:
    objectImage => {
      if (checkEmpty(objectImage)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_PROMOTIONAL_IMAGE]:
    compatibleImage => {
      if (checkEmpty(compatibleImage)) {
        return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
      }
      return IS_VALID()
    },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_TITLE]: (
    compatibleTitle,
    formData
  ) => {
    const { profileName } = formData || {}

    if (checkEmpty(compatibleTitle)) {
      return IS_NOT_VALID(CREATIVE_FORM_VALIDATION_MESSAGE.IS_REQUIRED)
    }

    if (hasFullWidthWhitespace(compatibleTitle)) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.HAS_FULL_WIDTH_WHITE_SPACE
      )
    }
    if (!validateInputLength(compatibleTitle, 1, 25)) {
      return IS_NOT_VALID('타이틀은 1~25자까지 입력가능합니다.')
    }

    if (profileName === compatibleTitle) {
      return IS_NOT_VALID(
        CREATIVE_FORM_VALIDATION_MESSAGE.DIFFERENT_VALUE_COMPATIBLE_TITLE
      )
    }

    return IS_VALID()
  },

  [CREATIVE_FORM_VALIDATION_KEY.COMPATIBLE_LANDING_URL]: (
    compatiblePcLandingUrl,
    formData
  ) => {
    return validateCompatibleLandingUrl({ compatiblePcLandingUrl, formData })
  },

  [CREATIVE_FORM_VALIDATION_KEY.PROFILE_NAME]: (profileName, formData) => {
    return validateCreativeProfileName(profileName, formData)
  },

  [CREATIVE_FORM_VALIDATION_KEY.OPINION_PROOF_COMMENT]: opinionProofComment => {
    return validateCreativeOpinionProofComment(opinionProofComment)
  },

  [CREATIVE_FORM_VALIDATION_KEY.NAME]: name => {
    return validateCreativeName(name)
  },
})

export {
  PC_TALK_BOTTOM_FORM_VALIDATION_KEY_PATH,
  PC_TALK_BOTTOM_FORM_VALIDATOR,
}
