import {
  CONTEXTPATH,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
  queryString,
} from '../../utils/fetchUtils'
import RequestLock from '../../utils/requestLock'

/**
 * 7.4.11 오디언스 목록 조회
 * @param adAccountId
 * @param queryParams
 */
const fetchAudienceList = (adAccountId, queryParams) => {
  return fetchGet(
    `${CONTEXTPATH}/targeting/audiences?${queryString(queryParams)}`,
    adAccountId
  )
}

/**
 * 7.4.11.1 광고만들기에서 오디언스 목록 조회
 * @param adAccountId
 * @param requestBody
 */
const loadAudienceList = (adAccountId, requestBody) => {
  return fetchPost(
    `${CONTEXTPATH}/targeting/audiences`,
    requestBody,
    adAccountId
  )
}

/**
 * 7.4.14 오디언스 사용현황
 * @param adAccountId
 * @param audienceId
 * @param queryParams
 */
const fetchAudienceUsageList = (adAccountId, audienceId, queryParams) => {
  return fetchGet(
    `${CONTEXTPATH}/targeting/${audienceId}/adGroups?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

/**
 * 7.4.15 오디언스 조회하기
 * @param adAccountId
 * @param audienceId
 */
const fetchAudienceInfoById = (adAccountId, audienceId) => {
  return fetchGet(`${CONTEXTPATH}/targeting/${audienceId}`, adAccountId)
}

/**
 * 7.8.6.1 내 광고 반응 타겟 사용중인 오디언스 조회
 * @param adAccountId
 * @param cohortId
 * @param queryParams
 */
const fetchCohortTargetAudienceUsageList = (
  adAccountId,
  cohortId,
  queryParams
) => {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/cohorts/${cohortId}/audiences?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

/**
 * 7.5.8.1 고객파일을 사용중인 오디언스 조회
 * @param adAccountId
 * @param customerFileId
 * @param queryParams
 */
const fetchCustomerFileTargetAudienceUsageList = (
  adAccountId,
  customerFileId,
  queryParams
) => {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/customerFiles/${customerFileId}/audiences?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

/**
 * 7.9.8.1 카카오톡 채널 그룹을 사용중인 오디언스 조회
 * @param adAccountId
 * @param plusFriendGroupFileId
 * @param queryParams
 */
const fetchPlusFriendGroupTargetAudienceUsageList = (
  adAccountId,
  plusFriendGroupFileId,
  queryParams
) => {
  return fetchGet(
    `${CONTEXTPATH}/adAccounts/${adAccountId}/plusFriendGroupFiles/${plusFriendGroupFileId}/audiences?${queryString(
      queryParams
    )}`,
    adAccountId
  )
}

/**
 * 7.4.8.1 오디언스 조합 예상모수 조회
 * @param adAccountId
 * @param requestBody
 * @param cancelToken
 */
const fetchAudienceCombinedPopulation = (
  adAccountId,
  requestBody,
  cancelToken
) => {
  return fetchPost(
    `${CONTEXTPATH}/targeting/population/combinedScore/audience`,
    requestBody,
    adAccountId,
    cancelToken
  )
}

/**
 * 7.4.10 오디언스 생성하기
 * @param adAccountId
 * @param requestBody
 */
const submitAudience = (adAccountId, requestBody) => {
  return RequestLock.acquire({
    key: 'submitAudience',
    executor: done =>
      fetchPost(`${CONTEXTPATH}/targeting`, requestBody, adAccountId).finally(
        () => {
          done()
        }
      ),
  })
}

/**
 * 7.4.12 오디언스 수정하기
 * @param adAccountId
 * @param requestBody
 */
const modifyAudience = (adAccountId, requestBody) => {
  return RequestLock.acquire({
    key: 'modifyAudience',
    executor: done =>
      fetchPut(`${CONTEXTPATH}/targeting`, requestBody, adAccountId).finally(
        () => {
          done()
        }
      ),
  })
}

/**
 * 7.4.13 오디언스 삭제하기
 * @param adAccountId
 * @param audienceId
 */
const deleteAudience = (adAccountId, audienceId) => {
  return RequestLock.acquire({
    key: 'deleteAudience',
    executor: done =>
      fetchDelete(
        `${CONTEXTPATH}/targeting/${audienceId}`,
        null,
        adAccountId
      ).finally(() => {
        done()
      }),
  })
}

/**
 * 7.4.13.1 여러개의 오디언스 삭제하기
 * @param adAccountId
 * @param audienceIds
 */
const deleteMultipleAudience = (adAccountId, audienceIds) => {
  return RequestLock.acquire({
    key: 'deleteMultipleAudience',
    executor: done =>
      fetchDelete(
        `${CONTEXTPATH}/targeting?${queryString({ audienceIds })}`,
        null,
        adAccountId
      ).finally(() => {
        done()
      }),
  })
}

/**
 * 7.4.16 오디언스 연동해제
 * @param adAccountId
 * @param adGroupId
 * @param audienceId
 */
const requestUnusedAudience = (adAccountId, adGroupId, audienceId) => {
  return RequestLock.acquire({
    key: '',
    executor: done =>
      fetchPut(
        `${CONTEXTPATH}/targeting/${audienceId}/unlink?adGroupId=${adGroupId}`,
        null,
        adAccountId
      ).finally(() => {
        done()
      }),
  })
}

/**
 * 7.4.17 오디언스 수정 여부 확인
 * @param adAccountId
 * @param audienceId
 */
const fetchAudienceModifiable = (adAccountId, audienceId) => {
  return fetchGet(
    `${CONTEXTPATH}/targeting/${audienceId}/isModifiable`,
    adAccountId
  )
}

export default {
  fetchAudienceList,
  loadAudienceList,
  fetchAudienceUsageList,
  fetchAudienceCombinedPopulation,
  fetchAudienceInfoById,
  submitAudience,
  modifyAudience,
  deleteAudience,
  deleteMultipleAudience,
  requestUnusedAudience,
  fetchCohortTargetAudienceUsageList,
  fetchCustomerFileTargetAudienceUsageList,
  fetchPlusFriendGroupTargetAudienceUsageList,
  fetchAudienceModifiable,
}
