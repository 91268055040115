import React from 'react'
import PropTypes from 'prop-types'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { cancelRequest } from '../../../../../../../utils/cancellation/cancellation'
import { MomentLoader } from '../../../../../../Common/Loader/MomentLoader'
import CmpAssetLibraryVideoRecentFilter from '../CmpAssetLibraryVideoRecentFilter'
import CreativeAssetPropertyEnum from '../../../../../../../enums/CreativeAssetPropertyEnum'
import CreativeFormatEnum from '../../../../../../../enums/CreativeFormatEnum'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { getCmpAssetLibraryVideoRecentDataForDisplay } from '../../../../../../../modules/cmp/mCmpAssetLibraryVideo'
import {
  CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT,
  CMP_ASSET_LIBRARY_VIDEO_TYPE,
} from '../../cmpAssetLibraryUtils'
import CmpAssetLibraryVideoDisplayAssetView from './CmpAssetLibraryVideoDisplayAssetView'
import Pagination from '../../../../../../Common/Pagenation/Pagenation'

const selector = ({
  cmpAssetLibrary: {
    video: {
      recent: { searchOptions, recentData },
      selectedItems,
      viewState: { isFetchingRecent },
    },
  },
}) => {
  return {
    searchOptions,
    recentData,
    selectedItems,
    isFetchingRecent,
  }
}

const CmpAssetLibraryVideoDisplayRecent = ({
  adAccountId,
  creativeFormat,
  creativeAssetPropertyType = CreativeAssetPropertyEnum.Type.VIDEO,
  assetConstraint,
  renderGuideView,
  validateVideoSize,
}) => {
  const dispatch = useDispatch()

  const {
    searchOptions: filterSearchOptions,
    recentData,
    selectedItems,
    isFetchingRecent,
  } = useSelector(selector, shallowEqual)

  const {
    query,
    content: items,
    number: pageIndex,
    size: pageSize,
    totalElements,
  } = recentData || {}

  const fetchRecent = React.useCallback(
    ({ page = 0, size = 50 } = {}) => {
      dispatch(
        getCmpAssetLibraryVideoRecentDataForDisplay({
          adAccountId,
          creativeFormat,
          creativeAssetPropertyType,
          page,
          size,
        })
      )
    },
    [adAccountId, creativeAssetPropertyType, creativeFormat, dispatch]
  )

  React.useEffect(() => {
    // empty 일 떄만 로드하도록 한다(탭 변경 시 유지되어야 한다).
    if (items.isEmpty()) {
      fetchRecent()
    }

    return () => {
      cancelRequest(CMP_ASSET_LIBRARY_CANCEL_KEY_RECENT)
    }
  }, [fetchRecent, items])

  const handlePageChange = currentIndex => {
    if (currentIndex !== pageIndex) {
      fetchRecent({ page: currentIndex, size: pageSize })
    }
  }

  const handlePageSizeChange = nextPageSize => {
    if (pageSize !== nextPageSize) {
      fetchRecent({ page: 0, size: nextPageSize })
    }
  }

  return (
    <div className="wrap_material">
      <div className="group_info">
        {typeof renderGuideView === 'function'
          ? renderGuideView()
          : renderGuideView}
        <CmpAssetLibraryVideoRecentFilter
          adAccountId={adAccountId}
          cmpVideoType={CMP_ASSET_LIBRARY_VIDEO_TYPE.DISPLAY}
          creativeFormat={creativeFormat}
          creativeAssetPropertyType={creativeAssetPropertyType}
          assetConstraint={assetConstraint}
          filterSearchOptions={filterSearchOptions}
        />
      </div>
      <div className="group_material">
        {isFetchingRecent ? (
          <div className="reform_box_nodata">
            <div className="txt_nodata">
              <MomentLoader />
            </div>
          </div>
        ) : totalElements > 0 ? (
          <div className="inner_material">
            <ul className="list_material">
              {items.map((item, index) => {
                const { videoUUID } = item
                const isSelected =
                  selectedItems.findIndex(
                    ({ videoUUID: selectedItemVideoUUID }) =>
                      selectedItemVideoUUID === videoUUID
                  ) >= 0

                return (
                  <CmpAssetLibraryVideoDisplayAssetView
                    key={videoUUID}
                    index={index}
                    item={item}
                    query={query}
                    isSelected={isSelected}
                    isVisibleAnim={false}
                    validateVideoSize={validateVideoSize}
                  />
                )
              })}
            </ul>
          </div>
        ) : (
          <div className="reform_box_nodata">
            <div className="txt_nodata">최근 사용한 동영상이 없습니다.</div>
          </div>
        )}
      </div>
      <Pagination
        currentIndex={pageIndex}
        totalElements={totalElements}
        paginationVisible={!isFetchingRecent && totalElements > 0}
        onChangePage={handlePageChange}
        pageSize={pageSize}
        isPageOptionEnable={true}
        onPageSizeChange={handlePageSizeChange}
      />
    </div>
  )
}

CmpAssetLibraryVideoDisplayRecent.propTypes = {
  adAccountId: PropTypes.any.isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
  assetConstraint: ImmutablePropTypes.map.isRequired,
  //
  renderGuideView: PropTypes.any,
  validateVideoSize: PropTypes.func,
}

export default CmpAssetLibraryVideoDisplayRecent
