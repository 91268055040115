import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import creativeApi from '../../../../../modules-api/advertise/adCreativeApi'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'

const CROP_ASPECT_STRING = {
  1: '1:1',
  2: '2:1',
}

const CROP_ASPECT_NAME_STRING = {
  1: '정사각형(1:1)',
  2: '가로 방향(2:1)',
}

const CROP_ASPECT_SIZE = {
  1: '500x500',
  2: '1200x600',
}

const ImageEditorUtils = {
  ZOOM: {
    MIN: 1,
    MAX: 10,
    STEP: 0.01,
  },

  EXTENSIONS_NOT_SUPPORT_QUALITY: ['image/png', 'image/jpg'],

  getCanvasDimension({ imageWidth, imageHeight, aspect }) {
    if (imageWidth / imageHeight < aspect) {
      return { canvasWidth: imageWidth, canvasHeight: imageWidth / aspect }
    } else if (imageWidth / imageHeight > aspect) {
      return { canvasWidth: imageHeight / aspect, canvasHeight: imageHeight }
    } else {
      return { canvasWidth: imageWidth, canvasHeight: imageHeight }
    }
  },

  createImage({ imageSrc }) {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.onload = () => resolve(image)
      image.onerror = e => reject(e)

      /**
       * CanvasRenderingContext2D.drawImage() 를 하기 위해 CORS 필요하기 때문에
       * CORS 없이 요청했던 캐싱된 이미지를 사용하면 안 됨.
       */
      image.crossOrigin = 'Anonymous'
      const rand = `?${Math.random()}`
      image.src = imageSrc + rand
    })
  },

  isValidQuality({ canvas, quality, mimeType, maxFileSize }) {
    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          resolve({ blob, isValid: blob.size < maxFileSize })
        },
        mimeType,
        quality / 10
      )
    })
  },

  async getImageBlog({ canvas, mimeType }) {
    // maxFileSize 를 초과하지 않는 최상의 quality 를 구한다. (image/jpeg 만 quality 옵션 가능)
    for (let quality = 10; quality >= 0; quality -= 1) {
      const { blob, isValid } = await this.isValidQuality({
        canvas,
        quality,
        mimeType,
        maxFileSize: 500 * 1024,
      })

      if (!isValid && this.EXTENSIONS_NOT_SUPPORT_QUALITY.includes(mimeType)) {
        return { blob: null, isValid: false }
      }

      // quality 가 0 (압축률 약 94.72%)일 때도 maxFileSize 를 초과하면 편집적용 불가.
      if (isValid || quality === 0) {
        return { blob, isValid }
      }
    }

    return null
  },

  async createEditedImageBlob({
    imageSrc,
    canvasWidth,
    canvasHeight,
    imageWidth,
    imageHeight,
    aspect,
    croppedPixels,
    mimeType = 'image/jpeg',
  }) {
    const image = await this.createImage({ imageSrc })
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const { canvasWidth: _canvasWidth, canvasHeight: _canvasHeight } =
      this.getCanvasDimension({
        imageWidth,
        imageHeight,
        aspect,
      })

    canvas.width = canvasWidth > 0 ? canvasWidth : _canvasWidth
    canvas.height = canvasHeight > 0 ? canvasHeight : _canvasHeight

    ctx.drawImage(
      image,
      croppedPixels.x,
      croppedPixels.y,
      croppedPixels.width,
      croppedPixels.height,
      0,
      0,
      canvasWidth,
      canvasHeight
    )

    const { blob, isValid } =
      (await this.getImageBlog({ canvas, mimeType })) || {}

    if (isValid) {
      return { blob, isConvertedToJpeg: false }
    } else if (
      !isValid &&
      this.EXTENSIONS_NOT_SUPPORT_QUALITY.includes(mimeType)
    ) {
      const { blob, isValid } =
        (await this.getImageBlog({
          canvas,
          mimeType: 'image/jpeg',
        })) || {}
      if (isValid) {
        return { blob, isConvertedToJpeg: true }
      }
    }

    return null
  },

  /**
   * center crop
   * - frame: cropArea
   * - media: image
   */
  getMediaDimension({ mediaAspect, cropAreaWidth, cropAreaHeight }) {
    if (mediaAspect < cropAreaWidth / cropAreaHeight) {
      return {
        mediaWidth: cropAreaWidth,
        mediaHeight: cropAreaWidth / mediaAspect,
      }
    } else if (mediaAspect > cropAreaWidth / cropAreaHeight) {
      return {
        mediaWidth: cropAreaWidth * mediaAspect,
        mediaHeight: cropAreaHeight,
      }
    } else {
      return { mediaWidth: cropAreaWidth, mediaHeight: cropAreaHeight }
    }
  },

  getEditedImageSize({ aspect }) {
    return CROP_ASPECT_SIZE[aspect]
  },

  getCropAspectString({ aspect }) {
    return CROP_ASPECT_STRING[aspect]
  },

  getCropAspectName({ aspect }) {
    return CROP_ASPECT_NAME_STRING[aspect]
  },

  async uploadEditedImage({
    adAccountId,
    objectUrl,
    originalFileName,
    mimeType,
    adGroupId,
    creativeFormat,
    creativeUploadAssetPropertyType,
    needEdit = false,
  }) {
    try {
      const blob = await fetch(objectUrl).then(r => r.blob())
      const file = new File([blob], originalFileName, {
        type: mimeType,
      })

      const formData = new FormData()

      formData.append('files', file)
      formData.append('creativeFormat', creativeFormat)
      formData.append('adGroupId', adGroupId)
      formData.append('assetPropertyType', creativeUploadAssetPropertyType)
      formData.append('needEdit', String(needEdit))
      formData.append('png24', String(false))

      const response = await creativeApi.uploadCreativeImages(
        adAccountId,
        formData,
        creativeFormat
      )

      const { successFiles, invalidFiles } = response?.data || {}

      if (invalidFiles?.length > 0) {
        invalidFiles.forEach(file => {
          const { invalidReasons } = file
          invalidReasons?.forEach(reason =>
            showErrorMessage(reason?.description)
          )
        })
      }

      if (successFiles?.length > 0) {
        return successFiles[0]
      }
    } catch (e) {
      console.log(e.message)
    } finally {
      URL.revokeObjectURL(objectUrl)
    }

    return null
  },

  getCropAspects({
    creativeUploadAssetPropertyType,
    creativeFormat,
    imageWidth,
    imageHeight,
    campaignType,
  }) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.IMAGE_NATIVE: {
        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.IMAGE
        ) {
          if (imageWidth < 1200) {
            return [1 / 1]
          }

          if (CampaignTypeEnum.isElectionCampaignType(campaignType)) {
            return [2 / 1]
          }

          return [2 / 1, 1 / 1]
        }

        break
      }

      case CreativeFormatEnum.Type.CATALOG_MANUAL: {
        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.IMAGE
        ) {
          return [1 / 1]
        }

        break
      }

      case CreativeFormatEnum.Type.IMAGE_BANNER: {
        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_IMAGE
        ) {
          return [2 / 1]
        }

        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_MULTI
        ) {
          return [1 / 1]
        }

        break
      }

      case CreativeFormatEnum.Type.VIDEO_NATIVE: {
        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.UPLOAD_THUMBNAIL
        ) {
          return [9 / 16, 16 / 9]
        }

        break
      }

      default: {
        if (
          creativeUploadAssetPropertyType ===
          CreativeUploadAssetPropertyEnum.Type.PROFILE_IMAGE
        ) {
          return [1 / 1]
        }

        break
      }
    }

    return []
  },
}

export default ImageEditorUtils
