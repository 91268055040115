import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { setOpinionProofFileObjectUrl } from './mCreativeCommonV2'

const OpinionProof = keyMirror(
  {
    SET_OPINION: null,
    SET_OPINION_PROOF: null,
    SET_UPLOADED_PROOF_FILES: null,
    DELETE_PROOF_FILE: null,
    CLEAR: null,
  },
  'OPINION_PROOF'
)

const initialState = fromJS({
  id: -1,
  opinion: '',
  opinionProofFileList: [],
  deletedDate: null,
  removalSystem: null,
})

export default createReducer(initialState, {
  [OpinionProof.SET_OPINION]: (state, { opinion }) =>
    state.withMutations(s => s.set('opinion', opinion)),

  [OpinionProof.SET_OPINION_PROOF]: (state, { opinionProof }) =>
    state.merge(fromJS(opinionProof)),

  [OpinionProof.SET_UPLOADED_PROOF_FILES]: (state, { proofFiles }) =>
    state.withMutations(s =>
      s.update('opinionProofFileList', opinionProofFileList =>
        opinionProofFileList.concat(fromJS(proofFiles))
      )
    ),

  [OpinionProof.DELETE_PROOF_FILE]: (state, { index }) =>
    state.withMutations(s =>
      s.update('opinionProofFileList', opinionProofFileList =>
        opinionProofFileList.delete(index)
      )
    ),

  [OpinionProof.CLEAR]: state => state.merge(initialState),
})

export const setOpinion = opinion => {
  return {
    type: OpinionProof.SET_OPINION,
    opinion,
  }
}

export const setOpinionProof = opinionProof => {
  return {
    type: OpinionProof.SET_OPINION_PROOF,
    opinionProof,
  }
}

export const setUploadedProofFiles = proofFiles => {
  return {
    type: OpinionProof.SET_UPLOADED_PROOF_FILES,
    proofFiles,
  }
}

export const deleteUploadedProofFileByIndex = index => {
  return {
    type: OpinionProof.DELETE_PROOF_FILE,
    index,
  }
}

export const clearOpinionProof = () => {
  return {
    type: OpinionProof.CLEAR,
  }
}

export function uploadProofFiles(
  adAccountId,
  files,
  onProgress,
  onFinish,
  isCanceled
) {
  return async (dispatch, getState, api) => {
    isCanceled.current = false
    const total = files.length
    let loaded = 0
    const uploadedProofFiles = []

    try {
      for (const file of Array.from(files)) {
        if (isCanceled.current) {
          break
        }

        const encodedName = encodeURIComponent(file.name)
        const { data } = await api.common.getTenthSignedURL(
          adAccountId,
          encodedName
        )

        const signedUrl = data.url
        if (signedUrl) {
          const formData = new FormData()
          formData.append('type', 'image')
          formData.append(encodedName, file)

          const { data } = await api.common.uploadProofFileToTenth(
            signedUrl,
            formData
          )

          if (data) {
            const {
              property: { filename, md5 },
              url: { path },
            } = data

            const objectUrl = URL.createObjectURL(file)
            dispatch(setOpinionProofFileObjectUrl(objectUrl))

            uploadedProofFiles.push({
              originalFileName: filename,
              md5,
              downloadUrl: path,
              objectUrl,
            })

            loaded += 1

            const progress = Math.max(loaded / total, 0.001)
            onProgress(progress)
          }
        }
      }
    } catch (e) {
      console.log(e.message)
    } finally {
      if (!isCanceled.current) {
        dispatch(setUploadedProofFiles(uploadedProofFiles))
      }
      onFinish()
    }
  }
}
