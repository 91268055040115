import { keyMirror } from '../../../../utils/utils'

/**
 * https://wiki.daumkakao.com/display/vera/CMP+Search+API+Specification
 */
const CMP_ASSET_TYPE = keyMirror({
  IMAGE: null,
  VIDEO: null,
  TITLE: null,
  LINK: null,
  DATA: null,
})

const CMP_SUB_ASSET_TYPE = keyMirror({
  // 메인 이미지 or 메인 타이틀
  MAIN: null,
  // 프로필 이미지
  PROFILE: null,
  // 비즈보드 에디터: 오브제 이미지
  OBJET: null,
  // 비즈보드 에디터: 아이콘 이미지
  ICON: null,
  // 이미지 에디터: 원본 이미지, BG 이미지
  RAW: null,
  // 이미지 에디터: 로고 이미지
  LOGO: null,
  // 이미지 에디터: 최종 결과 이미지
  UTILITY: null,
  // 비디오 콘텐츠
  VIDEO_CONTENT: null,
  // 동영상 자동 생성 썸네일
  AUTO_THUMBNAIL: null,
  // 동영상 직접 업로드 썸네일
  UPLOAD_THUMBNAIL: null,
  // 소재 설명문구
  ALT_TEXT: null,
  // 가격 정보
  PRICE: null,
  // 할인 가격 정보
  SALEPRICE: null,
  // 서브 타이틀
  SUB: null,
  // 행동유도문구
  CTATEXT: null,
  CUSTOM_ATTRIBUTE: null,
  // pc 전용 링크
  PC: null,
  // 모바일 전용 링크
  MOBILE: null,
  // 안드로이즈 전용 링크
  ANDROID: null,
  // iOS 전용 링크
  IOS: null,
})

const CMP_FILTER_GROUP_OPERATOR = keyMirror({
  AND: null,
  OR: null,
})

const CMP_FILTER_OPERATOR = keyMirror({
  // 입력 문자열이 포함됨
  LIKE: null,
  // 입력된 수치보다 크거나 같음
  GTE: null,
  // 입력된 수치보다 작거나 같음
  LTE: null,
  // 선택된 항목들 중 하나라도 일치함(alias: ANY)
  IN: null,
  // 선택된 항목들이 모두 일치하지 않음(alias: NONE)
  NOT_IN: null,
})

const CMP_FILTER_DIMENSION = keyMirror({
  // 이미지 가로 사이즈
  WIDTH: null,
  // 이미지 세로 사이즈
  HEIGHT: null,
  // 이미지 가로/세로 비율. 소수점 4자리 반올림(1.777777.. -> 1.7778)
  RATIO: null,
  // 파일명
  FILE_NAME: null,
  // 동영상 길이
  DURATION: null,
  // 텍스트
  TEXT: null,
  // URL
  URL: null,
  // DataAsset 의 VALUE 정보
  VALUE: null,
  // DataAsset 의 EXT 정보
  EXT: null,
})

const CMP_FILTER_PROPERTY_PATH = keyMirror({
  VIDEOURL: null,
  ALT_TEXT: null,
  PROFILE_NAME: null,
  type: null,
  clipId: null,
})

const CmpUtils = {}

CmpUtils.availableSubAssets = ({ assetType }) => {
  switch (assetType) {
    case CMP_ASSET_TYPE.IMAGE: {
      return [
        CMP_SUB_ASSET_TYPE.MAIN,
        CMP_SUB_ASSET_TYPE.PROFILE,
        CMP_SUB_ASSET_TYPE.ICON,
        CMP_SUB_ASSET_TYPE.RAW,
        CMP_SUB_ASSET_TYPE.OBJET,
        CMP_SUB_ASSET_TYPE.AUTO_THUMBNAIL,
        CMP_SUB_ASSET_TYPE.UPLOAD_THUMBNAIL,
      ]
    }
    case CMP_ASSET_TYPE.VIDEO: {
      return [CMP_SUB_ASSET_TYPE.MAIN]
    }
    case CMP_ASSET_TYPE.TITLE: {
      return [CMP_SUB_ASSET_TYPE.MAIN, CMP_SUB_ASSET_TYPE.SUB]
    }
    case CMP_ASSET_TYPE.LINK: {
      return [
        CMP_SUB_ASSET_TYPE.PC,
        CMP_SUB_ASSET_TYPE.MOBILE,
        CMP_SUB_ASSET_TYPE.ANDROID,
        CMP_SUB_ASSET_TYPE.IOS,
      ]
    }
    case CMP_ASSET_TYPE.DATA: {
      return [
        CMP_SUB_ASSET_TYPE.ALT_TEXT,
        CMP_SUB_ASSET_TYPE.PROFILE,
        CMP_SUB_ASSET_TYPE.PRICE,
        CMP_SUB_ASSET_TYPE.SALEPRICE,
        CMP_SUB_ASSET_TYPE.CTATEXT,
        CMP_SUB_ASSET_TYPE.VIDEO_CONTENT,
        CMP_SUB_ASSET_TYPE.CUSTOM_ATTRIBUTE,
      ]
    }
    default: {
      return []
    }
  }
}

CmpUtils.availableOperators = ({ assetType }) => {
  switch (assetType) {
    case CMP_ASSET_TYPE.IMAGE: {
      return [
        CMP_FILTER_OPERATOR.LIKE,
        CMP_FILTER_OPERATOR.GTE,
        CMP_FILTER_OPERATOR.LTE,
        CMP_FILTER_OPERATOR.IN,
        CMP_FILTER_OPERATOR.NOT_IN,
      ]
    }
    case CMP_ASSET_TYPE.VIDEO: {
      return [
        CMP_FILTER_OPERATOR.LIKE,
        CMP_FILTER_OPERATOR.GTE,
        CMP_FILTER_OPERATOR.LTE,
      ]
    }
    case CMP_ASSET_TYPE.TITLE: {
      return [CMP_FILTER_OPERATOR.LIKE]
    }
    case CMP_ASSET_TYPE.LINK: {
      return [CMP_FILTER_OPERATOR.LIKE]
    }
    case CMP_ASSET_TYPE.DATA: {
      return [
        CMP_FILTER_OPERATOR.LIKE,
        CMP_FILTER_OPERATOR.IN,
        CMP_FILTER_OPERATOR.GTE,
        CMP_FILTER_OPERATOR.LTE,
      ]
    }
    default: {
      return []
    }
  }
}

CmpUtils.availableDimensions = ({ assetType }) => {
  switch (assetType) {
    case CMP_ASSET_TYPE.IMAGE: {
      return [
        CMP_FILTER_DIMENSION.WIDTH,
        CMP_FILTER_DIMENSION.HEIGHT,
        CMP_FILTER_DIMENSION.FILE_NAME,
        CMP_FILTER_DIMENSION.RATIO,
      ]
    }
    case CMP_ASSET_TYPE.VIDEO: {
      return [CMP_FILTER_DIMENSION.DURATION, CMP_FILTER_DIMENSION.FILE_NAME]
    }
    case CMP_ASSET_TYPE.TITLE: {
      return [CMP_FILTER_DIMENSION.TEXT]
    }
    case CMP_ASSET_TYPE.LINK: {
      return [CMP_FILTER_DIMENSION.URL]
    }
    case CMP_ASSET_TYPE.DATA: {
      return [CMP_FILTER_DIMENSION.VALUE, CMP_FILTER_DIMENSION.EXT]
    }
    default: {
      return []
    }
  }
}

CmpUtils.findAssetsBySubAsset = ({ subAssetType }) =>
  Object.keys(CMP_ASSET_TYPE).filter(assetType =>
    CmpUtils.availableSubAssets({ assetType }).includes(subAssetType)
  )

export {
  CmpUtils,
  CMP_ASSET_TYPE,
  CMP_SUB_ASSET_TYPE,
  CMP_FILTER_GROUP_OPERATOR,
  CMP_FILTER_OPERATOR,
  CMP_FILTER_DIMENSION,
  CMP_FILTER_PROPERTY_PATH,
}
