import React from 'react'
import PopupHOC from '../../../../Popup/PopupHOC'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import cx from 'classnames'
import PopupButtonGroup from '../../../../Common/ButtonGroup/PopupButtonGroup'
import CreativeLibraryUpload from './CreativeLibraryUpload'
import { useMultipleImageUpload } from '../../Form/Viewable/useMultipleImageUpload'
import {
  addCreativeLibrarySelectedItems,
  deleteCreativeLibrarySelectedItem,
  initCreativeLibraryByKeyPath,
  setCreativeLibraryEditedItems,
  uploadCreativeLibraryImages,
} from '../../../../../modules/advertise/mCreativeLibrary'
import { cancelRequest } from '../../../../../utils/cancellation/cancellation'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import CreativeLibraryRecent from './CreativeLibraryRecent'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../../utils/alertUtils'
import { v4 as uuid } from 'uuid'
import PropTypes from 'prop-types'
import { CreativeGuideTooltip } from '../../Form/Common'
import { InputFileUtil } from '../../../../../utils/fileUtils'
import { openPopup, POPUP_KEY } from '../../../../../modules/common/mPopup'
import ImageEditorUtils from '../ImageEditor/imageEditorUtils'
import { fromJS } from 'immutable'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import CampaignTypeEnum from '../../../../../enums/CampaignTypeEnum'
import CreativeUploadAssetPropertyEnum from '../../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  hideLoading,
  showLoading,
} from '../../../../../modules/common/mLoading'
import CmpAssetLibraryGuide from '../../Cmp/CmpAssetLibrary/CmpAssetLibraryGuide'
import { NumberUtils } from '../../../../../utils/numberUtils'

const UPLOAD_COUNT_MAX = 100

const TAB = {
  RECENT: 0,
  UPLOAD: 1,
}

const selector = ({
  creativeV2: {
    creativeLibrary: { selectedItems, editedItemsMap },
    common: {
      adGroup: { id: adGroupId },
    },
  },
}) => {
  return {
    selectedItems,
    editedItemsMap,
    adGroupId,
  }
}

const multiImageSelector = ({
  creativeV2: {
    creativeLibrary: {
      upload: { uploadItems },
    },
  },
}) => {
  return {
    // for `useMultipleImageUpload` props
    countOfImages: uploadItems.count(),
  }
}

/**
 * @deprecated
 *
 * Recent | Upload | Catalog
 *
 * creativeUploadAssetPropertyType {CreativeUploadAssetPropertyEnum.Type}
 * imageSizes {array}
 *  {
 *    width: number | null,
 *    height: number | null,
 *    minWidth: number | null,
 *    ratio: number | null, // 0.0000
 *  }
 */
const CreativeLibrary = ({
  adAccountId,
  creativeFormat,
  creativeUploadAssetPropertyType,
  campaignType,
  imageSizes = [],
  multiSelect = true,
  onlyPNG24 = false,
  guideTooltipContent,
  onSave,
  close,
}) => {
  const dispatch = useDispatch()

  const { selectedItems, editedItemsMap, adGroupId } = useSelector(
    selector,
    shallowEqual
  )

  const [tabIndex, setTabIndex] = React.useState(TAB.UPLOAD)
  const [isProcessing, setIsProcessing] = React.useState(false)

  React.useEffect(() => {
    return () => {
      dispatch(initCreativeLibraryByKeyPath(['recent']))
      dispatch(initCreativeLibraryByKeyPath(['catalog']))
      dispatch(initCreativeLibraryByKeyPath(['selectedItems']))
      dispatch(initCreativeLibraryByKeyPath(['editedItemsMap']))
    }
  }, [dispatch])

  const needEdit = React.useMemo(
    () =>
      ImageEditorUtils.getCropAspects({
        creativeUploadAssetPropertyType,
        creativeFormat,
      }).length > 0,
    [creativeUploadAssetPropertyType, creativeFormat]
  )

  const multipleImageUpload = useMultipleImageUpload({
    adAccountId,
    imageSelector: multiImageSelector,
    imageUploadCountMax: UPLOAD_COUNT_MAX,
    onUpload: ({ files, cancelKey, onProgress, onFinish }) => {
      if (!InputFileUtil.isValid(files)) {
        showErrorMessage(
          `유효하지 않은 이미지 파일입니다.\n다른 이미지 파일을 업로드해 주세요.`
        )
      } else {
        dispatch(
          uploadCreativeLibraryImages({
            adAccountId,
            creativeUploadAssetPropertyType,
            sizeConstraintArray: imageSizes,
            needEdit,
            creativeFormat,
            guideTooltip: (
              <CreativeGuideTooltip
                fileSizeClassName="filesize_info_type2"
                {...guideTooltipContent}
              />
            ),
            files,
            cancelKey,
            onlyPNG24,
            multiSelect,
            callback: {
              onProgress,
              onFinish,
            },
          })
        )
      }
    },
  })

  const { imageUploadState, imageUploadCancelKey } = multipleImageUpload

  const onOK = async () => {
    dispatch(showLoading())

    setIsProcessing(true)

    const items = []

    for (const selectedItem of selectedItems) {
      const imageUUID = selectedItem.get('imageUUID')

      if (editedItemsMap.has(imageUUID)) {
        const editedItems = editedItemsMap.get(imageUUID)

        for (const editedItem of editedItems) {
          const { editedImageUrl, originalFileName, mimeType } = editedItem

          const uploadResult = await ImageEditorUtils.uploadEditedImage({
            adAccountId,
            objectUrl: editedImageUrl,
            originalFileName,
            mimeType,
            adGroupId,
            creativeFormat,
            creativeUploadAssetPropertyType,
            needEdit,
          })

          if (uploadResult) {
            // catalogProduct 와 같은 props 를 그대로 보존한다.
            items.push(Object.assign(editedItem.toJS(), uploadResult))
          }
        }
      } else {
        items.push(selectedItem.toJS())
      }
    }

    // 동일 항목을 반복해서 가져올 수 있기 때문에 가져오는 시점에 unique id 를 새로 발급한다.
    items.forEach(v => {
      v['imageUUID'] = uuid()
    })

    if (typeof onSave === 'function') {
      if (items.length > 0) {
        onSave({
          items: fromJS(items),
          onSuccess: message => {
            showSuccessMessage(message || '소재 요소 등록이 완료되었습니다.')

            close()
          },
          onFailure: message => {
            showErrorMessage(message || '소재 요소 등록에 실패했습니다.')
          },
          close,
        })
      } else {
        close()
      }

      setIsProcessing(false)

      dispatch(hideLoading())
    }
  }

  const renderGuideView = () => {
    return (
      <CmpAssetLibraryGuide
        creativeFormat={creativeFormat}
        creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
        campaignType={campaignType}
        uploadGuideTooltip={<CreativeGuideTooltip {...guideTooltipContent} />}
        isVisibleCatalogProductGuide={tabIndex === TAB.CATALOG_PRODUCT}
      />
    )
  }

  const openImageEditor = React.useCallback(
    ({ imageUUID, items, originalItem, isEditMode }) => {
      dispatch(
        openPopup(POPUP_KEY.CREATIVE_LIBRARY_IMAGE_EDITOR, {
          isEditMode,
          creativeFormat,
          campaignType,
          creativeUploadAssetPropertyType,
          validAspects: ImageEditorUtils.getCropAspects({
            creativeUploadAssetPropertyType,
            creativeFormat,
            imageWidth: items.first().get('imageWidth'),
            imageHeight: items.first().get('imageHeight'),
            campaignType,
          }),
          originalImageUUID: imageUUID,
          images: items,
          containerHeight: 420,
          onComplete: editedItems => {
            dispatch(setCreativeLibraryEditedItems(imageUUID, editedItems))
            dispatch(deleteCreativeLibrarySelectedItem(originalItem))

            if (
              editedItems &&
              (multiSelect ||
                selectedItems.filter(v => v.get('imageUUID') !== imageUUID)
                  .size === 0)
            ) {
              dispatch(addCreativeLibrarySelectedItems([originalItem]))
            }
          },
        })
      )
    },
    [
      campaignType,
      creativeUploadAssetPropertyType,
      creativeFormat,
      dispatch,
      multiSelect,
      selectedItems,
    ]
  )

  const selectedImageCount = React.useMemo(
    () =>
      selectedItems.reduce((prev, v) => {
        const { imageUUID } = v
        if (editedItemsMap.has(imageUUID)) {
          const editedItems = editedItemsMap.get(imageUUID)
          return prev + editedItems.count()
        } else {
          return prev + 1
        }
      }, 0),
    [editedItemsMap, selectedItems]
  )

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">소재 라이브러리</strong>
      </div>
      <div className="layer_body">
        <strong className="screen_out">소재 라이브러리 종류</strong>
        <Tabs onSelect={setTabIndex} selectedIndex={tabIndex}>
          <TabList className="tab_g8">
            <Tab className={cx({ on: tabIndex === TAB.RECENT })}>
              <a className="link_tab">
                이미지 불러오기
                {tabIndex === TAB.RECENT && (
                  <span className="screen_out">선택됨</span>
                )}
              </a>
            </Tab>
            <Tab className={cx({ on: tabIndex === TAB.UPLOAD })}>
              <a className="link_tab">
                직접 업로드
                {tabIndex === TAB.UPLOAD && (
                  <span className="screen_out">선택됨</span>
                )}
              </a>
            </Tab>
          </TabList>
          <TabPanel>
            <CreativeLibraryRecent
              adAccountId={adAccountId}
              campaignType={campaignType}
              creativeFormat={creativeFormat}
              creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
              multiSelect={multiSelect}
              imageSizes={imageSizes}
              renderGuideView={renderGuideView}
              handleImageCropperOpen={openImageEditor}
            />
          </TabPanel>
          <TabPanel>
            <CreativeLibraryUpload
              campaignType={campaignType}
              creativeFormat={creativeFormat}
              creativeUploadAssetPropertyType={creativeUploadAssetPropertyType}
              multiSelect={multiSelect}
              imageSizes={imageSizes}
              renderGuideView={renderGuideView}
              handleImageCropperOpen={openImageEditor}
              {...multipleImageUpload}
            />
          </TabPanel>
        </Tabs>
        {imageUploadState.progress > 0 && (
          <div className="layer_upload">
            <div className="inner_layer">
              <span className="txt_upload">
                {NumberUtils.withCommas(multipleImageUpload.fileCount)}개 이미지
                업로드 중
              </span>
              <span className="load_wrap">
                <span className="load_bg">
                  <span
                    className="load_bar"
                    style={{ width: `${imageUploadState.progress * 100}%` }}>
                    {imageUploadState.progress}%
                  </span>
                </span>
                <button
                  type="button"
                  className="btn_del"
                  onClick={() => {
                    cancelRequest(imageUploadCancelKey)
                  }}>
                  <span className="ico_comm ico_del">삭제</span>
                </button>
              </span>
            </div>
          </div>
        )}
      </div>
      <div className="layer_foot">
        {selectedItems.count() > 0 && (
          <span className="txt_select">
            <button
              type="button"
              className="btn_del"
              onClick={() => {
                dispatch(initCreativeLibraryByKeyPath(['selectedItems']))
              }}>
              <span className="ico_comm ico_del">삭제</span>
            </button>
            선택된 이미지
            <em className="num_select">
              {NumberUtils.withCommas(selectedImageCount)}
            </em>
          </span>
        )}
        <PopupButtonGroup
          okButtonLabel="확인"
          hasBack={false}
          isEnabledOK={selectedItems.count() > 0 && !isProcessing}
          isEnabledCancel={!isProcessing}
          onOK={onOK}
          onCancel={close}
        />
        <a className="btn_close" onClick={close}>
          <span className="ico_comm ico_close">닫기</span>
        </a>
      </div>
    </div>
  )
}

CreativeLibrary.propTypes = {
  adAccountId: PropTypes.any,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()),
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()),
  creativeUploadAssetPropertyType: PropTypes.oneOf(
    CreativeUploadAssetPropertyEnum.values()
  ),
  imageSizes: PropTypes.arrayOf(
    PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
      minWidth: PropTypes.number,
      ratio: PropTypes.number,
    })
  ),
  multiSelect: PropTypes.bool,
  onlyPNG24: PropTypes.bool,
  guideTooltipContent: PropTypes.shape({
    formats: PropTypes.array,
    sizes: PropTypes.array,
    extras: PropTypes.array,
  }),
  onSave: PropTypes.func,
  close: PropTypes.func,
}

export default PopupHOC(CreativeLibrary, { subClassName: 'material_layer' })
