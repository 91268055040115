import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { keyMirror, parseUrlParameter } from '../../utils/utils'

const Router_V2 = keyMirror(
  {
    LOCATION_CHANGED: null,
  },
  'Router_v2'
)

const initialState = fromJS({
  location: {
    pathname: '/',
    search: '',
    hash: '',
    key: '',
    query: {},
  },
  action: null,
})

export default createReducer(initialState, {
  [Router_V2.LOCATION_CHANGED]: (state, { payload }) =>
    state.withMutations(s =>
      s
        .set('location', payload.get('location'))
        .set('action', payload.get('action'))
        .setIn(
          ['location', 'query'],
          parseUrlParameter(payload.getIn(['location', 'search']))
        )
    ),
})

export const changeRouterLocation = (location, action) => ({
  type: Router_V2.LOCATION_CHANGED,
  payload: fromJS({ location, action }),
})
