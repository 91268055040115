import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { coerceToArray } from '../../utils/stringUtils'
import { hideLoading, LOADING_KEY, showLoading } from '../common/mLoading'

const MyInfo = keyMirror(
  {
    GET_DSP_AUTO_PAYMENT_CARD_LIST: null,
    SET_STATE_BY_KEY_PATH: null,
    INIT: null,
  },
  'MY_INFO'
)

const initialState = fromJS({
  dspAutoPaymentCardList: [],
})

export default createReducer(initialState, {
  [MyInfo.GET_DSP_AUTO_PAYMENT_CARD_LIST]: (state, { data }) =>
    state.set('dspAutoPaymentCardList', fromJS(data || [])),

  [MyInfo.SET_STATE_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn([...coerceToArray(keyPath)], value),
})

export function getDspAutoPaymentCardList() {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.DSP_AUTO_PAYMENT_CARD_LIST))

    try {
      const response = await api.card.getDspAutoPaymentCardList()

      dispatch({
        type: MyInfo.GET_DSP_AUTO_PAYMENT_CARD_LIST,
        data: response.data || [],
      })
    } catch (e) {
      // TODO
    } finally {
      dispatch(hideLoading(LOADING_KEY.DSP_AUTO_PAYMENT_CARD_LIST))
    }
  }
}
