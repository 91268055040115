/**
 * TODO: StorageFactory
 */

import momentLocalStorage, {
  STORAGE_KEY_REQUIRED_INFO_AGREEMENT,
} from './storage/momentLocalStorage'
import momentSessionStorage, {
  SESSION_KEY_ACCESS_TOKEN,
  SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT,
  SESSION_KEY_LOGIN_TOKEN,
  SESSION_KEY_OBSERVER,
  SESSION_KEY_OBSERVER_ADACCOUNT_ID,
  SESSION_KEY_OBSERVER_SERVICE_TYPE,
  SESSION_KEY_SELECTED_ADACCOUNT,
} from './storage/momentSessionStorage'

import { kakaoLoginURL, kakaoLogoutURL } from './app/services/kakaoAccount'
import {
  DASHBOARD_STORAGE_ITEM,
  DashboardStorageSession,
} from './storage/storageFactoryImpl'
import axios from 'axios'
import { showErrorMessage } from './alertUtils'
import { ERROR_CODE } from './errorCode'
import {
  toInvalidApproachPath,
  toServiceUnavailablePath,
} from './router/routeUtils'
import * as Sentry from '@sentry/browser'
import { checkNoneEmpty } from './regexUtils'

let accessToken = null
let loginToken = null
let observed = null

export function setObserverInfo(adAccountId, serviceType) {
  if (!adAccountId || !serviceType) return
  momentSessionStorage.setItem(SESSION_KEY_OBSERVER, true)
  momentSessionStorage.setItem(SESSION_KEY_OBSERVER_ADACCOUNT_ID, adAccountId)
  momentSessionStorage.setItem(SESSION_KEY_OBSERVER_SERVICE_TYPE, serviceType)
  observed = true
}

export function initObserverInfo() {
  momentSessionStorage.removeItem(SESSION_KEY_OBSERVER)
  momentSessionStorage.removeItem(SESSION_KEY_OBSERVER_ADACCOUNT_ID)
  momentSessionStorage.removeItem(SESSION_KEY_OBSERVER_SERVICE_TYPE)
  observed = null
}

export function invalidateObserverInfo() {
  observed = momentSessionStorage.getItem(SESSION_KEY_OBSERVER) !== null
}

export function isObserved() {
  if (observed === null) {
    observed = momentSessionStorage.getItem(SESSION_KEY_OBSERVER)
  }
  return Boolean(observed) || false
}

export function setAuthenticationToken(access, login) {
  momentSessionStorage.setItem(SESSION_KEY_ACCESS_TOKEN, access)
  momentSessionStorage.setItem(SESSION_KEY_LOGIN_TOKEN, login)
  accessToken = access
  loginToken = login
}

export function getAccessToken() {
  if (accessToken === null) {
    accessToken = momentSessionStorage.getItem(SESSION_KEY_ACCESS_TOKEN)
  }
  return accessToken
}

export function getLoginToken() {
  if (loginToken === null) {
    loginToken = momentSessionStorage.getItem(SESSION_KEY_LOGIN_TOKEN)
  }
  return loginToken
}

/**
 * @param params
 * @param params.redirectBack {boolean} - 로그인 후 return url 을 현재 Path 로 유지할 지 여부.
 */
export function login({ redirectBack = false } = {}) {
  window.location.href = kakaoLoginURL({ redirectBack })
}

export function logout() {
  momentSessionStorage.removeItem(SESSION_KEY_ACCESS_TOKEN)
  momentSessionStorage.removeItem(SESSION_KEY_LOGIN_TOKEN)
  momentSessionStorage.removeItem(SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT)
  momentLocalStorage.removeItem(STORAGE_KEY_REQUIRED_INFO_AGREEMENT)

  initObserverInfo()
  initStoredAdAccountInfo()
  DashboardStorageSession.set(DASHBOARD_STORAGE_ITEM.TABLE_ROW_ID_SET, {})

  accessToken = null
  loginToken = null

  window.location.href = kakaoLogoutURL()
}

/**
 * DSP 계정 탈퇴 시 처리 해야할 일련의 작업들.
 */
export function withdrawDspAccount() {
  momentSessionStorage.removeItem(SESSION_KEY_ACCESS_TOKEN)
  momentSessionStorage.removeItem(SESSION_KEY_LOGIN_TOKEN)
  momentSessionStorage.removeItem(SESSION_KEY_IGNORE_INTEGRATED_AGREEMENT)

  initStoredAdAccountInfo()

  accessToken = null
  loginToken = null
}

export function setCurrentAdAccountInfo(adAccount) {
  momentSessionStorage.setItem(
    SESSION_KEY_SELECTED_ADACCOUNT,
    JSON.stringify(adAccount)
  )
  DashboardStorageSession.set(DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS, '')
  DashboardStorageSession.set(DASHBOARD_STORAGE_ITEM.TABLE_ROW_ID_SET, {})
}

export function getStoredAdAccountInfo() {
  try {
    return JSON.parse(
      momentSessionStorage.getItem(SESSION_KEY_SELECTED_ADACCOUNT)
    )
  } catch (e) {
    return null
  }
}

export function initStoredAdAccountInfo() {
  momentSessionStorage.removeItem(SESSION_KEY_SELECTED_ADACCOUNT)

  DashboardStorageSession.set(DASHBOARD_STORAGE_ITEM.URI_SEARCH_PARAMS, '')
}

export function setAuthorizationHeader(config) {
  const accessToken = getAccessToken()
  if (accessToken) {
    config.headers.accessToken = accessToken
  }
  const loginToken = getLoginToken()
  if (loginToken) {
    config.headers.loginToken = loginToken
  }
  return config
}

export function onResponseFulfilled(response) {
  const { accesstoken, logintoken } = response.headers
  if (checkNoneEmpty(accesstoken, logintoken)) {
    setAuthenticationToken(accesstoken, logintoken)
  }
  return response
}

const HTTP_STATUS_CODE = {
  OK: 200,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  INTERNAL_SEVER_ERROR: 500,
  BAD_GATEWAY: 502,
}

export function onResponseRejected(error) {
  if (axios.isCancel(error)) {
    /*******************
     * Request canceled
     *******************/
    return Promise.reject(error)
  } else {
    if (error.response) {
      /**************************************
       * Server responded with a status code
       **************************************/
      const { status, data } = error.response
      const { errorCode, message } = data || {}

      switch (Number(status)) {
        case HTTP_STATUS_CODE.UNAUTHORIZED: {
          /**
           * handleDspLoginException 에서 별도로 처리하도록 무시
           * - https://wiki.daumkakao.com/display/bizdmp/ErrorCode
           * - 카카오광고 약관동의 https://wiki.daumkakao.com/pages/viewpage.action?pageId=692817805
           */
          if (
            ![2001, 1301, 1302].includes(Number(errorCode)) &&
            !/^150[1-8]/.test(String(errorCode))
          ) {
            login({ redirectBack: true })
          }
          break
        }

        case HTTP_STATUS_CODE.FORBIDDEN: {
          if (isObserved()) {
            showErrorMessage('접근 권한이 없습니다.')
            // 이 경우 빈 객체를 리턴하지 않으면 하위 catch 의 로직이 연달아 수행된다.
            return Promise.reject('')
          } else {
            const invalidErrorCode =
              Number(errorCode) === ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN
                ? errorCode
                : null
            window.location.href = toInvalidApproachPath(invalidErrorCode)
          }
          break
        }
        case HTTP_STATUS_CODE.BAD_GATEWAY:
        case HTTP_STATUS_CODE.INTERNAL_SEVER_ERROR: {
          if (message) {
            showErrorMessage(message)
          }
          if (
            Number(errorCode) === ERROR_CODE.GW_INTERNAL_SERVER_ERROR ||
            Number(errorCode) === ERROR_CODE.GW_INTERNAL_SERVER_ACCESS_ERROR ||
            Number(errorCode) === ERROR_CODE.CRUX_INTERNAL_SERVER_ERROR ||
            Number(errorCode) === ERROR_CODE.ETC_INTERNAL_ERROR
          ) {
            Sentry.captureException(`rejected errorCode: ${error}`)
            window.location.assign(toServiceUnavailablePath())
          }
          break
        }

        default: {
          break
        }
      }
      return Promise.reject(error)
    } else {
      /******************************
       * Something happened
       * - CORS, Network error, etc.
       ******************************/

      if (error.request) {
        /**************************************
         * Requested, but no response received
         * - Gateway shutdown (502 Bad Gateway)
         **************************************/
      }

      window.location.assign(toServiceUnavailablePath())

      Sentry.captureException(error)

      return Promise.reject(error)
    }
  }
}
