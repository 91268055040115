import { keyMirror } from '../utils/utils'

export const ProfileFullViewTitleEnum = {
  Type: keyMirror({
    TEXT: null,
    IMAGE: null,
  }),
  Name: {
    TEXT: '기본형',
    IMAGE: '자유형',
  },
  values() {
    return Object.keys(this.Type)
  },
  getName(type) {
    return this.Name[type]
  },
}

export const ProfileFullViewBackgroundEnum = {
  Type: keyMirror({
    VIDEO: null,
    IMAGE: null,
  }),
  Name: {
    VIDEO: '동영상형',
    IMAGE: '이미지형',
  },
  values() {
    return Object.keys(this.Type)
  },
  getName(type) {
    return this.Name[type]
  },
}
