import React from 'react'
import cx from 'classnames'
import { hasUrl } from '../../../utils/regexUtils'
import PropTypes from 'prop-types'
import { KakaoMoment } from '../../../utils/app/services/kakaoMoment'
import { useClickOutSide } from '../../../utils/hook/useClickOutside'

const GnbMenu = ({ gnbMenu }) => {
  const menuItems = React.useMemo(
    () => gnbMenu.filter(({ name }) => Boolean(name)),
    [gnbMenu]
  )

  return (
    <div id="kakaoGnb" className="gnb_comm">
      <h2 className="screen_out">비즈니스 메뉴</h2>
      <ul className="list_gnb">
        {menuItems.map(({ id, name, link, children }) =>
          children?.length > 0 ? (
            <GnbDropdownMenu key={id} name={name} menuItems={children} />
          ) : (
            <GnbSingleMenu key={id} name={name} link={link} />
          )
        )}
      </ul>
    </div>
  )
}

const linkPropTypes = PropTypes.shape({
  href: PropTypes.string.isRequired,
  target: PropTypes.string,
})

const menuItemPropTypes = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  link: linkPropTypes,
  children: PropTypes.array,
})

GnbMenu.propTypes = {
  gnbMenu: PropTypes.arrayOf(menuItemPropTypes),
}

const isAvailable = ({ href }) => hasUrl(href)
const isMoment = ({ id }) => id === 'moment'

const GnbSingleMenu = ({ name, link }) => {
  const available = isAvailable({ href: link?.href })

  if (!available) return null

  return (
    <li>
      <a
        className="link_gnb"
        rel="noopener noreferrer"
        target={link?.target}
        href={link?.href}>
        {name}
      </a>
    </li>
  )
}

GnbSingleMenu.propTypes = {
  name: PropTypes.string.isRequired,
  link: linkPropTypes.isRequired,
}

const SubItems = ({ menuItems, close }) => {
  return (
    <ul className="list_gnbsub">
      {menuItems.map(({ id, name, link, disabled }, index) => {
        const isMenuMoment = isMoment({ id })
        const href = isMenuMoment
          ? __LOCAL__
            ? 'https://dsp.kakao.com:4000'
            : KakaoMoment.getHost()
          : link?.href

        return (
          <li key={index}>
            <a
              rel="noopener noreferrer"
              href={href}
              target={link?.target}
              onClick={close}
              className={cx('link_sub', {
                on: isMenuMoment,
                disabled,
              })}>
              {name}
              {link?.target === '_blank' && (
                <span className="ico_gnb ico_outlink">페이지이동</span>
              )}
            </a>
          </li>
        )
      })}
    </ul>
  )
}

SubItems.propTypes = {
  menuItems: PropTypes.array,
  close: PropTypes.func,
}

const GnbDropdownMenu = ({ name, menuItems }) => {
  const rootRef = React.useRef()

  const [isOpened, setIsOpened] = React.useState(false)

  const close = React.useCallback(e => {
    e?.stopPropagation()

    setIsOpened(false)
  }, [])

  useClickOutSide(
    rootRef,
    () => {
      close()
    },
    { capture: true }
  )

  const on = React.useMemo(() => menuItems.some(isMoment), [menuItems])

  return (
    <li
      ref={rootRef}
      className={cx({ open: isOpened, on })}
      onClick={e => {
        e.stopPropagation()

        setIsOpened(prev => !prev)
      }}>
      <a className="link_gnb">
        {name}
        <span className="ico_gnb">더보기</span>
      </a>
      <div className="sub_gnb">
        {isOpened && <SubItems menuItems={menuItems} close={close} />}
      </div>
    </li>
  )
}

GnbDropdownMenu.propTypes = {
  name: PropTypes.string.isRequired,
  menuItems: PropTypes.arrayOf(menuItemPropTypes),
}

export default GnbMenu
