import cx from 'classnames'
import { openPopup, POPUP_KEY } from '../../../../../modules/common/mPopup'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import AdConstraintsHelper from '../../../../../utils/helper/helper-adConstraints'
import CreativeAssetPropertyEnum from '../../../../../enums/CreativeAssetPropertyEnum'
import PropTypes from 'prop-types'
import CreativeFormatEnum from '../../../../../enums/CreativeFormatEnum'
import { CMP_EDITOR_EVENT, CmpEditorUtils } from './cmpEditorUtils'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'
import { Tooltip } from '../../../../../resources/locale'
import CmpAssetPropertyEnum from '../../../../../enums/CmpAssetPropertyEnum'

const selector = ({ adConstraints: { creativeAssetConstraints } }) => {
  return {
    creativeAssetConstraints,
  }
}

const CmpEditorOpenButton = ({
  adAccountId,
  creativeFormat,
  baseClassName = 'btn_gw btn_bl',
  ratioValueFilter = ratioValue => true,
  onCreate = ({ editorItems }) => undefined,
  onModify = ({ editorItem }) => undefined,
  disabled = false,
  cmpAssetPropertyType = CmpAssetPropertyEnum.Type.MAIN_IMAGE,
  ratio,
}) => {
  const dispatch = useDispatch()

  const { creativeAssetConstraints } = useSelector(selector, shallowEqual)

  const saveSizeConstraintsRecommended = React.useMemo(
    () =>
      AdConstraintsHelper.Creative.getSaveSizeConstraintsRecommended({
        creativeAssetConstraints,
        creativeFormat,
        creativeAssetPropertyType: CreativeAssetPropertyEnum.Type.MAIN_IMAGE,
      }),
    [creativeAssetConstraints, creativeFormat]
  )

  const ratioValues = React.useMemo(
    () =>
      saveSizeConstraintsRecommended
        ?.map(({ width, height, ratio }) => {
          return {
            width: width?.get('eq') ?? width?.get('min') ?? width?.get('max'),
            height:
              height?.get('eq') ?? height?.get('min') ?? height?.get('max'),
            ratio: ratio?.get('eq') ?? ratio?.get('min') ?? ratio?.get('max'),
          }
        })
        // 현재 1:1, 2:1 비율만 지원
        .filter(({ width, height }) => width > 0 && height > 0)
        .map(({ ratio }) => ratio)
        .filter(ratioValueFilter)
        .toJS() || [],
    [ratioValueFilter, saveSizeConstraintsRecommended]
  )

  const imageRatios = React.useMemo(
    () =>
      ratioValues
        .map(ratioValue =>
          CmpEditorUtils.getRatioConstByValue({ value: ratioValue })
        )
        .filter(Boolean),
    [ratioValues]
  )

  const imageOpener = React.useMemo(() => {
    return CmpEditorUtils.getOpenerByCreativeFormatAndCmpAssetPropertyType({
      creativeFormat,
      cmpAssetPropertyType,
      ratio: CmpEditorUtils.getRatioConstByValue({ value: ratio }),
    })
  }, [creativeFormat, cmpAssetPropertyType, ratio])

  return (
    <button
      className={cx(baseClassName, { in_active: disabled })}
      onClick={() => {
        if (!disabled) {
          dispatch(
            openPopup(POPUP_KEY.CMP_EDITOR, {
              adAccountId,
              imageOpener,
              eventCallback: ({ event, payload = {} }) => {
                switch (event) {
                  case CMP_EDITOR_EVENT.CREATE_ITEM: {
                    const { data: editorItems = [] } = payload

                    if (editorItems.length === 0) {
                      // assertion error
                      showErrorMessage(
                        '생성된 에디터 아이템이 유효하지 않습니다.'
                      )
                    } else {
                      onCreate({ editorItems })
                    }

                    break
                  }

                  case CMP_EDITOR_EVENT.MODIFY_ITEM: {
                    const { data: imageEditorItem } = payload

                    if (!imageEditorItem) {
                      // assertion error
                      showErrorMessage(
                        '생성된 에디터 아이템이 유효하지 않습니다.'
                      )
                    } else {
                      onModify({ imageEditorItem })
                    }

                    break
                  }

                  default: {
                    break
                  }
                }
              },
            })
          )
        }
      }}>
      <span className="inner_g">
        <span className="img_comm ico_add" />
        이미지 만들기
        <DynamicTooltip
          content={
            !(__PRODUCTION__ || __STAGE__) ? (
              <>
                {Tooltip('cmp.editor.create_button')}
                <br />
                <br />
                <span style={{ color: 'blue', fontWeight: 'bold' }}>
                  *개발 환경에서만 노출되는 툴팁입니다*
                </span>
                <br />
                비율: {imageRatios.join(', ')}
              </>
            ) : (
              Tooltip('cmp.editor.create_button')
            )
          }
          onClick={e => e.stopPropagation()}>
          <a className="link_g link_help">
            <span className="ico_comm ico_help">도움말</span>
          </a>
        </DynamicTooltip>
      </span>
    </button>
  )
}

CmpEditorOpenButton.propTypes = {
  adAccountId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(
    CreativeAssetPropertyEnum.values()
  ),
  baseClassName: PropTypes.string,
  ratioValueFilter: PropTypes.func,
  onCreate: PropTypes.func,
  onModify: PropTypes.func,
  disabled: PropTypes.bool,
  cmpAssetPropertyType: PropTypes.oneOf(CmpAssetPropertyEnum.values()),
}

export default CmpEditorOpenButton
