import {
  BILLING,
  CRUX_ACCOUNT,
  fetchDelete,
  fetchGet,
  fetchPost,
  fetchPut,
} from '../../utils/fetchUtils'
import { queryString } from '../../utils/utils'
import CruxFetchUtils from '../../utils/cruxFetchUtils'

/**
 * 캐시충전 내역 페이징
 * @param adAccountId
 * @param searchOptions
 * @returns {*}
 */
function fetchChargeHistory(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v2/wallet/${adAccountId}/cash/list?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 무상캐시 사용내역 페이징
 * @param adAccountId
 * @param searchOptions
 * @returns {*}
 */
function fetchFreeCashHistory(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v1/wallet/${adAccountId}/freeCash?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 환불처리내역 페이징
 * @param adAccountId
 * @param searchOptions
 * @returns {*}
 */
function fetchWithdrawHistory(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v1/wallet/${adAccountId}/withdraw?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 캐시 충전 내역 전체 다운로드용
 * @param adAccountId
 * @param searchOptions
 * @returns {*}
 */
function downloadCashChargeHistory(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v2/wallet/${adAccountId}/cash/totalList?${queryString(
      searchOptions
    )}`,
    adAccountId
  )
}

/**
 * 무상캐시 사용하기
 * @param adAccountId
 * @param cashId
 * @param options
 * @returns {*}
 */
function requestUseFreeCash(adAccountId, cashId, options) {
  return fetchPost(
    `${BILLING}/v1/wallet/${adAccountId}/charge/freeCash/${cashId}/use?${queryString(
      options
    )}`,
    {},
    adAccountId
  )
}

/**
 * 전액환불, 부분환불 요청
 * @param adAccountId
 * @param requestBody
 * @returns {*}
 */
function requestRefund(adAccountId, requestBody) {
  return fetchPost(
    `${BILLING}/v1/wallet/${adAccountId}/withdraw`,
    requestBody,
    adAccountId
  )
}

/**
 * wallet 잔액 조회
 * @param adAccountId
 * @returns {*}
 */
function fetchAdAccountCashInfo(adAccountId) {
  return fetchGet(`${BILLING}/v2/wallet/${adAccountId}`, adAccountId)
}

/**
 * 환불계좌 조회
 * @param adAccountId
 * @returns {*}
 */
function requestRefundAccountInfo(adAccountId) {
  return fetchGet(
    `${BILLING}/v1/wallet/${adAccountId}/withdrawAccount`,
    adAccountId
  )
}

/**
 * 환불계좌 생성 및 수정
 * @param adAccountId
 * @param isModify
 * @param requestBody
 * @return {*}
 */
function requestSetRefundAccountInfo(adAccountId, isModify, requestBody) {
  const fetchMethod = isModify ? fetchPut : fetchPost
  return fetchMethod(
    `${BILLING}/v1/wallet/${adAccountId}/withdrawAccount`,
    requestBody,
    adAccountId
  )
}

/**
 * 환불계좌 삭제
 * @param adAccountId
 * @returns {*}
 */
function requestRemoveRefundAccount(adAccountId) {
  return fetchDelete(
    `${BILLING}/v1/wallet/${adAccountId}/withdrawAccount`,
    {},
    adAccountId
  )
}

/**
 * 충전하기
 * @param adAccountId
 * @param requestBody
 * @returns {*}
 */
function requestChargeCash(adAccountId, requestBody) {
  return fetchPost(
    `${BILLING}/v1/wallet/${adAccountId}/charge/paymentOrder`,
    requestBody,
    adAccountId
  )
}

/**
 * 충전 완료 후 결제 정보 확인
 * @param adAccountId
 * @param kakaoBillingTxId
 * @returns {*}
 */
function requestPaymentInfo(adAccountId, kakaoBillingTxId) {
  return fetchGet(
    `${BILLING}/v1/charge/${kakaoBillingTxId}/paymentInfo`,
    adAccountId
  )
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 리스트 조회
 * @param adAccountId
 * @returns {*}
 */
function fetchDeferredPaymentList(adAccountId, searchOptions) {
  return fetchGet(
    `${BILLING}/v2/wallet/${adAccountId}/bond?${queryString(searchOptions)}`,
    adAccountId
  )
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 리스트 > 결제 상세내역 조회 파업
 * @param adAccountId
 * @param params
 * @param {"YYYYMM"} params.settleDt
 * @param {GENERAL | PENALTY}params.bondType
 * @returns {*}
 */
function fetchRepaymentDetail(adAccountId, params) {
  return fetchGet(
    `${BILLING}/v2/wallet/${adAccountId}/repayment?${queryString(params)}`,
    adAccountId
  )
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 결제 가능내역 조회 팝업
 * @param adAccountId
 * @returns {*}
 */
function fetchEnableRepayment(adAccountId) {
  return fetchGet(`${BILLING}/v2/wallet/${adAccountId}/bond/repay`, adAccountId)
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 결제 가능내역 조회 팝업 > 결제 요청
 * @param adAccountId
 * @returns {*}
 */
function requestRepayment(adAccountId, requestBody) {
  return fetchPost(
    `${BILLING}/v2/wallet/${adAccountId}/bond/repay`,
    requestBody,
    adAccountId
  )
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 가상결제 발급이력
 * @param adAccountId
 * @returns {*}
 */
function fetchVirtualAccountHistory(adAccountId) {
  return fetchGet(`${BILLING}/v2/wallet/${adAccountId}/plan`, adAccountId)
}

/**
 * 설정 > 광고캐시 관리 > 후불결제 현황 > 가상계좌 발급 이력 삭제
 * @param adAccountId
 * @param txId
 * @returns {*}
 */
function deleteVirtualAccount(adAccountId, txId) {
  return fetchDelete(
    `${BILLING}/v2/wallet/${adAccountId}/plan?txId=${txId}`,
    null,
    adAccountId
  )
}

function fetchCancelDepositFeeHistory(adAccountId, searchOptions) {
  return fetchPost(
    `/aggregation/preference/cash/adAccounts/${adAccountId}/depositCanceled`,
    searchOptions,
    adAccountId
  )
}

/**
 * 08.363 후불한도 변경이력
 * @param adAccountId
 * @param searchOptions
 * @returns {null}
 */
function fetchDeferredPaymentLimitChangeLog(adAccountId, searchOptions) {
  return CruxFetchUtils.get({
    url: `${CRUX_ACCOUNT}/adAccounts/${adAccountId}/paymentLog?${queryString(
      searchOptions
    )}`,
    adAccountId,
  })
}

export default {
  fetchChargeHistory,
  fetchFreeCashHistory,
  fetchWithdrawHistory,
  downloadCashChargeHistory,
  requestUseFreeCash,
  requestRefund,
  fetchAdAccountCashInfo,
  requestRefundAccountInfo,
  requestSetRefundAccountInfo,
  requestRemoveRefundAccount,
  requestChargeCash,
  requestPaymentInfo,
  fetchDeferredPaymentList,
  fetchEnableRepayment,
  fetchRepaymentDetail,
  requestRepayment,
  fetchVirtualAccountHistory,
  deleteVirtualAccount,
  fetchCancelDepositFeeHistory,
  fetchDeferredPaymentLimitChangeLog,
}
