import React from 'react'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
} from '../../../utils/regexUtils'

import {
  convertToCommaSeparatedString,
  DATE_PRESET_KEY,
  dateTimeToString,
  dateToString,
  keyMirror,
  selectedDatePickerValue,
} from '../../../utils/utils'
import { RoleType } from '../../common/mRole'
import { deleteRefundAccountInfo } from '../mCash'
import { DspAccountHelper } from '../../../utils/helper/helper-dspAccount'
import { MomentLoader } from '../../../components/Common/Loader/MomentLoader'
import BankEnum from '../../../enums/BankEnum'

// FIXME Jaden 전체적으로 정리 필요함

export const CASH_LOADING_STATE = keyMirror({
  IDLE: null,
  LOADING: null,
})

export const BILLING_MEMBER_TYPE = keyMirror({
  DSP_ACCOUNT: null,
  AGENCY_ACCOUNT: null,
  INHOUSE_ACCOUNT: null,
  SYSTEM: null,
})

// FIXME 얘는 굳이 필요 없도록 해봅시다고
export const PAGE_STATE_KEY = keyMirror({
  chargeHistory: null,
  freeCashHistory: null,
  withdrawHistory: null,
  withdrawAccount: null,
})

export const CHARGE_STATUS_KEY = keyMirror({
  SUCCESS: null,
  FAIL: null,
})

export const FREE_CASH_STATUS_KEY = keyMirror({
  READY: null,
  USING: null,
  USED: null,
  EXPIRED: null,
  CANCEL: null,
})

export const WITHDRAW_STATUS_KEY = keyMirror({
  STANDBY: null,
  REJECTED: null,
  APPROVED: null,
  RESERVED: null,
})

export const WITHDRAW_TYPE = keyMirror({
  FULL: null,
  PARTIAL: null,
})

export const WITHDRAW_ERROR_TYPE = keyMirror({
  ALREADY_REQUESTED: null,
  BALANCE_DEFICIT: null,
  OVER_THAN_CASH: null,
  NEED_AD_OFF: null,
  NEED_FIX_AMOUNT: null,
  NO_INPUT_AMOUNT: null,
  IMPOSSIBLE_REFUND: null,
  NEED_ALL_WITHDRAW: null,
})

export const REQUEST_WITHDRAW_STEP = keyMirror({
  REQUEST: null,
  COMPLETE: null,
  REJECT: null,
})

export const FREE_CASH_STATUS_DESCRIPTION = {
  [FREE_CASH_STATUS_KEY.READY]: '사용전',
  [FREE_CASH_STATUS_KEY.USING]: '사용 중',
  [FREE_CASH_STATUS_KEY.USED]: '사용완료',
  [FREE_CASH_STATUS_KEY.EXPIRED]: '만료',
  [FREE_CASH_STATUS_KEY.CANCEL]: '회수',
}

export const WITHDRAW_STATUS_DESCRIPTION = {
  [WITHDRAW_STATUS_KEY.STANDBY]: '처리중',
  [WITHDRAW_STATUS_KEY.RESERVED]: '처리중',
  [WITHDRAW_STATUS_KEY.REJECTED]: '처리불가',
  [WITHDRAW_STATUS_KEY.APPROVED]: '처리완료',
}

export const WITHDRAW_ERROR_DESCRIPTION = {
  [WITHDRAW_ERROR_TYPE.ALREADY_REQUESTED]: '이미 전액환불 요청 중입니다.',
  [WITHDRAW_ERROR_TYPE.BALANCE_DEFICIT]: '잔액이 부족합니다.',
  [WITHDRAW_ERROR_TYPE.OVER_THAN_CASH]:
    '요청하신 금액이 유상캐시 잔액보다 많습니다.',
  [WITHDRAW_ERROR_TYPE.NEED_FIX_AMOUNT]:
    '요청하신 금액이 유상캐시 잔액보다 많습니다.',
  [WITHDRAW_ERROR_TYPE.NO_INPUT_AMOUNT]: '환불 금액을 입력하세요.',
  [WITHDRAW_ERROR_TYPE.NEED_AD_OFF]:
    '전액 환불은 광고계정 OFF 후 요청 가능합니다.',
  [WITHDRAW_ERROR_TYPE.IMPOSSIBLE_REFUND]:
    '환불 가능한 유상캐시 잔액이 0원 입니다.',
  [WITHDRAW_ERROR_TYPE.NEED_ALL_WITHDRAW]:
    '유상캐시 잔액과 환불 요청 금액이 동일합니다. 전액환불로 요청하세요.',
}

export const LIST_NO_DATA_DESCRIPTION = {
  [PAGE_STATE_KEY.chargeHistory]: '충전 내역이 없습니다.',
  [PAGE_STATE_KEY.freeCashHistory]: '무상캐시 사용현황이 없습니다.',
  [PAGE_STATE_KEY.withdrawHistory]: '환불 처리현황이 없습니다.',
}

export const WITHDRAW_ACCOUNT_INFO_DESCRIPTION = {
  INDIVIDUAL: '광고계정 생성 시 확인된 본인 명의 계좌로 등록하세요.',
  BUSINESS: '광고계정 생성 시 등록한 사업자 이름의 계좌로 등록하세요.',
  OVERSEAS: '광고계정 생성 시 등록한 사업자 이름의 계좌로 등록하세요.',
}

// FIXME 얘들은 공통에 있을 필요가 없음.
export const freeCashOptionListItems = [
  { id: 0, label: '전체', status: '', link: '' },
  {
    id: 1,
    label: FREE_CASH_STATUS_DESCRIPTION[FREE_CASH_STATUS_KEY.READY],
    status: FREE_CASH_STATUS_KEY.READY,
    link: '',
  },
  {
    id: 2,
    label: FREE_CASH_STATUS_DESCRIPTION[FREE_CASH_STATUS_KEY.USING],
    status: FREE_CASH_STATUS_KEY.USING,
    link: '',
  },
  {
    id: 3,
    label: FREE_CASH_STATUS_DESCRIPTION[FREE_CASH_STATUS_KEY.USED],
    status: FREE_CASH_STATUS_KEY.USED,
    link: '',
  },
  {
    id: 3,
    label: FREE_CASH_STATUS_DESCRIPTION[FREE_CASH_STATUS_KEY.EXPIRED],
    status: FREE_CASH_STATUS_KEY.EXPIRED,
    link: '',
  },
  {
    id: 4,
    label: FREE_CASH_STATUS_DESCRIPTION[FREE_CASH_STATUS_KEY.CANCEL],
    status: FREE_CASH_STATUS_KEY.CANCEL,
    link: '',
  },
]

export const withdrawOptionListItems = [
  { id: 0, label: '전체', status: '', link: '' },
  {
    id: 1,
    label: WITHDRAW_STATUS_DESCRIPTION[WITHDRAW_STATUS_KEY.STANDBY],
    status: [WITHDRAW_STATUS_KEY.STANDBY, WITHDRAW_STATUS_KEY.RESERVED].join(
      ','
    ),
    link: '',
  },
  {
    id: 2,
    label: WITHDRAW_STATUS_DESCRIPTION[WITHDRAW_STATUS_KEY.REJECTED],
    status: WITHDRAW_STATUS_KEY.REJECTED,
    link: '',
  },
  {
    id: 3,
    label: WITHDRAW_STATUS_DESCRIPTION[WITHDRAW_STATUS_KEY.APPROVED],
    status: WITHDRAW_STATUS_KEY.APPROVED,
    link: '',
  },
]

export const cashChargeMethodItems = [
  { id: 0, status: 'CREDIT_CARD', label: '신용카드 일반결제' },
  { id: 1, status: 'VIRTUAL_ACCOUNT', label: '무통장입금' }, // 해외광고주일 경우에는 "(주)카카오"임 (난제 수정할것.)
  {
    id: 2,
    status: 'KAKAOPAY_CARD_AUTO',
    label: '신용카드 자동 결제',
  },
  {
    id: 3,
    status: 'ADMIN_CASH',
    label: '유상캐시 관리자 충전',
  },
  { id: 4, status: 'ADMIN_FREECASH', label: '무상캐시 사용', member: '' },
  {
    id: 5,
    status: 'VAT_CASH_REFUND',
    label: '유상캐시 환급',
  },
  {
    id: 6,
    status: 'CPMS',
    label: '메시지 미발송분',
  },
  {
    id: 7,
    status: 'ADMIN_ADJUST_FREECASH',
    label: '무상캐시 금액보정',
  },
  {
    id: 8,
    status: 'CPT',
    label: '계약해지 환급금',
  },
  {
    id: 9,
    status: 'ADV_RET_CASH',
    label: '유상캐시 광고주 환원',
  },
  {
    id: 10,
    status: 'ADV_RET_FREE_CASH',
    label: '무상캐시 광고주 환원',
  },
  {
    id: 11,
    status: 'EVENT_FREE_CASH',
    label: '이벤트 무상캐시 사용',
  },
]

export const cashDefaultDate = selectedDatePickerValue(
  DATE_PRESET_KEY.RECENT_ONE_MONTH
)

export const withdrawMethodItems = [
  { id: 0, status: 'CREDIT_CARD', label: '신용카드 승인취소' },
  { id: 1, status: 'VIRTUAL_ACCOUNT', label: '계좌환불' },
]

export const getChargeHistoryInfoLabel = v => {
  if (checkEmpty(v)) return null

  const {
    dt,
    type,
    chargeMethod,
    withdrawMethod,
    methodDetail,
    amount,
    memberEmail,
    memberStatus,
    memberType,
  } = v

  const methodItems = chargeMethod ? cashChargeMethodItems : withdrawMethodItems
  const methodItem = methodItems
    ? methodItems.find(
        v => v.status === chargeMethod || v.status === withdrawMethod
      )
    : null

  return {
    memberEmail: DspAccountHelper.Status.toString(memberStatus, memberEmail),
    trader: getTraderLabel(memberType, memberStatus, memberEmail),
    type,
    date: dateTimeToString(dt),
    methodDetail: checkNotEmpty(methodDetail) ? methodDetail : '-',
    method: methodItem?.label || '-',
    amount: convertToCommaSeparatedString(amount),
  }
}

export const getFreeCashHistoryInfoValue = v => {
  if (checkEmpty(v)) return {}

  const {
    description,
    amount,
    endDt,
    status,
    issueMemberKakaoEmail,
    createDt,
    issueMemberStatus,
    freeCashEventItemId,
    freeCashEventDescription = '',
  } = v

  return {
    description: description || '',
    member: DspAccountHelper.Status.toString(
      issueMemberStatus,
      issueMemberKakaoEmail
    ),
    type: status,
    createDt: dateTimeToString(createDt),
    endDt,
    amount: convertToCommaSeparatedString(amount),
    freeCashEventItemId,
    freeCashEventDescription,
  }
}

export const getWithdrawHistoryInfoValue = v => {
  if (checkEmpty(v)) return null

  const {
    requestDt,
    requestAmount,
    amount,
    memberType,
    memberEmail,
    memberStatus,
    status,
    resultDt,
    rejectReason,
  } = v

  const requestAmountLabel = checkNotEmpty(requestAmount)
    ? requestAmount > 0
      ? convertToCommaSeparatedString(requestAmount)
      : '-'
    : '전액환불'

  return {
    requestDt: dateTimeToString(requestDt),
    amount: getAmountLabel(amount, status),
    requestAmount: requestAmountLabel,
    resultDt: checkNotEmpty(resultDt) ? dateToString(resultDt) : '-',
    trader: getTraderLabel(memberType, memberStatus, memberEmail),
    memberEmail,
    status,
    rejectReason,
  }
}

export const getOwnerLabel = (memberEmail, kakaoEmail) => {
  if (checkEmpty(memberEmail) || checkEmpty(kakaoEmail)) return ''
  return memberEmail === kakaoEmail ? '(나)' : ''
}

const getAmountLabel = (amount, status) => {
  switch (status) {
    case WITHDRAW_STATUS_KEY.RESERVED:
    case WITHDRAW_STATUS_KEY.STANDBY:
      return '-'
    case WITHDRAW_STATUS_KEY.APPROVED:
      return convertToCommaSeparatedString(amount)
    case WITHDRAW_STATUS_KEY.REJECTED:
      return 0
    default:
      return null
  }
}

export const handleErrorCatch = (e, dispatch) => {
  const { errorCode, description } = e?.response?.data || {}

  if (checkNoneEmpty(errorCode, description)) {
    switch (errorCode) {
      // 환불처리 관련 에러
      case 3007:
        return WITHDRAW_ERROR_DESCRIPTION.ALREADY_REQUESTED
      case 1002:
        return WITHDRAW_ERROR_DESCRIPTION.BALANCE_DEFICIT
      case 3005:
        return WITHDRAW_ERROR_DESCRIPTION.OVER_THAN_CASH

      // 환불계좌 관련 에러
      case 4001:
        typeof dispatch === 'function' && dispatch(deleteRefundAccountInfo())
        return description
      case 4002:
      case 4003:
      case 4004:
        return description

      default:
        return description
    }
  }

  return null
}

const getListColSpanCount = key => {
  switch (key) {
    case PAGE_STATE_KEY.chargeHistory:
      return 6
    case PAGE_STATE_KEY.freeCashHistory:
    case PAGE_STATE_KEY.withdrawHistory:
      return 7
    default:
      return 6
  }
}

// FIXME 제거될 예정 ----------------------------------------------------------------

export const renderLoading = key => {
  const isListEl = checkNotEmpty(key)
  const colSpan = isListEl ? getListColSpanCount(key) : null

  if (isListEl) {
    return (
      <tr>
        <td colSpan={colSpan} className="nodata_info">
          <div className="nodata_wrap">
            <div className="inner_wrap">
              <span className="img_loading">
                <MomentLoader />
              </span>
            </div>
          </div>
        </td>
      </tr>
    )
  } else {
    // TODO 얘는 그냥 환불계좌 컴포넌트 안으로 넣어서 사용할것. 공통 요소가 아님 !!!

    const el = document.getElementById('withdrawAccountInfoBox')
    if (checkEmpty(el)) return null

    const top = el.getBoundingClientRect().height / 3

    return (
      <div className="loading_wrap" style={{ paddingTop: top }}>
        <MomentLoader />
      </div>
    )
  }
}

export const renderListNoData = key => {
  if (checkEmpty(key)) return null

  const colSpan = getListColSpanCount(key)
  return (
    <tr>
      <td colSpan={colSpan} className="nodata_info">
        <div className="inner_tbl">{LIST_NO_DATA_DESCRIPTION[key]}</div>
      </td>
    </tr>
  )
}

// FIXME 제거될 예정 ----------------------------------------------------------------

export const checkExistWithdrawItem = list => {
  if (checkEmpty(list)) return false

  return list.some(v =>
    [WITHDRAW_STATUS_KEY.STANDBY, WITHDRAW_STATUS_KEY.RESERVED].includes(
      v.get('status')
    )
  )
}

export const checkRoleMaster = role => {
  return [RoleType.MASTER, RoleType.OBSERVER].includes(role)
}

export const defaultWithdrawAccountForm = {
  accountNumber: '',
  ownerName: '',
  bankCode: BankEnum.getValuesByOptionListItems()[0].code,
}

export const getWithdrawAccountValue = info => {
  if (checkEmpty(info)) return defaultWithdrawAccountForm

  const { accountNumber, ownerName, bankCode } = info
  const bankItem = BankEnum.getValuesByOptionListItems().find(
    v => v.code === bankCode
  )

  return {
    accountNumber,
    ownerName,
    bankCode: checkNotEmpty(bankItem)
      ? bankItem.code
      : BankEnum.getValuesByOptionListItems()[0].code,
    bankName: checkNotEmpty(bankItem)
      ? bankItem.label
      : BankEnum.getValuesByOptionListItems()[0].label,
  }
}

export const getTotalCashAmount = ({ cash = 0, freeCash = 0 }) =>
  parseFloat((Number(cash) + Number(freeCash)).toFixed(1))

export const getTraderLabel = (memberType, memberStatus, memberEmail) => {
  switch (memberType) {
    case BILLING_MEMBER_TYPE.DSP_ACCOUNT:
      return DspAccountHelper.Status.toString(memberStatus, memberEmail)
    case BILLING_MEMBER_TYPE.AGENCY_ACCOUNT:
      return '카카오광고 통합에이전시'
    case BILLING_MEMBER_TYPE.SYSTEM:
    case BILLING_MEMBER_TYPE.INHOUSE_ACCOUNT:
      return '(주)카카오'
    default:
      return ''
  }
}
