import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { InputFileUtil } from '../../../../../utils/fileUtils'
import { setIsVisibleImageMasking } from '../../../../../modules/advertise/mCreativeCommonV2'
import { showErrorMessage } from '../../../../../utils/alertUtils'
import {
  openPopupByProxy,
  POPUP_KEY,
} from '../../../../../modules/common/mPopup'
import { cancelRequest } from '../../../../../utils/cancellation/cancellation'
import { useIsMounted } from '../../../../../utils/hook/useIsMounted'
import { v4 as uuid } from 'uuid'
import { exceedCreativeUploadImageCountDialog } from '../../../Common/AdvertiseDialog'

const selector = ({
  creativeV2: {
    common: { isVisibleImageMasking },
  },
}) => {
  return {
    isVisibleImageMasking,
  }
}

const useMultipleImageUpload = ({
  adAccountId,
  imageContainerRef,
  imageSelector,
  imageUploadCountMax = 100,
  /**
   * upload callback ({ files, cancelKey, onProgress, onFinish }) => void
   */
  cancelRequestIfUnmounted = true,
  onUpload,
}) => {
  const dispatch = useDispatch()
  const isMounted = useIsMounted()

  const imageProps = useSelector(imageSelector, shallowEqual)
  const ownProps = useSelector(selector, shallowEqual)

  const { newestImage, countOfImages } = imageProps

  React.useEffect(() => {
    /**
     * -- 직전 업로드한 이미지들 중 real size 기준 가장 후순위인 것으로 강제 스크롤(포커싱) --
     * key 는 savedFileName(unique)
     * 타겟이 이미지 컨테이너에서 위 혹은 아래에 숨겨져 있을 경우, offset 을 계산 후 스크롤을 조정하여 포커싱한다.
     */
    if (imageContainerRef?.current && newestImage) {
      const { savedFileName } = newestImage
      const imageViewEl = document.getElementById(savedFileName)
      const imageListContainerEl = imageContainerRef.current

      if (imageViewEl && imageListContainerEl) {
        const imageRect = imageViewEl.getBoundingClientRect() // 타겟 이미지 Rect
        const imageContainerRect = imageListContainerEl.getBoundingClientRect() // 이미지 컨테이너 Rect
        const scrollTop = imageListContainerEl.scrollTop // 이미지 컨테이너의 현재 스크롤 포지션
        const outOfSightTop = imageRect.top < imageContainerRect.top
        const outOfSightBottom = imageRect.bottom > imageContainerRect.bottom

        if (outOfSightTop) {
          // 타겟이 컨테이너 위로 숨겨져 있을 경우
          imageListContainerEl.scrollTop =
            scrollTop - (imageContainerRect.top - imageRect.top) - 20
        } else if (outOfSightBottom) {
          // 타겟이 컨테이너 아래로 숨겨져 있을 경우
          imageListContainerEl.scrollTop =
            scrollTop + (imageRect.bottom - imageContainerRect.bottom)
        }
      }
    }
  }, [imageContainerRef, newestImage])

  const [isOverImageDropTarget, setIsOverImageDropTarget] =
    React.useState(false)
  const [imageUploadCancelKey, setImageUploadCancelKey] = React.useState(null)
  const [imageUploadState, setImageUploadState] = React.useState({
    progress: 0,
    requested: false,
    received: false,
  })

  const [fileCount, setFileCount] = React.useState(0)

  React.useEffect(() => {
    return () => {
      if (cancelRequestIfUnmounted) {
        cancelRequest(imageUploadCancelKey)
      }
    }
  }, [cancelRequestIfUnmounted, imageUploadCancelKey])

  const Handlers = {
    onImageDrop: e => {
      e.preventDefault()
      e.stopPropagation()

      const { files } = e.dataTransfer
      handleUploadImages(e, files)
    },
    onImageDragOver: e => {
      e.preventDefault()
      e.stopPropagation()
    },
    onImageDragEnter: e => {
      e.preventDefault()
      e.stopPropagation()

      setIsOverImageDropTarget(true)
    },
    onImageDragLeave: e => {
      e.preventDefault()
      e.stopPropagation()

      setIsOverImageDropTarget(false)
    },
    onImageDragEnd: e => {
      e.preventDefault()
      e.stopPropagation()

      setIsOverImageDropTarget(false)
    },
    onImageChange: e => {
      const { files } = e.target
      handleUploadImages(e, files)
    },
  }

  const handleUploadImages = (e, files) => {
    if (!InputFileUtil.isValidImageType(files)) {
      showErrorMessage('등록 가능한 이미지 포맷을 확인하신 후 다시 시도하세요.')
    } else {
      const isValidFileCount = InputFileUtil.isValidCount(
        files,
        imageUploadCountMax - countOfImages
      )

      if (!isValidFileCount) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            exceedCreativeUploadImageCountDialog(imageUploadCountMax)
          )
        )
      } else {
        setFileCount(files.length)

        const imageUploadCancelKey = uuid()

        dispatch(setIsVisibleImageMasking(true))

        if (typeof onUpload === 'function') {
          onUpload({
            files,
            cancelKey: imageUploadCancelKey,
            onProgress: progress => {
              if (isMounted.current) {
                setImageUploadState({
                  progress,
                  requested: progress === 1,
                  received: false,
                })
              }
            },
            onFinish: () => {
              if (isMounted.current) {
                setImageUploadState({
                  progress: 0,
                  requested: false,
                  received: true,
                })

                setImageUploadCancelKey(null)
              }
            },
          })
        }

        setImageUploadCancelKey(imageUploadCancelKey)
      }
    }

    setIsOverImageDropTarget(false)

    e.target.value = null
  }

  return {
    ...imageProps,
    ...ownProps,
    fileCount,
    isOverImageDropTarget,
    imageUploadCancelKey,
    setImageUploadCancelKey,
    imageUploadState,
    setImageUploadState,
    ...Handlers,
  }
}

export { useMultipleImageUpload }
