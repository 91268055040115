import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import { unusedAudienceDialog } from '../../../components/Targeting/Audience/Common/Dialog/AudienceDialog'
import { handleAudienceError } from './mAudienceCommon'
import { hideLoading, showLoading } from '../../common/mLoading'
import axios from 'axios'
import AudienceTypeEnum from '../../../enums/AudienceTypeEnum'
import { CATEGORY_TARGET_KEY } from '../../../utils/advertise/targeting'

export const TARGET_USAGE_TAB_KEY = keyMirror({
  AD_GROUP: null,
  AUDIENCE: null,
})

export const TARGET_USAGE_TAB_KEY_TEXT = {
  AD_GROUP: '광고그룹',
  AUDIENCE: '오디언스',
}

const AudienceList = keyMirror(
  {
    CHANGE_AUDIENCE_LIST_COUNT: null,
    INIT_AUDIENCE_LIST: null,

    CHANGE_TARGET_USAGE_BY_KEY: null,
  },
  'AUDIENCE_LIST'
)

const initialState = fromJS({
  managementList: {
    displayAudienceCount: 0,
    messageAudienceCount: 0,
  },
  targetUsageList: {
    selectedTabKey: TARGET_USAGE_TAB_KEY.AD_GROUP,
    totalElements: 0,
  },
})

export default createReducer(initialState, {
  [AudienceList.CHANGE_AUDIENCE_LIST_COUNT]: (state, { count }) =>
    state
      .setIn(
        ['managementList', 'displayAudienceCount'],
        count.displayAudienceCount
      )
      .setIn(
        ['managementList', 'messageAudienceCount'],
        count.messageAudienceCount
      ),

  [AudienceList.CHANGE_TARGET_USAGE_BY_KEY]: (state, { key, value }) =>
    state.setIn(['targetUsageList', key], value),

  [AudienceList.INIT_AUDIENCE_LIST]: state =>
    state.withMutations(s =>
      s
        .set('managementList', initialState.get('managementList'))
        .set('targetUsageList', initialState.get('targetUsageList'))
    ),
})

export const changeAudienceTargetUsageByKey = (key, value) => ({
  type: AudienceList.CHANGE_TARGET_USAGE_BY_KEY,
  key,
  value,
})

export const initAudienceList = () => ({
  type: AudienceList.INIT_AUDIENCE_LIST,
})

const changeAudienceManagementListCount = count => ({
  type: AudienceList.CHANGE_AUDIENCE_LIST_COUNT,
  count,
})

/**
 *  타겟 관리 > 오디언스 목록 조회
 */
export const fetchAudienceList = (
  adAccountId,
  audienceType,
  queryParams,
  onFinish
) => {
  return async (dispatch, getState, api) => {
    const finishCallback = _data => {
      if (typeof onFinish === 'function') {
        onFinish(_data || {})
      }
    }

    try {
      const [
        { data: displayAudienceResponse },
        { data: messageAudienceResponse },
      ] = await axios.all([
        api.audience.fetchAudienceList(adAccountId, {
          ...queryParams,
          audienceType: AudienceTypeEnum.Type.DISPLAY,
        }),
        api.audience.fetchAudienceList(adAccountId, {
          ...queryParams,
          audienceType: AudienceTypeEnum.Type.MESSAGE,
        }),
      ])

      dispatch(
        changeAudienceManagementListCount({
          displayAudienceCount: displayAudienceResponse.totalElements,
          messageAudienceCount: messageAudienceResponse.totalElements,
        })
      )

      const data =
        audienceType === AudienceTypeEnum.Type.DISPLAY
          ? fromJS(displayAudienceResponse)
          : fromJS(messageAudienceResponse)

      const {
        targeting: {
          categoryTargeting: { idLabelMap },
        },
      } = getState()

      const audienceList = data.set(
        'content',
        data.get('content').map(v =>
          v.withMutations(s =>
            s
              .set(
                'ufoBusinessTypes',
                v
                  .get('ufoBusinessTypes')
                  .filter(v =>
                    idLabelMap.get(CATEGORY_TARGET_KEY.business).get(v)
                  )
              )
              .set(
                'ufoInterests',
                s
                  .get('ufoInterests')
                  .filter(v =>
                    idLabelMap.get(CATEGORY_TARGET_KEY.interests).get(v)
                  )
              )
          )
        )
      )

      finishCallback(audienceList)
    } catch (e) {
      console.log(e.message)
      finishCallback()
    }
  }
}

/**
 *  타겟 관리 > 오디언스 사용현황 목록 조회
 */
export const fetchAudienceUsageList = (
  adAccountId,
  audienceId,
  campaignTypeGoalId = -1,
  page = 0,
  onSuccess
) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        ...(campaignTypeGoalId !== -1 && { campaignTypeGoalId }),
        page,
      }

      const { data } = await api.audience.fetchAudienceUsageList(
        adAccountId,
        audienceId,
        queryParams
      )

      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (e) {
      console.log(e.message)
    }
  }
}

/**
 *  타겟 관리 > 오디언스 변경이력 목록
 */
export const fetchAudienceChangeLogList = (
  adAccountId,
  audienceId,
  page,
  onSuccess = () => undefined,
  onError = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        page,
        size: 10,
      }

      const { data } = await api.editLog.fetchAudienceChangeLog(
        adAccountId,
        audienceId,
        queryParams
      )

      onSuccess(fromJS(data))
    } catch (e) {
      console.log(e.message)
      onError()
    }
  }
}

export const fetchCohortTargetAudienceUsageList = (
  adAccountId,
  cohortId,
  page,
  onSuccess = () => undefined,
  onError = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        page,
        size: 10,
      }

      const { data } = await api.audience.fetchCohortTargetAudienceUsageList(
        adAccountId,
        cohortId,
        queryParams
      )

      dispatch(
        changeAudienceTargetUsageByKey('totalElements', data.totalElements)
      )

      onSuccess(fromJS(data))
    } catch (e) {
      console.log(e.message)
      onError()
    }
  }
}

export const fetchCustomerFileTargetAudienceUsageList = (
  adAccountId,
  customerFileId,
  page,
  onSuccess = () => undefined,
  onError = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        page,
        size: 10,
      }

      const { data } =
        await api.audience.fetchCustomerFileTargetAudienceUsageList(
          adAccountId,
          customerFileId,
          queryParams
        )

      dispatch(
        changeAudienceTargetUsageByKey('totalElements', data.totalElements)
      )

      onSuccess(fromJS(data))
    } catch (e) {
      console.log(e.message)
      onError()
    }
  }
}

export const fetchPlusFriendGroupTargetAudienceUsageList = (
  adAccountId,
  plusFriendGroupId,
  page,
  onSuccess = () => undefined,
  onError = () => undefined
) => {
  return async (dispatch, getState, api) => {
    try {
      const queryParams = {
        page,
        size: 10,
      }

      const { data } =
        await api.audience.fetchPlusFriendGroupTargetAudienceUsageList(
          adAccountId,
          plusFriendGroupId,
          queryParams
        )

      dispatch(
        changeAudienceTargetUsageByKey('totalElements', data.totalElements)
      )

      onSuccess(fromJS(data))
    } catch (e) {
      console.log(e.message)
      onError()
    }
  }
}

export const requestUnusedAudience = (
  adAccountId,
  adGroupId,
  audienceId,
  onFinish = () => undefined
) => {
  return (dispatch, getState, api) => {
    const onClick = async () => {
      try {
        dispatch(showLoading())
        await api.audience.requestUnusedAudience(
          adAccountId,
          adGroupId,
          audienceId
        )
      } catch (e) {
        console.log(e.message)
        handleAudienceError({ e, dispatch })
      } finally {
        onFinish()
        dispatch(hideLoading())
      }
    }

    dispatch(
      openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, unusedAudienceDialog(onClick))
    )
  }
}
