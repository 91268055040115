import { keyMirror } from '../../utils/utils'
import PropTypes from 'prop-types'

const DYNAMIC_TOOLTIP_POSITION_HORIZONTAL = keyMirror({
  LEFT: null,
  CENTER: null,
  RIGHT: null,
})

const DYNAMIC_TOOLTIP_POSITION_VERTICAL = keyMirror({
  TOP: null,
  BOTTOM: null,
})

export const DYNAMIC_TOOLTIP_Z_INDEX = 1100

const DynamicTooltipFixedStyles = {
  // -- 수정 NO.
  position: 'fixed',
  display: 'none',
  whiteSpace: 'nowrap',
  zIndex: DYNAMIC_TOOLTIP_Z_INDEX,
  letterSpacing: '0px',
  textIndent: 0,
  wordBreak: 'break-word',
}

/**
 * 기본 white 스타일.
 */
const DynamicTooltipStyles = {
  ...DynamicTooltipFixedStyles,
  padding: '8px 13px 11px',
  borderRadius: '4px',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '18px',
  maxWidth: '300px',
  backgroundColor: '#fff',
  color: '#555',
  textAlign: 'left',
  boxShadow: '0 2px 10px rgba(0,0,0,0.25)',
}

/**
 * dark 스타일.
 */
const DynamicTooltipDarkStyles = {
  ...DynamicTooltipFixedStyles,
  padding: '0 5px',
  borderRadius: '2px',
  fontWeight: 500,
  fontSize: '11px',
  lineHeight: '22px',
  maxWidth: '300px',
  minHeight: '24px',
  backgroundColor: '#666',
  color: '#fff',
  textAlign: 'left',
  boxShadow: 'none',
}

const DynamicTooltipCommonPropTypes = {
  content: PropTypes.any,
  verticalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_VERTICAL)
  ),
  horizontalPosition: PropTypes.oneOf(
    Object.keys(DYNAMIC_TOOLTIP_POSITION_HORIZONTAL)
  ),
  rootElementType: PropTypes.string,
  rootElementClassName: PropTypes.any,
  rootElementStyles: PropTypes.object,
  className: PropTypes.string,
  styles: PropTypes.object,
  children: PropTypes.any,
  getContainerNode: PropTypes.func,
  onClick: PropTypes.func,
  onVisible: PropTypes.func,
  transitionTimeout: PropTypes.number,
}

const DynamicTooltipUtils = {
  /**
   * 현재 화면의 브라우저 영역에서 마우스 커서가 위치한 타겟 오브젝트 기준으로 툴팁이 그려질 수 있는 vertical position 을 정한다.
   * default: BOTTOM, 영역이 부족할 경우 TOP.
   * @param containerRect {object}: 툴팁 타겟 오브젝트의 Rect
   * @param documentBodyRect {object}: 현재 브라우저의 Rect (스크롤 offset 반영)
   * @param targetHeight {number}: 툴팁 View 의 Height
   * @return {DYNAMIC_TOOLTIP_POSITION_VERTICAL}
   */
  getRealVerticalPosition({ containerRect, targetHeight, verticalPosition }) {
    const documentBodyRect = document.body.getBoundingClientRect()

    const y =
      verticalPosition === DYNAMIC_TOOLTIP_POSITION_VERTICAL.TOP
        ? containerRect.top
        : containerRect.bottom

    if (verticalPosition === DYNAMIC_TOOLTIP_POSITION_VERTICAL.TOP) {
      return y - targetHeight < 0
        ? DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM
        : DYNAMIC_TOOLTIP_POSITION_VERTICAL.TOP
    } else {
      const r = documentBodyRect.height - y
      return targetHeight - r < 0
        ? DYNAMIC_TOOLTIP_POSITION_VERTICAL.BOTTOM
        : DYNAMIC_TOOLTIP_POSITION_VERTICAL.TOP
    }
  },

  /**
   * 현재 화면의 브라우저 영역에서 마우스 커서가 위치한 타겟 오브젝트 기준으로 툴팁이 그려질 수 있는 horizontal position 을 정한다.
   * default: props.horizontalPosition
   * 영역이 부족할 경우 default position 의 반대 방향으로 *필요한 만큼만* 밀어낸다.
   * @param x {number}: 툴팁 타겟 오브젝트의 절대 포지션 x
   * @param targetWidth {number}: 툴팁 View 의 width
   * @param horizontalPosition {DYNAMIC_TOOLTIP_POSITION_HORIZONTAL}
   * @return {number}
   */
  getHorizontalMargin({ x, targetWidth, horizontalPosition }) {
    const pageXOffset = window.pageXOffset
      ? window.pageXOffset
      : (document.documentElement || document.body.parentNode || document.body)
          .scrollLeft

    const documentBodyRect = document.body.getBoundingClientRect()

    if (horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.LEFT) {
      const r =
        x + documentBodyRect.right + pageXOffset - documentBodyRect.width
      return r - targetWidth < 0 ? r - targetWidth : 0
    } else if (
      horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.CENTER
    ) {
      const r1 =
        x + documentBodyRect.right + pageXOffset - documentBodyRect.width
      const r2 =
        documentBodyRect.width - (x + documentBodyRect.left + pageXOffset)
      if (r1 - targetWidth / 2 < 0) return r1 - targetWidth / 2
      if (targetWidth / 2 - r2 >= 0) return targetWidth / 2 - r2
      return 0
    } else {
      const r =
        documentBodyRect.width - (x + documentBodyRect.left + pageXOffset)
      return targetWidth - r < 0 ? 0 : targetWidth - r
    }
  },

  /**
   * horizontal margin 이 0 일 경우 or 반대 position 으로 밀어낸 값이 없을 경우 -> return 0 (pure 한 상태)
   * else (반대 방향으로 밀쳐진 상태) -> return (+,-)20 (기본 갭 사이즈를 반영함.)
   * @param horizontalMargin {number}
   * @param horizontalPosition {DYNAMIC_TOOLTIP_POSITION_HORIZONTAL}
   * @return {number}
   */
  getDefaultHorizontalOffset({ horizontalMargin, horizontalPosition }) {
    const OFFSET = 20
    if (horizontalMargin !== 0) return 0
    if (horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.LEFT)
      return -OFFSET
    if (horizontalPosition === DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.RIGHT)
      return OFFSET
    return 0
  },
}

export {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DYNAMIC_TOOLTIP_POSITION_VERTICAL,
  DynamicTooltipStyles,
  DynamicTooltipDarkStyles,
  DynamicTooltipCommonPropTypes,
  DynamicTooltipUtils,
}
