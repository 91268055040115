import DashboardEnumV2 from '../../enums/DashboardEnumV2'
import {
  DashboardFilterUtils,
  FILTER_TYPE,
} from '../../components/DashboardV3/DashboardHeader/DashboardFilter/dashboardFilterUtils'
import { toLower, toUpper } from 'lodash'
import memoizeOne from 'memoize-one'
import { dateToString } from '../../utils/utils'
import DashboardStatusEnum from '../../enums/DashboardStatusEnum'
import UserConfigEnum from '../../enums/UserConfigEnum'

const memoCampaignConditions = memoizeOne(conditions =>
  conditions.filter(
    ({ dashboardType }) => dashboardType === DashboardEnumV2.Type.CAMPAIGN
  )
)

const memoAdGroupConditions = memoizeOne(conditions =>
  conditions.filter(
    ({ dashboardType }) => dashboardType === DashboardEnumV2.Type.AD_GROUP
  )
)

const memoCreativeConditions = memoizeOne(conditions =>
  conditions.filter(
    ({ dashboardType }) => dashboardType === DashboardEnumV2.Type.CREATIVE
  )
)

const memoCampaignConditionals = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .filter(({ filterType }) => DashboardFilterUtils.isMetric({ filterType }))
    ?.map(({ filterSubType: metricCode, filterOperator, filterValue }) => ({
      indicator: toUpper(metricCode),
      conditionalFunction: filterOperator,
      value: filterValue.first(),
    }))
    ?.toJS()
)

const memoAdGroupConditionals = memoizeOne(conditions =>
  memoAdGroupConditions(conditions)
    .filter(({ filterType }) => DashboardFilterUtils.isMetric({ filterType }))
    ?.map(({ filterSubType: metricCode, filterOperator, filterValue }) => ({
      indicator: toUpper(metricCode),
      conditionalFunction: filterOperator,
      value: filterValue.first(),
    }))
    ?.toJS()
)

const memoCreativeConditionals = memoizeOne(conditions =>
  memoCreativeConditions(conditions)
    .filter(({ filterType }) => DashboardFilterUtils.isMetric({ filterType }))
    ?.map(({ filterSubType: metricCode, filterOperator, filterValue }) => ({
      indicator: toUpper(metricCode),
      conditionalFunction: filterOperator,
      value: filterValue.first(),
    }))
    ?.toJS()
)

const memoCampaignConditionalIndicators = memoizeOne(
  conditions =>
    memoCampaignConditionals(conditions)?.map(({ indicator }) =>
      toLower(indicator)
    ) || []
)

const memoAdGroupConditionalIndicators = memoizeOne(
  conditions =>
    memoAdGroupConditionals(conditions)?.map(({ indicator }) =>
      toLower(indicator)
    ) || []
)

const memoCreativeConditionalIndicators = memoizeOne(
  conditions =>
    memoCreativeConditionals(conditions)?.map(({ indicator }) =>
      toLower(indicator)
    ) || []
)

const memoCampaignDashboardFilters = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.OPERATION_STATUS)
    ?.get('filterValue')
    ?.toJS()
)

const memoAdGroupDashboardFilters = memoizeOne(conditions =>
  memoAdGroupConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.OPERATION_STATUS)
    ?.get('filterValue')
    ?.toJS()
)

/**
 * creativeDashboardFilters 의 경우 dashboardStatus + creativeReviewStatus 를 병합
 */
const memoCreativeDashboardFilters = memoizeOne(conditions => {
  const creativeDashboardStatusFilter = memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.OPERATION_STATUS)
    ?.get('filterValue')
    ?.toJS()

  const creativeReviewStatusFilter = memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.REVIEW_STATUS)
    ?.get('filterValue')
    ?.toJS()

  return [
    ...(creativeDashboardStatusFilter || []),
    ...(creativeReviewStatusFilter || []),
  ]
})

const memoCampaignOnOffFilter = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ON_OFF)
    ?.get('filterValue')
    ?.toJS()
)

const memoAdGroupOnOffFilter = memoizeOne(conditions =>
  memoAdGroupConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ON_OFF)
    ?.get('filterValue')
    ?.toJS()
)

const memoCreativeOnOffFilter = memoizeOne(conditions =>
  memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ON_OFF)
    ?.get('filterValue')
    ?.toJS()
)

const memoCampaignIdFilter = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ID)
    ?.get('filterValue')
    ?.toJS()
)

const memoAdGroupIdFilter = memoizeOne(conditions =>
  memoAdGroupConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ID)
    ?.get('filterValue')
    ?.toJS()
)

const memoCreativeIdFilter = memoizeOne(conditions =>
  memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.ID)
    ?.get('filterValue')
    ?.toJS()
)

const memoCampaignTypeFilter = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.CAMPAIGN_TYPE)
    ?.get('filterValue')
    ?.toJS()
)

const memoCampaignGoalFilter = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.GOAL)
    ?.get('filterValue')
    ?.toJS()
)

const memoCampaignNameFilter = memoizeOne(conditions =>
  memoCampaignConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.NAME)
    ?.get('filterValue')
    ?.take(1)
)

const memoAdGroupNameFilter = memoizeOne(conditions =>
  memoAdGroupConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.NAME)
    ?.get('filterValue')
    ?.take(1)
)

const memoCreativeNameFilter = memoizeOne(conditions =>
  memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.NAME)
    ?.get('filterValue')
    ?.take(1)
)

const memoCreativeFormatFilter = memoizeOne(conditions =>
  memoCreativeConditions(conditions)
    .find(({ filterType }) => filterType === FILTER_TYPE.CREATIVE_FORMAT)
    ?.get('filterValue')
    ?.toJS()
)

const memoCampaignFilter = memoizeOne(
  (dashboardType, conditions, summaryAdUnitId = undefined) => {
    const dashboardFilter = memoCampaignDashboardFilters(conditions)

    const isDeleted = dashboardFilter?.includes(
      DashboardStatusEnum.Type.DELETED
    )

    const filters = {
      ids:
        summaryAdUnitId > 0
          ? [summaryAdUnitId]
          : memoCampaignIdFilter(conditions),
      names: memoCampaignNameFilter(conditions),
      campaignTypes: memoCampaignTypeFilter(conditions),
      goals: memoCampaignGoalFilter(conditions),
    }

    const hasFilter = Object.values(filters).some(filter => filter?.length > 0)
    const hasDashboardFilter = !isDeleted && dashboardFilter?.length > 0
    const hasConditionalFilter =
      memoCampaignConditionals(conditions)?.length > 0
    const hasAnyFilter = hasFilter || hasDashboardFilter || hasConditionalFilter

    const onOffs = memoCampaignOnOffFilter(conditions)

    return {
      ...filters,
      userConfigs: isDeleted
        ? undefined
        : onOffs?.length > 0
        ? onOffs
        : dashboardType === DashboardEnumV2.Type.CAMPAIGN || hasAnyFilter
        ? [UserConfigEnum.Type.ON, UserConfigEnum.Type.OFF]
        : undefined,
    }
  }
)

const memoAdGroupFilter = memoizeOne(
  (
    dashboardType,
    conditions,
    campaignIdSet = undefined,
    summaryAdUnitId = undefined
  ) => {
    const dashboardFilter = memoAdGroupDashboardFilters(conditions)

    const isDeleted = dashboardFilter?.includes(
      DashboardStatusEnum.Type.DELETED
    )

    const filters = {
      ids:
        summaryAdUnitId > 0
          ? [summaryAdUnitId]
          : memoAdGroupIdFilter(conditions),
      campaignIds: campaignIdSet?.toJS(),
      names: memoAdGroupNameFilter(conditions),
    }

    const hasFilter = Object.values(filters).some(filter => filter?.length > 0)
    const hasDashboardFilter = !isDeleted && dashboardFilter?.length > 0
    const hasConditionalFilter = memoAdGroupConditionals(conditions)?.length > 0
    const hasAnyFilter = hasFilter || hasDashboardFilter || hasConditionalFilter

    const onOffs = memoAdGroupOnOffFilter(conditions)

    return {
      ...filters,
      userConfigs: isDeleted
        ? undefined
        : onOffs?.length > 0
        ? onOffs
        : dashboardType === DashboardEnumV2.Type.AD_GROUP || hasAnyFilter
        ? [UserConfigEnum.Type.ON, UserConfigEnum.Type.OFF]
        : undefined,
    }
  }
)

const memoCreativeFilter = memoizeOne(
  (
    dashboardType,
    conditions,
    campaignIdSet = undefined,
    adGroupIdSet = undefined,
    summaryAdUnitId = undefined
  ) => {
    const dashboardFilter = memoCreativeDashboardFilters(conditions)

    const isDeleted = dashboardFilter?.includes(
      DashboardStatusEnum.Type.DELETED
    )

    const filters = {
      ids:
        summaryAdUnitId > 0
          ? [summaryAdUnitId]
          : memoCreativeIdFilter(conditions),
      campaignIds: campaignIdSet?.toJS(),
      adGroupIds: adGroupIdSet?.toJS(),
      names: memoCreativeNameFilter(conditions),
      creativeFormats: memoCreativeFormatFilter(conditions),
    }

    const hasFilter = Object.values(filters).some(filter => filter?.length > 0)
    const hasDashboardFilter = !isDeleted && dashboardFilter?.length > 0
    const hasConditionalFilter =
      memoCreativeConditionals(conditions)?.length > 0
    const hasAnyFilter = hasFilter || hasDashboardFilter || hasConditionalFilter

    const onOffs = memoCreativeOnOffFilter(conditions)

    return {
      ...filters,
      userConfigs: isDeleted
        ? undefined
        : onOffs?.length > 0
        ? onOffs
        : dashboardType === DashboardEnumV2.Type.CREATIVE || hasAnyFilter
        ? [UserConfigEnum.Type.ON, UserConfigEnum.Type.OFF]
        : undefined,
    }
  }
)

const memoIndicators = memoizeOne(metrics => {
  const resultMetrics = metrics.map(({ id }) => id).toJS()

  // Roas, 추정 Roas 있을 경우 구매 전환율 추가
  if (resultMetrics.includes('conv_purchase_p_per_cost_1d')) {
    if (resultMetrics.includes('conv_purchase_1d')) {
      return [...resultMetrics, 'conv_purchase_with_p_per_conv_purchase_1d']
    } else {
      return [
        ...resultMetrics,
        'conv_purchase_1d',
        'conv_purchase_with_p_per_conv_purchase_1d',
      ]
    }
  }

  if (resultMetrics.includes('conv_purchase_p_per_cost_7d')) {
    if (resultMetrics.includes('conv_purchase_7d')) {
      return [...resultMetrics, 'conv_purchase_with_p_per_conv_purchase_7d']
    } else {
      return [
        ...resultMetrics,
        'conv_purchase_7d',
        'conv_purchase_with_p_per_conv_purchase_7d',
      ]
    }
  }

  return resultMetrics
})

const memoStart = memoizeOne(startDate => dateToString(startDate))

const memoEnd = memoizeOne(endDate => dateToString(endDate))

const DashboardRequestBodyUtils = {
  common({ metrics, conditions, startDate, endDate }) {
    /**
     * 상위 대시보드 레벨에 DELETED 가 걸린 경우, 하위는 empty 처리.
     */
    const campaignDashboardFilters = memoCampaignDashboardFilters(conditions)
    const isCampaignDeleted = campaignDashboardFilters?.includes(
      DashboardStatusEnum.Type.DELETED
    )

    const adGroupDashboardFilters = isCampaignDeleted
      ? []
      : memoAdGroupDashboardFilters(conditions)
    const isAdGroupDeleted = adGroupDashboardFilters?.includes(
      DashboardStatusEnum.Type.DELETED
    )
    const creativeDashboardFilters =
      isCampaignDeleted || isAdGroupDeleted
        ? []
        : memoCreativeDashboardFilters(conditions)

    const campaignConditionals = memoCampaignConditionals(conditions)
    const adGroupConditionals = memoAdGroupConditionals(conditions)
    const creativeConditionals = memoCreativeConditionals(conditions)

    // 필터에서 설정한 지표
    const campaignIndicators = memoCampaignConditionalIndicators(conditions)
    const adGroupIndicators = memoAdGroupConditionalIndicators(conditions)
    const creativeIndicators = memoCreativeConditionalIndicators(conditions)

    // 테이블에서 설정한 지표
    const tableIndicators = memoIndicators(metrics)

    // 필터에서 설정한 지표와 테이블 지표를 병합한다.
    const indicatorSet = new Set(
      tableIndicators.concat(
        campaignIndicators,
        adGroupIndicators,
        creativeIndicators
      )
    )

    return {
      campaignDashboardFilters,
      adGroupDashboardFilters,
      creativeDashboardFilters,
      campaignConditionals,
      adGroupConditionals,
      creativeConditionals,
      indicators: Array.from(indicatorSet),
      start: memoStart(startDate),
      end: memoEnd(endDate),
    }
  },

  chartSummary({ axes, startDate, endDate }) {
    return {
      indicators: axes,
      start: memoStart(startDate),
      end: memoEnd(endDate),
    }
  },

  campaignOperationTotalSummary({ metrics, conditions, startDate, endDate }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
    }
  },

  adGroupOperationTotalSummary({
    campaignIdSet,
    metrics,
    conditions,
    startDate,
    endDate,
  }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions,
        campaignIdSet
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
    }
  },

  adAccounts({ metrics, startDate, endDate }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        startDate,
        endDate,
      }),
    }
  },

  campaignTable({ summaryAdUnitId, metrics, conditions, startDate, endDate }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions,
        summaryAdUnitId
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
    }
  },

  adGroupTable({
    summaryAdUnitId,
    campaignIdSet,
    metrics,
    conditions,
    startDate,
    endDate,
  }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions,
        campaignIdSet,
        summaryAdUnitId
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
    }
  },

  creativeTable({
    summaryAdUnitId,
    campaignIdSet,
    adGroupIdSet,
    metrics,
    conditions,
    startDate,
    endDate,
  }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions,
        campaignIdSet,
        adGroupIdSet,
        summaryAdUnitId
      ),
    }
  },

  campaignTodayIndex({ metrics, conditions, startDate, endDate }) {
    const campaignIndicators = memoCampaignConditionalIndicators(conditions)
    const adGroupIndicators = memoAdGroupConditionalIndicators(conditions)
    const creativeIndicators = memoCreativeConditionalIndicators(conditions)

    // 필터에서 설정한 지표와 cost 를 병합(cost 는 항상 index 0 에 위치한다).
    const indicatorSet = new Set(
      ['cost'].concat(campaignIndicators, adGroupIndicators, creativeIndicators)
    )

    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      indicators: Array.from(indicatorSet),
      start: dateToString(),
      end: dateToString(),
    }
  },

  adGroupTodayIndex({ metrics, conditions, startDate, endDate }) {
    const campaignIndicators = memoCampaignConditionalIndicators(conditions)
    const adGroupIndicators = memoAdGroupConditionalIndicators(conditions)
    const creativeIndicators = memoCreativeConditionalIndicators(conditions)

    // 필터에서 설정한 지표와 cost 를 병합(cost 는 항상 index 0 에 위치한다).
    const indicatorSet = new Set(
      ['cost'].concat(campaignIndicators, adGroupIndicators, creativeIndicators)
    )

    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      indicators: Array.from(indicatorSet),
      start: dateToString(),
      end: dateToString(),
    }
  },

  creativeTodayIndex({ metrics, conditions, startDate, endDate }) {
    const campaignIndicators = memoCampaignConditionalIndicators(conditions)
    const adGroupIndicators = memoAdGroupConditionalIndicators(conditions)
    const creativeIndicators = memoCreativeConditionalIndicators(conditions)

    // 필터에서 설정한 지표와 cost 를 병합(cost 는 항상 index 0 에 위치한다).
    const indicatorSet = new Set(
      ['cost'].concat(campaignIndicators, adGroupIndicators, creativeIndicators)
    )

    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      indicators: Array.from(indicatorSet),
      start: dateToString(),
      end: dateToString(),
    }
  },

  adAccountTotalChart({ metrics, startDate, endDate }) {
    return {
      indicators: memoIndicators(metrics),
      start: memoStart(startDate),
      end: memoEnd(endDate),
    }
  },

  campaignTotalChart({ metrics, conditions, startDate, endDate }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CAMPAIGN,
        conditions
      ),
    }
  },

  adGroupTotalChart({
    campaignIdSet,
    metrics,
    conditions,
    startDate,
    endDate,
  }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions,
        campaignIdSet
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.AD_GROUP,
        conditions
      ),
    }
  },

  creativeTotalChart({
    campaignIdSet,
    adGroupIdSet,
    metrics,
    conditions,
    startDate,
    endDate,
  }) {
    return {
      ...DashboardRequestBodyUtils.common({
        metrics,
        conditions,
        startDate,
        endDate,
      }),
      campaignFilter: memoCampaignFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      adGroupFilter: memoAdGroupFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions
      ),
      creativeFilter: memoCreativeFilter(
        DashboardEnumV2.Type.CREATIVE,
        conditions,
        campaignIdSet,
        adGroupIdSet
      ),
    }
  },

  singleChart({ metrics, startDate, endDate }) {
    return {
      indicators: memoIndicators(metrics),
      start: memoStart(startDate),
      end: memoEnd(endDate),
    }
  },
}

export default DashboardRequestBodyUtils
