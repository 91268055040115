import EllipsisTooltip from '../../../../Tooltip/TooltipImpl/EllipsisTooltip'
import React from 'react'
import PropTypes from 'prop-types'
import { convertStringToBoldFaceByQuery } from '../../../../../utils/stringUtils'

const CmpAssetLibraryFileName = ({
  fileName = '',
  query = '',
  linkUrl = '',
}) => {
  return (
    <EllipsisTooltip rootElementType="p" rootElementClassName="txt_filename">
      {linkUrl ? (
        <a
          className="link_txt"
          rel="noopener noreferrer"
          target="_blank"
          href={linkUrl}>
          {convertStringToBoldFaceByQuery(
            String(fileName).normalize('NFC'),
            query,
            'fw_bold fc_emph'
          )}
        </a>
      ) : (
        <>
          {convertStringToBoldFaceByQuery(
            String(fileName).normalize('NFC'),
            query,
            'fw_bold fc_emph'
          )}
        </>
      )}
    </EllipsisTooltip>
  )
}

CmpAssetLibraryFileName.propTypes = {
  fileName: PropTypes.string,
  query: PropTypes.string,
  linkUrl: PropTypes.string,
}

export default CmpAssetLibraryFileName
