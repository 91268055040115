import TalkChannelMessageEnum from '../../enums/TalkChannelMessageEnum'
import { fromJS, List, Map, Range } from 'immutable'
import CreativeConstraints from '../constraints/constraints-creative'
import { v4 as uuid } from 'uuid'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import { Trim } from '../formTrim'
import {
  IS_VARIABLE_IMAGE,
  MESSAGE_TRIM_KEY_PATH,
} from '../../modules/message/mMessageCommon'
import { checkEmpty, checkNotEmpty } from '../regexUtils'
import KakaoStoreEnum from '../../enums/KakaoStoreEnum'
import { IS_NOT_VALID, IS_VALID } from '../../validators/validation'
import ChannelPostStatus from '../../enums/ChannelPostStatus'
import VideoEnum from '../../enums/VideoEnum'
import CouponBookTypeEnum from '../../enums/CouponBookTypeEnum'
import CouponBookTitleTypeEnum from '../../enums/CouponBookTitleTypeEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'

const TalkChannelMessageHelper = {
  initialForm: fromJS({
    adFlag: true,
    shareFlag: false,
    buttonAssetGroups: [],
    itemAssetGroups: [],
    couponBookAssetGroups: [],
    creativeFormat: CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
    id: -1,
    image: null,
    video: null,
    thumbnail: null,
    title: '',
    thumbnailUrl: null,
    description: null,
    uploadThumbnail: null,
  }),

  initialButtonAsset: fromJS({
    adViewId: -1,
    assetGroupId: -1,
    bizFormId: -1,
    channelCouponId: -1,
    channelPostId: -1,
    isHighlighted: false,
    landingType: TalkChannelMessageEnum.Landing.Type.NONE,
    mobileLandingUrl: '',
    ordering: 0,
    pcLandingUrl: '',
    title: '', // 버튼명
    webembedChannelUrl: '',
  }),

  initialAsset: fromJS({
    adViewId: -1,
    assetGroupId: -1,
    bizFormId: -1,
    channelCouponId: -1,
    channelPostId: -1,
    image: null,
    video: null,
    thumbnail: null,
    landingType: TalkChannelMessageEnum.Landing.Type.LANDING_URL,
    mobileLandingUrl: '',
    pcLandingUrl: '',
    title: '',
    ordering: 0,
    webembedChannelUrl: '',
    uploadThumbnail: null,
  }),

  initialCouponBookAsset: fromJS({
    couponBookType: null,
    couponBookTitleType: CouponBookTitleTypeEnum.Type.DISCOUNT_PRICE,
    couponBookTitle: '',
    title: '',
    channelCoupons: [],
    mobileLandingUrl: '',
    pcLandingUrl: '',
    ordering: 0,
  }),

  // 캐러셀 더보기
  initialAssetByCarouselMore: fromJS({
    channelCouponId: -1,
    channelPostId: -1,
    landingType: TalkChannelMessageEnum.Landing.Type.LANDING_URL,
    mobileLandingUrl: '',
    pcLandingUrl: '',
    talkStoreLandingUrl: '',
    webembedChannelUrl: '',
  }),

  // 캐러셀 인트로
  initialCarouselIntro: fromJS({
    hasIntro: true,
    image: null,
    title: '',
    description: null,

    introLandingType: TalkChannelMessageEnum.Landing.Type.NONE,
    introPcLandingUrl: '',
    introMobileLandingUrl: '',
    introTalkStoreLandingUrl: '',
    introChannelPostId: -1,
    introChannelPostTitle: '',
    introChannelPostStatus: ChannelPostStatus.Type.ACTIVE,
    introChannelCouponId: -1,
    introChannelCouponTitle: '',
    introWebembedChannelUrl: '',
  }),

  getDefaultMessage(type) {
    switch (type) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE: {
        return TalkChannelMessageHelper.Basic.createDefault()
      }
      case CreativeFormatEnum.Type.WIDE_MESSAGE: {
        return TalkChannelMessageHelper.Wide.createDefault()
      }
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE: {
        return TalkChannelMessageHelper.WideList.createDefault()
      }
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return TalkChannelMessageHelper.Carousel.createDefault(type)
      }
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return TalkChannelMessageHelper.PremiumVideo.createDefault()
      }
      default: {
        return this.initialForm
      }
    }
  },

  getThumbnailUrl(messageForm) {
    const { creativeFormat } = messageForm

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        const { uploadThumbnail, thumbnail, video } = messageForm
        const { url: uploadUrl } = uploadThumbnail || {}
        const { url } = thumbnail || {}
        const { valueWithVariable } = video || {}

        return (
          uploadUrl || url || (valueWithVariable && IS_VARIABLE_IMAGE) || null
        )
      }

      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE: {
        const { image, thumbnail, uploadThumbnail, video } = messageForm
        if (checkNotEmpty(image)) {
          const { url, valueWithVariable } = image
          return url || (valueWithVariable && IS_VARIABLE_IMAGE) || null
        } else {
          const { valueWithVariable } = video || {}
          return (
            uploadThumbnail?.get('url') ||
            thumbnail?.get('url') ||
            (valueWithVariable && IS_VARIABLE_IMAGE) ||
            null
          )
        }
      }

      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE: {
        const { itemAssetGroups } = messageForm
        const itemAsset = itemAssetGroups.first()
        const { image, thumbnail, uploadThumbnail, video } = itemAsset

        if (checkNotEmpty(image)) {
          const { url, valueWithVariable } = image
          return url || (valueWithVariable && IS_VARIABLE_IMAGE) || null
        } else {
          const { valueWithVariable } = video || {}
          return (
            uploadThumbnail?.get('url') ||
            thumbnail?.get('url') ||
            (valueWithVariable && IS_VARIABLE_IMAGE) ||
            null
          )
        }
      }

      // 기존에 캐러셀커머스와 캐러셀피드형이 같은 case 에 묶여 hasIntro로 분기 처리 되어있었으나 해당 부분 초기화 부분이 없어 KAMOQA-24761 이슈 발생 서로 썸네일을 가져오는 위치가 달라서 이부분 분리
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE: {
        const { image, hasIntro, itemAssetGroups } = messageForm
        const imageInfo = hasIntro
          ? image
          : itemAssetGroups.first()?.get('image')

        if (checkNotEmpty(imageInfo)) {
          const { url, valueWithVariable } = imageInfo
          return url || (valueWithVariable && IS_VARIABLE_IMAGE) || null
        }
        return null
      }

      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        const { itemAssetGroups } = messageForm
        const imageInfo = itemAssetGroups.first()?.get('image')

        if (checkNotEmpty(imageInfo)) {
          const { url, valueWithVariable } = imageInfo
          return url || (valueWithVariable && IS_VARIABLE_IMAGE) || null
        }
        return null
      }

      default:
        return null
    }
  },

  toTrimForm(messageForm) {
    const { creativeFormat, hasIntro = true } = messageForm

    return Trim(messageForm, MESSAGE_TRIM_KEY_PATH)
      .set(
        'messageVideoTitle',
        messageForm.get('messageVideoTitle')?.trim() || null
      )
      .set('title', hasIntro ? messageForm.get('title')?.trim() : null) // 캐러샐인 경우에 intro가 false인 경우에는 title이 없을수도 있음
      .set('description', messageForm.get('description')?.trim() || null)
      .set(
        'mobileLandingUrl',
        messageForm.get('mobileLandingUrl')?.trim() || null
      )
      .set('pcLandingUrl', messageForm.get('pcLandingUrl')?.trim() || null)
      .set(
        'webembedChannelUrl',
        messageForm.get('webembedChannelUrl')?.trim() || null
      )

      .set(
        'buttonAssetGroups',
        messageForm.get('buttonAssetGroups').map(button => {
          return button
            .set('title', button.get('title').trim())
            .set('mobileLandingUrl', button.get('mobileLandingUrl')?.trim())
            .set('pcLandingUrl', button.get('pcLandingUrl')?.trim())
            .set('webembedChannelUrl', button.get('webembedChannelUrl')?.trim())
        })
      )
      .set(
        'couponBookAssetGroups',
        messageForm
          .get('couponBookAssetGroups')
          .map(couponBookAssetGroup =>
            couponBookAssetGroup
              .set(
                'mobileLandingUrl',
                couponBookAssetGroup.get('mobileLandingUrl')?.trim()
              )
              .set(
                'pcLandingUrl',
                couponBookAssetGroup.get('pcLandingUrl')?.trim()
              )
              .set(
                'webembedChannelUrl',
                couponBookAssetGroup.get('webembedChannelUrl')?.trim()
              )
          )
      )
      .set(
        'itemAssetGroups',
        messageForm.get('itemAssetGroups').map(item => {
          // 와이드 메시지의 경우에 itemAsset의 title이 필수가 아님.
          // 수정시에 와이드 메시지 itemAsset의 title이 없이 내려오고 이를 set하고 있어서 이경우에는 그냥 return
          if (creativeFormat === CreativeFormatEnum.Type.WIDE_MESSAGE) {
            return item
              .set(
                'messageVideoTitle',
                item.get('messageVideoTitle')?.trim() || null
              )
              .set(
                'mobileLandingUrl',
                item.get('mobileLandingUrl')?.trim() || null
              )
              .set('pcLandingUrl', item.get('pcLandingUrl')?.trim() || null)
              .set(
                'webembedChannelUrl',
                item.get('webembedChannelUrl')?.trim() || null
              )
          }

          return item
            .set(
              'messageVideoTitle',
              item.get('messageVideoTitle')?.trim() || null
            )
            .set('title', item.get('title')?.trim() || null)
            .set('description', item.get('description')?.trim() || null)
            .set(
              'mobileLandingUrl',
              item.get('mobileLandingUrl')?.trim() || null
            )
            .set('pcLandingUrl', item.get('pcLandingUrl')?.trim() || null)
            .set(
              'webembedChannelUrl',
              item.get('webembedChannelUrl')?.trim() || null
            )
        })
      )
      .set(
        'introMobileLandingUrl',
        messageForm.get('introMobileLandingUrl')?.trim() || null
      )
      .set(
        'introPcLandingUrl',
        messageForm.get('introPcLandingUrl')?.trim() || null
      )
      .set(
        'introWebembedChannelUrl',
        messageForm.get('introWebembedChannelUrl')?.trim() || null
      )
  },

  toAPI(messageForm) {
    const {
      creativeFormat,
      buttonAssetGroups,
      itemAssetGroups,
      couponBookAssetGroups,
    } = messageForm

    // 캐러셀 커머스형이 아닌 경우, 불필요한 정보 제거
    const newMessageForm1 =
      creativeFormat !== CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE
        ? messageForm
            .merge(this.initialCarouselIntro)
            .set('image', messageForm.get('image'))
            .set('title', messageForm.get('title'))
            .set('description', messageForm.get('description'))
            .delete('hasIntro')
        : messageForm

    const newMessageForm2 = newMessageForm1
      .filter(v => v !== -1)
      .filter(v => v !== '')
      .filter(v => v !== TalkChannelMessageEnum.Landing.Type.NONE)

    // 미설정 버튼 제거
    const removedUnSetButtonAssets =
      buttonAssetGroups?.filter(
        button =>
          button.get('landingType') !== TalkChannelMessageEnum.Landing.Type.NONE
      ) || List()

    // ButtonAsset에서 불필요한 정보들 제거
    const filteredButtonAsset = removedUnSetButtonAssets.map(t => {
      return t.filter(v => v !== -1).filter(v => v !== '')
    })

    // itemAssetGroup에서 불필요한 정보 제거
    const filteredItemAssetGroups =
      itemAssetGroups
        ?.filter(itemAsset => {
          const { image, video, ordering, title } = itemAsset
          return !(
            ordering === 3 &&
            checkEmpty(image) &&
            checkEmpty(video) &&
            checkEmpty(title)
          )
        })
        .map(item => {
          return item
            .filter(v => v !== -1)
            .filter(v => v !== '')
            .delete('assetGroupUUID')
        }) || List()

    // couponBookAssetsGroup에서 불필요한 정보들 제거
    const filteredCouponBookAssetGroups = couponBookAssetGroups
      .filter(couponBookAsset => couponBookAsset.get('couponBookType') !== null)
      .map(couponBookAsset => {
        if (
          couponBookAsset.get('couponBookType') ===
          CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK
        ) {
          return couponBookAsset
            .filter(v => v !== '')
            .filter(v => v !== null)
            .delete('couponBookType')
            .delete('channelCoupons')
        }
        return couponBookAsset
          .filter(v => v !== '')
          .filter(v => v !== null)
          .delete('couponBookType')
      })
    return {
      buttonAssetGroups: filteredButtonAsset.toJS(),
      itemAssetGroups: filteredItemAssetGroups.toJS(),
      couponBookAssetGroups: filteredCouponBookAssetGroups.toJS(),
      ...newMessageForm2
        .withMutations(s => {
          return s
            .delete('profileName')
            .delete('profileImageUrl')
            .delete('csInfo')
            .delete('buttonAssetGroups')
            .delete('itemAssetGroups')
            .delete('couponBookAssetGroups')
            .delete('encodedId')
            .delete('isBizChannel')
            .delete('profileHomeInfo')
        })
        .toJS(),
    }
  },

  creativeImportToCompleted({ messageForm }) {
    const { name, creativeFormat } = messageForm
    const messageElement = this.convertMessageFormToMessageElement({
      messageForm,
    })
      .set('isCompleted', true)
      .set('id', -1)

    return fromJS({
      name: String(name).substr(
        0,
        CreativeConstraints.getNameMaxLength({
          creativeFormat,
        })
      ),
      formUUID: uuid(),
      frequencyCap: fromJS({
        count: null,
        type: 'AUTO',
      }),
      isSupportedImageSize: true,
      messageElement,
      creativeFormat,
      saveMessageTemplate: false,
    })
  },

  // 소재 설정 완료 리스트 -> 소재 form
  creativeCompletedToForm({ messageForm }) {
    const { creativeFormat } = messageForm

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE: {
        return messageForm
      }
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return messageForm.withMutations(m =>
          m.set(
            'itemAssetGroups',
            m
              .get('itemAssetGroups')
              .map(itemAsset => itemAsset.set('assetGroupUUID', uuid()))
          )
        )
      }

      default: {
        return TalkChannelMessageHelper.initialForm
      }
    }
  },

  /**
   * messageForm 을 소재 messageElement 형식에 맞게 변경
   * 캐러샐의 경우에 할인율 정보 초기화
   */
  convertMessageFormToMessageElement({ messageForm }) {
    const { creativeFormat } = messageForm

    switch (creativeFormat) {
      case CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_LIST_MESSAGE:
      case CreativeFormatEnum.Type.WIDE_MESSAGE:
      case CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE:
        return messageForm
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE:
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return TalkChannelMessageHelper.Carousel.removeDiscountedPercentage(
          messageForm
        )
      }
      default:
        return TalkChannelMessageHelper.initialForm
    }
  },

  /**
   * KAMOQA-19448 수정 진입 시, messageVideoTitle 이 empty 인 경우 videoType 을 `PREUPLOAD` 로 강제 변환.
   */
  transformMessageVideoTypeForModify({ messageForm }) {
    const { creativeFormat } = messageForm

    let nextMessageForm = messageForm

    const transformer = ({ videoType, messageVideoTitle }) => {
      if (VideoEnum.isTitleRequired(videoType)) {
        if (checkEmpty(messageVideoTitle)) {
          return VideoEnum.Type.PREUPLOAD
        }
      }

      return videoType
    }

    if (
      creativeFormat === CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE ||
      creativeFormat === CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE
    ) {
      const { video, messageVideoTitle } = nextMessageForm

      if (video) {
        nextMessageForm = nextMessageForm.update('video', prevVideo =>
          prevVideo.update('videoType', prevVideoType =>
            transformer({
              videoType: prevVideoType,
              messageVideoTitle,
            })
          )
        )
      }
    }

    if (
      creativeFormat === CreativeFormatEnum.Type.WIDE_MESSAGE ||
      creativeFormat === CreativeFormatEnum.Type.WIDE_LIST_MESSAGE
    ) {
      nextMessageForm = nextMessageForm.update(
        'itemAssetGroups',
        prevItemAssetGroups =>
          prevItemAssetGroups.map(itemAssetGroup => {
            const { video, messageVideoTitle } = itemAssetGroup

            if (video) {
              return itemAssetGroup.update('video', prevVideo =>
                prevVideo.update('videoType', prevVideoType =>
                  transformer({
                    videoType: prevVideoType,
                    messageVideoTitle,
                  })
                )
              )
            }

            return itemAssetGroup
          })
      )
    }

    return nextMessageForm
  },

  /**
   * FIXME: 이 동작을 뭐라도 정의할 수 있나?
   */
  defaultInitialize({ messageForm, campaignType }) {
    const { creativeFormat } = messageForm

    let nextMessageForm =
      TalkChannelMessageHelper.getDefaultMessage(creativeFormat).mergeDeep(
        messageForm
      )

    if (
      creativeFormat === CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE ||
      creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE
    ) {
      nextMessageForm =
        TalkChannelMessageHelper.Carousel.removeDiscountedPercentage(
          nextMessageForm
        )
      const { itemAssetGroups } = nextMessageForm

      nextMessageForm = nextMessageForm.withMutations(v =>
        v
          .set(
            'itemAssetGroups',
            itemAssetGroups.map(itemAsset =>
              itemAsset.set('assetGroupUUID', uuid())
            )
          )
          .update('buttonAssetGroups', buttonAssetGroups =>
            creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE ||
            campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
              ? TalkChannelMessageHelper.Carousel.initializeCarouselFeedButtonAssetGroups(
                  buttonAssetGroups,
                  itemAssetGroups.count() * 2
                )
              : buttonAssetGroups
          )
          .update('couponBookAssetGroups', couponBookAssetGroups =>
            creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE
              ? TalkChannelMessageHelper.initializeCarouselFeedCouponBookAssetGroups(
                  couponBookAssetGroups,
                  itemAssetGroups.count()
                )
              : couponBookAssetGroups
          )
      )
    } else if (
      [
        CreativeFormatEnum.Type.WIDE_MESSAGE,
        CreativeFormatEnum.Type.WIDE_LIST_MESSAGE,
      ].includes(creativeFormat)
    ) {
      nextMessageForm = nextMessageForm.withMutations(v =>
        v
          .update('buttonAssetGroups', buttonAssetGroups => {
            return campaignType === CampaignTypeEnum.Type.PERSONAL_MESSAGE
              ? List([
                  buttonAssetGroups?.find(item => item.get('ordering') === 0) ||
                    TalkChannelMessageHelper.initialButtonAsset.withMutations(
                      v => v.set('ordering', 0)
                    ),
                  buttonAssetGroups?.find(item => item.get('ordering') === 1) ||
                    TalkChannelMessageHelper.initialButtonAsset.withMutations(
                      v => v.set('ordering', 1)
                    ),
                ])
              : buttonAssetGroups
          })
          .update('couponBookAssetGroups', couponBookAssetGroups =>
            TalkChannelMessageHelper.initializeCouponBookAssetGroups(
              couponBookAssetGroups
            )
          )
      )
    } else {
      nextMessageForm = nextMessageForm.withMutations(v =>
        v.update('couponBookAssetGroups', couponBookAssetGroups =>
          TalkChannelMessageHelper.initializeCouponBookAssetGroups(
            couponBookAssetGroups
          )
        )
      )
    }

    return nextMessageForm
  },

  initializeCouponBookAssetGroups(couponBookAssetGroups) {
    let targetCouponBook = couponBookAssetGroups.find(
      item => item.get('ordering') === 0
    )
    if (targetCouponBook) {
      if (targetCouponBook.get('channelCoupons')?.size) {
        targetCouponBook = targetCouponBook.set(
          'couponBookType',
          CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
        )
      } else if (targetCouponBook.get('mobileLandingUrl')) {
        targetCouponBook = targetCouponBook.set(
          'couponBookType',
          CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK
        )
      }
      return couponBookAssetGroups.set(0, targetCouponBook)
    }
    return couponBookAssetGroups.set(
      0,
      TalkChannelMessageHelper.initialCouponBookAsset
    )
  },

  initializeCouponBookAssetGroupsV2(couponBookAssetGroups) {
    const targetCouponBook = couponBookAssetGroups.find(
      item => item.get('ordering') === 0
    )
    if (targetCouponBook) {
      if (targetCouponBook.get('channelCoupons')?.size) {
        return List([CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK])
      } else if (targetCouponBook.get('mobileLandingUrl')) {
        return List([CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK])
      }
    }
    return List([null])
  },

  initializeCarouselFeedCouponBookAssetGroups(couponBookAssetGroups, count) {
    return Range(0, count)
      .map(index => {
        let targetCouponBook = couponBookAssetGroups.find(
          item => item.get('ordering') === index
        )
        if (targetCouponBook) {
          if (targetCouponBook.get('channelCoupons')?.size) {
            targetCouponBook = targetCouponBook.set(
              'couponBookType',
              CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
            )
          } else if (targetCouponBook.get('mobileLandingUrl')) {
            targetCouponBook = targetCouponBook.set(
              'couponBookType',
              CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK
            )
          }
        }
        return checkNotEmpty(targetCouponBook)
          ? targetCouponBook
          : TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', index)
            )
      })
      .toList()
  },

  initializeCarouselFeedCouponBookAssetGroupsV2(couponBookAssetGroups, count) {
    return Range(0, count)
      .map(index => {
        const targetCouponBook = couponBookAssetGroups.find(
          item => item.get('ordering') === index
        )
        if (targetCouponBook) {
          if (targetCouponBook.get('channelCoupons')?.size) {
            return CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
          } else if (targetCouponBook.get('mobileLandingUrl')) {
            return CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK
          }
        }
        return null
      })
      .toList()
  },
}

TalkChannelMessageHelper.PremiumVideo = {
  createDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.PREMIUM_VIDEO_MESSAGE)
        .set('adFlag', true)
        .set('shareFlag', false)
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
    )
  },

  Button: {
    set(buttonAssetGroups, idx, key, value) {
      if (key === 'landingType') {
        const newButtonAsset =
          TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
            v.set(key, value).set('ordering', idx)
          )
        return buttonAssetGroups.set(idx, newButtonAsset)
      } else {
        return buttonAssetGroups.setIn([idx, key], value)
      }
    },
  },

  Asset: {
    set(messageForm, value) {
      return messageForm.merge(value)
    },
  },
}

TalkChannelMessageHelper.Basic = {
  createDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE)
        .set('adFlag', true)
        .set('shareFlag', false)
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 1)
            ),
          ]) // List를 조금더 fancy하게 만들수 있는 방법에 대해서 생각해보기
        ) // 빈 List로 두지 않고 처음에 초기화할때에 두개를 동시에 넣는 이유? -> 버튼 1이 없는데 버튼 2만 선택이 가능하다
        .set(
          'couponBookAssetGroups',
          List([
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
    )
  },
  Button: {
    set(buttonAssetGroups, idx, key, value) {
      const buttonIndex = buttonAssetGroups.findIndex(
        b => b.get('ordering') === idx
      )
      if (key === 'landingType') {
        const newButtonAsset =
          TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
            v.set(key, value).set('ordering', idx)
          ) // 랜딩타입변경시에는 초기화된 버튼으로 설정
        return buttonIndex > -1 // 기본텍스트형의 경우에는 버튼이 2개가 설정가능하다, 코어응답에서는 미설정 버튼에 대한 정보가 내려오지 않음. 그래서 버튼이 없는 경우에
          ? buttonAssetGroups.set(buttonIndex, newButtonAsset)
          : List([
              TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
                v.set('ordering', 0)
              ),
              TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
                v.set('ordering', 1)
              ),
            ]).map(button => {
              const { ordering: buttonOrdering } = button
              const originalButton = buttonAssetGroups.find(
                b => b.get('ordering') === buttonOrdering
              )
              return checkNotEmpty(originalButton) // 기존 버튼은 유지
                ? originalButton
                : buttonOrdering !== idx
                ? button
                : newButtonAsset
            })
      } else {
        return buttonAssetGroups.setIn([buttonIndex, key], value)
      }
    },
  },
  Asset: {
    set(messageForm, key, value) {
      if (key === 'image') {
        return messageForm.withMutations(v => v.set(key, value))
      } else if (key === 'video') {
        return messageForm.merge(value)
      } else {
        return messageForm
      }
    },
  },
  CouponBookAsset: {
    set(couponBookAssetGroups, idx, key, value) {
      if (key === 'couponBookType') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (value !== prevCouponBookType) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set(key, value).set('ordering', idx)
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
      }
      if (key === 'couponBookTitleType') {
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set('couponBookTitle', '')
          .set('title', '')
          .set(key, value)
        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      if (key === 'channelCoupons') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (prevCouponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v
                .set(key, value)
                .set('ordering', idx)
                .set(
                  'couponBookType',
                  CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
                )
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set(key, value)
          .set('mobileLandingUrl', '')
          .set('pcLandingUrl', '')
          .set('couponBookType', CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK)

        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      return couponBookAssetGroups.setIn([idx, key], value)
    },
  },
}

TalkChannelMessageHelper.Wide = {
  createDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.WIDE_MESSAGE)
        .set(
          'itemAssetGroups',
          List([
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set(
          'couponBookAssetGroups',
          List([
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set('adFlag', true)
    )
  },

  personalCreateDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.WIDE_MESSAGE)
        .set(
          'itemAssetGroups',
          List([
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 1)
            ),
          ])
        )
        .set(
          'couponBookAssetGroups',
          List([
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set('adFlag', true)
    )
  },

  Button: {
    set(buttonAssetGroups, idx, key, value) {
      if (key === 'landingType') {
        const newButtonAsset =
          TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
            v.set(key, value).set('ordering', idx)
          )
        return buttonAssetGroups.set(idx, newButtonAsset)
      } else {
        return buttonAssetGroups.setIn([idx, key], value)
      }
    },
  },

  Asset: {
    set(itemAssetGroups, idx, key, value) {
      const originalItemAsset = itemAssetGroups.get(idx)
      if (key === 'image') {
        if (checkEmpty(value)) {
          return itemAssetGroups.set(
            idx,
            TalkChannelMessageHelper.initialAsset.set(
              'title',
              originalItemAsset.get('title')
            )
          )
        }
        const newAsset = originalItemAsset.withMutations(v => v.set(key, value))
        return itemAssetGroups.set(idx, newAsset)
      } else if (key === 'video') {
        const { video } = value
        // 비디오 삭제한 경우
        if (video === null) {
          return itemAssetGroups.set(
            idx,
            TalkChannelMessageHelper.initialAsset.set(
              'title',
              originalItemAsset.get('title')
            )
          )
        }
        const newAsset = originalItemAsset.merge(value)

        return itemAssetGroups.set(idx, newAsset)
      } else if (key === 'landingType') {
        const newAsset = TalkChannelMessageHelper.initialAsset.withMutations(
          v =>
            v
              .set(key, value)
              .set('ordering', idx)
              .set('image', originalItemAsset.get('image'))
              .set('video', originalItemAsset.get('video'))
              .set('thumbnail', originalItemAsset.get('thumbnail'))
        )
        return itemAssetGroups.set(idx, newAsset)
      } else {
        return itemAssetGroups.setIn([idx, key], value)
      }
    },
  },
  CouponBookAsset: {
    set(couponBookAssetGroups, idx, key, value) {
      if (key === 'couponBookType') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (value !== prevCouponBookType) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set(key, value).set('ordering', idx)
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
      }
      if (key === 'couponBookTitleType') {
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set('couponBookTitle', '')
          .set('title', '')
          .set(key, value)
        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      if (key === 'channelCoupons') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (prevCouponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v
                .set(key, value)
                .set('ordering', idx)
                .set(
                  'couponBookType',
                  CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
                )
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set(key, value)
          .set('mobileLandingUrl', '')
          .set('pcLandingUrl', '')
          .set('couponBookType', CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK)

        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      return couponBookAssetGroups.setIn([idx, key], value)
    },
  },
}

TalkChannelMessageHelper.WideList = {
  createDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.WIDE_LIST_MESSAGE)
        .set(
          'itemAssetGroups',
          List([
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 1)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 2)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 3)
            ),
          ])
        )
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set(
          'couponBookAssetGroups',
          List([
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set('adFlag', true)
        /**
         * initialForm 내 기본으로 들어있는 항목 중 와이드리스트에서 불필요한 항목 제거함
         */
        .delete('uploadThumbnail')
    )
  },

  personalCreateDefault() {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', CreativeFormatEnum.Type.WIDE_LIST_MESSAGE)
        .set(
          'itemAssetGroups',
          List([
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 1)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 2)
            ),
            TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', 3)
            ),
          ])
        )
        .set(
          'buttonAssetGroups',
          List([
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
            TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', 1)
            ),
          ])
        )
        .set(
          'couponBookAssetGroups',
          List([
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set('ordering', 0)
            ),
          ])
        )
        .set('adFlag', true)
        /**
         * initialForm 내 기본으로 들어있는 항목 중 와이드리스트에서 불필요한 항목 제거함
         */
        .delete('uploadThumbnail')
    )
  },
  Button: {
    set(buttonAssetGroups, idx, key, value) {
      if (key === 'landingType') {
        const newButtonAsset =
          TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
            v.set(key, value).set('ordering', idx)
          )
        return buttonAssetGroups.set(idx, newButtonAsset)
      } else {
        return buttonAssetGroups.setIn([idx, key], value)
      }
    },
  },
  Asset: {
    set(itemAssetGroups, idx, key, value) {
      const originalItemAsset = itemAssetGroups.get(idx)
      if (key === 'image') {
        if (checkEmpty(value)) {
          return itemAssetGroups.set(
            idx,
            TalkChannelMessageHelper.initialAsset
              .set('title', originalItemAsset.get('title'))
              .set('ordering', originalItemAsset.get('ordering'))
          )
        }
        const newAsset = originalItemAsset.withMutations(v => v.set(key, value))
        return itemAssetGroups.set(idx, newAsset)
      } else if (key === 'video') {
        const { video } = value
        // 비디오 삭제한 경우
        if (video === null) {
          return itemAssetGroups.set(
            idx,
            TalkChannelMessageHelper.initialAsset
              .set('title', originalItemAsset.get('title'))
              .set('ordering', originalItemAsset.get('ordering'))
          )
        }
        const newAsset = originalItemAsset.merge(value)
        return itemAssetGroups.set(idx, newAsset)
      } else if (key === 'landingType') {
        const newAsset = TalkChannelMessageHelper.initialAsset.withMutations(
          v =>
            v
              .set(key, value)
              .set('ordering', idx)
              .set('image', originalItemAsset.get('image'))
              .set('video', originalItemAsset.get('video'))
              .set('title', originalItemAsset.get('title'))
              .set('thumbnail', originalItemAsset.get('thumbnail'))
        )
        return itemAssetGroups.set(idx, newAsset)
      } else {
        return itemAssetGroups.setIn([idx, key], value)
      }
    },
  },
  CouponBookAsset: {
    set(couponBookAssetGroups, idx, key, value) {
      if (key === 'couponBookType') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (value !== prevCouponBookType) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set(key, value).set('ordering', idx)
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
      }
      if (key === 'couponBookTitleType') {
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set('couponBookTitle', '')
          .set('title', '')
          .set(key, value)
        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      if (key === 'channelCoupons') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (prevCouponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v
                .set(key, value)
                .set('ordering', idx)
                .set(
                  'couponBookType',
                  CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
                )
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set(key, value)
          .set('mobileLandingUrl', '')
          .set('pcLandingUrl', '')
          .set('couponBookType', CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK)

        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      return couponBookAssetGroups.setIn([idx, key], value)
    },
  },
}

TalkChannelMessageHelper.Carousel = {
  createDefault(creativeFormat) {
    return TalkChannelMessageHelper.initialForm.withMutations(v =>
      v
        .set('creativeFormat', creativeFormat)
        .merge(
          creativeFormat === CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE
            ? TalkChannelMessageHelper.initialCarouselIntro
            : Map()
        )
        .merge(TalkChannelMessageHelper.initialAsset)
        .set('landingType', TalkChannelMessageEnum.Landing.Type.NONE)
        /**
         * initialForm 내 기본으로 들어있는 항목 중 와이드리스트에서 불필요한 항목 제거함
         */
        .delete('uploadThumbnail')
    )
  },

  // Intro Image
  IntroAsset: {
    set(messageForm, key, value) {
      if (key === 'image') {
        return messageForm.withMutations(v => v.set(key, value))
      }

      return messageForm
    },
  },

  Button: {
    set({
      buttonAssetGroups,
      idx,
      key,
      value,
      isConnectedToProduct,
      creativeFormat,
      isPersonalMessage = false,
    }) {
      const originalButtonAsset = buttonAssetGroups.get(idx)

      if (key === 'landingType') {
        switch (value) {
          case TalkChannelMessageEnum.Landing.Type.LANDING_URL: {
            return buttonAssetGroups.set(
              idx,
              originalButtonAsset
                .merge(
                  TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('assetGroupId')
                      .set(
                        'title',
                        creativeFormat ===
                          CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE ||
                          (isPersonalMessage && idx % 2)
                          ? ''
                          : '구매하기'
                      )
                  )
                )
                .set('landingType', value)
                .set(
                  'mobileLandingUrl',
                  isConnectedToProduct
                    ? originalButtonAsset.get('webembedChannelUrl')
                    : ''
                )
            )
          }
          case TalkChannelMessageEnum.Landing.Type.WEBEMBED_CHANNEL_URL: {
            return buttonAssetGroups.set(
              idx,
              originalButtonAsset
                .merge(
                  TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('assetGroupId')
                      .set(
                        'title',
                        creativeFormat ===
                          CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE ||
                          (isPersonalMessage && idx % 2)
                          ? ''
                          : '구매하기'
                      )
                  )
                )
                .set('landingType', value)
                .set(
                  'webembedChannelUrl',
                  isConnectedToProduct
                    ? originalButtonAsset.get('mobileLandingUrl')
                    : ''
                )
            )
          }
          default: {
            return buttonAssetGroups.set(
              idx,
              originalButtonAsset
                .merge(
                  TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('assetGroupId')
                      .set(
                        'title',
                        creativeFormat ===
                          CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE ||
                          (isPersonalMessage && idx % 2 === 1)
                          ? ''
                          : '구매하기'
                      )
                  )
                )
                .set('landingType', value)
            )
          }
        }
      } else {
        return buttonAssetGroups.set(
          idx,
          originalButtonAsset.set(key, fromJS(value))
        )
      }
    },
  },

  CouponBookAsset: {
    set(couponBookAssetGroups, idx, key, value) {
      if (key === 'couponBookType') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (value !== prevCouponBookType) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v.set(key, value).set('ordering', idx)
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
      }
      if (key === 'couponBookTitleType') {
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set('couponBookTitle', '')
          .set('title', '')
          .set(key, value)
        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      if (key === 'channelCoupons') {
        const prevCouponBookType = couponBookAssetGroups
          .get(idx)
          .get('couponBookType')
        if (prevCouponBookType === CouponBookTypeEnum.Type.CUSTOM_COUPON_BOOK) {
          const newCouponBookAsset =
            TalkChannelMessageHelper.initialCouponBookAsset.withMutations(v =>
              v
                .set(key, value)
                .set('ordering', idx)
                .set(
                  'couponBookType',
                  CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK
                )
            )
          return couponBookAssetGroups.set(idx, newCouponBookAsset)
        }
        const newCouponBookAsset = couponBookAssetGroups
          .get(idx)
          .set(key, value)
          .set('mobileLandingUrl', '')
          .set('pcLandingUrl', '')
          .set('couponBookType', CouponBookTypeEnum.Type.CHANNEL_COUPON_BOOK)

        return couponBookAssetGroups.set(idx, newCouponBookAsset)
      }
      return couponBookAssetGroups.setIn([idx, key], value)
    },
  },

  More: {
    set({ messageForm, key, value, ...rest }) {
      switch (key) {
        case 'landingType':
          if (
            value === TalkChannelMessageEnum.Landing.Type.TALK_STORE_LANDING
          ) {
            const {
              talkStoreInfo: { buttonLink },
            } = rest
            return messageForm.merge(
              TalkChannelMessageHelper.initialAssetByCarouselMore
                .set('landingType', value)
                .set('talkStoreLandingUrl', buttonLink)
            )
          } else {
            return messageForm.merge(
              TalkChannelMessageHelper.initialAssetByCarouselMore.set(
                'landingType',
                value
              )
            )
          }

        default:
          return messageForm.set(key, value)
      }
    },
  },

  ItemAsset: {
    set(itemAssetGroups, idx, key, value, isConnectedToProduct) {
      const originalItemAsset = itemAssetGroups.get(idx)
      if (key === 'landingType') {
        switch (value) {
          case TalkChannelMessageEnum.Landing.Type.LANDING_URL: {
            return itemAssetGroups.set(
              idx,
              originalItemAsset
                .merge(
                  TalkChannelMessageHelper.initialAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('title')
                      .delete('image')
                      .delete('video')
                      .delete('thumbnail')
                      .delete('uploadThumbnail')
                  )
                ) // 랜딩 타입 변경 시 초기화
                .set('landingType', value)
                .set(
                  'mobileLandingUrl',
                  isConnectedToProduct
                    ? originalItemAsset.get('webembedChannelUrl')
                    : ''
                )
            )
          }

          case TalkChannelMessageEnum.Landing.Type.WEBEMBED_CHANNEL_URL: {
            return itemAssetGroups.set(
              idx,
              originalItemAsset
                .merge(
                  TalkChannelMessageHelper.initialAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('title')
                      .delete('image')
                      .delete('video')
                      .delete('thumbnail')
                      .delete('uploadThumbnail')
                  )
                ) // 랜딩 타입 변경 시 초기화
                .set('landingType', value)
                .set(
                  'webembedChannelUrl',
                  isConnectedToProduct
                    ? originalItemAsset.get('mobileLandingUrl')
                    : ''
                )
            )
          }

          default:
            return itemAssetGroups.set(
              idx,
              originalItemAsset
                .merge(
                  TalkChannelMessageHelper.initialAsset.withMutations(v =>
                    v
                      .delete('ordering')
                      .delete('title')
                      .delete('image')
                      .delete('video')
                      .delete('thumbnail')
                      .delete('uploadThumbnail')
                  )
                ) // 랜딩 타입 변경 시 초기화
                .set('landingType', value)
            )
        }
      } else {
        return itemAssetGroups.set(
          idx,
          originalItemAsset.set(key, fromJS(value))
        )
      }
    },
  },

  IntroLandingType: {
    set({ messageForm, key, value, ...rest }) {
      const { title, description, image } = messageForm
      switch (key) {
        case 'landingType':
          if (
            value === TalkChannelMessageEnum.Landing.Type.TALK_STORE_LANDING
          ) {
            const {
              talkStoreInfo: { buttonLink },
            } = rest
            return messageForm.merge(
              TalkChannelMessageHelper.initialCarouselIntro
                .set('title', title)
                .set('description', description)
                .set('image', image)
                .set('introLandingType', value)
                .set('introTalkStoreLandingUrl', buttonLink)
            )
          } else {
            return messageForm.merge(
              TalkChannelMessageHelper.initialCarouselIntro
                .set('title', title)
                .set('description', description)
                .set('image', image)
                .set('introLandingType', value)
            )
          }

        default:
          return messageForm.set(key, value)
      }
    },
  },

  creativeLibraryToItemAsset(creativeFormat, item) {
    switch (creativeFormat) {
      case CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE: {
        return this.creativeLibraryToCommerceItemAsset(item)
      }
      case CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE: {
        return this.creativeLibraryToFeedItemAsset(item)
      }
      default: {
        return TalkChannelMessageHelper.initialAsset
      }
    }
  },

  creativeLibraryToCommerceItemAsset(item) {
    const {
      fileSize,
      imageHash,
      imageHeight,
      imageWidth,
      mimeType,
      originalFileName,
      downloadUrl: url,
      valueWithVariable,
    } = item

    return TalkChannelMessageHelper.initialAsset.withMutations(v =>
      v
        .set(
          'image',
          Map({
            fileSize,
            imageHash,
            imageHeight,
            imageWidth,
            mimeType,
            originalFileName,
            url,
            valueWithVariable,
          })
        )
        .set('title', '')
        .set('price', Map({ amount: null, currencyCode: 'KRW' }))
        .set(
          'discountedPrice',
          Map({
            amount: null,
          })
        )
    )
  },

  creativeLibraryToFeedItemAsset(item) {
    const {
      fileSize,
      imageHash,
      imageHeight,
      imageWidth,
      mimeType,
      originalFileName,
      downloadUrl: url,
      valueWithVariable,
    } = item

    return TalkChannelMessageHelper.initialAsset.withMutations(v =>
      v
        .set(
          'image',
          Map({
            fileSize,
            imageHash,
            imageHeight,
            imageWidth,
            mimeType,
            originalFileName,
            url,
            valueWithVariable,
          })
        )
        .set('title', '')
        .set('description', '')
        .set('landingType', TalkChannelMessageEnum.Landing.Type.LANDING_URL)
    )
  },

  creativeLibraryToCommerceButtonAsset(item) {
    return TalkChannelMessageHelper.initialButtonAsset.withMutations(button =>
      button
        .set('landingType', TalkChannelMessageEnum.Landing.Type.LANDING_URL)
        .set('title', `구매하기`)
    )
  },

  creativeLibraryToPersonalCommerceButtonAsset() {
    return List([
      TalkChannelMessageHelper.initialButtonAsset.withMutations(button =>
        button
          .set('landingType', TalkChannelMessageEnum.Landing.Type.LANDING_URL)
          .set('title', `구매하기`)
      ),
      TalkChannelMessageHelper.initialButtonAsset,
    ])
  },

  creativeLibraryToFeedButtonAsset() {
    return List([
      TalkChannelMessageHelper.initialButtonAsset.withMutations(button =>
        button.set(
          'landingType',
          TalkChannelMessageEnum.Landing.Type.LANDING_URL
        )
      ),
      TalkChannelMessageHelper.initialButtonAsset,
    ])
  },

  creativeLibraryToFeedCouponBookAsset() {
    return List([TalkChannelMessageHelper.initialCouponBookAsset])
  },

  talkStoreItemToAsset(item, shopKey) {
    const {
      id,
      name,
      salePrice,
      discountedPrice,
      discountedPercentage,
      originalImageUrl,
      displayUrl,
      displayMessage,
    } = item
    const isDiscounting = Number(discountedPercentage) !== 0 // todo discountedPercentage말고 다른 값을 사용하도록 변경

    return TalkChannelMessageHelper.initialAsset.withMutations(asset =>
      asset
        .set('image', Map({ url: originalImageUrl }))
        .set('price', Map({ amount: salePrice, currencyCode: 'KRW' }))
        .set(
          'discountedPrice',
          Map({
            amount: isDiscounting ? discountedPrice : null,
            displayMessage,
          })
        )
        .set('mobileLandingUrl', String(displayUrl).substr(0, 1000))
        .set('landingType', TalkChannelMessageEnum.Landing.Type.LANDING_URL)
        .set(
          'store',
          Map({
            storeType: KakaoStoreEnum.Type.TALK_STORE,
            shopKey,
            productId: id,
            productName: name,
          })
        )
        .update('title', prev => String(name).substr(0, 25) || prev)
    )
  },

  talkStoreItemToButtonAsset(item) {
    const { displayUrl } = item

    return TalkChannelMessageHelper.initialButtonAsset.withMutations(button =>
      button
        .set('isHighlighted', true)
        .set('landingType', TalkChannelMessageEnum.Landing.Type.LANDING_URL)
        .set('mobileLandingUrl', String(displayUrl).substr(0, 1000))
        .set('title', '구매하기')
    )
  },

  /**
   * 08/26 배포 이후 할인가격 입력시 할인율 정보는 자동입력지원
   * 기 입력된 할인율 정보를 모두 제거
   * @param carouselMessage
   * @returns {*}
   */
  removeDiscountedPercentage(carouselMessage) {
    const removedItemAssetGroups = carouselMessage
      .get('itemAssetGroups')
      .map(itemAsset => {
        const discountedPrice = itemAsset.get('discountedPrice')
        if (discountedPrice) {
          return itemAsset.set(
            'discountedPrice',
            discountedPrice.delete('percentage')
          )
        } else {
          return itemAsset
        }
      })

    return carouselMessage.set('itemAssetGroups', removedItemAssetGroups)
  },

  // 캐러셀 인트로 미 사용시 초기화
  initializeCarouselIntro(messageForm) {
    return messageForm.withMutations(v =>
      v
        .merge(TalkChannelMessageHelper.initialCarouselIntro)
        .set('hasIntro', false)
    )
  },

  initializeCarouselFeedButtonAssetGroups(buttonAssetGroups, count) {
    // 비어있는 ordering 에 대한 버튼을 초기화 값으로 세팅
    return Range(0, count)
      .map(index => {
        const targetButton = buttonAssetGroups.find(
          item => item.get('ordering') === index
        )

        return checkNotEmpty(targetButton)
          ? targetButton
          : TalkChannelMessageHelper.initialButtonAsset.withMutations(v =>
              v.set('ordering', index)
            )
      })
      .toList()
  },

  initializePersonalMessageCarouselItemAssetGroups(itemAssetGroups) {
    return Range(0, 6)
      .map(index => {
        const targetAsset = itemAssetGroups.find(
          item => item.get('ordering') === index
        )

        return checkNotEmpty(targetAsset)
          ? targetAsset
          : TalkChannelMessageHelper.initialAsset.withMutations(v =>
              v.set('ordering', index)
            )
      })
      .toList()
  },
}

/**
 * messageElement형태로 있는 스팸필터의 결과를 validationError의 형태로 변환
 * 스팸필터 적용의 결과는 크게 이미지, 텍스트로 구분
 * 텍스트의 경우에 { title: { keyword: array } } keyword는 스팸필터에 걸린 키워드의 array, 빈 경우에 통과 아닌경우에 스팸필터에 걸림
 * 이미지의 경우에 { image: { match: bool } } match는 true면 스팸필터에 걸림, false는 통과
 * (참고: https://wiki.daumkakao.com/pages/viewpage.action?pageId=794609504)
 * 위와 같은 형태를 validationError의 형태로 변환
 * 텍스트 요소는 { isValid: bool, message: array } 각각 스팸필터 통과여부, message는 스팸필터 걸린 keywrod array
 * 이미지 요소는 { isValid: bool }
 * buttonAssetGroups, itemAssetGroups는 각각의 요소의 결과를 담고 있는 array
 * ex.
 * buttonAssetGroups: {
 *   [ { title: { isValid: bool, message: array }, ordering: 0 }, ... ]
 * }
 * 변환 후 validationExtra - spamFilterResults에 정보를 담아서 스팸필터 결과를 화면에 노출
 */
TalkChannelMessageHelper.Spam = {
  convertSpamResult({ spamResult, creativeFormat, messageForm }) {
    const {
      title,
      description,
      image,
      itemAssetGroups,
      buttonAssetGroups,
      couponBookAssetGroups,
    } = spamResult

    const { itemAssetGroups: originItemAssetGroups } = messageForm

    return {
      title: this.convertTextSpamResult(title),
      description: this.convertTextSpamResult(description), // CAROUSEL_ONLY
      image: this.convertImageSpamResult(image), // BASIC_TEXT_ONLY + CAROUSEL
      itemAssetGroups: this.convertItemAssetGroupResult({
        itemAssetGroupSpamResults: itemAssetGroups,
        creativeFormat,
        originItemAssetGroups,
        buttonAssetGroups, // CAROUSEL_FEED ONLY
        couponBookAssetGroups,
      }),
      buttonAssetGroups: this.convertButtonAssetGroupResult(buttonAssetGroups),
      couponBookAssetGroups: this.convertCouponBookAssetGroupResult(
        couponBookAssetGroups
      ),
    }
  },

  convertTextSpamResult(textSpamResult) {
    if (checkEmpty(textSpamResult)) {
      return IS_VALID()
    } else {
      const filteredKeywordArray = textSpamResult.keyword ?? []
      return filteredKeywordArray.length === 0
        ? IS_VALID()
        : IS_NOT_VALID(filteredKeywordArray)
    }
  },

  convertImageSpamResult(imageSpamResult) {
    if (checkEmpty(imageSpamResult)) {
      return IS_VALID()
    } else {
      const isImageFiltered = imageSpamResult.match ?? false
      return isImageFiltered ? IS_NOT_VALID() : IS_VALID()
    }
  },

  convertItemAssetGroupResult({
    itemAssetGroupSpamResults,
    creativeFormat,
    originItemAssetGroups,
    buttonAssetGroups,
    couponBookAssetGroups,
  }) {
    if (checkEmpty(itemAssetGroupSpamResults)) {
      return []
    } else {
      const convertedItemAssetGroupSpamResults = []
      itemAssetGroupSpamResults.forEach((itemAssetGroupSpamResult, index) => {
        const { title, image, description, ordering } = itemAssetGroupSpamResult
        const { assetGroupUUID } = originItemAssetGroups.get(index)

        const itemAssetTitleSpamResult = this.convertTextSpamResult(title)
        const itemAssetDescriptionSpamResult =
          this.convertTextSpamResult(description)
        const itemAssetImageSpamResult = this.convertImageSpamResult(image)
        const buttonAssetSpamResult =
          creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE
            ? this.convertButtonAssetGroupResult(
                buttonAssetGroups.filter(v => {
                  const { ordering } = v
                  return [index * 2, index * 2 + 1].includes(ordering)
                })
              )
            : IS_VALID()

        const couponBookAssetSpamResult =
          creativeFormat === CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE
            ? this.convertCouponBookAssetGroupResult(
                couponBookAssetGroups.filter(v => {
                  const { ordering } = v
                  return [index].includes(ordering)
                })
              )
            : IS_VALID()
        const isValid = [
          itemAssetTitleSpamResult,
          itemAssetDescriptionSpamResult,
          itemAssetImageSpamResult,
          buttonAssetSpamResult,
          couponBookAssetSpamResult,
        ].every(v => v.isValid)

        convertedItemAssetGroupSpamResults.push({
          isValid,
          ordering,
          title: itemAssetTitleSpamResult,
          description: itemAssetDescriptionSpamResult,
          image: itemAssetImageSpamResult,
          button: buttonAssetSpamResult,
          couponBook: couponBookAssetSpamResult,
          assetGroupUUID: [
            CreativeFormatEnum.Type.CAROUSEL_COMMERCE_MESSAGE,
            CreativeFormatEnum.Type.CAROUSEL_FEED_MESSAGE,
          ].includes(creativeFormat)
            ? assetGroupUUID
            : null,
        })
      })
      return convertedItemAssetGroupSpamResults
    }
  },

  convertButtonAssetGroupResult(buttonAssetGroupSpamResults) {
    if (checkEmpty(buttonAssetGroupSpamResults)) {
      return IS_VALID()
    } else {
      const buttonAssetSpamValidationResults = []
      buttonAssetGroupSpamResults.forEach(buttonAssetGroupSpamResult => {
        const { title, ordering } = buttonAssetGroupSpamResult
        const buttonAssetTitleResult = this.convertTextSpamResult(title)
        const { isValid } = buttonAssetTitleResult
        buttonAssetSpamValidationResults.push({
          isValid,
          ordering,
          buttonName: buttonAssetTitleResult,
        })
      })

      const isAllValid = buttonAssetSpamValidationResults.every(v => {
        return v.isValid
      })

      return isAllValid
        ? IS_VALID()
        : IS_NOT_VALID('', { buttonAssetSpamValidationResults })
    }
  },

  convertCouponBookAssetGroupResult(couponBookAssetGroupSpamResults) {
    if (checkEmpty(couponBookAssetGroupSpamResults)) {
      return IS_VALID()
    } else {
      const couponBookAssetSpamValidationResults = []
      couponBookAssetGroupSpamResults.forEach(
        couponBookAssetGroupSpamResult => {
          const { title, ordering, couponBookTitle } =
            couponBookAssetGroupSpamResult
          const couponBookAssetTitleResult = this.convertTextSpamResult(title)
          const couponBookAssetCouponBookTitleResult =
            this.convertTextSpamResult(couponBookTitle)
          const { isValid: isTitleValid } = couponBookAssetTitleResult
          const { isValid: isCouponBookTitleValid } =
            couponBookAssetCouponBookTitleResult
          couponBookAssetSpamValidationResults.push({
            isValid: isTitleValid && isCouponBookTitleValid,
            ordering,
            title: couponBookAssetTitleResult,
            couponBookTitle: couponBookAssetCouponBookTitleResult,
          })
        }
      )

      const isAllValid = couponBookAssetSpamValidationResults.every(v => {
        return v.isValid
      })

      return isAllValid
        ? IS_VALID()
        : IS_NOT_VALID('', { couponBookAssetSpamValidationResults })
    }
  },
}

export { TalkChannelMessageHelper }
