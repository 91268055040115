import { convertToCommaSeparatedString } from '../../../utils/utils'
import PopupProxy from '../../Popup/Common/PopupProxy'
import React from 'react'

export const AD_VIEW_CREATE_LIMIT_COUNT = 1000

export const adViewCountLimitDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">애드뷰 개수 초과</strong>
      <p className="txt_layer">
        {`애드뷰는 광고계정당 ${convertToCommaSeparatedString(
          AD_VIEW_CREATE_LIMIT_COUNT
        )}개까지 만들 수 있습니다.`}
      </p>
    </PopupProxy>
  )
}

export const adViewDeleteNotAvailableDialog = defaultSize => {
  return (
    <PopupProxy>
      <strong className="tit_layer">소재 삭제 불가</strong>
      <p className="txt_layer">
        {`기본 ${defaultSize}개 이상의 소재를 등록해야 합니다.`}
      </p>
    </PopupProxy>
  )
}

export const adViewCreateCancelDialog = onAccept => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onAccept}>
      <strong className="tit_layer">애드뷰 만들기 취소</strong>
      <p className="txt_layer">
        애드뷰 만들기를 취소하시겠습니까?
        <br />
        저장되지 않은 모든 내용은 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const adViewModifyCancelDialog = onAccept => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onAccept}>
      <strong className="tit_layer">애드뷰 수정 취소</strong>
      <p className="txt_layer">
        애드뷰 수정을 취소하시겠습니까?
        <br />
        저장되지 않은 모든 내용은 사라집니다.
      </p>
    </PopupProxy>
  )
}

export const adViewDeleteDialog = onAccept => {
  return (
    <PopupProxy hasSecondaryButton={true} primaryButtonFunc={onAccept}>
      <strong className="tit_layer">애드뷰 삭제</strong>
      <p className="txt_layer">삭제하시겠습니까?</p>
    </PopupProxy>
  )
}

export const adViewNotAvailableDeleteDialog = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">애드뷰 삭제 불가</strong>
      <p className="txt_layer">
        이 애드뷰를 랜딩으로 사용 중인 소재 또는 메시지가 있어
        <br />
        삭제할 수 없습니다.
      </p>
    </PopupProxy>
  )
}

export const adViewInvalidLandingUrl = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">랜딩URL 오류</strong>
      <p className="txt_layer">
        입력한 랜딩URL이 유효하지 않습니다.
        <br />
        정상적인 랜딩URL을 다시 입력하세요.
      </p>
    </PopupProxy>
  )
}

export const adViewInvalidThumbnailImage = () => {
  return (
    <PopupProxy>
      <strong className="tit_layer">이미지 오류</strong>
      <p className="txt_layer">
        입력한 이미지가 유효하지 않습니다.
        <br />
        정상적인 이미지를 다시 추가하세요.
      </p>
    </PopupProxy>
  )
}
