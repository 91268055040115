import React from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { coerceAtLeast, keyMirror } from '../../../utils/utils'

class InputBox extends React.PureComponent {
  static MAX_LENGTH_CHANGE_MODE = keyMirror({
    CLEAR: null,
    TRIM: null,
  })

  static defaultProps = {
    tabIndex: undefined,
    readOnly: false,
    active: true,
    error: false,
    autoFocus: false,
    autoComplete: 'on',
    labelClassName: 'screen_out',
    maxLength: Number.MAX_SAFE_INTEGER,
    maxLengthChangeMode: InputBox.MAX_LENGTH_CHANGE_MODE.CLEAR,
    isShowingInputLength: false,
    isShowingRemoveButton: false,
    hideInputWhenInactive: false,
    preventInitialize: false,
    onChange: () => undefined,
    onClick: () => undefined,
    onFocus: () => undefined,
    onBlur: () => undefined,
    onEnterKey: () => undefined,
    onRemove: () => undefined,
  }

  static propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    labelClassName: PropTypes.string,
    value: PropTypes.any,
    maxLength: PropTypes.number,
    maxLengthChangeMode: PropTypes.oneOf(
      Object.keys(InputBox.MAX_LENGTH_CHANGE_MODE)
    ),
    readOnly: PropTypes.bool,
    active: PropTypes.bool,
    error: PropTypes.bool,
    autoFocus: PropTypes.bool,
    autoComplete: PropTypes.oneOf(['on', 'off']),
    containerRef: PropTypes.any,
    inputRef: PropTypes.any,
    isShowingInputLength: PropTypes.bool,
    isShowingRemoveButton: PropTypes.bool,
    onChange: PropTypes.func,
    onClick: PropTypes.func,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func,
    onEnterKey: PropTypes.func,
    onRemove: PropTypes.func,
    iconClassName: PropTypes.string,
    children: PropTypes.any,
    hideInputWhenInactive: PropTypes.bool,
    tabIndex: PropTypes.number,
    preventInitialize: PropTypes.bool,
  }

  state = {
    hasFocus: false,
    inputText: this.props.value || '',
    maxLength: this.props.maxLength,
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.maxLength !== nextProps.maxLength) {
      return { maxLength: nextProps.maxLength }
    }

    if (prevState.inputText !== nextProps.value) {
      return { inputText: nextProps.value || '' }
    }

    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevState.maxLength !== this.props.maxLength &&
      !this.props.preventInitialize
    ) {
      // set native value -> trigger input `change` event
      const inputEl = document.getElementById(this.props.id)
      const prevValue = inputEl.value
      inputEl.value = this.getInputValueByMaxLengthChangeMode()
      const event = new Event('input', { bubbles: true })
      const tracker = inputEl._valueTracker
      if (tracker) {
        tracker.setValue(prevValue)
      }

      inputEl.dispatchEvent(event)
    }
  }

  render() {
    const {
      id,
      className,
      title,
      labelClassName,
      value,
      containerRef,
      inputRef,
      readOnly,
      active,
      error,
      autoFocus,
      autoComplete,
      isShowingRemoveButton,
      children,
      hideInputWhenInactive,
      tabIndex,
    } = this.props

    const { inputText, maxLength, hasFocus } = this.state
    const hideInputValue = hideInputWhenInactive && !active
    const eventHandler = active && !readOnly ? this.handleEvent : () => {}

    return (
      <span
        ref={containerRef}
        className={cx('box_inptxt', className, {
          on: hasFocus,
          in_active: !active,
          in_error: error,
          on_inp: inputText?.length > 0 || false,
        })}>
        {this.renderInputTextLength()}
        <span className="inner_inp">
          {this.renderIconView()}
          <label className={labelClassName} htmlFor={id}>
            {this.renderPlaceHolder()}
          </label>
          <input
            tabIndex={tabIndex}
            className="inp_txt"
            type="text"
            id={id}
            name={id}
            title={title}
            maxLength={maxLength}
            value={(!hideInputValue && value) || ''}
            ref={inputRef}
            readOnly={readOnly}
            autoFocus={autoFocus}
            autoComplete={autoComplete}
            onChange={eventHandler}
            onFocus={eventHandler}
            onBlur={eventHandler}
            onClick={eventHandler}
            onKeyPress={eventHandler}
          />
        </span>
        {isShowingRemoveButton &&
          this.state.inputText.length > 0 &&
          active &&
          !readOnly && (
            <button className="btn_del" onClick={this.handleRemove}>
              <span className="ico_comm ico_del">삭제</span>
            </button>
          )}
        {children}
      </span>
    )
  }

  getInputValueByMaxLengthChangeMode = () => {
    switch (this.props.maxLengthChangeMode) {
      case InputBox.MAX_LENGTH_CHANGE_MODE.CLEAR: {
        return ''
      }

      case InputBox.MAX_LENGTH_CHANGE_MODE.TRIM: {
        return String(this.state.inputText).slice(0, this.props.maxLength)
      }

      default: {
        break
      }
    }

    return ''
  }

  renderInputTextLength = () => {
    if (!this.props.isShowingInputLength || this.state.maxLength < 0)
      return null

    const length = coerceAtLeast(
      this.state.maxLength - (this.state.inputText?.length || 0),
      0
    )

    return (
      <span className="num_byte">
        <span className="screen_out">작성가능한 총 텍스트 수</span>
        {length}
      </span>
    )
  }

  renderPlaceHolder = () => {
    const isNotEmptyState =
      !this.state.inputText || this.state.inputText.length === 0
    const isNotEmptyProps = !this.props.value || this.props.value.length === 0
    return isNotEmptyState && isNotEmptyProps ? this.props.placeholder : ''
  }

  renderIconView = () => {
    if (!this.props.iconClassName) return null
    return <span className={`ico_comm ${this.props.iconClassName}`} />
  }

  handleEvent = e => {
    const { type, key } = e
    const { active, readOnly, onFocus, onBlur, onChange, onClick, onEnterKey } =
      this.props

    if (!active || readOnly) return

    switch (type) {
      case 'focus': {
        this.setState({ hasFocus: true }, () => onFocus(e))
        break
      }

      case 'blur': {
        /**
         * Firefox issue
         * http://tirdadc.github.io/blog/2015/06/11/react-dot-js-firefox-issue-with-onblur/
         */
        const { explicitOriginalTarget, originalTarget } = e.nativeEvent
        if (explicitOriginalTarget && explicitOriginalTarget === originalTarget)
          return

        this.setState({ hasFocus: false }, () => onBlur(e))
        break
      }

      case 'change': {
        if (e.target.value.length > this.state.maxLength) return
        onChange(e)
        break
      }

      case 'click': {
        onClick(e)
        break
      }

      case 'keypress': {
        if (key === 'Enter') onEnterKey(e)
        break
      }

      default:
    }
  }

  handleRemove = e => {
    this.props.onRemove(e)
  }
}

export default InputBox
