import { Map } from 'immutable'

import { keyMirror } from '../../../../utils/utils'
import { IS_NOT_VALID, IS_VALID } from '../../../../validators/validation'
import TargetingInclusionEnum from '../../../../enums/TargetingInclusionEnum'

export const NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY = keyMirror({
  TRACKER_TARGETING_TERM: null,
  TRACKER_TARGETING_TRACK_ID: null,
})

export const NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY_PATH = Map({
  [NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY.TRACKER_TARGETING_TERM]: ['term'],
  [NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY.TRACKER_TARGETING_TRACK_ID]: [
    'trackId',
  ],
})

export const trackerTargetingTrackIdValidator = ({
  trackId,
  trackerTargetings,
  trackerTargetingForm,
}) => {
  const { trackId: id, eventCode, trackRuleId, term } = trackerTargetingForm

  const isAlreadyUsed = trackerTargetings.some(
    v =>
      v.get('trackId') === id &&
      v.get('eventCode') === eventCode &&
      v.get('trackRuleId') === trackRuleId &&
      v.get('term') === term
  )

  if (isAlreadyUsed) {
    return IS_NOT_VALID('동일한 타겟이 이미 등록되어 있습니다.')
  }

  const isExcludeAllEvent = trackerTargetings.some(
    v =>
      v.get('trackId') === id &&
      v.get('eventCode') === '*' &&
      v.get('inclusionType') === TargetingInclusionEnum.Type.EXCLUDE
  )

  if (isExcludeAllEvent) {
    return IS_NOT_VALID(
      '모든 이벤트 -제외 로 등록한 타겟이 있을 경우, 동일한 픽셀로 추가 타겟을 생성할 수 없습니다.'
    )
  }

  const isDuplicatedTarget =
    trackerTargetings.filter(trackerTargeting => {
      return (
        trackId === trackerTargeting.get('trackId') &&
        trackRuleId === trackerTargeting.get('trackRuleId') &&
        eventCode === trackerTargeting.get('eventCode')
      )
    }).size > 1

  if (isDuplicatedTarget) {
    return IS_NOT_VALID(
      '동일 픽셀 & SDK, 동일 이벤트로 등록 가능한 최대 2개의 타겟이 등록되어 있습니다.'
    )
  }

  return IS_VALID()
}

export const NEW_TRACKER_TARGETING_FORM_VALIDATOR_FOR_ADGROUP = Map({
  [NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY.TRACKER_TARGETING_TERM]: term => {
    if (term < 1 || term > 180) {
      return IS_NOT_VALID('1 이상 ~ 180 이하의 숫자를 입력할 수 있습니다.')
    }
    return IS_VALID()
  },
  [NEW_TRACKER_TARGETING_FORM_VALIDATION_KEY.TRACKER_TARGETING_TRACK_ID]: (
    trackId,
    trackerTargetingForm,
    getState
  ) => {
    const {
      adGroupV2: { creatingAdGroups, openAdGroupIndex, modifyAdGroup },
    } = getState()

    const {
      adGroupForm: {
        targeting: { trackerTargetings },
      },
    } = creatingAdGroups.isEmpty()
      ? modifyAdGroup
      : creatingAdGroups.get(openAdGroupIndex)

    return trackerTargetingTrackIdValidator({
      trackId,
      trackerTargetings,
      trackerTargetingForm,
    })
  },
})
