import { keyMirror } from '../../utils'
import {
  GLOBAL_STORAGE_ITEM,
  GlobalStorageLocal,
} from '../../storage/storageFactoryImpl'

export const KakaoMoment = {
  GW: {
    production: 'https://gateway.moment.kakao.com/gateway/',
    stage: 'https://km-gateway-stage.devel.kakao.com/gateway/',
    sandbox: 'https://km-gateway-qa.devel.kakao.com/gateway/',
    develop: 'https://km-gateway-dev.devel.kakao.com/gateway/',
    adhoc: 'https://dsp-gateway-adhoc.devel.kakao.com/gateway/',
    local: 'http://test.kakao.com:9090/gateway/',
  },
  Host: {
    production: 'https://moment.kakao.com',
    stage: 'https://mango-dsp-stage.devel.kakao.com',
    sandbox: 'https://mango-dsp-sandbox.devel.kakao.com',
    develop: 'https://mango-dsp-dev.devel.kakao.com',
    adhoc: 'https://mango-dsp-adhoc.devel.kakao.com',
    local: 'https://dsp.kakao.com',
  },
  Domain: {
    stage: 'mango-dsp-stage',
    sandbox: 'mango-dsp-sandbox',
    develop: 'mango-dsp-dev',
    adhoc: 'mango-dsp-adhoc',
  },
  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },
}

export const ApiPhase = keyMirror({
  production: null,
  stage: null,
  sandbox: null,
  develop: null,
  adhoc: null,
  local: null,
})

export const getApiPhase = () => {
  const defaultApiPhase = ApiPhase.sandbox
  const hostName = window.location.hostname

  if (__LOCAL__) {
    const apiPhaseByStorage = GlobalStorageLocal.get(
      GLOBAL_STORAGE_ITEM.API_PHASE
    )

    if (Object.keys(ApiPhase).includes(apiPhaseByStorage)) {
      return apiPhaseByStorage
    } else {
      GlobalStorageLocal.set(GLOBAL_STORAGE_ITEM.API_PHASE, defaultApiPhase)
      return defaultApiPhase
    }
  }

  const apiPhase = __PRODUCTION__
    ? ApiPhase.production
    : Object.keys(ApiPhase).find(
        phase =>
          KakaoMoment.Host[phase]?.includes(hostName) ||
          hostName.includes(KakaoMoment.Domain[phase])
      ) ?? defaultApiPhase

  GlobalStorageLocal.set(GLOBAL_STORAGE_ITEM.API_PHASE, apiPhase)

  return apiPhase
}

// https://wiki.daumkakao.com/pages/viewpage.action?pageId=485203376
export const isMomentRootPath = () =>
  ['', '/', '/login'].includes(window.location.pathname)

export const getApiServerURL = () =>
  __PRODUCTION__
    ? KakaoMoment.GW.production
    : KakaoMoment.GW[getApiPhase()] ?? KakaoMoment.GW.sandbox
