import { coerceIn, keyMirror } from '../../utils/utils'
import createReducer from 'redux-immutablejs/lib/utils/createReducer'
import { fromJS } from 'immutable'

const BizBoardImageEditor = keyMirror(
  {
    INIT_IMAGE_DATA: null,

    SET_IMAGE_RECT: null,
    SET_IMAGE_SCALE: null,
    SET_IMAGE_SCALE_VALUE: null,
    SET_IMAGE_SCALE_RANGE: null,

    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,

    CLEAR: null,
  },
  'BIZ_BOARD_CREATE'
)

const initialRect = fromJS({
  width: 0,
  height: 0,
  top: 0,
  left: 0,
})

const initialScale = fromJS({ value: 1, min: 0.8, max: 10 })

const initialState = fromJS({
  imageRects: [],
  imageScales: [],
})

export default createReducer(initialState, {
  [BizBoardImageEditor.INIT_IMAGE_DATA]: (state, { index = undefined }) =>
    state.withMutations(s =>
      index >= 0
        ? s
            .setIn(['imageRects', index], initialRect)
            .setIn(['imageScales', index], initialScale)
        : s
            .set('imageRects', initialState.get('imageRects'))
            .set('imageScales', initialState.get('imageScales'))
    ),

  [BizBoardImageEditor.SET_IMAGE_RECT]: (state, { index, imageRect }) =>
    state.updateIn(['imageRects', index], prevImageRect =>
      prevImageRect
        ? prevImageRect.mergeDeep(fromJS(imageRect))
        : fromJS(imageRect)
    ),

  [BizBoardImageEditor.SET_IMAGE_SCALE]: (state, { index, imageScale }) =>
    state.setIn(['imageScales', index], fromJS(imageScale)),

  [BizBoardImageEditor.SET_IMAGE_SCALE_VALUE]: (state, { index, value }) =>
    state.updateIn(['imageScales', index], prevScale =>
      prevScale
        ? prevScale.set(
            'value',
            coerceIn(value, prevScale.get('min'), prevScale.get('max'))
          )
        : initialScale
    ),

  [BizBoardImageEditor.SET_IMAGE_SCALE_RANGE]: (state, { index, min, max }) =>
    state.updateIn(['imageScales', index], prevScale =>
      prevScale ? prevScale.set('min', min).set('max', max) : initialScale
    ),

  [BizBoardImageEditor.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn(keyPath, fromJS(value)),

  [BizBoardImageEditor.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(keyPath, initialState.getIn(keyPath)),

  [BizBoardImageEditor.CLEAR]: () => initialState,
})

export function initBizBoardCreateByExpressMetaInfo(expressMetaInfo) {
  return dispatch => {
    const imageAssets = expressMetaInfo?.expressMetaInfoAsset?.images || []

    for (let index = 0; index < imageAssets.length; index++) {
      const extra = JSON.parse(imageAssets[index].extra)

      if (extra.imageRect) {
        dispatch(setBizBoardCreateImageRect(index, extra.imageRect))
      }

      if (extra.imageScale) {
        dispatch(setBizBoardCreateImageScale(index, extra.imageScale))
      }
    }
  }
}

export function initBizBoardCreateImageData(index = undefined) {
  return {
    type: BizBoardImageEditor.INIT_IMAGE_DATA,
    index,
  }
}

export function setBizBoardCreateImageRect(index, imageRect) {
  return {
    type: BizBoardImageEditor.SET_IMAGE_RECT,
    index,
    imageRect,
  }
}

export function setBizBoardCreateImageScale(index, imageScale) {
  return {
    type: BizBoardImageEditor.SET_IMAGE_SCALE,
    index,
    imageScale,
  }
}

export function setBizBoardCreateImageScaleValue(index, value) {
  return {
    type: BizBoardImageEditor.SET_IMAGE_SCALE_VALUE,
    index,
    value,
  }
}

export function updateBizBoardCreateImageScaleValue(index, updater) {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        bizBoardImageEditor: { imageScales },
      },
    } = getState()

    if (typeof updater === 'function') {
      dispatch(
        setBizBoardCreateImageScaleValue(
          index,
          updater(
            imageScales.getIn([index, 'value']) || initialScale.get('vale')
          )
        )
      )
    }
  }
}

export function setBizBoardCreateImageScaleRange(index, min, max) {
  return {
    type: BizBoardImageEditor.SET_IMAGE_SCALE_RANGE,
    index,
    min,
    max,
  }
}

export function setBizBoardCreateByKeyPath(keyPath, value) {
  return {
    type: BizBoardImageEditor.SET_BY_KEY_PATH,
    keyPath,
    value,
  }
}

export function initBizBoardCreateByKeyPath(keyPath) {
  return {
    type: BizBoardImageEditor.INIT_BY_KEY_PATH,
    keyPath,
  }
}

export function clearBizBoardCreate() {
  return {
    type: BizBoardImageEditor.CLEAR,
  }
}
