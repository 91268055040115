import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { fromJS } from 'immutable'
import { coerceToArray } from '../../utils/stringUtils'

const PcTalkBottomCreateBundle = keyMirror(
  {
    ADD_BUNDLES: null,
    SET_BUNDLES: null,
    DELETE_BUNDLE: null,
    SET_BUNDLE_PROP_BY_KEY_PATH: null,
    SELECT_BUNDLE: null,
    CLEAR: null,
  },
  'PC_TALK_BOTTOM_CREATE_BUNDLE'
)

const initialState = fromJS({
  pcTalkBottomBundles: [], // PcTalkBottomBundleModel[]
  selectedPcTalkBottomBundleId: null,
})

export default createReducer(initialState, {
  [PcTalkBottomCreateBundle.ADD_BUNDLES]: (state, { pcTalkBottomBundles }) => {
    const { pcTalkBottomBundles: prevPcTalkBottomBundles } = state

    return state.withMutations(s =>
      s
        .update(
          'selectedPcTalkBottomBundleId',
          prevSelectedPcTalkBottomBundleId =>
            prevPcTalkBottomBundles.isEmpty()
              ? pcTalkBottomBundles.first().get('bundleUUID')
              : prevSelectedPcTalkBottomBundleId
        )
        .update('pcTalkBottomBundles', prev =>
          prev.concat(fromJS(pcTalkBottomBundles))
        )
    )
  },

  [PcTalkBottomCreateBundle.SET_BUNDLES]: (state, { pcTalkBottomBundles }) =>
    state.set('pcTalkBottomBundles', fromJS(pcTalkBottomBundles)),

  [PcTalkBottomCreateBundle.SET_BUNDLE_PROP_BY_KEY_PATH]: (
    state,
    { index, keyPath, valueOrFn }
  ) =>
    state.updateIn(['pcTalkBottomBundles', index], pcTalkBottomBundle => {
      const keyPathArray = coerceToArray(keyPath)

      if (keyPathArray.length > 1) {
        const withoutLast = keyPathArray.slice(0, -1)

        if (!(pcTalkBottomBundle && pcTalkBottomBundle.getIn(withoutLast))) {
          return pcTalkBottomBundle
        }
      }

      return pcTalkBottomBundle
        ? typeof valueOrFn === 'function'
          ? pcTalkBottomBundle.updateIn(keyPathArray, prevPcTalkBottomBundle =>
              valueOrFn(prevPcTalkBottomBundle)
            )
          : pcTalkBottomBundle.setIn(keyPathArray, fromJS(valueOrFn))
        : pcTalkBottomBundle
    }),

  [PcTalkBottomCreateBundle.DELETE_BUNDLE]: (state, { id }) => {
    const { pcTalkBottomBundles, selectedPcTalkBottomBundleId } = state

    return state.withMutations(s =>
      s
        .update(
          'selectedPcTalkBottomBundleId',
          prevSelectedPcTalkBottomBundleId => {
            const isSelected = selectedPcTalkBottomBundleId === id

            // 삭제할 대상이 현재 선택된 경우.
            if (isSelected) {
              const pcTalkBottomBundleIdSeq = pcTalkBottomBundles
                .map(v => v.get('bundleUUID'))
                .toSeq()

              return pcTalkBottomBundleIdSeq.count() === 1 // 삭제할 대상을 제외하고 그 외 요소가 존재하지 않는 경우.
                ? undefined
                : pcTalkBottomBundleIdSeq.first() ===
                  selectedPcTalkBottomBundleId // 삭제할 대상이 첫번 째 항목인 경우 -> 그 다음 항목을 선택
                ? pcTalkBottomBundleIdSeq.rest().first()
                : pcTalkBottomBundleIdSeq.takeUntil(v => v === id).last() // 삭제할 대상이 첫번 째 항목이 아닌 경우 -> 그 앞 항목을 선택
            } else {
              return prevSelectedPcTalkBottomBundleId
            }
          }
        )
        .update('pcTalkBottomBundles', prev =>
          prev.filter(v => v.get('bundleUUID') !== id)
        )
    )
  },
  [PcTalkBottomCreateBundle.SELECT_BUNDLE]: (state, { id }) =>
    state.set('selectedPcTalkBottomBundleId', id),

  [PcTalkBottomCreateBundle.CLEAR]: () => initialState,
})

export function addPcTalkBottomBundles(pcTalkBottomBundles) {
  return {
    type: PcTalkBottomCreateBundle.ADD_BUNDLES,
    pcTalkBottomBundles,
  }
}

export function setPcTalkBottomCreateBundles(pcTalkBottomBundles) {
  return {
    type: PcTalkBottomCreateBundle.SET_BUNDLES,
    pcTalkBottomBundles,
  }
}

export function setPcTalkBottomCreateBundlePropByKey(
  index,
  keyPath,
  valueOrFn
) {
  return {
    type: PcTalkBottomCreateBundle.SET_BUNDLE_PROP_BY_KEY_PATH,
    index,
    keyPath,
    valueOrFn,
  }
}

export function deletePcTalkBottomCreateBundle(id) {
  return {
    type: PcTalkBottomCreateBundle.DELETE_BUNDLE,
    id,
  }
}

export function selectPcTalkBottomCreateBundle(id) {
  return {
    type: PcTalkBottomCreateBundle.SELECT_BUNDLE,
    id,
  }
}

export function clearPcTalkBottomCreateBundle() {
  return {
    type: PcTalkBottomCreateBundle.CLEAR,
  }
}
