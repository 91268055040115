import {
  setCategoryTargetingSelectedItems,
  TARGETING_POPULATION_CATEGORY_TYPE,
} from '../mTargeting'
import {
  CATEGORY_TARGET_KEY,
  createPixelS2Value,
} from '../../../utils/advertise/targeting'
import { getMultipleCustomTargetPopulationV2 } from '../mPopulation'
import CampaignTypeEnum from '../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../enums/GoalEnum'
import ObjectiveTypeEnum from '../../../enums/ObjectiveTypeEnum'
import TargetingInclusionEnum from '../../../enums/TargetingInclusionEnum'
import { AUDIENCE_TARGET } from '../../../components/Targeting/Audience/Common/audience'
import { Map } from 'immutable'

/**
 * 단일모수의 경우 INCLUDE로 조회
 * 단, 디스플레이 X 전환 X 친구수늘리기, 전환 X PIXEL_AND_SDK X APP_INSTALL 의 경우에만 선택된 톡채널만 EXCLUDE
 * @param adAccountId
 * @param targeting
 * @param campaignTypeGoal
 * @param objective
 * @param audienceType
 * @returns {function(...[*]=)}
 */
export function updateCustomTargetPopulation({
  adAccountId,
  targeting,
  campaignTypeGoal,
  objective,
  audienceType,
}) {
  return dispatch => {
    if (!targeting) return

    const { audienceTarget } = targeting
    const isAdGroupType = audienceTarget === AUDIENCE_TARGET.AD_GROUP

    const { campaignType, goal } = campaignTypeGoal || {}
    const { type: objectiveType, value: targetingValue } = objective || {}

    const isDisplayConversionTalkChannelType =
      isAdGroupType &&
      campaignType === CampaignTypeEnum.Type.DISPLAY &&
      goal === GoalEnum.Type.CONVERSION &&
      objectiveType === ObjectiveTypeEnum.Type.TALK_CHANNEL

    const {
      trackerTargetings,
      plusFriendTargetings,
      syncAppTargetings,
      customerFileTargetings,
      cohortTargetings,
      plusFriendGroupTargetings,
    } = targeting

    const pixelTO =
      trackerTargetings?.map(v => {
        const { term } = v

        return {
          term,
          targetingCategory: TARGETING_POPULATION_CATEGORY_TYPE.TRACKER,
          targetingValue: createPixelS2Value(v),
          inclusionType: TargetingInclusionEnum.Type.INCLUDE,
          populationScore: 0,
          similarityIndex: null,
          campaignType,
          goal,
          audienceType,
        }
      }) || []

    const kakaoUserTO =
      plusFriendTargetings?.map(v => {
        const { profileId } = v
        return {
          targetingCategory: TARGETING_POPULATION_CATEGORY_TYPE.PLUS_FRIENDS,
          targetingValue: profileId,
          inclusionType:
            isDisplayConversionTalkChannelType &&
            profileId === Number(targetingValue)
              ? TargetingInclusionEnum.Type.EXCLUDE
              : TargetingInclusionEnum.Type.INCLUDE,
          populationScore: 0,
          similarityIndex: null,
          campaignType,
          goal,
          audienceType,
        }
      }) || []

    const syncAppUserTO =
      syncAppTargetings?.map(v => {
        const { appId } = v
        return {
          targetingCategory: TARGETING_POPULATION_CATEGORY_TYPE.SYNC_APP,
          targetingValue: appId,
          inclusionType: TargetingInclusionEnum.Type.INCLUDE,
          populationScore: 0,
          similarityIndex: null,
          campaignType,
          goal,
          audienceType,
        }
      }) || []

    const customerFileTO =
      customerFileTargetings?.map(v => {
        const { adidListKey } = v
        return {
          targetingCategory:
            TARGETING_POPULATION_CATEGORY_TYPE.CUSTOM_ADID_UPLOAD,
          targetingValue: adidListKey,
          inclusionType: TargetingInclusionEnum.Type.INCLUDE,
          populationScore: 0,
          similarityIndex: null,
          campaignType,
          goal,
          audienceType,
        }
      }) || []

    const cohortTO =
      cohortTargetings?.map(v => {
        const { cohortId } = v
        return {
          targetingCategory: TARGETING_POPULATION_CATEGORY_TYPE.COHORT,
          targetingValue: cohortId,
          inclusionType: TargetingInclusionEnum.Type.INCLUDE,
          populationScore: 0,
          similarityIndex: null,
          campaignType,
          goal,
          audienceType,
        }
      }) || []

    const plusFriendGroupTO = plusFriendGroupTargetings?.map(v => {
      const { plusFriendProfileId, groupKey } = v

      return {
        plusFriendProfileId,
        targetingValue: groupKey,
        inclusionType: TargetingInclusionEnum.Type.INCLUDE,
        targetingCategory:
          TARGETING_POPULATION_CATEGORY_TYPE.PLUS_FRIEND_GROUP_FILE,
      }
    })

    const customTargetTO = [
      ...pixelTO,
      ...kakaoUserTO,
      ...syncAppUserTO,
      ...customerFileTO,
      ...cohortTO,
      ...plusFriendGroupTO,
    ]

    dispatch(getMultipleCustomTargetPopulationV2(adAccountId, customTargetTO))
  }
}

const sortByTreeSequence = (a, b) => Number(a.get('id')) - Number(b.get('id'))

export const updateCategorySelectedItems = targetingForm => {
  return dispatch => {
    const { ufoInterests, ufoBusinessTypes } = targetingForm || {}

    const interestValues = ufoInterests
      .map(i => Map({ id: i, type: CATEGORY_TARGET_KEY.interests }))
      .sort(sortByTreeSequence)

    const businessValues = ufoBusinessTypes
      .map(b => Map({ id: b, type: CATEGORY_TARGET_KEY.business }))
      .sort(sortByTreeSequence)

    const targetingValues = businessValues.concat(interestValues)

    dispatch(setCategoryTargetingSelectedItems(targetingValues))
  }
}
