import { keyMirror } from '../utils/utils'

export default {
  ACTIVE: '1',
  INACTIVE: '0',

  Day: {
    Type: keyMirror({
      mondayTime: null,
      tuesdayTime: null,
      wednesdayTime: null,
      thursdayTime: null,
      fridayTime: null,
      saturdayTime: null,
      sundayTime: null,
    }),

    Name: {
      mondayTime: '월',
      tuesdayTime: '화',
      wednesdayTime: '수',
      thursdayTime: '목',
      fridayTime: '금',
      saturdayTime: '토',
      sundayTime: '일',
    },

    values() {
      return Object.freeze(Object.keys(this.Type))
    },

    sortedValues() {
      const seq = [
        'mondayTime',
        'tuesdayTime',
        'wednesdayTime',
        'thursdayTime',
        'fridayTime',
        'saturdayTime',
        'sundayTime',
      ]
      return Object.freeze(
        Object.keys(this.Type).sort((a, b) => seq.indexOf(a) - seq.indexOf(b))
      )
    },

    getName(type) {
      return this.Name[type]
    },
  },
}
