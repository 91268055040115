import { CONTEXTPATH, fetchGet, fetchPost } from '../../utils/fetchUtils'
import { fetchPostFormExternal } from '../../utils/externalFetchUtils'

function validateUrl(adAccountId, url) {
  return fetchPost(`${CONTEXTPATH}/url/check`, { url }, adAccountId)
}

/**
 * 텐스에 동영상 업로드
 */
function uploadVideoFileToTenth(url, data, cancelTokenSource, onProgress) {
  return fetchPostFormExternal(url, data, cancelTokenSource, onProgress)
}

/**
 * 코어에서 서명된 URL 정보 얻어오기 (HTTP Query-string 인증)
 */
function getTenthSignedURL(adAccountId, fileName) {
  return fetchGet(
    `${CONTEXTPATH}/opinionProof/uploadPath?fileName=${fileName}`,
    adAccountId
  )
}

/**
 * 텐스에 증빙 파일 업로드
 */
function uploadProofFileToTenth(url, data, cancelTokenSource, onProgress) {
  return fetchPostFormExternal(url, data, cancelTokenSource, onProgress)
}

export default {
  validateUrl,
  uploadVideoFileToTenth,
  getTenthSignedURL,
  uploadProofFileToTenth,
}
