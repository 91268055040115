import { keyMirror } from '../utils'

export const API_SERVER = keyMirror({
  production: null,
  stage: null,
  sandbox: null,
  develop: null,
  adhoc: null,
  local: null,
})

/**
 * 현재 환경에서 선택 가능한 api server list.
 * - local: 제한없음.
 * - stage: prod, local 불가.
 * - dev, sandbox: prod, stage, local 불가.
 * @return {string[]}
 */
export function getSelectableApiServerKeys() {
  const apiServerKeys = Object.keys(API_SERVER)
  if (__LOCAL__) {
    return apiServerKeys
  } else if (__DEV__ || __SANDBOX__) {
    return apiServerKeys.filter(
      v =>
        v !== API_SERVER.production &&
        v !== API_SERVER.stage &&
        v !== API_SERVER.local
    )
  } else if (__STAGE__) {
    return apiServerKeys.filter(
      v => v !== API_SERVER.production && v !== API_SERVER.local
    )
  } else {
    return []
  }
}
