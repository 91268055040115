import { batch } from 'react-redux'
import { clearCreativeCommon } from '../modules/advertise/mCreativeCommonV2'
import { clearCreativeCreate } from '../modules/advertise/mCreativeCreateV2'
import { clearCreativeModify } from '../modules/advertise/mCreativeModifyV2'
import { clearAllPopulation } from '../modules/targeting/mPopulation'
import { initAllTargetingState } from '../modules/targeting/mTargeting'
import {
  hideLoading,
  LOADING_KEY,
  showLoading,
} from '../modules/common/mLoading'
import { clearAllCampaign } from '../modules/advertise/mCampaignV2'
import { clearAllAdGroup } from '../modules/advertise/mAdGroupV2'
import {
  clearAllAdvertise,
  receiveAdvertiseAdAccountInfo,
} from '../modules/advertise/mAdvertiseV2'
import { getAdAccountConstraints } from '../modules/advertise/mAdConstraints'

function batchAdvertiseOnMount(adAccountId) {
  return async (dispatch, getState, api) => {
    dispatch(showLoading(LOADING_KEY.SETUP_AD_BASE))

    try {
      const adAccountResponse =
        await api.adAccount.fetchAdAccountInfoByAdAccountId(adAccountId)

      const adAccount = adAccountResponse?.data || {}

      dispatch(receiveAdvertiseAdAccountInfo(adAccount))

      await dispatch(getAdAccountConstraints(adAccountId))
    } catch (e) {
      console.log(e.message)
    } finally {
      dispatch(hideLoading(LOADING_KEY.SETUP_AD_BASE))
    }
  }
}

// FIXME
function batchAdvertiseOnUnmount() {
  return (dispatch, getState) => {
    const {
      creativeV2: {
        common: { opinionProofFileObjectUrls },
      },
    } = getState()

    opinionProofFileObjectUrls?.toJS().forEach(objectUrl => {
      URL.revokeObjectURL(objectUrl)
    })

    batch(() => {
      dispatch(clearAllAdvertise())
      dispatch(clearAllCampaign())
      dispatch(clearAllAdGroup())
      dispatch(clearCreativeCommon())
      dispatch(clearCreativeCreate())
      dispatch(clearCreativeModify())
      dispatch(clearAllPopulation())
      dispatch(initAllTargetingState())
    })
  }
}

export { batchAdvertiseOnMount, batchAdvertiseOnUnmount }
