import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { Set } from 'immutable'

export const LOADING_KEY = keyMirror({
  GLOBAL: null,

  LNB_AD_ACCOUNT_SELECT: null,

  CASH_CANCEL_DEPOSIT_FEE: null,

  SETUP_AD_VIEW: null,

  COMMON_BOARD: null,

  // advertisement V2
  SETUP_AD_BASE: null,
  SETUP_AD_CAMPAIGN: null,
  SETUP_AD_GROUP: null,
  SETUP_AD_CREATIVE: null,

  // message
  SETUP_MESSAGE: null,
  MESSAGE_LIST: null,

  // dashboard v3
  DASHBOARD_V3: null,
  DASHBOARD_V3_AD_ACCOUNT_INFO: null,
  DASHBOARD_V3_SUMMARY_INFO: null,
  DASHBOARD_V3_SUMMARY_CHART: null,
  DASHBOARD_V3_TABLE: null,
  DASHBOARD_V3_ON_OFF_CELL: null,
  DASHBOARD_V3_ON_OFF_PRE_CHECK_CAMPAIGN: null,
  DASHBOARD_V3_ON_OFF_PRE_CHECK_AD_GROUP: null,
  DASHBOARD_V3_ON_OFF_BULK_CAMPAIGN: null,
  DASHBOARD_V3_ON_OFF_BULK_AD_GROUP: null,
  DASHBOARD_V3_ON_OFF_BULK_CREATIVE: null,
  DASHBOARD_V3_DELETE_BULK: null,
  DASHBOARD_V3_BID_AMOUNT_BULK: null,
  DASHBOARD_V3_AD_GROUP_COPY_BULK: null,
  DASHBOARD_V3_CREATIVE_COPY_BULK: null,
  DASHBOARD_V3_REQUEST_REVIEW_BULK: null,

  PAYMENT_CARD_MANAGEMENT: null,
  PAYMENT_CARD_SUMMARY: null,
  PAYMENT_CARD_LIST_IN_USE: null,
  PAYMENT_CARD_TRANSACTION_HISTORY: null,
  PAYMENT_CARD_MODIFYING_HISTORY: null,

  MY_AD_ACCOUNT_ALL_TAB: null,
  MY_AD_ACCOUNT_REQUEST_TAB: null,

  DSP_AUTO_PAYMENT_CARD_LIST: null,

  // report v2
  REPORT_V2_DETAIL: null,
  REPORT_V2_TABLE: null,
  REPORT_V2_COMMAND: null,
})

const Loading = keyMirror({
  SHOW_LOADING: null,
  HIDE_LOADING: null,
  CLEAR_LOADING: null,
})

const initialState = Set()

export default createReducer(initialState, {
  [Loading.SHOW_LOADING]: (state, { key }) => state.add(key),

  [Loading.HIDE_LOADING]: (state, { key }) => state.remove(key),

  [Loading.CLEAR_LOADING]: state => state.clear(),
})

export function showLoading(key = LOADING_KEY.GLOBAL) {
  return {
    type: Loading.SHOW_LOADING,
    key,
  }
}

export function hideLoading(key = LOADING_KEY.GLOBAL) {
  return {
    type: Loading.HIDE_LOADING,
    key,
  }
}

export function clearLoading() {
  return {
    type: Loading.CLEAR_LOADING,
  }
}
