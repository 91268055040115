import { keyMirror } from '../utils/utils'
import DeviceOsTypeEnum from './DeviceOsTypeEnum'
import { checkNoneEmpty, checkNotEmpty } from '../utils/regexUtils'

export default {
  StateKey: {
    RSPV_LANDING_URL: 'rspvLandingUrl',
    PC_LANDING_URL: 'pcLandingUrl',
    MOBILE_LANDING_URL: 'mobileLandingUrl',
  },

  Type: keyMirror({
    RSPV: null,
    PC: null,
    MOBILE: null,
    PC_AND_MOBILE: null,
  }),

  InputType: keyMirror({
    RSPV_DEVICE: null, //  [전체], [PC, ANDROID, IOS], [PC, ANDROID], [PC, IOS]
    PC_ONLY_DEVICE: null, // [PC]
    MOBILE_ONLY_DEVICE: null, // [ANDROID, IOS], [ANDROID], [IOS]
  }),

  /**
   *  현재 광고그룹에 설정된 deviceType에 따라 보여줘야 할 디폴트 뷰의 타입을 리턴한다.
   *    - RSPV_DEVICE : [전체] || [PC, ANDROID, IOS] || [PC, ANDROID] || [PC, IOS]
   *    - PC_ONLY_DEVICE: [PC]
   *    - MOBILE_ONLY_DEVICE: [ANDROID, IOS] || [ANDROID] || [IOS]
   */
  getCurrentSelectedDeviceTypeV2(deviceTypes) {
    const pcType = DeviceOsTypeEnum.hasPcOsType(deviceTypes)
    const mobileType = DeviceOsTypeEnum.hasMobileOsType(deviceTypes)

    if (deviceTypes?.size === 0 || (pcType && mobileType)) {
      return this.InputType.RSPV_DEVICE
    } else if (pcType) {
      return this.InputType.PC_ONLY_DEVICE
    } else if (mobileType) {
      return this.InputType.MOBILE_ONLY_DEVICE
    } else {
      return undefined
    }
  },

  getAdCreativeLandingUrlType({
    rspvLandingUrl,
    pcLandingUrl,
    mobileLandingUrl,
  }) {
    return checkNotEmpty(rspvLandingUrl)
      ? this.Type.RSPV
      : checkNoneEmpty(pcLandingUrl, mobileLandingUrl)
      ? this.Type.PC_AND_MOBILE
      : checkNotEmpty(pcLandingUrl)
      ? this.Type.PC
      : this.Type.MOBILE
  },
}
