import React from 'react'
import PropTypes from 'prop-types'
import { checkNotEmpty } from '../../../../utils/regexUtils'

SubTitle.propTypes = {
  className: PropTypes.string,
  subTitle: PropTypes.string,
  tooltipElements: PropTypes.element,
  totalElements: PropTypes.number,
  onRefresh: PropTypes.func,
}

function SubTitle({ subTitle, totalElements, onRefresh, tooltipElements }) {
  return (
    <strong className="tit_layer">
      {subTitle} <em className="fc_emph">{totalElements}</em>
      {checkNotEmpty(tooltipElements) && React.cloneElement(tooltipElements)}
      <a className="btn_refresh" onClick={onRefresh}>
        <span className="ico_comm">새로고침</span>
      </a>
    </strong>
  )
}

export default SubTitle
