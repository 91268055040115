import React, { Fragment } from 'react'

/**
 * 망고 string resource
 */
export const string = {
  // 공통 요소
  common: {
    inhouse: {
      contact: {
        customer_service_center: '1599-5533',
        cts_agency_service_center: '070-7464-0343, 070-7475-6052',
        cts_agency_senders: '070-7475-6052/6054',
      },
    },

    button: {
      cancel: '취소', // 해당 프로세스를 중단하는 경우
      prev: '이전', // 전 단계로/이전 페이지로 이동하는 경우
      next: '다음', // 해당 액션에 다음 단계가 존재하는 경우 (최종 단계 이전까지)
      save: '저장', // 값을 신규 등록하는 최종단계인 경우 or 수정 상세페이지에서 수정 후 저장하려는 경우
      modify: '수정', // 페이지에서 인풋박스로 바로 저장된 값을 수정하는 경우
      confirm: '확인', // 정보 안내 혹은 액션 재확인이 필요한 경우 or 삭제, 요청 등의 액션에 동의하는 경우
    },
  },

  // 화면 단위
  container: {},

  // 팝업
  popup: {
    // 광고캐시 관리
    cash_management: {
      charge: {
        suggestion:
          '타인의 광고비를 대신 결제하는 경우, 금전적 피해가 발생할 수 있으니 주의하시기 바랍니다.',
        call_center: '고객센터 : 1599-5533',
      },
    },
  },

  toast: {
    payment_card_management: {
      success_change_payment_card: '{0}이(가) 결제카드로 변경되었습니다.',
      failure_change_payment_card: '결제카드 변경에 실패하였습니다.',
    },
  },
}

/**
 * 줄 바꿈(<br />) 을 포함했을 경우 react.element array 리턴
 * 단일 string 일 경우 string 리턴
 *
 * R('가나다라마바\n사아자\n차타카파하') -> ["가나다라마바", <br />, "사아자", <br />, "차타카파하"]
 * R('가{0} 나{1} {2} {3}', 'a', 'b', 'c' 'd') -> '가a 나b c d'
 * R('가{0} 나{1}', 'a', 'b', 'c', 'd') -> '가a 나b'
 * R('가{1} 나다라', 'a', 'b', 'c') -> '가b 나다라'
 *
 * @param string
 * @param params
 * @return {any}
 * @constructor
 */
export const R = (string, ...params) => {
  // placeholder 대응 파라미터가 있는 경우 매핑.
  const mapped = Array.isArray(params)
    ? params.reduce((prev, v, i) => {
        return String(prev).replace(`{${i}}`, v)
      }, string)
    : string

  // 남아있는 placeholder 제거.
  const filtered = String(mapped).replace(/{\d+}/g, '').split('\n')

  const array = filtered.reduce((prev, v, i) => {
    return i === filtered.length - 1
      ? prev.concat(v)
      : prev.concat(v).concat(<br />)
  }, [])

  return array.length > 1
    ? array.map((v, i) => <Fragment key={i}>{v}</Fragment>)
    : array.toString()
}
