import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from '../modules'
import api from '../modules-api'
import { crashReportMiddleware } from './middleware/crashReportMiddleware'
import validateActionMiddleware from './middleware/validateActionMiddleware'
import routerMiddleware from './middleware/routerMiddleware'
import { changeRouterLocation } from '../modules/routerV2/mRouterV2'

export default function configureStore(initialState, history) {
  const store = createStore(
    rootReducer(history),
    initialState,
    applyMiddleware(
      routerMiddleware(history),
      thunk.withExtraArgument(api),
      crashReportMiddleware,
      validateActionMiddleware
    )
  )

  // Initial route dispatch
  const { location, action } = history
  store.dispatch(changeRouterLocation(location, action))

  return store
}
