import React from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'
import PopupHOC from '../../../Popup/PopupHOC'
import BizBoardBannerButtonGroup from './BizBoardBannerButtonGroup'
import BizBoardBannerForm from './BizBoardBannerForm'
import BizBoardBannerPreview from './BizBoardBannerPreview'
import {
  checkEmpty,
  checkNoneEmpty,
  checkNotEmpty,
} from '../../../../utils/regexUtils'
import BizBoardImageObject from '../../../../assets/img/bnrimg_object.png'
import BizBoardImageThumbnail from '../../../../assets/img/bnrimg_thumb.png'
import BizBoardImageMasking from '../../../../assets/img/bnrimg_masking.png'
import BizBoardImageText from '../../../../assets/img/bnrimg_text.png'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import axios from 'axios'
import {
  showErrorMessage,
  showSuccessMessage,
} from '../../../../utils/alertUtils'
import { useDebounce } from 'react-use'
import {
  BIZ_BOARD_APP_DOWNLOAD_TYPE,
  BIZ_BOARD_BADGE_TYPE,
  BIZ_BOARD_BANNER_TYPE,
  BIZ_BOARD_COPY_TYPE,
  BIZ_BOARD_FORM_VALIDATION_KEY,
  BIZ_BOARD_MASK_TYPE,
  BizBoardFormUtil,
  BizBoardTemplateUtil,
} from './bizBoardUtils'
import moment from 'moment'
import UserAgent from 'fbjs/lib/UserAgent'
import {
  CREATIVE_CREATE_IMAGE_MAX,
  DEPRECATED_BIZ_BOARD_SPEC,
} from '../../../../utils/advertise/creativeImage'
import {
  openPopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../../../../modules/common/mPopup'
import { exceedCreativeImageCountDialog } from '../../Common/AdvertiseDialog'
import { hideLoading, showLoading } from '../../../../modules/common/mLoading'
import {
  clearBizBoardCreate,
  initBizBoardCreateByExpressMetaInfo,
  initBizBoardCreateImageData,
} from '../../../../modules/advertise/mBizBoardImageEditor'
import PopupProxy from '../../../Popup/Common/PopupProxy'
import DebugTooltip from '../../../Tooltip/TooltipImpl/DebugTooltip'
import { BizBoardEditorAPI } from '../../../../modules-api/advertise/bizBoardEditorApi'
import { fromJS } from 'immutable'

const BIZ_BOARD_BANNER_OPTIONS = [
  {
    id: BIZ_BOARD_BANNER_TYPE.OBJECT,
    label: '오브젝트형',
    imageSrc: BizBoardImageObject,
  },
  {
    id: BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
    label: '썸네일형',
    imageSrc: BizBoardImageThumbnail,
  },
  {
    id: BIZ_BOARD_BANNER_TYPE.MASKING,
    label: '마스킹형',
    imageSrc: BizBoardImageMasking,
  },
  {
    id: BIZ_BOARD_BANNER_TYPE.TEXT,
    label: '텍스트형',
    imageSrc: BizBoardImageText,
  },
]

let requestTimer = null
const requestTimeout = 2000 // 마지막 입력 2sec 이후 loading

const selector = ({
  creativeV2: {
    bizBoardCreateBundle: { bizBoardBundles },
    bizBoardImageEditor: { imageRects, imageScales },
    modify: {
      creativeForm: { width, height, boardType: modifyBoardType },
    },
    create: {
      creativeForm: { boardType: createBoardType },
    },
  },
}) => {
  return {
    remainingCount:
      CREATIVE_CREATE_IMAGE_MAX.BIZ_BOARD - bizBoardBundles.count(),
    imageRects,
    imageScales,
    width,
    height,
    boardType: modifyBoardType || createBoardType,
  }
}
// isOnlyObjectBannerType : isMotionBoard || isCustomBoard || isBizBoardNativeServing
const BizBoardBanner = ({
  adAccountId,
  expressMetaInfo = {},
  onSave,
  continuable = false,
  isOnlyObjectBannerType = false,
  close,
}) => {
  const dispatch = useDispatch()
  const { remainingCount, imageRects, imageScales, width, height, boardType } =
    useSelector(selector, shallowEqual)

  // 비즈보드 배너 이미지 수정 mode
  const editMode = checkNoneEmpty(
    expressMetaInfo?.templateId,
    expressMetaInfo?.expressMetaInfoAsset
  )

  const isPastSize =
    width === DEPRECATED_BIZ_BOARD_SPEC.WIDTH &&
    height === DEPRECATED_BIZ_BOARD_SPEC.HEIGHT

  const isPastSizeModify = editMode && isPastSize

  // {TEMPLATE_CODE} ex: 'M01210'
  const [templateId, setTemplateId] = React.useState(
    expressMetaInfo?.templateId
  )

  const [formData, setFormData] = React.useState(
    BizBoardFormUtil.initialize({ expressMetaInfo, boardType })
  )

  const [templateData, setTemplateData] = React.useState(
    BizBoardTemplateUtil.initialize({
      templateId,
    })
  )

  const [base64PreviewImage, setBase64PreviewImage] = React.useState(null)

  // validation
  const [validationState, setValidationState] = React.useState({})

  // API
  const cancelTokenSource = React.useRef(axios.CancelToken.source())

  // state
  const [isPending, setIsPending] = React.useState(false) // last request 이후 2초 경과 시(no response received) true
  const [isImageProcessing, setIsImageProcessing] = React.useState(false) // request -> response 후 object image 가 set state 되기 전 까지의 단계
  const [shouldImageCenterCropping, setShouldImageCenterCropping] =
    React.useState(false)

  React.useEffect(() => {
    return () => {
      dispatch(clearBizBoardCreate())
    }
  }, [dispatch])

  /**
   * 최초 진입 시 editMode 일 경우
   * expressMetaInfo 를 기반으로 redux state 업데이트 (image edit 좌표, 스케일 등)
   */
  React.useEffect(() => {
    if (editMode) {
      dispatch(initBizBoardCreateByExpressMetaInfo(expressMetaInfo))
      setTemplateData(prev => ({ ...prev, imageIndex: -1 }))
    }
  }, [dispatch, editMode, expressMetaInfo])

  React.useEffect(() => {
    if (editMode && BizBoardFormUtil.isNotSupportedMaskType({ templateData })) {
      showErrorMessage('지원 종료된 배너 이미지 만들기 유형입니다.')
    }
  }, [editMode, templateData])

  /**
   * templateData 변경 시 templateId 갱신.
   */
  React.useEffect(() => {
    const templateId = BizBoardTemplateUtil.create(templateData)

    if (checkNotEmpty(templateId)) {
      setTemplateId(templateId)
    }
  }, [templateData])

  /**
   * 오브제, 앱 아이콘 이미지 onChange 시 isImageProcessing -> true 로 강제 전환.
   * fetchPreview callback 이 debounce(500ms) 적용되어 setIsImageProcessing 이 지연 동작됨.
   * - 저장, 계속 만들기 button 비활성.
   * - fetchPreview request 완료 후 활성 전환.
   */
  React.useEffect(() => {
    setIsImageProcessing(true)
  }, [formData.images, formData.icon])

  React.useEffect(() => {
    if (validationState[BIZ_BOARD_FORM_VALIDATION_KEY.MASK_TYPE]) {
      showErrorMessage(
        validationState[BIZ_BOARD_FORM_VALIDATION_KEY.MASK_TYPE].message
      )
    }
  }, [validationState])

  React.useEffect(() => {
    if (!editMode) {
      if (
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT ||
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL ||
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING
      ) {
        handleFormChange('images', [])
        dispatch(initBizBoardCreateImageData())
      }

      if (
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.TEXT ||
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING ||
        (templateData.bannerType === BIZ_BOARD_BANNER_TYPE.OBJECT &&
          formData.badge === BIZ_BOARD_BADGE_TYPE.BELT)
      ) {
        handleFormChange('badge', null)
        handleFormChange('badgeColor', '')
        handleFormChange('badgeDescription', '')
        handleFormChange('badgeDescription2', '')
      }

      const maskType =
        templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL
          ? BIZ_BOARD_MASK_TYPE.SQUARE
          : templateData.bannerType === BIZ_BOARD_BANNER_TYPE.MASKING
          ? BIZ_BOARD_MASK_TYPE.SEMICIRCLE_TOP
          : BIZ_BOARD_MASK_TYPE.NONE

      handleTemplateChange('maskType', maskType)

      if (templateData.bannerType === BIZ_BOARD_BANNER_TYPE.TEXT) {
        handleTemplateChange(
          'appDownloadType',
          BIZ_BOARD_APP_DOWNLOAD_TYPE.NONE
        )
      }
    }
  }, [templateData.bannerType])

  // 마스크 유형 변경 시 imageTypeArray, validationState 초기화
  React.useEffect(() => {
    setTemplateData(prev => ({
      ...prev,
      imageTypeArray: BizBoardTemplateUtil.getImageTypeArray(
        templateData.bannerType,
        templateData.maskType
      ),
    }))
    setValidationState({})
  }, [templateData.bannerType, templateData.maskType])

  React.useEffect(() => {
    setTemplateData(prev => ({
      ...prev,
      copyType:
        formData.description?.length > 0
          ? BIZ_BOARD_COPY_TYPE.MULTI
          : BIZ_BOARD_COPY_TYPE.SINGLE,
    }))
  }, [formData.description])

  /**
   * 배너 이미지 갱신 요청
   * - debounced 500ms
   */
  const fetchPreview = React.useCallback(async () => {
    if (checkEmpty(templateId)) return

    setIsImageProcessing(true)

    try {
      cancelTokenSource.current?.cancel()

      // 기존 타이머 제거
      clearTimeout(requestTimer)

      // 요청 후 2초 이후부터 로딩 시작
      requestTimer = setTimeout(() => {
        setIsPending(true)
      }, requestTimeout)

      // 중복 요청 cancel 처리
      cancelTokenSource.current = axios.CancelToken.source()

      const API = [
        BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
        BIZ_BOARD_BANNER_TYPE.MASKING,
      ].includes(templateData.bannerType)
        ? BizBoardEditorAPI.getMaskedBizBoardBannerImageBase64
        : BizBoardEditorAPI.getBizBoardBannerImageBase64

      const {
        data: { banner },
      } = await API({
        requestBody: BizBoardFormUtil.toPreviewAPI({
          templateId,
          formData,
          templateData,
          imageRects,
        }),
        cancelTokenSource: cancelTokenSource.current,
        preventDuplicateRequest: false,
      })

      setBase64PreviewImage(banner)

      setIsPending(false)

      clearTimeout(requestTimer)

      setIsImageProcessing(false)
    } catch (e) {
      if (!axios.isCancel(e)) {
        showErrorMessage(
          e?.response?.message || '이미지 갱신에 실패하였습니다.'
        )

        setIsPending(false)
        clearTimeout(requestTimer)

        // cancel 이 아닐 경우에만!
        setIsImageProcessing(false)
      }
    }
  }, [templateId, templateData, formData, imageRects]) // do not include `imageRects`

  const handleDownload = React.useCallback(async () => {
    try {
      const response = await BizBoardEditorAPI.getBizBoardBannerImageBase64({
        requestBody: BizBoardFormUtil.toBannerAPI({
          templateId,
          formData,
          templateData,
          imageRects,
        }),
        cancelTokenSource: cancelTokenSource.current,
      })

      const base64Image = response.data.banner
      const fileName = moment().format('YYYYMMDDHHmmss')

      if (
        UserAgent.isBrowser('IE') &&
        typeof window.navigator.msSaveBlob === 'function'
      ) {
        const decodedString = atob(String(base64Image)) // decode base64
        const buffer = new ArrayBuffer(decodedString.length) // create buffer
        const view = new Uint8Array(buffer) // 8bit Unsigned Int -> buffer

        for (let i = 0; i < decodedString.length; i++) {
          view[i] = decodedString.charCodeAt(i) & 0xff // for msb
        }

        const blobImage = new Blob([view], { type: 'image/png' }) // or application/octet-stream

        window.navigator.msSaveBlob(blobImage, `${fileName}.png`)
      } else {
        const el = document.createElement('a')
        el.style.display = 'none'

        document.body.appendChild(el)

        el.href = `data:image/png;base64,${base64Image}`
        el.download = fileName

        el.click()

        document.body.removeChild(el)
      }
    } catch (e) {
      console.log(e.message)
    }
  }, [templateId, formData, templateData, imageRects])

  // debounce fetch
  const [isReadyDebouncedFetch] = useDebounce(
    () => {
      fetchPreview()
    },
    500,
    [fetchPreview]
  )

  // 이미지 프로세싱 중이거나 fetch 가능한 상태가 아닐 경우
  const isLoading = isImageProcessing || !isReadyDebouncedFetch()

  const isAvailableDownload = React.useMemo(
    () => BizBoardFormUtil.isCompleted({ formData, templateData }),
    [formData, templateData]
  )

  const handleFormChange = React.useCallback((key, value) => {
    /**
     * 이미지를 명시적으로 업로드 or 가져오기 했을 경우에만 center crop 을 동작 가능하도록 변경한다.
     * editMode 진입 시에는 동작하지 않는다.
     */
    if (key === 'images') {
      setShouldImageCenterCropping(true)
    }

    setFormData(prev => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  const handleTemplateChange = React.useCallback((key, value) => {
    setTemplateData(prev => ({
      ...prev,
      [key]: value,
    }))
  }, [])

  const onMaskTypeChange = React.useCallback(
    maskType => {
      handleTemplateChange('maskType', maskType)
      dispatch(initBizBoardCreateImageData())

      if (templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL) {
        handleFormChange('images', [])
      }

      switch (maskType) {
        case BIZ_BOARD_MASK_TYPE.BLUR:
        case BIZ_BOARD_MASK_TYPE.MULTI_IMAGE:
          if (formData.badge === BIZ_BOARD_BADGE_TYPE.BELT) {
            handleFormChange('badge', null)
            handleFormChange('badgeColor', '')
            handleFormChange('badgeDescription', '')
            handleFormChange('badgeDescription2', '')
          }
          break
        default:
          break
      }
    },
    [
      dispatch,
      formData.badge,
      handleFormChange,
      handleTemplateChange,
      templateData.bannerType,
    ]
  )

  const handleSave = React.useCallback(
    async (autoClose = false) => {
      if (isLoading) {
        showErrorMessage('이미지 처리 중입니다. 잠시 후 다시 시도하세요.')
        return
      }

      try {
        dispatch(showLoading())
        const {
          data: { banner, invalidations },
        } = await BizBoardEditorAPI.getBizBoardBannerImageBase64({
          requestBody: BizBoardFormUtil.toBannerAPI({
            templateId,
            formData,
            templateData,
            imageRects,
          }),
          cancelTokenSource: cancelTokenSource.current,
        })

        const base64Image = banner
        dispatch(hideLoading())

        const { isValid, validationState } = BizBoardFormUtil.validate({
          formData,
          templateData,
          invalidations,
        })
        setValidationState(validationState)

        if (isValid) {
          if (
            editMode &&
            BizBoardFormUtil.isNotSupportedMaskType({ templateData })
          ) {
            showErrorMessage('지원 종료된 배너 이미지 만들기 유형입니다.')
            return
          }

          const failureImages = BizBoardFormUtil.getFailureImages({
            formData,
            templateData,
          })

          if (failureImages.length > 0) {
            dispatch(
              openPopup(POPUP_KEY.MULTI_IMAGE_UPLOAD_FAILURE_SUMMARY, {
                summary: fromJS(failureImages),
              })
            )
            return
          }

          if (remainingCount > 0) {
            const expressMetaInfo = BizBoardFormUtil.toExpressMetaInfo({
              templateId,
              formData,
              templateData,
              imageRects,
              imageScales,
            })

            const onSuccess = message => {
              showSuccessMessage(
                message || '배너 이미지 등록이 완료되었습니다.'
              )
              autoClose && close()
            }

            const onFailure = message => {
              showErrorMessage(message || '배너 이미지 등록에 실패하였습니다.')
            }

            onSave({
              base64Image,
              expressMetaInfo,
              originalFileName: formData.images?.[0]?.originalFileName,
              bizBoardNativeImage: formData.images?.[0],
              onSuccess,
              onFailure,
            })
          } else {
            dispatch(
              openPopupByProxy(
                POPUP_KEY.SIMPLE_POPUP,
                exceedCreativeImageCountDialog(
                  CREATIVE_CREATE_IMAGE_MAX.BIZ_BOARD
                )
              )
            )
          }
        }
      } catch (e) {
        showErrorMessage(e.message)
        dispatch(hideLoading())
      }
    },
    [
      close,
      dispatch,
      editMode,
      formData,
      imageRects,
      imageScales,
      isLoading,
      onSave,
      remainingCount,
      templateData,
      templateId,
    ]
  )

  const handleClose = React.useCallback(() => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy primaryButtonFunc={close} hasSecondaryButton={true}>
          <strong className="tit_layer">배너 이미지 만들기 취소</strong>
          <p className="txt_layer">
            배너 만들기를 취소하시겠습니까?
            <br />
            저장되지 않은 모든 내용은 사라집니다.
          </p>
        </PopupProxy>
      )
    )
  }, [close, dispatch])

  const handleBannerTypeChange = React.useCallback(
    bannerType => () => {
      const onOK = () => {
        handleTemplateChange('bannerType', bannerType)
        handleTemplateChange('imageIndex', -1)
        setValidationState({}) // 배너유형 변경시 validation 초기화
      }

      if (
        checkNotEmpty(templateData.bannerType) &&
        templateData.bannerType !== bannerType
      ) {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy primaryButtonFunc={onOK} hasSecondaryButton={true}>
              <strong className="tit_layer">배너 유형 변경</strong>
              <p className="txt_layer">
                유형 선택 변경 시, 기존에 등록된 정보가 일부 삭제됩니다.
                <br />
                변경하시겠습니까?
              </p>
            </PopupProxy>
          )
        )
      } else {
        onOK()
      }
    },
    [dispatch, handleTemplateChange, templateData.bannerType]
  )

  const isBannerTypeSelected = checkNotEmpty(templateData.bannerType)

  return (
    <div className="inner_basic_layer">
      <div className="layer_head">
        <strong className="tit_layer">
          배너 이미지 만들기
          <DebugTooltip
            maxWidth={600}
            content={
              <>
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  FormData
                </span>
                <br />
                <p>{JSON.stringify(formData)}</p>
                <span style={{ fontWeight: 'bold', color: 'red' }}>
                  TemplateData
                </span>
                <br />
                <p>{JSON.stringify(templateData)}</p>
              </>
            }
          />
        </strong>
      </div>
      <div className="layer_body">
        <em className="screen_out">배너 이미지 만들기 유형 선택 탭</em>
        <ul className="radio_bnrimg" role="tablist">
          {BIZ_BOARD_BANNER_OPTIONS.map(v => {
            const { id, label, imageSrc } = v
            const inputId = `inputRadio|bizBoardBanner|type|${id}`
            const isSelected = templateData.bannerType === id
            const active = editMode ? templateData.bannerType === id : true

            return (
              active && (
                <li key={id} role="presentation">
                  <span
                    className={cx('box_radioinp', {
                      on: isSelected,
                      in_active:
                        !active ||
                        (isOnlyObjectBannerType &&
                          [
                            BIZ_BOARD_BANNER_TYPE.THUMBNAIL,
                            BIZ_BOARD_BANNER_TYPE.MASKING,
                            BIZ_BOARD_BANNER_TYPE.TEXT,
                          ].includes(id)),
                    })}>
                    <input
                      type="radio"
                      name="inpArea"
                      id={inputId}
                      className="inp_radio"
                      checked={isSelected}
                      onChange={handleBannerTypeChange(id)}
                    />
                    <label htmlFor={inputId} className="lab_radio">
                      <span className="img_comm ico_radio" />
                      {label}
                      <span
                        className="img_bnrimg"
                        style={{
                          backgroundImage: `url(${imageSrc})`,
                        }}
                      />
                    </label>
                  </span>
                </li>
              )
            )
          })}
        </ul>
        {isBannerTypeSelected && (
          <div className="wrap_bnrimg" style={{ display: 'flex' }}>
            <BizBoardBannerForm
              adAccountId={adAccountId}
              formData={formData}
              templateData={templateData}
              handleFormChange={handleFormChange}
              handleTemplateChange={handleTemplateChange}
              setValidationState={setValidationState}
              validationState={validationState}
            />
            <BizBoardBannerPreview
              adAccountId={adAccountId}
              shouldImageCenterCropping={shouldImageCenterCropping}
              base64PreviewImage={base64PreviewImage}
              objetImages={formData.images}
              formData={formData}
              templateData={templateData}
              validationState={validationState}
              isLoading={isLoading}
              isAvailableDownload={isAvailableDownload}
              isPending={isPending}
              isPastSizeModify={isPastSizeModify}
              handleDownload={handleDownload}
              handleFormChange={handleFormChange}
              handleTemplateChange={handleTemplateChange}
              onMaskTypeChange={onMaskTypeChange}
            />
          </div>
        )}
      </div>
      <BizBoardBannerButtonGroup
        isVisibleButtonGroup={isBannerTypeSelected}
        handleSave={handleSave}
        handleClose={handleClose}
        continuable={continuable}
      />
    </div>
  )
}

BizBoardBanner.propTypes = {
  adAccountId: PropTypes.any,
  expressMetaInfo: PropTypes.object,
  onSave: PropTypes.func,
  continuable: PropTypes.bool,
  close: PropTypes.func,
  isOnlyObjectBannerType: PropTypes.bool,
}

export default PopupHOC(BizBoardBanner, {
  subClassName: 'bnrimg_layer',
})
