import CreativeUploadAssetPropertyEnum from '../../../../enums/CreativeUploadAssetPropertyEnum'
import {
  BIZ_BOARD_ICON_SPEC,
  BIZ_BOARD_OBJET_SPEC,
  CREATIVE_IMAGE_PROFILE_SPEC,
  getCalculatedMinHeightByMinWidthAndRatio,
  isCreativeRatioMatched,
} from '../../../../utils/advertise/creativeImage'
import CreativeFormatEnum from '../../../../enums/CreativeFormatEnum'
import { CreativeAssetUtils } from '../creativeAssetUtils'
import { List } from 'immutable'

/**
 * @deprecated
 *
 * @param assetList {Immutable.List}
 * @param creativeUploadAssetPropertyType {CreativeUploadAssetPropertyEnum.Type}
 * @param sizeConstraintArray {{ width: number, height: number, minWidth: number, ratio: number }[]}
 * @param creativeFormat {CreativeFormatEnum.Type}
 * @returns {Immutable.List}
 */
const filterCreativeLibraryUploadAssets = ({
  assetList = List(),
  creativeUploadAssetPropertyType,
  sizeConstraintArray = [],
  creativeFormat,
}) => {
  switch (creativeUploadAssetPropertyType) {
    case CreativeUploadAssetPropertyEnum.Type.PROFILE_IMAGE: {
      return assetList.filter(({ imageWidth, imageHeight }) => {
        const minWidthMatched = imageWidth >= CREATIVE_IMAGE_PROFILE_SPEC.WIDTH
        const minHeightMatched =
          imageHeight >= CREATIVE_IMAGE_PROFILE_SPEC.HEIGHT

        return minWidthMatched && minHeightMatched
      })
    }

    case CreativeUploadAssetPropertyEnum.Type.IMAGE:
    case CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_IMAGE:
    case CreativeUploadAssetPropertyEnum.Type.EXPANDABLE_MULTI: {
      return sizeConstraintArray.length > 0
        ? assetList.filter(({ imageWidth, imageHeight }) =>
            sizeConstraintArray.some(sizeConstraint => {
              let matched = false

              if (sizeConstraint.width > 0 && sizeConstraint.height > 0) {
                matched =
                  sizeConstraint.width === imageWidth &&
                  sizeConstraint.height === imageHeight
              } else if (
                sizeConstraint.minWidth > 0 &&
                sizeConstraint.ratio > 0
              ) {
                const minWidthMatched = imageWidth >= sizeConstraint.minWidth

                const exactRatio = CreativeFormatEnum.isVideo(creativeFormat)

                // 비디오 썸네일의 경우 crop 을 제공하지 않아 exact 매칭 필요함.
                if (exactRatio) {
                  const ratioMatched = isCreativeRatioMatched({
                    width: imageWidth,
                    height: imageHeight,
                    expectRatio: sizeConstraint.ratio,
                  })

                  matched = minWidthMatched && ratioMatched
                } else {
                  const minHeightMatched =
                    imageHeight >=
                    getCalculatedMinHeightByMinWidthAndRatio({
                      minWidth: sizeConstraint.minWidth,
                      ratio: sizeConstraint.ratio,
                    })

                  matched = minWidthMatched && minHeightMatched
                }
              }

              return matched
            })
          )
        : assetList
    }

    case CreativeUploadAssetPropertyEnum.Type.OBJET_IMAGE: {
      return assetList.filter(
        ({ imageWidth, imageHeight }) =>
          imageWidth === BIZ_BOARD_OBJET_SPEC.WIDTH &&
          imageHeight === BIZ_BOARD_OBJET_SPEC.HEIGHT
      )
    }

    case CreativeUploadAssetPropertyEnum.Type.ICON_IMAGE: {
      return assetList.filter(
        ({ imageWidth, imageHeight }) =>
          imageWidth === BIZ_BOARD_ICON_SPEC.WIDTH &&
          imageHeight === BIZ_BOARD_ICON_SPEC.HEIGHT
      )
    }

    case CreativeUploadAssetPropertyEnum.Type.VIDEO: {
      return assetList.filter(
        ({ mimeType, width, height }) =>
          CreativeAssetUtils.Video.allowedMimeTypes.includes(mimeType) &&
          sizeConstraintArray.length > 0 &&
          sizeConstraintArray.some(sizeConstraint => {
            let matched = false

            if (sizeConstraint.width > 0 && sizeConstraint.height > 0) {
              matched =
                sizeConstraint.width === width &&
                sizeConstraint.height === height
            } else if (
              sizeConstraint.minWidth > 0 &&
              sizeConstraint.ratio > 0
            ) {
              const minWidthMatched = width >= sizeConstraint.minWidth
              const ratioMatched = isCreativeRatioMatched({
                width,
                height,
                expectRatio: sizeConstraint.ratio,
              })

              matched = minWidthMatched && ratioMatched
            }

            return matched
          })
      )
    }

    default:
      return assetList
  }
}

export { filterCreativeLibraryUploadAssets }
