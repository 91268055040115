import React, { useCallback } from 'react'
import FloatingViewHOC from '../../Common/HOC/FloatingViewHOC'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { dateToString } from '../../../utils/utils'
import {
  toLoungeBoardNoticeDetailPath,
  toNoticeBoardPath,
} from '../../../utils/router/routeUtils'
import { useHistory } from 'react-router-dom'
import { receiveHasNewNotice } from '../../../modules/notice/mNotice'
import userApi from '../../../modules-api/common/userApi'
import moment from 'moment'
import PropTypes from 'prop-types'

const selector = ({
  user: {
    userInfo: { id },
  },
  notice: {
    hasNewNotice,
    noticeList: { list },
  },
}) => {
  return {
    hasNewNotice,
    notices: list,
    dspAccountId: id,
  }
}

const LoungeBoardNotice = ({ isVisible, dismiss }) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { hasNewNotice, notices, dspAccountId } = useSelector(
    selector,
    shallowEqual
  )

  const handleClick = async () => {
    if (isVisible && dspAccountId) {
      try {
        // 공지사항 클릭 후 날짜 preference에 저장
        await userApi.updateDspAccountPreference(dspAccountId, {
          lastDate: moment().format('YYYY-MM-DDTHH:mm:ss'),
        })

        if (hasNewNotice) {
          dispatch(receiveHasNewNotice(false))
        }
      } catch (e) {
        console.log(e)
      }
    }
  }

  const handleClickItems = useCallback(
    path => () => {
      history.push(path)
      dismiss()
    },
    [dismiss, history]
  )

  return (
    <li className={cx({ on: isVisible })}>
      <a className="link_gnb" onClick={handleClick}>
        공지사항{hasNewNotice && <span className="ico_gnb ico_new">new</span>}
        <span className="screen_out">펼침</span>
      </a>
      {isVisible && (
        <div className="layer_service">
          <div className="layer_head">
            <strong className="tit_service">공지사항</strong>
          </div>
          <div className="layer_body">
            {notices?.isEmpty() ? (
              <p className="txt_nodata">등록된 공지사항이 없습니다.</p>
            ) : (
              <div className="fake_scroll">
                <ul className="list_noti">
                  {notices?.map(
                    ({
                      id,
                      createdAt,
                      title,
                      pin,
                      isNew,
                      isTargeted,
                      categoryName,
                    }) => {
                      return (
                        <li
                          key={id}
                          className={cx({
                            new_on: isNew,
                            noti_on: pin,
                          })}>
                          <a
                            href={toLoungeBoardNoticeDetailPath(id)}
                            rel="noopener noreferrer"
                            target="_blank"
                            className="link_noti">
                            {isTargeted && (
                              <em className="fc_emph">추천알림</em>
                            )}
                            {pin && (
                              <span className="txt_noti">[중요공지]</span>
                            )}
                            <span className="txt_cate">[{categoryName}]</span>
                            <span className="desc_noti">
                              {title}
                              {isNew && (
                                <span className="ico_gnb ico_new">new</span>
                              )}
                            </span>
                            <span className="txt_date">
                              {dateToString(createdAt)}
                            </span>
                          </a>
                        </li>
                      )
                    }
                  )}
                </ul>
              </div>
            )}
            <a
              onClick={handleClickItems(toNoticeBoardPath())}
              className="link_allview">
              전체보기
            </a>
          </div>
        </div>
      )}
    </li>
  )
}

LoungeBoardNotice.propTypes = {
  isVisible: PropTypes.bool,
  dismiss: PropTypes.func,
}

export default FloatingViewHOC(LoungeBoardNotice)
