import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { showErrorMessage } from '../../utils/alertUtils'
import * as Sentry from '@sentry/browser'
import GoalEnum from '../../enums/GoalEnum'
import CampaignTypeEnum from '../../enums/CampaignTypeEnum'
import { CmpAPI } from '../../modules-api/advertise/cmpApi'
import { ERROR_CODE } from '../../utils/errorCode'
import ContractApi from '../../modules-api/advertise/contractApi'
import { setContractProductInfo } from './mContract'

const AdConstraints = keyMirror(
  {
    GET_AD_ACCOUNT_CONSTRAINTS: null,
    GET_CAMPAIGN_CONSTRAINTS: null,
    GET_AD_GROUP_CONSTRAINTS: null,
    GET_CREATIVE_CONSTRAINTS: null,

    // cmp
    GET_CREATIVE_ASSET_PROPERTY_TYPES: null,
    GET_CREATIVE_ASSET_CONSTRAINTS: null,

    // TargetCPA, TargetCPC Recommended Bid Amount
    GET_AD_GROUP_RECOMMENDED_BID_AMOUNT: null,
    CLEAR_AD_GROUP_RECOMMENDED_BID_AMOUNT: null,
  },
  'AD_CONSTRAINTS'
)

const initialState = fromJS({
  /**
   * [
   *  {
   *    id: number,
   *    campaignType: CampaignTypeEnum.Type,
   *    goal: GoalEnum.Type,
   *  }
   * ]
   */
  campaignTypeGoals: [],
  /**
   * CampaignTypeEnum.Type[]
   */
  campaignTypes: [],
  /**
   * GoalEnum.Type[]
   */
  goals: [],
  /**
   * 캠페인 대시보드에서 필수 로드(summary)
   *
   * {
   *     id,
   *     campaignType,
   *     goal,
   *     campaignDailyBudgetConstraint: {
   *         max: number,
   *         min: number,
   *         guide: number
   *     },
   *     trackConstraints: [{ available, required, objectiveType }],
   *     objectiveTypeConstraints: []
   * }
   */
  campaignConstraints: {},
  /**
   * 캠페인/광고그룹 대시보드에서 필수 로드(summary)
   *
   * {
   *     id,
   *     campaignType,
   *     goal,
   *     targetingTypes: AdGroupTargetEnum.Type[],
   *     devicePlacementConstraints: ,
   *     campaignDailyBudgetConstraint: {
   *         max: number,
   *         min: number,
   *         guide: number
   *     },
   *     pricingTypeConstraints: [{
   *         pricingType: string,
   *         priceConstraints: array
   *     }],
   *     bidConstraints: [{
   *         bidStrategy: string,
   *         pacing: array,
   *         creativeOptimizationConstraint: {
   *             available: bool,
   *             required: bool
   *         }
   *     }],
   *     adGroupDailyBudgetConstraint: {
   *         max: number,
   *         min: number,
   *         guide: number
   *     }
   * }
   */
  adGroupConstraints: {},
  /**
   * {
   *  campaignType: CampaignTypeEnum.Type,
   *  goal: GoalEnum.Type,
   *  creativeFormats: CreativeFormatEnum.Type[],
   *  imageSizes: {
   *   [CreativeFormatEnum.Type]: [
   *    {
   *      width: number,
   *      height: number,
   *      minWidth: number,
   *      templateType: string,
   *      placements: PlacementEnum.Type[]
   *    }
   *   ]
   *  }
   * }
   */
  creativeConstraints: {},

  // -- cmp
  /**
   * [
   *    {
   *        creativeFormat: CreativeFormatEnum.Type,
   *        assetPropertyConstraints: [
   *          {
   *            assetProperty: CreativeAssetPropertyEnum.Type,
   *            constraint: {
   *              cmpAssetProperty: {
   *                assetType: CMP_ASSET_TYPE,
   *                subAssetTypes: CMP_SUB_ASSET_TYPE[]
   *              },
   *              saveConstraint: {
   *                formats: [
   *                  {
   *                    name: 'PNG-24', // JPG, JPEG, PNG
   *                    extension: 'png',
   *                    mineType: 'image/png',  // image/jpeg..
   *                    png24: true,
   *                  }
   *                ],
   *                mimeTypeGuideMessage: 'AVI, FLV, MP4 권장',
   *                videoDuration: {
   *                  min: 1,
   *                  max: 10,
   *                  eq: 1,
   *                },
   *                fileSize: {
   *                  min: 307200,
   *                  max: 307200,
   *                  eq: 307200
   *                },
   *                sizeConstraints: [
   *                  {
   *                    width: {
   *                      min: number,
   *                      max: number,
   *                      eq: number
   *                    },
   *                    height: {
   *                      min: number,
   *                      max: number,
   *                      eq: number
   *                    },
   *                    ratio: {
   *                      min: 1.7777,  // 소수점 4자리 이하 버림
   *                      max: 2,
   *                      eq: 2,
   *                      description: '16:9'
   *                    }
   *                  }
   *                ],
   *                recommendSizeConstraints: [] // sizeConstraints 와 동일
   *              },
   *              uploadConstraint: // saveConstraint 와 동일
   *            }
   *          }
   *        ]
   *      }
   *   ]
   */
  creativeAssetConstraints: [],

  // -- TargetCPC, TargetCPA Recommend BidAmount
  /**
   * {
   *     recommendBudget,
   *     recommendValue,
   *     recommendValueLB,
   *     recommendValueUB,
   * }
   */
  adGroupRecommendedBidAmount: null,
})

export default createReducer(initialState, {
  [AdConstraints.GET_AD_ACCOUNT_CONSTRAINTS]: (state, { data }) => {
    const campaignTypeSequence = CampaignTypeEnum.orderedValues()
    const goalSequence = GoalEnum.orderedValues()

    const campaignTypeGoals = fromJS(data).sort(
      (
        { campaignType: campaignTypeA, goal: goalA },
        { campaignType: campaignTypeB, goal: goalB }
      ) => {
        const campaignSortResult =
          campaignTypeSequence.indexOf(campaignTypeA) -
          campaignTypeSequence.indexOf(campaignTypeB)
        return campaignSortResult === 0
          ? goalSequence.indexOf(goalA) - goalSequence.indexOf(goalB)
          : campaignSortResult
      }
    )

    return state.withMutations(s =>
      s
        .set('campaignTypeGoals', campaignTypeGoals)
        .set(
          'campaignTypes',
          campaignTypeGoals
            .groupBy(v => v.get('campaignType'))
            .map(v => v.first())
            .keySeq()
        )
        .set(
          'goals',
          campaignTypeGoals
            .groupBy(v => v.get('goal'))
            .map(v => v.first())
            .keySeq()
        )
    )
  },

  [AdConstraints.GET_CAMPAIGN_CONSTRAINTS]: (state, { data }) =>
    state.set('campaignConstraints', fromJS(data)),

  [AdConstraints.GET_AD_GROUP_CONSTRAINTS]: (state, { data }) =>
    state.set('adGroupConstraints', fromJS(data)),

  [AdConstraints.GET_CREATIVE_CONSTRAINTS]: (state, { data }) =>
    state.set('creativeConstraints', fromJS(data)),

  [AdConstraints.GET_CREATIVE_ASSET_CONSTRAINTS]: (state, { data }) =>
    state.set('creativeAssetConstraints', fromJS(data)),

  [AdConstraints.GET_AD_GROUP_RECOMMENDED_BID_AMOUNT]: (state, { data }) =>
    state.set('adGroupRecommendedBidAmount', fromJS(data)),

  [AdConstraints.CLEAR_AD_GROUP_RECOMMENDED_BID_AMOUNT]: state =>
    state.set('adGroupRecommendedBidAmount', null),
})

export function getAdAccountConstraints(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.adConstraints.getAdAccountConstraints(
        adAccountId
      )

      dispatch({
        type: AdConstraints.GET_AD_ACCOUNT_CONSTRAINTS,
        data: response.data || [],
      })
    } catch (e) {
      const errorCode = Number(e.response?.data?.errorCode)
      switch (errorCode) {
        case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
          break
        default:
          showErrorMessage(
            e.response?.data?.message ||
              '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
          )
      }
      throw e
    }
  }
}

function receiveCampaignConstraints(data) {
  return {
    type: AdConstraints.GET_CAMPAIGN_CONSTRAINTS,
    data,
  }
}

function receiveAdGroupConstraints(data) {
  return {
    type: AdConstraints.GET_AD_GROUP_CONSTRAINTS,
    data,
  }
}

export function getCampaignAndAdGroupConstraints(
  adAccountId,
  campaignTypeGoalId,
  objectiveType = null,
  objectDetailType = null
) {
  return async (dispatch, getState, api) => {
    try {
      const adAccountConstraintsResponse =
        await api.adConstraints.getAdAccountConstraints(adAccountId)

      const campaignConstraints =
        adAccountConstraintsResponse?.data?.find(
          v => v.id === campaignTypeGoalId
        ) || {}

      dispatch(receiveCampaignConstraints(campaignConstraints))

      const adGroupConstraintsResponse =
        await api.adConstraints.getAdGroupConstraints(
          adAccountId,
          campaignTypeGoalId,
          objectiveType,
          objectDetailType
        )
      const {
        data: { campaignType },
      } = adGroupConstraintsResponse

      if (CampaignTypeEnum.isContractCampaignType(campaignType)) {
        const {
          data: {
            pricingTypeConstraints: [
              {
                priceConstraints: [{ contractProductKey }],
              },
            ],
          },
        } = adGroupConstraintsResponse
        const { data: productInfo } = await ContractApi.fetchProductInfo(
          adAccountId,
          contractProductKey
        )
        dispatch(setContractProductInfo(productInfo))
      }

      dispatch(
        receiveAdGroupConstraints(adGroupConstraintsResponse?.data || {})
      )
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
      Sentry.captureException(e)
      throw e
    }
  }
}

export function getAdGroupConstraints(
  adAccountId,
  campaignTypeGoalId,
  objectiveType = null,
  objectDetailType = null
) {
  return async (dispatch, getState, api) => {
    try {
      const adGroupConstraints = await api.adConstraints.getAdGroupConstraints(
        adAccountId,
        campaignTypeGoalId,
        objectiveType,
        objectDetailType
      )

      dispatch(receiveAdGroupConstraints(adGroupConstraints?.data || {}))
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
      throw e
    }
  }
}

function receiveCreativeConstraints(data) {
  return {
    type: AdConstraints.GET_CREATIVE_CONSTRAINTS,
    data,
  }
}

export function getCreativeConstraints(
  adAccountId,
  campaignType,
  goal,
  devices,
  placements,
  pricingType
) {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.adConstraints.getCreativeConstraints(
        adAccountId,
        campaignType,
        goal,
        devices,
        placements,
        pricingType
      )

      const creativeConstraints = response.data || {}

      dispatch(receiveCreativeConstraints(creativeConstraints))
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
      throw e
    }
  }
}

export function getCreativeConstraintsByAdGroupId(adAccountId, adGroupId) {
  return async (dispatch, getState, api) => {
    try {
      const response =
        await api.adConstraints.getCreativeConstraintsByAdGroupId(
          adAccountId,
          adGroupId
        )

      const creativeConstraints = response.data || {}

      dispatch(receiveCreativeConstraints(creativeConstraints))
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
      Sentry.captureException(e)
      throw e
    }
  }
}

export function getCreativeAssetConstraints({
  adAccountId,
  adGroupId, // nullable(for message)
  creativeFormats = [],
}) {
  return async dispatch => {
    try {
      const response = await CmpAPI.getCreativeAssetConstraints({
        adAccountId,
        adGroupId,
        creativeFormats,
      })

      dispatch({
        type: AdConstraints.GET_CREATIVE_ASSET_CONSTRAINTS,
        data: response.data || [],
      })
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
      Sentry.captureException(e)
      throw e
    }
  }
}

export function getAdGroupRecommendedBidAmount({ adGroupId, adAccountId }) {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.adConstraints.getAdGroupRecommendedBidAmount(
        adGroupId,
        adAccountId
      )

      dispatch({
        type: AdConstraints.GET_AD_GROUP_RECOMMENDED_BID_AMOUNT,
        data: response?.data || null,
      })
    } catch (e) {
      showErrorMessage(
        e.response?.data?.message ||
          '시스템 오류가 발생하였습니다. 잠시 후 다시 시도하세요.'
      )
    }
  }
}

export function clearAdGroupRecommendedBidAmount() {
  return {
    type: AdConstraints.CLEAR_AD_GROUP_RECOMMENDED_BID_AMOUNT,
  }
}
