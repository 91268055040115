import React from 'react'
import PropTypes from 'prop-types'
import BizBoardBannerCopy from './Form/BizBoardBannerCopy'
import BizBoardBannerBehavior from './Form/BizBoardBannerBehavior'
import BizBoardBannerNotice from './Form/BizBoardBannerNotice'
import BizBoardBannerLogo from './Form/BizBoardBannerLogo'
import useBizBoardBannerFormAvailable from './useBizBoardBannerFormAvailable'
import { BIZ_BOARD_BANNER_TYPE, BIZ_BOARD_MASK_TYPE } from './bizBoardUtils'
import { Radio, RadioGroup } from '../../../Common/RadioGroup/RadioGroup'
import BizBoardBannerObjetImageGroup from './Form/BizBoardBannerObjetImageGroup'
import { initBizBoardCreateImageData } from '../../../../modules/advertise/mBizBoardImageEditor'
import { useDispatch } from 'react-redux'
import BizBoardBannerBadge from './Form/BizBoardBannerBadge'

/**
 * - 배너 유형 변경 시: 기 입력된 요소들 모두 유지
 * - 배지 사용 설정 / 앱다운로드 설정 해제 시: 배지 / 앱 아이콘 이미지, 앱 다운로드 카피 초기화
 *
 * - 서브카피 empty: 배지 해제
 * - 배지 선택: 서브카피 empty 시 에러, 앱다운로드 해제
 * - 앱다운로드 선택: 배지 해제, 채팅방 랜딩 고지문 해제
 * - 채팅방 랜딩 고지문 선택: 앱다운로드 선택된 경우 에러, 법적고지문 해제
 * - 법적 고지문 선택: 채팅방 랜딩 고지문 해제
 */
const BizBoardBannerForm = ({
  adAccountId,
  formData,
  templateData,
  handleFormChange,
  handleTemplateChange,
  setValidationState,
  validationState,
}) => {
  const {
    isAvailableObjetImage,
    isAvailableBehavior,
    isAvailableLogo,
    isAvailableBadge,
  } = useBizBoardBannerFormAvailable({
    templateData,
  })

  return (
    <div className="bnrimg_insert">
      <strong className="tit_layer">구성 요소</strong>
      <div className="cont_layer">
        {templateData.bannerType === BIZ_BOARD_BANNER_TYPE.THUMBNAIL && (
          <BizBoardBannerImageCountSelect
            imageCount={templateData.imageTypeArray.length}
            formData={formData}
            handleFormChange={handleFormChange}
            handleTemplateChange={handleTemplateChange}
          />
        )}
        {isAvailableObjetImage && (
          <BizBoardBannerObjetImageGroup
            adAccountId={adAccountId}
            formData={formData}
            templateData={templateData}
            validationState={validationState}
            handleFormChange={handleFormChange}
            handleTemplateChange={handleTemplateChange}
          />
        )}
        {isAvailableLogo && (
          <BizBoardBannerLogo
            adAccountId={adAccountId}
            index={1}
            formData={formData}
            templateData={templateData}
            validationState={validationState}
            handleFormChange={handleFormChange}
            handleTemplateChange={handleTemplateChange}
          />
        )}
        <BizBoardBannerCopy
          formData={formData}
          templateData={templateData}
          validationState={validationState}
          handleFormChange={handleFormChange}
          handleTemplateChange={handleTemplateChange}
        />
        {isAvailableBadge && (
          <BizBoardBannerBadge
            formData={formData}
            templateData={templateData}
            validationState={validationState}
            handleFormChange={handleFormChange}
            handleTemplateChage={handleTemplateChange}
            setValidationState={setValidationState}
          />
        )}
        {isAvailableBehavior && (
          <BizBoardBannerBehavior
            adAccountId={adAccountId}
            formData={formData}
            templateData={templateData}
            validationState={validationState}
            handleFormChange={handleFormChange}
            handleTemplateChange={handleTemplateChange}
          />
        )}
        <BizBoardBannerNotice
          formData={formData}
          templateData={templateData}
          validationState={validationState}
          handleFormChange={handleFormChange}
          handleTemplateChange={handleTemplateChange}
        />
      </div>
    </div>
  )
}

BizBoardBannerForm.propTypes = {
  adAccountId: PropTypes.any,
  formData: PropTypes.object,
  templateData: PropTypes.object,
  isAvailableCatalogProductObjet: PropTypes.bool,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
  setValidationState: PropTypes.func,
  validationState: PropTypes.object,
  onResize: PropTypes.func,
}

const BizBoardBannerImageCountSelect = ({
  imageCount,
  handleFormChange,
  handleTemplateChange,
}) => {
  const dispatch = useDispatch()

  const handleOnChange = React.useCallback(
    count => {
      handleFormChange('images', [])
      handleTemplateChange('imageIndex', -1)

      switch (count) {
        case 1: {
          handleTemplateChange('maskType', BIZ_BOARD_MASK_TYPE.SQUARE)
          break
        }
        case 2: {
          handleTemplateChange('maskType', BIZ_BOARD_MASK_TYPE.MULTI_IMAGE)

          break
        }
        default: {
          handleTemplateChange('maskType', BIZ_BOARD_MASK_TYPE.NONE)
          break
        }
      }

      dispatch(initBizBoardCreateImageData())
    },
    [dispatch, handleFormChange, handleTemplateChange]
  )

  return (
    <div className="bnrtop_area">
      <RadioGroup
        Component="ul"
        selectedValue={imageCount}
        onChange={handleOnChange}
        name="radioGroup|imageCount"
        className="reform_list_radiocheck reform_list_float">
        <li>
          <Radio label="단일 이미지" value={1} id="imageCount1" />
        </li>
        <li>
          <Radio label="멀티 이미지" value={2} id="imageCount2" />
        </li>
      </RadioGroup>
    </div>
  )
}

BizBoardBannerImageCountSelect.propTypes = {
  imageCount: PropTypes.number,
  formData: PropTypes.object,
  handleFormChange: PropTypes.func,
  handleTemplateChange: PropTypes.func,
}

export default BizBoardBannerForm
