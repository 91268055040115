import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { isEnoughPfGroupPopulation } from '../../../../utils/advertise/targeting'
import CampaignTypeEnum from '../../../../enums/CampaignTypeEnum'
import GoalEnum from '../../../../enums/GoalEnum'
import { checkEmpty } from '../../../../utils/regexUtils'
import { MomentLoader } from '../../../Common/Loader/MomentLoader'
import {
  getAdGroupTalkChannelPopulationMin,
  memoExpectedPopulationTextByType,
} from '../../../../utils/advertise/campaignAdGroup'
import { Tooltip } from '../../../../resources/locale'
import { updateAdGroupPopulation } from '../../../../modules/advertise/mAdGroupV2'
import AdGroupConstraints from '../../../../utils/constraints/constraints-adGroup'
import DynamicTooltip from '../../../Tooltip/DynamicTooltip'

export const getPopulationTitle = (campaignType, goal) => {
  switch (campaignType) {
    case CampaignTypeEnum.Type.VIDEO:
    case CampaignTypeEnum.Type.SPONSORED_BOARD:
    case CampaignTypeEnum.Type.ELECTION_2022_06:
    case CampaignTypeEnum.Type.DISPLAY: {
      return goal === GoalEnum.Type.CONVERSION ? '' : '예상 모수'
    }

    case CampaignTypeEnum.Type.TALK_BIZ_BOARD: {
      return goal === GoalEnum.Type.VISITING ? '예상 모수' : ''
    }

    case CampaignTypeEnum.Type.TALK_CHANNEL:
      return '예상 발송 모수'

    default:
      return ''
  }
}

const ExpectedPopulation = ({ adGroup, campaignForm }) => {
  const { adGroupForm } = adGroup

  const { combinedPopulation } = useSelector(
    state => state.get('population'),
    shallowEqual
  )

  const { campaignTypeGoal } = campaignForm
  const { campaignType, goal } = campaignTypeGoal
  const { targeting, smartMessage } = adGroupForm
  const { plusFriendGroupTargetings } = targeting || {}
  const isAvailablePfGroupPopulation = plusFriendGroupTargetings?.size > 0

  const title = getPopulationTitle(campaignType, goal)
  const titleTooltipId =
    title === '예상 모수'
      ? 'create_ads.v2.ad_group.population.display'
      : title === '예상 발송 모수'
      ? 'create_ads.v2.ad_group.population.message'
      : ''
  return (
    <li>
      <em className="reform_subtit_aside">{title}</em>
      {titleTooltipId && (
        <DynamicTooltip content={Tooltip(titleTooltipId)}>
          <a className="link_g link_help">
            <span className="ico_comm ico_help">도움말</span>
          </a>
        </DynamicTooltip>
      )}
      <PopulationView
        campaignForm={campaignForm}
        adGroupForm={adGroupForm}
        title={title}
        combinedPopulation={combinedPopulation}
        isAvailablePfGroupPopulation={isAvailablePfGroupPopulation}
        campaignTypeGoal={campaignTypeGoal}
        targeting={targeting}
        smartMessage={smartMessage}
      />
    </li>
  )
}

ExpectedPopulation.propTypes = {
  campaignType: PropTypes.string,
  goal: PropTypes.string,
  objectiveDetailType: PropTypes.string,
  adGroup: ImmutablePropTypes.map,
  campaignForm: ImmutablePropTypes.map,
}

export default ExpectedPopulation

const PopulationView = ({
  adGroupForm,
  title,
  combinedPopulation,
  isAvailablePfGroupPopulation,
  campaignTypeGoal: { campaignType, goal },
  targeting,
  smartMessage,
}) => {
  const dispatch = useDispatch()
  const { populationScore } = combinedPopulation
  const inValid = populationScore === -1
  const notSupportPopulation = !AdGroupConstraints.isPopulationRequired({
    campaignType,
    goal,
  })

  if (notSupportPopulation) {
    return (
      <p className="reform_desc_adgroup">
        해당 광고 유형, 목표에서는
        <br /> 예상 모수를 제공하지 않습니다.
      </p>
    )
  }

  if (checkEmpty(populationScore)) {
    return (
      <>
        <div className="reform_num_adgroup reform_loading">
          <span className="ico_comm" />
          <div className="reform_area_loading">
            <span className="group_loading loading_size2">
              <MomentLoader />
              <span className="screen_out">로딩중</span>
            </span>
          </div>
        </div>
        <p className="reform_desc_adgroup">
          {title}를 조회 중 입니다.
          <br />
          장시간 대기 중이면,
          <br />
          광고 대상 설정을 변경하세요.
        </p>
      </>
    )
  }

  if (inValid) {
    return (
      <div className="reform_num_adgroup reform_refresh">
        <span className="ico_comm" />
        조회 실패
        <a
          className="btn_refresh"
          onClick={() => dispatch(updateAdGroupPopulation(adGroupForm))}>
          <span className="ico_comm">새로고침</span>
        </a>
        <p className="reform_desc_adgroup">새로고침하여 재시도 하세요.</p>
      </div>
    )
  }

  const minPopulation = getAdGroupTalkChannelPopulationMin(
    campaignType,
    goal,
    populationScore,
    targeting,
    smartMessage
  )

  const checkEnoughPopulation = isAvailablePfGroupPopulation
    ? isEnoughPfGroupPopulation(populationScore)
    : populationScore > minPopulation

  if (!checkEnoughPopulation) {
    return (
      <div className="reform_num_adgroup reform_fail">
        <span className="ico_comm" />
        {minPopulation} 이하
        <p className="reform_desc_adgroup">
          {title}가 적어 광고그룹을 저장할 수 없습니다. 광고 대상 설정을
          변경하세요.
        </p>
      </div>
    )
  }
  const populationScoreText = memoExpectedPopulationTextByType(
    campaignType,
    populationScore
  )

  return (
    <div className="reform_num_adgroup">
      <span className="ico_comm" />
      {populationScoreText}
      <span className="screen_out">명</span>
      <p className="reform_desc_adgroup">
        {title.indexOf('예상 발송 모수') !== -1 ? (
          <>
            발송 시점에 따라 <br />
            예상 발송 모수는 달라질 수 있습니다.
          </>
        ) : (
          <>
            소재 등록에 따라 <br />
            예상 모수는 달라질 수 있습니다.
          </>
        )}
      </p>
    </div>
  )
}

PopulationView.propTypes = {
  title: PropTypes.string,
  isAvailablePfGroupPopulation: PropTypes.bool,
  dynamicTarget: PropTypes.bool,
  objective: ImmutablePropTypes.map,
  adGroupForm: ImmutablePropTypes.map,
  combinedPopulation: ImmutablePropTypes.map,
  campaignTypeGoal: ImmutablePropTypes.map,
  targeting: ImmutablePropTypes.map,
  creativeOptimization: PropTypes.bool,
  smartMessage: PropTypes.any,
}
