import { getApiPhase, KakaoMoment } from './kakaoMoment'
import {
  toCreateAdAccountPath,
  toInvitePath,
  toMyAdAccountListPath,
  toRequestAdAccountMemberPath,
} from '../../router/routeUtils'

/**
 * 카포비 랜딩 정책: https://wiki.daumkakao.com/pages/viewpage.action?pageId=457005368
 */
export const KakaoBizCenter = {
  Host: {
    production: 'https://business.kakao.com',
    stage: 'https://cbt-business.kakao.com',
    sandbox: 'https://sandbox-kabz-business.kakao.com',
    develop: 'https://sandbox-kabz-business.kakao.com', // dev-business deprecated.
  },
  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },
}

export const KakaoBizCenterAccount = {
  Host: {
    production: 'https://biz-auth.kakao.com',
    stage: 'https://crux-biz-auth-cbt.devel.kakao.com',
    sandbox: 'https://crux-biz-auth-sandbox.devel.kakao.com',
    develop: 'https://crux-biz-auth-sandbox.devel.kakao.com', // dev-business deprecated.
  },
  getHost() {
    return this.Host[getApiPhase()] || this.Host.sandbox
  },
}

export const kakaoBizCenterDashboardURL = momentUrl => {
  const params = `sid=kmo${momentUrl ? `&redirect=${momentUrl}` : ''}`
  return `${KakaoBizCenter.getHost()}/dashboard/?${encodeURIComponent(params)}`
}

export const kakaoBizCenterMyInfoURL = () => {
  return `${KakaoBizCenter.getHost()}/my-info`
}

export const kakaoBizCenterWithdrawURL = () => {
  return `${KakaoBizCenterAccount.getHost()}/account/leave`
}

export const kakaoBizCenterTalkBizFormURL = () => {
  return `${KakaoBizCenter.getHost()}/talkbizform`
}

export const fromKakaoBizCenterLink = () =>
  [
    toCreateAdAccountPath(), // 새 광고계정 만들기
    toRequestAdAccountMemberPath(), // 광고계정 멤버로 요청(@depreaceted)
    toMyAdAccountListPath(), // 내 광고계정 목록(멤버로 요청 팝업 포함)
    toInvitePath(), // 초대받은 광고계정 목록
  ].includes(window.location.pathname)

export const kakaoBizCenterIntegratedJoinURL = returnUrl => {
  return (
    `${KakaoBizCenterAccount.getHost()}/account/auth?after=` +
    `${returnUrl ?? `${KakaoMoment.getHost()}${toMyAdAccountListPath()}`}`
  )
}

export const kakaoBizCenterIntegratedNewJoinURL = returnUrl => {
  return (
    `${KakaoBizCenterAccount.getHost()}/account/join/new-service?after=` +
    `${returnUrl ?? `${KakaoMoment.getHost()}${toMyAdAccountListPath()}`}`
  )
}

export const kakaoBizCenterPrivacy = () => {
  return `${KakaoBizCenter.getHost()}/policy/privacy/`
}

export const kakaoBizCenterMyPixelAndSdkDetail = trackId => {
  return `${KakaoBizCenter.getHost()}/pixel/detail/${trackId}/`
}

export const kakaoBizCenterMyPixelAndSdk = () => {
  return `${KakaoBizCenter.getHost()}/pixel/`
}
