import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ON: null,
    OFF: null,
    DEL: null,
  }),

  Name: {
    ON: 'ON',
    OFF: 'OFF',
    DEL: '삭제',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  onOffValues() {
    return Object.freeze([this.Type.ON, this.Type.OFF])
  },

  getName(type) {
    return this.Name[type]
  },
}
