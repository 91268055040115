export const KakaoAD = {
  APP_KEY: '5148ad923bb55467194ab03d571eb909',
  URL: {
    CS: 'https://cs.kakao.com/helps?service=110',
    Request: 'https://cs.kakao.com/requests?service=110&locale=ko',
    Compliance:
      'https://cs.kakao.com/helps?articleId=1073191145&category=329&device=1158&locale=ko&service=110',
    GitHub: 'https://github.com/kakaoad',
    Brochure: 'http://ad.kakao.com/#lastPage',
    ReviewAdViewGuide: 'https://ad.kakao.com/guide/talkboard/review/60',
  },
}
