import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    CMP_DISPLAY: null,
    CMP_MESSAGE: null,
    CMP_UPLOAD: null,
    KAKAO_TV_UPLOAD: null,
    KAKAO_TV_PREUPLOAD: null,
    UNKNOWN: null,
  }),

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  isKakaoTv(type) {
    return [this.Type.KAKAO_TV_UPLOAD, this.Type.KAKAO_TV_PREUPLOAD].includes(
      type
    )
  },
}
