import { keyMirror } from '../../utils/utils'
import { fromJS, Map } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { coerceToArray } from '../../utils/stringUtils'
import { hideLoading, showLoading } from '../common/mLoading'
import { TalkChannelMessageHelper } from '../../utils/helper/helper-talkChannelMessage'
import { toMessageDetailPath } from '../../utils/router/routeUtils'
import CreativeFormatEnum from '../../enums/CreativeFormatEnum'
import {
  handleMessageError,
  initMessageValidation,
  setIsValidMessageByKey,
  setSpamValidationResult,
  showSpamFilterPopUp,
} from './mMessageCommon'
import { scrollByValidationKeys, Validation } from '../../validators/validation'
import {
  getMessageValidatorByMessageType,
  MESSAGE_FORM_VALIDATION_KEY_PATH,
} from '../../validators/message/messageFormValidator'
import { MESSAGE_FORM_VALIDATION_KEY } from '../../validators/message/messageFormValidationKey'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

const TalkChannelMessageModify = keyMirror(
  {
    INITIALIZE: null,
    SET_BY_KEY_PATH: null,
    INIT_BY_KEY_PATH: null,
    CLEAR: null,
    SET_PLUS_FRIEND_INFO: null,
  },
  'TALK_CHANNEL_MESSAGE_MODIFY'
)

const initialState = Map({
  profileId: -1,
  profileName: '',
  profileImageUrl: '',
  csInfo: '',
  profileHomeInfo: null,
  creativeFormat: CreativeFormatEnum.Type.BASIC_TEXT_MESSAGE,
})

export default createReducer(initialState, {
  [TalkChannelMessageModify.INITIALIZE]: (state, { data }) =>
    state.merge(fromJS(data)),

  [TalkChannelMessageModify.SET_BY_KEY_PATH]: (state, { keyPath, value }) =>
    state.setIn([...coerceToArray(keyPath)], fromJS(value)),

  [TalkChannelMessageModify.INIT_BY_KEY_PATH]: (state, { keyPath }) =>
    state.setIn(
      [...coerceToArray(keyPath)],
      initialState.getIn([...coerceToArray(keyPath)])
    ),

  [TalkChannelMessageModify.SET_PLUS_FRIEND_INFO]: (state, { data }) => {
    const {
      profileId,
      profileName,
      profileImageUrl,
      csInfo,
      encodedId,
      isBizChannel,
      profileHomeInfo,
    } = data

    const { type } = profileHomeInfo || {}
    const isProfileHomeInfo = type === 'web'

    return state
      .set('profileId', profileId)
      .set('profileName', profileName)
      .set('profileImageUrl', profileImageUrl)
      .set('csInfo', csInfo)
      .set('encodedId', encodedId)
      .set('isBizChannel', isBizChannel)
      .set('isProfileHomeInfo', isProfileHomeInfo)
  },

  [TalkChannelMessageModify.CLEAR]: () => initialState,
})

export function initializeTalkChannelMessageModify(messageForm) {
  let modifyMessageForm = messageForm

  modifyMessageForm = TalkChannelMessageHelper.defaultInitialize({
    messageForm: modifyMessageForm,
  })

  modifyMessageForm =
    TalkChannelMessageHelper.transformMessageVideoTypeForModify({
      messageForm: modifyMessageForm,
    })

  return {
    type: TalkChannelMessageModify.INITIALIZE,
    data: modifyMessageForm,
  }
}

export function setTalkChannelMessageModifyByKey(keyPath, value) {
  return {
    type: TalkChannelMessageModify.SET_BY_KEY_PATH,
    keyPath,
    value,
  }
}

export function setMessageModifyPlusFriendInfo(plusFriendProfile) {
  const {
    id: profileId,
    name: profileName,
    profileImageUrl,
    csInfo,
    encodedId,
    bizChannel: isBizChannel,
    profileHomeInfo,
  } = plusFriendProfile
  return {
    type: TalkChannelMessageModify.SET_PLUS_FRIEND_INFO,
    data: {
      profileId,
      profileName,
      profileImageUrl,
      csInfo,
      encodedId,
      isBizChannel,
      profileHomeInfo,
    },
  }
}

export function clearTalkChannelMessageModify() {
  return {
    type: TalkChannelMessageModify.CLEAR,
  }
}

export function modifyMessageTemplate(
  adAccountId,
  messageTemplateId,
  messageTemplate
) {
  return async (dispatch, getState, api) => {
    const { creativeFormat } = messageTemplate

    const messageForm = TalkChannelMessageHelper.toTrimForm(messageTemplate)

    dispatch(initMessageValidation())

    const validationResult = Validation(
      messageForm,
      MESSAGE_FORM_VALIDATION_KEY,
      MESSAGE_FORM_VALIDATION_KEY_PATH,
      getMessageValidatorByMessageType({ creativeFormat }),
      getState,
      setIsValidMessageByKey,
      dispatch
    )

    if (!validationResult) {
      const {
        message: {
          common: {
            validationErrorKeys,
            validationExtra: { itemAssetGroupsValidationResults },
          },
        },
      } = getState()

      const getExtra = validationKey => {
        const invalidItemAssetIndex =
          validationKey === MESSAGE_FORM_VALIDATION_KEY.ITEM_ASSET_GROUPS
            ? itemAssetGroupsValidationResults?.findIndex(
                ({ isValid }) => !isValid
              )
            : undefined

        return invalidItemAssetIndex >= 0 ? invalidItemAssetIndex : undefined
      }

      scrollByValidationKeys(
        validationErrorKeys,
        MESSAGE_FORM_VALIDATION_KEY,
        getExtra
      )

      return
    }

    try {
      dispatch(showLoading())

      const response = await api.message.validateSpamMessage(
        adAccountId,
        TalkChannelMessageHelper.toAPI(messageForm)
      )
      const { isFiltered = false } = response?.data || {}

      if (isFiltered) {
        const spamValidationResult =
          TalkChannelMessageHelper.Spam.convertSpamResult({
            spamResult: response.data,
            creativeFormat,
            messageForm: messageTemplate,
          })

        dispatch(setSpamValidationResult(spamValidationResult))
        dispatch(showSpamFilterPopUp())
        dispatch(hideLoading())

        return
      }

      await api.message.modifyMessageTemplate(
        adAccountId,
        messageTemplateId,
        TalkChannelMessageHelper.toAPI(messageForm)
      )

      dispatch(
        RouterV2.push(toMessageDetailPath(adAccountId, messageTemplateId))
      )
    } catch (e) {
      handleMessageError({ error: e?.response?.data, dispatch })
    } finally {
      dispatch(hideLoading())
    }
  }
}
