import React from 'react'
import InputBox from '../../../../../Common/InputBox'
import useComponentId from '../../../../../../utils/hook/useComponentId'
import { Radio, RadioGroup } from '../../../../../Common/RadioGroup/RadioGroup'
import CheckBox from '../../../../../Common/CheckBox'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import NumberFormatInput, {
  NUMBER_FORMAT_INPUT_ALIGN,
} from '../../../../../Common/NumberFormatInput/NumberFormatInput'
import {
  CMP_ASSET_LIBRARY_FILTER_OPTION,
  CMP_ASSET_LIBRARY_FILTER_TYPE,
  CmpAssetLibraryUtils,
} from '../cmpAssetLibraryUtils'
import PropTypes from 'prop-types'
import {
  getCmpAssetLibraryImageRecentData,
  setCmpAssetLibraryImageRecentSearchOption,
} from '../../../../../../modules/cmp/mCmpAssetLibraryImage'
import CreativeFormatEnum from '../../../../../../enums/CreativeFormatEnum'
import CreativeAssetPropertyEnum from '../../../../../../enums/CreativeAssetPropertyEnum'
import { floor } from 'lodash'
import { Tooltip } from '../../../../../../resources/locale'
import { usePrevious } from '../../../../../../utils/hook/usePrevious'
import CampaignTypeEnum from '../../../../../../enums/CampaignTypeEnum'
import { checkEmpty } from '../../../../../../utils/regexUtils'

const selector = ({
  cmpAssetLibrary: {
    image: {
      recent: { searchOptions },
    },
  },
}) => {
  return {
    searchOptions,
  }
}

const CmpAssetLibraryImageRecentFilter = ({
  adAccountId,
  campaignType,
  creativeFormat,
  creativeAssetPropertyType,
}) => {
  const dispatch = useDispatch()

  const {
    searchOptions: {
      searchText,
      filterType: initialFilterType,
      filterCondition: initialFilterCondition,
    },
  } = useSelector(selector, shallowEqual)

  const [isOpened, setIsOpened] = React.useState(false)

  const searchInputId = useComponentId()

  const handleSearchTextChange = React.useCallback(
    (searchText = '') => {
      dispatch(
        setCmpAssetLibraryImageRecentSearchOption({
          keyPath: 'searchText',
          value: searchText,
        })
      )
    },
    [dispatch]
  )

  const handleSearch = React.useCallback(() => {
    dispatch(
      getCmpAssetLibraryImageRecentData({
        adAccountId,
        campaignType,
        creativeFormat,
        creativeAssetPropertyType,
      })
    )
  }, [
    adAccountId,
    campaignType,
    creativeAssetPropertyType,
    creativeFormat,
    dispatch,
  ])

  return (
    <div className="group_option">
      <InputBox
        id={searchInputId}
        value={searchText}
        className="search_inp2"
        placeholder="파일 이름을 입력하세요."
        labelClassName="lab_txt"
        isShowingRemoveButton={true}
        maxLength={30}
        onChange={e => {
          handleSearchTextChange(e.target.value)
        }}
        onEnterKey={handleSearch}
        onRemove={() => {
          handleSearchTextChange('')
        }}>
        <button type="button" className="btn_search" onClick={handleSearch}>
          <span className="ico_comm ico_search">검색</span>
        </button>
      </InputBox>
      <button
        type="button"
        className={cx('btn_filter', { on: isOpened })}
        onClick={() => {
          setIsOpened(!isOpened)
        }}>
        <span className="ico_comm">필터 설정</span>
      </button>
      {isOpened && (
        <FilterLayer
          initialFilterType={initialFilterType}
          initialFilterCondition={initialFilterCondition.toJS()}
          handleSearch={handleSearch}
          dismiss={() => {
            setIsOpened(false)
          }}
        />
      )}
    </div>
  )
}

CmpAssetLibraryImageRecentFilter.propTypes = {
  adAccountId: PropTypes.any.isRequired,
  campaignType: PropTypes.oneOf(CampaignTypeEnum.values()).isRequired,
  creativeFormat: PropTypes.oneOf(CreativeFormatEnum.values()).isRequired,
  creativeAssetPropertyType: PropTypes.oneOf(CreativeAssetPropertyEnum.values())
    .isRequired,
}

const FILTER_PRESET = {
  RATIO: [
    ...CmpAssetLibraryUtils.Recent.ratioOptions().map(({ width, height }) => ({
      id: `EQ|${width}:${height}`,
      value: floor(width / height, 4),
      label: `${width}:${height}`,
    })),
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.UNKNOWN,
      label: '기타',
    },
  ],
  WIDTH: [
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
      value: 1,
      label: '전체',
    },
    {
      id: 'GTE|500',
      value: 500,
      label: '500픽셀 이상',
    },
    {
      id: 'GTE|1200',
      value: 1200,
      label: '1200픽셀 이상',
    },
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM,
      value: null,
      label: '직접 입력',
    },
  ],
  HEIGHT: [
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
      value: 1,
      label: '전체',
    },
    {
      id: 'GTE|500',
      value: 500,
      label: '500픽셀 이상',
    },
    {
      id: 'GTE|600',
      value: 600,
      label: '600픽셀 이상',
    },
    {
      id: CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM,
      label: '직접 입력',
    },
  ],
}

const INITIAL_VALIDATION_STATUS = {
  widthCustom: { error: false, errorMessage: '' },
  heightCustom: { error: false, errorMessage: '' },
}

const FilterLayer = ({
  initialFilterType,
  initialFilterCondition,
  handleSearch,
  dismiss,
}) => {
  const dispatch = useDispatch()

  const [validationState, setValidationState] = React.useState(
    INITIAL_VALIDATION_STATUS
  )
  const [filterType, setFilterType] = React.useState(initialFilterType)
  const [filterCondition, setFilterCondition] = React.useState(
    initialFilterCondition
  )

  const handleFilterTypeChange = React.useCallback(nextFilterType => {
    setFilterType(nextFilterType)

    setFilterCondition({
      ratios: [],
      width: {
        id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
        value: 1,
        label: '전체',
      },
      height: {
        id: CMP_ASSET_LIBRARY_FILTER_OPTION.ALL,
        value: 1,
        label: '전체',
      },
    })
  }, [])

  const disabledCustom = filterType !== CMP_ASSET_LIBRARY_FILTER_TYPE.CUSTOM

  const filterTypeRadioGroupId = useComponentId()

  return (
    <div className="layer_filter" style={{ display: 'block' }}>
      <div className="inner_g">
        <strong className="tit_filter">이미지 필터 설정</strong>
        <RadioGroup
          Component="ul"
          className="list_filter"
          onChange={handleFilterTypeChange}
          name={filterTypeRadioGroupId}
          selectedValue={filterType}>
          <li>
            <Radio
              id={CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE}
              label="등록 가능한 이미지만 보기"
              value={CMP_ASSET_LIBRARY_FILTER_TYPE.AVAILABLE}
              hasHelp={true}
              toolTipContent={Tooltip('cmp.image_asset.filter.available')}
            />
          </li>
          <li>
            <Radio
              id={CMP_ASSET_LIBRARY_FILTER_TYPE.CUSTOM}
              label="이미지 직접 선택하기"
              value={CMP_ASSET_LIBRARY_FILTER_TYPE.CUSTOM}
              hasHelp={true}
              toolTipContent={Tooltip('cmp.image_asset.filter.custom')}
            />
          </li>
        </RadioGroup>
        <div className="box_filter">
          <em className="tit_filter">소재 비율</em>
          <FilterCheckBoxGroup
            presets={FILTER_PRESET.RATIO}
            filterConditionKey={'ratios'}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            active={!disabledCustom}
          />
          <em className="tit_filter">소재 너비</em>
          <FilterRadioGroup
            presets={FILTER_PRESET.WIDTH}
            filterConditionKey={'width'}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            active={!disabledCustom}
            {...validationState.widthCustom}
          />
          <em className="tit_filter">소재 높이</em>
          <FilterRadioGroup
            presets={FILTER_PRESET.HEIGHT}
            filterConditionKey={'height'}
            filterCondition={filterCondition}
            setFilterCondition={setFilterCondition}
            active={!disabledCustom}
            {...validationState.heightCustom}
          />
        </div>
        <div className="btn_group">
          <button
            type="button"
            className={cx('btn_gm gm_bl', { in_active: false })}
            onClick={() => {
              const invalidWidthCustom =
                filterCondition.width.id ===
                  CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM &&
                checkEmpty(filterCondition.width.value)

              const invalidHeightCustom =
                filterCondition.height.id ===
                  CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM &&
                checkEmpty(filterCondition.height.value)

              if (!invalidWidthCustom && !invalidHeightCustom) {
                dispatch(
                  setCmpAssetLibraryImageRecentSearchOption({
                    keyPath: 'filterType',
                    value: filterType,
                  })
                )

                dispatch(
                  setCmpAssetLibraryImageRecentSearchOption({
                    keyPath: 'filterCondition',
                    value: filterCondition,
                  })
                )

                handleSearch()

                dismiss()
              } else {
                setValidationState(prev => {
                  const nextValidationState = { ...prev }

                  if (invalidWidthCustom) {
                    nextValidationState.widthCustom = {
                      error: true,
                      errorMessage: '픽셀 값을 입력하세요.',
                    }
                  } else {
                    nextValidationState.widthCustom =
                      INITIAL_VALIDATION_STATUS.widthCustom
                  }

                  if (invalidHeightCustom) {
                    nextValidationState.heightCustom = {
                      error: true,
                      errorMessage: '픽셀 값을 입력하세요.',
                    }
                  } else {
                    nextValidationState.heightCustom =
                      INITIAL_VALIDATION_STATUS.heightCustom
                  }

                  return nextValidationState
                })
              }
            }}>
            <span className="inner_g">적용</span>
          </button>
        </div>
      </div>
    </div>
  )
}

FilterLayer.propTypes = {
  initialFilterType: PropTypes.oneOf(Object.keys(CMP_ASSET_LIBRARY_FILTER_TYPE))
    .isRequired,
  initialFilterCondition: PropTypes.shape({
    ratio: PropTypes.array,
    width: PropTypes.object,
    height: PropTypes.object,
  }).isRequired,
  handleSearch: PropTypes.func.isRequired,
  dismiss: PropTypes.func.isRequired,
}

const FilterCheckBoxGroup = ({
  presets,
  filterConditionKey,
  filterCondition,
  setFilterCondition,
  active = true,
}) => {
  const prevActive = usePrevious(active)

  // 비활성 -> 활성으로 변경된 경우 모든 옵션 추가
  React.useEffect(() => {
    if (prevActive === false && active === true) {
      setFilterCondition(prevCondition => {
        return {
          ...prevCondition,
          [filterConditionKey]: presets,
        }
      })
    }
  }, [active, filterConditionKey, presets, prevActive, setFilterCondition])

  const handlePresetChange = React.useCallback(
    presetObj => e => {
      setFilterCondition(prevCondition => {
        return {
          ...prevCondition,
          [filterConditionKey]: e.target.checked
            ? prevCondition[filterConditionKey].concat(presetObj)
            : prevCondition[filterConditionKey].filter(
                ({ id }) => id !== presetObj.id
              ),
        }
      })
    },
    [filterConditionKey, setFilterCondition]
  )

  const baseId = useComponentId()

  return (
    <ul className="list_filter">
      {presets.map(preset => {
        const key = `${baseId}|${preset.id}`

        return (
          <li key={key}>
            <CheckBox
              id={key}
              label={preset.label ?? preset.value}
              isChecked={filterCondition[filterConditionKey].some(
                ({ id }) => id === preset.id
              )}
              active={active}
              onChange={handlePresetChange(preset)}
            />
          </li>
        )
      })}
    </ul>
  )
}

FilterCheckBoxGroup.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  filterConditionKey: PropTypes.string.isRequired,
  filterCondition: PropTypes.object.isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  active: PropTypes.bool,
}

const FilterRadioGroup = ({
  presets,
  filterConditionKey,
  filterCondition,
  setFilterCondition,
  active = true,
  error = false,
  errorMessage = '',
}) => {
  const baseId = useComponentId()

  const handlePresetChange = React.useCallback(
    nextPresetObj => {
      if (active) {
        setFilterCondition(prevCondition => ({
          ...prevCondition,
          [filterConditionKey]: nextPresetObj,
        }))
      }
    },
    [active, filterConditionKey, setFilterCondition]
  )

  const handleCustomPresetValueChange = React.useCallback(
    presetObj =>
      ({ value }) => {
        setFilterCondition(prevCondition => ({
          ...prevCondition,
          [filterConditionKey]: {
            ...presetObj,
            value,
          },
        }))
      },
    [filterConditionKey, setFilterCondition]
  )

  return (
    <RadioGroup
      Component="ul"
      name={baseId}
      className="list_filter"
      isEquals={radioItemObj =>
        radioItemObj.id === filterCondition[filterConditionKey].id
      }
      disabled={!active}
      onChange={handlePresetChange}>
      {presets.map(preset => {
        const key = `${baseId}|${preset.id}`
        const isCustomInputActive =
          filterCondition[filterConditionKey].id ===
          CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM

        return (
          <li key={key}>
            <Radio
              id={key}
              label={preset.label ?? preset.value}
              value={preset}
            />
            {preset.id === CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM && (
              <>
                <div className="group_filter">
                  <NumberFormatInput
                    id={`${baseId}|${CMP_ASSET_LIBRARY_FILTER_OPTION.CUSTOM}|input`}
                    align={NUMBER_FORMAT_INPUT_ALIGN.RIGHT}
                    thousandSeparator={false}
                    allowNegative={false}
                    allowLeadingZeros={false}
                    maxLength={8}
                    disabled={!isCustomInputActive}
                    value={
                      isCustomInputActive
                        ? filterCondition[filterConditionKey].value
                        : ''
                    }
                    active={isCustomInputActive}
                    error={error}
                    placeholder="픽셀 입력"
                    onValueChange={
                      isCustomInputActive
                        ? handleCustomPresetValueChange(preset)
                        : undefined
                    }
                  />
                  <span className="txt_filter">픽셀 이상</span>
                </div>
                {error && <p className="txt_error">{errorMessage}</p>}
              </>
            )}
          </li>
        )
      })}
    </RadioGroup>
  )
}

FilterRadioGroup.propTypes = {
  presets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.any,
      value: PropTypes.any,
      label: PropTypes.string,
    })
  ).isRequired,
  filterConditionKey: PropTypes.string.isRequired,
  filterCondition: PropTypes.object.isRequired,
  setFilterCondition: PropTypes.func.isRequired,
  active: PropTypes.bool,
  error: PropTypes.bool,
  errorMessage: PropTypes.string,
}

export default CmpAssetLibraryImageRecentFilter
