import { checkEmpty, hasFullWidthWhitespace } from '../../utils/regexUtils'
import { IS_NOT_VALID, IS_VALID } from '../validation'

const validateAdViewName = name => {
  if (checkEmpty(name)) {
    return IS_NOT_VALID('애드뷰 이름을 입력하세요.')
  }

  if (hasFullWidthWhitespace(name)) {
    return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
  }

  return IS_VALID()
}

const validateAdViewBrandName = brandName => {
  if (checkEmpty(brandName)) {
    return IS_NOT_VALID('브랜드 이름을 입력하세요.')
  }

  if (hasFullWidthWhitespace(brandName)) {
    return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
  }

  return IS_VALID()
}

const validateAdViewTitle = title => {
  if (checkEmpty(title)) {
    return IS_NOT_VALID('타이틀을 입력하세요.')
  }

  if (hasFullWidthWhitespace(title)) {
    return IS_NOT_VALID('전각 공백문자는 입력할 수 없습니다.')
  }

  return IS_VALID()
}

export { validateAdViewName, validateAdViewBrandName, validateAdViewTitle }
