import { Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { cancelRequest } from '../../utils/cancellation/cancellation'

export const DownloadStatus = keyMirror(
  {
    PREPARE: null,
    WAITING: null,
    START: null,
    PENDING: null,
    CANCEL: null,
    COMPLETE: null,
    FAILURE: null,
    CLEAR: null,
  },
  'DOWNLOAD'
)

const initialState = Map({
  status: DownloadStatus.WAITING,
  data: null,
  key: null,
})

export default createReducer(initialState, {
  [DownloadStatus.PREPARE]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.PREPARE))
      .set('key', key)
      .set('data', data),
  [DownloadStatus.START]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.START))
      .set('key', key)
      .set('data', data),
  [DownloadStatus.PENDING]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.PENDING))
      .set('key', key)
      .set('data', data),
  [DownloadStatus.CANCEL]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.CANCEL))
      .set('key', key)
      .set('data', data),
  [DownloadStatus.COMPLETE]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.COMPLETE))
      .set('key', key)
      .set('data', data),
  [DownloadStatus.FAILURE]: (state, { key, data }) =>
    state
      .withMutations(s => s.set('status', DownloadStatus.FAILURE))
      .set('key', key)
      .set('data', data),

  [DownloadStatus.CLEAR]: state => state.merge(initialState),
})

export function prepareDownload(key, data) {
  return {
    type: DownloadStatus.PREPARE,
    key,
    data,
  }
}

export function startDownload(key, data) {
  return {
    type: DownloadStatus.START,
    key,
    data,
  }
}

export function pendingDownload(key, data) {
  return {
    type: DownloadStatus.PENDING,
    key,
    data,
  }
}

export function completeDownload(key, data) {
  return {
    type: DownloadStatus.COMPLETE,
    key,
    data,
  }
}

export function failureDownload(key, data) {
  cancelRequest(key)
  return {
    type: DownloadStatus.FAILURE,
    key,
    data,
  }
}

export function cancelDownload(key) {
  cancelRequest(key)

  return {
    type: DownloadStatus.CANCEL,
    key,
  }
}

export function clearDownload() {
  return {
    type: DownloadStatus.CLEAR,
  }
}
