import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    KDB: null,
    IBK: null,
    KB: null,
    SH: null,
    NH: null,
    NH_UNIT: null,
    WOORI: null,
    SC: null,
    CITY: null,
    DGB: null,
    BNK: null,
    KJ: null,
    JEJU: null,
    JB: null,
    KN: null,
    KFCC: null,
    CU: null,
    EPOST: null,
    KEB: null,
    SHINHAN: null,
    KBANK: null,
    KAKAO: null,
    HSBC: null,
    YUANTA: null,
    SAMSUNG: null,
    TOSS: null,
  }),

  Name: {
    KDB: '산업',
    IBK: '기업',
    KB: '국민',
    SH: '수협',
    NH: '농협중앙',
    NH_UNIT: '농협',
    WOORI: '우리',
    SC: 'SC제일',
    CITY: '씨티',
    DGB: '대구',
    BNK: '부산',
    KJ: '광주',
    JEJU: '제주',
    JB: '전북',
    KN: '경남',
    KFCC: '새마을',
    CU: '신협',
    EPOST: '우체국',
    KEB: 'KEB',
    SHINHAN: '신한',
    KBANK: '케이뱅크',
    KAKAO: '카카오뱅크',
    HSBC: 'HSBC',
    YUANTA: '유안타증권',
    SAMSUNG: '삼성증권',
    TOSS: '토스뱅크',
  },

  Code: {
    KDB: '02',
    IBK: '03',
    KB: '04',
    SH: '07',
    NH: '11',
    NH_UNIT: '12',
    WOORI: '20',
    SC: '23',
    CITY: '27',
    DGB: '31',
    BNK: '32',
    KJ: '34',
    JEJU: '35',
    JB: '37',
    KN: '39',
    KFCC: '45',
    CU: '48',
    EPOST: '71',
    KEB: '81',
    SHINHAN: '88',
    KBANK: '89',
    KAKAO: '90',
    HSBC: '54',
    YUANTA: '209',
    SAMSUNG: '240',
    TOSS: '92',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getValuesByOptionListItems() {
    return this.values().map((type, index) => ({
      id: index,
      code: this.Code[type],
      label: this.Name[type],
    }))
  },
}
