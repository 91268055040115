import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    card: null,
    image: null,
    video: null,
    link: null,
    text: null,
  }),

  Name: {
    card: '카드뷰',
    image: '사진',
    video: '동영상',
    link: '링크',
    text: '텍스트',
  },

  getName(type) {
    return this.Name[type]
  },

  hasThumbnail(type) {
    return [
      this.Type.card,
      this.Type.image,
      this.Type.video,
      this.Type.link,
    ].includes(type)
  },
}
