import Messages from './Messages'

/**
 *
 * @param id
 * @param params
 * @return {*}
 * @constructor
 */
export const Tooltip = (id, params = {}) => {
  if (!id) return null // throw 'resource id is not defined'
  const message = Messages[id]
  if (!message) return null // throw `cannot find locale message: ${currentLocale} - ${id}`
  const v = params
    ? Object.keys(params).reduce((prev, k) => {
        return prev.replace(`{${k}}`, params[k])
      }, message)
    : message
  return v || id
}
