export const STORAGE_KEY_REQUIRED_INFO_AGREEMENT = 'moment.isRequiredAgreement'

const momentLocalStorage = (function selectStorage() {
  try {
    let testKey = '__localStorage.test__'
    localStorage.setItem(testKey, testKey)
    localStorage.removeItem(testKey)
    return localStorage
  } catch (e) {
    return createStorage()
  }
})()

function createStorage() {
  let storage = {}
  return {
    setItem(key, value) {
      storage[key] = value
    },
    getItem(key) {
      return storage[key]
    },
    removeItem(key) {
      delete storage[key]
    },
    clear() {
      storage = {}
    },
  }
}

export default momentLocalStorage
