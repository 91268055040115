import React from 'react'
import PropTypes from 'prop-types'
import { checkNotEmpty } from '../../../../../utils/regexUtils'

DefaultNoItem.propTypes = {
  Label: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.func,
    PropTypes.string,
  ]),
}

function DefaultNoItem({ Label }) {
  return (
    <div className="box_nodata">
      <p
        className="opt_nodata"
        style={{
          whiteSpace: 'pre-wrap',
        }}>
        {checkNotEmpty(Label) ? (
          typeof Label === 'string' ? (
            Label
          ) : (
            <Label />
          )
        ) : (
          '데이터가 존재하지 않습니다.'
        )}
      </p>
    </div>
  )
}

export default DefaultNoItem
