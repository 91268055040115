import moment from 'moment'
import CalendarEnum from '../../../enums/CalendarEnum'
import { dateToString } from '../../../utils/utils'

const ReportCalendarUtils = {}

ReportCalendarUtils.ChunkSize = 7
ReportCalendarUtils.MaxPeriodDate = 61

/**
 * date 를 기준으로 해당 월의 시퀀셜한 캘린더 데이터를 만든다.
 *
 *  ex)
 *    date: 2019-01 | 2019-01-24
 *
 *    * 실제로는 moment.Moment 객체 형태로 담김
 *
 *    return [
 *      ["18-12-30", "18-12-31", "19-01-01", "19-01-02", "19-01-03", "19-01-04", "19-01-05"]
 *      ["19-01-06", "19-01-07", "19-01-08", "19-01-09", "19-01-10", "19-01-11", "19-01-12"]
 *      ["19-01-13", "19-01-14", "19-01-15", "19-01-16", "19-01-17", "19-01-18", "19-01-19"]
 *      ["19-01-20", "19-01-21", "19-01-22", "19-01-23", "19-01-24", "19-01-25", "19-01-26"]
 *      ["19-01-27", "19-01-28", "19-01-29", "19-01-30", "19-01-31", "19-02-01", "19-02-02"]
 *    ]
 *
 *    date: 2019-03 | 2019-03-05
 *
 *    return [
 *      ["19-02-24", "19-02-25", "19-02-26", "19-02-27", "19-02-28", "19-03-01", "19-03-02"]
 *      ["19-03-03", "19-03-04", "19-03-05", "19-03-06", "19-03-07", "19-03-08", "19-03-09"]
 *      ["19-03-10", "19-03-11", "19-03-12", "19-03-13", "19-03-14", "19-03-15", "19-03-16"]
 *      ["19-03-17", "19-03-18", "19-03-19", "19-03-20", "19-03-21", "19-03-22", "19-03-23"]
 *      ["19-03-24", "19-03-25", "19-03-26", "19-03-27", "19-03-28", "19-03-29", "19-03-30"]
 *      ["19-03-31", "19-04-01", "19-04-02", "19-04-03", "19-04-04", "19-04-05", "19-04-06"]
 *    ]
 *
 * @param date {moment.Moment}
 * @return {moment.Moment | *}
 */
ReportCalendarUtils.create = function (date) {
  /**
   * date 기준 month 의 시작일(date)에 대한 요일(weekday)
   * ex)
   *  2019-01-01(화) => 2
   *  2019-02-01(금) => 5
   *  2019-09-01(일) => 0
   *
   * @type {number}
   */
  const startWeekdayOfThisMonth = moment(date).date(1).weekday()

  /**
   * date 기준 월의 마지막 날(date)
   * ex)
   *  2019-01 => 31
   *
   * @type {number}
   */
  const lastDateOfThisMonth = moment(date).endOf('M').date()

  /**
   * date 기준 month 의 마지막 날(date)에 대한 요일(weekday)
   * @type {number}
   */
  const lastWeekdayOfThisMonth = moment(date)
    .date(lastDateOfThisMonth)
    .weekday()

  /**
   * date 기준 month 에 포함된 이전달 dates
   * ex)
   *  1월 달력에 포함된 12월 날짜
   *  2019-01 => [2018-12-30, 2018-12-31]
   *
   * @type {moment.Moment[]}
   */
  const datesOfPrevMonth = Array.from(Array(startWeekdayOfThisMonth)).map(
    (v, i) =>
      moment(date)
        .date(1)
        .subtract(startWeekdayOfThisMonth - i, 'd')
  )

  /**
   * date 기준 month 의 dates
   * ex) 2019-01 => [2019-01-01, ..., 2019-01-31]
   *
   * @type {moment.Moment[]}
   */
  const datesOfThisMonth = Array.from(Array(lastDateOfThisMonth)).map((v, i) =>
    moment(date).date(i + 1)
  )

  /**
   * date 기준 month 에 포함된 다음달 dates
   * ex)
   *  1월 달력에 포함된 2월 날짜
   *  2019-01 => [2019-02-01, 2019-02-02]
   *
   * @type {moment.Moment[]}
   */
  const datesOfNextMonth = Array.from(
    Array(ReportCalendarUtils.ChunkSize - lastWeekdayOfThisMonth - 1)
  ).map((v, i) =>
    moment(date)
      .date(lastDateOfThisMonth)
      .add(i + 1, 'd')
  )

  const datesOfMonth = [
    ...datesOfPrevMonth,
    ...datesOfThisMonth,
    ...datesOfNextMonth,
  ]

  return datesOfMonth.reduce((prev, v, i) => {
    const chunkIndex = Math.floor(i / ReportCalendarUtils.ChunkSize)
    if (!prev[chunkIndex]) prev[chunkIndex] = []
    prev[chunkIndex].push(v)
    return prev
  }, [])
}

/**
 * preset 과 date(기준일) 으로 begin moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      begin: 2019-01-18
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment}
 * @param least {moment.Moment | undefined}
 * @return {moment.Moment}
 */
ReportCalendarUtils.beginByPreset = function (preset, date, least = undefined) {
  switch (preset) {
    case CalendarEnum.Preset.Type.LAST7DAYS: {
      return ReportCalendarUtils.coerceAtLeast(
        moment(date).subtract(7, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      return ReportCalendarUtils.coerceAtLeast(
        moment(date).subtract(14, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.THISWEEK: {
      return ReportCalendarUtils.coerceAtLeast(
        moment(date).startOf('isoWeek'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      return ReportCalendarUtils.coerceAtLeast(
        moment(date).subtract(30, 'd'),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.THISMONTH: {
      return ReportCalendarUtils.coerceAtLeast(
        moment(date).date(1),
        moment(least)
      )
    }

    case CalendarEnum.Preset.Type.YESTERDAY: {
      const subtracted = moment(date).subtract(1, 'd')

      return moment(least).isSameOrBefore(moment(subtracted), 'd')
        ? ReportCalendarUtils.coerceAtLeast(
            moment(date).subtract(1, 'd'),
            moment(least)
          )
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTWEEK: {
      const subtracted = moment(date).subtract(1, 'w')
      const firstDayOfLastWeek = moment(subtracted).startOf('isoWeek')

      return moment(least).isSameOrBefore(subtracted, 'w')
        ? ReportCalendarUtils.coerceAtLeast(firstDayOfLastWeek, moment(least))
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTMONTH: {
      const subtracted = moment(date).subtract(1, 'M')
      const firstDayOfLastMonth = moment(subtracted).date(1)

      return moment(least).isSameOrBefore(subtracted, 'M')
        ? ReportCalendarUtils.coerceAtLeast(firstDayOfLastMonth, moment(least))
        : undefined
    }

    default: {
      return moment().subtract(1, 'd')
    }
  }
}

/**
 * preset 과 date(기준일) 으로 end moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      end: 2019-01-24
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment}
 * @param least {moment.Moment | undefined}
 * @param most {moment.Moment | undefined}
 * @param begin {moment.Moment | undefined}
 * @return {moment.Moment}
 */
ReportCalendarUtils.endByPreset = function (
  preset,
  date,
  least = undefined,
  most = undefined,
  begin = undefined
) {
  switch (preset) {
    case CalendarEnum.Preset.Type.LAST7DAYS:
    case CalendarEnum.Preset.Type.LAST14DAYS:
    case CalendarEnum.Preset.Type.LAST30DAYS: {
      return moment(date).subtract(1, 'd')
    }

    case CalendarEnum.Preset.Type.THISWEEK:
    case CalendarEnum.Preset.Type.THISMONTH: {
      const isDuplicatedDate =
        moment(date).isSame(moment(begin), 'd') &&
        moment(date).isSame(moment(), 'd')

      return isDuplicatedDate ? moment(date) : moment(date).subtract(1, 'd')
    }

    case CalendarEnum.Preset.Type.YESTERDAY: {
      const subtracted = moment(date).subtract(1, 'd')

      return moment(least).isSameOrBefore(moment(subtracted), 'd') &&
        moment(most).isSameOrAfter(moment(subtracted), 'd')
        ? ReportCalendarUtils.coerceIn(subtracted, moment(least), moment(most))
        : undefined
    }

    case CalendarEnum.Preset.Type.LASTWEEK: {
      const subtracted = moment(date).subtract(1, 'w')
      const lastDayOfLastWeek = moment(subtracted).endOf('isoWeek')

      return moment(least).isSameOrBefore(moment(subtracted), 'w') &&
        moment(most).isSameOrAfter(moment(subtracted), 'w')
        ? ReportCalendarUtils.coerceIn(
            lastDayOfLastWeek,
            moment(least),
            moment(most)
          )
        : null
    }

    case CalendarEnum.Preset.Type.LASTMONTH: {
      const subtracted = moment(date).subtract(1, 'M')
      const lastDayOfLastMonth = moment(subtracted).endOf('M')

      return moment(least).isSameOrBefore(moment(subtracted), 'M') &&
        moment(most).isSameOrAfter(moment(subtracted), 'M')
        ? ReportCalendarUtils.coerceIn(
            lastDayOfLastMonth,
            moment(least),
            moment(most)
          )
        : null
    }

    default: {
      return moment().subtract(1, 'd')
    }
  }
}

/**
 * preset 과 date(기준일) 으로 begin, end moment 추출.
 *  ex)
 *    preset: CalendarEnum.Preset.Type.LAST7DAYS
 *    date: 2019-01-24
 *
 *    return {
 *      begin: 2019-01-18
 *      end: 2019-01-24
 *    }
 *
 * @param preset {CalendarEnum.Preset.Type}
 * @param date {moment.Moment | object | undefined}
 * @param least {moment.Moment | undefined}
 * @param most {moment.Moment | undefined}
 * @return {{end: moment.Moment | undefined, begin: moment.Moment | undefined}}
 */
ReportCalendarUtils.rangeByPreset = function (
  preset,
  date,
  least = moment().subtract(2, 'y'),
  most = undefined
) {
  const begin = ReportCalendarUtils.beginByPreset(preset, date, least)

  return {
    begin,
    end: ReportCalendarUtils.endByPreset(preset, date, least, most, begin),
  }
}

ReportCalendarUtils.coerceAtLeast = function (date, least) {
  return moment(date).isBefore(moment(least), 'd')
    ? moment(least)
    : moment(date)
}

ReportCalendarUtils.coerceAtMost = function (date, most) {
  return moment(date).isAfter(moment(most), 'd') ? moment(most) : moment(date)
}

ReportCalendarUtils.coerceIn = function (date, least, most) {
  return least && most && moment(date).isBefore(moment(least), 'd')
    ? moment(least)
    : moment(date).isAfter(moment(most), 'd')
    ? moment(most)
    : moment(date)
}

/**
 * begin, end moment 를 기반으로 기간 텍스트 출력.
 *  ex) 2019-01-24 ~ 2019-02-24
 *
 * @param begin {moment.Moment | Object}
 * @param end {moment.Moment | Object | undefined}
 * @return {string}
 */
ReportCalendarUtils.toString = function (begin, end = undefined) {
  if (!begin && !end) {
    return ''
  }

  return end
    ? `${dateToString(begin)} ~ ${dateToString(end)}`
    : dateToString(begin)
}

/**
 *
 * @param date {string | moment.Moment | Object}
 * @return {boolean}
 */
ReportCalendarUtils.isValid = function (date) {
  const m = moment(date)
  return m.year() > 0 && m.month() >= 0 && m.date() > 0
}

ReportCalendarUtils.toStringByPeriodType = ({
  periodType,
  startDate,
  endDate,
  customDateDiff,
}) => {
  switch (periodType) {
    case CalendarEnum.Preset.Type.YESTERDAY: {
      if (moment(endDate).isSame(moment().subtract(1, 'd'), 'd')) {
        return '어제'
      }

      return `${moment().diff(moment(endDate), 'd')}일 전`
    }

    case CalendarEnum.Preset.Type.THISWEEK:
    case CalendarEnum.Preset.Type.LASTWEEK: {
      if (
        moment(endDate).isoWeek() === moment().isoWeek() &&
        moment(endDate).year() === moment().year()
      ) {
        return '이번 주'
      }

      if (
        moment(endDate).isoWeek() === moment().subtract(1, 'w').isoWeek() &&
        moment(endDate).year() === moment().year()
      ) {
        return '지난 주'
      }

      return `${Math.ceil(moment().diff(moment(endDate), 'w', true))}주 전`
    }

    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      if (
        moment(endDate).isSame(moment(), 'M') &&
        moment(endDate).year() === moment().year()
      ) {
        return '이번 달'
      }

      if (
        moment(endDate).isSame(moment().subtract(1, 'M'), 'M') &&
        moment(endDate).year() === moment().year()
      ) {
        return '지난 달'
      }

      return `${Math.ceil(moment().diff(moment(endDate), 'M', true))}개월 전`
    }

    case CalendarEnum.Preset.Type.LAST7DAYS: {
      if (moment(endDate).isSame(moment().subtract(1, 'd'), 'd')) {
        return CalendarEnum.Preset.getName(periodType)
      }

      return '7일 단위'
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      if (moment(endDate).isSame(moment().subtract(1, 'd'), 'd')) {
        return CalendarEnum.Preset.getName(periodType)
      }

      return '14일 단위'
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      if (moment(endDate).isSame(moment().subtract(1, 'd'), 'd')) {
        return CalendarEnum.Preset.getName(periodType)
      }

      return '30일 단위'
    }

    case CalendarEnum.Preset.Type.CUSTOM: {
      return `${customDateDiff + 1}일 단위`
    }

    default: {
      return `${
        Math.abs(moment(startDate).diff(moment(endDate), 'd')) + 1
      }일 단위`
    }
  }
}

ReportCalendarUtils.Paging = {}

ReportCalendarUtils.Paging.goPrev = (
  createdDate,
  startDate,
  endDate,
  periodType,
  customDateDiff
) => {
  const least = ReportCalendarUtils.coerceAtLeast(
    createdDate,
    moment().subtract(2, 'y')
  )

  if (moment(startDate).isSameOrBefore(least, 'd')) return

  switch (periodType) {
    case CalendarEnum.Preset.Type.YESTERDAY: {
      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newStartDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST7DAYS: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )

      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).subtract(6, 'd'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LASTWEEK:
    case CalendarEnum.Preset.Type.THISWEEK: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(endDate).subtract(1, 'w').endOf('isoWeek'),
        least
      )

      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).startOf('isoWeek'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )

      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).subtract(13, 'd'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )

      const nextStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).subtract(29, 'd'),
        least
      )

      return {
        startDate: nextStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(endDate).subtract(1, 'M').endOf('M'),
        least
      )

      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).startOf('M'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.CUSTOM: {
      const newEndDate = ReportCalendarUtils.coerceAtLeast(
        moment(startDate).subtract(1, 'd'),
        least
      )

      const newStartDate = ReportCalendarUtils.coerceAtLeast(
        moment(newEndDate).subtract(customDateDiff, 'd'),
        least
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    default: {
      return { startDate, endDate, periodType }
    }
  }
}

ReportCalendarUtils.Paging.goNext = (
  startDate,
  endDate,
  periodType,
  customDateDiff
) => {
  const most = moment().subtract(1, 'd')

  if (moment(endDate).isSameOrAfter(most, 'd')) return

  switch (periodType) {
    case CalendarEnum.Preset.Type.YESTERDAY: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(startDate).add(1, 'd'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newStartDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST7DAYS: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(endDate).add(1, 'd'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).add(6, 'd'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LASTWEEK:
    case CalendarEnum.Preset.Type.THISWEEK: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(startDate).add(1, 'w').startOf('isoWeek'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).endOf('isoWeek'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(endDate).add(1, 'd'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).add(13, 'd'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(endDate).add(1, 'd'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).add(29, 'd'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.LASTMONTH:
    case CalendarEnum.Preset.Type.THISMONTH: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(startDate).add(1, 'M').startOf('M'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).endOf('M'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    case CalendarEnum.Preset.Type.CUSTOM: {
      const newStartDate = ReportCalendarUtils.coerceAtMost(
        moment(endDate).add(1, 'd'),
        most
      )

      const newEndDate = ReportCalendarUtils.coerceAtMost(
        moment(newStartDate).add(customDateDiff, 'd'),
        most
      )

      return {
        startDate: newStartDate,
        endDate: newEndDate,
        periodType,
      }
    }

    default: {
      return { startDate, endDate, periodType }
    }
  }
}

ReportCalendarUtils.dateByPreset = ({ startDate, endDate, periodType }) => {
  switch (periodType) {
    case CalendarEnum.Preset.Type.YESTERDAY: {
      return {
        startDate: moment().subtract(1, 'd'),
        endDate: moment().subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.THISWEEK: {
      return {
        startDate: moment().startOf('isoWeek'),
        endDate: moment().subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.LASTWEEK: {
      return {
        startDate: moment().subtract(1, 'w').startOf('isoWeek'),
        endDate: moment().subtract(1, 'w').startOf('isoWeek'),
      }
    }

    case CalendarEnum.Preset.Type.LAST7DAYS: {
      return {
        startDate: moment().subtract(7, 'd'),
        endDate: moment().subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.LAST14DAYS: {
      return {
        startDate: moment().subtract(14, 'd'),
        endDate: moment().subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.LAST30DAYS: {
      return {
        startDate: moment().subtract(30, 'd'),
        endDate: moment().subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.LASTMONTH: {
      return {
        startDate: moment({ day: 1 }).subtract(1, 'M'),
        endDate: moment({ day: 1 }).subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.THISMONTH: {
      return {
        startDate: moment({ day: 1 }),
        endDate: moment({ day: 1 }).subtract(1, 'd'),
      }
    }

    case CalendarEnum.Preset.Type.CUSTOM: {
      return {
        startDate: moment(startDate),
        endDate: moment(endDate),
      }
    }

    default: {
      // 초기값 LAST7DAYS
      return {
        startDate: moment().subtract(7, 'd'),
        endDate: moment().subtract(1, 'd'),
      }
    }
  }
}

export default ReportCalendarUtils
