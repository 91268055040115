import { keyMirror } from '../../utils/utils'

// 실제 화면과 동일한 순서로
const PERSONAL_MESSAGE_FORM_VALIDATION_KEY = keyMirror({
  ITEM: null,
  VIDEO_TITLE: null,
  CAROUSEL_INTRO_LANDING: null,
  ITEM_ASSET_GROUPS: null,
  TITLE: null,
  DESCRIPTION: null,
  BUTTON_ASSET_GROUPS: null,
  COUPON_BOOK_ASSET_GROUPS: null,
  CAROUSEL_MORE: null,
  NAME: null,
})

export { PERSONAL_MESSAGE_FORM_VALIDATION_KEY }
