import React from 'react'
import { keyMirror } from '../../../../../utils/utils'

const BIZ_BOARD_IMAGE_EDIT_STATE = keyMirror({
  IDLE: null,
  DRAG_START: null,
  DRAGGING: null,
  DRAG_END: null,
  DRAG_ENTER: null,
  DRAG_LEAVE: null,
  RESTORE: null,
  SCALE_UP: null,
  SCALE_DOWN: null,
  EDIT_START: null,
})

const useBizBoardImageEditState = () => {
  const [editState, setEditState] = React.useState(
    BIZ_BOARD_IMAGE_EDIT_STATE.IDLE
  )

  const listener = {
    onDragStart: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.DRAG_START),
    onDrag: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.DRAGGING),
    onDragEnd: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.DRAG_END),
    onDragEnter: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.DRAG_ENTER),
    onDragLeave: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.DRAG_LEAVE),
    onDoubleClick: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.RESTORE),
    onWheel: dy =>
      setEditState(
        dy > 0
          ? BIZ_BOARD_IMAGE_EDIT_STATE.SCALE_UP
          : BIZ_BOARD_IMAGE_EDIT_STATE.SCALE_DOWN
      ),
    onClick: () => setEditState(BIZ_BOARD_IMAGE_EDIT_STATE.EDIT_START),
  }

  return {
    editState,
    listener,
  }
}

export { useBizBoardImageEditState, BIZ_BOARD_IMAGE_EDIT_STATE }
