import { CONTEXTPATH, fetchPost, queryString } from '../../utils/fetchUtils'
import { v4 as uuid } from 'uuid'
import { CreativeHelper } from '../../utils/helper/helper-creative'

/**
 *
 * @param adAccountId
 * @param requestBody
 *  {
 *    assetPropertyTypes: ['PROFILE_NAME', 'IMAGE', 'OBJET_IMAGE'],
 *    imageSizes: [
 *      { width: 640, height: 480, minWidth: null, ratio: null },
 *      { width: null, height: null, minWidth: 500, ratio: 1.0 }
 *    ],
 *  }
 * @param pageRequest
 *  { page: number, size: number }
 * @param cancelTokenSource
 */
function getLatestCreativeAssetImages(
  adAccountId,
  requestBody,
  pageRequest = { page: 0, size: 50 },
  cancelTokenSource
) {
  return fetchPost(
    `${CONTEXTPATH}/assets/images/getLatest?${queryString(pageRequest)}`,
    requestBody,
    adAccountId,
    cancelTokenSource
  ).then(response => {
    response.data.content =
      response.data.content?.map(image => {
        return {
          ...image,
          ...CreativeHelper.Image.toView(image),
          ...CreativeHelper.Image.toAPI(image),
          imageUUID: uuid(),
        }
      }) || []

    return response
  })
}

export default { getLatestCreativeAssetImages }
