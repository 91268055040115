import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    ACTIVATED: null,
    DEACTIVATED: null,
    DELETING: null,
    DELETED: null,
  }),

  Name: {
    ACTIVATED: '공개',
    DEACTIVATED: '비공개',
    DELETING: '삭제대기',
    DELETED: '삭제됨',
  },

  isNotActivatedStatus(status) {
    return [
      this.Type.DEACTIVATED,
      this.Type.DELETING,
      this.Type.DELETED,
    ].includes(status)
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  notActivatedValues() {
    return this.values().filter(type => type !== this.Type.ACTIVATED)
  },
}
