import React from 'react'
import ReactRouterPropTypes from 'react-router-prop-types'
import { Route, Switch } from 'react-router-dom'
import Footer from '../Footer/Footer'
import Gnb from '../Navigation/Gnb/Gnb'
import Lnb from '../Navigation/Lnb/Lnb'

const AdViewManagementPage = React.lazy(() => import('./AdViewManagementPage'))
const AdViewCreate = React.lazy(() => import('./AdViewCreatePage'))
const AdViewDetail = React.lazy(() => import('./AdViewDetail/AdViewDetail'))

const AdViewWrapper = () => {
  return (
    <div id="kakaoWrap">
      <Gnb />
      <hr className="hide" />
      <main id="kakaoContent">
        <Lnb />
        <React.Suspense fallback="">
          <Switch>
            <Route
              path="/:adaccountid(\d+)/adview/:adviewid(\d+)/modify"
              component={AdViewCreate}
            />
            <Route
              path="/:adaccountid(\d+)/adview/:adviewid(\d+)"
              component={AdViewDetail}
            />
            <Route
              path="/:adaccountid(\d+)/adview/create"
              component={AdViewCreate}
            />
            <Route
              path="/:adaccountid(\d+)/adview"
              component={AdViewManagementPage}
            />
          </Switch>
        </React.Suspense>
      </main>
      <hr className="hide" />
      <Footer />
    </div>
  )
}

AdViewWrapper.propTypes = {
  location: ReactRouterPropTypes.location,
  history: ReactRouterPropTypes.history,
}

export default AdViewWrapper
