import { keyMirror } from '../utils/utils'

export default {
  Type: keyMirror({
    CLICK: null,
    CONVERSION: null,
    CONVERSION_VALUE: null,
  }),

  Name: {
    CLICK: '클릭수 최대화',
    CONVERSION: '전환수 최대화',
    CONVERSION_VALUE: '전환 가치 최대화',
  },

  values() {
    return Object.freeze(Object.keys(this.Type))
  },

  getName(type) {
    return this.Name[type]
  },
}
