import cx from 'classnames'
import ActionButtonEnum from '../../../../../../enums/ActionButtonEnum'
import React from 'react'
import ImageNativeTemplatePropTypes from '../imageNativeTemplatePropTypes'
import CreativeTemplateEnum from '../../../../../../enums/CreativeTemplateEnum'
import { checkNotEmpty } from '../../../../../../utils/regexUtils'

export const Short_Form_9_16_Page14 = ({
  title,
  actionButton,
  profileName,
  description,
  promotionalImageURL,
  profileImageURL,
  ratio = CreativeTemplateEnum.Type.RATIO_9_TO_16,
}) => {
  return (
    <div
      className={cx('material_default', 'material_shortform', {
        shortform_type1: ratio === CreativeTemplateEnum.Type.RATIO_2_TO_1,
        shortform_type2: ratio === CreativeTemplateEnum.Type.RATIO_1_TO_1,
      })}>
      <div className="inner_shortform">
        <div className="bg_top" />
        <div className="bg_bottom" />
        <div className="thumb_img">
          {promotionalImageURL &&
            [
              CreativeTemplateEnum.Type.RATIO_1_TO_1,
              CreativeTemplateEnum.Type.RATIO_2_TO_1,
            ].includes(ratio) && (
              <div
                className="item_bg"
                style={{
                  backgroundImage: `url(${promotionalImageURL})`,
                  backgroundPositionX: `50%`,
                  backgroundPositionY: `50%`,
                  backgroundSize: `cover`,
                }}></div>
            )}
          <div
            className="item_thumb"
            style={
              checkNotEmpty(promotionalImageURL)
                ? {
                    backgroundImage: `url(${promotionalImageURL})`,
                    backgroundPositionX: `50%`,
                    backgroundPositionY: `50%`,
                    backgroundSize: `cover`,
                  }
                : undefined
            }></div>
        </div>
        <div className="thumb_detail">
          <div className="profile_info">
            <span className="thumb_profile">
              {profileImageURL && (
                <img
                  src={profileImageURL}
                  alt="프로필 이미지"
                  className="img_profile"
                  style={{ backgroundColor: `#ffffff` }}
                />
              )}
            </span>
            <span className="info_profile">
              <span className="inner_info">
                <strong className="tit_profile">
                  {profileName || ' 프로필 이름'}
                </strong>
              </span>
            </span>
          </div>
          <strong className="tit_induce">{title || '타이틀'}</strong>
          <span className="txt_induce">{description || '홍보문구'}</span>
          <span className="ad_mark">AD</span>
          <div className="wrap_btn">
            <span
              className={cx('btn_adview', {
                no_text: actionButton === ActionButtonEnum.Type.NONE,
              })}>
              {ActionButtonEnum.getName(actionButton) || '버튼 선택'}
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

Short_Form_9_16_Page14.propTypes = ImageNativeTemplatePropTypes.Page
