import { keyMirror } from '../utils/utils'

/**
 * https://kakao.agit.in/g/435976/wall/338028360#comment_panel_338162799
 * sectionCategories
 *  - KK099-0 : 오픈채팅탭 제외
 *  - KKO99-1 : 채팅탭 (오픈채팅 포함) <-- 현재 반영된 사항
 *  - KKO99-2 : 오픈채팅 (채팅탭에서 오픈채팅이 분리될 경우)
 */
export default {
  Type: keyMirror({
    CHAT_TAB: null,
    OPEN_CHAT_TAB: null,
  }),

  Code: {
    CHAT_TAB: 'KKO99-1',
    OPEN_CHAT_TAB: 'KKO99-0',
  },

  getCode(type) {
    return this.Code[type]
  },
}
