import { CONTEXTPATH, fetchGet, fetchPost } from '../../utils/fetchUtils'

/**
 * 7.4.8 (구조개편이후) 조합 예상모수 조회 : 7.4.3 을 대체하는 API
 */
function fetchCombinedTargetingPopulationByAdGroupFormV2(
  adAccountId,
  adGroupForm,
  cancelTokenSource
) {
  return fetchPost(
    `${CONTEXTPATH}/targeting/population/combinedScore`,
    adGroupForm,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 7.4.9 (구조개편이후) 다건 모수 조회 : 7.4.2 + 7.4.6 을 대체하는 API
 */
function fetchTargetingPopulationByValueSetV2(adAccountId, valueSet) {
  return fetchPost(
    `${CONTEXTPATH}/targeting/population/targetScore`,
    valueSet,
    adAccountId
  )
}

/**
 * 광고그룹 최적화상태 조회
 * @param adAccountId
 * @param adGroupIds
 * @param cancelTokenSource
 */
function fetchAdGroupOptimizationStatus(
  adAccountId,
  adGroupIds,
  cancelTokenSource
) {
  return fetchGet(
    `${CONTEXTPATH}/adGroups/optimizationStatusInfo?adGroupIds=${adGroupIds}`,
    adAccountId,
    cancelTokenSource
  )
}

/**
 * 픽셀 & SDK 이벤트 타입별 최근 30일 모수 조회
 * @param adAccountId
 * @param trackId
 */
function fetchTrackerEventPopulation(adAccountId, trackId) {
  return fetchGet(
    `${CONTEXTPATH}/targeting/trackers/${trackId}/population`,
    adAccountId
  )
}

export default {
  fetchCombinedTargetingPopulationByAdGroupFormV2,
  fetchTargetingPopulationByValueSetV2,
  fetchAdGroupOptimizationStatus,
  fetchTrackerEventPopulation,
}
