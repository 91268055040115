import { fromJS } from 'immutable'
import { keyMirror } from '../../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import {
  closeAllPopup,
  closePopup,
  openPopupByProxy,
  POPUP_KEY,
} from '../../common/mPopup'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import React from 'react'
import { hideLoading, showLoading } from '../../common/mLoading'
import { ERROR_CODE } from '../../../utils/errorCode'

const BizFormList = keyMirror({
  GET_AUTHORIZED_BIZ_FORM_LIST: null,
  GET_UNAUTHORIZED_BIZ_FORM_LIST: null,
  CHANGE_AUTHORIZED_BIZ_FORM_LIST_PAGE_INDEX: null,
})

const initialState = fromJS({
  authorizedBizFormList: [],
  authorizedBizFormListWithPaging: [],
  unauthorizedBizFormList: [],
  pageIndex: 0,
})

export default createReducer(initialState, {
  [BizFormList.GET_AUTHORIZED_BIZ_FORM_LIST]: (state, { data }) => {
    return state.set('authorizedBizFormList', fromJS(data || []))
  },
  [BizFormList.GET_UNAUTHORIZED_BIZ_FORM_LIST]: (state, { data }) => {
    return state.set('unauthorizedBizFormList', fromJS(data || []))
  },
  [BizFormList.CHANGE_AUTHORIZED_BIZ_FORM_LIST_PAGE_INDEX]: (
    state,
    { pageIndex }
  ) => {
    return state
      .set(
        'authorizedBizFormListWithPaging',
        state
          .get('authorizedBizFormList')
          .slice(pageIndex * 10, (pageIndex + 1) * 10)
      )
      .set('pageIndex', pageIndex)
  },
})

export const getAuthorizedBizFormList = data => ({
  type: BizFormList.GET_AUTHORIZED_BIZ_FORM_LIST,
  data,
})

export const getUnauthorizedBizFormList = data => ({
  type: BizFormList.GET_UNAUTHORIZED_BIZ_FORM_LIST,
  data,
})

export const changeBizFormListPageIndex = pageIndex => ({
  type: BizFormList.CHANGE_AUTHORIZED_BIZ_FORM_LIST_PAGE_INDEX,
  pageIndex,
})

/**
 * 17.1 연동된 카카오 비즈니스폼 리스트 조회
 * @param adAccountId
 * @param onFinish
 * @returns {function(*, *, *): Promise<unknown>}
 */
export const fetchAuthorizedBizFormList = (adAccountId, onFinish) => {
  return async (dispatch, getState, api) => {
    try {
      const response = await api.bizForm.fetchAuthorizedBizFormList(
        adAccountId,
        true
      )
      dispatch(getAuthorizedBizFormList(response.data))
      dispatch(changeBizFormListPageIndex(0))
    } catch (e) {
      const { errorCode, message, detail } = e?.response?.data || {}
      dispatch(handleBizFormException({ errorCode, message, detail }))
    } finally {
      if (typeof onFinish === 'function') {
        onFinish()
      }
    }
  }
}

/**
 * 17.4 비즈니스폼 연동가능 리스트 조회
 * @param adAccountId
 * @param onStart
 * @param onFinish
 * @returns {function(*, *, *): Promise<unknown>}
 */
export const fetchUnauthorizedBizFormList = (
  adAccountId,
  onStart,
  onFinish
) => {
  return async (dispatch, getState, api) => {
    if (typeof onStart === 'function') {
      onStart()
    }
    try {
      const response = await api.bizForm.fetchUnauthorizedBizFormList(
        adAccountId
      )
      dispatch(getUnauthorizedBizFormList(response.data))
    } catch (e) {
      const { errorCode, message, detail } = e?.response?.data || {}
      dispatch(
        handleBizFormException({
          errorCode,
          message,
          detail,
          adAccountId,
        })
      )
    } finally {
      if (typeof onFinish === 'function') {
        onFinish()
      }
    }
  }
}

/**
 * 17.3 비즈니스폼 연동
 * @param adAccountId
 * @param bizFormId
 * @param reload
 * @returns {function(*, *, *): Promise<unknown>}
 */
export const fetchBizFormLinkage = (adAccountId, bizFormId, reload) => {
  return async (dispatch, getState, api) => {
    dispatch(showLoading())
    try {
      await api.bizForm.fetchBizFormLinkage(adAccountId, {
        bizFormId,
      })
      dispatch(fetchAuthorizedBizFormList(adAccountId))
      dispatch(changeBizFormListPageIndex(0))
      dispatch(closePopup(POPUP_KEY.ADD_BIZ_FORM_AUTHORITY))
    } catch (e) {
      const { errorCode, message, detail } = e?.response?.data || {}
      dispatch(
        handleBizFormException({
          errorCode,
          message,
          detail,
          adAccountId,
          reload,
        })
      )
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function unknownBizFormException(errorCode, message) {
  return dispatch => {
    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy onDismiss={() => dispatch(closeAllPopup())}>
          <strong className="tit_layer">시스템 오류</strong>
          <p className="txt_layer">
            시스템 오류가 발생하였습니다.
            <br />
            잠시 후 다시 시도하세요.
            <br />
            시스템 오류가 반복될 경우 처음부터 다시 진행하세요.
            <br />
            (errorCode: {errorCode}, message: {message})
          </p>
        </PopupProxy>
      )
    )
  }
}

export const handleBizFormException = ({
  errorCode,
  message = '',
  detail,
  ...rest
}) => {
  return dispatch => {
    const { reload } = rest

    switch (Number(errorCode)) {
      // 톡 비즈니스폼이 종료, 긴급종료, 삭제인 경우
      case 90001: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy onDismiss={reload}>
              <strong className="tit_layer">비즈니스폼 연동 불가</strong>
              <p className="txt_layer">
                해당 비즈니스폼은 권한을 추가할 수 없는 상태입니다.
                <br />
                다른 비즈니스폼을 선택하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 톡 비즈니스폼에 권한이 없는 경우
      case 33303: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy onDismiss={reload}>
              <strong className="tit_layer">비즈니스폼 연동 불가</strong>
              <p className="txt_layer">
                해당 비즈니스폼의 권한이 없습니다.
                <br />
                비즈니스폼에서 권한 정보를 확인하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }
      case ERROR_CODE.IN_HOUSE_AD_ACCOUNT_FORBIDDEN:
        break

      default: {
        dispatch(unknownBizFormException(errorCode, message))
        break
      }
    }
  }
}
