// https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
const isPassiveSupported = (function () {
  let isSupported = false
  try {
    const options = Object.defineProperty({}, 'passive', {
      get() {
        isSupported = true
        return isSupported
      },
    })

    window.addEventListener('testPassive', null, options)
    window.removeEventListener('testPassive', null, options)
  } catch (e) {
    // ignore
  }
  return isSupported
})()

export default isPassiveSupported
