import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { fromJS } from 'immutable'
import {
  buildPlusFriendGroupsPopulationBody,
  PLUS_FRIEND_GROUP_STATUS,
} from '../mPlusFriendGroup'
import { getMultiplePfGroupTargetPopulation } from '../mPopulation'

/**
 * 친구그룹 가져오기
 */
const PlusFriendGroupKey = keyMirror({
  GET_PLUS_FRIEND_GROUPS: null,
  GET_PLUS_FRIEND_GROUPS_WITH_PAGING: null,
  GET_PLUS_FRIEND_GROUPS_POPULATION: null,
  GET_READY_PLUS_FRIEND_GROUPS: null,
  GET_READY_PLUS_FRIEND_GROUPS_POPULATION: null,
})

const initialState = fromJS({
  readyPlusFriendGroups: [],
})

export default createReducer(initialState, {
  [PlusFriendGroupKey.GET_READY_PLUS_FRIEND_GROUPS]: (state, { data }) => {
    const readyPlusFriendGroups = fromJS(data)

    return state.set(
      'readyPlusFriendGroups',
      readyPlusFriendGroups.size > 0
        ? readyPlusFriendGroups.map(v => v.set('populationScore', null))
        : readyPlusFriendGroups
    )
  },

  [PlusFriendGroupKey.GET_READY_PLUS_FRIEND_GROUPS_POPULATION]: (
    state,
    { data }
  ) => {
    const { readyPlusFriendGroups } = state

    const newReadyPlusFriendGroups = data.reduce(
      (prevContent, populationResponse) => {
        const { targetingValue, plusFriendProfileId, populationScore } =
          populationResponse
        return prevContent.update(
          prevContent.findIndex(
            f =>
              f.get('targetingValue') === targetingValue &&
              f.get('plusFriendProfileId') === plusFriendProfileId
          ),
          undefined,
          plusFriendGroup =>
            plusFriendGroup?.set('populationScore', populationScore)
        )
      },
      readyPlusFriendGroups
    )

    return state.set('readyPlusFriendGroups', newReadyPlusFriendGroups)
  },
})

function receiveReadyPlusFriendGroups(data) {
  return {
    type: PlusFriendGroupKey.GET_READY_PLUS_FRIEND_GROUPS,
    data,
  }
}

export function fetchPlusFriendGroupList(adAccountId, onFinish) {
  return async (dispatch, getState, api) => {
    try {
      const {
        data: { content },
      } = await api.plusFriendGroup.getPlusFriendGroupFiles(adAccountId, 0, 50)

      const newContents = content
        .filter(p => p.status !== PLUS_FRIEND_GROUP_STATUS.ERROR)
        .map(v => ({ ...v, targetingValue: v.groupKey }))

      dispatch(receiveReadyPlusFriendGroups(newContents))

      onFinish && onFinish()
    } catch (e) {
      console.log(e.message)
      onFinish && onFinish()
    }
  }
}

export function getReadyPlusFriendGroupsPopulation(data) {
  return {
    type: PlusFriendGroupKey.GET_READY_PLUS_FRIEND_GROUPS_POPULATION,
    data,
  }
}
