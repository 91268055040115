import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../utils/utils'
import { fromJS } from 'immutable'

// constants
const Notification = keyMirror({
  HIDE_BROWSER_ALERT: null
})

// initial state
const initialState = fromJS({
  isVisibleBrowserAlert: false
})

// reducer
export default createReducer(initialState, {
  [Notification.HIDE_BROWSER_ALERT]: state =>
    state.set('isVisibleBrowserAlert', true)
})

// header > 브라우저 미지원 안내 alert 가리기
export const hideBrowserAlert = () => ({
  type: Notification.HIDE_BROWSER_ALERT
})
