import axios from 'axios'
import {
  createCancellation,
  deleteCancellation,
} from '../../../utils/cancellation/cancellation'
import { setDataSetForCategoryTargetingTree } from '../mTargeting'
import { CATEGORY_TARGET_KEY } from '../../../utils/advertise/targeting'
import { fromJS } from 'immutable'
import {
  FAILED_POPULATION,
  LOADING_POPULATION,
  receiveCombinedPopulation,
} from '../mPopulation'
import { updateCustomTargetPopulation } from '../targetingActions/aTargeting'
import { openPopupByProxy, POPUP_KEY } from '../../common/mPopup'
import PopupProxy from '../../../components/Popup/Common/PopupProxy'
import React from 'react'
import { showErrorMessage } from '../../../utils/alertUtils'
import {
  exceedAudienceCountDialog,
  modifyUnavailableAudienceDialog,
} from '../../../components/Targeting/Audience/Common/Dialog/AudienceDialog'
import { toAudienceManagementPath } from '../../../utils/router/routeUtils'
import { checkNotEmpty } from '../../../utils/regexUtils'
import { RouterV2 } from '../../../stores/middleware/routerMiddleware'

/**
 *  오디언스 조합모수 조회
 */
export const fetchAudienceCombinedPopulation = (
  adAccountId,
  audienceForm,
  onSuccess,
  onError
) => {
  return async (dispatch, getState, api) => {
    const cancelToken = createCancellation('fetchAudienceCombinedPopulation')

    try {
      const { data } = await api.audience.fetchAudienceCombinedPopulation(
        adAccountId,
        audienceForm,
        cancelToken
      )

      if (typeof onSuccess === 'function') {
        onSuccess(data)
      }
    } catch (e) {
      if (!axios.isCancel(e)) {
        console.log(e.message)

        if (typeof onError === 'function') {
          onError(e)
        }
      }
    } finally {
      deleteCancellation(cancelToken)
    }
  }
}

/**
 * 타겟 관리 > 맞춤타겟 > 내 데이터 > targetScore
 */
export const updateAudienceTargetPopulation = () => {
  return (dispatch, getState) => {
    const {
      audience: {
        audienceCreate: { audienceForm },
      },
    } = getState()

    const { adAccountId, audienceType } = audienceForm

    dispatch(
      updateCustomTargetPopulation({
        adAccountId,
        targeting: audienceForm,
        audienceType,
      })
    )
  }
}

export const fetchAudienceFormCombinedPopulation = (audienceForm, dispatch) => {
  const { adAccountId } = audienceForm

  const onSuccess = population => {
    dispatch(receiveCombinedPopulation(population))
  }

  const onError = e => {
    const errorPopulation = axios.isCancel(e)
      ? LOADING_POPULATION
      : FAILED_POPULATION
    dispatch(receiveCombinedPopulation(errorPopulation))
  }

  dispatch(receiveCombinedPopulation(LOADING_POPULATION))
  dispatch(
    fetchAudienceCombinedPopulation(
      adAccountId,
      audienceForm,
      onSuccess,
      onError
    )
  )
}

/**
 * 타겟 관리 > 오디언스 만들기 > combinedScore
 */
export const updateAudienceCombinedPopulation = () => {
  return (dispatch, getState) => {
    const {
      audience: {
        audienceCreate: { audienceForm },
      },
    } = getState()

    fetchAudienceFormCombinedPopulation(audienceForm, dispatch)
  }
}

/**
 *  오디언스관리에서 카테고리 타게팅 사용하기 위한 처리
 */
export const fetchAudienceCategoryData = (adAccountId, onSuccess, onFinish) => {
  return async (dispatch, getState, api) => {
    try {
      const {
        audience: {
          audienceCreate: { audienceForm },
        },
      } = getState()

      const [interestResponse, businessResponse] = await axios.all([
        api.targeting.fetchKakaoDataCategory(adAccountId, 'INTEREST'),
        api.targeting.fetchKakaoDataCategory(adAccountId, 'BUSINESS_TYPE'),
      ])

      const { data: interests } = interestResponse.data
      const { data: business } = businessResponse.data

      if (typeof onSuccess === 'function') {
        onSuccess(interests, business)
      }

      setDataSetForCategoryTargetingTree(
        CATEGORY_TARGET_KEY.interests,
        fromJS(interests),
        audienceForm,
        dispatch
      )

      setDataSetForCategoryTargetingTree(
        CATEGORY_TARGET_KEY.business,
        fromJS(business),
        audienceForm,
        dispatch
      )
    } catch (e) {
      console.log(e.message)
    } finally {
      if (typeof onFinish === 'function') {
        onFinish()
      }
    }
  }
}

export const handleAudienceError = ({ e, dispatch, adAccountId }) => {
  const { errorCode, message } = e?.response?.data || {}

  switch (errorCode) {
    case 22300: {
      showErrorMessage('지원 종료된 타겟입니다.')
      break
    }

    case 22405: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          <PopupProxy>
            <strong className="tit_layer">오디언스 수정 불가</strong>
            <p className="txt_layer">{message}</p>
          </PopupProxy>
        )
      )
      break
    }

    case 22406: {
      dispatch(
        openPopupByProxy(
          POPUP_KEY.SIMPLE_POPUP,
          modifyUnavailableAudienceDialog()
        )
      )
      break
    }

    case 22407: {
      showErrorMessage('이미 오디언스 사용 해제 되었습니다.')
      break
    }

    // audienceId에 해당하는 타겟이 존재하지 않는 경우
    case 22403: {
      showErrorMessage(message)

      if (checkNotEmpty(adAccountId)) {
        dispatch(RouterV2.replace(toAudienceManagementPath(adAccountId)))
      }
      break
    }

    // 광고계정 내 오디언스 개수 제한
    case 22400: {
      dispatch(
        openPopupByProxy(POPUP_KEY.SIMPLE_POPUP, exceedAudienceCountDialog())
      )
      break
    }

    // 모든 이벤트는 제외지만 같은 트랙의 다른 이벤트가 포함일 경우
    case 89717: {
      showErrorMessage(message)
      break
    }

    default:
      break
  }
}
