import { CONTEXTPATH, fetchGet } from '../../utils/fetchUtils'
import cruxFetchUtils from '../../utils/cruxFetchUtils'

export const SkanApi = {
  fetchAppIdList(adAccountId, trackId) {
    return fetchGet(`${CONTEXTPATH}/track/${trackId}/apps`, adAccountId)
  },

  fetchLinkedAppIdInfo(adAccountId, appId, sourceId) {
    return cruxFetchUtils.get({
      url: `/bcon/v2/services/moment/app/source/${appId}/${sourceId}`,
      adAccountId,
    })
  },
}
