import React from 'react'
import { fromJS, Map } from 'immutable'
import { keyMirror } from '../../utils/utils'
import { createReducer } from 'redux-immutablejs'
import { Trim } from '../../utils/formTrim'
import { closeAllPopup, openPopupByProxy, POPUP_KEY } from '../common/mPopup'
import {
  toCustomerFilesListPath,
  toInvalidApproachPath,
} from '../../utils/router/routeUtils'
import { Validation } from '../../validators/validation'
import { showErrorMessage } from '../../utils/alertUtils'
import {
  CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY,
  CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY_PATH,
  CUSTOMER_FILES_UPLOAD_FORM_VALIDATOR,
} from '../../validators/targeting/customerFilesUploadFormValidator'
import PopupProxy from '../../components/Popup/Common/PopupProxy'
import { hideLoading, showLoading } from '../common/mLoading'
import { RouterV2 } from '../../stores/middleware/routerMiddleware'

export const CUSTOMER_FILES_CSV_SIZE = 200 * 1024 * 1024

const CustomerFilesUpload = keyMirror({
  INIT_CUSTOMER_FILE_LIST_WITH_PAGING: null,
  GET_CUSTOMER_FILE_LIST_WITH_PAGING: null,
  GET_UPLOADED_CUSTOMER_FILES: null,
  REMOVE_UPLOADED_CUSTOMER_FILES: null,

  RECEIVE_UPLOADED_CUSTOMER_FILES_RESULT_DATA_FOR_TARGETING: null,
  INIT_UPLOADED_CUSTOMER_FILES: null,

  SET_CUSTOMER_FILES_UPLOAD_STATE: null,

  SET_IS_VALID_CUSTOMER_FILES_UPLOAD_BY_KEY: null,
  INIT_CUSTOMER_FILES_UPLOAD_VALIDATION_ERROR_KEYS: null,

  GET_CUSTOMER_FILES_DETAIL_INFO: null,
  GET_CUSTOMER_FILES_AD_GROUP_LIST: null,
  INIT_CUSTOMER_FILES_DETAIL: null,

  GET_READY_CUSTOMER_FILES: null,
  INIT_READY_CUSTOMER_FILES: null,

  CHANGE_CUSTOMER_FILES_CSV_UPLOAD_FORM_BY_KEY: null,
  INIT_CUSTOMER_FILES_CSV_UPLOAD_FORM: null,

  CHANGE_CUSTOMER_FILES_URL_UPLOAD_FORM_BY_KEY: null,
  INIT_CUSTOMER_FILES_URL_UPLOAD_FORM: null,
})

export const CUSTOMER_FILES_STATUS = keyMirror({
  WAITING: null,
  COMPLETE: null,
  ERROR: null,
  MODIFYING: null,
  MODIFYING_ERROR: null,
  TRANSFORM_ERROR: null,
  TRANSFORM_MODIFYING_ERROR: null,
})

export const CUSTOMER_FILES_STATUS_TEXT = {
  WAITING: '준비 중',
  COMPLETE: '조회 중',
  ERROR: '생성 실패',
  MODIFYING: '수정된 모수 준비중',
  TRANSFORM_ERROR: '등록 실패',
  TRANSFORM_MODIFYING_ERROR: '갱신 실패',
}

export const CUSTOMER_FILES_UPLOAD_STATUS = keyMirror({
  WAITING: null,
  PROCESSING: null,
  COMPLETE: null,
  DELETE: null,
})

const CUSTOMER_FILES_UPLOAD_TRIM_KEY_PATH_LIST = [['name']]

export const CUSTOMER_FILES_UPLOADING_STATE = keyMirror({
  IDLE: null,
  UPLOADING: null,
  ANALYZING: null,
})

const initialState = fromJS({
  validationErrorKeysForCustomerFilesUpload: Map(),

  // adid
  uploadedCustomerFiles: [],
  uploadedCustomerFilesResultData: {},
  customerFilesUploadState: CUSTOMER_FILES_UPLOADING_STATE.IDLE,

  customerFileListWithPaging: {
    content: [],
    totalElements: 0,
  },

  customerFilesCsvUploadForm: {
    adAccountId: null,
    name: '',
    fileUrl: null,
    failedFileUrl: null,
    duplicationFileUrl: null,
  },

  customerFilesUrlUploadForm: {
    adAccountId: -1,
    customerFileId: -1,
    name: '',
    fileType: 'ADID', // 현재는 고정값 사용 (ADID | PHONE_NUMBER)
    sourceUrl: '',
    renewable: false,
  },

  readyCustomerFiles: [],

  customerFilesDetailInfo: {
    name: null,
    fileUrl: '',
    adidListKey: null,
    adAccountId: -1,
    adAccountName: '',
    createdDate: null,
    ready: true,
    populationScore: null,
  },

  customerFilesDetailAdGroupList: {
    content: [],
    totalElements: 0,
  },
})

export default createReducer(initialState, {
  [CustomerFilesUpload.INIT_CUSTOMER_FILE_LIST_WITH_PAGING]: state => {
    return state.set(
      'customerFileListWithPaging',
      initialState.get('customerFileListWithPaging')
    )
  },

  [CustomerFilesUpload.GET_CUSTOMER_FILE_LIST_WITH_PAGING]: (
    state,
    { data }
  ) => {
    return state.set('customerFileListWithPaging', fromJS(data))
  },

  [CustomerFilesUpload.GET_UPLOADED_CUSTOMER_FILES]: (state, { data }) => {
    const { uploadedCustomerFiles } = state
    return state.set(
      'uploadedCustomerFiles',
      uploadedCustomerFiles.push(fromJS(data))
    )
  },

  [CustomerFilesUpload.REMOVE_UPLOADED_CUSTOMER_FILES]: (state, { data }) => {
    const { uploadedCustomerFiles } = state
    return state.set(
      'uploadedCustomerFiles',
      uploadedCustomerFiles.filter(f => f !== data)
    )
  },

  [CustomerFilesUpload.RECEIVE_UPLOADED_CUSTOMER_FILES_RESULT_DATA_FOR_TARGETING]:
    (state, { data }) => {
      return state.set('uploadedCustomerFilesResultData', fromJS(data))
    },

  [CustomerFilesUpload.INIT_UPLOADED_CUSTOMER_FILES]: state => {
    return state
      .set('uploadedCustomerFiles', initialState.get('uploadedCustomerFiles'))
      .set(
        'uploadedCustomerFilesResultData',
        initialState.get('uploadedCustomerFilesResultData')
      )
      .set(
        'customerFilesUploadState',
        initialState.get('customerFilesUploadState')
      )
  },

  [CustomerFilesUpload.SET_CUSTOMER_FILES_UPLOAD_STATE]: (
    state,
    { uploadState }
  ) => {
    return state.set('customerFilesUploadState', uploadState)
  },

  [CustomerFilesUpload.SET_IS_VALID_CUSTOMER_FILES_UPLOAD_BY_KEY]: (
    state,
    { data }
  ) => {
    const { validationErrorKeysForCustomerFilesUpload } = state
    const { key, isValid, message } = data
    const newErrorKeys = isValid
      ? validationErrorKeysForCustomerFilesUpload.delete(key)
      : validationErrorKeysForCustomerFilesUpload.set(key, message)
    return state.set('validationErrorKeysForCustomerFilesUpload', newErrorKeys)
  },

  [CustomerFilesUpload.INIT_CUSTOMER_FILES_UPLOAD_VALIDATION_ERROR_KEYS]:
    state => {
      return state.set(
        'validationErrorKeysForCustomerFilesUpload',
        initialState.get('validationErrorKeysForCustomerFilesUpload')
      )
    },

  [CustomerFilesUpload.CHANGE_CUSTOMER_FILES_CSV_UPLOAD_FORM_BY_KEY]: (
    state,
    { data }
  ) => {
    return state.setIn(
      ['customerFilesCsvUploadForm', data.key],
      fromJS(data.value)
    )
  },

  [CustomerFilesUpload.INIT_CUSTOMER_FILES_CSV_UPLOAD_FORM]: state => {
    return state.set(
      'customerFilesCsvUploadForm',
      initialState.get('customerFilesCsvUploadForm')
    )
  },

  [CustomerFilesUpload.CHANGE_CUSTOMER_FILES_URL_UPLOAD_FORM_BY_KEY]: (
    state,
    { key, value }
  ) => {
    return state.setIn(['customerFilesUrlUploadForm', key], fromJS(value))
  },

  [CustomerFilesUpload.INIT_CUSTOMER_FILES_URL_UPLOAD_FORM]: state => {
    return state.set(
      'customerFilesUrlUploadForm',
      initialState.get('customerFilesUrlUploadForm')
    )
  },

  [CustomerFilesUpload.GET_READY_CUSTOMER_FILES]: (state, { data }) => {
    return state.set('readyCustomerFiles', fromJS(data))
  },

  [CustomerFilesUpload.INIT_READY_CUSTOMER_FILES]: state =>
    state.set('readyCustomerFiles', initialState.get('readyCustomerFiles')),

  // details
  [CustomerFilesUpload.GET_CUSTOMER_FILES_DETAIL_INFO]: (state, { data }) => {
    const customerFilesDetailInfo = fromJS(data)
    return state.set('customerFilesDetailInfo', customerFilesDetailInfo)
  },

  [CustomerFilesUpload.GET_CUSTOMER_FILES_AD_GROUP_LIST]: (state, { data }) =>
    state.set('customerFilesDetailAdGroupList', fromJS(data)),

  [CustomerFilesUpload.INIT_CUSTOMER_FILES_DETAIL]: state => {
    return state
      .set(
        'customerFilesDetailInfo',
        initialState.get('customerFilesDetailInfo')
      )
      .set(
        'customerFilesDetailAdGroupList',
        initialState.get('customerFilesDetailAdGroupList')
      )
  },
})

function getUploadedCustomerFiles(data) {
  return {
    type: CustomerFilesUpload.GET_UPLOADED_CUSTOMER_FILES,
    data,
  }
}

function setCustomerFilesUploadState(uploadState) {
  return {
    type: CustomerFilesUpload.SET_CUSTOMER_FILES_UPLOAD_STATE,
    uploadState,
  }
}

export function uploadCustomerFiles(adAccountId, data, originalFileName) {
  const path = `/adid/${adAccountId}`
  return (dispatch, getState, api) => {
    dispatch(
      setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.UPLOADING)
    )

    return api.customerFile
      .uploadCustomerFile(adAccountId, path, data, originalFileName)
      .then(response => {
        dispatch(getUploadedCustomerFiles(response.data || []))

        dispatch(
          setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.IDLE)
        )
      })
      .catch(e => {
        if (e.response && e.response.data) {
          const { message } = e.response.data
          showErrorMessage(message || '')
        }

        dispatch(
          setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.IDLE)
        )
      })
  }
}

export function removeUploadedCustomerFiles(data) {
  return {
    type: CustomerFilesUpload.REMOVE_UPLOADED_CUSTOMER_FILES,
    data,
  }
}

function receiveUploadedCustomerFilesResultDataForTargeting(data) {
  return {
    type: CustomerFilesUpload.RECEIVE_UPLOADED_CUSTOMER_FILES_RESULT_DATA_FOR_TARGETING,
    data,
  }
}

/**
 * 7.5.1 ADID 업로드
 */
export function uploadCustomerFilesForTargeting(adAccountId, data, onSuccess) {
  return (dispatch, getState, api) => {
    dispatch(
      setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.ANALYZING)
    )

    return api.customerFile
      .submitCustomerFilesUpload(adAccountId, data)
      .then(response => {
        dispatch(
          receiveUploadedCustomerFilesResultDataForTargeting(
            response.data || {}
          )
        )
        dispatch(
          setIsValidCustomerFilesUploadByKey(
            CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_FILE,
            true
          )
        )
        dispatch(
          setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.IDLE)
        )

        onSuccess && onSuccess()
      })
      .catch(e => {
        if (e.response && e.response.data) {
          const { message } = e.response.data
          dispatch(
            setIsValidCustomerFilesUploadByKey(
              CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_FILE,
              false,
              message
            )
          )
        }

        dispatch(
          setCustomerFilesUploadState(CUSTOMER_FILES_UPLOADING_STATE.IDLE)
        )
      })
  }
}

export function initUploadedCustomerFiles() {
  return {
    type: CustomerFilesUpload.INIT_UPLOADED_CUSTOMER_FILES,
  }
}

export function initCustomerFilesUploadValidationErrorKeys() {
  return {
    type: CustomerFilesUpload.INIT_CUSTOMER_FILES_UPLOAD_VALIDATION_ERROR_KEYS,
  }
}

export function setIsValidCustomerFilesUploadByKey(key, isValid, message) {
  return {
    type: CustomerFilesUpload.SET_IS_VALID_CUSTOMER_FILES_UPLOAD_BY_KEY,
    data: { key, isValid, message },
  }
}

export function changeCustomerFilesUploadFormByKey(key, value) {
  return {
    type: CustomerFilesUpload.CHANGE_CUSTOMER_FILES_CSV_UPLOAD_FORM_BY_KEY,
    data: { key, value },
  }
}

export function initCustomerFilesCsvUploadForm() {
  return {
    type: CustomerFilesUpload.INIT_CUSTOMER_FILES_CSV_UPLOAD_FORM,
  }
}

export function initCustomerFilesDetail() {
  return {
    type: CustomerFilesUpload.INIT_CUSTOMER_FILES_DETAIL,
  }
}

/**
 * 7.5.2 고객파일 등록
 */
export function submitCustomFilesUploadForm(adAccountId) {
  return async (dispatch, getState, api) => {
    const {
      customerFilesUpload: {
        uploadedCustomerFilesResultData: { successFileUrl, fileType },
        customerFilesCsvUploadForm,
      },
    } = getState()

    // pre-processing
    const formData = Trim(
      customerFilesCsvUploadForm
        .set('adAccountId', adAccountId)
        .set('fileUrl', successFileUrl)
        .set('fileType', fileType),
      CUSTOMER_FILES_UPLOAD_TRIM_KEY_PATH_LIST
    )

    // validation
    const validationResult = Validation(
      formData,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY_PATH,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATOR,
      getState,
      setIsValidCustomerFilesUploadByKey,
      dispatch
    )

    if (!validationResult) return

    try {
      dispatch(showLoading())
      await api.customerFile.submitCustomerFilesUploadForm(
        adAccountId,
        formData.toJS()
      )
      dispatch(initCustomerFileListWithPaging())
      dispatch(getCustomerFileListWithPaging(adAccountId))
      dispatch(closeAllPopup())
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e))
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 아래 순서로 동작하도록 함.
 * 7.5.3 고객파일 삭제
 * 7.5.10 유사고객 Seed 로 사용 중인 고객파일 확인
 */
export function deleteCustomerFiles(adAccountId, customerFileId) {
  return (dispatch, getState, api) => {
    const handleClick = async () => {
      try {
        dispatch(showLoading())

        await api.customerFile.deleteCustomerFiles(adAccountId, customerFileId)
        dispatch(RouterV2.replace(toCustomerFilesListPath(adAccountId)))
      } catch (e) {
        dispatch(handleCustomerFilesUploadException(e, adAccountId))
      } finally {
        dispatch(hideLoading())
      }
    }

    dispatch(
      openPopupByProxy(
        POPUP_KEY.SIMPLE_POPUP,
        <PopupProxy hasSecondaryButton={true} primaryButtonFunc={handleClick}>
          <strong className="tit_layer">고객파일 삭제</strong>
          <p className="txt_layer">해당 고객파일을 삭제하시겠습니까?</p>
        </PopupProxy>
      )
    )
  }
}

function receiveCustomFilesDetailInfo(data) {
  return {
    type: CustomerFilesUpload.GET_CUSTOMER_FILES_DETAIL_INFO,
    data,
  }
}

/**
 * 7.5.4 고객파일 상세 조회
 */
export function getCustomerFilesDetail({
  adAccountId,
  customerFileId,
  onFinish = () => undefined,
}) {
  return async (dispatch, getState, api) => {
    try {
      const { data: customerFilesDetail } =
        await api.customerFile.getCustomerFilesDetail(
          adAccountId,
          customerFileId
        )

      dispatch(receiveCustomFilesDetailInfo(customerFilesDetail || {}))
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e, adAccountId))
    } finally {
      onFinish()
    }
  }
}

function receiveCustomerFileListWithPaging(data) {
  return {
    type: CustomerFilesUpload.GET_CUSTOMER_FILE_LIST_WITH_PAGING,
    data,
  }
}

export function initCustomerFileListWithPaging() {
  return {
    type: CustomerFilesUpload.INIT_CUSTOMER_FILE_LIST_WITH_PAGING,
  }
}

/**
 * 7.5.5 고객파일 목록 조회
 */
export function getCustomerFileListWithPaging(
  adAccountId,
  currentPage,
  size,
  onFinish = () => undefined
) {
  return (dispatch, getState, api) => {
    return api.customerFile
      .getCustomerFiles(adAccountId, currentPage, size)
      .then(response => {
        dispatch(receiveCustomerFileListWithPaging(response.data || {}))
        onFinish(response)
      })
      .catch(e => {
        if (e.response?.data?.errorCode === 21009) {
          dispatch(RouterV2.replace(toInvalidApproachPath()))
        }

        onFinish(null, e)
      })
  }
}

function receiveReadyCustomerFiles(data) {
  return {
    type: CustomerFilesUpload.GET_READY_CUSTOMER_FILES,
    data,
  }
}

export function initReadyCustomerFiles() {
  return {
    type: CustomerFilesUpload.INIT_READY_CUSTOMER_FILES,
  }
}

/**
 * 7.5.6 고객파일 이름 수정
 */
export function modifyCustomerFilesName(
  adAccountId,
  fileName,
  customerFileId,
  onSuccess
) {
  return async (dispatch, getState, api) => {
    const formData = Trim(
      Map({ name: fileName }),
      CUSTOMER_FILES_UPLOAD_TRIM_KEY_PATH_LIST
    )

    // validation
    const validationResult = Validation(
      formData,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY_PATH,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATOR,
      getState,
      setIsValidCustomerFilesUploadByKey,
      dispatch
    )

    if (!validationResult) return

    dispatch(showLoading())

    try {
      await api.customerFile.modifyCustomerFilesName(
        adAccountId,
        formData.toJS(),
        customerFileId
      )
      dispatch(getCustomerFilesDetail({ adAccountId, customerFileId }))
      dispatch(initCustomerFilesUploadValidationErrorKeys())
      onSuccess && onSuccess()
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e, adAccountId))
    } finally {
      dispatch(hideLoading())
    }
  }
}

/**
 * 고객파일 가져오기 팝업 목록 조회
 * @param adAccountId
 * @returns {function(...[*]=)}
 */
export function fetchCustomerFiles(adAccountId) {
  return async (dispatch, getState, api) => {
    try {
      const {
        data: { content },
      } = await api.customerFile.getCustomerFiles(adAccountId, 0, 50)

      const newCustomerFileList = content.filter(
        v =>
          ![
            CUSTOMER_FILES_STATUS.TRANSFORM_ERROR,
            CUSTOMER_FILES_STATUS.ERROR,
          ].includes(v.status)
      )

      dispatch(receiveReadyCustomerFiles(newCustomerFileList))
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e))
    }
  }
}

function receiveCustomerFilesDetailInfoAdGroupsList(data) {
  return {
    type: CustomerFilesUpload.GET_CUSTOMER_FILES_AD_GROUP_LIST,
    data,
  }
}

/**
 * 7.5.8 고객파일을 사용 중인 광고그룹 조회
 */
export function getCustomerFilesDetailAdGroupList(
  adAccountId,
  customerFileId,
  searchOptions
) {
  return (dispatch, getState, api) => {
    dispatch(showLoading('getCustomerFilesDetailAdGroupList'))

    return api.customerFile
      .getCustomerFilesDetailAdGroupList(
        adAccountId,
        customerFileId,
        searchOptions
      )
      .then(response => {
        dispatch(
          receiveCustomerFilesDetailInfoAdGroupsList(response.data) || {}
        )
      })
      .catch(e => e.message)
      .finally(() => {
        dispatch(hideLoading('getCustomerFilesDetailAdGroupList'))
      })
  }
}

/**
 * 고객파일 수정
 */
export function submitModifyCustomFilesUploadForm(adAccountId, customerFileID) {
  return async (dispatch, getState, api) => {
    const {
      customerFilesUpload: {
        uploadedCustomerFilesResultData: { successFileUrl, fileType },
        customerFilesCsvUploadForm,
      },
    } = getState()

    // pre-processing
    const formData = Trim(
      customerFilesCsvUploadForm
        .set('adAccountId', adAccountId)
        .set('fileUrl', successFileUrl)
        .set('fileType', fileType)
        .delete('name'),
      CUSTOMER_FILES_UPLOAD_TRIM_KEY_PATH_LIST
    )

    try {
      dispatch(showLoading())
      await api.customerFile.submitModifyCustomerFilesUploadForm(
        adAccountId,
        customerFileID,
        formData
      )

      dispatch(RouterV2.push(toCustomerFilesListPath(adAccountId)))
      dispatch(closeAllPopup())
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e, adAccountId))
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function uploadCustomerFilesCsv(adAccountId, file) {
  return (dispatch, getState) => {
    dispatch(initCustomerFilesUploadValidationErrorKeys())

    const data = new FormData()
    data.append('file', file)

    const {
      customerFilesUpload: { uploadedCustomerFiles },
    } = getState()

    const validFileSize = fileSize => {
      const totalSize = uploadedCustomerFiles.reduce(
        (sum, file) => sum + file.get('fileSize'),
        0
      )
      return totalSize + fileSize <= CUSTOMER_FILES_CSV_SIZE
    }

    const validFileName = uploadedCustomerFiles.every(
      v => v.get('originalFileName') !== file.name
    )

    if (!validFileSize(file.size)) {
      dispatch(
        setIsValidCustomerFilesUploadByKey(
          CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_FILE,
          false,
          '업로드한 파일의 전체 용량이 200MB를 초과합니다. 파일 크기 확인 후 재등록하세요.'
        )
      )
    } else if (!validFileName) {
      dispatch(
        setIsValidCustomerFilesUploadByKey(
          CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_NAME,
          false,
          '이미 업로드한 파일 이름입니다.'
        )
      )
    } else {
      dispatch(uploadCustomerFiles(adAccountId, data, file.name))
    }
  }
}

export function changeCustomerFilesUrlUploadByKey(key, value) {
  return dispatch => {
    dispatch({
      type: CustomerFilesUpload.CHANGE_CUSTOMER_FILES_URL_UPLOAD_FORM_BY_KEY,
      key,
      value,
    })
  }
}

export const initCustomerFilesUrlUploadForm = () => ({
  type: CustomerFilesUpload.INIT_CUSTOMER_FILES_URL_UPLOAD_FORM,
})

export function uploadCustomerFilesUrl({
  adAccountId,
  customerFileId,
  isModify,
}) {
  return async (dispatch, getState, api) => {
    const {
      customerFilesUpload: { customerFilesUrlUploadForm },
    } = getState()

    const formData = customerFilesUrlUploadForm.set('adAccountId', adAccountId)

    const validationResult = Validation(
      formData,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY_PATH,
      CUSTOMER_FILES_UPLOAD_FORM_VALIDATOR,
      getState,
      setIsValidCustomerFilesUploadByKey,
      dispatch
    )

    if (!validationResult) return

    dispatch(showLoading())

    try {
      if (isModify) {
        await api.customerFile.modifyCustomerFilesUrlUpload(
          adAccountId,
          customerFileId,
          formData
        )
      } else {
        await api.customerFile.createCustomerFilesUrlUpload(
          adAccountId,
          formData
        )
      }

      dispatch(closeAllPopup())
      dispatch(getCustomerFileListWithPaging(adAccountId))
      dispatch(RouterV2.push(toCustomerFilesListPath(adAccountId)))
    } catch (e) {
      dispatch(handleCustomerFilesUploadException(e))
    } finally {
      dispatch(hideLoading())
    }
  }
}

export function clearCustomerFilesUpload() {
  return dispatch => {
    dispatch(initUploadedCustomerFiles())
    dispatch(initCustomerFilesCsvUploadForm())
    dispatch(initCustomerFilesUrlUploadForm())
    dispatch(initCustomerFilesUploadValidationErrorKeys())
  }
}

function handleCustomerFilesUploadException(e, adAccountId) {
  return dispatch => {
    const { errorCode, message } = e?.response?.data || {}

    switch (errorCode) {
      case 2003:
      case 2012: {
        showErrorMessage(message)
        dispatch(RouterV2.replace(toCustomerFilesListPath(adAccountId)))
        break
      }

      case 2010: {
        showErrorMessage('이미 삭제된 고객파일입니다.')
        dispatch(RouterV2.replace(toCustomerFilesListPath(adAccountId)))
        break
      }

      case 21009: {
        showErrorMessage(message)
        dispatch(RouterV2.replace(toInvalidApproachPath()))
        break
      }

      // duplicated
      case 2007: {
        dispatch(
          setIsValidCustomerFilesUploadByKey(
            CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.CUSTOMER_FILES_UPLOAD_NAME,
            false,
            '이미 사용 중인 고객파일 이름입니다.'
          )
        )
        break
      }

      case 2008:
      case 2009: {
        dispatch(
          openPopupByProxy(
            POPUP_KEY.SIMPLE_POPUP,
            <PopupProxy>
              <strong className="tit_layer">고객파일 삭제 불가</strong>
              <p className="txt_layer">
                해당 타겟을 사용 중인 광고그룹 또는 오디언스가 있습니다.
                <br />
                타겟 사용현황을 참고하여 모든 광고그룹, 오디언스에서
                <br />
                타겟을 삭제한 후 다시 시도하세요.
              </p>
            </PopupProxy>
          )
        )
        break
      }

      // 고객파일 URL 유형 validation
      case 2013:
      case 2014:
      case 2016: {
        dispatch(
          setIsValidCustomerFilesUploadByKey(
            CUSTOMER_FILES_UPLOAD_FORM_VALIDATION_KEY.URL,
            false,
            message
          )
        )

        break
      }

      case 2028: {
        showErrorMessage('수정이 불가능한 고객파일입니다.')
        break
      }

      default: {
        showErrorMessage(message)
        break
      }
    }
  }
}
