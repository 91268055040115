import { fromJS } from 'immutable'
import { createReducer } from 'redux-immutablejs'
import { keyMirror } from '../../../utils/utils'
import { handlePlusFriendGroupFileException } from '../mPlusFriendGroup'

const PlusFriendGroupListKey = keyMirror({
  INIT_PLUS_FRIEND_GROUP_LIST_WITH_PAGING: null,

  GET_PLUS_FRIEND_PROFILE_LIST: null,
  GET_PLUS_FRIEND_GROUPS_WITH_PAGING: null,
  GET_PLUS_FRIEND_GROUP_LIST_POPULATION: null,
})

const initialState = fromJS({
  plusFriendGroupListWithPaging: {
    content: [],
    totalElements: 0,
  },
  plusFriendProfileList: [],
})

export default createReducer(initialState, {
  [PlusFriendGroupListKey.GET_PLUS_FRIEND_GROUPS_WITH_PAGING]: (
    state,
    { data }
  ) => {
    return state.set(
      'plusFriendGroupListWithPaging',
      fromJS(data).update('content', content =>
        content.map(v =>
          v
            .set('populationScore', null)
            .set('targetingValue', v.get('groupKey'))
        )
      )
    )
  },

  // population
  [PlusFriendGroupListKey.GET_PLUS_FRIEND_GROUP_LIST_POPULATION]: (
    state,
    { data }
  ) => {
    const { plusFriendGroupListWithPaging } = state
    const { content } = plusFriendGroupListWithPaging

    if (content.size === 0) {
      return state
    }

    const newContent = data.reduce((prevContent, v) => {
      const { targetingValue, populationScore } = v

      return prevContent.update(
        prevContent.findIndex(c => c.get('targetingValue') === targetingValue),
        plusFriendGroupFile =>
          plusFriendGroupFile.set('populationScore', populationScore)
      )
    }, content)

    return state.setIn(['plusFriendGroupListWithPaging', 'content'], newContent)
  },

  [PlusFriendGroupListKey.GET_PLUS_FRIEND_PROFILE_LIST]: (state, { data }) => {
    return state.set('plusFriendProfileList', fromJS(data))
  },

  [PlusFriendGroupListKey.INIT_PLUS_FRIEND_GROUP_LIST_WITH_PAGING]: state => {
    return state.set(
      'plusFriendGroupListWithPaging',
      initialState.get('plusFriendGroupListWithPaging')
    )
  },
})

function receivePlusFriendGroupFileListWithPaging(data) {
  return {
    type: PlusFriendGroupListKey.GET_PLUS_FRIEND_GROUPS_WITH_PAGING,
    data,
  }
}

export function getPlusFriendGroupListPopulation(data) {
  return {
    type: PlusFriendGroupListKey.GET_PLUS_FRIEND_GROUP_LIST_POPULATION,
    data,
  }
}

export function getPlusFriendGroupFileListWithPaging(
  adAccountId,
  currentPage,
  size,
  onFinish
) {
  return (dispatch, getState, api) => {
    return api.plusFriendGroup
      .getPlusFriendGroupFiles(adAccountId, currentPage, size)
      .then(response => {
        dispatch(receivePlusFriendGroupFileListWithPaging(response.data || {}))
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e)
      })
      .finally(() => {
        if (typeof onFinish === 'function') {
          onFinish()
        }
      })
  }
}

export function getPlusFriendProfileList(onFinish) {
  return (dispatch, getState, api) => {
    return api.user
      .getPlusFriendProfileList()
      .then(response => {
        dispatch(receivePlusFriendProfileList(response.data || []))
        if (typeof onFinish === 'function') {
          onFinish()
        }
      })
      .catch(e => {
        handlePlusFriendGroupFileException(dispatch, e)
        if (typeof onFinish === 'function') {
          onFinish()
        }
      })
  }
}

function receivePlusFriendProfileList(data) {
  return {
    type: PlusFriendGroupListKey.GET_PLUS_FRIEND_PROFILE_LIST,
    data,
  }
}

export function initPlusFriendGroupListWithPaging() {
  return {
    type: PlusFriendGroupListKey.INIT_PLUS_FRIEND_GROUP_LIST_WITH_PAGING,
  }
}
