import React from 'react'
import cx from 'classnames'
import useComponentId from '../../../../../utils/hook/useComponentId'
import PropTypes from 'prop-types'
import { CMP_INPUT_MAX_LENGTH_CHANGE_MODE } from './cmpInputUtils'
import { CmpInputAssetLayer } from './CmpInputAssetLayer'
import cmpPropTypes from '../cmpPropTypes'
import DynamicTooltip from '../../../../Tooltip/DynamicTooltip'
import {
  DYNAMIC_TOOLTIP_POSITION_HORIZONTAL,
  DynamicTooltipDarkStyles,
} from '../../../../Tooltip/dynamicTooltipUtils'
import ClickAwayListener from 'react-click-away-listener'
import { useMergeRefs } from 'use-callback-ref'
import useCmpInputAssetLayer from './useCmpInputAssetLayer'
import { useParams } from 'react-router-dom'
import { useDelayedSingleton } from '../../../../../utils/hook/useDelayedSingleton'
import { coerceAtLeast } from '../../../../../utils/utils'

const CmpTextInput = React.forwardRef(
  (
    {
      // cmp props
      adAccountId: _adAccountId,
      cmpRequestCreator,
      cmpResponseTransformer,
      cmpAssetButtonTooltip = '',
      cmpAssetListItemRenderer,
      // input props
      id,
      className = '',
      labelClassName = '',
      iconClassName = '',
      title = '',
      placeholder,
      maxLength = 999,
      maxLengthChangeMode = CMP_INPUT_MAX_LENGTH_CHANGE_MODE.NONE,
      autoComplete = 'on',
      autoFocus = false,
      active = true,
      error = false,
      readOnly = false,
      isShowingInputLength = true,
      isShowingRemoveButton = false,
      hideInputWhenInActive = false,
      value: _value = '',
      onRemove = e => undefined,
      onChange = e => undefined,
      onClick = e => undefined,
      onFocus = e => undefined,
      onBlur = e => undefined,
      onEnterKey = e => undefined,
      children,
    },
    forwardedInputRef
  ) => {
    const value = _value ?? '' // avoid `null` exception

    const adAccountId = useParams().adaccountid ?? _adAccountId

    const isAvailableCmpAsset = adAccountId > 0 && !!cmpRequestCreator

    const inputRef = React.useRef()

    const [hasFocus, setHasFocus] = React.useState(false)

    const _inputId = useComponentId()
    const inputId = id ?? _inputId

    const {
      cmpAssetLayerHandlers,
      cmpAssetLayerState,
      setCmpAssetLayerState,
      onClickAway,
    } = useCmpInputAssetLayer({
      inputRef,
      inputId,
      maxLength,
      inputValue: value,
      maxLengthChangeMode,
    })

    const { invoke, cancelInvoking } = useDelayedSingleton({ delay: 300 })

    const valueLength = String(value).length

    const hideInputValue = !active && hideInputWhenInActive

    return (
      <ClickAwayListener onClickAway={onClickAway}>
        <span
          className={cx('box_inptxt', className, {
            on: hasFocus,
            in_active: !active,
            in_error: error,
            on_inp: valueLength > 0,
            history_on: cmpAssetLayerState.isOpened,
          })}>
          {isAvailableCmpAsset && (
            <a
              className="btn_history"
              onClick={() => {
                setCmpAssetLayerState(prevState => ({
                  ...prevState,
                  isOpened: !prevState.isOpened,
                }))
              }}>
              <DynamicTooltip
                rootElementType="span"
                rootElementClassName="ico_comm"
                horizontalPosition={DYNAMIC_TOOLTIP_POSITION_HORIZONTAL.CENTER}
                styles={DynamicTooltipDarkStyles}
                content={cmpAssetButtonTooltip}>
                최근 사용 목록
              </DynamicTooltip>
            </a>
          )}
          {isShowingInputLength && maxLength > 0 && (
            <span className="num_byte">
              <span className="screen_out">작성가능한 총 텍스트 수</span>
              {coerceAtLeast(maxLength - valueLength, 0)}
            </span>
          )}
          <span className="inner_inp">
            {iconClassName && <span className={`ico_comm ${iconClassName}`} />}
            <label
              className={placeholder ? 'lab_txt' : labelClassName}
              htmlFor={inputId}>
              {value.length === 0 && placeholder}
            </label>
            <input
              ref={useMergeRefs([inputRef, forwardedInputRef])}
              id={inputId}
              name={inputId}
              className="inp_txt"
              type="text"
              title={title}
              maxLength={maxLength}
              value={hideInputValue ? '' : value}
              readOnly={readOnly}
              disabled={!active}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              onChange={e => {
                if (!active || readOnly) return
                if (
                  e.target.value.length > maxLength &&
                  e.target.value.length > valueLength
                ) {
                  return
                }

                onChange(e)
              }}
              onClick={e => {
                onClick(e)

                cancelInvoking()
              }}
              onFocus={e => {
                setHasFocus(true)

                onFocus(e)

                setCmpAssetLayerState(prevState => ({
                  ...prevState,
                  isOpened: prevState.isOpened || prevState.focusOpen,
                  focusOpen: false,
                }))

                cancelInvoking()
              }}
              onBlur={e => {
                /**
                 * Firefox issue
                 * http://tirdadc.github.io/blog/2015/06/11/react-dot-js-firefox-issue-with-onblur/
                 */
                const { explicitOriginalTarget, originalTarget } = e.nativeEvent

                if (
                  explicitOriginalTarget &&
                  explicitOriginalTarget === originalTarget
                )
                  return

                setHasFocus(false)

                onBlur(e)

                invoke(cmpAssetLayerHandlers.onCloseForced)
              }}
              onKeyPress={e => {
                if (e.key === 'Enter') {
                  onEnterKey(e)
                }
              }}
            />
          </span>
          {isShowingRemoveButton && valueLength > 0 && active && !readOnly && (
            <button className="btn_del" onClick={onRemove}>
              <span className="ico_comm ico_del">삭제</span>
            </button>
          )}
          {isAvailableCmpAsset && cmpAssetLayerState.isOpened && (
            <CmpInputAssetLayer
              adAccountId={adAccountId}
              value={value}
              cmpRequestCreator={cmpRequestCreator}
              cmpResponseTransformer={cmpResponseTransformer}
              cmpAssetListItemRenderer={cmpAssetListItemRenderer}
              {...cmpAssetLayerHandlers}
            />
          )}
          {children}
        </span>
      </ClickAwayListener>
    )
  }
)

CmpTextInput.displayName = 'CmpTextInput'
CmpTextInput.propTypes = {
  // cmp props
  ...cmpPropTypes.InputAsset.isRequired,
  // input props
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  iconClassName: PropTypes.string,
  title: PropTypes.string,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  maxLengthChangeMode: PropTypes.oneOf(
    Object.keys(CMP_INPUT_MAX_LENGTH_CHANGE_MODE)
  ),
  autoComplete: PropTypes.oneOf(['on', 'off']),
  autoFocus: PropTypes.bool,
  active: PropTypes.bool,
  error: PropTypes.bool,
  readOnly: PropTypes.bool,
  isShowingInputLength: PropTypes.bool,
  isShowingRemoveButton: PropTypes.bool,
  hideInputWhenInActive: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onRemove: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onEnterKey: PropTypes.func,
  children: PropTypes.any,
}

export default CmpTextInput
