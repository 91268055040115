import { toast } from 'react-hot-toast'
import React from 'react'
import { TOASTER_BUTTON_GROUP_CLASSNAME } from '../components/MomentToaster/MomentToaster'

const TOAST_DURATION_SUCCESS = 3 * 1000
const TOAST_DURATION_ERROR = 3 * 1000
const TOAST_DURATION_IMPORTANT = 5 * 1000

function showSuccessMessage(message) {
  return toast.success(message)
}

function showErrorMessage(message) {
  return toast.error(message)
}

function showLoadingMessage(message) {
  return toast.loading(message)
}

function removeToastMessage(toastId) {
  toast.remove(toastId)
}

async function showPromiseMessage({
  existToastId,
  promiseFn = () => new Promise(resolve => resolve()),
  onLoading = () => '처리 중입니다.',
  onSuccess = response => '변경사항이 적용되었습니다.',
  onError = e =>
    e?.response?.data?.message ||
    e?.message ||
    '변경사항을 적용할 수 없습니다.',
  onFinally = () => {},
  loadingOptions = {
    duration: Infinity,
  },
  successOptions = {
    duration: TOAST_DURATION_SUCCESS,
  },
  errorOptions = {
    duration: TOAST_DURATION_ERROR,
  },
  renderButtonOnSuccess = response => null,
  renderButtonOnError = error => null,
}) {
  let toastId = ''

  try {
    const nextToastId = toast.loading(
      typeof onLoading === 'function' ? onLoading() : onLoading || '',
      { ...loadingOptions, id: existToastId }
    )

    toastId = existToastId ?? nextToastId

    const response = await promiseFn()

    const button = renderButtonOnSuccess?.(response)
    const showButton = React.isValidElement(button)

    const successMessage = (
      <div style={{ width: '100%', height: '100%' }}>
        {typeof onSuccess === 'function'
          ? onSuccess(response)
          : onSuccess || ''}
        {showButton && (
          <div className={TOASTER_BUTTON_GROUP_CLASSNAME}>{button}</div>
        )}
      </div>
    )

    toast.success(successMessage, {
      id: toastId,
      ...successOptions,
      duration: showButton ? TOAST_DURATION_IMPORTANT : successOptions.duration,
      style: showButton ? { paddingBottom: '20px' } : undefined,
    })

    return toastId
  } catch (e) {
    const button = renderButtonOnError?.(e)
    const showButton = React.isValidElement(button)

    const errorMessage = (
      <div style={{ width: '100%', height: '100%' }}>
        {typeof onError === 'function' ? onError(e) : onError || ''}
        {showButton && (
          <div className={TOASTER_BUTTON_GROUP_CLASSNAME}>{button}</div>
        )}
      </div>
    )

    toast.error(errorMessage, {
      id: toastId,
      ...errorOptions,
      duration: showButton ? TOAST_DURATION_IMPORTANT : errorOptions.duration,
      style: showButton ? { paddingBottom: '20px' } : undefined,
    })

    return toastId
  } finally {
    if (typeof onFinally === 'function') {
      onFinally()
    }
  }
}

async function showCancelablePromiseMessage({
  promiseFn = () =>
    new Promise(res => {
      res()
    }),
}) {
  let toastId = ''
  try {
    toastId = toast.loading('')
    await promiseFn()
  } catch (e) {
    toast.error(e.message, { id: toastId })
  }
}

export {
  TOAST_DURATION_SUCCESS,
  TOAST_DURATION_ERROR,
  TOAST_DURATION_IMPORTANT,
  showSuccessMessage,
  showErrorMessage,
  showLoadingMessage,
  showPromiseMessage,
  removeToastMessage,
}
